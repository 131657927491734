export interface TutorialProgress {
	[step: number]: boolean | undefined;
	isEmpty: boolean;
}

export interface TutorialProgressService {
	getProgress(): Promise<TutorialProgress>;
	setProgress(step: number): Promise<void>;
}

export class LocalTutorialProgressService implements TutorialProgressService {
	private readonly key = 'tutorialProgress';

	constructor(private readonly localStorage: Storage, private readonly pageId: string) {}

	public getProgress() {
		const data = this.getFromStorage();
		const pageData = data[this.pageId];
		if (!pageData) {
			return Promise.resolve({ quizComplete: false, isEmpty: true });
		}
		const newFormat = Object.keys(pageData)
			.filter(k => /^#step-\d+$/.test(k))
			.reduce<TutorialProgress>(
				(progress, k) => {
					progress[+k.substr(6)] = true;
					progress.isEmpty = false;
					return progress;
				},
				{ isEmpty: true }
			);
		return Promise.resolve(newFormat);
	}

	public setProgress(step: number) {
		const data = this.getFromStorage();
		if (!data[this.pageId]) {
			data[this.pageId] = {};
		}
		const pageData = data[this.pageId];
		pageData[`#step-${step}`] = true;
		this.localStorage.setItem(this.key, JSON.stringify(data));
		return Promise.resolve();
	}

	private getFromStorage() {
		const serialized = this.localStorage.getItem(this.key);
		if (serialized === null) {
			return {};
		}
		let data: any = null;
		try {
			data = JSON.parse(serialized);
		} catch (e) {
			// corrupt.
			// this.localStorage.removeItem(this.key);
		}
		return data || {};
	}
}

import { assessmentApi } from '../apis/assessments';
import { authStatusDetermined } from '../auth/index';
import { user } from '../auth/user';
import { getAssessmentBranch } from './branches';
import { createSessionInvalidContent } from './invalid';
import {
	getAssessmentSessionList,
	getCurrentAssessment,
	getCurrentAssessmentList,
	tryGetGuidanceById
} from './model';
import { loc_assessment_assessmentNotFound } from '@msdocs/strings';
import { createNewLocalAssessmentSession, getSessionLocal } from './session';
import {
	Assessment,
	AssessmentNavigation,
	AssessmentSearchParams,
	AssessmentSession,
	Guidance,
	GuidanceNavigation,
	HomeNavigation,
	PreAssessmentNavigation,
	QuestionnaireNavigation
} from './types';
import {
	areThereUnansweredRequiredQuestionsBefore,
	hasQuestionBeenFullyAnswered,
	hasQuestionBeenPartiallyAnswered,
	precedesRequiredQuestion
} from './utilities';
import { features } from '../environment/features';

export let currentAssessmentNavigation: AssessmentNavigation;

/**
 * Determine logical navigation action and if applicable ensure the user's sessions supports this action
 */
export async function getAssessmentNavigationStep(
	query: AssessmentSearchParams
): Promise<AssessmentNavigation> {
	const action = await validateAction(await getRawAction(query));
	currentAssessmentNavigation = action;
	return action;
}

/**
 * Get the un-validated action based on the current url search parameters.
 */
export async function getRawAction(query: AssessmentSearchParams): Promise<AssessmentNavigation> {
	await authStatusDetermined;
	return !user.isAuthenticated
		? await getRawActionUnauthed(query)
		: await getRawActionAuthed(query);
}

/**
 * Give an action, determine if the current situation allows the user to be navigated. Change the action as required
 * @param action A object representing a particular mode of an assesment.
 */
export async function validateAction(action: AssessmentNavigation): Promise<AssessmentNavigation> {
	switch (action.mode) {
		case 'home':
		case 'invalid':
			return action;
		case 'guidance':
			return validateGuidanceNav(action);
		case 'pre-assessment':
			return validatePreAssessmentNav(action);
		case 'questionnaire':
			return validateQuestionnaireNav(action);
		default:
			return createHomeNavigationAction('master');
	}
}

/**
 * Determines if the current situation allows the user to be to the questionnaire
 * @param action A object representing a particular mode of an assessment.
 */
function validateQuestionnaireNav(action: QuestionnaireNavigation): AssessmentNavigation {
	const { assessment, session } = action;
	if (session.status === 'completed') {
		return createSessionInvalidContent(session, action.branch);
	}
	if (!assessment) {
		return createAssessmentNotFoundNavigationAction(action.branch);
	}

	if (!hasValidCategorySelection(session, assessment)) {
		return {
			mode: 'pre-assessment',
			session,
			assessment,
			message:
				'The user has tried to navigate to the questionnaire, but they do not have any categories selected',
			reportValidity: true,
			branch: action.branch
		};
	}

	if (!questionIsNavigable(session, assessment, action.categoryId, action.questionId)) {
		// A question may not be navigable for many reasons, likely outside the users control;
		// land the user in the resume location as if they are re-opening this assessment.
		const { categoryId, questionId } = getLogicalResumeQuestion(session, assessment);

		// Couldn't find a logical resume question, default the user back to home
		if (!categoryId || !questionId) {
			return createHomeNavigationAction(action.branch);
		}

		return createQuestionNavigationAction(
			session,
			assessment,
			categoryId,
			questionId,
			action.branch
		);
	}

	return action;
}

/**
 * Determines if the current situation allows the user to be to the preassessment
 * @param action A object representing a particular mode of an assesment.
 */
function validatePreAssessmentNav(action: PreAssessmentNavigation): AssessmentNavigation {
	const { assessment, session } = action;
	if (session.status === 'completed') {
		return createSessionInvalidContent(session, action.branch);
	}
	if (!assessment) {
		return createAssessmentNotFoundNavigationAction(action.branch);
	}
	return action;
}

/**
 * Determines if the current situation allows the user to be to the guidance
 * @param action A object representing a particular mode of an assessment.
 */
function validateGuidanceNav(action: GuidanceNavigation): AssessmentNavigation {
	const { guidance } = action;
	if (!guidance) {
		return createHomeNavigationAction(action.branch);
	}

	return action;
}

/**
 * Determine the correct navigation action for an unauthenticated user
 * @param query The search params for an assessment
 */
async function getRawActionUnauthed(query: AssessmentSearchParams): Promise<AssessmentNavigation> {
	const session = getSessionLocal();
	const branch = getAssessmentBranch(query);

	// If the query specifies session "local", use  the local session, browser button routing issues between home and pre-assessment
	if (query.session === 'local' && query.mode === 'pre-assessment' && session) {
		const assessment = await getCurrentAssessment(
			session.assessmentId,
			session.version,
			session.branch,
			session.locale
		);
		if (!assessment) {
			return createAssessmentNotFoundNavigationAction(branch);
		}
		return {
			mode: 'pre-assessment',
			assessment,
			session,
			branch
		};
	}

	if (query.id && !query.mode) {
		// If an assessment id is included, we take that as intended regardless of session

		let assessment = null;
		if (!session || session.assessmentId !== query.id) {
			// Unauted user starts a new session, always use the latest version
			assessment = await getCurrentAssessment(query.id, null, branch);
		} else {
			// Unauthed user resume a session, come back from a permalink, should pick up from where left off and still use the version in local storage when he started the session
			assessment = await getCurrentAssessment(
				query.id,
				session.version,
				session.branch,
				session.locale
			);
		}

		if (!assessment) {
			return createAssessmentNotFoundNavigationAction(branch);
		}

		// if a local session exists, see where to resume
		if (session && session.assessmentId === query.id) {
			// if responses exist, go to the next logical question
			if (session.responses.length > 0) {
				const { categoryId, questionId } = getLogicalResumeQuestion(session, assessment);

				// when the pre-assessment hasn't been completed
				if (!categoryId || !questionId) {
					return {
						mode: 'pre-assessment',
						assessment,
						session,
						branch
					};
				}

				// When we have a category and question to go to
				return createQuestionNavigationAction(session, assessment, categoryId, questionId, branch);
			}

			// if no responses exist, go to the pre-assessment with the previous session
			return {
				mode: 'pre-assessment',
				assessment,
				session,
				branch
			};
		}

		return {
			mode: 'pre-assessment',
			assessment,
			session: await createNewLocalAssessmentSession(
				assessment.title,
				assessment.id,
				assessment.version,
				branch,
				assessment.locale
			),
			branch
		};
	}

	if (query.mode === 'pre-assessment') {
		if (!query.id) {
			// The user want to go to the preassessment and we're going to use their local session to choose which assessment
			if (session) {
				const assessment = await getCurrentAssessment(
					session.assessmentId,
					session.version,
					session.branch,
					session.locale
				);
				if (assessment) {
					return {
						mode: 'pre-assessment',
						assessment,
						session,
						branch
					};
				}
				return createAssessmentNotFoundNavigationAction(branch);
			}
			return createHomeNavigationAction(branch);
		}

		const assessment = await getCurrentAssessment(query.id, null, branch);

		if (!assessment) {
			return createAssessmentNotFoundNavigationAction(branch);
		}

		return {
			mode: 'pre-assessment',
			assessment,
			session: await createNewLocalAssessmentSession(
				assessment.title,
				assessment.id,
				assessment.version,
				branch,
				assessment.locale
			),
			branch
		};
	}

	if (query.mode === 'questionnaire' && session && query.category && query.question) {
		const assessment = await getCurrentAssessment(
			session.assessmentId,
			session.version,
			session.branch,
			session.locale
		);
		if (assessment) {
			return createQuestionNavigationAction(
				session,
				assessment,
				query.category,
				query.question,
				branch
			);
		}
	}

	if (query.mode === 'guidance') {
		if (query.session) {
			const guidance = await tryGetGuidanceById(query);
			if (guidance && guidance.errorCode === undefined) {
				return {
					mode: 'guidance',
					guidance: guidance as Guidance,
					branch
				};
			}
			return createAssessmentNotFoundNavigationAction(branch);
		}

		if (session && session.status === 'completed') {
			const guidance = await assessmentApi.saveGuidance(session);
			return {
				mode: 'guidance',
				session,
				guidance,
				branch
			};
		}
	}

	return createHomeNavigationAction(branch);
}

/**
 * Determine the correct navigation action for an authenticated user
 * @param query The search params for an assessment
 */
async function getRawActionAuthed(query: AssessmentSearchParams): Promise<AssessmentNavigation> {
	const branch = getAssessmentBranch(query);

	if (!query.session) {
		if ((query.mode === 'pre-assessment' && query.id) || (!query.mode && query.id)) {
			const assessment = await getCurrentAssessment(query.id, null, branch);
			if (!assessment) {
				return createAssessmentNotFoundNavigationAction(branch);
			}
			return {
				mode: 'pre-assessment',
				assessment,
				session: await createNewLocalAssessmentSession(
					assessment.title,
					assessment.id,
					assessment.version,
					branch,
					assessment.locale
				),
				reportValidity: query.reportValidity === 'true',
				branch
			};
		}

		return createHomeNavigationAction(branch);
	}

	// Use session id and mode is guidance in query parameters
	if (query.session && query.mode === 'guidance') {
		const guidance = await tryGetGuidanceById(query);
		if (guidance && guidance.errorCode === undefined) {
			return {
				mode: 'guidance',
				guidance: guidance as Guidance,
				message:
					'User visit guidance without sign in using query parameter by passing in session id',
				branch
			};
		}
		return createAssessmentNotFoundNavigationAction(branch);
	}

	// We use the user's session id
	// Check if that session is real
	const session = await assessmentApi.getSessionById(query.session);
	if (!session) {
		return createHomeNavigationAction(branch);
	}

	if (session.status === 'completed') {
		return createSessionInvalidContent(session, branch);
	}
	const assessment = await getCurrentAssessment(
		session.assessmentId,
		session.version,
		session.branch,
		session.locale
	);
	if (!assessment) {
		return createAssessmentNotFoundNavigationAction(branch);
	}
	if (query.mode === 'questionnaire' && query.category && query.question) {
		return createQuestionNavigationAction(
			session,
			assessment,
			query.category,
			query.question,
			branch
		);
	}

	if (query.mode === 'pre-assessment') {
		return {
			mode: 'pre-assessment',
			session,
			assessment,
			reportValidity: query.reportValidity === 'true',
			branch
		};
	}

	return createHomeNavigationAction(branch);
}

export function responsesExist(session: Partial<AssessmentSession>): boolean {
	if (!session.responses) {
		return false;
	}
	/* eslint-disable-next-line */
	for (const _ of session.responses) {
		return true;
	}
	return false;
}

/**
 * Can be used to figure out a logical spot to land the user. For example, after a session resume.
 * Logical spot is defined as the first required, unanswered question or the next optional question
 * after an answered question.  This is the next question the user should take action on.
 */
export function getLogicalResumeQuestion(
	session: Partial<AssessmentSession>,
	assessment: Assessment
): { categoryId: string; questionId: string } {
	// In each category, filter by those that this session is interested in
	if (!assessment || !session || !hasSelectedOrRequiredCategory(session, assessment)) {
		return { categoryId: null, questionId: null };
	}

	// If we haven't answered any questions yet, short cut to the first question
	const returnFirstValidCombo: boolean = !session.responses || 0 === session.responses.length;

	// We have answered at least one question.  We'll try to drop the user off just after that answered question, but
	// we do need to loop back to see if the questionnaire has changed since the last time we loaded it (might have new
	// category, required questions, etc.)  If we find one of those, we land them there.  If we don't, then we
	// drop them off on the next required or optional question.
	const candidateQuestion: { categoryId: string; questionId: string } = {
		categoryId: null,
		questionId: null
	};
	const lastSeenQuestion: { categoryId: string; questionId: string } = {
		categoryId: null,
		questionId: null
	};

	// Loop through the selected or required categories
	for (const category of assessment.categories.filter(
		c => c.isRequired || session.categoriesSelected[c.id]
	)) {
		lastSeenQuestion.categoryId = category.id;

		// walk the questions
		for (const question of category.questions) {
			lastSeenQuestion.questionId = question.id;

			// If we don't have any answered questions, we just should land them on this first relevant question
			if (returnFirstValidCombo) {
				return lastSeenQuestion;
			}

			// If the question hasn't been fully answered
			if (!hasQuestionBeenFullyAnswered(category.id, question, session.responses)) {
				// If the question was partially answered, they should pick up where they left
				// off and go right there.  This would happen if they left in the middle of
				// a matrix question, for example.
				if (hasQuestionBeenPartiallyAnswered(category.id, question, session.responses)) {
					return { categoryId: category.id, questionId: question.id };
				}

				// If it's not partially answered, but it is required.
				if (question.isRequired) {
					// see if we have a prior candidate question, if so, they should start
					// back at that prior question before jumping all the way forward to
					// this one.
					if (candidateQuestion.categoryId && candidateQuestion.questionId) {
						// unless we have an answered (fully or partial) /required/ question beyond this question
						if (!precedesRequiredQuestion(category.id, question.id, assessment, session)) {
							return candidateQuestion;
						}
					}

					// We're okay to land the customer right on this required question
					// Either we didn't have a prior candidate or there is a required
					// question after this one that has been answered already.
					return { categoryId: category.id, questionId: question.id };
				}

				// It hasn't been answered at all and it's not required, and this is the first
				// non-required, non-answered question we have seen, let's make a note of it
				// we will land the user there if there is nothing later in the walk that
				// surfaces (partial matrix, filled question, etc)
				if (!candidateQuestion.categoryId || !candidateQuestion.questionId) {
					candidateQuestion.categoryId = category.id;
					candidateQuestion.questionId = question.id;
				}
			} else {
				// This question was fully answered, that means we need to keep looking.  If we had a candidate before,
				// forget about it we need to look further
				candidateQuestion.categoryId = null;
				candidateQuestion.questionId = null;
			}
		}
	}

	// Land them on the question we considered our last candidate, if we have one
	if (candidateQuestion.categoryId && candidateQuestion.questionId) {
		return candidateQuestion;
	}

	// If we don't have a candidate, that means the user answered the last question and bailed, so we should
	// just land them on the last question we have seen (if any)
	if (lastSeenQuestion.categoryId && lastSeenQuestion.questionId) {
		return lastSeenQuestion;
	}

	// If we can't figure it out, we meed tp just start them over.
	return { categoryId: null, questionId: null };
}

/**
 * Determine if this desired question is on or before the first unanswered question.
 * @param session The users session
 * @param assessment The assessment corresponding to the user's session
 * @param desiredCategoryId The id of the category that the user would like to go to.
 * @param desiredQuestionId The id of the question that the user would like to go to.
 */
export function questionIsNavigable(
	session: Partial<AssessmentSession>,
	assessment: Assessment,
	desiredCategoryId: string,
	desiredQuestionId: string
) {
	// Does the selected category even exist?
	const category = assessment.categories.find(c => c.id === desiredCategoryId);
	if (!category) {
		return false;
	}

	// does the question even exist in the category?
	if (category.questions.findIndex(c => c.id === desiredQuestionId) === -1) {
		return false;
	}

	// If the desired category is not required, then make sure it was one
	// that was selected.
	if (!category.isRequired && !session.categoriesSelected[desiredCategoryId]) {
		return false;
	}

	// Category and Question are totes legit, let's make sure there are no unanswered questions BEFORE this question though
	// If navigation is is place, no need to check if unanswered questions left
	if (!features.assessmentSideNav) {
		if (
			areThereUnansweredRequiredQuestionsBefore(
				session,
				assessment,
				desiredCategoryId,
				desiredQuestionId
			)
		) {
			return false;
		}
	}

	// Welcome to the party!
	return true;
}

function hasSelectedOrRequiredCategory(
	session: Partial<AssessmentSession>,
	assessment: Assessment
) {
	for (const cat in session.categoriesSelected) {
		const category = assessment.categories.find(c => c.id === cat);
		if (session.categoriesSelected[cat] && category) {
			return true;
		}
		if (category && category.isRequired) {
			return true;
		}
	}
	return false;
}

/**
 * Determine if this combination of session and assessment has the correct category selection in order to be in the questionnaire state
 * If there are no optional categories, the user is in a valid state.
 * If there are optional categories, and at least one is selected, the user is in a valid state.
 * @param session The session object associate with this assessment
 * @param assessment The model, the object containing the assessment's content
 */
function hasValidCategorySelection(session: Partial<AssessmentSession>, assessment: Assessment) {
	let hasOnlyRequiredCategories = true;
	for (const cat of assessment.categories) {
		if (!cat.isRequired) {
			hasOnlyRequiredCategories = false;
			break;
		}
	}

	// because some assessments have no optional categories to check
	if (hasOnlyRequiredCategories) {
		return true;
	}

	// some assessments have optional categories, at least one should be selected
	for (const cat in session.categoriesSelected) {
		const category = assessment.categories.find(c => c.id === cat);
		if (session.categoriesSelected[cat] && !category.isRequired) {
			return true;
		}
	}
	return false;
}

function createQuestionNavigationAction(
	session: Partial<AssessmentSession>,
	assessment: Assessment,
	categoryId: string,
	questionId: string,
	branch: string
): QuestionnaireNavigation {
	return {
		mode: 'questionnaire',
		session,
		categoryId,
		questionId,
		assessment,
		branch
	};
}

function createAssessmentNotFoundNavigationAction(branch: string | null): HomeNavigation {
	return createHomeNavigationAction(branch, loc_assessment_assessmentNotFound);
}

function createHomeNavigationAction(branch: string | null, reason?: string | null): HomeNavigation {
	return {
		mode: 'home',
		assessmentList: getCurrentAssessmentList(branch),
		sessionList: getAssessmentSessionList(branch),
		branch,
		reason
	};
}

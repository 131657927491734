import { features } from '../environment/features';
import { document } from '../globals';
import { getMoniker, monikerChangedEvent } from '../monikers/moniker';
import { handleMonikerChange } from '../monikers/moniker-changed-handler';
import { createMonikerPicker } from '../monikers/moniker-picker';
import { createMonikerPicker2, experimentalMonikerPicker } from '../monikers/moniker-picker2';
import { createQuickFilter, readQuickFilters } from './quick-filter';
import { initApiSearch } from './search';
import { createSearchField } from './search-field';
import { addResultsContainer } from './search-results';
import { apiSearchTermChangedEvent, getTerm } from './term';

export function apiBrowserPage() {
	if (!features.apiBrowser) {
		return;
	}
	handleMonikerChange();

	initApiSearch();
	const contentDiv = document.querySelector('.content');

	const searchFieldContainer = document.createElement('div');
	searchFieldContainer.classList.add('api-browser-search-field-container');
	contentDiv.appendChild(searchFieldContainer);

	const allApis = true;

	if (experimentalMonikerPicker()) {
		searchFieldContainer.appendChild(createMonikerPicker2(allApis));
	} else {
		searchFieldContainer.appendChild(createMonikerPicker(allApis));
	}

	searchFieldContainer.appendChild(createSearchField());

	readQuickFilters().then(result => {
		if (!result.any) {
			return;
		}
		const quickFilter = createQuickFilter(result);
		searchFieldContainer.appendChild(quickFilter);
	});

	const resultsContainer = document.createElement('div');
	resultsContainer.classList.add('api-browser-results-container');
	contentDiv.appendChild(resultsContainer);

	const renderHeading = true;
	addResultsContainer(resultsContainer, renderHeading);

	const updateStatus = () => {
		const method = getMoniker() === '' && getTerm() === '' ? 'remove' : 'add';
		document.documentElement.classList[method]('has-moniker-or-term');
	};

	updateStatus();

	window.addEventListener(monikerChangedEvent, updateStatus);
	window.addEventListener(apiSearchTermChangedEvent, updateStatus);
}

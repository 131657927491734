import { notifyContentUpdated } from '../affix';
import { dismissElement } from '../dismiss';

export class Toast {
	public contentElement: HTMLElement;
	private wrapper: HTMLElement;
	private container: HTMLElement;

	constructor(toastContent: Element) {
		// set attributes to toast parent
		this.container = document.createElement('div');
		this.container.setAttribute('role', 'alert');
		this.container.setAttribute('aria-live', 'assertive');
		this.container.setAttribute('data-dismissable', 'disappearing');
		this.container.classList.add('toast');

		// insert base code
		this.container.appendChild(toastContent);

		// insert into page
		this.wrapper = document.createElement('div');
		this.wrapper.insertAdjacentElement('afterbegin', this.container);

		this.contentElement = toastContent as HTMLElement;
	}

	public show() {
		if (document.querySelector('.toast')) {
			return;
		}
		document.body.insertAdjacentElement('afterbegin', this.container);
		this.container.classList.add('appearing');
		setTimeout(() => {
			notifyContentUpdated();
		}, 10);
		setTimeout(() => {
			dismissElement(this.container);
		}, 3500);
	}
}

import {
	loc_claimReward,
	loc_continue,
	loc_continueToNextModule,
	loc_exploreOtherModules,
	loc_goBackToFinish,
	loc_moduleComplete,
	loc_moduleIncomplete,
	loc_nextUnit,
	loc_reviewYourLearnHistory
} from '@msdocs/strings';
import { LearningPath, Module } from '../apis/learn';
import { Modal } from '../components/modal/modal';
import { features } from '../environment/features';
import { EventBus } from '../event-bus';
import { location, msDocs } from '../globals';
import { loadLearningPathInformation } from '../learn/learning-path';
import { displayModuleRatingControl } from '../learn/module-rating';
import { preloadImg } from '../preload-image';
import { initSharingLinks } from '../sharing';
import { buildModuleCompleteModal } from './completed-modal';
import type { UnitPageConfig, UnitProgressConfig } from './config';
import { UnitStateChangeEvent } from './events';
import { getGameStatus } from './game-status';
import { renderLearnNavigationAction } from './navigation-rendering';
import { LearnPageNavigationMap, LearnPageNavigationType } from './navigation-types';
import { getFirstIncompleteModuleUrl } from './utilities';
import { addLocaleToPath } from '../locale';

export function initLearnNavigation(
	config: UnitPageConfig,
	container: HTMLElement,
	bus: EventBus
): void {
	if (config.isChromeless || !features.gamification) {
		container.classList.add('is-hidden');
		return;
	}

	bus.subscribe(UnitStateChangeEvent, async e => {
		const actionType = getInPageLearnNavigationAction(e.config);

		if (!actionType) {
			return;
		}

		preloadAchievementImage(actionType, e.config);

		const actionInfo = await generateLearnNavigationActionInfo(actionType, e.config);
		const elt = renderLearnNavigationAction(actionInfo, actionType);

		// * blow away the contents of this element, and start afresh with newly created element
		container.innerHTML = '';
		container.appendChild(elt);

		if (container.classList.contains('is-hidden')) {
			container.classList.remove('is-hidden');
		}
	});
}

function preloadAchievementImage(actionType: string, config: UnitProgressConfig): void {
	if (actionType !== 'modal-complete') {
		return;
	}

	//* if the path is complete we'll load it's achievement icon for use in modal later
	if (config.singlePathComplete && config.module.parents[0].iconUrl) {
		preloadImg(config.module.parents[0].iconUrl).catch(() => {});
	}

	//* we want the module icon regardless, as it appears even in path-complete modals
	preloadImg(config.module.iconUrl).catch(() => {});
}

export function handleModuleCompleteModalClick(
	unitUpdateConfig: UnitProgressConfig,
	learningPath?: LearningPath
): void {
	const modal = buildModuleCompleteModal(unitUpdateConfig, learningPath);
	addEventListener('popstate', () => modal.hide());
	hideAchievementImageOnError(modal);
	// * get button specific elements after modal is set and run button logic
	displayModuleRatingControl(unitUpdateConfig, modal, learningPath);
	initSharingLinks(
		modal.contentElement,
		`${location.origin}/${msDocs.data.userLocale}${unitUpdateConfig.module.url}`,
		unitUpdateConfig.module.title
	);
	// do everything we need before this point
	// TODO: neeed to make modal aware of other modals and clear them prior to show()
	// if there is already an active modal wait for it to clear otherwise show modal. Fixes bug when going from quiz/task success to completed modal.
	if (document.querySelector('.modal.is-active')) {
		setTimeout(() => {
			modal.show();
		});
	} else {
		modal.show();
	}
	// fires game status on completion modal
	getGameStatus();
}

function hideAchievementImageOnError(modal: Modal) {
	const achievementImage = modal.contentElement.querySelector(
		'#achievement-src'
	) as HTMLImageElement;
	achievementImage.onerror = () => {
		achievementImage.src = 'https://docs.microsoft.com/en-us/media/learn/module.svg'; // ? fallback module svg
	};
}
export function getFirstIncompleteUnitUrl(module: Module): string {
	return module.units.filter(x => x.status !== 'completed')[0].url;
}

export function getInPageLearnNavigationAction(
	unitProgressConfig: UnitProgressConfig
): LearnPageNavigationType | null {
	const {
		moduleComplete,
		isFinalUnit,
		moduleCompletedBeforeSignIn,
		moduleCompletedThisPageView,
		hasIncompleteQuiz,
		hasIncompleteTask,
		oneParentPath,
		zeroParentPaths,
		multipleParentPaths,
		singlePathComplete
	} = unitProgressConfig;
	const modulePreviouslyCompleted =
		moduleComplete && !moduleCompletedBeforeSignIn && !moduleCompletedThisPageView;

	if (moduleCompletedBeforeSignIn || moduleCompletedThisPageView) {
		return 'modal-complete';
	}

	if (hasIncompleteQuiz) {
		return 'modal-quiz';
	}

	if (hasIncompleteTask) {
		return 'modal-task';
	}

	if (
		(!moduleComplete && !isFinalUnit && !hasIncompleteTask && !hasIncompleteQuiz) ||
		(modulePreviouslyCompleted && !isFinalUnit)
	) {
		return 'continue';
	}

	if (!moduleComplete && isFinalUnit) {
		return 'back-to-finish';
	}

	if (oneParentPath && modulePreviouslyCompleted && isFinalUnit) {
		if (!singlePathComplete) {
			return 'next-step-module';
		}

		// *else if (singlePathComplete)
		return 'next-step-path-complete';
	}

	if (zeroParentPaths && modulePreviouslyCompleted && isFinalUnit) {
		return 'next-step-explore-modules';
	}

	if (multipleParentPaths && modulePreviouslyCompleted && isFinalUnit) {
		return 'next-step-explore-paths';
	}

	return null;
}

/**
 *
 * @param type A string descriptor of action that should be taken by the navigation component at the bottom of the page
 * @param config The configuration object that is created each time we get progress.
 */
export async function generateLearnNavigationActionInfo<T extends LearnPageNavigationType>(
	type: T,
	config: UnitProgressConfig
): Promise<LearnPageNavigationMap[T]>;
export async function generateLearnNavigationActionInfo(
	type: keyof LearnPageNavigationMap,
	config: UnitProgressConfig
): Promise<LearnPageNavigationMap[keyof LearnPageNavigationMap]> {
	// https://github.com/microsoft/TypeScript/issues/31904
	let learningPath: LearningPath;

	// * the following cases require extra information about the learning path not available from the config object
	if (
		(type === 'modal-complete' || type === 'next-step-module' || 'next-step-path-complete') &&
		config.oneParentPath
	) {
		learningPath = await loadLearningPathInformation(config.module.parents[0].uid);
	}

	// * return the data that data that pertains to the current navigation action
	switch (type) {
		case 'continue':
			return {
				elementType: 'a',
				biName: 'continue',
				doconClass: 'docon-chevron-right-light',
				href: config.nextUnitUrl,
				sectionTitle: `${loc_nextUnit}: ${config.nextUnit.title}`,
				text: loc_continue,
				target:
					config.interactiveType &&
					config.interactiveType.name === 'lab-on-demand' &&
					config.nextUnit.interactive !== 'lab-on-demand'
						? '_top'
						: ''
			};
		case 'back-to-finish':
			return {
				elementType: 'a',
				biName: 'continue',
				doconClass: 'docon-chevron-right-light',
				href: config.firstIncompleteUnit
					? addLocaleToPath(config.firstIncompleteUnit.url, msDocs.data.userLocale)
					: `/${msDocs.data.userLocale}/learn/browse`,
				sectionTitle: `${loc_moduleIncomplete}:`,
				text: loc_goBackToFinish
			};
		case 'next-step-module':
			return {
				elementType: 'a',
				biName: 'continue',
				doconClass: 'docon-chevron-right-light',
				href: learningPath
					? getFirstIncompleteModuleUrl(learningPath, config.module)
					: `/${msDocs.data.userLocale}/learn/browse`,
				sectionTitle: `${loc_moduleComplete}:`,
				text: loc_continueToNextModule
			};
		case 'next-step-path-complete':
			return {
				elementType: 'a',
				biName: 'continue',
				doconClass: 'docon-chevron-right-light',
				href: learningPath
					? addLocaleToPath(learningPath.url, msDocs.data.userLocale)
					: `/${msDocs.data.userLocale}/learn/browse`, // available after progress update
				sectionTitle: `${loc_moduleComplete}:`,
				text: loc_reviewYourLearnHistory
			};
		case 'modal-complete':
			return {
				elementType: 'button',
				biName: 'unlock-achievement',
				onclick: () => {
					handleModuleCompleteModalClick(config, learningPath);
				},
				sectionTitle: `${loc_moduleComplete}:`,
				text: loc_claimReward
			};
		case 'next-step-explore-modules':
			return {
				elementType: 'a',
				biName: 'continue',
				doconClass: 'docon-chevron-right-light',
				href: `/${msDocs.data.userLocale}/learn/browse`,
				sectionTitle: `${loc_moduleComplete}:`,
				text: loc_exploreOtherModules
			};
		case 'next-step-explore-paths':
			return {
				elementType: 'ul',
				items: config.module.parents,
				sectionTitle: loc_exploreOtherModules
			};
		case 'modal-quiz':
			return {
				elementType: 'none'
			};
		case 'modal-task':
			return {
				elementType: 'none'
			};
		default:
			throw new Error('Unexpected page navigation type: ' + type);
	}
}

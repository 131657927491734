import { process401Response } from '../auth/service';
import { apis } from '../environment/apis';
import { createRequest, fetchWithTimeout } from '../fetch';

export const blankGameStatusResponse: GameStatus = {
	achievements: [] as any[],
	totalPoints: 0,
	level: {
		levelNumber: 0,
		pointsHigh: 0,
		pointsLow: 0
	},
	nextLevelNumber: 0,
	pointsToNextLevel: 999999,
	currentLevelPointsEarned: 0
};

export interface LevelingUpRule {
	levelNumber: number;
	pointsLow: number;
	pointsHigh: number;
}

export type AwardReason =
	| 'AwardPointsWhileCompletingUnit'
	| 'AwardPointsWhileCompletingModule'
	| 'AwardPointsWhileCompletingPath'
	| 'AwardBadge'
	| 'AwardTrophy'
	| 'AllQuizCorrectInFirstTrialBonus';

export interface Award {
	awardUid: string;
	awardType: string; // "Badge"
	reason: AwardReason;
	awardedDateUTC: string;
	sourceUid: string;
	sourceType: string; // "Module"
}

export interface GameStatus {
	totalPoints: number;
	level: LevelingUpRule;
	nextLevelNumber: number;
	currentLevelPointsEarned: number;
	pointsToNextLevel: number;
	achievements: Award[];
}

export interface UserLevel {
	userUid: string;
	level: number;
	dateLevelAchievedUTC: string;
	acknowledgedUTC: string | null;
}

export const gameApi = {
	getStatus(userId: string): Promise<GameStatus> {
		const url = `${apis.gameStatus}/${userId}`;
		const init: RequestInit = { mode: 'cors' };
		return fetchWithTimeout(createRequest(url, init, false))
			.then(process401Response)
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject();
			});
	}
};

import { loc_body, loc_headers, loc_responseCode } from '@msdocs/strings';
import { copyCodeBlockToClipboard, createCopyButtonHtml, handleCopyCode } from '../code/header';
import { syntaxHighlight } from '../code/syntax-highlighter';
import { EventBus } from '../event-bus';
import { RestRenderResponse } from './events';
import { RestTryItResponse } from './types';

let displayContainer: HTMLElement;

export function initResponse(container: HTMLElement, bus: EventBus) {
	displayContainer = container;

	const handleRenderResponse = (event: RestRenderResponse) => {
		renderResponse(displayContainer, event.response);
	};

	bus.subscribe(RestRenderResponse, handleRenderResponse);
}

export function renderResponse(container: HTMLElement, response: RestTryItResponse) {
	const responseContainer = container.querySelector('.response-section');
	if (responseContainer) {
		container.removeChild(responseContainer);
	}

	const blockDiv = document.createElement('div');
	blockDiv.classList.add('response-section');

	renderResponseCode(blockDiv, response.statusCode);
	renderResponseComponent(blockDiv, response.header, true);
	if (response.body !== null) {
		renderResponseComponent(blockDiv, response.body);
	}

	container.appendChild(blockDiv);
}

function renderResponseCode(container: HTMLElement, code: string) {
	const heading = document.createElement('h2');
	heading.textContent = loc_responseCode + ': ';

	const codeLabel = document.createElement('span');
	codeLabel.classList.add('status-code');

	if (code.charAt(0) === '2') {
		codeLabel.classList.add('status-success');
	} else if (code.charAt(0) === '3') {
		codeLabel.classList.add('status-warning');
	} else {
		codeLabel.classList.add('status-fail');
	}
	codeLabel.textContent = code;
	heading.appendChild(codeLabel);

	container.appendChild(heading);
}

function renderResponseComponent(container: HTMLElement, code: string, isHeader: boolean = false) {
	const blockDiv = document.createElement('div');

	const heading = document.createElement('h3');
	if (isHeader) {
		heading.textContent = loc_headers;
	} else {
		heading.textContent = loc_body;
	}
	blockDiv.appendChild(heading);

	const codeHeader = document.createElement('div');
	codeHeader.classList.add('codeHeader');

	const codeHeading = document.createElement('span');
	codeHeading.classList.add('language');
	if (isHeader) {
		codeHeading.textContent = 'HTTP';
	} else {
		codeHeading.textContent = 'JSON';
	}
	codeHeader.appendChild(codeHeading);

	codeHeader.insertAdjacentHTML('beforeend', createCopyButtonHtml());
	const copyButton = codeHeader.lastElementChild as HTMLButtonElement;

	blockDiv.appendChild(codeHeader);

	const codeBlock = document.createElement('pre');
	if (isHeader) {
		codeBlock.classList.add('response-header');
	} else {
		codeBlock.classList.add('response-body');
	}
	copyButton.onclick = event => {
		if (!handleCopyCode(copyButton)) {
			return;
		}
		copyCodeBlockToClipboard(codeBlock, 'json');
		event.preventDefault();
	};

	codeBlock.classList.add('small');
	codeBlock.textContent = code;
	blockDiv.appendChild(codeBlock);

	container.appendChild(blockDiv);

	syntaxHighlight([
		{ code: codeBlock.textContent, language: isHeader ? 'http' : 'json', highlightLines: '' }
	]).then(results => {
		codeBlock.innerHTML = results[0].html;
	});
}

import { loc_cancel, loc_close, loc_ok } from '@msdocs/strings';
import { Modal } from '../../components/modal/modal';
import { configureValidation } from '../../forms/form';
import { document } from '../../globals';
import { html, render, TemplateResult } from '../../lit-html';

/**
 * The result returned from the modal after the modal closes.
 */
export interface PromptResult<T> {
	submitted: boolean;
	form: HTMLFormElement;
	data: T;
}

export interface SuccessResult<T> {
	hasError: false;
	data: T;
}

interface ErrorResult {
	hasError: true;
}

export type SubmitResult<T> = SuccessResult<T> | ErrorResult;

/**
 * Handles behaviors after a form submit event is raised.
 * Can be used for server async form validation and using setCustomValidity to update the form or server api calls.
 */
export type SubmitDelegate<T> = (form: HTMLFormElement) => Promise<SubmitResult<T>>;

/**
 * A boilerplate function to display a form in a modal.
 * @param content The form content to be displayed.
 * @param submit A function that runs after the form is submitted for async validation or for api service calls.
 */
export async function prompt<T>(
	content: TemplateResult | Element,
	submit?: SubmitDelegate<T>
): Promise<PromptResult<T>> {
	const form = document.createElement('form');
	form.action = 'javascript:';

	if (content instanceof TemplateResult) {
		render(content, form);
	} else {
		form.appendChild(content);
	}

	validateFormContent(form);

	const modalContent = document.createElement('div');
	modalContent.classList.add('modal-content');
	modalContent.appendChild(form);

	const modal = new Modal(modalContent);
	let submitted = false;
	let result: SubmitResult<T> = { hasError: false, data: null };
	form.addEventListener('submit', async event => {
		event.preventDefault();

		result = submit ? await submit(form) : { hasError: false, data: null };
		if (result.hasError === false) {
			submitted = true;
			modal.hide();
		}
	});

	configureValidation(form);
	await modal.show();
	return {
		submitted,
		form,
		data: !result.hasError ? (result as SuccessResult<T>).data : null
	};
}

function validateFormContent(form: HTMLFormElement) {
	if (form.querySelector('form')) {
		throw new Error('The content argument should not contain a form');
	}
}

export function simplePromptTemplate(title: string, message: string, primary = loc_ok) {
	return html` <section
		class="modal-card is-shadowless-mobile has-padding-medium has-padding-large-tablet"
	>
		<h2 class="title is-3 has-margin-bottom-medium has-margin-right-small" id="modal-heading">
			${title}
		</h2>
		<button
			class="modal-close modal-card-close-button is-large is-sticky"
			aria-label="${loc_close}"
		></button>
		<div class="has-flex-grow-none has-padding-none">
			<p ?hidden="${!message}" id="modal-text" class="is-size-small has-padding-bottom-large">
				${message}
			</p>
			<div class="buttons has-margin-bottom-none">
				<button class="button is-primary is-small" type="submit" autofocus>${primary}</button>
				<button type="button" class="modal-close button is-small">${loc_cancel}</button>
			</div>
		</div>
	</section>`;
}

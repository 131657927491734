import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { user, UserChangedEvent } from '../auth/user';
import { features } from '../environment/features';
import { location } from '../globals';

/* eslint-disable */

// dummy app insights for cases when real app insights is not enabled
const shim: any = {
	trackEvent: () => {},
	trackException: () => {}
};

export let appInsights: ApplicationInsights = shim;

export function enableAppInsights() {
	// pilot phase: learn and profile pages only
	if (!features.applicationInsights) {
		return;
	}

	// map hostname to instrumentation key.
	const instrumentationKey =
		({
			'docs.microsoft.com': '9367385a-f935-4fd9-91da-c734500b5d55', // freelearning
			'docs.azure.cn': '9367385a-f935-4fd9-91da-c734500b5d55', // freelearning
			'developer.microsoft.com': '9367385a-f935-4fd9-91da-c734500b5d55', // freelearning
			'review.docs.microsoft.com': '9367385a-f935-4fd9-91da-c734500b5d55', // freelearning
			'ppe.docs.microsoft.com': '89ee6297-0550-4fd8-8d37-c2aa8d2ec81c' // freelearning-int
		} as { [domain: string]: string })[location.hostname] || 'a98c2de9-5013-4dbd-9c9d-6d341ef8d74a'; // docs-ui-dev

	appInsights = new ApplicationInsights({
		config: {
			instrumentationKey,
			disableFetchTracking: false
		}
	} as any);

	appInsights.loadAppInsights();

	const handleUserChanged = () => {
		if (user.isAuthenticated) {
			appInsights.setAuthenticatedUserContext(user.userId);
		} else {
			appInsights.clearAuthenticatedUserContext();
		}
	};
	user.subscribe(UserChangedEvent, handleUserChanged);
	handleUserChanged();
	// appInsights.trackPageView();
}

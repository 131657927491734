import { html, ifDefined } from '../lit-html';

export interface TextInputTemplateData {
	name: string;
	label: string;
	placeholder: string;
	isRequired?: boolean;
	size?: 'small' | 'default' | 'large';
	customLabelClasses?: string;
	value?: string;
	width?: 'full' | 'narrow';
	maxLength?: number;
	enableAutoComplete?: boolean;
}

export const textInputTemplate = ({
	name,
	label,
	placeholder,
	size = 'default',
	width = 'full',
	value = '',
	customLabelClasses = '',
	isRequired = false,
	maxLength,
	enableAutoComplete = false
}: TextInputTemplateData) => {
	const sizeClass = size !== 'default' ? `is-${size}` : '';
	const widthClass = width !== 'full' ? `is-${width}` : '';
	const requiredIndicator = isRequired
		? html`<span class="required-indicator" aria-hidden="true"></span>`
		: html``;
	return html`
		<div class="control">
			<label class="label ${customLabelClasses}" for="${name}">
				${label} ${requiredIndicator}
			</label>
			<input
				id="${name}"
				name="${name}"
				class="input ${sizeClass} ${widthClass}"
				type="text"
				placeholder="${placeholder}"
				.value=${value}
				autocomplete="${enableAutoComplete ? 'on' : 'off'}"
				?required=${isRequired}
				maxlength=${ifDefined(maxLength)}
			/>
		</div>
	`;
};

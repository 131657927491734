import { getBranch } from '../../../apis/learn';
import { authStatusDetermined } from '../../../auth';
import { defaultFacetSearchFilterOptions } from '../filter-options';
import { FacetSearchResponse } from '../model';
import { SearchVMConfig, UISearchArgs } from '../view-model';
import { apis } from '../../../environment/apis';
import { createRequest, fetchWithTimeout } from '../../../fetch';
import { msDocs } from '../../../globals';
import { html } from '../../../lit-html';
import { InputArgs, toQueryString } from '../../../query-string';
import { toLocaleDate } from '../../../text-formatting';
import { createTagHTML } from '../../../tags';
import { ProductId } from '../../../name-maps/product';
import { DevlangsTypeId } from '../../../name-maps/devlangs';
import { taxonomyNames } from '../../../name-maps/taxonomy';

export function createSampleSearchConfig(): SearchVMConfig<SampleBrowseResult> {
	return {
		layout: 'grid',
		fetch: fetchSampleResults,
		resultTemplate: createSampleFacetSearchCard
	};
}

const sampleSearchFacetDefaults = {
	orderBy: 'last_modified desc',
	top: 30
};

interface SampleBrowseResult {
	uid: string;
	title: string;
	summary: string;
	last_modified: string; // i.e. 11/09/2018 00:00:00 +00:00,
	locale: string;
	languages: DevlangsTypeId[];
	products: ProductId[];
	url: string;
}

interface SampleArgs {
	branch: string;
	environment: string;
	locale: string;
	terms: string;
	facet: string[];
	$filter: string;
	$orderBy: string;
	$skip: string;
	$top: string;
}

/**
 * Function that renders the cards for the Sample browse page, only applies to browseType: 'sample'.
 * @param items The resultant items from the Sample browse service API call.
 */
function createSampleFacetSearchCard(item: SampleBrowseResult) {
	const displayDate = toLocaleDate(new Date(item.last_modified), {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit'
	});
	return html` <article data-bi-name="card" class="card">
		<div class="card-content">
			<a class="card-content-title" href="${item.url}">
				<h3>${item.title.replace(/</g, '&lt;').replace(/>/g, '&gt;')}</h3>
			</a>
			<ul class="card-content-metadata">
				<li><time datetime="${item.last_modified}">${displayDate}</time></li>
			</ul>
			<p class="card-content-description">${item.summary}</p>
			<ul class="tags">
				${createTagHTML(createSampleTagStrings(item))}
			</ul>
		</div>
	</article>`;
}

function createSampleTagStrings(item: SampleBrowseResult): string[] {
	const tags = [];
	const product = taxonomyNames.products[item.products[0]];
	if (product) {
		tags.push(product);
	}
	const language = taxonomyNames.languages[item.languages[0]];
	if (language) {
		tags.push(language);
	}
	return tags;
}

async function fetchSampleResults(
	args: UISearchArgs
): Promise<FacetSearchResponse<SampleBrowseResult>> {
	const [results] = await Promise.all([getSampleResults(args), authStatusDetermined]);
	return results;
}

function uiArgsToSample(args: UISearchArgs): SampleArgs {
	return {
		branch: getBranch(),
		environment: null, // todo: figure out
		locale: msDocs.data.userLocale,
		terms: args.terms,
		facet: ['languages', 'products'],
		$filter: defaultFacetSearchFilterOptions(args.selectedFacets),
		// always null if terms are present
		$orderBy: args.terms && args.terms.length > 0 ? null : sampleSearchFacetDefaults.orderBy,
		$skip: args.skip ? args.skip.toString() : null, // todo
		$top: sampleSearchFacetDefaults.top.toString() // todo
	};
}

function getSampleResults(args: UISearchArgs): Promise<FacetSearchResponse<SampleBrowseResult>> {
	const query = (uiArgsToSample(args) as unknown) as InputArgs;
	const url = `${apis.browse.sample}?${toQueryString(query, true)}`;

	const init = { method: 'GET' };

	return fetchWithTimeout(createRequest(url, init)).then(response => {
		if (response.ok) {
			return response.json();
		}
		/* eslint-disable-next-line */
		throw { error: response.json() };
	});
}

import { features } from '../../environment/features';
import { beforeUnload } from '../../router/utils';
import { getRemainingSandboxTime } from '../sandbox';
import * as controllers from './controllers';
import { instrumentSandboxStateTransition } from './instrumentation';
import { isInvitationReady } from './prepare-invite';
import { getInitialState, SandboxPromptState } from './state';
import { clearAnimations, renderSandboxLodIsolatedPrompt } from './ui';
import { RiskApiError } from '../../risk-evaluation/risk-errors';

export const controllerChangedEvent = 'sandbox-controller-changed';

/**
 * A controller name.
 */
type ControllerName = KeysOfType<typeof controllers, (state: SandboxPromptState) => Promise<void>>;

/**
 * Render the sandbox prompt into the specified container.
 * This is the entry point into the sandbox prompt logic.
 */
export async function renderSandboxPrompt(container: Element) {
	container.setAttribute('data-bi-name', 'sandbox-prompt');

	const state = getInitialState(container);

	if (!features.sandbox) {
		renderSandboxLodIsolatedPrompt(state.container);
		return Promise.resolve();
	}

	// terminate loop when user navigates to another unit.
	let unloaded = false;
	beforeUnload(() => {
		unloaded = true;
		clearAnimations();
	});

	// state machine.
	while (!unloaded) {
		// We're about to change state. Reset "tickers" like the progress bar or the sandbox activated timer.
		clearAnimations();

		const name = getController(state);
		const controller = controllers[name];
		const changeEvent = new CustomEvent(controllerChangedEvent, { bubbles: false, detail: name });

		// fire event on next tick of micro task queue, ensuring controller has had opportunity to run.
		Promise.resolve().then(() => {
			container.dispatchEvent(changeEvent);
			instrumentSandboxStateTransition(name);
		});

		try {
			await controller(state);
			state.exception = null;
		} catch (exception) {
			// Add the exception to the state, handling cases where
			// Promise.reject() or Promise.reject(null) was used, making
			// the exception null or undefined.
			state.exception = exception || new Error(`Exception occurred in ${name} controller.`);
		}
	}
}

/**
 * Gets the appropriate controller for the current state.
 */
export function getController(state: SandboxPromptState): ControllerName {
	if (state.exception) {
		return getControllerForException(state.exception);
	}

	if (!state.module) {
		return 'loadingCurrentModule';
	}

	if (state.disabled === null) {
		return 'loadingSandboxAvailability';
	}

	if (state.disabled) {
		return 'sandboxDisabled';
	}

	if (!state.auth.authStatusDetermined) {
		return 'awaitingAuthStatus';
	}

	if (!state.auth.user.isAuthenticated) {
		return 'signInPrompt';
	}

	if (!state.apiResult) {
		return 'loadingSandbox';
	}

	if (state.apiResult.hasError === true) {
		return getControllerFor400Error(state.apiResult.error.errorCode);
	}

	if (!state.apiResult.sandbox.resourceGroupId) {
		return getControllerForMissingResourceGroup(state);
	}

	if (getRemainingSandboxTime(state.apiResult.sandbox) <= 0 || state.userDidDeactivate) {
		return 'deletingSandbox';
	}

	if (state.apiResult.sandbox.moduleId !== state.module.uid) {
		return getControllerForSandboxModuleMismatch(state);
	}

	return 'sandboxActivated';
}

/**
 * Gets the controller for exception states.
 */
function getControllerForException(exception: any) {
	// The sandbox API code will reject/throw when an unexpected response status code
	// is encountered. 401 Unauthorized responses mean the user signed out in the
	// middle of things or their login expired. In these scenarios, prompt them to sign in...
	if (exception instanceof Response && exception.status === 401) {
		if ((exception as any).requestVerb === 'GET') {
			return 'signInPrompt';
		} else {
			return 'loginExpiredPrompt';
		}
	}
	// Unexpected error. We don't know what happened. Show the retry prompt.
	return 'retryPrompt';
}

/**
 * Gets the controller for states where the API has returned
 * a 400 status with errorCode information.
 */
function getControllerFor400Error(errorCode: RiskApiError['errorCode']): ControllerName {
	// We have custom UI for some of the error codes. Mostly to provide localized
	// instructions/details to the user for common error codes. All other cases
	// fall through to the retry prompt.
	switch (errorCode) {
		case 'AppealDenied':
			return 'errorAppealDenied';
		case 'AppealPending':
			return 'errorAppealPending';
		case 'MissingEmail':
			return 'errorMissingEmail';
		case 'RestrictedCloud':
			return 'errorRestrictedCloud';
		case 'QuotaExceeded':
			return 'errorQuotaExceeded';
		case 'Blocked':
			return 'sandboxTriggerCaptcha';
		case 'OperationFailed':
		case 'Rejected':
			return 'sandboxAppeal';
		case 'SMSVerification':
			return features.riskIntegration ? 'smsChallenge' : 'retryPrompt';
		default:
			return 'retryPrompt';
	}
}

/**
 * Gets the controller for states where we've received a response from the
 * sandbox API that doesn't include a resource group id.
 */
function getControllerForMissingResourceGroup(state: SandboxPromptState) {
	// First, did we execute a GET or a POST?
	// A null resourceGroupId means different things depending on the request verb.
	if (state.apiResult.requestVerb === 'GET') {
		// We did a GET. A null resourceGroupId means there isn't an existing sandbox.
		// If we've already prompted the user to activate we can go ahead and provision the sandbox.
		// Otherwise show the activate prompt.
		if (state.userDidActivate) {
			return 'provisioningSandbox';
		}
		return 'activatePrompt';
	}

	// We did a POST. Null resourceGroupId means the API thinks the user has not been invited to the tenant.
	if (state.referredByTenantInvite && state.provisionAttempts < 6) {
		// If we were referred to the unit page via the tenant invite page, AAD latency could be causing
		// the API to think the user is not yet in the tenant... retry.
		return 'provisioningSandbox';
	}
	// We know we need to invite the user to the tenant... is the invitation ticket
	// old enough to ensure AAD latency won't be a problem when they arrive at the tenant invitation page?
	if (state.apiResult.hasError === false && !isInvitationReady(state.apiResult.sandbox)) {
		return 'preparingInvite';
	}
	// Show the tenant invite.
	return 'tenantInvite';
}

/**
 * Gets the controller for states where a sandbox is already active for a different module.
 */
function getControllerForSandboxModuleMismatch(state: SandboxPromptState) {
	if (!state.userDidActivate) {
		// We know right away there's a mismatch between the sandbox module and the
		// current module but we don't show any indication of this until the user
		// chooses to activate a sandbox.
		return 'activatePrompt';
	}
	if (!state.sandboxModuleInfo) {
		// Subsequent states need hierarchy and progress data about the "other"
		// module where the sandbox is already active.
		return 'loadingSandboxModule';
	}
	if (state.sandboxModuleInfo.isComplete || state.userDidRelease) {
		// We can blow away the existing sandbox if the "other" module is complete
		// or if the user has already agreed to destroy it.
		return 'deletingSandbox';
	}
	// Prompt the user to destroy the existing sandbox.
	return 'releasePrompt';
}

import { currentTheme, documentClasses as themeClasses, themeInfo } from '../theme-selection';
import { NavigationHandler } from './router';

/**
 * NavigationHandler implementation which fetches the Docs url and replaces only
 * the meta tags and content of the page, preserving the chrome and interactive component.
 * This was originally created for module unit pages to ensure users could navigate within
 * a module (between units) without losing their state in the cloud shell.
 */
export const documentNavigationHandler: NavigationHandler = async url => {
	try {
		const result = await loadDocument(url);
		replaceDocumentClasses(result.document.documentElement, document.documentElement);
		replaceMetaTags(result.document.head, document.head);
		replaceContent(result.document, document);
		return {
			url: new URL(result.url),
			title: result.document.title
		};
	} catch (err) {
		location.href = url.href;
		throw err;
	}
};

/**
 * Load the Docs page specified in the url, return the
 * resulting url (in case redirection occurred) and the parsed
 * html document.
 */
async function loadDocument(url: URL) {
	const init: RequestInit = {
		method: 'GET',
		credentials: 'include', // review needs cookies
		redirect: 'follow', // hosting layer may redirect
		headers: {
			Accept: 'text/html'
		}
	};
	const response = await fetch(url.href, init);
	if (!response.ok) {
		throw new Error(`Error loading ${url}. ${response.status}: ${response.statusText}`);
	}
	const html = await response.text();
	const parser = new DOMParser();
	return {
		url: response.url || url.href, // fallback to request url for Internet Explorer
		document: parser.parseFromString(html, 'text/html')
	};
}

/**
 * Replace the current document element's classes with those from the page
 * we're navigating to. Preserve the current page's theme class, avoiding a
 * switch to light theme while navigating.
 */
function replaceDocumentClasses(source: HTMLElement, target: HTMLElement) {
	source.classList.remove(...themeClasses);
	target.className = `${source.className} ${themeInfo[currentTheme].documentClass}`;
}

/**
 * Replace the current document's meta tags with the meta tags from the page
 * we're navigating to. Meta tags drive a lot of the Docs javascript logic,
 * it's important the new page's meta tags are in the DOM before finishing the
 * navigation.
 */
function replaceMetaTags(source: HTMLHeadElement, target: HTMLHeadElement) {
	const selector = 'meta';
	Array.from(target.querySelectorAll(selector)).forEach(m => m.remove());
	Array.from(source.querySelectorAll(selector)).forEach(m => {
		m.remove();
		target.appendChild(m);
	});
}

/**
 * Replaces the content of the current page with the content of the new page.
 */
function replaceContent(source: Document, target: Document) {
	// The primary holder is the main content area. Replace the element entirely.
	const primarySelector = '.primary-holder';
	const sourcePrimary = source.querySelector(primarySelector);
	const targetPrimary = target.querySelector(primarySelector);
	targetPrimary.insertAdjacentElement('afterend', sourcePrimary);
	targetPrimary.remove();

	// The interactive container is an empty div that will contain the interactive
	// content like the cloud shell or .net repl. This element must remain attached
	// to the DOM, it's content unmodified to ensure the cloud shell session in not
	// interrupted. All we're doing here is updating the element's classes to ensure
	// it has the wide layout or 50/50 layout.
	const secondaryId = 'interactive-container';
	const sourceSecondary = source.getElementById(secondaryId);
	const targetSecondary = target.getElementById(secondaryId);
	targetSecondary.className = sourceSecondary.className;
}

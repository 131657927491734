import { isMatchMediaSupported, printQuery } from '../src/match-media';
import { user, UserChangedEvent } from './auth/user';
import { initialCookieConsentDisposition } from './cookie-consent';
import { osHighContrastEnabled } from './detect-high-contrast';
import { features } from './environment/features';
import { eventBus } from './event-bus';
import { document, msDocs, window } from './globals';
import { loadLibrary } from './load-library';
import { getMeta } from './meta';
import { RouterAfterNavigateEvent } from './router/events';

/**
 * content metadata
 * https://microsoft.sharepoint.com/sites/mscomdata/Pages/awa-event-metadata-dictionary.aspx
 */
export const contentTags = {
	/**
	 * string descriptor for identification of a piece of media; guid
	 */
	id: 'id',
	/**
	 * string descriptor for identification of a piece of media; friendly name
	 */
	name: 'name',
	/**
	 * string descriptor for the categorization of media
	 */
	type: 'type',
	/**
	 * string descriptor for identification of a the scenario associated with a page interaction
	 */
	scenario: 'scn',
	/**
	 * string descriptor for identification of a specific step in a scenario associated with a page interaction
	 */
	scenarioStep: 'scnstp',
	/**
	 * integer descriptor for identification of a specific step in a scenario associated with a page interaction
	 */
	scenarioStepNumber: 'subnm'
	// todo: others...
};

/**
 * content metadata
 * https://microsoft.sharepoint.com/sites/mscomdata/Pages/awa-event-metadata-dictionary.aspx
 */
export const contentAttrs = {
	/**
	 * string descriptor for identification of a piece of media; guid
	 */
	id: 'data-bi-id',
	/**
	 * string descriptor for identification of a piece of media; friendly name
	 */
	name: 'data-bi-name',
	/**
	 * string descriptor for the categorization of media
	 */
	type: 'data-bi-type',
	/**
	 * string descriptor for identification of a the scenario associated with a page interaction
	 */
	scenario: 'data-bi-scn',
	/**
	 * string descriptor for identification of a specific step in a scenario associated with a page interaction
	 */
	scenarioStep: 'data-bi-scnstp',
	/**
	 * integer descriptor for identification of a specific step in a scenario associated with a page interaction
	 */
	scenarioStepNumber: 'data-bi-subnm',
	/**
	 * Floaing point descriptor for the user's satisfaction level; valid values are between zero and one (0 <= satisfaction <= 1)
	 */
	satisfaction: 'data-bi-sat'
	// todo: others...
};

/**
 * Creates the "name" content tagging attributes for the given component name.
 * @param name The name of the component
 */
export function nm(name: string) {
	return `${contentAttrs.name}="${name}"`;
}

const jsllUrl = 'https://az725175.vo.msecnd.net/scripts/jsll-4.js';

/**
 * A mapping of page <meta> tags to a mix of custom and standard AWA page metadata: https://microsoft.sharepoint.com/sites/mscomdata/Pages/awa-page-metadata-dictionary.aspx
 */
const tagMapping: { [name: string]: string } = {
	audience: 'aud',
	author: 'author',
	manager: 'manager',
	'ms.assetid': 'asst',
	'ms.author': 'pgauth',
	'ms.contentsource': 'pgpubl',
	'ms.custom': 'custom',
	'ms.date': 'date',
	depot_name: 'depotname',
	'ms.devlang': 'pgdevlng',
	gitcommit: 'gitcommit',
	original_content_git_url: 'giturl',
	updated_at: 'publishtime',
	'ms.lasthandoff': 'lasthandoff',
	'ms.locfileid': 'locfileid',
	'ms.prod': 'product',
	'ms.reviewer': 'reviewer',
	'ms.service': 'pgsrvcs',
	'ms.suite': 'suite',
	'ms.technology': 'technology',
	'ms.tgt_pltfrm': 'pgtrgtplf',
	'ms.topic': 'pgtop',
	'ms.workload': 'workload',
	'ms.search.region': 'searchregion',
	'ms.prod_service': 'prod_service',
	'ms.component': 'component',
	experimental: 'experimental',
	experiment_id: 'experiment_id',
	'ms.assigned_experiments': 'assigned_experiments',
	'ms.translationtype': 'translationtype',
	document_version_independent_id: 'document_version_independent_id',
	'ms.collection': 'collection'
};

let notifyJsllReady: (awa: Awa) => void;

/**
 * Augment standard JSLL event tracking with the suite of events specific to docs.microsoft.com
 */
export function track(): void | Promise<void> {
	if (!features.jsll) {
		return;
	}

	return Promise.all([loadLibrary<Awa>(jsllUrl, 'awa'), initialCookieConsentDisposition]).then(
		([awa, hasCookieConsent]) => {
			trackUser(awa);

			configureJsll(awa, hasCookieConsent);

			trackSelectElementChange(awa);
			trackPageFocus(awa);
			trackPageVisibility(awa);
			trackPrint(awa);
			trackSecondaryContentScroll(awa);
			trackUnload(awa);
			trackUHFSearch(awa);
			trackCtrlF(awa);
			trackNavigation(awa);

			notifyJsllReady(awa);
		}
	);
}

function setPageTagsFromMeta(pageTags: { [name: string]: string }) {
	const metas = document.querySelectorAll('meta');
	for (let i = 0; i < metas.length; i++) {
		const meta = metas.item(i);
		const awaTag = tagMapping[meta.name];
		if (meta.name === 'ms.collection') {
			// special case: ms.collection may appear multiple times.
			pageTags[awaTag] = pageTags[awaTag] ? `${pageTags[awaTag]},${meta.content}` : meta.content;
		} else if (awaTag) {
			pageTags[awaTag] = meta.content;
		}
	}

	pageTags.contentlocale = msDocs.data.contentLocale;
	pageTags.highContrast = osHighContrastEnabled.toString();
}

function getAppId(host: string) {
	// to verify AppId: https://appid.azurewebsites.net/
	const appIdMapping: { [name: string]: string } = {
		'docs.microsoft.com': 'Docs',
		'review.docs.microsoft.com': 'DocsReview',
		'docs.azure.cn': 'DocsCN',
		'developer.microsoft.com': 'DevCenter'
	};

	return appIdMapping[host] || 'JsllTest';
}

function configureJsll(awa: Awa, hasCookieConsent: boolean) {
	const jsllConfig = {
		syncMuid: hasCookieConsent,
		urlCollectHash: true,
		urlCollectQuery: true,
		autoCapture: {
			pageView: true,
			onLoad: true,
			click: true,
			scroll: true,
			resize: true,
			jsError: true,
			addin: true,
			msTags: false,
			perf: true,
			assets: false,
			lineage: true
		},
		coreData: {
			appId: getAppId(location.hostname),
			pageName: getMeta('document_id') || 'missing document_id',
			market: msDocs.data.userLocale,
			pageType: getMeta('page_type'),
			pageTags: {} as { [name: string]: string }
		},
		shareAuthStatus: true,
		isLoggedIn: false
	};

	setPageTagsFromMeta(jsllConfig.coreData.pageTags);
	awa.consoleVerbosity = awa.verbosityLevels.WARNING;

	awa.init(jsllConfig);
}

/**
 * A Promise that resolves with the JSLL/AWA API.
 */
export const jsllReady = new Promise<Awa>(resolve => (notifyJsllReady = resolve));

/**
 * Override values for manually fired BI events.
 */
export interface AwaOverrideValues {
	/**
	 * One of the awa.behavior values.
	 * https://microsoft.sharepoint.com/sites/mscomdata/Pages/awa-behavior-dictionary.aspx
	 */
	behavior?: AwaBehavior;
	/**
	 * 	Uri of the current page
	 */
	Uri?: string;
	/**
	 * Uri of the referrer page
	 */
	referrerUri?: string;
	/**
	 * Page name
	 */
	pageName?: string;
	/**
	 * One of the awa.actionType values
	 * https://microsoft.sharepoint.com/sites/mscomdata/Pages/AWA-Action-Type-Dictionary.aspx
	 */
	actionType?: AwaActionType;
	/**
	 * Height of the page
	 */
	pageHeight?: number;
	/**
	 * KVP of the content
	 */
	content?: { [key: string]: any };
	/**
	 * Target uri for PageAction events
	 */
	targetUri?: string;
	/**
	 * Scroll offset
	 */
	vScrollOffset?: number;
	/**
	 * Scroll offset
	 */
	hScrollOffset?: number;
	/**
	 * Indicates if the event was fired manually
	 */
	isManual?: boolean;
	/**
	 * Indicates if the Contentupdate event is the first ContentUpdate event
	 */
	isDomComplete?: boolean;
	/**
	 * Page load time
	 */
	pageLoadTime?: number;
	/**
	 * KVPs to be added to the content tags collected on a Page Action event; extends the items in the Content blob in Page Action events
	 */
	contentTags?: { [key: string]: string | number | boolean };
	/**
	 * KVP to be added to the page tags collected
	 */
	pageTags?: { [key: string]: string | number | boolean };
}

/**
 * The JSLL APIs
 */
export interface Awa {
	/**
	 * Dictionary of AWA action types.
	 * https://microsoft.sharepoint.com/sites/mscomdata/Pages/AWA-Action-Type-Dictionary.aspx
	 */
	actionType: AwaActionTypes;

	/**
	 * Dictionary of AWA behaviors
	 * https://microsoft.sharepoint.com/sites/mscomdata/Pages/awa-behavior-dictionary.aspx
	 */
	behavior: AwaBehaviors;

	/**
	 * Console log level.
	 */
	consoleVerbosity: number;

	/**
	 * Log levels.
	 */
	verbosityLevels: { ERROR: number; WARNING: number; INFORMATION: number; NONE: number };

	/**
	 * Methods for firing BI events manually.
	 */
	ct: {
		/**
		 * Fire a PageView event.
		 */
		capturePageView(overrideValues?: AwaOverrideValues): void;
		/**
		 * Fire a Contentupdate event.
		 */
		captureContentUpdate(overrideValues?: AwaOverrideValues): void;
		/**
		 * Fire a PageAction event for a specific element in the DOM.
		 */
		capturePageAction(element: EventTarget, overrideValues?: AwaOverrideValues): void;
		/**
		 * Fire a PageAction event when there is no DOM element involved.
		 */
		captureContentPageAction(overrideValues?: AwaOverrideValues): void;
	};

	ids: {
		getImpressionGuid(): string;
		setAppUserId(id: string): void;
	};

	/**
	 * Initializes jsll.
	 */
	init(config: object): void;

	/**
	 * Overrides the JSLL config's coreData object.
	 */
	extendCoreData(overrides: object): void;

	/**
	 * Gets current configuration.
	 */
	getConfig(): any;
}

/**
 * AWA action type: https://microsoft.sharepoint.com/sites/mscomdata/Pages/AWA-Action-Type-Dictionary.aspx
 */
export type AwaActionType = AwaActionTypes[keyof AwaActionTypes];

/**
 * AWA action types: https://microsoft.sharepoint.com/sites/mscomdata/Pages/AWA-Action-Type-Dictionary.aspx
 */
export interface AwaActionTypes {
	CLICKLEFT: 'CL';
	CLICKRIGHT: 'CR';
	SCROLL: 'S';
	ZOOM: 'Z';
	RESIZE: 'R';
	KEYBOARDENTER: 'KE';
	KEYBOARDSPACE: 'KS';
	OTHER: 'O';
	AUTO: 'A';
}

/**
 * AWA behavior: https://microsoft.sharepoint.com/sites/mscomdata/Pages/awa-behavior-dictionary.aspx
 */
export type AwaBehavior = AwaBehaviors[keyof AwaBehaviors];

/**
 * AWA behaviors: https://microsoft.sharepoint.com/sites/mscomdata/Pages/awa-behavior-dictionary.aspx
 */
export interface AwaBehaviors {
	UNDEFINED: 0;
	NAVIGATIONBACK: 1;
	NAVIGATION: 2;
	NAVIGATIONFORWARD: 3;
	APPLY: 4;
	REMOVE: 5;
	SORT: 6;
	EXPAND: 7;
	REDUCE: 8;
	CONTEXTMENU: 9;
	TAB: 10;
	COPY: 11;
	EXPERIMENTATION: 12;
	PRINT: 13;
	STARTPROCESS: 20;
	PROCESSCHECKPOINT: 21;
	COMPLETEPROCESS: 22;
	DOWNLOADCOMMIT: 40;
	DOWNLOAD: 41;
	SEARCHAUTOCOMPLETE: 60;
	SEARCH: 61;
	PURCHASE: 80;
	ADDTOCART: 81;
	VIEWCART: 82;
	ADDWISHLIST: 83;
	FINDSTORE: 84;
	CHECKOUT: 85;
	REMOVEFROMCART: 86;
	PURCHASECOMPLETE: 87;
	SIGNIN: 100;
	SIGNOUT: 101;
	SOCIALSHARE: 120;
	SOCIALLIKE: 121;
	SOCIALREPLY: 122;
	CALL: 123;
	EMAIL: 124;
	COMMUNITY: 125;
	VOTE: 140;
	SURVEYINITIATE: 141;
	SURVEYCOMPLETE: 142;
	REPORTAPPLICATION: 143;
	REPORTREVIEW: 144;
	CONTACT: 160;
	REGISTRATIONINITIATE: 161;
	REGISTRATIONCOMPLETE: 162;
	CHATINITIATE: 180;
	CHATEND: 181;
	TRIALSIGNUP: 200;
	TRIALINITIATE: 201;
	PARTNERREFERRAL: 220;
	VIDEOSTART: 240;
	VIDEOPAUSE: 241;
	VIDEOCONTINUE: 242;
	VIDEOCHECKPOINT: 243;
	VIDEOJUMP: 244;
	VIDEOCOMPLETE: 245;
	VIDEOBUFFERING: 246;
	VIDEOERROR: 247;
	VIDEOMUTE: 248;
	VIDEOUNMUTE: 249;
	VIDEOFULLSCREEN: 250;
	VIDEOUNFULLSCREEN: 251;
	VIDEOREPLAY: 252;
	VIDEPLAYERLOAD: 253;
	VIRTUALEVENTJOIN: 260;
	VIRTUALEVENTEND: 261;
	IMPRESSION: 280;
	CLICK: 281;
	RICHMEDIACOMPLETE: 282;
	ADBUFFERING: 283;
	ADERROR: 284;
	ADSTART: 285;
	ADCOMPLETE: 286;
	ADSKIP: 287;
	ADTIMEOUT: 288;
	OTHER: 300;
}

/**
 * Get the bi-name of an element or it's nearest ancestor if the element is not named.
 * @param element The element
 */
export function getName(element: HTMLElement) {
	while (element && element.hasAttribute && !element.hasAttribute(contentAttrs.name)) {
		element = element.parentElement;
	}
	if (!element) {
		return '';
	}
	return element.getAttribute(contentAttrs.name);
}

async function trackUser(awa: Awa) {
	const setUser = () => {
		const config = awa.getConfig();
		// https://osgwiki.com/wiki/JSLLv4#Tracking_Authenticated_Users
		// 1=MSA, 2=AAD
		config.authMethod = user.authenticationMode === 'MSA' ? 1 : 2;
		config.isLoggedIn = user.isAuthenticated;
		const id = user.isAuthenticated ? `c:${user.userId}` : null;
		awa.ids.setAppUserId(id);
	};
	user.subscribe(UserChangedEvent, setUser);
	setUser();

	await user.whenAuthenticated();
	awa.ct.captureContentPageAction({ content: { event: 'user-is-signed-in' } });
}

/**
 * Track the changes of named select elements.
 * @param awa The JSLL API
 */
function trackSelectElementChange(awa: Awa) {
	function handleChange(event: Event) {
		// skip events whose target is not a <select> or whose target does not have a "data-bi-name" attribute
		if (
			!event.isTrusted ||
			!(event.target instanceof HTMLSelectElement) ||
			!event.target.hasAttribute(contentAttrs.name)
		) {
			return;
		}

		awa.ct.capturePageAction(event.target, {
			actionType: awa.actionType.OTHER,
			behavior: awa.behavior.OTHER,
			content: {
				event: 'select-value-changed',
				name: getName(event.target as HTMLElement),
				value: event.target.value
			}
		});
	}
	document.addEventListener('change', handleChange, { passive: true } as any);
}

/**
 * Track when the page gains and loses focus.
 * @param awa The JSLL API
 */
function trackPageFocus(awa: Awa) {
	let previousType = '';
	function reportFocusChanged(event: FocusEvent) {
		if (!event.isTrusted || previousType === event.type) {
			return;
		}
		previousType = event.type;

		awa.ct.captureContentPageAction({
			actionType: awa.actionType.OTHER,
			behavior: awa.behavior.OTHER,
			content: {
				event: 'page-focus-changed',
				value: event.type
			}
		});
	}

	// The focus and blur events are debounced to reduce noise.
	// Internet Explorer fires extra focus/blur events.
	let timeout = 0;
	function handleFocusedChanged(event: FocusEvent) {
		clearTimeout(timeout);
		timeout = setTimeout(() => reportFocusChanged(event), 50);
	}

	window.addEventListener('focus', handleFocusedChanged, { passive: true } as any);
	window.addEventListener('blur', handleFocusedChanged, { passive: true } as any);
}

/**
 * Track when the page is hidden or visible.
 * @param awa The JSLL API
 */
function trackPageVisibility(awa: Awa) {
	function visibilityChanged() {
		awa.ct.captureContentPageAction({
			actionType: awa.actionType.OTHER,
			behavior: awa.behavior.OTHER,
			content: {
				event: 'page-visibility-changed',
				value: document.hidden ? 'hidden' : 'visible'
			}
		});
	}

	function attach() {
		document.addEventListener('visibilitychange', visibilityChanged, { passive: true } as any);
	}

	if (document.readyState === 'interactive' || document.readyState === 'complete') {
		attach();
	} else {
		document.addEventListener('DOMContentLoaded', attach);
	}
}

/**
 * Track when the page is printed.
 * @param awa The JSLL API
 */
function trackPrint(awa: Awa) {
	if (!isMatchMediaSupported) {
		return;
	}

	printQuery.addListener(m => {
		if (!m.matches) {
			return;
		}
		awa.ct.captureContentPageAction({
			actionType: awa.actionType.OTHER,
			behavior: awa.behavior.PRINT,
			content: {
				event: 'print'
			}
		});
	});
}

/**
 * Track when secondary content such as the sidebars, are scrolled.
 * @param awa The JSLL API
 */
function trackSecondaryContentScroll(awa: Awa) {
	function reportScroll(event: UIEvent) {
		if (!event.isTrusted || !(event.target instanceof HTMLElement)) {
			return;
		}
		const { width, height } = event.target.getBoundingClientRect();
		const { scrollLeft, scrollTop, scrollWidth, scrollHeight } = event.target;
		awa.ct.capturePageAction(event.target as HTMLElement, {
			actionType: awa.actionType.OTHER, // should this be "SCROLL"?
			behavior: awa.behavior.OTHER,
			content: {
				event: 'secondary-content-scroll',
				name: getName(event.target as HTMLElement),
				viewPortWidth: Math.floor(width),
				viewPortHeight: Math.floor(height),
				contentWidth: Math.floor(scrollWidth),
				contentHeight: Math.floor(scrollHeight),
				horizontalOffset: Math.floor(scrollLeft),
				verticalOffset: Math.floor(scrollTop)
			}
		});
	}

	function handleScroll(event: UIEvent) {
		// Skip reporting document scroll. JSLL has built-in scroll reporting for the document element.
		if (event.target === document) {
			return;
		}
		// debounce the scroll event
		const target = event.target as { reportScrollTimeout?: number };
		clearTimeout(target.reportScrollTimeout);
		target.reportScrollTimeout = setTimeout(() => reportScroll(event), 100);
	}

	window.addEventListener('scroll', handleScroll, { passive: true, capture: true } as any);
}

/**
 * Track the page unload event and whether a link click triggered the event.
 * Note: JSLL has built-in page unload tracking so we might not need this. Depends on
 * whether the link click tracking is required.
 * @param awa The JSLL API
 */
function trackUnload(awa: Awa) {
	let anchor = false;

	function handleUnload() {
		awa.ct.captureContentPageAction({
			actionType: awa.actionType.OTHER,
			behavior: awa.behavior.OTHER,
			content: {
				event: 'unload',
				anchor
			}
		});
	}

	function handleClick(event: MouseEvent) {
		if (event.target instanceof HTMLAnchorElement) {
			anchor = true;
			setTimeout(() => (anchor = false));
		}
	}

	function handleKeyDown(event: KeyboardEvent) {
		if (event.target instanceof HTMLAnchorElement) {
			anchor = true;
			setTimeout(() => (anchor = false));
		}
	}

	window.addEventListener('keydown', handleKeyDown, { capture: true, passive: true } as any);
	window.addEventListener('click', handleClick, { capture: true, passive: true } as any);
	window.addEventListener('beforeunload', handleUnload, { passive: true } as any);
}

/**
 * Track search submissions in the universal header and whether the submit button was used.
 * @param awa The JSLL API
 */
function trackUHFSearch(awa: Awa) {
	function handleSubmit(event: Event) {
		const form = event.target as HTMLFormElement;
		if (form.id !== 'searchForm' && form.id !== 'searchFormDesktop') {
			return;
		}

		const term = (form.querySelector('input[name="search"]') as HTMLInputElement).value;
		const submitButtonIsFocused = form.querySelector('#search:focus') !== null;

		awa.ct.capturePageAction(form, {
			actionType: awa.actionType.OTHER,
			behavior: awa.behavior.SEARCH,
			content: {
				event: 'uhf-search',
				value: term,
				submitButton: submitButtonIsFocused
			}
		});
	}

	window.addEventListener('submit', handleSubmit, { passive: true, capture: true } as any);
}

/**
 * Track CTRL+F
 * @param awa The JSLL API
 */
function trackCtrlF(awa: Awa) {
	function handleKeyDown(event: KeyboardEvent) {
		if (
			event.isTrusted &&
			event.keyCode === 70 &&
			event.ctrlKey &&
			!event.shiftKey &&
			!event.altKey &&
			!event.metaKey
		) {
			awa.ct.captureContentPageAction({
				actionType: awa.actionType.OTHER,
				behavior: awa.behavior.OTHER,
				content: {
					event: 'ctrl-f'
				}
			});
		}
	}

	window.addEventListener('keydown', handleKeyDown, { passive: true } as any);
}

function trackNavigation(awa: Awa) {
	function handleNavigate(detail: RouterAfterNavigateEvent) {
		const overrides = {
			referrerUri: detail.referrerUrl.href,
			requestUri: detail.url.href,
			title: detail.title,
			pageName: getMeta('document_id') || 'missing document_id',
			pageType: getMeta('page_type'),
			pageTags: {} as { [name: string]: string }
		};
		setPageTagsFromMeta(overrides.pageTags);
		awa.extendCoreData(overrides);
		awa.ct.capturePageView();
	}
	eventBus.subscribe(RouterAfterNavigateEvent, handleNavigate);
}

export async function capturePageAction(
	target: EventTarget,
	behavior: keyof AwaBehaviors,
	actionType: keyof AwaActionTypes
) {
	const awa = await jsllReady;
	awa.ct.capturePageAction(target, {
		behavior: awa.behavior[behavior],
		actionType: awa.actionType[actionType]
	});
}

import { contentLoaded, msDocs } from '../globals';
import { getMeta } from '../meta';
import { hasApplicationInsights } from './feature-conditions/app-insights';
import { traits } from './traits';
import { preview } from '../preview/query-string';

/**
 * The global features object.
 * Allows the front end to know which features are available in a given configuration.
 */
export const features = getFeatures();

export interface Features {
	apiBrowser: boolean;
	applicationInsights: boolean;
	assessments: boolean;
	assessmentBranches: boolean;
	assessmentSideNav: boolean;
	bingMaps: Promise<boolean>;
	binaryRating: boolean;
	chromeless: boolean;
	codeSamples: boolean;
	contentBrowse: boolean;
	cookieConsent: boolean;
	courseScheduling: boolean;
	feedbackReport: boolean;
	facetSearch: boolean;
	gamification: boolean;
	gitContributors: boolean;
	githubIssues: boolean;
	googleAnalytics: boolean;
	jsll: boolean;
	interactivity: boolean;
	labsOnDemand: boolean;
	marketing: boolean;
	moduleUnitFeedbackReport: boolean;
	mooncakeHeader: boolean;
	pageRecommendations: boolean;
	pdfDownload: boolean;
	riskIntegration: boolean;
	rss: boolean;
	sandbox: boolean;
	shareLink: boolean;
	speedCurve: boolean;
	starRatings: boolean;
	thirdPartyHeader: boolean;
	tocSearch: boolean;
	userServices: boolean;
	usabilla: boolean;
}

function getFeatures(): Features {
	return {
		apiBrowser: traits.accessLevel !== 'isolated',
		applicationInsights: hasApplicationInsights() && traits.accessLevel !== 'isolated',
		assessments: traits.accessLevel !== 'isolated',
		assessmentBranches:
			traits.accessLevel !== 'isolated' &&
			traits.supportLevel === 'production' &&
			traits.reviewFeatures,
		assessmentSideNav:
			traits.accessLevel !== 'isolated' &&
			traits.reviewFeatures &&
			preview('assessment-side-nav', { require: true }),
		binaryRating: traits.accessLevel !== 'isolated' && msDocs.data.hasBinaryRating,
		bingMaps: contentLoaded.then(() => msDocs.data.mapMode && traits.accessLevel !== 'isolated'),
		chromeless: msDocs.data.context.chromeless,
		codeSamples: traits.accessLevel !== 'isolated',
		contentBrowse: traits.accessLevel !== 'isolated',
		cookieConsent: traits.accessLevel !== 'isolated',
		courseScheduling: traits.accessLevel !== 'isolated',
		facetSearch: traits.accessLevel !== 'isolated',
		feedbackReport: traits.accessLevel !== 'isolated' && msDocs.data.showFeedbackReport,
		gamification: traits.accessLevel !== 'isolated',
		gitContributors: traits.accessLevel !== 'isolated',
		githubIssues: traits.accessLevel !== 'isolated' && msDocs.data.hasGithubIssues,
		googleAnalytics:
			location.hostname === 'docs.microsoft.com' && traits.accessLevel !== 'isolated',
		jsll: traits.accessLevel !== 'isolated',
		interactivity: traits.accessLevel !== 'isolated',
		labsOnDemand: traits.accessLevel !== 'isolated',
		marketing: traits.accessLevel !== 'isolated',
		moduleUnitFeedbackReport: traits.accessLevel !== 'isolated',
		mooncakeHeader: msDocs.data.brand === 'mooncake',
		pageRecommendations:
			traits.accessLevel !== 'isolated' &&
			getMeta('page_type') === 'conceptual' &&
			getMeta('recommendations') === 'true' &&
			msDocs.data.userLocale === 'en-us',
		pdfDownload: traits.accessLevel !== 'isolated',
		riskIntegration: traits.accessLevel !== 'isolated' && preview('risk-sms', { require: true }),
		rss: traits.accessLevel !== 'isolated',
		sandbox: traits.accessLevel !== 'isolated',
		shareLink: traits.accessLevel !== 'isolated',
		speedCurve: traits.accessLevel !== 'isolated' && traits.supportLevel === 'production',
		starRatings: traits.accessLevel !== 'isolated',
		thirdPartyHeader: traits.accessLevel !== 'isolated',
		tocSearch: msDocs.data.brand !== 'mooncake',
		userServices: traits.accessLevel !== 'isolated',
		usabilla: traits.accessLevel !== 'isolated'
	};
}

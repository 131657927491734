import { document, window } from './globals';

/**
 * Affix the sidebars
 */
export function affix() {
	const primary = document.querySelector('.primary-holder') as HTMLDivElement;
	const left = document.getElementById('affixed-left-container') as HTMLDivElement;
	const right = document.getElementById('affixed-right-container') as HTMLDivElement;
	const footer = document.querySelector('body > .footerContainer') as HTMLDivElement;

	if (left === null && right === null) {
		return;
	}

	// update the position of the affixed content
	function update() {
		const spacing = 24;
		const viewportHeight = window.innerHeight;
		const top = Math.max(0, primary.getBoundingClientRect().top) + spacing;
		const bottom = Math.max(0, viewportHeight - footer.getBoundingClientRect().top) + spacing;

		if (left !== null && !left.hasAttribute('disable-affix')) {
			left.style.width = `${getParentColumnWidth(left) - spacing * 2}px`;
			left.style.top = `${top}px`;
			left.style.bottom = `${bottom}px`;
		}
		if (right !== null) {
			right.style.width = `${getParentColumnWidth(right) - spacing * 2}px`;
			right.style.top = `${top}px`;
			right.style.bottom = `${bottom}px`;
		}
	}

	// debounces updates, puts update processing on an animation frame
	let animationFrame = 0;
	function scheduleUpdate() {
		cancelAnimationFrame(animationFrame);
		animationFrame = requestAnimationFrame(update);
	}

	// listen for scroll or resize
	window.addEventListener('scroll', scheduleUpdate, { passive: true } as any);
	window.addEventListener('resize', scheduleUpdate, { passive: true } as any);

	// listen for manual content updates
	window.addEventListener('content-update', scheduleUpdate);

	// do initial update
	update();
	window.addEventListener('load', update, false);
	window.addEventListener('DOMContentLoaded', update, false);
}

/**
 * Manually notify the affix system that a content update has occurred.
 */
export function notifyContentUpdated() {
	window.dispatchEvent(new CustomEvent('content-update'));
}

function getParentColumnWidth(element: HTMLElement): number {
	return element.parentElement.getBoundingClientRect().width;
}

import { fetchWithTimeout } from '../fetch';
import { location, msDocs } from '../globals';
import { getMeta } from '../meta';
import { getMoniker } from '../monikers/moniker';
import { parseQueryString } from '../query-string';
import { resolveUrl } from './url';

/**
 * Fetch toc.json data.
 * Must be consistent with <link rel="preload" ...> tags to avoid duplicate fetches.
 */
export function fetchToc(url: string) {
	const init: RequestInit = { credentials: 'include' }; // credentials are required in review.
	return fetchWithTimeout(url, init);
}

/**
 * Builds a prioritized list of URLs to attempt loading.
 * In the future the rendering layer will figure this out for us.
 */
export function getUrlQueue(
	contextProperty: 'tocRel' | 'breadcrumbPath',
	queryStringArg: 'toc' | 'bc',
	metaTagName: 'toc_rel' | 'breadcrumb_path'
) {
	const urls: string[] = [];

	// Does the context specify a URL?
	const contextValue = msDocs.data.context[contextProperty];
	if (contextValue) {
		const contextQueryString = parseQueryString().context;
		const pathSeparator = contextQueryString[0] === '/' ? '' : '/';
		const contextFile = `${location.origin}/${msDocs.data.userLocale}${pathSeparator}${contextQueryString}`;
		urls.push(resolveUrl(contextValue, contextFile).href);
	}

	// Does the query string specify a URL?
	const queryValue = parseQueryString(location.search)[queryStringArg];
	if (queryValue) {
		urls.push(resolveUrl(queryValue, location.href).href);
	}

	// Does the page meta provide a URL?
	const metaValue = getMeta(metaTagName);

	if (metaValue) {
		const resolvedUrl = new URL(resolveUrl(metaValue, location.href).href);
		const moniker = getMoniker();

		if (moniker) {
			resolvedUrl.searchParams.set('view', moniker);
		}

		urls.push(resolvedUrl.href);
	}

	return urls;
}

/**
 * Loads a sequence of URLs, returning the first url and associated
 * data which loads successfully.
 */
export async function loadUrlQueue(type: 'TOC' | 'breadcrumb', urlQueue: string[]) {
	for (const url of urlQueue) {
		const response = await fetchToc(url);

		if (response.status === 404) {
			continue;
		}

		if (!response.ok) {
			throw new Error(
				`Fetching ${type} data at ${url} resulted in ${response.status} ${response.statusText}.`
			);
		}

		const data = await response.json();

		return { url, data };
	}
	throw new Error(`Unable to locate ${type} file. URLs attempted: [${urlQueue}]`);
}

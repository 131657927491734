import { searchLocation } from '../apis/course-scheduling';
import { AutoCompleteSuggestionDetail } from '../components/autocomplete';
import { EventBus } from '../event-bus';
import { msDocs } from '../globals';
import { getMeta } from '../meta';
import { PropertyChangedEvent } from '../view-model';
import { reportAutocompleteCleared, reportAutocompleteSelection } from './bi';
import { CourseViewModelConfig, SearchLocation } from './course-types';

/**
 * View model for available courses - handling form filter submission
 * and date selection. Owns a list view model for displaying results.
 */
export class CourseViewModel extends EventBus {
	private _location: SearchLocation;
	private _date: Date;
	private _virtualOnly: boolean = false;

	public get location() {
		return this._location;
	}
	public set location(value: SearchLocation) {
		if (value === this._location) {
			return;
		}

		this._location = value;
		this.notifyPropertyChanged();
	}

	public get date() {
		return this._date;
	}
	public set date(value: Date) {
		if (value === this._date) {
			return;
		}

		this._date = value;
		this.notifyPropertyChanged();
	}

	public get virtualOnly() {
		return this._virtualOnly;
	}
	public set virtualOnly(value: boolean) {
		if (value === this._virtualOnly) {
			return;
		}

		this._virtualOnly = value;
		if (value) {
			this._location = undefined;
		}

		this.notifyPropertyChanged();
	}

	constructor(config?: CourseViewModelConfig) {
		super();

		if (config) {
			this._date = config.date;
			this._location = config.location;
			this._virtualOnly = config.virtualOnly;
		}
	}

	public async loadAutocompleteSuggestions(term: string) {
		return searchLocation({
			locale: msDocs.data.userLocale,
			courseId: getMeta('uid'),
			searchTerm: term
		});
	}

	public handleAutocompleteSelection = (
		event: CustomEvent<AutoCompleteSuggestionDetail<SearchLocation>>
	) => {
		this.location = event.detail.suggestion;
		reportAutocompleteSelection(this.location.location);
	};

	public handleAutocompleteClear = ({ target }: InputEvent) => {
		const value = (target as HTMLInputElement).value;
		if (value.length) {
			return;
		}

		this.location = undefined;
		reportAutocompleteCleared();
	};

	public handleDateChange = (event: InputEvent) => {
		const input = event.target as HTMLInputElement;
		let date = input.valueAsDate;

		// In browsers that don't support date input, value as date will be undefined.
		// Manually parse the date following the valueAsDate rules.
		// We use date.parse here instead of trying to manually deal with a string due to localization.
		if (input.value.length && !date) {
			const parsed = Date.parse(input.value);
			if (!isNaN(parsed)) {
				date = new Date(parsed);
			}
		}

		this.date = date;
	};

	public handleShowVirtualOnlyChange = (event: InputEvent) => {
		this.virtualOnly = (event.target as HTMLInputElement).checked;
	};

	private notifyPropertyChanged() {
		this.publish(new PropertyChangedEvent());
	}
}

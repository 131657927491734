import { fetchWithTimeout } from '../fetch';
import { AzureSandbox } from './sandbox';
import { AzureToken } from './token';

export function configureCloudShell(
	sandbox: AzureSandbox,
	token: AzureToken,
	isPowershell: boolean
) {
	const settings: CloudShellSettings = {
		properties: {
			preferredOsType: isPowershell ? 'Windows' : 'Linux',
			preferredLocation: sandbox.storageAccountRegion || 'westus',
			storageProfile: {
				storageAccountResourceId: sandbox.storageAccountId,
				fileShareName: sandbox.fileShareName,
				diskSizeInGB: 1
			},
			terminalSettings: {
				fontSize: 'Medium',
				fontStyle: 'Monospace'
			}
		}
	};

	const url =
		'https://management.azure.com/providers/Microsoft.Portal/userSettings/cloudconsole?api-version=2017-08-01-preview';

	const init = {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			Authorization: `${token.access_token_type} ${token.access_token}`,
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(settings)
	};

	return fetchWithTimeout(url, init).then(response => {
		if (response.ok) {
			return;
		}
		throw new Error(response.statusText);
	});
}

interface CloudShellSettings {
	properties: {
		preferredOsType: 'Linux' | 'Windows';
		preferredLocation: string; // westus
		storageProfile: {
			storageAccountResourceId: string;
			fileShareName: string;
			diskSizeInGB: number;
		};
		terminalSettings: {
			fontSize: 'Small' | 'Medium' | 'Large';
			fontStyle: 'Monospace' | 'Courier';
		};
	};
}

export function deleteCloudShellSession(token: AzureToken) {
	const url =
		'https://management.azure.com/providers/Microsoft.Portal/consoles/default?api-version=2017-12-01-preview';

	const init = {
		method: 'DELETE',
		headers: {
			Authorization: `${token.access_token_type} ${token.access_token}`
		}
	};

	return fetchWithTimeout(url, init).then(response => {
		if (response.ok) {
			return;
		}
		throw new Error(response.statusText);
	});
}

import {
	loc_aadPrivacyNotice,
	loc_areYouSure,
	loc_areYouSureDescription,
	loc_close,
	loc_createMSAProfileExplanation,
	loc_delete,
	loc_microsoftEmployeePrivacyNotice,
	loc_microsoftEmployeePrivacyStatement,
	loc_microsoftPrivacyNotice,
	loc_microsoftPrivacyNoticeAgreementStatement,
	loc_noThanks,
	loc_okGotIt,
	loc_somethingWentWrongPleaseTryAgain
} from '@msdocs/strings';
import { deleteProfile, setAcceptedPrivacyNotice } from '../apis/profile';
import { Modal } from '../components/modal/modal';
import { contentLoaded, document, msDocs } from '../globals';
import { html, render, unsafeHTML } from '../lit-html';
import { getMeta } from '../meta';
import { signOut } from './service';
import { User, user, userAcceptedDataConsentDate } from './user';

export let modal: Modal;
let privacyNoticeContainer: HTMLDivElement;

export async function checkAadConsent(user: User) {
	if (isConsentNeeded(user)) {
		await showConsentModal();
	}
}

export function isConsentNeeded(user: User): boolean {
	const isTechProfile = getMeta('page_type') === 'profile';
	if (!user.isAuthenticated || isTechProfile) {
		return false;
	}

	let userAcceptedDataConsentMessage: boolean = false;

	if (typeof user.acceptedPrivacyNotice === 'boolean') {
		userAcceptedDataConsentMessage = user.acceptedPrivacyNotice;
	} else {
		userAcceptedDataConsentMessage =
			new Date(user.acceptedPrivacyNotice) >= userAcceptedDataConsentDate;
	}

	return !userAcceptedDataConsentMessage;
}

export async function showConsentModal() {
	if (!modal) {
		const modalConsentContainer = document.createElement('div');
		modalConsentContainer.classList.add('modal-content');
		render(
			html` <div
				id="consentModal"
				class="modal-card is-shadowless-mobile fill-mobile has-padding-medium has-padding-large-tablet"
			></div>`,
			modalConsentContainer
		);
		modal = new Modal(modalConsentContainer);
		privacyNoticeContainer = modalConsentContainer.querySelector('#consentModal');
		renderPrivacyNotice();
	}

	await contentLoaded;
	modal.show();
}

function renderPrivacyNotice() {
	const modalTitle =
		user.userType === 'microsoft' ? loc_microsoftEmployeePrivacyNotice : loc_microsoftPrivacyNotice;
	const handleAcceptPrivacyNotice = () => setAcceptedPrivacyNotice();

	const privacyNoticeTemplate = html`
		<section class="modal-card-body">
			<h2 class="title is-text-centered">${modalTitle}</h2>
			<p>${unsafeHTML(getPrivacyNoticeByUserType())}</p>
			<button
				id="consent"
				@click="${handleAcceptPrivacyNotice}"
				class="modal-close button is-primary is-block has-margin-auto-sides has-margin-top-large"
				autofocus
			>
				${loc_okGotIt}
			</button>
			<button
				id="noThanks"
				@click="${renderDeleteConfirmation}"
				class="button is-text is-link has-text-primary is-block has-margin-auto-sides has-margin-top-medium"
			>
				${loc_noThanks}
			</button>
		</section>
	`;

	render(privacyNoticeTemplate, privacyNoticeContainer);
}

function renderDeleteConfirmation() {
	const deleteConfirmationTemplate = html` <section class="modal-card-body has-padding-bottom-none">
		<h2>${loc_areYouSure}</h2>
		<p>${loc_microsoftPrivacyNoticeAgreementStatement}</p>
		<p class="has-margin-top-large">${loc_areYouSureDescription}</p>
		<div class="is-flex has-flex-justify-content-end has-margin-top-extra-large">
			<button
				id="consent-close"
				class="button title is-5 has-border-none has-padding-left-large has-padding-right-large has-margin-right-small has-text-primary"
				@click="${renderPrivacyNotice}"
				autofocus
			>
				${loc_close}
			</button>
			<button
				id="consent-delete-acct"
				class="button is-danger title is-5 has-padding-left-large has-padding-right-large"
			>
				${loc_delete}
			</button>
		</div>
		<p
			hidden
			id="error-message"
			class="has-text-danger is-flex has-flex-justify-content-end has-margin-bottom-large"
			aria-live="assertive"
			aria-relevant="text"
		>
			${loc_somethingWentWrongPleaseTryAgain}
		</p>
	</section>`;

	render(deleteConfirmationTemplate, privacyNoticeContainer);

	handleProfileDeletion(privacyNoticeContainer);
}

async function handleProfileDeletion(privacyNoticeContainer: HTMLElement) {
	const errorMessage = privacyNoticeContainer.querySelector('#error-message');
	const deleteButton = privacyNoticeContainer.querySelector(
		'#consent-delete-acct'
	) as HTMLButtonElement;

	errorMessage.setAttribute('hidden', 'hidden');

	deleteButton.onclick = async () => {
		deleteButton.classList.add('is-loading');
		try {
			const success = await deleteProfile();

			if (success) {
				modal.hide();
				await signOut(`https://docs.microsoft.com/${msDocs.data.userLocale}/`, true);
			} else {
				errorMessage.removeAttribute('hidden');
			}
		} catch (err) {
			errorMessage.removeAttribute('hidden');
			throw new Error('error: ' + err.message);
		} finally {
			deleteButton.classList.remove('is-loading');
		}
	};
}

/**
 * Returns the correct privacy notice for user type
 * @param microsoftUser optional param used during step 2 of registration
 * 						user.userType does not exist yet since we only have claims info
 */
export function getPrivacyNoticeByUserType(microsoftUser?: string): string {
	if ((microsoftUser && microsoftUser.length !== 0) || user.userType === 'microsoft') {
		return loc_microsoftEmployeePrivacyStatement.replace('{email}', microsoftUser || user.upn);
	}

	if (user.authenticationMode === 'AAD' || user.userType === 'aad') {
		return loc_aadPrivacyNotice.replace('{email}', user.upn);
	}

	return loc_createMSAProfileExplanation;
}

import { loc_clickForRSS, loc_rss } from '@msdocs/strings';
import { html } from '../../../lit-html';
import { SearchViewModel } from '../view-model';

export function rssTemplate<TResult>(vm: SearchViewModel<TResult>) {
	if (!vm.rssEnabled || vm.results.length < 1) {
		return '';
	}

	return html`
		<div
			class="has-margin-top-medium has-margin-bottom-medium has-margin-top-large-tablet has-padding-extra-small-tablet"
		>
			<a
				href="${vm.rssUrl}"
				class="button has-border"
				aria-label="${loc_clickForRSS}"
				data-bi-name="search-rss-link"
			>
				<span class="icon" aria-hidden="true">
					<span class="docon docon-feed"></span>
				</span>
				<span>${loc_rss}</span>
			</a>
		</div>
	`;
}

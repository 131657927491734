import { keyCodes } from '../key-codes';
import { signIn, signOut } from './service';

export const signInClassName = 'docs-sign-in';
export const signOutClassName = 'docs-sign-out';

/**
 * Listen for clicks on elements with class "docs-sign-in-button" and navigate to the sign in page.
 */
export function initSignInLinks(container: Element) {
	initBIHandler(container);

	/**
	 * Handle the actual click event and change the browser URL accordingly.
	 */
	container.addEventListener('click', handler);
}

export function handler(event: Event) {
	if (!(event.target instanceof Element)) {
		return;
	}
	const element = event.target.closest(`.${signInClassName}, .${signOutClassName}`) as HTMLElement;
	if (!element) {
		return;
	}

	const isSignIn = element.classList.contains(signInClassName);
	event.preventDefault();

	const anchorWithHref = 'a[href]:not([href=""]):not([href="#"])';
	const returnUrl =
		element instanceof HTMLAnchorElement && element.matches(anchorWithHref)
			? element.href
			: location.href;

	if (isSignIn) {
		signIn(returnUrl);
	} else {
		signOut(returnUrl, true);
	}
}

export function initBIHandler(container: Element) {
	const attributeHandler = (event: Event) => {
		if (!(event.target instanceof Element)) {
			return;
		}
		const element = event.target.closest(
			`.${signInClassName}, .${signOutClassName}`
		) as HTMLElement;
		if (!element) {
			return;
		}

		const isSignIn = element.classList.contains(signInClassName);
		event.target.setAttribute(
			'data-m',
			JSON.stringify({
				cN: isSignIn ? signInClassName : signOutClassName,
				bhvr: isSignIn ? 100 : 101
			})
		);
	};
	container.addEventListener('touchstart', attributeHandler, true);
	container.addEventListener('pointerdown', attributeHandler, true);
	container.addEventListener(
		'keyup',
		(event: KeyboardEvent) => {
			if (event.which === keyCodes.enter) {
				attributeHandler(event);
			}
		},
		true
	);
}

import { loc_downloadPdf } from '@msdocs/strings';
import { document, location, msDocs } from '../globals';
import { html, render } from '../lit-html';
import { getMeta } from '../meta';
import { getMoniker } from '../monikers/moniker';
import { parseQueryString, toQueryString } from '../query-string';
import { TocMetadata } from './types';

export function getPdfUrl({ pdf_absolute_path, pdf_name }: TocMetadata) {
	const branch = parseQueryString().branch || 'live';
	const view = getMoniker();
	if (pdf_absolute_path) {
		const userLocale = msDocs.data.userLocale;
		return `${location.origin}/${userLocale}${pdf_absolute_path}?${toQueryString({
			branch,
			view
		})}`;
	}
	const template = getMeta('pdf_url_template');
	if (template && pdf_name) {
		return template
			.replace('{branchName}', branch)
			.replace('{viewName}', view)
			.replace('{pdfName}', pdf_name);
	}
	return null;
}

export function createPdfLink(url: string) {
	const a = document.createElement('a');
	a.className =
		'button is-small is-text has-flex-justify-content-start has-inner-focus has-margin-top-small has-border-top has-border-bottom has-flex-shrink-none';
	a.href = url;
	a.setAttribute('data-bi-name', 'downloadPdf');
	a.setAttribute('rel', 'nofollow');
	render(
		html` <span class="icon is-size-extra-small has-text-subtle">
				<span aria-hidden="true" class="docon docon-dictionary-download"></span>
			</span>
			<span>${loc_downloadPdf}</span>`,
		a
	);
	return a;
}

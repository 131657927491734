import {
	loc_signOut,
	loc_tryIt,
	loc_tryItAuth_changeAuth,
	loc_tryItAuth_selectAuth,
	loc_tryItAuth_selectAuthDescription,
	loc_tryItDescription
} from '@msdocs/strings';
import { createRestTryitResponse, restFetch, restRequest } from '../apis/rest-tryit';
import { azureOAuthLogout } from '../azure/oauth';
import { EventBus } from '../event-bus';
import { configureValidation } from '../forms/form';
import { document, msDocs } from '../globals';
import { scrollTo } from '../scroll-to';
import { reportRestFetch } from './bi';
import {
	RestRenderResponse,
	RestRetrieveRequestData,
	RestRunEvent,
	RestRunEventDone
} from './events';
import { renderRequest } from './request';
import { initResponse } from './response';
import { getSecurityStrategy, SecurityStrategy } from './security';
import { RestTryItRequest } from './types';

let restTryItRequest: RestTryItRequest = null;
let securityStrategies: SecurityStrategy[] = [];
let currentStrategy: SecurityStrategy;
let tryItElement: HTMLElement;

export function initRestTryIt(): HTMLElement {
	tryItElement = document.createElement('div');
	tryItElement.style.height = '100%';
	tryItElement.setAttribute('aria-live', 'polite');
	tryItElement.setAttribute('aria-atomic', 'true');
	tryItElement.tabIndex = -1;

	restTryItRequest = buildRestTryItRequest(msDocs.data.restAPIData);

	chooseSecurityMethod(tryItElement, msDocs.data.restAPIData.security).then(strategy => {
		currentStrategy = strategy;
		loginAndRender(tryItElement);
	});

	return tryItElement;
}

export async function chooseSecurityMethod(
	rootElement: HTMLElement,
	methods: Rest.RestSecurity[]
): Promise<SecurityStrategy> {
	if (!methods || !methods.length) {
		const strat = getSecurityStrategy('oauth2');
		securityStrategies = [strat];
		return strat;
	}

	if (methods.length === 1) {
		const strat = getSecurityStrategy(methods[0].type);
		securityStrategies = [strat];
		return strat;
	}

	// render some UI for switching, where interaction returns promise with security method for choice.
	securityStrategies = methods.map(x => getSecurityStrategy(x.type));
	return await renderSecuritySelector(rootElement, securityStrategies);
}

export async function renderSecuritySelector(
	rootElement: HTMLElement,
	strategies: SecurityStrategy[]
): Promise<SecurityStrategy> {
	return new Promise<SecurityStrategy>(resolve => {
		const strategyMap = strategies.reduce((p, c) => {
			p[c.type] = c;
			return p;
		}, {} as { [key: string]: SecurityStrategy });

		// Fix for duplicate buttons in display. Remove when we refactor this.
		const strategyDisplay = strategies.reduce((p, c) => {
			p[c.type] = `
				<button class="button is-primary is-outlined" data-strategy="${c.type}" id="choose-${c.type}" type="button">
					${c.prompt}
				</button>
			`;
			return p;
		}, {} as { [key: string]: string });

		const template = `
			<div class="azure-auth">
				<div class="azure-auth-step">
					<h2 class="has-margin-bottom-extra-small">${loc_tryItAuth_selectAuth}</h2>
					<p>${loc_tryItAuth_selectAuthDescription}</p>
					<div class="buttons">
						${Object.keys(strategyDisplay)
							.map(x => strategyDisplay[x])
							.join('')}
					</div>
				<div>
			</div>
		`;

		rootElement.innerHTML = template;

		const handler = (event: Event) => {
			const target = (event.target as HTMLElement).closest('button');
			if (!target) {
				return;
			}

			const strategy = target.dataset.strategy;
			if (!strategy || !strategy.length) {
				return;
			}

			rootElement.removeEventListener('click', handler);
			resolve(strategyMap[strategy]);
		};

		rootElement.addEventListener('click', handler);
	});
}

async function loginAndRender(rootElement: HTMLElement): Promise<void> {
	// Handle focus.
	if (rootElement.contains(document.activeElement)) {
		rootElement.focus();
	}

	await currentStrategy.login(rootElement);
	renderFrame(rootElement);
}

function renderFrame(element: HTMLElement): void {
	element.innerHTML = '';
	const container = document.createElement('form');
	container.classList.add('rest-tryit-form');

	const blockDiv = document.createElement('div');
	blockDiv.classList.add('signin-section');

	renderHeadingSection(blockDiv);
	container.appendChild(blockDiv);

	element.insertAdjacentElement('afterbegin', container);
	const bus = new EventBus();
	renderRestTryIt(container, bus, restTryItRequest);
	initResponse(container, bus);

	setTimeout(() => {
		tryItElement.setAttribute('aria-live', 'off');
	}, 0);
}

/**
 * Build the Try It Request. This should now include the authorization strategy and how it must be fulfilled.
 * @param restApiData Data used to build the request.
 */
function buildRestTryItRequest(restApiData: Rest.RestAPIData): RestTryItRequest {
	const req: RestTryItRequest = {
		url: restApiData.path,
		httpVerb: restApiData.httpVerb,
		headers: [],
		params: [],
		body: restApiData.requestBody
	};

	if (
		restApiData.httpVerb === 'PUT' ||
		restApiData.httpVerb === 'POST' ||
		restApiData.httpVerb === 'PATCH'
	) {
		//This may change to load from media types from backend in Rest V3 version
		req.headers.push({
			name: 'Content-Type',
			value: 'application/json',
			type: 'string',
			in: 'header',
			isRequired: true,
			skipUrlEncoding: false
		});
	}

	restApiData.requestHeader.forEach(header => {
		if (header.in === 'header') {
			req.headers.push({
				name: header.name,
				value: '',
				type: header.type,
				in: header.in,
				isRequired: header.isRequired,
				skipUrlEncoding: false
			});
		}
	});

	restApiData.uriParameters.forEach(uriParam => {
		if (uriParam.in === 'path' || uriParam.in === 'query') {
			req.params.push({
				name: uriParam.name,
				value: '',
				type: uriParam.type,
				in: uriParam.in,
				isRequired: uriParam.isRequired,
				skipUrlEncoding: uriParam.skipUrlEncoding
			});
		}
	});

	return req;
}

function renderHeadingSection(container: HTMLElement) {
	const template = `
		<h2>REST API ${loc_tryIt}</h2>
		<p>${loc_tryItDescription}</p>
		<button hidden type="button" name="choose-auth" class="button is-small">
			${loc_tryItAuth_changeAuth}
		</button>
		<button type="button" name="signout" class="button is-small">${loc_signOut}</button>
	`;

	container.innerHTML = template;
	const changeAuthButton = container.querySelector<HTMLButtonElement>('button[name=choose-auth]');
	changeAuthButton.addEventListener('click', async () => {
		if (tryItElement.contains(document.activeElement)) {
			tryItElement.focus();
		}
		tryItElement.setAttribute('aria-live', 'polite');
		currentStrategy = await renderSecuritySelector(tryItElement, securityStrategies);
		loginAndRender(tryItElement);
	});

	const logoutButton = container.querySelector<HTMLButtonElement>('button[name=signout]');
	logoutButton.onclick = async () => {
		// logout is always a page redirect
		azureOAuthLogout();
	};

	if (securityStrategies.length > 1) {
		changeAuthButton.hidden = false;
	}

	if (currentStrategy.type !== 'oauth2') {
		logoutButton.hidden = true;
	}
}

export function renderRestTryIt(
	container: HTMLFormElement,
	bus: EventBus,
	request: RestTryItRequest
) {
	const runButton = renderRequest(container, bus, request, currentStrategy);

	// Form validation
	configureValidation(container);

	container.onsubmit = event => {
		submitTryitForm(runButton, bus, currentStrategy);
		event.preventDefault();
	};

	const handleRunRequest = (event: RestRunEvent) => {
		const restReq = event.restTryItRequest;
		const security = event.security;

		// send request to server get response back.
		const httpRequest = restRequest(restReq, security);

		restFetch(httpRequest)
			.then(resp => {
				createRestTryitResponse(resp)
					.then(tryitResponse => {
						bus.publish(new RestRenderResponse(tryitResponse));
						bus.publish(new RestRunEventDone());
					})
					.then(() => {
						const form = document.querySelector('.rest-tryit-form') as HTMLElement;
						const { scrollTop } = form;
						const { height } = document.querySelector('.request-section').getBoundingClientRect();

						if (scrollTop >= height) {
							return;
						}

						scrollTo(height, 500, form);
					});
			})
			.catch(error => {
				bus.publish(new RestRunEventDone());
				throw error;
			});
	};

	// Listen for RestRunEvent event
	bus.subscribe(RestRunEvent, handleRunRequest);
}

export function submitTryitForm(
	runButton: HTMLButtonElement,
	bus: EventBus,
	security: SecurityStrategy
) {
	runButton.classList.add('is-loading');
	const requestData: RestTryItRequest = {
		url: null,
		httpVerb: null,
		headers: [],
		params: [],
		body: null
	};

	bus.publish(new RestRetrieveRequestData(requestData));

	// Report BI before sending request, but after retrieving request data.
	reportRestFetch(runButton, security.type, getReportingData(requestData));

	bus.publish(new RestRunEvent(requestData, security));
}

function getReportingData(requestData: RestTryItRequest) {
	return requestData.headers.concat(requestData.params).map(x => ({ [x.name]: !!x.value.length }));
}

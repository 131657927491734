import { azureToken } from './token';
import { tokenApi } from './token-api';

const fiveMinutesInMs = 5 * 60 * 1000;

let intervalHandle = 0;

export function initTokenRenewal() {
	document.addEventListener('visibilitychange', visibilityChanged, { passive: true } as any);
	visibilityChanged();
}

function visibilityChanged() {
	if (document.visibilityState === 'visible') {
		intervalHandle = setInterval(tryRenew, fiveMinutesInMs);
	} else {
		clearInterval(intervalHandle);
	}
}

function tryRenew() {
	if (!azureToken.value) {
		return;
	}

	tokenApi.tryLoadTokens().then(tokens => {
		const token = tokens.find(t => t.default_domain === azureToken.value.default_domain);
		if (token) {
			azureToken.value = token;
		}
	});
}

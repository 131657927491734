import { loc_xp } from '@msdocs/strings';
import {
	getUserProgress,
	getUserProgressByUids,
	GradedQuestion,
	LearnAchievement,
	LearnItem,
	Module,
	ProgressItem,
	putBatchProgress,
	putUnitProgress,
	QuizAnswer,
	StandardProgressResponse,
	TaskValidationCredential,
	TaskValidationResult,
	UnitUpdatedResponse,
	ViewUnitUpdatedResponse
} from '../apis/learn';
import { authStatusDetermined } from '../auth/index';
import { User, user } from '../auth/user';
import { features } from '../environment/features';
import { EventBus } from '../event-bus';
import { document } from '../globals';
import { getMeta } from '../meta';
import { localStorage } from '../protected-storage';
import { getCurrentModule } from './module';
import { QuizValidatedEvent } from './quiz-events';

export const learnLocalProgressKey = 'ModuleProgress';

export class RemoteValidationService implements ProgressService {
	public getProgress() {
		return getUserProgress();
	}

	public getProgressByUid(uid: string): Promise<StandardProgressResponse> {
		return getUserProgressByUids([uid]);
	}

	public completeViewUnit(unitUid: string) {
		return putUnitProgress(unitUid);
	}

	public validateQuiz(unitUid: string, answerData: QuizAnswer[]) {
		return putUnitProgress(unitUid, answerData);
	}

	public validateTask(unitUid: string, taskValidations: TaskValidationCredential[]) {
		return putUnitProgress(unitUid, taskValidations);
	}
}

export class LocalProgressService implements LocalProgressServiceInterface {
	private readonly key = learnLocalProgressKey;
	private readonly moduleUidLoaded: Promise<Module>;

	constructor(private readonly localStorage: Storage) {
		this.moduleUidLoaded = getCurrentModule().then(module => module);
	}

	public getProgress() {
		return this.moduleUidLoaded.then(module => {
			const data = this.getFromStorage();

			if (data && data.moduleUid === module.uid) {
				return convertToStandardProgress(data);
			}

			return [];
		});
	}

	public validateQuiz(unitUid: string, answerData: QuizAnswer[]) {
		return putUnitProgress(unitUid, answerData).then(quizResponse => {
			let data = this.getFromStorage() as LocalModuleProgress;

			return this.moduleUidLoaded.then(module => {
				const moduleUid = module.uid;
				const unitInfo = ({
					unitUid,
					type: 'unit',
					detail: answerData
				} as any) as LocalModuleProgressItem;
				const moduleInfo = { unitUid: module.uid, type: 'module' };
				const freshStart = { moduleUid, progress: [unitInfo] } as LocalModuleProgress;
				let updated: boolean = false;
				if (quizResponse.passed) {
					// if there's no data, start afresh
					if (!data || data.moduleUid !== moduleUid || data.progress.length === 0) {
						data = freshStart;
						updated = true;
					} else {
						// add to the list of completed units, it it isn't there
						if (data.progress.map(item => item.unitUid).indexOf(unitUid) === -1) {
							data.progress.push(unitInfo);
							updated = true;
						}
						if (
							data.progress[data.progress.length - 1].unitUid !== module.uid &&
							data.progress.length === module.units.length
						) {
							data.progress.push(moduleInfo);
						}
					}

					// store data in a format that fits that returned by remote api
					this.localStorage.setItem(this.key, JSON.stringify(data));
				}

				// return data in the same form returned by a PUT request
				const details = quizResponse.details as GradedQuestion[];
				return convertToQuizUpdatedResponse(
					answerData as any,
					details,
					quizResponse.passed,
					updated
				);
			});
		});
	}

	public completeViewUnit(unitUid: string) {
		let data = this.getFromStorage() as LocalModuleProgress;

		return this.moduleUidLoaded.then(module => {
			const moduleUid = module.uid;
			const unitInfo = { unitUid, type: 'unit' };
			const moduleInfo = { unitUid: module.uid, type: 'module' };
			const freshStart = { moduleUid, progress: [unitInfo] } as LocalModuleProgress;
			let updated: boolean = false;

			// if there's no data, start afresh
			if (!data || data.moduleUid !== moduleUid || data.progress.length === 0) {
				data = freshStart;
				updated = true;
			} else {
				// add to the list of completed units, it it isn't there
				if (data.progress.map(item => item.unitUid).indexOf(unitUid) === -1) {
					data.progress.push(unitInfo);
					updated = true;
				}
				if (
					data.progress[data.progress.length - 1].unitUid !== module.uid &&
					data.progress.length === module.units.length
				) {
					data.progress.push(moduleInfo);
				}
			}

			// store data in a format that fits that returned by remote api
			this.localStorage.setItem(this.key, JSON.stringify(data));

			// return data in the same form returned by a PUT request
			return convertToViewCompleteResponse(unitUid, updated);
		});
	}

	private getFromStorage(): LocalModuleProgress | null {
		{
			const serialized = this.localStorage.getItem(this.key);
			if (serialized === null) {
				return null;
			}
			let data: any = null;
			try {
				data = JSON.parse(serialized);
			} catch (e) {
				// corrupt.
				// this.localStorage.removeItem(this.key);
			}
			return data;
		}
	}
}

export class UnitProgressCheckedEvent {
	constructor(
		public readonly passed: boolean,
		public readonly updated: boolean,
		public readonly details: GradedQuestion[] | TaskValidationResult[],
		public readonly unit: LearnItemProgress,
		public readonly module: LearnItemProgress,
		public readonly paths: LearnItemProgress[],
		public readonly achievements: LearnAchievement[],
		public readonly standardProgress: StandardProgressResponse,
		public readonly moduleComplete: boolean,
		public readonly unitComplete: boolean,
		public readonly unitsComplete: number,
		public readonly firstIncompleteUnit: LearnItem | null,
		public readonly totalPoints: number
	) {
		this.standardProgress = standardProgress;
		this.updated = updated;
	}
}

export class CombinedProgressService implements ProgressService {
	private readonly remote: ProgressService;
	private readonly local: LocalProgressServiceInterface;

	constructor(private readonly user: User, private readonly bus: EventBus) {
		this.remote = new RemoteValidationService();
		this.local = new LocalProgressService(localStorage);
	}

	public async getProgress(): Promise<StandardProgressResponse> {
		await authStatusDetermined;

		return this.user.isAuthenticated && this.remote
			? this.remote.getProgress()
			: this.local.getProgress();
	}

	public async getProgressByUid(uid: string): Promise<StandardProgressResponse> {
		await authStatusDetermined;

		return this.user.isAuthenticated && this.remote
			? this.remote.getProgressByUid(uid)
			: this.local.getProgress();
	}

	public getCurrentUnitProgress(uid: string): Promise<UnitUpdatedResponse> {
		// ! updated is always false, because this only gets called for quiz and arm-task
		const response = convertToDummyUnitProgressResponse(uid, false);
		return this.processUnitProgress(uid, response);
	}

	public async completeViewUnit(unitUid: string): Promise<ViewUnitUpdatedResponse> {
		await authStatusDetermined;

		const response =
			this.user.isAuthenticated && this.remote
				? await this.remote.completeViewUnit(unitUid)
				: await this.local.completeViewUnit(unitUid);

		await this.processUnitProgress(unitUid, response as UnitUpdatedResponse);

		return response;
	}

	public async validateQuiz(unitUid: string, answerData: QuizAnswer[]) {
		await authStatusDetermined;

		const response =
			this.user.isAuthenticated && this.remote
				? await this.remote.validateQuiz(unitUid, answerData)
				: await this.local.validateQuiz(unitUid, answerData);
		// raise event to notify quiz of validation response.
		this.bus.publish(new QuizValidatedEvent(response));

		await this.processUnitProgress(unitUid, response);
		return response;
	}

	public async validateTask(unitUid: string, taskValidations: TaskValidationCredential[]) {
		await authStatusDetermined;
		const response = await this.remote.validateTask(unitUid, taskValidations);
		const validateMessage = document.getElementById('task-validation-message') as HTMLElement;
		validateMessage.classList.remove('is-hidden');

		await this.processUnitProgress(unitUid, response);

		return response;
	}

	private async processUnitProgress(
		uid: string,
		response: UnitUpdatedResponse
	): Promise<UnitProgressCheckedEvent> {
		// * Get the parent module.
		const module = await getCurrentModule();

		// * Get progress for the associated learning path(s), module and unit.
		const uids = module.parents.length ? module.parents.map(p => p.uid) : [module.uid];
		const progress = user.isAuthenticated
			? await getUserProgressByUids(uids)
			: await this.local.getProgress();

		// * Get the unit
		const unit = module.units.find(u => u.uid === uid);

		// * Get the unit's progress.
		const unitProgress =
			progress.find(p => p.type === 'unit' && p.uid === uid) ||
			convertToLearnItemsToProgressItems([unit])[0];

		// * Get the module's progress.
		const moduleProgress = progress.find(p => p.type === 'module' && p.uid === module.uid);

		// * Progress for all units in only this module
		const unitProgressInCurrentModule = user.isAuthenticated
			? convertToLearnItemsToProgressItems(module.units)
			: progress;

		// * See if this unit is complete
		const unitComplete = user.isAuthenticated
			? unitProgress && unitProgress.status === 'completed'
			: unitProgressInCurrentModule.filter(u => u.uid === unit.uid).length === 1;

		// * Progress for all units in all parents
		const unitsComplete =
			unitProgressInCurrentModule.filter(u => u.type === 'unit' && u.status === 'completed')
				.length || 0;

		// * The first incomplete unit URL in this module
		const firstIncompleteUnit = filterProgressForFirstIncomplete(
			module,
			unitProgressInCurrentModule
		);

		// * Use number of complete units in module to determine if progress is finished
		const progressComplete = unitsComplete === module.units.length;

		// * If authenticated we can use the module's api progress, otherwise we use the low tech local storage way generated above
		const moduleComplete =
			user.isAuthenticated && moduleProgress
				? moduleProgress.status === 'completed'
				: progressComplete;

		// * Get the learning path(s) and their progress.
		const paths = module.parents.map(path => ({
			item: path,
			progress: user.isAuthenticated
				? progress.find(p => p.type === 'learningPath' && p.uid === path.uid)
				: null
		}));

		// * Both local and remove responses return an accurate updated event, so pass it along
		const updated = response.updated;

		const achievements = response.achievements || [];
		const totalPoints = calculateTotalPoints(achievements);

		// Fire the event.
		const event = new UnitProgressCheckedEvent(
			response.passed,
			updated,
			response.details || [],
			{ item: unit, progress: unitProgress },
			{ item: module, progress: moduleProgress },
			paths,
			achievements,
			progress,
			moduleComplete,
			unitComplete,
			unitsComplete,
			firstIncompleteUnit,
			totalPoints
		);

		this.bus.publish(event);

		return event;
	}
}

export interface LocalModuleProgress {
	moduleUid: string;
	progress: LocalModuleProgressItem[];
}

export interface LocalModuleProgressItem {
	unitUid: string;
	type: string;
	detail?: GradedQuestion[];
}

export interface LocalProgressServiceInterface {
	getProgress(): Promise<StandardProgressResponse>;
	completeViewUnit(unitUid: string): Promise<ViewUnitUpdatedResponse>;
	validateQuiz(uid: string, answerData: QuizAnswer[]): Promise<UnitUpdatedResponse>;
}

export interface ProgressService {
	getProgress(): Promise<StandardProgressResponse>;
	completeViewUnit(unitUid: string): Promise<ViewUnitUpdatedResponse>;
	validateQuiz(uid: string, answerData: QuizAnswer[]): Promise<UnitUpdatedResponse>;
	validateTask(
		unitUid: string,
		taskValidations: TaskValidationCredential[]
	): Promise<UnitUpdatedResponse>;
	getProgressByUid(unitUid: string): Promise<StandardProgressResponse>;
}

export type UnitCompletionType = 'view' | 'quiz' | 'arm-task'; // not finalized values

export function renderStartContinueUnitButton(
	insertAfter: Element,
	url: string,
	text: string,
	biValue: string
) {
	if (!insertAfter) {
		return;
	}

	const renderToPathSummary =
		insertAfter.hasAttribute('id') && insertAfter.getAttribute('id') === 'learning-path-actions';

	const biAttr = biValue ? `data-bi-name="${biValue}"` : '';
	const btnId = renderToPathSummary ? 'start-path' : 'start-unit';
	removeStartUnitButton();

	const buttonHtml = `<a href="${url}" id="${btnId}" class="button is-primary centered-with-icon is-hidden-mobile" ${biAttr}><span>${text}</span><span class="icon docon docon-chevron-right-light" aria-hidden="true"></span></a>`;

	if (
		(getMeta('page_type') === 'learn' && getMeta('page_kind') === 'module') ||
		renderToPathSummary
	) {
		insertAfter.insertAdjacentHTML('afterbegin', buttonHtml);
	} else {
		const buttonWrapperHtml = `<p class="is-hidden-mobile has-margin-bottom-medium">${buttonHtml}</p>`;
		insertAfter.insertAdjacentHTML('afterend', buttonWrapperHtml);
	}

	const startUnitMobile = document.getElementById('start-unit-mobile') as HTMLAnchorElement;
	if (startUnitMobile) {
		startUnitMobile.href = url;
		const startUnitMobileText = startUnitMobile.children[0] as HTMLSpanElement;
		if (startUnitMobileText) {
			startUnitMobileText.textContent = text;
		}
	}
}

export function removeStartUnitButton(
	removeMobile: boolean = false,
	removeFromPathSummary: boolean = false
) {
	const startUnit = document.getElementById('start-unit');
	if (startUnit) {
		startUnit.parentElement.remove();
	}

	if (removeFromPathSummary) {
		const startPath = document.getElementById('start-path');
		if (startPath) {
			startPath.remove();
		}
	}

	if (removeMobile) {
		const startUnitMobile = document.getElementById('start-unit-mobile') as HTMLAnchorElement;
		if (startUnitMobile) {
			startUnitMobile.parentElement.remove();
		}
	}
}

export function convertToLearnItemsToProgressItems(items: LearnItem[]): ProgressItem[] {
	// takes local progress as we store it, and coerces the shape to fit remote
	return items.map(item => {
		return {
			uid: item.uid,
			status: item.status,
			type: item.type,
			remainingTime: item.remainingTime
		} as ProgressItem;
	});
}

export function convertToStandardProgress(
	localProgress: LocalModuleProgress
): StandardProgressResponse {
	// takes local progress as we store it, and coerces the shape to fit remote
	return localProgress.progress.map(item => {
		return {
			uid: item.unitUid,
			status: 'completed',
			type: item.type,
			remainingTime: 0
		} as ProgressItem;
	});
}

function convertToDummyUnitProgressResponse(
	unitUid: string,
	updated: boolean
): UnitUpdatedResponse {
	return {
		updated,
		passed: false,
		achievements: [
			{
				uid: unitUid,
				type: 'unit',
				points: []
			}
		],
		details: []
	};
}

function convertToViewCompleteResponse(unitUid: string, updated: boolean): ViewUnitUpdatedResponse {
	return {
		updated,
		passed: true,
		achievements: [
			{
				uid: unitUid,
				type: 'unit',
				points: []
			}
		]
	};
}

function convertToQuizUpdatedResponse(
	gradedQuestions: GradedQuestion[],
	correctQuestions: GradedQuestion[],
	passed: boolean,
	updated: boolean
): UnitUpdatedResponse {
	return {
		updated,
		passed,
		achievements: [],
		details: gradedQuestions,
		answers: correctQuestions
	};
}

export function handleXpTag(
	xpTags: HTMLElement[],
	learnItems: LearnItem[],
	totalUid: string = null
) {
	if (!features.gamification) {
		xpTags.forEach(tag => (tag.hidden = true));
		return;
	}

	const lookup = createPointsLookup(learnItems);
	let totalPoints: number = 0;
	for (const uid in lookup) {
		if (uid !== totalUid) {
			totalPoints += lookup[uid].points;
		}
	}

	xpTags.forEach(tag => {
		const uid = tag.dataset.progressUid;
		const xp = tag.querySelector('.xp-tag-xp');
		if (uid in lookup) {
			if (lookup[uid].points) {
				if (uid === totalUid) {
					xp.textContent = loc_xp.replace('{totalXP}', totalPoints.toString());
				} else {
					xp.textContent = loc_xp.replace('{totalXP}', lookup[uid].points.toString());
				}
				tag.classList.remove('is-hidden');
			}
			if (lookup[uid].status === 'completed') {
				// if this unit's completed, mark it as such
				tag.classList.add('is-complete');
			}
		}
	});
}

export function createProgressLookup(array: ProgressItem[]) {
	const initialValue: { [uid: string]: boolean } = {};
	return array.reduce((lookup, item) => {
		if (item.status === 'completed') {
			lookup[item.uid] = true;
		}
		return lookup;
	}, initialValue);
}

export function createPointsLookup(array: LearnItem[]) {
	const initialValue: { [uid: string]: { points: number; status: string } } = {};
	return array.reduce((lookup, item) => {
		lookup[item.uid] = { points: item.points, status: item.status };
		return lookup;
	}, initialValue);
}

export function updateProgressElements(progress: StandardProgressResponse): HTMLElement[] {
	if (!features.gamification) {
		return [];
	}

	const progressElements = Array.from(
		document.querySelectorAll('[data-progress-uid]')
	) as HTMLElement[];

	// use a data-progress-uid selector to get elements
	const lookup = createProgressLookup(progress);
	progressElements.forEach(elt => {
		const uid = elt.dataset.progressUid;
		if (lookup[uid]) {
			elt.classList.add('is-complete');
		}
	});
	return progressElements;
}

export interface LearnItemProgress {
	item: LearnItem;
	progress: ProgressItem;
}

export async function syncUserProgress(): Promise<void> {
	if (!features.gamification) {
		return;
	}

	await authStatusDetermined;

	const data = JSON.parse(localStorage.getItem(learnLocalProgressKey)) as LocalModuleProgress;

	if (!(getMeta('page_type') === 'learn') || !user.isAuthenticated || !data || !data.progress) {
		return;
	}

	const sync = data.progress.reduce((state, current) => {
		state[current.unitUid] = current.detail || {};
		return state;
	}, {} as { [uid: string]: any });

	await putBatchProgress(sync);

	localStorage.removeItem(learnLocalProgressKey);
}

export function filterProgressForFirstIncomplete(
	module: Module,
	unitsInModuleProgress: ProgressItem[]
): LearnItem | null {
	// ! note: the back end sometime lags behind the user's state, meaning sometimes completed units are not always marked as true by this time
	if (user.isAuthenticated) {
		return module.units.filter(u => u.status !== 'completed')[0];
	}

	let firstIncompleteUnit;

	const lookup = unitsInModuleProgress.reduce((lookup, unit) => {
		lookup[unit.uid] = true;
		return lookup;
	}, {} as { [uid: string]: true });

	for (const unit of module.units) {
		if (!(unit.uid in lookup)) {
			firstIncompleteUnit = unit;
			break;
		}
	}

	return firstIncompleteUnit;
}

function calculateTotalPoints(achievements: LearnAchievement[]): number {
	//quiz completion progress results return an array of achievements, we tally them up to ensure the user
	//gets credit for bonus points, such as completing a quiz in one try
	if (!achievements.length) {
		return 0;
	} else {
		return achievements
			.map(x => {
				return x.points.map(x => x.value).reduce((state, current) => state + current, 0);
			})
			.reduce((state, current) => state + current, 0);
	}
}

import { loc_filter, loc_findAFacetFormat, loc_loading } from '@msdocs/strings';
import { html, repeat, DirectiveFn } from '../../../lit-html';
import { TaxonomyLabel, taxonomyParentNamesSingular } from '../../../name-maps/taxonomy';
import { forceWrite } from '../../../plugins/lit-html/force-write';
import { debounce } from '../../../scheduling';
import { FacetGroupViewModel } from '../facet-group';
import { FacetViewModel } from '../facets';
import { SearchViewModel } from '../view-model';
import { createExpander } from './expander';
import { clearFilterInputTemplate } from './filter';
import { categoryFacetTemplate } from './category';
import { clearAllFilterButton } from './clear-filters';
import { classMap } from 'lit-html/directives/class-map';
import { hideCompletedTemplate } from './hide-completed';
import { TemplateResult } from 'lit-html';

const filtersTitleClases = {
	title: true,
	'is-3': true,
	'has-margin-bottom-small': true
};

const facetTitleClasses = {
	title: true,
	'is-5': true,
	'has-margin-bottom-medium': true
};

export function facetSidebarDesktopTemplate<TResult>(vm: SearchViewModel<TResult>) {
	return vm.facetsEnabled
		? html`
				<div class="${getSidebarColumnClasses()}">
					<h2 class="${classMap(filtersTitleClases)}">${loc_filter}</h2>
					${clearAllFilterButton(vm)} ${hideCompletedTemplate(vm)}
					<hr class="hr has-margin-top-medium has-margin-bottom-medium" />
					${facetGroupDesktopTemplate(vm)}
				</div>
		  `
		: '';
}

export function facetGroupDesktopTemplate<TResult>(vm: SearchViewModel<TResult>) {
	const labels = Object.keys(vm.facetGroups);
	const singleFacet = labels.length === 1;

	return repeat(
		labels,
		label => label,
		label => facetGroupTemplate(vm.facetGroups[label as TaxonomyLabel], false, !singleFacet)
	);
}

export function facetGroupTemplate(
	vm: FacetGroupViewModel,
	isMobile: boolean,
	constrainHeight?: boolean
) {
	if (vm.isEmpty) {
		return '';
	}

	const heightClasses = constrainHeight ? 'is-vertically-scrollable has-max-height-260' : '';

	// Content categories do no function like normal facets
	if (vm.key === 'category') {
		return html`
			<div class="has-margin-bottom-medium has-border-bottom">
				<div class="${heightClasses}">
					<h3 class="${classMap(facetTitleClasses)}">${vm.name}</h3>
					${categoryFacetTemplate(vm, isMobile ? 'mobile' : '')}
				</div>
			</div>
		`;
	}

	return html`
		<div class="has-margin-bottom-medium has-border-bottom">
			<h3 class="${classMap(facetTitleClasses)}">${vm.name}</h3>
			${facetFilterInputTemplate(vm)}
			<div class="${heightClasses}">
				<ul class=" has-padding-bottom-medium">
					${clearFilterInputTemplate(vm)} ${facetListItemTemplate(vm.facets)}
				</ul>
			</div>
		</div>
	`;
}

function facetFilterInputTemplate(vm: FacetGroupViewModel): TemplateResult | '' {
	if (!vm.hasFilterInput && !vm.filter) {
		return '';
	}
	const placeholder = loc_findAFacetFormat.replace(
		'{facet}',
		taxonomyParentNamesSingular[vm.key].toLowerCase()
	);

	return html`
		<input
			class="input has-margin-bottom-small"
			type="text"
			@input=${debounce.timeout((e: Event) => vm.handleFilterInput(e))}
			placeholder=${placeholder}
			.value=${vm.filter}
		/>
	`;
}

export function facetListItemTemplate(items: FacetViewModel[], isBranch = false): DirectiveFn {
	const isTree = isBranch || !!items.find(item => item.hasChildren);
	return repeat(
		items,
		item => item.value,
		item => facetTemplate(item, items.length, isTree)
	);
}

function facetTemplate(fvm: FacetViewModel, setSize: number, isTree: boolean) {
	const expander = createExpander(fvm, isTree);

	return html` <li
		class="is-flex has-padding-top-small has-margin-left-small ${fvm.hasChildren
			? 'has-flex-wrap'
			: ''}"
		aria-posinset="${fvm.position}"
		aria-setsize="${setSize}"
		aria-level="${fvm.level}"
		?hidden=${fvm.hidden}
	>
		${expander}

		<label class="checkbox ${fvm.inputClass}">
			<input
				.checked=${forceWrite(fvm.isChecked)}
				id="${fvm.value}"
				name="${fvm.value}"
				value="${fvm.value}"
				type="checkbox"
				data-bi-name="facet"
				@change=${() => fvm.toggleSelected()}
			/>
			<span class="checkbox-check" role="presentation"></span>
			<span class="checkbox-text">${fvm.name}</span>
		</label>

		${fvm.hasChildren
			? html`<ul
					id="${fvm.value}-child-list"
					class="has-padding-left-large is-full-width"
					aria-setsize="${fvm.children.length}"
			  >
					${facetListItemTemplate(fvm.children, isTree)}
			  </ul>`
			: null}
	</li>`;
}

export async function facetLoadingTemplate(isBlank: boolean) {
	if (isBlank) {
		return '';
	}
	return html`
		<div class="has-loading-skeleton ${getSidebarColumnClasses()}" aria-label="${loc_loading}">
			<div aria-hidden="true">
				<h2 class="${classMap(filtersTitleClases)}">${loc_filter}</h2>
				<hr class="hr has-margin-top-medium has-margin-bottom-medium" />
				<h2 class="${classMap(facetTitleClasses)}"></h2>
				<p class="is-full-width"></p>
				<p class="is-full-width"></p>
				<p class="is-full-width"></p>
				<p class="is-full-width"></p>
			</div>
		</div>
	`;
}

function getSidebarColumnClasses() {
	return `column is-hidden-mobile is-two-fifths-tablet is-one-quarter-widescreen`;
}

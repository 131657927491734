export const enum WalkInstruction {
	/** Stop walking entirely. */
	Break = 0,
	/** Continue walking. */
	Continue = 1,
	/** Don't drill any deeper into the current node. */
	StepOut = 2
}

/**
 * Traverse a TOC or breadcrumb data structure in depth-first order.
 * @param nodes The nodes to walk.
 * @param callback A callback that will be invoked with each visited node. Return false to stop traversal.
 */
export function walk<T extends { children?: T[] }>(
	nodes: T[],
	callback: (node: T, ancestors: readonly T[]) => boolean | WalkInstruction,
	depth = 0,
	ancestors: T[] = []
): WalkInstruction {
	for (const node of nodes) {
		ancestors.length = depth;
		const instruction = callback(node, ancestors);
		if (!instruction) {
			return WalkInstruction.Break;
		}
		if (instruction === WalkInstruction.StepOut) {
			continue;
		}
		ancestors[depth] = node;
		if (node.children && !walk(node.children, callback, depth + 1, ancestors)) {
			return WalkInstruction.Break;
		}
	}
	return WalkInstruction.Continue;
}

import { escapeRegExp } from '../text-formatting';

export function profileListPage() {
	const filter = document.querySelector('.filter-list') as HTMLInputElement;

	if (!filter) {
		return;
	}

	const profilesRaw = Array.from(document.querySelectorAll('.profile-component')) as HTMLElement[];

	let timeout = 0;

	const handleSearch = () => {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			searchToFilter(escapeRegExp(filter.value), profilesRaw);
		}, 250);
	};

	filter.addEventListener('input', handleSearch);
	filter.addEventListener('change', handleSearch);
}

export function searchToFilter(searchTerm: string, arr: HTMLElement[]) {
	const noResults = document.querySelector('.no-results') as HTMLLIElement;
	const regex = new RegExp(searchTerm, 'gi');

	noResults.hidden = true;

	const filtered = arr.filter(el => {
		el.hidden = !el.textContent.match(regex);
		if (!el.hidden) {
			return el;
		}
		return false;
	});

	if (filtered.length === 0) {
		noResults.hidden = false;
	}
	return filtered;
}

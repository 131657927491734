import { createRequest, fetchWithTimeout } from '../fetch';
import { msDocs } from '../globals';
import { parseQueryString, toQueryString } from '../query-string';

interface Recommendation {
	url: string;
	recommendedTitle: string;
	recommendedUrl: string;
}

let recommendations: Promise<Recommendation[]>;

export function getRecommendations(): Promise<Recommendation[]> {
	if (recommendations === undefined) {
		let url;

		if (location.hostname === 'localhost') {
			const { branch } = parseQueryString();
			const queryString = branch ? '?' + toQueryString({ branch }) : '';
			const docsetBase = location.pathname.split('/').slice(0, 3).join('/');
			url = `${docsetBase}/recommendations/recommendations.json${queryString}`;
		} else {
			url = `${location.origin}/${msDocs.data.contentLocale}/recommendations/recommendations.json?branch=master`;
		}

		recommendations = fetchWithTimeout(createRequest(url, { credentials: 'include' })).then(
			response => {
				if (response.ok) {
					return response.json();
				}
				throw new Error(`${response.status}: ${response.statusText}`);
			}
		);
	}

	return recommendations;
}

import {
	loc_badgeEarnedFor,
	loc_close,
	loc_congratulations,
	loc_continueToNextModule,
	loc_email,
	loc_exploreOtherModules,
	loc_exploreOtherPaths,
	loc_facebook,
	loc_linkedin,
	loc_reviewYourLearnHistory,
	loc_shareYourAchievement,
	loc_signInToSaveProgress,
	loc_trophyEarnedFor,
	loc_twitter,
	loc_xp,
	loc_youDidIt
} from '@msdocs/strings';
import { html, render, TemplateResult } from '../lit-html';
import { LearningPath, ModuleOrPath } from '../apis/learn';
import { signInClassName } from '../auth/sign-in-links';
import { user } from '../auth/user';
import { Modal } from '../components/modal/modal';
import { addEnUsLocally } from '../content-adjustments/modify-path';
import { msDocs } from '../globals';
import { createSignInReferralUrl, signInAfterLearnCompleteArgName } from './auth-referral';
import { UnitProgressConfig } from './config';
import {
	LearnActionAnchor,
	LearnActionLinks,
	LearnModalNavigationInfo,
	LearnModalNavigationMap,
	LearnModalNavigationType
} from './navigation-types';
import { getFirstIncompleteModuleUrl } from './utilities';

export function buildModuleCompleteModal(
	unitUpdateConfig: UnitProgressConfig,
	learningPath?: LearningPath
) {
	const { module, singlePathComplete } = unitUpdateConfig;
	const modalContent = document.createElement('div');

	const useLearningPathInformation = singlePathComplete && learningPath;
	const imageSrc = useLearningPathInformation
		? addEnUsLocally(learningPath.achievement.iconUrl)
		: addEnUsLocally(module.achievement.iconUrl);
	const uid = useLearningPathInformation ? learningPath.uid : module.uid;
	const achievementTitle = useLearningPathInformation ? learningPath.title : module.title;
	const completeText = useLearningPathInformation ? loc_trophyEarnedFor : loc_badgeEarnedFor;
	const completeTitle = useLearningPathInformation ? loc_congratulations : loc_youDidIt;
	const moduleMiniBadgeHTML = useLearningPathInformation
		? html`<img class="module-badge" src="${module.achievement.iconUrl}" />`
		: null;

	render(
		html`
			<div
				class="modal-card modal-completed fill-mobile has-border"
				aria-describedby="modalText"
				aria-labelledby="modal-title"
				data-bi-name="module-complete-card"
			>
				<div class="modal-card-content">
					<button
						class="modal-close modal-card-close-button is-large has-text-primary-invert is-sticky"
						data-bi-name="close"
						aria-label="${loc_close}"
					></button>
					<div class="modal-slide-container">
						<div class="modal-slide">
							<section
								class="module-completed-hero has-background-primary is-flex has-flex-justify-content-center has-padding-top-extra-large"
							>
								<div class="animation-holder is-relative has-margin-bottom-large">
									${moduleMiniBadgeHTML}
									<img
										id="achievement-src"
										class="achievement-image"
										src="${imageSrc}"
										aria-hidden="true"
									/>
									<div
										id="achievement-uid"
										data-progress-uid="${uid}"
										class="xp-tag is-absolute is-complete"
									>
										<div class="xp-tag-hexagon">
											<span class="xp-tag-icon is-shown-complete docon docon-check"></span>
											<span aria-hidden="true" class="xp-tag-xp"
												>${loc_xp.replace('{totalXP}', module.points.toString())}</span
											>
										</div>
									</div>
								</div>
							</section>
							<section
								class="modal-card-body has-padding-top-none has-flex-justify-content-center has-text-centered has-padding-top-large"
							>
								<p id="modal-title" class="title is-2 has-margin-bottom-medium">${completeTitle}</p>
								<p id="modal-text">${completeText}</p>
								<p id="achievement-title" class="title is-4 has-margin-bottom-medium">
									${achievementTitle}
								</p>
								<div class="continue-action">
									${createModalNextStepHTML(unitUpdateConfig, learningPath)}
									${createSignInNudgeHTML()}
								</div>
								<div
									class="rating-container has-padding-top-medium has-padding-bottom-extra-large"
								></div>
							</section>
						</div>
					</div>
					<div
						class="modal-card-foot is-sticky has-flex-justify-content-center has-background-transparent"
					>
						<div data-bi-name="share-links">
							<p class="has-margin-none has-text-weight-semibold is-inline-desktop">
								${loc_shareYourAchievement}
							</p>
							<div class="has-margin-none is-text-centered is-inline-desktop">
								<a
									title="${loc_facebook}"
									class="share-facebook is-undecorated is-inline-block has-padding-small has-padding-none-tablet"
									data-bi-name="facebook"
								>
									<span
										class="has-text-subtle icon docon docon-brand-facebook"
										aria-hidden="true"
									></span>
								</a>
								<a
									title="${loc_twitter}"
									class="share-twitter is-undecorated is-inline-block has-padding-small has-padding-none-tablet"
									data-bi-name="twitter"
								>
									<span
										class="has-text-subtle icon docon docon-brand-twitter"
										aria-hidden="true"
									></span>
								</a>
								<a
									title="${loc_linkedin}"
									class="share-linkedin is-undecorated is-inline-block has-padding-small has-padding-none-tablet"
									data-bi-name="linkedin"
								>
									<span
										class="has-text-subtle icon docon docon-brand-linkedin"
										aria-hidden="true"
									></span>
								</a>
								<a
									title="${loc_email}"
									class="share-email is-undecorated is-inline-block has-padding-small has-padding-none-tablet"
									data-bi-name="email"
								>
									<span
										class="has-text-subtle icon docon docon-mail-message-fill"
										aria-hidden="true"
									></span>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		`,
		modalContent
	);

	return new Modal(modalContent);
}

function createSignInNudgeHTML() {
	if (!user.isAuthenticated) {
		return html`<p class="not-authenticated">
			<a
				class="is-fullwidth-mobile is-size-small button is-text ${signInClassName}"
				href="${createSignInReferralUrl(signInAfterLearnCompleteArgName)}"
				autofocus
				>${loc_signInToSaveProgress}</a
			>
		</p>`;
	}
	return null;
}

export function createModalNextStepHTML(
	unitUpdateConfig: UnitProgressConfig,
	learningPath?: LearningPath
): TemplateResult {
	const actionType = getLearnModalNavigationType(unitUpdateConfig);
	const actionInfo = getLearnModalNavigationInfo(actionType, unitUpdateConfig, learningPath);
	return convertModalActionToHTML(actionInfo);
}

export function convertModalActionToHTML(actionInfo: LearnModalNavigationInfo): TemplateResult {
	if (!actionInfo) {
		return null;
	}

	if (actionInfo.elementType === 'a') {
		return createModalActionAnchorHTML(actionInfo);
	} else if (actionInfo.elementType === 'ul') {
		return createModalActionLinksHTML(actionInfo as LearnActionLinks);
	}

	return null;
}

/**
 * Get the type of action to be taken, base on the config and learning path that were generated upstream (see continue-links.ts)
 *
 * @param unitProgressConfig Configuration object created when we get progress
 * @param learningPath Learning Path information that is generated after the in-page 'modal-complete' info generation
 */
export function getLearnModalNavigationType(
	unitProgressConfig: UnitProgressConfig
): LearnModalNavigationType {
	const { oneParentPath, multipleParentPaths, singlePathComplete } = unitProgressConfig;

	if (oneParentPath) {
		if (singlePathComplete) {
			return 'next-step-single-path-complete';
		}

		return 'next-step-single-path-incomplete';
	}

	if (multipleParentPaths) {
		return 'next-step-multiple-parent-paths';
	}

	// * if (zeroParentPaths)
	return 'next-step-orphan-module';
}

/**
 * Creates structured object for use in the functions that render HTML in the modal.
 *
 * @param type The navigation action the user takes in the modal. Created by getInModalLearnNavigationAction
 * @param unitUpdateConfig Configuration object created when we get progress
 * @param learningPath [optional] Learning Path information that is generated after the in-page 'modal-complete' info generation
 */
export function getLearnModalNavigationInfo<T extends LearnModalNavigationType>(
	type: T,
	unitUpdateConfig: UnitProgressConfig,
	learningPath?: LearningPath
): LearnModalNavigationMap[T];
export function getLearnModalNavigationInfo(
	type: keyof LearnModalNavigationMap,
	unitUpdateConfig: UnitProgressConfig,
	learningPath?: LearningPath
): LearnModalNavigationMap[keyof LearnModalNavigationMap] {
	// https://github.com/microsoft/TypeScript/issues/31904
	const { module } = unitUpdateConfig;

	switch (type) {
		case 'next-step-single-path-complete':
			return {
				biName: 'continue',
				doconClass: 'docon-chevron-right-light',
				elementType: 'a',
				href: learningPath.url,
				text: loc_reviewYourLearnHistory
			};
		case 'next-step-single-path-incomplete':
			return {
				biName: 'continue',
				doconClass: 'docon-chevron-right-light',
				elementType: 'a',
				href: getFirstIncompleteModuleUrl(learningPath, module),
				text: loc_continueToNextModule
			};
		case 'next-step-orphan-module':
			return {
				biName: 'continue',
				doconClass: 'docon-chevron-right-light',
				elementType: 'a',
				href: `/${msDocs.data.userLocale}/learn/browse`,
				text: loc_exploreOtherModules
			};
		case 'next-step-multiple-parent-paths':
			return {
				elementType: 'ul',
				items: module.parents,
				sectionTitle: loc_exploreOtherPaths
			};
		default:
			throw new Error('Unexpected modal navigation type: ' + type);
	}
}

export function createModalActionAnchorHTML(info: LearnActionAnchor): TemplateResult {
	const { sectionTitle, doconClass, text, biName, href } = info;

	return html` ${sectionTitle ? `<p class="has-margin-top-none">${sectionTitle}</p>` : ''}
		<p>
			<a
				href="${href}"
				class="button is-primary is-fullwidth-mobile is-large-mobile has-margin-bottom-small has-margin-top-none has-content-margin-left-none has-content-margin-right-none"
				data-bi-name="${biName}"
				autofocus
			>
				<span>${text} </span>
				${doconClass ? html`<span class="icon docon ${doconClass}" aria-hidden="true"></span>` : ''}
			</a>
		</p>`;
}

export function createModalActionLinksHTML(info: LearnActionLinks): TemplateResult {
	const linkHtml = info.items.map(
		item => html` <li class="is-unstyled">
			<a href="/${msDocs.data.userLocale}${item.url}" autofocus>${item.title}</a>
		</li>`
	);

	return html`<p class="has-margin-top-none">${info.sectionTitle}</p>
		<ul
			class="is-unstyled has-margin-top-none has-margin-right-none has-margin-left-none has-margin-bottom-small"
		>
			${linkHtml}
		</ul>`;
}

export function updateModalWithPathProperties(
	modalContent: HTMLElement,
	completedItem: ModuleOrPath,
	completeTitle: string,
	completeText: string
) {
	// * only necessary for LP, or anytime when the displayed information does not belong to the module.
	const { uid, title } = completedItem;

	modalContent.querySelector<HTMLElement>('#achievement-uid').dataset.progressUid = uid;
	modalContent.querySelector<HTMLElement>('#modal-title').innerText = completeTitle;
	modalContent.querySelector<HTMLElement>('#modal-text').innerText = completeText;
	modalContent.querySelector<HTMLElement>('#achievement-title').innerText = title;

	return modalContent;
}

import { loc_requestPreview } from '@msdocs/strings';
import { restRequest } from '../apis/rest-tryit';
import { copyCodeBlockToClipboard, createCopyButtonHtml, handleCopyCode } from '../code/header';
import { syntaxHighlight } from '../code/syntax-highlighter';
import { EventBus } from '../event-bus';
import { RestParamValueChanged, RestRetrieveRequestData } from './events';
import { SecurityStrategy } from './security';
import { RestTryItRequest } from './types';

export function renderHttpRequest(
	container: HTMLElement,
	bus: EventBus,
	request: RestTryItRequest,
	security: SecurityStrategy
) {
	const blockDiv = document.createElement('div');

	const heading = document.createElement('h3');
	heading.textContent = loc_requestPreview;
	blockDiv.appendChild(heading);

	const codeHeader = document.createElement('div');
	codeHeader.classList.add('codeHeader');

	const codeHeading = document.createElement('span');
	codeHeading.classList.add('language');
	codeHeading.textContent = 'HTTP';
	codeHeader.appendChild(codeHeading);

	codeHeader.insertAdjacentHTML('beforeend', createCopyButtonHtml());
	const copyButton = codeHeader.lastElementChild as HTMLButtonElement;

	const codeBlock = document.createElement('pre');
	copyButton.onclick = event => {
		if (!handleCopyCode(copyButton)) {
			return;
		}
		copyCodeBlockToClipboard(codeBlock, 'json');
		event.preventDefault();
	};

	codeBlock.setAttribute('name', 'http-request');
	codeBlock.textContent = buildHttpRequestString(request, security);
	codeBlock.classList.add('small');
	codeBlock.setAttribute('aria-live', 'polite');
	codeBlock.setAttribute('aria-atomic', 'true');

	blockDiv.appendChild(codeHeader);
	blockDiv.appendChild(codeBlock);

	container.appendChild(blockDiv);

	syntaxHighlight([{ code: codeBlock.textContent, language: 'http', highlightLines: '' }]).then(
		results => {
			codeBlock.innerHTML = results[0].html;
		}
	);

	const handleParamValueChanged = () => {
		const requestData: RestTryItRequest = {
			url: null,
			httpVerb: null,
			headers: [],
			params: [],
			body: null
		};
		bus.publish(new RestRetrieveRequestData(requestData));

		codeBlock.textContent = buildHttpRequestString(requestData, security);

		syntaxHighlight([{ code: codeBlock.textContent, language: 'http', highlightLines: '' }]).then(
			results => {
				codeBlock.innerHTML = results[0].html;
			}
		);
	};

	// Listen for RestParamValueChanged event
	bus.subscribe(RestParamValueChanged, handleParamValueChanged);
}

function buildHttpRequestString(request: RestTryItRequest, security: SecurityStrategy) {
	const httpRequest: Request = restRequest(request, security);

	let httpRequestString = `${httpRequest.method} ${httpRequest.url}\n`;
	const headers = httpRequest.headers;
	headers.forEach((value, key) => {
		// title cases the key
		httpRequestString += `${key[0].toUpperCase()}${key.substring(1)}: ${value}\n`;
	});

	return httpRequestString;
}

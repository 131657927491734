import { EnvironmentTraits, traits } from './traits';

/**
 * Base urls for API dependencies.
 * Each constant must include origin (eg https://docs.microsoft.com/api/...)
 * Each constant must not end in "/"
 */
export interface Apis {
	apiBrowser: string;
	assessments: string;
	assessmentsBranch?: string;
	auth: {
		docs: string;
		github: string;
		identityPlatform: string;
		signOut: string;
		token: string;
	};
	browse: {
		certification: string;
		course: string;
		learn: string;
		learnHome: string;
		learnHomePersonalized: string;
		sample: string;
		lifecycle: string;
	};
	bingMaps: string;
	contentNav: string;
	forumSearch: {
		azureKbQuestionSuggestions: string;
		forumInternalQuestionSuggestions: string;
	};
	gameStatus: string;
	githubFeedback: string;
	labOnDemand: string;
	learn: {
		achievementsByUids: string;
		batchItemSummaries: string;
		learningPath: string;
		module: string;
		progress: {
			batchUnit: string;
			unit: string;
			user: string;
		};
		iltCourses: {
			scheduling: string;
			location: string;
		};
		tvSchedule: string;
	};
	lists: string;
	monikers: string;
	privacy: {
		cookieConsent: string;
		delete: string;
		partialDelete: string;
		export: string;
	};
	profile: {
		profiles: string;
		me: string;
		speakersBureauStart: string;
		qnaFollowing: string;
		acknowledgePrivacyNotice: string;
		avatar: string;
		avert: string;
		link: string;
	};
	qnaSettings: string;
	rating: string;
	sandbox: string;
	siteFeedback: string;
	siteSearch: string;
	uhf: {
		consentBanner: string;
		mooncakeFooter: string;
	};
}

const quirkMap: Record<string, Partial<Apis>> = {
	'https://review.docs.microsoft.com': {
		siteSearch: 'https://search.review.docs.microsoft.com/api/search'
	},
	'https://ppe.docs.microsoft.com': {
		siteSearch: 'https://docs-live-sitesearch-ppe.azurewebsites.net/api/search'
	},
	'http://localhost:3000': {
		siteSearch: 'https://docs-live-sitesearch-ppe.azurewebsites.net/api/search'
	}
};

export function getApis(currentOrigin: string, traits: EnvironmentTraits): Apis {
	const origin =
		traits.accessLevel === 'isolated'
			? currentOrigin
			: traits.supportLevel === 'production'
			? 'https://docs.microsoft.com'
			: 'https://ppe.docs.microsoft.com';

	const contentOrigin = traits.systemContent ? currentOrigin : origin;

	const quirks = quirkMap[currentOrigin] || {};

	return {
		apiBrowser: `${origin}/api/apibrowser`,
		assessments: `${contentOrigin}/api/assessments`,
		assessmentsBranch: `https://review.docs.microsoft.com/api/assessments/branches`,
		auth: {
			docs: `${origin}/api/profiles/auth`,
			github: `${origin}/api/githubauth`,
			identityPlatform: `https://login.microsoftonline.com/common/oauth2/v2.0/authorize`,
			signOut: `${origin}/api/profiles/signout`,
			token: 'https://token.docs.microsoft.com'
		},
		browse: {
			certification: `${origin}/api/contentbrowser/search/certifications`,
			course: `${origin}/api/contentbrowser/search/certifications/courses`,
			learn: `${origin}/api/contentbrowser/search`,
			learnHome: `${origin}/api/contentbrowser/home/learn`,
			learnHomePersonalized: `${origin}/api/contentbrowser/home/personalized/learn`,
			sample: `${origin}/api/contentbrowser/search/samples`,
			lifecycle: `${origin}/api/contentbrowser/search/lifecycles`
		},
		contentNav: contentOrigin,
		forumSearch: {
			azureKbQuestionSuggestions: `${origin}/api/qna/azurekb/searchMSDNThreads/v1.0`,
			forumInternalQuestionSuggestions: `${origin}/answers/search.json`
		},
		gameStatus: `${origin}/api/gamestatus`,
		labOnDemand: `${origin}/api/resources/labondemand`,
		learn: {
			achievementsByUids: `${origin}/api/hierarchy/achievements`,
			batchItemSummaries: `${origin}/api/hierarchy/items`,
			learningPath: `${origin}/api/hierarchy/paths`, // /${pathId}
			module: `${origin}/api/hierarchy/modules`,
			progress: {
				batchUnit: `${origin}/api/progress/units`,
				unit: `${origin}/api/progress/units`, // /${unitId}`
				user: `${origin}/api/progress`
			},
			iltCourses: {
				scheduling: `${origin}/api/wwl/ilt-scheduling/offerings`,
				location: `${origin}/api/wwl/ilt-scheduling/offerings/location`
			},
			tvSchedule: 'https://learntv.blob.core.windows.net/public/schedule.json'
		},
		lists: `${origin}/api/lists`,
		monikers:
			traits.accessLevel === 'isolated'
				? `${contentOrigin}/api/familyTrees`
				: `${contentOrigin}/_api/familyTrees`,
		privacy: {
			cookieConsent: `${contentOrigin}/api/privacy/cookieConsent`,
			delete: `${contentOrigin}/api/privacy/delete`,
			partialDelete: `${contentOrigin}/api/privacy/partialdelete`,
			export: `${contentOrigin}/api/privacy/export`
		},
		profile: {
			acknowledgePrivacyNotice: `${origin}/api/profiles/me/acknowledgePrivacyNotice`,
			avatar: `${origin}/api/profiles/me/avatar`,
			avert: `${origin}/api/profiles/avert`,
			link: `${origin}/api/profiles/link`,
			me: `${origin}/api/profiles/me`,
			profiles: `${origin}/api/profiles`,
			qnaFollowing: `${origin}/api/qna/following`,
			speakersBureauStart: `${origin}/api/speaker/accounts`
		},
		qnaSettings: `${origin}/answers/users/{qnaId}/{username}/preferences.html`,
		rating: `${origin}/api/rating`,
		sandbox: `${origin}/api/resources/sandbox`,
		siteFeedback: `${origin}/api/feedback/report`,
		siteSearch: `${origin}/api/search`,
		uhf: {
			consentBanner: `${origin}/api/GetConsentBanner`,
			mooncakeFooter: traits.reviewFeatures
				? `https://review.docs.azure.cn/zh-CN/uhf/footer.html`
				: `https://docs.azure.cn/zh-CN/uhf/footer.html`
		},
		bingMaps: 'https://www.bing.com/api/maps/mapcontrol',
		githubFeedback: 'https://api.github.com/',
		...quirks
	};
}

export const apis = getApis(location.origin, traits);

// function report() {
// 	const hostnames = [
// 		'docs.microsoft.com',
// 		'docs.azure.cn',
// 		'rd.microsoft.com',
// 		'developer.microsoft.com',
// 		'review.docs.microsoft.com',
// 		'ppe.docs.microsoft.com',
// 		// 'docs-render-dev.azurewebsites.net',
// 		// 'composition-sim1.azurewebsites.net',
// 		'docsstyleguide.z13.web.core.windows.net',
// 		'localhost'
// 	];
// 	const context: any = {};
// 	const result: Record<string, Apis> = {};
// 	for (const hostname of hostnames) {
// 		const traits = getTraitsByHostname(hostname, context);
// 		const origin = hostname === 'localhost' ? 'http://localhost:3000' : 'https://' + hostname;
// 		result[hostname] = getApis(origin, traits);
// 	}
// 	// @ts-ignore
// 	window.report = result;
// }

// report();

import { globalWindow } from './window';

export const window = globalWindow;
export const navigator = globalWindow.navigator;
export const document = globalWindow.document;
export const localStorage = globalWindow.localStorage;
export const history = globalWindow.history;
export const location = globalWindow.location;

export const $: JQueryStatic = (window as any).jQuery;
export const jQuery: JQueryStatic = (window as any).jQuery;

export const msDocs: msDocsGlobal = (window as any).msDocs;

export const contentLoaded = new Promise<void>(resolve => {
	if (document.readyState === 'loading') {
		document.addEventListener('DOMContentLoaded', () => resolve());
	} else {
		resolve();
	}
});

import {
	loc_certification,
	loc_exam,
	loc_examination,
	loc_learningpath,
	loc_module
} from '@msdocs/strings';

// * extension point for browse types

export const learnResourceTypeNames = {
	module: loc_module,
	'learning path': loc_learningpath,
	certification: loc_certification,
	examination: loc_examination
};

export const certificationResourceTypeNames = {
	certification: loc_certification,
	examination: loc_exam
};

export type CertificationResourceTypeId = keyof typeof certificationResourceTypeNames;

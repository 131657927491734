import { process401Response } from '../auth/service';
import { apis } from '../environment/apis';
import { createRequest, fetchWithTimeout } from '../fetch';
import { msDocs } from '../globals';
import { pathLocaleRegex } from '../locale';
import { indexSegmentRegex } from '../toc/url';

export type ListType = 'bookmarks' | 'collection';

/**
 * Props required to create a new list.
 */
export interface ListInit {
	/**
	 * Can be anything but the front-end is using "bookmarks" and "collection" only.
	 */
	type: ListType;

	/**
	 * The list's title. User defined for collections, "Bookmarks" for bookmarks.
	 */
	name: string;

	/**
	 * The list's description. User defined for collections.
	 * ⚠ "bookmark" type lists have a null description.
	 */
	description: string | null;
}

/**
 * A list of documents/pages on docs.microsoft.com.
 */
export interface List extends ListInit {
	/**
	 * Server generated. Used in API calls.
	 */
	id: string;

	/**
	 * The items in the list.
	 */
	items: ListItem[];

	/**
	 * The list owner.
	 */
	userId: string;

	/**
	 * The date the list was recently modified.
	 */
	lastModified: string;
}

export interface ListItemData {
	/**
	 * The pages document_id. This is a durable id that remains the same
	 * across locales and through source content renames.
	 */
	docId: string;

	/**
	 * The pages document_version_independent_id. This is a durable id that remains the same
	 * across versions, locales, and through source content renames.
	 */
	docVIId: string;

	/**
	 * The page_type metadata value.
	 */
	pageType: string;

	/**
	 * The page_kind metadata value.
	 */
	pageKind: string;

	/**
	 * The uid metadata value. This is an id used in the learn hierarchy pages.
	 */
	uid: string;

	/**
	 * A snapshot of the page's title.
	 */
	title: string;

	/**
	 * The page's abbreviated url. Limited to pathname without locale and the "view" query string argument.
	 * /dotnet/getting-started?view=netcore=3.0
	 */
	url: string;

	/**
	 * The url in the address bar, in case we need it in future iterations of the service.
	 */
	rawUrl: string;
}

/**
 * The list item's type.
 */
export type ListItemType = 'docs' | 'qa';

/**
 * Props required to create a new list item.
 */
export interface ListItemInit {
	/**
	 * The type of list item.
	 */
	type: ListItemType;

	/**
	 * The list item's data.
	 */
	data: ListItemData;
}

/**
 * The association between a list and a document/page hosted on
 * docs.microsoft.com.
 */
export interface ListItem extends ListItemInit {
	/**
	 * Server generated. Used in API calls.
	 */
	id: string;

	/**
	 * Server generated, the ID of the list item's parent list.
	 */
	listId: string;
}

export interface ListAssociation {
	/**
	 * A url used in the getAllListsByUrl API call.
	 */
	url: string;
	/**
	 * The ids of lists the url appears in
	 */
	lists: string[];
}

/**
 * Get the list item abbreviated url.
 * Limited to pathname, without locale, and the view arg.
 */
export function getListItemAbbreviatedUrl(href: string) {
	const url = new URL(href, location.origin);

	// Strip the locale (note: Q&A urls do not have a locale).
	let pathname = decodeURIComponent(url.pathname)
		.toLowerCase()
		.replace(indexSegmentRegex, '$1')
		.replace(pathLocaleRegex, '/');

	// Remove all query string args except for view.
	const view = url.searchParams.get('view');
	if (view) {
		url.search = '';
		url.searchParams.set('view', view);
		pathname += url.search;
	}
	return pathname;
}

/**
 * Update a list item to the latest spec.
 */
function normalizeListItem(item: ListItem) {
	if (item.type !== 'docs' && item.type !== 'qa') {
		item.type = 'docs';
	}
	item.data.url = getListItemAbbreviatedUrl(item.data.url);
}

/**
 * Update a list to the latest spec.
 */
function normalizeList(list: List) {
	list.items.forEach(normalizeListItem);
}

export interface BadRequestError<TCode extends string> {
	/**
	 * The error code, used to associate a code with a form field and localized error message in the docs strings.
	 */
	code: TCode;

	/**
	 * A Docs platform developer facing message. Cannot be shown to users because it's not localized.
	 */
	message: string;
}

export interface OKApiResult<T> {
	hasError: false;
	data: T;
}

export interface BadRequestApiResult<TCode extends string> {
	hasError: true;
	error: BadRequestError<TCode>;
}

export type ApiResult<T, TCode extends string> = OKApiResult<T> | BadRequestApiResult<TCode>;

export type ListErrorCode =
	| 'InvalidBody'
	| 'MissingType'
	| 'InvalidTitle'
	| 'InvalidDescription'
	| 'DuplicateTitle';
export type ListApiResult<T> = ApiResult<T, ListErrorCode>;

export const listApi = {
	baseUrl: apis.lists,
	/**
	 * Get all of the user's lists.
	 */
	async getAllLists(): Promise<List[]> {
		const request = createRequest(this.baseUrl, {});
		const response = await fetchWithTimeout(request);
		process401Response(response);
		if (response.ok) {
			const lists: List[] = await response.json();
			lists.forEach(normalizeList);
			return lists;
		}
		throw new Error(`${response.status}: ${response.statusText}`);
	},
	/**
	 * Get all of the lists a url is associated with.
	 */
	async getAllListsByUrl(urls: string[]): Promise<ListAssociation[]> {
		const newUrls = [] as string[];
		urls.forEach(url => {
			newUrls.push(getListItemAbbreviatedUrl(url));
		});
		const body = JSON.stringify(newUrls);
		const request = createRequest(this.baseUrl + '/' + 'by-url', { method: 'POST', body });
		const response = await fetchWithTimeout(request);
		process401Response(response);
		if (response.ok) {
			return await response.json();
		}

		throw new Error(`${response.status}: ${response.statusText}`);
	},
	/**
	 * Get a specific list.
	 */
	async getList(listId: string): Promise<List> {
		const requiresAuth = listId === 'bookmarks';
		const request = createRequest(this.baseUrl + '/' + listId, {}, requiresAuth);
		const response = await fetchWithTimeout(request);
		process401Response(response);
		if (response.ok) {
			const list = await response.json();
			normalizeList(list);
			return list;
		}

		if (response.status === 404) {
			return null;
		}
		throw new Error(`${response.status}: ${response.statusText}`);
	},
	/**
	 * Create a list.
	 */
	async createList(init: ListInit): Promise<ListApiResult<List>> {
		const body = JSON.stringify(init);
		const request = createRequest(this.baseUrl, { method: 'POST', body }, true);
		const response = await fetchWithTimeout(request);
		process401Response(response);
		if (response.ok) {
			const data = await response.json();
			return { hasError: false, data };
		}

		if (response.status === 400) {
			const error = await response.json();
			return { hasError: true, error };
		}
		throw new Error(`${response.status}: ${response.statusText}`);
	},
	/**
	 * Delete a list.
	 */
	async deleteList(listId: string): Promise<void> {
		const request = createRequest(this.baseUrl + '/' + listId, { method: 'DELETE' }, true);
		const response = await fetchWithTimeout(request);
		process401Response(response);
		if (response.ok) {
			return;
		}
		throw new Error(`${response.status}: ${response.statusText}`);
	},
	/**
	 * Update a list's metadata.
	 * This is artificially locked down to name and description however the API supports type
	 * and potentially even items.
	 */
	async updateListMetadata(
		listId: string,
		listMeta: { name: string; description: string }
	): Promise<ListApiResult<List>> {
		const body = JSON.stringify({ name: listMeta.name, description: listMeta.description });
		const request = createRequest(this.baseUrl + '/' + listId, { method: 'PATCH', body }, true);
		const response = await fetchWithTimeout(request);
		process401Response(response);
		if (response.ok) {
			const data: List = await response.json();
			normalizeList(data);
			return { hasError: false, data };
		}

		if (response.status === 400) {
			const error = await response.json();
			return { hasError: true, error };
		}
		throw new Error(`${response.status}: ${response.statusText}`);
	},
	/**
	 * Sort a list.
	 * @param listId The list's id.
	 * @param itemSequence An array of list item ids that represents the desired order.
	 */
	async sortList(listId: string, itemSequence: string[]): Promise<ListApiResult<List>> {
		const body = JSON.stringify(itemSequence);
		const request = createRequest(this.baseUrl + '/' + listId, { method: 'PUT', body }, true);
		const response = await fetchWithTimeout(request);
		process401Response(response);
		if (response.ok) {
			const data = await response.json();
			normalizeList(data);
			return { hasError: false, data };
		}

		if (response.status === 400) {
			const error = await response.json();
			return { hasError: true, error };
		}
		throw new Error(`${response.status}: ${response.statusText}`);
	},
	/**
	 * Append a new item to a list.
	 */
	async addItem(listId: string, init: ListItemInit): Promise<ListApiResult<ListItem>> {
		const body = JSON.stringify(init);
		const request = createRequest(this.baseUrl + '/' + listId, { method: 'POST', body }, true);
		const response = await fetchWithTimeout(request);
		process401Response(response);
		if (response.ok) {
			const data = await response.json();
			return { hasError: false, data };
		}

		if (response.status === 400) {
			const error = await response.json();
			return { hasError: true, error };
		}
		throw new Error(`${response.status}: ${response.statusText}`);
	},
	/**
	 * Remove an item from a list.
	 */
	async deleteItem(listId: string, id: string): Promise<void> {
		const request = createRequest(
			this.baseUrl + '/' + listId + '/' + id,
			{ method: 'DELETE' },
			true
		);
		const response = await fetchWithTimeout(request);
		process401Response(response);
		if (response.ok) {
			return;
		}
		throw new Error(`${response.status}: ${response.statusText}`);
	}
};

/**
 * Gets the ListItemInit for a url or document instance.
 * Intended to be called without parameters to get the init for the current page.
 * Otherwise call with a url string and the function will load that page and construct
 * list init from the page's metadata. This mode will be handy for learn/browse scenarios
 * while the hierarchy API doesn't return document_id.
 */
export async function getListItemInit(
	document: string | Document = window.document,
	origin = location.origin
): Promise<ListItemInit> {
	let url: URL;
	if (typeof document === 'string') {
		const response = await fetchWithTimeout(document, {
			credentials: 'include',
			redirect: 'follow'
		});
		if (!response.ok) {
			throw new Error(`Unexpected response status ${response.status} for ${document}`);
		}
		url = new URL(response.url || document, origin); // response.url fallback for IE.
		const html = await response.text();
		const parser = new DOMParser();
		document = parser.parseFromString(html, 'text/html');
	} else {
		url = new URL(document.URL);
	}

	const metadata = Array.from(document.querySelectorAll('meta')).reduce((map, meta) => {
		const key = meta.name || meta.getAttribute('property');
		map[key] = meta.content;
		return map;
	}, {} as Record<string, string>);

	const regex = new RegExp('^/' + msDocs.data.userLocale + '/answers[$/]', 'i');
	const type = url.pathname.match(regex) ? 'qa' : 'docs';
	const urlToString = url.toString();

	return {
		type,
		data: {
			url: getListItemAbbreviatedUrl(urlToString),
			rawUrl: urlToString,
			title: metadata['og:title'] || document.title,
			docId: metadata.document_id || '',
			docVIId: metadata.document_version_independent_id || '',
			pageType: metadata.page_type || '',
			pageKind: metadata.page_kind || '',
			uid: metadata.uid || ''
		}
	};
}

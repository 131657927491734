import { getDocsAuthToken, UserNotFoundError } from '../apis/auth';
import { apis } from '../environment/apis';
import { features } from '../environment/features';
import { createRequest, fetchWithTimeout } from '../fetch';
import { contentLoaded } from '../globals';
import { generateUuidV4 } from '../html';
import { getMeta } from '../meta';
import { safeUrl } from '../url';
import { toQueryString } from '../query-string';
import { WindowMessenger } from '../window-messenger';
import { SignInReturnUrl } from './sign-in-return-url';
import { DocsAuthTokenClaims, getProfileBaseUrl, IdTokenClaims, user } from './user';
import {
	deleteDocsToken,
	deleteIdToken,
	getDocsToken,
	getIdToken,
	InvalidClaimError,
	storeDocsToken,
	verifyToken
} from './v2/jwt';
import { Orchestrator, OrchestratorCancellationFunction } from './v2/orchestrator';

const meProfileUrl = apis.profile.me;

// This is used when a user is on the edit page and to determine whether they
// previously opted-in for MS emails
const callCpmQuery = `${meProfileUrl}?isCPMCalled=true`;

export const clientId = '18fbca16-2224-45f6-85b0-f7bf2b39b3f3';
export const registrationUrl = new URL('./register', getProfileBaseUrl()).toString();
export const redirectUrl =
	location.hostname === 'localhost'
		? 'https://localhost:3000/dev/global/sign-in.html'
		: `${location.origin}/_themes/docs.theme/master/en-us/_themes/global/sign-in.html`;
export const signOutUrlDefault = 'https://docs.microsoft.com';

export const signInArgs = {
	client_id: clientId,
	nonce: '',
	prompt: 'select_account',
	redirect_uri: redirectUrl,
	response_mode: 'fragment',
	response_type: 'id_token',
	scope: 'openid profile',
	sso_reload: true,
	state: ''
};

/**
 * Fetch user's profile information. This is only used on profile pages where
 * we want to fetch additional user information that is not stored in the Docs
 * token.
 */
export async function tryLoadUserFromProfileAPI() {
	const pathItems = location.pathname.split('/');
	const profileUrl = pathItems.indexOf('edit') !== -1 ? callCpmQuery : meProfileUrl;

	try {
		const response = await fetch(createRequest(profileUrl));

		if (response.ok && response.status !== 204) {
			// 204 no content === user is not signed in.
			const claims = await response.json();
			user.readUserProfile(claims);
		} else {
			user.setAnonymous();
		}
	} catch (error) {
		user.setAnonymous();
	}
}

/**
 * 1. Attempt to read DocsAuth JWT from localstorage.
 * 2. Attempt to verify token validity, or at least expiration.
 * 3. If DocsAuth JWT is missing or invalid (expired, etc), attempt to read id_token from localstorage.
 * 4. If id_token is missing or somehow invalid, attempt to do background sign-in using AAD.
 *
 * read docsauthtoken -> set user.
 * read docsauthtoken <> read id_token, get docsAuthToken -> readdocsauthtoken -> set user
 * read docsauthtoken <> read id_token <> get token from AAD -> read id_token, get docsAuthToken -> readdocsauthtoken -> setuser
 */
export async function tryLoadUser(
	final = () => {
		user.setAnonymous();
	}
) {
	if (!features.userServices) {
		user.setAnonymous();
		return Promise.resolve();
	}

	const orchestrator = new Orchestrator(
		[loadUserFromDocsAuthToken, loadDocsAuthTokenFromIdToken, getIdTokenFromBackgroundLogin],
		final
	);

	await orchestrator.run();
}

/**
 * Attempt to read DocsAuth JWT from localstorage and verify.
 * If DocsAuth JWT is missing or invalid (expired, etc), yield to next handler.
 */
export async function loadUserFromDocsAuthToken(
	cancel: OrchestratorCancellationFunction = () => false
) {
	try {
		const docsAuthToken = getDocsToken();
		if (docsAuthToken) {
			const payload = verifyToken(docsAuthToken);
			user.readUserFromToken(payload as DocsAuthTokenClaims);

			return true;
		}

		return false;
	} catch (ex) {
		if (ex instanceof InvalidClaimError) {
			return false;
		}

		return cancel();
	}
}

/**
 * Attempt to read AAD JWT from localstorage and verify.
 * If AAD JWT is missing or invalid (expired, etc), yield to next handler.
 */
export async function loadDocsAuthTokenFromIdToken(
	cancel: OrchestratorCancellationFunction = () => false
) {
	try {
		const idToken = getIdToken();
		if (idToken) {
			verifyToken(idToken);

			const docsToken = await getDocsAuthToken(idToken);

			storeDocsToken(docsToken);
			return true;
		}

		return cancel();
	} catch (ex) {
		if (ex instanceof InvalidClaimError) {
			return false;
		}

		if (ex instanceof UserNotFoundError) {
			return cancel(registrationHandler);
		}

		return cancel();
	}
}

/**
 * Attempt to do the oAuth implicit flow via iframe in the background.
 * We'll attempt to grab the token and set it in localstorage.
 */
export async function getIdTokenFromBackgroundLogin() {
	const args = Object.assign({}, signInArgs) as any;
	args.fromOrigin = location.origin;
	const frame = document.createElement('iframe');
	const messenger = new WindowMessenger(frame, new URL(redirectUrl).origin);
	args.state = `silent://${location.origin}`;
	args.nonce = generateUuidV4();
	args.prompt = 'none';
	frame.src = `${apis.auth.identityPlatform}?${toQueryString(args)}`;
	frame.classList.add('visually-hidden');
	frame.hidden = true;
	frame.setAttribute('aria-hidden', 'true');

	const promise = messenger.subscribeOnce(data => (data as any).type === 'auth', 4000);

	await contentLoaded;
	document.body.appendChild(frame);

	try {
		const message = await promise;

		return (message as any).success;
	} catch {
		return false;
	} finally {
		frame.remove();
	}
}

/**
 * If the Docs Auth API states that we don't have a profile for an AAD user, this handler is called.
 * Redirect to the register page; when on the register page, set the user based on the id_token claims.
 */
export function registrationHandler() {
	if (getMeta('page_kind') !== 'register') {
		user.setAnonymous();
		const query = toQueryString({ redirectUrl: location.href });
		location.href = `${registrationUrl}?${query}`;
		return;
	}

	// If we're on the register page, then we need to set the user based on the claims from the AAD token.
	const idToken = getIdToken();
	const payload = verifyToken(idToken);
	user.mapIdTokenToProfile(payload as IdTokenClaims);
}

/**
 * Redirect to logout URL after deleting docsAuth token and id_token.
 */
export async function signOut(returnUrl = location.href, singleSignOut: boolean = false) {
	if (singleSignOut) {
		await fetchWithTimeout(apis.auth.signOut);
	}

	deleteDocsToken();
	deleteIdToken();

	// being picky about where we redirect on sign out. if something looks off go to prod docs (for now - could be env based)
	location.href = safeUrl(returnUrl, signOutUrlDefault).href;
}

export function signIn(returnUrl = location.href) {
	const args = Object.assign({}, signInArgs);
	args.state = signInReturnUrl(returnUrl);
	args.nonce = generateUuidV4();
	location.href = `${apis.auth.identityPlatform}?${toQueryString(args)}`;
}

export function signInReturnUrl(returnUrl: string): string {
	let urlString: string = null;

	try {
		const url = new SignInReturnUrl(returnUrl);
		urlString = url.toString();
	} catch {
		// fall back to the current url - which might be the same as what's passed in but using as a last resort on URL constructor failure
		urlString = location.href;
	}

	return urlString;
}

export function process401Response(response: Response) {
	if (response.status === 401) {
		user.setAnonymous();
	}
	return response;
}

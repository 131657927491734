import { eventBus } from './event-bus';
import { document } from './globals';
import { RouterAfterNavigateEvent } from './router/events';

let metaDictionary: { [name: string]: string[] };

export function readMetaTags(): void {
	metaDictionary = {};
	const metaTags = document.head.querySelectorAll<HTMLMetaElement>('meta[name],meta[property]');
	for (let i = 0; i < metaTags.length; i++) {
		const meta = metaTags.item(i);
		let name = meta.name;
		if (name === '') {
			name = meta.getAttribute('property');
			if (name === '') {
				continue;
			}
		}
		if (metaDictionary[name]) {
			metaDictionary[name].push(meta.content);
		} else {
			metaDictionary[name] = [meta.content];
		}
	}

	eventBus.subscribe(RouterAfterNavigateEvent, readMetaTags);
}

export function getMeta(name: string): string | undefined {
	if (metaDictionary === undefined) {
		readMetaTags();
	}

	return metaDictionary[name] === undefined ? undefined : metaDictionary[name][0];
}

export function getMetas(name: string): string[] {
	if (metaDictionary === undefined) {
		readMetaTags();
	}
	return metaDictionary[name] ? metaDictionary[name].slice() : [];
}

import {
	loc_day,
	loc_days,
	loc_hour,
	loc_hours,
	loc_hr,
	loc_hrMin,
	loc_minFormat,
	loc_week,
	loc_weeks
} from '@msdocs/strings';
import { msDocs } from './globals';

// formatted minutes helper function
export function convertMinsToHrsMins(mins: number): string {
	const h = Math.floor(mins / 60);
	const m = mins % 60;

	if (h === 0) {
		return loc_minFormat.replace('{numberOfMinutes}', m.toString());
	}
	if (m === 0) {
		return `${h} ${loc_hr}`;
	}
	return loc_hrMin
		.replace('{numberOfHours}', h.toString())
		.replace('{numberOfMinutes}', m.toString());
}

// locally formatted (h)h:mm am/pm
export const shortTimeFormat = new Intl.DateTimeFormat(msDocs.data.userLocale, {
	hour: 'numeric',
	minute: 'numeric'
});

/**
 * converts milliseconds to a number, in minutes, rounded down to the nearest integer
 * @param milliseconds the milliseconds
 */
export function convertMillisecondsToMins(milliseconds: number): number {
	return Math.floor(milliseconds / 1000 / 60);
}

// Converts hrs to a formatted string containing comma-delimited
// weeks/days/hours
export function formatHoursAsWeeksDaysHours(hrs: number): string {
	let label: string = '';
	let commaCount: number = 0;
	let delimiter: string = '';
	const weeks: number = Math.floor(hrs / 168);
	const days: number = Math.floor((hrs % 168) / 24);
	const hours: number = hrs - weeks * 168 - days * 24;

	if (weeks > 0) {
		commaCount++;
	}
	if (days > 0) {
		commaCount++;
	}
	if (hours > 0) {
		commaCount++;
	}
	if (commaCount > 0) {
		commaCount--;
	}

	if (weeks > 0) {
		const weeksLabel = weeks === 1 ? loc_week : loc_weeks;
		setDelimiter();
		label += weeks.toString() + ' ' + weeksLabel + delimiter;
		delimiter = '';
	}
	if (days > 0) {
		const daysLabel = days === 1 ? loc_day : loc_days;
		setDelimiter();
		label += days.toString() + ' ' + daysLabel + delimiter;
		delimiter = '';
	}
	if (hours > 0) {
		const hoursLabel = hours === 1 ? loc_hour : loc_hours;
		label += hours.toString() + ' ' + hoursLabel;
	}
	return label;

	function setDelimiter(): void {
		if (commaCount > 0) {
			delimiter = ', ';
			commaCount--;
		}
	}
}

/**
 * Serialize JS date object to the "date" input type value format.
 * @param date The target date.
 */
export function convertDateToYYYYMMDD(date: Date) {
	if (!date) {
		return '';
	}

	const mm = date.getMonth() + 1;
	const dd = date.getDate();

	return `${date.getFullYear()}-${(mm > 9 ? '' : '0') + mm}-${(dd > 9 ? '' : '0') + dd}`;
}

import { apis } from '../environment/apis';
import { isReview } from '../is-production';
import { toQueryString } from '../query-string';
import { fetchWithTimeout } from '../fetch';

export const pivotCategories: PivotCategory[] = ['All', 'Documentation', 'Learn', 'Reference'];

export type PivotCategory = 'All' | 'Documentation' | 'Learn' | 'Reference';

export const pageSize = 10;

export const category = 'category';

export type SearchApiMode = 'search' | 'category-counts' | 'rss';

export async function fetchAutocompleteSuggestion(
	term: string,
	locale: string
): Promise<AutocompleteSuggestion> {
	const emptyObj: AutocompleteSuggestion = { query: term, suggestions: [] };
	if (!term || !locale) {
		return Promise.resolve(emptyObj);
	}

	const url = `${apis.siteSearch}/autocomplete?${toQueryString({ locale, query: term })}`;
	const init: RequestInit = {
		mode: 'cors',
		credentials: isReview ? 'include' : 'omit'
	};

	const response = await fetchWithTimeout(url, init);
	if (response.ok) {
		return response.json();
	}

	return emptyObj;
}

export interface AutocompleteSuggestion {
	query: string;
	suggestions: string[];
}

export interface AddressBarArgs {
	dataSource: string | null;
	category: PivotCategory;
	term: string | null; // "search"
	scope: string | null;
	skip: number;
	preview: string | null;
}

export interface SearchApiArgs extends AddressBarArgs {
	locale: string | null;
	scoringProfile?: string;
	facet?: 'category';
}

/**
 * Read the address bar query string into the object interface.
 * @param search The search string that could be from location.search
 */
export function readAddressBar(search: string): AddressBarArgs {
	const params = new URLSearchParams(search);
	const raw = params.get('category') || 'All';
	const category =
		pivotCategories.find(c => c.localeCompare(raw, undefined, { sensitivity: 'base' }) === 0) ||
		'All';

	return {
		dataSource: params.get('dataSource'),
		category,
		skip: parseInt(params.get('skip') || '0'),
		term: params.get('search'),
		scope: params.get('scope'),
		preview: params.get('preview')
	} as AddressBarArgs;
}

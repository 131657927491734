import { msDocs } from '../globals';
import { replaceLocaleInPath, setLocaleCookie } from '../locale';
import { parseQueryString } from '../query-string';
import { safeUrl } from '../url';

interface LocaleInfo {
	a: HTMLAnchorElement;
	locale: string;
	name: string;
	displayName: string;
}

export function localePage() {
	const anchorElements = Array.from(
		document.querySelectorAll('#locale-list a')
	) as HTMLAnchorElement[];
	const form = document.querySelector('.select-locale form');
	const headlineCurrentLocaleElement = document.querySelector('.select-locale .headline strong');
	const locales: LocaleInfo[] = [];
	let localeInfo: LocaleInfo;
	const targetUrl = safeUrl(parseQueryString().target);

	headlineCurrentLocaleElement.textContent = msDocs.data.userLocaleName;
	if (msDocs.data.contentDir === 'rtl' && /\)$/.test(headlineCurrentLocaleElement.textContent)) {
		headlineCurrentLocaleElement.appendChild(document.createTextNode('\u200E'));
	}

	for (const element of anchorElements) {
		// for backward compatibility before the old process is deprecated
		const locale = element.getAttribute('locale') || element.search.substr(1);
		localeInfo = {
			a: element,
			locale: locale.toLocaleLowerCase(),
			name: element.title.toLocaleLowerCase(),
			displayName: element.textContent.toLocaleLowerCase()
		};
		locales.push(localeInfo);

		localeInfo.a.href = targetUrl.href;
		localeInfo.a.pathname = replaceLocaleInPath(localeInfo.a.pathname, localeInfo.locale);

		element.setAttribute('data-locale', localeInfo.locale);
		element.setAttribute('data-bi-name', localeInfo.locale);

		if (localeInfo.locale === msDocs.data.userLocale) {
			element.parentElement.classList.add('selected');
		}

		if (msDocs.data.contentDir === 'rtl' && /\)$/.test(element.textContent)) {
			element.appendChild(document.createTextNode('\u200E'));
		}
	}

	function filterLocales(event: Event) {
		const term = (form.querySelector('[type="search"]') as HTMLInputElement).value
			.trim()
			.toLocaleLowerCase();
		let localeHidden;
		let visibleLocales = 0;

		for (const locale of locales) {
			localeHidden =
				term.length &&
				locale.locale.indexOf(term) === -1 &&
				locale.name.indexOf(term) === -1 &&
				locale.displayName.indexOf(term) === -1;
			locale.a.parentElement.hidden = localeHidden;
			if (!localeHidden) {
				visibleLocales++;
			}
		}

		msDocs.data.jsllReady.then(function (awa) {
			awa.ct.capturePageAction(event.target, {
				behavior: awa.behavior.OTHER,
				actionType: awa.actionType.OTHER,
				content: {
					type: 'localesearch',
					term,
					results: visibleLocales
				}
			});
		});
	}

	let filterTimeout = 0;

	function throttleInput(event: Event) {
		clearTimeout(filterTimeout);
		filterTimeout = setTimeout(function () {
			filterLocales(event);
		}, 300);
	}

	form.addEventListener('input', throttleInput);
	form.addEventListener('change', filterLocales);

	document.querySelector('.content').addEventListener('click', function (event) {
		if (event.target instanceof HTMLAnchorElement) {
			const locale = event.target.getAttribute('data-locale');
			if (locale) {
				setLocaleCookie(locale);
			}
		}
	});
}

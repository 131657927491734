import {
	loc_courseScheduling_courseInfo,
	loc_courseScheduling_courseSchedulingMessage,
	loc_courseScheduling_errorLoadingCourseSchedules,
	loc_courseScheduling_noCoursesFound,
	loc_courseScheduling_noCoursesFoundForFilter,
	loc_courseScheduling_providedBy,
	loc_courseScheduling_scheduledTime,
	loc_loading,
	loc_register,
	loc_viewDetails
} from '@msdocs/strings';
import { CourseTime, IltCourseSchedule } from '../apis/course-scheduling';
import { msDocs, document } from '../globals';
import { html, render, unsafeHTML } from '../lit-html';
import { getMeta } from '../meta';
import { formatHoursAsWeeksDaysHours } from '../time-format';
import { CourseListViewModel } from './course-list-view-model';
import { CourseSchedulingCriteria, View, ViewRenderer } from './course-types';

const duration = parseInt(getMeta('hoursToComplete'), 10);
const durationDisplay = formatHoursAsWeeksDaysHours(duration).toLocaleLowerCase();

export class CourseListView implements View<CourseListViewModel> {
	constructor(
		private readonly view: ViewRenderer<CourseListViewModel>,
		public readonly element: HTMLElement,
		private childViews: HTMLElement[]
	) {}

	public init(vm: CourseListViewModel) {
		this.element.setAttribute('aria-live', 'polite');
		this.element.setAttribute('aria-atomic', 'false');
		this.element.tabIndex = -1;
		this.update(vm);
	}

	public update(vm: CourseListViewModel) {
		const element = this.element;
		const view = this.view(vm, this.childViews);
		const selector = this.getFocus();
		element.setAttribute('aria-busy', `${vm.busy}`);

		render(view, element);
		this.setFocus(selector);
		if (vm.busy) {
			return;
		}
	}

	/**
	 * Before we re-render the component, check to see if the browser's focus is set inside of the element.
	 * If it is, return the selector for closest link that we can to the active element.
	 */
	private getFocus() {
		return this.element.contains(document.activeElement);
	}

	/**
	 * Set focus to a specific element inside of the paging control component.
	 * @param selector The querySelector value for which to search.
	 */
	private setFocus(focus: boolean) {
		if (focus) {
			this.element.focus();
		}
	}
}

export const coursesTemplate = ({ items, count, criteria }: Partial<CourseListViewModel>) => html`
	<p class="has-margin-bottom-medium is-size-small">
		${loc_courseScheduling_courseInfo.replace('{duration}', durationDisplay)}
	</p>
	<ol id="course-list-items" class="grid is-horizontal has-margin-none">
		${items.map(
			(item, index) => html`
				<li
					class="grid-item is-flex-column"
					aria-setsize="${count}"
					aria-posinset="${criteria.skip + index + 1}"
				>
					${courseListCardTemplate(item)}
				</li>
			`
		)}
	</ol>
`;

export const noCoursesFoundTemplate = (
	criteria: CourseSchedulingCriteria,
	filterApplied: boolean
) => html`
	<div id="course-list-no-courses" class="has-margin-top-extra-large has-margin-bottom-small">
		${filterApplied && criteria.location
			? html`
				<p><strong>${loc_courseScheduling_noCoursesFoundForFilter.replace(
					'{location}',
					criteria.location.displayName
				)}<strong></p>
			`
			: html`
					<p>
						<strong
							>${unsafeHTML(
								loc_courseScheduling_noCoursesFound.replace(
									'{href}',
									'https://microsoft.com/learning/partners.aspx'
								)
							)}</strong
						>
					</p>
			  `}
	</div>
`;

/**
 * The list template for displaying results.
 * @param vm The course list view model.
 * @param nestedViews Encapsulated HTML elements meant to be slotted into this view.
 */
export const courseListViewTemplate: ViewRenderer<CourseListViewModel> = ({
	busy,
	pageSize,
	error,
	items,
	filterApplied,
	count,
	criteria
}: Partial<CourseListViewModel>) => {
	if (busy) {
		// IE does not support ranges built with Array.from or keys, and won't map over sparse arrays. Use Array.apply instead.
		// eslint-disable-next-line prefer-spread
		const skeleton = Array.apply(null, { length: pageSize }).map(courseListCardSkeletonTemplate);

		return html`
			<div id="course-list-busy">
				<p class="has-margin-bottom-medium is-size-small">
					${loc_courseScheduling_courseInfo.replace('{duration}', durationDisplay)}
				</p>
				<ul class="grid is-horizontal has-margin-none">
					${skeleton}
				</ul>
				<p class="visually-hidden">
					${loc_loading}
				</p>
			</div>
		`;
	}

	if (error) {
		return html`
			<div id="course-list-error" class="has-margin-top-extra-large has-margin-bottom-small">
				<p>${loc_courseScheduling_errorLoadingCourseSchedules}</p>
			</div>
		`;
	}

	return items.length
		? coursesTemplate({ items, count, criteria })
		: noCoursesFoundTemplate(criteria, filterApplied);
};

export function courseListCardSkeletonTemplate() {
	return html`
		<li class="grid-item">
			<div class="box skeleton has-padding-medium has-padding-bottom-none has-margin-bottom-none">
				<div class="columns is-mobile has-margin-bottom-none">
					<div class="column has-padding-none is-narrow">
						<div
							class="button is-text icon has-padding-large has-height-auto has-border-none has-inner-focus"
						>
							<span aria-hidden="true" class="icon is-small"></span>
						</div>
					</div>
				</div>
			</div>
		</li>
	`;
}

const scheduleTemplate = (schedule: CourseTime) => {
	const startTime = new Date(schedule.startTime);
	const endTime = new Date(schedule.endTime);
	const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
	const timeOptions = { hour: 'numeric', minute: '2-digit' };
	const accessibleDateOptions = Object.assign({}, dateOptions, timeOptions);
	const accessibleTime = loc_courseScheduling_scheduledTime
		.replace('{startTime}', startTime.toISOString())
		.replace('{endTime}', endTime.toISOString())
		.replace(
			'{startTimeDisplay}',
			startTime.toLocaleString(msDocs.data.userLocale, accessibleDateOptions)
		)
		.replace(
			'{endTimeDisplay}',
			endTime.toLocaleString(msDocs.data.userLocale, accessibleDateOptions)
		);

	return html`
		<p class="has-margin-none">
			<span class="visually-hidden">
				${unsafeHTML(accessibleTime)}
			</span>
			<span aria-hidden="true">
				<time datetime="${startTime.toISOString()}">
					${startTime.toLocaleDateString(msDocs.data.userLocale, dateOptions)}
					${startTime.toLocaleTimeString(msDocs.data.userLocale, timeOptions)}
				</time>
				-
				<time datetime="${endTime.toISOString()}">
					${endTime.toLocaleTimeString(msDocs.data.userLocale, timeOptions)}
				</time>
			</span>
		</p>
	`;
};

export const handleExpanderToggle = ({ target }: Partial<Event>) => {
	const targetElement = (target as HTMLElement).closest('button');
	const controls = targetElement.getAttribute('aria-controls');
	const expanded = targetElement.getAttribute('aria-expanded') === 'true';

	const element = document.getElementById(controls);
	if (element) {
		targetElement.setAttribute('aria-expanded', expanded ? 'false' : 'true');
		element.hidden = expanded;
	}
};

/**
 * The card template for the results view.
 * @param course The course data model.
 *
 * This view uses definition lists for semantic purposes to aid accessibility (giving context
 * to the displayed metadata for each course item).
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dl
 */
export function courseListCardTemplate(course: IltCourseSchedule) {
	const startTime = new Date(course.schedule.startDate);
	const timeZone = `${course.schedule.values[0].date.slice(-6).replace(':', '')} (${
		course.schedule.timezone
	})`;
	return html`
		<div
			class="box has-padding-medium has-padding-bottom-none has-margin-bottom-none has-height-auto"
		>
			<div class="columns is-mobile has-margin-bottom-none">
				<div class="column has-padding-none is-narrow">
					<button
						type="button"
						class="button is-text icon has-padding-large has-height-auto has-border-none has-inner-focus"
						data-dropdown="course-${course.id}"
						aria-expanded="false"
						aria-controls="course-detail-${course.id}"
						data-bi-name="course-expander"
						aria-label="${loc_viewDetails}"
						@click=${handleExpanderToggle}
					>
						<span aria-hidden="true" class="icon is-small">
							<i class="expanded-indicator docon docon-chevron-down-light"></i>
						</span>
					</button>
				</div>
				<div class="column is-flex-tablet is-flex-column has-flex-justify-content-center">
					<div class="columns is-gapless">
						<div class="column is-one-third-tablet">
							<div class="is-inline-mobile">
								<time datetime="${startTime.toISOString()}"
									>${startTime.toLocaleDateString(msDocs.data.userLocale, {
										year: 'numeric',
										month: 'long',
										day: 'numeric'
									})}
								</time>
							</div>
						</div>
						<div class="column is-size-7-mobile">
							<div class="columns is-mobile is-gapless-mobile">
								<div class="column is-one-half-tablet is-narrow-mobile">
									<div>${course.location}</div>
								</div>
								<div class="column is-narrow is-hidden-tablet">
									<span aria-hidden="true">&nbsp;•&nbsp;</span>
								</div>
								<div class="column is-one-half-tablet is-inline-block-mobile">
									<div>${course.price}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="column is-narrow is-flex is-flex-column has-flex-justify-content-center">
					<div>
						<a href="${course.partnerCheckoutUrl}" data-bi-name="course-register">
							${loc_register}
							<span aria-hidden="true" class="docon docon-navigate-external"></span>
						</a>
					</div>
				</div>
			</div>
		</div>
		<div
			id="course-detail-${course.id}"
			class="box has-padding-medium has-padding-bottom-small has-margin-bottom-none has-border-top has-height-auto"
			hidden
		>
			<div class="columns">
				<div class="column">
					<div class="columns is-mobile has-margin-bottom-none">
						<div class="column is-narrow">
							<span aria-hidden="true" class="icon">
								<span class="docon docon-clock"></span>
							</span>
						</div>
						<div class="column">
							<p class="has-margin-none">
								${unsafeHTML(
									loc_courseScheduling_courseSchedulingMessage.replace(
										'{timezone}',
										`<strong>UTC${timeZone}</strong>`
									)
								)}
							</p>
							${course.schedule.values.map(scheduleTemplate)}
						</div>
					</div>
					<div class="columns is-mobile has-margin-bottom-none">
						<div class="column is-narrow">
							<span aria-hidden="true" class="icon">
								<span class="docon docon-people"></span>
							</span>
						</div>
						<div class="column">
							<div class="is-inline">
								${loc_courseScheduling_providedBy.replace('{partnerName}', course.partnerName)}
							</div>
						</div>
					</div>
					<div class="columns is-mobile has-margin-bottom-none">
						<div class="column is-narrow">
							<span aria-hidden="true" class="icon">
								<span class="docon docon-enhance-pop"></span>
							</span>
						</div>
						<div class="column">
							<address>${course.address}</address>
						</div>
					</div>
				</div>
			</div>
		</div>
	`;
}

import { location } from '../globals';
import { isProd } from '../is-production';
import { localStorage } from '../protected-storage';

export const localStorageKey = 'docs.preview';

interface PreviewOptions {
	/**
	 * Property used in non-production env that enables flags when no preview query string exist in the address bar.
	 */
	require?: boolean;

	/**
	 * Property used to persist/update flags in localStorage when a flag exists (in the preview query string in the address bar, or in localStorage.)
	 */
	persist?: boolean;
}

// starting with only supporting page load, not SPA URL changes
const allowedQS: { [index: string]: 1 } = (
	new URLSearchParams(location.search).get('preview') || ''
)
	.split(',')
	.map(x => x.trim().toLowerCase())
	.reduce<{ [index: string]: 1 }>((o, k) => {
		if (k !== '') {
			o[k] = 1;
		}
		return o;
	}, {});

let allowedLS: { [index: string]: number };
const readLS = () => {
	let store;
	if (!localStorage.getItem(localStorageKey)) {
		store = {};
	} else {
		store = JSON.parse(localStorage.getItem(localStorageKey));
	}

	return store;
};

const allowed = (key: string, persist: boolean): boolean => {
	allowedLS = readLS();
	const existsQS = allowedQS[key] === 1;
	const existsLS = !isNaN(allowedLS[key]);
	const exists = existsQS || existsLS;
	const remove = allowedQS[`x-${key}`];
	if (existsQS) {
		/* eslint-disable-next-line */ /* tslint:disable-next-line */ // remove after eslint migration
		console.log(`Preview ${key} via query string`);
	} else if (existsLS) {
		/* eslint-disable-next-line */ /* tslint:disable-next-line */ // remove after eslint migration
		console.log(`Preview ${key} via local storage`);
	}

	if (persist && (exists || remove)) {
		if (exists) {
			allowedLS[key] = Date.now();
		}

		if (remove) {
			delete allowedLS[key];
		}

		localStorage.setItem(localStorageKey, JSON.stringify(allowedLS));
	}

	return exists;
};

export const preview = (
	key: string,
	options: PreviewOptions = { require: false, persist: false }
): boolean => {
	const { require, persist } = options;
	// not isProd, no qs and not require, then enable all preview
	// this still allows the preview qs to be used in non-prod to just enable select features
	if (!isProd && Object.keys(allowedQS).length === 0 && !require) {
		return true;
	}

	// all allowed values are lower-cased, do the same to `key` for a simple case-insensitive comparison
	key = key.toLowerCase();
	return allowed(key, persist);
};

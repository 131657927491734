import { loc_copied, loc_copy, loc_copyCode } from '@msdocs/strings';
import { contentAttrs } from '../bi';
import { clipboardCopy, clipboardCopySupported } from '../clipboard';
import { document, msDocs } from '../globals';
import { getActionPanel } from '../interactivity/action-panel';
import { InteractiveComponent, renderInteractiveComponent } from '../interactivity/activation';
import { updateQueryString } from '../query-string';
import { escape } from '../text-formatting';
import { CodeBlock } from './block';

// Use the URL hash to target a code header to activate. Primarily intended to wire together a redirect auth flow but also works for specific code URL sharing.
let codeHeaderCount: number = 0;
const codeTryHashName = 'code-try-';
const codeHeaderAORId = ((): number => {
	const codeTryIdString = location.hash.split(codeTryHashName)[1];
	if (codeTryIdString === undefined) {
		return -1;
	}

	const headerAORId = parseInt(codeTryIdString, 10);
	if (headerAORId >= -1) {
		updateQueryString({}, 'replaceState', '');
		return headerAORId;
	}

	return -1;
})();
const getNextCodeHeaderId = (): number => codeHeaderCount++;
const shouldActivateOnReturn = (headerId: number): boolean => headerId === codeHeaderAORId;

export function getLanguageNameRtlHtml(displayName: string, contentDir: string) {
	if (contentDir === 'rtl') {
		return escape(displayName).replace(
			/(^|\s|\>)(C#|F#|C\+\+)(\s*|[.!?;:]*)(\<|[\n\r]|$)/gi,
			'$1$2&lrm;$3$4'
		);
	}
	return displayName;
}

export function addCodeHeader(block: CodeBlock, contentDir: string) {
	const header = document.createElement('div');
	const headerId = getNextCodeHeaderId();
	const headerAttrId = `${codeTryHashName}${headerId}`;
	header.classList.add('codeHeader');
	header.setAttribute('id', headerAttrId);
	header.setAttribute(contentAttrs.name, 'code-header');
	header.innerHTML = `<span class="language">${getLanguageNameRtlHtml(
		block.displayName,
		contentDir
	)}</span>`;
	if (block.monikers) {
		header.setAttribute('data-moniker', block.monikers);
	}

	if (clipboardCopySupported) {
		header.insertAdjacentHTML('beforeend', createCopyButtonHtml());
		const copyButton = header.lastElementChild as HTMLButtonElement;
		copyButton.addEventListener('click', () => {
			if (!handleCopyCode(copyButton)) {
				return;
			}
			copyCodeBlockToClipboard(block.element.firstElementChild as HTMLElement, block.language);
			copyButton.focus();
		});
	}

	const interactiveType = block.interactiveType;
	const scaffoldingType = block.scaffoldingType;
	if (
		interactiveType &&
		!(interactiveType.flags.requiresStructuredData && msDocs.data.pageTemplate === 'Conceptual')
	) {
		const buttonConfig = interactiveType.activateButtonConfig;
		header.insertAdjacentHTML(
			'beforeend',
			`
			<button class="action action-interactive" ${contentAttrs.name}="code-header-try-it-${
				interactiveType.name
			}">
				<span class="${buttonConfig.iconClass}" role="presentation"></span>
				<span>${escape(buttonConfig.name)}</span>
			</button>`
		);

		const activateButton = header.lastElementChild as HTMLButtonElement;
		for (const attr of buttonConfig.attributes) {
			activateButton.setAttribute(attr.name, attr.value);
		}

		const activate = (_event: MouseEvent, skipHashSet: boolean = false) => {
			if (skipHashSet !== true) {
				history.replaceState(null, null, `#${headerAttrId}`);
			}
			activateButton.classList.add('is-loading');
			activateButton.disabled = true;
			const code = block.element.textContent.trim();
			let component: InteractiveComponent;
			if (msDocs.data.pageTemplate === 'ModuleUnit') {
				const interactiveContainer = document.querySelector('#interactive-container');
				component = renderInteractiveComponent(interactiveType, interactiveContainer);
			} else {
				const actionPanel = getActionPanel('animate', activateButton);
				component = renderInteractiveComponent(interactiveType, actionPanel);
			}
			component
				.setCode(code, scaffoldingType)
				.then(() => component.execute())
				.catch(() => {})
				.then(() => {
					activateButton.classList.remove('is-loading');
					activateButton.disabled = false;
				});
		};

		activateButton.addEventListener('click', activate);

		// skip straight to activation if hitting this on auth return
		if (shouldActivateOnReturn(headerId)) {
			activate(null, true);
		}
	}

	block.element.classList.remove('loading');
	block.element.insertAdjacentElement('beforebegin', header);
	block.header = header;
}

export function copyCodeBlockToClipboard(codeBlock: HTMLElement, language: string) {
	let text = codeBlock.textContent.trim();
	if (language === 'powershell') {
		text = text.replace(/\bPS [a-z]:\\>\s?/gi, '');
	}
	return clipboardCopy(text, codeBlock);
}

export function createCopyButtonHtml(): string {
	return `<button type="button" class="action is-relative" ${contentAttrs.name}="copy" aria-label="${loc_copyCode}">
				<span class="icon">
					<span class="docon docon-edit-copy" role="presentation"></span>
				</span>
				<span>${loc_copy}</span>
				<div class="successful-copy-alert is-absolute has-right-zero has-top-zero has-left-zero has-bottom-zero is-flex has-flex-align-items-center has-flex-justify-content-center has-text-success-invert has-background-success is-transparent" aria-hidden="true">
					<span class="icon is-size-large">
						<span class="docon docon-check-mark" role="presentation"></span>
					</span>
				</div>
			</button>`;
}

export function handleCopyCode(copyButton: HTMLButtonElement): boolean {
	let buttonDisabled: boolean;
	if (buttonDisabled) {
		return false;
	}

	const successIcon = copyButton.querySelector('.successful-copy-alert');
	successIcon.classList.remove('is-transparent');
	copyButton.setAttribute('role', 'alert');
	copyButton.setAttribute('aria-label', loc_copied);
	buttonDisabled = true;
	setTimeout(() => {
		successIcon.classList.add('is-transparent');
		copyButton.removeAttribute('role');
		copyButton.setAttribute('aria-label', loc_copyCode);
		buttonDisabled = false;
	}, 1250);
	return true;
}

import {
	loc_courseScheduling_location,
	loc_courseScheduling_noCoursesFoundForFilterCTA,
	loc_courseScheduling_noCoursesFoundForFilterHelpText,
	loc_courseScheduling_startingOn,
	loc_courseScheduling_virtualOnly
} from '@msdocs/strings';
import { autocompleteChangeEvent, createAutoComplete } from '../components/autocomplete';
import { html, render, unsafeHTML } from '../lit-html';
import { convertDateToYYYYMMDD } from '../time-format';
import { SearchLocation, View, ViewRenderer } from './course-types';
import { CourseViewModel } from './course-view-model';

export class CourseView implements View<CourseViewModel> {
	constructor(
		private view: ViewRenderer<CourseViewModel>,
		public readonly element: HTMLElement,
		private childViews: HTMLElement[]
	) {}

	public init(vm: CourseViewModel) {
		this.update(vm);
	}

	public update(vm: CourseViewModel) {
		const element = this.element;
		const view = this.view(vm, this.childViews);
		render(view, element);
	}
}

/**
 * The view for the course view model.
 * @param vm The course view model.
 * @param nestedViews Encapsulated elements meant to be slotted into this view.
 */
export const courseViewTemplate = (
	{
		loadAutocompleteSuggestions,
		handleAutocompleteSelection,
		handleAutocompleteClear,
		date,
		handleDateChange,
		virtualOnly,
		handleShowVirtualOnlyChange
	}: Partial<CourseViewModel>,
	nestedViews: HTMLElement[]
) => {
	const locationAutocomplete = createAutoComplete<SearchLocation>({
		input: {
			id: 'course-scheduling-location',
			placeholder: '',
			docon: 'enhance-pop',
			doconOrientation: 'right',
			doconClasses: 'has-text-color',
			isFullWidth: true
		},
		getSuggestions: loadAutocompleteSuggestions,
		getTitle: suggestion => suggestion.displayName
	});
	locationAutocomplete.addEventListener(autocompleteChangeEvent, handleAutocompleteSelection);
	locationAutocomplete.addEventListener('input', handleAutocompleteClear);
	const autoCompleteInput = locationAutocomplete.querySelector('input');

	const virtualOnlyAutocompleteHandler = (event: InputEvent) => {
		const checked = (event.target as HTMLInputElement).checked;
		autoCompleteInput.disabled = checked;
		autoCompleteInput.value = '';
		handleShowVirtualOnlyChange(event);
	};

	return html`
		<form
			id="course-filter"
			data-bi-name="course-filter"
			class="has-margin-top-medium has-margin-bottom-medium"
			action="#"
			@submit=${(event: Event) => {
				event.preventDefault();
			}}
		>
			<div class="columns">
				<div class="column is-full">
					<div class="field">
						<label class="checkbox" for="course-scheduling-virtual-only">
							<input
								id="course-scheduling-virtual-only"
								type="checkbox"
								.checked=${virtualOnly}
								@change=${virtualOnlyAutocompleteHandler}
							/>
							<span class="checkbox-check" role="presentation"></span>
							<span class="checkbox-text">${loc_courseScheduling_virtualOnly}</span>
						</label>
					</div>
				</div>
			</div>
			<div class="columns">
				<div class="column is-half">
					<div class="field">
						<label class="label" for="course-scheduling-location"
							>${loc_courseScheduling_location}</label
						>
						${locationAutocomplete}
					</div>
				</div>
				<div class="column is-half">
					<div class="field">
						<label class="label" for="course-scheduling-date"
							>${loc_courseScheduling_startingOn}</label
						>
						<div class="control has-icons-right">
							<input
								class="input"
								id="course-scheduling-date"
								type="date"
								value=${convertDateToYYYYMMDD(date)}
								@blur=${handleDateChange}
							/>
							<span aria-hidden="true" class="icon is-small is-right has-text-color">
								<span class="docon docon-calendar"></span>
							</span>
						</div>
					</div>
				</div>
			</div>
		</form>
		<section id="schedule-list">
			${nestedViews[0]}
		</section>
		<div id="course-paging" class="has-margin-top-large">
			${nestedViews[1]}
		</div>
		<p class="is-size-small">
			${unsafeHTML(
				loc_courseScheduling_noCoursesFoundForFilterCTA.replace(
					'{href}',
					'https://microsoft.com/learning/partners.aspx'
				)
			)}
		</p>
		<p class="is-size-small">
			<a class="is-undecorated" href="#">${loc_courseScheduling_noCoursesFoundForFilterHelpText}</a>
		</p>
	`;
};

import { notifyContentUpdated } from './affix';

export function setupDismiss() {
	window.addEventListener(
		'click',
		({ target }: Event) => {
			const dismissButton = target instanceof Element && target.closest('[data-dismiss]');

			if (!dismissButton) {
				return;
			}

			const dismissTarget = dismissButton.closest('[data-dismissable]');
			dismissElement(dismissTarget);
		},
		{ passive: true } as any
	);
}

export async function dismissElement(dismissTarget: Element) {
	if (dismissTarget.getAttribute('data-dismissable') === 'disappearing') {
		dismissTarget.classList.add('disappearing');
		await new Promise(resolve => setTimeout(resolve, 500));
	}

	dismissTarget.classList.remove('disappearing');
	dismissTarget.remove();
	notifyContentUpdated();
}

import {
	loc_close,
	loc_continueWithoutSaving,
	loc_correctAnswer,
	loc_incorrectAnswer,
	loc_knowledgeCheckCompleAllAnswers,
	loc_questionIsIncorrectFormat,
	loc_questionsAreIncorrectFormat,
	loc_quizComplete,
	loc_signInToSaveProgress,
	loc_xp,
	loc_youEarnedXpKnowledgeCheck
} from '@msdocs/strings';
import { html, render, TemplateResult } from '../lit-html';
import { GradedQuestion, GradedQuestionForBi, LearnItem, QuizAnswer } from '../apis/learn';
import { user } from '../auth/user';
import { jsllReady } from '../bi';
import { Modal } from '../components/modal/modal';
import { EventBus } from '../event-bus';
import { document, msDocs } from '../globals';
import { handleXpTag } from '../learn/unit-progress';
import { getMeta } from '../meta';
import { listenUntilUnload } from '../router/utils';
import { UnitStateChangeEvent } from '../unit/events';
import {
	generateLearnNavigationActionInfo,
	getInPageLearnNavigationAction
} from '../unit/navigation-in-content';
import {
	LearnActionAnchor,
	LearnActionButton,
	LearnActionLinks,
	LearnPageNavigationInfo
} from '../unit/navigation-types';
import { QuizValidatedEvent, QuizValidationRequestEvent } from './quiz-events';

const createQuizModalContent = (
	uid: string,
	repeatQuizCompletion: boolean,
	totalPoints: number,
	actionInfo: LearnPageNavigationInfo
) => {
	// Build quiz Continue Section html
	const xpTagSpanHtml =
		totalPoints !== 0 ? loc_xp.replace('{totalXP}', totalPoints.toString()) : '';
	const titleHtml = repeatQuizCompletion
		? loc_knowledgeCheckCompleAllAnswers
		: loc_youEarnedXpKnowledgeCheck;
	const xpEarnedHtml = repeatQuizCompletion
		? html``
		: html` <div id="quiz-xp-earned">
				<div
					class="quiz-modal-image has-padding-top-extra-large has-padding-bottom-extra-large has-margin-top-medium"
				>
					<div
						data-progress-uid="${uid}"
						class="xp-tag is-large is-complete has-flex-justify-content-center has-margin-top-medium is-flex"
					>
						<div class="xp-tag-hexagon">
							<span class="xp-tag-icon is-shown-complete docon docon-check"></span>
							<span class="xp-tag-xp x-hidden-focus">${xpTagSpanHtml}</span>
						</div>
					</div>
				</div>
		  </div>`;

	// Create quiz Modal html
	const contentElement = document.createElement('div');
	const quizContentHtml = html` <div class="modal-card fill-mobile">
		<div class="modal-card-head has-padding-small">
			<button
				class="modal-close modal-card-close-button is-large"
				data-bi-name="close"
				aria-label="${loc_close}"
			></button>
		</div>
		<section
			class="modal-card-body has-padding-top-none has-flex-justify-content-center has-text-centered"
		>
			<p class="title is-2 has-margin-bottom-small">${loc_quizComplete}</p>
			<p id="quiz-modal-title">${titleHtml}</p>
			${xpEarnedHtml}
		</section>
		<div class="modal-card-foot has-flex-justify-content-center">
			<div id="quiz-button-holder">
				${createQuizContinueSectionHtml(actionInfo)}
			</div>
		</div>
	</div>`;
	render(quizContentHtml, contentElement);
	return contentElement;
};

// Create the quiz modal button html so that it has an onclick that opens the completion modal
export function createQuizModuleCompleteHtml(actionInfo: LearnActionButton): TemplateResult {
	const biName = user.isAuthenticated ? actionInfo.biName : 'continue-without-save';

	const onActionButtonClick = {
		handleEvent() {
			actionInfo.onclick();
		},
		capture: true
	};

	return html` <div>
		<button
			@click=${onActionButtonClick}
			class="button is-primary is-block modal-close"
			data-bi-name="${biName}"
			autofocus
		>
			<span class="authenticated">${actionInfo.text}</span>
			<span class="not-authenticated">${loc_continueWithoutSaving}</span>
		</button>
		<button
			class="button docs-sign-in not-authenticated is-fullwidth is-text has-margin-bottom-large has-text-primary is-small is-block is-text-centered"
		>
			${loc_signInToSaveProgress}
		</button>
	</div>`;
}

// This one is for when a user has already completed the module, so we can't fire a completion modal
export function createQuizModalLinkHtml(actionInfo: LearnActionAnchor): TemplateResult {
	return html` <div>
		<a
			class="button is-primary has-margin-top-medium modal-close"
			href="${actionInfo.href}"
			data-bi-name="${actionInfo.biName}"
			autofocus
		>
			<span class="authenticated">${actionInfo.text}</span>
			<span class="not-authenticated">${loc_continueWithoutSaving}</span>
		</a>
		<button
			class="button docs-sign-in not-authenticated is-fullwidth is-text has-margin-bottom-large has-text-primary is-small is-block is-text-centered"
		>
			${loc_signInToSaveProgress}
		</button>
	</div>`;
}

export function createQuizModalLinkFromUl(actionInfo: LearnActionLinks): TemplateResult {
	return html` <div>
		<a
			class="button is-primary has-margin-top-medium"
			href="/${msDocs.data.userLocale}/learn/browse/"
			data-bi-name="continue"
			autofocus
		>
			<span class="authenticated">${actionInfo.sectionTitle}</span>
			<span class="not-authenticated">${loc_continueWithoutSaving}</span>
		</a>
		<button
			class="button docs-sign-in not-authenticated is-fullwidth is-text has-margin-bottom-large has-text-primary is-small is-block is-text-centered"
		>
			${loc_signInToSaveProgress}
		</button>
	</div>`;
}

export class Quiz {
	public readonly submitButton: HTMLButtonElement;
	private readonly form: HTMLFormElement;
	private currentUnit: LearnItem;
	private isSubmitting: boolean;
	private modal: Modal;

	constructor(form: HTMLFormElement, bus: EventBus, currentUnit: LearnItem) {
		this.form = form;
		this.currentUnit = currentUnit;
		this.submitButton = form.querySelector('button[type=submit]'); // ! change to data-learn-action="quiz-modal:"
		const answerAllQuestionsPrompt = document.getElementById(
			'unanswered-question-error'
		) as HTMLParagraphElement;

		const inputs = Array.from(form.querySelectorAll<HTMLLabelElement>('label')).map(label => ({
			label,
			input: form.querySelector<HTMLInputElement>(`#${label.getAttribute('for')}`),
			question: label.closest('.quiz-question')
		}));

		const handleInputChange = (event: Event) => {
			const inputInfo = inputs.find(x => x.input === event.target);
			if (!inputInfo) {
				return;
			}

			if (answerAllQuestionsPrompt !== null) {
				answerAllQuestionsPrompt.classList.add('is-hidden');
			}

			inputs
				.filter(x => x.question === inputInfo.question)
				.forEach(({ label, input }) => {
					label.classList.remove('is-incorrect', 'is-correct');
					const method = input.checked ? 'add' : 'remove';
					label.classList[method]('is-selected');
				});
		};

		// syncs the label element's focus status with the visually hidden input's focus status.
		const syncFocus = () =>
			inputs.forEach(({ input, label }) => {
				const method = input.matches(':focus') ? 'add' : 'remove';
				label.classList[method]('is-focused');
			});

		listenUntilUnload(form, 'blur', syncFocus, true);
		listenUntilUnload(form, 'focus', syncFocus, true);
		listenUntilUnload(form, 'change', handleInputChange);
		listenUntilUnload(form, 'submit', event => {
			event.preventDefault();
			if (this.isSubmitting) {
				return;
			}

			if (!this.allQuestionsAnswered()) {
				if (answerAllQuestionsPrompt !== null) {
					answerAllQuestionsPrompt.classList.remove('is-hidden');
				}
				return;
			}

			this.isSubmitting = true;

			this.submitButton.classList.add('is-loading');
			try {
				bus.publish(new QuizValidationRequestEvent(this, this.serializeSelectedAnswers()));
			} catch (responseStatus) {
				// let it through
				if (responseStatus !== 429) {
					throw responseStatus;
				}
			}
		});

		// Subscribe to quiz events for passing and failing.
		bus.subscribe(QuizValidatedEvent, e => {
			this.handleValidationEvent(e);
		});

		// Subscribe to unit state change event to handle quiz validation passed scenario to create quiz Modal.
		bus.subscribe(UnitStateChangeEvent, e => {
			this.handleUnitStateChangeEvent(e);
		});
	}

	// This is to create quiz modal after quiz validation passed
	public async handleUnitStateChangeEvent(event: UnitStateChangeEvent) {
		const { config } = event;
		const { firstQuizCompletion, repeatQuizCompletion } = config;

		// If quiz is not passed, bail out since handleValidationEvent already took care of the handling
		if (!firstQuizCompletion && !repeatQuizCompletion) {
			return;
		}

		// Create quiz modal content
		const actionType = getInPageLearnNavigationAction(config);
		const actionInfo = await generateLearnNavigationActionInfo(actionType, config);

		this.modal = new Modal(
			createQuizModalContent(
				getMeta('uid'),
				config.repeatQuizCompletion,
				config.totalPoints,
				actionInfo
			)
		);

		const modalContent = this.modal.contentElement;

		const xpTag = modalContent.querySelector('.xp-tag') as HTMLElement;
		if (xpTag !== null && config.totalPoints === 0) {
			handleXpTag([xpTag], [this.currentUnit]);
		}

		const modalButton = document.querySelector('.quiz-form button') as HTMLButtonElement;
		modalButton.classList.add('is-hidden');

		// Hide submit button. Disable changes to quiz and remove focus state handling.
		this.submitButton.classList.add('is-hidden');
		Array.from(this.form.elements).forEach(element => {
			element.setAttribute('disabled', 'disabled');
		});

		addEventListener('popstate', () => this.modal.hide());
		this.modal.show();
	}

	public handleTooManyAttempts() {
		this.submitButton.classList.remove('is-loading');
		this.isSubmitting = false;
	}

	private allQuestionsAnswered() {
		// create a map of "question name" -> "question is answered".
		const isAnsweredByName = Array.from(
			this.form.querySelectorAll<HTMLInputElement>('.choice-input')
		).reduce((map, input) => {
			map[input.name] = map[input.name] || input.checked;
			return map;
		}, {} as { [name: string]: boolean });
		// check whether each question was answered.
		return Object.keys(isAnsweredByName).reduce<boolean>(
			(allAnswered, name) => allAnswered && isAnsweredByName[name],
			true
		);
	}

	// Making this private might interfere with testing, but hey, Typescript.
	private serializeSelectedAnswers(): QuizAnswer[] {
		const answerHash = (Array.from(this.form.elements).filter(
			element => element.tagName.toLowerCase() === 'input'
		) as HTMLInputElement[]).reduce((state, element) => {
			const name = element.name;
			const value = element.value;

			state[name] = state[name] || [];
			if (element.checked) {
				state[name].push(value);
			}
			return state;
		}, {} as { [name: string]: string[] });

		return Object.keys(answerHash).map(x => ({ id: x, answers: answerHash[x] }));
	}

	private handleValidationEvent(event: QuizValidatedEvent) {
		this.submitButton.classList.remove('is-loading');
		this.isSubmitting = false;
		const questions = Array.from(document.querySelectorAll('.quiz-choice')) as HTMLLabelElement[];
		const selectedQuestions = Array.from(
			document.querySelectorAll('.quiz-choice.is-selected')
		) as HTMLLabelElement[];

		// Check if correct / incorrect
		const checkQuestions = event.answers ? event.answers : (event.details as GradedQuestion[]); // local vs. remote
		if (checkQuestions && selectedQuestions.length !== 0) {
			questions.forEach(question => {
				question.removeAttribute('aria-label');
			});
			checkQuestions.forEach(answer => {
				if (selectedQuestions[answer.id]) {
					const labelText = selectedQuestions[answer.id].innerText;
					selectedQuestions[answer.id].setAttribute(
						'aria-label',
						answer.isCorrect
							? `${loc_correctAnswer}: ${labelText}`
							: `${loc_incorrectAnswer}: ${labelText}`
					);
					selectedQuestions[answer.id].classList.add(
						answer.isCorrect ? 'is-correct' : 'is-incorrect'
					);
					// get the explanation holder and set the explanation text
					const explanation = selectedQuestions[answer.id].nextElementSibling as HTMLDivElement;
					if (answer.choices[0].explanation) {
						explanation.innerHTML = answer.choices[0].explanation;
					}
				}
			});
		}

		this.reportQuizValidation(event);

		if (event.passed) {
			// When quiz validation passed, delay creating quiz modal till we get UnitStateChangeEvent
			return;
		}

		const incorrects = document.querySelectorAll('.quiz-choice.is-incorrect');

		// If incorrect
		if (incorrects.length !== 0) {
			// Error text
			let formattedText: string;
			const list: string[] = [];
			const questions = document.querySelectorAll('.quiz-question');
			const screenReaderText = document.getElementById(
				'screen-reader-text'
			) as HTMLParagraphElement;

			// build the array of incorrect questions
			for (let i = 0; i < questions.length; i++) {
				const incorrect = questions[i].querySelector('.is-incorrect');
				if (incorrect) {
					list.push((i + 1).toString());
				}
			}
			const last = list.pop();

			// singular vs. plural
			if (incorrects.length === 1) {
				formattedText = loc_questionIsIncorrectFormat
					.replace('{numberOfQuestions}', questions.length.toString())
					.replace('{lastIncorrectQuestionNumber}', last);
			} else {
				formattedText = loc_questionsAreIncorrectFormat
					.replace('{numberOfIncorrectQuestions}', incorrects.length.toString())
					.replace('{numberOfQuestions}', questions.length.toString())
					.replace('{incorrectQuestionNumbers}', list.join(', '))
					.replace('{lastIncorrectQuestionNumber}', last);
			}

			// alert and show
			screenReaderText.innerText = formattedText;
			screenReaderText.setAttribute('role', 'alert');
		}
	}

	private reportQuizValidation(event: QuizValidatedEvent) {
		const checkQuestions = event.answers ? event.answers : (event.details as GradedQuestion[]); // local vs. remote
		const gradedQuestionForBi = checkQuestions.map(item => ({
			id: item.id,
			isCorrect: item.isCorrect
		})) as GradedQuestionForBi[];

		jsllReady.then(function (awa) {
			awa.ct.captureContentPageAction({
				behavior: awa.behavior.OTHER,
				actionType: awa.actionType.OTHER,
				content: {
					type: 'quiz-validated',
					uid: getMeta('uid'),
					passed: event.passed,
					questions: gradedQuestionForBi
				}
			});
		});
	}
}

function createQuizContinueSectionHtml(actionInfo: LearnPageNavigationInfo): TemplateResult {
	if (!actionInfo) {
		return html``;
	}

	if (actionInfo.elementType === 'button') {
		// If the unit is finished, the quiz continue button will pop a completion modal
		return createQuizModuleCompleteHtml(actionInfo);
	}

	if (actionInfo.elementType === 'ul') {
		return createQuizModalLinkFromUl(actionInfo);
	}

	if (actionInfo.elementType === 'a') {
		return createQuizModalLinkHtml(actionInfo);
	}

	return html``;
}

import {
	loc_disclaimerSearchPreviousVersions,
	loc_disclaimer_archive,
	loc_disclaimer_archiveMachineTranslated,
	loc_disclaimer_archiveRetire,
	loc_disclaimer_archiveRetireMachineTranslated,
	loc_disclaimer_recommendedVersion,
	loc_disclaimer_returnToMain,
	loc_visualStudioArchive,
	loc_visualStudioArchiveDownload
} from '@msdocs/strings';
import { showDisclaimer } from './disclaimer';
import { msDocs } from './globals';
import { getMeta } from './meta';
import { parseQueryString } from './query-string';

export const checkIsArchived = ((): Function => {
	let isArchived: boolean;

	return (force?: boolean): boolean => {
		if (!force && isArchived !== undefined) {
			return isArchived;
		}

		const dataSource = parseQueryString().dataSource;
		isArchived =
			getMeta('is_archived') === 'true' ||
			(msDocs.data.pageTemplate === 'SearchPage' && dataSource === 'previousVersions');
		return isArchived;
	};
})();

export function checkMachineTranslated(meta: string) {
	return /^(?:MT|MTE|MTE75|MTE95)$/i.test(meta);
}

export function checkIsRetired(): boolean {
	return getMeta('is_retired') === 'true';
}

export function showArchiveDisclaimer(): void {
	if (!checkIsArchived()) {
		return;
	}
	const isMachineTranslated = checkMachineTranslated(getMeta('ms.translationtype'));
	const retireString = isMachineTranslated
		? loc_disclaimer_archiveRetireMachineTranslated
		: loc_disclaimer_archiveRetire;
	const archiveString = isMachineTranslated
		? loc_disclaimer_archiveMachineTranslated
		: loc_disclaimer_archive;

	const archiveUrl = getMeta('current_version_url');
	const disclaimer = checkIsRetired() ? retireString : archiveString;
	const visualStudioArchive = getMeta('vs_archive') === 'true';

	// Use getMeta to check for metadata value
	if (visualStudioArchive) {
		const visualStudioBanner = loc_visualStudioArchive;
		const text = loc_visualStudioArchiveDownload;
		showDisclaimer(visualStudioBanner, {
			url:
				'https://visualstudio.microsoft.com/downloads/?utm_medium=microsoft&utm_source=docs.microsoft.com&utm_campaign=button+cta&utm_content=download+vs2019',
			text
		});
	}
	if (archiveUrl) {
		const text = loc_disclaimer_recommendedVersion;
		showDisclaimer(disclaimer, { url: archiveUrl, text });
	} else if (msDocs.data.pageTemplate === 'SearchPage') {
		const text = loc_disclaimer_returnToMain;
		showDisclaimer(loc_disclaimerSearchPreviousVersions, {
			url: 'https://docs.microsoft.com',
			text
		});
	} else {
		const text = loc_disclaimer_returnToMain;
		showDisclaimer(disclaimer, { url: 'https://docs.microsoft.com', text });
	}
}

export function handleArchive(): void {
	if (!checkIsArchived()) {
		return;
	}

	showArchiveDisclaimer();
	// there is also a breadcrumb hack in the makeDisplayHtml function in toc.ts, circa line 935.
}

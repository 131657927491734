import { getDocsToken } from './auth/v2/jwt';

export const fetchWithTimeout: typeof fetch = (input: string | Request, init?: RequestInit) => {
	const timeout = 30 * 1000; // 30 seconds
	return new Promise<Response>((resolve, reject) => {
		const timeoutHandle = setTimeout(() => trackAndReject('timeout'), timeout);
		function trackAndResolve(response: Response) {
			clearTimeout(timeoutHandle);
			resolve(response);
		}
		function trackAndReject(reason: any) {
			clearTimeout(timeoutHandle);
			reject(reason);
		}
		fetch(input, init).then(trackAndResolve, trackAndReject);
	});
};

/**
 *  Common function to create Request based on url and init
 */
export function createRequest(
	url: string,
	init?: RequestInit,
	requiresAuthorization: boolean = true
): Request {
	init = init || {};
	init.mode = 'cors';

	if (requiresAuthorization && !init.credentials) {
		init.credentials = 'omit';
	}

	const request = new Request(url, init);
	if (requiresAuthorization) {
		const token = getDocsToken();

		if (token) {
			request.headers.set('Authorization', `Bearer ${getDocsToken()}`);
		}
	}

	if (request.method === 'PUT' || request.method === 'POST') {
		request.headers.set('Content-Type', 'application/json');
	}

	return request;
}

import { assessmentApi, assessmentDelimiter } from '../apis/assessments';
import { user } from '../auth/user';
import { jsllReady } from '../bi';
import { EventBus } from '../event-bus';
import { localStorage as protectedLocalStorage } from '../protected-storage';
import { updateUrlSearchFromMap } from '../query-string';
import { router } from '../router/router';
import { PropertyChangedEvent } from '../view-model';
import {
	Assessment,
	AssessmentBusyIndication,
	AssessmentInitializationObject,
	AssessmentInputTypeMap,
	AssessmentNavigation,
	AssessmentSession,
	Category,
	SessionInputKeyValue
} from './types';
import {
	calculateQuestionsAnswered,
	calculateNumberOfRequiredQuestionsNotAnswered,
	createGenericResponseIdentifier,
	removeItemsFromArray
} from './utilities';
import { features } from '../environment/features';

export const assessmentStorageKey = 'assessment_session';

export interface AssessmentModel {
	session: AssessmentSession;
	assessment: Assessment;
}

export class AssessmentViewModel extends EventBus {
	public currentQuestion: AssessmentInputTypeMap[keyof AssessmentInputTypeMap];
	public assessment: Assessment;
	public currentCategory: Category;
	public localStorageKey: string;
	public session: Partial<AssessmentSession>;

	private _categoryIndex: number;
	private _questionIndex: number;
	private _categoryTotal: number;
	private _questionTotalIndex: number;
	private _isPreAssessment: boolean;
	private _originalCategories: Category[];
	private _busy: AssessmentBusyIndication = {
		busy: false
	};
	private _validateNextRender: boolean = false;

	public get categoryIndex() {
		return this._categoryIndex;
	}
	public get questionIndex() {
		return this._questionIndex;
	}
	public get categoryTotal() {
		return this._categoryTotal;
	}
	public set categoryTotal(num: number) {
		this._categoryTotal = num;
	}
	public get questionTotalInSelectedCategories() {
		return this.assessment.categories.reduce(
			(totalQuestionCount, category) =>
				(totalQuestionCount +=
					category.isRequired || this.session.categoriesSelected[category.id]
						? category.questions.length
						: 0),
			0
		);
	}
	public get questionTotal() {
		return this.assessment.categories.reduce(
			(totalQuestionCount, category) => (totalQuestionCount += category.questions.length),
			0
		);
	}
	public get questionTotalIndex() {
		return this._questionTotalIndex;
	}
	public get questionsAnswered() {
		return calculateQuestionsAnswered(this.session, this.assessment);
	}
	public get isValid() {
		return this.validateCurrentQuestion();
	}
	public get isPreAssessment() {
		return this._isPreAssessment;
	}
	public get isValidPreAssessment() {
		return this.validatePreAssessment();
	}
	public get questionNumber() {
		return this.questionTotalIndex + 1;
	}
	public get questionOfTotalPercentage() {
		return Math.round((this.questionNumber / this.questionTotal) * 100);
	}
	public get onFirstQuestion() {
		return this._questionTotalIndex === 0;
	}
	public get onLastQuestion() {
		return this._questionTotalIndex === this.questionTotal - 1;
	}
	public set busy(busy: AssessmentBusyIndication) {
		this._busy = busy;
		this.notifyPropertyChanged();
	}
	public get busy() {
		return this._busy;
	}
	public get canClickNext() {
		return !this.busy.busy && this.isValid;
	}
	public get canClickSubmit() {
		return !this.busy.busy && 0 === this.countRequiredQuestionsNotAnswered;
	}
	public get reportValidationOnRender() {
		return this._validateNextRender;
	}
	public set reportValidationOnRender(toValidate: boolean) {
		this._validateNextRender = toValidate;
	}
	public get countRequiredQuestionsNotAnswered() {
		return calculateNumberOfRequiredQuestionsNotAnswered(this.session, this.assessment);
	}

	constructor(
		action: AssessmentInitializationObject,
		private readonly localStorage = protectedLocalStorage
	) {
		super();
		const { session, assessment, mode: view } = action;

		// Assign model
		this.assessment = JSON.parse(JSON.stringify(assessment));
		this.session = session;

		this._originalCategories = [...this.assessment.categories];

		// Check for previous progress made
		this.assignInitialSelectedCategories();

		if (view === 'questionnaire') {
			this.removeUnselectedCategories();
		}

		// Send user to the pre-assessment
		this._isPreAssessment = view === 'pre-assessment';

		// Set up indexes to begin at the first question
		this._categoryIndex = 0;
		this._questionIndex = 0;
		this._questionTotalIndex = 0;
		this._categoryTotal = assessment.categories.length;
		this.currentQuestion = this.assessment.categories[0].questions[0];
		this.currentCategory = this.assessment.categories[0];
		this.localStorage = localStorage;
		this.localStorageKey = assessmentStorageKey;

		this.assignQuestionnaireIndexes(action);
		this.reportValidationOnRender = action.mode === 'pre-assessment' && action.reportValidity;

		this.syncInitialState();
	}

	/**
	 * Move the state of the assessment from the current question to the next question
	 */
	public async next() {
		if (!this.isValid || this.busy.busy) {
			return;
		}

		this.busy = { busy: true, action: 'next' };

		// If we're at the end of the questionnaire
		if (this.onLastQuestion) {
			this.completeSession();
			// We want to show a warning that they should login to save,
			// that should not update the url
			this.notifyPropertyChanged();
			return;
		}

		await this.saveAssessmentSession();
		this.resetRadioInputs();
		this.handleNextIndices();
		this.updateQuestionPointers();
		await router.goto(this.getCurrentUrl(), 'pushState');
		this.busy = { busy: false };
	}

	/**
	 * Move the state of the assessment from the current question to the previous question
	 */
	public async previous() {
		if (this.busy.busy) {
			return;
		}
		this.busy = { busy: true, action: 'back' };
		await this.saveAssessmentSession();
		this.resetRadioInputs();
		this.handleBackIndices();
		this.updateQuestionPointers();

		await router.goto(this.getCurrentUrl(), 'pushState');
		this.busy = { busy: false };
	}

	/**
	 * Navigate back from the question section of the assessment to the pre-assessment
	 */
	public async backToPreAssessment() {
		if (this.busy.busy) {
			return;
		}
		this.busy = { busy: true, action: 'back' };

		this._isPreAssessment = true;
		this.resetCategories();

		await router.goto(this.getCurrentUrl(), 'pushState');

		this.busy = { busy: false };
	}

	public async continueFromPreAssessment() {
		if (this.busy.busy) {
			return;
		}
		this.busy = { busy: true, action: 'next' };

		this.removeUnselectedCategories();

		this.currentCategory = this.assessment.categories[0];
		this.currentQuestion = this.currentCategory.questions[0];
		this.updateIndexesViaCurrentValues();
		this._isPreAssessment = false;

		await this.saveAssessmentSession();

		await router.goto(this.getCurrentUrl(), 'pushState');

		this.busy = { busy: false };

		this.instrumentQuestionTotal();
	}

	/**
	 * Save the assessment session remotely if logged in, and locally regardless
	 */
	public async saveAssessmentSession() {
		if (this.session.status !== 'completed') {
			this.session.status = 'in-progress';
		}
		this.session.questionTotal = this.questionTotal;
		this.session.questionTotalIndex = this.questionTotalIndex;
		this.session.questionsAnswered = this.questionsAnswered;

		if (user.isAuthenticated) {
			if (this.session.id === 'local') {
				delete this.session.id;
			}
			// Post this information to a database
			const res = await assessmentApi.saveSession(this.session);
			this.session.id = res.id;
		}
		// Save session locally
		this.saveSessionLocal();
	}

	/**
	 * Ensures only selected and default categories are in the assessment categories array.
	 * Adds unused categories to the unselectedCategories store.
	 * Recalculates question and category totals.
	 */
	public removeUnselectedCategories() {
		// Avoid problems related to removing array items mid loop by looping instead through all ids
		const categoryIds = this.assessment.categories.reduce((sum, c) => {
			sum.push(c.id);
			return sum;
		}, [] as string[]);

		for (const id of categoryIds) {
			const category = this.assessment.categories.find(c => c.id === id);
			if (
				!(
					(id in this.session.categoriesSelected && this.session.categoriesSelected[id]) // check to make sure we didn't miss an id
				) && // the user selected this category
				!category.isRequired
			) {
				// this category is default and is always included

				const index = this.assessment.categories.findIndex(c => c.id === id);

				// Clear choices for the unselected category
				this.deleteResponsesForCategoryFromSession(this.assessment.categories[index]);

				// Remove the unselected category from the categories
				this.assessment.categories.splice(index, 1);

				// Update category total
				this.categoryTotal = this.assessment.categories.length;
			}
		}
	}

	/**
	 * Add a single piece of information to the view-model's session
	 * @param detail An object containing a single key corresponding to something that will be added to the session
	 */
	public saveSessionDetail(detail: SessionInputKeyValue) {
		if ('selectCategory' in detail) {
			this.session.categoriesSelected[detail.selectCategory] = !this.session.categoriesSelected[
				detail.selectCategory
			];
			return;
		}
		if ('sessionName' in detail) {
			this.session.name = detail.sessionName;
			return;
		}
		// disallow modification of immutable properties
		if ('id' in detail || 'categoriesSelected' in detail) {
			return;
		}
	}

	/**
	 * Create a string id from the ids of all objects this question is contained by - category, question and/or input, choice
	 * @param inputId The name passed from the group of element the user selects
	 * @param choiceId The value passed from the element the user selects
	 */
	public createResponseIdentifier(inputId: string, choiceId: string): string {
		if (this.currentQuestion.type === 'matrix') {
			return `${this.currentCategory.id}${assessmentDelimiter}${this.currentQuestion.id}${assessmentDelimiter}${inputId}${assessmentDelimiter}${choiceId}`;
		}

		// For single answer question, the inputId is the same as currentQuestion.id
		return `${this.currentCategory.id}${assessmentDelimiter}${inputId}${assessmentDelimiter}${choiceId}`;
	}

	/**
	 * Creates the ids for an entire group of inputs, usually used to ensure these ids are no longer in recorded responses
	 * @param inputName The name passed from the group of element the user selects
	 */
	public getAllChoiceIds(inputName: string): string[] {
		if (this.currentQuestion.type !== 'matrix') {
			return this.currentQuestion.choices.map(
				choice =>
					`${this.currentCategory.id}${assessmentDelimiter}${inputName}${assessmentDelimiter}${choice.id}`
			);
		}

		// Matrix questions have a deeper structure, meaning inputId !== currentQuestion.id
		const currentRow = this.currentQuestion.rows.filter(i => i.id === inputName)[0];
		return this.currentQuestion.choices.map(
			input =>
				`${this.currentCategory.id}${assessmentDelimiter}${this.currentQuestion.id}${assessmentDelimiter}${currentRow.id}${assessmentDelimiter}${input.id}`
		);
	}

	/**
	 * Ensure the user has selected the minimum required number of responses.
	 */
	public validateCurrentQuestion() {
		// If side navigation is being shown, navigation restriction is being lifted
		if (features.assessmentSideNav || !this.currentQuestion.isRequired) {
			return true;
		}

		const { id: categoryId } = this.currentCategory;
		const { id: questionId } = this.currentQuestion;

		if (this.currentQuestion.type === 'matrix') {
			const rowNumber = this.currentQuestion.rows.length;
			let numberOfSelections = 0;
			for (const row of this.currentQuestion.rows) {
				for (const choice of this.currentQuestion.choices) {
					if (this.isSelectedResponse(categoryId, questionId, choice.id, row.id)) {
						numberOfSelections++;
					}
				}
			}
			if (rowNumber === numberOfSelections) {
				return true;
			}
		} else {
			for (const input of this.currentQuestion.choices) {
				if (this.isSelectedResponse(categoryId, questionId, input.id)) {
					return true;
				}
			}
		}
		return false;
	}

	/**
	 * Save a users response in the view model removed previous responses for this input group
	 * @param questionRowId This is the questionId or row id, name of input
	 * @param choiceId This is the choiceId, value of input
	 */
	public saveResponseToViewModel(questionRowId: string, choiceId: string) {
		const responseId = this.createResponseIdentifier(questionRowId, choiceId);

		switch (this.currentQuestion.type) {
			case 'multiSelect':
				const newlyChecked = !this.isSelectedResponse(
					this.currentCategory.id,
					questionRowId,
					choiceId
				);

				if (newlyChecked) {
					this.session.responses.push(responseId);
				} else {
					removeItemsFromArray([responseId], this.session.responses);
				}

				break;

			case 'matrix':
			case 'singleSelect':
			case 'singleSelectImage':
				const allSelectIds = this.getAllChoiceIds(questionRowId);
				removeItemsFromArray(allSelectIds, this.session.responses);
				this.session.responses.push(responseId);
				break;

			default:
				throw new Error('Unsupported question type. Cannot save response.');
		}
		this.notifyPropertyChanged();
	}

	/**
	 * Update pointers to allow for navigation to the next question in the Assessment.
	 */
	public handleNextIndices() {
		const categories = this.assessment.categories;
		const questions = categories[this._categoryIndex].questions;

		// Increment if we're not switching to the next category
		if (this._questionIndex !== questions.length - 1) {
			this._questionIndex++;
			this._questionTotalIndex++;

			return;
		}

		// Prevent navigating past the amount of possible categories
		if (this._categoryIndex === categories.length - 1) {
			return;
		}

		// Switch category: increment category if we're not on the last one, change question index to zero
		this._categoryIndex++;
		this._questionTotalIndex++;
		this._questionIndex = 0;
	}

	/**
	 * Update pointers to allow for navigation to the previous question in the Assessment.
	 */
	public handleBackIndices() {
		// Return if we're on the first question of the whole assessment
		if (this._questionTotalIndex === 0) {
			return;
		}

		// If we're in the first question of a category
		if (this._questionIndex === 0) {
			this._categoryIndex--;
			this._questionTotalIndex--;
			this._questionIndex = this.assessment.categories[this._categoryIndex].questions.length - 1;
			return;
		}

		// Otherwise just decrement the two question related indexes
		this._questionIndex--;
		this._questionTotalIndex--;
	}

	/**
	 * Publish the updated event for any subscriptions
	 */
	public notifyPropertyChanged(): void {
		this.publish(new PropertyChangedEvent());
	}

	/**
	 * Shared pointer updates between next and previous
	 */
	public updateQuestionPointers() {
		this.setCurrentQuestion();
		this.setCurrentCategory();
	}

	/**
	 * Save the the current responses to the client's local storage
	 */
	public saveLocalSession() {
		this.localStorage.setItem(this.localStorageKey, JSON.stringify(this.session));
	}

	public async completeSession() {
		this.session.status = 'completed';
		await this.saveAssessmentSession();
		await router.goto(this.getCurrentUrl(), 'pushState');
	}

	public isSelectedResponse(
		categoryId: string,
		questionId: string,
		choiceId: string,
		rowId?: string
	) {
		const possibleResponse = createGenericResponseIdentifier(
			categoryId,
			questionId,
			choiceId,
			rowId
		);
		return this.session.responses.includes(possibleResponse);
	}

	public async navigate(step: AssessmentNavigation) {
		switch (step.mode) {
			case 'questionnaire':
				this.removeUnselectedCategories();
				this._isPreAssessment = false;
				this.session = step.session;
				this.currentCategory = this.assessment.categories.find(c => c.id === step.categoryId);
				this.currentQuestion = this.currentCategory.questions.find(c => c.id === step.questionId);
				this.updateIndexesViaCurrentValues();
				break;
			case 'pre-assessment':
				this._isPreAssessment = true;
				this.resetCategories();
				break;
			default:
				break;
		}

		// In several a few cases the router needs to report the form validity on the pre-assessment
		if (step.mode === 'pre-assessment' && step.reportValidity) {
			this.reportValidationOnRender = true;
			await router.goto(this.getCurrentUrl(), 'replaceState');
		} else {
			this.notifyPropertyChanged();
		}
	}

	private async syncInitialState() {
		await this.saveAssessmentSession();
		// sync initial url
		history.replaceState(undefined, document.title, this.getCurrentUrl().toString());
	}

	private assignQuestionnaireIndexes(action: AssessmentInitializationObject) {
		if (action.mode !== 'questionnaire') {
			return;
		}
		this.currentCategory = this.assessment.categories.find(c => c.id === action.categoryId);
		this.currentQuestion = this.currentCategory.questions.find(q => q.id === action.questionId);

		this.updateIndexesViaCurrentValues();
	}

	private assignInitialSelectedCategories() {
		// Set all categories except default to false
		for (const category of this.assessment.categories) {
			// don't overwrite sections that come from the session
			if (
				category.id in this.session.categoriesSelected &&
				this.session.categoriesSelected[category.id] === true
			) {
				continue;
			}
			// all isRequired categories will be shown
			if (category.isRequired) {
				this.session.categoriesSelected[category.id] = true;
			}

			// Default to false
			this.session.categoriesSelected[category.id] = false;
		}
	}
	/**
	 * Ensure the user has selected the minimum required number of responses.
	 */
	private validatePreAssessment(): boolean {
		// get list of all category that aren't default
		const selectableCategories = this.assessment.categories
			.filter(c => c.isRequired !== true)
			.map(c => c.id);

		// make sure one of them is in selected categories and has value "true"
		for (const cat of selectableCategories) {
			if (cat in this.session.categoriesSelected && this.session.categoriesSelected[cat] === true) {
				return true;
			}
		}
		return false;
	}

	private getCurrentUrl() {
		let url: URL;
		if (this._isPreAssessment) {
			url = updateUrlSearchFromMap({
				mode: 'pre-assessment',
				session: this.session.id,
				id: null,
				category: null,
				question: null
			});
		} else if (this.session.status === 'completed') {
			url = updateUrlSearchFromMap({
				mode: 'guidance',
				session: this.session.id !== 'local' ? this.session.id : null,
				id: null,
				category: null,
				question: null
			});
		} else {
			url = updateUrlSearchFromMap({
				mode: 'questionnaire',
				session: this.session.id !== 'local' ? this.session.id : null,
				id: null,
				question: this.currentQuestion.id,
				category: this.currentCategory.id
			});
		}

		return url;
	}

	/**
	 * TLDR; lit-html problems occur without this. Radio groups can cause problems for lit html if checked elements reside after the previous questions checked choice.
	 */
	private resetRadioInputs() {
		const container = document.getElementById('assessments-container') as HTMLElement;
		if (container) {
			(Array.from(container.querySelectorAll('input[type="radio"]')) as HTMLInputElement[]).forEach(
				radio => (radio.checked = false)
			);
		}
	}

	private instrumentQuestionTotal() {
		const questionTotal = this.session.questionTotal;
		jsllReady.then(function (awa) {
			awa.ct.captureContentPageAction({
				behavior: awa.behavior.OTHER,
				actionType: awa.actionType.OTHER,
				content: {
					numberOfQuestions: questionTotal
				}
			});
		});
	}

	private saveSessionLocal(): void {
		localStorage.setItem(assessmentStorageKey, JSON.stringify(this.session));
	}

	/**
	 * Removes a user's previous responses to question choices within the specified category.
	 * @param category The category for which to remove responses
	 */
	private deleteResponsesForCategoryFromSession(category: Category) {
		const ids = category.questions.reduce((ids, question) => {
			if (question.type === 'matrix') {
				question.rows.forEach(row => {
					question.choices.forEach(choice => {
						ids.push(createGenericResponseIdentifier(category.id, question.id, choice.id, row.id));
					});
				});
			} else {
				question.choices.forEach(choice => {
					ids.push(createGenericResponseIdentifier(category.id, question.id, choice.id));
				});
			}
			return ids;
		}, []);
		removeItemsFromArray(ids, this.session.responses);
	}

	/**
	 * Use indexes to assign a new currentQuestion to the view model
	 */
	private setCurrentQuestion() {
		this.currentQuestion = this.assessment.categories[this._categoryIndex].questions[
			this._questionIndex
		];
	}

	/**
	 * Use indexes to assign a new currentCategory to the view model
	 */
	private setCurrentCategory() {
		this.currentCategory = this.assessment.categories[this._categoryIndex];
	}

	private updateIndexesViaCurrentValues() {
		if (!this.setCurrentCategory) {
			this.currentCategory = this.assessment.categories[0];
		}
		if (!this.currentQuestion) {
			this.currentQuestion = this.currentCategory.questions[0];
		}

		this._categoryIndex = this.assessment.categories.findIndex(
			c => c.id === this.currentCategory.id
		);
		this._questionIndex = this.currentCategory.questions.findIndex(
			q => q.id === this.currentQuestion.id
		);
		this._questionTotalIndex = this.findQuestionTotalIndex();
	}

	private findQuestionTotalIndex(): number {
		return (
			this.assessment.categories.reduce((sum, category, i) => {
				if (i < this._categoryIndex) {
					sum += category.questions.length;
				}
				if (i === this._categoryIndex) {
					sum += category.questions.findIndex(q => q.id === this.currentQuestion.id) + 1;
				}
				return sum;
			}, 0) - 1
		);
	}

	private resetCategories() {
		// add back all categories
		this.assessment.categories = [...this._originalCategories];
	}
}

import type { ProfileRequest } from '../apis/profile';
import { EventBus } from '../event-bus';
import { location } from '../globals';
import { isPPE, isProduction, isReview } from '../is-production';
import { getMeta } from '../meta';
import { toLocaleDate } from '../text-formatting';
import { getBaseUserLocaleUrl } from '../url';
import { Interests } from '../learner-interests/model';

/**
 * The fallback image URL for a profile picture.
 * This should hopefully be able to be moved into an environment setting. -jeahyoun 20180514
 */
export const fallbackImageUrl = `data:image/svg+xml;base64,${btoa(
	'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448"><path fill="#919191" d="M277.733 252.81c31.966-18.644 53.742-52.923 53.742-92.527 0-59.267-48.22-107.475-107.475-107.475-59.267 0-107.475 48.208-107.475 107.475 0 39.604 21.77 73.884 53.738 92.528C112.35 274.105 72 328.998 72 395.193h24.566c0-71.466 55.98-127.434 127.434-127.434 72.64 0 127.434 54.793 127.434 127.434H376c0-66.197-40.35-121.09-98.267-142.383zM141.09 160.282c0-45.713 37.197-82.91 82.91-82.91 45.725 0 82.91 37.197 82.91 82.91s-37.186 82.91-82.91 82.91c-45.713 0-82.91-37.197-82.91-82.91z"/></svg>'
)}`;

/**
 * Published when the current user changes.
 * Typically from an anonymous to an authenticated user.
 */
export class UserChangedEvent {}

export const microsoftTenantId = '72f988bf-86f1-41af-91ab-2d7cd011db47';
const standardTenantId = '9188040d-6c67-4c5b-b112-36a304b66dad';

export const tenantMapping: { [key: string]: UserType } = {
	[microsoftTenantId]: 'microsoft',
	[standardTenantId]: 'standard'
};

/**
 * The privacyLaunchDate is used for the data consent modal.
 * If the data consent strings change, then this date can be updated to reflect
 */
export const userAcceptedDataConsentDate = new Date('2019-11-21T00:00:00.000Z');

export const getProfileUrlString = (userName: string, profileTab?: string): string =>
	getProfileUrl(userName, profileTab).toString();

/**
 * A docs user.
 */
export class User extends EventBus implements ProfileResponse {
	public userId: string;
	public email: string;
	public userName: string;
	public avatarUrl: string;
	public avatarThumbnailUrl: string;
	public upn: string;
	public displayName: string;
	public profileUrl: string;
	public locale: string;
	public country: string;
	public contactPointTopicSetting: ContactPointTopicSetting;
	public authenticationMode: AuthenticationMode;
	public authenticationModes: AuthenticationModes;
	public isAuthenticated: boolean;
	public userType: UserType;
	public acceptedPrivacyNotice: Date;
	public isAvatarTakenDown: boolean;
	public followerCount: number;
	public followingCount: number;
	public answersAccepted: number;
	public isMicrosoftUser: boolean;
	public isMvp: boolean;
	public qnaUserId: number;
	public createdOn: Date;
	public reputationPoints: number;
	public interests: Interests;
	public isPrivate: boolean;
	public gsi: boolean | null;

	constructor() {
		super();
		this.setAnonymous();
	}

	public setAnonymous() {
		if (this.isAuthenticated === false) {
			return; // already anonymous... no change
		}
		this.userId = '00000000-0000-0000-0000-000000000000';
		this.upn = 'anonymous@anonymous.com';
		this.email = 'anonymous@anonymous.com';
		this.userName = 'Anonymous';
		this.displayName = 'Anonymous';
		this.locale = 'en-us';
		this.country = null;
		this.contactPointTopicSetting = null;
		this.authenticationMode = 'AAD';
		this.authenticationModes = [];
		this.isAuthenticated = false;
		this.avatarUrl = fallbackImageUrl;
		this.avatarThumbnailUrl = fallbackImageUrl;
		this.profileUrl = '#';
		this.createdOn = null;
		this.userType = 'standard';
		this.acceptedPrivacyNotice = userAcceptedDataConsentDate;
		this.isAvatarTakenDown = false;
		this.followerCount = 0;
		this.followingCount = 0;
		this.answersAccepted = 0;
		this.isMicrosoftUser = false;
		this.isMvp = false;
		this.reputationPoints = 1;
		this.qnaUserId = 0;
		this.interests = null;
		this.isPrivate = false;
		this.gsi = null;
		this.publish(new UserChangedEvent());
	}

	/**
	 * Set properties on the user and set authenticated based on the AAD token. This
	 * is only used for the registration page.
	 * @param claims Well-known claims from the login.microsoftonline.com id token.
	 */
	public mapIdTokenToProfile(claims: IdTokenClaims) {
		this.email = claims.preferred_username;
		this.upn = claims.preferred_username;
		this.userName = claims.name;
		this.displayName = claims.name;
		this.authenticationMode = tenantMapping[claims.tid] === 'standard' ? 'MSA' : 'AAD';
		this.userType = tenantMapping[claims.tid] || 'aad';
		this.isAuthenticated = true;
		this.country = '';
		this.publish(new UserChangedEvent());
	}

	/**
	 * Read the user from a profile response from any profile API. This is only used on
	 * profile pages where we load more data than we have in the token.
	 * @param profileResponse Profile response from server.
	 */
	public readUserProfile(profileResponse: ProfileResponse) {
		this.userId = profileResponse.userId;
		this.email = profileResponse.email || '';
		this.userName = profileResponse.userName;
		this.displayName =
			profileResponse.displayName || profileResponse.userName || profileResponse.email;
		this.locale = profileResponse.locale;
		this.country = profileResponse.country;
		this.contactPointTopicSetting = profileResponse.contactPointTopicSetting;
		this.isAuthenticated = true;
		this.avatarUrl = profileResponse.avatarUrl || fallbackImageUrl;
		this.avatarThumbnailUrl =
			profileResponse.avatarThumbnailUrl || profileResponse.avatarUrl || fallbackImageUrl;
		this.profileUrl = getProfileUrlString(profileResponse.userName);
		this.authenticationModes = profileResponse.authenticationModes;
		this.userType = getUserType(profileResponse.authenticationModes);
		this.acceptedPrivacyNotice = new Date(profileResponse.acceptedPrivacyNotice);
		this.isAvatarTakenDown = profileResponse.isAvatarTakenDown;
		this.followingCount = profileResponse.followingCount;
		this.followerCount = profileResponse.followerCount;
		this.answersAccepted = profileResponse.answersAccepted;
		this.isMicrosoftUser = profileResponse.isMicrosoftUser;
		this.isMvp = profileResponse.isMvp;
		this.reputationPoints = profileResponse.reputationPoints;
		this.createdOn = new Date(profileResponse.createdOn);
		this.qnaUserId = profileResponse.qnaUserId;
		this.interests = profileResponse.interests;
		this.isPrivate = profileResponse.isPrivate;
		this.gsi = profileResponse.gsi;
		this.publish(new UserChangedEvent());
	}

	/**
	 * Set properties for the current user based on the DocsAuth token.
	 * @param claims Well-known claims from the docsAuth token.
	 */
	public readUserFromToken(claims: DocsAuthTokenClaims) {
		this.isAuthenticated = true;
		this.userId = claims.sub;
		this.upn = claims.cred_upn;
		this.userName = claims.preferred_username;
		this.displayName = claims.name || claims.preferred_username || claims.email;
		this.avatarUrl = claims.picture || fallbackImageUrl;
		this.avatarThumbnailUrl = claims.thumbnail || claims.picture || fallbackImageUrl;
		this.authenticationMode = claims.cred_type;
		this.locale = claims.locale;
		this.acceptedPrivacyNotice = claims.accepted_privacy_notice;
		this.userType = tenantMapping[claims.tid] || 'aad';
		this.profileUrl = getProfileUrlString(claims.preferred_username);
		this.publish(new UserChangedEvent());
	}

	public whenAuthenticated(): Promise<void> {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		if (user.isAuthenticated) {
			return Promise.resolve();
		}
		return new Promise(resolve =>
			this.subscribe(UserChangedEvent, () => {
				if (this.isAuthenticated) {
					resolve();
				}
			})
		);
	}
}

/**
 * The current docs user.
 */
export const user = new User();

export const hasValidEmail = () =>
	user.email && user.email.length > 0 && user.email !== 'anonymous@anonymous.com';

/**
 * The DocsAuth token claims.
 */
export interface DocsAuthTokenClaims extends Partial<JWT.Payload> {
	/**
	 * The user's TechProfile username
	 */
	preferred_username: string;
	/**
	 * The user's TechProfile display name
	 */
	name: string;
	/**
	 * The user's email (note that this is their actual email from AAD/MSA, not any kind of marketing or subscription email)
	 */
	email: string;
	/**
	 * The tenant id of the user's home tenant. MSA users will have the default MSA tenant value
	 */
	tid: string;
	/**
	 * The user's TechProfile avatar url
	 */
	picture: string;
	/**
	 * The user's TechProfile avatar thumbnail url
	 */
	thumbnail: string;
	/**
	 * The user's locale
	 */
	locale: string;
	/**
	 * DocsToken version; currently always "1.0"
	 */
	ver: string;
	/**
	 * The user's actual AAD oid or MSA puid as provided in the original IdToken
	 */
	cred_id: string;
	/**
	 * The user's actual AAD or MSA username (usually email, but possibly phone number or other) as provided in the original IdToken
	 */
	cred_upn: string;
	/**
	 * 'AAD' or 'MSA' depending on the type of account the user used to log in with
	 */
	cred_type: 'AAD' | 'MSA';
	/**
	 * The date of when the user accepted the privacy notice.
	 */
	accepted_privacy_notice: Date;
}

export interface IdTokenClaims extends Partial<JWT.Payload> {
	name: string;
	preferred_username: string;
	tid: string;
}

export interface ProfileResponse {
	// todo: rename UserProfile?
	userId: string;
	email: string;
	userName: string;
	avatarUrl: string;
	avatarThumbnailUrl: string;
	displayName: string;
	locale: string;
	country: string;
	contactPointTopicSetting: ContactPointTopicSetting;
	authenticationModes: AuthenticationModes;
	acceptedPrivacyNotice: Date;
	isAvatarTakenDown: boolean;
	followerCount: number;
	followingCount: number;
	answersAccepted: number;
	isMicrosoftUser: boolean;
	isMvp: boolean;
	createdOn: Date;
	reputationPoints: number;
	qnaUserId: number;
	interests?: Interests;
	isPrivate: boolean;
	gsi: boolean | null;
}

export type UserType = 'standard' | 'microsoft' | 'aad';
type AuthenticationMode = 'MSA' | 'AAD';
type ContactPointTopicSetting = 'OptInExplicit' | 'OptInImplicit';

interface LinkedAccount {
	dateAdded: Date;
	id: string;
	tenantId: string;
	type: AuthenticationMode;
	upn: string;
}
export type AuthenticationModes = LinkedAccount[];

export function refreshProfileFields(container: Element, profile: ProfileResponse | User) {
	Array.from(container.querySelectorAll<HTMLElement>('[data-profile-property]')).forEach(
		element => {
			const propertyName = element.getAttribute('data-profile-property') as keyof ProfileResponse;
			const profileUrl = (profile as User).profileUrl;
			if (element instanceof HTMLImageElement && propertyName === 'avatarUrl') {
				element.onerror = () => (element.src = fallbackImageUrl);
				element.src = profile[propertyName];
			} else if (element instanceof HTMLImageElement && propertyName === 'avatarThumbnailUrl') {
				element.onerror = () => (element.src = profile.avatarUrl || fallbackImageUrl);
				element.src = profile[propertyName];
			} else if (
				element instanceof HTMLAnchorElement &&
				(propertyName as string) === 'profileUrl'
			) {
				element.href = profileUrl;
				if (profileUrl === '#') {
					element.href = new URL('./register', getProfileBaseUrl()).toString();
				}
			} else if (
				element instanceof HTMLAnchorElement &&
				(propertyName as string) === 'bookmarksUrl'
			) {
				element.href = profileUrl + (isPPE || isReview ? '&section=bookmarks' : 'bookmarks');
				if (profileUrl === '#') {
					element.parentElement.remove();
				}
			} else if (
				element instanceof HTMLAnchorElement &&
				(propertyName as string) === 'collectionsUrl'
			) {
				element.href = profileUrl + (isPPE || isReview ? '&section=collections' : 'collections');
				if (profileUrl === '#') {
					element.parentElement.remove();
				}
			} else if (element instanceof HTMLInputElement) {
				if (element.type !== 'file') {
					element.value = profile[propertyName] as string;
				}
			} else if (propertyName === 'createdOn') {
				element.textContent = toLocaleDate(profile[propertyName]);
			} else {
				element.textContent = profile[propertyName] as string;
			}
		}
	);
}

export async function retrieveProfileFields(container?: Element): Promise<ProfileRequest> {
	const profile: ProfileRequest = {
		displayName: '',
		userName: '',
		email: user.email,
		locale: user.locale,
		country: user.country,
		contactPointTopicSetting: user.contactPointTopicSetting,
		avatarImageBytes: null,
		avatarExtension: null,
		source: null,
		isPrivate: user.isPrivate,
		interests: user.interests,
		gsi: user.gsi
	};

	if (!container) {
		return profile;
	}

	function getFileBytes(file: Blob): Promise<string> {
		return new Promise(resolve => {
			const reader = new FileReader();
			reader.onloadend = () => {
				resolve((reader.result as string).split(',').pop());
			};
			reader.readAsDataURL(file);
		});
	}

	for (const element of Array.from(container.querySelectorAll('[data-profile-property]'))) {
		const propertyName = element.getAttribute('data-profile-property') as keyof ProfileRequest;
		if (element instanceof HTMLInputElement) {
			if (element.type === 'file') {
				// handle this differently.
				(profile[propertyName] as any) = (await getFileBytes(element.files[0])) as any;
			} else {
				(profile[propertyName] as any) = element.value as any;
			}
		} else {
			(profile[propertyName] as any) = element.textContent as any;
		}
	}

	profile.gsi = user.gsi; // Quick fix for GSI element checkbox being detected in username save with an invalid value.

	return profile;
}

// every use of getProfileURL passes in msDocs.data.userLocale for the locale string. might want to deprecate that
// todo: refactor getProfileUrl to return URL obj
export function getProfileUrl(userName: string, profileTab?: string): URL {
	let profileUrl = getProfileBaseUrl();
	const currentPageParams = new URLSearchParams(location.search);

	if (getMeta('page_type') === 'profile') {
		// todo: would we still need this, just for non-prod edge case
		if (currentPageParams.has('section')) {
			profileUrl.searchParams.set('section', currentPageParams.get('section'));
		}

		// todo: document what needs to retain the hash on profile
		profileUrl.hash = location.hash;
	}

	if (isReview || isPPE) {
		if (currentPageParams.has('branch')) {
			profileUrl.searchParams.set('branch', currentPageParams.get('branch'));
		}
		profileUrl.searchParams.set('username', userName);

		if (profileTab) {
			profileUrl.searchParams.set('section', profileTab);
		}
	} else {
		let relativeUrl = `./${userName}/`;
		if (profileTab) {
			relativeUrl += `${profileTab}/`;
		}

		const userProfileUrl = new URL(relativeUrl, profileUrl);
		// need to explicitly retain the hash and search
		userProfileUrl.hash = profileUrl.hash;
		userProfileUrl.search = profileUrl.search;
		profileUrl = userProfileUrl;
	}

	return profileUrl;
}

export function getProfileBaseUrl(): URL {
	const baseUserLocalUrl = getBaseUserLocaleUrl();
	let profileBaseUrl = new URL('./users/', baseUserLocalUrl);

	if (!isProduction || isReview) {
		if (isPPE) {
			profileBaseUrl = new URL('./learn-sandbox/profile/profile', baseUserLocalUrl);
		}

		if (isReview) {
			profileBaseUrl = new URL('./test/profile/profile', baseUserLocalUrl);
		}
	}

	return profileBaseUrl;
}

/**
 * Returns the most specific user type for a user using their linked accounts
 * @param authenticationModes linked accounts
 */
export function getUserType(authenticationModes: AuthenticationModes): UserType {
	let userType: UserType = 'standard';

	if (authenticationModes && authenticationModes.length) {
		if (
			authenticationModes.find(authMode => authMode.tenantId === microsoftTenantId) !== undefined
		) {
			userType = 'microsoft';
		} else if (authenticationModes.find(authMode => authMode.type === 'AAD') !== undefined) {
			userType = 'aad';
		}
	}

	return userType;
}

import { msDocs } from '../globals';

/**
 * Docs environment support level
 */
export type SupportLevel = 'development' | 'production';

/**
 * Network access level
 */
export type AccessLevel = 'online' | 'isolated' | 'local';

export interface EnvironmentTraits {
	/** Docs environment support level */
	supportLevel: SupportLevel;
	/** The level of network access */
	accessLevel: AccessLevel;
	/** Whether review features should by enabled. */
	reviewFeatures: boolean;
	/** Whether this environment self-hosts system content like content-nav, locale page, home page, etc */
	systemContent: boolean;
	/** Azure portal link host name */
	azurePortalHostname: string;
}

export function getTraitsByHostname(
	hostname: string,
	context: { isolated?: boolean; reviewFeatures?: boolean; azurePortalHostname?: string }
): EnvironmentTraits {
	const contextReviewFeatures = context && !!context.reviewFeatures;
	const contextAccessLevel = context && context.isolated ? 'isolated' : 'online';
	const contextAzurePortalHostname = context && context.azurePortalHostname;

	switch (hostname) {
		case 'docs.microsoft.com':
			return {
				supportLevel: 'production',
				accessLevel: contextAccessLevel,
				reviewFeatures: contextReviewFeatures,
				systemContent: true,
				azurePortalHostname: contextAzurePortalHostname
			};
		case 'review.docs.microsoft.com':
			return {
				supportLevel: 'production',
				accessLevel: contextAccessLevel,
				reviewFeatures: true,
				systemContent: true,
				azurePortalHostname: contextAzurePortalHostname
			};
		case 'ppe.docs.microsoft.com':
			return {
				supportLevel: 'development',
				accessLevel: contextAccessLevel,
				reviewFeatures: true,
				systemContent: true,
				azurePortalHostname: contextAzurePortalHostname
			};
		case 'isodev.docs.microsoft.com':
			return {
				supportLevel: 'development',
				accessLevel: 'isolated',
				reviewFeatures: true,
				systemContent: true,
				azurePortalHostname: 'portal.azure.eaglex.ic.gov'
			};
		case 'docs-render-dev.azurewebsites.net':
			return {
				supportLevel: 'development',
				accessLevel: 'isolated',
				reviewFeatures: true,
				systemContent: true,
				azurePortalHostname: 'portal.azure.eaglex.ic.gov'
			};
		case 'composition-sim1.azurewebsites.net':
			return {
				supportLevel: 'development',
				accessLevel: 'isolated',
				reviewFeatures: true,
				systemContent: true,
				azurePortalHostname: 'portal.azure.microsoft.scloud'
			};
		case 'docsstyleguide.z13.web.core.windows.net':
			return {
				supportLevel: 'development',
				accessLevel: 'online',
				reviewFeatures: true,
				systemContent: false,
				azurePortalHostname: 'portal.azure.com'
			};
		case 'localhost':
			return {
				supportLevel: 'development',
				accessLevel: 'local',
				reviewFeatures: true,
				systemContent: false,
				azurePortalHostname: 'portal.azure.com'
			};
		case 'review.docs.azure.cn':
			return {
				supportLevel: 'production',
				accessLevel: contextAccessLevel,
				reviewFeatures: true,
				systemContent: false,
				azurePortalHostname: 'portal.azure.com'
			};
		case 'docs.azure.cn':
		case 'rd.microsoft.com':
		case 'developer.microsoft.com':
		default:
			return {
				supportLevel: 'production',
				accessLevel: contextAccessLevel,
				reviewFeatures: contextReviewFeatures,
				systemContent: false,
				azurePortalHostname: 'portal.azure.com'
			};
	}
}

export const traits: EnvironmentTraits = msDocs?.environment
	? msDocs.environment
	: getTraitsByHostname(location.hostname, msDocs?.data?.context || {});

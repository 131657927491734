import {
	loc_certificationType,
	loc_certificationTypes,
	loc_contentArea,
	loc_language,
	loc_languages,
	loc_level,
	loc_levels,
	loc_product,
	loc_products,
	loc_programmingLanguage,
	loc_programmingLanguages,
	loc_resource_type,
	loc_role,
	loc_roles,
	loc_type
} from '@msdocs/strings';
import { certificationNames } from './certifications';
import { contentAreaNames } from './content-areas';
import { devlangs } from './devlangs';
import { levelNames } from './level';
import { localeNames } from './locales';
import { allProductNames } from './product';
import { certificationResourceTypeNames, learnResourceTypeNames } from './resource-type';
import { roleNames } from './roles';

/**
 * Collection of Name Maps for Taxonomy Values
 */
export const taxonomyNames = {
	category: contentAreaNames,
	languages: devlangs,
	levels: levelNames,
	locales: localeNames,
	products: allProductNames,
	roles: roleNames,
	resource_type: { ...learnResourceTypeNames, ...certificationResourceTypeNames },
	type: certificationNames
} as const;

export type TaxonomyLabel = keyof typeof taxonomyNames;
export type LocString = keyof typeof taxonomyNames[TaxonomyLabel];

export const taxonomyParentNames = {
	category: loc_contentArea,
	languages: loc_programmingLanguages,
	levels: loc_levels,
	locales: loc_languages,
	products: loc_products,
	roles: loc_roles,
	resource_type: loc_resource_type,
	type: loc_certificationTypes
};

export const taxonomyParentNamesSingular = {
	category: loc_contentArea,
	languages: loc_programmingLanguage,
	levels: loc_level,
	locales: loc_language,
	products: loc_product,
	roles: loc_role,
	resource_type: loc_type,
	type: loc_certificationType
};

export type ProductSlug = keyof typeof taxonomyNames.products;
export type LevelSlug = keyof typeof taxonomyNames.levels;
export type RoleSlug = keyof typeof taxonomyNames.roles;

import { assessmentDelimiter } from '../apis/assessments';
import {
	Assessment,
	AssessmentResponseListItem,
	AssessmentSession,
	MatrixQuestion,
	Question
} from './types';

export function createGenericResponseIdentifier(
	categoryId: string,
	questionId: string,
	choiceId: string,
	rowId?: string
): string {
	if (rowId) {
		return `${categoryId}${assessmentDelimiter}${questionId}${assessmentDelimiter}${rowId}${assessmentDelimiter}${choiceId}`;
	}

	// For single answer question, the inputId is the same as questionId
	return `${categoryId}${assessmentDelimiter}${questionId}${assessmentDelimiter}${choiceId}`;
}

export const getAssessmentTitleById = (
	assessmentSummaries: AssessmentResponseListItem[],
	assessmentId: string
) => {
	const summary: AssessmentResponseListItem = assessmentSummaries.find(summary => {
		return summary.id === assessmentId;
	});

	return summary && summary.title ? summary.title : '';
};

export function removeItemsFromArray(removables: string[], arr: string[]) {
	for (const removable of removables) {
		let i = arr.indexOf(removable);
		while (i !== -1) {
			arr.splice(i, 1);
			i = arr.indexOf(removable);
		}
	}
}

/**
 * Given the desired category and question, finds out if there are unanswered questions
 * that exist "before" this question that are required but unanswered.
 * @param desiredCategoryId The category id to check before
 * @param desiredQuestionId The question id to check before
 * @returns True if there are unanswered, required questions before this question. False otherwise.
 */
export function areThereUnansweredRequiredQuestionsBefore(
	session: Partial<AssessmentSession>,
	assessment: Assessment,
	desiredCategoryId: string,
	desiredQuestionId: string
): boolean {
	for (const category of assessment.categories.filter(
		c => c.isRequired || session.categoriesSelected[c.id]
	)) {
		// We are in a selected or required category, let's walk the all the questions
		for (const question of category.questions) {
			if (category.id === desiredCategoryId && question.id === desiredQuestionId) {
				// This is the one they want, and we haven't bailed yet, so we can stop looking
				return false;
			}

			// They didn't ask for this one, but if it's required and hasn't been answered, this
			// means there is something unanswered before their question (which we haven't gotten to yet)
			if (
				question.isRequired &&
				!hasQuestionBeenFullyAnswered(category.id, question, session.responses)
			) {
				return true;
			}
		}

		if (category.id === desiredCategoryId) {
			// No need to look further, question didn't exist in this category or wasn't required
			return false;
		}
	}

	// We only look as far as the identified question
	// if no un-answered, required questions to that point
	// then return false.
	return false;
}

function hasAnswer(categoryId: string, question: Question, responses: string[]): boolean {
	for (const choice of question.choices) {
		if (responses.includes(createGenericResponseIdentifier(categoryId, question.id, choice.id))) {
			return true;
		}
	}

	return false;
}

/**
 * This allows us to see if there is an answered (or partially answered), REQUIRED question
 * AFTER the provided question.  True if there are.
 * @param categoryId The category id of the question to check
 * @param questionId The question id of the question to check
 * @param assessment The assessment where these questions come from
 * @param session The progress the user has made
 */
export function precedesRequiredQuestion(
	categoryId: string,
	questionId: string,
	assessment: Assessment,
	session: Partial<AssessmentSession>
): boolean {
	let startLookingAtCategory = false;
	let startLookingAtQuestion = false;
	let afterQuestion = false;

	// We don't know where this category/question is in the stack, so let's find it.  We search from that point on.
	for (const category of assessment.categories.filter(
		c => c.isRequired || session.categoriesSelected[c.id]
	)) {
		if (!afterQuestion && !startLookingAtCategory && category.id === categoryId) {
			startLookingAtCategory = true;
		}
		if (!startLookingAtCategory) {
			continue;
		}

		// If we are here, we are on the category (or beyond)
		for (const question of category.questions) {
			if (!afterQuestion && !startLookingAtQuestion && question.id === questionId) {
				startLookingAtQuestion = true;
			}
			if (!startLookingAtQuestion) {
				continue;
			}

			// If we are here, we are on the provided question (or beyond)
			if (!afterQuestion) {
				afterQuestion = true; // move to the next question, not interesting in the provided question
				continue;
			}

			// If we are here, we are beyond the provided question -- so look at this question to see if it's
			// required and answered.
			if (
				question.isRequired &&
				(hasQuestionBeenFullyAnswered(category.id, question, session.responses) ||
					hasQuestionBeenPartiallyAnswered(category.id, question, session.responses))
			) {
				return true;
			}
		}
	}

	// Didn't find any
	return false;
}

/**
 * Given the category, question, and responses, finds out if this question is "partially"
 * answered.  This is only a valid question to ask of matrix.  All other types have either
 * no answers or just accept one answer (both = false for this question), or the question
 * is multi-select, which also have no no idea if it is a partial answer, therefor false.
 * @param categoryId The category id of the question to check
 * @param questionId The question id of the question to check
 * @param responses The given responses so far
 */
export function hasQuestionBeenPartiallyAnswered(
	categoryId: string,
	question: Question | MatrixQuestion,
	responses: string[]
): boolean {
	if ('matrix' === question.type) {
		return onlySomeRowsHaveAnswer(categoryId, question, responses);
	} else {
		// All other types have 0 or 1 answer, or is multi-select, which we don't have any idea
		// if its a partial answer or not.
		return false;
	}
}

/**
 * Given the category, question, and responses, finds out if this question is "fully"
 * answered.  For a matrix question, this means all rows have an answer.  For all other
 * types, it means that they have at least one answer.
 * @param categoryId The category id of the question to check
 * @param questionId The question id of the question to check
 * @param responses The given responses so far
 */
export function hasQuestionBeenFullyAnswered(
	categoryId: string,
	question: Question | MatrixQuestion,
	responses: string[]
): boolean {
	if ('matrix' === question.type) {
		return allRowsHaveAnswer(categoryId, question, responses);
	} else {
		return hasAnswer(categoryId, question, responses);
	}
}

function onlySomeRowsHaveAnswer(
	categoryId: string,
	question: MatrixQuestion,
	responses: string[]
): boolean {
	let numAnswers: number = 0;
	for (const row of question.rows) {
		for (const choice of question.choices) {
			if (
				responses.includes(
					createGenericResponseIdentifier(categoryId, question.id, choice.id, row.id)
				)
			) {
				++numAnswers;
				break;
			}
		}
	}
	return numAnswers > 0 && numAnswers < question.rows.length;
}

function allRowsHaveAnswer(
	categoryId: string,
	question: MatrixQuestion,
	responses: string[]
): boolean {
	for (const row of question.rows) {
		let rowHasAnswer: boolean = false;

		for (const choice of question.choices) {
			const responseId = createGenericResponseIdentifier(
				categoryId,
				question.id,
				choice.id,
				row.id
			);
			if (responses.includes(responseId)) {
				rowHasAnswer = true;
				break;
			}
		}

		if (!rowHasAnswer) {
			return false;
		}
	}
	return true;
}

export function calculateQuestionsAnswered(
	session: Partial<AssessmentSession>,
	assessment: Assessment
): number {
	if (!session.responses || !assessment) {
		return 0;
	}

	return assessment.categories.reduce((sum, cat) => {
		if (cat.isRequired || session.categoriesSelected[cat.id]) {
			cat.questions.forEach(question => {
				if (hasQuestionBeenFullyAnswered(cat.id, question, session.responses)) {
					sum++;
				}
			});
		}

		return sum;
	}, 0);
}

/**
 * Returns the number of required questions in the assessment that are in selected categories, but aren't fully answered.
 * @param session the current assessment session (used to see what choices have been made)
 * @param assessment the current assessment (used to see what categories & questions are required)
 */
export function calculateNumberOfRequiredQuestionsNotAnswered(
	session: Partial<AssessmentSession>,
	assessment: Assessment
): number {
	return assessment.categories
		.filter(cat => cat.isRequired || session.categoriesSelected[cat.id])
		.reduce(
			(count, cat) =>
				cat.questions.filter(
					question =>
						question.isRequired &&
						!hasQuestionBeenFullyAnswered(cat.id, question, session.responses)
				).length + count,
			0
		);
}

import { LocString, TaxonomyLabel, taxonomyNames } from '../../name-maps/taxonomy';
import { FSFacet, ResponseFacetType as FSResponseFacetType } from './model';
import { msDocs } from '../../globals';

/**
 * Localize and sort facets sent from server.
 * @param facets The raw (not view model) facet object.
 */
export function localizeAndSort(facets: FSResponseFacetType): FSResponseFacetType {
	for (const f in facets) {
		const parent = f as TaxonomyLabel;

		facets[parent] = facets[parent]
			.map(facet => localizeSortFacet(facet, parent))
			.sort(sortFacetsByName);
	}

	return facets;
}

function sortFacetsByName(a: FSFacet, b: FSFacet): number {
	return a.name.localeCompare(b.name, msDocs.data.userLocale);
}

/**
 * Localize the current facet.
 * Recursively localize and sort any **children**.
 * @param facet The raw (not view model) facet object.
 * @param label The section of the taxonomy this facet exists in (i.e. products, languages)
 */
function localizeSortFacet(facet: FSFacet, label: TaxonomyLabel) {
	// localize
	facet.name = taxonomyNames?.[label]?.[facet.value as LocString] || facet.value;

	if (facet.children && facet.children.length) {
		// localize children
		facet.children.map(facet => {
			localizeSortFacet(facet, label);
		});
		// sort children
		if (facet.children.length > 1) {
			facet.children.sort(sortFacetsByName);
		}
	}
	return facet;
}

import { loc_headers, loc_parameters } from '@msdocs/strings';
import { EventBus } from '../event-bus';
import { RestAddParamEvent, RestParamValueChanged } from './events';
import { renderEmptyParam, renderParam } from './param';
import { Parameter } from './types';

export function renderParamList(
	container: HTMLElement,
	bus: EventBus,
	params: Parameter[],
	isHeaders: boolean = true,
	urlParams: { [name: string]: string } = {}
) {
	const blockDiv = document.createElement('div');

	const columnsDiv = document.createElement('div');
	columnsDiv.classList.add('columns');

	const columnDiv = document.createElement('div');
	columnDiv.classList.add('column', 'is-5', 'param-heading');

	const heading = document.createElement('h3');
	if (isHeaders) {
		heading.textContent = loc_headers;
	} else {
		heading.textContent = loc_parameters;
	}
	columnDiv.appendChild(heading);
	columnsDiv.appendChild(columnDiv);
	blockDiv.appendChild(columnsDiv);

	const paramFormGroup = document.createElement('div');
	paramFormGroup.classList.add('param-form-group');

	for (const param of params) {
		renderParam(paramFormGroup, bus, param, isHeaders, urlParams);
	}

	renderEmptyParam(paramFormGroup, bus, isHeaders);

	blockDiv.appendChild(paramFormGroup);

	container.appendChild(blockDiv);

	const handleAddParam = (event: RestAddParamEvent) => {
		if ((event.param.in === 'header' && isHeaders) || (event.param.in !== 'header' && !isHeaders)) {
			renderParam(paramFormGroup, bus, event.param, isHeaders);
			renderEmptyParam(paramFormGroup, bus, isHeaders);
			bus.publish(new RestParamValueChanged());
		}
	};

	// Listen for RestAddParamEvent event
	bus.subscribe(RestAddParamEvent, handleAddParam);

	return paramFormGroup;
}

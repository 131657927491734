import { loc_more } from '@msdocs/strings';
import { checkIsArchived } from '../archive';
import { NavItem, NavLink, NavMenu } from '../components/navbar/model';
import { apis } from '../environment/apis';
import { traits } from '../environment/traits';
import { fetchWithTimeout } from '../fetch';
import { msDocs } from '../globals';
import { getMeta } from '../meta';
import { parseQueryString, toQueryString } from '../query-string';
import { UhfData, UhfParseResult } from './model';

/**
 * Get the content nav JSON from the docs repository.
 * Localization and locale/region fallback is handled by the hosting layer.
 */
export async function fetchContentNav(
	headerId: string,
	locale: string = msDocs.data.userLocale
): Promise<UhfParseResult> {
	// Don't get with traits.accessLevel - this is a Docs artifact.
	const query = { branch: getBranch() };
	const docset = 'content-nav';
	const url = `${apis.contentNav}/${locale}/${docset}/${headerId}.json?${toQueryString(query)}`;

	const response = await fetchWithTimeout(url, { credentials: 'include' });
	if (!response.ok) {
		const error: UhfError = new Error(
			`Content navigation JSON not found for ${headerId} in ${locale}.`
		) as any;
		error.headerId = headerId;
		throw error;
	}

	const result: UhfParseResult = await response.json();

	// For azure header, change call to action link for isolated environment
	if (traits.accessLevel === 'isolated' && headerId === 'azure' && result.callToAction) {
		if (
			result.callToAction.secondary &&
			result.callToAction.secondary.href.toLowerCase().indexOf('portal.azure.com') !== -1
		) {
			result.callToAction.secondary.href = `https://${traits.azurePortalHostname}`;
		}
	}
	// Add final "More" overflow menu to model.
	result.items.push({ kind: 'menu', title: loc_more, items: [] });

	return result;
}

export interface UhfError extends Error {
	headerId: string;
}

export function getHeaderId(): string {
	return checkIsArchived()
		? 'MSDocsHeader-Archive'
		: msDocs.data.context.uhfHeaderId || getMeta('uhfHeaderId') || 'MSDocsHeader-DocsL1';
}

let uhf: Promise<UhfParseResult>;

export function getContentNav() {
	if (uhf === undefined) {
		uhf = fetchContentNav(getHeaderId());
	}
	return uhf;
}

/**
 * Get a NavLink from an anchor element.
 * @param anchor The anchor whose attributes or child attributes make up the NavLink
 */
export function getUhfLink(anchor: HTMLAnchorElement): NavLink {
	return anchor
		? {
				kind: 'link',
				href: anchor.href,
				title: anchor.firstElementChild
					? anchor.firstElementChild.textContent.trim()
					: anchor.textContent.trim()
		  }
		: null;
}

export function parseUhfData(uhf: UhfData): UhfParseResult {
	const uhfDoc = parseStringToHtmlDocument(uhf.headerHTML);
	const primaryCta = getUhfLink(
		uhfDoc.getElementById('c-uhf-nav-cta')
			? (uhfDoc.getElementById('c-uhf-nav-cta').firstElementChild as HTMLAnchorElement)
			: null
	);
	const parseResult: UhfParseResult = {
		category: getUhfLink(uhfDoc.getElementById('uhfCatLogo') as HTMLAnchorElement),
		items: [],
		callToAction: primaryCta ? { primary: primaryCta } : undefined,
		cookieBanner: uhfDoc.getElementById('msccBanner') as Element
	};

	buildMenuItems(uhfDoc, parseResult.items);
	return parseResult;
}

export function parseStringToHtmlDocument(markup: string): Document {
	const parser = new DOMParser();
	return parser.parseFromString(markup, 'text/html');
}

/**
 * Build the menu tree for the nav menus.
 * @param uhfDoc The document being parsed for nav menus.
 * @param menuItems The array to collect the top level menus.
 */
export function buildMenuItems(uhfDoc: Document, menuItems: NavItem[]) {
	let linkedItems: MenuNode[] = [];

	for (const link of Array.from(uhfDoc.querySelector('nav ul').children)) {
		if (!isLinkAValidMenuItem(link)) {
			continue;
		}

		if (link.querySelector('button')) {
			if (link.classList.contains('overflow-menu')) {
				continue;
			}
			const menuItem = getUhfMenu(link);
			menuItems.push(menuItem);
			linkedItems.push({ link, items: menuItem.items });
			linkedItems = buildChildMenuItems(linkedItems);
		} else if (isValidMenuLeaf(link)) {
			menuItems.push(getUhfLink(link.firstElementChild as HTMLAnchorElement));
		}
	}

	menuItems.push({ kind: 'menu', title: loc_more, items: [] });
}

/**
 * Build the descendant menu tree for the children of the element that abstracts a menu item.
 * @param linkedItems The array of the associations between the element that abstracts a menu item, and its menu items.
 */
export function buildChildMenuItems(linkedItems: MenuNode[]): MenuNode[] {
	while (linkedItems.length) {
		const { link, items } = linkedItems[0];
		if (isSubMenu(link)) {
			// not the #overflow-menu container where children may be empty with the li element hidden
			for (const childLink of Array.from(
				link.querySelector('button').nextElementSibling.children
			)) {
				if (isSubMenu(childLink)) {
					const menuItem = getUhfMenu(childLink);
					items.push(menuItem);
					Array.from(childLink.querySelector('button').nextElementSibling.children).forEach(link =>
						linkedItems.push({ link, items: menuItem.items })
					);
				} else if (isValidMenuLeaf(childLink)) {
					items.push(getUhfLink(childLink.firstElementChild as HTMLAnchorElement));
				}
			}
		} else if (isValidMenuLeaf(link)) {
			items.push(getUhfLink(link.firstElementChild as HTMLAnchorElement));
		}

		linkedItems = linkedItems.slice(1);
	}

	return linkedItems;
}

/**
 * Check that the element that abstracts a menu item should be parsed for its nav menus.
 * @param link The element that abstracts a menu item.
 */
export function isLinkAValidMenuItem(link: Element): boolean {
	if (
		link.classList.contains('single-link') &&
		link.classList.contains('x-hidden-none-mobile-vp')
	) {
		return false;
	}

	return link.id !== 'c-uhf-nav-cta';
}

/**
 * Checks that the element that abstracts a menu item has menu items.
 * @param link The element that abstracts a menu item.
 */
export function isSubMenu(link: Element): boolean {
	return (
		link.querySelector('button') &&
		link.querySelector('button').nextElementSibling.children.length > 0
	);
}

/**
 * Checks that the element that abstracts a menu item is a leaf menu item.
 * @param link The element that abstracts a menu item.
 */
export function isValidMenuLeaf(link: Element): boolean {
	return (
		link.firstElementChild &&
		link.firstElementChild instanceof HTMLAnchorElement &&
		link.classList.contains('single-link')
	);
}

function getUhfMenu(link: Element): NavMenu {
	return {
		kind: 'menu',
		title: link.querySelector('button').textContent.trim(),
		items: []
	};
}

export function getBranch() {
	if (traits.reviewFeatures === true) {
		const params = parseQueryString(location.search);
		return params['contentnav-branch'] || 'master';
	}

	if (traits.accessLevel === 'local') {
		return 'master';
	}

	return null;
}

interface MenuNode {
	link: Element;
	items: NavItem[];
}

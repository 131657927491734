import { loc_search } from '@msdocs/strings';
import { features } from '../../environment/features';
import { generateElementId } from '../../html';
import { useApiSearch, useMonikerPicker } from '../../toc-setup';
import { TocNodeAccessor } from '../toc-node-accessor';
import { TocNode } from '../types';
import { createApiSearchAutocomplete } from './api-browser';
import { createTocAutocomplete } from './toc';

/**
 * Create the TOC search form.
 */
export function createTocSearchForm(nodes: TocNode[], accessor: TocNodeAccessor) {
	// Create the form.
	const form = document.createElement('form');
	form.action = 'javascript:';
	form.setAttribute('role', 'search');
	form.setAttribute('aria-label', loc_search);

	// Create the label for the autocomplete element's input.
	const label = document.createElement('label');
	form.appendChild(label);
	label.classList.add('visually-hidden');
	label.textContent = loc_search;
	label.htmlFor = generateElementId();

	// Create the autocomplete element.
	const autocomplete =
		useMonikerPicker && useApiSearch && features.apiBrowser
			? createApiSearchAutocomplete(label.htmlFor)
			: createTocAutocomplete(label.htmlFor, nodes, accessor);
	form.appendChild(autocomplete);

	return form;
}

import { document, window } from './globals';

/* hover images */
const hoverImgUrls: string[] = [];
const hoverImgEls: HTMLElement[] = [];

export function loadHoverImages() {
	detectTouch();
	if ($('html.hasTouch').length || $('html.highContrast').length) {
		return;
	}

	//TODO: check for SVG support
	setTimeout(function () {
		$('img[data-hoverimage]').each(function () {
			const $this = $(this);

			hoverImgUrls.push($this.attr('data-hoverimage'));
			hoverImgEls.push(this);
		});

		loadHoverImg();
	}, 20);
}

function resetHover(el: HTMLElement) {
	const $img = $(el).find('.cardImage');
	$img.css('background-position', '0 0');
}

function loadHoverImg() {
	if (hoverImgUrls.length > 0) {
		const imgUrl = hoverImgUrls[0];

		const img = new Image();

		img.onload = setHoverImage;
		img.src = imgUrl;
	}
}

function setHoverImage() {
	const imgUrl = hoverImgUrls.shift();
	const imgEl = hoverImgEls.shift();

	const $this = $(imgEl);
	const $card = $this.parent();
	$card.css({
		'background-image': 'url(' + imgUrl + ')',
		'background-size': 'cover'
	});

	$this
		.parents('.card')
		.mouseover(function () {
			const $img = $(this).find('.cardImage');
			$img.css('background-position', '-' + $card.width() + 'px 0');
		})
		.mouseout(function () {
			resetHover(this);
		})
		.click(function () {
			resetHover(this);
		});

	setTimeout(function () {
		$card.parent().parent().addClass('ready');
		$this.fadeOut('fast');
	}, 20);

	loadHoverImg();
}

/* end hover images */

export function loadScaleImages() {
	if ($('html.highContrast').length) {
		return;
	}

	//TODO: check for SVG support
	$('img[data-scaleimage]').each(function () {
		const $this = $(this);
		const $card = $this.parent();
		$this.attr('role', 'presentation');
		$card
			.css('background-image', 'url(' + $this.attr('data-scaleimage') + ')')
			.addClass('cardScaleImage');

		$this.hide();
	});
}

export function detectTouch() {
	const html = document.documentElement;

	let className = html.className;

	const w = window as any;

	if ('ontouchstart' in w || (w.DocumentTouch && document instanceof w.DocumentTouch)) {
		className += ' hasTouch';
	} else {
		className += ' noTouch';
	}

	html.className = className;
}

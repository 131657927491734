/***************************************************************************
 * Utilities optimized for bulk processing of toc.json URL data.
 * Robustness has been traded for performance.
 * Not for general use. Do not use outside of the TOC!
 ***************************************************************************/
import { location, msDocs } from '../globals';
import { checkLocaleSupported, pathLocaleRegex } from '../locale';
import { parseUrl } from '../query-string';

/**
 * Breaks a relative url into it's components.
 * Hate to do it this way but the URL constructor and anchor element
 * are too slow for parsing large numbers of URLs, especially in
 * Internet Explorer.
 *
 * rel: the relative part of the pathname component. eg "/", "./", "../", "./../", "../../"
 * subpath: the rest of the pathname component.
 * search: the query string component.
 * hash: the hash/fragment component.
 *
 *                                    rel                search
 *                                    |        subpath   |         hash
 *                                    |        |         |         |
 */ export const relativeUrlRegex = /^([.\/]*)?([^\?#]*)?(\?[^#]*)?(#.*)?$/;

/**
 * Matches a pathname's trailing "index" segment.
 */
export const indexSegmentRegex = /(^|\/)index$/i;

/**
 * Identify a local content build without falsely identifying
 * a local instance of docs-render using content from the content proxy.
 */
const isLocalBuild = location.hostname === 'localhost' && msDocs.environment === undefined;

/**
 * Resolved base URLs. This data structure is used
 * to quickly lookup a base URL's origin and "rel map".
 * The rel map is used to quickly map "../" strings to
 * their associated absolute path.
 */
const baseUrls: {
	[baseUrl: string]: {
		/** The origin of the base URL. */
		origin: string;
		/** A mapping of rels to base pathnames. */
		relMap: { [rel: string]: string };
	};
} = {};

/**
 * Caches a base URL's origin and base path lookup.
 */
export function cacheBaseUrl(baseUrl: string, locale: string) {
	const { origin, pathname } = parseUrl(baseUrl);
	const relMap: { [rel: string]: string } = { '/': `/${locale}/` };
	const segments = pathname.split('/');
	let rel = '';
	while (segments.length > 2) {
		segments.pop();
		const pathname = segments.join('/') + '/';
		relMap[rel] = pathname;
		relMap['./' + rel] = pathname; // needed?
		rel += '../';
	}
	return { origin, relMap };
}

export interface OriginAndPathname {
	origin: string;
	pathname: string;
}

/**
 * A URL-like data structure.
 */
export class SimpleUrl {
	constructor(
		public readonly external: boolean,
		public readonly origin: string,
		public readonly pathname: string,
		public search: string,
		public readonly hash: string
	) {}

	public get href() {
		return this.origin + this.pathname + this.search + this.hash;
	}

	public originAndPathnameEquals({ origin, pathname }: OriginAndPathname) {
		return (
			this.pathname.length === pathname.length && // check length before doing localeCompare because localeCompare is expensive.
			this.origin.length === this.origin.length &&
			this.pathname.localeCompare(pathname, undefined, { sensitivity: 'base' }) === 0 &&
			this.origin.localeCompare(origin, undefined, { sensitivity: 'base' }) === 0
		);
	}

	public originAndPathnameStartsWith({ origin, pathname }: OriginAndPathname) {
		return (
			this.pathname.length >= pathname.length &&
			(pathname[pathname.length - 1] === '/' ||
				this.pathname[pathname.length] === undefined ||
				this.pathname[pathname.length] === '/') &&
			this.origin.length === origin.length &&
			this.pathname
				.substr(0, pathname.length)
				.localeCompare(pathname, undefined, { sensitivity: 'base' }) === 0 &&
			this.origin.localeCompare(origin, undefined, { sensitivity: 'base' }) === 0
		);
	}
}

/**
 * Resolves a relative or absolute URL. Supports internal or external URLs.
 * Normalizes pathname, ensuring index is stripped and locale is specified.
 * @param relativeOrAbsoluteUrl The URL to be resolved.
 * @param baseUrl The URL from which relative URLs should be resolved.
 * @param locale The locale to add to internal URLs which do not have the locale segment.
 */
export function resolveUrl(
	relativeOrAbsoluteUrl: string,
	baseUrl: string,
	locale = msDocs.data.userLocale,
	pageOrigin = location.origin
): SimpleUrl {
	// Do we have an absolute URL?
	// hrefs that start with "//" or have a colon (:) at the fourth or fifth
	// character are considered absolute.
	// hrefs that start with a host like "www.foo.com/bla/bla" are not
	// supported here or in the legacy TOC logic.
	const [c0, c1, , , c4, c5] = relativeOrAbsoluteUrl as any;
	if ((c0 === '/' && c1 === '/') || c4 === ':' || c5 === ':') {
		// Use the slow but robust URL parser.
		let { origin, pathname, search, hash } = parseUrl(relativeOrAbsoluteUrl);

		// Is the URL external?
		if (origin !== pageOrigin) {
			// Stop processing.
			return new SimpleUrl(true, origin, pathname, search, hash);
		}

		// Ensure the first segment of the path is a supported locale.
		const match = pathLocaleRegex.exec(pathname);
		if (!match || !checkLocaleSupported(match[1])) {
			pathname = '/' + locale + pathname;
		}

		// Strip the trailing "index", decode the pathname.
		pathname = decodeURIComponent(pathname.replace(indexSegmentRegex, '$1'));

		return new SimpleUrl(false, origin, pathname, search, hash);
	}

	// We have a relative URL... quick-parse mode:

	// Lookup the origin and rel map.
	if (baseUrls[baseUrl] === undefined) {
		baseUrls[baseUrl] = cacheBaseUrl(baseUrl, locale);
	}
	const { origin, relMap } = baseUrls[baseUrl];

	// Parse the relative path.
	const match = relativeUrlRegex.exec(relativeOrAbsoluteUrl);
	let [, rel = '', subpath = '', search = '', hash = ''] = match;

	// Map the relative part of the path to an absolute path.
	const basePath = relMap[rel];

	// Local builds use html extension.
	if (isLocalBuild) {
		subpath = subpath.replace(/\.html$/i, '');
	}

	// Strip the trailing "index".
	let normalizedSubpath = subpath.replace(indexSegmentRegex, '$1');

	// Decode if necessary... this is slow.
	if (normalizedSubpath.indexOf('%') !== -1) {
		normalizedSubpath = decodeURIComponent(normalizedSubpath);
	}

	const pathname = basePath + normalizedSubpath;

	return new SimpleUrl(false, origin, pathname, search, hash);
}

/**
 * Normalizes a URL for comparison with TOC URLs.
 */
export function normalizeLocation({ origin, pathname, search, hash } = location) {
	pathname = decodeURIComponent(pathname).toLowerCase().replace(indexSegmentRegex, '$1');
	return new SimpleUrl(false, origin, pathname, search, hash);
}

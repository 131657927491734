import {
	loc_assessment_homeLocaleNotFound,
	loc_assessment_questionnaireLocaleNotFound
} from '@msdocs/strings';
import { msDocs } from '../globals';
import { AssessmentViewModel } from './view-model';
import { HomeNavigation } from './types';
import { notifyContentUpdated } from '../affix';
import { showDisclaimer } from '../disclaimer';

const assessmentDisclaimers: HTMLDivElement[] = [];

export function showQuestionnaireDisclaimer(viewModel: AssessmentViewModel) {
	removeAssessmentDisclaimers();

	const userLocale = msDocs.data.userLocale;
	const assessmentLocale = viewModel.assessment.locale;
	if (assessmentLocale !== userLocale && /^en/.test(assessmentLocale) && !/^en/.test(userLocale)) {
		addAssessmentDisclaimer(loc_assessment_questionnaireLocaleNotFound);
	}
}

export async function showHomeDisclaimer(action: HomeNavigation) {
	removeAssessmentDisclaimers();

	const userLocale = msDocs.data.userLocale;
	const list = await action.assessmentList;
	if (
		!/^en/.test(userLocale) &&
		list.some(assessment => assessment.locale !== userLocale && /^en/.test(assessment.locale))
	) {
		addAssessmentDisclaimer(loc_assessment_homeLocaleNotFound);
	}

	if (action.reason) {
		addAssessmentDisclaimer(action.reason);
	}
}

function addAssessmentDisclaimer(message: string) {
	assessmentDisclaimers.push(showDisclaimer(message));
}

function removeAssessmentDisclaimers() {
	const mustUpdate = assessmentDisclaimers.length > 0;

	let assessmentDisclaimer = assessmentDisclaimers.pop();
	while (assessmentDisclaimer) {
		assessmentDisclaimer.parentElement.removeChild(assessmentDisclaimer);
		assessmentDisclaimer = assessmentDisclaimers.pop();
	}

	if (mustUpdate) {
		notifyContentUpdated();
	}
}

import { capturePageAction } from '../bi';
import { keyCodes } from '../key-codes';

/**
 * A collection of functions to access properties of a tree node.
 * Used to decouple the tree UI component from the specifics
 * of the backing data.
 */
export interface TreeNodeAccessor<TNode> {
	hasChildren(node: TNode): boolean;
	children(node: TNode): TNode[] | undefined;
	htmlTitle(node: TNode): string;
	textTitle(node: TNode): string;
	href(node: TNode): string;
	isNewSection(node: TNode): boolean;
	isExpanded(node: TNode): boolean;
	isSelected(node: TNode): boolean;
	setHtmlAttributes(node: TNode, set: (name: string, value: string) => void): void;
}

/**
 * Create a tree component.
 * https://www.w3.org/TR/wai-aria-practices-1.1/examples/treeview/treeview-2/treeview-2a.html
 * @param nodes The tree nodes.
 * @param accessor Accessor function for tree node properties.
 * @param label The aria-label for the tree.
 */
export function createTree<TNode>(
	nodes: TNode[],
	accessor: TreeNodeAccessor<TNode>,
	label: string
) {
	return createTreeGeneric(nodes, accessor, label, true);
}

/**
 * Create a static tree component.
 * https://www.w3.org/WAI/GL/wiki/Using_the_WAI-ARIA_aria-expanded_state_to_mark_expandable_and_collapsible_regions#Example_3:_Omitting_aria-expanded_produces_a_static_tree
 *
 * @param nodes The tree nodes.
 * @param accessor Accessor function for tree node properties.
 * @param label The aria-label for the tree.
 */
export function createTreeStatic<TNode>(
	nodes: TNode[],
	accessor: TreeNodeAccessor<TNode>,
	label: string
) {
	return createTreeGeneric(nodes, accessor, label, false);
}

/**
 * Sets the focus target of the tree to the selected node if available, otherwise the first node.
 */
export function resetFocusTarget(tree: Element) {
	let treeItem = tree.querySelector('.tree-item.is-selected');
	if (!treeItem) {
		const supportsCollapsing = getIsCollapsible(tree);
		treeItem = supportsCollapsing
			? tree.querySelector('.tree-item')
			: tree.querySelector('.tree-item.is-leaf');
	}
	if (treeItem) {
		updateTabindexes(treeItem);
	}
}

export function resetEventListeners<TNode>(
	tree: HTMLUListElement,
	accessor: TreeNodeAccessor<TNode>
) {
	tree.addEventListener('focus', focusHandler, true);
	tree.addEventListener('click', event => clickHandler(event, accessor), true);
	tree.addEventListener('keydown', event => keydownHandler(event, accessor), true);
}

function createTreeGeneric<TNode>(
	nodes: TNode[],
	accessor: TreeNodeAccessor<TNode>,
	label: string,
	supportsCollapsing: boolean
) {
	const tree = document.createElement('ul');
	tree.classList.add('tree');
	tree.setAttribute('role', 'tree');
	tree.setAttribute('aria-label', label);
	tree.setAttribute('data-bi-name', 'tree');
	tree.setAttribute('data-is-collapsible', supportsCollapsing ? 'true' : 'false');

	renderTreeBranch(tree, nodes, accessor);
	resetFocusTarget(tree);
	resetEventListeners(tree, accessor);

	return tree;
}

function renderTreeBranch<TNode>(
	ul: HTMLUListElement,
	nodes: TNode[],
	accessor: TreeNodeAccessor<TNode>,
	level: number = 1
) {
	const supportsCollapsing = getIsCollapsible(ul);

	// Posinset is 1-based, rather than 0 based. Increments once for each treeitem in a branch
	let posinset = 1;
	for (const node of nodes) {
		const li = document.createElement('li');
		const a = document.createElement('a');
		ul.appendChild(li);
		if (accessor.isNewSection(node)) {
			li.classList.add('has-border-top');
		}
		accessor.setHtmlAttributes(node, (name, value) => li.setAttribute(name, value));
		const htmlTitle = accessor.htmlTitle(node);
		if (accessor.hasChildren(node)) {
			// Inner node.
			setNode(li, node);
			li.classList.add('tree-item');
			li.setAttribute('aria-setsize', nodes.length.toString());
			li.setAttribute('aria-level', level.toString());
			li.setAttribute('aria-posinset', posinset.toString());
			li.setAttribute('role', 'treeitem');
			li.setAttribute('tabindex', '-1');
			li.setAttribute('id', `title-${posinset}-${level}`);
			if (supportsCollapsing) {
				li.setAttribute('aria-expanded', 'false');
			}

			const expander = document.createElement('span');
			li.appendChild(expander);
			expander.setAttribute('data-bi-name', 'tree-expander');
			if (supportsCollapsing) {
				expander.className = 'tree-expander';
				const indicator = document.createElement('span');
				expander.appendChild(indicator);
				indicator.className = 'tree-expander-indicator docon docon-chevron-right-light';
				indicator.setAttribute('aria-hidden', 'true');
			}
			expander.insertAdjacentHTML('beforeend', htmlTitle);

			if (!supportsCollapsing || accessor.isExpanded(node)) {
				setExpanded(li, true, accessor);
			}

			posinset++;
			continue;
		}
		// Leaf node.
		a.setAttribute('aria-setsize', nodes.length.toString());
		a.setAttribute('aria-level', level.toString());
		a.setAttribute('aria-posinset', posinset.toString());
		a.setAttribute('role', 'treeitem');
		a.setAttribute('tabindex', '-1');
		if (!supportsCollapsing && ul.parentElement && ul.parentElement.id) {
			a.setAttribute('aria-describedby', ul.parentElement.id);
		}
		li.setAttribute('role', 'none');
		setNode(a, node);
		li.appendChild(a);
		a.classList.add('tree-item', 'is-leaf');
		a.setAttribute('data-bi-name', 'tree-leaf');
		a.href = accessor.href(node);
		a.innerHTML = htmlTitle;
		if (accessor.isSelected(node)) {
			a.classList.add('is-selected');
			a.setAttribute('aria-current', 'page');
		}
		if (a.querySelector('.icon') != null) {
			a.classList.add('has-icon');
		}
		posinset++;
	}
}

/**
 * Stash the node data on the treeitem element.
 */
function setNode(treeItem: Element, node: any) {
	(treeItem as any).node = node;
}

/**
 * Retrieve the node data that was stashed on the treeitem element.
 */
function getNode(treeItem: Element): any {
	return (treeItem as any).node;
}

function getIsCollapsible(treeItem: Element) {
	return treeItem.closest('.tree').getAttribute('data-is-collapsible') !== 'false';
}

function getExpanded(treeItem: Element) {
	return treeItem.getAttribute('aria-expanded') === 'true' || !getIsCollapsible(treeItem);
}

function setExpanded(treeItem: Element, expanded: boolean, accessor: TreeNodeAccessor<any>) {
	const level = treeItem.getAttribute('aria-level');
	const parentLevel = level ? parseInt(level, 10) : 1;
	const nextLevel = parentLevel + 1;
	const supportsCollapsing = getIsCollapsible(treeItem);

	if (!supportsCollapsing && !expanded) {
		// You can't collapse the node if the element does not supports it
		return;
	}

	if (supportsCollapsing) {
		// Only set aria-expanded attribute when element supports collapsing
		treeItem.setAttribute('aria-expanded', expanded.toString());
	}
	treeItem.classList[expanded ? 'add' : 'remove']('is-expanded');

	if (!expanded || treeItem.lastElementChild instanceof HTMLUListElement) {
		return;
	}

	// render children
	const node = getNode(treeItem);
	const ul = document.createElement('ul');
	ul.classList.add('tree-group');
	ul.setAttribute('role', 'group');
	treeItem.appendChild(ul);
	const children = accessor.children(node);
	renderTreeBranch(ul, children, accessor, nextLevel);
}

/**
 * Ensures the specified treeitem is the only element with tabindex=0.
 */
function updateTabindexes(treeItem: Element) {
	const tree = treeItem.closest('.tree');
	Array.from(tree.querySelectorAll('[tabindex="0"]')).forEach(el =>
		el.setAttribute('tabindex', '-1')
	);
	treeItem.setAttribute('tabindex', '0');
}

/**
 * Finds the next treeitem relative to the specified treeitem.
 * Skips collapsed or hidden elements.
 */
function findNext(treeItem: Element, direction: 'following' | 'preceding') {
	const tree = treeItem.closest('.tree');
	const supportsCollapsing = getIsCollapsible(treeItem);
	const selector = supportsCollapsing
		? ':not([aria-expanded="false"]) [role="treeitem"]'
		: '[role="treeitem"] .is-leaf';
	const visibleTreeItems = Array.from(tree.querySelectorAll(selector));

	if (direction === 'preceding') {
		visibleTreeItems.reverse();
	}
	const positionMask =
		direction === 'preceding' ? Node.DOCUMENT_POSITION_PRECEDING : Node.DOCUMENT_POSITION_FOLLOWING;
	return visibleTreeItems.find(t => {
		return (
			treeItem.compareDocumentPosition(t) & positionMask && // is in the next position.
			t.closest('.tree [aria-expanded="false"] [role="treeitem"]') !== t && // is not inside a collapsed node.
			isDisplayed(t.closest('li'))
		); // is not hidden by other styles like data-moniker.
	}) as HTMLElement;
}

function isDisplayed(element: Element) {
	return window.getComputedStyle(element).display !== 'none';
}

function focusHandler({ target }: FocusEvent) {
	const treeItem = target instanceof HTMLElement && target.closest('[role="treeitem"]');
	if (!treeItem) {
		return;
	}
	updateTabindexes(treeItem);
}

function clickHandler<TNode>({ target }: Event, accessor: TreeNodeAccessor<TNode>) {
	// was the click on something interesting?
	const clickable = target instanceof HTMLElement && target.closest('.tree-expander, a');
	if (!clickable) {
		return;
	}

	// get the treeitem associated with the click.
	let treeItem: Element;
	if (clickable instanceof HTMLAnchorElement) {
		treeItem = clickable;
	} else {
		treeItem = clickable.parentElement;
		const isExpanded = getExpanded(treeItem);
		setExpanded(treeItem, !isExpanded, accessor);
		capturePageAction(target, isExpanded ? 'REDUCE' : 'EXPAND', 'CLICKLEFT');
	}

	// fire a tree-item-clicked event, using the associated node as the payload.
	const detail = getNode(treeItem);
	const treeItemClicked = new CustomEvent<TNode>('tree-item-clicked', { detail, bubbles: true });
	clickable.closest('.tree').dispatchEvent(treeItemClicked);
}

function keydownHandler(event: KeyboardEvent, accessor: TreeNodeAccessor<any>) {
	const { target, keyCode, shiftKey, altKey, ctrlKey } = event;

	if (
		altKey ||
		ctrlKey ||
		(shiftKey && keyCode !== keyCodes.eight && !(keyCode >= keyCodes.a && keyCode <= keyCodes.b))
	) {
		return;
	}

	const treeItem = target instanceof HTMLElement && target.closest('[role="treeitem"]');
	if (!treeItem) {
		return;
	}

	const isLeaf = treeItem instanceof HTMLAnchorElement;
	const isExpanded = !isLeaf && getExpanded(treeItem);
	const supportsCollapsing = getIsCollapsible(treeItem);

	if (keyCode === keyCodes.enter || keyCode === keyCodes.space) {
		if (isLeaf || !supportsCollapsing) {
			return;
		}
		setExpanded(treeItem, !isExpanded, accessor);
		capturePageAction(
			event.target,
			isExpanded ? 'REDUCE' : 'EXPAND',
			keyCode === keyCodes.enter ? 'KEYBOARDENTER' : 'KEYBOARDSPACE'
		);
		event.preventDefault();
		return;
	}

	if (keyCode === keyCodes.right) {
		if (isLeaf || !supportsCollapsing) {
			return;
		}
		if (isExpanded) {
			const firstChild = treeItem.querySelector('[role="treeitem"]') as HTMLElement;
			firstChild.focus();
			updateTabindexes(firstChild);
		} else {
			setExpanded(treeItem, true, accessor);
			capturePageAction(event.target, 'EXPAND', 'OTHER');
		}
		event.preventDefault();
		return;
	}

	if (keyCode === keyCodes.left) {
		if (!supportsCollapsing) {
			return;
		}

		if (isExpanded) {
			setExpanded(treeItem, false, accessor);
			capturePageAction(event.target, 'REDUCE', 'OTHER');
			event.preventDefault();
		} else {
			const closestAncestor = treeItem.parentElement.closest('[role="treeitem"]') as HTMLElement;
			if (closestAncestor) {
				closestAncestor.focus();
				updateTabindexes(closestAncestor);
				event.preventDefault();
			}
		}
		return;
	}

	if (keyCode === keyCodes.down || keyCode === keyCodes.up) {
		const direction = keyCode === keyCodes.down ? 'following' : 'preceding';
		const next = findNext(treeItem, direction);
		if (next) {
			next.focus();
			updateTabindexes(next);
			event.preventDefault();
		}
		return;
	}

	if (keyCode === keyCodes.home || keyCode === keyCodes.end) {
		const isHome = keyCode === keyCodes.home;
		const tree = treeItem.closest('.tree');

		let home: HTMLElement;
		if (supportsCollapsing) {
			const navProp = isHome ? 'firstElementChild' : 'lastElementChild';
			home = tree[navProp].firstElementChild.closest('[role="treeitem"]') as HTMLElement;
			if (!isDisplayed(home)) {
				home = findNext(home, isHome ? 'following' : 'preceding');
			}
		} else {
			const nodes = tree.querySelectorAll('.tree-item.is-leaf');
			const index = isHome ? 0 : nodes.length - 1;
			home = nodes[index] as HTMLElement;
		}

		home.focus();
		updateTabindexes(home);
		event.preventDefault();
		return;
	}

	if (keyCode === keyCodes.numPadAsterisk || (keyCode === keyCodes.eight && shiftKey)) {
		if (!supportsCollapsing) {
			return;
		}

		const ul = treeItem.closest('ul');
		for (let i = 0; i < ul.children.length; i++) {
			const li = ul.children.item(i);
			if (li.matches('[role="treeitem"][aria-expanded="false"]')) {
				setExpanded(li, true, accessor);
				capturePageAction(event.target, 'EXPAND', 'OTHER');
			}
		}
		event.preventDefault();
		return;
	}
}

import { document, msDocs } from './globals';
import { getVisibleTextContent } from './text-formatting';

let h2Headings: { element: HTMLHeadingElement; anchor: HTMLAnchorElement }[] = [];
let sectionIndicatorEnabled: boolean = false;
let ignoreScrollOnce: boolean = false;
let ignoreContentUpdateUntilScroll: boolean = false;

export function renderInTopicTOC() {
	const centerContainer = document.getElementById('center-doc-outline') as HTMLElement;
	const sideContainer = document.getElementById('side-doc-outline') as HTMLElement;
	const containers = [centerContainer, sideContainer];

	if (centerContainer === null || sideContainer === null) {
		return;
	}

	const headings = Array.from(
		document.querySelectorAll('#main h2') as NodeListOf<HTMLHeadingElement>
	).filter(h => h.offsetParent !== null);
	const minHeadings = msDocs.data.pageTemplate === 'NamespaceListPage' ? 2 : 1;
	const hide = headings.length < minHeadings;

	containers.forEach(container => {
		container.hidden = hide;
		if (container.lastElementChild.nodeName === 'OL') {
			container.removeChild(container.lastElementChild);
		}
	});

	if (hide) {
		return;
	}

	const ol = document.createElement('ol');
	h2Headings = [];
	for (const heading of headings) {
		const text = getVisibleTextContent(heading).trim();

		if (heading.id.length === 0 || !heading.id) {
			heading.id = text.toLowerCase().replace(/\s+/g, '-');
		}

		const a = document.createElement('a');
		a.href = '#' + heading.id;
		a.textContent = text;

		const li = document.createElement('li');
		li.appendChild(a);
		ol.appendChild(li);

		h2Headings.push({ element: heading, anchor: a });
	}

	sideContainer.appendChild(ol);
	centerContainer.appendChild(ol.cloneNode(true));

	if (h2Headings.length <= 1) {
		return;
	}
	if (!sectionIndicatorEnabled) {
		sideContainer.addEventListener('click', event => {
			const item =
				event.target instanceof Element && (event.target.closest('a') as HTMLAnchorElement);
			if (!item) {
				return;
			}

			ignoreScrollOnce = true;
			ignoreContentUpdateUntilScroll = true;
			selectH2ItemInSideOutline({ element: null, anchor: item });
		});

		// listen for scroll
		window.addEventListener(
			'scroll',
			() => {
				if (ignoreScrollOnce) {
					ignoreScrollOnce = false;
					return;
				}
				ignoreContentUpdateUntilScroll = false;
				scheduleUpdate();
			},
			{ passive: true } as any
		);

		// listen for manual content updates
		window.addEventListener('content-update', () => {
			if (ignoreContentUpdateUntilScroll) {
				return;
			}
			scheduleUpdate();
		});

		sectionIndicatorEnabled = true;
	}
	scheduleUpdate();
}

let animationFrame = 0;
function scheduleUpdate() {
	cancelAnimationFrame(animationFrame);
	animationFrame = requestAnimationFrame(updateH2Selection);
}

function updateH2Selection(): void {
	const selectedHeading = findDisplayedH2Item();
	selectH2ItemInSideOutline(selectedHeading);
}

function findDisplayedH2Item(): { element: HTMLHeadingElement; anchor: HTMLAnchorElement } {
	const threshold = document.documentElement.clientHeight * (1 / 2);
	for (let i = h2Headings.length - 1; i >= 0; i--) {
		const rect = h2Headings[i].element.getBoundingClientRect();
		if (rect.top + rect.height / 2 <= threshold) {
			return h2Headings[i];
		}
	}

	return h2Headings[0] || null;
}

function selectH2ItemInSideOutline(heading: {
	element: HTMLHeadingElement;
	anchor: HTMLAnchorElement;
}): void {
	if (heading === null) {
		return;
	}

	const current = document.querySelector(`#side-doc-outline > ol > li.selected`);
	if (current) {
		current.classList.remove('selected');
	}
	heading.anchor.parentElement.classList.add('selected');
}

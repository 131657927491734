import { document } from '../../globals';
import { html, render } from '../../lit-html';
import { listenUntilUnload } from '../../router/utils';
import { disableScrollbar } from './scrollbar';
import { constrainFocus, createTabSentinel, isFocusable } from './tabindex';

/**
 * Handle modal focus upon open. Delegate the focus target choice to the view by using the "autofocus"
 * HTML attribute per the HTML spec. If the "autofocus" attribute is on the root element of the view, the
 * container element itself will receive focus. If no "autofocus" attribute is present on the element children
 * or on the element itself, fall back to original focus logic.
 *
 * @see https://www.w3.org/TR/html52/sec-forms.html#autofocusing-a-form-control-the-autofocus-attribute.
 *
 */
export const setFocusInModalView = (container: HTMLElement) => {
	const autofocusTarget: HTMLElement | null = container.querySelector('[autofocus]');
	if (autofocusTarget && isFocusable(autofocusTarget)) {
		autofocusTarget.focus();
		return;
	}
	container.focus();
};

export class Modal {
	public static openCount = 0; // used for test purposes
	private wrapper: HTMLElement;
	private container: HTMLElement;
	private contentStack: Element[];
	private focusHandler: (event: FocusEvent) => void;
	private lastFocus: HTMLElement;
	private resolveOpen: (result: string | null) => void;
	private openPromise: Promise<string | null>;

	constructor(modalContent: Element) {
		// set attributes to modal parent
		this.container = document.createElement('div');
		this.container.setAttribute('tabindex', '-1');
		this.container.setAttribute('role', 'dialog');
		this.container.setAttribute('aria-modal', 'true');
		this.container.classList.add('modal', 'has-default-focus');

		// insert base code
		render(
			html`<div class="modal-background modal-close"></div>
				${modalContent} `,
			this.container
		);

		// insert into page
		this.wrapper = document.createElement('div');
		this.wrapper.insertAdjacentElement('afterbegin', this.container);

		this.contentStack = [modalContent];
	}

	public show() {
		if (this.container.classList.contains('is-active')) {
			return this.openPromise;
		}
		Modal.openCount++;

		// Create sentinel tabbable elements to prevent screen scrolling and BI events from firing
		// when use attempted to tab forward out of the modal. This also prevents focus-visible styles
		// from being applied incorrectly. This must happen before the wrapper is inserted into the DOM.
		document.body.insertAdjacentElement('afterbegin', createTabSentinel(document));

		document.body.insertAdjacentElement('afterbegin', this.wrapper);

		this.wrapper.addEventListener('click', this.clickHandler);
		this.wrapper.addEventListener('keydown', this.escHandler);
		this.restoreScrollbar = disableScrollbar(
			document.documentElement,
			(document.scrollingElement || document.documentElement) as HTMLElement,
			(document.scrollingElement || document.documentElement || document.body).scrollTop
		);
		this.container.classList.add('is-active');

		// Create sentinel tabbable elements to prevent screen scrolling and BI events from firing
		// when use attempted to shift+tab backwards out of the modal. This also prevents focus-visible
		// styles from being applied incorrectly. This must happen after the wrapper is inserted into the DOm.
		document.body.insertAdjacentElement('afterbegin', createTabSentinel(document));

		// set arialabelledBy
		this.setAriaLabelledBy(this.container);

		// Notify content is already in the DOM and about to be shown
		window.dispatchEvent(new CustomEvent('modal-show', { detail: { container: this.container } }));

		// save last focused element and then lock focus to the container
		this.lastFocus = document.activeElement as HTMLElement;
		this.focusHandler = constrainFocus(this.wrapper);
		listenUntilUnload(window, 'focus', this.focusHandler, true);

		setFocusInModalView(this.container);

		this.openPromise = new Promise<string | null>(resolve => (this.resolveOpen = resolve));
		return this.openPromise;
	}

	public hide(result: string | null = null) {
		if (!this.container.classList.contains('is-active')) {
			return;
		}
		Modal.openCount--;

		this.restoreScrollbar();

		this.container.classList.remove('is-active');
		this.wrapper.parentElement.removeChild(this.wrapper);

		Array.from(document.body.querySelectorAll('.modal-tab-sentinel')).forEach(element => {
			document.body.removeChild(element);
		});

		window.removeEventListener('focus', this.focusHandler);
		this.wrapper.removeEventListener('click', this.clickHandler);
		this.wrapper.removeEventListener('keydown', this.escHandler);

		// set focus back to last focused element if it exists // Additional checks for IE and tests
		if (this.lastFocus && this.lastFocus instanceof HTMLElement) {
			this.lastFocus.focus();
		}

		this.resolveOpen(result);
		this.resolveOpen = undefined;
		this.openPromise = undefined;
	}

	public updateContent(modalContent: HTMLElement, isAnimated: boolean = true) {
		const cardContentContainer = this.container.querySelector('.modal-slide-container');
		const firstSlide = cardContentContainer.querySelector('.modal-slide:first-of-type');

		if (cardContentContainer === null || firstSlide === null) {
			throw new Error(
				`The ${
					cardContentContainer === null ? 'modal-slide-container' : 'modal-slide'
				} class is missing!`
			);
		}

		cardContentContainer.appendChild(modalContent);

		if (isAnimated) {
			setTimeout(() => {
				// slide in after append of content
				firstSlide.classList.add('slide-left');
				modalContent.classList.add('slide-left');
			}, 50);

			setTimeout(() => {
				// Remove the old content, 250 is timed with transition speed
				modalContent.classList.remove('slide-left');
				resetModalContent(this);
			}, 250);
		} else {
			resetModalContent(this);
		}

		// Clear out old modal content and update the aria-labelledby and focus
		function resetModalContent(modal: Modal) {
			cardContentContainer.removeChild(firstSlide);
			setFocusInModalView(modal.container);
			modal.setAriaLabelledBy(modal.container);
		}
	}

	public pushContent(content: Element) {
		this.contentStack.push(content);
		render(
			html`<div class="modal-background modal-close"></div>
				${this.contentElement} `,
			this.container
		);
		this.setAriaLabelledBy(this.container);
		setFocusInModalView(this.contentElement as HTMLElement);
	}

	public popContent() {
		if (this.contentStack && this.contentStack.length <= 1) {
			throw new Error('Error getting modal content');
		}
		this.contentStack.pop();
		render(
			html`<div class="modal-background modal-close"></div>
				${this.contentElement} `,
			this.container
		);
		setFocusInModalView(this.contentElement as HTMLElement);
	}

	private restoreScrollbar: ReturnType<typeof disableScrollbar> = () => {};

	get contentElement() {
		return this.contentStack[this.contentStack.length - 1];
	}

	private clickHandler = (event: Event) => {
		const target = event.target as HTMLElement;
		const previous = target.closest('.modal-pop-content');
		const close = target.closest('.modal-close');
		if (close) {
			event.preventDefault();
			this.hide(close.getAttribute('data-modal-result'));
		} else if (previous) {
			event.preventDefault();
			this.popContent();
		}
	};

	private escHandler = (event: KeyboardEvent) => {
		if (event.keyCode === 27) {
			event.preventDefault();
			this.hide();
		}
	};

	private setAriaLabelledBy = (element: HTMLElement) => {
		// set aria-labelledby if we have text
		const firstText =
			element.querySelector('h1') ||
			element.querySelector('h2') ||
			element.querySelector('h3') ||
			element.querySelector('h4') ||
			element.querySelector('p') ||
			element.querySelector('figcaption');
		if (firstText) {
			if (!firstText.id) {
				// set the id if missing
				firstText.id = 'modal-heading';
			}
			element.setAttribute('aria-labelledby', firstText.id);
		}
	};
}

import { User, UserChangedEvent } from './user';

/**
 * Set the data-authenticated attribute based on the user's auth status.
 * This drives the "authenticated" and "not-authenticated" classes.
 */
export async function initDisplayClasses(
	user: User,
	authStatusDetermined: Promise<void>,
	container: Element
) {
	const handler = () =>
		container.setAttribute('data-authenticated', user.isAuthenticated.toString());
	handler();
	user.subscribe(UserChangedEvent, handler);

	await authStatusDetermined;
	container.setAttribute('data-auth-status-determined', 'true');
}

import { loc_loading } from '@msdocs/strings';

let nextId = 0;
export function generateElementId() {
	return `ax-${nextId++}`;
}

export function generateUuidV4() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
		.split('')
		.map(c => {
			if (c === '-' || c === '4') {
				return c;
			}

			const r = (Math.random() * 16) | 0;
			const v = c === 'x' ? r : (r & 0x3) | 0x8;
			return v.toString(16);
		})
		.join('');
}

export function createHtmlId(input: string) {
	return input ? input.toLowerCase().replace(/_/g, '-').replace(/\W/g, '-') : '';
}

export const loadingHtml = `
	<div id="spinner" class="c-progress f-indeterminate-local f-progress-large" role="progressbar" tabindex="0" aria-valuetext="${loc_loading}" aria-label="${loc_loading}">
		<span></span>
		<span></span>
		<span></span>
		<span></span>
		<span></span>
	</div>`;

import { TaxonomyLabel, taxonomyNames } from '../../name-maps/taxonomy';
import { getQueryStringMap } from '../../query-string';
import { FacetGroupViewModel } from './facet-group';
import { FacetViewModel } from './facets';
import type {
	FSFacet,
	FSFilterMap,
	FSFlatFacetMap,
	FSResponseFacets,
	FSSelectedFacetType,
	FSUIFacetGroups
} from './model';

/**
 * Take a query string map object and create a reliable selectedFacets object.
 * @param query A map object corresponding to the current url's search params.
 */
export function readUrlSelectedFacets(query: ReturnType<typeof getQueryStringMap>) {
	const selected: FSSelectedFacetType = {};
	for (const key in query) {
		if (key in taxonomyNames && !!query[key]) {
			const label = key as TaxonomyLabel;
			const facetValues = query[label].split(',');
			selected[label] = facetValues.reduce((sum, label) => {
				sum[label] = true;
				return sum;
			}, {} as FSSelectedFacetType[TaxonomyLabel]);
		}
	}
	return selected;
}

/**
 * Take a query string map object and create a reliable selectedFacets object.
 * @param query A map object corresponding to the current url's search params.
 */
export function readUrlExpandedFacets(query: ReturnType<typeof getQueryStringMap>) {
	if (!query.expanded) {
		return {};
	}

	const facetValues = query.expanded.split(',');
	return facetValues.reduce((expanded, facetValue) => {
		expanded[facetValue] = true;
		return expanded;
	}, {} as FSFlatFacetMap);
}

/**
 * Turns all raw facets into view models.
 */
export function rawFacetsToViewModels(
	rawFacets: FSResponseFacets,
	selectedFacets: FSSelectedFacetType,
	expandedFacets: FSFlatFacetMap,
	filterValues: FSFilterMap
): FSUIFacetGroups {
	const facetgroups: FSUIFacetGroups = {};

	for (const facet in rawFacets) {
		const label = facet as TaxonomyLabel;
		const group = new FacetGroupViewModel(label, filterValues[label]);
		// assign facets outside of constructor so facets can be aware of their group's properties
		group.facets = rawFacets[label].map(
			mapFacetToVm(label, 1, selectedFacets, expandedFacets, group)
		);
		facetgroups[label] = group;
	}

	return facetgroups;
}

/**
 * Turns a raw facet into a view model.
 */
export function mapFacetToVm(
	label: TaxonomyLabel,
	level: number,
	selectedFacets: FSSelectedFacetType,
	expandedFacets: FSFlatFacetMap,
	group: FacetGroupViewModel,
	parent?: FacetViewModel
) {
	return (raw: FSFacet, i: number) => {
		const isSelected = label in selectedFacets && raw.value in selectedFacets[label];
		const isExpanded = raw.value in expandedFacets;

		const fvm = new FacetViewModel(
			label,
			raw.count,
			raw.name,
			raw.value,
			isSelected,
			isExpanded,
			level + 1,
			i + 1,
			group,
			parent
		);
		if (raw.children && raw.children.length) {
			fvm.children = raw.children.map(
				mapFacetToVm(label, level + 1, selectedFacets, expandedFacets, group, fvm)
			);
		}
		return fvm;
	};
}

export function saveFilterValues(facets: FSUIFacetGroups): FSFilterMap {
	const filters: FSFilterMap = {};
	for (const facetName in facets) {
		const label = facetName as TaxonomyLabel;
		const group = facets[label];

		filters[label as TaxonomyLabel] = group.filter || '';
	}
	return filters;
}

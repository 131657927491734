import { loc_landmarkToc, loc_tryIt } from '@msdocs/strings';
import { initFeedbackReport, setIsRendered } from '../../src/feedback-report/index';
import { mobileQuery } from '../../src/match-media';
import { getContextQS } from '../../src/unit/feedback';
import { notifyContentUpdated } from '../affix';
import { Modal } from '../components/modal/modal';
import { features } from '../environment/features';
import { contentLoaded, document, msDocs } from '../globals';
import {
	collapseActionPanel,
	getActionPanel,
	scrollContentToTop
} from '../interactivity/action-panel';
import { parseInteractiveType, renderInteractiveComponent } from '../interactivity/activation';
import { html, render, TemplateResult } from '../lit-html';
import { getMeta } from '../meta';
import { preview } from '../preview/query-string';
import { localStorage } from '../protected-storage';
import { parseQueryString, updateQueryString } from '../query-string';
import { listenUntilUnload } from '../router/utils';
import { initModalToc } from '../toc/modal-toc';
import {
	LocalTutorialProgressService,
	TutorialProgress,
	TutorialProgressService
} from './tutorial-progress';

const argName = 'tutorial-step';
let modal: Modal;
let progressService: TutorialProgressService;
let autoExpand = false;
const chromeless = features.chromeless;
const interactiveType = features.interactivity
	? parseInteractiveType(getMeta('interactive_type'))
	: null;

export function tutorialPage() {
	contentLoaded.then(() => {
		if (chromeless) {
			const prevButton = document.querySelector(
				'.tutorial-step:nth-of-type(2) .tutorial-nav-button-previous'
			);
			prevButton.parentElement.removeChild(prevButton);
		}

		progressService = new LocalTutorialProgressService(
			localStorage,
			'section_' + location.pathname.replace(/[^a-zA-Z\d\s]+/g, '_')
		);

		processQueryString();

		addEventListener('content-update', addRunButtons);
		addEventListener('popstate', () => processQueryString());
		addEventListener('click', handleStepNavClick);
	});

	const renderModalToc = (container: Element) => {
		// copy the tutorial toc's html into the modal toc container.
		const leftContainer = document.getElementById('affixed-left-container');
		container.insertAdjacentHTML('beforeend', leftContainer.innerHTML);
	};

	initModalToc(renderModalToc);
}

function setupFeedbackModal() {
	if (!modal) {
		const modalContainer = document.createElement('div');
		modalContainer.classList.add('modal-content');
		render(modalContent(), modalContainer);
		modal = new Modal(modalContainer);
	}
}

function modalContent(): TemplateResult {
	return html` <div class="tutorial-feedback">
		<div class="modal-card" data-bi-name="feedback-report-section">
			<div class="modal-card-head has-padding-small">
				<button
					class="modal-close modal-card-close-button is-large"
					aria-label="Close"
					autofocus
				></button>
			</div>
			<section
				class="feedback-report modal-card-body has-padding-top-none has-flex-justify-content-center"
				data-bi-name="feedback-report-section"
			></section>
		</div>
	</div>`;
}

/**
 * Reads and normalizes the "tutorial-step" query string parameter.
 */
export function readStepFromQueryString() {
	const rawStep = parseQueryString()[argName] || '0';
	if (/^\d+$/.test(rawStep)) {
		return parseInt(rawStep);
	}
	return 0;
}

/**
 * Navigates to the step indicated by the query string
 */
function processQueryString() {
	const step = readStepFromQueryString();
	if (step === 0) {
		autoExpand = true;
	}
	showStep(step);
}

/**
 * Initialized the step the user in on.
 * @param step (optional) current step of tutorial
 */
export const initSteps = (step?: number) => {
	// read the step section elements.
	const steps = document.querySelectorAll<HTMLElement>('.tutorial-step');
	let currentStep = step || readStepFromQueryString();
	const minStep = chromeless && getMeta('labUrl') ? 1 : 0;
	const isFirst = currentStep === 0;
	const isLast = currentStep === steps.length - 1;

	if (currentStep <= minStep || currentStep >= steps.length) {
		currentStep = minStep;
		const args = parseQueryString();
		args[argName] = currentStep === 0 ? null : currentStep.toString();
		updateQueryString(args, 'replaceState');
	}

	return {
		steps,
		step: currentStep,
		isFirst,
		isLast,
		hasInteractive: !isFirst && !isLast && interactiveType && !chromeless
	};
};

/**
 * Shows a specific step.
 */
function showStep(step: number) {
	const { steps, isFirst, isLast, hasInteractive } = initSteps(step);

	// stop videos playing in current step
	stopVideo();

	// re-render the toc.
	progressService.getProgress().then(renderToc);

	// update the progress.
	progressService.setProgress(step);

	// get references to the elements we need to show or hide (fallback elements for chromeless).
	const fallback = document.createElement('span');
	const h1 = document.querySelector('h1');
	const metadata = (document.querySelector('.page-metadata') as HTMLElement) || fallback;
	const feedback = (document.querySelector('.feedback-section') as HTMLElement) || fallback;
	const feedbackPageAction =
		(document.querySelector('.action-list a[href="#feedback"]') || fallback).parentElement ||
		fallback;
	const tutorialFeedbackSection = document.getElementById(
		'tutorial-feedback-section'
	) as HTMLDivElement;

	// toggle visibility...
	h1.hidden = !isFirst;
	metadata.hidden = !isFirst;
	feedback.hidden = !isLast;
	feedbackPageAction.hidden = !isLast;
	tutorialFeedbackSection.hidden = true;
	modal = null;
	// Show tutorial feedback if not on the first step and enableTutorialFeedback feature flag is set
	if (!isFirst && msDocs.data.enableTutorialFeedback) {
		let showTutorialFeedback = true;
		// Temporary environment check to not show in production unless preview=tutorialFeedback querystring is present
		if (!preview('tutorialFeedback')) {
			showTutorialFeedback = false;
		}
		if (showTutorialFeedback) {
			tutorialFeedbackSection.hidden = false;
			listenUntilUnload(
				document.getElementById('tutorial-feedback-link'),
				'click',
				handleTutorialFeedback
			);
		}
	}

	if (hasInteractive) {
		document.documentElement.classList.remove('hasPageActions');
	} else {
		document.documentElement.classList.add('hasPageActions');
	}

	Array.from(steps).forEach((s, i) => (s.hidden = i !== step));

	notifyContentUpdated();
	scrollContentToTop();

	if (hasInteractive) {
		ensureInteractive();
	} else {
		collapseActionPanel();
	}
}

/**
 * Ensure the interactive experience is ready.
 */
function ensureInteractive() {
	// get the action panel... animate it into view if we're not on mobile.
	const actionPanel = getActionPanel(autoExpand && !mobileQuery.matches ? 'animate' : 'none');
	autoExpand = false;

	// render the interactive component if necessary.
	if (interactiveType) {
		renderInteractiveComponent(interactiveType, actionPanel);
	}
}

/**
 * Uses pushState nav for clicks on elements with "tutorial-nav-behavior".
 */
function handleStepNavClick(event: MouseEvent) {
	if (!(event.target instanceof HTMLElement)) {
		return;
	}
	const anchor = event.target.closest('.tutorial-nav-behavior') as HTMLAnchorElement;
	if (!anchor) {
		return;
	}
	const targetStep = parseQueryString(anchor.search)[argName];
	if (targetStep === undefined) {
		return;
	}
	event.preventDefault();
	const args = parseQueryString(location.search);
	args[argName] = targetStep;
	updateQueryString(args, 'pushState');
	processQueryString();
}

/**
 * Render the tutorial TOC, highlighting the current step.
 */
export function renderToc(progress: TutorialProgress) {
	if (chromeless) {
		return;
	}
	const activeStep = readStepFromQueryString();
	const checkMark = `<svg class="check-mark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 448"><path d="M160 397.255L9.373 246.627l45.255-45.254L160 306.745 393.373 73.373l45.254 45.255L160 397.255z"/></svg>`;
	const steps = Array.from(document.querySelectorAll<HTMLElement>('.tutorial-step')).map(
		(section, index) => ({
			index,
			title: section.getAttribute('tutorial-step-title'),
			type: section.getAttribute('tutorial-step-type')
		})
	);

	document.querySelector('.toc, #affixed-left-container').innerHTML = `
	<ol class="tutorial-toc is-vertically-scrollable" role="navigation" aria-label="${loc_landmarkToc}">
		${steps
			.map(
				({ index, title }) => `
		<li class="${progress[index] ? 'completed-step' : ''} ${index === activeStep ? 'active-step' : ''}">
			<a class="tutorial-nav-behavior has-inner-focus modal-close" href="?tutorial-step=${index}">
				${checkMark}
				<span>${title}</span>
			</a>
		</li>`
			)
			.join('')}
	</ol>`;
}

/**
 * Add run buttons to all code blocks... temporary measure...
 */
function addRunButtons() {
	if (!interactiveType) {
		return;
	}
	const selector = `section.tutorial-step:not(:first-of-type):not(:last-of-type) .codeHeader + pre > code[class="lang-${interactiveType}"]`;
	Array.from(document.querySelectorAll<HTMLElement>(selector)) // find code blocks
		.map(block => block.parentElement.previousElementSibling) // get the code block's header
		.filter(header => !header.querySelector('.ap-expand-behavior')) // filter out blocks that already have the button
		.forEach(header => {
			// add the button...
			header.insertAdjacentHTML(
				'beforeend',
				`
			<button class="action ap-expand-behavior ap-collapsed" data-bi-name="code-header-try-it-${interactiveType}">
				<span class="docon docon-play" aria-hidden="true"></span>
				${loc_tryIt}
			</button>
		`
			);
		});
}

function stopVideo() {
	const iframes = Array.from(
		document.querySelectorAll('.embeddedvideo iframe')
	) as HTMLIFrameElement[];
	if (iframes !== null) {
		iframes.forEach(iframe => {
			if (iframe.offsetParent !== null) {
				// if the iframe is visible reset the src to stop video
				const iframeSrc = iframe.src;
				iframe.src = iframeSrc;
			}
		});
	}
}

function handleTutorialFeedback(event: MouseEvent) {
	event.preventDefault();
	setupFeedbackModal();
	modal.show().then(() => {
		setIsRendered(false);
	});
	initFeedbackReport('tutorialFeedback', getContextQS());
}

import {
	loc_bookmarks,
	loc_collections,
	loc_globalNavigation,
	loc_profile,
	loc_signIn,
	loc_signOut,
	loc_yourAccount
} from '@msdocs/strings';
import { msDocs } from '../../globals';
import { CallToAction } from '../../header/model';
import { siteNavMenuTitle } from '../../header/site-context';
import { generateElementId } from '../../html';
import { html, TemplateResult } from '../../lit-html';
import { Modal } from '../modal/modal';
import { createNavModalContent } from './mobile-modal/index';
import { NavBarConfig, NavItem, NavLink, NavMenu } from './model';
import { NavBarViewModel } from './view-model';

export function navBarTemplate(config: NavBarConfig, viewModel: NavBarViewModel) {
	const { leftTemplate, rightTemplate } = config;
	return html` ${leftTemplate(viewModel)} ${rightTemplate(viewModel)} `;
}

export function tabletProfileTemplate() {
	return html`
		<div class="nav-bar-item is-size-small is-hidden-mobile has-padding-left-extra-small">
			${unAuthenticatedUserTemplate()} ${authenticatedUserTemplate()}
		</div>
	`;
}

export function logoTemplate() {
	return html` <div class="nav-bar-brand">
		<a
			class="nav-bar-button"
			itemprop="url"
			href="https://www.microsoft.com"
			aria-label="Microsoft"
		>
			<div
				class="nav-bar-logo has-background-image theme-display is-light"
				role="presentation"
				aria-hidden="true"
				itemprop="logo"
				itemscope="itemscope"
			></div>
			<div
				class="nav-bar-logo has-background-image theme-display is-dark is-high-contrast"
				role="presentation"
				aria-hidden="true"
				itemprop="logo"
				itemscope="itemscope"
			></div>
		</a>
	</div>`;
}

export function siteCategoryTemplate(
	viewModel: NavBarViewModel,
	category: NavLink
): TemplateResult {
	if (!category) {
		return null;
	}

	const { href, title } = category;
	return html`
		<a
			class="nav-bar-button is-title has-hover-underline"
			href="${href}"
			?hidden=${viewModel.mobile}
			itemprop="url"
		>
			<span>${title}</span>
		</a>
	`;
}

export function categoryTemplate(category: NavLink): TemplateResult {
	if (!category) {
		return null;
	}

	const { href, title } = category;
	return html`
		<li class="nav-bar-item is-category">
			<a class="nav-bar-button is-title has-hover-underline" href="${href}" itemprop="url">
				<span>${title}</span>
			</a>
		</li>
	`;
}

export function ctaTemplate(cta: CallToAction): TemplateResult {
	if (!cta) {
		return null;
	}

	const { primary, secondary } = cta;
	return html`
		<ol class="nav-bar-list">
			${ctaLinkTemplate(secondary, true)} ${ctaLinkTemplate(primary)}
		</ol>
	`;
}

export function ctaLinkTemplate(ctaLink: NavLink, isOutlined: boolean = false): TemplateResult {
	if (!ctaLink) {
		return null;
	}

	const { href, title } = ctaLink;
	// outlined/secondary buttons need spacing for primary button
	const spacingClass = isOutlined ? 'has-spacing' : '';
	return html`
		<li class="nav-bar-item ${spacingClass}">
			<a class="button is-small is-primary ${isOutlined ? 'is-outlined' : ''}" href="${href}">
				${title}
			</a>
		</li>
	`;
}

export function tabletNavTemplate(viewModel: NavBarViewModel, label: string, category?: NavLink) {
	const { items } = viewModel;
	return html`
		<nav class="nav-bar-nav" role="navigation" aria-label="${label}">
			<ul class="nav-bar-nav-list">
				${category ? categoryTemplate(category) : null}
				${items.map(item => navItemTemplate(item, false, category && category.title === 'Docs'))}
			</ul>
		</nav>
	`;
}

export function mobileNavTemplate(viewModel: NavBarViewModel) {
	return html`
		<div class="nav-bar-item is-hidden-tablet">
			<button
				class="nav-bar-button nav-bar-menu"
				title="${loc_globalNavigation}"
				aria-expanded="${viewModel.mobileNavExpanded}"
				@click=${() => showModal(viewModel)}
			>
				<span class="nav-bar-menu-title">${siteNavMenuTitle}</span>
				<div class="nav-bar-burger">
					<span></span>
					<span></span>
					<span></span>
					<span class="nav-bar-burger-label">${loc_globalNavigation}</span>
				</div>
			</button>
		</div>
	`;
}

async function showModal(viewModel: NavBarViewModel) {
	const content = await createNavModalContent(viewModel);
	const modal = new Modal(content);
	const windowWidth = window.outerWidth;
	function dispose() {
		content.dispose();
		window.removeEventListener('resize', close);
		viewModel.mobileNavExpanded = false;
	}
	function close() {
		modal.hide();
		dispose();
	}
	window.addEventListener('resize', () => {
		// prevent modal close when resize is triggered by virtual keyboard on mobile devices
		if (window.outerWidth === windowWidth) {
			return;
		}
		close();
	});
	viewModel.mobileNavExpanded = true;
	await modal.show();
	dispose();
}

export function navItemTemplate(
	item: NavItem,
	submenu: boolean,
	isGlobal: boolean = false
): TemplateResult {
	return item.kind === 'link'
		? navLinkTemplate(item, submenu, isGlobal)
		: navMenuTemplate(item, submenu);
}

function navLinkTemplate(
	link: NavLink,
	submenu: boolean,
	isGlobal: boolean = false
): TemplateResult {
	const isActive = isGlobal && link.title === siteNavMenuTitle ? 'is-active' : '';
	return submenu
		? html`
				<li class="nav-bar-item" ?hidden=${link.hidden}>
					<a class="nav-bar-button" href="${link.href}">${link.title}</a>
				</li>
		  `
		: html` <li class="nav-bar-item" ?hidden=${link.hidden}>
				<a class="nav-bar-button has-hover-underline ${isActive}" href="${link.href}">
					<span>${link.title}</span>
				</a>
		  </li>`;
}

function navMenuTemplate(menu: NavMenu, submenu: boolean): TemplateResult {
	const menuId = generateElementId();
	const docon = submenu
		? msDocs.data.userDir === 'ltr'
			? 'chevron-right-light'
			: 'chevron-left-light'
		: 'chevron-down-light';
	const expandedIndicator = submenu ? '' : 'expanded-indicator';
	return html` <li class="dropdown nav-bar-item" ?hidden=${menu.hidden}>
		<button
			class="dropdown-trigger nav-bar-button ${submenu ? '' : 'has-hover-underline'}"
			aria-controls="${menuId}"
			aria-expanded="false"
		>
			<span>${menu.title}</span>
			<span class="nav-bar-button-chevron" aria-hidden="true">
				<span class="docon docon-${docon} ${expandedIndicator}"></span>
			</span>
		</button>
		<ul class="dropdown-menu" id="${menuId}" aria-label="${menu.title}">
			${menu.items.map(item => navItemTemplate(item, true))}
		</ul>
	</li>`;
}

function authenticatedUserTemplate(): TemplateResult {
	return html`
		<div class="dropdown has-caret auth-status-determined authenticated">
			<button
				class="dropdown-trigger button is-text has-padding-none has-background-transparent"
				aria-label="${loc_yourAccount}"
				aria-controls="user-menu"
				aria-expanded="false"
			>
				<figure class="image is-36x36">
					<img
						class="has-body-background-medium is-rounded"
						alt=""
						data-profile-property="avatarUrl"
					/>
				</figure>
			</button>
			<div class="dropdown-menu box" id="user-menu" role="menu">
				<div class="media is-user-dir">
					<div class="media-left">
						<figure class="image is-48x48" aria-hidden="true">
							<img
								class="has-body-background-medium is-rounded"
								aria-labelledby="displayName"
								data-profile-property="avatarUrl"
							/>
						</figure>
					</div>
					<div class="media-content">
						<p class="title is-size-large">
							<span
								id="displayName"
								class="is-inline-truncated has-max-width-200"
								data-profile-property="displayName"
							></span>
						</p>
						<p class="subtitle is-size-small has-margin-bottom-none">
							<span
								class="is-inline-truncated has-max-width-200"
								data-profile-property="upn"
							></span>
						</p>
					</div>
				</div>
				<ul>
					<li><a href="/profile" data-profile-property="profileUrl">${loc_profile}</a></li>
					<li><a href="/profile" data-profile-property="bookmarksUrl">${loc_bookmarks}</a></li>
					<li><a href="/profile" data-profile-property="collectionsUrl">${loc_collections}</a></li>
				</ul>
				<div class="has-border-top">
					<a class="docs-sign-out" href="#">${loc_signOut}</a>
				</div>
			</div>
		</div>
	`;
}

function unAuthenticatedUserTemplate(): TemplateResult {
	return html`
		<a
			class="docs-sign-in auth-status-determined not-authenticated button is-text has-inner-focus has-text-primary is-small"
			href="#"
		>
			${loc_signIn}
		</a>
	`;
}

import { loc_showLess, loc_loadMore } from '@msdocs/strings';

export function referencePage() {
	toggleDerivedClasses();
	toggleGlobalParams();
}

function toggleDerivedClasses() {
	//derived classes
	const derivedClassButtons = document.querySelectorAll<HTMLAnchorElement>('.derivedClassesButton');
	derivedClassButtons.forEach(button => {
		button.onclick = () => {
			const hdc = button.closest('.hiddenDerivedClass') as HTMLElement;
			if (hdc.hidden === true) {
				hdc.hidden = false;
				button.textContent = `${loc_showLess} &#8230;`;
			} else {
				hdc.hidden = true;
				button.textContent = `${loc_loadMore} &#8230;`;
			}
		};
	});
}

function toggleGlobalParams() {
	//for AzureCli
	const globalParamsLinks = document.querySelectorAll<HTMLLinkElement>('.globalParamsHolder > a');
	globalParamsLinks.forEach(link => {
		link.onclick = e => {
			e.preventDefault();
			const parent = link.parentElement;
			if (parent.getAttribute('aria-expanded') === 'true') {
				parent.setAttribute('aria-expanded', 'false');
			} else {
				parent.setAttribute('aria-expanded', 'true');
			}
		};
	});
}

import { appInsights } from '../../instrumentation/app-insights';
import { jsllReady } from '../../bi';
import { document } from '../../globals';
import { beforeUnload } from '../../router/utils';
import { convertMillisecondsToMins } from '../../time-format';
import { azureSandbox, AzureSandbox, getRemainingSandboxTime } from '../sandbox';

export async function publishTenantInviteFailed(attempts: number, retryDelay: number) {
	appInsights.trackEvent({ name: 'tenant-invite-failed' }, { attempts, retryDelay });
	const awa = await jsllReady;
	awa.ct.captureContentPageAction({
		content: { event: 'tenant-invite-failed', attempts, retryDelay }
	});
}

export function instrumentSandboxActivity(sandbox: AzureSandbox, isNew: boolean) {
	jsllReady.then(awa =>
		awa.ct.captureContentPageAction({
			content: {
				event: 'azure-sandbox-activated',
				sandbox,
				isNew,
				minutesRemaining: convertMillisecondsToMins(getRemainingSandboxTime(sandbox))
			}
		})
	);

	// todo: this is temporary instrumentation. remove when the following epic completes.
	// https://ceapex.visualstudio.com/Engineering/_workitems/edit/47640.
	const activityInterval = setInterval(() => {
		if (azureSandbox.value === null) {
			clearInterval(activityInterval);
			return;
		}
		if (document.visibilityState !== 'visible') {
			return;
		}

		jsllReady.then(awa =>
			awa.ct.captureContentPageAction({
				content: {
					event: 'azure-sandbox-active',
					sandbox,
					isNew,
					minutesRemaining: convertMillisecondsToMins(getRemainingSandboxTime(sandbox))
				}
			})
		);
	}, 60 * 1000); // every minute

	beforeUnload(() => clearInterval(activityInterval));
	return () => clearInterval(activityInterval);
}

export function instrumentSandboxStateTransition(sandboxUIState: string) {
	jsllReady.then(awa =>
		awa.ct.captureContentPageAction({
			content: {
				event: 'azure-sandbox-activation-step-changed',
				step: sandboxUIState
			}
		})
	);
}

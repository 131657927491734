import { document, history, location } from './globals';

/**
 * Parses the query string into a map object.
 * Handles x-www-form-urlencoded query strings. See https://www.w3.org/TR/html401/interact/forms.html#h-17.13.4.1
 * @param queryString Optional query string to parse. If not supplied, location.search will be used.
 */
export function parseQueryString(queryString?: string) {
	let match: RegExpExecArray;
	const pl = /\+/g;
	const search = /([^&=]+)=?([^&]*)/g;
	const decode = (s: string) => decodeURIComponent(s.replace(pl, ' '));
	if (queryString === undefined) {
		queryString = location.search;
	}
	queryString = queryString.substring(1);
	const urlParams: { [name: string]: string } = {};
	while ((match = search.exec(queryString))) {
		urlParams[decode(match[1])] = decode(match[2]);
	}
	return urlParams;
}

export interface InputArgs {
	[name: string]: string | boolean | number | null | undefined | string[];
}

/**
 * Flattens an object into a query string.
 * @param args The query string arguments map to flatten.
 */
export function toQueryString(args: InputArgs, multiKey: boolean = false) {
	const parts: string[] = [];
	for (const name in args) {
		if (
			args.hasOwnProperty(name) &&
			args[name] !== '' &&
			args[name] !== null &&
			args[name] !== undefined
		) {
			if (multiKey && Array.isArray(args[name])) {
				(args[name] as string[]).forEach(arg => {
					parts.push(encodeURIComponent(name) + '=' + encodeURIComponent(arg));
				});
			} else {
				parts.push(encodeURIComponent(name) + '=' + encodeURIComponent(args[name].toString()));
			}
		}
	}
	return parts.join('&');
}

/**
 * Updates query string arguments.
 * @param args The arguments to update.
 * @param method The query string update method: history.pushState/history.replaceState/location.href.
 * @param hash Hash override.
 */
export function updateQueryString(
	args: InputArgs,
	method: 'pushState' | 'replaceState' | 'href',
	newHash?: string
) {
	const current = parseQueryString();
	let changed = false;
	for (const name in args) {
		if (args.hasOwnProperty(name) && current[name] !== String(args[name])) {
			current[name] = args[name] as any;
			changed = true;
		}
	}

	let hash = location.hash;
	if (typeof newHash === 'string') {
		newHash = newHash.trim();
		if (newHash.substr(0, 1) !== '#' && newHash !== '') {
			newHash = `#${newHash}`;
		}
		if (hash !== newHash) {
			hash = newHash;
			changed = true;
		}
	}

	if (!changed) {
		return;
	}

	let queryString = toQueryString(current);
	if (queryString.length > 0) {
		queryString = '?' + queryString;
	}

	const url = `${location.protocol}//${location.host}${location.pathname}${queryString}${hash}`;
	const state = history.state || {};
	if (method === 'pushState') {
		history.pushState(state, document.title, url);
	} else if (method === 'replaceState') {
		history.replaceState(state, document.title, url);
	} else {
		location.href = url;
	}
}

/**
 * Parse a url into it's components.
 */
export function parseUrl(url: string) {
	// use an anchor element to parse the url.
	const a = document.createElement('a');
	// if the input string does not have a protocol, make it relative to the page
	// (IE quirk, other browsers do this automatically)
	if (/^https:\/\/|^http:\/\//.test(url)) {
		a.href = url;
	} else if (/^\/\//.test(url)) {
		a.href = location.protocol + url;
	} else {
		a.href = location.origin + url;
	}
	// prefix the resulting pathname with "/" (IE quirk, other browsers correctly include the "/")
	const pathname = a.pathname[0] === '/' ? a.pathname : '/' + a.pathname;
	// remove trailing 443 and 80 ports from host props (IE quirk)
	const host = a.host.replace(/:443$|:80$/, '');
	const hostname = a.hostname.replace(/:443$|:80$/, '');
	return {
		hash: a.hash,
		host,
		hostname,
		href: a.href,
		origin: `${a.protocol}//${host}`,
		pathname,
		protocol: a.protocol,
		search: a.search
	};
}

/**
 * Partitions an array of values into multiple arrays, ensuring none of the partitions exceed maxLength once encoded.
 * @param values The values to partition.
 * @param maxLength The max **encoded** length of a partition.
 * @param delimiter The plain text delimiter that will be used when joining the values. Not encoded.
 */
export function partitionValuesByEncodedLength(
	values: string[],
	maxLength = 2000,
	delimiter = ';'
): string[][] {
	const delimiterLength = encodeURIComponent(delimiter).length;
	const partitions: string[][] = [];
	let partition: string[];
	let partitionLength: number;
	for (const value of values) {
		const valueLength = encodeURIComponent(value).length;
		if (valueLength > maxLength) {
			throw new Error(
				`The encoded length of "${value}" (${valueLength}) is greater than the max partition length (${maxLength}).`
			);
		}
		if (!partition || partitionLength + valueLength > maxLength) {
			partition = [];
			partitions.push(partition);
			partitionLength = 0;
		}
		partition.push(value);
		partitionLength += valueLength + delimiterLength;
	}
	return partitions;
}

interface NameMap {
	[key: string]: string;
}

export function getQueryStringMap(url: URL = new URL(location.href)): NameMap {
	const args = new URLSearchParams(url.search);
	const query: NameMap = {};
	args.forEach((value, key) => (query[key] = value));
	return query;
}

export function updateUrlSearchFromMap(args: NameMap, url: URL = new URL(location.href)): URL {
	const params = url.searchParams;

	// Ensure any queries are not removed
	const existingArgs = getQueryStringMap(url);

	for (const arg in existingArgs) {
		if (!params.has(arg)) {
			params.set(arg, existingArgs[arg]);
		}
	}

	// Set new and delete any args explicitly set to null
	for (const arg in args) {
		if (args[arg] === null || args[arg] === undefined) {
			params.delete(arg);
		} else {
			params.set(arg, args[arg]);
		}
	}

	return url;
}

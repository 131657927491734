import { PageControlChangeEvent, PagingControl } from '../components/paging-control/paging-control';
import { CourseListView, courseListViewTemplate } from '../course/course-list-view';
import { CourseListViewModel } from '../course/course-list-view-model';
import { CourseView, courseViewTemplate } from '../course/course-view';
import { CourseViewModel } from '../course/course-view-model';
import { features } from '../environment/features';
import { contentLoaded, document, msDocs } from '../globals';
import { renderLearnBreadcrumbs } from '../learn/breadcrumbs';
import { getMeta } from '../meta';
import { preview } from '../preview/query-string';
import { PropertyChangedEvent } from '../view-model';

export async function coursePage() {
	await contentLoaded;

	renderLearnBreadcrumbs(`${getMeta('og:title')}`);

	if (
		(preview('course-scheduling', { require: true }) || preview('ilt', { require: true })) &&
		features.courseScheduling
	) {
		const findALearningPartnerContainer = document.getElementById('findALearningPartnerContainer');

		if (findALearningPartnerContainer) {
			findALearningPartnerContainer.parentElement.removeChild(findALearningPartnerContainer);
		}

		initCourseScheduling();
	}
}

async function initCourseScheduling() {
	const courseId = getMeta('uid');
	const browseAllSessionsContainer = document.getElementById('browseAllSessionsContainer');
	const skillsGainedContainer = document.getElementById('skillsGainedContainer');

	if (!browseAllSessionsContainer) {
		return;
	}

	browseAllSessionsContainer.setAttribute('lang', msDocs.data.userLocale);
	browseAllSessionsContainer.setAttribute('dir', msDocs.data.userDir);
	browseAllSessionsContainer.hidden = false;

	if (skillsGainedContainer) {
		skillsGainedContainer.firstElementChild.classList.remove('has-padding-top-none');
	}

	const courseVM = new CourseViewModel();
	const courseListVM = new CourseListViewModel();
	courseListVM.load(courseId);

	const courseSlot = browseAllSessionsContainer.querySelector<HTMLElement>(
		'#browseAllSessionsContent'
	);
	const courseListSlot = document.createElement('div');
	const pagingSlot = document.createElement('div');
	pagingSlot.hidden = true;

	const courseView = new CourseView(courseViewTemplate, courseSlot, [courseListSlot, pagingSlot]);
	const courseListView = new CourseListView(courseListViewTemplate, courseListSlot, [pagingSlot]);

	courseView.init(courseVM);
	courseListView.init(courseListVM);

	// Attach the paging control to the paging div.
	const control = new PagingControl(pagingSlot, 1, 1, { doNotScroll: true });
	control.subscribe(PageControlChangeEvent, event => {
		courseListVM.goToPage(event.page);
	});

	courseVM.subscribe(PropertyChangedEvent, () => {
		courseListVM.load(courseId, courseVM.location, courseVM.date, courseVM.virtualOnly);
		control.update({ currentPage: 1, lastPage: 1 });
	});

	courseListVM.subscribe(PropertyChangedEvent, () => {
		courseListView.update(courseListVM);

		// We should get a count back. Current API doesn't support this, though. If we don't have a count,
		// set the max page to infinity so that we at least always have active paging.
		const { count, error, page, pageSize } = courseListVM;
		const pagingCount = !isNaN(count) ? count : Infinity;
		const lastPage = Math.ceil(pagingCount / pageSize);

		control.update({ currentPage: page, lastPage });
		pagingSlot.hidden = error || !count || count < pageSize;
	});
}

import { contentLoaded } from '../../globals';
import { render } from '../../lit-html';
import { desktopOnlyQuery, tabletOrDesktopQuery } from '../../match-media';
import { PropertyChangedEvent } from '../../view-model';
import { NavBarConfig, NavBarElement, NavItem } from './model';
import { navBarTemplate } from './view';
import { NavBarViewModel } from './view-model';

export function createNavBar(config: NavBarConfig) {
	const view: NavBarElement = document.createElement('div') as any;
	view.classList.add('nav-bar');
	const viewModel = new NavBarViewModel();

	// expose the paint handler
	view.paint = () => renderImmediately(view, viewModel, config);
	Object.defineProperty(view, 'items', {
		enumerable: true,
		get: () => viewModel.items,
		set: (value: NavItem[]) => (viewModel.items = value)
	});

	// Responsiveness
	viewModel.desktop = desktopOnlyQuery.matches;
	viewModel.mobile = !tabletOrDesktopQuery.matches;
	desktopOnlyQuery.addListener(() => {
		viewModel.desktop = desktopOnlyQuery.matches;
	});
	tabletOrDesktopQuery.addListener(() => {
		viewModel.mobile = !tabletOrDesktopQuery.matches;
	});

	window.addEventListener('resize', () => {
		if (tabletOrDesktopQuery.matches) {
			// update more menu
			scheduleRender(view, viewModel, config);
		}
	});

	// Render immediately. At least the logo and search components will be visible.
	view.paint();

	// Render when the element is attached to the DOM.
	contentLoaded.then(() => {
		const observer = new MutationObserver(records => {
			for (const record of records) {
				// eslint-disable-next-line @typescript-eslint/prefer-for-of
				for (let i = 0; i < record.addedNodes.length; i++) {
					if (record.addedNodes[i] === view) {
						observer.disconnect();
						view.paint();
						return;
					}
				}
			}
		});
		observer.observe(document.body, { childList: true, subtree: true });
	});

	// Render when the view model changes.
	viewModel.subscribe(PropertyChangedEvent, () => view.paint());

	return view;
}

let lastRender = 0;

function renderImmediately(view: Element, viewModel: NavBarViewModel, config: NavBarConfig) {
	lastRender = Date.now();
	viewModel.resetItemVisibility();
	render(navBarTemplate(config, viewModel), view);

	if (viewModel.mobile) {
		return;
	}

	// The .nav-bar-spacer is a sentinel used to determine whether we
	// need to collapse items into the "more" menu.
	const spacer = view.querySelector('.nav-bar-spacer');

	// Hide nav-bar items incrementally, revealing them in the "more" menu, until
	// no items are clipped.
	const { more, items } = viewModel;
	let i = items.length - 1;
	while (i > 0 && spacer.getBoundingClientRect().width === 0) {
		i--;
		more.hidden = false; // Show the "more" menu
		items[i].hidden = true; // Hide the last visible nav-bar item.
		more.items[i].hidden = false; // Show the corresponding item in the "more" menu.
		render(navBarTemplate(config, viewModel), view);
	}
}

let timeout = 0;
const navBarRenderInterval = 50;

function scheduleRender(view: NavBarElement, viewModel: NavBarViewModel, config: NavBarConfig) {
	clearTimeout(timeout);
	if (Date.now() - lastRender > navBarRenderInterval) {
		view.paint();
	} else {
		timeout = setTimeout(renderImmediately, navBarRenderInterval, view, viewModel, config);
	}
}

import { ProductOrFamily } from '../apis/learn';
import { productFamilyAlternativeMap, productFamilyNames } from '../name-maps/product-family';
export const brandedHeader = (products: ProductOrFamily[]) => {
	const defaultColor = 'has-background-docs';
	const isVsCode = products.indexOf('vs-code') === 1; //TODO: remove when branding rework is done.
	const productName = products.length > 0 && !isVsCode ? products[0] : undefined;
	if (!productName || !productFamilyNames[productName]) {
		return defaultColor;
	}
	// Match product name to css brand name
	const alternateName = productFamilyAlternativeMap[productName] || '';
	return `has-background-${alternateName || productName}-card`;
};

import { loc_apiSearchIsUnavailable } from '@msdocs/strings';
import { fetchNamespaces, search } from '../apis/api-browser';
import { jsllReady } from '../bi';
import { msDocs, window } from '../globals';
import { getMoniker, monikerChangedEvent } from '../monikers/moniker';
import { getPlatform, platformId } from '../monikers/platform';
import { platformConfig } from './platform-config';
import { clearResults, displayLoadingIndicator, renderResults, renderText } from './search-results';
import { apiSearchTermChangedEvent, getTerm } from './term';

export function initApiSearch() {
	window.addEventListener(apiSearchTermChangedEvent, doApiSearch);

	window.addEventListener(monikerChangedEvent, doApiSearch);

	if (msDocs.data.pageTemplate === 'ApiBrowserPage') {
		doApiSearch();
	}
}

let previousSearch = '';

function doApiSearch() {
	const term = getTerm();
	const moniker = getMoniker();

	const currentSearch = `${term}/${moniker}`;
	if (currentSearch === previousSearch) {
		return Promise.resolve();
	}
	previousSearch = currentSearch;

	if (msDocs.data.pageTemplate === 'ApiBrowserPage' && moniker !== '' && term === '') {
		displayLoadingIndicator();
		return Promise.all([
			fetchNamespaces(platformId, moniker, msDocs.data.userLocale),
			getPlatform()
		]).then(
			([result, platform]) => {
				if (currentSearch !== previousSearch) {
					// out of order results from server.
					return;
				}
				if (result.apiItems.length === 0) {
					renderText('No namespaces');
					return;
				}
				renderResults(platform, result.apiItems, null);
			},
			() => {
				renderText(loc_apiSearchIsUnavailable);
			}
		);
	}

	if (term.length < 3) {
		clearResults();
		return Promise.resolve();
	}

	if (!platformConfig[platformId].validSearchTerm.test(term)) {
		return getPlatform().then(platform => renderResults(platform, [], null));
	}

	displayLoadingIndicator();
	return Promise.all([
		search(platformId, moniker, term, msDocs.data.userLocale),
		getPlatform()
	]).then(
		([result, platform]) => {
			if (currentSearch !== previousSearch) {
				// out of order results from server.
				return;
			}
			reportApiSearchWithJsll(moniker, term, result.results.length);
			renderResults(platform, result.results, result['@nextLink']);
		},
		() => {
			renderText(loc_apiSearchIsUnavailable);
		}
	);
}

export function reportApiSearchWithJsll(moniker: string, term: string, results: number) {
	jsllReady.then(awa =>
		awa.ct.captureContentPageAction({
			actionType: awa.actionType.OTHER,
			behavior: awa.behavior.SEARCH,
			content: {
				event: 'api-browser-search',
				platform: platformId,
				moniker,
				term,
				results
			}
		})
	);
}

import { process401Response } from '../auth/service';
import { apis } from '../environment/apis';
import { createRequest, fetchWithTimeout } from '../fetch';

export interface QuestionAndAnswer {
	questionId: string; // "rating-module"
	answerIds: string[];
	comments: string;
}

export type RatingLocation = 'module_completion_page' | 'module_detail_page';

// Used for user rating
export interface Metadata {
	ratingLocation: RatingLocation;
	moduleProgress: number;
}

export interface UserRating {
	pageType: 'learn';
	pageKind: 'module';
	locale: string;
	ratingScore: number;
	questionAndAnswers: QuestionAndAnswer[];
	metadata: Metadata;
}

export interface UserRatingResult {
	pageId: string;
	pageType: 'learn';
	pageKind: 'module';
	locale: string;
	ratingScore: number;
}

interface RatingCountByScore {
	[score: number]: number;
}

// Used for page rating
// Check one module rating
export interface PageRatingResult {
	pageId: string;
	pageType: 'learn';
	pageKind: 'module';
	locale: string;
	ratingCountByScore: RatingCountByScore;
}

export interface RatingPageId {
	pageId: string;
}

// List all module ratings for a learning path request
export interface PageRatingBatch {
	pages: RatingPageId[];
	locale: string;
	pageId?: string;
}

export interface PageRatingBatchResult {
	ratings: PageRatingResult[];
}

export const ratingApi = {
	// User Rating starts here
	// Login user rate a module page
	putUserRating(userRating: UserRating, pageId: string): Promise<UserRatingResult> {
		const url = `${apis.rating}/user/pages/${pageId}/rating`;
		const init: RequestInit = {
			method: 'PUT',
			body: JSON.stringify(userRating)
		};
		return fetchWithTimeout(createRequest(url, init))
			.then(process401Response)
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject();
			});
	},

	// Login user get his or her own user rating on a given page
	getUserRating(pageId: string, locale: string): Promise<UserRatingResult> {
		const url = `${apis.rating}/user/pages/${pageId}/rating?locale=${locale}`;
		const init: RequestInit = { method: 'GET' };
		return fetchWithTimeout(createRequest(url, init))
			.then(process401Response)
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject();
			});
	},

	// Page Rating starts here
	// Get rating for a page, no credential needed
	getPageRating(pageId: string, locale: string): Promise<PageRatingResult> {
		const url = `${apis.rating}/pages/${pageId}/rating?locale=${locale}`;
		const init: RequestInit = { method: 'GET', mode: 'cors' };
		return fetchWithTimeout(url, init)
			.then(process401Response)
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject();
			});
	},

	// Get ratings for a batch of pages, no credential needed
	getBatchPageRating(
		locale: string,
		pageRatingBatch: PageRatingBatch
	): Promise<PageRatingBatchResult> {
		const url = `${apis.rating}/pages/rating?locale=${locale}`;
		const init: RequestInit = {
			method: 'POST',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(pageRatingBatch)
		};

		return fetchWithTimeout(url, init)
			.then(process401Response)
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject();
			});
	}
};

import { EventBus } from '../../event-bus';
import { TaxonomyLabel, taxonomyParentNames } from '../../name-maps/taxonomy';
import { FacetChangeEvent, FacetChangeType } from './events';
import { FacetViewModel } from './facets';
import type { FSSelectedFacets } from './model';

export const reduceToNumberOfFacets = (accumulator: number, current: FacetViewModel): number => {
	if (current.hasChildren) {
		return current.children.reduce(reduceToNumberOfFacets, accumulator) + 1;
	}
	return accumulator + 1;
};

export class FacetGroupViewModel extends EventBus {
	/**
	 * The localized name of this group of facets.
	 */
	public name: string;
	/**
	 * An array of individual facets.
	 */
	private _facets: FacetViewModel[] = [];
	/**
	 * The total number of facets, including those nested within other facets.
	 * Updated in facet setter.
	 */
	private _totalFacets = 0;
	get facets() {
		return this._facets;
	}
	set facets(facets: FacetViewModel[]) {
		this._facets = facets;
		this._totalFacets = this._facets.reduce(reduceToNumberOfFacets, 0);
		this.subscribeToChanges(this.facets);
	}
	get hasFilterInput() {
		const maxFacets = 14;
		return this.filterActive || this._totalFacets > maxFacets;
	}
	get selected() {
		return this.facets.reduce(reduceFacetTo('isSelected'), {});
	}
	get expanded() {
		return this.facets.reduce(reduceFacetTo('isExpanded'), {});
	}
	set filter(filter: string) {
		this._filter = filter;
	}
	get filter(): string {
		return this._filter;
	}
	get filterActive() {
		return this._filter !== '';
	}
	get hasVisibleChild() {
		return !!this.facets.find(f => !f.hidden);
	}
	get isEmpty() {
		return this._totalFacets === 0;
	}

	constructor(public readonly key: TaxonomyLabel, private _filter: string = '') {
		super();
		this.name = taxonomyParentNames[key];
	}

	public handleFilterInput(e: Event) {
		const target = e.target instanceof HTMLInputElement && e.target;
		this.filter = target.value;
		this.notifyFacetChange('filter');
	}

	public clearFilter() {
		this._filter = '';
		this.notifyFacetChange('filter');
	}

	public deselectAll() {
		this.facets.forEach(f => {
			if (f.isSelected) {
				f.toggleSelected();
			}
		});
	}

	public selectByValue(value: string) {
		// because this is used only in radio buttons
		// first deselect all
		this.deselectAll();
		const f = this.facets.find(f => f.value === value);
		if (f && !f.isSelected) {
			f.toggleSelected();
		}
		this.notifyFacetChange('select');
	}

	private subscribeToChanges(facets: FacetViewModel[]) {
		for (const facet of facets) {
			facet.subscribe(FacetChangeEvent, e => this.notifyFacetChange(e.type));

			if (facet.children) {
				this.subscribeToChanges(facet.children);
			}
		}
	}

	private notifyFacetChange(type: FacetChangeType): void {
		this.publish(new FacetChangeEvent(type));
	}
}

/**
 * Reduce to items on which the parameter property is true.
 * Returns a flattened lookup object with { [fvm.value]: true }
 * @param property The property on the FacetViewModel on which to reduce.
 */
export function reduceFacetTo(property: 'isSelected' | 'isExpanded') {
	return (
		accumulator: FSSelectedFacets[TaxonomyLabel],
		current: FacetViewModel
	): FSSelectedFacets[TaxonomyLabel] => {
		if (current[property]) {
			accumulator[current.value] = true;
		} else if (current.hasChildren) {
			return current.children.reduce(reduceFacetTo(property), accumulator);
		}
		return accumulator;
	};
}

import {
	Assessment,
	AssessmentListResponse,
	AssessmentSession,
	Guidance
} from '../assessment/types';
import { process401Response } from '../auth/service';
import { apis } from '../environment/apis';
import { createRequest, fetchWithTimeout } from '../fetch';
import { msDocs } from '../globals';
import { isReview } from '../is-production';
import { toQueryString } from '../query-string';

const assessmentUrlBase = apis.assessments;
const assessmentSessionUrlBase = `${assessmentUrlBase}/sessions`;

export const assessmentDelimiter = '.';

export let currentSave: Promise<{ id: string }>;

export const assessmentApi = {
	/**
	 * Get full assessment data using the id of the assessment
	 * @param assessmentId The id of the assessment for which information will be returned
	 * @param version The version of the assessment for which information will be returned
	 * @param branch The branch of the assessment for which information will be returned
	 * @param locale The locale of the assessment for which information will be returned
	 */
	getAssessmentById(
		assessmentId: string,
		version?: string,
		branch?: string,
		locale?: string
	): Promise<Assessment> {
		const userLocale = locale || msDocs.data.userLocale;
		const query = { branch, locale: userLocale, version };
		const url = `${assessmentUrlBase}/${assessmentId}?${toQueryString(query)}`;

		return fetchWithTimeout(createRequest(url, null, false))
			.then(process401Response)
			.then(response => {
				if (response.status === 404) {
					return null;
				}
				if (response.ok) {
					return response.json();
				}
				return Promise.reject();
			});
	},

	/**
	 * Get the list of all assessments, returns abbreviated assessments with only required data to render cards
	 */
	async getAssessmentList(branch?: string): Promise<AssessmentListResponse> {
		const query = { locale: msDocs.data.userLocale, branch };
		const url = `${assessmentUrlBase}/list?${toQueryString(query)}`;

		return fetchWithTimeout(createRequest(url, null, false))
			.then(process401Response)
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject();
			});
	},

	/**
	 * Get a list of the sessions a user has begun or completed
	 */
	getSessionList(branch?: string): Promise<AssessmentSession[]> {
		const query = { branch };
		const url = `${assessmentSessionUrlBase}/list?${toQueryString(query)}`;
		const init: RequestInit = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: isReview ? 'include' : 'same-origin'
		};

		return fetchWithTimeout(createRequest(url, init))
			.then(process401Response)
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject();
			});
	},

	/**
	 * Get complete or in progress session data using the id of the session
	 * @param sessionId The id of the session for which information will be returned.
	 */
	getSessionById(sessionId: string): Promise<AssessmentSession> {
		const url = `${assessmentSessionUrlBase}/${sessionId}`;

		return fetchWithTimeout(createRequest(url))
			.then(process401Response)
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject();
			});
	},

	/**
	 * delete session data using the id of the session
	 * @param sessionId The id of the session which will be deleted.
	 */
	deleteSessionById(sessionId: string) {
		const url = `${assessmentSessionUrlBase}/${sessionId}`;
		const init: RequestInit = {
			method: 'DELETE'
		};

		return fetchWithTimeout(createRequest(url, init))
			.then(process401Response)
			.then(response => response.ok);
	},

	/**
	 * POST a session to the user's list of assessment sessions
	 * @param session A complete or in progress session object, including a responses object.
	 */
	saveSession(session: Partial<AssessmentSession>): Promise<{ id: string }> {
		const url = assessmentSessionUrlBase;
		const init: RequestInit = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: isReview ? 'include' : 'same-origin',
			body: JSON.stringify(session)
		};

		currentSave = fetchWithTimeout(createRequest(url, init))
			.then(process401Response)
			.then(response => {
				if (response.ok) {
					return response.json() as Promise<{ id: string }>;
				}
				return Promise.reject();
			});

		return currentSave;
	},

	/**
	 * POST a user's list of assessment responses
	 * @param responses A list of response ids for an assessment
	 */
	saveResponses(responses: string[]): Promise<void> {
		const url = `${assessmentUrlBase}/responses`;
		const init: RequestInit = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: isReview ? 'include' : 'same-origin',
			body: JSON.stringify(responses)
		};

		return fetchWithTimeout(createRequest(url, init))
			.then(process401Response)
			.then(response => {
				if (response.ok) {
					return Promise.resolve();
				}
				return Promise.reject();
			});
	},

	// Guidance
	/**
	 * Get guidance using the id of the session
	 * @param sessionId The id of the session for which guidance will be returned.
	 */
	getGuidanceBySessionId(sessionId: string): Promise<Guidance | { errorCode: string }> {
		const url = `${assessmentSessionUrlBase}/${sessionId}/guidance`;
		const init: RequestInit = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: isReview ? 'include' : 'same-origin'
		};

		return fetchWithTimeout(createRequest(url, init))
			.then(process401Response)
			.then(response => {
				if (response.status === 404 || response.status === 401) {
					return null;
				}
				if (response.status === 400 && response.body) {
					return response.json();
				}
				if (response.ok) {
					return response.json();
				}
				return Promise.reject();
			});
	},

	/**
	 * POST a guidance
	 * @param session A session object, including a responses object.
	 */
	saveGuidance(session: Partial<AssessmentSession>): Promise<Guidance> {
		const url = `${assessmentUrlBase}/guidance`;
		const init: RequestInit = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: isReview ? 'include' : 'same-origin',
			body: JSON.stringify(session)
		};

		return fetchWithTimeout(createRequest(url, init))
			.then(process401Response)
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject();
			});
	},

	getBranches(): Promise<string[]> {
		if (!isReview) {
			return Promise.resolve([]);
		}

		const query = { locale: msDocs.data.userLocale };
		const url = `${apis.assessmentsBranch}?${toQueryString(query)}`;

		const init: RequestInit = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: isReview ? 'include' : 'same-origin'
		};

		return fetchWithTimeout(createRequest(url, init, true))
			.then(process401Response)
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject();
			});
	},

	getBranchesByAssessment(assessmentId: string): Promise<string[]> {
		const query = { locale: msDocs.data.userLocale };

		const init: RequestInit = {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json'
			},
			credentials: isReview ? 'include' : 'same-origin'
		};

		const url = `${assessmentUrlBase}/${assessmentId}/branches?${toQueryString(query)}`;

		return fetchWithTimeout(createRequest(url, init, true))
			.then(process401Response)
			.then(response => {
				if (response.ok) {
					return response.json();
				}
				return Promise.reject();
			});
	}
};

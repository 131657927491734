import { html } from '../../lit-html';
import { forceWrite } from '../../plugins/lit-html/force-write';
import { Choice } from '../types';
import { AssessmentViewModel } from '../view-model';
import { ifDefined } from '.,/../lit-html/directives/if-defined';

export interface ImageRadiosTemplateData {
	choices: Choice[];
	name: string;
	label: string;
	isRequired?: boolean;
	externalLabel?: boolean;
}

export const imageRadioInputTemplate = (
	{ choices, name, label, isRequired = false, externalLabel }: ImageRadiosTemplateData,
	viewModel: AssessmentViewModel
) => {
	const {
		currentCategory: { id: categoryId },
		currentQuestion: { id: questionId }
	} = viewModel;
	const requiredIndicator = isRequired
		? html`<span class="required-indicator" aria-hidden="true"></span>`
		: html``;
	return html`
		${externalLabel
			? html` <legend>
					${label} ${requiredIndicator}
			  </legend>`
			: html``}
		<div class="radios has-images">
			${choices.map(({ id, imageUrl, text, description }) => {
				const checked = viewModel.isSelectedResponse(categoryId, questionId, id);
				return html`
					<label
						class="radio is-image"
						title="${ifDefined(description ?? undefined)}"
						aria-describedby="${ifDefined(description ? `${id}-tooltip` : undefined)}"
					>
						<span class="radio-image" aria-hidden="true">
							<img src="${imageUrl}" />
						</span>
						<input
							checked
							name="${name}"
							type="radio"
							.value="${id}"
							.checked=${forceWrite(checked)}
							?required=${isRequired}
						/>
						<span class="radio-background"></span>
						<span class="radio-dot" aria-hidden="true"></span>
						<span class="radio-label-text">${text}</span>
						${description
							? html`<span class="is-visually-hidden" id="${id}-tooltip" role="tooltip"
									>${description}</span
							  >`
							: null}
					</label>
				`;
			})}
		</div>
	`;
};

export const keyCodes = {
	left: 37,
	up: 38,
	right: 39,
	down: 40,
	home: 36,
	end: 35,
	escape: 27,
	enter: 13,
	space: 32,
	eight: 56,
	numPadAsterisk: 106,
	a: 65,
	b: 90
};

import {
	loc_cancel,
	loc_close,
	loc_completed,
	loc_continue,
	loc_done,
	loc_portallab_modalCloseDescription,
	loc_portallab_modalCloseTitle,
	loc_previous,
	loc_returnToModule,
	loc_unitMarker
} from '@msdocs/strings';
import { html, render } from '../lit-html';
import * as labApi from '../apis/lab-on-demand';
import { LearnItem, Module } from '../apis/learn';
import { Modal } from '../components/modal/modal';
import { features } from '../environment/features';
import { contentLoaded, msDocs } from '../globals';
import { InteractiveType } from '../interactivity/activation';
import { getMeta } from '../meta';
import { toQueryString } from '../query-string';
import { escape } from '../text-formatting';
import { UnitModuleConfig } from './config';
import { createActionAnchorElement } from './navigation-rendering';

export function buildLabOnDemandNavigation(
	anchor: HTMLAnchorElement,
	targetUnit: LearnItem,
	interactiveName: InteractiveType,
	isChromeless: boolean
) {
	if (!isChromeless) {
		return;
	}

	const currentIsLab = interactiveName === 'lab-on-demand';
	const targetIsLab = targetUnit.interactive === 'lab-on-demand';
	const stayInFrame = currentIsLab && targetIsLab;
	if (stayInFrame) {
		// the hosting layer will not set the frame options response header
		// unless we provide the FromOrigin argument.
		const args = {
			FromOrigin: 'https://labclient.labondemand.com',
			context: 'context/chromeless'
		};
		anchor.search = toQueryString(args);
	} else {
		// hide the anchor
		anchor.hidden = true;
		// if previous swap anchor for disabled button
		if (anchor.id === 'previous-unit-link-mobile') {
			render(
				html`
					<button
						disabled
						class="button is-secondary is-disabled is-fullwidth is-radiusless is-large has-margin-none has-inner-focus"
					>
						<span aria-hidden="true" class="icon docon docon-chevron-left-light"></span>
						${loc_previous}
					</button>
				`,
				anchor.parentElement
			);
			return;
		}
		// if next create an open button for the modal to confirm closing the lab
		const closeLabModal = createLabCloseModal(anchor.href);
		render(
			html`
				<button
					@click=${() => closeLabModal.show()}
					class="button is-primary is-fullwidth is-radiusless is-large has-margin-none has-inner-focus"
				>
					${loc_done}
				</button>
			`,
			anchor.parentElement
		);
	}
}

export function createLabCloseModal(url: string): Modal {
	const modalConsentContainer = document.createElement('div');
	modalConsentContainer.classList.add('modal-content', 'is-full-width');
	render(
		html`
		<div id="consentModal" class="modal-card has-padding-medium has-padding-large-tablet" aria-describedby="modalTitle" aria-labelledby="modalTitle"">
			<button class="modal-close modal-card-close-button is-large is-sticky" data-bi-name="close" aria-label="${loc_close}"></button>
			<section class="modal-card-body">
				<h2 class="title is-2 has-margin-bottom-small" id="modalTitle">${loc_portallab_modalCloseTitle}</h2>
				<p class="has-margin-bottom-medium">${loc_portallab_modalCloseDescription}</p>
				<div class="buttons">
					<button id="closer" @click=${(e: { target: HTMLButtonElement }) =>
						closeLab(e.target, url)} class="button is-primary">${loc_continue}</button>
					<button class="modal-close button is-unstyled">${loc_cancel}</button>
				</div>
			</section>
		</div>
	`,
		modalConsentContainer
	);
	return new Modal(modalConsentContainer);
}

function closeLab(button: HTMLButtonElement, url: string) {
	button.classList.add('is-loading');
	const labIsModal = getMeta('lab-modal') === 'true';
	const labId = parseInt(getMeta('lab-id'));
	labApi.get().then(labDetails => {
		const currentLab = labDetails.RunningLabs.find(lab => lab.LabProfileId === labId);
		// Fail safe if the lab failed to load
		if (!currentLab) {
			return closeLabWindow(url, labIsModal);
		}
		labApi.remove(currentLab.LabInstanceId).then(() => {
			closeLabWindow(url, labIsModal);
		});
	});
}

function closeLabWindow(url: string, labIsModal: boolean) {
	if (labIsModal) {
		// send user to next module unit in new window
		window.open(url);
		// wait for cancel call and LOD to close the adjacent lab window then close our window
		setTimeout(() => top.window.close(), 5500);
		return;
	}
	// VM lab open go to next unit in same window
	window.top.location.href = url;
}

export async function renderModuleNavigation(config: UnitModuleConfig): Promise<HTMLElement[]> {
	await contentLoaded;
	const { unitIndex, module, interactiveName, isChromeless } = config;
	const { length: unitTotal } = module.units;
	if (!isChromeless) {
		buildUnitMenu(module, unitIndex);
	}
	buildUnitNavigation(
		module.units,
		unitIndex,
		unitTotal,
		interactiveName,
		isChromeless,
		module.url
	);
	return Array.from(document.querySelectorAll('.module-navigation-item')) as HTMLLIElement[];
}

export function buildUnitNavigation(
	units: LearnItem[],
	unitIndex: number,
	unitTotal: number,
	interactiveName: InteractiveType,
	isChromeless: boolean,
	moduleUrl: Module['url']
) {
	const previousButton = document.getElementById('previous-unit-link');
	const nextButton = document.getElementById('next-unit-link');
	const unitPlace = document.getElementById('unit-place');
	const prevColMobile = document.getElementById('previous-unit-link-mobile-col');
	const prevMobile = document.getElementById('previous-unit-link-mobile') as HTMLAnchorElement;
	const nextColMobile = document.getElementById('next-unit-link-mobile-col');
	const nextMobile = document.getElementById('next-unit-link-mobile') as HTMLAnchorElement;
	const unitDetailText = loc_unitMarker
		.replace('{index}', (unitIndex + 1).toString())
		.replace('{total}', unitTotal.toString());
	const notLastUnit = unitIndex !== unitTotal - 1;

	unitPlace.innerText = unitDetailText;
	if (unitIndex !== 0) {
		previousButton.setAttribute('href', '/' + msDocs.data.userLocale + units[unitIndex - 1].url);
		previousButton.classList.remove('is-invisible');
		prevMobile.setAttribute('href', '/' + msDocs.data.userLocale + units[unitIndex - 1].url);
		buildLabOnDemandNavigation(prevMobile, units[unitIndex - 1], interactiveName, isChromeless);
		prevColMobile.classList.remove('is-hidden');
	}
	if (notLastUnit) {
		nextButton.setAttribute('href', '/' + msDocs.data.userLocale + units[unitIndex + 1].url);
		nextButton.classList.remove('is-invisible');
		nextMobile.setAttribute('href', '/' + msDocs.data.userLocale + units[unitIndex + 1].url);
		buildLabOnDemandNavigation(nextMobile, units[unitIndex + 1], interactiveName, isChromeless);
		nextColMobile.classList.remove('is-hidden');
	}

	if (!features.gamification) {
		handleNonGamifiedNavigation(notLastUnit, units, unitIndex, moduleUrl);
	}
}

/**
 *  When in some environments we cannot rely on normal progress based navigation.
 *  In these cases, just a continue link to the next unit,
 *  or a link to the module's page if there is not next unit.
 * @param notLastUnit Whether we're on the final unit in a module
 * @param units The array of units for this module
 * @param unitIndex The index of the unit the user is on
 * @param moduleUrl The url to the module's page
 */
function handleNonGamifiedNavigation(
	notLastUnit: boolean,
	units: LearnItem[],
	unitIndex: number,
	moduleUrl: string
) {
	const container = document.getElementById('unit-inner-section');
	if (!container) {
		return;
	}
	const href = `/${msDocs.data.userLocale}/${notLastUnit ? units[unitIndex + 1].url : moduleUrl}`;
	const nextActionIsolated = createActionAnchorElement(
		{
			elementType: 'a',
			href,
			text: notLastUnit ? loc_continue : loc_returnToModule,
			biName: 'continue'
		},
		notLastUnit ? 'continue' : 'next-step-module'
	);

	if (nextActionIsolated) {
		container.insertAdjacentElement('beforeend', nextActionIsolated);
	}
}

export function buildUnitMenu(module: Module, unitIndex: number) {
	const template = `
	<div class="nav-box">
		<h2 class="nav-box-title is-size-5 has-margin-top-none">${escape(module.title)}</h2>
		<ul class="has-margin-none is-size-small has-text-subtle is-unstyled" data-bi-name="module-menu-links">
			${module.units
				.map(
					({ uid, title, url, durationInMinutes }) => `
				<li data-nav-uid="${uid}" data-progress-uid="${uid}" class="nav-box-line module-navigation-item completable is-unstyled">
					<a href="/${msDocs.data.userLocale}${url}" class="line-item is-spaced has-inner-focus">
						<span>${escape(title)}</span><div>
							<span class="is-hidden-complete is-undecorated has-no-wrap">${durationInMinutes} min</span>
							<span class="is-shown-complete has-text-success docon docon-check">
								<span class="is-visually-hidden">: ${loc_completed}</span>
							</span>
						</div>
					</a>
				</li>
			`
				)
				.join('')}
		</ul>
	</div>
	`;
	const moduleMenu = document.getElementById('module-menu');
	if (moduleMenu) {
		moduleMenu.innerHTML = template;
	}
	const currentLi = document.querySelector(
		'.has-centered-menu li:nth-of-type(' + (unitIndex + 1) + ')'
	);
	if (currentLi) {
		currentLi.classList.add('is-current');
	}
}

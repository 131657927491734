import { loc_clearfilter, loc_noResults } from '@msdocs/strings';
import { html, TemplateResult } from '../../../lit-html';
import { FacetGroupViewModel } from '../facet-group';

export function clearFilterInputTemplate(vm: FacetGroupViewModel): TemplateResult {
	if (!vm.hasFilterInput || !vm.filterActive || vm.hasVisibleChild) {
		return html``;
	}
	return html`
		<p class="has-padding-bottom-medium">
			${loc_noResults}.
			<button
				@click=${() => vm.clearFilter()}
				data-bi-name="clear-facet-filter"
				class="is-inline has-inner-focus has-margin-none has-appearance-none has-text-primary has-border-none has-background-transparent"
			>
				${loc_clearfilter}
			</button>
		</p>
	`;
}

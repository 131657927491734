export const focusable = [
	'a',
	'area',
	'button:not([disabled])',
	'iframe',
	'input:not([disabled])',
	'select:not([disabled])',
	'textarea:not([disabled])',
	'[contenteditable]',
	'[tabindex]:not([tabindex="-1"])'
];

export function isFocusable(element: HTMLElement) {
	return element.matches(focusable.join(','));
}

export function constrainFocus(element: HTMLElement, wrap = true) {
	return (event: FocusEvent) => {
		if (event.target instanceof Element && !element.contains(event.target)) {
			event.preventDefault();
			let target: HTMLElement;
			const visibleFocusableElements = Array.from(
				element.querySelectorAll<HTMLElement>(focusable.join(','))
			) as HTMLElement[];

			if (
				event.target.compareDocumentPosition(element) &
				(wrap ? Node.DOCUMENT_POSITION_PRECEDING : Node.DOCUMENT_POSITION_FOLLOWING)
			) {
				visibleFocusableElements.reverse();
			}

			let i = visibleFocusableElements.length;

			while (i--) {
				const el = visibleFocusableElements[i];
				const style = getComputedStyle(el);
				if (!el.hidden && style.display !== 'none' && style.visibility !== 'hidden') {
					target = el;
					break;
				}
			}

			target = target || element;
			target.focus();
		}
	};
}

export function createTabSentinel(document: Document) {
	const sentinel = document.createElement('span');
	sentinel.setAttribute('tabindex', '0');
	sentinel.classList.add('modal-tab-sentinel');

	return sentinel;
}

/**
 * MS Edge has a bad way of handling focused elements when they disappear from the DOM or are hidden -
 * Edge will completely lose focus state and start over from the top of the page. Chrome and Firefox
 * do not do this. This is a workaround until Edge gets it together.
 *
 * Usage - if focus is on an element that will disappear (like the close button on a banner),
 * use this function to get the next tabbable element and then manually set focus before destroying
 * or hiding the currently focused element.
 *
 * @param element The current element.
 * @param container An optional containing element, if we need to get the next tabbable element outside of the container.
 */
export function getNextFocusableElement(
	element: HTMLElement,
	container?: HTMLElement
): HTMLElement {
	const focusableElements = Array.from(
		document.body.querySelectorAll<HTMLElement>(focusable.join(','))
	);
	const currentIndex = focusableElements.indexOf(element);

	if (!container) {
		return focusableElements[currentIndex + 1] || focusableElements[0];
	}

	for (let i = currentIndex; i < focusableElements.length; i++) {
		if (
			!(
				container.compareDocumentPosition(focusableElements[i]) &
				Node.DOCUMENT_POSITION_CONTAINED_BY
			)
		) {
			return focusableElements[i];
		}
	}

	return focusableElements[0];
}

import { getBranch } from '../../../apis/learn';
import { authStatusDetermined } from '../../../auth';
import { defaultFacetSearchFilterOptions } from '../filter-options';
import { FacetSearchResponse } from '../model';
import { SearchVMConfig, UISearchArgs } from '../view-model';
import { apis } from '../../../environment/apis';
import { createRequest, fetchWithTimeout } from '../../../fetch';
import { msDocs } from '../../../globals';
import { html } from '../../../lit-html';
import { InputArgs, toQueryString } from '../../../query-string';
import { loc_exam } from '@msdocs/strings';
import { createTagHTML, createTagStrings } from '../../../tags';
import { CertificationId } from '../../../name-maps/certifications';
import { RoleId } from '../../../name-maps/roles';
import { LevelId } from '../../../name-maps/level';
import { ProductId } from '../../../name-maps/product';
import { CertificationResourceTypeId } from '../../../name-maps/resource-type';
import { taxonomyNames } from '../../../name-maps/taxonomy';

export interface CertificationBrowseResult {
	exam_display_name?: string;
	exams?: CertificationBrowseResultExam[];
	icon_url: string;
	last_modified: string; // i.e. 11/09/2018 00:00:00 +00:00
	levels: LevelId[];
	locale: string;
	products: ProductId[];
	resource_type: CertificationResourceTypeId;
	roles: RoleId[];
	title: string;
	type?: CertificationId;
	uid: string;
	url: string;
}

interface CertificationBrowseResultExam {
	display_name: string;
	uid: string;
	url: string;
}
interface CertificationArgs {
	branch: string;
	locale: string;
	terms: string;
	facet: string[];
	$filter: string;
	$orderBy: string;
	$skip: string;
	$top: string;
}

export function createCertificationFacetSearchConfig(): SearchVMConfig<CertificationBrowseResult> {
	return {
		layout: 'grid',
		fetch: fetchCertificationResults,
		resultTemplate: createCertificationFacetSearchCard
	};
}

const certificationSearchFacetDefaults = {
	orderBy: 'last_modified desc',
	top: 30
};

/**
 * Function that renders the cards for the Certification browse page, only applies to browseType: 'certification'.
 * @param items The resultant items from the Certification browse service API call.
 */
function createCertificationFacetSearchCard(item: CertificationBrowseResult) {
	const examItemsHtml = (item.exams || [])
		.filter(exam => {
			return !!exam.display_name;
		})
		.map((exam, index) => {
			return index === 0 ? html` ${exam.display_name}` : html`, ${exam.display_name}`;
		});

	const examsTemplate =
		examItemsHtml.length > 0
			? html`<li>${loc_exam} <span class="is-comma-delimited">${examItemsHtml}</span></li>`
			: '';

	const itemSuperTitle = taxonomyNames.resource_type[item.resource_type];

	const itemTitle =
		item.resource_type === 'examination'
			? `${loc_exam} ${item.exam_display_name}: ${item.title}`
			: item.title;

	return html`
		<article class="card" data-bi-name="card">
			<div class="card-header">
				<figure class="card-header-image">
					<img role="presentation" src="${item.icon_url}" alt="" />
				</figure>
			</div>
			<div class="card-content">
				<p class="card-content-super-title">${itemSuperTitle}</p>
				<a href="${item.url}" class="card-content-title">
					<h3>${itemTitle}</h3>
				</a>
				<ul class="card-content-metadata">
					${examsTemplate}
				</ul>
				<ul class="tags">
					${createTagHTML(createTagStrings(item))}
				</ul>
			</div>
		</article>
	`;
}

async function fetchCertificationResults(
	args: UISearchArgs
): Promise<FacetSearchResponse<CertificationBrowseResult>> {
	const [results] = await Promise.all([getCertificationResults(args), authStatusDetermined]);
	return results;
}

function uiArgsToCertification(args: UISearchArgs): CertificationArgs {
	return {
		branch: getBranch(),
		locale: msDocs.data.userLocale,
		terms: args.terms,
		facet: ['roles', 'products', 'levels', 'resource_type', 'type'],
		$filter: defaultFacetSearchFilterOptions(args.selectedFacets),
		// always null if terms are present
		$orderBy: args.terms && args.terms.length > 0 ? null : certificationSearchFacetDefaults.orderBy,
		$skip: args.skip ? args.skip.toString() : null,
		$top: certificationSearchFacetDefaults.top.toString()
	};
}

function getCertificationResults(
	args: UISearchArgs
): Promise<FacetSearchResponse<CertificationBrowseResult>> {
	const query = (uiArgsToCertification(args) as unknown) as InputArgs;
	const url = `${apis.browse.certification}?${toQueryString(query, true)}`;

	const init = { method: 'GET' };

	return fetchWithTimeout(createRequest(url, init)).then(response => {
		if (response.ok) {
			return response.json();
		}
		/* eslint-disable-next-line */
		throw { error: response.json() };
	});
}

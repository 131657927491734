/**
 * Tree transformations.
 */
import { location, msDocs } from '../globals';
import { TocNode, TocNodeBreadcrumb } from './types';
import { resolveUrl } from './url';
import { walk } from './walk';

/**
 * Resolves each node's URL, relative to the specified base URL.
 */
export function resolveUrls(nodes: TocNode[] | TocNodeBreadcrumb[], baseUrl: string) {
	const fallbackHref = `${location.origin}/${msDocs.data.userLocale}/`;
	const fallbackUrl = resolveUrl(fallbackHref, baseUrl);
	walk(nodes, node => {
		if (node.href) {
			node.url = resolveUrl(node.href, baseUrl);
		} else if (!node.children) {
			// Node is a leaf node with no href. Use the docs homepage URL.
			// https://ceapex.visualstudio.com/Engineering/_workitems/edit/91703
			node.href = fallbackHref;
			node.url = fallbackUrl;
		}
		return true;
	});
}

/**
 * When the TOC data contains a single root node, remove it's url and create a leaf node from it.
 */
export function ungroupRootNode(nodes: TocNode[]) {
	if (nodes.length !== 1) {
		return;
	}
	const rootNode = nodes[0];
	if (!rootNode.children || !rootNode.url) {
		return;
	}
	cloneAsLeaf(rootNode);
}

/**
 * Clones a node as a leaf node.
 */
export function cloneAsLeaf(node: TocNode) {
	const clone = Object.assign({}, node);
	delete clone.children;
	delete clone.isNewSection;
	delete node.url;
	delete node.href;
	node.children.unshift(clone);
}

/**
 * Expands and selects a node path.
 */
export function expandAndSelectPath(nodePath: TocNode[]) {
	const last = nodePath[nodePath.length - 1];
	for (const node of nodePath) {
		const property = node === last ? 'selected' : 'expanded';
		node[property] = true;
	}
}

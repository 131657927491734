import { cookieConsent } from './cookie-consent';
import { contentLoaded } from './globals';
import { loadLibrary } from './load-library';
import { getMeta } from './meta';

export function enableTwitterWidgets() {
	if (getMeta('twitterWidgets') === 'true') {
		Promise.all([cookieConsent, contentLoaded]).then(() =>
			loadLibrary('https://platform.twitter.com/widgets.js')
		);
	}
}

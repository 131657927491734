import { Module } from '../../apis/learn';
import { AzureSandboxApiResult } from '../../apis/sandbox';
import { User, user } from '../../auth/user';
import { document } from '../../globals';
import * as urlActivation from './url-activation';
import { getMeta } from '../../meta';

/**
 * The sandbox prompt state.
 */
export interface SandboxPromptState {
	/** The sandbox activation UI container. */
	container: Element;
	/** Whether the sandbox is disabled for the current module. */
	disabled: null | boolean;
	/** The auth state: user and status */
	auth: { user: User; authStatusDetermined: boolean };
	/** The Unit ID - document.querySelector('meta[name="uid"]').content */
	unitUid: string;
	/** Whether the user chose to activate a sandbox for the current module. */
	userDidActivate: boolean;
	/** Whether the user chose to release an existing sandbox for another module. */
	userDidRelease: boolean;
	/** Whether the user chose to deactivate the sandbox for the current module. */
	userDidDeactivate: boolean;
	/** Whether the user was referred to this page by the tenant invite page. */
	referredByTenantInvite: boolean;
	/** The number of times we've attempted to provision the sandbox (via POST). */
	provisionAttempts: number;
	/** The current module. */
	module?: Module;
	/** The most recent sandbox API call result. */
	apiResult?: AzureSandboxApiResult;
	/** Module info for the currently active sandbox. May not be the current module. */
	sandboxModuleInfo?: { module: Module; isComplete: boolean };
	/** Exception caught while invoking a controller. */
	exception?: any;
}

/**
 * Create the initial sandbox prompt state.
 */
export function getInitialState(container: Element): SandboxPromptState {
	return {
		container,
		disabled: null,
		auth: { user, authStatusDetermined: false },
		unitUid: getMeta('uid'),
		userDidActivate: urlActivation.alreadyActivated(),
		userDidRelease: false,
		userDidDeactivate: false,
		referredByTenantInvite: document.referrer.indexOf('tenant-invitation-accepted.html') !== -1,
		provisionAttempts: 0
	};
}

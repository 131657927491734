import {
	loc_findPostsTagsUsers,
	loc_noResultsFound,
	loc_search,
	loc_searchHelp
} from '@msdocs/strings';
import {
	ForumQuestionSuggestion,
	getForumQuestionSuggestions,
	qnaMinUserInput
} from '../../apis/question-suggestions';
import {
	autocompleteChangeEvent,
	AutoCompleteSuggestionDetail,
	createAutoComplete
} from '../../components/autocomplete';
import { msDocs } from '../../globals';
import { html } from '../../lit-html';
import { reportAutocompleteWithJsll, suggestionClass } from '../../toc/search/shared';
import { parseQueryString } from '../../query-string';

const locale = `/${msDocs.data.userLocale}`;

/**
 * Builds the url string used for both the form submit and user selection.
 * @param userInput value of the user input
 */
export const getUrlForTerm = (userInput: string) =>
	`${locale}/answers/search.html?c=&includeChildren=&f=&type=question+OR+idea+OR+kbentry+OR+answer+OR+topic+OR+user&redirect=search%2Fsearch&sort=relevance&q=${encodeURIComponent(
		userInput
	)}`;

const getSuggestions = async (userInput: string): Promise<ForumQuestionSuggestion[]> => {
	const header: ForumQuestionSuggestion = {
		acceptedAnswer: false,
		answerCount: 0,
		title: userInput,
		type: 'header',
		upVotesCount: 0,
		url: getUrlForTerm(userInput)
	};

	const footer: ForumQuestionSuggestion = {
		...header,
		title: loc_searchHelp,
		type: 'help',
		url: `${locale}/answers/search/advanced-search-usage.html`
	};

	const noResults: ForumQuestionSuggestion[] = [
		header,
		{
			...header,
			type: '',
			title: loc_noResultsFound,
			url: `${locale}/answers/search`
		},
		footer
	];

	userInput = userInput.trim();
	if (userInput.length < qnaMinUserInput) {
		return [];
	}

	const results: ForumQuestionSuggestion[] = await getForumQuestionSuggestions(userInput, 'object');

	return results.length ? [header, ...results, footer] : noResults;
};

const suggestionHeaderClass: string = 'is-block is-size-small has-padding-small has-text-color';

/**
 * Builds the docon string for the icons based off the suggestion type
 * @param suggestionType suggestion type
 */
const suggestionDoconMap: { [key: string]: string } = {
	idea: 'lightbulb-regular',
	kbentry: 'book-solid',
	question: 'question-circle-solid',
	topic: 'tag-circle-solid'
};

const suggestionDocon = (suggestionType: string) =>
	suggestionDoconMap[suggestionType] ? `docon-${suggestionDoconMap[suggestionType]}` : '';

const itemTemplate = (suggestion: ForumQuestionSuggestion) => {
	const { title, type } = suggestion;

	switch (type) {
		case 'header':
			return html`
				<span class="${suggestionHeaderClass} has-border-bottom">
					${loc_search}: ${title}
				</span>
			`;
		case 'help':
			return html`
				<span class="${suggestionHeaderClass} has-border-top">
					<span class="has-text-primary">${title}</span>
				</span>
			`;
		default:
			return html`
				<span class="${suggestionClass} has-overflow-ellipsis has-text-primary">
					<span class="icon docon ${suggestionDocon(type)} xs-hidden " aria-hidden="true"></span>
					${title}
				</span>
			`;
	}
};

/**
 * Creates the autocomplete search bar
 * @param id input field id
 */
export const createQnaSearchAutocomplete = (id: string) => {
	const userQuery = parseQueryString().q || '';
	const userSuggestion = {
		acceptedAnswer: false,
		answerCount: 0,
		title: userQuery,
		type: 'header',
		upVotesCount: 0,
		url: getUrlForTerm(userQuery)
	};

	const autocomplete = createAutoComplete<ForumQuestionSuggestion>({
		input: {
			id,
			name: 'q',
			placeholder: loc_findPostsTagsUsers,
			type: 'search',
			docon: 'search',
			isFullWidth: true
		},
		getSuggestions,
		getTitle: (suggestion: ForumQuestionSuggestion) => suggestion.title,
		itemTemplate,
		initialValue: userSuggestion
	});

	autocomplete.addEventListener(
		autocompleteChangeEvent,
		(event: CustomEvent<AutoCompleteSuggestionDetail<ForumQuestionSuggestion>>) => {
			const { title, url } = event.detail.suggestion;
			reportAutocompleteWithJsll(autocomplete, title, url);
			autocomplete.value = null;
			location.href = url;
		}
	);

	return autocomplete;
};

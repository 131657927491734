import { msDocs } from './globals';

export enum WordBreakFlags {
	None = 0,
	Case = 1 << 0,
	Dot = 1 << 1,
	Slash = 1 << 2
}

const breakTextRegexDot = /([a-z]\.)([a-z])/gi;
const breakTextRegexCase = /([a-z])([A-Z]+[a-z])/g;
const breakTextRegexSlash = /(\w\/)(\S?)/gi;
const breakElement = '<wbr>';
const breakTextReplace = `$1${breakElement}$2`;
const unbreakTextRegex = /\u200B/g;

export function breakText(str: string, flags = WordBreakFlags.Dot | WordBreakFlags.Case): string {
	if (!str || !str.length || flags === WordBreakFlags.None) {
		return str;
	}

	if (flags & WordBreakFlags.Dot) {
		str = str.replace(breakTextRegexDot, breakTextReplace);
	}

	if (flags & WordBreakFlags.Case) {
		str = str.replace(breakTextRegexCase, breakTextReplace);
	}

	if (flags & WordBreakFlags.Slash) {
		str = str.replace(breakTextRegexSlash, breakTextReplace);
	}
	return str;
}

/**
 * Break text into chunks compatible with lit-html rendering.
 * @param str The text to break up.
 * @param flags Break opportunity pattern flags.
 */
export function litBreakText(str: string, flags: WordBreakFlags): TextSegment[] {
	const result: TextSegment[] = [];
	if (!str || flags === WordBreakFlags.None) {
		return result;
	}

	str = breakText(str, flags);

	let startIndex = 0;
	let nextIndex = str.indexOf(breakElement, startIndex);
	while (nextIndex !== -1) {
		result.push(str.substring(startIndex, nextIndex));
		result.push(document.createElement('wbr'));
		startIndex = nextIndex + breakElement.length;
		nextIndex = str.indexOf(breakElement, startIndex);
	}

	result.push(str.substring(startIndex));
	return result;
}

export type TextSegment = string | Element;

export function unbreakText(str: string) {
	return str.replace(unbreakTextRegex, '');
}

const htmlEscapes: { [char: string]: string } = {
	'&': '&amp;',
	'<': '&lt;',
	'>': '&gt;',
	'"': '&quot;',
	"'": '&#39;'
};
const reUnescapedHtml = /[&<>"']/g;
const reHasUnescapedHtml = RegExp(reUnescapedHtml.source);
/**
 * Converts the characters "&", "<", ">", '"', and "'" in `string` to their
 * corresponding HTML entities.
 *
 * **Note:** No other characters are escaped. To escape additional
 * characters use a third-party library like [_he_](https://mths.be/he).
 *
 * Though the ">" character is escaped for symmetry, characters like
 * ">" and "/" don't need escaping in HTML and have no special meaning
 * unless they're part of a tag or unquoted attribute value. See
 * [Mathias Bynens's article](https://mathiasbynens.be/notes/ambiguous-ampersands)
 * (under "semi-related fun fact") for more details.
 *
 * When working with HTML you should always
 * [quote attribute values](http://wonko.com/post/html-escaping) to reduce
 * XSS vectors.
 *
 * @since 0.1.0
 * @category String
 * @param {string} [string=''] The string to escape.
 * @returns {string} Returns the escaped string.
 * @example
 *
 * escape('fred, barney, & pebbles')
 * // => 'fred, barney, &amp pebbles'
 */
export function escape(string: string) {
	// https://github.com/lodash/lodash/blob/master/escape.js
	return string && reHasUnescapedHtml.test(string)
		? string.replace(reUnescapedHtml, chr => htmlEscapes[chr])
		: string;
}

export function escapeRegExp(string: string) {
	// https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex/6969486#6969486
	return string.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}

let supportsInnerText = false;
export function checkInnerTextSupported(): boolean {
	document.body.insertAdjacentHTML('beforeend', '<div><span hidden>hidden</span></div>');
	const el = document.body.lastElementChild as HTMLElement;
	supportsInnerText = el.innerText === '';
	document.body.removeChild(el);
	return supportsInnerText;
}

export function getVisibleTextContent(elt: HTMLElement): string {
	if (supportsInnerText) {
		return elt.innerText;
	}

	// clone the node and add it to the body so we can use getComputedStyle
	const clone = elt.cloneNode(true) as HTMLElement;
	clone.hidden = true;
	document.body.appendChild(clone);

	// remove any children that are hidden by css.
	function removeHiddenNodes(el: Element) {
		if (el === null) {
			return;
		}
		removeHiddenNodes(el.nextElementSibling);
		if (window.getComputedStyle(el, null).getPropertyValue('display') === 'none') {
			el.parentElement.removeChild(el);
		} else {
			removeHiddenNodes(el.firstElementChild);
		}
	}
	removeHiddenNodes(clone.firstElementChild);

	// cleanup
	document.body.removeChild(clone);

	return clone.textContent;
}

export function toLocaleDate(
	date: Date,
	formatOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' },
	locale = msDocs.data.userLocale
) {
	return new Date(date).toLocaleDateString(locale, formatOptions).replace(/\u200E/g, ''); // replace ltr mark (Edge/IE https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/15643706/)
}

export function localizeDateElements(formatOptions?: Intl.DateTimeFormatOptions) {
	const elts = Array.from(
		document.querySelectorAll('span[data-localize-date]')
	) as HTMLSpanElement[];
	elts.forEach(dateEl => {
		if (dateEl.textContent !== '') {
			const localizedDate = toLocaleDate(new Date(dateEl.textContent), formatOptions);
			dateEl.textContent = localizedDate;
		}
	});
}

/**
 * Regex matching products which require left-to-right marks when
 * displayed in RTL (C#, F#, and C++).
 */
const lrmProductsRegex = /(^|\s)(C#|F#|C\+\+)($|\s|[.,!?;:])/g;

/**
 * Adds left-to-right marks to strings containing products
 * that require marks when displayed in RTL.
 * @param text The text to adjust.
 * @param markType The type of left-to-right mark to add (unicode character or HTML entity).
 */
export function insertLeftToRightMark(text: string, markType: 'text' | 'html' = 'text') {
	const replacement = `$1$2${markType === 'text' ? '\u200E' : '&lrm;'}$3`;
	return text.replace(lrmProductsRegex, replacement);
}

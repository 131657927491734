import { loc_search, loc_searchPreviousVersions } from '@msdocs/strings';
import { fetchAutocompleteSuggestion, readAddressBar } from '../../apis/site-search';
import { checkIsArchived } from '../../archive';
import {
	AutoCompleteConfig,
	AutoCompleteSuggestionDetail,
	createAutoComplete
} from '../autocomplete';
import { instrumentAutocompleteList, instrumentAutocompleteSelection } from '../facet-search/bi';
import { NavBarViewModel } from './view-model';
import { traits } from '../../environment/traits';
import { document, location, msDocs } from '../../globals';
import { generateElementId } from '../../html';
import { isTestRepo } from '../../is-production';
import { keyCodes } from '../../key-codes';
import { html, TemplateResult } from '../../lit-html';
import { suggestionClass } from '../../toc/search/shared';
import { getScope } from '../../scope';

export const pivotCategoryChangeEvent = 'pivot-category-change-event';
export const mooncakeFormActionUrl = 'https://www.azure.cn/searchresults/';

export function headerSearchFormTemplate(
	viewModel: NavBarViewModel,
	isDesktopWidthForm: boolean = false
): TemplateResult {
	const collapseOnEscape = (event: KeyboardEvent) => {
		if (event.keyCode === keyCodes.escape && viewModel.searchExpanded) {
			viewModel.searchExpanded = false;
			document.getElementById('form-expander').focus();
		}
	};

	const { scope, category, dataSource, preview } = getSearchConfig();
	let actionUrl;
	if (msDocs.data.brand === 'mooncake') {
		actionUrl = mooncakeFormActionUrl;
	} else if (!traits.systemContent) {
		// for developer, rd, advocates satellite sites, search goes to Docs, review search not supported
		actionUrl = `https://docs.microsoft.com/${msDocs.data.userLocale}/search/`;
	} else {
		actionUrl = `/${msDocs.data.userLocale}/${isTestRepo ? 'test/docs/search/search' : 'search/'}`;
	}

	// 'q' is the search term name for mooncake search
	const inputName = msDocs.data.brand === 'mooncake' ? 'q' : 'search';
	const formId = isDesktopWidthForm ? 'searchFormDesktop' : 'searchForm';

	return html`
		<form
			class="nav-bar-search-form"
			method="GET"
			id="${formId}"
			name="searchForm"
			aria-label="${loc_search}"
			role="search"
			aria-expanded="${viewModel.searchExpanded}"
			action="${actionUrl}"
			@keydown=${collapseOnEscape}
			@autocomplete-change-event=${autocompleteChangeHandler}
			@autocomplete-displayed-event=${autocompleteDisplayedHandler}
		>
			${createNavAutocomplete(inputName, isDesktopWidthForm)}
			<!-- mobile safari will not dispatch submit event unless there's a submit button that is not display:none -->
			<button type="submit" class="visually-hidden" tabindex="-1" aria-hidden="true"></button>
			<input name="category" value="${category}" hidden />
			${scope ? html`<input name="scope" value="${scope}" hidden />` : ''}
			${dataSource ? html`<input name="dataSource" value="${dataSource}" hidden />` : ''}
			${preview ? html`<input name="preview" value="${preview}" hidden />` : ''}
		</form>
	`;
}

export function updateInputSearchTerm(searchInput: HTMLInputElement, term?: string) {
	if (!searchInput) {
		return;
	}

	term = term || readAddressBar(location.search).term;
	searchInput.value = term;
}

function getSearchConfig() {
	const scope = getScope();
	const category = getCategory();
	const preview = getPreview();

	return {
		scope,
		category,
		dataSource: checkIsArchived() ? 'previousVersions' : '',
		preview
	};
}

function getCategory(): string {
	let categoryValue: string;
	const categoryInput = document.querySelector<HTMLInputElement>('input[name="category"]');
	if (categoryInput) {
		categoryValue = categoryInput.value;
	}

	return categoryValue || readAddressBar(location.search).category;
}

function getPreview(): string {
	const previewParam: string = readAddressBar(location.search).preview || '';
	return previewParam;
}

async function getSuggestions(term: string) {
	const result = await fetchAutocompleteSuggestion(term, msDocs.data.userLocale);
	return result.suggestions;
}

function createNavAutocomplete(name: string, isDesktopWidthForm: boolean) {
	const id = isDesktopWidthForm ? generateElementId() : 'site-search-input';
	const docon = isDesktopWidthForm ? 'search' : null;
	return createAutoComplete(getSearchAutocompleteConfig({ id, name, docon, size: 'small' }));
}

function getSearchAutocompleteConfig({
	id,
	name,
	isFullWidth = false,
	docon = null,
	size = null
}: Partial<AutoCompleteConfig<string>['input']>): AutoCompleteConfig<string> {
	return {
		input: {
			id,
			type: 'search',
			name,
			placeholder: checkIsArchived() ? loc_searchPreviousVersions : loc_search,
			label: loc_search,
			docon: docon ? docon : undefined,
			isFullWidth,
			size: size ? size : undefined
		},
		getSuggestions,
		itemTemplate: suggestion => html`<span class="${suggestionClass}">${suggestion}</span>`
	};
}

function autocompleteChangeHandler(event: CustomEvent<AutoCompleteSuggestionDetail<string>>) {
	instrumentAutocompleteSelection(event.detail);
}

function autocompleteDisplayedHandler(event: CustomEvent<string[]>) {
	instrumentAutocompleteList(event.detail);
}

import { msDocs } from './globals';
import { getMeta } from './meta';
import { readAddressBar } from './apis/site-search';

export function getScope(): string {
	let rawScope = msDocs.data.context.searchScope || getMeta('scope');

	const isSearchPage = msDocs.data.pageTemplate === 'SearchPage';
	if (isSearchPage) {
		rawScope = readAddressBar(location.search).scope;
	}

	const hideScope = getMeta('hideScope');
	if (hideScope === 'true' || !rawScope) {
		return '';
	}

	const scopes = rawScope
		.split(',')
		.map(s => s.trim())
		.filter(s => s.length);
	if (!scopes.length) {
		return '';
	}

	return scopes[scopes.length - 1];
}

import { html, TemplateResult } from '../../../lit-html';
import { gridItemsPerPage, listItemsPerPage } from '../constants';
import { SearchViewModel, SearchVMConfig } from '../view-model';
import { noResultsTemplate } from './no-results';

const createLoadingGridItems = () => {
	const items = [];

	for (let i = 0; i < gridItemsPerPage; i++) {
		items.push(html` <li class="grid-item" aria-hidden="true">
			<article class="card has-loading-skeleton">
				<div class="card-content">
					<h3></h3>
					<p></p>
					<p></p>
					<ul class="tags">
						<li class="tag is-small"></li>
						<li class="tag is-small"></li>
						<li class="tag is-small"></li>
					</ul>
				</div>
			</article>
		</li>`);
	}
	return items;
};

const createLoadingListItems = () => {
	const items = [];

	for (let i = 0; i < listItemsPerPage; i++) {
		items.push(html` <li class="has-margin-top-medium has-loading-skeleton" aria-hidden="true">
			<h2></h2>
			<p></p>
		</li>`);
	}
	return items;
};

const gridContainer = (layout: 'grid' | 'list', items: TemplateResult[]) => html` <ul
	class="${layout === 'grid' ? 'grid ' : ''}has-margin-top-large"
>
	${items}
</ul>`;

export function facetSearchResultsTemplate<TResult>(
	vm: SearchViewModel<TResult>,
	layout: SearchVMConfig<TResult>['layout'],
	resultTemplate: SearchVMConfig<TResult>['resultTemplate']
): TemplateResult {
	if ((!vm.results || vm.results.length === 0) && !vm.busy) {
		return noResultsTemplate(vm);
	}

	if (layout === 'grid') {
		const items = vm.busy
			? createLoadingGridItems()
			: vm.results.map(
					(result, i) => html` <li class="grid-item" data-bi-name="result">
						${resultTemplate(result, i)}
					</li>`
			  );

		return gridContainer(layout, items);
	} else if (layout === 'list') {
		const items = vm.busy
			? createLoadingListItems()
			: vm.results.map(
					(result, i) => html` <li data-bi-name="result" class="has-margin-top-medium">
						${resultTemplate(result, i)}
					</li>`
			  );

		return gridContainer(layout, items);
	} else {
		throw new Error('Invalid layout property specified in View Model config.');
	}
}

import {
	loc_archiveDocs,
	loc_helpMeChoose,
	loc_product,
	loc_selectedVersion,
	loc_version
} from '@msdocs/strings';
import { bigScreenQuery } from '../../src/match-media';
import { platformConfig } from '../api-browser/platform-config';
import { contentAttrs } from '../bi';
import { document, window } from '../globals';
import { generateElementId } from '../html';
import { keyCodes } from '../key-codes';
import { getMeta } from '../meta';
import { parseQueryString } from '../query-string';
import { breakText, escape, WordBreakFlags } from '../text-formatting';
import { findPackageInFamily, getFamily } from './family';
import { getMoniker, monikerChangedEvent, setMoniker } from './moniker';
import { getPlatform, platformId } from './platform';

let singleProduct = false;

export function createMonikerPicker(allApis: boolean): HTMLElement {
	const { element, button, buttonCaption, productList } = initialRender();

	function checkEventTarget(event: Event) {
		if (event.target instanceof Element && !element.contains(event.target)) {
			collapse();
		}
	}

	function collapse() {
		document.documentElement.classList.remove('moniker-picker-expanded');
		element.classList.remove('expanded');
		button.setAttribute('aria-expanded', 'false');
		window.removeEventListener('focus', checkEventTarget, { capture: true } as any);
		window.removeEventListener('click', checkEventTarget);
		bigScreenQuery.removeListener(collapse);
	}

	function collapseAndFocusButton() {
		collapse();
		button.focus();
	}

	function expand() {
		document.body.removeAttribute('style'); // universal header adds a style attribute to the body.
		document.documentElement.classList.add('moniker-picker-expanded');
		element.classList.add('expanded');
		button.setAttribute('aria-expanded', 'true');
		window.addEventListener('focus', checkEventTarget, { capture: true } as any);
		window.addEventListener('click', checkEventTarget);
		bigScreenQuery.addListener(collapse);
	}

	finishRenderingAsync(allApis, button, buttonCaption, productList);

	handleMainMenuButtonInteraction(button, productList, expand, collapseAndFocusButton);
	handleKeyboardInteractionInMenu(productList, collapseAndFocusButton);
	handleMenuItemClick(productList, collapseAndFocusButton);

	return element;
}

function initialRender() {
	// render as much as we can while waiting for the platform/family to load.
	const element = document.createElement('div');
	element.classList.add('moniker-picker');
	element.setAttribute(contentAttrs.name, 'moniker-picker');
	const buttonId = generateElementId();
	const menuId = generateElementId();
	element.innerHTML = `
		<button class="products"
				id="${buttonId}"
				aria-controls="${menuId}"
				aria-expanded="false">
			<span class="visually-hidden">${escape(
				platformId ? platformConfig[platformId].selectLabel : loc_selectedVersion
			)}</span>
			<span></span>
		</button>
		<div	class="products"
				id="${menuId}"
				role="menu"
				aria-labelledby="${buttonId}" style="z-index: 5000">
			<span aria-hidden="true">${escape(loc_product)}</span>
			<ul aria-label="${escape(loc_product)}"></ul>
		</div>`;

	return {
		element,
		button: element.firstElementChild as HTMLButtonElement,
		buttonCaption: element.firstElementChild.lastElementChild as HTMLSpanElement,
		productList: element.lastElementChild.lastElementChild as HTMLUListElement
	};
}

function finishRenderingAsync(
	allApis: boolean,
	button: HTMLButtonElement,
	buttonCaption: HTMLSpanElement,
	productList: HTMLUListElement
) {
	if (platformId === 'rest') {
		singleProduct = true;
	}
	if (allApis) {
		return getPlatform().then(platform => {
			if (platform.packagesByMoniker[getMoniker()] === undefined) {
				setMoniker('');
			}

			const updateCaption = () => {
				const moniker = getMoniker();
				if (moniker === '') {
					buttonCaption.textContent = platformConfig[platformId].allApisLabel;
				} else {
					buttonCaption.innerHTML = breakText(platform.packagesByMoniker[moniker].displayName);
				}
			};
			window.addEventListener(monikerChangedEvent, updateCaption);
			updateCaption();

			renderAllApis(productList);
			for (const family of platform.families) {
				renderProducts(family.products, productList);
			}
		});
	}

	return getFamily().then(family => {
		singleProduct = family.products.length === 1;

		if (singleProduct && family.products[0].packages.length === 1) {
			button.disabled = true;
		}

		const updateCaption = () => {
			const moniker = getMoniker();
			buttonCaption.innerHTML = breakText(findPackageInFamily(family, moniker).displayName);
		};
		window.addEventListener(monikerChangedEvent, updateCaption);
		updateCaption();

		renderProducts(family.products, productList);
	});
}

function renderAllApis(productList: HTMLUListElement) {
	const displayName = platformConfig[platformId].allApisLabel;
	productList.insertAdjacentHTML(
		'afterbegin',
		`<li><a class="preserve-view has-inner-focus" role="menuitem" href="?view=" tabindex="-1">${displayName}</a></li>`
	);
}

function renderProducts(products: Api.Product[], productList: HTMLUListElement) {
	if (singleProduct) {
		productList.previousElementSibling.textContent =
			platformId === 'rest' ? loc_product : loc_version;
		renderPackages(products[0].packages, productList);
		return;
	}

	for (const { displayName, packages } of products) {
		const buttonId = generateElementId();
		const menuId = generateElementId();
		productList.insertAdjacentHTML(
			'beforeend',
			`<li>
				<button class="versions has-inner-focus"
						id="${buttonId}"
						role="menuitem"
						aria-controls="${menuId}"
						aria-expanded="false"
						tabindex="-1">
					${breakText(displayName, WordBreakFlags.Dot)}
				</button>
				<div	class="versions"
						id="${menuId}"
						role="menu"
						aria-labelledby="${buttonId}">
					<span aria-hidden="true">${escape(loc_version)}</span>
					<ul aria-label="${escape(loc_version)}"></ul>
				</div>
			</li>`
		);
		const versionsMenu = productList.lastElementChild.lastElementChild;
		const packageList = versionsMenu.lastElementChild as HTMLUListElement;
		renderPackages(packages, packageList);

		// container for help-me-choose and archive links
		versionsMenu.insertAdjacentHTML('beforeend', `<p class="moniker-auxillary-links"></p>`);
		const monikerLinks = versionsMenu.lastElementChild;

		if (packages.find(pkg => /^azurermps/.test(pkg.moniker))) {
			monikerLinks.insertAdjacentHTML(
				'beforeend',
				`<a href="https://aka.ms/pshelpmechoose">${escape(loc_helpMeChoose)}</a>`
			);
		}
		// render archive link if the archive url exists in metadata
		const archiveUrl = getMeta('archive_url');
		if (archiveUrl) {
			monikerLinks.insertAdjacentHTML(
				'beforeend',
				`<a href="${archiveUrl}">${escape(loc_archiveDocs)}</a>`
			);
		}
	}
}

function renderPackages(packages: Api.Package[], packageList: HTMLUListElement) {
	for (const { moniker, isDefault, versionDisplayName, displayName } of packages) {
		packageList.insertAdjacentHTML(
			'beforeend',
			`<li><a class="preserve-view has-inner-focus" role="menuitem" href="?view=${moniker}" aria-label="${displayName}" data-default="${isDefault}" tabindex="-1">${escape(
				versionDisplayName
			)}</a></li>`
		);
	}
}

function expandProduct(productList: HTMLUListElement, productButton: HTMLButtonElement | null) {
	const current = productList.querySelector('button[aria-expanded="true"]');
	if (current === productButton) {
		return;
	}
	if (current !== null) {
		current.setAttribute('aria-expanded', 'false');
	}
	if (productButton !== null) {
		productButton.setAttribute('aria-expanded', 'true');
	}
}

function findAnchorByMoniker(container: Element, moniker: string) {
	return container.querySelector(`a[href="?view=${moniker}"]`) as HTMLAnchorElement;
}

/**
 * Finds the "default" moniker anchor corresponding to a product button.
 * @param productButton
 */
function findAnchorToSelect(productButton: HTMLButtonElement) {
	const versionsMenu = productButton.nextElementSibling;
	const current = findAnchorByMoniker(versionsMenu, getMoniker());
	const productDefault = versionsMenu.querySelector(
		'a[href^="?view="][data-default="true"]'
	) as HTMLAnchorElement;
	const first = versionsMenu.querySelector(`a[href^="?view="]`) as HTMLAnchorElement;
	return current || productDefault || first;
}

/**
 * Finds the product button corresponding to a moniker anchor.
 * @param monikerAnchor
 */
function getProductButton(monikerAnchor: HTMLAnchorElement): HTMLButtonElement | null {
	if (monikerAnchor.search === '?view=' || singleProduct) {
		return null;
	}
	return monikerAnchor.parentElement.parentElement.parentElement
		.previousElementSibling as HTMLButtonElement;
}

function handleMainMenuButtonInteraction(
	button: HTMLButtonElement,
	productList: HTMLUListElement,
	expand: () => void,
	collapse: () => void
) {
	const expandAndSelectCurrent = () => {
		expand();

		const moniker = getMoniker();
		const anchor = findAnchorByMoniker(productList, moniker);
		const productButton = getProductButton(anchor);
		expandProduct(productList, productButton);
		setTimeout(() => {
			if (productButton !== null) {
				productButton.scrollIntoView(false);
			}
			anchor.scrollIntoView(false);
			anchor.focus();
		});
	};

	button.addEventListener('click', () => {
		const expand = button.getAttribute('aria-expanded') === 'false';
		if (expand) {
			expandAndSelectCurrent();
		} else {
			collapse();
		}
	});

	button.addEventListener('keydown', event => {
		const expanded = button.getAttribute('aria-expanded') === 'true';
		if (expanded && event.keyCode === keyCodes.up) {
			event.preventDefault();
			collapse();
		} else if (!expanded && event.keyCode === keyCodes.down) {
			event.preventDefault();
			expandAndSelectCurrent();
		}
	});
}

function handleKeyboardInteractionInMenu(productList: HTMLUListElement, collapse: () => void) {
	productList.addEventListener('keydown', event => {
		if (!bigScreenQuery.matches) {
			return;
		}

		const target = event.target as HTMLElement;
		if (target.getAttribute('role') !== 'menuitem') {
			return;
		}

		const keyCode = event.keyCode;

		let el: HTMLElement;
		switch (keyCode) {
			case keyCodes.left:
				if (target instanceof HTMLAnchorElement && target.search !== '?view=') {
					event.preventDefault();
					getProductButton(target).focus();
				}
				break;
			case keyCodes.right:
				if (target instanceof HTMLButtonElement && target.hasAttribute('aria-controls')) {
					event.preventDefault();
					findAnchorToSelect(target).focus();
				}
				break;
			case keyCodes.up:
			case keyCodes.down:
				event.preventDefault();
				const nextFn = keyCode === keyCodes.up ? 'previousElementSibling' : 'nextElementSibling';
				const firstFn = keyCode === keyCodes.up ? 'lastElementChild' : 'firstElementChild';
				if (target.parentElement[nextFn] === null) {
					el = target.parentElement.parentElement[firstFn].firstElementChild as HTMLElement;
				} else {
					el = target.parentElement[nextFn].firstElementChild as HTMLElement;
				}
				el.focus();
				if (el.parentElement.parentElement === productList) {
					expandProduct(productList, el instanceof HTMLButtonElement ? el : null);
				}
				break;
			case keyCodes.home:
			case keyCodes.end:
				event.preventDefault();
				const fn = keyCode === keyCodes.home ? 'firstElementChild' : 'lastElementChild';
				el = target.parentElement.parentElement[fn].firstElementChild as HTMLElement;
				el.focus();
				if (el.parentElement.parentElement === productList) {
					expandProduct(productList, el instanceof HTMLButtonElement ? el : null);
				}
				break;
			case keyCodes.escape:
				event.preventDefault();
				collapse();
				break;
		}
	});
}

function handleMenuItemClick(productList: HTMLUListElement, collapse: () => void) {
	productList.addEventListener('click', event => {
		const target = event.target as HTMLElement;
		if (target.getAttribute('role') !== 'menuitem') {
			return;
		}

		if (target instanceof HTMLAnchorElement) {
			event.preventDefault();
			const moniker = parseQueryString(target.search).view;
			setMoniker(moniker);
			collapse();
			return;
		}

		if (target instanceof HTMLButtonElement) {
			if (!bigScreenQuery.matches && target.getAttribute('aria-expanded') === 'true') {
				target.setAttribute('aria-expanded', 'false');
			} else {
				expandProduct(productList, target);
			}
			target.focus(); // safari doesn't focus the clicked button.
		}
	});
}

/*
function mediaQueryTest() {
	const mobileMql = window.matchMedia(`
		screen and (max-width: 767px) and (max-height: 1023px),
		screen and (min-resolution: 120dpi) and (max-width: 767.9px) and (max-height: 1023.9px)
	`);
	mobileMql.addListener(log);
	const desktopMql = window.matchMedia(`
		screen and (min-width: 768px), screen and (min-height: 1024px)
	`);
	desktopMql.addListener(log);

	addEventListener('resize', log);

	function log() {
		requestAnimationFrame(() => {
			const exclusive = mobileMql.matches || desktopMql.matches && mobileMql.matches !== desktopMql.matches;
			const message = `mobile: ${mobileMql.matches}; desktop: ${desktopMql.matches}; width: ${window.innerWidth}; height: ${window.innerHeight};`;
			console.clear();
			console.log(exclusive ? message : message.toUpperCase());
		});
	}
}

mediaQueryTest();
*/

import {
	loc_countriesRegions_AD,
	loc_countriesRegions_AE,
	loc_countriesRegions_AF,
	loc_countriesRegions_AG,
	loc_countriesRegions_AI,
	loc_countriesRegions_AL,
	loc_countriesRegions_AM,
	loc_countriesRegions_AO,
	loc_countriesRegions_AQ,
	loc_countriesRegions_AR,
	loc_countriesRegions_AS,
	loc_countriesRegions_AT,
	loc_countriesRegions_AU,
	loc_countriesRegions_AW,
	loc_countriesRegions_AX,
	loc_countriesRegions_AZ,
	loc_countriesRegions_BA,
	loc_countriesRegions_BB,
	loc_countriesRegions_BD,
	loc_countriesRegions_BE,
	loc_countriesRegions_BF,
	loc_countriesRegions_BG,
	loc_countriesRegions_BH,
	loc_countriesRegions_BI,
	loc_countriesRegions_BJ,
	loc_countriesRegions_BL,
	loc_countriesRegions_BM,
	loc_countriesRegions_BN,
	loc_countriesRegions_BO,
	loc_countriesRegions_BQ,
	loc_countriesRegions_BR,
	loc_countriesRegions_BS,
	loc_countriesRegions_BT,
	loc_countriesRegions_BV,
	loc_countriesRegions_BW,
	loc_countriesRegions_BY,
	loc_countriesRegions_BZ,
	loc_countriesRegions_CA,
	loc_countriesRegions_CC,
	loc_countriesRegions_CD,
	loc_countriesRegions_CF,
	loc_countriesRegions_CG,
	loc_countriesRegions_CH,
	loc_countriesRegions_CI,
	loc_countriesRegions_CK,
	loc_countriesRegions_CL,
	loc_countriesRegions_CM,
	loc_countriesRegions_CN,
	loc_countriesRegions_CO,
	loc_countriesRegions_CR,
	loc_countriesRegions_CV,
	loc_countriesRegions_CW,
	loc_countriesRegions_CX,
	loc_countriesRegions_CY,
	loc_countriesRegions_CZ,
	loc_countriesRegions_DE,
	loc_countriesRegions_DJ,
	loc_countriesRegions_DK,
	loc_countriesRegions_DM,
	loc_countriesRegions_DO,
	loc_countriesRegions_DZ,
	loc_countriesRegions_EC,
	loc_countriesRegions_EE,
	loc_countriesRegions_EG,
	loc_countriesRegions_EH,
	loc_countriesRegions_ER,
	loc_countriesRegions_ES,
	loc_countriesRegions_ET,
	loc_countriesRegions_FI,
	loc_countriesRegions_FJ,
	loc_countriesRegions_FK,
	loc_countriesRegions_FM,
	loc_countriesRegions_FO,
	loc_countriesRegions_FR,
	loc_countriesRegions_GA,
	loc_countriesRegions_GB,
	loc_countriesRegions_GD,
	loc_countriesRegions_GE,
	loc_countriesRegions_GF,
	loc_countriesRegions_GG,
	loc_countriesRegions_GH,
	loc_countriesRegions_GI,
	loc_countriesRegions_GL,
	loc_countriesRegions_GM,
	loc_countriesRegions_GN,
	loc_countriesRegions_GP,
	loc_countriesRegions_GQ,
	loc_countriesRegions_GR,
	loc_countriesRegions_GS,
	loc_countriesRegions_GT,
	loc_countriesRegions_GU,
	loc_countriesRegions_GW,
	loc_countriesRegions_GY,
	loc_countriesRegions_HK,
	loc_countriesRegions_HM,
	loc_countriesRegions_HN,
	loc_countriesRegions_HR,
	loc_countriesRegions_HT,
	loc_countriesRegions_HU,
	loc_countriesRegions_ID,
	loc_countriesRegions_IE,
	loc_countriesRegions_IL,
	loc_countriesRegions_IM,
	loc_countriesRegions_IN,
	loc_countriesRegions_IO,
	loc_countriesRegions_IQ,
	loc_countriesRegions_IS,
	loc_countriesRegions_IT,
	loc_countriesRegions_JE,
	loc_countriesRegions_JM,
	loc_countriesRegions_JO,
	loc_countriesRegions_JP,
	loc_countriesRegions_KE,
	loc_countriesRegions_KG,
	loc_countriesRegions_KH,
	loc_countriesRegions_KI,
	loc_countriesRegions_KM,
	loc_countriesRegions_KN,
	loc_countriesRegions_KR,
	loc_countriesRegions_KW,
	loc_countriesRegions_KY,
	loc_countriesRegions_KZ,
	loc_countriesRegions_LA,
	loc_countriesRegions_LB,
	loc_countriesRegions_LC,
	loc_countriesRegions_LI,
	loc_countriesRegions_LK,
	loc_countriesRegions_LR,
	loc_countriesRegions_LS,
	loc_countriesRegions_LT,
	loc_countriesRegions_LU,
	loc_countriesRegions_LV,
	loc_countriesRegions_LY,
	loc_countriesRegions_MA,
	loc_countriesRegions_MC,
	loc_countriesRegions_MD,
	loc_countriesRegions_ME,
	loc_countriesRegions_MF,
	loc_countriesRegions_MG,
	loc_countriesRegions_MH,
	loc_countriesRegions_MK,
	loc_countriesRegions_ML,
	loc_countriesRegions_MM,
	loc_countriesRegions_MN,
	loc_countriesRegions_MO,
	loc_countriesRegions_MP,
	loc_countriesRegions_MQ,
	loc_countriesRegions_MR,
	loc_countriesRegions_MS,
	loc_countriesRegions_MT,
	loc_countriesRegions_MU,
	loc_countriesRegions_MV,
	loc_countriesRegions_MW,
	loc_countriesRegions_MX,
	loc_countriesRegions_MY,
	loc_countriesRegions_MZ,
	loc_countriesRegions_NA,
	loc_countriesRegions_NC,
	loc_countriesRegions_NE,
	loc_countriesRegions_NF,
	loc_countriesRegions_NG,
	loc_countriesRegions_NI,
	loc_countriesRegions_NL,
	loc_countriesRegions_NO,
	loc_countriesRegions_NP,
	loc_countriesRegions_NR,
	loc_countriesRegions_NU,
	loc_countriesRegions_NZ,
	loc_countriesRegions_OM,
	loc_countriesRegions_PA,
	loc_countriesRegions_PE,
	loc_countriesRegions_PF,
	loc_countriesRegions_PG,
	loc_countriesRegions_PH,
	loc_countriesRegions_PK,
	loc_countriesRegions_PL,
	loc_countriesRegions_PM,
	loc_countriesRegions_PN,
	loc_countriesRegions_PR,
	loc_countriesRegions_PS,
	loc_countriesRegions_PT,
	loc_countriesRegions_PW,
	loc_countriesRegions_PY,
	loc_countriesRegions_QA,
	loc_countriesRegions_RE,
	loc_countriesRegions_RO,
	loc_countriesRegions_RS,
	loc_countriesRegions_RU,
	loc_countriesRegions_RW,
	loc_countriesRegions_SA,
	loc_countriesRegions_SB,
	loc_countriesRegions_SC,
	loc_countriesRegions_SE,
	loc_countriesRegions_SG,
	loc_countriesRegions_SH,
	loc_countriesRegions_SI,
	loc_countriesRegions_SJ,
	loc_countriesRegions_SK,
	loc_countriesRegions_SL,
	loc_countriesRegions_SM,
	loc_countriesRegions_SN,
	loc_countriesRegions_SO,
	loc_countriesRegions_SR,
	loc_countriesRegions_SS,
	loc_countriesRegions_ST,
	loc_countriesRegions_SV,
	loc_countriesRegions_SX,
	loc_countriesRegions_SZ,
	loc_countriesRegions_TC,
	loc_countriesRegions_TD,
	loc_countriesRegions_TF,
	loc_countriesRegions_TG,
	loc_countriesRegions_TH,
	loc_countriesRegions_TJ,
	loc_countriesRegions_TK,
	loc_countriesRegions_TL,
	loc_countriesRegions_TM,
	loc_countriesRegions_TN,
	loc_countriesRegions_TO,
	loc_countriesRegions_TR,
	loc_countriesRegions_TT,
	loc_countriesRegions_TV,
	loc_countriesRegions_TW,
	loc_countriesRegions_TZ,
	loc_countriesRegions_UA,
	loc_countriesRegions_UG,
	loc_countriesRegions_UM,
	loc_countriesRegions_US,
	loc_countriesRegions_UY,
	loc_countriesRegions_UZ,
	loc_countriesRegions_VA,
	loc_countriesRegions_VC,
	loc_countriesRegions_VE,
	loc_countriesRegions_VG,
	loc_countriesRegions_VI,
	loc_countriesRegions_VN,
	loc_countriesRegions_VU,
	loc_countriesRegions_WF,
	loc_countriesRegions_WS,
	loc_countriesRegions_YE,
	loc_countriesRegions_YT,
	loc_countriesRegions_ZA,
	loc_countriesRegions_ZM,
	loc_countriesRegions_ZW
} from '@msdocs/strings';

export const countryMap = {
	AF: loc_countriesRegions_AF,
	AX: loc_countriesRegions_AX,
	AL: loc_countriesRegions_AL,
	DZ: loc_countriesRegions_DZ,
	AS: loc_countriesRegions_AS,
	AD: loc_countriesRegions_AD,
	AO: loc_countriesRegions_AO,
	AI: loc_countriesRegions_AI,
	AQ: loc_countriesRegions_AQ,
	AG: loc_countriesRegions_AG,
	AR: loc_countriesRegions_AR,
	AM: loc_countriesRegions_AM,
	AW: loc_countriesRegions_AW,
	AU: loc_countriesRegions_AU,
	AT: loc_countriesRegions_AT,
	AZ: loc_countriesRegions_AZ,
	BS: loc_countriesRegions_BS,
	BH: loc_countriesRegions_BH,
	BD: loc_countriesRegions_BD,
	BB: loc_countriesRegions_BB,
	BY: loc_countriesRegions_BY,
	BE: loc_countriesRegions_BE,
	BZ: loc_countriesRegions_BZ,
	BJ: loc_countriesRegions_BJ,
	BM: loc_countriesRegions_BM,
	BT: loc_countriesRegions_BT,
	BO: loc_countriesRegions_BO,
	BQ: loc_countriesRegions_BQ,
	BA: loc_countriesRegions_BA,
	BW: loc_countriesRegions_BW,
	BV: loc_countriesRegions_BV,
	BR: loc_countriesRegions_BR,
	IO: loc_countriesRegions_IO,
	BN: loc_countriesRegions_BN,
	BG: loc_countriesRegions_BG,
	BF: loc_countriesRegions_BF,
	BI: loc_countriesRegions_BI,
	KH: loc_countriesRegions_KH,
	CM: loc_countriesRegions_CM,
	CA: loc_countriesRegions_CA,
	CV: loc_countriesRegions_CV,
	KY: loc_countriesRegions_KY,
	CF: loc_countriesRegions_CF,
	TD: loc_countriesRegions_TD,
	CL: loc_countriesRegions_CL,
	CN: loc_countriesRegions_CN,
	CX: loc_countriesRegions_CX,
	CC: loc_countriesRegions_CC,
	CO: loc_countriesRegions_CO,
	KM: loc_countriesRegions_KM,
	CG: loc_countriesRegions_CG,
	CD: loc_countriesRegions_CD,
	CK: loc_countriesRegions_CK,
	CR: loc_countriesRegions_CR,
	CI: loc_countriesRegions_CI,
	HR: loc_countriesRegions_HR,
	CW: loc_countriesRegions_CW,
	CY: loc_countriesRegions_CY,
	CZ: loc_countriesRegions_CZ,
	DK: loc_countriesRegions_DK,
	DJ: loc_countriesRegions_DJ,
	DM: loc_countriesRegions_DM,
	DO: loc_countriesRegions_DO,
	EC: loc_countriesRegions_EC,
	EG: loc_countriesRegions_EG,
	SV: loc_countriesRegions_SV,
	GQ: loc_countriesRegions_GQ,
	ER: loc_countriesRegions_ER,
	EE: loc_countriesRegions_EE,
	ET: loc_countriesRegions_ET,
	FK: loc_countriesRegions_FK,
	FO: loc_countriesRegions_FO,
	FJ: loc_countriesRegions_FJ,
	FI: loc_countriesRegions_FI,
	FR: loc_countriesRegions_FR,
	GF: loc_countriesRegions_GF,
	PF: loc_countriesRegions_PF,
	GA: loc_countriesRegions_GA,
	GM: loc_countriesRegions_GM,
	GE: loc_countriesRegions_GE,
	DE: loc_countriesRegions_DE,
	GH: loc_countriesRegions_GH,
	GI: loc_countriesRegions_GI,
	GR: loc_countriesRegions_GR,
	GL: loc_countriesRegions_GL,
	GD: loc_countriesRegions_GD,
	GP: loc_countriesRegions_GP,
	GU: loc_countriesRegions_GU,
	GT: loc_countriesRegions_GT,
	GG: loc_countriesRegions_GG,
	GN: loc_countriesRegions_GN,
	GW: loc_countriesRegions_GW,
	GY: loc_countriesRegions_GY,
	HT: loc_countriesRegions_HT,
	HM: loc_countriesRegions_HM,
	HN: loc_countriesRegions_HN,
	HK: loc_countriesRegions_HK,
	HU: loc_countriesRegions_HU,
	IS: loc_countriesRegions_IS,
	IN: loc_countriesRegions_IN,
	ID: loc_countriesRegions_ID,
	IQ: loc_countriesRegions_IQ,
	IE: loc_countriesRegions_IE,
	IM: loc_countriesRegions_IM,
	IL: loc_countriesRegions_IL,
	IT: loc_countriesRegions_IT,
	JM: loc_countriesRegions_JM,
	JP: loc_countriesRegions_JP,
	JE: loc_countriesRegions_JE,
	JO: loc_countriesRegions_JO,
	KZ: loc_countriesRegions_KZ,
	KE: loc_countriesRegions_KE,
	KI: loc_countriesRegions_KI,
	KW: loc_countriesRegions_KW,
	KG: loc_countriesRegions_KG,
	LA: loc_countriesRegions_LA,
	LV: loc_countriesRegions_LV,
	LB: loc_countriesRegions_LB,
	LS: loc_countriesRegions_LS,
	LR: loc_countriesRegions_LR,
	LY: loc_countriesRegions_LY,
	LI: loc_countriesRegions_LI,
	LT: loc_countriesRegions_LT,
	LU: loc_countriesRegions_LU,
	MO: loc_countriesRegions_MO,
	MK: loc_countriesRegions_MK,
	MG: loc_countriesRegions_MG,
	MW: loc_countriesRegions_MW,
	MY: loc_countriesRegions_MY,
	MV: loc_countriesRegions_MV,
	ML: loc_countriesRegions_ML,
	MT: loc_countriesRegions_MT,
	MH: loc_countriesRegions_MH,
	MQ: loc_countriesRegions_MQ,
	MR: loc_countriesRegions_MR,
	MU: loc_countriesRegions_MU,
	YT: loc_countriesRegions_YT,
	MX: loc_countriesRegions_MX,
	FM: loc_countriesRegions_FM,
	MD: loc_countriesRegions_MD,
	MC: loc_countriesRegions_MC,
	MN: loc_countriesRegions_MN,
	ME: loc_countriesRegions_ME,
	MS: loc_countriesRegions_MS,
	MA: loc_countriesRegions_MA,
	MZ: loc_countriesRegions_MZ,
	MM: loc_countriesRegions_MM,
	NA: loc_countriesRegions_NA,
	NR: loc_countriesRegions_NR,
	NP: loc_countriesRegions_NP,
	NL: loc_countriesRegions_NL,
	NC: loc_countriesRegions_NC,
	NZ: loc_countriesRegions_NZ,
	NI: loc_countriesRegions_NI,
	NE: loc_countriesRegions_NE,
	NG: loc_countriesRegions_NG,
	NU: loc_countriesRegions_NU,
	NF: loc_countriesRegions_NF,
	MP: loc_countriesRegions_MP,
	NO: loc_countriesRegions_NO,
	OM: loc_countriesRegions_OM,
	PK: loc_countriesRegions_PK,
	PW: loc_countriesRegions_PW,
	PS: loc_countriesRegions_PS,
	PA: loc_countriesRegions_PA,
	PG: loc_countriesRegions_PG,
	PY: loc_countriesRegions_PY,
	PE: loc_countriesRegions_PE,
	PH: loc_countriesRegions_PH,
	PN: loc_countriesRegions_PN,
	PL: loc_countriesRegions_PL,
	PT: loc_countriesRegions_PT,
	PR: loc_countriesRegions_PR,
	QA: loc_countriesRegions_QA,
	RE: loc_countriesRegions_RE,
	RO: loc_countriesRegions_RO,
	RU: loc_countriesRegions_RU,
	RW: loc_countriesRegions_RW,
	BL: loc_countriesRegions_BL,
	KN: loc_countriesRegions_KN,
	LC: loc_countriesRegions_LC,
	MF: loc_countriesRegions_MF,
	WS: loc_countriesRegions_WS,
	SM: loc_countriesRegions_SM,
	ST: loc_countriesRegions_ST,
	VC: loc_countriesRegions_VC,
	SA: loc_countriesRegions_SA,
	SN: loc_countriesRegions_SN,
	RS: loc_countriesRegions_RS,
	SC: loc_countriesRegions_SC,
	SL: loc_countriesRegions_SL,
	SG: loc_countriesRegions_SG,
	SX: loc_countriesRegions_SX,
	SK: loc_countriesRegions_SK,
	SI: loc_countriesRegions_SI,
	SB: loc_countriesRegions_SB,
	SO: loc_countriesRegions_SO,
	ZA: loc_countriesRegions_ZA,
	GS: loc_countriesRegions_GS,
	SS: loc_countriesRegions_SS,
	KR: loc_countriesRegions_KR,
	ES: loc_countriesRegions_ES,
	LK: loc_countriesRegions_LK,
	SH: loc_countriesRegions_SH,
	PM: loc_countriesRegions_PM,
	SR: loc_countriesRegions_SR,
	SJ: loc_countriesRegions_SJ,
	SZ: loc_countriesRegions_SZ,
	SE: loc_countriesRegions_SE,
	CH: loc_countriesRegions_CH,
	TW: loc_countriesRegions_TW,
	TJ: loc_countriesRegions_TJ,
	TZ: loc_countriesRegions_TZ,
	TH: loc_countriesRegions_TH,
	TL: loc_countriesRegions_TL,
	TG: loc_countriesRegions_TG,
	TK: loc_countriesRegions_TK,
	TO: loc_countriesRegions_TO,
	TT: loc_countriesRegions_TT,
	TN: loc_countriesRegions_TN,
	TR: loc_countriesRegions_TR,
	TM: loc_countriesRegions_TM,
	TC: loc_countriesRegions_TC,
	TV: loc_countriesRegions_TV,
	UG: loc_countriesRegions_UG,
	UA: loc_countriesRegions_UA,
	AE: loc_countriesRegions_AE,
	GB: loc_countriesRegions_GB,
	US: loc_countriesRegions_US,
	UY: loc_countriesRegions_UY,
	UM: loc_countriesRegions_UM,
	UZ: loc_countriesRegions_UZ,
	VU: loc_countriesRegions_VU,
	VA: loc_countriesRegions_VA,
	VE: loc_countriesRegions_VE,
	VN: loc_countriesRegions_VN,
	VG: loc_countriesRegions_VG,
	VI: loc_countriesRegions_VI,
	WF: loc_countriesRegions_WF,
	EH: loc_countriesRegions_EH,
	YE: loc_countriesRegions_YE,
	ZM: loc_countriesRegions_ZM,
	ZW: loc_countriesRegions_ZW,
	TF: loc_countriesRegions_TF
};

export type CountryId = keyof typeof countryMap;

import { msDocs } from './globals';
import { createHtmlId } from './html';
import { getVisibleTextContent } from './text-formatting';

export const headingAnchorAllowList = {
	Conceptual: true,
	ContentPage: true,
	Hub: true,
	LandingPage: true,
	Reference: true,
	Rest: true
};

export function handleHeadings(container: HTMLElement): void {
	if (!(msDocs.data.pageTemplate in headingAnchorAllowList)) {
		return;
	}

	const allowParents = [
		'.content',
		'.content section',
		'.content [data-moniker]',
		'.anchor-headings'
	];
	const allowHeadings = ['h2', 'h3', 'h4', 'h5', 'h6'];
	const headings = Array.from(
		container.querySelectorAll(`${allowHeadings.join(',')}`)
	) as HTMLHeadingElement[];

	headings.forEach(heading => {
		if (heading.parentElement.matches(`${allowParents.join(',')}`)) {
			const id = heading.id || createHtmlId(getVisibleTextContent(heading).trim());
			if (!heading.id) {
				heading.id = id;
			}
			heading.classList.add('heading-anchor');

			const headingAnchor = document.createElement('a');

			headingAnchor.classList.add('anchor-link', 'docon', 'docon-link');
			headingAnchor.href = '#' + id;
			headingAnchor.setAttribute('aria-labelledby', id);

			headingAnchor.addEventListener('focus', event => {
				(event.target as HTMLElement).parentElement.setAttribute('role', 'none');
			});
			headingAnchor.addEventListener('blur', event => {
				(event.target as HTMLElement).parentElement.removeAttribute('role');
			});

			heading.insertAdjacentElement('afterbegin', headingAnchor);
		}
	});
}

import { document } from '../../globals';

import type { ImageBrowser } from './image-browser';

let mousemoveListener: (event: MouseEvent) => void = null;
const state: any = {};

export function attachEvents(imageBrowser: ImageBrowser, imageElement: Element) {
	mousemoveListener =
		mousemoveListener ||
		((event: MouseEvent) => {
			if (imageBrowser.state.expanded) {
				if (event.buttons % 2) {
					event.preventDefault();
					state.dragging = true;

					const movementX =
						event.movementX || !isNaN(state.prevX) ? event.screenX - state.prevX : 0;
					const movementY =
						event.movementY || !isNaN(state.prevY) ? event.screenY - state.prevY : 0;

					imageBrowser.panViewport(movementX, movementY);
				} else {
					if (state.dragging) {
						setTimeout(() => {
							state.dragging = false;
						}, 0);
					}
				}
			} else {
				state.dragging = false;
			}

			state.prevX = event.screenX;
			state.prevY = event.screenY;
		});

	document.removeEventListener('mousemove', mousemoveListener);
	document.addEventListener('mousemove', mousemoveListener);

	imageElement.addEventListener('dragstart', (event: DragEvent) => {
		event.preventDefault();
	});

	imageElement.addEventListener('click', (event: MouseEvent) => {
		if (!state.dragging) {
			event.preventDefault();
			imageBrowser.toggleExpand(event.offsetX * -1, event.offsetY * -1, true);
		}
	});
}

import { contentLoaded, window } from '../../globals';
import { render } from '../../lit-html';
import { getMeta } from '../../meta';
import { parseQueryString, updateQueryString } from '../../query-string';
import { createQnaSearchForm } from './qna-form';

export async function hubPage(container: HTMLElement) {
	// check if page is Q&A Hub
	const isQnaHub = getMeta('pageModifier') === 'qna-home';
	if (isQnaHub) {
		// initialize autocomplete
		render(createQnaSearchForm(), document.querySelector('.autocomplete-qna-hero'));
	}

	const facetList = container.querySelector('#hub-facet-list');
	if (!facetList) {
		return;
	} // bail if structure is not met, we have build validation to ensure structure is only in azure and qna repos

	await contentLoaded;

	const facetButtons = Array.from(facetList.querySelectorAll('.hub-facet')) as HTMLButtonElement[];
	const allButtons = Array.from(
		facetList.querySelectorAll('button:not(.dropdown-trigger)')
	) as HTMLButtonElement[];

	// filter initial view and show section
	selectProductFromQueryString(isQnaHub, allButtons);
	facetList.removeAttribute('hidden');

	facetButtons.forEach(button => {
		button.onclick = () => {
			filterProductCards(button, allButtons);
		};
	});

	if (!isQnaHub) {
		const allButton = container.querySelector('.all-facet') as HTMLButtonElement;
		allButton.onclick = () => {
			showAllCards(allButton, allButtons);
		};
	}

	// filter on horizontal resize.
	const handleResize = () => {
		if (window.innerWidth >= 768) {
			return;
		}
		window.removeEventListener('resize', handleResize);
		filterProductCards(facetButtons[0], allButtons);
	};
	window.addEventListener('resize', handleResize);
}

function selectProductFromQueryString(isQnaHub: boolean, allButtons: HTMLButtonElement[]) {
	// check for product in query string
	const { product } = parseQueryString();
	const allButton = document.querySelector(`button[data-facet='all']`) as HTMLButtonElement;
	const productButton = document.querySelector(
		`button[data-facet='${product}']`
	) as HTMLButtonElement;
	const featuredButton = document.querySelector('.featured-facet') as HTMLButtonElement;

	if (!product || !productButton) {
		// if no or bad product queryString value

		if (isQnaHub) {
			filterProductCards(allButton, allButtons);
		} else if (featuredButton) {
			filterProductCards(featuredButton, allButtons);
		} else {
			showAllCards(allButton, allButtons);
		}

		return;
	}

	if (product === 'all') {
		// if product queryString is all

		if (isQnaHub) {
			filterProductCards(allButton, allButtons);
		} else {
			showAllCards(allButton, allButtons);
		}

		return;
	}

	// filter by qualified product queryString value
	filterProductCards(productButton, allButtons);
}

function updateProductQueryString(buttonText: string) {
	const qs = parseQueryString();
	qs.product = buttonText.toLowerCase();
	updateQueryString(qs, 'replaceState');
}

function showAllCards(button: HTMLButtonElement, buttons: HTMLButtonElement[]) {
	toggleAll(true);
	toggleControls(button, buttons);
	updateProductQueryString(button.getAttribute('data-facet'));
}

function filterProductCards(button: HTMLButtonElement, buttons: HTMLButtonElement[]) {
	filterByCategory(button.innerText);
	toggleControls(button, buttons);
	updateProductQueryString(button.getAttribute('data-facet'));
}

export function filterByCategory(item: string) {
	const sectionTitle = document.querySelector('#section-title');
	sectionTitle.removeAttribute('hidden');
	toggleAll(false);

	Array.from(document.querySelectorAll('#product-directory .item-column')).forEach(element => {
		if (element.getAttribute('data-categories').indexOf(item) < 0) {
			element.setAttribute('hidden', 'hidden');
		} else {
			element.removeAttribute('hidden');
			sectionTitle.querySelector('h3').innerText = item;
			document.querySelector<HTMLSpanElement>('#category-hub-dropdown').innerText = item;
		}
	});
}

export function toggleAll(showAll: boolean) {
	const productCards = document.querySelector('#product-cards');
	const productAll = document.querySelector('#product-cards-all');
	(showAll ? productCards : productAll).setAttribute('hidden', 'hidden');
	(showAll ? productAll : productCards).removeAttribute('hidden');
}

export function toggleControls(button: HTMLButtonElement, buttons: HTMLButtonElement[]) {
	buttons.forEach(individualButton => {
		if (individualButton.innerText === button.innerText) {
			individualButton.setAttribute('aria-pressed', 'true');
			individualButton.classList.add('has-background-secondary-light');
		} else {
			individualButton.setAttribute('aria-pressed', 'false');
			individualButton.classList.remove('has-background-secondary-light');
		}
	});
}

export function createRatingLink(onRateThisModule: () => void, text: string): HTMLElement {
	const container = document.createElement('div');
	container.classList.add('has-padding-none');

	const ratingLinkHtml = `
		<div>
			<button class="button is-text is-link is-small rate-this-module authenticated" data-bi-name="rate-this-module">${text}</button>
		</div>`;

	container.innerHTML = ratingLinkHtml;

	const anchor = container.querySelector<HTMLAnchorElement>('.rate-this-module');
	anchor.onclick = onRateThisModule;

	return container;
}

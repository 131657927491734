import { renderLearnBreadcrumbs } from '../learn/breadcrumbs';
import { contentLoaded } from '../globals';
import { WindowMessenger } from '../window-messenger';
import { jsllReady } from '../bi';
import { render, html } from 'lit-html';
import { loc_videoScheduleWatchNow, loc_videoScheduleLive } from '@msdocs/strings';
import { shortTimeFormat } from '../time-format';
import { getLearnTVSchedule, LearnTVShow } from '../apis/learn-tv';
import { parseQueryString } from '../query-string';
import { isProduction } from '../is-production';

export function initLearnTV() {
	renderLearnBreadcrumbs('Learn TV');
	contentLoaded.then(initStudioPlayers).then(initSchedule);
}

function initStudioPlayers() {
	const studioPlayers = Array.from(
		document.querySelectorAll('.studio-player')
	) as HTMLIFrameElement[];

	studioPlayers.forEach(studioPlayer => {
		const src = studioPlayer.src;
		if (!src) {
			return;
		}
		const url = new URL(src);

		let playerHeight: number = -1;

		new WindowMessenger(studioPlayer, url.origin).subscribe<any>(async data => {
			if (data && data.type === 'jsll') {
				if (!data.data) {
					return;
				}
				const payload = data.data;
				if (payload.actionType && payload.behavior) {
					const actionType = payload.actionType;
					delete payload.actionType;
					const behavior = payload.behavior;
					delete payload.behavior;

					const awa = await jsllReady;
					awa.ct.captureContentPageAction({
						actionType,
						behavior,
						contentTags: payload
					});
				}
			} else {
				let newPlayerHeight: number;
				if (data && data.type === 'height') {
					newPlayerHeight = data.data;
				} else {
					newPlayerHeight = data;
				}

				if (
					newPlayerHeight &&
					typeof newPlayerHeight === 'number' &&
					playerHeight !== newPlayerHeight
				) {
					studioPlayer.style.height = newPlayerHeight + 'px';
					if (playerHeight === -1) {
						studioPlayer.setAttribute('scrolling', 'no');
						studioPlayer.parentElement.classList.add('height-from-message');
					}
					playerHeight = newPlayerHeight;
				}
			}
		});
	});
}

const trimSchedule = (schedule: LearnTVShow[], lowerLimit: number, upperLimit: number) => {
	/*
		trims a schedule to only shows that start between the upper and lower limit,
		and have a valid start and end time
	*/
	let i = 0;
	while (i < schedule.length) {
		const show = schedule[i];

		const startTime_time = show.start_time.getTime();
		if (
			!isNaN(show.end_time.getTime()) &&
			startTime_time >= lowerLimit &&
			startTime_time <= upperLimit
		) {
			++i;
		} else {
			schedule.splice(i, 1);
		}
	}
};

const getActiveShowIndex = (schedule: LearnTVShow[], now: number) => {
	/*
		return the index of the first show that has not ended as the currently active show.
		Shows must be in order for this to be successful.
	*/
	return schedule.findIndex(show => now < show.end_time.getTime());
};

const hideSchedule = () => {
	const videoSection = document.getElementById('video-section') as HTMLElement;
	if (videoSection) {
		videoSection.classList.add('no-schedule');
	}
};

const scheduleTemplate = (schedule: LearnTVShow[], activeShowIndex: number) => {
	return schedule.map((show, index) => {
		const activeClass = activeShowIndex === index ? 'active' : '';
		const watchNow =
			show.externalurl && show.externalurl.length
				? html`<a href="${show.externalurl}" class="has-margin-top-small">
						${loc_videoScheduleWatchNow}
				  </a>`
				: html``;

		return html`
			<li
				class="${activeClass} has-padding-right-small has-padding-left-large has-padding-bottom-medium has-padding-top-medium has-border-bottom"
			>
				${show.islive ? html`<span class="live">${loc_videoScheduleLive}</span>` : html``}
				<time datetime="${show.start_time}" class="has-margin-bottom-small">
					${shortTimeFormat.format(show.start_time)}
				</time>
				<h2 class="title is-5 has-margin-bottom-small">${show.title}</h2>
				<p class="is-size-small">${show.description}</p>
				${watchNow}
			</li>
		`;
	});
};

const renderSchedule = (schedule: LearnTVShow[], activeShowIndex: number) => {
	render(scheduleTemplate(schedule, activeShowIndex), document.querySelector('.schedule'));

	const activeShow = document.querySelector('.schedule li.active') as HTMLElement;
	const scheduleHolder = document.querySelector('.schedule-holder') as HTMLElement;
	if (activeShow && scheduleHolder) {
		scheduleHolder.scrollTop = activeShow.offsetTop;
	}
};

/**
 * Gets the date from query string for testing of the visual diff. Prod should not have testDate query.
 */
const getNow = () => {
	if (isProduction) {
		return Date.now();
	}

	const { testDate } = parseQueryString();
	const now = parseInt(testDate);
	return isNaN(now) ? Date.now() : now;
};

async function initSchedule() {
	const now = getNow();
	const hoursPrevious = 2;
	const hoursAhead = 24;
	// time in milliseconds when schedule should be reloaded: 12 * 60 * 60 * 1000 = ‭43200000‬;
	const timeToRefresh = now + 43200000;
	let schedule: LearnTVShow[];

	try {
		schedule = await getLearnTVSchedule();
	} catch {
		hideSchedule();
		return;
	}

	const lowerLimit = now - hoursPrevious * 60 * 60 * 1000;
	const upperLimit = now + hoursAhead * 60 * 60 * 1000;
	trimSchedule(schedule, lowerLimit, upperLimit);

	let currentActiveShowIndex = getActiveShowIndex(schedule, now);

	if (currentActiveShowIndex < 0) {
		hideSchedule();
		return;
	}

	renderSchedule(schedule, currentActiveShowIndex);
	const updateTimer = setInterval(() => {
		const now = Date.now();

		if (now > timeToRefresh) {
			clearInterval(updateTimer);
			initSchedule();
			return;
		}

		const newActiveShowIndex = getActiveShowIndex(schedule, now);
		if (newActiveShowIndex !== currentActiveShowIndex) {
			renderSchedule(schedule, newActiveShowIndex);
			currentActiveShowIndex = newActiveShowIndex;
		}
	}, 60 * 1000);
}

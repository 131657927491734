import { GradedQuestion, LearnAchievement, QuizAnswer, UnitUpdatedResponse } from '../apis/learn';

import type { Quiz } from './quiz';

export class QuizValidationRequestEvent {
	constructor(public instance: Quiz, public answers: QuizAnswer[]) {}
}

export class QuizValidatedEvent {
	public updated: boolean;
	public passed: boolean;
	public details: GradedQuestion[];
	public answers?: GradedQuestion[];
	public achievements: LearnAchievement[];

	constructor(response: UnitUpdatedResponse) {
		this.updated = response.updated;
		this.passed = response.passed;
		this.details = response.details as any;
		this.answers = response.answers;
		this.achievements = response.achievements;
	}
}

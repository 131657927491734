import { loc_iAgree, loc_optInOptOutEmailPrivacyPolicy } from '@msdocs/strings';
import { hideLabel, showLabel } from './labels';
import { html, render, unsafeHTML } from '../lit-html';

/**
 * Render opt in privacy policy text
 *
 * @param container The element to insert the privacy policy text
 */
export function renderCreateOptInOptOutPrivacyPolicy(container: HTMLElement) {
	const privacyPolicyBlock = container.querySelector(
		'#create-optin-optout-privacy-policy'
	) as HTMLElement;
	const privacyPolicy = html`${unsafeHTML(loc_optInOptOutEmailPrivacyPolicy)}`;
	render(privacyPolicy, privacyPolicyBlock);
}

/**
 * Render the opt in checkbox based on the user's country  selection
 *
 * @param container The element to insert the opt-in checkbox
 * @param countryOptInPreference The selected country's email opt in preference
 * @param formId The ID of the form that the function is checking within
 * @param submitButtonId The ID of the submit button
 */
export function renderOptInCheckbox(
	container: HTMLElement,
	countryOptInPreference: string,
	formId?: string,
	submitButtonId?: string
) {
	const optInCheckboxBlock = container.querySelector('#create-opt-in-checkbox') as HTMLElement;

	switch (countryOptInPreference) {
		case 'opt-in':
		case 'double-opt-in':
			render(checkboxTemplate(countryOptInPreference), optInCheckboxBlock);
			validateEmailIsNotEmptyForOptIn(container, formId, submitButtonId);
			break;
		case 'opt-out':
			render(checkboxTemplate(countryOptInPreference, true), optInCheckboxBlock);
			validateEmailIsNotEmptyForOptIn(container, formId, submitButtonId);
			break;
		case 'notice':
			render(html``, optInCheckboxBlock);
			validateEmailIsNotEmptyForOptIn(container, formId, submitButtonId, countryOptInPreference);
			break;
	}
}

function checkboxTemplate(countryOptInPreference: string, checked: boolean = false) {
	return html` <label class="checkbox">
		<input
			type="checkbox"
			class="opt-in"
			name="${countryOptInPreference}"
			.checked=${checked}
			aria-label="${loc_iAgree}"
		/>
		<span class="checkbox-check" role="presentation"></span>
		<span class="checkbox-text">${loc_iAgree}</span>
	</label>`;
}

/**
 * If email is unfilled and the opt-in checkbox is checked, require the email
 *
 * @param container The HTML Element
 * @param formId The ID of the form that the function is checking within
 * @param submitButtonId The ID of the submit button
 * @param countryOptInPreference Whether a country is a notice, opt-in, opt-out or doubt-opt-in status
 */
function validateEmailIsNotEmptyForOptIn(
	container: HTMLElement,
	formId?: string,
	submitButtonId?: string,
	countryOptInPreference?: string
) {
	const form =
		formId !== undefined ? (container.querySelector(`#${formId}`) as HTMLFormElement) : null;
	const countryDropDownBlock = container.querySelector('#create-country-list') as HTMLElement;
	const optInCheckboxElement = container.querySelector('.opt-in') as HTMLInputElement;
	const emailElement = container.querySelector('#update-email') as HTMLInputElement;
	const submitButton =
		submitButtonId !== undefined
			? (form.elements.namedItem(submitButtonId) as HTMLButtonElement)
			: null;

	const checkInput = () => {
		const isUserEmailEmpty = emailElement && emailElement.value ? false : true;

		// this if statement is to adjust for the opt-in form on the edit page
		if (submitButtonId === 'update-profile-button') {
			// check if it's a opt-in, opt-out or double opt-in country
			if (optInCheckboxElement) {
				const isOptInCheckboxChecked = optInCheckboxElement.checked;
				if (!isOptInCheckboxChecked) {
					submitButton.setAttribute('disabled', 'disabled');
				} else if (isOptInCheckboxChecked && isUserEmailEmpty) {
					// if checked and email is empty, show error message
					showLabel(form, 'emptyEmailForOptIn');
					emailElement.setAttribute('required', '');
					submitButton.setAttribute('disabled', 'disabled');
				} else if (isOptInCheckboxChecked && !isUserEmailEmpty) {
					hideLabel(form, 'emptyEmailForOptIn');
					submitButton.removeAttribute('disabled');
				}
				// check if it's a notice country
			} else if (optInCheckboxElement === null && isUserEmailEmpty) {
				showLabel(form, 'emptyEmailForOptIn');
				submitButton.setAttribute('disabled', 'disabled');
			} else if (optInCheckboxElement === null && !isUserEmailEmpty) {
				hideLabel(form, 'emptyEmailForOptIn');
				submitButton.removeAttribute('disabled');
			}
		} else {
			// if user chooses a notice country after email error message is displayed, hide email error
			if (countryOptInPreference === 'notice') {
				if (form && submitButton) {
					hideLabel(form, 'emptyEmailForOptIn');
					emailElement.removeAttribute('required');
					submitButton.removeAttribute('disabled');
				}
			} else if (optInCheckboxElement) {
				const isOptInCheckboxChecked = optInCheckboxElement.checked;
				if (isOptInCheckboxChecked && isUserEmailEmpty) {
					// if checked and email is empty, show error message
					if (form && submitButton) {
						showLabel(form, 'emptyEmailForOptIn');
						emailElement.setAttribute('required', '');
						submitButton.setAttribute('disabled', 'disabled');
					}
				} else {
					// if unchecked and email, hide error message
					if (form && submitButton) {
						hideLabel(form, 'emptyEmailForOptIn');
						emailElement.removeAttribute('required');
						submitButton.removeAttribute('disabled');
					}
				}
			}
		}
	};

	if (countryDropDownBlock) {
		countryDropDownBlock.addEventListener('change', checkInput);
	}
	if (emailElement) {
		emailElement.addEventListener('input', checkInput);
	}
	if (optInCheckboxElement) {
		optInCheckboxElement.addEventListener('change', checkInput);
	}
}

export function hideLabel(container: Element, label: string) {
	const errorElement = container.querySelector(`[data-label="${label}"]`) as HTMLElement;
	if (errorElement !== null) {
		errorElement.hidden = true;
	}
}

export function hideLabels(container: Element) {
	Array.from(container.querySelectorAll<HTMLElement>('[data-label]')).forEach(element => {
		element.hidden = true;
	});
}

export function showLabel(container: Element, label: string) {
	const errorElement = container.querySelector(`[data-label="${label}"]`) as HTMLElement;
	if (errorElement !== null) {
		errorElement.hidden = false;
	}
}

import { loc_assessment_guidance } from '@msdocs/strings';
import { msDocs } from '../globals';
import { localStorage } from '../protected-storage';
import { toLocaleDate } from '../text-formatting';
import { AssessmentSession, GuidanceNavigation } from './types';
import { assessmentStorageKey } from './view-model';

export function getSessionLocal(): Partial<AssessmentSession> | null {
	// check if there's an item in local storage with the correct key -> _localStorageSessionIdentifier
	if (localStorage.getItem(assessmentStorageKey) !== null) {
		return JSON.parse(localStorage.getItem(assessmentStorageKey));
	}
	return null;
}

export function removeSessionLocal(): void {
	if (localStorage.getItem(assessmentStorageKey) !== null) {
		localStorage.removeItem(assessmentStorageKey);
	}
}

export async function createNewLocalAssessmentSession(
	assessmentName: string,
	assessmentId: string,
	version: string,
	branch: string | null,
	locale: string | null
): Promise<Partial<AssessmentSession>> {
	return {
		id: 'local',
		assessmentId,
		version,
		categoriesSelected: {},
		name: createDefaultSessionName(assessmentName),
		responses: [],
		status: 'not-started',
		branch,
		locale
	};
}

function createDefaultSessionName(assessmentName: string) {
	const date = new Date();
	return `${assessmentName} - ${toLocaleDate(date)} - ${date.toLocaleTimeString(
		msDocs.data.userLocale
	)}`;
}

export function getGuidanceSessionName(navigationAction: GuidanceNavigation) {
	if (navigationAction.session) {
		return navigationAction.session.name;
	}

	if (navigationAction.guidance && navigationAction.guidance.sessionName) {
		return navigationAction.guidance.sessionName;
	}

	return loc_assessment_guidance;
}

import { loc_search } from '@msdocs/strings';
import { html } from '../../lit-html';
import { createQnaSearchAutocomplete, getUrlForTerm } from './qna-autocomplete';
import { parseQueryString } from '../../query-string';

/**
 * Create the Q&A search form - the hidden inputs are needed for the AnswerHub type filters on the right panel.
 * @param cssClass - The css class for the form, which AnswerHub uses 'search-query'.
 */
export const createQnaSearchForm = (cssClass?: string) => {
	const {
		f = '', // filter by author
		sort, // relevance or newest
		type = 'question OR idea OR kbentry OR answer OR topic OR user'
	} = parseQueryString();
	const handleSubmit = (event: Event) => {
		event.preventDefault();
		const form: HTMLFormElement = event.target as any;
		const input = form.elements.namedItem('search-query') as HTMLInputElement;
		if (input.value) {
			location.href = getUrlForTerm(input.value);
		}
	};

	return html`
		<form
			role="search"
			aria-label="${loc_search}"
			@submit=${handleSubmit}
			class=${cssClass ? cssClass : ''}
		>
			<label class="label visually-hidden" for="search-query">${loc_search}</label>
			<input type="hidden" id="search-container" name="c" value="7" />
			<input type="hidden" id="include-space-children" name="includeChildren" value="false" />
			<input type="hidden" id="search-filters" name="f" value="${f}" />
			<input type="hidden" id="search-types" name="type" value="${type}" />
			<input type="hidden" id="search-redirect" name="redirect" value="search/search" />
			<input
				type="hidden"
				id="search-sort"
				name="sort"
				value="${sort === 'newest' ? sort : 'relevance'}"
			/>
			${createQnaSearchAutocomplete('search-query')}
		</form>
	`;
};

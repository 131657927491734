import {
	loc_disclaimer_text,
	loc_learning_path_not_supported,
	loc_learning_path_supported,
	loc_module_not_supported,
	loc_module_supported
} from '@msdocs/strings';
import { checkMachineTranslated } from './archive';
import { cookies } from './cookies';
import { showDisclaimer } from './disclaimer';
import { msDocs, contentLoaded } from './globals';
import { getMeta, getMetas } from './meta';
import { supportedLocaleNames } from '@msdocs/locales';

export const pathLocaleRegex = /^\/([a-z]{2,3}-(?:[a-z]{4}-)?[a-z]{2})(\/|$)/i;

const localeCookieName = 'MarketplaceSelectedLocale';

const localeFallbackTemplates: typeof msDocs.data.pageTemplate[] = [
	'ContentPage',
	'Conceptual',
	'Module',
	'ModuleUnit',
	'LearningPath'
];

export function checkLocaleSupported(locale: string) {
	return !!supportedLocaleNames[locale];
}

export function getLocaleFromPath(path: string) {
	const match = pathLocaleRegex.exec(path);
	return match === null ? 'en-us' : match[1].toLowerCase();
}

export function addLocaleToPath(path: string, locale: string, replace: boolean = true): string {
	if (!checkLocaleSupported(locale)) {
		return path;
	}
	// URL is absolute; either "http://example.com" or "https://example.com" or "//example.com"
	// This will recognize an absolute URL, if URL contains "http://" or "https://" anywhere after the first character (ex: "http://example.com"),
	// or URL starts with "//" (ex: "//example.com")
	if (path.indexOf('http://') === 0 || path.indexOf('https://') === 0 || path.indexOf('//') === 0) {
		return path;
	}
	if (pathLocaleRegex.test(path)) {
		return replace ? replaceLocaleInPath(path, locale) : path;
	}
	return '/' + locale + path;
}

export function removeLocaleFromPath(path: string) {
	return path.replace(pathLocaleRegex, '/');
}

export function replaceLocaleInPath(path: string, locale: string) {
	return path.replace(pathLocaleRegex, `/${locale}$2`);
}

export async function setDocumentLocale() {
	const userLocale = msDocs.data.userLocale;
	const contentLocale = msDocs.data.contentLocale;
	const isMachineTranslated = checkMachineTranslated(getMeta('ms.translationtype'));
	msDocs.data.userLocaleName = supportedLocaleNames[userLocale];

	await contentLoaded;

	if (
		contentLocale !== userLocale &&
		/^en/.test(contentLocale) &&
		!/^en/.test(userLocale) &&
		!isMachineTranslated &&
		localeFallbackTemplates.indexOf(msDocs.data.pageTemplate) !== -1
	) {
		showDisclaimer(getLocDisclaimerText(msDocs.data.pageTemplate, userLocale));
	}

	setupLocaleLinks(userLocale);
}

export function setLocaleCookie(locale: string) {
	cookies.set(localeCookieName, locale, { expires: 365 * 10 });
}

export function setupLocaleLinks(userLocale: string) {
	Array.from(
		document.querySelectorAll<HTMLAnchorElement>('.locale-selector-link')
	).forEach(localeSelector => setupLocaleLink(localeSelector, userLocale));
}

export function setupLocaleLink(localeSelector: HTMLAnchorElement, userLocale: string) {
	const textArea = localeSelector.querySelector('.local-selector-link-text') as HTMLSpanElement;
	const localeName = supportedLocaleNames[userLocale];

	if (textArea) {
		textArea.textContent = localeName;
	} else {
		localeSelector.textContent = localeName;
	}

	localeSelector.href = `/${userLocale}/locale?target=${location.href}`;
}

export function checkLocalizationScopeSupported(locale: string) {
	const localizationScopes = getMetas('localization_scopes') as string[];
	return localizationScopes.indexOf(locale) !== -1;
}

export function getLocDisclaimerText(pageTemplate: string, userLocale: string): string {
	const isSupported = checkLocalizationScopeSupported(userLocale);
	const localeName = supportedLocaleNames[userLocale];

	if (pageTemplate === 'LearningPath') {
		return isSupported
			? loc_learning_path_supported.replace('{language}', localeName)
			: loc_learning_path_not_supported.replace('{language}', localeName);
	} else if (pageTemplate === 'Module' || pageTemplate === 'ModuleUnit') {
		return isSupported
			? loc_module_supported.replace('{language}', localeName)
			: loc_module_not_supported.replace('{language}', localeName);
	}

	return loc_disclaimer_text;
}

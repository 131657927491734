import { html, TemplateResult } from '../../lit-html';
import { TextSegment } from '../../text-formatting';

/**
 * Get a template for the highlighted content.
 * @param highlightedContent The content with its hit highlights
 */
export function getTemplateForHighlightedContent(
	highlightedContent: HighlightedContent,
	breakText: TextBreaker = (s: string) => s
): TemplateResult {
	const parts: string[] = getParts(highlightedContent);
	return html`${parts.map((part, i) =>
		i % 2 === 0
			? html`${breakText(part)}`
			: html`<mark class="has-body-background has-text-weight-semibold has-color-current-color"
					>${breakText(part)}</mark
			  >`
	)}`;
}

type TextBreaker = (s: string) => string | TextSegment[];

/**
 * Parse the content with the highlights into a string array where even parts are plain text and odd are highlighted.
 */
export function getParts({ content, hitHighlights }: HighlightedContent): string[] {
	if (!content) {
		return [];
	}

	hitHighlights.sort((first, second) => first.start - second.start); // ascending sort where start !== NaN && start !== Infinity
	const parts: string[] = [];
	let cursor = 0;
	for (const { start, length } of hitHighlights) {
		parts.push(content.substring(cursor, start), content.substring(start, start + length));
		cursor = start + length;
	}

	parts.push(content.substring(cursor));
	return parts;
}

export interface HighlightedContent {
	content: string;
	hitHighlights: HitHighlight[];
}

export interface HitHighlight {
	start: number;
	length: number;
}

//
// This file is generated by 'strings\build\taxonomy.js'
// If you need to make a change, update the build script
//

import {
	loc_products_ai_builder,
	loc_products_aspnet,
	loc_products_aspnet_core,
	loc_products_azure_active_directory,
	loc_products_azure_active_directory_b2c,
	loc_products_azure_active_directory_domain,
	loc_products_azure_advisor,
	loc_products_azure_analysis_services,
	loc_products_azure_anomaly_detector,
	loc_products_azure_api_apps,
	loc_products_azure_api_fhir,
	loc_products_azure_api_management,
	loc_products_azure_app_configuration,
	loc_products_azure_app_service,
	loc_products_azure_app_service_mobile,
	loc_products_azure_app_service_static,
	loc_products_azure_app_service_web,
	loc_products_azure_application_gateway,
	loc_products_azure_application_insights,
	loc_products_azure_arc,
	loc_products_azure_archive_storage,
	loc_products_azure_artifacts,
	loc_products_azure_automation,
	loc_products_azure_avere_vFXT,
	loc_products_azure_backup,
	loc_products_azure_bastion,
	loc_products_azure_batch,
	loc_products_azure_bing_autosuggest,
	loc_products_azure_bing_custom,
	loc_products_azure_bing_entity,
	loc_products_azure_bing_image,
	loc_products_azure_bing_news,
	loc_products_azure_bing_spellcheck,
	loc_products_azure_bing_video,
	loc_products_azure_bing_visual,
	loc_products_azure_bing_web,
	loc_products_azure_blob_storage,
	loc_products_azure_blockchain_service,
	loc_products_azure_blockchain_tokens,
	loc_products_azure_blockchain_workbench,
	loc_products_azure_blueprints,
	loc_products_azure_boards,
	loc_products_azure_bot_service,
	loc_products_azure_cache_redis,
	loc_products_azure_cdn,
	loc_products_azure_clis,
	loc_products_azure_cloud_services,
	loc_products_azure_cloud_shell,
	loc_products_azure_cognitive_search,
	loc_products_azure_cognitive_services,
	loc_products_azure_computer_vision,
	loc_products_azure_container_instances,
	loc_products_azure_container_registry,
	loc_products_azure_content_moderator,
	loc_products_azure_content_protection,
	loc_products_azure_cosmos_db,
	loc_products_azure_cost_management,
	loc_products_azure_custom_vision,
	loc_products_azure_cyclecloud,
	loc_products_azure_data_box_family,
	loc_products_azure_data_catalog,
	loc_products_azure_data_explorer,
	loc_products_azure_data_factory,
	loc_products_azure_data_lake,
	loc_products_azure_data_lake_analytics,
	loc_products_azure_data_lake_gen1,
	loc_products_azure_data_lake_gen2,
	loc_products_azure_data_lake_storage,
	loc_products_azure_data_science_vm,
	loc_products_azure_data_share,
	loc_products_azure_database_mariadb,
	loc_products_azure_database_migration,
	loc_products_azure_database_mysql,
	loc_products_azure_database_postgresql,
	loc_products_azure_databricks,
	loc_products_azure_ddos_protection,
	loc_products_azure_dedicated_host,
	loc_products_azure_dedicated_hsm,
	loc_products_azure_dev_spaces,
	loc_products_azure_dev_tool_integrations,
	loc_products_azure_devops,
	loc_products_azure_devops_tool_integrations,
	loc_products_azure_devtest_labs,
	loc_products_azure_digital_twins,
	loc_products_azure_disk_encryption,
	loc_products_azure_disk_storage,
	loc_products_azure_dns,
	loc_products_azure_encoding,
	loc_products_azure_event_grid,
	loc_products_azure_event_hubs,
	loc_products_azure_expressroute,
	loc_products_azure_face,
	loc_products_azure_farmbeats,
	loc_products_azure_files,
	loc_products_azure_firewall,
	loc_products_azure_firewall_manager,
	loc_products_azure_form_recognizer,
	loc_products_azure_front_door,
	loc_products_azure_functions,
	loc_products_azure_fxt_edge_filer,
	loc_products_azure_genomics,
	loc_products_azure_hdinsight,
	loc_products_azure_hdinsight_rserver,
	loc_products_azure_hpc_cache,
	loc_products_azure_immersive_reader,
	loc_products_azure_information_protection,
	loc_products_azure_ink_recognizer,
	loc_products_azure_internet_analyzer,
	loc_products_azure_iot,
	loc_products_azure_iot_central,
	loc_products_azure_iot_dps,
	loc_products_azure_iot_edge,
	loc_products_azure_iot_hub,
	loc_products_azure_iot_pnp,
	loc_products_azure_iot_sdk,
	loc_products_azure_iot_security_center,
	loc_products_azure_iot_solution_accelerators,
	loc_products_azure_key_vault,
	loc_products_azure_kinect_dk,
	loc_products_azure_kubernetes_service,
	loc_products_azure_lab_services,
	loc_products_azure_language_understanding,
	loc_products_azure_lighthouse,
	loc_products_azure_linux_vm,
	loc_products_azure_live_ondemand_streaming,
	loc_products_azure_live_video_analytics,
	loc_products_azure_load_balancer,
	loc_products_azure_log_analytics,
	loc_products_azure_logic_apps,
	loc_products_azure_machine_learning,
	loc_products_azure_machine_learning_service,
	loc_products_azure_machine_learning_studio,
	loc_products_azure_managed_applications,
	loc_products_azure_managed_disks,
	loc_products_azure_maps,
	loc_products_azure_media_analytics,
	loc_products_azure_media_player,
	loc_products_azure_media_services,
	loc_products_azure_migrate,
	loc_products_azure_mobile_apps,
	loc_products_azure_monitor,
	loc_products_azure_netapp_files,
	loc_products_azure_network_watcher,
	loc_products_azure_notebooks,
	loc_products_azure_notification_hubs,
	loc_products_azure_open_datasets,
	loc_products_azure_personalizer,
	loc_products_azure_pipelines,
	loc_products_azure_playfab,
	loc_products_azure_policy,
	loc_products_azure_portal,
	loc_products_azure_powerbi_embedded,
	loc_products_azure_private_link,
	loc_products_azure_qna_maker,
	loc_products_azure_quantum,
	loc_products_azure_queue_storage,
	loc_products_azure_rbac,
	loc_products_azure_redhat_openshift,
	loc_products_azure_remote_rendering,
	loc_products_azure_repos,
	loc_products_azure_resource_graph,
	loc_products_azure_resource_manager,
	loc_products_azure_rtos,
	loc_products_azure_sap,
	loc_products_azure_scheduler,
	loc_products_azure_sdks,
	loc_products_azure_search,
	loc_products_azure_security_center,
	loc_products_azure_sentinel,
	loc_products_azure_service_bus,
	loc_products_azure_service_fabric,
	loc_products_azure_service_health,
	loc_products_azure_signalr_service,
	loc_products_azure_site_recovery,
	loc_products_azure_sovereign_china,
	loc_products_azure_sovereign_germany,
	loc_products_azure_sovereign_us,
	loc_products_azure_spatial_anchors,
	loc_products_azure_speaker_recognition,
	loc_products_azure_speech,
	loc_products_azure_speech_text,
	loc_products_azure_speech_translation,
	loc_products_azure_sphere,
	loc_products_azure_spring_cloud,
	loc_products_azure_sql_data_warehouse,
	loc_products_azure_sql_database,
	loc_products_azure_sql_edge,
	loc_products_azure_sql_managed_instance,
	loc_products_azure_sql_virtual_machines,
	loc_products_azure_sqlserver_stretchdb,
	loc_products_azure_sqlserver_vm,
	loc_products_azure_stack,
	loc_products_azure_stack_edge,
	loc_products_azure_stack_hci,
	loc_products_azure_storage,
	loc_products_azure_storage_accounts,
	loc_products_azure_storage_explorer,
	loc_products_azure_storsimple,
	loc_products_azure_stream_analytics,
	loc_products_azure_synapse_analytics,
	loc_products_azure_table_storage,
	loc_products_azure_test_plans,
	loc_products_azure_text_analytics,
	loc_products_azure_text_speech,
	loc_products_azure_time_series_insights,
	loc_products_azure_traffic_manager,
	loc_products_azure_translator,
	loc_products_azure_translator_speech,
	loc_products_azure_translator_text,
	loc_products_azure_video_indexer,
	loc_products_azure_virtual_machines,
	loc_products_azure_virtual_machines_windows,
	loc_products_azure_virtual_network,
	loc_products_azure_virtual_wan,
	loc_products_azure_vm_scalesets,
	loc_products_azure_vmware_solution,
	loc_products_azure_vpn_gateway,
	loc_products_azure_web_application_firewall,
	loc_products_azure_web_apps,
	loc_products_azure_webapp_containers,
	loc_products_common_data_service,
	loc_products_customer_voice,
	loc_products_dotnet_core,
	loc_products_dotnet_standard,
	loc_products_dynamics_365,
	loc_products_dynamics_ai_customer_service,
	loc_products_dynamics_ai_market_insights,
	loc_products_dynamics_ai_sales,
	loc_products_dynamics_business_central,
	loc_products_dynamics_commerce,
	loc_products_dynamics_cust_insights,
	loc_products_dynamics_cust_svc_insights,
	loc_products_dynamics_customer_engagement,
	loc_products_dynamics_customer_service,
	loc_products_dynamics_field_service,
	loc_products_dynamics_finance,
	loc_products_dynamics_finance_operations,
	loc_products_dynamics_fraud_protection,
	loc_products_dynamics_guides,
	loc_products_dynamics_human_resources,
	loc_products_dynamics_layout,
	loc_products_dynamics_market_insights,
	loc_products_dynamics_marketing,
	loc_products_dynamics_operations,
	loc_products_dynamics_prod_visualize,
	loc_products_dynamics_product_insights,
	loc_products_dynamics_project_service,
	loc_products_dynamics_remote_assist,
	loc_products_dynamics_retail,
	loc_products_dynamics_sales,
	loc_products_dynamics_sales_insights,
	loc_products_dynamics_scm,
	loc_products_dynamics_talent,
	loc_products_dynamics_talent_attract,
	loc_products_dynamics_talent_core,
	loc_products_dynamics_talent_onboard,
	loc_products_ef_core,
	loc_products_expression_studio,
	loc_products_forms_pro,
	loc_products_m365_ems,
	loc_products_m365_ems_cloud_app_security,
	loc_products_m365_ems_configuration_manager,
	loc_products_m365_ems_intune,
	loc_products_m365_information_protection,
	loc_products_m365_security_center,
	loc_products_m365_security_score,
	loc_products_m365_threat_protection,
	loc_products_m365_workplace_analytics,
	loc_products_mlnet,
	loc_products_msc_configuration_manager,
	loc_products_msc_operations_manager,
	loc_products_msc_service_manager,
	loc_products_office_365_atp,
	loc_products_office_access,
	loc_products_office_adaptive_cards,
	loc_products_office_add_ins,
	loc_products_office_bookings,
	loc_products_office_excel,
	loc_products_office_exchange_server,
	loc_products_office_forefront,
	loc_products_office_kaizala,
	loc_products_office_lync_server,
	loc_products_office_onedrive,
	loc_products_office_onenote,
	loc_products_office_outlook,
	loc_products_office_planner,
	loc_products_office_powerpoint,
	loc_products_office_project,
	loc_products_office_project_server,
	loc_products_office_publisher,
	loc_products_office_skype_business,
	loc_products_office_sp,
	loc_products_office_sp_designer,
	loc_products_office_sp_framework,
	loc_products_office_sp_server,
	loc_products_office_teams,
	loc_products_office_ui_fabric,
	loc_products_office_visio,
	loc_products_office_word,
	loc_products_office_yammer,
	loc_products_power_apps,
	loc_products_power_automate,
	loc_products_power_bi,
	loc_products_power_platform_power_query,
	loc_products_power_virtual_agents,
	loc_products_powerapps,
	loc_products_vs_app_center,
	loc_products_vs_code,
	loc_products_vs_mac,
	loc_products_vs_online,
	loc_products_windows_api_win32,
	loc_products_windows_azure_pack,
	loc_products_windows_forms,
	loc_products_windows_iot,
	loc_products_windows_iot_10core,
	loc_products_windows_mdop,
	loc_products_windows_server,
	loc_products_windows_smb_server,
	loc_products_windows_system_center,
	loc_products_windows_uwp,
	loc_products_windows_virtual_desktop,
	loc_products_windows_wdk,
	loc_products_windows_wpf,
	loc_products_xamarin
} from '@msdocs/strings';
import { productFamilyNames } from './product-family';

export const productNames = {
	'ai-builder': loc_products_ai_builder,
	aspnet: loc_products_aspnet,
	'aspnet-core': loc_products_aspnet_core,
	'azure-active-directory': loc_products_azure_active_directory,
	'azure-active-directory-b2c': loc_products_azure_active_directory_b2c,
	'azure-active-directory-domain': loc_products_azure_active_directory_domain,
	'azure-advisor': loc_products_azure_advisor,
	'azure-analysis-services': loc_products_azure_analysis_services,
	'azure-anomaly-detector': loc_products_azure_anomaly_detector,
	'azure-api-apps': loc_products_azure_api_apps,
	'azure-api-fhir': loc_products_azure_api_fhir,
	'azure-api-management': loc_products_azure_api_management,
	'azure-app-configuration': loc_products_azure_app_configuration,
	'azure-app-service': loc_products_azure_app_service,
	'azure-app-service-mobile': loc_products_azure_app_service_mobile,
	'azure-app-service-static': loc_products_azure_app_service_static,
	'azure-app-service-web': loc_products_azure_app_service_web,
	'azure-application-gateway': loc_products_azure_application_gateway,
	'azure-application-insights': loc_products_azure_application_insights,
	'azure-arc': loc_products_azure_arc,
	'azure-archive-storage': loc_products_azure_archive_storage,
	'azure-artifacts': loc_products_azure_artifacts,
	'azure-automation': loc_products_azure_automation,
	'azure-avere-vFXT': loc_products_azure_avere_vFXT,
	'azure-backup': loc_products_azure_backup,
	'azure-bastion': loc_products_azure_bastion,
	'azure-batch': loc_products_azure_batch,
	'azure-bing-autosuggest': loc_products_azure_bing_autosuggest,
	'azure-bing-custom': loc_products_azure_bing_custom,
	'azure-bing-entity': loc_products_azure_bing_entity,
	'azure-bing-image': loc_products_azure_bing_image,
	'azure-bing-news': loc_products_azure_bing_news,
	'azure-bing-spellcheck': loc_products_azure_bing_spellcheck,
	'azure-bing-video': loc_products_azure_bing_video,
	'azure-bing-visual': loc_products_azure_bing_visual,
	'azure-bing-web': loc_products_azure_bing_web,
	'azure-blob-storage': loc_products_azure_blob_storage,
	'azure-blockchain-service': loc_products_azure_blockchain_service,
	'azure-blockchain-tokens': loc_products_azure_blockchain_tokens,
	'azure-blockchain-workbench': loc_products_azure_blockchain_workbench,
	'azure-blueprints': loc_products_azure_blueprints,
	'azure-boards': loc_products_azure_boards,
	'azure-bot-service': loc_products_azure_bot_service,
	'azure-cache-redis': loc_products_azure_cache_redis,
	'azure-cdn': loc_products_azure_cdn,
	'azure-clis': loc_products_azure_clis,
	'azure-cloud-services': loc_products_azure_cloud_services,
	'azure-cloud-shell': loc_products_azure_cloud_shell,
	'azure-cognitive-search': loc_products_azure_cognitive_search,
	'azure-cognitive-services': loc_products_azure_cognitive_services,
	'azure-computer-vision': loc_products_azure_computer_vision,
	'azure-container-instances': loc_products_azure_container_instances,
	'azure-container-registry': loc_products_azure_container_registry,
	'azure-content-moderator': loc_products_azure_content_moderator,
	'azure-content-protection': loc_products_azure_content_protection,
	'azure-cosmos-db': loc_products_azure_cosmos_db,
	'azure-cost-management': loc_products_azure_cost_management,
	'azure-custom-vision': loc_products_azure_custom_vision,
	'azure-cyclecloud': loc_products_azure_cyclecloud,
	'azure-data-box-family': loc_products_azure_data_box_family,
	'azure-data-catalog': loc_products_azure_data_catalog,
	'azure-data-explorer': loc_products_azure_data_explorer,
	'azure-data-factory': loc_products_azure_data_factory,
	'azure-data-lake': loc_products_azure_data_lake,
	'azure-data-lake-analytics': loc_products_azure_data_lake_analytics,
	'azure-data-lake-gen1': loc_products_azure_data_lake_gen1,
	'azure-data-lake-gen2': loc_products_azure_data_lake_gen2,
	'azure-data-lake-storage': loc_products_azure_data_lake_storage,
	'azure-data-science-vm': loc_products_azure_data_science_vm,
	'azure-data-share': loc_products_azure_data_share,
	'azure-database-mariadb': loc_products_azure_database_mariadb,
	'azure-database-migration': loc_products_azure_database_migration,
	'azure-database-mysql': loc_products_azure_database_mysql,
	'azure-database-postgresql': loc_products_azure_database_postgresql,
	'azure-databricks': loc_products_azure_databricks,
	'azure-ddos-protection': loc_products_azure_ddos_protection,
	'azure-dedicated-host': loc_products_azure_dedicated_host,
	'azure-dedicated-hsm': loc_products_azure_dedicated_hsm,
	'azure-dev-spaces': loc_products_azure_dev_spaces,
	'azure-dev-tool-integrations': loc_products_azure_dev_tool_integrations,
	'azure-devops': loc_products_azure_devops,
	'azure-devops-tool-integrations': loc_products_azure_devops_tool_integrations,
	'azure-devtest-labs': loc_products_azure_devtest_labs,
	'azure-digital-twins': loc_products_azure_digital_twins,
	'azure-disk-encryption': loc_products_azure_disk_encryption,
	'azure-disk-storage': loc_products_azure_disk_storage,
	'azure-dns': loc_products_azure_dns,
	'azure-encoding': loc_products_azure_encoding,
	'azure-event-grid': loc_products_azure_event_grid,
	'azure-event-hubs': loc_products_azure_event_hubs,
	'azure-expressroute': loc_products_azure_expressroute,
	'azure-face': loc_products_azure_face,
	'azure-farmbeats': loc_products_azure_farmbeats,
	'azure-files': loc_products_azure_files,
	'azure-firewall': loc_products_azure_firewall,
	'azure-firewall-manager': loc_products_azure_firewall_manager,
	'azure-form-recognizer': loc_products_azure_form_recognizer,
	'azure-front-door': loc_products_azure_front_door,
	'azure-functions': loc_products_azure_functions,
	'azure-fxt-edge-filer': loc_products_azure_fxt_edge_filer,
	'azure-genomics': loc_products_azure_genomics,
	'azure-hdinsight': loc_products_azure_hdinsight,
	'azure-hdinsight-rserver': loc_products_azure_hdinsight_rserver,
	'azure-hpc-cache': loc_products_azure_hpc_cache,
	'azure-immersive-reader': loc_products_azure_immersive_reader,
	'azure-information-protection': loc_products_azure_information_protection,
	'azure-ink-recognizer': loc_products_azure_ink_recognizer,
	'azure-internet-analyzer': loc_products_azure_internet_analyzer,
	'azure-iot': loc_products_azure_iot,
	'azure-iot-central': loc_products_azure_iot_central,
	'azure-iot-dps': loc_products_azure_iot_dps,
	'azure-iot-edge': loc_products_azure_iot_edge,
	'azure-iot-hub': loc_products_azure_iot_hub,
	'azure-iot-pnp': loc_products_azure_iot_pnp,
	'azure-iot-sdk': loc_products_azure_iot_sdk,
	'azure-iot-security-center': loc_products_azure_iot_security_center,
	'azure-iot-solution-accelerators': loc_products_azure_iot_solution_accelerators,
	'azure-key-vault': loc_products_azure_key_vault,
	'azure-kinect-dk': loc_products_azure_kinect_dk,
	'azure-kubernetes-service': loc_products_azure_kubernetes_service,
	'azure-lab-services': loc_products_azure_lab_services,
	'azure-language-understanding': loc_products_azure_language_understanding,
	'azure-lighthouse': loc_products_azure_lighthouse,
	'azure-linux-vm': loc_products_azure_linux_vm,
	'azure-live-ondemand-streaming': loc_products_azure_live_ondemand_streaming,
	'azure-live-video-analytics': loc_products_azure_live_video_analytics,
	'azure-load-balancer': loc_products_azure_load_balancer,
	'azure-log-analytics': loc_products_azure_log_analytics,
	'azure-logic-apps': loc_products_azure_logic_apps,
	'azure-machine-learning': loc_products_azure_machine_learning,
	'azure-machine-learning-service': loc_products_azure_machine_learning_service,
	'azure-machine-learning-studio': loc_products_azure_machine_learning_studio,
	'azure-managed-applications': loc_products_azure_managed_applications,
	'azure-managed-disks': loc_products_azure_managed_disks,
	'azure-maps': loc_products_azure_maps,
	'azure-media-analytics': loc_products_azure_media_analytics,
	'azure-media-player': loc_products_azure_media_player,
	'azure-media-services': loc_products_azure_media_services,
	'azure-migrate': loc_products_azure_migrate,
	'azure-mobile-apps': loc_products_azure_mobile_apps,
	'azure-monitor': loc_products_azure_monitor,
	'azure-netapp-files': loc_products_azure_netapp_files,
	'azure-network-watcher': loc_products_azure_network_watcher,
	'azure-notebooks': loc_products_azure_notebooks,
	'azure-notification-hubs': loc_products_azure_notification_hubs,
	'azure-open-datasets': loc_products_azure_open_datasets,
	'azure-personalizer': loc_products_azure_personalizer,
	'azure-pipelines': loc_products_azure_pipelines,
	'azure-playfab': loc_products_azure_playfab,
	'azure-policy': loc_products_azure_policy,
	'azure-portal': loc_products_azure_portal,
	'azure-powerbi-embedded': loc_products_azure_powerbi_embedded,
	'azure-private-link': loc_products_azure_private_link,
	'azure-qna-maker': loc_products_azure_qna_maker,
	'azure-quantum': loc_products_azure_quantum,
	'azure-queue-storage': loc_products_azure_queue_storage,
	'azure-rbac': loc_products_azure_rbac,
	'azure-redhat-openshift': loc_products_azure_redhat_openshift,
	'azure-remote-rendering': loc_products_azure_remote_rendering,
	'azure-repos': loc_products_azure_repos,
	'azure-resource-graph': loc_products_azure_resource_graph,
	'azure-resource-manager': loc_products_azure_resource_manager,
	'azure-rtos': loc_products_azure_rtos,
	'azure-sap': loc_products_azure_sap,
	'azure-scheduler': loc_products_azure_scheduler,
	'azure-sdks': loc_products_azure_sdks,
	'azure-search': loc_products_azure_search,
	'azure-security-center': loc_products_azure_security_center,
	'azure-sentinel': loc_products_azure_sentinel,
	'azure-service-bus': loc_products_azure_service_bus,
	'azure-service-fabric': loc_products_azure_service_fabric,
	'azure-service-health': loc_products_azure_service_health,
	'azure-signalr-service': loc_products_azure_signalr_service,
	'azure-site-recovery': loc_products_azure_site_recovery,
	'azure-sovereign-china': loc_products_azure_sovereign_china,
	'azure-sovereign-germany': loc_products_azure_sovereign_germany,
	'azure-sovereign-us': loc_products_azure_sovereign_us,
	'azure-spatial-anchors': loc_products_azure_spatial_anchors,
	'azure-speaker-recognition': loc_products_azure_speaker_recognition,
	'azure-speech': loc_products_azure_speech,
	'azure-speech-text': loc_products_azure_speech_text,
	'azure-speech-translation': loc_products_azure_speech_translation,
	'azure-sphere': loc_products_azure_sphere,
	'azure-spring-cloud': loc_products_azure_spring_cloud,
	'azure-sql-data-warehouse': loc_products_azure_sql_data_warehouse,
	'azure-sql-database': loc_products_azure_sql_database,
	'azure-sql-edge': loc_products_azure_sql_edge,
	'azure-sql-managed-instance': loc_products_azure_sql_managed_instance,
	'azure-sql-virtual-machines': loc_products_azure_sql_virtual_machines,
	'azure-sqlserver-stretchdb': loc_products_azure_sqlserver_stretchdb,
	'azure-sqlserver-vm': loc_products_azure_sqlserver_vm,
	'azure-stack': loc_products_azure_stack,
	'azure-stack-edge': loc_products_azure_stack_edge,
	'azure-stack-hci': loc_products_azure_stack_hci,
	'azure-storage': loc_products_azure_storage,
	'azure-storage-accounts': loc_products_azure_storage_accounts,
	'azure-storage-explorer': loc_products_azure_storage_explorer,
	'azure-storsimple': loc_products_azure_storsimple,
	'azure-stream-analytics': loc_products_azure_stream_analytics,
	'azure-synapse-analytics': loc_products_azure_synapse_analytics,
	'azure-table-storage': loc_products_azure_table_storage,
	'azure-test-plans': loc_products_azure_test_plans,
	'azure-text-analytics': loc_products_azure_text_analytics,
	'azure-text-speech': loc_products_azure_text_speech,
	'azure-time-series-insights': loc_products_azure_time_series_insights,
	'azure-traffic-manager': loc_products_azure_traffic_manager,
	'azure-translator': loc_products_azure_translator,
	'azure-translator-speech': loc_products_azure_translator_speech,
	'azure-translator-text': loc_products_azure_translator_text,
	'azure-video-indexer': loc_products_azure_video_indexer,
	'azure-virtual-machines': loc_products_azure_virtual_machines,
	'azure-virtual-machines-windows': loc_products_azure_virtual_machines_windows,
	'azure-virtual-network': loc_products_azure_virtual_network,
	'azure-virtual-wan': loc_products_azure_virtual_wan,
	'azure-vm-scalesets': loc_products_azure_vm_scalesets,
	'azure-vmware-solution': loc_products_azure_vmware_solution,
	'azure-vpn-gateway': loc_products_azure_vpn_gateway,
	'azure-web-application-firewall': loc_products_azure_web_application_firewall,
	'azure-web-apps': loc_products_azure_web_apps,
	'azure-webapp-containers': loc_products_azure_webapp_containers,
	'common-data-service': loc_products_common_data_service,
	'customer-voice': loc_products_customer_voice,
	'dotnet-core': loc_products_dotnet_core,
	'dotnet-standard': loc_products_dotnet_standard,
	'dynamics-365': loc_products_dynamics_365,
	'dynamics-ai-customer-service': loc_products_dynamics_ai_customer_service,
	'dynamics-ai-market-insights': loc_products_dynamics_ai_market_insights,
	'dynamics-ai-sales': loc_products_dynamics_ai_sales,
	'dynamics-business-central': loc_products_dynamics_business_central,
	'dynamics-commerce': loc_products_dynamics_commerce,
	'dynamics-cust-insights': loc_products_dynamics_cust_insights,
	'dynamics-cust-svc-insights': loc_products_dynamics_cust_svc_insights,
	'dynamics-customer-engagement': loc_products_dynamics_customer_engagement,
	'dynamics-customer-service': loc_products_dynamics_customer_service,
	'dynamics-field-service': loc_products_dynamics_field_service,
	'dynamics-finance': loc_products_dynamics_finance,
	'dynamics-finance-operations': loc_products_dynamics_finance_operations,
	'dynamics-fraud-protection': loc_products_dynamics_fraud_protection,
	'dynamics-guides': loc_products_dynamics_guides,
	'dynamics-human-resources': loc_products_dynamics_human_resources,
	'dynamics-layout': loc_products_dynamics_layout,
	'dynamics-market-insights': loc_products_dynamics_market_insights,
	'dynamics-marketing': loc_products_dynamics_marketing,
	'dynamics-operations': loc_products_dynamics_operations,
	'dynamics-prod-visualize': loc_products_dynamics_prod_visualize,
	'dynamics-product-insights': loc_products_dynamics_product_insights,
	'dynamics-project-service': loc_products_dynamics_project_service,
	'dynamics-remote-assist': loc_products_dynamics_remote_assist,
	'dynamics-retail': loc_products_dynamics_retail,
	'dynamics-sales': loc_products_dynamics_sales,
	'dynamics-sales-insights': loc_products_dynamics_sales_insights,
	'dynamics-scm': loc_products_dynamics_scm,
	'dynamics-talent': loc_products_dynamics_talent,
	'dynamics-talent-attract': loc_products_dynamics_talent_attract,
	'dynamics-talent-core': loc_products_dynamics_talent_core,
	'dynamics-talent-onboard': loc_products_dynamics_talent_onboard,
	'ef-core': loc_products_ef_core,
	'expression-studio': loc_products_expression_studio,
	'forms-pro': loc_products_forms_pro,
	'm365-ems': loc_products_m365_ems,
	'm365-ems-cloud-app-security': loc_products_m365_ems_cloud_app_security,
	'm365-ems-configuration-manager': loc_products_m365_ems_configuration_manager,
	'm365-ems-intune': loc_products_m365_ems_intune,
	'm365-information-protection': loc_products_m365_information_protection,
	'm365-security-center': loc_products_m365_security_center,
	'm365-security-score': loc_products_m365_security_score,
	'm365-threat-protection': loc_products_m365_threat_protection,
	'm365-workplace-analytics': loc_products_m365_workplace_analytics,
	mlnet: loc_products_mlnet,
	'msc-configuration-manager': loc_products_msc_configuration_manager,
	'msc-operations-manager': loc_products_msc_operations_manager,
	'msc-service-manager': loc_products_msc_service_manager,
	'office-365-atp': loc_products_office_365_atp,
	'office-access': loc_products_office_access,
	'office-adaptive-cards': loc_products_office_adaptive_cards,
	'office-add-ins': loc_products_office_add_ins,
	'office-bookings': loc_products_office_bookings,
	'office-excel': loc_products_office_excel,
	'office-exchange-server': loc_products_office_exchange_server,
	'office-forefront': loc_products_office_forefront,
	'office-kaizala': loc_products_office_kaizala,
	'office-lync-server': loc_products_office_lync_server,
	'office-onedrive': loc_products_office_onedrive,
	'office-onenote': loc_products_office_onenote,
	'office-outlook': loc_products_office_outlook,
	'office-planner': loc_products_office_planner,
	'office-powerpoint': loc_products_office_powerpoint,
	'office-project': loc_products_office_project,
	'office-project-server': loc_products_office_project_server,
	'office-publisher': loc_products_office_publisher,
	'office-skype-business': loc_products_office_skype_business,
	'office-sp': loc_products_office_sp,
	'office-sp-designer': loc_products_office_sp_designer,
	'office-sp-framework': loc_products_office_sp_framework,
	'office-sp-server': loc_products_office_sp_server,
	'office-teams': loc_products_office_teams,
	'office-ui-fabric': loc_products_office_ui_fabric,
	'office-visio': loc_products_office_visio,
	'office-word': loc_products_office_word,
	'office-yammer': loc_products_office_yammer,
	'power-apps': loc_products_power_apps,
	'power-automate': loc_products_power_automate,
	'power-bi': loc_products_power_bi,
	'power-platform-power-query': loc_products_power_platform_power_query,
	'power-virtual-agents': loc_products_power_virtual_agents,
	powerapps: loc_products_powerapps,
	'vs-app-center': loc_products_vs_app_center,
	'vs-code': loc_products_vs_code,
	'vs-mac': loc_products_vs_mac,
	'vs-online': loc_products_vs_online,
	'windows-api-win32': loc_products_windows_api_win32,
	'windows-azure-pack': loc_products_windows_azure_pack,
	'windows-forms': loc_products_windows_forms,
	'windows-iot': loc_products_windows_iot,
	'windows-iot-10core': loc_products_windows_iot_10core,
	'windows-mdop': loc_products_windows_mdop,
	'windows-server': loc_products_windows_server,
	'windows-smb-server': loc_products_windows_smb_server,
	'windows-system-center': loc_products_windows_system_center,
	'windows-uwp': loc_products_windows_uwp,
	'windows-virtual-desktop': loc_products_windows_virtual_desktop,
	'windows-wdk': loc_products_windows_wdk,
	'windows-wpf': loc_products_windows_wpf,
	xamarin: loc_products_xamarin
};

export const allProductNames: { [key: string]: string } = {
	...productFamilyNames,
	...productNames
};

export type ProductId = keyof typeof productNames;

import type { ImageBrowser } from './image-browser';

export function attachEvents(imageBrowser: ImageBrowser, imageElement: Element) {
	const state = {
		touches: [] as { identifier: number; screenX: number; screenY: number }[]
	};

	imageElement.addEventListener('touchstart', (event: TouchEvent) => {
		if (imageBrowser.state.expanded) {
			event.preventDefault();

			Array.from(event.changedTouches).forEach(({ identifier, screenX, screenY }) => {
				state.touches.push(Object.assign({}, { identifier, screenX, screenY }));
			});
		}
	});

	imageElement.addEventListener('touchmove', (event: TouchEvent) => {
		if (imageBrowser.state.expanded) {
			event.preventDefault();

			const identifiers = state.touches.map(x => x.identifier);
			// check touches
			const touch = Array.from(event.changedTouches).filter(x => {
				return x.identifier === state.touches[0].identifier;
			});

			const deltaX = touch[0].screenX - state.touches[0].screenX;
			const deltaY = touch[0].screenY - state.touches[0].screenY;

			// update all touches except for the first so that we can track deltas.
			Array.from(event.changedTouches).forEach(touch => {
				const index = identifiers.indexOf(touch.identifier);
				if (index >= 0) {
					state.touches[index] = Object.assign(
						{},
						{ identifier: touch.identifier, screenX: touch.screenX, screenY: touch.screenY }
					);
				}
			});

			imageBrowser.panViewport(deltaX, deltaY);
		}
	});

	imageElement.addEventListener('touchend', (event: TouchEvent) => {
		const identifiers = state.touches.map(x => x.identifier);

		Array.from(event.changedTouches).forEach(({ identifier }) => {
			state.touches.splice(identifiers.indexOf(identifier), 1);
		});
	});
}

import { Award, blankGameStatusResponse, gameApi, GameStatus } from '../../apis/game-status';
import { Achievement, getAchievementsByUids } from '../../apis/learn';
import { List, listApi, ListInit, ListItem } from '../../apis/lists';
import { getFollowingQnA, getProfile } from '../../apis/profile';
import { ProfileResponse, user } from '../../auth/user';
import { features } from '../../environment/features';
import { Following, FollowingUser, Thread, Topic } from '../../following/model';
import { msDocs } from '../../globals';
import { Page } from './list-view-model';

export function isCurrentUser(username: string) {
	return (
		user.isAuthenticated &&
		user.userName.localeCompare(username, undefined, { sensitivity: 'base' }) === 0
	);
}

let profiles: Record<string, Promise<ProfileResponse>> = {};

export function getUserProfile(username: string): Promise<ProfileResponse> {
	if (isCurrentUser(username)) {
		return Promise.resolve(user);
	}
	username = username.toLowerCase();
	if (profiles[username] === undefined) {
		profiles[username] = getProfile(username);
	}
	return profiles[username];
}

export async function getUserId(username: string) {
	const profile = await getUserProfile(username);
	return profile.userId;
}

let gameStati: Record<string, Promise<GameStatus>> = {};

export function getGameStatus(userid: string): Promise<GameStatus> {
	if (!features.gamification) {
		return Promise.resolve(blankGameStatusResponse);
	}
	if (gameStati[userid] === undefined) {
		gameStati[userid] = gameApi.getStatus(userid);
	}
	return gameStati[userid];
}

export interface AwardedAchievement {
	award: Award;
	achievement: Achievement;
}

let achievements: Record<string, Promise<AwardedAchievement[]>> = {};

export function getAchievements(userid: string): Promise<AwardedAchievement[]> {
	if (achievements[userid] === undefined) {
		achievements[userid] = getGameStatus(userid).then(async status => {
			const awards = status.achievements;
			const uids = awards.map(award => award.awardUid);
			const achievements = await getAchievementsByUids(uids, msDocs.data.userLocale);
			return awards
				.map(award => ({
					award,
					achievement: achievements.find(x => x.uid === award.awardUid)
				}))
				.filter(x => x.achievement);
		});
	}
	return achievements[userid];
}

export async function getBadges(userid: string): Promise<AwardedAchievement[]> {
	const all = await getAchievements(userid);
	return all.filter(x => x.achievement.type === 'badge');
}

export async function getTrophies(userid: string): Promise<AwardedAchievement[]> {
	const all = await getAchievements(userid);
	return all.filter(x => x.achievement.type === 'trophy');
}

const bookmarks: Record<string, Promise<ListItem[]>> = {};

export function getBookmarks(userId: string): Promise<ListItem[]> {
	if (bookmarks[userId] === undefined) {
		bookmarks[userId] = listApi.getList('bookmarks').then(list => list.items);
	}

	return bookmarks[userId];
}

export async function removeBookmark(userId: string, bookmarkId: string) {
	await listApi.deleteItem('bookmarks', bookmarkId);

	if (await bookmarks[userId]) {
		const collectionToClean = bookmarks[userId];
		bookmarks[userId] = collectionToClean.then(bookmarks => {
			return bookmarks.filter(x => x.id !== bookmarkId);
		});
	}
}

let collections: Promise<List[]>;

export function getCollections(): Promise<List[]> {
	if (collections === undefined) {
		collections = listApi.getAllLists().then(lists => lists.filter(x => x.type === 'collection'));
	}

	return collections;
}

export function createNewCollection(args: ListInit) {
	collections = undefined;
	return listApi.createList(args);
}

export function removeCollection(listId: string) {
	collections = undefined;
	return listApi.deleteList(listId);
}

const following: Record<string, Promise<Following>> = {};

export async function getFollowing(userId: string): Promise<Following> {
	if (following[userId] === undefined) {
		const following = await getFollowingQnA();
		return following;
	}
	return await following[userId];
}

export async function getTopics(userId: string): Promise<Topic[]> {
	const following = await getFollowing(userId);
	if (following && following.topics) {
		return following.topics;
	} else {
		return [];
	}
}

export async function getFollowingUsers(
	userId: string,
	pageIndex: number,
	pageSize: number
): Promise<Page<FollowingUser>> {
	const following = await getFollowing(userId);
	if (following && following.users) {
		return {
			totalCount: following.users.length,
			items: following.users.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize)
		};
	} else {
		return {
			totalCount: 0,
			items: []
		};
	}
}

export async function getThreads(
	userId: string,
	pageIndex: number,
	pageSize: number
): Promise<Page<Thread>> {
	const following = await getFollowing(userId);
	if (following && following.threads) {
		return {
			totalCount: following.threads.length,
			items: following.threads.slice(pageIndex * pageSize, pageIndex * pageSize + pageSize)
		};
	} else {
		return {
			totalCount: 0,
			items: []
		};
	}
}

/**
 * Only used in tests... tree-shaken away
 */
export function __reset_cache__() {
	profiles = {};
	gameStati = {};
	achievements = {};
}

import { location } from './globals';
import { parseQueryString, toQueryString } from './query-string';

export interface OAuthFlowArgs {
	signInUrl: string;
	signInArgs?: { [name: string]: string };
	returnUrlArg: string;
	returnUrlArgs?: { [name: string]: string };
	returnUrlHash?: string;
}

export function getReturnUrl(
	returnUrlArgs?: { [name: string]: string },
	returnUrlHash?: string
): string {
	let { search, hash } = location;
	const { hostname, pathname, protocol } = location;

	// need to set any query params on the return URL?
	if (returnUrlArgs != null && Object.keys(returnUrlArgs).length > 0) {
		const searchArgs = parseQueryString(search);
		Object.assign(searchArgs, returnUrlArgs);
		search = `?${toQueryString(searchArgs)}`;
	}

	// need to set a hash on the return URL?
	if (typeof returnUrlHash === 'string' && returnUrlHash.trim() !== '') {
		hash = `#${returnUrlHash}`;
	}

	return `${protocol}//${hostname}${pathname}${search}${hash}`;
}

export function doOAuthFlow({
	signInUrl,
	signInArgs = {},
	returnUrlArg,
	returnUrlArgs = null,
	returnUrlHash = null
}: OAuthFlowArgs): void {
	signInArgs[returnUrlArg] = getReturnUrl(returnUrlArgs, returnUrlHash);
	location.href = `${signInUrl}?${toQueryString(signInArgs)}`;
}

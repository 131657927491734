import { getMeta } from '../meta';
import { safeUrl } from '../url';

export class SignInReturnUrl {
	public readonly source: string;
	private readonly url: URL;

	constructor(returnUrl: string) {
		this.url = safeUrl(returnUrl);
		this.source = this.url.searchParams.get('source');
		if (!this.source) {
			this.source =
				getMeta('page_type') === 'learn' || getMeta('page_kind') === 'learn' ? 'learn' : 'docs';

			if (getMeta('uhfHeaderId') !== 'answers') {
				this.url.searchParams.set('source', this.source);
			}
		}
	}

	public static getProfileContext(returnUrl: string): object {
		try {
			const url = returnUrl || location.href;
			const signInReturnUrl = new SignInReturnUrl(url);
			const urlClone = safeUrl(url);
			urlClone.searchParams.delete('source');

			return {
				redirectUrl: urlClone.href,
				source: signInReturnUrl.source,
				microsoftUser: undefined
			};
		} catch {
			return {
				redirectUrl: undefined,
				source: undefined,
				microsoftUser: undefined
			};
		}
	}

	public toString(): string {
		return this.url.href;
	}
}

import { loc_selectOneCheckboxBeforeProceeding } from '@msdocs/strings';
import { html, TemplateResult, ifDefined } from '../../lit-html';
import { forceWrite } from '../../plugins/lit-html/force-write';
import { AssessmentSession, Category, Question } from '../types';
import { AssessmentViewModel } from '../view-model';
import { createQuestionHeaderTemplate } from './shared-template';

export interface CheckBoxGroupConfig {
	spacing: 'none' | 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';
	controlClasses?: string;
	atLeastOneRequired: boolean;
}

export const createCheckboxGroupTemplate = (
	viewModel: AssessmentViewModel,
	config: CheckBoxGroupConfig = {
		controlClasses: 'has-margin-bottom-extra-large has-margin-top-extra-large',
		spacing: 'medium',
		atLeastOneRequired: true
	}
) => {
	const category = viewModel.currentCategory;
	const question = viewModel.currentQuestion as Question;
	const selectionInGroup = viewModel.isValid;
	config.atLeastOneRequired = question.isRequired;
	// selection in group is coming in as true
	const checkboxesTemplate = question.choices.map((choice, i) =>
		getCheckboxTemplate(
			question.id,
			choice.id,
			viewModel.isSelectedResponse(category.id, question.id, choice.id),
			choice.text,
			selectionInGroup,
			i !== question.choices.length - 1,
			config,
			undefined,
			choice.description
		)
	);

	return html`
		${createQuestionHeaderTemplate(question.title, question.description)}
		<div class="control ${config.controlClasses}">
			${checkboxesTemplate}
		</div>
	`;
};

export const categoriesToCheckboxGroup = (
	categories: Category[],
	session: Partial<AssessmentSession>,
	selectionInGroup: boolean,
	config: CheckBoxGroupConfig = {
		controlClasses: 'has-margin-bottom-medium',
		spacing: 'medium',
		atLeastOneRequired: true
	}
) => {
	const categoriesTemplate = categories.map((category, i) => {
		const checked =
			category.id in session.categoriesSelected && session.categoriesSelected[category.id];
		return category.isRequired
			? html``
			: getCheckboxTemplate(
					'selectCategory',
					category.id,
					checked,
					category.title,
					selectionInGroup,
					i !== categories.length - 1,
					config,
					category.description,
					undefined
			  );
	});

	return html`
		<div class="control ${config.controlClasses}">
			${categoriesTemplate}
		</div>
	`;
};

function getCheckboxDescribedBy(
	checkboxDescription: string,
	value: string,
	tooltipText?: string
): undefined | string {
	const descByDesc = checkboxDescription ? `${value}-description` : undefined;
	const descByToolTip = tooltipText ? `${value}-tooltip` : undefined;

	if (!descByDesc && !descByToolTip) {
		return undefined;
	} else if (descByDesc && descByToolTip) {
		return `${descByDesc} ${descByToolTip}`;
	} else if (descByDesc) {
		return descByDesc;
	} else {
		return descByToolTip;
	}
}

function getCheckboxTemplate(
	name: string,
	value: string,
	isChecked: boolean,
	checkboxText: string,
	selectionInGroup: boolean,
	isLast: boolean,
	config: CheckBoxGroupConfig = {
		controlClasses: 'has-margin-bottom-medium',
		spacing: 'medium',
		atLeastOneRequired: true
	},
	checkboxDescription?: string,
	tooltipText?: string
): TemplateResult {
	const validationMessage =
		config.atLeastOneRequired && !selectionInGroup ? loc_selectOneCheckboxBeforeProceeding : '';

	return html`
		<label
			class="checkbox"
			aria-describedby="${ifDefined(
				getCheckboxDescribedBy(checkboxDescription, value, tooltipText) ?? undefined
			)}"
			title="${ifDefined(tooltipText ?? undefined)}"
		>
			<input
				type="checkbox"
				name=${name}
				value=${value}
				.checked=${isChecked}
				.customValidity=${forceWrite(validationMessage)}
			/>
			<span class="checkbox-check" role="presentation"></span>
			<span class="checkbox-text ${!!checkboxDescription ? 'has-text-weight-semibold' : ''}"
				>${checkboxText}</span
			>
		</label>
		${tooltipText
			? html`<span class="is-visually-hidden" id="${value}-tooltip" role="tooltip"
					>${tooltipText}</span
			  >`
			: null}
		${checkboxDescription
			? html`<p
					id="${value}-description"
					class="has-margin-top-small has-margin-left-large has-padding-left-extra-small"
			  >
					${checkboxDescription}
			  </p>`
			: ''}
		${isLast ? html`<div class="has-margin-bottom-${config.spacing}"></div>` : ''}
	`;
}

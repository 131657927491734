import { fetchWithTimeout } from '../fetch';
import { apis } from '../environment/apis';

export interface LearnTVShow {
	islive: boolean;
	title: string;
	description: string;
	externalurl: string;
	externaltitle: string;
	start_time: Date;
	end_time: Date;
}

export async function getLearnTVSchedule(): Promise<LearnTVShow[]> {
	const response = await fetchWithTimeout(apis.learn.tvSchedule);
	if (!response.ok) {
		throw new Error(`${response.status}: ${response.statusText}`);
	}

	const data = await response.json();
	if (!data || !data.content || !data.content.length) {
		throw new Error('No Content');
	}

	return data.content.map((show: any) => {
		return {
			islive: show.islive,
			title: show.title,
			description: show.description,
			externalurl: show.externalurl,
			externaltitle: show.externaltitle,
			start_time: new Date(show.start_time),
			end_time: new Date(show.end_time)
		};
	});
}

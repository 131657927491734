import { base64URLdecode } from '../../base64';
import { localStorage } from '../../protected-storage';

export const IdTokenKey = 'id_token';
export const DocsAuthTokenKey = 'docsAuthToken';

/**
 * Used when the JWT itself is malformed.
 */
export class InvalidTokenError {
	public readonly message: string;
	public readonly detail: string;
	constructor({ detail }: { detail: string }) {
		this.message = 'invalid token';
		this.detail = detail;
	}
}

/**
 * Used when a claim value does not pass validation. Examples include expiration dates, invalid issuers, etc.
 */
export class InvalidClaimError {
	public readonly message: string;
	public readonly detail: string;
	constructor({ detail }: { detail: string }) {
		this.message = 'invalid claim';
		this.detail = detail;
	}
}

/**
 * Used when a claim value is malformed in some way according to the spec. Examples included a user replacing
 * an expiration date with a string instead of a number, or leaving a claim value empty without removing the key.
 */
export class MalformedClaimError {
	public readonly message: string;
	public readonly detail: string;
	constructor({ detail }: { detail: string }) {
		this.message = 'malformed claim';
		this.detail = detail;
	}
}

export function storeIdToken(token: string) {
	localStorage.setItem(IdTokenKey, token);
}

export function getIdToken() {
	return localStorage.getItem(IdTokenKey);
}

export function deleteIdToken() {
	localStorage.removeItem(IdTokenKey);
}

export function storeDocsToken(token: string) {
	localStorage.setItem(DocsAuthTokenKey, token);
}

export function getDocsToken() {
	return localStorage.getItem(DocsAuthTokenKey);
}

export function deleteDocsToken() {
	localStorage.removeItem(DocsAuthTokenKey);
}

/**
 * Verification should use the steps outlined by https://tools.ietf.org/html/rfc7519#section-7.2,
 * but this is a rough frontend version. Responsibilities of this function:
 * 1. Ensure that the base-64 token has at least one period, according to the spec.
 * 2. Ensure that a payload exists.
 * 3. If an expiration claim is set, check that the date is a valid timestamp in seconds and ensure that Date.now is before exp timestamp.
 * 4. If a "not before" date is set, check that the date is a valid timestamp in seconds and ensure that Date.now is after nbf timestamp.
 * We do this primarily because an attacker can mess with the token in localstorage. If this happens, ideally we should just get a new token.
 * We can't check the signing of the token, unfortunately.
 * @param token The base64-encoded JWT, with sections separated by periods.
 */
export function verifyToken(
	token: string,
	options: JWTVerificationOptions = {
		nbfClockSkewInMilliseconds: 2500
	}
) {
	try {
		const valid = token.indexOf('.') > -1;
		if (!valid) {
			throw new InvalidTokenError({
				detail: 'Invalid JWT - no separators found.'
			});
		}

		const sections: string[] = token.split('.');
		const payload: Partial<JWT.Payload> = JSON.parse(base64URLdecode(sections[1]));

		// check payload claims for exp and nbf.
		if (!payload) {
			throw new InvalidTokenError({
				detail: 'Invalid JWT - payload is missing.'
			});
		}

		if (payload.hasOwnProperty('nbf')) {
			const nbf = payload.nbf;
			if (isNaN(nbf)) {
				throw new MalformedClaimError({
					detail: 'Invalid NBF claim - claim is malformed.'
				});
			}

			const notBeforeDate = new Date(0);
			notBeforeDate.setUTCSeconds(payload.nbf);
			const currentDate = new Date(Date.now() + options.nbfClockSkewInMilliseconds);
			if (notBeforeDate > currentDate) {
				throw new InvalidClaimError({
					detail: 'Invalid NBF claim - token not yet valid.'
				});
			}
		}

		if (payload.hasOwnProperty('exp')) {
			const exp = payload.exp;
			if (isNaN(exp)) {
				throw new MalformedClaimError({
					detail: 'Invalid EXP claim - claim is malformed.'
				});
			}

			const expiryDate = new Date(0);
			expiryDate.setUTCSeconds(payload.exp);
			const currentDate = new Date(Date.now());
			if (expiryDate <= currentDate) {
				throw new InvalidClaimError({
					detail: 'Invalid EXP claim - token has expired.'
				});
			}
		}

		return payload;
	} finally {
	}
}

export interface JWTVerificationOptions {
	nbfClockSkewInMilliseconds: number;
}

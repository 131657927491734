import { location } from '../../globals';
import { parseQueryString, toQueryString, updateQueryString } from '../../query-string';
import { azureSandboxActivateParameter } from '../sandbox';

export function alreadyActivated() {
	const args = parseQueryString(location.search);
	if (args[azureSandboxActivateParameter] === 'true') {
		args[azureSandboxActivateParameter] = null;
		updateQueryString(args, 'replaceState');
		return true;
	}
	return false;
}

export function createReturnUrl() {
	const args = parseQueryString(location.search);
	args[azureSandboxActivateParameter] = 'true';
	return `${location.protocol}//${location.hostname}${location.pathname}?${toQueryString(args)}${
		location.hash
	}`;
}

export function createTenantInviteReturnUrl() {
	const url = createReturnUrl();
	try {
		sessionStorage.setItem('tenant-invitation-return-url', url);
	} catch (err) {}

	// tenant-invitation-accepted.html does a client-side redirect
	// to the url specified in local storage.
	// - forcing non .docs.microsoft.com origin through prod docs
	const origin =
		!location.origin || !location.origin.endsWith('docs.microsoft.com')
			? 'https://docs.microsoft.com'
			: location.origin;
	return `${origin}/_themes/docs.theme/master/en-us/_themes/global/tenant-invitation-accepted.html`;
}

import { LearnItem } from '../apis/learn';

export interface LearnActionAnchor {
	elementType: 'a';
	text: string;
	href: string;
	biName: string;
	sectionTitle?: string;
	doconClass?: string;
	target?: string;
}

export interface LearnActionButton {
	elementType: 'button';
	text: string;
	onclick: () => void;
	biName: string;
	sectionTitle?: string;
}

export interface LearnActionLinks {
	elementType: 'ul';
	sectionTitle: string;
	items: LearnItem[];
}

export interface LearnActionNoOp {
	elementType: 'none';
}

export interface LearnPageNavigationMap {
	'back-to-finish': LearnActionAnchor;
	continue: LearnActionAnchor;
	'modal-complete': LearnActionButton;
	'modal-quiz': LearnActionNoOp;
	'modal-task': LearnActionNoOp;
	'next-step-module': LearnActionAnchor;
	'next-step-path-complete': LearnActionAnchor;
	'next-step-explore-modules': LearnActionAnchor;
	'next-step-explore-paths': LearnActionLinks;
}
export interface LearnModalNavigationMap {
	'next-step-orphan-module': LearnActionAnchor;
	'next-step-single-path-complete': LearnActionAnchor;
	'next-step-single-path-incomplete': LearnActionAnchor;
	'next-step-multiple-parent-paths': LearnActionLinks;
}

export type LearnPageNavigationType = keyof LearnPageNavigationMap;
export type LearnModalNavigationType = keyof LearnModalNavigationMap;
export type LearnPageNavigationInfo = LearnPageNavigationMap[keyof LearnPageNavigationMap];
export type LearnModalNavigationInfo = LearnModalNavigationMap[keyof LearnModalNavigationMap];

export function isLearnNavNoOp(
	learnActionInfo: LearnPageNavigationInfo
): learnActionInfo is LearnActionNoOp {
	return learnActionInfo.elementType === 'none';
}

import {
	loc_disclaimer_machineTranslatedOnly,
	loc_disclaimer_machineTranslatedWithoutEditButton
} from '@msdocs/strings';
import { notifyContentUpdated } from './affix';
import { checkIsArchived, checkMachineTranslated } from './archive';
import { showDisclaimer } from './disclaimer';
import { msDocs } from './globals';
import { renderInTopicTOC } from './in-topic-toc';
import { getMeta } from './meta';
import { localStorage } from './protected-storage';

const translationMeta = getMeta('ms.translationtype');
const machineTranslated = checkMachineTranslated(translationMeta);
const translated = machineTranslated || translationMeta === 'HT';

const translationTypeMeta = getMeta('ms.translationtype') || 'blank';
const translationType = translationTypeMeta.toUpperCase();

export function displayTranslations(lang: string) {
	const locale = lang.toLowerCase();
	const openToPublicContributorsContainer = document.getElementById('contenteditbtn') !== null;
	const openToPublicContributors = openToPublicContributorsContainer
		? !document.getElementById('contenteditbtn').hasAttribute('hidden')
		: false;

	// no disclaimer when archived or the article is not machine translated/blank
	if (
		checkIsArchived() ||
		locale === 'en-us' ||
		!(machineTranslated || translationType === 'BLANK')
	) {
		return;
	}
	if (openToPublicContributors) {
		showDisclaimer(loc_disclaimer_machineTranslatedOnly);
	} else {
		showDisclaimer(loc_disclaimer_machineTranslatedWithoutEditButton);
	}
}

export function handleEngContentToggle(container: HTMLElement): void {
	const content = document.querySelector('.primary-holder main.content') as HTMLElement;
	const languageToggle = document.getElementById('language-toggle') as HTMLInputElement;

	// toggle should be active on all translated content
	if (!translated || !languageToggle) {
		return;
	}

	const english = localStorage.getItem('toggledToEnglish') === 'true';

	setEngToggleBi(languageToggle, english);

	const languageConfig = {
		originalLang: content.lang,
		originalDir: content.dir
	} as OriginalContentSettings;

	if (english) {
		languageToggle.checked = true;
		toggleContentLanguage(english, languageConfig);
	}

	languageToggle.addEventListener('change', e => {
		const target = e.target as HTMLInputElement;
		const checked = target.checked;
		setEngToggleBi(languageToggle, checked);

		toggleContentLanguage(checked, languageConfig);
		renderInTopicTOC();
		notifyContentUpdated();
	});

	container.style.display = 'flex';
}

export function toggleContentLanguage(
	toEnglish: boolean,
	{ originalLang, originalDir }: OriginalContentSettings
): void {
	localStorage.setItem('toggledToEnglish', `${toEnglish}`);

	const content = document.querySelector('main.content') as HTMLElement;
	const localizedElements = Array.from(content.querySelectorAll('[data-ttu-id]')) as HTMLElement[];
	const sxslookupElements = Array.from(content.querySelectorAll('.sxs-lookup')) as HTMLElement[];

	if (toEnglish) {
		localizedElements.forEach(elt => (elt.style.display = 'none'));
		sxslookupElements.forEach(elt => (elt.style.display = 'inline'));

		content.lang = 'en-us';
		content.dir = 'ltr';
	} else {
		// change the content to original language
		localizedElements.forEach(elt => (elt.style.display = 'inline'));
		sxslookupElements.forEach(elt => (elt.style.display = 'none'));

		content.lang = originalLang;
		content.dir = originalDir;
	}
	notifyContentUpdated();
}

export function devSetupEnglishToggle(lang: string = 'pl-pl'): void {
	/*  this function is for development purposes only,
		uncomment it in index.ts to see the element
		you'll still need to add `bilingual_type: hover over` in content's yml metadata
	*/
	const content = document.querySelector('#main.content') as HTMLElement;
	msDocs.data.contentLocale = lang;
	content.lang = lang;

	const html = `  <li role="presentation" class="typeSep">|</li>
					<li>
						<div class="lang-toggle-container">
							<label for="language-toggle" aria-hidden="true" tabindex="-1">Read in English</label>
							<div class="checkbox-toggle-wrapper">
								<input type="checkbox" id="language-toggle" aria-label="Read in English" aria-enabled="false">
								<label for="language-toggle" class="switch"></label>
							</div>
						</div>
					</li>
					`;

	if (!document.querySelector('.lang-toggle-container')) {
		document.querySelector('.action-list').insertAdjacentHTML('beforeend', html);
	}
}

export function setEngToggleBi(elt: HTMLInputElement, english: boolean): void {
	// if toggle is off at page load, the element's attribute should be data-bi-language-to-on, otherwise just switch it
	const toggleOnOff = !english ? 'off' : 'on';
	elt.setAttribute('data-m', JSON.stringify({ cN: 'language-toggle', value: toggleOnOff }));
}

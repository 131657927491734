import { user } from '../../auth/user';
import { features } from '../../environment/features';
import { EventBus } from '../../event-bus';
import { scrollContentToTop } from '../../interactivity/action-panel';
import { TemplateResult } from '../../lit-html';
import type { TaxonomyLabel } from '../../name-maps/taxonomy';
import { getQueryStringMap } from '../../query-string';
import { PropertyChangedEvent } from '../../view-model';
import { AutoCompleteConfig, AutocompleteElement } from '../autocomplete';
import { facetSearchInputNameId } from './constants';
import { FacetChangeEvent, FacetChangeType } from './events';
import { getExpandedFacets, getSelectedFacets } from './facets';
import { createFacetSearchInput } from './input';
import type {
	FacetSearchResponse,
	FSFilterMap,
	FSFlatFacetMap,
	FSSelectedFacets,
	FSSelectedFacetType,
	FSUIFacetGroups,
	MobileMenuViewLabel
} from './model';
import { PagerNavigateEvent, SearchPaginationViewModel } from './pagination';
import { updateFacetSearchUrl } from './query';
import { localizeAndSort } from './sort';
import {
	rawFacetsToViewModels,
	readUrlExpandedFacets,
	readUrlSelectedFacets,
	saveFilterValues
} from './utils';
import { instrumentSearchResults } from './bi';
import { msDocs } from '../../globals';

/**
 * Facet search arguments - to provide universal I
 */
export interface UISearchArgs {
	scope: string | null;
	terms: string;
	selectedFacets: FSSelectedFacets;
	expandedFacets: FSFlatFacetMap;
	skip: number;
	top: number; // the number of max results to return
	hideCompleted?: boolean;
	dataSource?: string;
	// showHidden?: string;
	scoringProfile?: string | null;
	filters: FSFilterMap;
}

export interface SearchVMConfig<TResult> {
	/**
	 * Template the displays when there is no search state, and there's been no previous searches.
	 */
	blankTemplate?: (args: any) => TemplateResult;

	/**
	 * layout
	 */
	layout: 'grid' | 'list';
	/**
	 * The configuration object for the form's autocomplete element.
	 * If omitted, a normal input without autocomplete will be rendered.
	 */
	autocomplete?: AutoCompleteConfig<string>;

	/**
	 * Hide completed modules and learning paths on browse page.
	 */
	hideCompleted?: true;

	/**
	 * Heading level for the search results heading
	 */
	headingLevel?: 'h1' | 'h2';

	/**
	 * Include information about the APIs scoring profile.
	 * Use for testing on site search
	 */
	scoringProfile?: string | undefined;
	/**
	 * The template to render a single result within the results section.
	 */
	resultTemplate: (result: TResult, i?: number) => TemplateResult;

	/**
	 * Function to fetch search results, given a certain configuration of FacetSearchArgs.
	 * Fetch will need to include an adapter that takes UI arguments and transforms to fix each api
	 */
	fetch(args: UISearchArgs): Promise<FacetSearchResponse<TResult>>;

	/**
	 * Function to get RSS Url
	 */
	rss?(args: UISearchArgs): string;
}

export class SearchViewModel<TResult> extends EventBus {
	/**
	 * Promise to resolve after first fetch has been performed.
	 * Primarily for testing purposes.
	 */
	public initialized = new Promise(resolve => {
		this.initialize = resolve;
	});
	public pager: SearchPaginationViewModel;
	private _busy = false;
	private _dataSource: string = '';
	private _expandedFacets: FSFlatFacetMap = {};
	private _facetGroups: FSUIFacetGroups = {};
	private _fetch: (args: UISearchArgs) => Promise<FacetSearchResponse<TResult>>;
	private _filters: FSFilterMap = {};
	private _hideCompletedEnabled: boolean = false;
	private _input: AutocompleteElement<string> | HTMLInputElement;
	private _hideCompleted: boolean = false;
	private _mobileMenuView: MobileMenuViewLabel = 'top';
	private _resultCount: number;
	private _results: TResult[] = [];
	private _rss: (args: UISearchArgs) => string;
	private _rssEnabled: boolean = false;
	private _rssUrl: string;
	private _scope: UISearchArgs['scope'];
	private _scoringProfile: string | undefined;
	private _scoringProfileEnabled: boolean = false;
	private _selectedFacets: FSSelectedFacetType;
	private _skip: number;
	private _disallowBlankSlate = false;

	get selectedFacetsMinusCategory() {
		const selectedFacets = Object.assign({}, this._selectedFacets);
		if (selectedFacets.category) {
			delete selectedFacets.category;
		}
		return selectedFacets;
	}

	get mobileMenuView() {
		return this._mobileMenuView;
	}

	get resultsCount() {
		if (this.categoriesEnabled) {
			const selected = this.facetGroups.category.facets.find(f => f.isSelected);
			if (selected) {
				return selected.count;
			}
		}
		return this._resultCount;
	}
	get input() {
		return this._input;
	}
	get terms() {
		return this._input.value || '';
	}
	get scope() {
		return this._scope;
	}
	get results() {
		return this._results;
	}
	get busy() {
		return this._busy;
	}
	get facetGroups() {
		return this._facetGroups;
	}
	get selectedFacets() {
		return this._selectedFacets;
	}
	get expandedFacets() {
		return this._expandedFacets;
	}
	get hideCompleted() {
		return this._hideCompleted;
	}
	get hideCompletedEnabled() {
		return this._hideCompletedEnabled;
	}
	get scoringProfileEnabled() {
		return this._scoringProfileEnabled;
	}
	get scoringProfile() {
		return this._scoringProfile;
	}
	get rssEnabled() {
		return this._rssEnabled;
	}
	get rssUrl() {
		return this._rssUrl;
	}
	get dataSource() {
		return this._dataSource;
	}
	get showBlankSlate() {
		return (
			// artificially gate the blank view to search pages.
			msDocs.data.pageTemplate === 'SearchPage' &&
			!this.disallowBlankSlate &&
			(!this.results || this.results.length === 0) &&
			!this.busy &&
			this.isBlank
		);
	}
	get disallowBlankSlate() {
		return this._disallowBlankSlate;
	}
	set disallowBlankSlate(bool: boolean) {
		if (!this.disallowBlankSlate) {
			this._disallowBlankSlate = bool;
		}
	}
	get hasActiveFilter() {
		for (const f in this._selectedFacets) {
			if (this._selectedFacets[f as TaxonomyLabel]) {
				for (const k in this._selectedFacets[f as TaxonomyLabel]) {
					// for backwards compat with some older urls, all is equal to nothing
					if (k.toLowerCase() === 'all') {
						continue;
					}
					if (this._selectedFacets[f as TaxonomyLabel][k]) {
						return true;
					}
				}
			}
		}
		return false;
	}
	get isBlank() {
		return !this.hasActiveFilter && this.terms === '';
	}
	get categoriesEnabled() {
		return !!this.facetGroups.category;
	}
	get facetsEnabled() {
		// really just for seeing if we're on the review service
		for (const f in this._facetGroups) {
			if (this._facetGroups[f as TaxonomyLabel]) {
				return true;
			}
		}
		return false;
	}

	constructor(
		fetch: SearchVMConfig<TResult>['fetch'],
		private readonly _top: number,
		options: {
			autocomplete?: SearchVMConfig<TResult>['autocomplete'];
			hideCompleted?: SearchVMConfig<TResult>['hideCompleted'];
			scoringProfile?: SearchVMConfig<TResult>['scoringProfile'];
			rss?: SearchVMConfig<TResult>['rss'];
		} = {}
	) {
		super();
		this._fetch = fetch;

		if (options.hideCompleted && user.isAuthenticated) {
			this._hideCompletedEnabled = true;
		}

		this._scoringProfileEnabled = !!options.scoringProfile;
		if (this.scoringProfileEnabled) {
			this._scoringProfile = options.scoringProfile;
		}

		if (options.rss && features.rss) {
			this._rss = options.rss;
			this._rssEnabled = true;
		}

		this._input = createFacetSearchInput(async () => {
			await this.fetch();
			updateFacetSearchUrl(this.createSearchArgs());
		}, options.autocomplete);

		this.readState();

		this.fetch();

		updateFacetSearchUrl(this.createSearchArgs(), 'replaceState');
		window.addEventListener('popstate', () => this.handlePopState());
	}

	/**
	 * Handle submission of the search form.
	 * @param e Browser Event
	 */
	public async submit(e: Event) {
		e.preventDefault();

		const input = (e.target as HTMLFormElement).elements.namedItem(
			facetSearchInputNameId
		) as HTMLInputElement;
		this.input.value = input.value;
		this._skip = 0;

		this.fetch();
		updateFacetSearchUrl(this.createSearchArgs());
	}

	/**
	 * Set busy. Call internal _fetch function.
	 * Assign results to view model. Set ready.
	 */
	public async fetch() {
		this.setBusy();

		let includeCategory = false;

		try {
			const args = this.createSearchArgs(false);
			const [response, categorySpecificResponse] = await Promise.all([
				this._fetch(args),
				this.categoryFetch()
			]);
			includeCategory = !!categorySpecificResponse;
			// results
			this.updateResults(includeCategory ? categorySpecificResponse : response);
			this.updateFacets(response);
			this.updatePaging();
			this.updateRssUrl();
		} catch {
			this._results = [];
			this._facetGroups = {};
			this._resultCount = 0;
		} finally {
			if (this.results && this.results.length > 0) {
				this.disallowBlankSlate = true;
			}
			this.setReady();
			this.initialize();

			// After all the user-centric functionality, we send analytics.
			instrumentSearchResults(
				this.createSearchArgs(includeCategory),
				this._results.map(this.toUrl),
				this._resultCount,
				this.getSelectedCategoryName()
			);
		}
	}

	/**
	 * Fire a function when a facet has been selected or expanded.
	 * Passed into the Facet view models on their creation.
	 */
	public handleFacetChange(type: FacetChangeType) {
		switch (type) {
			case 'select':
				this._selectedFacets = getSelectedFacets(this.facetGroups);
				this._skip = 0;
				this.fetch();
				updateFacetSearchUrl(this.createSearchArgs());
				break;
			case 'expand':
				this._expandedFacets = getExpandedFacets(this.facetGroups);
				updateFacetSearchUrl(this.createSearchArgs());
				this.notifyPropertyChanged();
				break;
			case 'filter':
				this._filters = saveFilterValues(this.facetGroups);
				updateFacetSearchUrl(this.createSearchArgs(), 'replaceState');
				this.notifyPropertyChanged();
				break;
			default:
				break;
		}
	}

	public clearFacets() {
		if (this.hideCompletedEnabled) {
			this._hideCompleted = false;
		}
		for (const f in this.facetGroups) {
			this._selectedFacets[f as TaxonomyLabel] = {};
		}
		this._skip = 0;
		this.fetch();
		updateFacetSearchUrl(this.createSearchArgs());
	}

	public selectMobileView(view: MobileMenuViewLabel) {
		this._mobileMenuView = view;
		this.notifyPropertyChanged();
	}

	public clearScope() {
		this._scope = null;
		this.fetch();
		updateFacetSearchUrl(this.createSearchArgs());
		this.notifyPropertyChanged();
	}

	public notifyPropertyChanged(): void {
		this.publish(new PropertyChangedEvent());
	}

	public toggleHideCompleted() {
		if (!this.hideCompletedEnabled) {
			return;
		}
		this._hideCompleted = !this._hideCompleted;
		this._skip = 0;
		this.fetch();
		updateFacetSearchUrl(this.createSearchArgs());
	}

	private initialize() {}

	/**
	 * Fetch results with the selected category included filter args.
	 * Happens when categories are enabled, and a category is selected.
	 * This is considerably easier than caching count differences
	 * And how deciding display them in category facets.
	 */
	private async categoryFetch() {
		if (this.getSelectedCategoryName()) {
			return await this._fetch(this.createSearchArgs());
		}
		return Promise.resolve(undefined);
	}

	/**
	 * Return the name of the selected category or an empty string
	 */
	private getSelectedCategoryName() {
		if (!this.categoriesEnabled) {
			return '';
		}
		const s = this.facetGroups.category.facets.find(f => f.isSelected);
		return s?.name || '';
	}

	private updateResults(response: FacetSearchResponse<TResult>) {
		this._results = response.results;
		this._resultCount =
			response.count < this._top && this._skip < response.count
				? response.results.length
				: response.count;
	}

	private updateFacets(response: FacetSearchResponse<TResult>) {
		if (!response.facets) {
			return;
		}
		const rawFacets = localizeAndSort(response.facets || {});
		this._facetGroups = rawFacetsToViewModels(
			rawFacets,
			this.selectedFacets,
			this.expandedFacets,
			this._filters
		);
		this.subscribeFacetChanges();
	}

	private updatePaging() {
		if (this.pager) {
			this.pager.dispose();
		}
		this.pager = new SearchPaginationViewModel(this._resultCount, this._top, this._skip);
		this.pager.subscribe(PagerNavigateEvent, e => {
			this._skip = e.skip;
			this.fetch();
			updateFacetSearchUrl(this.createSearchArgs());
			scrollContentToTop();
		});
	}

	private updateRssUrl() {
		if (!this._rssEnabled) {
			return;
		}
		this._rssUrl = this._rss(this.createSearchArgs());
	}

	private createSearchArgs(includeCategory = true): UISearchArgs {
		return {
			terms: this.terms,
			scope: this.scope,
			selectedFacets: includeCategory ? this._selectedFacets : this.selectedFacetsMinusCategory,
			expandedFacets: this._expandedFacets,
			skip: this._skip || null,
			top: this._top,
			hideCompleted: this.hideCompletedEnabled ? this.hideCompleted : null,
			scoringProfile: this.scoringProfileEnabled ? this.scoringProfile : null,
			dataSource: this.dataSource ? this.dataSource : null,
			filters: this._filters
		};
	}

	private setBusy() {
		this._busy = true;
		this.notifyPropertyChanged();
	}

	private setReady() {
		this._busy = false;
		this.notifyPropertyChanged();
	}

	private readState() {
		const query = getQueryStringMap();

		// overwrite any default properties with those in the query
		this.input.value = query.terms || query.search || query.term || '';
		this._scope = query.scope || null;
		this._selectedFacets = readUrlSelectedFacets(query);
		this._expandedFacets = readUrlExpandedFacets(query);
		this._dataSource = query.dataSource;

		if (this.hideCompletedEnabled && query.hideCompleted) {
			this._hideCompleted = true;
		}

		try {
			this._skip = query.skip ? parseInt(query.skip) : 0;
		} catch {
			this._skip = 0;
		}
	}

	/**
	 * Handle for popstate event
	 * Reads state from URL,
	 * Must manually sync input value,
	 * Gets new results
	 */
	private handlePopState() {
		this.readState();
		this.fetch();
	}

	private subscribeFacetChanges() {
		for (const name in this._facetGroups) {
			const group = this._facetGroups[name as TaxonomyLabel];
			group.subscribe(FacetChangeEvent, e => this.handleFacetChange(e.type));
		}
	}

	/**
	 * A mapping function to take a result and return its url property.
	 * Used prior to instrumentation. Extend via config in the future if required.
	 */
	private toUrl = (x: any) => x.url;
}

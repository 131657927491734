import { contentAttrs } from '../bi';
import { document, window } from '../globals';
import { loc_clearterm, loc_search } from '@msdocs/strings';
import { apiSearchTermChangedEvent, getTerm, setTerm } from './term';

export const blockName = 'api-search-field';

export function createSearchField() {
	const form = document.createElement('form');
	form.classList.add(blockName);
	form.setAttribute(contentAttrs.name, blockName);
	form.action = 'javascript:';
	form.addEventListener('submit', event => event.preventDefault());

	const label = document.createElement('label');
	const labelSpan = document.createElement('span');
	labelSpan.classList.add('visually-hidden');
	labelSpan.textContent = loc_search;
	label.appendChild(labelSpan);
	form.appendChild(label);

	const input = document.createElement('input');
	input.type = 'search';
	input.classList.add('input');
	input.value = getTerm();
	input.placeholder = loc_search;
	label.appendChild(input);

	const clearAnchor = document.createElement('a');
	clearAnchor.href = '#';
	clearAnchor.title = loc_clearterm;
	clearAnchor.classList.add('clear');
	clearAnchor.addEventListener('click', event => {
		event.preventDefault();
		input.value = '';
		input.dispatchEvent(new CustomEvent('change', { bubbles: true }));
	});
	label.appendChild(clearAnchor);

	const updateEmpty = () => {
		if (input.value === '') {
			input.classList.add('empty');
		} else {
			input.classList.remove('empty');
		}
	};

	updateEmpty();

	// debounce user input
	let timeout = 0;
	const handleInput = () => {
		updateEmpty();
		clearTimeout(timeout);
		timeout = setTimeout(() => setTerm(input.value), 500);
	};
	input.addEventListener('change', handleInput);
	input.addEventListener('input', handleInput);

	window.addEventListener(apiSearchTermChangedEvent, () => {
		const term = getTerm();
		if (input.value.trim() === term) {
			return;
		}
		input.value = term;
		updateEmpty();
	});

	return form;
}

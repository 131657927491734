import { EventBus } from '../../event-bus';
import { TaxonomyLabel } from '../../name-maps/taxonomy';
import { FacetChangeEvent, FacetChangeType } from './events';
import type { FacetGroupViewModel } from './facet-group';
import type { FSFlatFacetMap, FSSelectedFacetType, FSUIFacetGroups } from './model';

export class FacetViewModel extends EventBus {
	/**
	 * Child facets.
	 */
	public children: FacetViewModel[] = [];
	/**
	 * Helper to determine this element's child array has items.
	 */
	get hasChildren() {
		return this.children.length > 0;
	}
	/**
	 * Whether this facet view model has a child that is selected.
	 */
	get hasSelectedChild() {
		return !this.isSelected && !!this.children.find(child => child.isSelected);
	}
	/**
	 * Whether or not this element was explicitly selected.
	 */
	get isSelected() {
		return this._isSelected;
	}
	/**
	 * Whether or not this element should display as checked.
	 */
	get isChecked() {
		return this.parent?._isSelected || this.isSelected;
	}
	/**
	 * The CSS class that should be placed on the element.
	 * Blue for explicitly selected. Grey for child of explictly selected.
	 */
	get inputClass() {
		if (this.parent && this.parent.isSelected) {
			return 'is-secondary';
		} else if (this.isSelected) {
			return 'is-primary';
		}
		return '';
	}
	/**
	 * Whether or not this child has children and has been explicitly expanded by user or has children selected by user.
	 */
	get isExpanded() {
		return (
			this.group.filterActive || (this.hasChildren && this._isExpanded) || this.hasSelectedChild
		);
	}
	/**
	 * Whether the current facet should be hidden.
	 */
	get hidden() {
		if (this.group.filterActive && this.hasVisibleChild) {
			return false;
		}
		if (this.isFiltered) {
			return true;
		}
		if (this.parent && !this.parent.isExpanded) {
			return true;
		}

		return false;
	}
	get isFiltered() {
		return (
			this.group.filterActive &&
			!this.name.toLocaleLowerCase().includes(this.group.filter.toLocaleLowerCase())
		);
	}
	get hasVisibleChild() {
		return this.hasChildren && !!this.children.find(c => !c.hidden);
	}

	/**
	 * Create an individual Facet's View Model
	 * @param label The parent item from the Taxonomy. Ex: 'languages'.
	 * @param name The display name of the facet.
	 * @param value The value of the facet.
	 * @param _isSelected Whether or not this facet starts as selected.
	 * @param level Since facets can be nested, the nesting level (aria-level).
	 * @param position Since facets are large arrays, facet's position in array.
	 * @param group An instance of a FacetGroupViewModel, keeps track of facets and subscribes to their changes.
	 * @param parent The parent facet. Optional.
	 */
	constructor(
		public readonly label: TaxonomyLabel,
		public readonly count: number,
		public readonly name: string,
		public readonly value: string,
		private _isSelected: boolean,
		private _isExpanded: boolean,
		public readonly level: number,
		public readonly position: number,
		public group: FacetGroupViewModel,
		public parent?: FacetViewModel | undefined // public for testing
	) {
		super();
	}

	/**
	 * Call this function when the input/label is clicked.
	 * Toggles the facets's selected state.
	 * Call the Search class's update function.
	 */
	public toggleSelected() {
		if (this.isSelected) {
			this.deselect();
		} else {
			this.select();
		}
		this.notifyFacetChange('select');
	}

	public toggleExpanded() {
		if (this._isExpanded) {
			this.collapse();
		} else {
			this.expand();
		}
		this.notifyFacetChange('expand');
	}

	private deselect() {
		this._isSelected = false;
	}

	private select() {
		// if the parent facet is selected, deselect it first
		if (this.parent?.isSelected) {
			this.parent.deselect();
		}

		if (this.children.length > 0) {
			for (const child of this.children) {
				child.deselect();
			}
		}

		this._isSelected = true;
	}

	private expand() {
		if (this.hasChildren) {
			this._isExpanded = true;
		}
	}

	private collapse() {
		this._isExpanded = false;
	}

	private notifyFacetChange(type: FacetChangeType) {
		this.publish(new FacetChangeEvent(type));
	}
}

/**
 * Distill all facet view models down into an object that indicates which are selected.
 * @param facets Object containing all FacetSearch's Facet View Models
 */
export function getSelectedFacets(facets: FSUIFacetGroups): FSSelectedFacetType {
	const selected: FSSelectedFacetType = {};
	for (const facetLabel in facets) {
		const label = facetLabel as TaxonomyLabel;
		const group = facets[label];
		selected[label] = group.selected;
	}
	return selected;
}

/**
 * Distill all facet view models down into an flattened object that indicates which are expanded.
 * @param facets Object containing all FacetSearch's Facet View Models
 */
export function getExpandedFacets(facets: FSUIFacetGroups): FSFlatFacetMap {
	let expanded: FSFlatFacetMap = {};
	for (const facetName in facets) {
		const label = facetName as TaxonomyLabel;
		const group = facets[label];

		expanded = {
			...expanded,
			...group.expanded
		};
	}
	return expanded;
}

export function insertRouterProgress() {
	const element = document.createElement('progress');
	element.classList.add(
		'is-full-width',
		'is-fixed',
		'has-top-zero',
		'has-left-zero',
		'has-right-zero',
		'has-margin-bottom-none',
		'is-extra-small',
		'progress'
	);
	element.setAttribute('max', '100');
	element.style.zIndex = '10';
	document.body.insertAdjacentElement('afterbegin', element);
	return element;
}

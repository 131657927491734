import { localStorage } from './protected-storage';

interface TransientObject {
	value: string;
	expiry: number;
}

interface TransientStorage {
	clear(): void;
	getItem(key: string): string | null;
	removeItem(key: string): void;
	setItem(key: string, value: string, expiry: Date): void;
}

export const transientLocalStorage = {
	setItem(key: string, value: string, expiry: Date): void {
		try {
			const expiryNumber = expiry.getTime();
			if (Date.now() >= expiryNumber) {
				return;
			}

			const timedValue = {
				value,
				expiry: expiryNumber
			};
			localStorage.setItem(key, JSON.stringify(timedValue));
		} catch (e) {}
	},

	getItem(key: string): string | null {
		try {
			const timedValue = localStorage.getItem(key);
			if (!timedValue) {
				return null;
			}
			const item = JSON.parse(timedValue) as TransientObject;
			if (typeof item.expiry !== 'number' || isNaN(item.expiry) || Date.now() >= item.expiry) {
				localStorage.removeItem(key);
				return null;
			}

			return item.value;
		} catch (e) {
			return null;
		}
	},

	removeItem(key: string): void {
		localStorage.removeItem(key);
	},

	clear(): void {
		localStorage.clear();
	}
} as TransientStorage;

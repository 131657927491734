import { TaxonomyLabel, taxonomyNames } from '../../name-maps/taxonomy';
import { updateQueryString } from '../../query-string';
import type { UISearchArgs } from './view-model';

export function stateToQueryMap(state: UISearchArgs): { [key: string]: string } {
	const map: { [key: string]: string } = {};

	map.scope = state.scope || null;
	map.terms = state.terms || null;
	map.top = null;
	map.skip = state.skip && state.skip !== 0 ? state.skip.toString() : null;
	map.hideCompleted = state.hideCompleted ? state.hideCompleted.toString() : null;
	map.dataSource = state.dataSource || null;

	if (state.selectedFacets) {
		for (const f in state.selectedFacets) {
			map[f] = Object.keys(state.selectedFacets[f as TaxonomyLabel]).join(',');
		}
	}

	if (state.expandedFacets) {
		map.expanded = Object.keys(state.expandedFacets).join(',');
	}

	for (const f in taxonomyNames) {
		const keyName = `filter-${f}`;
		const exists = f in state.filters;
		map[keyName] = exists ? state.filters[f as TaxonomyLabel] : null;
	}

	// Ensure that search is never placed back in the URL
	map.search = null;

	return map;
}

export function updateFacetSearchUrl(
	args: UISearchArgs,
	method: 'replaceState' | 'pushState' = 'pushState'
) {
	const urlMap = stateToQueryMap(args);
	updateQueryString(urlMap, method);
}

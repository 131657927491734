import {
	loc_portallab_disabled,
	loc_portallab_launchButton,
	loc_portallab_loading,
	loc_portallab_prompt1,
	loc_portallab_prompt2,
	loc_portallab_ready,
	loc_portallab_releaseButton,
	loc_portallab_releasePrompt1,
	loc_portallab_releasePrompt2,
	loc_portallab_retryButton,
	loc_portallab_signInButton,
	loc_portallab_startButton,
	loc_somethingWentWrong,
	loc_vmlab_launchButton,
	loc_vmlab_loading,
	loc_vmlab_prompt1,
	loc_vmlab_prompt2,
	loc_vmlab_releaseButton,
	loc_vmlab_releasePrompt1,
	loc_vmlab_releasePrompt2,
	loc_vmlab_retryButton,
	loc_vmlab_signInButton
} from '@msdocs/strings';
import * as labApi from '../apis/lab-on-demand';
import { authStatusDetermined } from '../auth/index';
import { signInClassName } from '../auth/sign-in-links';
import { user } from '../auth/user';
import { parseQueryString, toQueryString, updateQueryString } from '../query-string';
import { escape } from '../text-formatting';
import { isLabDisabled } from '../unit/module-availability';
import { getCurrentModule } from './module';
import { html, render } from '../lit-html';
import { labRiskPrompts } from '../risk-evaluation/risk-prompts';

export const stateAttr = 'data-ux-state'; // used by unit tests to verify state of lab prompt
export const state = {
	disabled: 'disabled',
	finished: 'finished',
	launchPrompt: 'launch-prompt',
	modalPrompt: 'modal-prompt',
	signInPrompt: 'sign-in',
	spinner: 'spinner',
	releasePrompt: 'release-prompt',
	retryPrompt: 'retry-prompt',
	riskError: 'risk-error'
};

const labArgName = 'launch-lab';

export async function promptLab(
	id: number,
	isModal: boolean,
	container: Element,
	promptLaunch = true
) {
	container.setAttribute('data-bi-name', 'lab-prompt');
	renderSpinner(container, isModal);
	promptLaunch = promptLaunch && !shouldSkipLaunchPrompt();

	const module = await getCurrentModule();

	const isDisabled = await isLabDisabled(module.uid);
	if (isDisabled) {
		renderDisabled(container);
	}

	await authStatusDetermined;
	if (!user.isAuthenticated) {
		await promptToSignIn(container, isModal);
		renderSpinner(container, isModal);
	}

	const lab = await labApi.get();

	if (lab.Error) {
		await promptToRetry(container, isModal, lab.Error);
		promptLab(id, isModal, container, false);
	}

	if (promptLaunch) {
		await promptToLaunch(container, isModal);
		renderSpinner(container, isModal);
	}

	const isRunningLab = lab.RunningLabs.find(x => x.LabProfileId === id);
	let url: string;

	if (isRunningLab) {
		url = isRunningLab.Url;
	} else {
		let labDetails = await labApi.post(id);

		// User risk evaluation
		if (labDetails.hasError === true) {
			container.setAttribute(stateAttr, state.riskError);
			await labRiskPrompts(labDetails.error.errorCode, container, id);
			promptLab(id, isModal, container, false);
			labDetails = await labApi.post(id);
		}

		const isSavedLab = lab.SavedLabs.find(x => x.LabProfileId === id);

		if (!isSavedLab && (lab.RunningLabs.length || lab.SavedLabs.length)) {
			await promptToReleaseLabs(container, isModal, lab);
			renderSpinner(container, isModal);
			labDetails = await labApi.post(id);
		} else {
			renderSpinner(container, isModal);
		}

		// if there are no errors set the url
		if (labDetails.hasError === false && !labDetails.lab.Error) {
			url = labDetails.lab.Url;
		}
	}

	// fail catch
	if (!url) {
		await promptToRetry(container, isModal, loc_somethingWentWrong);
		promptLab(id, isModal, container, false);
	}

	if (isModal) {
		promptModalLab(container, id, url);
	} else {
		renderSpinner(container, isModal);
		container.setAttribute(stateAttr, state.finished);
	}

	return url;
}

function shouldSkipLaunchPrompt() {
	const args = parseQueryString(location.search);
	if (args[labArgName] === 'true') {
		args[labArgName] = null;
		updateQueryString(args, 'replaceState');
		return true;
	}
	return false;
}

function createReturnUrl() {
	const args = parseQueryString(location.search);
	args[labArgName] = 'true';
	return `${location.protocol}//${location.hostname}${location.pathname}?${toQueryString(args)}${
		location.hash
	}`;
}

function renderSpinner(container: Element, isModal: boolean) {
	container.setAttribute(stateAttr, state.spinner);
	const message = isModal ? loc_portallab_loading : loc_vmlab_loading;
	const spinnerHtml = html`<p>
		<span class="loader"></span>
		<span class="has-padding-left-small has-padding-right-small">${message}</span>
	</p>`;

	render(spinnerHtml, container);
}

function renderDisabled(container: Element) {
	container.setAttribute(stateAttr, state.disabled);
	const disabledHtml = html`<p>${loc_portallab_disabled}</p>`;

	render(disabledHtml, container);
}

function promptToSignIn(container: Element, isModal: boolean) {
	container.setAttribute(stateAttr, state.signInPrompt);
	const prompt1 = isModal ? loc_portallab_prompt1 : loc_vmlab_prompt1;
	const prompt2 = isModal ? loc_portallab_prompt2 : loc_vmlab_prompt2;
	const buttonTitle = isModal ? loc_portallab_signInButton : loc_vmlab_signInButton;
	const signInHtml = html`<p>${prompt1}</p>
		<p>${prompt2}</p>
		<p><a class="button ${signInClassName}" href="${createReturnUrl()}">${buttonTitle}</a></p>`;

	render(signInHtml, container);
	return user.whenAuthenticated();
}

function promptToLaunch(container: Element, isModal: boolean) {
	container.setAttribute(stateAttr, state.launchPrompt);
	const prompt1 = isModal ? loc_portallab_prompt1 : loc_vmlab_prompt1;
	const prompt2 = isModal ? loc_portallab_prompt2 : loc_vmlab_prompt2;
	const buttonTitle = isModal ? loc_portallab_launchButton : loc_vmlab_launchButton;
	const launchLabHtml = html`<p>${prompt1}</p>
		<p>${prompt2}</p>
		<p><button type="button" class="button" data-bi-name="activate">${buttonTitle}</button></p>`;

	render(launchLabHtml, container);
	return new Promise<void>(
		resolve => (container.querySelector('button').onclick = () => resolve())
	);
}

function promptToReleaseLabs(
	container: Element,
	isModal: boolean,
	result: labApi.LabGetResult
): Promise<void> {
	container.setAttribute(stateAttr, state.releasePrompt);

	const labs = [...result.RunningLabs, ...result.SavedLabs];

	const prompt1 = isModal ? loc_portallab_releasePrompt1 : loc_vmlab_releasePrompt1;
	let prompt2 = isModal ? loc_portallab_releasePrompt2 : loc_vmlab_releasePrompt2;
	prompt2 = prompt2.replace('{lab-name}', labs[0].LabProfileName);
	const buttonTitle = isModal ? loc_portallab_releaseButton : loc_vmlab_releaseButton;
	const releaseLabsHtml = html`<p>${prompt1}</p>
		<p>${prompt2}</p>
		<p><button type="button" class="button" data-bi-name="release">${buttonTitle}</button></p>`;

	render(releaseLabsHtml, container);
	return new Promise<void>((resolve, reject) => {
		container.querySelector('button').onclick = () => {
			renderSpinner(container, isModal);
			Promise.all(labs.map(x => labApi.remove(x.LabInstanceId))).then(() => resolve(), reject);
		};
	});
}

function promptToRetry(container: Element, isModal: boolean, errorMessage: string): Promise<void> {
	container.setAttribute(stateAttr, state.retryPrompt);
	const buttonTitle = isModal ? loc_portallab_retryButton : loc_vmlab_retryButton;
	const retryHtml = html`<p>${errorMessage ? escape(errorMessage) : loc_somethingWentWrong}</p>
		<p><button type="button" class="button" data-bi-name="retry">${buttonTitle}</button></p>`;

	render(retryHtml, container);
	return new Promise<void>(
		resolve => (container.querySelector('button').onclick = () => resolve())
	);
}

function promptModalLab(container: Element, id: number, url: string) {
	container.setAttribute(stateAttr, state.modalPrompt);
	const modalLabHtml = html`<p>${loc_portallab_ready}</p>
		<p>
			<button type="button" class="button" data-bi-name="activate-modal">
				${loc_portallab_startButton}
			</button>
		</p>`;

	render(modalLabHtml, container);
	const target = `lab${id}`;
	const features = 'width=800,height=600,status=0,resizable=1,toolbar=0,menubar=0,scrollbars=0';
	container.querySelector('button').onclick = () => window.open(url, target, features);
}

import {
	loc_close,
	loc_tooManyAttempts,
	loc_tryAgain,
	loc_yourWorkNotCompletedCorrectly
} from '@msdocs/strings';
import { html, render } from '../lit-html';
import { Modal } from '../components/modal/modal';

let modal: Modal;

export function tooManyAttemptsModal() {
	if (!modal) {
		const errorBodyHtml = `<p id="taskModalBody" class="task-body has-margin-top-medium">${loc_yourWorkNotCompletedCorrectly}</p>`;
		const modalTitle = `<p id="taskModalTitle" class="task-title is-size-2">${loc_tooManyAttempts}</p>`;
		const tooManyAttemptsModal = document.createElement('div');
		render(
			html`
				<div class="modal-card fill-mobile">
					<div class="modal-card-head has-padding-small">
						<button
							class="modal-close modal-card-close-button is-large"
							data-bi-name="close"
							aria-label="${loc_close}"
						></button>
					</div>
					<section
						class="modal-card-body has-padding-top-none has-flex-justify-content-center has-text-centered has-padding-bottom-none"
					>
						${modalTitle} ${errorBodyHtml}
					</section>
					<div
						class="modal-card-foot has-flex-justify-content-center has-padding-bottom-extra-large"
					>
						<button class="task-modal-incorrect modal-close button is-primary" autofocus>
							<span class="task-button-text">${loc_tryAgain}</span>
							<span class="icon docon docon-chevron-right-light"></span>
						</button>
					</div>
				</div>
			`,
			tooManyAttemptsModal
		);
		modal = new Modal(tooManyAttemptsModal);
	}

	modal.contentElement.classList.remove('shake-in');
	modal.show();
	modal.contentElement.classList.add('shake-in');
}

export class TooManyAttemptsEvent {}

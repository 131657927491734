import {
	loc_completeModuleMessage,
	loc_editYourRating,
	loc_rated,
	loc_rateIt,
	loc_yourRating
} from '@msdocs/strings';
import { LearningPath, Module } from '../apis/learn';
import { PageRatingBatch, ratingApi, UserRating, UserRatingResult } from '../apis/rating';
import { user } from '../auth/user';
import { jsllReady } from '../bi';
import { Modal } from '../components/modal/modal';
import { features } from '../environment/features';
import { RatingFeedback, reportRatingVerbatim } from '../feedback-report/rating';
import { msDocs } from '../globals';
import { isReview } from '../is-production';
import { getMeta } from '../meta';
import { createCompletionDropdown } from '../rating/completion-dropdown';
import {
	createDisabledRatingButton,
	createRatedText,
	createRatingButton
} from '../rating/rating-button';
import { createRatingCard } from '../rating/rating-card';
import { calculateRatingFields, createRatingDropdown } from '../rating/rating-dropdown';
import { createRatingLink } from '../rating/rating-link';
import { createRatingModal } from '../rating/rating-modal';
import { createRatingControl } from '../rating/star-rating-control';
import { createRatingDisplay, updateRatingDisplayScore } from '../rating/star-rating-display';
import { getSurveyQuestions } from '../rating/survey-questions';
import { createThanksCard } from '../rating/thanks-card';
import { UnitProgressConfig } from '../unit/config';
import { createSharingLinks } from './sharing-links';

let isRatingDialogVisible = false;

export async function displayModuleRating(container: HTMLElement, module: Module) {
	if (!features.starRatings) {
		return;
	}

	const pageRatingContainer = container.querySelector<HTMLElement>('.page-rating');
	pageRatingContainer.classList.add('level', 'is-mobile');
	const pageRatingLevelLeft = document.createElement('div');
	pageRatingLevelLeft.classList.add('level-left');
	pageRatingContainer.appendChild(pageRatingLevelLeft);

	if (pageRatingContainer === null) {
		return;
	}

	// Get page average rating for this module
	const pageRatingResponse = await ratingApi.getPageRating(module.uid, msDocs.data.userLocale);

	// Display page rating (with popover)
	let ratingPopover = createRatingDropdown(pageRatingResponse);
	pageRatingLevelLeft.appendChild(ratingPopover);

	await user.whenAuthenticated();

	// Below are only for sign-in user, display user rating related info
	const userRatingResponse = await ratingApi.getUserRating(module.uid, msDocs.data.userLocale);
	const onRateThisModuleCallback = async () => {
		if (isRatingDialogVisible) {
			return;
		}

		const surveyQuestions = await getSurveyQuestions();
		const ratingModal = createRatingModal(
			userRatingResponse.ratingScore,
			onSubmitCallback,
			createSharingLinksCallback,
			surveyQuestions
		);

		isRatingDialogVisible = true;

		ratingModal.show();

		isRatingDialogVisible = false;

		async function onSubmitCallback(userRating: UserRating, ratingFeedbackData: RatingFeedback) {
			if (ratingFeedbackData) {
				reportRatingVerbatim(ratingFeedbackData);
			}

			if (isReview) {
				//todo: temp fix for 65872: Don't post data to the production service if the rating is submitted on the review site
				const { thanksCardContainer } = createThanksCard();
				ratingModal.updateContent(thanksCardContainer, false);
				return;
			}

			const putUserRatingResponse = await ratingApi.putUserRating(userRating, module.uid);
			userRatingResponse.ratingScore = putUserRatingResponse.ratingScore;

			if (userRatingResponse.ratingScore !== 0) {
				// Remove previous rating popover when user gives a new rating.
				pageRatingLevelLeft.removeChild(pageRatingLevelLeft.lastChild);
				pageRatingLevelLeft.removeChild(pageRatingLevelLeft.lastChild);

				// Create a new rating popover with new rating and user rating info.
				ratingPopover = createRatingDropdown(pageRatingResponse);
				pageRatingLevelLeft.appendChild(ratingPopover);
				displayYourRatingInfo(container, userRatingResponse, onRateThisModuleCallback);

				// Show RATED text instead of "Rate it".
				const ratedHtml = createRatedText(loc_rated);
				pageRatingLevelLeft.appendChild(ratedHtml);
			}

			const { thanksCardContainer } = createThanksCard();
			ratingModal.updateContent(thanksCardContainer, false);

			const userRatingDisplayContainer = container.querySelector<HTMLElement>(
				'.dropdown-menu .star-rating-display'
			);
			updateRatingDisplayScore(userRatingDisplayContainer, putUserRatingResponse.ratingScore);
		}

		function createSharingLinksCallback() {
			return createSharingLinks(module.url, module.title);
		}
	};

	const onCompleteThisModuleCallback = () => {
		createCompletionDropdown();
	};

	if (module.status === 'completed') {
		if (userRatingResponse.ratingScore !== 0) {
			// Show RATED text if user has completed the module and voted.
			const ratedHtml = createRatedText(loc_rated);
			pageRatingLevelLeft.appendChild(ratedHtml);

			// Display your rating in rating popover
			displayYourRatingInfo(container, userRatingResponse, onRateThisModuleCallback);
		} else {
			// Show active rating button if user has completed the module but did not vote yet.
			const ratingButton = createRatingButton(onRateThisModuleCallback, loc_rateIt);
			pageRatingLevelLeft.appendChild(ratingButton);
		}
	} else {
		// Show disabled rating button if user has not completed the module.
		const disabledRatingButton = createDisabledRatingButton(
			onCompleteThisModuleCallback,
			loc_rateIt,
			loc_completeModuleMessage
		);
		pageRatingLevelLeft.appendChild(disabledRatingButton);
	}
}

export function displayYourRatingInfo(
	container: HTMLElement,
	userRatingResponse: UserRatingResult,
	onRateThisModuleCallback: () => void
) {
	const ratingPopoverMenu = container.querySelector('.dropdown-menu');
	if (userRatingResponse.ratingScore !== 0) {
		// User rated before: display user rating in rating popover
		const ratingDisplay = createRatingDisplay(
			userRatingResponse.ratingScore,
			1,
			false,
			`${loc_yourRating}:`
		);
		// Show edit rating link
		const editRatingLink = createRatingLink(onRateThisModuleCallback, loc_editYourRating);
		ratingPopoverMenu.appendChild(ratingDisplay);
		ratingPopoverMenu.appendChild(editRatingLink);
	}
}

export async function displayModuleRatingsForLearningPathPage(
	container: HTMLElement,
	learningPath: LearningPath
) {
	const pages = [];
	for (const module of learningPath.modules) {
		const moduleContainer = container.querySelector(
			`#${module.uid.replace(/\./g, '\\.')} .module-units`
		);
		if (moduleContainer !== null && module.ratingEnabled) {
			pages.push({ pageId: module.uid });
		}
	}

	const pageRatingBatch = {
		pages,
		locale: msDocs.data.userLocale,
		pageId: learningPath.uid
	} as PageRatingBatch;

	const pageRatingBatchResponse = await ratingApi.getBatchPageRating(
		msDocs.data.userLocale,
		pageRatingBatch
	);

	for (const pageRatingResult of pageRatingBatchResponse.ratings) {
		const pageId = pageRatingResult.pageId;
		const ratingContainer = container.querySelector(
			`#${pageId.replace(/\./g, '\\.')} .page-rating`
		);
		if (ratingContainer !== null) {
			const { averageScore, totalCount } = calculateRatingFields(pageRatingResult);
			if (totalCount >= 5) {
				const ratingDisplay = createRatingDisplay(averageScore, totalCount);
				ratingContainer.appendChild(ratingDisplay);
			}
		}
	}
}

export function displayModuleRatingControl(
	unitUpdatedConfig: UnitProgressConfig,
	modal: Modal,
	learningPath?: LearningPath
) {
	if (!features.starRatings) {
		return;
	}

	if (!unitUpdatedConfig.module.ratingEnabled || !user.isAuthenticated) {
		return;
	}

	const ratingControl = createRatingControl(0, 3, 5, 'is-centered', true, onRatingCallback);
	const ratingSection = modal.contentElement.querySelector('.rating-container');
	const {
		module: { uid }
	} = unitUpdatedConfig;

	async function onSubmitCallback(userRating: UserRating, ratingFeedbackData: RatingFeedback) {
		if (ratingFeedbackData) {
			reportRatingVerbatim(ratingFeedbackData);
		}

		if (isReview) {
			//todo: temp fix for 65872: Don't post data to the production service if the rating is submitted on the review site
			const { thanksCardContainer } = createThanksCard(unitUpdatedConfig, learningPath);
			modal.updateContent(thanksCardContainer, false);
			return;
		}

		ratingApi.putUserRating(userRating, uid).then(() => {
			const { thanksCardContainer } = createThanksCard(unitUpdatedConfig, learningPath);
			modal.updateContent(thanksCardContainer, false);
		});
	}

	getSurveyQuestions(); //eager load

	async function onRatingCallback(score: number) {
		const isFirstTimeRating = score === 0; // if score is 0 it is first time rating

		const userRating: UserRating = {
			pageType: 'learn',
			pageKind: 'module',
			locale: msDocs.data.userLocale,
			ratingScore: score,
			questionAndAnswers: [],
			metadata: {
				ratingLocation: 'module_completion_page',
				moduleProgress: 1
			}
		};

		if (!isReview) {
			jsllReady.then(function (awa) {
				awa.ct.captureContentPageAction({
					behavior: awa.behavior.OTHER,
					actionType: awa.actionType.OTHER,
					content: {
						type: 'rating',
						uid: getMeta('uid'), //module id
						score: userRating.ratingScore, //  number 1 to 5
						isNew: isFirstTimeRating, // boolean, true or false
						reasons: [], // an array of answer ids.
						verbatim: null
					}
				});
			});
		}

		const showRatingModal = async () => {
			const selectedRatingInput = ratingControl.querySelector<HTMLInputElement>(
				`input[type=radio]:checked`
			);
			const score = parseInt(selectedRatingInput.value);

			const surveyQuestions = await getSurveyQuestions();
			const ratingCard = createRatingCard(score, onSubmitCallback, surveyQuestions);
			modal.updateContent(ratingCard);

			// animate the close button to black
			const closeButton = document.querySelector('.modal-card-close-button');
			closeButton.classList.add('has-color-transition');
			closeButton.classList.remove('has-text-primary-invert');
		};

		const handleRatingClick = async () => {
			const hintElement = ratingSection.querySelector<HTMLElement>('.rating-hint');
			if (hintElement !== null) {
				// Come from unlock achievement modal when rating by clicking star
				showRatingModal();
				return;
			}

			const rateButton = ratingSection.querySelector('button');
			rateButton.onclick = async () => {
				showRatingModal();
			};
		};

		if (isReview) {
			//todo: temp fix for 65872: Don't post data to the production service if the rating is submitted on the review site
			handleRatingClick();
		} else {
			ratingApi.putUserRating(userRating, uid).then(() => {
				handleRatingClick();
			});
		}
	}

	ratingSection.appendChild(ratingControl);
}

import { contentLoaded } from '../globals';
import { UnitPageConfig } from './config';

export function handleChromelessNextSectionButton(config: UnitPageConfig) {
	const { isChromeless } = config;
	if (isChromeless) {
		contentLoaded.then(() =>
			document
				.getElementById('next-section')
				.classList.remove('is-hidden-mobile', 'is-hidden-portrait')
		);
	}
}

export function displayMobileNavInChromelessMode(
	{ isChromeless }: UnitPageConfig,
	mobileNavContainer: HTMLElement
) {
	if (isChromeless) {
		mobileNavContainer.classList.remove('is-hidden-tablet', 'is-hidden-landscape');
	}
}

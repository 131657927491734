import { loc_quickfilters } from '@msdocs/strings';
import { contentAttrs, jsllReady } from '../bi';
import { document } from '../globals';
import { getMeta } from '../meta';
import { setMoniker } from '../monikers/moniker';
import { getPlatform, platformId } from '../monikers/platform';
import { breakText } from '../text-formatting';

export const blockName = 'api-search-quick-filter';

export interface QuickFilters {
	any: boolean;
	columns: { moniker: string; displayName: string }[][];
}

export function readQuickFilters(): Promise<QuickFilters> {
	return getPlatform().then(platform => {
		const packages = platform.packagesByMoniker;

		let any = false;

		const readColumn = (name: string) => {
			const raw = getMeta(name) || '';
			const monikers = raw
				.split(',')
				.map(str => str.trim())
				.filter(moniker => {
					if (packages[moniker] === undefined) {
						/* eslint-disable-next-line */ /* tslint:disable-next-line */ // remove after eslint migration
						console.warn(`Quick Filter: no package with moniker "${moniker}" was found.`);
						return false;
					}
					any = true;
					return true;
				});
			return monikers.map(moniker => ({ moniker, displayName: packages[moniker].displayName }));
		};

		const columns = [
			readColumn('quickFilterColumn1'),
			readColumn('quickFilterColumn2'),
			readColumn('quickFilterColumn3')
		];

		return { any, columns };
	});
}

export function createQuickFilter(quickFilters: QuickFilters) {
	const blockDiv = document.createElement('div');
	blockDiv.classList.add(blockName);
	blockDiv.setAttribute(contentAttrs.name, blockName);

	const heading = document.createElement('h2');
	heading.textContent = loc_quickfilters;
	heading.classList.add('api-search-heading');
	blockDiv.appendChild(heading);

	for (const column of quickFilters.columns) {
		const columnDiv = document.createElement('div');
		blockDiv.appendChild(columnDiv);
		for (const { moniker, displayName } of column) {
			const button = document.createElement('button');
			button.classList.add(
				'button',
				'is-text',
				'has-text-wrap',
				'has-text-left',
				'is-block',
				'is-fullwidth'
			);
			button.innerHTML = breakText(displayName);
			button.addEventListener('click', () => {
				jsllReady.then(awa =>
					awa.ct.capturePageAction(button, {
						actionType: awa.actionType.OTHER,
						behavior: awa.behavior.OTHER,
						content: {
							event: 'api-browser-quickfilter',
							value: moniker,
							platform: platformId
						}
					})
				);
				setMoniker(moniker);
			});
			columnDiv.appendChild(button);
		}
	}

	return blockDiv;
}

import { loc_thankYouForYourFeedback, loc_yourFeedbackIsVeryImportantToUs } from '@msdocs/strings';
import { html, render } from '../lit-html';
import { LearningPath } from '../apis/learn';
import { createModalNextStepHTML } from '../unit/completed-modal';
import { UnitProgressConfig } from '../unit/config';

export function createThanksCard(
	unitUpdateConfig?: UnitProgressConfig,
	learningPath?: LearningPath
) {
	const thanksCardContainer = document.createElement('div');
	thanksCardContainer.classList.add('modal-slide');
	const nextStepHTML = unitUpdateConfig
		? createModalNextStepHTML(unitUpdateConfig, learningPath)
		: '';

	const thanksCardHtml = html` <div class="modal-card-content" data-bi-name="rating-thanks-card">
		<section
			class="modal-card-body has-padding-top-extra-large has-flex-justify-content-center has-text-centered"
		>
			<h2 class="title is-2">${loc_thankYouForYourFeedback}</h2>
			<p class="is-size-h5 has-padding-left-extra-large has-padding-right-extra-large">
				${loc_yourFeedbackIsVeryImportantToUs}
			</p>
			<div class="continue-action has-padding-top-large has-padding-bottom-extra-large">
				${nextStepHTML}
			</div>
		</section>
	</div>`;

	render(thanksCardHtml, thanksCardContainer);

	const continueHolder = thanksCardContainer.querySelector<HTMLElement>('.continue-action');

	return { thanksCardContainer, continueHolder };
}

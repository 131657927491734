import { EventBus } from '../../event-bus';

export class PagerNavigateEvent {
	constructor(public readonly skip: number) {}
}

export class SearchPaginationViewModel extends EventBus {
	/**
	 * The indexes, starting at 0, of all the pages.
	 */
	public pageIndexes: number;
	/**
	 * The page the user is currently on.
	 */
	public pageIndex: number;
	/**
	 * An object that describes which indexes should be displayed as buttons.
	 */
	get displayRange(): { first: number; last: number } {
		return {
			first: Math.max(this.pageIndex - 2, 0),
			last: Math.min(this.pageIndex + 2, this.pageIndexes)
		};
	}
	/**
	 *
	 * @param resultCount The total number of items to display.
	 * @param top The number of items per page.
	 * @param skip The number of items displayed on previous pages.
	 */
	constructor(resultCount: number, private readonly _top: number, skip: number = 0) {
		super();

		this.pageIndexes = Math.ceil(resultCount / _top) - 1;
		this.pageIndex = Math.floor(skip / _top);
	}
	/**
	 * Publish an event that says which page the user has selected to travel to.
	 * @param index The index of the page to navigate to.
	 */
	public setPage(index: number) {
		const skip = index * this._top;
		this.publish(new PagerNavigateEvent(skip));
	}
}

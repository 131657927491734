import { localizeDateElements } from '../text-formatting';

export function lifecyclePage() {
	localizeDates();
}

function localizeDates() {
	const formatOptions: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit'
	};

	localizeDateElements(formatOptions);
}

import {
	loc_bookmarks_addedToBookmarks,
	loc_bookmarks_button_pressedFormat,
	loc_bookmarks_button_unpressedFormat,
	loc_bookmarks_signin_title,
	loc_collections_addedToCollection,
	loc_collections_button_pressedFormat,
	loc_collections_button_unpressedFormat,
	loc_collections_signin_titleForLp,
	loc_collections_signin_titleForModule,
	loc_disclaimer_dismissAlert,
	loc_signIn,
	loc_collections_addedToCollections
} from '@msdocs/strings';
import { Docon } from '@msdocs/styles';
import {
	getListItemAbbreviatedUrl,
	getListItemInit,
	listApi,
	ListAssociation,
	ListType
} from './apis/lists';
import { signIn } from './auth/service';
import { user } from './auth/user';
import {
	collectionModalTemplate,
	renderCollectionCheckboxes
} from './collections/collection-modal';
import { Modal } from './components/modal/modal';
import { Toast } from './components/toast';
import { features } from './environment/features';
import { document } from './globals';
import { html, render, unsafeHTML } from './lit-html';
import { prompt, simplePromptTemplate } from './profile/detail/form-view';
import { generateProfileDetailUrl } from './profile/detail/routing';

/**
 * A mapping of bookmark/collection button states to docons.
 */
const icons: { [type in ListType]: { pressed: Docon; unpressed: Docon } } = {
	bookmarks: {
		pressed: 'single-bookmark-solid',
		unpressed: 'single-bookmark'
	},
	collection: {
		pressed: 'circle-addition-solid',
		unpressed: 'circle-addition'
	}
};

/**
 * A mapping of bookmark/collection button states to button labels.
 */
const labels: { [type in ListType]: { pressed: string; unpressed: string } } = {
	bookmarks: {
		pressed: loc_bookmarks_button_pressedFormat,
		unpressed: loc_bookmarks_button_unpressedFormat
	},
	collection: {
		pressed: loc_collections_button_pressedFormat,
		unpressed: loc_collections_button_unpressedFormat
	}
};

/**
 * Retrieve the icon class for the specified list type and button state.
 */
function getIconClass(type: ListType, pressed: boolean) {
	return 'docon-' + (pressed ? icons[type].pressed : icons[type].unpressed);
}

/**
 * Retrieve the button label for the specified list type and button state,
 * substituting the target item's title.
 * Example loc string: "Add bookmark for {title}"
 */
function getLabel(type: ListType, pressed: boolean, title: string) {
	return labels[type][pressed ? 'pressed' : 'unpressed'].replace(/\{title\}/g, title);
}

/**
 * Sets the button's pressed state, including label and icon.
 */
export function setButtonPressed(
	button: HTMLButtonElement,
	type: ListType,
	title: string,
	pressed: boolean
) {
	button.setAttribute('aria-pressed', pressed.toString());
	button.setAttribute('title', getLabel(type, pressed, title));
	const docon = button.querySelector('.docon');
	if (!docon) {
		throw new Error('Expected list button to contain a docon');
	}
	docon.classList.remove(getIconClass(type, !pressed));
	docon.classList.add(getIconClass(type, pressed));
}

/**
 * This is the primary export. It wires up bookmark and collection management buttons
 * for the entire page. It should only be called once.
 */
export function initListButtons(container: Element) {
	if (!features.userServices) {
		const items = Array.from(
			container.querySelectorAll('button[data-list-type]')
		) as HTMLButtonElement[];
		items.forEach(button => (button.hidden = true));
		return;
	}
	synchronizeListButtons(container);
	container.addEventListener('click', handleListButtonClick);
}

/**
 * Synchronize the state of list management buttons with the list data on the server.
 * Typically called only once by initListButtons but may be called after content updates
 * on dynamically rendered pages like the browse page.
 */
export async function synchronizeListButtons(
	container: Element,
	_isAuthenticated = user.isAuthenticated
) {
	const items = Array.from(container.querySelectorAll('button[data-list-type]'))
		.map(element => getListButtonInfo(element))
		.filter(info => info !== null);
	if (items.length === 0) {
		return;
	}
	const urls = items.map(x => x.url).filter((x, i, a) => x && a.indexOf(x) === i); // get urls and filter dupes
	const associations = _isAuthenticated ? await listApi.getAllListsByUrl(urls) : [];

	for (const { button, type, url, title } of items) {
		const pressed =
			type === 'bookmarks' ? isUrlBookmarked(url, associations) : isUrlCollected(url, associations);
		setButtonPressed(button, type, title, pressed);
	}
}

/**
 * Gets whether a url has been bookmarked.
 * NOTE: Logic assumes the url has been normalized by getListItemAbbreviatedUrl
 */
export function isUrlBookmarked(url: string, associations: ListAssociation[]) {
	return associations.findIndex(x => x.url === url && x.lists.includes('bookmarks')) !== -1;
}

/**
 * Gets whether a url is in a collection.
 * NOTE: Logic assumes the url has been normalized by getListItemAbbreviatedUrl
 */
export function isUrlCollected(url: string, associations: ListAssociation[]) {
	return associations.findIndex(x => x.url === url && x.lists.find(y => y !== 'bookmarks')) !== -1;
}

/**
 * Checks whether a click event is interesting with respect
 * to bookmark/collection management and handles the event.
 */
export async function handleListButtonClick(event: Event, _isAuthenticated = user.isAuthenticated) {
	const info = getListButtonInfo(event.target);
	if (info === null) {
		return;
	}
	event.preventDefault();

	if (!_isAuthenticated) {
		promptToSignIn(info.type, info.source);
		return;
	}

	if (info.type === 'collection') {
		toggleCollectionItem(info);
	} else {
		toggleBookmarkItem(info);
	}
}

/**
 * Attributes of a list button, gleaned from html attributes.
 */
interface ListButtonInfo {
	button: HTMLButtonElement;
	/** The type of list the button interacts with. */
	type: ListType;
	/** The url of the page associated with the button. The url has been normalized by getListItemAbbreviatedUrl */
	url: string;
	/** The title of the page associated with the button. */
	title: string;
	/** Whether the button is currently presenting a pressed/filled/selected/active state. */
	pressed: boolean;
	/** Defining if the button located on learning path or module page */
	source: string;
}

/**
 * Get the list button, type, url, and pressed status of an event target (if available).
 * null is returned if the event target is not relevant to bookmarks/collections.
 */
export function getListButtonInfo(target: EventTarget): ListButtonInfo {
	const button = target instanceof Element && target.closest('button');
	if (!button) {
		return null;
	}
	const type = button.getAttribute('data-list-type');
	if (type !== 'collection' && type !== 'bookmarks') {
		return null;
	}
	const rawUrl = button.getAttribute('data-list-item-url') || location.href;
	const title = button.getAttribute('data-list-item-title') || document.title;
	const url = getListItemAbbreviatedUrl(rawUrl);
	const pressed = button.getAttribute('aria-pressed') === 'true';
	const source = button.getAttribute('data-list-source') || 'module';
	return { button, type, url, title, pressed, source };
}

/**
 * Prompt a user to sign in to complete the specified list type action.
 */
async function promptToSignIn(type: ListType, source: string) {
	const title =
		type === 'bookmarks'
			? loc_bookmarks_signin_title
			: source === 'learning-path'
			? loc_collections_signin_titleForLp
			: loc_collections_signin_titleForModule;
	const primary = loc_signIn;
	const template = simplePromptTemplate(title, null, primary);
	const result = await prompt(template);
	if (result.submitted) {
		signIn();
	}
}

/**
 * Toggles the bookmarked state of a url.
 */
async function toggleBookmarkItem({ button, url, title, pressed }: ListButtonInfo) {
	if (button.classList.contains('is-loading')) {
		return;
	}
	button.classList.add('is-loading');
	try {
		if (pressed) {
			// Delete the bookmark...
			// We do not have an API to delete list items by url.
			// Load the entire bookmark list.
			const bookmarks = await listApi.getList('bookmarks');
			// Find the bookmark which matches the url.
			const bookmark = bookmarks.items.find(b => b.data.url === url);
			if (bookmark) {
				// Delete the bookmark by it's id.
				await listApi.deleteItem('bookmarks', bookmark.id);
			}
		} else {
			// Add a bookmark and toast the user... cheers!
			const init = await getListItemInit(
				url === getListItemAbbreviatedUrl(location.href) ? document : url
			);
			await listApi.addItem('bookmarks', init);
			createToast('bookmarks');
		}
		// Update the button state to reflect the successful server update.
		setButtonPressed(button, 'bookmarks', title, !pressed);
	} finally {
		button.classList.remove('is-loading');
	}
}

/**
 * Toggles the collection state of a url.
 */
async function toggleCollectionItem({ button, url, title }: ListButtonInfo) {
	if (button.classList.contains('is-loading')) {
		return;
	}
	button.classList.add('is-loading');
	try {
		initCollectionsModal(url, title);
	} finally {
		button.classList.remove('is-loading');
	}
}

/**
 * Toast the user with a link to their bookmarks list after a successful bookmark addition.
 */
function createToast(listType: 'bookmarks' | 'collections') {
	const selectedCollections = document.querySelectorAll('#collections input:checked');
	const profileUrl = generateProfileDetailUrl(
		user.userName,
		listType,
		selectedCollections.length === 1 ? selectedCollections.item(0).getAttribute('id') : ''
	).href;

	let message: string;
	if (listType === 'bookmarks') {
		message = loc_bookmarks_addedToBookmarks.replace('{url}', profileUrl);
	} else {
		message = (selectedCollections.length > 1
			? loc_collections_addedToCollections
			: loc_collections_addedToCollection.replace(
					'{collectionName}',
					selectedCollections.item(0).getAttribute('name')
			  )
		).replace('{url}', profileUrl);
	}

	const toastTemplate = html` <div
		class="has-background-dark-opacity has-text-tertiary-invert is-full-width"
	>
		<div class="uhf-container">
			<div class="level has-padding-top-small has-padding-bottom-small">
				<div
					class="is-full-width level-item has-line-height-reset has-text-overlay-invert has-margin-top-small has-margin-right-medium has-margin-top-none-tablet has-margin-right-none-tablet"
				>
					<span
						aria-hidden="true"
						class="icon is-small is-rounded has-background-tertiary-invert has-text-tertiary"
					>
						<span class="is-size-extra-small docon docon-check"></span>
					</span>
					<span class="message has-margin-left-small">
						${unsafeHTML(message)}
					</span>
				</div>
				<div class="level-right">
					<button
						data-dismiss
						type="button"
						class="dismiss delete is-large is-absolute-mobile has-top-zero-mobile has-right-zero-mobile has-margin-extra-small has-margin-none-tablet"
						aria-label="${loc_disclaimer_dismissAlert}"
					>
						<span class="docon docon-navigate-close" aria-hidden="true"></span>
					</button>
				</div>
			</div>
		</div>
	</div>`;

	const content = document.createElement('div');
	render(toastTemplate, content);

	new Toast(content).show();
}

/**
 * Initiate the collections modal with list of collections
 */
async function initCollectionsModal(url: string, title: string) {
	if (document.querySelector('.modal')) {
		// bail if modal exists
		return false;
	}

	// Create new modal
	const modalConsentContainer = document.createElement('div');
	modalConsentContainer.classList.add('modal-content');
	const modal = new Modal(modalConsentContainer);

	// Render the modal content and list of collections then show modal
	render(collectionModalTemplate(modal, title, url), modalConsentContainer);
	await renderCollectionCheckboxes(modalConsentContainer, url);
	handleSaveCollection(modalConsentContainer, modal);
	return modal.show();
}

/**
 * On save click rerender the browse card collection buttons, show toast if collections selected, and close modal
 */
function handleSaveCollection(container: HTMLElement, modal: Modal) {
	const saveCollectionsButton = container.querySelector<HTMLButtonElement>(
		'button#save-collection'
	);
	saveCollectionsButton.onclick = () => {
		synchronizeListButtons(document.querySelector('#main'));
		if (document.querySelector('#collections input:checked')) {
			createToast('collections');
		}
		modal.hide();
	};
}

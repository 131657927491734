import { loc_documentDetails, loc_doNotEditThis } from '@msdocs/strings';
import { context } from './context';

export function formatIssueBody(body: string) {
	let markdown = `${body}\n\n---\n#### ${loc_documentDetails}\n\n⚠ *${loc_doNotEditThis}*\n\n`;

	const url = location.href;
	let { documentId, versionIndependentDocumentId, documentSourceUrl, contentTitle } = context;
	// compute the title of the "source" link by ripping off the https://github.com/{owner}/{repo}/blob/{branch} and escaping any square brackets.
	const documentSourceTitle = documentSourceUrl
		.replace(/^https:\/\/github.com\/[^/]+\/[^/]+\/blob\/[^/]+\//i, '')
		.replace(/\[|\]/g, '\\$&');
	contentTitle = contentTitle.replace(/\[|\]/g, '\\$&'); // escape square brackets

	// do not indent the following code.
	markdown += `* ID: ${documentId}
* Version Independent ID: ${versionIndependentDocumentId}
* Content: [${contentTitle}](${url})
* Content Source: [${documentSourceTitle}](${documentSourceUrl})`;
	const { service, subservice, product, technology } = context;
	if (service !== undefined) {
		markdown += `\n* Service: **${service.toLowerCase()}**`;
	}
	if (subservice !== undefined) {
		markdown += `\n* Sub-service: **${subservice.toLowerCase()}**`;
	}
	if (product !== undefined) {
		markdown += `\n* Product: **${product.toLowerCase()}**`;
	}
	if (technology !== undefined) {
		markdown += `\n* Technology: **${technology.toLowerCase()}**`;
	}
	const { author, msAuthor } = context;
	if (author !== undefined) {
		if (/github\.com/.test(documentSourceUrl)) {
			markdown += `\n* GitHub Login: @${author}`;
		} else {
			markdown += `\n* Git Login: **${author}**`;
		}
	}
	if (msAuthor !== undefined) {
		markdown += `\n* Microsoft Alias: **${msAuthor}**`;
	}

	markdown = markdown.replace(/</g, '&lt;').replace(/>/g, '&gt;');

	return markdown;
}

export function getCreateOnGitHubUrl(title: string, body: string) {
	return `https://github.com/${context.repo}/issues/new?title=${encodeURIComponent(
		title
	)}&body=${encodeURIComponent(formatIssueBody(body))}`;
}

import { checkIsArchived } from '../../archive';
import { jsllReady } from '../../bi';
import { isTestRepo } from '../../is-production';
import { getMeta } from '../../meta';
import { toQueryString } from '../../query-string';

/**
 * Shared classes for consistent styling of search suggestions.
 */
export const suggestionClass =
	'is-block is-size-small has-padding-left-small has-padding-right-small';

let pageScope: string;

/**
 * Get the site search suggestion.
 * When multiple scopes are defined (comma delimited), the last one is used.
 */
export function getSiteSearchSuggestion(term: string, useScope: boolean) {
	if (pageScope === undefined) {
		const rawScope = getMeta('scope') || '';
		const scopes = rawScope
			.split(',')
			.map(s => s.trim())
			.filter(s => s.length);
		pageScope = scopes.length ? scopes[scopes.length - 1] : '';
	}

	let scope: string;
	if (pageScope && useScope) {
		scope = pageScope;
	}

	const dataSource = checkIsArchived() ? 'previousVersions' : '';
	const baseUrl = isTestRepo ? '/test/docs/search/search' : '/search/';

	const title = `Search for "${term}" in all${scope ? ' ' + scope : ''} documentation`; // todo: localize
	const url = `${baseUrl}?${toQueryString({ search: term, scope, dataSource })}`;
	return { title, url };
}

/**
 * Gets whether the href is the site search suggestion.
 */
export function isSiteSearch(href: string) {
	return (
		href.indexOf('/search/?search') !== -1 ||
		(isTestRepo && href.indexOf('/search/search?search') !== -1)
	);
}

export async function reportAutocompleteWithJsll(
	autocomplete: HTMLElement,
	title: string,
	href: string
) {
	const awa = await jsllReady;
	awa.ct.capturePageAction(autocomplete, {
		behavior: awa.behavior.APPLY,
		targetUri: href,
		content: {
			event: 'autocomplete',
			title,
			href
		}
	});
}

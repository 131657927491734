/**
 * Fusion TOC Feature
 */
import { normalizeTocResponse } from './normalize';
import { FusionToc, TocMetadata, TocNode } from './types';
import { resolveUrl } from './url';
import { fetchToc } from './url-queue';
import { walk } from './walk';

/**
 * Loads toc.json files in TOCs that use the Fusion TOC feature.
 * @param metadata Metadata returned by the initial toc.json load.
 * @param tocUrl The URL used to load the initial toc.json.
 * @param moniker The page moniker.
 */
export async function loadFusionTocs(metadata: TocMetadata, tocUrl: string, moniker: string) {
	const promises: Promise<FusionToc>[] = [];

	const loadFusionToc = async (href: string, type: 'conceptual' | 'reference') => {
		const url = resolveUrl(href, tocUrl);
		if (moniker) {
			url.search = `?view=${encodeURIComponent(moniker)}`;
		}
		const response = await fetchToc(url.href);
		if (!response.ok) {
			if (response.status === 404) {
				// todo: appinsights
				/* eslint-disable-next-line */ /* tslint:disable-next-line */ // remove after eslint migration
				console.log(`Fusion TOC does not exist: ${type} ${href}`);
			}
			return null;
		}
		const data = await response.json();
		const { nodes } = normalizeTocResponse(data);
		return { type, url: url.href, nodes };
	};

	const { universal_conceptual_toc, universal_ref_toc } = metadata;
	if (universal_conceptual_toc) {
		promises.push(loadFusionToc(universal_conceptual_toc, 'conceptual'));
	}
	if (universal_ref_toc) {
		promises.push(loadFusionToc(universal_ref_toc, 'reference'));
	}
	const results = await Promise.all(promises);
	return results.filter(x => x); // filter out Fusion TOCs that could not be loaded.
}

/**
 * Merges the Fusion TOC(s) into the page TOC.
 * @param page The page TOC. The Fusion TOCs will be inserted into this TOC array.
 * @param conceptual The Fusion TOC "conceptual" TOC.
 * @param reference The Fusion TOC "reference" TOC.
 */
export function mergeTocs(page: TocNode[], conceptual: TocNode[], reference: TocNode[]) {
	if (conceptual && reference) {
		reference[0].isNewSection = true;
		if (page[0].url) {
			const pathname = page[0].url.pathname;
			walk(reference, node => {
				if (
					node.url &&
					node.url.pathname.localeCompare(pathname, undefined, { sensitivity: 'base' }) === 0
				) {
					node.toc_title = page[0].toc_title;
					node.children = page[0].children;
					return false;
				}
				return true;
			});
		} else if (page[0].toc_title) {
			const tocTitle = page[0].toc_title;
			const overviewPathName = page[0].children[0]?.url?.pathname;
			walk(reference, node => {
				if (node.toc_title.localeCompare(tocTitle, undefined, { sensitivity: 'base' }) === 0) {
					node.children = page[0].children;
					if (
						overviewPathName &&
						overviewPathName.localeCompare(node.url.pathname, undefined, {
							sensitivity: 'base'
						}) === 0
					) {
						node.url = undefined;
					}
					return false;
				}
				return true;
			});
		}
		page.splice(0, page.length, ...conceptual.concat(reference));
	} else if (conceptual) {
		page[0].isNewSection = true;
		page.unshift(...conceptual);
	} else if (reference) {
		reference[0].isNewSection = true;
		page.push(...reference);
	}
}

import { loc_search } from '@msdocs/strings';
import {
	autocompleteChangeEvent,
	AutoCompleteConfig,
	autocompleteDisplayedEvent,
	createAutoComplete
} from '../autocomplete';
import { instrumentAutocompleteChange, instrumentAutocompleteDisplayed } from './bi';
import { facetSearchInputNameId } from './constants';
import type { SearchViewModel } from './view-model';

/**
 * Creates either a normal input element or an autocomplete element.
 * Sets event listener on returned element, linking value to view model.
 * @param initialValue
 * @param autocomplete
 */
export function createFacetSearchInput<TResult>(
	onchange: SearchViewModel<TResult>['fetch'],
	autocomplete?: AutoCompleteConfig<string>
) {
	if (!autocomplete) {
		const input = document.createElement('input');
		input.setAttribute('type', 'text');
		input.dataset.biName = 'search-input';
		input.classList.add('input', 'is-large');
		input.placeholder = loc_search;
		input.id = facetSearchInputNameId;
		input.name = facetSearchInputNameId;
		input.value = '';
		// if there is no autocomplete, we should not do any onchange fetchs for accessibilty reasons -
		return input;
	}

	// This Facet Search has autocomplete enabled
	autocomplete.input = {
		...autocomplete.input,
		name: facetSearchInputNameId,
		id: facetSearchInputNameId,
		size: 'large'
	};

	const ac = createAutoComplete({
		...autocomplete,
		initialValue: ''
	});

	ac.addEventListener(autocompleteChangeEvent, onchange);

	// jsll instrumentation for the autocomplete
	ac.addEventListener(autocompleteChangeEvent, instrumentAutocompleteChange);
	ac.addEventListener(autocompleteDisplayedEvent, instrumentAutocompleteDisplayed);

	return ac;
}

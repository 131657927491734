import { loc_wechat } from '@msdocs/strings';
import { html } from './lit-html';
import { features } from './environment/features';
import { msDocs } from './globals';

export const allowsWeChat =
	features.mooncakeHeader ||
	msDocs.data.userLocale === 'zh-cn' ||
	msDocs.data.userLocale === 'zh-tw';

export async function buildShareQR(qrElement: HTMLElement, url: string, allowsWeChat: boolean) {
	if (!qrElement && !allowsWeChat) {
		return;
	}

	// @ts-ignore - qrious library is loaded over CDN in Master.liquid
	const qr = new QRious({
		element: qrElement,
		value: url,
		size: 96,
		padding: 4
	});

	return qr;
}

//export function showWechatShareQRDropdown(allowsWeChat: boolean): string {
//	return allowsWeChat ? `
//	<div id="wechat-qr" class="dropdown has-caret is-inline-block">
//		<button title="${loc_wechat}" aria-controls="qr-holder" aria-expanded="false" class="dropdown-trigger share-wechat button is-text is-icon-only-touch is-block has-padding-small" data-bi-name="wechat">
//			<span class="has-text-subtle icon docon docon-wechat" aria-hidden="true"></span>
//		</button>
//		<div id="qr-holder" class="dropdown-menu has-padding-medium">
//			<span class="is-block has-margin-bottom-small">${loc_wechat}</span>
//			<canvas class="qr-code">
//			</canvas>
//		</div>
//	</div>
//	` : '';
//}

export function showWechatShareQRStatic(allowsWeChat: boolean) {
	return allowsWeChat
		? html`
				<li>
					<span class="share-wechat is-size-small">
						<span class="icon has-text-primary" aria-hidden="true">
							<span class="docon docon-wechat" data-bi-name="wechat"></span>
						</span>
						<span>${loc_wechat}</span>
					</span>
				</li>
				<li><canvas class="qr-code"></canvas></li>
		  `
		: '';
}

export function buildProfileCardQRs(element: HTMLElement) {
	const qrCodes = Array.from(element.querySelectorAll<HTMLElement>('canvas.qr-code'));
	if (qrCodes.length) {
		qrCodes.forEach(qr => {
			const headingLink = qr.closest('.card').querySelector('.card-content a') as HTMLLinkElement;
			if (headingLink) {
				buildShareQR(qr, headingLink.href, true);
			}
		});
	}
}

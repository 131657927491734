import { apis } from '../environment/apis';
import { features } from '../environment/features';
import { fetchWithTimeout } from '../fetch';
import { getMeta } from '../meta';

let restProductsPromise: Promise<RestProduct[]>;
export function getRestProducts() {
	if (restProductsPromise === undefined) {
		const url = getMeta('api') || '/en-us/rest/api/rest-products.json';
		restProductsPromise = fetchWithTimeout(url, { credentials: 'include' }).then(response =>
			response.json()
		);
	}
	return restProductsPromise;
}

export function fetchPlatform(platformId: string): Promise<Api.Platform> {
	return platformId === 'rest'
		? getRestProducts()
				.then(data => mapRestProductsToPlatform(data, platformId))
				.catch(() => ({ platformId, displayName: platformId, families: [] }))
		: fetchWithTimeout(`${apis.monikers}/byPlatform/${encodeURIComponent(platformId)}`)
				.then<Api.DhsFamily[]>(response => response.json())
				.then(dhsFamlies => mapDhsPlatformToPlatform(dhsFamlies, platformId))
				.catch(() => ({ platformId, displayName: platformId, families: [] }));
}

export function fetchFamilyByMoniker(moniker: string) {
	return fetchWithTimeout(`${apis.monikers}/bymoniker/${encodeURIComponent(moniker)}`)
		.then<Api.DhsFamily>(response => {
			if (response.ok) {
				return response.json().then(dhsFamily => {
					assertMonikerExists(moniker, dhsFamily);
					return dhsFamily;
				});
			}
			return Promise.reject(null);
		})
		.then(family => mapDhsFamilyToFamily(family))
		.catch(() => createFamilyFromMoniker(moniker));
}

function createFamilyFromMoniker(moniker: string): Api.Family {
	return {
		displayName: moniker,
		products: [
			{
				displayName: moniker,
				packages: [
					{
						displayName: moniker,
						isDefault: true,
						moniker,
						versionDisplayName: moniker,
						isDeprecated: false,
						isPrerelease: false
					}
				]
			}
		]
	};
}

function mapDhsFamilyToFamily(dhsFamily: Api.DhsFamily): Api.Family {
	return {
		displayName: dhsFamily.familyName,
		products: dhsFamily.products.map(({ productName, packages }) => {
			return {
				displayName: productName,
				packages: packages
					.sort((a, b) => b.order - a.order)
					.filter(pkg => filterDhsPackage(pkg))
					.map(p => {
						return {
							displayName: p.monikerDisplayName,
							isDefault: p.isDefault,
							moniker: p.monikerName,
							versionDisplayName: p.versionDisplayName,
							isDeprecated: p.isDeprecated,
							isPrerelease: p.isPrerelease
						};
					})
			};
		})
	};
}

type DhsPackage = Api.DhsFamily['products'][0]['packages'][0];

export function filterDhsPackage(pkg: DhsPackage) {
	if (features.mooncakeHeader) {
		return pkg.monikerName !== 'azure-cli-2017-03-09-profile';
	}
	return true;
}

function mapDhsPlatformToPlatform(dhsFamilies: Api.DhsFamily[], platformId: string): Api.Platform {
	return {
		platformId,
		families: dhsFamilies.map(mapDhsFamilyToFamily)
	};
}

function assertMonikerExists(moniker: string, family: Api.DhsFamily) {
	if (
		!family.products.find(product => !!product.packages.find(pkg => pkg.monikerName === moniker))
	) {
		throw new Error(`The family "${family.familyName}" does not contain the moniker "${moniker}".`);
	}
}

function mapRestProductsToPlatform(products: RestProduct[], platformId: string): Api.Platform {
	return {
		platformId,
		families: [
			{
				displayName: '',
				products: [
					{
						displayName: '',
						packages: products.map(product => ({
							displayName: product.name,
							moniker: product.scope,
							versionDisplayName: product.name,
							isDefault: false,
							isDeprecated: false,
							isPrerelease: false
						}))
					}
				]
			}
		]
	};
}

export interface RestProduct {
	name: string;
	scope: string;
	services: {
		description: string;
		name: string;
		url: string;
	}[];
}

import { render } from '../../lit-html';
import { PropertyChangedEvent } from '../../view-model';
import { AssessmentInitializationObject, AssessmentNavigation } from '../types';
import { AssessmentViewModel } from '../view-model';
import { renderGuidance } from './guidance-mode';
import { createAssessmentsTemplate } from './home-mode';
import { renderInvalidMode } from './invalid-mode';
import { assessmentTemplate } from './questionnaire-mode';
import { showHomeDisclaimer, showQuestionnaireDisclaimer } from './../assessment-disclaimer';
import { features } from '../../environment/features';

export let viewModel: AssessmentViewModel;

export async function renderAssessmentMode(
	navigationAction: AssessmentNavigation,
	view: HTMLElement,
	hero: HTMLElement
) {
	hero.hidden = navigationAction.mode !== 'home';

	disposeOutdateViewmodel(navigationAction);

	switch (navigationAction.mode) {
		case 'home':
			await showHomeDisclaimer(navigationAction);
			render(await createAssessmentsTemplate(navigationAction), view);
			break;
		case 'pre-assessment':
		case 'questionnaire':
			if (!viewModel) {
				initAssessmentVM(navigationAction, view);
			} else {
				// when getting here through the router
				viewModel.navigate(navigationAction);
			}
			break;
		case 'guidance':
			renderGuidance(navigationAction.guidance, view);
			break;
		case 'invalid':
			renderInvalidMode(navigationAction, view);
			break;
		default:
			throw new Error('Unsupported navigation action.');
	}
}

/**
 * Determines if the view model needs to be disposed.
 * Can occur only if there is a current view model.
 * Occurs if we're moving to a view that doesn't use a view model.
 * Occurs if we're switching assessment-branches.
 * @param navigationAction The current navigation step
 */
function disposeOutdateViewmodel(navigationAction: AssessmentNavigation) {
	if (
		(!!viewModel &&
			navigationAction.mode !== 'pre-assessment' &&
			navigationAction.mode !== 'questionnaire') ||
		(!!viewModel &&
			navigationAction.mode === 'pre-assessment' &&
			navigationAction.branch &&
			navigationAction.branch !== viewModel.session.branch)
	) {
		disposeUsedViewModel();
	}
}

/**
 * Disposes of unnecessary view models on view that don't require it.
 * Required for the router, which maintains the same context when window.history is envoked.
 * @param navigationAction The action describing which assessment view to render
 */
function disposeUsedViewModel() {
	viewModel.dispose();
	viewModel = null;
}

function initAssessmentVM(navigationAction: AssessmentInitializationObject, view: HTMLElement) {
	viewModel = new AssessmentViewModel(navigationAction);
	showQuestionnaireDisclaimer(viewModel);

	render(assessmentTemplate(viewModel), view);

	viewModel.subscribe(PropertyChangedEvent, () => {
		render(assessmentTemplate(viewModel), view);
		if (features.assessmentSideNav) {
			window.dispatchEvent(new CustomEvent('assessment-content-update'));
		}
	});
}

import { getContentPath } from '../feedback-report/content-path';
import { contentLoaded, msDocs } from '../globals';
import { isPPE } from '../is-production';
import { getMeta } from '../meta';

export async function feedbackLink(): Promise<void> {
	await contentLoaded;

	const feedbackLink = document.getElementById('module-unit-feedback-link') as HTMLAnchorElement;
	if (!feedbackLink) {
		return; // only continue if there's a feedback link
	}

	const troubleshootingGuide = document.getElementById(
		'troubleshooting-guide'
	) as HTMLAnchorElement;

	const troubleShootingURL = troubleShootingUrl();

	troubleshootingGuide.href = troubleShootingURL;
	feedbackLink.href = `${troubleShootingURL}#report-feedback`;
}

export function troubleShootingUrl(): string {
	const troubleshootingContextQS = getContextQS();
	const troubleShootingURL = isPPE
		? `/${msDocs.data.userLocale}/learn-sandbox/support/troubleshoot${troubleshootingContextQS}`
		: `/${msDocs.data.userLocale}/learn/support/troubleshooting${troubleshootingContextQS}`;

	return troubleShootingURL;
}

export function getContextQS(): string {
	const context: { [index: string]: string } = {
		uid: getMeta('uid') || '',
		documentId: getMeta('document_id') || '',
		versionIndependentDocumentId: getMeta('document_version_independent_id') || '',
		contentPath: getContentPath(),
		url: location.href
	};

	const contextParams = Object.keys(context).reduce((params, k) => {
		const v = context[k];
		if (v === undefined || v.trim().length === 0) {
			return params;
		}

		params.push(`${k}=${encodeURIComponent(v)}`);
		return params;
	}, []);

	if (contextParams.length === 0) {
		return '';
	}

	return `?${contextParams.join('&')}`;
}

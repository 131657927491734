let worker: Worker;
let nextId = 0;

export interface LineHighlightInstruction {
	start: number;
	end: number;
}

export interface SyntaxHighlightInstruction {
	code: string;
	language: string;
	highlightLines: string;
}

export interface SyntaxHighlightResult {
	code: string;
	html: string;
	success: boolean;
}

interface WorkerRequest {
	id: number;
	instructions: SyntaxHighlightInstruction[];
}

interface WorkerResponse {
	id: number;
	results: SyntaxHighlightResult[];
}

const pending: { [id: number]: (results: SyntaxHighlightResult[]) => void } = {};

export function syntaxHighlight(
	instructions: SyntaxHighlightInstruction[]
): Promise<SyntaxHighlightResult[]> {
	if (worker === undefined) {
		createWorker();
	}

	const request = { id: nextId++, instructions };
	worker.postMessage(request);
	return new Promise(resolve => (pending[request.id] = resolve));
}

function createWorker() {
	const highlightJsUrl =
		location.origin + '/static/third-party/highlight.js/9.15.16/highlight.pack.js';
	const blob = new Blob(
		[`(${workerScript.toString()})('${highlightJsUrl}')\n//# sourceURL=syntax-highlighter.js`],
		{ type: 'application/javascript' }
	);
	const url = URL.createObjectURL(blob);

	worker = new Worker(url);
	worker.onmessage = message => {
		const response = message.data as WorkerResponse;
		pending[response.id](response.results);
	};
}

declare const hljs: {
	highlight(name: string, value: string, ignore_illegals?: boolean, continuation?: object): any;
	highlightAuto(value: string, languageSubset?: string[]): any;
};

function workerScript(highlightJsUrl: string) {
	function parseHighlightLines(code: string, rawInstruction: string): LineHighlightInstruction[] {
		const instructions: LineHighlightInstruction[] = [];

		if (rawInstruction === null) {
			return instructions;
		}

		const lineRegex = /\n/g;
		let lines = 1;
		while (lineRegex.exec(code)) {
			lines++;
		}

		const rangeRegex = /(\d+)(?:\s*-\s*(\d+))?/g;
		let match: RegExpExecArray;
		while ((match = rangeRegex.exec(rawInstruction))) {
			const start = +match[1] - 1;
			if (isNaN(start) || start >= lines) {
				continue;
			}
			let end = match[2] === undefined ? start : +match[2] - 1;
			if (isNaN(end) || end < start) {
				continue;
			}
			end = Math.min(end, lines - 1);

			instructions.push({ start, end });
		}

		return instructions;
	}

	const rgnRegex = /<rgn>.*<\/rgn>/gi;
	const rgnPlaceholderRegex = /RGNPLACEHOLDER/g;
	const rgnPlaceholder = 'RGNPLACEHOLDER';

	function removeRgns(code: string, removed: string[]): string {
		return code.replace(rgnRegex, rgn => {
			removed.push(rgn);
			return rgnPlaceholder;
		});
	}

	function restoreRgns(html: string, removed: string[]): string {
		return html.replace(rgnPlaceholderRegex, () => removed.shift());
	}

	/**
	 * Runs a code string through highlighting.
	 *
	 * Due to accessibility and line marking, we now use the <mark> tag to indicate sections of code that should be emphasized.
	 * Parsing the full block of code with HLJS did not allow for this (attempting to use the mark tag would result in)
	 * malformed HTML) and while highlighting the content line-by-line solved this problem, there were concerns about DOM perf
	 * due to many extra elements. We now take a middle path, grouping emphasis sections by boundary.
	 *
	 * The best-case performance of this approach should match the block strategy - with no lines emphasized, or all lines
	 * emphasized, one group will be generated to send to the highlighter.
	 *
	 * The worst-case performanceof this approach would occur if an author decided to highlight every other line. In this case,
	 * the number of groups would match the number of lines.
	 *
	 * Highlighting groups, the full block, or line-by-line uses the same code path in HLJS.
	 * See https://github.com/highlightjs/highlight.js/blob/9.15.10/src/highlight.js#L380.
	 *
	 * @param event The worker message event.
	 */
	function handleMessageLineByLine(event: MessageEvent) {
		const { id, instructions } = event.data as WorkerRequest;
		const results: SyntaxHighlightResult[] = [];
		for (const { language, code, highlightLines } of instructions) {
			const result = { code, html: '', success: false };

			try {
				const rgns: string[] = [];
				const removed = removeRgns(code, rgns);
				const groups: { highlight: boolean; lines: string }[] = [];

				const lineInstructions = parseHighlightLines(code, highlightLines);

				if (lineInstructions.length) {
					const lines = removed.split('\n');
					let nextLine = 0;

					lineInstructions.forEach(instruction => {
						if (instruction.start > nextLine) {
							groups.push({
								highlight: false,
								lines: lines.slice(nextLine, instruction.start).join('\n')
							});
						}

						groups.push({
							highlight: true,
							lines: lines.slice(instruction.start, instruction.end + 1).join('\n')
						});
						nextLine = instruction.end + 1;
					});

					if (nextLine <= lines.length) {
						groups.push({ highlight: false, lines: lines.slice(nextLine).join('\n') });
					}
				} else {
					groups.push({ highlight: false, lines: removed });
				}

				let continuation = null;
				let output = '';
				do {
					const group = groups.shift();
					const { value, top } = hljs.highlight(language, group.lines, true, continuation);
					continuation = top;

					output += group.highlight ? `<mark>${value}</mark>` : `<span>${value}</span>`;

					if (groups.length) {
						output += '\n';
					}
				} while (groups.length);

				result.html = restoreRgns(output, rgns);
				result.success = true;
			} catch (err) {}

			results.push(result);
		}

		const response: WorkerResponse = { id, results };
		(self as any).postMessage(response);
	}

	self.importScripts(highlightJsUrl);

	self.addEventListener('message', (event: MessageEvent) => {
		handleMessageLineByLine(event);
	});
}

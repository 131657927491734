import {
	loc_assessment_assessmentOverview,
	loc_assessment_returnToGuidance,
	loc_assessment_signInAndSave
} from '@msdocs/strings';
import { updateUrlSearchFromMap } from '../query-string';
import { assessmentAuthReferralKey, AssessmentSession, InvalidNavigation } from './types';

export function createSessionInvalidContent(
	session: Partial<AssessmentSession>,
	branch: string | null
): InvalidNavigation {
	return {
		branch,
		session,
		mode: 'invalid',
		message: 'This session is complete, proceed to invalid screen.',
		content: {
			heading: 'This assessment cannot be edited',
			body:
				'Completed assessments are unable to be changed at this time. Please sign in to save or start a new assessment from the assessments overview',
			links: [
				{
					url: updateUrlSearchFromMap({
						mode: 'guidance',
						session: session.id !== 'local' ? session.id : null,
						id: null,
						category: null,
						question: null
					}),
					text: loc_assessment_returnToGuidance,
					buttonClass: 'is-primary'
				},
				{
					url: updateUrlSearchFromMap({
						mode: null,
						session: null,
						id: null,
						category: null,
						question: null,
						[assessmentAuthReferralKey]: 'true'
					}),
					text: loc_assessment_signInAndSave,
					buttonClass: 'docs-sign-in not-authenticated'
				},
				{
					url: updateUrlSearchFromMap({
						mode: null,
						session: null,
						id: null,
						category: null,
						question: null
					}),
					text: loc_assessment_assessmentOverview
				}
			]
		}
	};
}

import { TemplateResult } from './lit-html';

/**
 * Encapsulates a stack of TemplateResults used for modal push/pop scenarios
 */
export class BodyTemplateContainer {
	private _contentStack: TemplateResult[] = [];

	get stackLength() {
		return this._contentStack ? this._contentStack.length : undefined;
	}
	get bodyTemplate() {
		return !this.stackLength ? null : this._contentStack[this._contentStack.length - 1];
	}

	public push(content: TemplateResult) {
		if (!content) {
			throw new Error('Cannot push empty content');
		}

		this._contentStack.push(content);
	}

	public pop() {
		if (!this._contentStack || this.stackLength <= 1) {
			// there should always be at least one modal-body template when instance is active
			return;
		}

		this._contentStack.pop();
	}

	public dispose() {
		this._contentStack = null;
	}
}

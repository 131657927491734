import * as api from '../../apis/sandbox';
import { user } from '../../auth/user';
import { getDocsToken, verifyToken } from '../../auth/v2/jwt';
import { getMeta } from '../../meta';
import { AzureSandbox } from '../sandbox';
import * as urlActivation from './url-activation';

/**
 * Prepare the tenant invite as soon as possible to decrease the chances
 * the invitation is not ready when the user reaches the tenant invitation page.
 *
 * This function hits the API if the following conditions are met:
 * 1. The user is on a "learn" page OR the user is on the register page.
 * 2. The user is authenticated.
 */
export async function prepareTenantInvite() {
	const pageType = getMeta('page_type');
	const pageKind = getMeta('page_kind');

	const shouldPrepare = pageType === 'learn' || (pageType === 'profile' && pageKind === 'register');

	if (!shouldPrepare) {
		return;
	}

	// This needs to be fixed for registration cases.
	await user.whenAuthenticated();

	try {
		const token = getDocsToken();
		if (token) {
			verifyToken(token); // Will throw is expired or invalid
			api.prepareTenantInvite(urlActivation.createTenantInviteReturnUrl());
		}
	} catch {
		// no-op - do not run the tenant invite if we don't have a valid token.
		// This will happen in registration cases.
	}
}

/**
 * The minimum age of the tenant invite ticket in milliseconds.
 * Used to prevent sending the user to the tenant invitation page too early.
 */
export const minimumInvitationTicketAge = 1000 * 10;

/**
 * Gets whether the tenant invite is ready for the user to accept.
 */
export function isInvitationReady(sandbox: AzureSandbox) {
	return getInvitationDelay(sandbox) === 0;
}

/**
 * Gets the delay necessary before displaying the tenant invite link.
 */
export function getInvitationDelay(sandbox: AzureSandbox) {
	if (!sandbox.userCreatedAt) {
		return minimumInvitationTicketAge;
	}
	const invitationAge = Date.now() - new Date(sandbox.userCreatedAt).getTime();
	return Math.max(0, minimumInvitationTicketAge - invitationAge);
}

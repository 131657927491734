import { localStorage } from '../protected-storage';
import { verbatimForm } from './verbatim-feedback';

export function initBinaryRating(container: Element, isArchived: boolean) {
	const ratingContainers = Array.from(
		container.querySelectorAll<HTMLFormElement>('.feedback-verbatim')
	);

	if (!ratingContainers.length || isArchived) {
		// bail if the rating containers don't exist or page is archived
		return;
	}

	const likeRatingButtons = Array.from(container.querySelectorAll<HTMLButtonElement>('.like'));
	const dislikeRatingButtons = Array.from(
		container.querySelectorAll<HTMLButtonElement>('.dislike')
	);
	const allRatingButtons = likeRatingButtons.concat(dislikeRatingButtons);

	allRatingButtons.forEach(element => {
		element.onclick = () => {
			const verbatimForm = element.closest('.binary-rating-buttons').nextElementSibling;

			const rateValue =
				element.getAttribute('data-bi-name') === 'rating-no'
					? dislikeRatingButtons
					: likeRatingButtons;
			const reverseValue =
				rateValue === dislikeRatingButtons ? likeRatingButtons : dislikeRatingButtons;

			setButtonValues(rateValue, true);
			setButtonValues(reverseValue, false);

			verbatimForm.classList.remove('is-hidden');

			localStorage.setItem(
				`binary-rating-${location.pathname}`,
				element.getAttribute('data-bi-name')
			);
		};
	});

	// set feedback form logic
	ratingContainers.forEach(container => {
		verbatimForm(container, allRatingButtons);
	});

	//check storage
	const savedRating = localStorage.getItem(`binary-rating-${location.pathname}`);
	if (savedRating) {
		const pageRating = savedRating === 'rating-no' ? dislikeRatingButtons : likeRatingButtons;
		pageRating.forEach(button => {
			button.setAttribute('aria-selected', 'true');
			button.classList.add('is-selected');
		});
	}

	// Set aria values and styles
	function setButtonValues(buttonArray: HTMLButtonElement[], addValues: boolean) {
		buttonArray.forEach(button => {
			if (addValues) {
				button.classList.add('is-selected');
			} else {
				button.classList.remove('is-selected');
			}

			button.setAttribute('aria-expanded', `${addValues}`);
		});
	}
}

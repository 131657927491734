import { getSearchHeaderString } from '../../../results-string';
import { SearchViewModel, SearchVMConfig } from '../view-model';
import { html } from '../../../lit-html';
import { PivotCategory } from '../../../apis/site-search';

export function searchHeadingTemplate<TResult>(
	vm: SearchViewModel<TResult>,
	headingLevel: SearchVMConfig<TResult>['headingLevel'] = 'h2'
) {
	const selectedCategory =
		vm.facetGroups?.category && vm.facetGroups.category.facets.find(f => f.isSelected);
	const pivotCategoryString = (selectedCategory?.value || '') as PivotCategory;

	switch (headingLevel) {
		case 'h1':
			return html`
				<h1
					class="title is-5 ${vm.resultsCount !== 0 ? '' : 'visually-hidden'}"
					aria-live="polite"
					aria-role="alert"
					aria-atomic="true"
					aria-relevant="text"
				>
					${vm.busy
						? ''
						: getSearchHeaderString(vm.resultsCount, vm.terms, pivotCategoryString, vm.scope)}
				</h1>
			`;
		default:
			return html`
				<h2
					class="title is-5 ${vm.resultsCount !== 0 ? '' : 'visually-hidden'}"
					aria-live="polite"
					aria-role="alert"
					aria-atomic="true"
					aria-relevant="text"
				>
					${vm.busy
						? ''
						: getSearchHeaderString(vm.resultsCount, vm.terms, pivotCategoryString, vm.scope)}
				</h2>
			`;
	}
}

import { loc_azureSelectorPrompt } from '@msdocs/strings';
import { html, render, TemplateResult } from './lit-html';

/**
 * The Azure Selector component of the DOCS website.
 * Azure Selector is a component consists of one or two dropdowns displayed inline. When all of the dropdowns has a valid
 * value, the website will jump to the target page. If the user selects a value in one of the dropdown, the other dropdown
 * will also populate all the available values accordign the first selected one.
 *
 * Dependencies:
 *     - urijs: Find the current selected item by comparing the URLs
 *     - docs.mainframe: Construct/Manipulate Azure DOM elements
 */

// I will do a unified implementation of the Azure Selector for both one-dropdown and two-dropdowns.
// For the single azure selector, the input HTML would like to be:
// <div class="op_single_selector">
//   <ul>
//     <li><a href="../a1.html">A1</a></li>
//     <li><a href="../a2.html">A2</a></li>
//     <li><a href="../a3.html">A3</a></li>
//   </ul>
// </div>
//
// For the double azure selectors, the input HTML would look like:
// <div class="op_multi_selector" title1="First Opt" title2="Second Opt">
//   <ul>
//     <li><a href="../c1.html">(A1 | B1)</a></li>
//     <li><a href="../c2.html">(A1 | B2)</a></li>
//     <li><a href="../c3.html">(A2 | B1)</a></li>
//     <li><a href="../c4.html">(A2 | B3)</a></li>
//     <li><a href="../c5.html">(A3 | B1)</a></li>
//     <li><a href="../c6.html">(A3 | B2)</a></li>
//     <li><a href="../c7.html">(A3 | B3)</a></li>
//   </ul>
// </div>

/**
 * First of all, we need to generate a Dictionary of the available options. For example, the mapping table of the single azure selector should be like:
 * {
 *     A1: { default: "../a1.html" },
 *     A2: { default: "../a2.html" },
 *     A3: { default: "../a3.html" }
 * }
 * But the mapping table for the double azure selectors would be a little bit compilicated:
 * {
 *     A1: {
 *         B1: "../c1.html",
 *         B2: "../c2.html"
 *     }
 *     A2: {
 *         B1: "../c3.html",
 *         B3: "../c4.html"
 *     },
 *     A3: {
 *         B1: "../c5.html",
 *         B2: "../c6.html",
 *         B3: "../c7.html"
 *     }
 * }
 */

interface AzureSelectorOption {
	title: string;
	href: string;
}

function generateOptionsMap(selectorDivElement: HTMLElement, isSingleSelector: boolean) {
	const optionsMap = {} as any;
	const selectorLinks = selectorDivElement.querySelectorAll<HTMLAnchorElement>('li > a');
	selectorLinks.forEach(link => {
		if (link.textContent) {
			const contents = isSingleSelector
				? (link.textContent = 'default')
				: link.textContent.trim().slice(1, -1).split('|');
			if (contents.length === 2) {
				const firstOption = contents[0].trim();
				const secondOption = contents[1].trim();
				const targetLink = link.href;
				if (firstOption && secondOption && targetLink) {
					if (!optionsMap[firstOption]) {
						optionsMap[firstOption] = {};
					}
					optionsMap[firstOption][secondOption] = targetLink;
				}
			}
		}
	});
	return optionsMap;
}

function getAbsoluteURI(url: string) {
	const link = document.createElement('a');
	link.href = url;
	if (link.host === '') {
		// IE doesn't populate all link properties when setting .href to a relative URL
		link.href = link.href;
	}
	return link.protocol + '//' + link.host + link.pathname;
}

function getCurrentSelectedOptions(optionsMap: any) {
	const browserUrlString = window.location.href.toLowerCase();
	const browser = getAbsoluteURI(browserUrlString);
	for (const mainOptionValue in optionsMap) {
		for (const secondaryOptionValue in optionsMap[mainOptionValue]) {
			const targetUrlString = optionsMap[mainOptionValue][secondaryOptionValue].toLowerCase();
			if (
				getAbsoluteURI(targetUrlString).localeCompare(browser, undefined, {
					sensitivity: 'base'
				}) === 0
			) {
				return [mainOptionValue, secondaryOptionValue];
			}
		}
	}
	return null;
}

function createDropdowns(
	selectorDivElement: HTMLElement,
	isSingleSelector: boolean,
	defaultOption: string
) {
	function dropdownItemTemplate(key: string) {
		return [key, key];
	}
	function jumpToUrl(targetUrl: string) {
		window.location.href = targetUrl;
	}

	const optionsMap = generateOptionsMap(selectorDivElement, isSingleSelector);
	const selectedOptions = getCurrentSelectedOptions(optionsMap);

	const container = document.createElement('div');
	container.classList.add('azureselector');

	// create first dropdown
	const selectorOneTitle = selectorDivElement.getAttribute('title1');
	createAzureSelectorDropdown(container, selectorOneTitle);
	const firstDropdown = container.querySelector<HTMLSelectElement>(`#${selectorOneTitle}`);
	populateDropdownOptions(firstDropdown, optionsMap, dropdownItemTemplate, false, defaultOption);
	if (selectedOptions) {
		firstDropdown.value = selectedOptions[0];
	}
	if (!isSingleSelector) {
		// create second dropdown
		const selectorTwoTitle = selectorDivElement.getAttribute('title2');
		createAzureSelectorDropdown(container, selectorTwoTitle);
		const secondDropdown = container.querySelector<HTMLSelectElement>(`#${selectorTwoTitle}`);
		firstDropdown.addEventListener('change', () => {
			populateDropdownOptions(
				secondDropdown,
				firstDropdown.value ? optionsMap[firstDropdown.value as string] : {},
				dropdownItemTemplate,
				false,
				defaultOption
			);
		});
		secondDropdown.addEventListener('change', () => {
			if (firstDropdown.value && secondDropdown.value) {
				jumpToUrl(optionsMap[firstDropdown.value as string][secondDropdown.value as string]);
			}
		});
		populateDropdownOptions(
			secondDropdown,
			firstDropdown.value ? optionsMap[firstDropdown.value as string] : {},
			dropdownItemTemplate,
			false,
			defaultOption
		);
		if (selectedOptions) {
			secondDropdown.value = selectedOptions[1];
		}
	} else {
		firstDropdown.addEventListener('change', () => {
			if (firstDropdown.value) {
				jumpToUrl(optionsMap[firstDropdown.value as string].default);
			}
		});
	}
	// add the azure selector to the page
	selectorDivElement.insertAdjacentElement('afterend', container);
}

export function renderAzureSelectors() {
	const defaultOption = loc_azureSelectorPrompt;
	const opSingleSelectorDiv = document.querySelector<HTMLDivElement>('.op_single_selector');
	const opDoubleSelectorDiv = document.querySelectorAll<HTMLDivElement>('.op_multi_selector');

	if (opSingleSelectorDiv) {
		renderSingleSelectorDropdown(opSingleSelectorDiv);
	}

	opDoubleSelectorDiv.forEach(selector => {
		createDropdowns(selector, false, defaultOption);
	});
}

/**
 * Render the azure single selector dropdown
 *
 * @param opSingleSelectorDiv The HTML Div Element of the single selector
 */
export function renderSingleSelectorDropdown(opSingleSelectorDiv: HTMLDivElement) {
	const opSingleSelectorParent = opSingleSelectorDiv.parentNode;
	const singleSelectorTitle = opSingleSelectorDiv.getAttribute('title1') as string;
	const singleSelectorList = Array.from(
		document.querySelectorAll('.op_single_selector > ul > li')
	) as HTMLLIElement[];
	const singleSelectorAnchorList = Array.from(
		document.querySelectorAll('.op_single_selector > ul > li > a')
	) as HTMLAnchorElement[];

	const dropdownItems: AzureSelectorOption[] = [];

	singleSelectorAnchorList.map(item => {
		const newDropdownItem = {
			title: item.textContent,
			href: item.href
		};
		dropdownItems.push(newDropdownItem);
	});

	// Determine whether the displayed dropdown option will be a passed default value or related to the selected page
	const defaultDropdownSelection = singleSelectorList[0].firstElementChild.getAttribute('href')
		? findDefaultSelectedOption(dropdownItems)
		: singleSelectorList.shift().textContent;

	const navContainer = document.createElement('nav');
	navContainer.classList.add('has-margin-top-small');
	navContainer.setAttribute('role', 'navigation');
	navContainer.setAttribute('aria-label', singleSelectorTitle || 'Dropdown menu');

	opSingleSelectorParent.replaceChild(navContainer, opSingleSelectorDiv);

	const dropdownTemplateResult = createSingleSelectorDropdownHtml(
		defaultDropdownSelection,
		dropdownItems,
		singleSelectorTitle
	);
	render(dropdownTemplateResult, navContainer);
}

/**
 * Find the default dropdown menu item
 *
 * @param dropdownItems Data containing dropdown list text and href
 */
export function findDefaultSelectedOption(dropdownItems: AzureSelectorOption[]) {
	const locationPathname = decodeURI(location.pathname).toLowerCase();
	for (const item of dropdownItems) {
		const itemPathname = new URL(item.href, location.href).pathname.toLowerCase();
		if (itemPathname === locationPathname) {
			return item.title;
		}
	}

	return dropdownItems[0].title;
}

/**
 * Render the HTML for the azure single selector dropdown
 *
 * @param dropdownItems Data containing dropdown list text and href
 * @param defaultDropdownSelection Default dropdown selection
 * @param singleSelectorTitle If selector contains a title
 */
export function createSingleSelectorDropdownHtml(
	defaultDropdownSelection: string,
	dropdownItems: AzureSelectorOption[],
	singleSelectorTitle?: string
): TemplateResult {
	let selectorTitle = html``;
	const dropdownLinksTemplates = [];

	if (singleSelectorTitle) {
		selectorTitle = html`<span id="azure-single-selector-label" class="is-vertically-aligned-middle"
			>${singleSelectorTitle}</span
		>`;
	}

	const linkPadding =
		'has-padding-top-extra-small has-padding-bottom-extra-small has-padding-left-small has-padding-right-small';

	for (const item of dropdownItems) {
		dropdownLinksTemplates.push(html`
			<li data-bi-name="azure-selector" class="is-unstyled">
				<a class="is-block has-inner-focus has-text-wrap ${linkPadding}" href="${item.href}">
					${item.title}
				</a>
			</li>
		`);
	}

	return html` ${selectorTitle}
		<div class="dropdown is-inline-block">
			<button
				id="azure-single-selector-dropdown"
				type="button"
				class="button is-small dropdown-trigger"
				aria-controls="azure-selector-menu"
				aria-expanded="false"
				aria-describedby="azure-single-selector-label"
			>
				<span>${defaultDropdownSelection}</span>
				<span class="icon" aria-hidden="true">
					<span class="docon docon-chevron-down-light expanded-indicator"></span>
				</span>
			</button>
			<ul
				id="azure-selector-menu"
				role="menu"
				class="dropdown-menu has-min-width-300 has-overflow-auto is-size-small has-margin-none has-margin-top-extra-small"
			>
				${dropdownLinksTemplates}
			</ul>
		</div>`;
}

function createAzureSelectorDropdown(azureSelectorContainer: HTMLElement, title: string) {
	const dropdownHolder = document.createElement('div');
	const azureDropdown = html`
		${title ? html`<label>${title}</label>` : ''}
		<select id="${title}" data-bi-name="azure-selector"></select>
	`;
	render(azureDropdown, dropdownHolder);
	azureSelectorContainer.appendChild(dropdownHolder);
}

function populateDropdownOptions(
	selectElement: HTMLSelectElement,
	optionsModel: any,
	itemTemplate: Function,
	noNullOption: boolean,
	defaultOption: string
) {
	const options = [];

	if (!noNullOption) {
		options.push(html`<option disabled selected value="">${defaultOption}</option>`);
	}
	for (const key in optionsModel) {
		const item = itemTemplate(key, optionsModel[key]);
		if (item && item.length === 2) {
			options.push(html`<option value="${item[1]}">${item[0]}</option>`);
		}
	}
	render(options, selectElement);
}

import { SearchViewModel } from '../view-model';
import { html } from '../../../lit-html';
import { loc_clearAll } from '@msdocs/strings';

export function clearAllFilterButton<TResult>(vm: SearchViewModel<TResult>) {
	if (!vm.hasActiveFilter && !vm.hideCompleted) {
		return '';
	}
	return html`
		<button
			data-bi-name="clear-facets"
			@click=${() => vm.clearFacets()}
			class=" has-padding-none is-inline has-inner-focus has-margin-none has-appearance-none has-text-primary has-border-none has-background-transparent"
		>
			<span>${loc_clearAll}</span>
		</button>
	`;
}

import {
	loc_close,
	loc_searchFilters,
	loc_allFilters,
	loc_viewResultsWithCountFormat,
	loc_clearAll
} from '@msdocs/strings';
import { TemplateResult } from 'lit-html';
import { msDocs } from '../../../globals';
import { html } from '../../../lit-html';
import { TaxonomyLabel } from '../../../name-maps/taxonomy';
import { Modal } from '../../modal/modal';
import { FacetGroupViewModel } from '../facet-group';
import { SearchViewModel } from '../view-model';
import { facetListItemTemplate } from './facets';
import { numberFormatCompact } from '../../../number-format';
import { categoryFacetTemplate } from './category';
import { hideCompletedMobileTemplate } from './hide-completed';

export function mobileFilterButtonTemplate<TResults>(vm: SearchViewModel<TResults>) {
	return html`
		<button
			class="button is-transparent is-small has-inverted-border"
			data-bi-name="mobile-menu-button"
			@click=${() => {
				displayMobileMenu();
				vm.notifyPropertyChanged();
			}}
		>
			<span class="icon" aria-hidden="true">
				<span class="docon docon-filter-settings"></span>
			</span>
			<span>${loc_searchFilters}</span>
		</button>
	`;
}

function getTitleByMobileView<TResults>(vm: SearchViewModel<TResults>) {
	if (vm.mobileMenuView in vm.facetGroups) {
		return vm.facetGroups[vm.mobileMenuView as TaxonomyLabel].name;
	}

	return loc_searchFilters;
}

export let moduleMenuContent: HTMLDivElement;

let mobileSearchModal: Modal;

export async function displayMobileMenu() {
	if (!moduleMenuContent) {
		moduleMenuContent = document.createElement('div');
	}
	if (!mobileSearchModal) {
		mobileSearchModal = new Modal(moduleMenuContent);
	}
	await mobileSearchModal.show();
}

export function mobileMenuTemplate<TResults>(vm: SearchViewModel<TResults>) {
	return html`
		<div
			class="modal-content is-docked-left is-flex is-flex-column has-overflow-hidden"
			data-bi-name="mobile-search-menu"
		>
			${mobileHeaderTemplate(vm)} ${mobileMenuContent(vm)}
			<div class="has-flex-grow"></div>
			${mobileMenuButtonsTemplate(vm)}
		</div>
	`;
}

function mobileHeaderTemplate<TResults>(vm: SearchViewModel<TResults>) {
	return html`
		<div class="is-flex is-size-large has-border-bottom has-flex-justify-content-space-between">
			<div class="has-padding-medium has-flex-align-items-center">
				<h4 class="title is-4">
					${getTitleByMobileView(vm)}
				</h4>
			</div>
			<button
				type="button"
				class="nav-bar-button modal-close has-border-left"
				data-bi-name="close"
				?hidden=${false}
			>
				<span class="visually-hidden">${loc_close}</span>
				<span class="icon is-small">
					<span class="docon docon-math-multiply" aria-hidden="true"></span>
				</span>
			</button>
		</div>
		${mobileSubheader(vm)}
	`;
}

function mobileSubheader<TResults>(vm: SearchViewModel<TResults>) {
	if (vm.mobileMenuView === 'top') {
		return '';
	}
	return html`
		<div
			class="is-flex has-border-bottom has-flex-justify-content-space-between has-padding-top-small has-padding-bottom-small has-padding-right-none has-padding-left-none has-flex-align-items-center"
		>
			<button
				class="button is-text has-margin-left-small"
				data-bi-name="back"
				@click=${() => vm.selectMobileView('top')}
			>
				<span class="icon has-text-subtle">
					<span
						class="docon docon-arrow-${msDocs.data.userDir === 'ltr' ? 'left' : 'right'}"
					></span>
				</span>
				<span class="has-text-weight-semibold">
					${loc_allFilters}
				</span>
			</button>
		</div>
	`;
}

function mobileMenuContent<TResults>(vm: SearchViewModel<TResults>) {
	switch (vm.mobileMenuView) {
		case 'top':
			return mobileMenuTopView(vm);
		default:
			return mobileFacetGroupTemplate(vm.facetGroups[vm.mobileMenuView]);
	}
}

function mobileMenuTopView<TResults>(vm: SearchViewModel<TResults>) {
	return html`
		<ul class="is-flex is-flex-column">
			${hideCompletedMobileTemplate(vm)} ${mobileFacetGroupSelectors(vm)}
		</ul>
	`;
}

function mobileFacetGroupSelectors<TResults>(vm: SearchViewModel<TResults>) {
	const templates: TemplateResult[] = [];
	for (const name in vm.facetGroups) {
		const label = name as TaxonomyLabel;
		templates.push(html`
			<li>
				<button
					class="is-full-width is-flex has-padding-left-medium has-padding-right-medium has-padding-top-small has-padding-bottom-small has-inner-focus has-flex-align-items-center has-flex-justify-content-space-between has-border-none is-size-small"
					@click=${() => vm.selectMobileView(label)}
					data-bi-name="select-facet"
				>
					<span>${vm.facetGroups[label].name}</span>
					<span class="icon has-text-subtle">
						<span
							class="docon docon-chevron-${msDocs.data.userDir === 'ltr' ? 'right' : 'left'}-light"
						></span>
					</span>
				</button>
			</li>
		`);
	}
	return templates;
}

function mobileFacetGroupTemplate(gvm: FacetGroupViewModel) {
	return html` <ul class="has-padding-medium is-vertically-scrollable">
		${gvm.key === 'category' // Content categories do no function like normal facets
			? categoryFacetTemplate(gvm, 'mobile')
			: facetListItemTemplate(gvm.facets)}
	</ul>`;
}

function mobileMenuButtonsTemplate<TResults>(vm: SearchViewModel<TResults>) {
	return html`
		<div class="has-padding-small has-border-top">
			<div class="is-flex has-flex-wrap">
				<button
					class="button is-small is-primary modal-close has-flex-grow has-margin-extra-small"
					data-bi-name="view-results"
				>
					${loc_viewResultsWithCountFormat.replace(
						'{totalResults}',
						numberFormatCompact.format(vm.resultsCount)
					)}
				</button>
				<button
					class="button is-small has-flex-grow has-margin-extra-small"
					data-bi-name="clear-facets"
					@click=${() => vm.clearFacets()}
				>
					${loc_clearAll}
				</button>
			</div>
		</div>
	`;
}

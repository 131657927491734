import { fetchPlatform } from '../apis/monikers';
import { getMeta } from '../meta';

export const platformId: string | null = getMeta('platform') || getMeta('apiPlatform') || null;

let platformPromise: Promise<Api.RefPlatform>;

export function getPlatform() {
	if (platformPromise === undefined) {
		platformPromise = fetchPlatform(platformId).then(flattenPlatform);
	}
	return platformPromise;
}

function flattenPlatform(platform: Api.Platform): Api.RefPlatform {
	const { platformId, families } = platform;
	const packages: Api.RefPackage[] = [];
	const packagesByMoniker: { [moniker: string]: Api.RefPackage } = {};
	const products: Api.Product[] = [];
	for (const family of families) {
		for (const product of family.products) {
			products.push(product);
			for (const pckg of product.packages) {
				const {
					moniker,
					displayName,
					versionDisplayName,
					isDefault,
					isDeprecated,
					isPrerelease
				} = pckg;
				const pkg = {
					platform,
					family,
					product,
					moniker,
					displayName,
					versionDisplayName,
					isDefault,
					isDeprecated,
					isPrerelease
				};
				packages.push(pkg);
				packagesByMoniker[pkg.moniker] = pkg;
			}
		}
	}
	return { platformId, families, products, packages, packagesByMoniker };
}

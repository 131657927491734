/**
 * Loads a library.
 * Example:
 *   loadLibary('jsll.js').then(() => { ... });
 *   loadLibary('jsll.js', 'awa').then(awa => { ... });
 * @param url The library's url. Absolute and relative urls are permitted.
 * @param globalName Optional: The name of the global variable the library exports.
 */
export function loadLibrary<T>(url: string, globalName?: string): Promise<T> {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script');
		script.src = url;
		script.async = true;
		script.defer = true;
		script.onload = resolve;
		script.onerror = () => {
			reject(`Failed to load ${url}`);
		};
		(document.body || document.head).appendChild(script);
	}).then(() => {
		if (globalName === undefined) {
			return undefined;
		}
		if ((window as any)[globalName] === undefined) {
			throw new Error(`${url} loaded successfully but ${globalName} is undefined.`);
		}
		return (window as any)[globalName];
	});
}

import {
	loc_areYouSure,
	loc_cancel,
	loc_close,
	loc_itemDeleteConfirmationMessageFormat,
	loc_yesDelete
} from '@msdocs/strings';
import { html, render } from '../lit-html';
import { Modal } from '../components/modal/modal';

/**
 * The initial assessment delete session modal template
 */
export function createAssessmentDeleteSessionModal(assessmentTitle: string) {
	const title = loc_itemDeleteConfirmationMessageFormat.replace('{itemTitle}', assessmentTitle);
	const modalContent = document.createElement('div');
	modalContent.setAttribute('id', 'delete-assessment-modal');
	render(
		html`
			<div class="modal-card">
				<div class="modal-card-head has-padding-none">
					<button
						class="modal-close modal-card-close-button is-large is-sticky"
						aria-label="${loc_close}"
					></button>
				</div>
				<section class="modal-card-body has-padding-large has-flex-justify-content-center">
					<h2 class="title is-3 has-margin-bottom-medium has-margin-right-small">
						${loc_areYouSure}
					</h2>
					<p class="is-size-small has-padding-bottom-large">${title}</p>
					<div class="buttons">
						<button id="save-collection" class="button is-primary is-small" type="submit" autofocus>
							${loc_yesDelete}
						</button>
						<button class="modal-close cancel-button button is-small" type="button">
							${loc_cancel}
						</button>
					</div>
				</section>
			</div>
		`,
		modalContent
	);

	return new Modal(modalContent);
}

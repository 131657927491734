import { loc_levelFormat, loc_profile, loc_xp } from '@msdocs/strings';
import { gameApi, GameStatus } from '../apis/game-status';
import { getProfileUrlString, user } from '../auth/user';
import { features } from '../environment/features';
import { EventBus } from '../event-bus';
import { contentLoaded, window } from '../globals';

export const gameBus = new EventBus();
export let currentLevel: number;
export let animateProgressBar: boolean = false;
export let levelStatusText: HTMLSpanElement;
export let progressElt: HTMLProgressElement;
export let levelStatusPoints: HTMLSpanElement;

let animationHandle = 0;
let progressPoints = 0;
let animateValue = 0;
const animateRate = 0.125;
let levelUp = false;

export const levelStatusTemplate = `
	<a href="#" class="is-absolute-fills has-z-index-one" aria-label="${loc_profile}"></a>
	<span id="level-status-text" class="is-size-extra-small has-text-wrap is-inline-truncated is-uppercase has-text-weight-semibold"></span>
	<span id="level-status-progress-container" class="hexagonal-progress">
		<progress id="level-status-progress" class="progress is-hidden-mobile is-inline-block is-relative is-success" value="1" max="100">1%</progress>
		<span class="hexagonal-progress-marker"></span>
	</span>
	<span id="level-status-points" class="is-size-extra-small has-text-wrap is-inline-truncated is-uppercase">
	</span>
`;

export function showLevelStatus() {
	if (!features.gamification || features.chromeless) {
		return;
	}

	// show it (change to when authed)
	const levelStatusContainer = document.querySelector('#level-status-container') as HTMLLIElement;
	const levelStatus = document.querySelector('#level-status') as HTMLElement;

	if (!levelStatusContainer || !levelStatus) {
		return;
	}

	levelStatus.innerHTML = levelStatusTemplate;

	contentLoaded.then(() => {
		user.whenAuthenticated().then(() => {
			levelStatusText = document.querySelector('#level-status-text') as HTMLSpanElement;
			progressElt = document.querySelector('#level-status-progress') as HTMLProgressElement;
			levelStatusPoints = document.querySelector('#level-status-points') as HTMLSpanElement;

			const levelStatusAnchor = levelStatus.querySelector('a') as HTMLAnchorElement;
			levelStatusAnchor.setAttribute('href', getProfileUrlString(user.userName));

			gameApi.getStatus(user.userId).then(status => {
				updateGameStatus(status);
				animateProgressBar = true; // always animate after page loads
				levelStatusContainer.classList.remove('is-hidden');
			});
		});
	});
}

export function setUserXp(
	gameStatus: GameStatus,
	levelStatusText: HTMLSpanElement,
	progressElt: HTMLProgressElement,
	levelstatusPoints: HTMLSpanElement
) {
	if (gameStatus.level.levelNumber > currentLevel) {
		levelUp = true;
	}

	currentLevel = gameStatus.level.levelNumber;

	setLevelStatusText(levelStatusText, gameStatus.level.levelNumber);
	setLevelStatusBar(progressElt, gameStatus, animateProgressBar);
	setLevelStatusPoints(
		levelstatusPoints,
		gameStatus.level.pointsHigh - gameStatus.level.pointsLow,
		gameStatus.currentLevelPointsEarned
	);
}

export function setLevelStatusText(elt: HTMLSpanElement, level: number) {
	elt.textContent = `${loc_levelFormat.replace('{number}', level.toString())}`;
}

export function setLevelStatusBar(
	elt: HTMLProgressElement,
	gameStatus: GameStatus,
	animate: boolean
) {
	progressPoints = getLevelPointsOutOf100(
		gameStatus.level.pointsHigh,
		gameStatus.level.pointsLow,
		gameStatus.currentLevelPointsEarned
	);

	elt.textContent = `${progressPoints}%`;
	if (!animate) {
		window.cancelAnimationFrame(animationHandle);
		elt.value = animateValue = progressPoints;
		return;
	}

	incrementLevelStatusBar(elt);
}

export function incrementLevelStatusBar(elt: HTMLProgressElement) {
	if (!animationHandle) {
		animationHandle = animate(elt);
	}
}

function animate(elt: HTMLProgressElement) {
	if (levelUp || elt.value < progressPoints) {
		if (Math.floor(animateValue + animateRate) >= 100) {
			levelUp = false;
		}

		animateValue = animateValue + animateRate;

		elt.value = Math.floor(animateValue + animateRate) % 100;

		return window.requestAnimationFrame(() => {
			animationHandle = animate(elt);
		});
	}

	return 0;
}

export function setLevelStatusPoints(
	elt: HTMLSpanElement,
	pointsHigh: number,
	totalPoints: number
) {
	elt.innerHTML = `<span class="has-text-weight-semibold">${totalPoints}</span>/${loc_xp.replace(
		'{totalXP}',
		pointsHigh.toString()
	)}`;
}

export function getLevelPointsOutOf100(
	pointsHigh: number,
	pointsLow: number,
	currentLevelPointsEarned: number
) {
	const levelRange = pointsHigh - pointsLow;
	const levelCompletedRange = currentLevelPointsEarned / levelRange;
	return Math.floor(levelCompletedRange * 100);
}

// dev functions

export function updateGameStatus(status: GameStatus) {
	if (features.chromeless) {
		return;
	}

	setUserXp(status, levelStatusText, progressElt, levelStatusPoints);
}

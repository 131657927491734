import {
	loc_bookmark,
	loc_collections_addToCollection,
	loc_completed,
	loc_continue,
	loc_inProgress,
	loc_learningPath,
	loc_module,
	loc_remaining,
	loc_start
} from '@msdocs/strings';
import {
	getUserProgressByUids,
	ModuleAndPathSummary,
	ProgressItem,
	StandardProgressResponse
} from '../apis/learn';
import { PageRatingResult } from '../apis/rating';
import { user } from '../auth/user';
import { createSearchResultRatingTemplate } from '../content-browser/rating-template';
import { msDocs } from '../globals';
import { html, render, TemplateResult, unsafeHTML, until } from '../lit-html';
import { getMetas } from '../meta';
import { levelNames } from '../name-maps/level';
import { allProductNames } from '../name-maps/product';
import { roleNames } from '../name-maps/roles';
import { displayProgress } from '../pages/learning-path';
import { countChildrenCompleted, UserModulePathSummary } from '../profile/profile-progress';
import { calculateRatingFields } from '../rating/rating-dropdown';
import { convertMinsToHrsMins } from '../time-format';
import { brandedHeader } from './branded-header';
import { taxonomyNames } from '../name-maps/taxonomy';

interface LearnProductInfo {
	paths: ModuleAndPathSummary[];
	modules: ModuleAndPathSummary[];
}

export function organizeLearnCards(summaries: ModuleAndPathSummary[]): LearnProductInfo {
	return {
		paths: summaries ? summaries.filter(item => item.type === 'learningPath') : [],
		modules: summaries ? summaries.filter(item => item.type === 'module') : []
	};
}

export async function getUserProgressFromMetaTags() {
	return await getUserProgressByUids(getMetas('learn_item'));
}

export function renderLearnCards(
	container: HTMLElement,
	learnInfo: LearnProductInfo,
	userProgress: Promise<StandardProgressResponse>,
	pageTemplate: PageTemplate,
	ratings?: PageRatingResult[]
) {
	const { paths, modules } = learnInfo;

	switch (pageTemplate) {
		case 'LearnHome':
		case 'LearnProduct':
			renderPathOrModuleCards(container, paths, userProgress);
			renderModuleListCards(container, modules, userProgress);
			break;
		case 'TopicLanding':
			renderPathOrModuleCards(container, paths, userProgress, ratings);
			renderPathOrModuleCards(container, modules, userProgress, ratings);
			break;
		case 'Certification':
		case 'Examination':
			renderPathListCards(container, paths, userProgress);
			break;
		default:
			throw new Error(`Learn cards not supported for "${pageTemplate}" page template`);
	}
}

type cardFallback = 'A' | 'ARTICLE' | 'LI';
/**
 * Normalizing function to allow for cards to render properly before all templates have been rebuilt
 * Remove after rebuilding certs, and all learn after this ships.
 * @param element The current card element with learnUid. Should be anchor, li (deprecated) or <article>, current.
 */
function normalizeCardElement(element: HTMLElement) {
	const uid = element.dataset.learnUid;
	switch (element.tagName as cardFallback) {
		case 'A':
			// create new element
			const article = document.createElement('article');
			// add required classes
			article.classList.add('card', 'is-horizontal', 'has-loading-skeleton');
			article.dataset.learnUid = uid;
			const grid = element.parentElement.parentElement as HTMLUListElement;
			if (grid) {
				grid.classList.remove('has-border');
				grid.classList.add('grid', 'is-horizontal');
			}
			// insert the new element
			element.insertAdjacentElement('beforebegin', article);
			// remove the old one
			element.remove();
			return article;
		case 'ARTICLE':
			return element;
		case 'LI':
			// create new element
			const liArticle = document.createElement('article');
			// remove all its children
			Array.from(element.children).forEach(elt => elt.remove());
			liArticle.classList.add('card', 'is-branded', 'has-loading-skeleton');
			liArticle.dataset.learnUid = uid;
			// insert new element inside li
			element.insertAdjacentElement('afterbegin', liArticle);
			return liArticle;
		default:
			throw new Error('unsupported card element type');
	}
}

//card Learning Path
export function renderPathOrModuleCards(
	container: HTMLElement,
	info: ModuleAndPathSummary[],
	userProgress: Promise<StandardProgressResponse>,
	ratings?: PageRatingResult[]
) {
	info.forEach(pathSummary => {
		let card: HTMLElement = container.querySelector(`[data-learn-uid="${pathSummary.uid}"]`);
		if (!card) {
			return;
		}
		card = normalizeCardElement(card);
		card.classList.remove('has-loading-skeleton');
		card.removeAttribute('aria-label');

		const template = createCardHTML(pathSummary, userProgress, ratings);
		render(template, card);
	});
}

//cards Learning Path Cert/Exam
export function renderPathListCards(
	container: HTMLElement,
	info: UserModulePathSummary[],
	userProgress: Promise<StandardProgressResponse>
) {
	let isStartButtonAdded = false;
	info.forEach(pathSummary => {
		let card: HTMLElement = container.querySelector(`[data-learn-uid="${pathSummary.uid}"]`);
		if (!card) {
			return;
		}
		card = normalizeCardElement(card);
		card.classList.remove('has-loading-skeleton');
		card.removeAttribute('aria-label');
		card.dataset.learnStatus = pathSummary.status;

		const addStartButton = checkStartButtonNeeded(isStartButtonAdded, pathSummary);
		if (addStartButton) {
			isStartButtonAdded = true;
		}

		const template = createListCardHTML(pathSummary, userProgress, '', true, addStartButton);
		render(template, card);
		card.setAttribute(
			'aria-labelledby',
			`${pathSummary.uid}_title ${pathSummary.uid}_metadata ${pathSummary.uid}_tags`
		);

		displayProgressLabel(card, pathSummary, userProgress);
	});
}

function checkStartButtonNeeded(
	isStartButtonAdded: boolean,
	pathSummary: UserModulePathSummary = null
) {
	if (isStartButtonAdded) {
		// If already a Start Button added, no need to add
		return false;
	}

	if (msDocs.data.pageTemplate !== 'Certification' && msDocs.data.pageTemplate !== 'Examination') {
		return false;
	}

	if (!user.isAuthenticated) {
		// If the user is unauthenticated (no progress), we should show start button on the first path
		return true;
	}

	if (pathSummary.status !== 'completed') {
		// When authenticated Start/Continue button should appear on first incomplete or in-progress learning path.
		return true;
	}

	return false;
}

//cards Module
export function renderModuleListCards(
	container: HTMLElement,
	info: ModuleAndPathSummary[],
	userProgress: Promise<StandardProgressResponse>
) {
	info.forEach(moduleSummary => {
		let card: HTMLElement = container.querySelector(`[data-learn-uid="${moduleSummary.uid}"]`);
		if (!card) {
			return;
		}
		card = normalizeCardElement(card);
		card.classList.remove('has-loading-skeleton');
		card.removeAttribute('aria-label');

		const template = createListCardHTML(moduleSummary, userProgress, '', true);
		render(template, card);

		card.setAttribute(
			'aria-labelledby',
			`${moduleSummary.uid}_title ${moduleSummary.uid}_metadata ${moduleSummary.uid}_tags`
		);

		displayProgressLabel(card, moduleSummary, userProgress);
	});
}

export function displayProgressLabel(
	progressLabelHolder: HTMLElement,
	modulePathInfo: ModuleAndPathSummary,
	userProgress: Promise<StandardProgressResponse>
) {
	const progressLabel = progressLabelHolder.querySelector(`.progress-label`);
	if (!progressLabel) {
		return;
	}

	render(
		html` ${until(
			userProgress
				.then(items => {
					const item = findUserProgressForModulePath(modulePathInfo.uid, items);
					progressLabel.classList.add(
						item.status === 'inProgress' ? 'is-in-progress' : 'is-complete'
					);
					progressLabel.classList.remove('is-hidden');
					return html`
						${item.status === 'inProgress' ? html`${loc_inProgress}` : html`${loc_completed}`}
						<span class="progress-icon docon" role="presentation"></span>
					`;
				})
				.catch(() => '')
		)}`,
		progressLabel
	);
}

export function findUserProgressForModulePath(
	uid: string,
	allUserProgress: StandardProgressResponse
): ProgressItem {
	return allUserProgress.find(progress => (progress ? progress.uid === uid : undefined));
}

export function createTagsMarkup(itemSummary: ModuleAndPathSummary): TemplateResult {
	const { levels, roles, products } = itemSummary;

	const productName = products.length > 0 ? allProductNames[products[0]] : undefined;
	const roleName = roles.length ? roleNames[roles[0]] : undefined;
	const levelName = levels.length ? levelNames[levels[0]] : undefined;

	const level = levelName ? html`<li class="tag is-small">${levelName}</li>` : '';
	const role = roleName ? html`<li class="tag is-small">${roleName}</li>` : '';
	const product = productName ? html`<li class="tag is-small">${productName}</li>` : '';
	return html` ${level} ${role} ${product} `;
}

export const bookmarkButton = (title: string, url: string): TemplateResult =>
	html`<button
		type="button"
		class="bookmark button is-text has-text-primary has-inner-focus is-small has-margin-right-none is-icon-only"
		data-bi-name="bookmarks"
		data-list-item-title="${title}"
		data-list-item-url="${url}"
		data-list-type="bookmarks"
	>
		<span class="icon" aria-hidden="true">
			<span class="docon docon-single-bookmark"></span>
		</span>
		<span class="is-visually-hidden">${loc_bookmark}</span>
	</button>`;

export const collectionButton = (title: string, url: string): TemplateResult => {
	return html`
		<button
			type="button"
			class="collection button is-text has-text-primary has-inner-focus is-small is-icon-only"
			data-bi-name="collection"
			data-list-item-title="${title}"
			data-list-item-url="${url}"
			data-list-type="collection"
		>
			<span class="icon" aria-hidden="true">
				<span class="docon docon-circle-addition"></span>
			</span>
			<span class="is-visually-hidden">${loc_collections_addToCollection}</span>
		</button>
	`;
};

const createProgressBarFromProgressItem = (
	itemSummary: ModuleAndPathSummary,
	progress: ProgressItem | undefined
): TemplateResult | '' => {
	if (!progress || progress.status === 'notStarted') {
		return '';
	}

	if (progress.status === 'inProgress') {
		const percentageComplete =
			Math.floor(
				((itemSummary.durationInMinutes - progress.remainingTime) / itemSummary.durationInMinutes) *
					100
			) + '%';
		return html`<progress
				class="progress is-success is-extra-small"
				value="${itemSummary.durationInMinutes - progress.remainingTime}"
				max="${itemSummary.durationInMinutes}"
			></progress
			><span class="progress-label">${percentageComplete}</span>`;
	}

	// completed
	return html`
		<span class="progress-label"
			>${loc_completed}<span class="docon docon-check" role="presentation"></span
		></span>
	`;
};

export function createCardHTML(
	itemSummary: ModuleAndPathSummary,
	userProgress: Promise<StandardProgressResponse>,
	ratings?: PageRatingResult[]
): TemplateResult {
	const { uid, iconUrl, title, url, products } = itemSummary;

	const footer = html` ${until(
		userProgress
			.then(items => {
				const item = items.find(item => item.uid === itemSummary.uid);
				return html` <div class="card-footer">
					<div class="card-footer-item">
						${item !== undefined ? createProgressBarFromProgressItem(itemSummary, item) : ''}
					</div>
					<div class="card-footer-item">
						<div class="buttons">
							${bookmarkButton(title, url)} ${collectionButton(title, url)}
						</div>
					</div>
				</div>`;
			})
			.catch(() => '')
	)}`;

	const rating = getModuleAndPathSummaryRatingFields(ratings, itemSummary);
	const ratingContainerHTML = createSearchResultRatingTemplate(rating);

	return html`
		<div class="card-header ${brandedHeader(products)}">
			<div class="card-header-image">
				<img role="presentation" src="${iconUrl}" alt="" />
			</div>
		</div>
		<div class="card-content">
			<p class="card-content-super-title">
				${itemSummary.type === 'learningPath' ? loc_learningPath : loc_module}
			</p>
			<a href="${itemSummary.url}" class="card-content-title">
				<h3 id="${uid}_title" class="is-size-5 has-margin-none">${title}</h3>
			</a>
			<ul class="card-content-metadata">
				<li>
					${itemSummary.durationInMinutes
						? convertMinsToHrsMins(itemSummary.durationInMinutes)
						: ''}
				</li>
				${ratingContainerHTML}
			</ul>
			<ul id="${uid}_tags" class="tags is-hidden-mobile is-hidden-tablet-only has-margin-top-small">
				${createTagsMarkup(itemSummary)}
			</ul>
		</div>
		${footer}
	`;
}

/**
 * Renders list card html for Learn Product cards and Tech Profile progress cards
 * @param itemSummary UserModulePathSummary (extends ModuleAndPathSummary)
 * @param serverBase Server base path to add before url, used for techprofile
 */
export function createListCardHTML(
	itemSummary: UserModulePathSummary,
	userProgress: Promise<StandardProgressResponse>,
	serverBase: string = '',
	hasTags: boolean = true,
	hasStartButton: boolean = false
): TemplateResult {
	const {
		uid,
		iconUrl,
		title,
		childCount,
		levels,
		roles,
		products,
		type,
		childUIds,
		url
	} = itemSummary;

	// Show remaining time if exists
	const remainingTime = itemSummary.remainingTime !== undefined ? itemSummary.remainingTime : 0;

	const tagHTML: TemplateResult | '' = hasTags
		? html`<ul
				id="${uid}_tags"
				class="tags is-hidden-mobile is-hidden-tablet-only has-margin-top-small"
		  >
				${levels.length > 0
					? unsafeHTML(
							`<li class="tag is-small is-hidden-desktop-only">${
								taxonomyNames.levels[levels[0]]
							} </li>`
					  )
					: ''}
				${roles.length > 0
					? unsafeHTML(`<li class="tag is-small">${taxonomyNames.roles[roles[0]]} </li>`)
					: ''}
				${products.length > 0
					? unsafeHTML(
							`<li class="tag is-small is-hidden-tablet-only is-hidden-desktop-only is-hidden-widescreen-only">${
								taxonomyNames.products[products[0]]
							} </li>`
					  )
					: ''}
		  </ul>`
		: '';

	const footer = html` ${until(
		userProgress
			.then(items => {
				const item = items.find(item => item.uid === itemSummary.uid);
				return html` <div class="card-footer">
					<div class="card-footer-item">
						${item !== undefined ? createProgressBarFromProgressItem(itemSummary, item) : ''}
					</div>
					<div class="card-footer-item">
						<div class="buttons">
							${bookmarkButton(title, url)} ${collectionButton(title, url)}
						</div>
					</div>
				</div>`;
			})
			.catch(() => '')
	)}`;

	const startButtonText = itemSummary.status === 'inProgress' ? loc_continue : loc_start;
	const startButtonHtml: TemplateResult | '' = hasStartButton
		? html`<p class="has-margin-top-medium has-margin-bottom-small">
				<a
					href="${itemSummary.url}"
					id="start-learn-path"
					class="button is-primary centered-with-icon"
					data-bi-name="${loc_start}"
				>
					<span>${startButtonText}</span>
					<span class="icon" aria-hidden="true">
						<span class="docon docon-chevron-right-light"></span>
					</span>
				</a>
		  </p>`
		: '';

	return html`
		<div class="card-header" aria-hidden="true">
			<div class="card-header-image">
				<img role="presentation" src="${serverBase}${iconUrl}" alt="" />
			</div>
		</div>
		<div class="card-content">
			<p class="card-content-super-title has-margin-top-none">
				${itemSummary.type === 'learningPath' ? loc_learningPath : loc_module}
			</p>
			<a href="${itemSummary.url}" class="card-content-title"
				><h3 id="${uid}_title" class="is-size-5 has-margin-none">${title}</h3></a
			>
			<ul id="${uid}_metadata" class="card-content-metadata has-margin-top-small has-bullets">
				${remainingTime !== 0
					? unsafeHTML(
							`<li class="has-margin-bottom-none has-margin-bottom-extra-small-tablet">${convertMinsToHrsMins(
								remainingTime
							)} ${loc_remaining}</li>`
					  )
					: ``}
				${until(
					userProgress
						.then(items => {
							return html` <li class="has-margin-bottom-none has-margin-bottom-extra-small-tablet">
								${displayProgress(type, countChildrenCompleted(childUIds, items), childCount)}
							</li>`;
						})
						.catch(() => '')
				)}
			</ul>
			${tagHTML} ${startButtonHtml}
		</div>
		${footer}
	`;
}

/**
 * Get a the rating that corresponds to an item returned by the rating service
 * @param ratings An array of ratings for rendering within module cards
 * @param item The rating object corresponding to the particular learn item, or null if there is not corresponding rating.
 */
function getModuleAndPathSummaryRatingFields(
	ratings: PageRatingResult[],
	item: ModuleAndPathSummary
): ReturnType<typeof calculateRatingFields> | null {
	const rating =
		ratings && ratings.length ? ratings.find(rating => item.uid === rating.pageId) : null;
	if (rating) {
		return calculateRatingFields(rating);
	}
	return null;
}

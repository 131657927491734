import { breakText, escape, WordBreakFlags } from '../text-formatting';

export function namepaceListPage() {
	const followedByNamespaceTable = function (this: Element) {
		const $next = $(this).next();
		return $next.is('div') && $next.hasClass('mx-namespace');
	};

	const filterList = function (inputField: HTMLElement) {
		const val = ($(inputField).val() as string).toLowerCase();

		if (val && val.length) {
			let resultIsEmpty = true;

			$('.table-scroll-wrapper td:nth-child(1) a').each(function () {
				if (this && this.innerText.toLowerCase().indexOf(val) !== -1) {
					resultIsEmpty = false;
					$(this).parents('tr').show();
				} else {
					$(this).parents('tr').hide();
				}
			});

			if (resultIsEmpty) {
				$('.emptyFilterMessage').show();
				$('.content h2').filter(followedByNamespaceTable).hide();
			} else {
				$('.emptyFilterMessage').hide();
				$('.content h2')
					.filter(followedByNamespaceTable)
					.each(function () {
						const $this = $(this);
						if ($this.next().find('tr:visible').length > 0) {
							$this.show();
							$this.next().show();
						} else {
							$this.hide();
							$this.next().hide();
						}
					});
			}
		} else {
			$('.emptyFilterMessage').hide();
			$('.content h2').show();
			$('.table-scroll-wrapper tr').show();
		}
	};

	const init = function () {
		$('.mx-namespace table').each(function () {
			$(this).addClass('nameValue');
		});

		$('.mx-namespace td:nth-child(1) a').each(function () {
			const $this = $(this);
			$this.attr('data-name', $this.text().toLowerCase());
			$this.html(breakText(escape($this.text()), WordBreakFlags.Dot));
		});

		const $namespaceForm = $('.mx-namespaceForm');
		if ($namespaceForm.length) {
			const $nsformHolder = $('<div>').addClass('nsformHolder');

			const $formFilter = $('<form>').submit(function (e) {
				e.preventDefault();
			});

			const $formInput = $('<input>')
				.attr('type', 'search')
				.attr('placeholder', 'Filter') //TODO: localize
				.keypress(function (e) {
					if (e.which === 13) {
						e.preventDefault();
						return;
					}
				})
				.keyup(function () {
					filterList(this);
				});

			$formFilter.append($formInput);
			$nsformHolder.append($formFilter);

			const $nsFormActions = $('<div>').addClass('nsformActions');

			$nsformHolder.append($nsFormActions);

			$namespaceForm.append($nsformHolder);

			$namespaceForm.append($('<div>').addClass('emptyFilterMessage').html('No results'));
		}
	};

	init();
}

import { html, TemplateResult } from '../../../lit-html';
import { loc_hideCompleted } from '@msdocs/strings';
import { SearchViewModel } from '../view-model';
import { forceWrite } from '../../../plugins/lit-html/force-write';

export function hideCompletedTemplate<TResult>(vm: SearchViewModel<TResult>): TemplateResult | '' {
	if (!vm.hideCompletedEnabled) {
		return '';
	}

	return html`
		<div class="authenticated">
			<hr class="hr has-margin-top-medium has-margin-bottom-medium" />
			${hideCompletedCheckbox(vm)}
		</div>
	`;
}
export function hideCompletedMobileTemplate<TResult>(
	vm: SearchViewModel<TResult>
): TemplateResult | '' {
	if (!vm.hideCompletedEnabled) {
		return '';
	}

	return html`
		<li class="authenticated has-padding-medium has-inner-focus is-size-small has-border-bottom">
			${hideCompletedCheckbox(vm)}
		</li>
	`;
}

function hideCompletedCheckbox<TResult>(vm: SearchViewModel<TResult>): TemplateResult {
	return html`
		<label id="hide-completed-label" class="checkbox">
			<input
				id="hide-completed"
				type="checkbox"
				data-bi-name="hide-completed"
				.checked=${forceWrite(vm.hideCompleted)}
				@change=${() => vm.toggleHideCompleted()}
			/>
			<span class="checkbox-check" role="presentation"></span>
			<span class="checkbox-text">${loc_hideCompleted}</span>
		</label>
	`;
}

import { loc_disclaimer_pageRedirectedToDocs, loc_learnmore } from '@msdocs/strings';
import { showDisclaimer } from './disclaimer';
import { parseQueryString } from './query-string';

export function handleRedirected(): void {
	// the msdn-samples redirect banner can be removed on 12/18
	if (isRedirectedFrom('msdn-samples')) {
		showDisclaimer(
			loc_disclaimer_pageRedirectedToDocs.replace('{url}', 'docs.microsoft.com/samples'),
			{ url: 'https://docs.microsoft.com/teamblog/msdn-code-gallery-retired', text: loc_learnmore }
		);
	}
}

function isRedirectedFrom(redirectValue: string): boolean {
	const redirectedFrom = parseQueryString().redirectedfrom;
	if (redirectedFrom === undefined) {
		return false;
	}

	return redirectedFrom.toLowerCase() === redirectValue;
}

import { notifyContentUpdated } from './affix';
import { getCookieConsent } from './apis/cookie-consent';
import { features } from './environment/features';
import { contentLoaded, msDocs, window } from './globals';
import { isForumsPage } from './is-production';
import { loadLibrary } from './load-library';

interface MSCC {
	hasConsent: () => boolean;
	setConsent: () => void;
	on: (event: 'consent' | 'show' | 'hide', callback: () => void) => void;
}

let consentGranted: () => void;
export const cookieConsent = new Promise<void>(resolve => (consentGranted = resolve));

let setInitialDisposition: (hasConsent: boolean) => void;
export const initialCookieConsentDisposition = new Promise<boolean>(
	resolve => (setInitialDisposition = resolve)
);

export async function initCookieConsent() {
	if (!features.cookieConsent) {
		return;
	}

	const locale = isForumsPage ? '' : msDocs.data.userLocale; // api should deduce consent for forums
	const { isConsentRequired, cookieBanner, scriptUrl } = await getCookieConsent(locale);
	if (!isConsentRequired || features.chromeless) {
		setInitialDisposition(true);
		consentGranted();
		return;
	}

	// insert the banner element in preparation for the mscc script.
	await contentLoaded;
	cookieBanner.classList.add('uhf-container');
	Array.from(cookieBanner.querySelectorAll('svg')).forEach(svg => svg.remove());
	const cookieBannerContainer = document.createElement('div');
	cookieBannerContainer.hidden = true;
	cookieBannerContainer.classList.add(
		'has-padding-top-medium',
		'has-padding-bottom-medium',
		'has-body-background-dark'
	);
	cookieBannerContainer.appendChild(cookieBanner);
	const container = document.querySelector('.skip-to-main-link');
	container.insertAdjacentElement('afterend', cookieBannerContainer);
	notifyContentUpdated();

	// script may be blocked by ad blocker.
	const mscc = await loadCookieConsentLibrary(scriptUrl).then<MSCC>(
		mscc => mscc,
		() => null
	);
	if (!mscc) {
		cookieBannerContainer.hidden = false;
		setInitialDisposition(false);
		// bad state: get out... we can't determine whether the user consents to cookies on our own.
		return;
	}

	// use the mscc library to determine whether the user has previously consented to cookies.
	if (mscc.hasConsent()) {
		setInitialDisposition(true);
		consentGranted();
		cookieBannerContainer.remove();
	} else {
		setInitialDisposition(false);
		const unobserve = observeInteractions(mscc);
		cookieBannerContainer.hidden = false;
		mscc.on('consent', () => {
			cookieBannerContainer.hidden = true;
			unobserve();
			consentGranted();
		});
	}
}

export function loadCookieConsentLibrary(scriptUrl: string) {
	return loadLibrary<MSCC>(scriptUrl, 'mscc');
}

function observeInteractions(mscc: MSCC) {
	function processInteraction({ isTrusted, target, type }: Event) {
		if (!isTrusted) {
			return;
		}

		if (
			/input|change/.test(type) &&
			(target instanceof HTMLInputElement || target instanceof HTMLSelectElement)
		) {
			mscc.setConsent();
			return;
		}

		if (type === 'click' && target instanceof Element && target.closest('button')) {
			mscc.setConsent();
			return;
		}
	}

	window.addEventListener('input', processInteraction, { passive: true } as any);
	window.addEventListener('change', processInteraction, { passive: true } as any);
	window.addEventListener('click', processInteraction, { passive: true } as any);

	return () => {
		window.removeEventListener('input', processInteraction);
		window.removeEventListener('change', processInteraction);
		window.removeEventListener('click', processInteraction);
	};
}

import { msDocs, window } from '../../globals';
import { RouterDelegate } from '../../router/router';
import { getSectionTitle, ProfileSectionId, sectionIds } from './model';

/**
 * Reads the username and section values from a profile detail url.
 * On docs.microsoft.com /users the values are read from the pathname.
 * Otherwise the values are read from the query string.
 */
export function readProfileDetailUrl(url: URL) {
	let [
		,
		,
		/* '' */ /* locale */ usersSegment,
		username = '',
		rawSection,
		detail = ''
	] = decodeURIComponent(url.pathname).toLowerCase().split('/');
	if (url.hostname !== 'docs.microsoft.com' || usersSegment !== 'users') {
		username = (url.searchParams.get('username') || '').toLowerCase();
		rawSection = (url.searchParams.get('section') || '').toLowerCase();
		detail = (url.searchParams.get('detail') || '').toLowerCase();
	}

	const section = sectionIds.find(x => x === rawSection) || 'overview';

	// only collections permits a detail component.
	if (detail && section !== 'collections') {
		detail = '';
	}

	return {
		username,
		section,
		detail
	};
}

/**
 * Generates a profile detail url given the username and optionally the section.
 * Chooses pathname or query string based schemes based on the location.hostname.
 */
export function generateProfileDetailUrl(
	username: string,
	section?: ProfileSectionId,
	detail?: string,
	location = window.location,
	locale = msDocs.data.userLocale
): URL {
	username = username.toLowerCase();
	if (detail && section !== 'collections') {
		throw new Error(`Detail url component is not supported in ${section} section.`);
	}
	if (location.hostname === 'docs.microsoft.com') {
		let url: URL;
		if (section && section !== 'overview') {
			url = new URL(
				`/${locale}/users/${username}/${section}${detail ? '/' + detail : ''}${location.search}`,
				location.origin
			);
		} else {
			url = new URL(`/${locale}/users/${username}/${location.search}`, location.origin);
		}
		return url;
	}
	const base = location.hostname === 'ppe.docs.microsoft.com' ? 'learn-sandbox' : 'test';
	const pathname = `/${locale}/${base}/profile/profile`;
	const url = new URL(pathname + location.search, location.origin);
	url.searchParams.delete('username');
	url.searchParams.delete('section');
	url.searchParams.delete('detail');
	url.searchParams.set('username', username);
	if (section && section !== 'overview') {
		url.searchParams.set('section', section);
		if (detail) {
			url.searchParams.set('detail', detail);
		}
	} else {
		url.searchParams.delete('section');
	}
	return url;
}

/**
 * Create the RouterDelegate which maps the router to the
 * private profile detail's section property setter.
 */
export function createRouterDelegate(
	setSection: (section: ProfileSectionId, detail: string) => void,
	currentPathname = window.location.pathname
): RouterDelegate {
	return {
		canHandle(url) {
			return (
				readProfileDetailUrl(url).username.length > 0 &&
				(url.hostname === 'docs.microsoft.com' || url.pathname === currentPathname) &&
				!(url.pathname === location.pathname && url.search === location.search)
			);
		},
		handle(url) {
			const { username, section, detail } = readProfileDetailUrl(url);
			setSection(section, detail);
			return Promise.resolve({
				url: generateProfileDetailUrl(username, section, detail), // normalize the url.
				title: getDocumentTitle(username, section)
			});
		},
		params: ['username', 'section', 'detail']
	};
}

/**
 * Compute the document title from the username and section.
 */
export function getDocumentTitle(username: string, section?: ProfileSectionId) {
	if (section && section !== 'overview') {
		return `${getSectionTitle(section)} - ${username} | Microsoft Docs`;
	}
	return `${username} | Microsoft Docs`;
}

/**
 * The hosting layer does not set the profile detail document title or
 * normalize it's url path & parameters. This function fills the gap.
 */
export function normalizeUrlAndTitle(
	username: string,
	section?: ProfileSectionId,
	detail?: string
) {
	const title = getDocumentTitle(username, section);
	document.title = title;
	history.replaceState(
		undefined,
		title,
		generateProfileDetailUrl(username, section, detail) + window.location.hash
	);
}

import { jsllReady } from '../bi';
import { EventBus } from '../event-bus';
import { UnitProgressCheckedEvent } from '../learn/unit-progress';
import { getMeta } from '../meta';

export function reportValidation(passed: boolean, startTime: Date, endTime: Date) {
	jsllReady.then(function (awa) {
		awa.ct.captureContentPageAction({
			behavior: awa.behavior.OTHER,
			actionType: awa.actionType.OTHER,
			content: {
				type: 'task-validation',
				uid: getMeta('uid'),
				validationType: 'FreeAzureSubscription',
				passed,
				startTime,
				endTime
			}
		});
	});
}

export function reportUnitCompletion(bus: EventBus) {
	bus.subscribe(UnitProgressCheckedEvent, async event => {
		if (!event.passed || !event.updated || !event.unit.item) {
			return;
		}

		const awa = await jsllReady;

		awa.ct.captureContentPageAction({
			actionType: awa.actionType.OTHER,
			behavior: awa.behavior.OTHER,
			content: {
				type: 'learn-unit-completed',
				unit: {
					uid: event.unit.item.uid,
					completed: true
				},
				module: {
					uid: event.module.item.uid,
					completed: !!event.module.progress && event.module.progress.status === 'completed'
				},
				paths: event.paths.map(x => ({
					uid: x.item.uid,
					completed: !!x.progress && x.progress.status === 'completed'
				})),
				achievements: event.achievements.map(x => ({
					uid: x.uid,
					type: x.type
				}))
			}
		});
	});
}

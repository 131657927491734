import * as riskTemplates from './risk-templates';
import { captchaResolved, loadCaptchaChallenge, resetCaptcha } from '../captcha';
import { loc_azureSandbox_checkingAvailability } from '@msdocs/strings';
import { SandboxPromptState } from '../azure/sandbox-prompt/state';
import { resetProgress, renderProgress } from '../azure/sandbox-prompt/ui';
import * as labApi from '../apis/lab-on-demand';
import * as sandboxApi from '../apis/sandbox';
import { createTenantInviteReturnUrl } from '../azure/sandbox-prompt/url-activation';
import { RiskApiError } from './risk-errors';

/**
 * Promise returned by prompts that never resolves.
 * This is used in cases where sandbox or lab activation cannot proceed
 * due to circumstances out of the user's control like they're
 * appeal has been rejected.
 *
 * This promise is also used when the next step in the activation
 * process requires navigation (sign in to activate, tenant invite).
 * In these scenarios, upon return from the external page,
 * a query string argument will indicate we should proceed with
 * activating the sandbox without waiting for user interaction
 * @see {@link url-activation.ts}.
 */
export const neverResolve = new Promise<void>(() => {});

export async function labRiskPrompts(
	errorCode: RiskApiError['errorCode'],
	container: Element,
	id: number
) {
	switch (errorCode) {
		case 'AppealDenied':
			return errorAppealDenied(container);
		case 'AppealPending':
			return errorAppealPending(container);
		case 'MissingEmail':
			return errorMissingEmail(container);
		case 'Blocked':
			return labTriggerCaptcha(id, container);
		case 'OperationFailed':
		case 'Rejected':
			return labAppeal(id, container);
	}
}

export function errorAppealDenied(container: Element): Promise<void> {
	riskTemplates.renderAppealDenied(container);
	return neverResolve;
}

export function errorAppealPending(container: Element): Promise<void> {
	riskTemplates.renderAppealPending(container);
	return neverResolve;
}

export function errorMissingEmail(container: Element, state?: SandboxPromptState): Promise<void> {
	// need a reset for sandbox
	if (state) {
		state.apiResult = null;
	}
	riskTemplates.renderMissingEmail(container);
	return riskTemplates.waitForButtonClick(container);
}

export function errorRestrictedCloud(container: Element): Promise<void> {
	riskTemplates.renderRestrictedCloud(container);
	return neverResolve;
}

export function errorUserRejected(container: Element): Promise<void> {
	container.classList.remove('alert'); // allow custom error UI
	riskTemplates.renderUserRejected(container);
	return neverResolve;
}

export function errorQuotaExceeded(container: Element): Promise<void> {
	riskTemplates.renderQuotaExceeded(container);
	return neverResolve;
}

export async function sandboxTriggerCaptcha(
	state: SandboxPromptState,
	appeal: boolean = false
): Promise<void> {
	riskTemplates.renderCaptcha(state.container);
	const arkose = loadCaptchaChallenge();

	await captchaResolved;
	const captchaToken = arkose.getSessionToken();
	if (appeal) {
		state.apiResult = null;
		await sandboxApi.appealSandboxBan(captchaToken);
	} else {
		// reset / render progress and wait for good api return
		resetProgress();
		renderProgress(state.container, loc_azureSandbox_checkingAvailability);
		state.apiResult = await sandboxApi.post({
			returnUrl: createTenantInviteReturnUrl(),
			locale: state.auth.user.locale,
			unitUid: state.unitUid,
			moduleUid: state.module.uid,
			captchaToken
		});
	}
	resetCaptcha(arkose);
}

export async function sandboxAppeal(state: SandboxPromptState): Promise<void> {
	state.container.classList.remove('alert'); // allow custom error UI
	riskTemplates.renderAppeal(state.container);
	await riskTemplates.waitForButtonClick(state.container);
	state.container.classList.add('alert'); // reset container
	state.apiResult = null;
	await sandboxTriggerCaptcha(state, true);
}

export async function labTriggerCaptcha(id: number, container: Element): Promise<void> {
	riskTemplates.renderCaptcha(container);
	const arkose = loadCaptchaChallenge();

	await captchaResolved;
	const captchaToken = arkose.getSessionToken();

	await labApi.post(id, captchaToken);
	resetCaptcha(arkose);
}

export async function labAppeal(id: number, container: Element): Promise<void> {
	container.classList.remove('alert'); // allow custom error UI
	riskTemplates.renderAppeal(container);
	await riskTemplates.waitForButtonClick(container);
	container.classList.add('alert'); // reset container
	await labTriggerCaptcha(id, container);
}

export async function smsChallenge(state: SandboxPromptState): Promise<void> {
	riskTemplates.renderSmsChallenge(state.container);
	// TODO add functionality for SMS challenge solution
	return neverResolve;
}

import { loc_onboardingExperience_promptForASelectionOrSkip } from '@msdocs/strings';
import { BodyTemplateContainer } from '../../body-template-container';
import { setFocusInModalView } from '../../components/modal/modal';
import { DisposableElement } from '../../disposable-html-element';
import { document, msDocs, window } from '../../globals';
import {
	getInterests,
	hasAllInterestsDefined,
	hasAtLeastOneChecked,
	hasAtLeastOneInterestDefined,
	loadSelectionsFromCache,
	saveInterestSelectionsToClient,
	saveInterestsToClient,
	saveUserInterestsFromClient,
	updateCurrentSelectionCache
} from '../index';
import { render, TemplateResult } from '../../lit-html';
import { InterestsFacetKey, FacetKeys, Interests } from '../model';
import { scrollToHash } from '../../router/router';
import {
	createConfirmationOnboardingTemplate,
	createLevelsOnboardingTemplate,
	createModalTemplate,
	createProductsOnboardingTemplate,
	createRolesOnboardingTemplate,
	filteredProductSlugs,
	refreshProductCache
} from '../view';

const facetTemplateMap: Record<Steps, TemplateResult> = {
	roles: createRolesOnboardingTemplate(),
	levels: createLevelsOnboardingTemplate(),
	products: undefined,
	finish: createConfirmationOnboardingTemplate()
};

const errorHash = '#error-message';
const trendingSectionHash = '#trending-section';
const animationCompleteTimeout = 250;

/**
 * Creates the Learn-Home page modal content with encapsulated logic for state and state transitions.
 * @param mode Identifies if the modal is launched for a new interest selection or interest edits.
 * @param onUpdateInterest Handler run when user has a chance to select interests; it can encapsulate logic to filter on interest.
 */
export function createLearnerInterestModalContent(
	mode: 'onboard' | 'edit',
	onUpdateInterest: () => void
) {
	const view: LearnerInterestElement = document.createElement('div') as any;
	view.classList.add('modal-card', 'fill-mobile', 'has-border-none');
	const container = new BodyTemplateContainer();

	let isFormDirty = false;
	let currentStep = 'roles' as Steps;
	let nextStep: Steps = currentStep;
	let goBackCache = {} as Record<InterestsFacetKey, string[]>;
	let selectionsCache = initializeCache({} as Interests);
	let enableEditPreferences = false;
	let sessionComplete = false;
	let hasRunInterestUpdateHandler = false;
	const handleOnUpdateInterest = () => {
		onUpdateInterest();
		hasRunInterestUpdateHandler = true;
	};
	let goToPreviousView = false;

	view.paint = () => {
		if (currentStep === nextStep) {
			// there was an error on page after initial paint, no transition
			return;
		}

		animateTransition(container.bodyTemplate, view, currentStep, goToPreviousView);
		currentStep = nextStep;
		if (currentStep !== 'finish') {
			setTimeout(() => {
				loadSelectionsFromCache(view, selectionsCache[currentStep as InterestsFacetKey]);
				loadSelectionsFromCache(view, goBackCache[currentStep as InterestsFacetKey]);
				setFocusInModalView(view);
			}, animationCompleteTimeout);
		} else {
			setTimeout(
				() => view.querySelector('.modal-card-head').scrollIntoView(),
				animationCompleteTimeout
			);
		}
	};

	Object.defineProperty(view, 'enableInterestEdit', {
		get: () => enableEditPreferences
	});

	const saveOnClose = async () => {
		if (sessionComplete) {
			return;
		}

		if (!hasRunInterestUpdateHandler && isFormDirty) {
			if (mode === 'edit' || hasAllInterestsDefined(selectionsCache)) {
				await saveUserInterestsFromClient('learn');
			}

			handleOnUpdateInterest();
		}

		sessionComplete = true;
	};

	const saveCurrentSelectionsOnNextClick = (step: InterestsFacetKey) => {
		// can be made smarter to check before and after values
		// for now, if the next button is never clicked, there's no need to make api PUT calls
		isFormDirty = true;
		saveInterestSelectionsToClient(
			Array.from(view.querySelectorAll('input[type="checkbox"]')),
			step,
			selectionsCache
		);
		delete goBackCache[step];
	};

	const updateProfileWithInterests = async () => {
		const buttons = Array.from(view.querySelectorAll('button[action]')) as HTMLButtonElement[];
		for (const button of buttons) {
			if (button.getAttribute('action') === 'next') {
				button.classList.add('is-loading');
			} else {
				button.disabled = true;
			}
		}
		saveInterestsToClient(selectionsCache);
		await saveUserInterestsFromClient('learn');
	};

	const handleSkipClick = (step: InterestsFacetKey) => {
		view.querySelector(errorHash).parentElement.classList.add('is-hidden');
		delete goBackCache[step];
		return transitionOnboardingStep(step);
	};

	const handleBackClick = (step: InterestsFacetKey) => {
		view.querySelector(errorHash).parentElement.classList.add('is-hidden');
		if (
			mode === 'onboard' &&
			!hasAtLeastOneChecked(Array.from(view.querySelectorAll('input[type="checkbox"]')))
		) {
			return transitionOnboardingStep(step, true);
		}

		// we want to cache the current selection for reload when we return
		goBackCache[step] = updateCurrentSelectionCache(view, goBackCache[step] || []);
		return transitionOnboardingStep(step, true);
	};

	const reportError = () => {
		const notice = view.querySelector(errorHash);
		notice.textContent = loc_onboardingExperience_promptForASelectionOrSkip;
		notice.parentElement.classList.remove('is-hidden');
		scrollToHash(errorHash);
	};

	const handleNextClick = async (step: InterestsFacetKey) => {
		if (
			mode === 'onboard' &&
			!hasAtLeastOneChecked(Array.from(view.querySelectorAll('input[type="checkbox"]')))
		) {
			reportError();
			return step;
		}

		view.querySelector(errorHash).parentElement.classList.add('is-hidden');
		saveCurrentSelectionsOnNextClick(step);
		return transitionOnboardingStep(step);
	};

	const clickHandler = async (event: Event) => {
		const button =
			event.target instanceof HTMLElement && (event.target.closest('button') as HTMLButtonElement);
		const anchor =
			event.target instanceof HTMLElement && (event.target.closest('a') as HTMLAnchorElement);
		if (!button && !anchor) {
			return;
		}

		if (anchor) {
			const url = new URL(location.href);
			delete url.hash;
			if (new URL(anchor.href, url.href).hash !== trendingSectionHash) {
				return;
			}
			event.preventDefault();
			view.querySelector('.modal-close').dispatchEvent(new CustomEvent('click', { bubbles: true }));
			setTimeout(() => scrollToHash(trendingSectionHash));
			return;
		}

		if (button.classList.contains('modal-close')) {
			saveOnClose();
			return;
		}

		currentStep = button.getAttribute('data-facet-id') as InterestsFacetKey;
		const action = button.getAttribute('action');

		if (!facetTemplateMap.products) {
			const slugs = await filteredProductSlugs;
			refreshProductCache(slugs);
			facetTemplateMap.products = createProductsOnboardingTemplate();
		}

		switch (action) {
			case 'back':
				nextStep = handleBackClick(currentStep);
				goToPreviousView = true;
				container.pop();
				break;
			case 'next':
				nextStep = await handleNextClick(currentStep);
				if (nextStep !== currentStep) {
					goToPreviousView = false;
					container.push(facetTemplateMap[nextStep]);
				}
				break;
			case 'skip':
				nextStep = handleSkipClick(currentStep);
				goToPreviousView = false;
				container.push(facetTemplateMap[nextStep]);
				break;
			default:
				nextStep = currentStep;
				break;
		}

		if (nextStep === 'finish') {
			if (isOnboardingComplete(mode, selectionsCache)) {
				FacetKeys.forEach(facet => {
					// To ensure non-defined interests are defined to
					// guarantee that the user is considered onboarded afterwards
					selectionsCache[facet] = selectionsCache[facet] || [];
				});
				await updateProfileWithInterests();
				enableEditPreferences = true;
				renderOnboardedUserExperience(mode);
				handleOnUpdateInterest();
				isFormDirty = false;
			} else if (mode === 'edit') {
				await updateProfileWithInterests();
				handleOnUpdateInterest();
				isFormDirty = false;
			}

			sessionComplete = true;
		}

		view.paint();
	};

	const resetBgdImageSize = () => {
		const bgdImage = view.querySelector<HTMLElement>('.has-background-image');
		bgdImage.style.width = '';
		bgdImage.style.height = '';
	};

	view.dispose = () => {
		saveOnClose();
		container.dispose();
		selectionsCache = null;
		goBackCache = null;
		view.removeEventListener('click', clickHandler);
		window.removeEventListener('resize', resetBgdImageSize);
	};

	const initialPaint = () => {
		container.push(facetTemplateMap[currentStep]);
		const template = createModalTemplate(container.bodyTemplate);
		render(template, view);
		loadSelectionsFromCache(view, selectionsCache[currentStep as InterestsFacetKey]);
		setFocusInModalView(view);

		// because we are setting the width during animation, we reset during resize
		window.addEventListener('resize', resetBgdImageSize);
	};

	initialPaint();
	view.addEventListener('click', clickHandler);
	return view;
}

type Steps = InterestsFacetKey | 'finish';

export interface LearnerInterestElement extends DisposableElement {
	enableInterestEdit: boolean;
}

function animateTransition(
	template: TemplateResult,
	view: HTMLElement,
	currentStep: Steps,
	goToPreviousView: boolean = false
) {
	const cardContentContainer = view.querySelector('.modal-slide-container');
	const firstSlide = cardContentContainer.querySelector('.modal-slide:first-of-type');

	// fix the bgd image container to prevent flickering/container due to content size changes
	const bgdImage = view.querySelector<HTMLElement>('.has-background-image');
	if (currentStep !== 'levels') {
		// roles/products content >> levels, so we fix the container pixels
		bgdImage.style.width = `${bgdImage.getBoundingClientRect().width}px`;
		bgdImage.style.height = `${bgdImage.getBoundingClientRect().height}px`;
	} else {
		// levels content << roles/products content, we reset the height so the container can grow vertically
		// we do not reset the width as to prevent the content expanding the width which exacerbates flickering
		bgdImage.style.height = '';
	}

	const content = document.createElement('div');
	content.classList.add('modal-slide');
	render(template, content);

	const direction = {
		forward: msDocs.data.userDir === 'ltr' ? 'slide-left' : 'slide-right',
		backward: msDocs.data.userDir === 'ltr' ? 'slide-right' : 'slide-left'
	};

	const animationClass = goToPreviousView ? direction.backward : direction.forward;
	firstSlide.classList.add(animationClass);

	setTimeout(() => {
		cardContentContainer.appendChild(content);
		content.classList.add(animationClass);
	}, animationCompleteTimeout / 5);

	setTimeout(() => {
		// Remove the old content, 250 is timed with transition speed
		content.classList.remove(animationClass);
		cardContentContainer.removeChild(firstSlide);
	}, animationCompleteTimeout);
}

function transitionOnboardingStep(
	interest: InterestsFacetKey,
	goToPreviousStep: boolean = false
): Steps {
	switch (interest) {
		case 'roles':
			return 'levels'; // should only go forward from here
		case 'levels':
			return goToPreviousStep ? 'roles' : 'products';
		case 'products':
			return goToPreviousStep ? 'levels' : 'finish';
		default:
			return interest;
	}
}

function isOnboardingComplete(mode: string, cache: Interests) {
	return mode === 'onboard' && hasAtLeastOneInterestDefined(cache);
}

function initializeCache(cache: Record<InterestsFacetKey, string[]>): Interests {
	const interests = getInterests();
	if (!interests) {
		return cache;
	}

	for (const facet in interests) {
		const facetKey = facet as InterestsFacetKey;
		if (interests[facetKey]) {
			cache[facetKey] = [...interests[facetKey]];
		}
	}

	return cache;
}

function renderOnboardedUserExperience(mode: string) {
	const customizeCta = document.querySelector(
		'button[data-bi-name="learn-homepage-cta-customize"]'
	) as HTMLButtonElement;
	if (mode === 'onboard') {
		customizeCta.onclick = null;
		customizeCta.remove();
	}
	const browseCta = document.querySelector('a[data-bi-name="learn-homepage-cta-browse"]');
	browseCta.classList.remove('is-hidden');
	enableEditPreferenceButton();
}

function enableEditPreferenceButton() {
	const preference = document.getElementById('edit-learning-preferences');
	preference.classList.remove('is-hidden');
	if (msDocs.data.userDir === 'rtl') {
		preference.parentElement.classList.remove('has-margin-right-extra-large-desktop');
		preference.parentElement.classList.add('has-margin-left-extra-large-desktop');
	}
}

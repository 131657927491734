/**
 * Base64 URL decode a string.
 * This is built to handle Unicode values inside of a base 64 string by using decodeURIComponent.
 * It also pads the base64 value before doing the conversion.
 * See https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#Solution_4_%E2%80%93_escaping_the_string_before_encoding_it.
 */
export function base64URLdecode(s: string): string {
	return decodeURIComponent(
		atob(pad(s).replace(/_/g, '/').replace(/-/g, '+'))
			.split('')
			.map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
			.join('')
	);
}

/**
 * Pads a base64 encoded string with equal signs.
 */
export function pad(s: string): string {
	switch (s.length % 4) {
		case 0:
			return s;
		case 2:
			return s + '==';
		case 3:
			return s + '=';
		default:
			throw new Error('Illegal base64url string!');
	}
}

import { generateElementId } from '../html';
import { keyCodes } from '../key-codes';
import { html, ifDefined, render, TemplateResult } from '../lit-html';

/** Event fired when a selection is made. Event type is CustomEvent<AutoCompleteSuggestionDetail<T>>. */
export const autocompleteChangeEvent = 'autocomplete-change-event';

/** Event fired when the suggestions have been fetched and are displayed. Event type is CustomEvent<T[]>. */
export const autocompleteDisplayedEvent = 'autocomplete-displayed-event';

export const userInputDebounceMilliseconds = 50;

/** Config for the autocomplete element. */
export interface AutoCompleteConfig<T> {
	/** The HTML input element configuration. */
	input: {
		id: string;
		placeholder?: string;
		size?: 'small' | 'large';
		type?: string;
		name?: string;
		docon?: string;
		doconOrientation?: 'left' | 'right';
		doconClasses?: string;
		label?: string;
		isFullWidth?: boolean;
	};
	/** A function to retrieve autocomplete suggestions. */
	getSuggestions: (term: string) => Promise<T[]>;
	/** An optional function to retrieve a suggestion object's title. Default is suggestion.toString(). */
	getTitle?: (suggestion: T) => string;
	/** An optional function to retrieve an HTML template to display a single suggestion. Default is getTitle. */
	itemTemplate?: (suggestion: T) => TemplateResult | string;
	/** The initial value. Optional. */
	initialValue?: T;
}

export interface AutoCompleteSuggestionDetail<T> {
	suggestion: T;
	term: string;
}

export interface AutocompleteElement<T> extends HTMLElement {
	value: T;
}

/**
 * Creates an autocomplete element.
 * @param {AutoCompleteConfig} config The configuration for the component.
 * @returns {HTMLElement}.
 */
export function createAutoComplete<T>(config: AutoCompleteConfig<T>) {
	// process config.
	config.input.placeholder = config.input.placeholder || '';
	config.input.type = config.input.type || 'text';
	const {
		getSuggestions,
		getTitle = (x: T) => x.toString(),
		itemTemplate = getTitle,
		initialValue = null
	} = config;

	// create container element.
	const element: AutocompleteElement<T> = document.createElement('div') as any;

	element.classList.add('autocomplete');
	if (config.input.isFullWidth) {
		element.classList.add('is-block');
	}
	element.setAttribute('data-bi-name', 'autocomplete');
	// create a unique id to be used with sub-components of the autocomplate element.
	const uid = generateElementId();
	// the id of the listbox element.
	const listboxId = `${uid}-listbox`;
	// a function to create ids for option elements.
	const optionId = (index: number) => (index === -1 ? '' : `${uid}-option-${index}`);
	// state
	let suggestions: T[] = []; // current suggestions.
	let activeDescendant = -1; // index of the active option. -1 means none are active.
	let value = initialValue ? getTitle(initialValue) : ''; // the input's value.
	let userInput = ''; // what the user last entered- could be different from the input's value.
	let currentSelection: T = initialValue; // currently selected suggestion.
	let userInputTimeout = 0; // debounce user input.
	let isLoading = false; // whether suggestions are loading.
	let isComposing = false; // whether an IME is in use

	/** scrolls the active descendant into view. */
	const scroll = () => {
		const ul = element.querySelector(`#${listboxId}`) as HTMLUListElement;
		const li = ul.children.item(activeDescendant === -1 ? 0 : activeDescendant) as HTMLLIElement;
		if (!li) {
			ul.scrollTop = 0;
		} else if (li.offsetTop + li.offsetHeight > ul.offsetHeight) {
			ul.scrollTop = li.offsetTop - ul.offsetHeight + li.offsetHeight + 4;
		} else if (li.offsetTop < ul.scrollTop) {
			ul.scrollTop = li.offsetTop;
		}
	};

	/** renders and scrolls the active descendant into view. */
	const paint = () => {
		render(templateResult(), element);
		element.querySelector('input').value = value || userInput;
		scroll();
	};

	/** collapse the listbox and clear the suggestions and active descendant. */
	const collapse = () => {
		suggestions = [];
		activeDescendant = -1;
		paint();
	};

	/**
	 * select a suggestion (user clicked or pressed enter on a suggestion).
	 * @param suggestion - single suggestion
	 * @param notify - notify the event
	 */
	const select = (suggestion: T, notify: boolean) => {
		const term = userInput;
		currentSelection = suggestion;
		value = suggestion ? getTitle(suggestion) : '';
		userInput = value;
		collapse();
		if (notify) {
			const event = new CustomEvent<AutoCompleteSuggestionDetail<T>>(autocompleteChangeEvent, {
				detail: { suggestion, term },
				bubbles: true
			});
			element.dispatchEvent(event);
		}
	};

	Object.defineProperty(element, 'value', {
		get() {
			return currentSelection;
		},
		set(newValue) {
			select(newValue, false);
		}
	});

	/** make autocomplete suggestions based on what the user input. */
	const suggest = async () => {
		if (userInput === '') {
			isLoading = false;
			currentSelection = null;
			collapse();
			return;
		}
		suggestions = await getSuggestions(userInput);
		const event = new CustomEvent<T[]>(autocompleteDisplayedEvent, {
			detail: suggestions,
			bubbles: true
		});
		element.dispatchEvent(event);

		activeDescendant = -1;
		isLoading = false;
		paint();
	};

	const handleOptionClick = (event: Event) => {
		const option =
			event.target instanceof Element && (event.target.closest('[role="option"]') as HTMLLIElement);
		if (!option) {
			return;
		}
		event.preventDefault();
		const listbox = option.parentElement as HTMLUListElement;
		let index = 0;
		for (; index < listbox.childElementCount; index++) {
			if (listbox.children.item(index) === option) {
				break;
			}
		}
		const suggestion = suggestions[index];
		select(suggestion, true);
	};

	const handleInput = async (event: Event) => {
		clearTimeout(userInputTimeout);
		userInputTimeout = setTimeout(suggest, userInputDebounceMilliseconds);
		const input = event.target as HTMLInputElement;
		userInput = input.value;
		value = '';
		if (!isLoading && input.value !== '' && !isComposing) {
			isLoading = true;
			paint();
		}
	};

	const handleCompositionStart = () => {
		isComposing = true;
	};

	const handleCompositionEnd = () => {
		isComposing = false;
	};

	const handleBlur = () => collapse();

	const handleKeydown = ({ keyCode, shiftKey, altKey, ctrlKey }: KeyboardEvent) => {
		if (shiftKey || altKey || ctrlKey) {
			return;
		}

		// down
		if (keyCode === keyCodes.down) {
			event.preventDefault();
			if (activeDescendant < suggestions.length - 1) {
				activeDescendant++;
				value = getTitle(suggestions[activeDescendant]);
			} else {
				activeDescendant = -1;
				value = userInput;
			}
			paint();
			return;
		}

		// up
		if (keyCode === keyCodes.up) {
			event.preventDefault();
			if (!suggestions.length) {
				return;
			}

			if (activeDescendant === -1) {
				activeDescendant = suggestions.length - 1;
				value = getTitle(suggestions[activeDescendant]);
			} else if (activeDescendant > 0) {
				activeDescendant--;
				value = getTitle(suggestions[activeDescendant]);
			} else {
				activeDescendant = -1;
				value = userInput;
			}
			paint();
			return;
		}

		// escape
		if (keyCode === keyCodes.escape) {
			event.preventDefault();
			select(currentSelection, false);
			return;
		}

		// enter
		if (keyCode === keyCodes.enter) {
			if (activeDescendant >= 0) {
				const selectedSuggestion = suggestions[activeDescendant];
				select(selectedSuggestion, true);
			}
			return;
		}
	};

	/** the template for the autocomplete component. */
	function templateResult() {
		return html` <div
				class="control ${config.input.docon
					? `has-icons-${config.input.doconOrientation || 'left'}`
					: ''}"
			>
				<input
					id="${config.input.id}"
					class="autocomplete-input input ${config.input.docon
						? `control has-icons-${config.input.doconOrientation || 'left'}`
						: ''} ${config.input.isFullWidth ? 'is-full-width' : ''} ${config.input.size
						? `is-${config.input.size}`
						: ''}"
					type="${config.input.type}"
					name=${ifDefined(config.input.name)}
					role="combobox"
					maxlength="100"
					@input=${handleInput}
					@blur=${handleBlur}
					@focus=${suggest}
					@keydown=${handleKeydown}
					aria-autocomplete="list"
					aria-expanded="${suggestions.length ? 'true' : 'false'}"
					aria-owns="${listboxId}"
					aria-activedescendant="${optionId(activeDescendant)}"
					aria-label="${ifDefined(config.input.label)}"
					placeholder="${config.input.placeholder}"
					autocapitalize="off"
					autocomplete="off"
					autocorrect="off"
					spellcheck="false"
				/>

				<span
					class="icon is-small is-${config.input.doconOrientation || 'left'}"
					?hidden=${!config.input.docon}
					aria-hidden="true"
				>
					<span
						class="${config.input.doconClasses || 'has-text-primary'} docon docon-${config.input
							.docon}"
					></span>
				</span>

				<span
					class="autocomplete-loader loader has-text-primary"
					?hidden=${!isLoading}
					aria-hidden="true"
				></span>
			</div>

			<ul
				id="${listboxId}"
				class="autocomplete-suggestions is-vertically-scrollable"
				role="listbox"
				aria-label="${config.input.id}-suggestions"
				@mousedown=${(e: MouseEvent) => e.preventDefault()}
				@click=${handleOptionClick}
				?hidden=${!suggestions.length}
			>
				${suggestions.map(
					(suggestion, index) => html` <li
						id="${optionId(index)}"
						role="option"
						class="autocomplete-suggestion ${index === activeDescendant
							? 'is-active-descendant'
							: ''}"
					>
						${itemTemplate(suggestion)}
					</li>`
				)}
			</ul>`;
	}

	paint(); // initial paint.

	element.addEventListener('compositionstart', handleCompositionStart);
	element.addEventListener('compositionend', handleCompositionEnd);

	return element;
}

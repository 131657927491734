import { loc_loading } from '@msdocs/strings';
import { createBranchSelectorTemplate } from '../branch-selector';
import { features } from '../environment/features';
import { html, render } from '../lit-html';
import { getQueryStringMap, updateUrlSearchFromMap } from '../query-string';
import { router } from '../router/router';
import { assessmentBranchKey, AssessmentNavigation, AssessmentSearchParams } from './types';

export function getAssessmentBranch(query?: AssessmentSearchParams): string | null {
	if (!features.assessmentBranches) {
		return null;
	}

	if (!query) {
		query = getQueryStringMap() as AssessmentSearchParams;
	}

	if (!(assessmentBranchKey in query)) {
		return null;
	}

	return query[assessmentBranchKey];
}

let assessmentBranchSelector: HTMLElement;

export const createAssessmentBranchSelector = async (
	action: AssessmentNavigation,
	currentBranch: string,
	branchList: Promise<string[]>
) => {
	const { mode } = action;

	if (!features.assessmentBranches) {
		return;
	}

	const actionList = document.querySelector('.action-list');
	if (!actionList) {
		return;
	}

	if (!assessmentBranchSelector) {
		assessmentBranchSelector = document.createElement('li');
		actionList.insertAdjacentElement('afterbegin', assessmentBranchSelector);
	}

	const template =
		mode === 'home' || mode === 'pre-assessment'
			? createBranchSelectorTemplate({
					id: 'assessment-branch-selector',
					branches: branchList,
					onchange: handleAssessmentBranchSelectorChange(currentBranch, action),
					currentBranch,
					classList: 'is-small is-rounded has-margin-left-medium',
					loadingMessage: loc_loading
			  })
			: html``;

	render(template, assessmentBranchSelector);
};

function handleAssessmentBranchSelectorChange(
	currentBranch: string,
	action: AssessmentNavigation
): (e: Event) => void {
	return (e: Event) => {
		const target = e.target as HTMLSelectElement;
		if (!target) {
			return;
		}

		const branchSelected = target.value;

		if (branchSelected === currentBranch) {
			return;
		}

		currentBranch = branchSelected;

		const url = new URL(location.href);

		if (action.mode === 'home') {
			const branchUrl = updateUrlSearchFromMap(
				{
					[assessmentBranchKey]: branchSelected
				},
				url
			);
			router.goto(branchUrl, 'pushState');
		} else if (action.mode === 'pre-assessment') {
			const branchUrl = updateUrlSearchFromMap({
				[assessmentBranchKey]: branchSelected,
				id: action.session.assessmentId,
				mode: null,
				session: null
			});
			router.goto(branchUrl, 'pushState');
		} else {
			return;
		}
	};
}

import { parseQueryString, updateQueryString } from './query-string';

export function handleSimpleTabs(
	container: HTMLElement,
	getTab: () => string = getTabFromQueryString,
	setUrl: (tabId: string) => void = setQueryStringForTab
): void {
	// Only one of these components on the page is currently supported
	const tabs = Array.from(container.querySelectorAll('.tabs .tab-control')) as HTMLElement[];
	const tabsContent = Array.from(container.querySelectorAll('[data-tab-content]')) as HTMLElement[];
	const tabIds = tabs.map(tab => tab.id);
	const tabId = getTab();

	tabs.forEach(tab => {
		if (tabIds.indexOf(tabId) !== -1) {
			setTabByTabId(tabId, tab, tabsContent);
		}

		tab.onclick = () => {
			showTab(tab.id, container);
			setUrl(tab.id);
		};
	});
}

export function showTab(tabId: string, container: HTMLElement): void {
	const tab = container.querySelector(`#${tabId}`);
	if (tab === null) {
		return;
	}

	const tabs = Array.from(container.querySelectorAll('.tabs .tab-control')) as HTMLElement[];
	const tabsContent = Array.from(container.querySelectorAll('[data-tab-content]')) as HTMLElement[];

	tabs.forEach(resetTab => {
		if (resetTab !== tab) {
			resetTab.parentElement.classList.remove('is-active');
			resetTab.setAttribute('aria-selected', 'false');
		}
	});

	tab.parentElement.classList.add('is-active');
	tab.setAttribute('aria-selected', 'true');

	filterTabs(tab.getAttribute('aria-controls'), tabsContent);
}

export function setQueryStringForTab(tabId: string): void {
	updateQueryString({ tab: tabId }, 'replaceState');
}

export function getTabFromQueryString(): string {
	return parseQueryString().tab;
}

function setTabByTabId(tabId: string, tab: HTMLElement, tabsContent: HTMLElement[]) {
	if (tabId === tab.id) {
		tab.setAttribute('aria-selected', 'true');
		tab.parentElement.classList.add('is-active');
		filterTabs(tab.getAttribute('aria-controls'), tabsContent);
	} else {
		tab.setAttribute('aria-selected', 'false');
		tab.parentElement.classList.remove('is-active');
	}
}

function filterTabs(tabId: string, tabsContent: HTMLElement[]) {
	tabsContent.forEach(elt => {
		if (elt.dataset.tabContent === tabId) {
			elt.hidden = false;
			elt.setAttribute('aria-hidden', 'false');
		} else {
			elt.hidden = true;
			elt.setAttribute('aria-hidden', 'true');
		}
	});
}

import { location, msDocs } from './globals';
import { isPPE, isProduction, isReview } from './is-production';

const knownDocsEnvironments: { [index: string]: URL } = {
	// localhost port might not match if using non-standard port for dev
	localhost: new URL('http://localhost:3000/'),
	ppe: new URL('https://ppe.docs.microsoft.com/'),
	production: new URL('https://docs.microsoft.com/'),
	review: new URL('https://review.docs.microsoft.com/')
};

let _bestGuessEnvString: string = null;
const bestGuessEnvString = (): string => {
	if (_bestGuessEnvString !== null) {
		return _bestGuessEnvString;
	}

	_bestGuessEnvString = 'production';

	if (!isProduction || isReview) {
		if (isPPE) {
			_bestGuessEnvString = 'ppe';
		} else if (isReview) {
			_bestGuessEnvString = 'review';
		} else if (location.hostname.indexOf('localhost') > -1) {
			// is-production doesn't use globals which makes this a little more difficult for testing
			// alternative for testability without potentially breaking everything would be to add an isLocalhost export to is-production
			_bestGuessEnvString = 'localhost';
		}
	}

	return _bestGuessEnvString;
};

export const getBaseUrl = (): URL => knownDocsEnvironments[bestGuessEnvString()];

const getCurrentUserLocale = (): string => (msDocs && msDocs.data && msDocs.data.userLocale) || '';

export const getBaseUserLocaleUrl = (locale: string = getCurrentUserLocale()): URL => {
	let baseUserLocaleUrl = getBaseUrl();
	if (locale !== '') {
		baseUserLocaleUrl = new URL(`/${locale}/`, baseUserLocaleUrl);
	}
	return baseUserLocaleUrl;
};

export function safeUrl(
	unsafeUrl: string = null,
	fallbackUrl: string = `${location.origin}${location.pathname}`
): URL {
	const targetUrl = unsafeUrl ? new URL(unsafeUrl) : new URL(fallbackUrl);

	if (
		targetUrl.protocol === 'https:' &&
		(targetUrl.hostname === location.hostname || targetUrl.hostname.endsWith('docs.microsoft.com'))
	) {
		return targetUrl;
	}

	const defaultFallbackUrl = `${location.origin}${location.pathname}`;
	return fallbackUrl === defaultFallbackUrl
		? new URL(`${targetUrl.pathname}${targetUrl.search}${targetUrl.hash}`, `${location.origin}`)
		: safeUrl(fallbackUrl);
}

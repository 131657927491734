import { initFacetSearch } from '../components/facet-search';
import { features } from '../environment/features';
import { contentLoaded } from '../globals';
import { createUpdatedFacetSearchConfig } from '../components/facet-search/implementations/site-search';

export async function searchPage() {
	if (features.facetSearch) {
		await contentLoaded;
		const container = document.getElementById('main-column') as HTMLElement;
		initFacetSearch(createUpdatedFacetSearchConfig(), container);
	}
}

import { shouldHandleClick } from '../anchors';
import { eventBus } from '../event-bus';
import { document, history, location, window } from '../globals';
import { isPPE, isReview } from '../is-production';
import { RouterAfterNavigateEvent, RouterBeforeNavigateEvent } from './events';
import { insertRouterProgress } from './progress';
import { beforeUnloadFns } from './utils';

/**
 * The result returned by a NavigationHandler. The url may be different
 * than the url passed to the handler if redirection occured.
 */
export interface NavigationResult {
	title: string;
	url: URL;
}

/**
 * Handles navigating to a url.
 */
export type NavigationHandler = (url: URL) => Promise<NavigationResult>;

/**
 * The router delegates navigation handling to this component.
 * todo: determine whether router should accept multiple Routes instead of a single delegate.
 */
export interface RouterDelegate {
	/**
	 * Handles navigating to a url.
	 */
	handle: NavigationHandler;

	/**
	 * Query string parameters that are relevant to routing
	 */
	params: string[];

	/**
	 * Gets whether the router should handle the url.
	 */
	canHandle(url: URL): boolean;
}

class Router {
	private readonly referrerStack: URL[] = [];
	private delegate: RouterDelegate;
	private finished = Promise.resolve();
	private currentUrl: URL;

	public enable(delegate: RouterDelegate, currentUrl: URL = new URL(location.href)) {
		this.delegate = delegate;
		this.currentUrl = currentUrl;
		window.addEventListener('click', this.handleClick, true);
		window.addEventListener('popstate', this.handlePopstate);
	}

	public disable() {
		this.delegate = null;
		window.removeEventListener('click', this.handleClick, true);
		window.removeEventListener('popstate', this.handlePopstate);
	}

	public get enabled() {
		return !!this.delegate;
	}

	public async finishNavigating() {
		await this.finished;
	}

	public goto(url: URL, mode: 'pushState' | 'replaceState') {
		if (!this.delegate) {
			throw new Error('Router is not enabled.');
		}
		if (url.origin !== location.origin) {
			throw new Error(`Cross-origin navigation is not permitted`);
		}
		if (!this.delegate.canHandle(url)) {
			throw new Error(`Router delegate cannot handle "${url.href}".`);
		}
		this.preserveBranch(this.currentUrl, url);
		return this.navigateInternal(url, mode === 'pushState');
	}

	private preserveBranch(from: URL, to: URL) {
		// preserve branch args in review/ppe.
		if (isReview || isPPE) {
			copyArgs(['branch', 'themebranch', 'api-branch'], from, to);
		}
	}

	private readonly handleClick = (event: MouseEvent) => {
		const { shouldHandle, anchor } = shouldHandleClick(event);
		if (!shouldHandle) {
			return;
		}
		const url = new URL(anchor.href);
		if (!this.delegate.canHandle(url)) {
			return;
		}

		event.preventDefault();
		this.preserveBranch(this.currentUrl, url);
		this.navigateInternal(url, true);
	};

	private readonly handlePopstate = () => {
		const url = new URL(location.href);
		if (this.routeChanged(url, this.currentUrl)) {
			this.referrerStack.pop();
			this.navigateInternal(url, false);
		} else {
			this.currentUrl = url;
		}
	};

	private readonly routeChanged = (url: URL, previousUrl: URL): boolean => {
		let same = url.pathname === previousUrl.pathname;
		for (const param of this.delegate.params) {
			same = same && url.searchParams.get(param) === previousUrl.searchParams.get(param);
		}
		return !same;
	};

	private navigateInternal(url: URL, pushState: boolean) {
		const go = async () => {
			// Invoke all the "before unload" callbacks.
			beforeUnloadFns.splice(0, beforeUnloadFns.length).forEach(fn => fn());
			// Notify that we're about to navigate.
			eventBus.publish(new RouterBeforeNavigateEvent(url));
			const progress = insertRouterProgress();
			// Delegate to the navigation handler implementation.
			const result = await this.delegate.handle(url);
			// Update the document title and history.
			document.title = result.title;
			if (pushState) {
				history.pushState(undefined, result.title, result.url.href);
				this.referrerStack.push(result.url);
			} else {
				history.replaceState(undefined, result.title, result.url.href);
			}
			// Scroll
			window.scrollTo(0, 0);
			scrollToHash(url.hash);
			// Notify that we've navigated.
			eventBus.publish(new RouterAfterNavigateEvent(result.title, result.url, this.currentUrl));
			progress.remove();
			this.currentUrl = url;
		};
		// Enqueue navigation
		const result = this.finished.then(go);
		this.finished = result.catch(() => {});
		return result;
	}
}

/**
 * The global router.
 */
export const router = new Router();

/**
 * Copies args from one URL to another.
 */
function copyArgs(names: string[], from: URL, to: URL) {
	for (const name of names) {
		const value = from.searchParams.get(name);
		if (value) {
			to.searchParams.set(name, value);
		}
	}
}

/**
 * Scrolls the element identified by the hash into view.
 */
export function scrollToHash(hash: string) {
	// Is there a hash longer than "#"?
	if (hash.length < 2) {
		return;
	}

	// Is there an element matching the hash?
	const element = document.body.querySelector(hash);
	if (!element) {
		return;
	}

	element.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
}

import { FSSelectedFacets } from './model';
import { UISearchArgs } from './view-model';

export function defaultFacetSearchFilterOptions(selectedFacets: FSSelectedFacets): string {
	const queries: string[] = [];

	for (const label in selectedFacets) {
		const facets = Object.keys(selectedFacets[label]);
		const query = facets
			.map((value: string) => {
				return label !== 'resource_type' && label !== 'type'
					? `(${label}/any(t: t eq '${value}'))`
					: `(${label} eq '${value.replace('_', ' ')}')`; // on browse, these values have spaces in them, but that's not okay
			})
			.join(' or ');

		if (query.length) {
			queries.push(`(${query})`);
		}
	}
	return queries.join(' and ');
}

/** Remove or reimplement when new faceted search service crawls into being. */
export function siteSearchFilterOptions(args: UISearchArgs): string {
	const scopeFilter = args.scope ? `scopes/any(t: t eq '${args.scope}')` : '';

	let selectedCategory: string;
	for (const c in args.selectedFacets.category) {
		selectedCategory = c;
		break;
	}

	const categoryFilter = selectedCategory ? `category eq '${selectedCategory}'` : '';

	if (scopeFilter && categoryFilter) {
		return `${scopeFilter} and ${categoryFilter}`;
	}

	return scopeFilter ? scopeFilter : categoryFilter;
}

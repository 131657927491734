import { loc_search } from '@msdocs/strings';
import { platformConfig } from '../../api-browser/platform-config';
import { reportApiSearchWithJsll } from '../../api-browser/search';
import { processUrl as processApiResultUrl } from '../../api-browser/search-results';
import { search as apiSearch } from '../../apis/api-browser';
import {
	autocompleteChangeEvent,
	AutoCompleteSuggestionDetail,
	createAutoComplete
} from '../../components/autocomplete';
import { location, msDocs } from '../../globals';
import { html, unsafeHTML } from '../../lit-html';
import { getMoniker } from '../../monikers/moniker';
import { platformId } from '../../monikers/platform';
import { breakText, escape } from '../../text-formatting';
import {
	getSiteSearchSuggestion,
	isSiteSearch,
	reportAutocompleteWithJsll,
	suggestionClass
} from './shared';

async function getSuggestions(term: string) {
	const { validSearchTerm } = platformConfig[platformId];
	const results: Api.ApiItem[] = [];
	if (validSearchTerm.test(term)) {
		const moniker = getMoniker();
		const response = await apiSearch(platformId, moniker, term, msDocs.data.userLocale);
		results.push(...response.results);
		reportApiSearchWithJsll(moniker, term, response.results.length);
	}

	const useScope = platformId !== 'rest'; // rest api browser is scoped site search.
	const { url, title: displayName } = getSiteSearchSuggestion(term, useScope);
	results.push({ url, displayName, description: '' });
	return results;
}

function itemTemplate(result: Api.ApiItem) {
	const { namespaceItemType } = platformConfig[platformId];
	const { displayName, url, itemKind, itemType } = result;
	if (isSiteSearch(url)) {
		return html`<span class="${suggestionClass} has-text-primary">${displayName}</span>`;
	}
	return html` <span class="${suggestionClass} has-overflow-ellipsis-tablet">
		${unsafeHTML(breakText(escape(displayName)))}
		<span class="has-text-subtle">${itemKind || itemType || namespaceItemType}</span>
	</span>`;
}

export function createApiSearchAutocomplete(id: string) {
	const autocomplete = createAutoComplete<Api.ApiItem>({
		input: {
			id,
			placeholder: loc_search,
			docon: 'search',
			isFullWidth: true,
			size: 'small'
		},
		getSuggestions,
		getTitle: result => result.displayName,
		itemTemplate
	});

	autocomplete.addEventListener(
		autocompleteChangeEvent,
		(event: CustomEvent<AutoCompleteSuggestionDetail<Api.ApiItem>>) => {
			const title = event.detail.suggestion.displayName;
			const url = event.detail.suggestion.url;
			const href = isSiteSearch(url) ? url : processApiResultUrl(url, getMoniker());
			reportAutocompleteWithJsll(autocomplete, title, href);
			location.href = href;
		}
	);
	return autocomplete;
}

import { contentLoaded, msDocs } from '../globals';
import { generateUuidV4 } from '../html';
import { isProduction } from '../is-production';
import { loadLibrary } from '../load-library';
import { getMeta } from '../meta';
import { sessionStorage } from '../protected-storage';

export const fingerprintSid = getSessionId();

export function fingerprint() {
	const isLabOrSandbox = getMeta('azure_sandbox') === 'true' || getMeta('lab-id');
	if (msDocs.data.pageTemplate !== 'ModuleUnit' || !isLabOrSandbox) {
		return;
	}
	sentinelFingerprint();
	riskFingerprint();
}

async function riskFingerprint() {
	const url = new URL('https://fpt.microsoft.com/tags');
	url.searchParams.set('session_id', fingerprintSid);
	// Optional name value collection (partner provided data about the user, FormId and SubmitId) for further processing.
	// url.searchParams.set('pctx', ????)

	const iframe = document.createElement('iframe');
	iframe.src = url.href;

	// Risk guide recommends this rather than visually-hidden to avoid browser deferring loading the iframe.
	iframe.style.cssText = 'width: 100px; height: 100px; position: absolute; top: -5000px;';

	// Hide from assistive tech
	iframe.title = 'empty';
	iframe.tabIndex = -1;
	iframe.setAttribute('aria-hidden', 'true');
	iframe.setAttribute('aria-disabled', 'true');

	await contentLoaded;
	document.body.appendChild(iframe);
}

function sentinelFingerprint() {
	const orgid = isProduction ? 'y6jn8c31' : 'w0rnzpxd';
	const taggingUrl = `https://vlscppe.microsoft.com/fp/tags.js?org_id=${orgid}&session_id=${fingerprintSid}`;
	loadLibrary(taggingUrl);
}

function getSessionId(): string {
	let sid = sessionStorage.getItem('x-sid');
	if (sid) {
		return sid;
	}

	sid = generateUuidV4();
	sessionStorage.setItem('x-sid', sid);
	return sid;
}

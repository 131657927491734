import {
	loc_azureSandbox_allActivationsRemaining,
	loc_checkWorkAgain,
	loc_moduleRequiresSandbox,
	loc_taskNotComplete,
	loc_youMustUseSandboxResources
} from '@msdocs/strings';
import { TaskValidationCredential, TaskValidationResult } from '../apis/learn';
import { authStatusDetermined } from '../auth/index';
import { azureSandbox, AzureSandboxChangedEvent } from '../azure/sandbox';
import { renderSandboxPrompt } from '../azure/sandbox-prompt/index';
import { features } from '../environment/features';
import { eventBus } from '../event-bus';
import { msDocs } from '../globals';
import {
	InteractiveComponent,
	InteractiveType,
	renderInteractiveComponent
} from '../interactivity/activation';
import { promptLab } from '../learn/lab-prompt';
import { taskValidationModal } from '../learn/task-validation';
import { CombinedProgressService } from '../learn/unit-progress';
import { html, render, unsafeHTML } from '../lit-html';
import { getMeta } from '../meta';
import { beforeUnload } from '../router/utils';
import { reportValidation } from './bi';
import { UnitModuleConfig, UnitPageConfig } from './config';
import { renderSandboxLodIsolatedPrompt } from '../azure/sandbox-prompt/ui';

export async function setupTaskValidation(
	combinedProgressService: CombinedProgressService,
	config: UnitModuleConfig
) {
	const { hasTaskValidation, unitId, module, unitIndex } = config;

	if (!features.gamification || !combinedProgressService) {
		return;
	}
	if (!hasTaskValidation) {
		return;
	}

	await authStatusDetermined;

	const currentUnit = module.units[unitIndex];
	const nextUnitUrl = module.units[unitIndex + 1].url;
	const validateButton = document.getElementById('task-validate') as HTMLButtonElement;
	const validateMessage = document.getElementById('task-validation-message') as HTMLElement;
	const setButtonVisibility = () =>
		(validateButton.hidden = validateMessage.hidden = azureSandbox.value === null);

	// set initial visibility for validate task button
	setButtonVisibility();

	// listen for changes to sandbox state
	beforeUnload(eventBus.subscribe(AzureSandboxChangedEvent, setButtonVisibility));

	let isSubmitting = false;
	validateButton.onclick = handleValidationRequest;

	async function handleValidationRequest() {
		if (isSubmitting) {
			return;
		}

		const startTime = new Date();

		validateButton.classList.add('is-loading');
		isSubmitting = true;
		validateMessage.classList.add('is-hidden');

		if (azureSandbox.value) {
			const taskValidations: TaskValidationCredential[] = [
				{
					type: 'FreeAzureSubscription',
					azureSubscription: azureSandbox.value.subscriptionId,
					resourceGroup: azureSandbox.value.resourceGroupName
				}
			];

			try {
				const event = await combinedProgressService.validateTask(unitId, taskValidations);
				const endTime = new Date();
				reportValidation(event.passed, startTime, endTime);

				// only show the error modal one time
				if (event.passed) {
					taskValidationModal(true, currentUnit, nextUnitUrl);
					validateMessage.textContent = '';
					validateButton.setAttribute('disabled', 'disabled');
					validateButton.classList.add('is-hidden');
					validateMessage.classList.add('is-hidden');
				} else {
					let errorHtml = null;
					if (event.details && event.details.length > 0) {
						// show hint if the API responds
						const details = event.details as TaskValidationResult[];
						const availableHints = details.filter(x => x.hint); // hints only return on incomplete tasks
						const hint = availableHints[0].hint;
						validateMessage.innerHTML = hint;
						errorHtml = hint;
					}
					validateButton.innerText = loc_checkWorkAgain;
					taskValidationModal(false, currentUnit, nextUnitUrl, errorHtml);
				}
			} catch (responseStatus) {
				if (responseStatus === 429) {
					validateMessage.innerHTML = loc_taskNotComplete;
					validateButton.innerText = loc_checkWorkAgain;
					validateMessage.classList.remove('is-hidden');
				} else {
					throw responseStatus;
				}
			} finally {
				validateButton.classList.remove('is-loading');
				isSubmitting = false;
			}
		} else {
			validateMessage.classList.remove('is-hidden');
			validateMessage.innerText = loc_youMustUseSandboxResources;
			validateButton.classList.remove('is-loading');
			isSubmitting = false;
		}
	}
}

export function handleAzureSandbox(interactiveContainer: Element, config: UnitPageConfig) {
	const { isChromeless, hasSandbox, interactiveType } = config;

	// render the sandbox prompt if the unit uses a sandbox and we're not in chromeless mode.
	if (!isChromeless && hasSandbox) {
		const resourceAlert = createResourceAlert();
		renderSandboxPrompt(resourceAlert);
	}

	// check whether the unit uses an interactive component
	if (
		!interactiveType ||
		interactiveType.flags.isExternal ||
		isChromeless ||
		!interactiveContainer
	) {
		return;
	}

	let component: InteractiveComponent | null;
	const renderInteractive = () => {
		if (hasSandbox && !azureSandbox.value) {
			// this unit requires a sandbox but the sandbox has not been activated yet...
			// render a placeholder for the interactive component.
			renderInteractivePlaceholder(interactiveType.name, interactiveContainer);
			// destroy any existing interactive component.
			if (component) {
				component.dispose();
				component = null;
			}
		} else {
			// this unit does not require a sandbox OR the sandbox is already ready...
			// render the interactive component immediately.
			component = renderInteractiveComponent(interactiveType, interactiveContainer);
		}
	};

	renderInteractive();

	// when the sandbox changes we need to reset the interactive component.
	beforeUnload(eventBus.subscribe(AzureSandboxChangedEvent, renderInteractive));
}

export async function handleLab(config: UnitPageConfig) {
	const { isChromeless } = config;
	const labId = getMeta('lab-id');

	if (!isChromeless && labId) {
		const resourceAlert = createResourceAlert();
		if (!features.labsOnDemand) {
			renderSandboxLodIsolatedPrompt(resourceAlert, false);
			return;
		}
		const labIsModal = getMeta('lab-modal') === 'true';
		const labUrl = await promptLab(+labId, labIsModal, resourceAlert);
		if (!labIsModal) {
			location.href = labUrl;
		}
	}
}

export function createResourceAlert() {
	const { userDir, userLocale } = msDocs.data;
	const metadataControl = document.querySelector('.page-metadata') as HTMLElement;
	const sandboxAlertHtml = `<div class="alert top-alert is-hidden-portrait" dir="${userDir}" lang="${userLocale}"></div>`;
	metadataControl.insertAdjacentHTML('afterend', sandboxAlertHtml);

	const alertContainer = metadataControl.nextElementSibling;
	return alertContainer;
}

export function renderInteractivePlaceholder(type: InteractiveType, container: Element) {
	switch (type) {
		case 'bash':
		case 'powershell':
			render(
				html`
					<div
						class="is-monospace is-size-small has-${type}-colors has-padding-medium is-full-height is-vertically-scrollable"
					>
						<p>Azure Cloud Shell</p>
						<p>
							${loc_moduleRequiresSandbox} ${unsafeHTML(loc_azureSandbox_allActivationsRemaining)}
						</p>
					</div>
				`,
				container
			);
			break;
		default:
			render(html``, container);
			break;
	}
}

import {
	loc_certificationExamOfficialPracticeTest,
	loc_exam,
	loc_forNonStudentsInterestedInTechnology,
	loc_forStudentsOrInstructors,
	loc_gsi_comingSoonLearnMore,
	loc_gsi_forJobSeekers,
	loc_gsi_learnAboutGsi,
	loc_none,
	loc_scheduleExam,
	loc_scheduleWithCertiport,
	loc_scheduleWithPearsonVue
} from '@msdocs/strings';
import { html, unsafeHTML, render } from '../../lit-html';
import { localeNames } from '../../name-maps/locales';

export function updateExamCard(container: HTMLElement) {
	updateLangs(container);
	renderScheduleExam(container);
	updateExamCardTitle(container);
	updateExamPracticeTestTitle(container);
}

export function updateExamRetirementDate(container: HTMLElement) {
	const retirementElements = Array.from(
		container.querySelectorAll<HTMLElement>(`.retirement-date-none`)
	);
	retirementElements.forEach(element => {
		element.textContent = loc_none;
	});
}

function updateLangs(container: HTMLElement) {
	const localeElements = Array.from(container.querySelectorAll<HTMLElement>(`.locale`));
	localeElements.forEach(element => {
		const locale: string = element.textContent.trim();
		element.textContent = localeNames[locale];
	});
}

function renderScheduleExam(container: HTMLElement) {
	const examCardElements = Array.from(
		container.querySelectorAll('[data-learn-type="certification"]')
	);
	examCardElements.forEach(examCardElement => {
		const providersElement = examCardElement.querySelector<HTMLElement>(`.providers`);
		const retirementDateElement = examCardElement.querySelector<HTMLElement>('time');

		if (
			retirementDateElement !== null &&
			new Date(retirementDateElement.getAttribute('datetime')) <= new Date()
		) {
			if (providersElement !== null) {
				providersElement.hidden = true;
			}
			return;
		}

		const scheduleElements = Array.from(
			examCardElement.querySelectorAll<HTMLElement>(`.schedule-exam`)
		);
		if (scheduleElements.length === 1) {
			const titleElement = scheduleElements[0].querySelector<HTMLElement>('.schedule-exam-title');
			const textElement = scheduleElements[0].querySelector<HTMLElement>('.schedule-exam-text');
			titleElement.hidden = true;
			textElement.textContent = loc_scheduleExam;
			return;
		}

		scheduleElements.forEach(scheduleElement => {
			const titleElement = scheduleElement.querySelector<HTMLElement>('.schedule-exam-title');
			const textElement = scheduleElement.querySelector<HTMLElement>('.schedule-exam-text');

			const providerType = scheduleElement.getAttribute('data-provider-type');
			if (providerType === 'certiport') {
				titleElement.textContent = loc_forStudentsOrInstructors;
				textElement.textContent = loc_scheduleWithCertiport;
			} else if (providerType === 'pearson-vue') {
				titleElement.textContent = loc_forNonStudentsInterestedInTechnology;
				textElement.textContent = loc_scheduleWithPearsonVue;
			} else if (providerType === 'gsi') {
				titleElement.textContent = loc_gsi_forJobSeekers;
				textElement.textContent = loc_gsi_comingSoonLearnMore;

				const explainElement = scheduleElement.querySelector<HTMLElement>(
					'.schedule-exam-explanation'
				);
				explainElement.hidden = false;
				render(html`${unsafeHTML(loc_gsi_learnAboutGsi)}`, explainElement);
			}
		});
	});
}

function updateExamCardTitle(container: HTMLElement) {
	const titleElements = Array.from(container.querySelectorAll<HTMLElement>(`.exam-card-title`));
	titleElements.forEach(element => {
		element.textContent = loc_exam + ' ' + element.textContent;
	});
}

function updateExamPracticeTestTitle(container: HTMLElement) {
	const practiceTestElements = Array.from(
		container.querySelectorAll<HTMLElement>(`.exam-practice-test`)
	);
	practiceTestElements.forEach(element => {
		if (element.getAttribute('href') === '') {
			element.parentElement.hidden = true;
		} else {
			element.textContent = loc_certificationExamOfficialPracticeTest;
		}
	});
}

import { html, render } from '../../lit-html';
import { CountryId, countryMap } from '../../country-map';
import { msDocs } from '../../globals';
import { ExamsPricing } from './get-pricing';

export async function renderExamsPrices(container: HTMLElement, examsPricing: ExamsPricing) {
	const examElements = Array.from(
		container.querySelectorAll<HTMLElement>(`div[data-learn-type="certification"]`)
	);

	examElements.forEach(element => {
		const examUid = element.getAttribute('data-learn-uid');
		const examType = getExamType(examUid, examsPricing);
		renderExamsPrice(element, examsPricing, examType);
	});
}

function getExamType(examUid: string, examsPricing: ExamsPricing): string {
	const specialExamType = examUid.replace('exam.', '').toLowerCase();
	const examTypes: string[] = examsPricing.exams.map(exam => exam.examType.toLowerCase());
	if (examTypes.indexOf(specialExamType) !== -1) {
		// Found full match with exam names which need default pricing with currency and amount info defined in exam.price object
		// Special case exam types which are actually exam name such as "AZ-900", "MS-202"... need special pricing
		return specialExamType;
	}

	// Normal case exam types such as "AZ", "70"...
	return specialExamType.split('-')[0];
}
export async function renderExamsPrice(
	container: HTMLElement,
	examsPricing: ExamsPricing,
	examType: string
) {
	const retirementDateElement = container.querySelector<HTMLElement>('time');
	if (
		retirementDateElement !== null &&
		new Date(retirementDateElement.getAttribute('datetime')) <= new Date()
	) {
		const pricingSelectElement = container.querySelector<HTMLElement>('.exam-countries');
		pricingSelectElement.parentElement.parentElement.hidden = true;
		return;
	}

	const exam = examsPricing.exams.filter(
		e => examType.toLowerCase() === e.examType.toLowerCase()
	)[0];

	// filter out the countries in availability list which has no amount and don't have default price amount
	const availability = exam.price
		? exam.availability
		: exam.availability.filter(item => item.amount);

	// flatten exams pricing info to join with country info
	const pricesWithCountryInfo: PriceWithCountryInfo[] = availability.map(p => {
		// availability array according country's price amount take precedence than price object, if found use that. If not, use price object default amount and countryCode to decide currency info.
		const amount = p.amount ? p.amount : exam.price.amount;
		const currencyCountry = p.amount
			? examsPricing.countries.find(a => a.countryCode === p.countryCode)
			: examsPricing.countries.find(a => a.countryCode === exam.price.countryCode);

		return {
			countryCode: p.countryCode,
			amount,
			currencySymbol: currencyCountry.currencySymbol,
			currencyCode: currencyCountry.currencyCode,
			message: currencyCountry.message
		};
	});

	const selectElement = container.querySelector<HTMLSelectElement>('.exam-countries');

	pricesWithCountryInfo.sort((a, b) =>
		countryMap[a.countryCode].localeCompare(countryMap[b.countryCode], undefined, {
			sensitivity: 'base'
		})
	);

	const templateResult = html`${pricesWithCountryInfo.map(x => {
		const isSelected = msDocs.data.userLocale.indexOf(x.countryCode.toLowerCase()) !== -1;
		if (isSelected) {
			renderExamPriceAmount(container, x);
		}
		return html`<option ?selected=${isSelected} value="${x.countryCode}"
			>${countryMap[x.countryCode]}</option
		>`;
	})}`;

	render(templateResult, selectElement);

	selectElement.onchange = () => {
		const priceWithCountryInfo = pricesWithCountryInfo.filter(
			p => p.countryCode === selectElement.value
		)[0];
		renderExamPriceAmount(container, priceWithCountryInfo);
	};
}

function renderExamPriceAmount(container: HTMLElement, priceWithCountryInfo: PriceWithCountryInfo) {
	const amountElement = container.querySelector<HTMLElement>('.exam-amount');
	const priceHtml = `${
		priceWithCountryInfo.currencySymbol
	}${priceWithCountryInfo.amount.toString()} ${priceWithCountryInfo.currencyCode}*`;
	render(priceHtml, amountElement);
}

interface PriceWithCountryInfo {
	countryCode: CountryId;
	amount: number;
	currencySymbol: string;
	currencyCode: string;
	message?: string;
}

import {
	loc_activateNewSandbox,
	loc_activateSandbox,
	loc_azureSandbox_allActivationsRemaining,
	loc_azureSandbox_needPermissions,
	loc_azureSandbox_resourcePrioritizationDisclaimer,
	loc_azureSandbox_reviewPermissions,
	loc_azureSandbox_someActivationsRemainingFormat,
	loc_azureSandbox_zeroActivationsRemainingFormat,
	loc_close,
	loc_differentSandboxAlreadyActive,
	loc_differentSandboxAlreadyActiveExplanation,
	loc_finishModuleFormat,
	loc_isolatedMessageSandboxLod,
	loc_moduleRequiresSandbox,
	loc_retryActivatingSandbox,
	loc_sandboxAccessAvailable,
	loc_sandboxDuration,
	loc_sandboxDurationDetails,
	loc_sandboxLoginExpired,
	loc_sandboxTemporaryDisabled,
	loc_signInToActivateSandbox,
	loc_portallab_launchButton
} from '@msdocs/strings';
import { html, render, TemplateResult, unsafeHTML } from '../../lit-html';
import { escape } from '../../text-formatting';
import { convertMillisecondsToMins, convertMinsToHrsMins } from '../../time-format';
import { AzureSandbox, getRemainingSandboxTime } from '../sandbox';
import * as urlActivation from './url-activation';
import { signInClassName } from '../../auth/sign-in-links';
import { msDocs } from '../../globals';
import { Modal } from '../../components/modal/modal';
import { isReview, isPPE } from '../../is-production';
import { troubleshootingLinkTemplate } from '../../risk-evaluation/risk-templates';

let sharedInterval = 0; // shared interval handle for animated UI states

/**
 * End animations.
 */
export function clearAnimations() {
	clearInterval(sharedInterval);
}

export function renderSandboxDisabled(container: Element) {
	render(
		html`
			<p>${unsafeHTML(loc_sandboxTemporaryDisabled)}</p>
			${troubleshootingLinkTemplate()}
		`,
		container
	);
}

let progressStartTime = 0;
const progressMaxMs = 1000 * 60;

export function resetProgress() {
	progressStartTime = Date.now();
}

export function renderProgress(container: Element, message: string, isComplete = false) {
	clearInterval(sharedInterval);

	const getPercentComplete = () => {
		if (isComplete) {
			return 1;
		}
		let t = Math.min(0.999, (Date.now() - progressStartTime) / progressMaxMs);
		return --t * t * t + 1; // ease out cubic: https://gist.github.com/gre/1650294
	};

	const template = (percentComplete: number) => html` <p>
		<progress
			class="progress is-extra-small is-inline-block has-margin-bottom-extra-small"
			value="${percentComplete * 100}"
			max="100"
			style="height: 4px"
			aria-describedby="sandbox-progress-description"
		>
		</progress>
		<strong
			class="progress-percentage has-padding-left-small has-padding-left-small"
			aria-hidden="true"
		>
			${Math.floor(percentComplete * 100)}%
		</strong>
		<span id="sandbox-progress-description">${escape(message)}</span>
	</p>`;

	const update = () => render(template(getPercentComplete()), container);

	update();

	if (!isComplete) {
		sharedInterval = setInterval(update, 50);
	}
}

export function renderSignInPrompt(container: Element) {
	render(
		html`
			<p>
				${loc_moduleRequiresSandbox} ${unsafeHTML(loc_azureSandbox_allActivationsRemaining)}
			</p>
			<p>
				<a class="button ${signInClassName}" href="${urlActivation.createReturnUrl()}"
					>${loc_signInToActivateSandbox}
				</a>
			</p>
		`,
		container
	);
}

export function renderSandboxLodIsolatedPrompt(container: Element, isSandbox: boolean = true) {
	const subscriptionLink = `${location.origin}/${msDocs.data.userLocale}/learn/support/use-your-own-subscription`;
	const isolatedMessage = loc_isolatedMessageSandboxLod.replace(
		'{subscriptionLink}',
		subscriptionLink
	);
	render(
		html`
			<p>${unsafeHTML(isolatedMessage)}</p>
			<p>
				<button disabled type="button" class="button">
					${isSandbox ? loc_activateSandbox : loc_portallab_launchButton}
				</button>
			</p>
		`,
		container
	);
}

export function renderLoginExpiredPrompt(container: Element) {
	render(
		html`
			<p>${loc_sandboxLoginExpired}</p>
			${troubleshootingLinkTemplate()}
			<p>
				<a class="button ${signInClassName}" href="${urlActivation.createReturnUrl()}"
					>${loc_signInToActivateSandbox}
				</a>
			</p>
		`,
		container
	);
}

export function renderActivatePrompt(container: Element, sandbox: AzureSandbox) {
	const remaining = sandbox.sandboxesRemaining;
	const noSandboxesRemaining = remaining === 0 ? true : false;
	const limit = sandbox.sandboxesPermitted;

	const getExplanation = () => {
		let explanation: TemplateResult;
		if (remaining === 0) {
			explanation = html`
				${loc_azureSandbox_zeroActivationsRemainingFormat.replace(
					'{num-limit}',
					limit.toString(10)
				)}
			`;
		} else if (remaining < limit) {
			explanation = html`
				${loc_azureSandbox_someActivationsRemainingFormat
					.replace('{num-used}', (limit - remaining).toString(10))
					.replace('{num-limit}', limit.toString(10))}
			`;
		} else {
			explanation = html`
				${unsafeHTML(loc_azureSandbox_allActivationsRemaining)}
				<p>${unsafeHTML(loc_azureSandbox_resourcePrioritizationDisclaimer)}</p>
			`;
		}
		return explanation;
	};

	const template = (explanation: TemplateResult) => html`
		<p>
			${loc_moduleRequiresSandbox} ${explanation}
		</p>
		<p></p>
		<div ?hidden=${!noSandboxesRemaining}>
			${troubleshootingLinkTemplate()}
		</div>
		<p ?hidden=${noSandboxesRemaining}>
			<button type="button" class="button" data-bi-name="activate">${loc_activateSandbox}</button>
		</p>
	`;
	render(template(getExplanation()), container);
}

export function renderRetryPrompt(container: Element, message: string) {
	render(
		html`
			<p>${message}</p>
			${troubleshootingLinkTemplate()}
			<p>
				<button type="button" class="button" data-bi-name="retry">
					${loc_retryActivatingSandbox}
				</button>
			</p>
		`,
		container
	);
}

export function renderTenantInvite(container: Element, invitationUrl: string) {
	render(
		html`
			<p>${loc_azureSandbox_needPermissions}</p>
			${troubleshootingLinkTemplate()}
			<p>
				<a class="button" href="${invitationUrl}" data-bi-name="tenant-invite">
					${loc_azureSandbox_reviewPermissions}
				</a>
			</p>
		`,
		container
	);
}

export function renderReleaseModal(currentModule: { title: string; url: string }) {
	const modalContent = document.createElement('div');
	const finishModule = loc_finishModuleFormat.replace('{module}', currentModule.title);

	render(
		html`
			<div
				id="consentModal"
				class="modal-card is-shadowless-mobile fill-mobile has-padding-medium has-padding-large-tablet"
				aria-describedby="modalText"
				aria-labelledby="modalTitle"
			>
				<button
					class="modal-close modal-card-close-button is-large is-sticky"
					data-bi-name="close"
					aria-label="${loc_close}"
				></button>
				<section class="modal-card-body">
					<h2 id="modalTitle" class="is-text-centered">${loc_differentSandboxAlreadyActive}</h2>
					<p id="modalText">${unsafeHTML(loc_differentSandboxAlreadyActiveExplanation)}</p>
					<button
						class="modal-close button is-primary is-block has-margin-auto-sides has-margin-top-large"
						data-bi-name="release"
						data-modal-result="release"
						autofocus
					>
						${loc_activateNewSandbox}
					</button>
					<a
						class="is-block is-size-small is-text-centered has-margin-top-small"
						href="${currentModule.url}"
					>
						${finishModule}
					</a>
				</section>
			</div>
		`,
		modalContent
	);

	modalContent.setAttribute('data-bi-name', 'release-sandbox-modal');
	const anchorElement = modalContent.querySelector('#modalText a') as HTMLAnchorElement;
	anchorElement.href = currentModule.url;
	anchorElement.textContent = currentModule.title;
	return new Modal(modalContent);
}

export function renderSandboxActivated(container: Element, sandbox: AzureSandbox) {
	clearInterval(sharedInterval);

	const hideDeactivate = !(isReview || isPPE);

	const remaining = sandbox.sandboxesRemaining;
	const limit = sandbox.sandboxesPermitted;
	let explanation: string;

	if (remaining === 0) {
		explanation = loc_azureSandbox_zeroActivationsRemainingFormat.replace(
			'{num-limit}',
			limit.toString(10)
		);
	} else {
		explanation = loc_azureSandbox_someActivationsRemainingFormat
			.replace('{num-used}', (limit - remaining).toString(10))
			.replace('{num-limit}', limit.toString(10));
	}

	const template = (sandbox: AzureSandbox) => html`
		<span>${loc_sandboxAccessAvailable}</span>
		<div class="dropdown has-caret is-inline">
			<button
				class="dropdown-trigger button is-text has-padding-none"
				style="height: auto"
				aria-expanded="false"
				aria-describedby="sandbox-duration-menu"
				aria-label="${loc_sandboxDuration} ${convertMinsToHrsMins(
					convertMillisecondsToMins(getRemainingSandboxTime(sandbox))
				)}"
				aria-controls="sandbox-duration-menu"
			>
				<time class="sandbox-remaining-time has-text-weight-semibold is-underlined-dotted">
					${convertMinsToHrsMins(convertMillisecondsToMins(getRemainingSandboxTime(sandbox)))}
				</time>
			</button>
			<div
				class="dropdown-menu has-width-250 box has-margin-top-medium is-size-small has-line-height-reset"
				id="sandbox-duration-menu"
				role="tooltip"
				aria-labelledby="sandbox-duration-details"
			>
				<div class="has-text-wrap" id="sandbox-duration-details">
					${loc_sandboxDurationDetails}
				</div>
				<button
					class="dropdown-menu-close delete is-text is-absolute has-top-zero has-right-zero has-margin-extra-small has-inner-focus"
					aria-label="${loc_close}"
				></button>
			</div>
		</div>
		<p>${explanation}</p>
		<p ?hidden=${hideDeactivate}>
			<button id="deactivate-sandbox" type="button" class="button" data-bi-name="deactivate">
				Deactivate Sandbox
			</button>
		</p>
		<p ?hidden=${hideDeactivate} class="help has-margin-top-none">
			Note: the deactivate sandbox button is not available in production.
		</p>
	`;

	const update = () => render(template(sandbox), container);

	update();

	sharedInterval = setInterval(update, 1000 * 60, container, sandbox);
}

import { TreeNodeAccessor } from '../components/tree';
import { parseQueryString, toQueryString } from '../query-string';
import { breakText, escape, insertLeftToRightMark, WordBreakFlags } from '../text-formatting';
import { cloneAsLeaf } from './transforms';
import { TocNode } from './types';

/**
 * Maps the toc.json data structure to the structure expected by
 * the tree UX component.
 */
export class TocNodeAccessor implements TreeNodeAccessor<TocNode> {
	constructor(
		private readonly getMoniker: () => string,
		private readonly tocUrl: string,
		private readonly breadcrumbUrl: string,
		private readonly isRtl: boolean
	) {}

	public hasChildren(node: TocNode) {
		return !!node.children;
	}

	public children(node: TocNode) {
		// if the parent node has a URL, create a child node from the parent node.
		if (node.url) {
			cloneAsLeaf(node);
		}
		return node.children;
	}

	public textTitle(node: TocNode) {
		return this.isRtl ? insertLeftToRightMark(node.toc_title, 'text') : node.toc_title;
	}

	public htmlTitle(node: TocNode) {
		return breakText(
			escape(this.isRtl ? insertLeftToRightMark(node.toc_title, 'html') : node.toc_title),
			WordBreakFlags.Dot
		);
	}

	public href(node: TocNode) {
		if (node.url.external) {
			return node.url.href;
		}

		let { origin, pathname, search, hash } = node.url;
		const moniker = this.getMoniker();

		if (moniker || node.maintainContext) {
			const args = parseQueryString(search);
			args.view = moniker;
			if (node.maintainContext) {
				args.toc = this.tocUrl || null;
				args.bc = this.breadcrumbUrl || null;
			}
			search = '?' + toQueryString(args);
		}

		// On localhost, append "index" to urls ending with "/" because
		// the dev hosting layer serves directory listings at ".../" urls.
		if (location.hostname === 'localhost' && /\/$/.test(pathname)) {
			pathname += 'index';
		}

		return origin + pathname + search + hash;
	}
	public isNewSection(node: TocNode) {
		return !!node.isNewSection;
	}
	public isExpanded(node: TocNode) {
		return !!node.expanded;
	}
	public isSelected(node: TocNode) {
		return !!node.selected;
	}
	public setHtmlAttributes(node: TocNode, set: (name: string, value: string) => void) {
		if (node.monikers && node.monikers.length) {
			set('data-moniker', node.monikers.join(' '));
		}
	}
}

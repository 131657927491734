import { loc_pleaseFillOut } from '@msdocs/strings';
import { EventBus, eventBus as globalEventBus } from '../event-bus';
import { createHtmlId, generateElementId, generateUuidV4 } from '../html';
import { FormModel, getModel, SubmitFormRequest, SubmitFormResponse } from './api';
import { buildFormFromModel } from './template';

const valueStrategies = {
	text: (element: HTMLInputElement) => () => element.value,
	number: (element: HTMLInputElement) => () => element.value,
	boolean: (element: HTMLInputElement) => () => element.checked
};

export class FormSubmitSuccessEvent {
	public readonly message: SubmitFormResponse['data'];

	constructor(public readonly formId: string, message: SubmitFormResponse['data']) {
		this.message = message;
		this.message.responseText = message.responseText || '';
	}
}

export class FormSubmitErrorEvent {
	constructor(public readonly formId: string, public readonly error: any) {}
}

export class FormSubmitEvent {
	constructor(public readonly formId: string, public readonly message: SubmitFormRequest) {}
}

export class ChromelessForm {
	private eventBus: EventBus;
	private formElement: HTMLFormElement;
	private id: string;
	private action: string;
	private modelPath: string;
	private formValueGetters = {} as { [name: string]: () => number | boolean | string };

	constructor(eventbus: EventBus = globalEventBus, formElement: HTMLFormElement) {
		this.eventBus = eventbus;
		this.formElement = formElement;
		this.id = generateElementId();
		this.formElement.id = this.id;
		this.action = formElement.getAttribute('data-action');
		this.modelPath = formElement.getAttribute('data-model');
	}

	public init() {
		this.eventBus.subscribe(FormSubmitSuccessEvent, event => {
			if (event.formId !== this.id) {
				return;
			}

			this.formElement.innerHTML = `
				<div class="notification is-success">
					${event.message.responseText}
				</div>
			`;
		});

		this.eventBus.subscribe(FormSubmitErrorEvent, event => {
			if (event.formId !== this.id) {
				return;
			}

			Array.from(this.formElement.elements).forEach(element => {
				element.removeAttribute('disabled');
			});
		});

		this.formElement.addEventListener('submit', event => {
			event.preventDefault();
			Array.from(this.formElement.elements).forEach(element => {
				element.setAttribute('disabled', 'disabled');
			});

			this.eventBus.publish(new FormSubmitEvent(this.id, this.toMessage()));
		});

		const promise = this.modelPath
			? getModel(this.modelPath)
			: Promise.resolve({ inputs: [] } as { inputs: FormModel[] });

		return promise
			.then(model => {
				this.formElement.querySelector('div:first-child').innerHTML = buildFormFromModel(
					model.inputs
				);
				configureValidation(this.formElement);

				this.formValueGetters = model.inputs.reduce((p, c) => {
					const input = this.formElement.querySelector(
						`#${createHtmlId(c.name)}`
					) as HTMLInputElement;
					p[createHtmlId(c.name)] = (valueStrategies[c.type] || Function.prototype)(input);
					return p;
				}, {} as { [id: string]: () => number | boolean | string });

				Array.from(this.formElement.elements).forEach(element => {
					element.removeAttribute('disabled');
				});
			})
			.catch(() => {
				// If a model is specified and we don't have it, the form probably shouldn't show as we don't want undefined behavior.
				// TODO: Hide form or something of that nature. We probably need to figure out the scenario for this.
				this.formElement.style.display = 'none';
			});
	}

	private toMessage(): SubmitFormRequest {
		const requestModel = Object.keys(this.formValueGetters).reduce((state, key) => {
			state[key] = (this.formValueGetters[key] || Function.prototype)();
			return state;
		}, {} as { [name: string]: number | boolean | string });

		const id = generateUuidV4();
		return {
			id,
			type: 'Forms',
			signature: 'pcIframe',
			data: {
				id,
				action: this.action,
				model: requestModel
			}
		};
	}
}

// TODO: consolidate.
export function configureValidation(form: HTMLFormElement) {
	for (let i = 0; i < form.elements.length; i++) {
		const element = form.elements.item(i);
		if (element instanceof HTMLInputElement || element instanceof HTMLTextAreaElement) {
			// whitespace-only data-entry is considered invalid.
			element.onchange = () =>
				element.setCustomValidity(/^\s+$/.test(element.value) ? loc_pleaseFillOut : '');
		}

		if (element instanceof HTMLButtonElement && element.type === 'submit') {
			// don't show :invalid until user attempts to submit form.
			element.onclick = () => form.classList.add('show-validation-status');
		}
	}
}

import { UISearchArgs, SearchVMConfig } from '../view-model';
import { FacetSearchResponse } from '../model';
import { authStatusDetermined } from '../../../auth';
import { defaultFacetSearchFilterOptions } from '../filter-options';
import { msDocs } from '../../../globals';
import { getBranch } from '../../../apis/learn';
import { fetchWithTimeout, createRequest } from '../../../fetch';
import { toQueryString, InputArgs } from '../../../query-string';
import { apis } from '../../../environment/apis';
import { html } from '../../../lit-html';
import { loc_startDate, loc_endDate } from '@msdocs/strings';
import { toLocaleDate } from '../../../text-formatting';

export function createLifecycleFacetSearchConfig(): SearchVMConfig<LifecycleBrowseResult> {
	return {
		layout: 'list',
		fetch: fetchCourseResults,
		resultTemplate: createLifecycleFacetSearchCard
	};
}

const lifecycleOrderDefaults = {
	orderBy: 'last_modified desc',
	top: 30
};

export interface LifecycleBrowseResult {
	summary: string;
	title: string;
	last_modified: string;
	locale: string;
	start: string;
	end: string;
	products: string[];
	url: string;
}

interface LifecycleArgs {
	branch: string;
	locale: string;
	terms: string;
	facet: string[];
	$filter: string;
	$orderBy: string;
	$skip: string;
	$top: string;
}

async function fetchCourseResults(
	args: UISearchArgs
): Promise<FacetSearchResponse<LifecycleBrowseResult>> {
	const [results] = await Promise.all([getLifecycleResults(args), authStatusDetermined]);
	return results;
}

function uiArgsToLifecycle(args: UISearchArgs): LifecycleArgs {
	return {
		branch: getBranch(),
		locale: msDocs.data.userLocale,
		terms: args.terms,
		facet: ['products'],
		$filter: defaultFacetSearchFilterOptions(args.selectedFacets),
		// always null if terms are present
		$orderBy: args.terms && args.terms.length > 0 ? null : lifecycleOrderDefaults.orderBy,
		$skip: args.skip ? args.skip.toString() : null,
		$top: lifecycleOrderDefaults.top.toString()
	};
}

function getLifecycleResults(
	args: UISearchArgs
): Promise<FacetSearchResponse<LifecycleBrowseResult>> {
	const query = (uiArgsToLifecycle(args) as unknown) as InputArgs;
	const url = `${apis.browse.lifecycle}?${toQueryString(query, true)}`;

	const init = { method: 'GET' };

	return fetchWithTimeout(createRequest(url, init)).then(response => {
		if (response.ok) {
			return response.json();
		}
		/* eslint-disable-next-line */
		throw { error: response.json() };
	});
}

/**
 * Function that renders the cards for the Lifecycle browse page, only applies to browseType: 'lifecycle'.
 * @param items The resultant items from the Lifecycle browse service API call.
 */
function createLifecycleFacetSearchCard(item: LifecycleBrowseResult) {
	const startDate = formatDate(item.start);
	const endDate = item.end && item.end !== '0001-01-01T00:00:00' ? formatDate(item.end) : '';

	return html`
		<article class="card" data-bi-name="card">
			<div class="card-content">
				<a href="${item.url}" class="card-content-title">
					<h3>${item.title}</h3>
				</a>
				<ul class="card-content-metadata">
					<li>${loc_startDate}: ${startDate}</li>
					<li>${loc_endDate}: ${endDate}</li>
				</ul>
			</div>
		</article>
	`;
}

function formatDate(date: string): string {
	const formatOptions: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		timeZone: 'UTC'
	};

	return toLocaleDate(new Date(date), formatOptions);
}

import { ProfileRequest, putProfile, UpdateProfileResult } from '../apis/profile';
import { user } from '../auth/user';
import { traits } from '../environment/traits';
import { apis } from '../environment/apis';
import { fetchWithTimeout, createRequest } from '../fetch';
import { msDocs } from '../globals';
import { getBranch } from '../header/uhf-service';
import { FacetKeys, InterestsFacetKey, Interests } from './model';
import { toQueryString } from '../query-string';
import { transientLocalStorage } from '../transient-storage';

// Storage Key to access interests data from local browser storage.
export const interestKey: string = 'docs.userInterests';
export const localStoreExpiryDays = 30;

export function isUserOnboarded(): boolean {
	const interestData = getInterests();
	return interestData && hasAllInterestsDefined(interestData);
}

export function isUserOnboardedLocally(): boolean {
	const interest = getInterestsFromLocalStorage();
	return interest && hasAllInterestsDefined(interest);
}

export function hasAtLeastOneChecked(checkboxes: HTMLInputElement[]) {
	if (!checkboxes || !checkboxes.length) {
		return false;
	}

	let hasAtLeastOnceChecked = false;
	for (const checkbox of checkboxes) {
		if (checkbox.checked) {
			hasAtLeastOnceChecked = true;
			break;
		}
	}

	return hasAtLeastOnceChecked;
}

export function hasAllInterestsDefined(interests: Interests): boolean {
	if (interests) {
		for (const facet of FacetKeys) {
			const facetKey = facet as InterestsFacetKey;
			if (!interests[facetKey]) {
				return false;
			}
		}

		return true;
	}

	return false;
}

export function hasAtLeastOneInterestDefined(interests: Interests): boolean {
	if (interests) {
		for (const facet in interests) {
			const facetKey = facet as InterestsFacetKey;
			if (interests[facetKey] && interests[facetKey].length >= 0) {
				// redefining this check for when a user decides to clear interests
				// we still want the user to be signalled as onboarded
				return true;
			}
		}
	}

	return false;
}

export function loadSelectionsFromCache(container: HTMLElement, interestsCache: string[]) {
	if (!interestsCache) {
		return;
	}

	const checkboxes = Array.from(
		container.querySelectorAll('input[type="checkbox"]')
	) as HTMLInputElement[];

	checkboxes.forEach(checkbox => {
		checkbox.checked = interestsCache.find(x => x === checkbox.name) !== undefined;
	});
}

export function updateCurrentSelectionCache(container: Element, interestsCache: string[]) {
	if (!interestsCache) {
		return interestsCache;
	}

	const checkboxes = Array.from(
		container.querySelectorAll('input[type="checkbox"]')
	) as HTMLInputElement[];

	interestsCache = updateInterestListFromCheckboxes(checkboxes, interestsCache);
	return interestsCache;
}

export function updateInterestListFromCheckboxes(
	checkboxes: HTMLInputElement[],
	interests: string[]
) {
	checkboxes.forEach(checkbox => {
		interests = updateInterestListFromCheckbox(checkbox, interests);
	});

	return interests;
}

function updateInterestListFromCheckbox(checkbox: HTMLInputElement, interests: string[]) {
	if (checkbox.checked) {
		if (!interests.find(x => x === checkbox.name)) {
			interests.push(checkbox.name);
		}

		return interests;
	}

	return interests.filter(x => x !== checkbox.name);
}

/**
 * Updating all checkboxes within the specified facet
 * to match the user's facet selection.
 * @param container container with grid of checkboxes
 * @param facetKey the interests facet key
 */
export function loadInterestSelections(container: Element, facetKey: InterestsFacetKey) {
	const interests = getInterests();
	if (!interests || !interests[facetKey]) {
		return;
	}

	const checkboxes = Array.from(
		container.querySelectorAll('input[type="checkbox"]')
	) as HTMLInputElement[];

	checkboxes.forEach(checkbox => {
		checkbox.checked = interests[facetKey].find(x => x === checkbox.name) !== undefined;
	});
}

export function saveInterestSelectionsToClient(
	checkboxes: HTMLInputElement[],
	facetKey: InterestsFacetKey,
	interestsCache: Interests = null
) {
	if (!checkboxes || !checkboxes.length) {
		return false;
	}

	const interests = interestsCache || getInterestsFromLocalStorage() || ({} as Interests);
	interests[facetKey] = updateInterestListFromCheckboxes(checkboxes, interests[facetKey] || []);
	const expiry = getExpiryDate();
	transientLocalStorage.setItem(interestKey, JSON.stringify(interests), expiry);
	return true;
}

export function saveInterestsToClient(interests: Interests) {
	if (!interests) {
		return;
	}

	const expiry = getExpiryDate();
	transientLocalStorage.setItem(interestKey, JSON.stringify(interests), expiry);
}

/**
 * Gets the interest from local storage or from the current user or default empty arrays.
 * @returns interest data.
 */
export function getInterests(): Interests {
	return getInterestsFromLocalStorage() || user.interests;
}

export async function saveUserInterestsFromClient(source: string) {
	if (!user.isAuthenticated) {
		return;
	}

	const interests = getInterestsFromLocalStorage();
	if (!hasAllInterestsDefined(interests)) {
		return;
	}
	await saveUserInterests(interests, source);
}

/**
 * Save the interests to profile; if succeeds, remove the entry from storage.
 * @param interests a non-null interests object
 * @param source the profileContext source
 * @returns hasError false, and null profile if unauthenticated; else result of the profile api.
 */
async function saveUserInterests(
	interests: Interests,
	source: string
): Promise<UpdateProfileResult> {
	const updatedProfileResult = await saveUserInterestsToProfile(interests, source);
	if (!updatedProfileResult.hasError && updatedProfileResult.profile) {
		user.readUserProfile(updatedProfileResult.profile);
		transientLocalStorage.removeItem(interestKey);
	}

	return updatedProfileResult;
}

export function getInterestsFromLocalStorage(): Interests {
	let results = null;
	const interestData = transientLocalStorage.getItem(interestKey);
	try {
		if (interestData) {
			results = JSON.parse(interestData) as Interests;
		}
	} catch (e) {
		// If there is a parsing error, then we assume user has no interest data.
		results = null;
	}
	return results;
}

export async function filterProductsForContentExist(productSlugs: string[]) {
	const args = {
		branch: getBranch(),
		locale: msDocs.data.userLocale,
		facet: 'products',
		$orderBy: 'popularity desc,last_modified desc,title',
		$top: 1,
		hideCompleted: true,
		showHidden: traits.reviewFeatures.toString()
	};
	const url = `${apis.browse.learn}?${toQueryString(args, true)}`;
	const init = { method: 'GET' };

	let result: any;
	try {
		const response = await fetchWithTimeout(createRequest(url, init));
		if (response.ok) {
			result = await response.json();
		}
	} catch {} // graceful for now

	if (!result || !result.facets || !result.facets.products) {
		return productSlugs;
	}
	const products = result.facets.products as Record<string, any>[];
	return productSlugs.filter(
		slug => products.find(product => product.value === slug) !== undefined
	);
}

function saveUserInterestsToProfile(interests: Interests, source: string) {
	if (!interests) {
		return { hasError: true, profile: null } as UpdateProfileResult;
	}

	const updatedProfile: ProfileRequest = {
		userName: user.userName,
		locale: user.locale,
		email: user.email,
		displayName: user.displayName,
		country: user.country,
		contactPointTopicSetting: user.contactPointTopicSetting,
		isPrivate: user.isPrivate,
		avatarImageBytes: null,
		avatarExtension: null,
		source,
		interests,
		gsi: user.gsi
	};

	return putProfile(user.userId, updatedProfile);
}

function getExpiryDate() {
	const expiry = new Date(Date.now());
	expiry.setDate(expiry.getDate() + localStoreExpiryDays);
	return expiry;
}

import {
	loc_backToPriorMenu,
	loc_bookmarks,
	loc_close,
	loc_collections,
	loc_global,
	loc_home,
	loc_profile,
	loc_signIn,
	loc_signOut,
	loc_welcomeToMicrosoftDocs
} from '@msdocs/strings';
import { user } from '../../../auth/user';
import { features } from '../../../environment/features';
import { msDocs } from '../../../globals';
import { CallToAction } from '../../../header/model';
import { siteContext, siteNavMenuTitle } from '../../../header/site-context';
import { isPPE, isReview } from '../../../is-production';
import { html, TemplateResult, unsafeHTML } from '../../../lit-html';
import { NavItem, NavLink, NavMenu } from '../model';
import { siteSearchTemplate } from '../site-search-view';
import { NavBarViewModel } from '../view-model';

const hasUserProfileTemplate =
	features.userServices &&
	siteContext !== 'Advocates' &&
	siteContext !== 'Developer' &&
	siteContext !== 'Regional Directors' &&
	siteContext !== 'Style-Guide';

function mobileNavItemTemplate(
	item: NavItem,
	menuHandler: (menu: NavMenu) => void,
	isGlobalNav: boolean
): TemplateResult {
	let globalNavLinkClasses: string[];
	if (isGlobalNav) {
		globalNavLinkClasses = [];
		globalNavLinkClasses.push('has-text-weight-semibold', 'is-size-5');
	}

	return item.kind === 'link'
		? mobileNavLinkTemplate(item, false, globalNavLinkClasses)
		: mobileNavMenuTemplate(item, menuHandler, globalNavLinkClasses);
}

function mobileNavLinkTemplate(
	{ href, title }: NavLink,
	isCallToAction: boolean = false,
	globalNavLinkClasses: string[] = []
): TemplateResult {
	const ctaButtonClasses = isCallToAction ? 'has-text-primary' : '';

	return html` <li class="nav-bar-mobile-nav-item ${globalNavLinkClasses.join(' ')}">
		<a class="nav-bar-button has-text-wrap ${ctaButtonClasses}" href="${href}" autofocus
			>${title}</a
		>
	</li>`;
}

function mobileNavMenuTemplate(
	menu: NavMenu,
	clickHandler: (menu: NavMenu) => void,
	globalNavLinkClasses: string[] = []
): TemplateResult {
	return html`
		<li class="nav-bar-mobile-nav-item">
			<button
				type="button"
				class="nav-bar-button has-text-wrap"
				@click=${() => clickHandler(menu)}
				autofocus
			>
				<span class="is-text-left ${globalNavLinkClasses.join(' ')}">${menu.title}</span>
				<span class="nav-bar-button-chevron" aria-hidden="true">
					<span
						class="docon docon-chevron-${msDocs.data.userDir === 'ltr' ? 'right' : 'left'}-light"
					></span>
				</span>
			</button>
		</li>
	`;
}

export function modalHeaderTemplate(viewModel: NavBarViewModel): TemplateResult {
	const template = html`
		<div
			id="modal-header"
			class="is-flex is-size-large has-border-bottom has-flex-justify-content-space-between"
		>
			<div class="nav-bar-item">
				<button
					type="button"
					class="nav-bar-button has-padding-medium has-flex-align-items-center"
					?hidden=${viewModel.searchExpanded}
					autofocus
				>
					<span class="icon is-small">
						<span class="docon docon-waffle-office365" aria-hidden="true"></span>
					</span>
					<h4 class="title has-padding-left-extra-small has-padding-bottom-extra-small">Docs</h4>
				</button>
			</div>
			${siteSearchTemplate(viewModel)}
			<div class="nav-bar-item">
				<button
					type="button"
					class="nav-bar-button modal-close has-border-left"
					?hidden=${viewModel.searchExpanded}
				>
					<span class="visually-hidden">${loc_close}</span>
					<span class="icon is-small">
						<span class="docon docon-math-multiply" aria-hidden="true"></span>
					</span>
				</button>
			</div>
		</div>
	`;

	return template;
}

export function modalBodyTemplate(
	navItems: NavItem[],
	menuHandler: (menu: NavMenu) => void,
	isSubMenu: boolean,
	title?: string | TemplateResult,
	category?: NavLink,
	cta?: CallToAction
): TemplateResult {
	const bookmarksLink = user.isAuthenticated
		? user.profileUrl + (isPPE || isReview ? '&section=bookmarks' : 'bookmarks')
		: '#';
	const collectionsLink = user.isAuthenticated
		? user.profileUrl + (isPPE || isReview ? '&section=collections' : 'collections')
		: '#';

	const profileTemplate = user.isAuthenticated
		? html`
				<li class="has-margin-top-large"></li>
				${mobileNavLinkTemplate({ href: user.profileUrl, title: loc_profile } as NavLink)}
				${mobileNavLinkTemplate({ href: bookmarksLink, title: loc_bookmarks } as NavLink)}
				${mobileNavLinkTemplate({ href: collectionsLink, title: loc_collections } as NavLink)}
		  `
		: null;

	const template = html`
		${isIntermediateView(title, isSubMenu)
			? html`
					<div class="has-border-bottom">
						<button
							type="button"
							class="nav-bar-button pop-content is-text has-flex-justify-content-start has-padding-medium is-full-width"
							title=${loc_backToPriorMenu}
							autofocus
						>
							<span class="icon" aria-hidden="true">
								<span
									class="docon docon-arrow-${msDocs.data.userDir === 'ltr' ? 'left' : 'right'}"
								></span>
							</span>
							<h3 class="is-size-small">${siteNavMenuTitle}</h3>
							<span class="is-visually-hidden">${loc_backToPriorMenu}</span>
						</button>
					</div>
			  `
			: null}
		${title
			? html`<div class="is-size-5">
					<h2 id="modal-heading" class="has-padding-medium has-text-weight-semibold">${title}</h2>
			  </div>`
			: null}
		<nav class="nav-bar-mobile-nav" role="navigation" aria-label="${loc_global}">
			<ul class="nav-bar-mobile-nav-list">
				${category
					? mobileNavLinkTemplate({ href: category.href, title: loc_home } as NavLink)
					: null}
				${navItems && navItems.length
					? navItems.map(i => mobileNavItemTemplate(i, menuHandler, !isSubMenu))
					: null}
				${cta
					? html`
							${cta.primary ? mobileNavLinkTemplate(cta.primary, true) : null}
							${cta.secondary ? mobileNavLinkTemplate(cta.secondary, true) : null}
					  `
					: null}
				${isIntermediateView(title, isSubMenu) || !hasUserProfileTemplate ? null : profileTemplate}
			</ul>
		</nav>
	`;

	return template;
}

export function modalFooterTemplate(): TemplateResult {
	let href = '#';
	let authClasses = user.isAuthenticated
		? 'is-size-extra-small has-padding-small'
		: 'auth-status-determined not-authenticated button is-extra-small has-outer-focus is-primary';
	if (siteContext === 'Q&A') {
		href = `/${msDocs.data.userLocale}/answers/users/${
			user.isAuthenticated ? 'logout' : 'login'
		}.html`;
	} else {
		authClasses = `docs-sign-${user.isAuthenticated ? 'out' : 'in'} ` + authClasses;
	}

	const welcomeTemplate = html`<span class="has-flex-grow has-flex-shrink is-size-extra-small"
		>${unsafeHTML(loc_welcomeToMicrosoftDocs)}</span
	>`;
	const profileTemplate = html` ${user.isAuthenticated ? userTemplate() : welcomeTemplate}
		<div>
			<a class="${authClasses}" href="${href}"
				>${user.isAuthenticated ? loc_signOut : loc_signIn}</a
			>
		</div>`;

	const template = html`
		<div class="has-border-top is-flex has-flex-align-items-center has-padding-small">
			${hasUserProfileTemplate ? profileTemplate : welcomeTemplate}
		</div>
	`;

	return template;
}

function userTemplate() {
	return html`
		<div class="has-padding-left-small">
			<figure class="image is-24x24" aria-hidden="true">
				<img
					class="has-body-background-medium is-rounded"
					aria-labelledby="display-name-mobile"
					data-profile-property="avatarUrl"
					src=${user.avatarUrl}
				/>
			</figure>
		</div>
		<p
			class="title has-flex-grow has-flex-shrink is-size-small has-margin-none has-padding-left-small"
		>
			<span
				id="display-name-mobile"
				class="is-inline-truncated has-max-width-200"
				data-profile-property="displayName"
				>${user.displayName}</span
			>
		</p>
	`;
}

function isIntermediateView(title: string | TemplateResult, isSubMenu: boolean) {
	return isSubMenu || (siteContext !== 'Docs_Home' && siteNavMenuTitle && title);
}

import {
	loc_assessment_someRequiredQuestionsAreIncomplete,
	loc_assessment_youMustCompleteAllRequiredQuestions,
	loc_assessmentProgressLabelFormat,
	loc_back,
	loc_beforeStartedSignInToSaveProgress,
	loc_chooseYourInterests,
	loc_next,
	loc_signInToGainFullAccess,
	loc_submit
} from '@msdocs/strings';
import { assessmentApi } from '../../apis/assessments';
import { user } from '../../auth/user';
import { isReview } from '../../is-production';
import { html, TemplateResult, unsafeHTML, classMap } from '../../lit-html';
import { forceWrite } from '../../plugins/lit-html/force-write';
import { textInputTemplate } from '../../templates/input-template';
import { createVideoTemplate } from '../../video-adjustments';
import {
	categoriesToCheckboxGroup,
	createCheckboxGroupTemplate
} from '../assessment-templates/checkbox-template';
import {
	imageRadioInputTemplate,
	ImageRadiosTemplateData
} from '../assessment-templates/image-radio-template';
import { currentAssessmentNavigation } from '../navigation';
import { getAssessmentTextInputTemplateData } from '../template-data';
import {
	AssessmentBusyIndication,
	AssessmentSession,
	MatrixQuestion,
	Question,
	SessionInputKeyValue
} from '../types';
import { AssessmentViewModel } from '../view-model';
import { ifDefined } from '.,/../lit-html/directives/if-defined';
import { createQuestionHeaderTemplate } from '../assessment-templates/shared-template';
import { features } from '../../environment/features';
import { createSideNav } from './side-nav-mode';

function signInBannerTemplate(viewModel: AssessmentViewModel): TemplateResult {
	if (user.isAuthenticated) {
		return html``;
	}

	let bannerText = null;

	if (viewModel.isPreAssessment) {
		// Not signed in and on pre-assessment
		bannerText = loc_beforeStartedSignInToSaveProgress;
	} else if (viewModel.onLastQuestion) {
		// Not signed in and on Questionnaire last question
		bannerText = loc_signInToGainFullAccess;
	} else {
		return html``;
	}

	return html`
		<div class="is-banner alert is-radiusless is-primary has-margin-bottom-medium">
			<p class="disclaimer-title">
				<span class="icon">
					<span class="docon docon-status-error-outline" aria-hidden="true"></span>
				</span>
				${unsafeHTML(bannerText)}
			</p>
		</div>
	`;
}

/**
 * @param viewModel the current viewmodel for the questionnaire
 * @returns Template for "you have unanswered, required questions" assessment error.
 */
function unansweredRequiredQuestionsTemplate(viewModel: AssessmentViewModel): TemplateResult | '' {
	if (!features.assessmentSideNav || !viewModel.onLastQuestion) {
		return '';
	}

	const classes = {
		'is-hidden': 0 === viewModel.countRequiredQuestionsNotAnswered
	};

	return html` <div class="alert is-danger ${classMap(classes)}">
		<p class="alert-title">
			<span class="icon is-left" aria-hidden="true">
				<span class="icon docon docon-status-failure-outline" role="presentation"></span>
			</span>
			${loc_assessment_someRequiredQuestionsAreIncomplete}
		</p>
		<p>
			${loc_assessment_youMustCompleteAllRequiredQuestions}
			<span class="icon" aria-hidden="true">
				<span class="icon docon docon-asterisk-solid has-text-danger" role="presentation"></span>
			</span>
		</p>
	</div>`;
}

const isLoading = (
	viewModel: AssessmentViewModel,
	viewAction: AssessmentBusyIndication['action']
) => {
	const { busy, action } = viewModel.busy;
	return busy && action === viewAction;
};

const createProgressTemplate = (viewModel: AssessmentViewModel) => {
	if (features.assessmentSideNav) {
		return '';
	}

	const progressLabel: string = loc_assessmentProgressLabelFormat
		.replace('{question}', viewModel.questionNumber.toString())
		.replace('{questionsTotal}', viewModel.questionTotal.toString());
	return html` <div class="has-margin-bottom-large">
		<progress
			class="progress is-primary is-extra-small is-full-width is-inline-block has-margin-bottom-none"
			max="100"
			aria-describedby="progress-description"
			value="${viewModel.questionOfTotalPercentage}"
		></progress>
		<div id="progress-description" class="is-size-extra-small has-padding-none">
			${progressLabel}
		</div>
	</div>`;
};

const categoriesTemplate = (viewModel: AssessmentViewModel) => {
	// To do: determine if we're revisiting the category / save answers locally
	return categoriesToCheckboxGroup(
		viewModel.assessment.categories,
		viewModel.session,
		viewModel.isValidPreAssessment
	);
};

/**
 * @param question the question to generate the video for.
 * @returns Returns the video player HTML elements if the question has a video, else empty.
 */
const videoTemplate = (question: Question | MatrixQuestion): TemplateResult | '' => {
	return question.video?.url && question.video?.title
		? html`<div class="is-full-width has-margin-bottom-large">
				${createVideoTemplate(question.video?.url, question.video?.title)}
		  </div>`
		: '';
};

const questionContainerTemplate = (viewModel: AssessmentViewModel) => {
	const handleInputChange = ({ target }: Event) => {
		if (target instanceof HTMLInputElement) {
			const { name, value } = target;
			viewModel.saveResponseToViewModel(name, value);
			viewModel.saveLocalSession();
			nonBlockingSessionSave(viewModel.session);
			return;
		}
		throw new Error('unexpected change target');
	};

	const { busy: actionsEnabled } = viewModel.busy;
	const nextLoadingClass = isLoading(viewModel, 'next') ? 'is-loading' : '';
	const backLoadingClass = isLoading(viewModel, 'back') ? 'is-loading' : '';
	const titleSizeClass = features.assessmentSideNav
		? 'is-7 has-text-weight-normal has-text-subtle'
		: '';

	return html` <h1 id="current-category-title" class="title has-margin-none ${titleSizeClass}">
			${viewModel.currentCategory.title}
		</h1>
		${createProgressTemplate(viewModel)}
		<form
			@submit=${() => viewModel.next()}
			action="javascript:"
			aria-labelledby="current-category-title"
			class="is-flex has-flex-wrap has-flex-grow has-margin-top-small"
		>
			${videoTemplate(viewModel.currentQuestion)}
			<fieldset aria-live="polite" @change=${handleInputChange} class="field is-full-width">
				${createQuestionTemplate(viewModel)}
			</fieldset>
			<div
				class="buttons has-flex-align-self-end has-flex-grow is-full-width is-flex-direction-reverse has-flex-justify-content-space-between"
			>
				<button
					type="submit"
					class="button is-primary is-fullwidth-mobile ${nextLoadingClass}"
					data-bi-name="${viewModel.assessment.id}-submit"
					?disabled=${features.assessmentSideNav
						? !viewModel.canClickSubmit
						: !viewModel.canClickNext}
					?hidden=${!viewModel.onLastQuestion}
				>
					<span>${loc_submit}</span>
					<span class="icon" aria-hidden="true">
						<span class="docon docon-arrow-right"> </span>
					</span>
				</button>

				<button
					type="submit"
					class="button is-primary is-fullwidth-mobile ${nextLoadingClass}"
					data-bi-name="${viewModel.assessment.id}-next"
					?disabled=${!viewModel.canClickNext}
					?hidden=${viewModel.onLastQuestion}
				>
					<span>${loc_next}</span>
					<span class="icon" aria-hidden="true">
						<span class="docon docon-arrow-right"> </span>
					</span>
				</button>

				<button
					type="button"
					class="button is-fullwidth-mobile ${backLoadingClass}"
					data-bi-name="back"
					?disabled=${actionsEnabled}
					?hidden=${!viewModel.onFirstQuestion}
					@click=${() => viewModel.backToPreAssessment()}
				>
					<span class="icon" aria-hidden="true">
						<span class="docon docon-arrow-left"> </span>
					</span>
					<span>${loc_back}</span>
				</button>

				<button
					type="button"
					class="button is-fullwidth-mobile ${backLoadingClass}"
					data-bi-name="back"
					?disabled=${actionsEnabled}
					?hidden=${viewModel.onFirstQuestion}
					@click=${() => viewModel.previous()}
				>
					<span class="icon" aria-hidden="true">
						<span class="docon docon-arrow-left"> </span>
					</span>
					<span>${loc_back}</span>
				</button>
			</div>
			${unansweredRequiredQuestionsTemplate(viewModel)}
		</form>`;
};

const preAssessmentContainerTemplate = (viewModel: AssessmentViewModel) => {
	const handleInputChange = ({ target }: Event) => {
		if (target instanceof HTMLInputElement || target instanceof HTMLSelectElement) {
			const { name, value } = target;
			viewModel.saveSessionDetail({ [name]: value } as SessionInputKeyValue);
			viewModel.saveLocalSession();
			nonBlockingSessionSave(viewModel.session);
			viewModel.notifyPropertyChanged();
			return;
		}
		throw new Error('unexpected change target');
	};

	const savePreAssessmentAndBegin = () => {
		viewModel.continueFromPreAssessment();
	};
	const { sessionName } = getAssessmentTextInputTemplateData(viewModel);

	const hasOptionalCategories =
		viewModel.assessment.categories.find(category => category.isRequired !== true) !== undefined;
	const chooseInterestsHtml = !hasOptionalCategories
		? html``
		: html`<h2 class="title is-3 has-margin-top-extra-large">${loc_chooseYourInterests}</h2>
				<div class="field">
					${categoriesTemplate(viewModel)}
				</div>`;
	const branchTag =
		isReview && viewModel.session.branch
			? html`<span class="has-margin-left-small tag is-small is-warning"
					>${viewModel.session.branch}</span
			  >`
			: html``;

	return html`
		<form
			@submit=${savePreAssessmentAndBegin}
			action="javascript:"
			aria-labelledby="current-category-title"
		>
			<h1 class="title is-2">${viewModel.assessment.title}${branchTag}</h1>
			<p class="has-margin-bottom-large">${viewModel.assessment.description}</p>
			<fieldset @change=${handleInputChange} class="field">
				<div class="columns">
					<div class="column">
						<div class="has-margin-bottom-medium">
							${textInputTemplate(sessionName)}
						</div>
						${chooseInterestsHtml}
					</div>
				</div>
			</fieldset>
			<div class="buttons has-margin-top-large has-flex-justify-content-end">
				<button
					type="submit"
					class="button is-primary is-fullwidth-mobile ${isLoading(viewModel, 'next')
						? 'is-loading'
						: ''}"
					data-bi-name="${viewModel.assessment.id}-next"
					?disabled=${viewModel.busy.busy}
				>
					<span>${loc_next}</span>
					<span class="icon" aria-hidden="true">
						<span class="docon docon-arrow-right"> </span>
					</span>
				</button>
			</div>
		</form>
	`;
};

function nonBlockingSessionSave(session: Partial<AssessmentSession>) {
	if (user.isAuthenticated) {
		// when authed issue a non-blocking save in the case of change to the view model
		assessmentApi.saveSession(session);
	}
}

// Questions
const createRadioQuestionTemplate = (viewModel: AssessmentViewModel) => {
	const question = viewModel.currentQuestion as Question;
	const {
		currentQuestion: { id: questionId },
		currentCategory: { id: categoryId }
	} = viewModel;
	const isRequired = question.isRequired && !features.assessmentSideNav;

	const radiosTemplateResult = question.choices.map(choice => {
		return html`
			<label
				class="radio"
				title="${ifDefined(choice.description ?? undefined)}"
				aria-describedby="${ifDefined(
					choice.description ? `assessment-choice-${question.id}-${choice.id}-tooltip` : undefined
				)}"
			>
				<input
					id="assessment-choice-${question.id}-${choice.id}"
					type="radio"
					name="${question.id}"
					value="${choice.id}"
					.checked=${forceWrite(viewModel.isSelectedResponse(categoryId, questionId, choice.id))}
					?required="${isRequired}"
				/>
				<span class="radio-dot" aria-hidden="true"></span>
				<span class="radio-label-text">${choice.text}</span>
				${getInvisibleSpanForAriaDescribedBy(
					`assessment-choice-${question.id}-${choice.id}-tooltip`,
					choice.description
				)}
			</label>
		`;
	});

	return html`
		${createQuestionHeaderTemplate(question.title, question.description)}
		<div class="control has-margin-bottom-extra-large has-margin-top-extra-large">
			<div class="radios is-vertical">
				${radiosTemplateResult}
			</div>
		</div>
	`;
};

const createImageRadioQuestionTemplate = (viewModel: AssessmentViewModel) => {
	const { currentQuestion } = viewModel;
	const isRequired = currentQuestion.isRequired && !features.assessmentSideNav;
	const config: ImageRadiosTemplateData = {
		choices: currentQuestion.choices,
		isRequired,
		externalLabel: false,
		label: currentQuestion.description,
		name: currentQuestion.id
	};

	return html` ${createQuestionHeaderTemplate(currentQuestion.title, currentQuestion.description)}
		<div class="control has-margin-bottom-extra-large has-margin-top-extra-large">
			${imageRadioInputTemplate(config, viewModel)}
		</div>`;
};

function createQuestionTemplate(viewModel: AssessmentViewModel): TemplateResult {
	const { currentQuestion } = viewModel;

	switch (currentQuestion.type) {
		case 'singleSelect':
			return createRadioQuestionTemplate(viewModel);
		case 'multiSelect':
			return createCheckboxGroupTemplate(viewModel);
		case 'matrix':
			return createMatrixQuestionTemplate(viewModel);
		case 'singleSelectImage':
			return createImageRadioQuestionTemplate(viewModel);
		default:
			throw new Error(`Cannot render unsupported question type.`);
	}
}

function getInvisibleSpanForAriaDescribedBy(
	id: string,
	tooltipText?: string
): null | TemplateResult {
	return tooltipText
		? html`<span class="is-visually-hidden" id="${id}" role="tooltip">${tooltipText}</span>`
		: null;
}

function createMatrixQuestionTemplate(viewModel: AssessmentViewModel) {
	const matrix = viewModel.currentQuestion as MatrixQuestion;
	const {
		currentQuestion: { id: questionId },
		currentCategory: { id: categoryId }
	} = viewModel;
	const isRequired = matrix.isRequired && !features.assessmentSideNav;
	const headingTemplate = html`
		<div aria-hidden="true" class="is-hidden-mobile columns has-margin-bottom-extra-small">
			<div class="column is-offset-4 is-8">
				<div class="columns is-gapless">
					${matrix.choices.map(choice => {
						return html`<div class="column">
							<div
								class="is-flex is-flex-column is-full-height has-flex-justify-content-end has-padding-right-small has-padding-left-small"
							>
								<p
									class="has-margin-none is-text-centered"
									title="${ifDefined(choice.description ?? undefined)}"
									aria-describedby="${ifDefined(
										choice.description ? `${choice.id}-tooltip` : undefined
									)}"
								>
									${choice.text}
								</p>
								${getInvisibleSpanForAriaDescribedBy(`${choice.id}-tooltip`, choice.description)}
							</div>
						</div>`;
					})}
				</div>
			</div>
		</div>
	`;

	const rowTemplate = html` ${matrix.rows.map(
		row => html`
			<fieldset
				class="has-background-alternating-grey has-body-background-mobile has-border has-margin-bottom-medium has-padding-medium has-margin-bottom-none-tablet has-padding-none-tablet"
				aria-label="${row.text}"
			>
				<div class="columns is-gapless">
					<div class="column is-4 has-border-right-tablet">
						<legend
							class="has-margin-bottom-small has-margin-bottom-none-tablet has-padding-medium-tablet"
						>
							${row.text}
						</legend>
					</div>
					<div class="column is-8">
						<div class="columns is-full-height is-gapless">
							${matrix.choices.map(choice => {
								return html`
									<div class="column is-full-height">
										<div
											class="is-flex-tablet is-full-height has-flex-justify-content-center has-flex-align-items-center has-padding-top-extra-small has-padding-top-none-tablet"
										>
											<label
												class="radio"
												title="${ifDefined(choice.description ?? undefined)}"
												aria-describedby="${ifDefined(
													choice.description
														? `assessment-choice-${row.id}-${choice.id}-tooltip`
														: undefined
												)}"
											>
												<input
													id="assessment-choice-${row.id}-${choice.id}"
													type="radio"
													name="${row.id}"
													value="${choice.id}"
													.checked=${forceWrite(
														viewModel.isSelectedResponse(categoryId, questionId, choice.id, row.id)
													)}
													?required="${isRequired}"
												/>
												<span class="radio-dot" aria-hidden="true"></span>
												<span class="radio-label-text is-visually-hidden-tablet"
													>${choice.text}</span
												>
												${getInvisibleSpanForAriaDescribedBy(
													`assessment-choice-${row.id}-${choice.id}-tooltip`,
													choice.description
												)}
											</label>
										</div>
									</div>
								`;
							})}
						</div>
					</div>
				</div>
			</fieldset>
		`
	)}`;

	return html`
		<div class="control has-margin-bottom-medium is-full-width">
			${headingTemplate} ${rowTemplate}
		</div>
	`;
}

export function assessmentTemplate(viewModel: AssessmentViewModel): TemplateResult {
	const signInPromptHtml = signInBannerTemplate(viewModel);

	let sideNavClassNames = '';
	if (features.assessmentSideNav) {
		sideNavClassNames = 'has-flex-grow has-margin-left-small has-margin-right-small is-full-width';
	}

	const content = html`
		<div
			class="modular-content-container has-margin-top-medium has-margin-bottom-medium is-flex is-flex-column ${sideNavClassNames}"
		>
			<div
				id="assessments-container"
				class="section is-uniform is-flex is-flex-column has-min-height-567-tablet box"
				data-bi-name="${currentAssessmentNavigation.mode}"
			>
				${signInPromptHtml}
				${viewModel.isPreAssessment
					? preAssessmentContainerTemplate(viewModel)
					: questionContainerTemplate(viewModel)}
			</div>
		</div>
	`;

	if (!features.assessmentSideNav) {
		return content;
	}

	const sideNav = createSideNav(viewModel);
	return html`
		<div
			class="assessments-with-side-nav container is-flex has-margin-top-small has-margin-bottom-small"
		>
			${sideNav} ${content}
		</div>
	`;
}

import { apis } from '../environment/apis';
import { fetchWithTimeout } from '../fetch';

export async function reportFeedback(formData: FormData): Promise<Response> {
	const requestOptions = {
		method: 'POST',
		body: formData
	};

	const request = new Request(apis.siteFeedback, requestOptions);
	const response = await fetchWithTimeout(request);

	if (!response.ok) {
		throw new Error(`Error fetching ${request.url}`);
	}

	return response;
}

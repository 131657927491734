import { apis } from '../environment/apis';
import { fetchWithTimeout } from '../fetch';
import { parseStringToHtmlDocument } from '../header/uhf-service';

export async function getCookieConsent(locale?: string) {
	let url = apis.privacy.cookieConsent;
	if (locale) {
		const search = new URLSearchParams();
		search.append('locale', locale);
		url += `?${search.toString()}`;
	}

	const response = await fetchWithTimeout(url, { mode: 'cors' });
	if (!response.ok) {
		throw new Error('Cannot fetch cookie consent');
	}

	const cookieConsent = (await response.json()) as CookieConsent;
	return {
		isConsentRequired: cookieConsent.isConsentRequired,
		scriptUrl: cookieConsent.scripts[0],
		cookieBanner: parseCookieBanner(cookieConsent.markup)
	};
}

export function parseCookieBanner(markup: string): HTMLElement {
	return parseStringToHtmlDocument(markup).getElementById('msccBanner');
}

interface CookieConsent {
	isConsentRequired: boolean;
	markup: string;
	scripts: string[];
	css: string[];
	message: CookieBannerMessage;
}

interface CookieBannerMessage {
	message: string;
	moreInfoAriaLabel: string;
	moreInfoText: string;
	moreInfoUrl: string;
	scripts: string[];
}

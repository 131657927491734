import { mobileQuery } from '../../src/match-media';
import { notifyContentUpdated } from '../affix';
import { features } from '../environment/features';
import { eventBus } from '../event-bus';

export class APExpandedChangedEvent {
	constructor(public expanded: boolean) {}
}

export const apClasses = {
	expanded: 'ap-expanded',
	collapsed: 'ap-collapsed',
	expandButton: 'ap-expand-behavior',
	collapseButton: 'ap-collapse-behavior'
};

let lastFocusedElement: HTMLElement;

export function getActionPanel(expandMode: 'none' | 'snap' | 'animate', caller?: HTMLElement) {
	lastFocusedElement = caller;
	const html = document.documentElement.classList;
	const actionPanel = document.querySelector('.action-panel') as HTMLDivElement;

	if (html.contains('ap-layout') || expandMode === 'none') {
		return actionPanel;
	}

	const mainColumn = document.getElementById('main-column') as HTMLDivElement;
	const restoreScroll = snapshotScroll();

	html.add('ap-layout');
	if (expandMode === 'animate') {
		html.add('ap-layout-animates');
	}

	setTimeout(() => html.add('ap-layout-in'), 20);

	const finish = () => {
		actionPanel.removeEventListener('transitionend', finish);
		html.remove('ap-layout-animates');
		html.add('ap-layout-finished');
		positionActionPanel(actionPanel);
		notifyContentUpdated();
		restoreScroll(mainColumn);
		actionPanel.setAttribute('aria-expanded', 'true');
		actionPanel.focus();
		eventBus.publish(new APExpandedChangedEvent(true));
	};
	actionPanel.addEventListener('transitionend', finish);
	return actionPanel;
}

function positionActionPanel(actionPanel: HTMLElement) {
	const contentHeaderHeight = (() => {
		const header = document.querySelector('.ap-layout-finished .content-header');
		const styles = window.getComputedStyle(header);
		return styles.getPropertyValue('height');
	})();

	const main: HTMLElement = document.querySelector('.ap-layout-finished #main-column');

	actionPanel.style.top = contentHeaderHeight;
	main.style.top = contentHeaderHeight;
}

export function collapseActionPanel() {
	const html = document.documentElement.classList;
	const restoreScroll = snapshotScroll();
	const actionPanel = document.querySelector('.action-panel') as HTMLDivElement;

	html.remove('ap-layout');
	html.remove('ap-layout-animates');
	html.remove('ap-layout-in');
	html.remove('ap-layout-finished');
	notifyContentUpdated();
	restoreScroll(document.documentElement);
	if (lastFocusedElement) {
		lastFocusedElement.focus();
	}
	actionPanel.setAttribute('aria-expanded', 'false');
	eventBus.publish(new APExpandedChangedEvent(false));
}

/**
 * Returns a function that can restore the scroll position on another scrollable container.
 */
function snapshotScroll() {
	// loop through the content's immediate children..
	const main = document.querySelector('main');

	if (!main || !main.children) {
		return () => {};
	}

	const children = main.children;

	for (let i = children.length - 1; i >= 0; i--) {
		const element = children.item(i);
		if (element.hasAttribute('hidden')) {
			continue;
		}
		const { top } = element.getBoundingClientRect();
		// find the element that is at the top of the viewport.
		if (top <= 5 || i === 0) {
			const width = element.clientWidth;
			// return a function that can restore the top element's scroll position.
			return (scrollableParent: Element) => {
				// scroll the element to the top.
				element.scrollIntoView(true);
				// restore the element's offset from the top, try to account for the change in width.
				scrollableParent.scrollTop -= (top * width) / element.clientWidth;
			};
		}
	}
	return () => {}; // no-op;
}

export function initActionPanel() {
	addEventListener('click', event => {
		if (!(event.target instanceof Element)) {
			return;
		}
		const element = event.target.closest(
			`.${apClasses.expandButton}, .${apClasses.collapseButton}`
		) as HTMLElement;
		if (!element) {
			return;
		}

		if (element.classList.contains(apClasses.expandButton)) {
			getActionPanel('animate', element);
		} else {
			collapseActionPanel();
		}
	});
	mobileQuery.addListener(collapseActionPanel);
}

export function scrollContentToTop() {
	window.scrollTo(0, 0);
	if (!features.chromeless) {
		document.getElementById('main-column').scrollTop = 0;
	}
}

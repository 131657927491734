export function verbatimForm(container: HTMLElement, verbatimActivator: HTMLElement[]) {
	const ratingArea = container.querySelector('.binary-rating');
	const ratingForm = container.querySelector('.feedback-verbatim-form') as HTMLFormElement;
	const ratingTextarea = ratingForm.querySelector('textarea') as HTMLTextAreaElement;
	const skipRatingButton = container.querySelector('.skip-rating') as HTMLButtonElement;
	const submitRatingButton = container.querySelector('.submit-rating') as HTMLButtonElement;
	const ratingThankYou = container.querySelector('.thankyou-rating') as HTMLElement;

	ratingTextarea.onchange = ratingTextarea.oninput = () => {
		const value = ratingTextarea.value;
		const cN = submitRatingButton.getAttribute('data-bi-name');
		submitRatingButton.setAttribute('data-m', JSON.stringify({ cN, value, vtbm: value }));
		submitRatingButton.disabled = value.length === 0;
	};

	skipRatingButton.onclick = () => {
		resetRatingForm();
	};

	submitRatingButton.onclick = () => {
		resetRatingForm();
	};

	function resetRatingForm() {
		event.preventDefault();
		verbatimActivator.forEach(element => {
			element.setAttribute('aria-expanded', 'false');
		});
		ratingTextarea.value = '';
		ratingArea.classList.add('is-hidden');
		ratingForm.classList.add('is-hidden');
		ratingThankYou.classList.remove('is-hidden');
		setTimeout(() => ratingThankYou.focus(), 0);
	}
}

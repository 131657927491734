import { notifyContentUpdated } from '../affix';
import { document } from '../globals';
import { renderInTopicTOC } from '../in-topic-toc';
import { parseQueryString, toQueryString } from '../query-string';
import { getMoniker } from './moniker';
import { pageSupportsMoniker } from './page-monikers';

/**
 * Client-side content filtering by moniker.
 */
export function filterContentByMoniker() {
	const moniker = getMoniker();

	if (!pageSupportsMoniker(moniker)) {
		return false;
	}

	processDataMoniker(moniker);

	const links = discoverLinks(document.querySelector('.mainContainer'));
	processLinks(links, moniker);

	renderInTopicTOC();

	notifyContentUpdated();

	return true;
}

const monikerStyle = document.createElement('style');
document.head.appendChild(monikerStyle);

function processDataMoniker(moniker: string) {
	monikerStyle.textContent = `
		[data-moniker]:not([data-moniker~='${moniker}']) {
			display: none !important;
		}
	`;

	const addId = document.querySelectorAll(`[data-moniker~='${moniker}'] [data-id]`);
	for (let i = 0; i < addId.length; i++) {
		const element = addId.item(i);
		element.id = element.getAttribute('data-id');
	}

	const removeId = document.querySelectorAll(
		`[data-moniker]:not([data-moniker~='${moniker}']) [id]`
	);
	for (let i = 0; i < removeId.length; i++) {
		const element = removeId.item(i);
		element.setAttribute('data-id', element.id);
		element.removeAttribute('id');
	}
}

/**
 * Discover anchor links in the DOM that need to be updated to use a new moniker parameter.
 * @param containerElement The element to query for anchor links to transform.
 */
export function discoverLinks(containerElement: Element) {
	return Array.from(
		containerElement.querySelectorAll(
			'a[href*="view="]:not(.preserve-view):not([data-linktype="external"])'
		)
	) as HTMLAnchorElement[];
}

export function processLinks(links: HTMLAnchorElement[], moniker: string) {
	function processLink(link: HTMLAnchorElement) {
		if (link.search === '') {
			return;
		}
		const query = parseQueryString(link.search);
		if (query.view === undefined) {
			return;
		}
		if (query['preserve-view'] === 'true') {
			return;
		}
		query.view = moniker;
		link.search = toQueryString(query);
	}

	links.forEach(processLink);
}

/**
 * Production builds are breaking additional query string parameters when a view arg is present.
 * This is not occurring in local builds.
 *
 * This function fixes links authored like this:
 * [cat-1.0 with preserve-view](./api/CatLibrary.Cat-2?view=cat-1.0&preserve-view=true)
 *
 * Which are emitted by the production build like this:
 * <a href="./api/CatLibrary.Cat-2?view=cat-1.0&%3bpreserve-view=true" data-linktype="relative-path">cat-1.0 with preserve-view</a>
 */
export function tempFixPreserveViewArgument(container: Element) {
	const selector = 'a[href*="&%3bpreserve-view="]';
	const links = container.querySelectorAll<HTMLAnchorElement>(selector);
	for (let i = 0; i < links.length; i++) {
		const link = links.item(i);
		const href = link.getAttribute('href');
		link.setAttribute('href', href.replace('&%3bpreserve-view=', '&preserve-view='));
	}
}

/**
 * Add the preserve-view class to links whose view does not match the current page's view.
 * The intent is to reduce backwards compatibility issues with content that links to a
 * specific view without using preserve-view.
 */
export function tempAddPreserveViewClass(container: Element, moniker = getMoniker()) {
	const selector = `a[href*="?view="]:not([href*="?view=${moniker}"]):not(.preserve-view)`;
	const links = container.querySelectorAll<HTMLAnchorElement>(selector);
	for (let i = 0; i < links.length; i++) {
		const link = links.item(i);
		link.classList.add('preserve-view');
	}
}

import { jsllReady } from '../bi';
import { initialCookieConsentDisposition } from '../cookie-consent';
import { cookies } from '../cookies';
import { traits } from '../environment/traits';
import { loadLibrary } from '../load-library';
import { preview } from '../preview/query-string';

const visitorServiceUrl = '/static/third-party/adobe-target/visitorAPI/4.4.0/visitorAPI.js';
const adobeIdSyncScriptUrl = '/static/third-party/adobe-target/adobeIdSync/1.0.0/adobeIdSync.js';
const atJsUrl = '/static/third-party/adobe-target/at-js/2.3.0/at.js';

export async function adobeTarget() {
	if (!preview('adobe-target', { require: true })) {
		return;
	}
	// Todo: Enable metadata when we remove preview flag
	//if (getMeta('adobe-target') !== 'true') {
	//  return;
	//}
	const consentAlreadyGranted = await initialCookieConsentDisposition;
	if (!consentAlreadyGranted) {
		return;
	}
	avoidFlicker();
	await loadLibrary(visitorServiceUrl);
	await loadLibrary(adobeIdSyncScriptUrl);
	defineTargetPageParams();
	instrumentTarget();
	loadLibrary(atJsUrl);
	await experimentRendered();
	performance.mark('Experiment Rendered');
}

function defineTargetPageParams() {
	const at_property =
		traits.supportLevel === 'production' && traits.reviewFeatures !== true
			? 'bdabb721-9b44-aabd-3839-ac91540d91f8'
			: '67962d9e-5268-2282-0f8d-510e18b41bc8';
	const rawCookie = (cookies.get('MC1') || cookies.get('MSFPC') || '').slice(0, 32);
	if (rawCookie.length > 0) {
		window.targetPageParams = function () {
			return {
				mbox3rdPartyId: rawCookie,
				at_property
			};
		};
	} else {
		window.targetPageParams = function () {
			return {
				at_property
			};
		};
	}
}

function instrumentTarget() {
	document.addEventListener('at-request-succeeded', async (e: CustomEvent) => {
		performance.mark('at-request-succeeded');
		const tntResponse = e && e.detail ? e.detail.analyticsDetails : null;
		if (tntResponse) {
			const awa = await jsllReady;
			awa.ct.captureContentUpdate({
				actionType: 'A',
				behavior: 12,
				content: {},
				pageTags: {
					tnta: tntResponse[0] && tntResponse[0].payload ? tntResponse[0].payload.tnta : ''
				}
			});
		}
	});
	const events = [
		'at-library-loaded',
		'at-request-start',
		'at-request-failed',
		'at-content-rendering-start',
		'at-content-rendering-succeeded',
		'at-content-rendering-failed',
		'at-content-rendering-no-offers',
		'at-content-rendering-redirect'
	];
	for (const event of events) {
		document.addEventListener(event, () => {
			performance.mark(event);
		});
	}
}

function avoidFlicker() {
	document.body.insertAdjacentHTML(
		'beforeend',
		`<style>
		/* disable at.js pre-hide */
		html > body { opacity: 1 !important; }
		</style>`
	);
}

// observe the experiment container element, resolving when "hello world" has been rendered within.
function experimentRendered(): Promise<void> {
	return new Promise(resolve => {
		const el = document.getElementById('adobe-target-experiment-container');
		const observer = new MutationObserver(() => {
			if (el.textContent.includes('hello world')) {
				observer.disconnect();
				resolve();
			}
		});
		observer.observe(el, { childList: true, subtree: true });
	});
}

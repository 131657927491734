import { signInClassName } from './auth/sign-in-links';
import { features } from './environment/features';
import { getOrigin } from './get-origin';
import { location } from './globals';
import { getQueryStringMap, InputArgs, parseQueryString, toQueryString } from './query-string';

export function transformAzurePortalLinks(element: Element) {
	Array.from(element.querySelectorAll<HTMLAnchorElement>('a[href*="azure-portal=true"]')).forEach(
		link => {
			const parameters = parseQueryString(link.search);
			delete parameters['azure-portal'];

			link.search = toQueryString(parameters);
			link.target = 'az-portal';
			link.insertAdjacentHTML(
				'beforeend',
				' <span class="docon docon-navigate-external" aria-hidden="true"></span>'
			);
		}
	);
}

export function addChromelessLinkBehavior(
	container: HTMLElement,
	locationSearch = location.search
) {
	if (!features.chromeless) {
		return;
	}

	const queryStrings = parseQueryString(locationSearch);
	const isNew = queryStrings.linktarget === 'new';

	container.addEventListener('click', event => {
		const { anchor, shouldHandle } = shouldHandleClick(event, { compareOrigin: !isNew });

		if (shouldHandle) {
			event.preventDefault();

			// if specified in query string we should open the link in a new tab
			if (isNew) {
				window.open(anchor.href, '_blank');
				return;
			}

			// otherwise rebuild the link url with context/chromeless and go there.
			location.href = buildChromelessUrl(anchor, queryStrings);
		}
	});
}

export function buildChromelessUrl(anchor: HTMLAnchorElement, queryStrings: InputArgs) {
	const { pathname, hash } = anchor;
	const origin = getOrigin(anchor);

	const anchorUrl = new URL(anchor.href);
	const args = getQueryStringMap(anchorUrl) as InputArgs;
	args.fromOrigin = queryStrings.fromOrigin;
	args.context = 'context/chromeless';

	return `${origin}${pathname}?${toQueryString(args)}${hash}`;
}

/**
 * Checks whether a link click should be handled by custom navigation logic.
 * @param event The click event.
 * @param options Flags to enable/disable checks.
 */
export function shouldHandleClick(event: MouseEvent, { compareOrigin } = { compareOrigin: true }) {
	// Get the clicked element's ancestor anchor.
	const anchor =
		event.target instanceof HTMLElement && (event.target.closest('a') as HTMLAnchorElement);
	// Check whether this is a standard nav link click.
	if (
		!anchor ||
		event.altKey ||
		event.ctrlKey ||
		event.metaKey ||
		event.shiftKey || // user is modifying the click
		(anchor.target && anchor.target !== window.name && anchor.target !== '_self') || // target is set
		anchor.href.lastIndexOf('javascript:') === 0 || // it's javascript: or javascript:void
		anchor.hasAttribute('download') // it's link to download a file like a pdf
	) {
		return { anchor, shouldHandle: false };
	}
	// Check the origin and client-side features.
	const originMatches = getOrigin(anchor) === location.origin;
	if (
		(compareOrigin && !originMatches) || // origin matches?
		(originMatches && anchor.classList.contains(signInClassName)) || // we have custom javascript for sign-in links
		(originMatches && /^#lightbox/.test(anchor.hash)) // lightbox javascript component will handle this link
	) {
		return { anchor, shouldHandle: false };
	}
	// Profile page: following tab. User profile link fix
	if (anchor.classList.contains('following-user-profile')) {
		return { anchor, shouldHandle: false };
	}
	return { anchor, shouldHandle: true };
}

export interface AzureToken {
	access_token: string;
	access_token_type: string;
	expires_at: string;
	graph_access_token: string;
	graph_access_token_type: string;
	graph_expires_at: string;
	key_vault_access_token: string;
	key_vault_access_token_type: string;
	key_vault_expires_at: string;
	pbi_access_token: string;
	pbi_access_token_type: string;
	pbi_expires_at: string;
	display_name: string;
	default_domain: string;
	tenant_id: string;
}

export const azureToken = {
	value: null as AzureToken | null
};

import {
	loc_exploreOtherPopularPaths,
	loc_loading,
	loc_onboardingExperience_pleaseEditYourInterests,
	loc_onboardingExperience_zeroTrendingContentDescription,
	loc_welcomeBackUser,
	loc_weThinkYouMightLikeThese
} from '@msdocs/strings';

import { flatten, StandardProgressResponse } from '../apis/learn';
import { authStatusDetermined } from '../auth';
import { tryLoadUserFromProfileAPI } from '../auth/service';
import { user } from '../auth/user';
import { Modal } from '../components/modal/modal';
import { getResultRatings, getUidsFromBrowseResults } from '../content-browser';

import { createListItemsHtml } from '../content-browser/rendering';
import { features } from '../environment/features';
import { contentLoaded, history, location, msDocs } from '../globals';
import { getUserProgressFromMetaTags, organizeLearnCards, renderLearnCards } from '../learn/cards';
import { getItemSummariesFromMetaTags } from '../learn/summaries';
import {
	getInterests,
	getInterestsFromLocalStorage,
	isUserOnboarded,
	isUserOnboardedLocally,
	saveUserInterestsFromClient
} from '../learner-interests/index';
import { createLearnerInterestModalContent } from '../learner-interests/modal';
import { synchronizeListButtons } from '../lists';
import { html, render, TemplateResult } from '../lit-html';
import { addLocaleToPath } from '../locale';
import { getMetas } from '../meta';
import {
	SingleRequestBrowseTypes,
	browseConfig,
	getTopByType,
	getBrowseContent,
	TrendingBrowseResponse,
	TrendingBrowseResult
} from '../apis/browse';
import { Interests } from '../learner-interests/model';

const initialTrendingCriteria = (browseType: SingleRequestBrowseTypes) => {
	const homeBrowseConfig = browseConfig;
	let filterCriteria = homeBrowseConfig.filterCriteria;
	if (!user.isAuthenticated) {
		// auth filtering is handled server-side
		filterCriteria = getInterestsFromLocalStorage() || browseConfig.filterCriteria;
	}
	return {
		locale: msDocs.data.userLocale,
		filter: filterCriteria,
		top: user.isAuthenticated ? null : getTopByType(browseType),
		terms: '',
		hideCompleted: true,
		excludeUids: user.isAuthenticated ? null : getMetas('learn_item')
	};
};

export async function learnHomePage() {
	await authStatusDetermined;

	await renderLearnerInterest();

	renderPersonalization();

	if (!user.isAuthenticated) {
		renderFeatured();
	}

	synchronizeListButtons(document.querySelector('#main'));
	renderMascots();
}

let gridSkeleton: TemplateResult[];

/**
 * Updates the trending and featured content, and should be run at least once.
 */
async function renderBrowseContent() {
	if (!features.contentBrowse) {
		return;
	}

	if (!gridSkeleton) {
		gridSkeleton = [];
		for (let i = 0; i < 9; ++i) {
			gridSkeleton.push(skeletonGridItemTemplate());
		}
	}

	const trendingGridId = 'trending-grid';
	const trendingGrid = document.getElementById(trendingGridId);
	render(gridSkeleton, trendingGrid);
	trendingGrid.setAttribute('aria-label', loc_loading);

	const browseType: SingleRequestBrowseTypes = 'home';
	const browseResponse = (await getBrowseContent(
		initialTrendingCriteria(browseType),
		browseType
	)) as TrendingBrowseResponse;
	browseResponse.popularity = browseResponse.popularity.map((x: TrendingBrowseResult) => {
		x.icon_url = addLocaleToPath(x.icon_url, 'en-us');
		return x;
	});

	if (browseResponse.newest) {
		browseResponse.newest = browseResponse.newest.map((el: TrendingBrowseResult) => {
			el.icon_url = addLocaleToPath(el.icon_url, 'en-us');
			return el;
		});
	}

	if (user.isAuthenticated) {
		renderTrendingResultsGrid(
			browseResponse.newest,
			browseType,
			document.getElementById('featured-grid')
		);
	}

	const zeroResultsId = 'zero-results-interests';
	const browseTrendingLinkId = 'browse-trending-link';
	const trendingTitleQuery = '#trending-title';

	trendingGrid.removeAttribute('aria-label');
	if (!browseResponse.popularity || !browseResponse.popularity.length) {
		document.getElementById('edit-learning-preferences').classList.add('is-hidden');
		document.getElementById(browseTrendingLinkId).classList.add('is-hidden');
		render('', trendingGrid);
		const zeroResultsContainer = document.getElementById(zeroResultsId);
		const zeroResultsButton = zeroResultsContainer.querySelector<HTMLButtonElement>('button');
		if (zeroResultsContainer.classList.contains('is-hidden')) {
			zeroResultsContainer.parentElement
				.querySelector(trendingTitleQuery)
				.classList.remove('has-margin-bottom-none');
			zeroResultsContainer.classList.remove('is-hidden');
		} else if (zeroResultsButton) {
			// cleanup to previous handler as this section could run repeatedly
			zeroResultsButton.onclick = null;
		}
		render(zeroResultsTemplate(), zeroResultsContainer);
		zeroResultsContainer.querySelector<HTMLButtonElement>('button').onclick = () =>
			showLearnerInterestModal('edit', () => renderBrowseContent());
	} else {
		const zeroResultsContainer = document.getElementById(zeroResultsId);
		const zeroResultsButton = zeroResultsContainer.querySelector<HTMLButtonElement>('button');
		if (zeroResultsButton) {
			zeroResultsButton.onclick = null;
		}
		render('', zeroResultsContainer);
		zeroResultsContainer.classList.add('is-hidden');
		zeroResultsContainer.parentElement
			.querySelector(trendingTitleQuery)
			.classList.add('has-margin-bottom-none');
		enableEditPreferenceButton();
		document.getElementById(browseTrendingLinkId).classList.remove('is-hidden');

		await renderTrendingResultsGrid(
			browseResponse.popularity,
			browseType,
			document.getElementById(trendingGridId)
		);
		const interests = getInterests();
		customizeByInterest(interests);
	}
}

async function renderLearnerInterest() {
	if (user.isAuthenticated) {
		await tryLoadUserFromProfileAPI();
	}

	if (location.hash === '#onboard') {
		history.replaceState(undefined, document.title, location.pathname + location.search);
		if (isUserOnboarded()) {
			await renderBrowseContent();
		} else {
			await showLearnerInterestModal('onboard', () => renderBrowseContent());
		}
		return;
	}

	if (!isUserOnboarded()) {
		document
			.querySelector('a[data-bi-name="learn-homepage-cta-browse"]')
			?.classList.add('is-hidden');
		const customizeCta = document.querySelector(
			'button[data-bi-name="learn-homepage-cta-customize"]'
		) as HTMLButtonElement;
		customizeCta.classList.remove('is-hidden');
		customizeCta.onclick = () => showLearnerInterestModal('onboard', () => renderBrowseContent());
	} else {
		await renderOnboardedUserExperience();
	}

	renderBrowseContent();
}

function zeroResultsTemplate() {
	return html`
		<span>${loc_onboardingExperience_zeroTrendingContentDescription}</span>
		<button
			class="button is-text has-text-primary has-flex-align-items-baseline"
			type="button"
			aria-labelledby="please-edit-your-interests"
		>
			<span id="please-edit-your-interests"
				>${loc_onboardingExperience_pleaseEditYourInterests}</span
			>
			<span class="docon docon-chevron-right-light" aria-hidden="true"></span>
		</button>
	`;
}

async function renderOnboardedUserExperience() {
	const customizeCta = document.querySelector(
		'button[data-bi-name="learn-homepage-cta-customize"]'
	) as HTMLButtonElement;

	if (customizeCta) {
		customizeCta.onclick = null;
		customizeCta.remove();
	}
	document
		.querySelector('a[data-bi-name="learn-homepage-cta-browse"]')
		.classList.remove('is-hidden');
	enableEditPreferenceButton();

	if (isUserOnboardedLocally()) {
		await saveUserInterestsFromClient('learn');
	}
}

/**
 * Create and shows the component that handles the user learner interest interaction.
 * @param mode Identifies if the component is to be created for new interests selection or interests edits.
 * @param onUpdateInterest Handler guaranteed to run once after an update to interests.
 */
async function showLearnerInterestModal(mode: 'onboard' | 'edit', onUpdateInterest: () => void) {
	if (mode === 'onboard' && isUserOnboarded()) {
		// check again as registration flow could update interests
		renderOnboardedUserExperience();
		return;
	}

	const content = createLearnerInterestModalContent(mode, onUpdateInterest);
	const modal = new Modal(content);
	await modal.show();
	if (content.enableInterestEdit) {
		document.getElementById('edit-learning-preferences').onclick = () =>
			showLearnerInterestModal('edit', () => renderBrowseContent());
	}

	content.dispose();
}

function enableEditPreferenceButton() {
	if (!isUserOnboarded()) {
		return;
	}
	const preference = document.getElementById('edit-learning-preferences');
	if (!preference.classList.contains('is-hidden')) {
		return;
	}
	preference.classList.remove('is-hidden');
	preference.onclick = () => showLearnerInterestModal('edit', () => renderBrowseContent());
	if (msDocs.data.userDir === 'rtl') {
		preference.parentElement.classList.remove('has-margin-right-extra-large-desktop');
		preference.parentElement.classList.add('has-margin-left-extra-large-desktop');
	}
}

function renderPersonalization() {
	const trendingSection = document.getElementById('trending-section');
	if (!features.contentBrowse) {
		trendingSection.hidden = true;
		return;
	}

	if (!user.isAuthenticated) {
		return;
	}

	const heroTitle: HTMLElement = document.getElementById('hero-title');
	render(html` ${loc_welcomeBackUser.replace('{user}', `${user.displayName}`)}`, heroTitle);

	const featuredSection = document.getElementById('featured-section');

	featuredSection.setAttribute('data-bi-name', 'learn-homepage-new');
	trendingSection.setAttribute('data-bi-name', 'learn-homepage-personalized-trending');
}

async function renderFeatured() {
	const featuredSection = document.getElementById('featured-section');
	if (!featuredSection) {
		return;
	}

	await contentLoaded;
	const summaries = (await getItemSummariesFromMetaTags()).map(x =>
		x.map(y => {
			y.iconUrl = addLocaleToPath(y.iconUrl, 'en-us');
			return y;
		})
	);

	const userProgress: Promise<StandardProgressResponse> =
		user.isAuthenticated && features.gamification
			? getUserProgressFromMetaTags()
			: Promise.resolve([]);

	renderLearnCards(
		featuredSection,
		organizeLearnCards(flatten(summaries)),
		userProgress,
		msDocs.data.pageTemplate
	);
}

async function renderTrendingResultsGrid(
	results: TrendingBrowseResult[],
	browseType: SingleRequestBrowseTypes,
	resultsGrid: HTMLElement
) {
	if (!resultsGrid) {
		return;
	}

	const resultUids = getUidsFromBrowseResults(results);
	const ratings = await getResultRatings(browseType, resultUids);

	render(html`${createListItemsHtml(results, browseType, ratings)}`, resultsGrid);
}

const gridItemImage = (imageUrl: string) => {
	const gridListItem = document.createElement('li');
	gridListItem.classList.add('grid-item', 'is-decorative');
	render(
		html`
			<img
				alt=""
				src="${imageUrl}"
				class="image has-flex-align-self-center has-flex-justify-self-center has-height-175 has-width-auto is-center-aligned"
			/>
		`,
		gridListItem
	);
	return gridListItem;
};

function renderMascots() {
	const featuredGrid = document.getElementById('featured-grid');
	const trendingItemsGrid = document.getElementById('trending-grid');
	const hasNonSkeletonContent = () => {
		return (
			trendingItemsGrid.firstElementChild !== null &&
			trendingItemsGrid.firstElementChild.querySelector('.has-loading-skeleton') === null
		);
	};

	if (featuredGrid) {
		const cloudImage = 'https://docs.microsoft.com/en-us/media/learn/home/mascot-cloud.png';
		featuredGrid.appendChild(gridItemImage(cloudImage));
	}

	if (trendingItemsGrid && hasNonSkeletonContent()) {
		const docImage = 'https://docs.microsoft.com/en-us/media/learn/home/mascot-doc.png';
		trendingItemsGrid.appendChild(gridItemImage(docImage));
	}
}

function customizeByInterest(interests: Interests) {
	if (!interests) {
		return;
	}

	const browseLink = document.getElementById('browse-trending-link') as HTMLAnchorElement;
	const sectionHeading = document.getElementById('trending-title') as HTMLHeadingElement;
	if (!browseLink || !sectionHeading) {
		return;
	}

	sectionHeading.innerText = loc_weThinkYouMightLikeThese;
	browseLink.href = `browse/?roles=${interests.roles}&levels=${interests.levels}&products=${interests.products}`;
	browseLink.querySelector<HTMLSpanElement>(
		'#browse-trending-link-text'
	).innerText = loc_exploreOtherPopularPaths;
}

function skeletonGridItemTemplate() {
	return html`
		<li class="grid-item" aria-hidden="true">
			<article data-bi-name="card" class="card is-branded has-loading-skeleton">
				<div class="card-header has-background-azure-card" aria-hidden="true">
					<figure class="card-header-image">
						<img role="presentation" alt="" src="" />
					</figure>
				</div>
				<div class="card-content">
					<p class="card-content-super-title"></p>
					<a class="card-content-title" href=""><h3></h3></a>
					<ul class="card-content-metadata">
						<li></li>
						<li></li>
					</ul>
					<ul class="tags">
						<li class="tag is-small"></li>
						<li class="tag is-small"></li>
						<li class="tag is-small"></li>
					</ul>
				</div>
				<div class="card-footer">
					<div class="card-footer-item">
						<progress class="progress is-success is-extra-small" value="0" max="100"></progress
						><span class="progress-label">0%</span>
					</div>
					<div class="card-footer-item">
						<div class="buttons">
							<button
								type="button"
								class="bookmark button is-text has-text-primary has-inner-focus is-small has-margin-right-none is-icon-only"
								data-bi-name="bookmarks"
								data-list-type="bookmarks"
								data-list-item-title=""
								data-list-item-url=""
							>
								<span class="icon" aria-hidden="true">
									<span class="docon docon-single-bookmark"></span>
								</span>
								<span class="is-visually-hidden"></span>
							</button>
							<button
								type="button"
								class="collection button is-text has-text-primary has-inner-focus is-small is-icon-only"
								data-bi-name="collection"
								data-list-type="collection"
								data-list-item-title=""
								data-list-item-url=""
							>
								<span class="icon" aria-hidden="true">
									<span class="docon docon-circle-addition"></span>
								</span>
								<span class="is-visually-hidden"></span>
							</button>
						</div>
					</div>
				</div>
			</article>
		</li>
	`;
}

import { keyCodes } from '../../key-codes';

import type { ImageBrowser } from './image-browser';

export function attachEvents(imageBrowser: ImageBrowser, imageElement: Element) {
	const scalar = 5;
	const state = {
		horizontal: 0,
		vertical: 0
	};

	imageElement.addEventListener('keypress', (event: KeyboardEvent) => {
		if (event.which === keyCodes.enter) {
			event.preventDefault();
			imageBrowser.toggleExpand();
		}
	});

	imageElement.addEventListener('keydown', (event: KeyboardEvent) => {
		const code = event.which || event.keyCode;

		switch (code) {
			case keyCodes.left:
				event.preventDefault();
				state.horizontal = 1;
				break;
			case keyCodes.right:
				event.preventDefault();
				state.horizontal = -1;
				break;
			case keyCodes.up:
				event.preventDefault();
				state.vertical = 1;
				break;
			case keyCodes.down:
				event.preventDefault();
				state.vertical = -1;
				break;
		}

		if ([keyCodes.left, keyCodes.right, keyCodes.up, keyCodes.down].indexOf(code) > -1) {
			imageBrowser.panViewport(scalar * state.horizontal, scalar * state.vertical);
		}
	});

	imageElement.addEventListener('keyup', (event: KeyboardEvent) => {
		const code = event.which || event.keyCode;

		switch (code) {
			case keyCodes.left:
				event.preventDefault();
				state.horizontal = 0;
				break;
			case keyCodes.right:
				event.preventDefault();
				state.horizontal = 0;
				break;
			case keyCodes.up:
				event.preventDefault();
				state.vertical = 0;
				break;
			case keyCodes.down:
				event.preventDefault();
				state.vertical = 0;
				break;
		}
	});
}

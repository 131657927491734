import { msDocs } from '../globals';
import { devlangs } from '../name-maps/devlangs';
import { localStorage } from '../protected-storage';

const preferenceStorageKey = 'proglang';

export interface LanguageConfig {
	displayNameMap: { [language: string]: string };
	visibilityMap: { [language: string]: string };
	syntaxMap: { [language: string]: string };
	unset: '';
	default: string;
	preferred: string;
}

export const languageConfig: LanguageConfig = {
	displayNameMap: devlangs,

	visibilityMap: {
		'aspx-csharp': 'csharp',
		'aspx-vb': 'vb'
	},

	syntaxMap: {
		aspx: 'csharp',
		azurepowershell: 'powershell',
		cppcx: 'cpp',
		cppwinrt: 'cpp',
		hcl: 'json',
		nodejs: 'js'
	},

	unset: '',

	default: getDefaultDevLang(),

	get preferred() {
		return (localStorage.getItem(preferenceStorageKey) || languageConfig.unset).substr(5);
	},
	set preferred(language: string) {
		localStorage.setItem(preferenceStorageKey, 'lang-' + language);
	}
};

export function getDefaultDevLang() {
	if (msDocs && msDocs.data && msDocs.data.defaultDevLang) {
		return msDocs.data.defaultDevLang;
	}
	return '';
}

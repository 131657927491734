import {
	loc_allapis,
	loc_allPackages,
	loc_apiReference,
	loc_moduleReference,
	loc_packageReference,
	loc_selectPackage,
	loc_selectVersion
} from '@msdocs/strings';

export interface PlatformConfig {
	displayName: string;
	validSearchTerm: RegExp;
	namespaceItemType: string;
	namespacesPath: string;
	allApisLabel: string;
	resultsHeadingTemplate: string;
	selectLabel: string;
}

export const platformConfig: { [platformId: string]: PlatformConfig } = {
	dotnet: {
		displayName: '.NET',
		validSearchTerm: /^[A-Za-z_][A-Za-z0-9.<>,_]{2,255}$/,
		namespaceItemType: 'Namespace',
		namespacesPath: 'namespaces',
		allApisLabel: loc_allapis,
		resultsHeadingTemplate: loc_apiReference,
		selectLabel: loc_selectVersion
	},
	java: {
		displayName: 'Java',
		validSearchTerm: /^[A-Za-z][A-Za-z0-9.<>,]{2,255}$/,
		namespaceItemType: 'Package',
		namespacesPath: 'namespaces',
		allApisLabel: loc_allapis,
		resultsHeadingTemplate: loc_apiReference,
		selectLabel: loc_selectVersion
	},
	javascript: {
		displayName: 'JavaScript',
		validSearchTerm: /^[A-Za-z@][A-Za-z0-9.<>,\/\-]{2,255}$/,
		namespaceItemType: 'Package',
		namespacesPath: 'packages',
		allApisLabel: loc_allPackages,
		resultsHeadingTemplate: loc_packageReference,
		selectLabel: loc_selectPackage
	},
	powershell: {
		displayName: 'PowerShell',
		validSearchTerm: /^[A-Za-z][A-Za-z0-9.-]{2,255}$/,
		namespaceItemType: 'Module',
		namespacesPath: 'modules',
		allApisLabel: loc_allPackages,
		resultsHeadingTemplate: loc_moduleReference,
		selectLabel: loc_selectPackage
	},
	python: {
		displayName: 'Python',
		validSearchTerm: /^[A-Za-z][A-Za-z0-9.<>,_]{2,255}$/,
		namespaceItemType: 'Package',
		namespacesPath: 'packages',
		allApisLabel: loc_allPackages,
		resultsHeadingTemplate: loc_packageReference,
		selectLabel: loc_selectPackage
	},
	rest: {
		displayName: 'REST',
		validSearchTerm: /^[A-Za-z ]{3,255}$/,
		namespaceItemType: '',
		namespacesPath: 'services',
		allApisLabel: loc_allapis,
		resultsHeadingTemplate: loc_apiReference,
		selectLabel: loc_selectVersion
	}
};

import { document, msDocs, window } from './globals';
import { keyCodes } from './key-codes';

export function initDropdowns(container: Element) {
	container.addEventListener('click', event => {
		const trigger =
			event.target instanceof Element && (event.target.closest('.dropdown-trigger') as HTMLElement);
		if (!trigger) {
			return;
		}
		event.preventDefault();
		const dropdown = trigger.parentElement;
		const activate = trigger.getAttribute('aria-expanded') !== 'true';
		trigger.setAttribute('aria-expanded', activate.toString());

		let collapse: () => void;

		if (activate) {
			if (!dropdown.classList.contains('has-centered-menu')) {
				alignMenu(dropdown);
			}

			const checkTarget = (event: Event) => {
				if (!(event.target instanceof Element)) {
					return;
				}

				if (
					!dropdown.contains(event.target) ||
					(event.type === 'click' && event.target.closest('.dropdown-menu-close'))
				) {
					collapse();
				}
			};

			const keyHandler = (event: KeyboardEvent) => {
				if (event.keyCode === keyCodes.escape) {
					collapse();
					trigger.focus();
				}
			};

			const blurHandler = async () => {
				if (
					((document.activeElement && document.activeElement.nodeName) || '').toLowerCase() ===
					'iframe'
				) {
					collapse();
				}
			};

			collapse = () => {
				container.removeEventListener('focus', checkTarget);
				container.removeEventListener('click', checkTarget);
				container.removeEventListener('touchstart', checkTarget);
				container.removeEventListener('keydown', keyHandler);
				window.removeEventListener('blur', blurHandler);
				trigger.setAttribute('aria-expanded', 'false');
			};

			const useCapture = true;
			container.addEventListener('focus', checkTarget, useCapture);
			container.addEventListener('click', checkTarget);
			container.addEventListener('touchstart', checkTarget);
			container.addEventListener('keydown', keyHandler);
			container.addEventListener('collapse-dropdown', event => {
				event.stopPropagation();
				collapse();
			});
			window.addEventListener('blur', blurHandler);
		}
	});
}

/**
 * Given a child element, collapses it's parent .dropdown element.
 * @param child The child element of a .dropdown element.
 */
export function collapseDropdown(child: Element) {
	child.dispatchEvent(new CustomEvent('collapse-dropdown', { bubbles: true }));
}

/**
 * Given the dropdown element, dispatch a click event to the dropdown-trigger
 * @param dropdown The dropdown element with a dropdown-trigger child element
 */
export function toggleDropdown(dropdown: Element) {
	const trigger = dropdown.querySelector('.dropdown-trigger');
	trigger.dispatchEvent(new CustomEvent('click', { bubbles: true }));
}

function alignMenu(dropdown: Element) {
	// locate the ancestor that will clip the dropdown
	const overflowX = (el: Element) => window.getComputedStyle(el).overflowX;
	let container = dropdown.parentElement;
	while (container && container.nodeName !== 'BODY' && overflowX(container) !== 'hidden') {
		container = container.parentElement;
	}

	// if the element is attached to the DOM we should have found a clipping ancestor.
	if (container === null) {
		return;
	}

	const trigger = dropdown.querySelector('.dropdown-trigger') as HTMLElement;
	const menu = dropdown.querySelector('.dropdown-menu') as HTMLElement;
	const isSubmenu = dropdown.closest('.dropdown-menu') !== null;
	const isScrollable = menu.classList.contains('is-vertically-scrollable');

	// reset the menu alignment
	menu.style.left = '';
	menu.style.right = '';
	menu.style.maxHeight = '';

	// align the menu based on the trigger's proximity to a clipping edge.
	const { left: containerLeft, right: containerRight } = container.getBoundingClientRect();
	const { left, right, top, bottom } = trigger.getBoundingClientRect();
	const menuWidth = menu.getBoundingClientRect().width;

	if (isSubmenu) {
		// submenus are adjacent to the parent menu and vertically aligned with the top of the parent dropdown trigger.
		menu.style.top = '-1px'; // top border.
		if (msDocs.data.userDir === 'ltr') {
			if (right + menuWidth <= containerRight) {
				menu.style.right = '0';
				menu.style.transform = 'translateX(100%)';
			} else {
				menu.style.left = '0';
				menu.style.transform = 'translateX(-100%)';
			}
		} else {
			if (right - menuWidth >= containerLeft) {
				menu.style.left = '0';
				menu.style.transform = 'translateX(-100%)';
			} else {
				menu.style.right = '0';
				menu.style.transform = 'translateX(100%)';
			}
		}
		if (isScrollable) {
			menu.style.maxHeight = `${window.innerHeight - top - 8}px`;
		}
	} else {
		// top level menus are aligned with the dropdown trigger. preferrably the user-left, if there's room.
		if (msDocs.data.userDir === 'ltr') {
			if (left + menuWidth <= containerRight) {
				menu.style.left = '0';
			} else {
				menu.style.right = '0';
			}
		} else {
			if (right - menuWidth >= containerLeft) {
				menu.style.right = '0';
			} else {
				menu.style.left = '0';
			}
		}
		if (isScrollable) {
			menu.style.maxHeight = `${window.innerHeight - bottom - 8}px`;
		}
	}
}

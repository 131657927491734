import { loc_home } from '@msdocs/strings';
import { BodyTemplateContainer } from '../../../body-template-container';
import { deepClone } from '../../../clone-object';
import { DisposableElement } from '../../../disposable-html-element';
import { apis } from '../../../environment/apis';
import { document, msDocs } from '../../../globals';
import {
	CallToAction,
	docsDefaultHeader,
	learnHeader,
	removeOverflowMenu,
	UhfParseResult
} from '../../../header/model';
import {
	hasSecondaryNavigation,
	resolveLinkUrl,
	siteContentNav,
	siteContext
} from '../../../header/site-context';
import { html, render, TemplateResult } from '../../../lit-html';
import { PropertyChangedEvent } from '../../../view-model';
import { isFocusable } from '../../modal/tabindex';
import { NavItem, NavLink, NavMenu } from '../model';
import { NavBarViewModel } from '../view-model';
import { modalBodyTemplate, modalFooterTemplate, modalHeaderTemplate } from './view';

export async function createNavModalContent(navbarViewModel: NavBarViewModel) {
	const view: DisposableElement = document.createElement('div') as any;
	view.classList.add('modal-content', 'is-docked-right', 'is-flex', 'is-flex-column');

	const viewModel = new BodyTemplateContainer();

	view.paint = () => {
		render(
			html` ${modalHeaderTemplate(navbarViewModel)}
				<div
					id="modal-body"
					class="has-flex-grow has-flex-shrink is-full-height is-vertically-scrollable"
				>
					${viewModel.bodyTemplate}
				</div>
				${modalFooterTemplate()}`,
			view
		);
	};

	function paintBody() {
		view.paint();

		const body = view.querySelector<HTMLElement>('#modal-body');
		setFocusOnBody(body);
	}

	function popContent(event: Event) {
		const target =
			event.target instanceof HTMLElement && (event.target.closest('button') as HTMLButtonElement);
		if (!target || !target.classList.contains('pop-content')) {
			return;
		}

		viewModel.pop();
		paintBody();
	}

	const unsubscribe = navbarViewModel.subscribe(PropertyChangedEvent, view.paint);
	view.dispose = () => {
		viewModel.dispose();
		unsubscribe();

		view.removeEventListener('click', popContent);
	};

	let items = loadItems(navbarViewModel.items);
	let title: string | TemplateResult;
	let contextCategory: NavLink;
	let contextCta: CallToAction;

	function menuHandler(menu: NavMenu) {
		items = menu.items;
		title = menu.title;

		// intermediate views rendering menu items do not have category or call to action
		contextCategory = null;
		contextCta = null;
		pushNewBodyTemplateOnStack(true);
		paintBody();
	}

	function pushNewBodyTemplateOnStack(isSubMenu: boolean = false) {
		const template = modalBodyTemplate(
			items,
			menuHandler,
			isSubMenu,
			title,
			contextCategory,
			contextCta
		);
		viewModel.push(template);
	}

	function paint() {
		pushNewBodyTemplateOnStack();
		view.paint();
	}

	function popUntilFirstElement() {
		if (viewModel.stackLength <= 1) {
			return;
		}

		while (viewModel.stackLength > 1) {
			viewModel.pop();
		}

		paintBody();
	}

	paint(); // initial modal-content rendering
	view.addEventListener('click', popContent);

	if (hasSecondaryNavigation(siteContext)) {
		const data = await siteContentNav;
		contextCategory = data.category;
		contextCta = data.callToAction;
		title = data.category ? data.category.title : null;
		items = removeOverflowMenu(data.items);
		pushNewBodyTemplateOnStack(true);
		paintBody();
	}

	view.querySelector<HTMLButtonElement>('#modal-header button').onclick = popUntilFirstElement;
	return view;
}

function setFocusOnBody(container: HTMLElement) {
	const focusTarget = container.querySelector<HTMLElement>('[autofocus]');
	if (focusTarget && isFocusable(focusTarget)) {
		focusTarget.focus();
		return;
	}

	container.focus();
}

function loadItems(items: NavItem[]) {
	const { userLocale } = msDocs.data;
	if (!items || !items.length) {
		return [];
	}

	const result = items.slice(0, items.length - 1);
	const linksToTransform = {} as Record<string, NavItem[]>;
	linksToTransform[`/${userLocale}/learn/`] = removeOverflowMenu(
		getMobileSubMenuLinks(learnHeader)
	).map(resolveLinkUrl);
	linksToTransform[`/${userLocale}/`] = flattenDocsDefaultMoreMenuItems(
		removeOverflowMenu(getMobileSubMenuLinks(docsDefaultHeader))
	).map(resolveLinkUrl);
	result.forEach((item, idx) => {
		if (item.kind === 'menu') {
			return;
		}

		const pathname = new URL(item.href, apis.contentNav).pathname;
		if (linksToTransform[pathname]) {
			result[idx] = {
				title: item.title,
				kind: 'menu',
				items: linksToTransform[pathname]
			} as NavItem;
		}
	});

	return result;
}

/**
 * Merge the nav items in the more menu that, by default, truncates the Docs default header links displayed in tablet.
 * @param navItems The nav items array from docsDefaultHeader
 */
function flattenDocsDefaultMoreMenuItems(navItems: NavItem[]) {
	const moreIndex = navItems.findIndex(i => i.kind === 'menu' && i.items.length);
	if (moreIndex === -1) {
		return navItems;
	}

	const result = [...navItems];
	result.splice(moreIndex, 1, ...(result[moreIndex] as NavMenu).items);
	return result;
}

/**
 * Return a array of NavItems that includes the category titled loc_home.
 * @param model The nav-bar model for the navigation links.
 */
function getMobileSubMenuLinks(model: UhfParseResult): NavItem[] {
	const homeLink = deepClone(model.category);
	homeLink.title = loc_home;
	return [homeLink, ...model.items];
}

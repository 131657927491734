import { loc_cancel, loc_search, loc_siteSearch_form_cancelSearch } from '@msdocs/strings';
import { siteContext } from '../../header/site-context';
import { generateElementId } from '../../html';
import { html, TemplateResult } from '../../lit-html';
import { headerSearchFormTemplate, updateInputSearchTerm } from './search-form';
import { NavBarViewModel } from './view-model';

export function siteSearchTemplate(viewModel: NavBarViewModel): TemplateResult {
	if (!hasPageSiteSearch()) {
		return null;
	}

	const formDivId = generateElementId();

	const expandTemplate = html`
		<span class="is-visually-hidden-mobile">${loc_search}</span>
		<span class="icon" aria-hidden="true">
			<span class="docon docon-search"></span>
		</span>
	`;

	const collapseTemplate = html`<span class="nav-bar-button-text">${loc_cancel}</span>`;

	const formExpandHandler = () => {
		viewModel.searchExpanded = !viewModel.searchExpanded;

		if (viewModel.searchExpanded) {
			// focus, then update value so that the cursor is at the end of term in input
			const searchInput = document.getElementById('site-search-input') as HTMLInputElement;
			if (viewModel.mobile) {
				// to fix auto-zoom on mobile for inputs with font-size < 16px
				searchInput.style.fontSize = '16px';
			}
			searchInput.focus();
			if (viewModel.mobile) {
				// to fix auto-zoom on mobile for inputs with font-size < 16px
				searchInput.style.fontSize = '';
			}
			updateInputSearchTerm(searchInput);
		}
	};

	const searchClasses = `${viewModel.searchExpanded ? 'is-expanded' : ''}`;

	return html`
		<div
			class="nav-bar-search ${!viewModel.searchExpanded ? '' : 'is-expanded'}"
			id="${formDivId}"
			?hidden="${!viewModel.searchExpanded}"
		>
			${headerSearchFormTemplate(viewModel)}
		</div>
		${!viewModel.mobile
			? html`
					<div class="nav-bar-search" ?hidden="${!viewModel.desktop}">
						<!-- hidden when the width drops to tablet -->
						${headerSearchFormTemplate(viewModel, true)}
					</div>
			  `
			: null}
		<div class="nav-bar-search ${searchClasses}" ?hidden="${viewModel.desktop}">
			<div class="nav-bar-search-form-expander">
				<button
					id="form-expander"
					class="nav-bar-button has-hover-underline ${searchClasses}"
					title="${viewModel.searchExpanded ? loc_siteSearch_form_cancelSearch : loc_search}"
					aria-expanded="${viewModel.searchExpanded}"
					aria-controls="${formDivId}"
					@click=${formExpandHandler}
				>
					${viewModel.searchExpanded ? collapseTemplate : expandTemplate}
				</button>
			</div>
		</div>
	`;
}

function hasPageSiteSearch() {
	return siteContext !== 'Q&A' && siteContext !== 'Style-Guide';
}

import { window } from '../globals';
import { parseQueryString } from '../query-string';

export const monikerChangedEvent = 'moniker-changed';
export const sameMonikerSelectedEvent = 'same-moniker-selected';

const readMonikerFromQuery = () => {
	const view = parseQueryString().view;
	return view === undefined ? '' : view;
};

let moniker = readMonikerFromQuery();

/**
 * Gets the page moniker.
 */
export function getMoniker(): string {
	return moniker;
}

/**
 * Update the page moniker (the "view" argument in the query string).
 * @param moniker The API moniker/view- ex: "netcore-1.1".
 */
export function setMoniker(newMoniker: string) {
	if (newMoniker === moniker) {
		window.dispatchEvent(new CustomEvent(sameMonikerSelectedEvent, { detail: { moniker } }));
		return;
	}
	moniker = newMoniker;
	window.dispatchEvent(new CustomEvent(monikerChangedEvent, { detail: { moniker } }));
}

window.addEventListener('popstate', () => setMoniker(readMonikerFromQuery()));

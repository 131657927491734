import { DocsAuthTokenClaims, user } from '../auth/user';
import { InvalidClaimError, storeDocsToken, verifyToken } from '../auth/v2/jwt';
import { apis } from '../environment/apis';
import { createRequest, fetchWithTimeout } from '../fetch';

export class UserNotFoundError {}

/**
 * API call to trade a login.microsoftonline.com id token for a Docs-specific authentication token.
 * @param idToken The id_token in JWT format received from login.microsoftonline.com
 */
export function getDocsAuthToken(idToken: string): Promise<string> {
	const init = { method: 'GET' };
	const request = createRequest(apis.auth.docs, init, false);
	request.headers.set('Authorization', `Bearer ${idToken}`);

	return fetchWithTimeout(request).then(response => {
		if (response.ok) {
			return response.text();
		}

		if (response.status === 404 || response.status === 204) {
			// User wasn't found and we need to step into registration flow.
			throw new UserNotFoundError();
		}

		if (response.status === 401) {
			throw new InvalidClaimError({
				detail: 'token not accepted'
			});
		}

		throw new Error(`get docs auth token failed (${response.status} ${response.statusText})`);
	});
}

/**
 * Intercept the JWT in the authorization header and store the token.
 * This only should happen for profile API calls.
 */
export function detectDocsTokenInResponseHeader(response: Response, refreshUser: boolean = true) {
	try {
		if (response.ok && response.headers.has('Authorization')) {
			const token = response.headers.get('Authorization');
			if (token) {
				const payload = verifyToken(token) as DocsAuthTokenClaims;
				storeDocsToken(token);
				if (refreshUser) {
					user.readUserFromToken(payload);
				}
			}
		}

		return response;
	} catch {
		return response;
	}
}

import { html, render } from '../lit-html';
import { reportFeedback } from '../apis/site-feedback';
import { jsllReady } from '../bi';
import { cookies } from '../cookies';
import { features } from '../environment/features';
import { eventBus } from '../event-bus';
import { validateFileInput } from '../file-input';
import { msDocs } from '../globals';
import { isProduction, isReview } from '../is-production';
import { parseQueryString } from '../query-string';
import { RouterAfterNavigateEvent } from '../router/events';
import { beforeUnload, listenUntilUnload } from '../router/utils';
import { FeedbackReportType, renderFeedbackReport } from './rendering';

interface SubCategoryCollection {
	[index: string]: HTMLInputElement;
}

let isRendered: boolean = false;

eventBus.subscribe(RouterAfterNavigateEvent, (): void => {
	initFeedbackReport('reportIssue');
});

export const setIsRendered = (value: boolean): void => {
	isRendered = value;
};

const getJsllImpressionGuid = async (): Promise<string> => {
	let impressionGuid = '';

	try {
		const awa = await jsllReady;
		impressionGuid = awa.ids.getImpressionGuid();
	} catch (ex) {}

	return impressionGuid;
};

const enableSubmit = (submitButton: HTMLButtonElement): void => {
	submitButton.disabled = false;
	submitButton.classList.remove('is-loading');
};

const getCheckedInputElement = (container: HTMLElement): HTMLInputElement => {
	return container.querySelector('input:checked');
};

const showSubCategory = (
	firstCategoryInput: HTMLElement,
	subCategories: SubCategoryCollection
): boolean => {
	let subCategoryShown = false;
	const elementSubCategory =
		(firstCategoryInput != null &&
			'subCategory' in firstCategoryInput.dataset &&
			firstCategoryInput.dataset.subCategory) ||
		null;

	for (const subCategoryId in subCategories) {
		const hide = elementSubCategory !== subCategoryId;

		const categoryContainer = subCategories[subCategoryId].closest('div.field') as HTMLElement;
		subCategories[subCategoryId].disabled = hide;
		categoryContainer.hidden = hide;

		const subCategoryRadios = Array.from(
			categoryContainer.querySelectorAll<HTMLInputElement>(`.radio`)
		);

		if (!hide) {
			subCategoryShown = true;
			subCategoryRadios.forEach(radio => {
				const radioInput = radio.querySelector<HTMLInputElement>('input');
				radioInput.setAttribute('required', '');
			});
		} else {
			subCategoryRadios.forEach(radio => {
				const radioInput = radio.querySelector<HTMLInputElement>('input');
				radioInput.removeAttribute('required');
			});
		}
	}

	return subCategoryShown;
};

const formOpened = (form: HTMLFormElement): boolean =>
	form.dispatchEvent(new CustomEvent('opened', { bubbles: false }));

const categoryChecked = (
	firstCategoryInput: HTMLElement,
	subCategories: SubCategoryCollection,
	form: HTMLFormElement
): void => {
	const shown = showSubCategory(firstCategoryInput, subCategories);
	if (!shown) {
		// no sub category, enable all fields and submit button
		formOpened(form);
	}
};

const subCategoryChecked = (form: HTMLFormElement): void => {
	const subCategoryRadios = Array.from(
		form.querySelectorAll<HTMLInputElement>(`[id^='feedback-report-category-'] .radio`)
	);

	subCategoryRadios.forEach(radio => {
		const radioInput = radio.querySelector<HTMLInputElement>('input');
		radioInput.setAttribute('required', '');
	});

	// sub category checked, enable all fields and submit button
	formOpened(form);
};

const enableRemainingForm = (fields: HTMLFieldSetElement): void => {
	fields.hidden = false;
};

const showAttachmentFileNames = (event: Event): void => {
	const attachmentInput = event.target as HTMLInputElement;
	if (!attachmentInput.files || attachmentInput.files.length === 0) {
		return;
	}

	const attachmentValidationMessage = document.querySelector<HTMLElement>('#attachment-validation');
	const errors = validateFileInput(attachmentInput.files);
	const fileNameContainer = attachmentInput.parentElement.querySelector<HTMLElement>(
		'#feedback-report-attachment-file-name'
	);
	if (errors.length) {
		const errorMessages = errors.map(
			error => html`<p class="help has-text-danger is-size-small has-margin-top-none">${error}</p>`
		);
		render(html`${errorMessages}`, attachmentValidationMessage);
		attachmentValidationMessage.hidden = false;
		render(html``, fileNameContainer);
		return;
	}

	const fileNames = Array.from(attachmentInput.files)
		.map(file => file.name)
		.join(', ');
	render(html`${fileNames}`, fileNameContainer);
	render(html``, attachmentValidationMessage);
	attachmentValidationMessage.hidden = true;
};

const disableSubmit = (submitButton: HTMLButtonElement): void => {
	submitButton.disabled = true;
	submitButton.classList.add('is-loading');
};

const hideMessage = (message: HTMLElement): boolean => (message.hidden = true);

const getCategory = () => {
	const categoryContainer = document.getElementById('feedback-report-category');
	const input = getCheckedInputElement(categoryContainer);
	const value = input.value;
	const text = input.parentElement.textContent.trim();
	return { value, text };
};

const getSubCategory = (subCategories: SubCategoryCollection) => {
	const categoryContainer = document.getElementById('feedback-report-category');
	const input = getCheckedInputElement(categoryContainer);

	if (input === null || !input.dataset.subCategory) {
		const valueSub: string = null;
		const textSub: string = null;
		return { valueSub, textSub };
	}

	const inputElements = Array.from(
		subCategories[input.dataset.subCategory].querySelectorAll<HTMLInputElement>('.radio input')
	);
	const checkedInputElement = inputElements.filter(i => i.checked)[0];
	const valueSub = checkedInputElement.value;
	const textSub = checkedInputElement.parentElement.textContent.trim();
	return { valueSub, textSub };
};

const getFormData = (form: HTMLFormElement): FormData => new FormData(form);

const showMessage = (message: HTMLElement): boolean => (message.hidden = false);

const hideInteractiveFormElements = (
	submitAnotherFeedbackButton: HTMLElement,
	submitButton: HTMLButtonElement,
	clearFormButton: HTMLElement,
	feedbackTypeSection: HTMLElement
): void => {
	submitAnotherFeedbackButton.classList.remove('is-hidden');
	feedbackTypeSection.classList.add('is-hidden');
	submitButton.classList.add('is-hidden');
	clearFormButton.classList.add('is-hidden');
};

const resetForm = (form: HTMLFormElement, subCategories: SubCategoryCollection): void => {
	// only resetting user input
	const fileNameContainer = form.querySelector<HTMLElement>(
		'#feedback-report-attachment-file-name'
	);

	document.getElementById('feedback-report-category-product').setAttribute('value', '');
	document.getElementById('feedback-report-category-content').setAttribute('value', '');

	form.category.value = '';
	form.summary.value = '';
	form.description.value = '';
	form.attachment.value = '';
	form.reset();

	const subCategoryRadios = Array.from(form.querySelectorAll<HTMLInputElement>('.radio'));
	subCategoryRadios.forEach(radio => {
		const radioInput = radio.querySelector<HTMLInputElement>('input');
		radioInput.setAttribute('required', '');
		radioInput.checked = false;
	});

	for (const subCategoryId in subCategories) {
		const subCategoryContainer = subCategories[subCategoryId].closest('div.field') as HTMLElement;
		const feedbackFormInfo = form.querySelector<HTMLElement>('#feedback-form-info');
		subCategoryContainer.hidden = true;
		feedbackFormInfo.hidden = true;
	}
	render(html``, fileNameContainer);
};

const submitReportFeedback = async (
	form: HTMLFormElement,
	subCategories: SubCategoryCollection,
	{ successMessage }: { successMessage: HTMLElement }
): Promise<void> => {
	// set the category. can't just override via FormData.set since IE11 doesn't support set
	const { value, text } = getCategory();
	const { valueSub, textSub } = getSubCategory(subCategories);

	if (form.feedbackCategory) {
		form.feedbackCategory.value = text; //first category text
	}
	form.category.value = valueSub ? valueSub : value; // sub or first category value
	if (form.issueType) {
		form.issueType.value = textSub; // sub category text
	}

	if (!isProduction || isReview) {
		form.summary.value = `[TEST] ${form.summary.value}`;
	}

	reportFeedback(getFormData(form));
	showMessage(successMessage);
	resetForm(form, subCategories);
};

const showInteractiveFormElements = (
	submitAnotherFeedbackButton: HTMLElement,
	submitButton: HTMLButtonElement,
	clearFormButton: HTMLElement,
	feedbackTypeSection: HTMLElement,
	{ successMessage }: { successMessage: HTMLElement }
): void => {
	submitAnotherFeedbackButton.classList.add('is-hidden');
	hideMessage(successMessage);
	feedbackTypeSection.classList.remove('is-hidden');
	submitButton.classList.remove('is-hidden');
	submitButton.disabled = true;
	clearFormButton.classList.remove('is-hidden');
};

export async function initFeedbackReport(
	type: FeedbackReportType,
	queryString?: string
): Promise<void> {
	const feedbackContainer = document.querySelector<HTMLElement>('.feedback-report');
	if (!features.feedbackReport || !feedbackContainer) {
		return;
	}

	renderFeedbackReport(feedbackContainer, type);

	const form = document.querySelector<HTMLFormElement>('#feedback-report-form');
	if (form === null || isRendered) {
		return;
	}

	// just to gate init
	isRendered = true;
	beforeUnload(() => {
		isRendered = false;
	});

	const errorMessage = form.querySelector<HTMLElement>('#feedback-report-error-message');
	const category = form.querySelector<HTMLElement>('#feedback-report-category');
	const subCategories: SubCategoryCollection = {
		'feedback-report-category-product': form.querySelector<HTMLInputElement>(
			'#feedback-report-category-product'
		),
		'feedback-report-category-content': form.querySelector<HTMLInputElement>(
			'#feedback-report-category-content'
		)
	};
	const attachmentInput = form.querySelector<HTMLInputElement>('#feedback-report-attachment');
	const fields = form.querySelector<HTMLFieldSetElement>('fieldset:nth-of-type(2)');
	const submitButton = form.querySelector<HTMLButtonElement>('button[type=submit]');
	const successMessage = form.querySelector<HTMLElement>('#feedback-report-success-message');
	const titleInput = form.querySelector<HTMLInputElement>('#feedback-report-feedbackTitle');
	const descriptionTextArea = form.querySelector<HTMLTextAreaElement>(
		'#feedback-report-description'
	);
	const submitAnotherFeedbackButton = form.querySelector<HTMLElement>(
		'#submit-another-feedback-button'
	);
	const clearFormButton = form.querySelector<HTMLElement>('#clear-form-button');
	const feedbackTypeSection = form.querySelector<HTMLElement>('#feedback-type-selection');

	// Setting some hidden fields with some current session context...
	form.contentLocale.value = msDocs.data.contentLocale;
	form.userLocale.value = msDocs.data.userLocale;
	// The following will hang the entire init if jsll doesn't load for some reason. Instead, fire off an async call to set a field value
	// form.jsllImpressionGuid.value = await getJsllImpressionGuid();
	(async () => {
		form.jsllImpressionGuid.value = await getJsllImpressionGuid();
	})();

	const sessionId = cookies.get('ai_session');
	form.aiSessionId.value = sessionId !== undefined ? sessionId.split('|')[0] : '';

	// ...and a little more context from referring page
	const { uid, documentId, versionIndependentDocumentId, contentPath, url } = parseQueryString(
		queryString
	);
	form.uid.value = uid;
	form.documentId.value = documentId;
	form.versionIndependentDocumentId.value = versionIndependentDocumentId;
	form.contentPath.value = contentPath;
	form.url.value = url;

	const checkRequiredInputFields = (): void => {
		if (!form.checkValidity()) {
			submitButton.disabled = true;
			return;
		}

		enableSubmit(submitButton);
	};

	// Fire off a change event on category selection...
	listenUntilUnload(category, 'change', () => {
		let element = getCheckedInputElement(category);

		if (element) {
			element.dispatchEvent(new CustomEvent('selected'));
		} else {
			element = null;
		}
	});

	listenUntilUnload(titleInput, 'input', () => {
		checkRequiredInputFields();
	});

	listenUntilUnload(descriptionTextArea, 'input', () => {
		checkRequiredInputFields();
	});

	// Wire up options to show their related sub-category
	const firstCategoryInputs = Array.from(category.querySelectorAll('input'));
	Array.from(firstCategoryInputs).forEach(firstCategoryInput =>
		listenUntilUnload(firstCategoryInput, 'selected', () =>
			categoryChecked(firstCategoryInput, subCategories, form)
		)
	);

	// The form needs to listen for an 'opened' event to reveal and enable the rest of the form
	Object.keys(subCategories).forEach(k =>
		listenUntilUnload(subCategories[k], 'change', () => {
			subCategoryChecked(form);
		})
	);
	listenUntilUnload(form, 'opened', () => enableRemainingForm(fields));

	// The bulma file field ui needs a little shim to display the file names as files are selected
	listenUntilUnload(attachmentInput, 'change', showAttachmentFileNames);

	// Handle the form submit
	listenUntilUnload(form, 'submit', async event => {
		event.preventDefault();
		if (submitButton.disabled) {
			return;
		}

		disableSubmit(submitButton);
		hideMessage(errorMessage);
		hideMessage(successMessage);

		try {
			await submitReportFeedback(form, subCategories, { successMessage });
			hideInteractiveFormElements(
				submitAnotherFeedbackButton,
				submitButton,
				clearFormButton,
				feedbackTypeSection
			);
		} catch (ex) {
			// todo: need to ensure an error here throws up a flare
			showMessage(errorMessage);
		}

		enableSubmit(submitButton);
	});

	listenUntilUnload(clearFormButton, 'click', () => {
		resetForm(form, subCategories);
	});

	// Run if user wants to submit another feedback
	listenUntilUnload(submitAnotherFeedbackButton, 'click', () => {
		showInteractiveFormElements(
			submitAnotherFeedbackButton,
			submitButton,
			clearFormButton,
			feedbackTypeSection,
			{ successMessage }
		);
	});
}

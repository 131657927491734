import { msDocs } from '../globals';
import { parseQueryString, updateQueryString } from '../query-string';

export const apiSearchTermChangedEvent = 'api-search-term-changed';

let term = '';

export function getTerm(): string {
	return term;
}

export function setTerm(newTerm: string) {
	newTerm = newTerm.trim();
	if (newTerm === term) {
		return;
	}

	term = newTerm;

	if (msDocs.data.pageTemplate === 'ApiBrowserPage') {
		updateQueryString({ term }, 'pushState');
	}

	window.dispatchEvent(new CustomEvent(apiSearchTermChangedEvent, { detail: { term } }));
}

function readTermFromQueryString() {
	const term = parseQueryString().term;
	return term === undefined ? '' : term.trim();
}

if (msDocs.data.pageTemplate === 'ApiBrowserPage') {
	term = readTermFromQueryString();
	window.addEventListener('popstate', () => setTerm(readTermFromQueryString()));
}

import {
	loc_about,
	loc_advocates,
	loc_assessments,
	loc_assessment_azureArchitectureReview,
	loc_assessment_azureFreeTrial,
	loc_assessment_azureStrategicMigrationAssessment,
	loc_assessment_cloudJourneyTracker,
	loc_assessment_governanceBenchmark,
	loc_availableAssessments,
	loc_browseAllPaths,
	loc_browseCertifications,
	loc_browseCourses,
	loc_certifications,
	loc_codeSamples,
	loc_documentation,
	loc_faqHelp,
	loc_learn,
	loc_mapView,
	loc_microsoftEducation,
	loc_more,
	loc_overview,
	loc_preview,
	loc_products_aspnet,
	loc_products_azure,
	loc_products_azure_devops,
	loc_products_dotnet,
	loc_products_dynamics,
	loc_products_m365,
	loc_products_m365_ems,
	loc_products_ms_graph,
	loc_products_office,
	loc_products_power_platform,
	loc_products_sql_server,
	loc_products_vs,
	loc_products_windows,
	loc_products_xamarin,
	loc_regionalDirectors,
	loc_tweets,
	loc_videos,
	loc_products,
	loc_roles,
	loc_roles_administrator,
	loc_roles_ai_engineer,
	loc_roles_maker,
	loc_roles_business_user,
	loc_roles_data_analyst,
	loc_roles_data_engineer,
	loc_roles_data_scientist,
	loc_roles_developer,
	loc_roles_devops_engineer,
	loc_roles_functional_consultant,
	loc_roles_security_engineer,
	loc_roles_solution_architect,
	loc_roles_student
} from '@msdocs/strings';
import { NavItem, NavLink } from '../components/navbar/model';
import { features } from '../environment/features';
import { msDocs } from '../globals';
import { html } from '../lit-html';
import { StyleGuideTocNode, tocJson } from '../toc/style-guide/model';

export interface UhfParseResult {
	category: NavLink;
	items: NavItem[];
	callToAction?: CallToAction;
	cookieBanner: Element | null;
}

export interface CallToAction {
	primary: NavLink;
	secondary?: NavLink;
}

export interface UhfData {
	footerHTML: string;
	headerHTML: string;
}

/**
 * Return a new array without the Overflow 'more' menu with the assumption that the menu is always at the end of the array.
 * @param navItems The NavItem array that has an overflow 'more' menu object.
 */
export function removeOverflowMenu(navItems: NavItem[]) {
	return navItems.slice(0, navItems.length - 1);
}

const { userLocale } = msDocs.data;
export const docsGlobalHeader: UhfParseResult = {
	category: {
		kind: 'link',
		href: `/${userLocale}/`,
		title: 'Docs'
	},
	items: [
		{
			kind: 'link',
			href: `/${userLocale}/`,
			title: loc_documentation
		},
		{
			kind: 'link',
			href: `/${userLocale}/learn/`,
			title: 'Learn'
		},
		{
			kind: 'link',
			href: `/${userLocale}/answers/products/`,
			title: 'Q&A'
		},
		...((features.codeSamples
			? [
					{
						kind: 'link',
						href: `/${userLocale}/samples/browse/`,
						title: loc_codeSamples
					}
			  ]
			: []) as NavItem[]),
		{
			kind: 'menu',
			title: loc_more,
			items: []
		}
	],
	cookieBanner: null
};

export const docsDefaultHeader: UhfParseResult = {
	category: {
		kind: 'link',
		href: `/${userLocale}/`,
		title: loc_documentation
	},
	items: [
		{
			kind: 'link',
			href: `/${userLocale}/windows/`,
			title: loc_products_windows
		},
		{
			kind: 'link',
			href: `/${userLocale}/azure/`,
			title: loc_products_azure
		},
		{
			kind: 'link',
			href: `/${userLocale}/visualstudio/products/`,
			title: loc_products_vs
		},
		{
			kind: 'link',
			href: `/${userLocale}/office/`,
			title: loc_products_office
		},
		{
			kind: 'link',
			href: `/${userLocale}/microsoft-365/`,
			title: loc_products_m365
		},
		{
			kind: 'link',
			href: `/${userLocale}/dotnet/`,
			title: loc_products_dotnet
		},
		{
			kind: 'menu',
			title: loc_more,
			items: [
				{
					kind: 'link',
					href: `/${userLocale}/aspnet/`,
					title: loc_products_aspnet
				},
				{
					kind: 'link',
					href: `/${userLocale}/sql/`,
					title: loc_products_sql_server
				},
				{
					kind: 'link',
					href: `/${userLocale}/enterprise-mobility-security/`,
					title: loc_products_m365_ems
				},
				{
					kind: 'link',
					href: `/${userLocale}/surface/`,
					title: 'Microsoft Surface'
				},
				{
					kind: 'link',
					href: `/${userLocale}/dynamics365/`,
					title: loc_products_dynamics
				},
				{
					kind: 'link',
					href: `/${userLocale}/xamarin/`,
					title: loc_products_xamarin
				},
				{
					kind: 'link',
					href: `/${userLocale}/azure/devops/`,
					title: loc_products_azure_devops
				},
				{
					kind: 'link',
					href: `/${userLocale}/system-center/`,
					title: 'System Center'
				},
				{
					kind: 'link',
					href: `/${userLocale}/powershell/`,
					title: 'PowerShell'
				},
				{
					kind: 'link',
					href: `/${userLocale}/graph/`,
					title: loc_products_ms_graph
				},
				{
					kind: 'link',
					href: `/${userLocale}/education/`,
					title: loc_microsoftEducation
				}
			]
		},
		{
			kind: 'menu',
			title: loc_more,
			items: []
		}
	],
	cookieBanner: null
};

export const assessmentsHeader: UhfParseResult = {
	category: {
		kind: 'link',
		href: `/${userLocale}/assessments/`,
		title: html`${loc_assessments} <span class="tag is-small is-uppercase">${loc_preview}</span>`
	},
	items: [
		{
			kind: 'menu',
			title: loc_availableAssessments,
			items: [
				{
					kind: 'link',
					href: `/${userLocale}/assessments/?id=azure-architecture-review`,
					title: loc_assessment_azureArchitectureReview
				},
				{
					kind: 'link',
					href: `/${userLocale}/assessments/?id=cloud-journey-tracker`,
					title: loc_assessment_cloudJourneyTracker
				},
				{
					kind: 'link',
					href: 'https://www.cafbaseline.com/',
					title: loc_assessment_governanceBenchmark
				},
				{
					kind: 'link',
					href: `/${userLocale}/assessments/?id=Strategic-Migration-Assessment`,
					title: loc_assessment_azureStrategicMigrationAssessment
				}
			]
		},
		{
			kind: 'link',
			href: `/${userLocale}/assessments/support/`,
			title: loc_faqHelp
		},
		{
			kind: 'menu',
			title: loc_more,
			items: []
		}
	],
	callToAction: {
		primary: {
			kind: 'link',
			href: `https://azure.microsoft.com/${userLocale}/free/?utm_source=docs.microsoft.com/assessments/`,
			title: loc_assessment_azureFreeTrial
		}
	},
	cookieBanner: null
};

export const learnHeader: UhfParseResult = {
	category: {
		kind: 'link',
		href: `/${userLocale}/learn/`,
		title: loc_learn
	},
	items: [
		{
			kind: 'menu',
			title: loc_products,
			items: [
				{
					kind: 'link',
					href: `/${userLocale}/learn/azure/`,
					title: loc_products_azure
				},
				{
					kind: 'link',
					href: `/${userLocale}/learn/dynamics365/`,
					title: loc_products_dynamics
				},
				{
					kind: 'link',
					href: `/${userLocale}/learn/m365/`,
					title: loc_products_m365
				},
				{
					kind: 'link',
					href: `/${userLocale}/learn/powerplatform/`,
					title: loc_products_power_platform
				},
				{
					kind: 'link',
					href: `/${userLocale}/learn/browse/`,
					title: loc_browseAllPaths
				}
			]
		},
		{
			kind: 'menu',
			title: loc_roles,
			items: [
				{
					kind: 'link',
					href: `/${userLocale}/learn/roles/administrator`,
					title: loc_roles_administrator
				},
				{
					kind: 'link',
					href: `/${userLocale}/learn/roles/ai-engineer`,
					title: loc_roles_ai_engineer
				},
				{
					kind: 'link',
					href: `/${userLocale}/learn/roles/app-maker`,
					title: loc_roles_maker
				},
				{
					kind: 'link',
					href: `/${userLocale}/learn/roles/business-user`,
					title: loc_roles_business_user
				},
				{
					kind: 'link',
					href: `/${userLocale}/learn/roles/data-analyst`,
					title: loc_roles_data_analyst
				},
				{
					kind: 'link',
					href: `/${userLocale}/learn/roles/data-engineer`,
					title: loc_roles_data_engineer
				},
				{
					kind: 'link',
					href: `/${userLocale}/learn/roles/data-scientist`,
					title: loc_roles_data_scientist
				},
				{
					kind: 'link',
					href: `/${userLocale}/learn/roles/developer`,
					title: loc_roles_developer
				},
				{
					kind: 'link',
					href: `/${userLocale}/learn/roles/devops-engineer`,
					title: loc_roles_devops_engineer
				},
				{
					kind: 'link',
					href: `/${userLocale}/learn/roles/functional-consultant`,
					title: loc_roles_functional_consultant
				},
				{
					kind: 'link',
					href: `/${userLocale}/learn/roles/security-engineer`,
					title: loc_roles_security_engineer
				},
				{
					kind: 'link',
					href: `/${userLocale}/learn/roles/solutions-architect`,
					title: loc_roles_solution_architect
				},
				{
					kind: 'link',
					href: `/${userLocale}/learn/roles/student`,
					title: loc_roles_student
				}
			]
		},
		{
			kind: 'link',
			href: `/${userLocale}/learn/tv/`,
			title: 'Learn TV'
		},
		{
			kind: 'menu',
			title: loc_certifications,
			items: [
				{
					kind: 'link',
					href: `/${userLocale}/learn/certifications/`,
					title: loc_overview
				},
				{
					kind: 'link',
					href: `/${userLocale}/learn/certifications/browse/`,
					title: loc_browseCertifications
				},
				{
					kind: 'link',
					href: `/${userLocale}/learn/certifications/courses/browse/`,
					title: loc_browseCourses
				}
			]
		},
		{
			kind: 'link',
			href: `/${userLocale}/learn/support/`,
			title: loc_faqHelp
		},
		{
			kind: 'menu',
			title: loc_more,
			items: []
		}
	],
	cookieBanner: null
};

export const developerHeader: UhfParseResult = {
	category: {
		kind: 'link',
		href: `/${userLocale}/`,
		title: 'Developer'
	},
	items: [
		{
			kind: 'link',
			href: `https://docs.microsoft.com/${userLocale}/`,
			title: loc_documentation
		},
		{
			kind: 'link',
			href: `https://docs.microsoft.com/${userLocale}/learn/`,
			title: 'Learn'
		},
		{
			kind: 'link',
			href: `https://docs.microsoft.com/${userLocale}/answers/products/`,
			title: 'Q&A'
		},
		{
			kind: 'link',
			href: 'https://channel9.msdn.com/',
			title: loc_videos
		},
		{
			kind: 'menu',
			title: loc_more,
			items: []
		}
	],
	cookieBanner: null
};

export const advocatesHeader: UhfParseResult = {
	category: {
		kind: 'link',
		href: `/${userLocale}/advocates/`,
		title: loc_advocates
	},
	items: [
		{
			kind: 'link',
			href: `/${userLocale}/advocates/map`,
			title: loc_mapView
		},
		{
			kind: 'link',
			href: `/${userLocale}/advocates/tweets`,
			title: loc_tweets
		},
		{
			kind: 'menu',
			title: loc_more,
			items: []
		}
	],
	cookieBanner: null
};

export const regionalDirectorsHeader: UhfParseResult = {
	category: {
		kind: 'link',
		href: `/${userLocale}/`,
		title: loc_regionalDirectors
	},
	items: [
		{
			kind: 'link',
			href: `/${userLocale}/map`,
			title: loc_mapView
		},
		{
			kind: 'link',
			href: `/${userLocale}/about/`,
			title: loc_about
		},
		{
			kind: 'menu',
			title: loc_more,
			items: []
		}
	],
	cookieBanner: null
};

const tocNodes = tocJson.data as StyleGuideTocNode[];
export const styleGuideHeader: UhfParseResult = {
	category: {
		kind: 'link',
		title: 'Docs Style Guide',
		href: './index.html'
	},
	items: tocNodes.map(tocNodeToNavItem),
	callToAction: {
		primary: {
			kind: 'link',
			title: 'Visit Microsoft Docs',
			href: 'https://docs.microsoft.com/en-us/'
		}
	},
	cookieBanner: null
};

styleGuideHeader.items.push({ kind: 'menu', title: 'More', items: [] });

function tocNodeToNavItem({ title, href, children }: StyleGuideTocNode): NavItem {
	if (children) {
		return { kind: 'menu', title, items: children.map(tocNodeToNavItem) };
	}
	return { kind: 'link', title, href };
}

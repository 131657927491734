import { loc_disclaimer_learnMobileInteractive } from '@msdocs/strings';
import { Module } from '../apis/learn';
import { showDisclaimer } from '../disclaimer';

/**
 * Given a module determines if a disclaimer should be shown.
 * Disclaimer indicates that some units have interactivity that
 * Isn't available on mobile.
 * @param modules An array of Learn modules
 */
export function checkForInteractiveDisclaimer(modules: Module[]) {
	for (const module of modules) {
		for (const unit of module.units) {
			if (
				unit.interactive !== null &&
				unit.interactive !== undefined &&
				unit.interactive !== 'lab-on-demand'
			) {
				showDisclaimer(loc_disclaimer_learnMobileInteractive, null, 'is-hidden-tablet');
				return;
			}
		}
	}
}

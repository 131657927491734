import { apis } from '../environment/apis';
import { features } from '../environment/features';
import { msDocs } from '../globals';
import { loadLibrary } from '../load-library';
import { parseQueryString, updateQueryString } from '../query-string';
import { escapeRegExp } from '../text-formatting';

const BING_MAPS_KEY = 'Apoz1_I8r9NMGHKv2saSMyFUvQaECEpRw9TVqq3RZajMaMMsmaj3NRK-jkiOabRt'; //store properly later

let profiles: Profiles.Profile[];
let activeProfiles: Profiles.Profile[];
let filteredProfiles: Profiles.Profile[];
let infobox: Microsoft.Maps.Infobox;
let clusterLayer: Microsoft.Maps.ClusterLayer;
let map: Microsoft.Maps.Map;
let center: Microsoft.Maps.Location;
let filterInput: HTMLInputElement;

// see docs PushpinOptions Object:  https://msdn.microsoft.com/en-us/library/mt712673.aspx
const pushpinOptions = {
	color: '#d73924'
} as Microsoft.Maps.IPushpinOptions;

export async function setupMap() {
	const enableMap = await features.bingMaps;
	if (!enableMap) {
		return;
	}

	const loadMapScenario = new Promise(resolve => {
		(window as any).loadMapScenario = resolve;
	});

	Promise.all([
		loadMapScenario,
		loadLibrary(`${apis.bingMaps}?key=${BING_MAPS_KEY}&callback=loadMapScenario`, 'Microsoft')
	]).then(() => {
		profiles = activeProfiles = msDocs.data.profileList;
		center = new Microsoft.Maps.Location(35.433847, -75.133743);
		filterInput = document.getElementById('filter-list-map') as HTMLInputElement;

		const queryStringValue = parseQueryString().filter;

		// Filter profiles by deleting invalid location data and only include profile with valid location data
		profiles = profiles.map(ensureValidLocation).filter(p => p.location !== undefined);

		if (queryStringValue !== undefined && queryStringValue !== '') {
			filteredProfiles = searchFilterProfiles(queryStringValue, profiles);
			filterInput.value = queryStringValue;
		} else {
			filteredProfiles = profiles;
		}

		const mapConfig = {
			/*
			 * Docs on this object: https://msdn.microsoft.com/en-us/library/mt712646.aspx
			 * Also, no need to set credentials if already passed in URL
			 */
			credentials: '',
			center,
			zoom: 3,
			minZoom: 3,
			maxZoom: 6,
			disableStreetside: true
		} as Microsoft.Maps.IMapLoadOptions;

		map = new Microsoft.Maps.Map(document.getElementById('map'), mapConfig);
		infobox = new Microsoft.Maps.Infobox(map.getCenter(), {
			visible: false,
			showCloseButton: false
		});
		infobox.setMap(map);

		updateClusterLayer(filteredProfiles, createCustomClusteredPin);

		Microsoft.Maps.Events.addHandler(map, 'click', function () {
			infobox.setOptions({
				visible: false
			});
		});

		filterInput.addEventListener('input', handleMapSearch);
		filterInput.addEventListener('change', handleMapSearch);
		filterInput.addEventListener('keydown', handleInfoboxVisiblity);

		let timeout = 0;

		function handleMapSearch(): void {
			// debounce search a bit
			clearTimeout(timeout);

			timeout = setTimeout(() => {
				map.layers.remove(clusterLayer);
				filteredProfiles = searchFilterProfiles(escapeRegExp(filterInput.value), profiles);
				updateClusterLayer(filteredProfiles, createCustomClusteredPin);
				updateQueryString({ filter: filterInput.value }, 'replaceState');
			}, 300);
		}

		function handleInfoboxVisiblity(): void {
			if (infobox.getVisible()) {
				infobox.setOptions({
					visible: false
				});
			}
		}
	});
}

function createPushpins(profileList: Profiles.Profile[]): Microsoft.Maps.Pushpin[] {
	let pushpins = [];
	activeProfiles = [];

	if (!profileList) {
		activeProfiles = profiles;
		return [];
	}

	if (profileList.length === 1) {
		const profile = profileList[0];
		activeProfiles.push(profileList[0]);
		const {
			location: { lat, long }
		} = profile;

		if (lat && long) {
			const pushpin = new Microsoft.Maps.Pushpin(
				new Microsoft.Maps.Location(lat, long),
				pushpinOptions
			);
			Microsoft.Maps.Events.addHandler(pushpin, 'click', pushpinClicked);
			pushpins = [pushpin];
		}
	} else {
		pushpins = profileList.reduce((pins, profile): Microsoft.Maps.Pushpin[] => {
			const {
				location: { lat, long }
			} = profile;

			if (lat && long) {
				activeProfiles.push(profile);
				const pushpin = new Microsoft.Maps.Pushpin(
					new Microsoft.Maps.Location(lat, long),
					pushpinOptions
				);
				Microsoft.Maps.Events.addHandler(pushpin, 'click', pushpinClicked);
				pins.push(pushpin);
			}

			return pins;
		}, []);
	}

	return pushpins;
}

function pushpinClicked(e: Event) {
	showInfobox((e as any).target);
}

function showInfobox(pin: Microsoft.Maps.Pushpin | Microsoft.Maps.ClusterPushpin) {
	let listItems = '';
	let numOfPins;

	if ('containedPushpins' in pin && pin.containedPushpins !== undefined) {
		// deal with multiple pins
		listItems = pinsToInfoboxHtml(pin.containedPushpins, activeProfiles);
	} else {
		listItems = pinsToInfoboxHtml([pin], activeProfiles);
	}

	const htmlContent = `<div class="map-infobox">
							<ul class="map-ul">
								${listItems}
							</ul>
						</div>`;

	const infoboxOffset = getInfoboxOffset(numOfPins);

	infobox.setOptions({
		location: pin.getLocation(),
		htmlContent,
		visible: true,
		offset: new Microsoft.Maps.Point(24, infoboxOffset)
	});

	function pinsToInfoboxHtml(
		pushpins: Microsoft.Maps.Pushpin[],
		profiles: Profiles.Profile[]
	): string {
		const htmlDict: { [name: string]: string } = {};
		let html = '';
		numOfPins = pushpins.length;

		pushpins.forEach(pin => {
			const location = pin.getLocation();

			profiles.forEach(profile => {
				if (
					location.latitude === profile.location.lat &&
					location.longitude === profile.location.long
				) {
					if (!htmlDict[`${profile.name}`]) {
						htmlDict[`${profile.name}`] = profile.html;
					}
				}
			});
		});

		for (const key in htmlDict) {
			html += htmlDict[key];
		}

		return html;
	}

	function getInfoboxOffset(numOfPins: number) {
		let offsetY = 42.5;

		if (numOfPins > 4) {
			numOfPins = 4;
		}

		offsetY = (offsetY + (numOfPins + 1)) * numOfPins;
		return -offsetY;
	}
}

export function buildHtmlProfiles(profiles: Profiles.Profile[]) {
	return profiles.map(profile => {
		const href =
			profile.uid.split('.').length > 1
				? `./${String(profile.uid.split('.')[1])}` // advocates.persons-name --> '/persons-name'
				: `./${String(profile.uid.split('.')[0])}`;

		profile.location.display = checkForUndefined(profile.location.display);
		profile.twitter = profile.twitter
			? `<p><a class="twitter" href="http://twitter.com/${profile.twitter}">${profile.twitter}</a></p>`
			: '';
		profile.tagline = profile.tagline ? `<p class="tagline">${profile.tagline}</p>` : '';

		profile.html = `
			<li class="map-profile-component">
				<a href="${href}" title="${profile.name}">
					<img class="profile-list-image" src="${profile.image.src}" alt="${profile.image.alt}">
				</a>
				<div class="profile-text">
					<a href="${href}" title="${profile.name}">
						<h3>${profile.name}</h3>
					</a>
					${profile.twitter}
					${profile.tagline}
				</div>
			</li>`;

		profile.searchText = `${profile.name} ${profile.twitter} ${profile.tagline} ${profile.location.display}`;

		return profile;
	}, {});
}

export function searchFilterProfiles(
	searchTerm: string,
	arr: Profiles.Profile[]
): Profiles.Profile[] {
	const placeholder = document.querySelector('.no-results') as HTMLLIElement;
	const regex = new RegExp(searchTerm, 'gi');

	placeholder.hidden = true;

	const filtered = arr.filter(profile => {
		if (profile.searchText.match(regex)) {
			return profile;
		}
		return false;
	});

	if (filtered.length === 0) {
		placeholder.hidden = false;
		return [];
	} else {
		return filtered;
	}
}

function updateClusterLayer(
	profileList: Profiles.Profile[],
	clusteredPinCallback: (cluster: Microsoft.Maps.ClusterPushpin) => void
) {
	Microsoft.Maps.loadModule('Microsoft.Maps.Clustering', function () {
		const pins = createPushpins(profileList);

		clusterLayer = new Microsoft.Maps.ClusterLayer(pins, {
			clusteredPinCallback,
			gridSize: 120
		});

		const locations = clusterLayer.getPushpins().map(pin => pin.getLocation());
		const rect = Microsoft.Maps.LocationRect.fromLocations(locations);

		map.setView({
			bounds: rect,
			zoom: 12,
			center
		});

		map.layers.insert(clusterLayer);

		if (locations.length === 1) {
			showInfobox(clusterLayer.getPushpins()[0]);
		}
	});
}

function createCustomClusteredPin(cluster: Microsoft.Maps.ClusterPushpin): void {
	cluster.setOptions(pushpinOptions);
	Microsoft.Maps.Events.addHandler(cluster, 'click', pushpinClicked);
}

function checkForUndefined(value: any) {
	if (value === undefined) {
		return '';
	} else {
		return value;
	}
}

/**
 * In the case that one of either lat or long is incorrect, we do not include that profile's location date,
 * since it will break the map.
 * @param profile A RD or CDA profile.
 */
function ensureValidLocation(profile: Profiles.Profile) {
	if (!profile.location) {
		delete profile.location;
		return profile;
	}
	const { lat, long } = profile.location;

	if (isNaN(lat) || isNaN(long) || Math.abs(lat) > 90 || Math.abs(long) > 180) {
		delete profile.location;
	}

	return profile;
}

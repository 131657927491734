import { loc_more } from '@msdocs/strings';
import { deepClone } from '../../clone-object';
import { EventBus } from '../../event-bus';
import { PropertyChangedEvent } from '../../view-model';
import type { NavItem, NavMenu } from './model';

export class NavBarViewModel extends EventBus {
	private _items: NavItem[] = [{ title: loc_more, kind: 'menu', items: [] }];
	private _mobile = false;
	private _searchExpanded = false;
	private _desktop = false;
	private _mobileNavExpanded = false;

	constructor() {
		super();
	}

	public get items() {
		return this._items;
	}
	public set items(items) {
		this._items = items && items.length ? items : [{ title: loc_more, kind: 'menu', items: [] }];
		// The "more" menu is empty. Fill it with top level items, not including itself.
		this.more.items = deepClone(this._items.slice(0, this._items.length - 1));

		this.resetItemVisibility();
		this.notifyPropertyChanged();
	}

	public get mobile() {
		return this._mobile;
	}
	public set mobile(value) {
		this._mobile = value;
		this._mobileNavExpanded = false;
		this.notifyPropertyChanged();
	}

	/**
	 * Boolean property for screen width matching >= 960px.
	 */
	public get desktop() {
		return this._desktop;
	}
	public set desktop(value) {
		this._desktop = value;
		this._searchExpanded = false;
		this.notifyPropertyChanged();
	}

	public get searchExpanded() {
		return this._searchExpanded;
	}
	public set searchExpanded(value) {
		this._searchExpanded = value;
		this.notifyPropertyChanged();
	}

	public get mobileNavExpanded() {
		return this._mobileNavExpanded;
	}
	public set mobileNavExpanded(value) {
		this._mobileNavExpanded = value;
		this._searchExpanded = false;
		this.notifyPropertyChanged();
	}

	public get more(): NavMenu {
		// Assume the "more" menu is the last item.
		const length = this._items.length;
		return length ? (this._items[length - 1] as NavMenu) : null;
	}

	public resetItemVisibility() {
		if (this.items.length === 0) {
			return;
		}
		this.items.forEach(x => (x.hidden = false));
		this.more.hidden = true;
		this.more.items.forEach(x => (x.hidden = true));
	}

	private notifyPropertyChanged() {
		this.publish(new PropertyChangedEvent());
	}
}

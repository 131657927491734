import { assessmentApi } from '../apis/assessments';
import { authStatusDetermined } from '../auth/index';
import { user } from '../auth/user';
import {
	Assessment,
	AssessmentResponseListItem,
	AssessmentSearchParams,
	AssessmentSession,
	Guidance
} from './types';

let currentAssessment: Assessment;
let currentAssessmentList: AssessmentResponseListItem[];

export let currentBranchList: string[];

export async function getCurrentAssessment(
	id: string,
	version?: string,
	branch?: string | null,
	locale?: string | null
): Promise<Assessment> {
	if (
		currentAssessment &&
		currentAssessment.id === id &&
		currentAssessment.branch === branch &&
		currentAssessment.locale === locale
	) {
		return currentAssessment;
	}

	currentAssessment = await assessmentApi.getAssessmentById(id, version, branch, locale);
	return currentAssessment;
}

export async function getCurrentAssessmentList(
	branch: string | null
): Promise<AssessmentResponseListItem[]> {
	currentAssessmentList = await assessmentApi.getAssessmentList(branch);
	return currentAssessmentList;
}

export async function getAssessmentSessionList(
	branch: string | null
): Promise<AssessmentSession[]> {
	await authStatusDetermined;
	return user.isAuthenticated
		? assessmentApi.getSessionList(branch)
		: new Promise(resolve => resolve([]));
}

/**
 * A function to try and re-try getting guidance. This acts as protection agains a race condition.
 * Race conditions: session is saved as complete. We immediately ask for guidance by id. Sometimes that guidance isn't ready yet and we get an error code saying the session isn't complete.
 * Retry three times and then give up.
 * @param query The navigation functions query
 * @param tries The number of times we've tried before the first call of this function.
 * @param maxTries The maximum number of times this function can be called recursively.
 */
export async function tryGetGuidanceById(
	query: AssessmentSearchParams,
	tries: number = 0,
	maxTries: number = 3
): Promise<Guidance | { errorCode: string }> {
	const response = await assessmentApi.getGuidanceBySessionId(query.session);
	if (
		response &&
		response.errorCode &&
		response.errorCode === 'guidance_on_incomplete_session' &&
		tries < maxTries
	) {
		return await new Promise(resolve => {
			setTimeout(async () => {
				const guidance = await tryGetGuidanceById(query, tries++);
				resolve(guidance);
			}, 1000);
		});
	}
	return response;
}

export async function getBranches() {
	if (currentBranchList) {
		return currentBranchList;
	}

	const response = await assessmentApi.getBranches();

	if (!response) {
		return [];
	}
	currentBranchList = response;
	return response;
}

import { AssessmentViewModel } from './view-model';
import { Question, MatrixQuestion, Category } from './types';
import { updateUrlSearchFromMap } from '../query-string';
import { loc_assessmentProgressLabelFormat } from '@msdocs/strings';
import { hasQuestionBeenFullyAnswered } from './utilities';
import { TreeNodeAccessor } from '../components/tree';
import { html, render, TemplateResult } from '../lit-html';

export interface AssessmentNavTreeNode {
	isCategory: boolean;
	id: string;
	title: string;
	children?: AssessmentNavTreeNode[];
	isSelected: boolean;
	isAnswered?: boolean;
	isRequired: boolean;
	href?: URL;
}

const questionClasses = (node: AssessmentNavTreeNode): string => {
	if (node.isAnswered) {
		return 'docon-check has-text-success';
	}
	if (node.isRequired) {
		return 'docon-asterisk-solid has-text-danger';
	}
	return 'docon-location-circle';
};

const questionNodeTemplate = (node: AssessmentNavTreeNode): TemplateResult => {
	// TODO: Handle Matrix Questions (they don't have a title)
	return html`<span class="icon docon ${questionClasses(node)}" aria-hidden="true"></span
		><span>${node.title || node.id}</span>`;
};

export class AssessmentNavTreeNodeAccessor implements TreeNodeAccessor<AssessmentNavTreeNode> {
	public hasChildren(node: AssessmentNavTreeNode): boolean {
		return !!node.children;
	}

	public children(node: AssessmentNavTreeNode): AssessmentNavTreeNode[] | undefined {
		return node.children;
	}

	public htmlTitle(node: AssessmentNavTreeNode): string {
		// Categories are title
		if (node.isCategory) {
			return node.title;
		}

		// Questions are docon + title
		const title = document.createElement('span');
		render(questionNodeTemplate(node), title);
		return title.innerHTML;
	}

	public textTitle(node: AssessmentNavTreeNode): string {
		return node.title || node.id;
	}

	public href(node: AssessmentNavTreeNode): string {
		return node.href.toString();
	}

	public isNewSection(_: AssessmentNavTreeNode): boolean {
		return false; // Not using section breaks
	}

	public isExpanded(node: AssessmentNavTreeNode): boolean {
		return !!node.children;
	}

	public isSelected(node: AssessmentNavTreeNode): boolean {
		return node.isSelected;
	}

	public setHtmlAttributes(
		_: AssessmentNavTreeNode,
		__: (name: string, value: string) => void
	): void {}
}

export class AssessmentSideNavQuestionProgress {
	readonly progressLabel: string;
	readonly questionsAnswered: number;
	readonly questionsTotal: number;
	readonly questionsProgress: number;

	constructor(viewModel: AssessmentViewModel) {
		this.questionsAnswered = viewModel.questionsAnswered;
		this.questionsTotal = viewModel.questionTotalInSelectedCategories;
		this.questionsProgress =
			this.questionsTotal === 0
				? 0
				: Math.round((this.questionsAnswered / this.questionsTotal) * 100);
		this.progressLabel = loc_assessmentProgressLabelFormat
			.replace('{question}', this.questionsAnswered.toString())
			.replace('{questionsTotal}', this.questionsTotal.toString());
	}
}

export class AssessmentSideNavHeader {
	readonly assessmentTitle: string;
	readonly sessionName: string;
	readonly sessionUrl: URL;
	readonly questionsProgress: AssessmentSideNavQuestionProgress;

	constructor(viewModel: AssessmentViewModel) {
		this.assessmentTitle = viewModel.assessment.title;
		this.sessionName = viewModel.session.name;
		this.sessionUrl = updateUrlSearchFromMap({
			mode: 'pre-assessment',
			session: viewModel.session.id,
			id: null,
			category: null,
			question: null
		});
		this.questionsProgress = new AssessmentSideNavQuestionProgress(viewModel);
	}
}

export class AssessmentSideNavTree {
	readonly treeNodes: AssessmentNavTreeNode[];

	constructor(viewModel: AssessmentViewModel) {
		this.treeNodes = viewModel.assessment.categories
			.filter(category => category.isRequired || viewModel.session.categoriesSelected[category.id])
			.map(category => this.createCategoryNavTreeNode(category, viewModel));
	}

	createCategoryNavTreeNode(
		category: Category,
		viewModel: AssessmentViewModel
	): AssessmentNavTreeNode {
		return {
			isCategory: true,
			id: category.id,
			title: category.title,
			children: category.questions.map(question =>
				this.createQuestionNavTreeNode(question, category, viewModel)
			),
			isSelected: !viewModel.isPreAssessment && viewModel.currentCategory.id === category.id,
			isRequired: category.isRequired
		};
	}

	createQuestionNavTreeNode(
		question: Question | MatrixQuestion,
		category: Category,
		viewModel: AssessmentViewModel
	): AssessmentNavTreeNode {
		return {
			isCategory: false,
			id: question.id,
			title: question.title,
			isAnswered: hasQuestionBeenFullyAnswered(category.id, question, viewModel.session.responses),
			isSelected:
				!viewModel.isPreAssessment &&
				viewModel.currentCategory.id === category.id &&
				viewModel.currentQuestion.id === question.id,
			isRequired: question.isRequired,
			href: updateUrlSearchFromMap({
				mode: 'questionnaire',
				session: viewModel.session.id !== 'local' ? viewModel.session.id : null,
				id: null,
				question: question.id,
				category: category.id
			})
		};
	}
}

export class AssessmentSideNav {
	readonly navHeader: AssessmentSideNavHeader;
	readonly navTree: AssessmentSideNavTree;

	constructor(viewModel: AssessmentViewModel) {
		this.navHeader = new AssessmentSideNavHeader(viewModel);
		this.navTree = new AssessmentSideNavTree(viewModel);
	}
}

/**
 * Normalize toc.json data.
 */
import { NormalizedTocResponse, TocMetadata, TocNode, TocNodeBreadcrumb } from './types';
import { walk } from './walk';

/**
 * Locates the "items" in toc.json data.
 * This ensures the front-end is compatible with legacy toc.json
 * formats.
 */
export function normalizeItems(data: any): any[] {
	if (Array.isArray(data)) {
		return data;
	}
	if (Array.isArray(data.items)) {
		return data.items;
	}
	return [];
}

/**
 * Extracts the TOC and metadata components from toc flavored toc.json data.
 */
export function normalizeTocResponse(data: any): NormalizedTocResponse {
	const nodes: TocNode[] = normalizeItems(data);
	const metadata: TocMetadata = data.metadata || nodes[0] || {};
	return { metadata, nodes };
}

/**
 * Extracts the breadcrumb nodes from breadcrumb flavored toc.json data.
 */
export function normalizeBreadcrumbResponse(data: any): TocNodeBreadcrumb[] {
	return normalizeItems(data);
}

/**
 * Strip &lrm; html entities from toc_title.
 */
export function removeLrm(nodes: TocNode[] | TocNodeBreadcrumb[], baseUrl: string) {
	if (!/\/(?:ar-sa|he-il)\//.test(baseUrl)) {
		return;
	}
	walk(nodes, node => {
		node.toc_title = node.toc_title.replace('&lrm;', '');
		return true;
	});
}

import {
	loc_clearform,
	loc_feedbackReport,
	loc_feedbackReport_attachment,
	loc_feedbackReport_attachmentAction,
	loc_feedbackReport_attachmentAllowed,
	loc_feedbackReport_categoryContentMistake,
	loc_feedbackReport_categoryFeatureRequest,
	loc_feedbackReport_categoryFunctionalityOther,
	loc_feedbackReport_categoryGeneral,
	loc_feedbackReport_categoryLearnContentOther,
	loc_feedbackReport_categoryLearnContentQuestion,
	loc_feedbackReport_categoryLearnCreditProblem,
	loc_feedbackReport_categoryOther,
	loc_feedbackReport_categoryRegistrationProblem,
	loc_feedbackReport_categorySandboxProblem,
	loc_feedbackReport_categoryTranslationQuality,
	loc_feedbackReport_describeYourFeedback,
	loc_feedbackReport_feedbackTitle,
	loc_feedbackReport_optionCloudShellProblem,
	loc_feedbackReport_optionEditorProblem,
	loc_feedbackReport_optionProblemWithInstall,
	loc_feedbackReport_optionTutorialContentQuality,
	loc_feedbackReport_selectAnIssueThatAppliesToYou,
	loc_feedbackReport_selectTypeOfFeedback,
	loc_somethingWentWrongPleaseTryAgain,
	loc_submit,
	loc_submitAnotherFeedback,
	loc_successfullySubmittedFeedback
} from '@msdocs/strings';
import { html, render, TemplateResult } from '../lit-html';

export type FeedbackReportType = 'reportIssue' | 'tutorialFeedback';

const categoryNames = {
	// Normal feedback
	// Category
	'functionality-other': loc_feedbackReport_categoryFunctionalityOther,
	'learn-content-other': loc_feedbackReport_categoryLearnContentOther,
	'feature-request': loc_feedbackReport_categoryFeatureRequest,
	'category-general': loc_feedbackReport_categoryGeneral,
	'tutorial-content-quality': loc_feedbackReport_optionTutorialContentQuality,
	// SubCategory for functionality-other
	'learn-credit-problem': loc_feedbackReport_categoryLearnCreditProblem,
	'sandbox-problem': loc_feedbackReport_categorySandboxProblem,
	'registration-problem': loc_feedbackReport_categoryRegistrationProblem,
	'problem-with-cloudshell': loc_feedbackReport_optionCloudShellProblem,
	'problem-with-install': loc_feedbackReport_optionProblemWithInstall,
	'problem-with-dotnet-editor': loc_feedbackReport_optionEditorProblem,
	// SubCategory for learn-content-other
	'content-mistake': loc_feedbackReport_categoryContentMistake,
	'content-question': loc_feedbackReport_categoryLearnContentQuestion,
	'translation-quality': loc_feedbackReport_categoryTranslationQuality,
	// SubCategory for both functionality-other and learn-content-other
	'category-other': loc_feedbackReport_categoryOther

	//Tutorial feedback - add later
};

interface FeedbackReport {
	type: FeedbackReportType;
	source: string;
	orgId: string;
	categories: FeedbackCategory[];
}

interface FeedbackCategory {
	icon: string;
	value: string;
	id?: string;
	subCategories?: FeedbackSubCategory[];
	text: string;
}

interface FeedbackSubCategory {
	value: string;
	text: string;
}

const reportIssueCategories: FeedbackCategory[] = [
	{
		icon: 'docon-developer-tools',
		value: 'Microsoft Learn/Customer Feedback/Engineering',
		id: 'feedback-report-category-product',
		subCategories: [
			{
				value: 'Microsoft Learn/Customer Feedback/Engineering/Gamification',
				text: categoryNames['learn-credit-problem']
			},
			{
				value: 'Microsoft Learn/Customer Feedback/Engineering/Interactivity',
				text: categoryNames['sandbox-problem']
			},
			{
				value: 'Microsoft Learn/Customer Feedback/Engineering/Profile',
				text: categoryNames['registration-problem']
			},
			{
				value: 'Microsoft Learn/Customer Feedback/Engineering',
				text: categoryNames['category-other']
			}
		],
		text: categoryNames['functionality-other']
	},
	{
		icon: 'docon-text-document',
		value: 'Microsoft Learn/Customer Feedback/Content',
		id: 'feedback-report-category-content',
		subCategories: [
			{
				value: 'Microsoft Learn/Customer Feedback/Content/{{REPO-AREA}}',
				text: categoryNames['content-mistake']
			},
			{
				value: 'Microsoft Learn/Customer Feedback/Content/{{REPO-AREA}}',
				text: categoryNames['content-question']
			},
			{
				value: 'Microsoft Learn/Customer Feedback/Localization/{{REPO-AREA}}',
				text: categoryNames['translation-quality']
			},
			{
				value: 'Microsoft Learn/Customer Feedback/Content',
				text: categoryNames['category-other']
			}
		],
		text: categoryNames['learn-content-other']
	},
	{
		icon: 'docon-web-components',
		value: 'Microsoft Learn/Customer Feedback/Engineering/Experience',
		text: categoryNames['feature-request']
	},
	{
		icon: 'docon-feedback',
		value: 'Microsoft Learn/Customer Feedback',
		text: categoryNames['category-general']
	}
];

const reportIssueFeedbackReport: FeedbackReport = {
	type: 'reportIssue',
	source: 'Report an issue',
	orgId: '0842495d64db4f69a49eaab7007ce057',
	categories: reportIssueCategories
};

const tutorialFeedbackCategories: FeedbackCategory[] = [
	{
		icon: 'docon-developer-tools',
		value: 'Tutorial Feedback/Customer Feedback/Engineering',
		id: 'feedback-report-category-product',
		subCategories: [
			{
				value: 'Tutorial Feedback/Customer Feedback/Engineering/Interactivity',
				text: categoryNames['problem-with-cloudshell']
			},
			{
				value: 'Tutorial Feedback/Customer Feedback/Engineering/Interactivity',
				text: categoryNames['problem-with-install']
			},
			{
				value: 'Tutorial Feedback/Customer Feedback/Engineering/Interactivity',
				text: categoryNames['problem-with-dotnet-editor']
			},
			{
				value: 'Tutorial Feedback/Customer Feedback/Engineering/Profile',
				text: categoryNames['registration-problem']
			},
			{
				value: 'Tutorial Feedback/Customer Feedback/Engineering',
				text: categoryNames['category-other']
			}
		],
		text: categoryNames['functionality-other']
	},
	{
		icon: 'docon-text-document',
		value: 'Tutorial Feedback/Customer Feedback/Content',
		id: 'feedback-report-category-content',
		subCategories: [
			{
				value: 'Tutorial Feedback/Customer Feedback/Content/{{REPO-AREA}}',
				text: categoryNames['content-mistake']
			},
			{
				value: 'Tutorial Feedback/Customer Feedback/Content/{{REPO-AREA}}',
				text: categoryNames['content-question']
			},
			{
				value: 'Tutorial Feedback/Customer Feedback/Localization/{{REPO-AREA}}',
				text: categoryNames['translation-quality']
			},
			{
				value: 'Tutorial Feedback/Customer Feedback/Content',
				text: categoryNames['category-other']
			}
		],
		text: categoryNames['tutorial-content-quality']
	},
	{
		icon: 'docon-web-components',
		value: 'Tutorial Feedback/Customer Feedback/Engineering/Experience',
		text: categoryNames['feature-request']
	},
	{
		icon: 'docon-feedback',
		value: 'Tutorial Feedback/Customer Feedback',
		text: categoryNames['category-general']
	}
];

const tutorialFeedbackReport: FeedbackReport = {
	type: 'tutorialFeedback',
	source: 'Report an issue',
	orgId: '0842495d64db4f69a49eaab7007ce057',
	categories: tutorialFeedbackCategories
};

export function renderFeedbackReport(container: HTMLElement, type: FeedbackReportType) {
	const feedbackReport = getFeedbackReport(type);
	const feedbackHtml = createFeedbackReportTemplate(feedbackReport);

	render(feedbackHtml, container);
}

function getFeedbackReport(type: FeedbackReportType): FeedbackReport {
	if (type === 'reportIssue') {
		return reportIssueFeedbackReport;
	}
	return tutorialFeedbackReport;
}

function createFeedbackReportTemplate(feedbackReport: FeedbackReport): TemplateResult {
	return html`
		<h2 id="report-feedback" class="title is-2 has-margin-top-medium">${loc_feedbackReport}</h2>
		<form id="feedback-report-form" method="POST">
			<fieldset id="feedback-type-selection">
				<div class="field">
					<span class="title is-5">
						${loc_feedbackReport_selectTypeOfFeedback}
						<span class="required-indicator" aria-hidden="true"></span>
					</span>
					<div class="control radios has-images" id="feedback-report-category">
						${feedbackReport.categories.map(
							category => html`<label class="radio is-image" for="${category.value}">
								<span class="radio-image icon" aria-hidden="true">
									<span class="radio-image-icon docon ${category.icon}"></span>
								</span>
								<input
									type="radio"
									name="feedback-report-category"
									id="${category.value}"
									value="${category.value}"
									data-sub-category="${category.subCategories ? category.id : ''}"
								/>
								<span class="radio-background"></span>
								<span class="radio-dot" aria-hidden="true"></span>
								<span class="radio-label-text">${category.text}</span>
							</label>`
						)}
					</div>
				</div>
				${feedbackReport.categories
					.filter(category => category.subCategories !== undefined)
					.map(
						category => html` <div class="field" hidden aria-live="polite">
							<span class="title is-5">
								${loc_feedbackReport_selectAnIssueThatAppliesToYou}
								<span class="required-indicator" aria-hidden="true"></span>
							</span>
							<div class="control">
								<div class="radios is-vertical" id="${category.id}" disabled aria-required="true">
									<label class="label" aria-hidden="true"></label>
									${category.subCategories.map(
										subCategory => html` <label class="radio">
											<input
												name="question-2"
												type="radio"
												value="${subCategory.value}"
												aria-labelledby="${category.id}"
											/>
											<span class="radio-dot"></span>
											<span class="radio-label-text">${subCategory.text}</span>
										</label>`
									)}
								</div>
							</div>
						</div>`
					)}
				<input type="hidden" name="category" value="" />
				<input type="hidden" name="feedbackCategory" value="" />
				<input type="hidden" name="issueType" value="" />
			</fieldset>
			<fieldset hidden id="feedback-form-info">
				<div class="field">
					<label class="label" for="feedback-report-feedbackTitle">
						${loc_feedbackReport_feedbackTitle}
						<span class="required-indicator" aria-hidden="true"></span>
					</label>
					<div class="control">
						<input
							class="input"
							type="text"
							id="feedback-report-feedbackTitle"
							name="summary"
							aria-required="true"
							value=""
							required
						/>
					</div>
				</div>
				<div class="field">
					<label class="label" for="feedback-report-description">
						${loc_feedbackReport_describeYourFeedback}
						<span class="required-indicator" aria-hidden="true"></span>
					</label>
					<div class="control">
						<textarea
							class="textarea"
							id="feedback-report-description"
							name="description"
							aria-required="true"
							required
						></textarea>
					</div>
				</div>
				<label class="label" for="feedback-report-attachment"
					>${loc_feedbackReport_attachment}</label
				>
				<div class="file has-name is-fullwidth">
					<label class="file-label">
						<input
							class="file-input"
							type="file"
							id="feedback-report-attachment"
							name="attachment"
							aria-required="false"
							accept=".jpeg, .jpg, .png, .gif"
							multiple="true"
						/>
						<span class="file-cta">
							<span class="file-icon">
								<span class="docon docon-upload"></span>
							</span>
							<span class="file-label">${loc_feedbackReport_attachmentAction}</span>
						</span>
						<span
							aria-controls="filename"
							aria-live="polite"
							class="file-name"
							id="feedback-report-attachment-file-name"
						></span>
					</label>
				</div>
				<div class="help has-margin-bottom-large">${loc_feedbackReport_attachmentAllowed}</div>
				<div hidden id="attachment-validation" role="alert" aria-live="polite"></div>
			</fieldset>
			<input type="hidden" name="contentLocale" value="" />
			<input type="hidden" name="userLocale" value="" />
			<input type="hidden" name="jsllImpressionGuid" value="" />
			<input type="hidden" name="aiSessionId" value="" />
			<input type="hidden" name="documentId" value="" />
			<input type="hidden" name="versionIndependentDocumentId" value="" />
			<input type="hidden" name="organizationId" value="${feedbackReport.orgId}" />
			<input type="hidden" name="uid" value="" />
			<input type="hidden" name="contentPath" value="" />
			<input type="hidden" name="url" value="" />
			<input type="hidden" name="feedbackType" value="${feedbackReport.type}" />
			<input type="hidden" name="feedbackSource" value="${feedbackReport.source}" />
			<p
				hidden
				id="feedback-report-error-message"
				class="has-text-danger has-margin-bottom-large"
				aria-live="assertive"
				aria-relevant="text"
			>
				${loc_somethingWentWrongPleaseTryAgain}
			</p>
			<p
				hidden
				id="feedback-report-success-message"
				class="has-text-success has-margin-bottom-large"
				aria-live="polite"
				aria-relevant="text"
			>
				${loc_successfullySubmittedFeedback}
			</p>
			<div class="buttons">
				<button class="button is-primary" type="submit" disabled>${loc_submit}</button>
				<button class="button is-text has-text-primary" id="clear-form-button" type="reset">
					${loc_clearform}
				</button>
				<button
					id="submit-another-feedback-button"
					class="button is-primary is-hidden"
					type="button"
				>
					${loc_submitAnotherFeedback}
				</button>
			</div>
		</form>
	`;
}

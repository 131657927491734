import { reportRestFetchComplete } from '../rest-tryit/bi';
import { SecurityStrategy } from '../rest-tryit/security';
import { RestTryItRequest, RestTryItResponse } from '../rest-tryit/types';

export function restRequest(
	tryitRequest: RestTryItRequest,
	security: SecurityStrategy,
	init?: RequestInit
): Request {
	init = init || {};
	init.mode = 'cors';
	init.cache = 'reload';

	init.method = tryitRequest.httpVerb;

	if (tryitRequest.body) {
		init.body = tryitRequest.body;
	}

	const url = tryitRequest.url;

	const request = security.transform(new Request(url, init), tryitRequest);

	if (tryitRequest.headers !== null) {
		tryitRequest.headers.forEach(item => {
			if (item.value !== '') {
				request.headers.set(item.name, item.value);
			}
		});
	}

	return request;
}

export function restFetch(request: Request): Promise<Response> {
	return fetch(request).then(response => {
		reportRestFetchComplete(request, response);

		return response;
	});
}

export function createRestTryitResponse(response: Response): Promise<RestTryItResponse> {
	const tryitResponse = {} as RestTryItResponse;
	tryitResponse.statusCode = response.status.toString();
	const headers = response.headers;
	tryitResponse.header = '';
	headers.forEach((value, key) => {
		tryitResponse.header += `${key}: ${value}\n`;
	});

	if (/application\/json/i.test(response.headers.get('Content-Type'))) {
		return response.json().then(data => {
			tryitResponse.body = JSON.stringify(data, null, 2);
			return tryitResponse;
		});
	} else {
		return response.text().then(text => {
			tryitResponse.body = text;
			return tryitResponse;
		});
	}
}

import { getMeta } from './meta';

export function handleProtocolRendering(containers: HTMLElement[]) {
	if (getMeta('protocol_rendering') !== 'true') {
		return;
	}

	containers.forEach(styleProtocolTables);
}

export function styleProtocolTables(container: HTMLElement) {
	const tables = Array.from(container.querySelectorAll('table'));

	tables.forEach(table => table.classList.add('protocol-table'));
}

import { EventBus } from '../../event-bus';
import { scrollTo } from '../../scroll-to';
import { pagingControlTemplate } from './template';

export class PageControlChangeEvent {
	constructor(public page: number) {}
}

export class PagingControl extends EventBus {
	private currentPage: number = 1;
	private lastPage: number = 1;

	constructor(
		private element: HTMLElement,
		currentPage: number,
		lastPage?: number,
		private options?: PagingControlOptions
	) {
		super();
		this.currentPage = Math.max(currentPage, 1);
		this.lastPage = lastPage ? Math.max(lastPage) : this.lastPage;

		element.addEventListener('click', event => {
			const target = (event.target as HTMLElement).closest('[data-page]');
			if (!target || isNaN(parseInt(target.getAttribute('data-page')))) {
				return;
			}

			event.preventDefault();
			const pageNumber = parseInt(target.getAttribute('data-page'));
			const pageEvent = new PageControlChangeEvent(pageNumber);
			this.publish(pageEvent);
		});

		this.render();
	}

	public get state() {
		return {
			currentPage: this.currentPage,
			lastPage: this.lastPage
		};
	}

	public update({ currentPage, lastPage = Infinity }: { currentPage: number; lastPage: number }) {
		this.currentPage = currentPage;
		this.lastPage = lastPage;

		// Before we refresh the DOM, check to see if focus is inside the component. If it is, instead of losing focus
		// we want to try to restore it. If getFocus() returns a valid selector, try to refocus that element if it exists;
		// otherwise, focus on the aria-current page element.
		const selector = this.getFocus();
		this.render();
		this.setFocus(selector);

		// Bad separation of concerns with scrollTo inside the paging control. Workaround. -jeahyoun 20200401
		if (this.options && this.options.doNotScroll) {
			return;
		}

		scrollTo(0, 0, window);
	}

	private render() {
		this.element.innerHTML = pagingControlTemplate(this.currentPage, this.lastPage);
	}

	/**
	 * Before we re-render the component, check to see if the browser's focus is set inside of the element.
	 * If it is, return the selector for closest link that we can to the active element.
	 */
	private getFocus() {
		if (this.element.contains(document.activeElement)) {
			const anchor = document.activeElement.closest('a');
			if (anchor) {
				const direction = anchor.getAttribute('rel');
				const page = anchor.getAttribute('data-page');
				return direction ? `a[rel='${direction}']` : `a[data-page='${page}']:not([rel])`;
			}
		}

		return '';
	}

	/**
	 * Set focus to a specific element inside of the paging control component.
	 * @param selector The querySelector value for which to search.
	 */
	private setFocus(selector: string) {
		if (selector) {
			const target =
				this.element.querySelector<HTMLElement>(selector) ||
				this.element.querySelector<HTMLElement>('[aria-current]');

			target.focus();
		}
	}
}

export interface PagingControlOptions {
	doNotScroll: boolean;
}

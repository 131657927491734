import { msDocs } from '../globals';
import { getMeta } from '../meta';

export const context = {
	repo: msDocs.data.feedbackGitHubRepo,
	documentId: getMeta('document_id') || btoa(location.pathname),
	versionIndependentDocumentId:
		getMeta('document_version_independent_id') || btoa(location.pathname),
	documentSourceUrl:
		msDocs.data.contentGitUrl ||
		getMeta('original_content_git_url') ||
		getMeta('original_ref_skeleton_git_url') ||
		'',
	service: getMeta('ms.service'),
	subservice: getMeta('ms.subservice'),
	product: getMeta('ms.prod'),
	technology: getMeta('ms.technology'),
	author: getMeta('author'),
	msAuthor: getMeta('ms.author'),
	contentTitle: getMeta('og:title') || document.title
};

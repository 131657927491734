import { notifyContentUpdated } from '../affix';
import { showDisclaimer } from '../disclaimer';
import { loc_monikerFallback } from '@msdocs/strings';
import { parseQueryString } from '../query-string';
import { escape } from '../text-formatting';
import { findPackageInFamily, getFamily } from './family';

let fallbackDisclaimer: HTMLDivElement;

/**
 * Displays an alert when the "viewFallbackFrom" query string argument is present.
 */
export function displayMonikerFallbackMessage() {
	removeMonikerFallbackMessage();

	const fallbackFromMoniker = escape(parseQueryString().viewFallbackFrom);
	if (fallbackFromMoniker === undefined) {
		return Promise.resolve();
	}

	return getFamily()
		.then(
			family => {
				const pkg = findPackageInFamily(family, fallbackFromMoniker);
				return pkg ? pkg.displayName : fallbackFromMoniker;
			},
			() => fallbackFromMoniker
		)
		.then(displayName => {
			fallbackDisclaimer = showDisclaimer(loc_monikerFallback.replace('{moniker}', displayName));
		});
}

export function removeMonikerFallbackMessage() {
	if (fallbackDisclaimer) {
		fallbackDisclaimer.parentElement.removeChild(fallbackDisclaimer);
		fallbackDisclaimer = null;
		notifyContentUpdated();
	}
}

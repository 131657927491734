import { loc_close, loc_completeModuleMessage } from '@msdocs/strings';

export function createCompletionDropdown(): HTMLElement {
	const outerContainer = document.createElement('div');
	outerContainer.classList.add('level-item');
	const container = document.createElement('div');
	outerContainer.appendChild(container);
	outerContainer.classList.add('dropdown', 'has-caret');
	outerContainer.setAttribute('data-bi-name', 'rating-popover'); // old bi-name kept to ensure reports are not broken.

	const completionDropDownHtml = `
		<div class="dropdown-menu has-width-250 has-text-wrap has-line-height-reset has-padding-medium is-size-small has-body-background-medium" id="complete-dropdown" role="tooltip">
			<div>
				<p class="has-margin-none has-padding-none">${loc_completeModuleMessage}</p>
			</div>
			<button class="dropdown-menu-close delete is-text is-absolute has-top-zero has-right-zero has-margin-extra-small has-inner-focus" aria-label="${loc_close}"></button>
		</div>`;

	outerContainer.innerHTML = completionDropDownHtml;

	return outerContainer;
}

import { loc_searchAllAcrossMicrosoftDocs, loc_searchPreviousVersions } from '@msdocs/strings';
import { html } from '../../../lit-html';
import { SearchViewModel } from '../view-model';
import { facetSearchFormTemplate } from './form';

export function blankSlateSearchTemplate<TResult>(vm: SearchViewModel<TResult>) {
	const title =
		vm.dataSource !== 'previousVersions'
			? loc_searchAllAcrossMicrosoftDocs
			: loc_searchPreviousVersions;
	return html` <div class="columns is-centered" data-bi-name="zero-state-search">
			<div class="column is-8">
				<h1 class="title is-2 is-text-centered-tablet">
					${title}
				</h1>
				${facetSearchFormTemplate(vm)}
			</div>
		</div>
		<div class="has-padding-top-medium">
			<figure class="image">
				<img src="/en-us/media/illustrations/Docs-Overworld-Map.png?branch=master" alt="" />
			</figure>
		</div>`;
}

import { loc_search, loc_searchPreviousVersions } from '@msdocs/strings';
import { getBranch } from '../../../apis/learn';
import { AutocompleteSuggestion } from '../../../apis/site-search';
import { checkIsArchived } from '../../../archive';
import { siteSearchFilterOptions } from '../filter-options';
import { FacetSearchResponse } from '../model';
import { getRssUrl } from '../rss';
import { SearchVMConfig, UISearchArgs } from '../view-model';
import { apis } from '../../../environment/apis';
import { traits } from '../../../environment/traits';
import { fetchWithTimeout } from '../../../fetch';
import { msDocs } from '../../../globals';
import { isReview } from '../../../is-production';
import { html } from '../../../lit-html';
import { getMeta } from '../../../meta';
import { toQueryString } from '../../../query-string';
import { litBreakText, WordBreakFlags } from '../../../text-formatting';
import { getTemplateForHighlightedContent } from '../highlighting';

/**
 * Create configuration object for Site Search's FacetSearch implementation
 */
export function createUpdatedFacetSearchConfig(): SearchVMConfig<SiteSearchResult> {
	return {
		layout: 'list',
		autocomplete: {
			input: {
				id: 'site-search-input',
				type: 'search',
				name: 'search',
				placeholder: checkIsArchived() ? loc_searchPreviousVersions : loc_search,
				docon: 'search',
				isFullWidth: true
			},
			getSuggestions,
			itemTemplate: (suggestion: string) =>
				html`<span class="is-block is-size-small has-padding-left-small has-padding-right-small"
					>${suggestion}</span
				>`
		},
		fetch: fetchFacetSiteSearchResults,
		resultTemplate: (result, i) => html` <li class="has-margin-top-large">
			<h2 class="has-margin-none title is-3">
				<a aria-describedby="description.${i}" data-bi-name="searchItem.${i}" href="${result.url}">
					${result.title}
				</a>
			</h2>
			<blockquote class="has-margin-none is-size-small has-line-height-reset">
				<cite class="is-normal has-text-success">
					${getTemplateForHighlightedContent(result.displayUrl, (s: string) =>
						litBreakText(s, WordBreakFlags.Dot | WordBreakFlags.Slash)
					)}
				</cite>
				<p>${result.descriptions.map(desc => getTemplateForHighlightedContent(desc))}</p>
			</blockquote>
		</li>`,
		scoringProfile: getMeta('scoring_profile'),
		rss: getRssUrl,
		headingLevel: 'h1'
	};
}

/**
 * Site Search's function for autocomplete suggestions
 * @param term The term from the suggestion
 */
export async function getSuggestions(term: string) {
	const result = await fetchAutocompleteSuggestion(term);
	return result.suggestions;
}

/**
 * Site Search's function for autocomplete suggestions
 * @param term The term from the suggestion
 */
export async function fetchAutocompleteSuggestion(term: string): Promise<AutocompleteSuggestion> {
	const emptyObj: AutocompleteSuggestion = { query: term, suggestions: [] };
	if (!term) {
		return Promise.resolve(emptyObj);
	}

	const url = `${apis.siteSearch}/autocomplete?${toQueryString({
		locale: msDocs.data.userLocale,
		query: term
	})}`;
	const init: RequestInit = {
		mode: 'cors',
		credentials: isReview ? 'include' : 'omit'
	};

	const response = await fetchWithTimeout(url, init);
	if (response.ok) {
		return response.json();
	}

	return emptyObj;
}

/**
 * The result-fetching function for Site Search's FacetSearch implemention
 * @param args The state object from the view model.
 */
export async function fetchFacetSiteSearchResults(
	args: UISearchArgs
): Promise<FacetSearchResponse<SiteSearchResult>> {
	const query = uiArgsToSiteSearchArgs(args);
	const url = `${apis.siteSearch}?${toQueryString({ ...query })}`;

	const init: RequestInit = {
		mode: 'cors',
		credentials: isReview ? 'include' : 'omit'
	};

	const response = await fetchWithTimeout(url, init);
	if (response.ok) {
		return await response.json();
	}

	return null;
}

// The review search service is a separate API that errors if certain properties are added
const searchFiltersEnabled =
	traits.accessLevel === 'online' && !traits.reviewFeatures && !checkIsArchived();

/**
 * Adapt universal FacetSearch arguments to fit SiteSearch
 * @param args The arguments used to configure the FacetSearch view
 */
export function uiArgsToSiteSearchArgs(args: UISearchArgs): SiteSearchArgs {
	return {
		search: args.terms,
		scope: args.scope,
		locale: msDocs.data.userLocale,
		scoringprofile: searchFiltersEnabled && args.scoringProfile ? args.scoringProfile : null,
		$filter: searchFiltersEnabled ? siteSearchFilterOptions(args) : null,
		facet: searchFiltersEnabled ? ['category'] : null,
		$top: args.top ? args.top.toString() : null,
		branch: getBranch(),
		$skip: args.skip ? args.skip.toString() : null,
		dataSource: args.dataSource ? args.dataSource : null
	};
}

export interface SiteSearchHighlightedContent {
	content: string;
	hitHighlights: SiteSearchHitHighlight[];
}

export interface SiteSearchArgs {
	search: string;
	locale: string;
	scoringprofile: string;
	facet: string[];
	$skip: string;
	$top: string;
	branch: string;
	scope: string;
	$filter: string;
	dataSource: string;
}

interface SiteSearchResult {
	title: string;
	url: string;
	displayUrl: SiteSearchDisplayURL;
	description: string;
	descriptions: SiteSearchHighlightedContent[];
	lastUpdatedDate: Date;
	breadcrumbs: any[];
}

interface SiteSearchDisplayURL {
	content: string;
	hitHighlights: SiteSearchHitHighlight[];
}

interface SiteSearchHitHighlight {
	start: number;
	length: number;
}

import { getBranch } from '../../apis/learn';
import { CountryId } from '../../country-map';
import { fetchWithTimeout } from '../../fetch';
import { isProduction, isReview } from '../../is-production';

interface ExamPrice {
	countryCode: CountryId;
	amount?: number;
}

export interface Country {
	currencyCode: string;
	currencySymbol: string;
	countryCode: CountryId;
	message?: string;
}

interface Exam {
	examType: string;
	price?: ExamPrice; // Default price info including countryCode and amount, if exist, and no amount in availability array for that countryCode, we will use this default info
	availability: ExamPrice[];
}

export interface ExamsPricing {
	exams: Exam[];
	countries: Country[];
}

let examsPricing: Promise<ExamsPricing>;
/**
 * Loads the exam pricing with countries details. File is expected to be
 * at content repo's certifications eg: "/en-us/learn/certifications/exam-pricing.json"
 */
export function getExamsPricing(): Promise<ExamsPricing> {
	const branch = getBranch();

	if (examsPricing === undefined) {
		const url = `/en-us/${location.pathname.split('/')[2]}/certifications/exam-pricing.json${
			isProduction && !isReview ? '' : `?branch=${branch}`
		}`;

		examsPricing = fetchWithTimeout(url, { credentials: 'include', mode: 'cors' })
			.then(response => {
				return response.json();
			})
			.then(data => {
				return { exams: data.exams, countries: data.countries };
			});
	}

	return examsPricing;
}

export function getOrigin({ origin, protocol, hostname, port }: HTMLAnchorElement) {
	// IE doesn't support HTMLAnchorElement.origin
	if (origin) {
		return origin;
	}
	// IE reports empty string for the protocol and hostname properties
	// when href is set to a relative uri.
	if (protocol && hostname) {
		return `${protocol}//${hostname}${port && port !== '443' && port !== '80' ? ':' + port : ''}`; // port is added for tests
	}
	return location.origin;
}

import { flatten, StandardProgressResponse } from '../apis/learn';
import { user } from '../auth/user';
import { handleCertPathInteractivity } from '../components/hide-completed';
import { features } from '../environment/features';
import { msDocs } from '../globals';
import { renderLearnBreadcrumbs } from '../learn/breadcrumbs';
import { getUserProgressFromMetaTags, organizeLearnCards, renderLearnCards } from '../learn/cards';
import { updateExamCard, updateExamRetirementDate } from '../learn/certification/exam-card';
import { renderExamsPrices } from '../learn/certification/exam-pricing';
import { getExamsPricing } from '../learn/certification/get-pricing';
import { renderPrerequisiteOptionDescription } from '../learn/certification/prerequisite-card';
import { getItemSummariesFromMetaTags } from '../learn/summaries';
import { getMeta } from '../meta';

export async function certificationPage() {
	const mainContainer = document.getElementById('main');

	const [summaries, examsPricing] = await Promise.all([
		getItemSummariesFromMetaTags(),
		getExamsPricing()
	]);

	renderLearnBreadcrumbs(`${getMeta('og:title')}`);

	const userProgress: Promise<StandardProgressResponse> =
		user.isAuthenticated && features.gamification
			? getUserProgressFromMetaTags()
			: Promise.resolve([]);

	renderLearnCards(
		mainContainer,
		organizeLearnCards(flatten(summaries)),
		userProgress,
		msDocs.data.pageTemplate
	);

	renderExamsPrices(mainContainer, examsPricing);
	updateExamCard(mainContainer);
	updateExamRetirementDate(mainContainer);

	const requiredPrerequisites = mainContainer.querySelector<HTMLElement>('#requiredPrerequisites');
	if (requiredPrerequisites) {
		renderPrerequisiteOptionDescription(requiredPrerequisites);
	}
	const optionalPrerequisites = mainContainer.querySelector<HTMLElement>('#optionalPrerequisites');
	if (optionalPrerequisites) {
		renderPrerequisiteOptionDescription(optionalPrerequisites);
	}
	handleCertPathInteractivity();
}

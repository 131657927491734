import { loc_relatedCertification } from '@msdocs/strings';
import { html, render } from '../../lit-html';

export function renderRelatedCertificationDescription(container: HTMLElement) {
	const descElements = Array.from(
		container.querySelectorAll<HTMLElement>(`.related-certification-desc`)
	);
	descElements.forEach(element => {
		const descHtml = html`${loc_relatedCertification}`;
		render(descHtml, element);
	});
}

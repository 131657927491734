import {
	loc_back,
	loc_close,
	loc_next,
	loc_notSureYet,
	loc_onboardingExperience_confirmationPromptBrowseRecommendation,
	loc_onboardingExperience_confirmationPromptSignin,
	loc_onboardingExperience_confirmationPromptTitle,
	loc_onboardingExperience_levelPromptTitle,
	loc_onboardingExperience_productPromptTitle,
	loc_onboardingExperience_rolePromptSubText,
	loc_onboardingExperience_rolePromptTitle,
	loc_signIn,
	loc_thanks
} from '@msdocs/strings';
import { user } from '../auth/user';
import { msDocs } from '../globals';
import { filterProductsForContentExist } from './index';
import { html, TemplateResult } from '../lit-html';
import { InterestsFacetKey } from './model';
import { levelDescriptions, levelNames } from '../name-maps/level';
import { productFamilyNames } from '../name-maps/product-family';
import { roleNames } from '../name-maps/roles';

const createFacetMap = () => {
	const roles = Object.keys(roleNames)
		.map(role => [role, (roleNames as Record<string, string>)[role]])
		.sort(compareFunction);
	const products = Object.keys(productFamilyNames)
		.map(product => [product, productFamilyNames[product]])
		.sort(compareFunction);
	const levels: string[][] = [];
	levels.push(['beginner', levelNames.beginner]);
	levels.push(['intermediate', levelNames.intermediate]);
	levels.push(['advanced', levelNames.advanced]);

	return {
		roles,
		products,
		levels
	};
};

export const interestsFacetMap = createFacetMap();
export let filteredProductSlugs: Promise<string[]>;
export const refreshProductCache = (slugs: string[]) => {
	interestsFacetMap.products = interestsFacetMap.products.filter(
		facetMap => slugs.find(slug => facetMap[0] === slug) !== undefined
	);
};

export function initializeFilteredProductSlugs() {
	filteredProductSlugs = filterProductsForContentExist(Object.keys(productFamilyNames)).then(
		result => result
	);
}

export function createRolesOnboardingTemplate() {
	return createInterestSelectionTemplate({
		title: loc_onboardingExperience_rolePromptTitle,
		subTitle: loc_onboardingExperience_rolePromptSubText,
		facetKey: 'roles'
	});
}

export function createLevelsOnboardingTemplate() {
	return createInterestSelectionTemplate({
		title: loc_onboardingExperience_levelPromptTitle,
		facetKey: 'levels'
	});
}

export function createProductsOnboardingTemplate() {
	return createInterestSelectionTemplate({
		title: loc_onboardingExperience_productPromptTitle,
		facetKey: 'products'
	});
}

export function createConfirmationOnboardingTemplate() {
	return html` <div class="is-flex is-flex-column has-flex-align-items-center">
		<h1 class="title is-2 has-margin-top-large has-margin-bottom-large">
			${loc_thanks}
		</h1>
		<p
			class="has-text-weight-semibold title is-5 is-full-width has-text-centered has-text-subtle has-margin-bottom-large"
		>
			${loc_onboardingExperience_confirmationPromptTitle}
			${user.isAuthenticated ? loc_onboardingExperience_confirmationPromptSignin : ''}
		</p>
		<a
			href="#"
			autofocus
			class="docs-sign-in auth-status-determined not-authenticated button is-primary is-large has-margin-bottom-medium"
		>
			${loc_signIn}
		</a>
		<a class="is-primary has-margin-bottom-extra-large" href="#trending-section" autofocus
			>${loc_onboardingExperience_confirmationPromptBrowseRecommendation}</a
		>
	</div>`;
}

export function createModalTemplate(bodyTemplate: TemplateResult) {
	initializeFilteredProductSlugs();

	return html` <div class="modal-card-full">
		<div
			style="min-height: 751px;"
			class="columns has-body-background has-margin-none is-vertically-scrollable has-flex-justify-content-center-mobile"
		>
			<div
				aria-hidden="true"
				style="background-image: url('/en-us/media/learn/learner-interest-tablet.svg?branch=master'); background-color: #243A5E; min-height: 751px;"
				class="column is-3 is-hidden-mobile has-background-image has-z-index-one"
			></div>
			<div class="column is-9">
				<div class="modal-card-head has-padding-right-none-mobile has-padding-bottom-none">
					<button
						class="modal-close modal-card-close-button is-large"
						type="button"
						aria-label="${loc_close}"
					></button>
				</div>
				<div
					class="modal-slide-container has-padding-large has-padding-top-none has-margin-left-extra-large-tablet has-margin-right-extra-large-tablet"
				>
					<div class="modal-slide">
						${bodyTemplate}
					</div>
				</div>
			</div>
		</div>
	</div>`;
}

function compareFunction(first: string[], second: string[]) {
	// 0th index is the slug, 1th index is the display name
	return first[1].localeCompare(second[1]);
}

function createNavigationTemplate(facetKey: InterestsFacetKey) {
	const isLTR = msDocs.data.userDir === 'ltr';

	const backButton =
		facetKey === 'roles'
			? {
					tablet: html`
						<button
							class="button is-invisible-tablet"
							type="button"
							data-facet-id="${facetKey}"
							action="back"
						>
							${loc_back}
						</button>
					`
			  }
			: {
					mobile: html`
						<button
							class="button is-fullwidth is-small has-inner-focus"
							type="button"
							data-facet-id="${facetKey}"
							action="back"
						>
							<span>${loc_back}</span>
						</button>
					`,
					tablet: html`
						<button class="button" type="button" data-facet-id="${facetKey}" action="back">
							<span class="icon" aria-hidden="true">
								<span class="docon docon-arrow-${isLTR ? 'left' : 'right'}"></span>
							</span>
							<span>${loc_back}</span>
						</button>
					`
			  };

	return html`
		<div
			class="is-flex is-hidden-mobile has-flex-justify-content-space-between has-margin-bottom-large"
		>
			${backButton.tablet}
			<div class="buttons">
				<button
					class="button has-text-primary is-text"
					type="button"
					data-facet-id="${facetKey}"
					action="skip"
				>
					${loc_notSureYet}
				</button>
				<button class="button is-primary" type="button" data-facet-id="${facetKey}" action="next">
					<span>${loc_next}</span>
					<span class="icon" aria-hidden="true">
						<span class="docon docon-arrow-${isLTR ? 'right' : 'left'}"></span>
					</span>
				</button>
			</div>
		</div>
		<div class="buttons is-hidden-tablet">
			<button
				class="button is-fullwidth is-primary is-small has-inner-focus"
				type="button"
				data-facet-id="${facetKey}"
				action="next"
			>
				<span>${loc_next}</span>
			</button>
			${backButton.mobile}
			<button
				class="button has-text-primary is-text is-fullwidth is-small has-inner-focus"
				type="button"
				data-facet-id="${facetKey}"
				action="skip"
			>
				<span>${loc_notSureYet}</span>
			</button>
		</div>
	`;
}

function createInterestSelectionTemplate({ title, subTitle, facetKey }: any) {
	const subTitleTemplate = subTitle
		? html`
				<p
					class="has-text-weight-semibold title is-5 has-text-subtle has-padding-bottom-small has-margin-bottom-none"
				>
					${subTitle}
				</p>
		  `
		: '';

	return html`
		<h1
			id="modal-heading"
			class="title is-2 has-margin-top-large-tablet has-margin-bottom-large-tablet"
		>
			${title}
		</h1>
		${subTitleTemplate}
		<ul
			class="grid has-min-width-170-tablet-only is-3-desktop has-padding-top-medium has-flex-justify-content-center-mobile"
		>
			${generateFacetTemplate(facetKey)}
		</ul>
		<div class="has-margin-top-extra-large has-margin-top-large-tablet"></div>
		${createNavigationTemplate(facetKey)}
		<div class="alert is-hidden is-warning has-margin-bottom-medium is-flex">
			<span aria-hidden="true" class="icon is-left">
				<span class="icon docon docon-status-info-outline"></span>
			</span>
			<p id="error-message"></p>
		</div>
	`;
}

function generateFacetTemplate(interestKey: InterestsFacetKey): TemplateResult[] {
	const arrayOfFacets = interestsFacetMap[interestKey];
	const hasDescription = interestKey === 'levels';
	const lastIndex = arrayOfFacets.length - 1;

	return arrayOfFacets.map((facet, index) => {
		const facetKey = facet[0];
		const facetValue = facet[1];

		const descriptionTemplate = hasDescription
			? html`
					<p class="has-text-subtle is-size-extra-small has-line-height-reset has-margin-top-small">
						${levelDescriptions[facetKey]}
					</p>
			  `
			: '';

		const checkboxTemplate = html`
			<div class="checkbox-button ${hasDescription ? 'is-full-width' : ''}">
				<input type="checkbox" value=${facetValue} id="${facetKey}" name="${facetKey}" autofocus />
				<label class="checkbox-button-label" for="${facetKey}">
					<span class="checkbox-button-label-inner-text">${facetValue}</span>
				</label>
				<span class="checkbox-button-label-check" role="presentation"></span>
				<span class="checkbox-button-label-text" role="presentation">${facetValue}</span>
			</div>
		`;

		if (hasDescription) {
			return html`
				<li
					class="grid-item ${index === lastIndex
						? ''
						: 'has-padding-bottom-medium has-padding-bottom-none-tablet'}"
				>
					<div class="has-max-width-200-tablet is-flex-column has-flex-align-items-center">
						${checkboxTemplate} ${descriptionTemplate}
					</div>
				</li>
			`;
		} else {
			return html`<li class="grid-item">
				${checkboxTemplate}
			</li>`;
		}
	});
}

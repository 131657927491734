import { loc_feedbackPlaceholder } from '@msdocs/strings';
import { getCreateOnGitHubUrl } from './markdown';

export function initGitHubUrl(section: Element) {
	const createOnGitHubLinks = Array.from(
		section.querySelectorAll<HTMLAnchorElement>('.choose-feedback-type .github-link')
	);
	const createOnGitHubUrl = getCreateOnGitHubUrl('', `\n\n${loc_feedbackPlaceholder}\n`);
	createOnGitHubLinks.forEach(a => (a.href = createOnGitHubUrl));
}

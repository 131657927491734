import {
	loc_certificationLevelAssociateLabel,
	loc_certificationLevelExpertLabel,
	loc_certificationLevelFundamentalsLabel
} from '@msdocs/strings';

export async function certificationHomePage() {
	const levels = ['beginner', 'intermediate', 'advanced'] as string[];
	levels.forEach(level => {
		setCertificateLevelSuperTitle(level);
	});
}

function setCertificateLevelSuperTitle(level: string) {
	const stringContainers = Array.from(document.querySelectorAll<HTMLElement>(`p.${level}`));
	if (!stringContainers) {
		return;
	}
	stringContainers.forEach(container => {
		switch (level) {
			case 'beginner':
				container.innerText = loc_certificationLevelFundamentalsLabel;
				break;
			case 'intermediate':
				container.innerText = loc_certificationLevelAssociateLabel;
				break;
			case 'advanced':
				container.innerText = loc_certificationLevelExpertLabel;
				break;
		}
	});
}

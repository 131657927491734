import { cookieConsent } from './cookie-consent';
import { contentLoaded, document } from './globals';

Promise.all([cookieConsent, contentLoaded]).then(() => {
	const imageList = Array.from(
		document.querySelectorAll('.contributors img[data-src]')
	) as HTMLImageElement[];
	for (const image of imageList) {
		const newImage = new Image();

		newImage.onload = function () {
			image.src = newImage.src;
		};

		newImage.onerror = function () {
			const anchorEle = image.parentElement;
			const listEle = anchorEle.parentElement;

			if (anchorEle.tagName === 'a') {
				//chromeless does not use links and does not need a fallback

				// add title
				image.title = anchorEle.getAttribute('title');

				// remove anchor
				listEle.removeChild(anchorEle);
				listEle.appendChild(image);
			}
		};

		newImage.src = image.getAttribute('data-src');
	}
});

/**
 * Fired prior to the router handling a navigation event such as a link click or popstate.
 */
export class RouterBeforeNavigateEvent {
	constructor(public readonly url: URL) {}
}

/**
 * Fired after the router has navigated to a new page.
 */
export class RouterAfterNavigateEvent {
	constructor(
		public readonly title: string,
		public readonly url: URL,
		public readonly referrerUrl: URL
	) {}
}

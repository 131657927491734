import {
	loc_assessment,
	loc_assessments,
	loc_assessment_completedAssessmentWorkNotSaved,
	loc_assessment_noDoNotSave,
	loc_assessment_preparingYourAssessment,
	loc_assessment_removeThisAssessment,
	loc_assessment_saveYourPreviousAssessment,
	loc_assessment_weJustNeedAMomentToGetEverythingReady,
	loc_assessment_yesSignInAndSave,
	loc_availableAssessments,
	loc_close,
	loc_completed,
	loc_inProgress,
	loc_yourAssessments,
	loc_yourAssessmentsCompleteFormat,
	loc_yourAssessmentsInProgressAndCompleteFormat,
	loc_yourAssessmentsInProgressFormat
} from '@msdocs/strings';
import { html, render } from '../../lit-html';
import { assessmentApi } from '../../apis/assessments';
import { user } from '../../auth/user';
import { Modal } from '../../components/modal/modal';
import { isProduction, isReview } from '../../is-production';
import { updateUrlSearchFromMap } from '../../query-string';
import { router } from '../../router/router';
import { toLocaleDate } from '../../text-formatting';
import { createAssessmentDeleteSessionModal } from '../assessment-modal';
import { getCurrentAssessment } from '../model';
import { getLogicalResumeQuestion, responsesExist } from '../navigation';
import { getSessionLocal } from '../session';
import {
	assessmentAuthReferralKey,
	assessmentBranchKey,
	AssessmentResponseListItem,
	AssessmentSession,
	HomeNavigation
} from '../types';
import { getAssessmentTitleById } from '../utilities';

const createProgressFromStatus = (session: AssessmentSession) => {
	if (session.status !== 'completed') {
		return html`<span class="progress-label has-text-subtle">${loc_inProgress}</span>`;
	}

	// completed
	return html`
		<span class="progress-label"
			>${loc_completed} <span class="docon docon-check" aria-hidden="true"></span
		></span>
	`;
};

const createYourAssessmentsTemplate = async (action: HomeNavigation) => {
	if (!user.isAuthenticated || action.sessionList === undefined) {
		return html``;
	}

	const [previousSessions, assessmentSummaries] = await Promise.all([
		action.sessionList,
		action.assessmentList
	]);

	if (previousSessions.length < 1) {
		return html``;
	}

	const handleAssessmentResumeClick = async (session: Partial<AssessmentSession>) => {
		if (session.status === 'completed') {
			const guidanceUrl = updateUrlSearchFromMap({
				mode: 'guidance',
				session: user.isAuthenticated ? session.id : null,
				id: user.isAuthenticated ? null : session.assessmentId,
				category: null,
				question: null,
				[assessmentBranchKey]: session.branch && session.branch && isReview ? session.branch : null
			});

			await startAssessmentSessionByUrl(guidanceUrl);
			return;
		}
		const { categoryId, questionId } = getLogicalResumeQuestion(
			session,
			await getCurrentAssessment(
				session.assessmentId,
				session.version,
				session.branch,
				session.locale
			)
		);

		const mode = isQuestionnaire(session, questionId, categoryId)
			? 'questionnaire'
			: 'pre-assessment';

		const resumeQuestionaireUrl = updateUrlSearchFromMap({
			mode,
			session: user.isAuthenticated ? session.id : null,
			id: isQuestionnaire ? null : session.assessmentId,
			category: categoryId,
			question: questionId,
			[assessmentBranchKey]: session.branch && isReview ? session.branch : null
		});

		await startAssessmentSessionByUrl(resumeQuestionaireUrl);
	};

	const handleDeleteSessionButtonClick = async (event: MouseEvent) => {
		const deleteElement =
			event.target instanceof HTMLElement &&
			(event.target.closest('[data-assessment-session-deletion]') as HTMLButtonElement);
		if (!deleteElement) {
			return;
		}

		event.preventDefault();

		const deletedSessionElement = deleteElement.closest('[data-session-id]') as HTMLElement;
		const sessionId = deletedSessionElement.getAttribute('data-session-id');
		const assessmentTitleElement = deletedSessionElement.querySelector(
			'.card-content-title h3'
		) as HTMLElement;
		const confirmationModal = createAssessmentDeleteSessionModal(assessmentTitleElement.innerText);
		confirmationModal.show();

		const cancelButton = document.querySelector(
			'#delete-assessment-modal button[type=button].cancel-button'
		) as HTMLButtonElement;
		const deleteButton = document.querySelector(
			'#delete-assessment-modal button[type=submit]'
		) as HTMLButtonElement;

		deleteButton.onclick = async () => {
			deleteButton.classList.add('is-loading');
			deleteButton.disabled = true;
			cancelButton.disabled = true;

			try {
				await assessmentApi.deleteSessionById(sessionId);
			} finally {
				const updatedAction = {
					...action,
					sessionList: new Promise(resolve =>
						resolve(previousSessions.filter(session => session.id !== sessionId))
					)
				} as HomeNavigation;

				render(
					await createAssessmentsTemplate(updatedAction),
					document.getElementById('assessment-content-container')
				);
			}

			confirmationModal.hide();
		};
	};

	const getYourAssessmentDescription = () => {
		const yourAssessmentsInProgress = previousSessions.reduce((inProgress, session) => {
			if (session.status !== 'completed') {
				inProgress++;
			}
			return inProgress;
		}, 0);

		const yourAssessmentsCompleted = previousSessions.length - yourAssessmentsInProgress;
		let yourAssessmentDescription;
		const progressAssessmentString =
			yourAssessmentsInProgress === 1 ? loc_assessment : loc_assessments.toLowerCase();
		const completedAssessmentString =
			yourAssessmentsCompleted === 1 ? loc_assessment : loc_assessments.toLowerCase();
		if (yourAssessmentsInProgress === 0) {
			yourAssessmentDescription = loc_yourAssessmentsCompleteFormat;
		} else if (yourAssessmentsCompleted === 0) {
			yourAssessmentDescription = loc_yourAssessmentsInProgressFormat;
		} else {
			yourAssessmentDescription = loc_yourAssessmentsInProgressAndCompleteFormat;
		}
		yourAssessmentDescription = yourAssessmentDescription
			.replace('{progressAssessment}', progressAssessmentString)
			.replace('{completedAssessment}', completedAssessmentString)
			.replace('{completedNum}', yourAssessmentsCompleted.toString())
			.replace('{inProgressNum}', yourAssessmentsInProgress.toString());
		return yourAssessmentDescription;
	};
	const localeDateFormat = { year: 'numeric', month: '2-digit', day: '2-digit' };

	return html` <section id="your-assessment">
		<div class="uhf-container has-padding-top-large has-padding-bottom-large">
			<h2 class="title is-2 has-margin-bottom-small">${loc_yourAssessments}</h2>
			<p class="has-padding-bottom-large">${getYourAssessmentDescription()}</p>
			<ul class="grid">
				${previousSessions.map(
					session => html`
					<li class="grid-item">
						<article class="card" data-session-id="${session.id}">
							<div class="card-content">
								<p class="card-content-super-title">${getAssessmentTitleById(
									assessmentSummaries,
									session.assessmentId
								)}</p>
								<button class="card-content-title" @click=${async () => {
									handleAssessmentResumeClick(session);
								}}>
									<h3>${session.name}</h3>
								</button>
								<p class="card-content-description">
									${
										session.dateModified
											? toLocaleDate(new Date(session.dateModified), localeDateFormat)
											: toLocaleDate(new Date(session.dateCreated), localeDateFormat)
									}
								</p>
							</div>
							<div class="card-footer">
								<div class="card-footer-item">
									${createProgressFromStatus(session)}
								</div>
								<div class="card-footer-item">
									<div class="buttons">
										<button aria-label="${loc_assessment_removeThisAssessment}" class="button is-text has-text-primary is-small" data-assessment-session-deletion @click=${async (
						event: MouseEvent
					) => {
						await handleDeleteSessionButtonClick(event);
					}}>
											<span class="docon docon-delete" aria-hidden="true"></span>
										</button>
									</div>
								</div>
						</article>
					</li>
				`
				)}
			</ul>
		</div>
	</section>`;
};

const startNewSession = (summary: AssessmentResponseListItem) => {
	const handleAssessmentClick = async (summary: AssessmentResponseListItem) => {
		// special casing governance assessment for Ignite
		// https://ceapex.visualstudio.com/Engineering/_workitems/edit/135983
		if (summary.id === 'governance') {
			location.href = 'https://www.cafbaseline.com/';
			return;
		}

		const startUrl = updateUrlSearchFromMap({
			mode: 'pre-assessment',
			id: summary.id,
			[assessmentBranchKey]: summary.branch && isReview ? summary.branch : null
		});

		await startAssessmentSessionByUrl(startUrl);
	};

	return html` <button
		class="card-content-title"
		@click="${async () => handleAssessmentClick(summary)}"
	>
		<h3>${summary.title}</h3>
	</button>`;
};

const saveLocalSessionAndStart = (summary: AssessmentResponseListItem) => {
	return html` <button
		class="card-content-title"
		data-bi-name="${summary.id}-start"
		@click="${(e: MouseEvent) => {
			renderSignInModal(e, summary);
		}}"
	>
		<h3>${summary.title}</h3>
	</button>`;
};

const newSessionTemplate = (summary: AssessmentResponseListItem) =>
	user.isAuthenticated || !getSessionLocal()
		? startNewSession(summary)
		: saveLocalSessionAndStart(summary);

const createAvailableAssessmentsTemplate = async (action: HomeNavigation) => {
	const list = await action.assessmentList;

	return html` <section>
		<div class="uhf-container has-padding-top-large has-padding-bottom-large">
			<h2 class="title is-2">${loc_availableAssessments}</h2>
			<ul class="grid">
				${list.map((summary: AssessmentResponseListItem) => {
					const hiddenTag = summary.id.match(/^test/i)
						? html`<ul class="tags">
								<li class="tag is-small is-warning">Hidden</li>
						  </ul>`
						: html``;
					return isProduction && !isReview && summary.id.match(/^test/i)
						? html``
						: html`<li class="grid-item">
								<article class="card">
									<div class="card-content">
										${newSessionTemplate(summary)}
										<p class="card-content-description">
											${summary.description}
										</p>
										${hiddenTag}
									</div>
								</article>
						  </li>`;
				})}
			</ul>
		</div>
	</section>`;
};

export async function createAssessmentsTemplate(action: HomeNavigation) {
	return html`
		${await createYourAssessmentsTemplate(action)}
		${await createAvailableAssessmentsTemplate(action)}
	`;
}

function isQuestionnaire(
	session: Partial<AssessmentSession>,
	questionId: string,
	categoryId: string
) {
	return responsesExist(session) && questionId && categoryId;
}

function renderSignInModal(e: MouseEvent, summary: AssessmentResponseListItem) {
	e.preventDefault();

	// special casing governance assessment for Ignite
	// https://ceapex.visualstudio.com/Engineering/_workitems/edit/135983
	if (summary.id === 'governance') {
		location.href = 'https://www.cafbaseline.com/';
		return;
	}

	const startUrl = updateUrlSearchFromMap({
		mode: 'pre-assessment',
		id: summary.id
	});

	const signInUrl = updateUrlSearchFromMap({
		[assessmentAuthReferralKey]: 'true'
	});

	const modalContent = document.createElement('div');

	const signInModal = new Modal(modalContent);

	render(
		html`
			<div
				tabindex="-1"
				role="dialog"
				aria-modal="true"
				class="modal has-default-focus is-active"
				aria-labelledby="modal-heading"
			>
				<div class="modal-background modal-close"></div>
				<div class="modal-card modal-content">
					<div class="modal-card-head has-padding-none">
						<button
							class="modal-close modal-card-close-button is-large"
							aria-label="${loc_close}"
						></button>
					</div>
					<section class="modal-card-body has-padding-top-none has-padding-bottom-none">
						<h2 class="title is-size-2 has-margin-bottom-small">
							${loc_assessment_saveYourPreviousAssessment}
						</h2>
						<p>${loc_assessment_completedAssessmentWorkNotSaved}</p>
					</section>
					<div class="modal-card-foot">
						<div class="buttons">
							<a
								href="${signInUrl}"
								class="docs-sign-in button is-primary has-text-weight-semibold"
								data-modal-result="sign-in-save"
								autofocus
							>
								${loc_assessment_yesSignInAndSave}
							</a>
							<a
								href="${startUrl}"
								class="modal-close button has-text-weight-semibold"
								data-modal-result="no-sign-in-save"
								data-bi-name="${summary.id}-start"
							>
								${loc_assessment_noDoNotSave}
							</a>
						</div>
					</div>
				</div>
			</div>
		`,
		modalContent
	);

	signInModal.show();
}

let preparingAssessmentModal: Modal;

function renderPreparingAssessment(): Promise<void> {
	if (!preparingAssessmentModal) {
		const modalContent = document.createElement('div');
		preparingAssessmentModal = new Modal(modalContent);

		render(
			html` <div class="modal-card has-padding-extra-large">
				<section
					class="modal-card-body has-padding-top-none has-padding-bottom-none has-text-centered"
				>
					<div role="presentation">
						<span class="loader has-margin-auto-sides has-text-primary is-size-2"></span>
					</div>
					<div role="alert">
						<h2 class="title is-size-2 has-margin-bottom-small">
							${loc_assessment_preparingYourAssessment}
						</h2>
						<p>${loc_assessment_weJustNeedAMomentToGetEverythingReady}</p>
					</div>
				</section>
			</div>`,
			modalContent
		);
	}

	preparingAssessmentModal.show();

	// modal displays for a minimum of 1s
	return new Promise(resolve => setTimeout(resolve, 1000));
}

async function startAssessmentSessionByUrl(url: URL) {
	await Promise.all([router.goto(url, 'pushState'), renderPreparingAssessment()]).then(() => {
		preparingAssessmentModal.hide();
	});
}

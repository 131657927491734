import { features } from '../environment/features';
import { contentLoaded, document, msDocs } from '../globals';
import { checkAadConsent } from './aad-check';
import { initDisplayClasses } from './display-classes';
import { tryLoadUser } from './service';
import { initSignInLinks } from './sign-in-links';
import { refreshProfileFields, user, UserChangedEvent } from './user';

let resolveAuthStatus: () => void;

/**
 * A promise that resolves when we know whether the user is authenticated.
 */
export const authStatusDetermined = new Promise<void>(resolve => (resolveAuthStatus = resolve));

export function initAuth() {
	if (!features.userServices) {
		resolveAuthStatus();
		contentLoaded.then(() => {
			const signInLi = document.getElementById('docs-sign-in-container-item') as HTMLElement;
			if (signInLi) {
				signInLi.hidden = true;
			}
		});
		return;
	}

	initDisplayClasses(user, authStatusDetermined, document.documentElement);
	initSignInLinks(document.documentElement);

	user.subscribe(UserChangedEvent, async () => {
		await contentLoaded;
		refreshProfileFields(document.body, user);
	});

	tryLoadUser().then(resolveAuthStatus);

	authStatusDetermined.then(() => {
		if (user.userType !== 'standard') {
			checkAadConsent(user);
		}
	});
}

export function isSigninEnabled() {
	const restrictedBrands: { [brand: string]: true } = {
		mooncake: true,
		advocates: true,
		'regional-directors': true
	};

	return !restrictedBrands[msDocs.data.brand];
}

import { getCourseScheduling, IltCourseSchedule } from '../apis/course-scheduling';
import { EventBus } from '../event-bus';
import { msDocs } from '../globals';
import { PropertyChangedEvent } from '../view-model';
import { reportCourseFilterWithJsll } from './bi';
import { CourseSchedulingCriteria, SearchLocation } from './course-types';
import { getVisitorId } from './visitor-id';

const courseSchedulingPageSize = 5;

/**
 * View model for course list, only handling results view and pagination.
 */
export class CourseListViewModel extends EventBus {
	public readonly pageSize: number;
	public page: number;
	private _criteria: CourseSchedulingCriteria;
	private _items: IltCourseSchedule[];
	private _count: number;
	private _state: {
		busy: boolean;
		error: boolean;
	};

	public get count() {
		return this._count;
	}
	public get error() {
		return this._state.error;
	}
	public get criteria() {
		return Object.assign({}, this._criteria);
	}

	public get busy() {
		return this._state.busy;
	}
	public set busy(value: boolean) {
		if (this._state.busy === value) {
			return;
		}

		this._state.busy = value;
		this.notifyPropertyChanged();
	}

	public get filterApplied() {
		return this._criteria && (!!this._criteria.location || !!this._criteria.startDate);
	}

	public get items() {
		return this._items || [];
	}
	public set items(value: IltCourseSchedule[]) {
		this._items = value;
	}

	constructor() {
		super();
		this.pageSize = courseSchedulingPageSize;
		this._state = {
			busy: false,
			error: false
		};
	}

	/**
	 * Load the course data and set class state.
	 * This will update the search criteria sent to the API and reset the paging.
	 */
	public load = async (
		courseId: string,
		location?: SearchLocation,
		date?: Date,
		virtualOnly: boolean = false,
		visitorId: string = getVisitorId()
	) => {
		try {
			this.busy = true;
			this._criteria = {
				courseId,
				visitorId,
				location,
				virtualOnly,
				startDate: date,
				locale: msDocs.data.userLocale,
				top: this.pageSize,
				skip: 0
			};
			const { results, count } = await getCourseScheduling(this._criteria);
			this.items = results;
			this._count = count;
			this.page = 1;
			if (this.filterApplied) {
				reportCourseFilterWithJsll(this._criteria, count);
			}
			this._state.error = false;
		} catch {
			this._state.error = true;
		} finally {
			this.busy = false;
		}
	};

	/**
	 * Load existing course data by page. This does not change the search criteria.
	 * @param page The page of data to load.
	 */
	public async goToPage(page: number) {
		try {
			this.busy = true;
			this.page = page;
			this._criteria.skip = (page - 1) * this.pageSize;
			const { results, count } = await getCourseScheduling(this._criteria);
			this.items = results;
			this._count = count;
			this._state.error = false;
		} catch {
			this._state.error = true;
		} finally {
			this.busy = false;
		}
	}

	private notifyPropertyChanged() {
		this.publish(new PropertyChangedEvent());
	}
}

import { html, render, unsafeHTML } from '../lit-html';
import { TocNodeAccessor } from './toc-node-accessor';
import { TocNode, TocNodeBreadcrumb } from './types';
import { resolveUrl, SimpleUrl } from './url';
import { walk, WalkInstruction } from './walk';

/**
 * Search the breadcrumb data structure for a node whose pathname matches
 * the page's pathname, choosing the match with the deepest ancestry.
 */
export function getBreadcrumbsForUrl(
	breadcrumbs: TocNodeBreadcrumb[],
	url: SimpleUrl,
	breadcrumbJsonUrl: string
): TocNodeBreadcrumb[] {
	let match: TocNodeBreadcrumb;
	let matchAncestors: TocNodeBreadcrumb[];

	walk(breadcrumbs, (node, ancestors) => {
		// Does the node have the potential to go as deep as the current best-match?
		if (matchAncestors && !node.children && ancestors.length < matchAncestors.length) {
			return WalkInstruction.StepOut;
		}
		// Does the page's pathname doesn't start with the node's pathname?
		if (!node.url || !url.originAndPathnameStartsWith(node.url)) {
			return WalkInstruction.Continue;
		}
		// Is this a better match? (more ancestry)
		if (matchAncestors && matchAncestors.length > ancestors.length) {
			return WalkInstruction.Continue;
		}
		match = node;
		matchAncestors = ancestors.slice(0);
		return WalkInstruction.Continue;
	});

	if (!match) {
		return [];
	}

	return [...matchAncestors, match].map(breadcrumb => {
		// A breadcrumb anchor's href should be the breadcrumb node's "homepage" value, when available.
		// Lazily resolve the homepage URLs (as opposed to doing this for the entire breadcrumb array).
		const url = breadcrumb.homepage
			? resolveUrl(breadcrumb.homepage, breadcrumbJsonUrl)
			: breadcrumb.url;
		return { ...breadcrumb, url };
	});
}

/**
 * Extend the breadcrumbs with the toc node corresponding to the current
 * page, including the node's ancestors.
 */
export function extendBreadcrumbs(breadcrumbs: TocNodeBreadcrumb[], tocNodes: TocNode[]) {
	// remove duplicate toc nodes and any toc nodes with the same title as an existing breadcrumb.
	tocNodes = tocNodes.filter(
		(x, index) =>
			tocNodes.findIndex(y => y.toc_title === x.toc_title) === index &&
			!breadcrumbs.find(y => y.toc_title === x.toc_title)
	);

	breadcrumbs.push(
		...tocNodes.map(node => ({
			...node,
			// use the node's url if available, otherwise the url of the node's first child.
			url: node.url || !node.children ? node.url : node.children[0].url
		}))
	);
}

export function renderBreadcrumbs(
	container: Element,
	breadcrumbs: TocNodeBreadcrumb[],
	url: SimpleUrl,
	accessor: TocNodeAccessor
) {
	// if the crumb doesn't have a url OR the url matches the current page, render a span instead of an anchor
	render(
		html`
			${breadcrumbs.map(
				(bc, i) => html` <li
					itemprop="itemListElement"
					itemscope
					itemtype="http://schema.org/ListItem"
				>
					${!bc.url || bc.url.originAndPathnameEquals(url)
						? html` <span itemprop="name">
								${unsafeHTML(accessor.htmlTitle(bc))}
						  </span>`
						: html` <a itemprop="item" href="${accessor.href(bc)}">
								<span itemprop="name">
									${unsafeHTML(accessor.htmlTitle(bc))}
								</span>
						  </a>`}
					<meta itemprop="position" content="${i + 1}" />
				</li>`
			)}
		`,
		container
	);
}

import { fetchWithTimeout } from '../fetch';
import { isPPE, isReview } from '../is-production';

const prodServerUrl: string = 'https://docs.microsoft.com/learn/module-availability';
const reviewServerUrl: string = 'https://review.docs.microsoft.com/en-us/learn/module-availability';
const ppeServerUrl: string =
	'https://ppe.docs.microsoft.com/en-us/learn-sandbox/module-availability';

let moduleAvailability: Promise<ModuleAvailability>;

function getServerUrl(): string {
	if (isReview) {
		return reviewServerUrl;
	}
	if (isPPE) {
		return ppeServerUrl;
	}
	return prodServerUrl;
}

function getModuleAvailability() {
	if (moduleAvailability === undefined) {
		moduleAvailability = getModuleAvailabilityConfig();
	}
	return moduleAvailability;
}

function getModuleAvailabilityConfig(): Promise<ModuleAvailability> {
	const url = `${getServerUrl()}/index.json?branch=master`;

	return fetchWithTimeout(url).then(response => {
		if (response.ok) {
			return response.json();
		}
		if (response.status === 404) {
			return {
				disableAllSandboxes: false,
				disableAllLabs: false
			};
		}
		throw new Error(`Unexpected module availability response: ${response.status}`);
	});
}

async function isDisabled(moduleUid: string, type: 'lab' | 'sandbox') {
	const availability = await getModuleAvailability();
	const globalDisable =
		type === 'sandbox' ? availability.disableAllSandboxes : availability.disableAllLabs;
	const disabledSandboxes =
		type === 'sandbox' ? availability.disabledSandboxes : availability.disabledLabs;

	return globalDisable || (disabledSandboxes && disabledSandboxes.indexOf(moduleUid) > -1);
}

export async function isLabDisabled(moduleUid: string) {
	return isDisabled(moduleUid, 'lab');
}

export async function isSandboxDisabled(moduleUid: string) {
	return isDisabled(moduleUid, 'sandbox');
}

interface ModuleAvailability {
	disableAllLabs: boolean;
	disableAllSandboxes: boolean;
	disabledSandboxes: string[];
	disabledLabs: string[];
}

import {
	loc_description,
	loc_loadMoreResults,
	loc_name,
	loc_noResults,
	loc_noResultsRest
} from '@msdocs/strings';
import { siteSearchToApiSearch } from '../apis/api-browser';
import { contentAttrs } from '../bi';
import { fetchWithTimeout } from '../fetch';
import { document, msDocs } from '../globals';
import { getMoniker } from '../monikers/moniker';
import { platformId } from '../monikers/platform';
import { breakText, escape } from '../text-formatting';
import { platformConfig } from './platform-config';
import { html, render, unsafeHTML } from '../lit-html';

const config = platformConfig[platformId];

const containers: {
	container: HTMLDivElement;
	renderHeading: boolean;
}[] = [];

export function addResultsContainer(container: HTMLDivElement, renderHeading: boolean) {
	containers.push({ container, renderHeading });
}

export function renderResults(
	platform: Api.RefPlatform,
	results: Api.ApiItem[],
	moreUrl: string | null
) {
	document.documentElement.classList.add('api-search-has-results');

	for (const { container, renderHeading } of containers) {
		container.innerHTML = '';

		if (results.length === 0) {
			const noResultsMessage = platformId === 'rest' ? loc_noResultsRest : loc_noResults;

			container.insertAdjacentHTML(
				'afterbegin',
				`
				<div class="no-results">
					${noResultsMessage}
				</div>
			`
			);
			return;
		}

		if (renderHeading) {
			renderResultsHeading(container, platform);
		}

		const table = document.createElement('table');
		table.classList.add('table', 'api-search-results');
		table.setAttribute(contentAttrs.name, 'api-search-results');

		const thead = document.createElement('thead');
		table.appendChild(thead);
		const theadrow = document.createElement('tr');
		thead.appendChild(theadrow);

		let th = document.createElement('th');
		th.textContent = loc_name;
		theadrow.appendChild(th);

		th = document.createElement('th');
		th.textContent = loc_description;
		theadrow.appendChild(th);

		const tbody = document.createElement('tbody');
		table.appendChild(tbody);

		appendResultsToTable(tbody, results);

		container.appendChild(table);

		if (moreUrl && renderHeading) {
			const moreButton = document.createElement('button');
			moreButton.classList.add('more-button', 'button');
			moreButton.textContent = loc_loadMoreResults;
			moreButton.setAttribute(contentAttrs.name, 'api-browser-load-more-results');
			moreButton.addEventListener('click', () => {
				fetchWithTimeout(moreUrl)
					.then<Api.QueryResponse>(response => response.json())
					.then(result => {
						if (platform.platformId === 'rest') {
							result = siteSearchToApiSearch(result as any); // todo: clean this up
						}
						moreUrl = result['@nextLink'];
						if (moreUrl === undefined) {
							container.removeChild(moreButton);
						}
						appendResultsToTable(tbody, result.results);
					});
			});

			container.appendChild(moreButton);
		}
	}
}

function appendResultsToTable(tbody: HTMLElement, results: Api.ApiItem[]) {
	// search result descriptions may contain html entities.
	const parser = document.createElement('div');
	const toText = (text: string) => {
		render(html`${text}`, parser);
		return parser.textContent !== 'null' ? parser.textContent : ''; // must check for null because of Edge
	};

	const moniker = getMoniker();
	for (const result of results) {
		const resultType = result.itemKind || result.itemType || config.namespaceItemType;

		const tr = document.createElement('tr');
		tbody.appendChild(tr);

		let td = document.createElement('td');
		const a = document.createElement('a');
		a.href = processUrl(result.url, moniker);
		const displayNameTemplate = html`${unsafeHTML(
			breakText(result.displayName.replace(/</g, '&lt;').replace(/>/g, '&gt;'))
		)}`;

		render(displayNameTemplate, a);

		const s = document.createElement('span');
		s.textContent = ' ' + resultType;
		td.appendChild(a);
		td.appendChild(s);
		tr.appendChild(td);

		td = document.createElement('td');
		td.textContent = toText(result.description);
		tr.appendChild(td);

		tr.appendChild(td);
	}
}

export function displayLoadingIndicator() {
	document.documentElement.classList.add('api-search-has-results');

	for (const { container } of containers) {
		const loadingIndicatorTemplate = html` <div
			class="c-progress f-indeterminate-regional"
			role="progressbar"
			aria-valuetext="Loading..."
			tabindex="0"
			aria-label="indeterminate regional progress bar"
		>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
			<span></span>
		</div>`;

		render(loadingIndicatorTemplate, container);
	}
}

export function renderText(text: string) {
	document.documentElement.classList.add('api-search-has-results');

	for (const { container } of containers) {
		container.textContent = text;
	}
}

export function clearResults() {
	document.documentElement.classList.remove('api-search-has-results');

	for (const { container } of containers) {
		container.innerHTML = '';
	}
}

function renderResultsHeading(container: HTMLDivElement, platform: Api.RefPlatform) {
	const moniker = getMoniker();
	let displayName: string;
	let versionDisplayName: string | null;
	if (moniker === '') {
		displayName = platformConfig[platformId].displayName;
		versionDisplayName = null;
	} else {
		const pkg = platform.packagesByMoniker[moniker];
		displayName = pkg.product.displayName;
		versionDisplayName = pkg.versionDisplayName;
	}
	const heading = document.createElement('h2');
	heading.classList.add('api-search-results-heading');

	const platformNameTemplate = html`${config.resultsHeadingTemplate.replace(
		'{platformName}',
		displayName
	)}`;

	render(platformNameTemplate, heading);

	// rest quick filter headlines formatting
	if (versionDisplayName !== null && platformId === 'rest') {
		const headlineTemplate = html`${unsafeHTML(
			`${escape(moniker)} REST ${config.resultsHeadingTemplate.replace(
				'{platformName}',
				displayName
			)}`
		)}`;

		render(headlineTemplate, heading);
	} else if (versionDisplayName !== null) {
		const headlineTemplate = html`${unsafeHTML(
			`${config.resultsHeadingTemplate.replace(
				'{platformName}',
				displayName
			)} <span class="moniker-version">version ${escape(versionDisplayName)}</span>`
		)}`; // todo: localize

		render(headlineTemplate, heading);
	}
	container.appendChild(heading);
}

/**
 * Limited URL processing logic specific to API search result rendering.
 * @param url URL from the search API. Support is limited to urls with hash only. No query strings.
 * @param moniker The moniker to append as a view arg.
 */
export function processUrl(url: string, moniker: string) {
	// add moniker to link on all but REST:
	if (moniker !== '' && !/[?&]view=/i.test(url)) {
		let [path, hash] = url.split('#');
		hash = hash === undefined ? '' : '#' + hash;
		url =
			platformId === 'rest'
				? `${path}${hash}`
				: `${path}?view=${encodeURIComponent(moniker)}${hash}`;
	}

	// make relative
	if (/^https:\/\/docs.microsoft.com/.test(url)) {
		url = url.substr('https://docs.microsoft.com'.length);
	}

	if (platformId !== 'rest') {
		url = `/${msDocs.data.userLocale}${url}`;
	}

	return url;
}

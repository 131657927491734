import { loc_apiKey, loc_signIn } from '@msdocs/strings';
import { azureOAuthLogin } from '../azure/oauth';
import { azureToken } from '../azure/token';
import { getMeta } from '../meta';
import { RestTryItRequest } from './types';

// Create a set of security request transformers that act as request interceptors,
// adding values as necessary for the security type. For default oAuth, use the accessToken interceptor.

export interface SecurityStrategy {
	prompt: string;
	type: string;
	login: (element: HTMLElement) => Promise<void>;
	transform: (request: Request, tryItRequest?: RestTryItRequest) => Request;
}

export const OAuthAccessTokenStrategy: SecurityStrategy = {
	prompt: loc_signIn,
	type: 'oauth2',
	login: async (element: HTMLElement) => {
		await azureOAuthLogin(element);
	},
	transform: (request: Request) => {
		if (azureToken.value !== null) {
			// Can we make this more generic?
			const accessToken =
				(getMeta('rest_product') || '').toLowerCase() === 'power bi'
					? azureToken.value.pbi_access_token
					: azureToken.value.access_token;
			request.headers.set('Authorization', `Bearer ${accessToken}`);
		}

		return request;
	}
};

export const APIKeyAccessTokenStrategy: SecurityStrategy = {
	prompt: loc_apiKey,
	type: 'APIKey',
	login: async () => {},
	transform: (request: Request) => {
		return request;
	}
};

export const UnsecuredStrategy: SecurityStrategy = {
	prompt: '',
	type: 'none',
	login: async () => {},
	transform: (request: Request) => {
		return request;
	}
};

export function getSecurityStrategy(type: string): SecurityStrategy {
	const strategyMap: { [key: string]: SecurityStrategy } = {
		oauth2: OAuthAccessTokenStrategy,
		apiKey: APIKeyAccessTokenStrategy
	};

	return strategyMap[type] || OAuthAccessTokenStrategy;
}

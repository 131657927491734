import {
	loc_rateThisModule,
	loc_submit,
	loc_tellUsWhatYouThinkYourFeedbackIsEssentialToUs
} from '@msdocs/strings';
import { html, render } from '../lit-html';
import { UserRating } from '../apis/rating';
import { jsllReady } from '../bi';
import { RatingFeedback } from '../feedback-report/rating';
import { msDocs } from '../globals';
import { isReview } from '../is-production';
import { getMeta } from '../meta';
import { createRatingComment } from './comment';
import { createRatingRemarks } from './question-answers';
import { createRatingControl } from './star-rating-control';
import { SurveyQuestion } from './survey-questions';

let isRatingSubmitting = false;

export function createRatingCard(
	score: number,
	onSubmit: (data: UserRating, ratingFeedbackData: RatingFeedback) => void,
	surveyQuestions: SurveyQuestion[]
): HTMLElement {
	const ratingFormContainer = document.createElement('div');
	ratingFormContainer.classList.add('modal-slide');

	render(
		html`
			<div class="modal-card-content" data-bi-name="rating-form-card">
				<h2
					id="modalTitle"
					class="title is-2 has-padding-left-large has-margin-bottom-medium has-margin-top-large"
				>
					${loc_rateThisModule}
				</h2>
				<form id="ratingForm">
					<section class="has-padding-left-large has-padding-top-none has-padding-bottom-large">
						<p id="modalText">${loc_tellUsWhatYouThinkYourFeedbackIsEssentialToUs}</p>
						<div class="rating-control-container has-padding-top-small"></div>
					</section>

					<section
						class="modal-card-body has-padding-top-small ${score === 0
							? 'has-padding-bottom-extra-large'
							: ''} has-background-secondary-light"
					>
						<div class="rating-details" data-bi-name="rating-details"></div>
						<div
							class="has-flex-justify-content-center has-text-centered has-padding-top-medium has-padding-bottom-large has-margin-bottom-large"
						>
							<button
								type="submit"
								class="button is-primary submit-rating has-padding-left-large has-padding-right-large"
								data-bi-name="rating-submit"
								autofocus
							>
								${loc_submit}
							</button>
						</div>
					</section>
				</form>
			</div>
		`,
		ratingFormContainer
	);

	const ratingSection = ratingFormContainer.querySelector('.rating-control-container');
	const ratingDetails = ratingFormContainer.querySelector('.rating-details');
	const isFirstTimeRating = score === 0; // if score is 0 it is first time rating

	const onRatingCallback = (score: number) => {
		ratingDetails.innerHTML = ``;

		const ratingRemarks = createRatingRemarks(score, surveyQuestions);
		ratingDetails.appendChild(ratingRemarks);

		const ratingComment = createRatingComment();
		ratingDetails.appendChild(ratingComment);
	};

	const ratingControl = createRatingControl(score, 2, 3, '', false, onRatingCallback);
	ratingSection.appendChild(ratingControl);

	onRatingCallback(score);

	const ratingForm = ratingFormContainer.querySelector<HTMLElement>('#ratingForm');
	ratingForm.addEventListener('submit', event => {
		event.preventDefault();

		if (isRatingSubmitting) {
			return;
		}

		isRatingSubmitting = true;

		const selectedRatingInput = ratingFormContainer.querySelector<HTMLInputElement>(
			`input[type=radio]:checked`
		);
		const score = parseInt(selectedRatingInput.value);
		const answerIds: string[] = [];
		let ratingFeedbackData: RatingFeedback = null;
		Array.from(
			ratingFormContainer.querySelectorAll<HTMLInputElement>('input[type=checkbox]')
		).forEach(inputItem => {
			if (inputItem.checked) {
				answerIds.push(inputItem.getAttribute('id'));
			}
		});
		const comments = ratingFormContainer.querySelector<HTMLInputElement>('.rating-comments').value;

		if (comments !== '') {
			ratingFeedbackData = {
				selectedReasons: answerIds,
				description: comments,
				ratingScore: score
			};
		}

		const userRating: UserRating = {
			pageType: 'learn',
			pageKind: 'module',
			locale: msDocs.data.userLocale,
			ratingScore: score,
			questionAndAnswers: [
				{
					questionId: surveyQuestions[0].id,
					answerIds,
					comments
				}
			],
			metadata: {
				ratingLocation: 'module_completion_page',
				moduleProgress: 1
			}
		};

		if (!isReview) {
			jsllReady.then(function (awa) {
				awa.ct.captureContentPageAction({
					behavior: awa.behavior.OTHER,
					actionType: awa.actionType.OTHER,
					content: {
						type: 'rating',
						uid: getMeta('uid'), //module id
						score: userRating.ratingScore, //  number 1 to 5
						isNew: isFirstTimeRating, // boolean, true or false
						reasons: [answerIds], // an array of answer ids.
						verbatim: comments
					}
				});
			});
		}

		onSubmit(userRating, ratingFeedbackData);

		isRatingSubmitting = false;
	});

	return ratingFormContainer;
}

import { loc_close } from '@msdocs/strings';

export function createRatingButton(onRateThisModule: () => void, text: string): HTMLElement {
	const dropdownItem = document.createElement('div');
	dropdownItem.classList.add('dropdown', 'has-snug-menu', 'has-caret');
	dropdownItem.setAttribute('data-bi-name', 'rating-popover'); // old bi-name kept to ensure reports are not broken.
	const container = document.createElement('div');
	container.classList.add('level-item');
	container.appendChild(dropdownItem);

	const ratingButtonHtml = `
		<button class="button is-extra-small rate-this-module authenticated" aria-controls="complete-dropdown" aria-expanded="false" data-bi-name="rate-this-module" aria-label="Rate it" aria-describedby="complete-dropdown">${text}</button></button>
	`;

	dropdownItem.innerHTML = ratingButtonHtml;

	const anchor = container.querySelector<HTMLAnchorElement>('.rate-this-module');
	anchor.onclick = onRateThisModule;

	return container;
}

export function createDisabledRatingButton(
	onCompleteThisModule: () => void,
	text: string,
	message: string
): HTMLElement {
	const dropdownItem = document.createElement('div');
	dropdownItem.classList.add('dropdown', 'has-caret');
	dropdownItem.setAttribute('data-bi-name', 'rating-popover'); // old bi-name kept to ensure reports are not broken.
	const container = document.createElement('div');
	container.classList.add('level-item');
	container.appendChild(dropdownItem);

	const disabledRatingButtonHtml = `
		<button class="dropdown-trigger button is-disabled is-extra-small rate-this-module authenticated" aria-controls="complete-dropdown" aria-expanded="false" data-bi-name="rate-this-module" aria-label="Rate it" aria-describedby="complete-dropdown">${text}</button>
		<div class="dropdown-menu has-width-250 has-text-wrap has-line-height-reset has-padding-medium is-size-small has-body-background-medium" id="complete-dropdown" role="tooltip">
			<div>
				<p class="has-margin-none has-padding-none">${message}</p>
			</div>
			<button class="dropdown-menu-close delete is-text is-absolute has-top-zero has-right-zero has-margin-extra-small has-inner-focus" aria-label="${loc_close}"></button>
		</div>`;

	dropdownItem.innerHTML = disabledRatingButtonHtml;

	const anchor = container.querySelector<HTMLAnchorElement>('.rate-this-module');
	anchor.onclick = onCompleteThisModule;

	return container;
}

export function createRatedText(text: string): HTMLElement {
	const container = document.createElement('div');
	container.classList.add('level-item');

	const ratedHtml = `
		<p class="is-size-extra-small has-text-success authenticated" aria-label="Rated module">${text}</p>
		<span class="icon is-size-extra-small has-text-success" aria-hidden="true">
			<span class="docon docon-check"></span>
		</span>
	`;

	container.innerHTML = ratedHtml;

	return container;
}

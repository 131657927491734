import { loc_browse, loc_browseCertifications, loc_learn } from '@msdocs/strings';
import { Module } from '../apis/learn';
import { Breadcrumb, createBreadcrumbs } from '../breadcrumbs';
import { getMeta } from '../meta';

/*
 * Learn breadcrumbs
 */
export function renderLearnBreadcrumbs(title: string, module?: Module, container?: HTMLElement) {
	const breadcrumbs = createBreadcrumbs(container);
	if (!breadcrumbs) {
		return;
	}

	const breadcrumbExamTitle = title.replace(/^[^:]*:\s*/, '');
	let singleLearningPath: Breadcrumb;
	if (module && module.parents.length === 1) {
		singleLearningPath = {
			title: module.parents[0].title,
			url: `/${breadcrumbs.basePath}${module.parents[0].url}`
		};
	}

	const pageKind = getMeta('page_kind');
	switch (pageKind) {
		case 'product':
			breadcrumbs.add({
				title: loc_learn
			});
			break;
		case 'path':
			breadcrumbs.add(
				{
					title: loc_learn,
					url: `/${breadcrumbs.basePath}/learn/`
				},
				{
					title: loc_browse,
					url: `/${breadcrumbs.basePath}/learn/browse/`
				},
				{ title }
			);
			break;
		case 'module':
			breadcrumbs.add(
				{
					title: loc_learn,
					url: `/${breadcrumbs.basePath}/learn/`
				},
				{
					title: loc_browse,
					url: `/${breadcrumbs.basePath}/learn/browse/`
				}
			);
			if (singleLearningPath) {
				breadcrumbs.add(singleLearningPath);
			}
			breadcrumbs.add({ title });
			break;
		case 'unit':
			breadcrumbs.add(
				{
					title: loc_learn,
					url: `/${breadcrumbs.basePath}/learn/`
				},
				{
					title: loc_browse,
					url: `/${breadcrumbs.basePath}/learn/browse/`
				}
			);
			if (singleLearningPath) {
				breadcrumbs.add(singleLearningPath);
			}
			breadcrumbs.add(
				{
					title: module.title,
					url: `/${breadcrumbs.basePath}${module.url}`
				},
				{ title }
			);
			break;
		case 'examination':
			breadcrumbs.add(
				{
					title: loc_learn,
					url: `/${breadcrumbs.basePath}/learn/`
				},
				{
					title: loc_browseCertifications,
					url: `/${breadcrumbs.basePath}/learn/certifications/browse/`
				},
				{ title: breadcrumbExamTitle }
			);
			break;
		case 'certification':
		case 'course':
		case 'role':
			breadcrumbs.add(
				{
					title: loc_learn,
					url: `/${breadcrumbs.basePath}/learn/`
				},
				{
					title: loc_browseCertifications,
					url: `/${breadcrumbs.basePath}/learn/certifications/browse/`
				},
				{ title }
			);
			break;
		case 'tv':
			breadcrumbs.add(
				{
					title: loc_learn,
					url: `/${breadcrumbs.basePath}/learn/`
				},
				{ title }
			);
			break;
		default:
			throw new Error(`Unsupported page_kind "${pageKind}".`);
	}

	breadcrumbs.render();
}

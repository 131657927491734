//
// This file is generated by 'strings\build\taxonomy.js'
// If you need to make a change, update the build script
//

import {
	loc_devlangs_al_language,
	loc_devlangs_aspx,
	loc_devlangs_aspx_csharp,
	loc_devlangs_aspx_vb,
	loc_devlangs_azcopy,
	loc_devlangs_azurecli,
	loc_devlangs_azurepowershell,
	loc_devlangs_bash,
	loc_devlangs_brainscript,
	loc_devlangs_c__,
	loc_devlangs_console,
	loc_devlangs_cpp,
	loc_devlangs_cppcx,
	loc_devlangs_cppwinrt,
	loc_devlangs_csharp,
	loc_devlangs_cshtml,
	loc_devlangs_dax,
	loc_devlangs_dockerfile,
	loc_devlangs_dotnetcli,
	loc_devlangs_fsharp,
	loc_devlangs_go,
	loc_devlangs_gradle,
	loc_devlangs_hcl,
	loc_devlangs_hiveql,
	loc_devlangs_html,
	loc_devlangs_http,
	loc_devlangs_inf,
	loc_devlangs_inkling,
	loc_devlangs_java,
	loc_devlangs_javascript,
	loc_devlangs_js,
	loc_devlangs_json,
	loc_devlangs_kusto,
	loc_devlangs_md,
	loc_devlangs_mof,
	loc_devlangs_nodejs,
	loc_devlangs_objc,
	loc_devlangs_odata,
	loc_devlangs_output,
	loc_devlangs_php,
	loc_devlangs_powerapps_comma,
	loc_devlangs_powerapps_dot,
	loc_devlangs_powerappsfl,
	loc_devlangs_powershell,
	loc_devlangs_protobuf,
	loc_devlangs_python,
	loc_devlangs_qsharp,
	loc_devlangs_r__,
	loc_devlangs_ruby,
	loc_devlangs_scala,
	loc_devlangs_solidity,
	loc_devlangs_sql,
	loc_devlangs_swift,
	loc_devlangs_terraform,
	loc_devlangs_tsql,
	loc_devlangs_typescript,
	loc_devlangs_usql,
	loc_devlangs_vb,
	loc_devlangs_vba,
	loc_devlangs_vbscript,
	loc_devlangs_xaml,
	loc_devlangs_xml,
	loc_devlangs_xpp,
	loc_devlangs_yaml
} from '@msdocs/strings';

export const devlangs: { [key: string]: string } = {
	'al-language': loc_devlangs_al_language,
	aspx: loc_devlangs_aspx,
	'aspx-csharp': loc_devlangs_aspx_csharp,
	'aspx-vb': loc_devlangs_aspx_vb,
	azcopy: loc_devlangs_azcopy,
	azurecli: loc_devlangs_azurecli,
	azurepowershell: loc_devlangs_azurepowershell,
	bash: loc_devlangs_bash,
	brainscript: loc_devlangs_brainscript,
	c: loc_devlangs_c__,
	console: loc_devlangs_console,
	cpp: loc_devlangs_cpp,
	cppcx: loc_devlangs_cppcx,
	cppwinrt: loc_devlangs_cppwinrt,
	csharp: loc_devlangs_csharp,
	cshtml: loc_devlangs_cshtml,
	dax: loc_devlangs_dax,
	dockerfile: loc_devlangs_dockerfile,
	dotnetcli: loc_devlangs_dotnetcli,
	fsharp: loc_devlangs_fsharp,
	go: loc_devlangs_go,
	gradle: loc_devlangs_gradle,
	hcl: loc_devlangs_hcl,
	hiveql: loc_devlangs_hiveql,
	html: loc_devlangs_html,
	http: loc_devlangs_http,
	inf: loc_devlangs_inf,
	inkling: loc_devlangs_inkling,
	java: loc_devlangs_java,
	javascript: loc_devlangs_javascript,
	js: loc_devlangs_js,
	json: loc_devlangs_json,
	kusto: loc_devlangs_kusto,
	md: loc_devlangs_md,
	mof: loc_devlangs_mof,
	nodejs: loc_devlangs_nodejs,
	objc: loc_devlangs_objc,
	odata: loc_devlangs_odata,
	output: loc_devlangs_output,
	php: loc_devlangs_php,
	'powerapps-comma': loc_devlangs_powerapps_comma,
	'powerapps-dot': loc_devlangs_powerapps_dot,
	powerappsfl: loc_devlangs_powerappsfl,
	powershell: loc_devlangs_powershell,
	protobuf: loc_devlangs_protobuf,
	python: loc_devlangs_python,
	qsharp: loc_devlangs_qsharp,
	r: loc_devlangs_r__,
	ruby: loc_devlangs_ruby,
	scala: loc_devlangs_scala,
	solidity: loc_devlangs_solidity,
	sql: loc_devlangs_sql,
	swift: loc_devlangs_swift,
	terraform: loc_devlangs_terraform,
	tsql: loc_devlangs_tsql,
	typescript: loc_devlangs_typescript,
	usql: loc_devlangs_usql,
	vb: loc_devlangs_vb,
	vba: loc_devlangs_vba,
	vbscript: loc_devlangs_vbscript,
	xaml: loc_devlangs_xaml,
	xml: loc_devlangs_xml,
	xpp: loc_devlangs_xpp,
	yaml: loc_devlangs_yaml,
	// aliases for friendly names
	cs: loc_devlangs_csharp,
	msgraph: loc_devlangs_http,
	rest: loc_devlangs_http,
	vbs: loc_devlangs_vbscript
};

export type DevlangsTypeId = keyof typeof devlangs;

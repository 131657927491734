import { jsllReady } from '../bi';
import { CourseSchedulingCriteria } from './course-types';

/**
 * @module bi
 * Function related to custom BI tasks for course scheduling.
 */

export function reportCourseFilterWithJsll(
	criteria: CourseSchedulingCriteria,
	resultCount: number
) {
	jsllReady.then(awa =>
		awa.ct.captureContentPageAction({
			actionType: awa.actionType.OTHER,
			behavior: awa.behavior.SEARCH,
			content: {
				event: 'course-filter',
				criteria,
				count: resultCount
			}
		})
	);
}

export function reportAutocompleteCleared() {
	jsllReady.then(awa =>
		awa.ct.captureContentPageAction({
			actionType: awa.actionType.OTHER,
			behavior: awa.behavior.SEARCH,
			content: {
				event: 'location-autocomplete-cleared'
			}
		})
	);
}

export function reportAutocompleteSelection(location: string) {
	jsllReady.then(awa =>
		awa.ct.captureContentPageAction({
			actionType: awa.actionType.OTHER,
			behavior: awa.behavior.SEARCH,
			content: {
				event: 'location-autocomplete-selected',
				location
			}
		})
	);
}

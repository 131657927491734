export const beforeUnloadFns: (() => any)[] = [];

/**
 * Enqueue a function to be executed before the current page is unloaded by the router.
 */
export function beforeUnload(fn: () => any) {
	beforeUnloadFns.push(fn);
}

/**
 * Listen to an event until the current page is unloaded by the router.
 */
export function listenUntilUnload(
	target: EventTarget,
	type: string,
	listener: EventListenerOrEventListenerObject,
	options?: boolean | AddEventListenerOptions
): () => void {
	target.addEventListener(type, listener, options);
	const dispose = () => target.removeEventListener(type, listener, options);
	beforeUnload(dispose);

	return dispose;
}

import { user } from '../auth/user';
import { generateUuidV4 } from '../html';
import { localStorage } from '../protected-storage';

export function getVisitorId(): string {
	if (user.isAuthenticated) {
		return user.userId;
	}

	const visitorIdLocalStorageKey = 'course-visitor-id';
	let visitorId = localStorage.getItem(visitorIdLocalStorageKey);
	if (visitorId) {
		return visitorId;
	}

	visitorId = generateUuidV4();
	localStorage.setItem(visitorIdLocalStorageKey, visitorId);
	return visitorId;
}

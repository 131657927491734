import {
	loc_noResults,
	loc_siteSearch_results_noResultsWithinScopeFormat,
	loc_siteSearch_results_noResults
} from '@msdocs/strings';
import { html, TemplateResult, render, unsafeHTML } from '../../../lit-html';
import { SearchViewModel } from '../view-model';

export function noResultsTemplate<TResult>(vm: SearchViewModel<TResult>): TemplateResult {
	const noFoundMessage = document.createElement('p');
	render(
		vm.scope
			? unsafeHTML(loc_siteSearch_results_noResultsWithinScopeFormat.replace('{scope}', vm.scope))
			: loc_siteSearch_results_noResults,
		noFoundMessage
	);

	const searchGloballyBtn = noFoundMessage.querySelector<HTMLButtonElement>('#search-globally-btn');

	if (searchGloballyBtn) {
		searchGloballyBtn.addEventListener('click', () => vm.clearScope());
	}

	return html`
		<div class="has-margin-top-large has-margin-bottom-medium is-text-centered">
			<figure class="has-margin-bottom-medium">
				<img
					src="/en-us/media/illustrations/no-results.svg?branch=master"
					role="presentation"
					alt=""
				/>
			</figure>
			<h2 class="title is-2 has-margin-bottom-medium">${loc_noResults}</h2>
			${noFoundMessage}
		</div>
	`;
}

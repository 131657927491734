import { msDocs } from '../globals';
import { getMeta } from '../meta';

export type PageKind =
	| 'achievements'
	| 'assessments'
	| 'azure'
	| 'certification'
	| 'command'
	| 'course'
	| 'delegate'
	| 'detail'
	| 'docs'
	| 'entity'
	| 'enum'
	| 'examination'
	| 'group'
	| 'learn'
	| 'lifecycle'
	| 'list'
	| 'module'
	| 'namespace'
	| 'operation'
	| 'operationgroup'
	| 'package'
	| 'path'
	| 'product'
	| 'profile'
	| 'register'
	| 'role'
	| 'sample'
	| 'service'
	| 'style-guide'
	| 'tv'
	| 'twitter'
	| 'type'
	| 'unit'
	| 'vsts';

export type PageType =
	| 'apibrowser'
	| 'assessment'
	| 'browse'
	| 'cli'
	| 'community'
	| 'conceptual'
	| 'content'
	| 'dotnet'
	| 'home'
	| 'hub'
	| 'hubpage'
	| 'java'
	| 'javascript'
	| 'landing'
	| 'landingdata'
	| 'landingpage'
	| 'learn'
	| 'lifecycle'
	| 'locale'
	| 'notfound'
	| 'odata'
	| 'openapi'
	| 'powerconnector'
	| 'powershell'
	| 'profile'
	| 'python'
	| 'qsharp'
	| 'rest'
	| 'sample'
	| 'search'
	| 'topiclanding'
	| 'tutorial'
	| 'typescript';

export class Archive {
	static get archived(): boolean {
		return getMeta('is_archived') === 'true';
	}

	static get retired(): boolean {
		return getMeta('is_retired') === 'true';
	}
}

export class GitAttributes {
	static get contentGitUrl(): string {
		return msDocs.data.contentGitUrl;
	}

	static get fragmentsFileGitUrl(): string {
		return getMeta('fragmentsFileGitUrl');
	}

	static get hideViewSource(): boolean {
		return msDocs.data.hideViewSource;
	}

	static get isDeveloperSite(): boolean {
		return getMeta('depot_name') === 'devMSFT.dev';
	}

	static get isEditDisplayable(): boolean {
		return msDocs.data.isEditDisplayable;
	}

	static get originalContentGitUrl(): string {
		return getMeta('original_content_git_url');
	}

	static get originalContentGitUrlTemplate(): string {
		return getMeta('original_content_git_url_template');
	}

	static get prRepo(): string {
		return getMeta('pr_repo');
	}

	static get prBranch(): string {
		return getMeta('pr_branch');
	}

	static get sourceUrl(): string {
		return getMeta('source_url');
	}
}

export class IdentityAttributes {
	static get documentID(): string {
		return getMeta('document_id');
	}

	static get versionIndependentDocumentID(): string {
		return getMeta('document_version_independent_id');
	}
}

export class LocalizationAttributes {
	static get contentDir(): string {
		return msDocs.data.contentDir;
	}

	static get contentLocale(): string {
		return msDocs.data.contentLocale;
	}

	static get userDir(): string {
		return msDocs.data.userDir;
	}

	static get userLocale(): string {
		return msDocs.data.userLocale;
	}

	static get hasLocSideBySideTranslation(): boolean {
		return getMeta('enable_loc_sxs') === 'true' || getMeta('bilingual_type') === 'hover over';
	}
}

export class TemplateAttributes {
	static get brand(): string {
		return msDocs.data.brand;
	}

	static get kind(): PageKind {
		return getMeta('page_kind') as PageKind;
	}

	static get type(): PageType {
		return getMeta('page_type') as PageType;
	}

	static get template(): PageTemplate {
		return msDocs.data.pageTemplate;
	}
}

const tabletMinWidth = 768; // use Docs breakpoint rather than UHF tablet breakpoint
const desktopMinWidth = 1088; // to be consistent with $desktop: 960px + 2 * $gap !default;
const bigScreenMinHeight = 1024;

export const isMatchMediaSupported = !!window.matchMedia;
export const bigScreenQuery = window.matchMedia(
	`screen and (min-width: ${tabletMinWidth}px), screen and (min-height: ${bigScreenMinHeight}px)`
);
export const desktopOnlyQuery = window.matchMedia(`screen and (min-width: ${desktopMinWidth}px)`);
export const tabletOrDesktopQuery = window.matchMedia(
	`screen and (min-width: ${tabletMinWidth}px)`
);
export const mobileQuery = window.matchMedia(`screen and (max-width: ${tabletMinWidth}px)`);
export const printQuery = window.matchMedia('print');
export const prefersColorSchemeDarkQuery = window.matchMedia('(prefers-color-scheme: dark)');

export function scrollTo(y: number, duration: number, container: Window | HTMLElement = window) {
	const startingY = container instanceof Window ? window.pageYOffset : container.scrollTop;
	const diff = y - startingY;
	let start: number;

	function step(timestamp: number) {
		if (!start) {
			start = timestamp;
		}
		const elapsed = timestamp - start;
		const targetPercentComplete = Math.min(elapsed / duration, 1);

		// element.scrollTo doesn't work in IE :(
		if (container instanceof Window) {
			container.scrollTo(0, startingY + diff * targetPercentComplete);
		} else {
			container.scrollTop = startingY + diff * targetPercentComplete;
		}

		if (elapsed < duration) {
			window.requestAnimationFrame(step);
		}
	}

	window.requestAnimationFrame(step);
}

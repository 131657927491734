import { apiBrowserPage } from '../api-browser/page';
import { assessmentsHomePage } from '../assessment';
import { profileListPage } from '../community/profiles-filter';
import { document, msDocs } from '../globals';
import { searchPage } from './search';
import { moduleUnitPage } from '../unit/index';
import { certificationPage } from './certification';
import { certificationHomePage } from './certification-home';
import { coursePage } from './course';
import { examinationPage } from './examination';
import { homePage } from './home';
import { hubPage } from './hub/index';
import { landingPage } from './landing';
import { learnHomePage } from './learn-home';
import { learnProduct } from './learn-product';
import { learningPathPage } from './learning-path';
import { initLearnTV } from './learn-tv';
import { localePage } from './locale';
import { modulePage } from './module';
import { namepaceListPage } from './namespace-list';
import { notFoundPage } from './not-found';
import { oldHubPagePage } from './old-hub-page';
import { oldLandingPage } from './old-landing';
import { referencePage } from './reference';
import { rolePage } from './role';
import { samplePage } from './sample';
import { topicLandingPage } from './topic-landing';
import { tutorialPage } from './tutorial';
import { contentBrowserPage } from './content-browser';
import { lifecyclePage } from './lifecycle';

/**
 * On router enabled pages, pageTemplateSpecific is run after every navigation.
 * On the assessment's page template this can lead to multiple re-renders.
 * This variable is used to prevent all except the first envokation of the page template function.
 */
let firstRun = true;

export function pageTemplateSpecific() {
	const pageTemplate = msDocs.data.pageTemplate;
	const mainElement = document.getElementById('main');

	switch (pageTemplate) {
		case 'ApiBrowserPage':
			apiBrowserPage();
			break;
		case 'Home':
			homePage();
			break;
		case 'Hub':
			hubPage(mainElement);
			break;
		case 'HubPage':
			oldHubPagePage();
			break;
		case 'LandingPage':
			oldLandingPage();
			break;
		case 'LearnTV':
			initLearnTV();
			break;
		case 'LocaleList':
			localePage();
			break;
		case 'NamespaceListPage':
			namepaceListPage();
			break;
		case 'NotFound':
			notFoundPage();
			break;
		case 'ProfileList':
			profileListPage();
			break;
		case 'Reference':
			referencePage();
			break;
		case 'SearchPage':
			searchPage();
			break;
		case 'Tutorial':
			tutorialPage();
			break;
		case 'Module':
			modulePage(mainElement);
			break;
		case 'ModuleUnit':
			moduleUnitPage();
			break;
		case 'LearningPath':
			learningPathPage(mainElement);
			break;
		case 'Sample':
			samplePage();
			break;
		case 'ContentBrowserPage':
			contentBrowserPage();
			break;
		case 'LearnProduct':
			learnProduct();
			break;
		case 'Certification':
			certificationPage();
			break;
		case 'CertificationHome':
			certificationHomePage();
			break;
		case 'Examination':
			examinationPage();
			break;
		case 'Course':
			coursePage();
			break;
		case 'Landing':
			landingPage(mainElement);
			break;
		case 'Role':
			rolePage();
			break;
		case 'AssessmentsHome':
			if (firstRun) {
				assessmentsHomePage();
				firstRun = false;
			}
			break;
		case 'LearnHome':
			learnHomePage();
			break;
		case 'TopicLanding':
			topicLandingPage();
			break;
		case 'ContentPage':
			break;
		case 'Lifecycle':
			lifecyclePage();
			break;
	}
}

import { clipboardCopy } from '../clipboard';
import { features } from '../environment/features';
import { initGitHubUrl } from './github-url';
import { initViewOnGithub } from './view-on-github';
import { formatIssueBody } from './markdown';
import { msDocs } from '../globals';

export function initFeedback() {
	const section = document.body.querySelector('.feedback-section') as HTMLDivElement;

	if (!section) {
		return;
	}

	if (msDocs.data.pageTemplate === 'ModuleUnit') {
		if (!features.moduleUnitFeedbackReport) {
			hideFeedbackSection(section);
		}
		return;
	}

	if (!features.githubIssues) {
		hideFeedbackSection(section);
		return;
	}

	initGitHubUrl(section);

	initViewOnGithub(section);

	// CTRL+ALT+G copies issue footer markdown to help Azure CXP team fill in
	// ad-hoc GitHub issues created outside of docs.
	addEventListener('keydown', ({ keyCode, altKey, ctrlKey }) => {
		if (
			keyCode === 71 && // G
			altKey &&
			ctrlKey &&
			clipboardCopy(formatIssueBody(''), document.body)
		) {
			alert('✅ GitHub issue footer copied.');
		}
	});
}

function hideFeedbackSection(section: HTMLDivElement) {
	if (section) {
		section.hidden = true;

		const sectionLink = document.querySelector('#feedback-section-link') as HTMLElement;
		if (sectionLink) {
			sectionLink.hidden = true;
		}
	}
}

import { loc_assessment_assessmentName, loc_assessment_myAssessment } from '@msdocs/strings';
import { TextInputTemplateData } from '../templates/input-template';
import { AssessmentViewModel } from './view-model';

export const getAssessmentTextInputTemplateData = (viewModel: AssessmentViewModel) => {
	return {
		sessionName: {
			name: 'sessionName',
			label: loc_assessment_assessmentName,
			placeholder: loc_assessment_myAssessment,
			value: viewModel.session.name,
			size: 'large',
			customLabelClasses: 'title is-3 has-margin-bottom-small',
			isRequired: true,
			maxLength: 200
		}
	} as { [key: string]: TextInputTemplateData };
};

import { loc_learn } from '@msdocs/strings';
import { flatten, StandardProgressResponse } from '../apis/learn';
import { user } from '../auth/user';
import { features } from '../environment/features';
import { contentLoaded, msDocs } from '../globals';
import { renderLearnBreadcrumbs } from '../learn/breadcrumbs';
import { getUserProgressFromMetaTags, organizeLearnCards, renderLearnCards } from '../learn/cards';
import { getItemSummariesFromMetaTags } from '../learn/summaries';
import { syncUserProgress } from '../learn/unit-progress';
import { addLocaleToPath } from '../locale';

export async function learnProduct() {
	syncUserProgress();

	contentLoaded.then(() => {
		renderLearnBreadcrumbs(loc_learn);
	});

	let [summaries] = await Promise.all([getItemSummariesFromMetaTags(), contentLoaded]);
	summaries = summaries.map(x =>
		x.map(y => {
			y.iconUrl = addLocaleToPath(y.iconUrl, 'en-us');
			return y;
		})
	);

	const userProgress: Promise<StandardProgressResponse> =
		user.isAuthenticated && features.gamification
			? getUserProgressFromMetaTags()
			: Promise.resolve([]);

	renderLearnCards(
		document.getElementById('main'),
		organizeLearnCards(flatten(summaries)),
		userProgress,
		msDocs.data.pageTemplate
	);
}

import { getBranch } from '../../../apis/learn';
import { authStatusDetermined } from '../../../auth';
import { defaultFacetSearchFilterOptions } from '../filter-options';
import { FacetSearchResponse } from '../model';
import { SearchVMConfig, UISearchArgs } from '../view-model';
import { apis } from '../../../environment/apis';
import { createRequest, fetchWithTimeout } from '../../../fetch';
import { msDocs } from '../../../globals';
import { html } from '../../../lit-html';
import { InputArgs, toQueryString } from '../../../query-string';
import { loc_course, loc_exam } from '@msdocs/strings';
import { createTagHTML, createTagStrings } from '../../../tags';
import { ProductId } from '../../../name-maps/product';
import { LevelId } from '../../../name-maps/level';
import { RoleId } from '../../../name-maps/roles';
import { LocaleId } from '../../../name-maps/locales';

interface CourseBrowseResult {
	roles: RoleId[];
	levels: LevelId[];
	exams: CourseBrowseResultExam[];
	course_number: string;
	locales: LocaleId[];
	hours_to_complete: number;
	uid: string;
	resource_type: string;
	title: string;
	last_modified: string; // i.e. 11/09/2018 00:00:00 +00:00
	locale: string;
	products: ProductId[];
	url: string;
	retirement_date?: string; // i.e. 11/09/2018 00:00:00 +00:00
}

interface CourseBrowseResultExam {
	uid: string;
	display_name: string;
	url: string;
}

interface CourseArgs {
	branch: string;
	locale: string;
	terms: string;
	facet: string[];
	$filter: string;
	$orderBy: string;
	$skip: string;
	$top: string;
}

export function createCourseFacetSearchConfig(): SearchVMConfig<CourseBrowseResult> {
	return {
		layout: 'grid',
		fetch: fetchCourseResults,
		resultTemplate: createCourseFacetSearchCard
	};
}

const courseSearchFacetDefaults = {
	orderBy: 'last_modified desc',
	top: 30
};

/**
 * Function that renders the cards for the Course browse page, only applies to browseType: 'course'.
 * @param items The resultant items from the Course browse service API call.
 */
function createCourseFacetSearchCard(item: CourseBrowseResult) {
	const examItemsHtml = (item.exams || [])
		.filter(exam => {
			return !!exam.display_name;
		})
		.map(exam => {
			return html`<span>${exam.display_name}</span>`;
		});

	const examsTemplate =
		examItemsHtml.length > 0
			? html`<li>
					<span>${loc_exam}: <span class="is-comma-delimited">${examItemsHtml}</span></span>
			  </li>`
			: '';

	const itemTitle = `${loc_course} ${item.course_number}: ${item.title}`;

	return html`
		<article class="card" data-bi-name="card">
			<div class="card-content">
				<p class="card-content-super-title">${loc_course}</p>
				<a href="${item.url}" class="card-content-title">
					<h3>${itemTitle}</h3>
				</a>
				<ul class="card-content-metadata">
					${item.exams?.length ? examsTemplate : ''}
				</ul>
				<ul class="tags">
					${createTagHTML(createTagStrings(item))}
				</ul>
			</div>
		</article>
	`;
}

async function fetchCourseResults(
	args: UISearchArgs
): Promise<FacetSearchResponse<CourseBrowseResult>> {
	const [results] = await Promise.all([getCourseResults(args), authStatusDetermined]);
	return results;
}

function uiArgsToCourse(args: UISearchArgs): CourseArgs {
	return {
		branch: getBranch(),
		locale: msDocs.data.userLocale,
		terms: args.terms,
		facet: ['roles', 'products', 'levels', 'locales'],
		$filter: defaultFacetSearchFilterOptions(args.selectedFacets),
		// always null if terms are present
		$orderBy: args.terms && args.terms.length > 0 ? null : courseSearchFacetDefaults.orderBy,
		$skip: args.skip ? args.skip.toString() : null,
		$top: courseSearchFacetDefaults.top.toString()
	};
}

function getCourseResults(args: UISearchArgs): Promise<FacetSearchResponse<CourseBrowseResult>> {
	const query = (uiArgsToCourse(args) as unknown) as InputArgs;
	const url = `${apis.browse.course}?${toQueryString(query, true)}`;

	const init = { method: 'GET' };

	return fetchWithTimeout(createRequest(url, init)).then(response => {
		if (response.ok) {
			return response.json();
		}
		/* eslint-disable-next-line */
		throw { error: response.json() };
	});
}

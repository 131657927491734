import {
	loc_completedModules,
	loc_completedUnits,
	loc_continue,
	loc_minFormat,
	loc_module,
	loc_modules,
	loc_oneModule,
	loc_oneUnit,
	loc_overview,
	loc_remaining,
	loc_showHideUnits,
	loc_start,
	loc_units
} from '@msdocs/strings';
import { LearnItem, Module, ProgressItem } from '../apis/learn';
import { user } from '../auth/user';
import { expander } from '../components/expander';
import { contentLoaded, msDocs } from '../globals';
import { renderLearnBreadcrumbs } from '../learn/breadcrumbs';
import { checkForInteractiveDisclaimer } from '../learn/interactive-disclaimer';
import { loadLearningPathInformation } from '../learn/learning-path';
import { displayModuleRatingsForLearningPathPage } from '../learn/module-rating';
import {
	convertToLearnItemsToProgressItems,
	handleXpTag,
	removeStartUnitButton,
	renderStartContinueUnitButton,
	syncUserProgress,
	updateProgressElements
} from '../learn/unit-progress';
import { synchronizeListButtons } from '../lists';
import { getMeta } from '../meta';
import { convertMinsToHrsMins } from '../time-format';

export async function learningPathPage(container: HTMLElement) {
	const addToCollectionBtn = document.querySelector('button.collection') as HTMLButtonElement;
	if (addToCollectionBtn.hidden) {
		addToCollectionBtn.hidden = false;
	}

	const pathId = getMeta('uid');
	const userLocale = msDocs.data.userLocale;

	await syncUserProgress();
	const [learningPath] = await Promise.all([loadLearningPathInformation(pathId), contentLoaded]);

	renderLearnBreadcrumbs(learningPath.title);
	const xpTags = Array.from(container.querySelectorAll('.xp-tag')) as HTMLElement[];
	totalModulePoints(learningPath.modules);
	handleXpTag(xpTags, [learningPath, ...learningPath.modules], learningPath.uid);
	const moduleSummaries = Array.from(
		container.querySelectorAll('.module-summary')
	) as HTMLElement[];
	const expanderToggles = initExpanders(container);
	expanderToggles.forEach(toggle => toggle(false));

	displayModuleRatingsForLearningPathPage(container, learningPath);

	for (const module of learningPath.modules) {
		const moduleContainer = container.querySelector(`${getModuleIdSelector(module)} .module-units`);
		moduleContainer.innerHTML = populateModuleCard(module.units);
	}

	syncBookmarkAndCollectionButtons(learningPath.modules).then(() =>
		synchronizeListButtons(container)
	);

	if (!user.isAuthenticated) {
		container.querySelector('#time-remaining').textContent = displayTime(
			learningPath.remainingTime,
			learningPath.durationInMinutes
		);
		for (const module of learningPath.modules) {
			const moduleContainer = container.querySelector<HTMLElement>(getModuleIdSelector(module));
			displayModuleCardDetails(
				moduleContainer,
				0,
				module.units.length,
				module.remainingTime,
				module.durationInMinutes
			);
		}

		// Use first unit as start unit
		renderStartContinueUnitButton(
			container.querySelector<HTMLDivElement>('#learning-path-actions'),
			`/${userLocale}${learningPath.modules[0].units[0].url}`,
			loc_start,
			'start'
		);

		renderStartContinueUnitButton(
			moduleSummaries[0],
			`/${userLocale}${learningPath.modules[0].units[0].url}`,
			loc_start,
			'start'
		);
		expanderToggles[0](true);
	}

	checkForInteractiveDisclaimer(learningPath.modules);

	await user.whenAuthenticated();
	container.querySelector('#modules-remaining').textContent = displayProgress(
		'learningPath',
		countCompleted(learningPath.modules),
		learningPath.modules.length
	);
	container.querySelector('#time-remaining').textContent = displayTime(
		learningPath.remainingTime,
		learningPath.durationInMinutes
	);

	const units: ProgressItem[] = learningPath.modules.reduce(
		(state, module) => state.concat(convertToLearnItemsToProgressItems(module.units)),
		[]
	);
	const completedCount = learningPath.modules.filter(x => x.status === 'completed').length;
	for (const module of learningPath.modules) {
		const moduleContainer = container.querySelector<HTMLElement>(getModuleIdSelector(module));
		const completedUnitCount = countCompleted(module.units);
		displayModuleCardDetails(
			moduleContainer,
			completedUnitCount,
			module.units.length,
			module.remainingTime,
			module.durationInMinutes
		);
	}

	//check for completed state
	if (completedCount === learningPath.modules.length) {
		removeStartUnitButton(true, true);
		expanderToggles.forEach(toggle => toggle(false));
	} else {
		const args = determineStartingModule(learningPath.modules);
		renderStartContinueUnitButton(
			container.querySelector<HTMLDivElement>('#learning-path-actions'),
			`/${userLocale}${args.url}`,
			args.title,
			args.type
		);

		renderStartContinueUnitButton(
			moduleSummaries[args.moduleIndex],
			`/${userLocale}${args.url}`,
			args.title,
			args.type
		);
		expanderToggles[args.moduleIndex](true);
	}

	updateProgressElements(units);
}

function determineStartingModule(modules: Module[]) {
	let currentModuleIndex = 0;
	let startUnitUrl = '';
	let startModuleIndex = 0;
	let continueUnitUrl = '';
	let continueModuleIndex = 0;

	for (const module of modules) {
		if (startUnitUrl === '' && (!module.status || module.status === 'notStarted')) {
			startUnitUrl = module.units[0].url;
			startModuleIndex = currentModuleIndex;
		}
		if (continueUnitUrl === '' && module.status === 'inProgress') {
			for (const unit of module.units) {
				if (unit.status !== 'completed') {
					continueUnitUrl = unit.url;
					continueModuleIndex = currentModuleIndex;
					break;
				}
			}
		}

		currentModuleIndex++;
	}

	return {
		moduleIndex: continueUnitUrl ? continueModuleIndex : startModuleIndex,
		url: continueUnitUrl || startUnitUrl || '',
		type: continueUnitUrl ? 'continue' : 'start',
		title: continueUnitUrl ? loc_continue : loc_start
	};
}

export function populateModuleCard(units: LearnItem[]) {
	return `
		<ul class="has-margin-left-none">
			${units
				.map(
					({ uid, title, url, durationInMinutes }) => `
				<li data-progress-uid="${uid}" class="module-unit is-unstyled is-size-5 is-relative has-padding-bottom-small has-padding-top-small completable">
					<div class="barLink is-unit-list-item has-margin-none columns">
						<div class="column is-11 has-padding-none has-content-margin-right-small">
							<a class="unit-title is-block is-size-5 has-line-height-reset" href="/${
								msDocs.data.userLocale
							}${url}">${title}</a>
							<span class="unit-duration is-size-extra-small has-margin-top-small has-text-subtle">${loc_minFormat.replace(
								'{numberOfMinutes}',
								durationInMinutes.toString()
							)}</span>
						</div>
						<div class="column is-1 is-shown-complete has-padding-none">
							<span class="has-text-success docon docon-check"></span>
						</div>
					</div>
				</li>
			`
				)
				.join('')}
		</ul>
	`;
}

function displayTime(timeRemaining: number, durationInMinutes: number): string {
	return timeRemaining === 0 || timeRemaining === undefined
		? convertMinsToHrsMins(durationInMinutes)
		: `${convertMinsToHrsMins(timeRemaining)} ${loc_remaining}`;
}

export function displayProgress(type: string, completedCount: number, totalCount: number): string {
	const childTypeText = type === 'module' ? loc_units : loc_modules;
	const childrenCompletedText = type === 'module' ? loc_completedUnits : loc_completedModules;

	if (!user.isAuthenticated || completedCount === totalCount) {
		if (totalCount === 1) {
			return type === 'module' ? loc_oneUnit : loc_oneModule;
		}
		return `${totalCount} ${childTypeText}`;
	}

	return childrenCompletedText
		.replace('{completedCount}', String(completedCount))
		.replace('{totalCount}', String(totalCount));
}

export function displayModuleCardDetails(
	container: HTMLElement,
	completedUnitCount: number,
	totalUnitCount: number,
	timeRemaining: number,
	durationInMinutes: number
) {
	const units = container.querySelector(`.module-units-remaining`) as HTMLSpanElement;
	const resourceType = container.querySelector(`.module-type`) as HTMLSpanElement;
	const overview = container.querySelector(`.module-overview`) as HTMLSpanElement;
	const time = container.querySelector(`.module-time-remaining`) as HTMLSpanElement;
	const unitsExpander = container.querySelector(`button.unit-expander`) as HTMLButtonElement;

	units.textContent = displayProgress('module', completedUnitCount, totalUnitCount);
	resourceType.textContent = loc_module;
	time.textContent = displayTime(timeRemaining, durationInMinutes);
	overview.textContent = loc_overview;
	unitsExpander.setAttribute('aria-label', loc_showHideUnits);
}

function countCompleted(items: LearnItem[]) {
	return items.filter(i => i.status === 'completed').length;
}

function initExpanders(container: HTMLElement) {
	const expanders = Array.from(
		container.querySelectorAll<HTMLButtonElement>('button.unit-expander')
	);
	return expanders.map(expandButton => {
		return expander(expandButton);
	});
}

function totalModulePoints(modules: Module[]) {
	let totalPoints: number = 0;
	modules.forEach(module => {
		totalPoints = 0;
		module.units.forEach(unit => {
			totalPoints += unit.points;
		});
		module.points = totalPoints;
	});
}

function getModuleIdSelector(module: Module) {
	return `[id="${module.uid}"]`;
}

async function syncBookmarkAndCollectionButtons(modules: Module[]) {
	modules.forEach(module => {
		const bookmarks = document.querySelectorAll(
			`${getModuleIdSelector(module)} button[data-list-type]`
		);
		bookmarks.forEach(button => {
			button.setAttribute('data-list-item-url', module.url);
		});
	});
}

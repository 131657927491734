import { loc_body, loc_requestUrl, loc_run } from '@msdocs/strings';
import { EventBus } from '../event-bus';
import { parseQueryString } from '../query-string';
import { RestParamValueChanged, RestRetrieveRequestData, RestRunEventDone } from './events';
import { renderHttpRequest } from './http-request';
import { renderParamList } from './param-list';
import { SecurityStrategy } from './security';
import { Parameter, RestTryItRequest } from './types';

let urlTemplate = '' as string;

export function renderRequest(
	container: HTMLElement,
	bus: EventBus,
	request: RestTryItRequest,
	security: SecurityStrategy
) {
	const { url, urlParams } = parseRequestUrl(request.url);
	urlTemplate = url;

	const blockDiv = document.createElement('div');
	blockDiv.classList.add('request-section');

	renderRequestUrl(blockDiv, bus, url, request.httpVerb);
	renderParamList(blockDiv, bus, request.params, false, urlParams);
	renderParamList(blockDiv, bus, request.headers, true);
	if (request.body !== null) {
		// if request.verb?  todo: when we can select verb, we can show/hide the body based on the verb
		renderBody(blockDiv, bus, request.body);
	}

	renderHttpRequest(blockDiv, bus, request, security);

	const runButton = renderRunButton(blockDiv, bus);

	container.appendChild(blockDiv);

	return runButton;
}

export function parseRequestUrl(
	path: string
): { url: string; urlParams: { [name: string]: string } } {
	let url = path;
	let urlParams: { [name: string]: string } = {};
	const index = path.indexOf('?');

	if (index !== -1) {
		const queryString = path.substr(index);
		url = path.substr(0, index);
		urlParams = parseQueryString(queryString);
	}
	return { url, urlParams };
}

export function renderRequestUrl(
	container: HTMLElement,
	bus: EventBus,
	url: string,
	httpVerb: string
) {
	const blockDiv = document.createElement('div');

	const heading = document.createElement('h3');
	heading.textContent = loc_requestUrl;
	blockDiv.appendChild(heading);

	const urlDiv = document.createElement('div');
	urlDiv.classList.add('url-group');

	// http Verb
	const httpVerbDiv = document.createElement('div');
	httpVerbDiv.classList.add('http-verb');

	const verbInfo = document.createElement('span');
	verbInfo.textContent = httpVerb;
	httpVerbDiv.appendChild(verbInfo);

	// input url
	const inputUrl = document.createElement('input');
	inputUrl.setAttribute('name', 'url');
	inputUrl.readOnly = true;
	inputUrl.classList.add('url-input', 'input', 'is-radiusless');
	inputUrl.setAttribute('aria-label', loc_requestUrl);
	inputUrl.setAttribute('value', url);

	urlDiv.appendChild(httpVerbDiv);
	urlDiv.appendChild(inputUrl);

	blockDiv.appendChild(urlDiv);

	container.appendChild(blockDiv);

	// Listen for RestRetrieveRequestData event
	bus.subscribe(RestRetrieveRequestData, (event: RestRetrieveRequestData) => {
		const requestData = event.restTryItRequest;
		requestData.url = url;
		requestData.httpVerb = verbInfo.textContent;
	});

	const handleParamValueChanged = () => {
		const requestData: RestTryItRequest = {
			url: null,
			httpVerb: null,
			headers: [],
			params: [],
			body: null
		};
		bus.publish(new RestRetrieveRequestData(requestData));

		url = urlTemplate;
		let urlDisplay = urlTemplate;
		requestData.params.forEach(param => {
			url = updateUrlParam(url, param);
			urlDisplay = updateUrlParam(urlDisplay, param);
		});

		inputUrl.value = urlDisplay;
	};

	// Listen for RestParamValueChanged event
	bus.subscribe(RestParamValueChanged, handleParamValueChanged);

	return { inputUrl };
}

// The url parameter passing in is always a template
export function updateUrlParam(url: string, param: Parameter) {
	if (param.in === 'path' && param.value !== '' && isValidPath(param.value)) {
		url = url.replace('{' + param.name + '}', getPathParamValue(param));
	} else if (param.in === 'query' && param.value !== '') {
		if (url.indexOf('?') === -1) {
			url = url + '?' + encodeURIComponent(param.name) + '=' + encodeURIComponent(param.value);
		} else {
			url = url + '&' + encodeURIComponent(param.name) + '=' + encodeURIComponent(param.value);
		}
	}
	return url;
}

function getPathParamValue(param: Parameter): string {
	if (param.skipUrlEncoding) {
		return param.value;
	} else {
		return encodeURIComponent(param.value);
	}
}

function isValidPath(path: string): boolean {
	return /[a-zA-Z0-9@:%._\+~#=!]{2,255}$/.test(path);
}

export function renderBody(container: HTMLElement, bus: EventBus, body: string) {
	const blockDiv = document.createElement('div');

	const heading = document.createElement('h3');
	heading.textContent = loc_body;
	blockDiv.appendChild(heading);

	const bodyText = document.createElement('textarea');
	bodyText.setAttribute('name', loc_body);
	bodyText.textContent = body;
	bodyText.classList.add('textarea', 'request-body', 'tall', 'small');
	bodyText.setAttribute('aria-label', loc_body);
	blockDiv.appendChild(bodyText);

	container.appendChild(blockDiv);

	// Listen for RestRetrieveRequestData event
	bus.subscribe(RestRetrieveRequestData, (event: RestRetrieveRequestData) => {
		const requestData = event.restTryItRequest;
		requestData.body = bodyText.value;
	});

	return { bodyText };
}

export function renderRunButton(container: HTMLElement, bus: EventBus) {
	const runButton = document.createElement('button');
	runButton.setAttribute('type', 'submit');
	runButton.classList.add('button', 'is-success', 'is-large', 'is-radiusless');

	const runSpan = document.createElement('span');
	runSpan.classList.add('run-text');
	runSpan.textContent = loc_run;
	runButton.appendChild(runSpan);

	const runIconSpan = document.createElement('span');
	runIconSpan.classList.add('docon', 'docon-play');
	runIconSpan.setAttribute('aria-hidden', 'true');
	runButton.appendChild(runIconSpan);

	container.insertAdjacentHTML('beforeend', '<div class="request-section"></div>');
	container.lastElementChild.appendChild(runButton);

	// Listen for RestRunEventDone event
	bus.subscribe(RestRunEventDone, () => {
		runButton.classList.remove('is-loading');
	});

	return runButton;
}

import { process401Response } from '../auth/service';
import { fingerprintSid } from '../risk-evaluation/fingerprint';
import { AzureSandbox } from '../azure/sandbox';
import { apis } from '../environment/apis';
import { createRequest, fetchWithTimeout } from '../fetch';
import { RiskApiError } from '../risk-evaluation/risk-errors';

const baseUrl = apis.sandbox;

export async function get(moduleUid: string): Promise<AzureSandboxApiResult> {
	const init: RequestInit = {
		method: 'GET'
	};
	const response = await fetchWithTimeout(createRequest(`${baseUrl}/${moduleUid}`, init));
	process401Response(response);
	if (response.ok || response.status === 400) {
		const data = await response.json();
		if (response.ok) {
			processResourceGroupId(data);
			return { requestVerb: 'GET', hasError: false, sandbox: data };
		}
		return { requestVerb: 'GET', hasError: true, error: data };
	}
	(response as any).requestVerb = init.method;
	return Promise.reject(response);
}

export async function post(request: AzureSandboxPostRequest): Promise<AzureSandboxApiResult> {
	const { returnUrl, locale, unitUid, moduleUid, captchaToken } = request;
	const init: RequestInit = {
		method: 'POST',
		headers: {
			'X-SID': fingerprintSid
		},
		body: JSON.stringify({ returnUrl, locale, unitUid, captchaToken })
	};
	const response = await fetch(createRequest(`${baseUrl}/${moduleUid}`, init)); // intentionally not using fetchWithTimeout
	process401Response(response);
	if (response.ok || response.status === 400) {
		const data = await response.json();
		if (response.ok) {
			processResourceGroupId(data);
			return { requestVerb: 'POST', hasError: false, sandbox: data };
		}
		return { requestVerb: 'POST', hasError: true, error: data };
	}
	(response as any).requestVerb = init.method;
	return Promise.reject(response);
}

export async function remove(moduleUid: string): Promise<void> {
	const init: RequestInit = {
		method: 'DELETE'
	};
	const response = await fetch(createRequest(`${baseUrl}/${moduleUid}`, init)); // intentionally not using fetchWithTimeout
	process401Response(response);
	if (response.ok) {
		return;
	}
	(response as any).requestVerb = init.method;
	return Promise.reject(response);
}

export async function appealSandboxBan(captchaToken?: string): Promise<void> {
	const init: RequestInit = {
		method: 'POST',
		body: JSON.stringify({ captchaToken })
	};

	const response = await fetchWithTimeout(createRequest(`${baseUrl}/appeal`, init));

	process401Response(response);
	if (!response.ok) {
		throw new Error(`Appeal failed: ${response.status}: ${response.statusText}`);
	}
}

export async function prepareTenantInvite(returnUrl: string): Promise<void> {
	const init: RequestInit = {
		method: 'POST',
		body: JSON.stringify({ returnUrl }),
		headers: {
			'X-SID': fingerprintSid
		}
	};

	const response = await fetchWithTimeout(createRequest(`${baseUrl}/invite`, init));
	process401Response(response);
	if (response.ok) {
		return;
	}
	(response as any).requestVerb = init.method;
	return Promise.reject(response);
}

export function processResourceGroupId(sandbox: AzureSandbox) {
	if (sandbox.resourceGroupId) {
		const [
			,
			subscriptionId,
			resourceGroupName
		] = /^\/subscriptions\/([a-f0-9\-]{36})\/resourceGroups\/([\w\-]+)$/i.exec(
			sandbox.resourceGroupId
		);
		sandbox.subscriptionId = subscriptionId;
		sandbox.resourceGroupName = resourceGroupName;
	} else {
		sandbox.subscriptionId = null;
		sandbox.resourceGroupName = null;
	}
}
interface AzureSandboxPostRequest {
	returnUrl: string;
	locale: string;
	unitUid: string;
	moduleUid: string;
	captchaToken?: string;
	phoneNumber?: number;
	verificationCode?: string;
}

export type AzureSandboxApiResult =
	| {
			hasError: true;
			requestVerb: 'GET' | 'POST';
			error: RiskApiError;
	  }
	| {
			hasError: false;
			requestVerb: 'GET' | 'POST';
			sandbox: AzureSandbox;
	  };

import { AzSubscription } from '../azure/oauth';
import { SecurityStrategy } from './security';
import { Parameter, RestTryItRequest, RestTryItResponse } from './types';

export class RestRetrieveRequestData {
	constructor(public restTryItRequest: RestTryItRequest) {}
}
export class RestRunEvent {
	constructor(public restTryItRequest: RestTryItRequest, public security: SecurityStrategy) {}
}
export class RestRunEventDone {}
export class RestParamValueChanged {}
export class RestAddParamEvent {
	constructor(public param: Parameter) {}
}
export class RestSubscriptionIdLoading {}
export class RestSubscriptionIdLoaded {
	constructor(public subscriptions: AzSubscription[]) {}
}
export class RestRenderResponse {
	constructor(public response: RestTryItResponse) {}
}

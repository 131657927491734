export const template = `
	<section class="image-browser">
		<figure>
			<div autofocus tabindex="0" class="has-inner-focus">
				<img id="image-browser-image" src="" />
			</div>
			<figcaption id="image-browser-caption">

			</figcaption>
		</figure>
	</section>
`;

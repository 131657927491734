import { LearningPath, Module } from '../apis/learn';
import { devPartialDeleteProfile } from '../apis/profile';
import { user } from '../auth/user';
import { features } from '../environment/features';
import { msDocs } from '../globals';
import { addLocaleToPath } from '../locale';
import { localStorage } from '../protected-storage';
import { listenUntilUnload } from '../router/utils';

export function addDeleteProgressListener() {
	if (!features.gamification) {
		return;
	}

	listenUntilUnload(window, 'keydown', async ({ keyCode, altKey, ctrlKey }: KeyboardEvent) => {
		if (
			(location.hostname === 'review.docs.microsoft.com' ||
				location.hostname === 'ppe.docs.microsoft.com') &&
			keyCode === 69 && // E
			altKey &&
			ctrlKey
		) {
			localStorage.removeItem('ModuleProgress');
			await Promise.all([devPartialDeleteProfile(['Gamification', 'Progress'])]);
			alert('✅ Progress and Gamification deleted. Page will be reloaded...');
			location.reload();
		}
	});
}

export function getFirstIncompleteModuleUrl(learningPath: LearningPath, module: Module): string {
	if (!user.isAuthenticated) {
		return findNextModuleUrl(learningPath, module);
	}

	const moduleUrl = learningPath.modules.filter(
		m => m.status && m.status !== 'completed' && m.uid !== module.uid
	)[0].url;
	return addLocaleToPath(moduleUrl, msDocs.data.userLocale);
}

function findNextModuleUrl(learningPath: LearningPath, module: Module): string {
	const { modules } = learningPath;
	const currentModuleIndex = modules.findIndex(m => m.uid === module.uid);

	if (currentModuleIndex + 1 === modules.length) {
		return addLocaleToPath(learningPath.url, msDocs.data.userLocale);
	}
	return addLocaleToPath(modules[currentModuleIndex + 1].url, msDocs.data.userLocale);
}

import { loc_microsoftGraphEditor, loc_tryIt } from '@msdocs/strings';
import { eventBus } from '../event-bus';
import { msDocs } from '../globals';
import { isReview } from '../is-production';
import { currentTheme, ThemeChangedEvent } from '../theme-selection';
import { WindowMessenger } from '../window-messenger';
import { registerInteractiveType } from './activation';

/**
 * type: Message type
 * theme: The changed Docs theme
 */
interface ThemeChangedMessage {
	type: 'theme-changed';
	theme: 'light' | 'dark' | 'high-contrast';
}

/**
 * type: Message type
 * locale: The user's locale on Docs
 * theme: The current Docs theme
 * code: The text within the Docs code block
 * permissions: Data extracted from the permissions table. Will be null if Docs cannot locale the permissions table
 */
interface InitMessage {
	type: 'init';
	locale: string;
	theme: 'light' | 'dark' | 'high-contrast';
	code: string;
	permissions: string[][] | null;
}

type DocsMessage = InitMessage | ThemeChangedMessage;

/**
 * Initiate gathering permissions data
 *
 * @param container The HTMLElement where the permissions table is found
 */
export function getPermissions(container: HTMLElement) {
	const foundPermissionsTable = locatePermissionsTable(container);
	const table = createEnglishClone(foundPermissionsTable);
	const permissions = tableToArray(table);
	return permissions;
}

/**
 * Locate the permissions table within the DOM
 *
 * @param container The HTMLElement where the permissions table is found
 */
export function locatePermissionsTable(container: HTMLElement): HTMLTableElement {
	return container.querySelector('h2[id="permissions"] ~ * table, h2[id="permissions"] ~ table');
}

/**
 * Create a clone of the element and return the english version by removing data-ttu-id attribute
 *
 * @param element The element to be cloned
 */
export function createEnglishClone<T extends Element>(element: T): T {
	const tableClone = element.cloneNode(true) as T;
	const childNodes = Array.from(tableClone.childNodes);

	Array.from(childNodes[0].parentNode.querySelectorAll('[data-ttu-id]')).map(el => {
		el.remove();
	});

	return tableClone;
}

/**
 * Create a two dimensional array from a table
 *
 * @param table HTML table element
 */
export function tableToArray(table: HTMLTableElement): string[][] {
	const rows = Array.from(table.querySelectorAll('tr'));
	return rows.map(row =>
		Array.from(row.querySelectorAll('th,td')).map(cell => cell.textContent.trim())
	);
}

const activateButtonConfig = {
	name: loc_tryIt,
	iconClass: 'docon docon-terminal',
	attributes: [{ name: 'aria-haspopup', value: 'true' }]
};

registerInteractiveType({
	name: 'msgraph',
	activateButtonConfig,
	create: () => {
		const graphExplorerOrigin = isReview
			? 'https://graphtryit-staging.azurewebsites.net'
			: 'https://graphtryit.azurewebsites.net';
		const url = new URL(graphExplorerOrigin);
		const hostOrigin = location.origin;
		const rootElement = document.getElementById('main') as HTMLDivElement;
		const permissions = getPermissions(rootElement);

		// data to send to postMessage instead of passing via query strings
		const initMessage: DocsMessage = {
			type: 'init',
			locale: msDocs.data.userLocale,
			theme: currentTheme,
			code: '', // this is determined when we know what try it button the user clicked
			permissions
		};

		url.searchParams.set('locale', initMessage.locale);
		url.searchParams.set('host-origin', hostOrigin);
		url.searchParams.set('theme', initMessage.theme);

		const element = document.createElement('iframe');
		element.classList.add('msgraph', 'is-full-height');
		element.title = loc_microsoftGraphEditor;
		element.src = url.toString();

		// Before Docs can send messages to the Graph Explorer, we need to know whether the Graph Explorer is ready to receive them
		const messenger = new WindowMessenger(element, graphExplorerOrigin);
		const ready = messenger.subscribeOnce<{ type: string }>(message => message.type === 'ready');

		let unsubscribeThemeChanged: () => void;

		return {
			element,
			setCode: code => {
				initMessage.code = code;
				initMessage.theme = currentTheme;
				return Promise.resolve();
			},
			execute: async () => {
				await ready;
				element.contentWindow.postMessage(initMessage, graphExplorerOrigin);
				unsubscribeThemeChanged = eventBus.subscribe(ThemeChangedEvent, event => {
					messenger.publish({ type: 'theme-changed', theme: event.currentTheme });
				});
			},
			dispose: () => {
				unsubscribeThemeChanged();
			}
		};
	}
});

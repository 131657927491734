import {
	loc_advanced,
	loc_beginner,
	loc_intermediate,
	loc_levelDescription_advanced,
	loc_levelDescription_beginner,
	loc_levelDescription_intermediate
} from '@msdocs/strings';

export const levelNames = {
	advanced: loc_advanced,
	beginner: loc_beginner,
	intermediate: loc_intermediate
} as { [level: string]: string };

export type LevelId = keyof typeof levelNames;

/**
 * Descriptions for level facets used in onboarding experience.
 */
export const levelDescriptions = {
	advanced: loc_levelDescription_advanced,
	beginner: loc_levelDescription_beginner,
	intermediate: loc_levelDescription_intermediate
} as { [level: string]: string };

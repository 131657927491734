//
// This file is generated by 'strings\build\taxonomy.js'
// If you need to make a change, update the build script
//

import {
	loc_roles_administrator,
	loc_roles_ai_edge_engineer,
	loc_roles_ai_engineer,
	loc_roles_auditor,
	loc_roles_business_analyst,
	loc_roles_business_owner,
	loc_roles_business_user,
	loc_roles_data_analyst,
	loc_roles_data_engineer,
	loc_roles_data_scientist,
	loc_roles_database_administrator,
	loc_roles_developer,
	loc_roles_devops_engineer,
	loc_roles_functional_consultant,
	loc_roles_maker,
	loc_roles_privacy_manager,
	loc_roles_risk_practitioner,
	loc_roles_security_engineer,
	loc_roles_solution_architect,
	loc_roles_student,
	loc_roles_technology_manager
} from '@msdocs/strings';

export const roleNames = {
	administrator: loc_roles_administrator,
	'ai-edge-engineer': loc_roles_ai_edge_engineer,
	'ai-engineer': loc_roles_ai_engineer,
	auditor: loc_roles_auditor,
	'business-analyst': loc_roles_business_analyst,
	'business-owner': loc_roles_business_owner,
	'business-user': loc_roles_business_user,
	'data-analyst': loc_roles_data_analyst,
	'data-engineer': loc_roles_data_engineer,
	'data-scientist': loc_roles_data_scientist,
	'database-administrator': loc_roles_database_administrator,
	developer: loc_roles_developer,
	'devops-engineer': loc_roles_devops_engineer,
	'functional-consultant': loc_roles_functional_consultant,
	maker: loc_roles_maker,
	'privacy-manager': loc_roles_privacy_manager,
	'risk-practitioner': loc_roles_risk_practitioner,
	'security-engineer': loc_roles_security_engineer,
	'solution-architect': loc_roles_solution_architect,
	student: loc_roles_student,
	'technology-manager': loc_roles_technology_manager
};

export type RoleId = keyof typeof roleNames;

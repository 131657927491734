import { process401Response } from '../auth/service';
import { CourseSchedulingCriteria, LocationCriteria, SearchLocation } from '../course/course-types';
import { apis } from '../environment/apis';
import { createRequest, fetchWithTimeout } from '../fetch';
import { msDocs } from '../globals';
import { toQueryString } from '../query-string';

/**
 * API call to return course results for the specified filter. Currently, because thr
 * Worldwide Learning API does not send back a count, paging doesn't have an upper bound.
 * @param criteria Search criteria for course search.
 */
export async function getCourseScheduling(
	criteria: CourseSchedulingCriteria
): Promise<CourseResponse> {
	const url = apis.learn.iltCourses.scheduling;
	const query = {
		eventId: criteria.courseId
			.replace(/^course\./, '')
			.replace(/-test$/, '')
			.toUpperCase(),
		location: criteria.location ? criteria.location.location : undefined,
		startDate: criteria.startDate
			? criteria.startDate.toISOString().split('.')[0]
			: new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString().split('.')[0],
		locale: criteria.locale || msDocs.data.userLocale,
		visitorId: criteria.visitorId,
		skip: criteria.skip || 0,
		top: criteria.top,
		modality: criteria.virtualOnly ? 'vilt' : 'ilt'
	};

	const request = createRequest(`${url}?${toQueryString(query)}`);
	request.headers.set('x-api-version', 'v1');
	const response = await fetchWithTimeout(request).then(process401Response);
	if (response.ok) {
		// Account for the API not sending back count data.
		const payload = await response.json();
		if (payload.hasOwnProperty('count')) {
			return payload as CourseResponse;
		}

		return {
			count: query.skip ? Infinity : payload.length,
			results: payload as IltCourseSchedule[]
		};
	}

	if (response.status === 404) {
		return {
			count: 0,
			results: []
		};
	}

	throw { error: response.json() };
}

/**
 * Memoize the initial location response since it doesn't support term filtering yet.
 * TODO: Remove when the Worldwide Learning API supports term filtering. -jeahyoun 20200331
 */
let locationPromise: Promise<SearchLocation[]>;

/**
 * API call to return locations that are administering courses within a date threshold of current date
 * for the current course ID. Currently, we memoize the API call and do sorting and filtering on the client.
 * This will change when the Worldwide Learning API supports term-based search.
 * @param criteria Search criteria for location search.
 */
export async function searchLocation(criteria: LocationCriteria): Promise<SearchLocation[]> {
	if (locationPromise !== undefined) {
		return (await locationPromise)
			.filter(x =>
				criteria.searchTerm
					? x.displayName.toLocaleLowerCase().indexOf(criteria.searchTerm.toLocaleLowerCase()) > -1
					: true
			)
			.sort((a, b) => a.displayName.localeCompare(b.displayName));
	}

	const url = apis.learn.iltCourses.location;
	const query = {
		eventId: criteria.courseId.replace(/^course\./, '').replace(/-test$/, ''),
		locale: criteria.locale
	};

	const request = createRequest(`${url}?${toQueryString(query)}`);
	request.headers.set('x-api-version', 'v1');
	const response = await fetchWithTimeout(request);
	if (response.ok) {
		locationPromise = response.json();

		return (await locationPromise)
			.filter(x =>
				criteria.searchTerm
					? x.displayName.toLocaleLowerCase().indexOf(criteria.searchTerm.toLocaleLowerCase()) > -1
					: true
			)
			.sort((a, b) => a.displayName.localeCompare(b.displayName));
	}

	if (response.status === 404) {
		return [];
	}

	throw { error: response.json() };
}

export interface CourseResponse {
	results: IltCourseSchedule[];
	count: number;
}

export interface CourseTime {
	date: string;
	startTime: string;
	endTime: string;
}

export interface IltCourseSchedule {
	id: string;
	partnerName: string;
	languageCode: string;
	schedule: {
		startDate: string;
		timezone: string;
		values: CourseTime[];
	};
	price: string;
	location: string;
	address: string;
	partnerCheckoutUrl: string;
	modality: 'ilt' | 'vilt';
}

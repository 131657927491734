import { msDocs, window } from '../globals';
import { updateQueryString } from '../query-string';
import { removeMonikerFallbackMessage } from './fallback-message';
import { filterContentByMoniker } from './filter-content';
import { getMoniker, monikerChangedEvent, sameMonikerSelectedEvent } from './moniker';
import { pageMonikers } from './page-monikers';

function canHandleClientSide(moniker: string | null) {
	return pageMonikers[moniker] !== undefined || msDocs.data.pageTemplate === 'ApiBrowserPage';
}

function monikerChangedHandler() {
	const moniker = getMoniker();

	const isClientSide = canHandleClientSide(moniker);

	// update the query string with the new moniker and clear the viewFallbackFrom arg.
	updateQueryString({ view: moniker, viewFallbackFrom: null }, isClientSide ? 'pushState' : 'href');

	if (isClientSide) {
		removeMonikerFallbackMessage();
		filterContentByMoniker();
	}
}

function sameMonikerSelectedHandler() {
	// clear the fallback message.
	updateQueryString({ viewFallbackFrom: null }, 'replaceState');
	removeMonikerFallbackMessage();
}

export function handleMonikerChange() {
	window.addEventListener(monikerChangedEvent, monikerChangedHandler);
	window.addEventListener(sameMonikerSelectedEvent, sameMonikerSelectedHandler);
}

import { loc_dark, loc_highContrast, loc_light } from '@msdocs/strings';
import { prefersColorSchemeDarkQuery } from '../src/match-media';
import { EventBus, eventBus } from './event-bus';
import { contentLoaded } from './globals';
import { localStorage } from './protected-storage';
import { parseQueryString } from './query-string';

// * Global

/**
 * The globally available descriptor of the current theme. It is updated each time setTheme is called.
 */
export let currentTheme: ThemeType = 'light';

// * Types

export type ThemeType = keyof ThemeTypeMap;

export interface ThemeTypeInfo {
	documentClass: string;
	name: string;
	text: string;
	icon: string;
}

export interface ThemeTypeMap {
	light: ThemeTypeInfo;
	dark: ThemeTypeInfo;
	'high-contrast': ThemeTypeInfo;
}

// * Data

export const themeInfo: ThemeTypeMap = {
	light: {
		documentClass: 'theme-light',
		name: 'light',
		text: loc_light,
		icon: 'docon-sun'
	},
	dark: {
		documentClass: 'theme-dark',
		name: 'dark',
		text: loc_dark,
		icon: 'docon-clear-night'
	},
	'high-contrast': {
		documentClass: 'theme-high-contrast',
		name: 'high-contrast',
		text: loc_highContrast,
		icon: 'docon-clear-night'
	}
};

export const themes = Object.keys(themeInfo) as ThemeType[];
export const documentClasses = themes.map(theme => themeInfo[theme].documentClass);

// * Events

export interface ThemeChangeInfo {
	currentTheme: ThemeType;
	previousTheme: ThemeType;
}

/**
 * Global Event that fires when the visual theme is changed.
 * @param currentTheme A string descriptor of the current theme (the theme just applied).
 * @param previousTheme A string descriptor of the previous theme.
 */
export class ThemeChangedEvent implements ThemeChangeInfo {
	constructor(public readonly currentTheme: ThemeType, public readonly previousTheme: ThemeType) {}
}

export function publishThemeChangedEvent(
	bus: EventBus,
	currentTheme: ThemeType,
	previousTheme: ThemeType
) {
	bus.publish(new ThemeChangedEvent(currentTheme, previousTheme));
	// used by theme polyfill:
	window.dispatchEvent(
		new CustomEvent<ThemeChangeInfo>('theme-changed', { detail: { currentTheme, previousTheme } })
	);
}

// * Elements -> functions that modify elements

export function setThemeClass(appliedTheme: ThemeType) {
	const htmlClassList = document.documentElement.classList;
	for (const docClass of documentClasses) {
		htmlClassList.remove(docClass);
	}

	htmlClassList.add(themeInfo[appliedTheme].documentClass);
}

export function setGlobalThemeValue(appliedTheme: ThemeType) {
	return (currentTheme = appliedTheme);
}

export function removeThemeDoconClasses(
	themeInfo: ThemeTypeMap,
	element: HTMLElement
): HTMLElement {
	for (const key in themeInfo) {
		element.classList.remove(themeInfo[key as keyof ThemeTypeMap].icon);
	}
	return element;
}

function addSelectedClassToControls(themeToApply: string) {
	const themeControlButtons = Array.from(
		document.querySelectorAll('.theme-control[data-theme-to]')
	) as HTMLElement[];
	for (const elt of themeControlButtons) {
		if (elt.dataset.themeTo === themeToApply) {
			elt.classList.add('is-selected');
			elt.setAttribute('aria-pressed', 'true');
		} else {
			elt.classList.remove('is-selected');
			elt.setAttribute('aria-pressed', 'false');
		}
	}
}

// * LocalStorage

export function storeTheme(theme: ThemeType) {
	localStorage.setItem('theme', theme);
}

export function getPreferredTheme(prefersDarkTheme: boolean = false): ThemeType {
	// check for user selection
	const theme = localStorage.getItem('theme');
	if (/^light|dark|high-contrast$/.test(theme)) {
		return theme as ThemeType;
	}

	// check for system dark preference
	if (prefersDarkTheme) {
		return 'dark';
	}

	// default to light
	return 'light';
}

/**
 * SetTheme is the public way of changing the visual theme. It ensures we use the correct EventBus instance, while allow the user of the function to remain free of such implementation detail..
 *
 * @param theme The ThemeType to apply to the current window.
 */
export function setTheme(theme: ThemeType) {
	setThemeInternal(eventBus, theme);
}

export function setThemeInternal(bus: EventBus, appliedTheme: ThemeType) {
	const previousTheme = currentTheme;
	setGlobalThemeValue(appliedTheme);
	setThemeClass(appliedTheme);
	if (previousTheme === appliedTheme) {
		return;
	}
	publishThemeChangedEvent(bus, appliedTheme, previousTheme);
}

// * Initialization

export async function initTheme(bus: EventBus) {
	const theme = getInitialTheme();

	setThemeInternal(bus, theme);
	initThemeControls(bus);

	await contentLoaded;
	addSelectedClassToControls(theme);

	// screenshot build
	(window as any).setTheme = setTheme;
}

export function getInitialTheme(
	prefersDarkTheme: boolean = prefersColorSchemeDarkQuery.matches
): ThemeType {
	const args = parseQueryString();

	// * check for query arguments first
	if (args.theme === 'light' || args.theme === 'dark' || args.theme === 'high-contrast') {
		return args.theme as ThemeType;
	}

	return getPreferredTheme(prefersDarkTheme);
}

export function initThemeControls(bus: EventBus): void {
	window.addEventListener('click', ({ target }: Event) => {
		const button =
			target instanceof Element && (target.closest('.theme-control[data-theme-to]') as HTMLElement); // ? .theme-control class is to avoid collisions with theme toggle

		if (!button) {
			return;
		}

		const themeToApply = button.dataset.themeTo as ThemeType;

		addSelectedClassToControls(themeToApply);
		storeTheme(themeToApply);
		setThemeInternal(bus, themeToApply);
	});
}

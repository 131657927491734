import { assessmentApi } from '../apis/assessments';
import { user } from '../auth/user';
import { localStorage } from '../protected-storage';
import { updateUrlSearchFromMap } from '../query-string';
import { getSessionLocal } from './session';
import {
	assessmentAuthReferralKey,
	AssessmentSearchParams,
	assessmentSignOutReferralKey
} from './types';
import { assessmentStorageKey } from './view-model';

export async function handleAssessmentAuthReferral(query: AssessmentSearchParams) {
	const signInHandledQuery = await handelAuthReferralSessionUpload(query);
	return handleSignOutDeleteLocalSession(signInHandledQuery);
}

/**
 * Handle both session saving and query modification in the case the user is coming back from being signed in
 * @param query The query object representing the URL search params.
 */
async function handelAuthReferralSessionUpload(query: AssessmentSearchParams) {
	if (!query[assessmentAuthReferralKey] || !user.isAuthenticated) {
		return query;
	}
	const localSession = getSessionLocal();
	if (
		!localSession ||
		!localSession.assessmentId ||
		localSession.id !== 'local' ||
		localSession.status === 'not-started'
	) {
		location.search = updateUrlSearchFromMap({
			[assessmentAuthReferralKey]: null
		}).search;
		return query;
	}

	delete localSession.id; // ids can't be posted unless they exist in the database
	const updateLoadedSession = await assessmentApi.saveSession(localSession);

	localSession.id = updateLoadedSession.id;
	localStorage.setItem(assessmentStorageKey, JSON.stringify(localSession));

	delete query.id; // don't need the assessment property as the session is should be used
	delete query[assessmentAuthReferralKey]; // don't need auth referral key either now that we've saved
	query.session = updateLoadedSession.id;
	location.search = updateUrlSearchFromMap({
		...query,
		[assessmentAuthReferralKey]: null
	}).search;

	return query;
}

function handleSignOutDeleteLocalSession(query: AssessmentSearchParams) {
	if (!query[assessmentSignOutReferralKey] || user.isAuthenticated) {
		return query;
	}
	localStorage.removeItem(assessmentStorageKey);
	location.search = updateUrlSearchFromMap({
		...query,
		[assessmentSignOutReferralKey]: null
	}).search;
	return query;
}

import 'focus-visible';
import { affix, notifyContentUpdated } from './affix';
import { checkIsArchived, handleArchive } from './archive';
import { formatArticleDate } from './article-date';
import { initBinaryRating } from './article-rating/binary-rating';
import { initAuth } from './auth/index';
import { renderAzureSelectors } from './azure-selector';
import { fingerprint } from './risk-evaluation/fingerprint';
import { azureSandbox } from './azure/sandbox';
import { prepareTenantInvite } from './azure/sandbox-prompt/prepare-invite';
import { initTokenRenewal } from './azure/token-renewal';
import { jsllReady, track as bi } from './bi';
import { displayTranslations, handleEngContentToggle } from './bilingual';
import { renderBranchSelector } from './branch-selector';
import { interceptCopy } from './clipboard';
import { makeCodeBlocks } from './code/blocks';
import { buildHtmlProfiles, setupMap } from './community/profiles-map';
import { initResourceGroupNameElements } from './components/rgn-element';
import { initShowMoreSections } from './components/show-more';
import { initZonePivots } from './components/zone-pivot';
import { fixContent } from './content-adjustments/index';
import { initContentTables } from './content-adjustments/modify-tables';
import './contributors';
import { cookieConsent, initCookieConsent } from './cookie-consent';
import { cookies } from './cookies';
import { detectHighContrast } from './detect-high-contrast';
import { setupDismiss } from './dismiss';
import { initDropdowns } from './dropdown';
import { updateEditLink } from './edit-link';
import { features } from './environment/features';
import { eventBus } from './event-bus';
import './feedback-report';
import { initFeedbackReport } from './feedback-report/index';
import { initFeedback } from './feedback/index';
import { contentLoaded, document, msDocs } from './globals';
import { siteContext } from './header/site-context';
import { handleHeadings } from './heading-anchors';
import { renderInTopicTOC } from './in-topic-toc';
import './input-validity';
import { enableAppInsights } from './instrumentation/app-insights';
import { enableGoogleAnalytics } from './instrumentation/google-analytics';
import { enableSpeedcurve } from './instrumentation/speedcurve';
import { initActionPanel } from './interactivity/action-panel';
import './interactivity/cloud-shell';
import './interactivity/dotnet-online';
import './interactivity/msgraph';
import './interactivity/rest';
import { basePathname } from './is-production';
import { showLevelStatus } from './learn/level-status';
import { initializeLightBox } from './lightbox';
import { initListButtons, synchronizeListButtons } from './lists';
import { loadLibrary } from './load-library';
import { setDocumentLocale, setupLocaleLinks } from './locale';
import { getMeta } from './meta';
import { renderAppliesTo } from './monikers/applies-to';
import { displayMonikerFallbackMessage } from './monikers/fallback-message';
import {
	filterContentByMoniker,
	tempAddPreserveViewClass,
	tempFixPreserveViewArgument
} from './monikers/filter-content';
import { getMoniker } from './monikers/moniker';
import { pageSupportsMoniker } from './monikers/page-monikers';
import { initMobileModalPageActions } from './page-actions/modal-page-actions';
import { pageTemplateSpecific } from './pages/index';
import { preview } from './preview/query-string';
import { handleProtocolRendering } from './protocol-rendering';
import { parseQueryString } from './query-string';
import { renderRecommendations } from './recommendations/index';
import { handleRedirected } from './redirected';
import { RouterAfterNavigateEvent } from './router/events';
import { rtlDictionary } from './rtl-dictionary';
import { initSharingLinks, removeSharePageAction } from './sharing';
import { handleSimpleTabs } from './simple-tabs';
import { initTabs } from './tabs';
import { checkInnerTextSupported, escape } from './text-formatting';
import { initTheme } from './theme-selection';
import { setupToc } from './toc-setup';
import { initTocBreadcrumbsAndPdf } from './toc/index';
import { enableTwitterWidgets } from './twitter-widgets';
import { initHeader } from './uhf';
import { initUsabilla } from './usabilla';
import { processOnePlayerFrames, processVideoFrames } from './video-adjustments';
import { adobeTarget } from './instrumentation/adobe-target';

msDocs.data.rtl = rtlDictionary;
msDocs.data.jsllReady = jsllReady;
msDocs.data.cookieConsent = cookieConsent;
msDocs.data.isArchived = checkIsArchived();
msDocs.functions.notifyContentUpdated = notifyContentUpdated;
msDocs.functions.escape = escape;
msDocs.functions.cookies = cookies;
msDocs.functions.loadLibrary = loadLibrary;
msDocs.functions.parseQueryString = parseQueryString;
msDocs.functions.buildHtmlProfiles = buildHtmlProfiles;

fingerprint();
initTheme(eventBus);
setDocumentLocale();
initHeader(siteContext);
initTocBreadcrumbsAndPdf();
setupDismiss();
bi();
interceptCopy();
initCookieConsent();
adobeTarget();
initAuth();
initActionPanel();
initZonePivots();
initTokenRenewal();
enableGoogleAnalytics();
enableAppInsights();
prepareTenantInvite();
enableSpeedcurve();
enableTwitterWidgets();

const isLearnPage = basePathname === 'learn';
if (!isLearnPage) {
	initUsabilla('cd99660205c0');
}

contentLoaded.then(() => {
	const mainContainer = document.getElementById('main');

	detectHighContrast();
	updateEditLink(document.documentElement);
	formatArticleDate();
	setupToc();
	makeCodeBlocks(); // must run before renderInTopicTOC to ensure dev-lang specific h2 content is hidden appropriately
	handleSimpleTabs(mainContainer);

	handleHeadings(document.querySelector('.content')); // Must run before moniker content filtering to avoid id duplication.

	if (pageSupportsMoniker(getMoniker())) {
		tempFixPreserveViewArgument(mainContainer);
		tempAddPreserveViewClass(mainContainer);
		filterContentByMoniker();
	} else {
		renderInTopicTOC();
	}

	displayMonikerFallbackMessage();
	setupMap();
	handleRedirected();
	handleArchive();
	affix();
	initTabs(document.body);
	checkInnerTextSupported();

	const monikerContainer = document.querySelector('.moniker-applies-to');
	if (monikerContainer) {
		renderAppliesTo(monikerContainer);
	}

	displayTranslations(msDocs.data.contentLocale);
	processVideoFrames(mainContainer);
	initDropdowns(document.body);
	initListButtons(document.body);

	if (!features.userServices || !msDocs.data.hasShare) {
		removeSharePageAction();
	} else {
		initSharingLinks(
			document.body,
			location.origin + location.pathname + location.search,
			document.title
		);
	}

	initBinaryRating(document.body, checkIsArchived());

	renderRecommendations();

	pageTemplateSpecific();

	initFeedback();

	initFeedbackReport('reportIssue');

	renderBranchSelector();
	renderAzureSelectors();
	fixContent();
	initializeLightBox(mainContainer);
	initContentTables();
	// devSetupEnglishToggle(); // uncomment to test functionality with Fiddler or see button locally
	handleEngContentToggle(document.querySelector('.lang-toggle-container'));

	initShowMoreSections(mainContainer);
	initResourceGroupNameElements(document.body, eventBus, azureSandbox);
	showLevelStatus();
	handleProtocolRendering(Array.from(document.querySelectorAll('.content')));

	initMobileModalPageActions();

	previewExternal();
});

eventBus.subscribe(RouterAfterNavigateEvent, () => {
	const mainContainer = document.getElementById('main');

	setupLocaleLinks(msDocs.data.userLocale);
	initZonePivots();
	pageTemplateSpecific();
	makeCodeBlocks();
	fixContent();
	initContentTables();
	initResourceGroupNameElements(document.body, eventBus, azureSandbox);
	synchronizeListButtons(document.body);
	processOnePlayerFrames(document.querySelector('main'), msDocs.data.userLocale);
	initializeLightBox(mainContainer);

	if (getMeta('show_latex') === 'true') {
		//@ts-ignore - mathjax in master
		MathJax.Hub.Queue(['Typeset', MathJax.Hub, mainContainer]);
	}
});

// temp code to identify external refs in content
function previewExternal() {
	if (!preview('external-refs', { persist: true, require: true })) {
		return;
	}
	const { protocol, origin } = location;
	document.head.insertAdjacentHTML(
		'beforeend',
		`
		<style>
			@keyframes external-ref {
				0%   { opacity: 1; }
				50%  { opacity: .3; }
				100% { opacity: 1; }
			}

			a[href^="${protocol}"]:not([href^="${origin}"]),
			img[src^="${protocol}"]:not([src^="${origin}"]),
			iframe[src^="${protocol}"]:not([src^="${origin}"]) {
				animation: external-ref 1.2s infinite;
			}
		</style>`
	);
}

import {
	loc_completedUnits,
	loc_continue,
	loc_relatedLearningPaths,
	loc_remaining,
	loc_start
} from '@msdocs/strings';
import { getModule, LearnItem, ProgressItem, Module, getUserProgressByUids } from '../apis/learn';
import { user } from '../auth/user';
import { contentLoaded, msDocs } from '../globals';
import { renderLearnBreadcrumbs } from '../learn/breadcrumbs';
import { checkForInteractiveDisclaimer } from '../learn/interactive-disclaimer';
import { displayModuleRating } from '../learn/module-rating';
import {
	convertToLearnItemsToProgressItems,
	handleXpTag,
	removeStartUnitButton,
	renderStartContinueUnitButton,
	syncUserProgress,
	updateProgressElements
} from '../learn/unit-progress';
import { getMeta } from '../meta';
import { convertMinsToHrsMins } from '../time-format';
import { consolidateModuleAndProgress } from '../learn/module';

export async function modulePage(container: HTMLElement) {
	const moduleId = getMeta('uid');
	const userLocale = msDocs.data.userLocale;
	await syncUserProgress();

	return Promise.all([loadModuleInformation(moduleId), contentLoaded]).then(([module]) => {
		const xpTags = Array.from(container.querySelectorAll('.xp-tag')) as HTMLElement[];
		const hasRemaining =
			module.remainingTime !== null &&
			module.remainingTime !== undefined &&
			module.remainingTime !== 0;
		const minutesRemaining = hasRemaining ? module.remainingTime : module.durationInMinutes;
		const moduleTimeHolder = container.querySelector('.module-duration-minutes');
		const unitList = container.querySelector('#unit-list') as HTMLElement;
		const learningPathList = container.querySelector('#parent-learning-paths');

		renderLearnBreadcrumbs(module.title, module);
		handleXpTag(xpTags, [module, ...module.units], module.uid);
		displayModuleDuration(moduleTimeHolder, minutesRemaining, hasRemaining);
		displayModuleUnitDuration(unitList, module.units);

		displayModuleRating(container, module);
		displayRelatedLearningPaths(learningPathList, module.parents);

		if (!user.isAuthenticated) {
			// Use first unit as start unit
			renderStartContinueUnitButton(
				container.querySelector<HTMLDivElement>('#module-actions'),
				`/${userLocale}${module.units[0].url}`,
				loc_start,
				'start'
			);
		}

		user.whenAuthenticated().then(() => {
			if (module.status === 'notStarted' || module.status === undefined) {
				renderStartContinueUnitButton(
					container.querySelector<HTMLDivElement>('#module-actions'),
					`/${userLocale}${module.units[0].url}`,
					loc_start,
					'start'
				);
			} else {
				const units: ProgressItem[] = convertToLearnItemsToProgressItems(module.units);
				updateProgressElements(units);
				let unitsCompletedCount: number = 0;

				if (module.status === 'inProgress') {
					// check if the continue button has been rendered already
					let continueButtonRendered = false;
					for (const unit of module.units) {
						if (unit.status !== 'completed') {
							if (!continueButtonRendered) {
								renderStartContinueUnitButton(
									container.querySelector<HTMLDivElement>('#module-actions'),
									`/${userLocale}${unit.url}`,
									loc_continue,
									'continue'
								);
								continueButtonRendered = true;
							}
						} else {
							unitsCompletedCount++;
						}
					}
				} else {
					removeStartUnitButton(true);
				}

				if (unitsCompletedCount > 0 && unitsCompletedCount !== units.length) {
					displayNumUnitsCompleted(
						container.querySelector('.module-unit-count'),
						unitsCompletedCount,
						units.length
					);
				}
			}
		});

		checkForInteractiveDisclaimer([module]);
	});
}

export function loadModuleInformation(moduleId: string): Promise<Module> {
	if (!user.isAuthenticated) {
		return getModule(msDocs.data.userLocale, moduleId);
	}

	return getModuleWithProgress(moduleId);
}

export function displayRelatedLearningPaths(learningPathList: Element, parents: LearnItem[]) {
	const template = (paths: LearnItem[]) => `
	<h2 class="title is-6 has-margin-top-medium has-margin-bottom-none">${loc_relatedLearningPaths}</h2>
	<ul class="has-margin-top-none has-margin-left-none has-margin-right-none">
		${paths
			.map(
				path => `<li class="is-unstyled is-size-small"><a href="${path.url}">${path.title}</a></li>`
			)
			.join('')}
	</ul>
	`;

	if (parents.length > 0) {
		learningPathList.innerHTML = template(parents);
	} else {
		learningPathList.innerHTML = '';
	}
}

export function displayModuleDuration(
	moduleTimeHolder: Element,
	timeInMinutes: number,
	hasRemaining: boolean = false
) {
	const appendedText = hasRemaining ? ` ${loc_remaining}` : '';
	moduleTimeHolder.innerHTML = `${convertMinsToHrsMins(timeInMinutes)}${appendedText}`;
}

export function displayModuleUnitDuration(unitList: HTMLElement, units: LearnItem[]) {
	units.forEach(unit => {
		const duration = unitList.querySelector(`li[data-unit-uid='${unit.uid}'] .unit-duration`);
		if (!duration) {
			return;
		}
		duration.textContent = convertMinsToHrsMins(unit.durationInMinutes);
	});
}

export function displayNumUnitsCompleted(
	unitCountHolder: Element,
	unitsCompletedCount: number,
	totalUnitsCount: number
) {
	unitCountHolder.innerHTML = loc_completedUnits
		.replace('{completedCount}', unitsCompletedCount.toString())
		.replace('{totalCount}', totalUnitsCount.toString());
}

function getModuleWithProgress(moduleId: string): Promise<Module> {
	return Promise.all([
		getModule(msDocs.data.userLocale, moduleId),
		getUserProgressByUids([moduleId])
	]).then(([module, items]) => {
		return consolidateModuleAndProgress(module, items);
	});
}

import { authStatusDetermined } from '../auth';
import { features } from '../environment/features';
import { contentLoaded } from '../globals';
import { getQueryStringMap } from '../query-string';
import { handleAssessmentAuthReferral } from './auth-referral';
import { renderAssessmentsBreadcrumbs } from './breadcrumbs';
import { updateAssessmentChrome } from './element-updates';
import { renderAssessmentMode } from './modes';
import { getAssessmentNavigationStep } from './navigation';
import { getTitleForNavigationAction, initAssessmentRouter } from './routing';
import { AssessmentSearchParams } from './types';

export async function assessmentsHomePage() {
	if (!features.assessments) {
		return;
	}
	await Promise.all([authStatusDetermined, contentLoaded]);

	const query = await handleAssessmentAuthReferral(getQueryStringMap() as AssessmentSearchParams);
	const navigationAction = await getAssessmentNavigationStep(query);

	const hero = document.getElementById('assessment-home-hero') as HTMLElement;
	const view = document.getElementById('assessment-content-container') as HTMLElement;

	initAssessmentRouter(view, hero);

	const title = getTitleForNavigationAction(navigationAction);
	document.title = title;
	renderAssessmentsBreadcrumbs(navigationAction);

	await renderAssessmentMode(navigationAction, view, hero);
	updateAssessmentChrome(new URL(location.href), title);
}

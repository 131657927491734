import {
	loc_cloudShellTroubleshoot,
	loc_configuringCloudShellForSandbox,
	loc_products_azure_cloud_shell,
	loc_restart,
	loc_tryIt
} from '@msdocs/strings';
import { azureOAuthLogin } from '../azure/oauth';
import { azureSandbox } from '../azure/sandbox';
import { configureCloudShell, deleteCloudShellSession } from '../azure/sandbox-configuration';
import { azureToken } from '../azure/token';
import { contentAttrs } from '../bi';
import { document, location, msDocs, window } from '../globals';
import { appInsights } from '../instrumentation/app-insights';
import { escape } from '../text-formatting';
import { InteractiveComponent, registerInteractiveType } from './activation';

//
/**
 * DF        https://azconsole-df.azurewebsites.net
 * RC        https://ux-rc.console.azure.com
 * MS Portal https://ux-mpac.console.azure.com
 * PROD      https://ux.console.azure.com
 */
export const cliPageOrigin = 'https://ux.console.azure.com';
const messageHandlerResponseTime: number = 10000;

export class CloudShell implements InteractiveComponent {
	public readonly element: HTMLDivElement;
	private consoleFrame: HTMLIFrameElement;
	private messageHandlerTimeout: number;
	private messageReceived = false;
	private interactiveType: string;

	constructor(public readonly isPowerShell: boolean) {
		window.addEventListener('message', this.messageHandler);

		this.element = document.createElement('div');
		this.element.classList.add('cloud-shell');
		this.element.setAttribute(contentAttrs.name, 'azure-cli');
		this.messageHandlerTimeout = 0;
		this.interactiveType = isPowerShell ? 'powershell' : 'bash';

		azureOAuthLogin(this.element)
			.then(() => {
				this.element.classList.add(
					`has-text-${this.interactiveType}-invert`,
					`has-background-${this.interactiveType}`
				);

				if (azureSandbox.value) {
					this.element.innerHTML = `
						<div class="is-monospace is-size-small has-padding-medium is-full-height is-vertically-scrollable">
							<p>Azure Cloud Shell</p>
							<p>${loc_configuringCloudShellForSandbox}</p>
						</div>`;
					return deleteCloudShellSession(azureToken.value).then(() =>
						configureCloudShell(azureSandbox.value, azureToken.value, isPowerShell)
					);
				}
				return Promise.resolve();
			})
			.then(() => this.whenPageVisible())
			.then(() => {
				const lang = getCloudShellLanguage(msDocs.data.userLocale);
				this.element.innerHTML = `
					<div class="cloud-shell-header level is-mobile has-margin-bottom-none">
						<div class="level-left">
							<div class="level-item">
								<button title="${escape(
									loc_restart
								)}" class="button is-icon-only has-border-none is-radiusless has-inner-focus" data-bi-name="restart">
									<span class="icon">
										<span class="is-size-small docon docon-navigate-refresh" aria-hidden="true"></span>
									</span>
									<span class="is-size-small visually-hidden">${escape(loc_restart)}</span>
								</button>
								<h2 class="is-size-small has-text-weight-normal has-padding-left-small cloud-shell-header-title">Azure Cloud Shell</h2>
							</div>
						</div>
					</div>
					<iframe	class="cloud-shell-frame" title="${loc_products_azure_cloud_shell}"
									src="${cliPageOrigin}?trustedAuthority=${location.origin}&embed=true&feature.azureconsole.ostype=${
					isPowerShell ? 'windows' : 'linux'
				}&l=${lang}"
									frameborder="0">
					</iframe>`;

				const restartButton = this.element.querySelector(
					'button[data-bi-name="restart"]'
				) as HTMLButtonElement;
				restartButton.onclick = () => this.restart();
				this.consoleFrame = this.element.querySelector('iframe');
				this.messageHandlerTimeout = setTimeout(
					this.instrumentMessageHandler,
					messageHandlerResponseTime
				);
			});
	}

	public setCode() {
		return Promise.resolve();
	}

	public execute() {
		return Promise.resolve();
	}

	public restart() {
		if (this.consoleFrame.hidden) {
			return;
		}
		this.consoleFrame.contentWindow.postMessage(
			{
				signature: 'portalConsole',
				type: 'restart'
			},
			cliPageOrigin
		);
	}

	public dispose() {
		window.removeEventListener('message', this.messageHandler);
	}

	private replyToken(audience: string) {
		let token: string;
		switch (audience) {
			case '':
				token = azureToken.value.access_token;
				break;
			case 'graph':
				token = azureToken.value.graph_access_token;
				break;
			case 'keyvault':
				token = azureToken.value.key_vault_access_token;
				break;
			default:
				throw new Error(`Unsupported token audience: "${audience}".`);
		}

		this.consoleFrame.contentWindow.postMessage(
			{
				signature: 'portalConsole',
				type: 'postToken',
				audience,
				message: `Bearer ${token}`
			},
			cliPageOrigin
		);
	}

	private messageHandler = ({ data: { signature, type, audience }, origin }: MessageEvent) => {
		if (!this.consoleFrame || !this.consoleFrame.contentWindow) {
			// iframe was detached from the DOM
			return;
		}
		if (origin !== cliPageOrigin || signature !== 'portalConsole' || type !== 'getToken') {
			return;
		}

		if (!this.messageReceived) {
			appInsights.trackEvent({ name: 'cloud-shell-message-received' });
			this.messageReceived = true;
		}
		clearTimeout(this.messageHandlerTimeout);
		this.replyToken(audience);
	};

	private whenPageVisible() {
		if (document.visibilityState === 'visible') {
			return Promise.resolve();
		}
		return new Promise<void>(resolve =>
			document.addEventListener('visibilitychange', () => {
				if (document.visibilityState === 'visible') {
					resolve();
				}
			})
		);
	}

	private instrumentMessageHandler = () => {
		if (!this.consoleFrame || !this.consoleFrame.contentWindow) {
			return;
		}

		// fire when:
		// The cloud shell iframe element has been added to the page
		// ${messageHandlerResponseTime} have elapsed since the iframe element was added to the page
		// The "messageHandler" function has *not* been invoked
		appInsights.trackEvent({ name: 'cloud-shell-not-responsive' });
		this.showTroubleshootingMessage();
	};

	private showTroubleshootingMessage = () => {
		this.element.innerHTML = `
			<div class="is-monospace is-size-small has-${this.interactiveType}-colors has-padding-medium is-full-height is-vertically-scrollable">
				<p><em>${loc_cloudShellTroubleshoot}</em></p>
			</div>
		`;
	};
}

const activateButtonConfig = {
	name: loc_tryIt,
	iconClass: 'docon docon-terminal',
	attributes: [{ name: 'aria-haspopup', value: 'true' }]
};

registerInteractiveType({
	name: 'bash',
	activateButtonConfig,
	create: () => new CloudShell(false)
});

registerInteractiveType({
	name: 'powershell',
	activateButtonConfig,
	create: () => new CloudShell(true)
});

function getCloudShellLanguage(locale: string) {
	switch (locale) {
		case 'zh-cn':
			return 'zh-hans';
		case 'zh-hk':
			return 'zh-hans';
		case 'zh-tw':
			return 'zh-hant';
	}
	const cloudShellSupports = [
		'en',
		'cs',
		'de',
		'es',
		'fr',
		'hu',
		'it',
		'ja',
		'ko',
		'nl',
		'pl',
		'pt-br',
		'pt-pt',
		'ru',
		'sv',
		'tr',
		'zh-hans',
		'zh-hant'
	];
	const match = cloudShellSupports.find(lang => locale.indexOf(lang) === 0);
	return match || 'en';
}

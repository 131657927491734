import { loc_disclaimer_dismissAlert } from '@msdocs/strings';
import { notifyContentUpdated } from './affix';
import { document } from './globals';

export function showDisclaimer(
	message: string,
	link?: { url: string; text: string },
	additionalBannerClasses?: string,
	additionalButtonClasses?: string
): HTMLDivElement {
	const disclaimerHolder = document.getElementById('disclaimer-holder');
	const buttonClasses = additionalButtonClasses || 'is-info is-outlined';
	const bannerClasses = additionalBannerClasses || 'is-info';

	const buttonHTML = link
		? `
			<div class="level-right has-margin-extra-small has-margin-none-tablet">
				<div class="level-item has-flex-justify-content-start-mobile has-padding-small-tablet">
					<a href="${link.url}" class="button ${buttonClasses} is-small has-margin-left-extra-small has-margin-right-extra-small">
						<span>${link.text}</span>
					</a>
				</div>
			</div>`
		: ``;

	disclaimerHolder.insertAdjacentHTML(
		'afterbegin',
		`
			<section data-dismissable="disappearing" class="uhf-container has-padding has-padding-top-small has-padding-bottom-small ${bannerClasses} alert is-banner">
				<button data-dismiss type="button" class="delete is-absolute has-top-zero has-right-zero has-margin-extra-small is-large"><span class="visually-hidden">${loc_disclaimer_dismissAlert}</span></button>
				<div class="columns">
					<div class="column is-12">
						<div class="level">
							<div class="level-left has-flex-shrink">
								<div class="level-item has-flex-shrink">
									<p class="has-text-white-static disclaimer-title has-margin-top-extra-small has-margin-right-medium has-line-height-reset is-size-7-mobile">
										<span class="icon">
											<span class="docon docon-status-error-outline" aria-hidden="true"></span>
										</span>
										<span>${message}</span>
									</p>
								</div>
							</div>
							${buttonHTML}
						</div>
					</div>
				</div>
			</section>
			`
	);

	notifyContentUpdated();

	return disclaimerHolder.firstElementChild as HTMLDivElement;
}

import { loc_completed } from '@msdocs/strings';
import { ProductOrFamily, ProgressItem } from '../apis/learn';
import { PageRatingResult } from '../apis/rating';
import { features } from '../environment/features';
import { brandedHeader } from '../learn/branded-header';
import { bookmarkButton, collectionButton } from '../learn/cards';
import { html, TemplateResult } from '../lit-html';
import { formatNumber } from '../number-format';
import { calculateRatingFields } from '../rating/rating-dropdown';
import { createRatingTemplate } from '../rating/star-rating-display';
import { convertMinsToHrsMins } from '../time-format';
import { createTagHTML, createTagStrings, TaggedItem } from '../tags';
import { taxonomyNames } from '../name-maps/taxonomy';
import { LearnBrowseResult, SingleRequestBrowseTypes, TrendingBrowseResult } from '../apis/browse';

/**
 * Function that calls correct rendering function per specific browse type. // ? extension point for browse types
 * @param items Resultant API items from either Learn or Sample service
 * @param browseType String identifier that determines which rendering function is called.
 * @param userProgress Optional user progress. It should always be there for 'learn' browse type. Never for 'sample'.
 * @param ratings An array of ratings for rendering within module cards
 */
export function createListItemsHtml(
	items: LearnBrowseResult[],
	browseType: SingleRequestBrowseTypes,
	ratings: PageRatingResult[]
): TemplateResult {
	switch (browseType) {
		case 'home':
		case 'topiclanding':
			return createTrendingListCard(items as TrendingBrowseResult[], ratings);
		default:
			throw new Error(`Cannot create list item html for unsupported browse type: ${browseType}.`);
	}
}

function createCardProgress(
	itemProgress: ProgressItem | null,
	item: LearnBrowseResult
): TemplateResult | '' {
	if (!itemProgress || itemProgress.status === 'notStarted') {
		return '';
	}

	const isProgressElement = itemProgress.status === 'inProgress';

	if (isProgressElement) {
		const percentageComplete =
			Math.floor(
				((item.duration_in_minutes - itemProgress.remainingTime) / item.duration_in_minutes) * 100
			) + '%';
		return html`<progress
				class="progress is-success is-extra-small"
				value="${item.duration_in_minutes - itemProgress.remainingTime}"
				max="${item.duration_in_minutes}"
			></progress
			><span class="progress-label">${percentageComplete}</span>`;
	}

	return html`
		<span class="progress-label"
			>${loc_completed} <span class="docon docon-check" role="presentation"></span
		></span>
	`;
}

export function createSearchResultRatingTemplate(rating: {
	averageScore: number;
	totalCount: number;
}): TemplateResult | '' {
	if (!rating || !features.starRatings) {
		return '';
	}
	return html`
		<div class="is-size-small has-text-subtle">
			${createRatingTemplate(rating.averageScore)}
			<span>${rating.averageScore} (${formatNumber(rating.totalCount)})</span>
		</div>
	`;
}
/**
 * Get a the rating that corresponds to an item returned by the browse service
 * @param ratings An array of ratings for rendering within module cards
 * @param item The rating object corresponding to the particular learn item, or null if there is not corresponding rating.
 */
function getBrowseItemsRatingFields(
	ratings: PageRatingResult[],
	item: LearnBrowseResult
): ReturnType<typeof calculateRatingFields> | null {
	const rating = ratings.find(rating => item.uid === rating.pageId);
	if (rating) {
		return calculateRatingFields(rating);
	}
	return null;
}

/**
 * Function that renders the cards for the Learn home page, only applies to browseType: 'home'.
 * @param items The resultant items from the trending browse service API call.
 */
function createTrendingListCard(
	items: TrendingBrowseResult[],
	ratings: PageRatingResult[]
): TemplateResult {
	return html`${items.map(item => {
		const progressTag = item.progress_status
			? html`<div class="card-footer-item">
					${createCardProgress(
						{
							uid: item.uid,
							status: item.progress_status,
							remainingTime: item.remaining_time
						},
						item
					)}
			  </div>`
			: '';

		const rating = getBrowseItemsRatingFields(ratings, item);
		const ratingContainerHTML = createSearchResultRatingTemplate(rating);
		const tags: string[] = createTagStrings(item as TaggedItem);

		return html` <li class="grid-item">
			<article data-bi-name="card" class="card is-branded">
				<div class="card-header ${brandedHeader(item.products as ProductOrFamily[])}">
					<figure class="card-header-image">
						<img role="presentation" src="${item.icon_url}" alt="" />
					</figure>
				</div>
				<div class="card-content">
					<p class="card-content-super-title">
						${taxonomyNames.resource_type[item.resource_type] || item.resource_type}
					</p>
					<a href="${item.url}" class="card-content-title"><h3>${item.title}</h3></a>
					<ul class="card-content-metadata">
						<li>${convertMinsToHrsMins(item.duration_in_minutes)}</li>
						<li>${ratingContainerHTML}</li>
					</ul>
					<ul class="tags">
						${item.hidden ? html`<li class="tag is-small is-warning">Hidden</li>` : ''}
						${createTagHTML(tags)}
					</ul>
				</div>
				<div class="card-footer">
					${progressTag}
					<div class="card-footer-item">
						<div class="buttons">
							${bookmarkButton(item.title, item.url)} ${collectionButton(item.title, item.url)}
						</div>
					</div>
				</div>
			</article>
		</li>`;
	})}`;
}

// Function ensures any multi-word terms are joined with -
export function idFromTerm(term: string) {
	return term.replace(/ /g, '-');
}

import { process401Response } from '../auth/service';
import { user } from '../auth/user';
import { apis } from '../environment/apis';
import { traits } from '../environment/traits';
import { eventBus } from '../event-bus';
import { createRequest, fetchWithTimeout } from '../fetch';
import { msDocs } from '../globals';
import { TooManyAttemptsEvent } from '../learn/too-many-attempts-modal';
import { ProductId } from '../name-maps/product';
import { ProductFamilyId } from '../name-maps/product-family';
import { RoleId } from '../name-maps/roles';
import { parseQueryString, partitionValuesByEncodedLength, toQueryString } from '../query-string';

const branch = getBranch();
const locale = msDocs.data.userLocale;

// Learn API

export function getModule(locale: string, moduleId: string): Promise<Module> {
	const query = { locale, branch };
	const url = `${apis.learn.module}/${moduleId}?${toQueryString(query)}`;
	const init = { method: 'GET' };

	return fetchWithTimeout(createRequest(url, init)).then(response => {
		if (response.ok) {
			return response.json();
		}

		return Promise.reject();
	});
}

export function getModuleByUnit(locale: string, unitId: string): Promise<Module> {
	const query = { unitId, locale, branch };
	const url = `${apis.learn.module}?${toQueryString(query)}`;
	const init = { method: 'GET' };

	return fetchWithTimeout(createRequest(url, init)).then(response => {
		if (response.ok) {
			return response.json();
		}

		return Promise.reject();
	});
}

export function getLearningPath(locale: string, pathId: string): Promise<LearningPath> {
	const query = { locale, branch };
	const url = `${apis.learn.learningPath}/${pathId}?${toQueryString(query)}`;
	const init = { method: 'GET' };

	return fetchWithTimeout(createRequest(url, init)).then(response => response.json());
}

export function getBatchItemSummaries(
	locale: string,
	uids: string[]
): Promise<ModuleAndPathSummary[]>[] {
	// accepts Learning Path and Module uids, 404s and units are ignored
	const uidSets = partitionValuesByEncodedLength(uids);
	const init = { method: 'GET' };

	return uidSets.map(uidSet => {
		const query = { locale, branch, uids: uidSet.join(';') };
		const url = `${apis.learn.batchItemSummaries}?${toQueryString(query)}`;
		return fetchWithTimeout(createRequest(url, init)).then(response => {
			if (response.ok) {
				return response.json();
			}

			return Promise.reject();
		});
	});
}

export async function getModuleAndPathSummaries(
	uids: string[],
	locale: string
): Promise<ModuleAndPathSummary[]> {
	const init = { method: 'GET' } as RequestInit;
	const uidSets = partitionValuesByEncodedLength(uids);
	const items: ModuleAndPathSummary[] = [];
	const promises = uidSets.map(async uidSet => {
		const query = { locale, branch, uids: uidSet.join(';') };
		const url = `${apis.learn.batchItemSummaries}?${toQueryString(query)}`;
		const request = createRequest(url, init);
		const response = await fetchWithTimeout(request);
		if (response.ok) {
			const itemSet = await response.json();
			items.push(...itemSet);
		}
	});
	await Promise.all(promises);
	return items.filter(value => value !== null);
}

// Learn Progress APIs

export function getUserProgress(): Promise<StandardProgressResponse> {
	const url = apis.learn.progress.user;
	const init = { method: 'GET' } as RequestInit;

	return (
		fetchWithTimeout(createRequest(url, init))
			// TODO: Process possible 404 response if question ID not found.
			.then(response => process401Response(response))
			.then(response => {
				if (response.status === 204) {
					return [];
				} else if (response.ok) {
					return response.json();
				}

				return [];
			})
	);
}

export async function getUserProgressByUids(uids: string[]): Promise<StandardProgressResponse> {
	const uidSets = partitionValuesByEncodedLength(uids);
	const items: StandardProgressResponse = [];
	const init = { method: 'GET' } as RequestInit;

	const promises = uidSets.map(async uidSet => {
		const query = { branch, uids: uidSet.join(';'), locale };
		const url = `${apis.learn.progress.user}?${toQueryString(query)}`;
		const request = createRequest(url, init);
		const response = await fetchWithTimeout(request);
		if (response.ok && response.status !== 204) {
			const itemSet = await response.json();
			items.push(...itemSet);
		}
	});
	await Promise.all(promises);
	return items;
}

export async function getModuleIsComplete(uid: string): Promise<boolean> {
	const items = await getUserProgressByUids([uid]);
	const item = items.find(x => x.uid === uid);
	return item && item.status === 'completed';
}

// This function is only used to PUT single unit progress for view
export function putUnitProgress(
	unitId: string,
	details?: QuizAnswer[] | TaskValidationCredential[] | string
): Promise<UnitUpdatedResponse> {
	const query = { branch, locale };
	const url = `${apis.learn.progress.unit}/${unitId}?${toQueryString(query)}`;
	const body = JSON.stringify(details);

	const init = { method: 'PUT', body } as RequestInit;

	// TODO: Process possible 404 response if question ID not found.
	return fetchWithTimeout(createRequest(url, init))
		.then(process429Response)
		.then(process401Response)
		.then(response => {
			if (response.ok) {
				return response.json();
			}

			return Promise.reject(response.status);
		});
}

/**
 * PUT user storage in a batch.
 */
export function putBatchProgress(payload: any): Promise<UnitUpdatedResponse> {
	const query = { branch, locale };
	const url = `${apis.learn.progress.batchUnit}?${toQueryString(query)}`;
	const body = JSON.stringify(payload);

	const init = { method: 'PUT', body } as RequestInit;

	return fetchWithTimeout(createRequest(url, init))
		.then(response => process401Response(response))
		.then(response => {
			if (response.ok) {
				return response.json();
			}

			return response.text();
		});
}

export async function getAchievementsByUids(
	uids: string[],
	locale: string
): Promise<Achievement[]> {
	const init = { method: 'GET' } as RequestInit;
	const uidSets = partitionValuesByEncodedLength(uids);
	const achievements: Achievement[] = [];
	const promises = uidSets.map(async uidSet => {
		const query = { locale, branch, uids: uidSet.join(';') };
		const url = `${apis.learn.achievementsByUids}?${toQueryString(query)}`;
		const request = createRequest(url, init);
		const response = await fetchWithTimeout(request);
		if (response.ok) {
			const achievementSet = await response.json();
			achievements.push(...achievementSet);
		}
	});
	await Promise.all(promises);
	return achievements.filter(value => value !== null);
}

export function loadLearningPath(pathId: string): Promise<LearningPath> {
	if (!user.isAuthenticated) {
		return getLearningPath(msDocs.data.userLocale, pathId);
	}

	return getLearningPathWithProgress(pathId);
}

function getLearningPathWithProgress(pathId: string): Promise<LearningPath> {
	return Promise.all([
		getLearningPath(msDocs.data.userLocale, pathId),
		getUserProgressByUids([pathId])
	]).then(([learningPath, items]) => {
		return consolidateLearningPathAndProgress(learningPath, items);
	});
}

function consolidateLearningPathAndProgress(
	learningPath: LearningPath,
	items: StandardProgressResponse
): LearningPath {
	if (!items || !items.length) {
		return learningPath;
	}

	const itemPath = items.find(i => i.uid === learningPath.uid);
	if (itemPath === undefined) {
		learningPath.status = 'notStarted';
		learningPath.remainingTime = learningPath.durationInMinutes;
	} else {
		learningPath = { ...learningPath, ...itemPath };
	}

	for (let i = 0; i < learningPath.modules.length; i++) {
		const itemModule = items.find(item => item.uid === learningPath.modules[i].uid);
		if (itemModule === undefined) {
			learningPath.modules[i].status = 'notStarted';
			learningPath.modules[i].remainingTime = learningPath.modules[i].durationInMinutes;
		} else {
			learningPath.modules[i] = { ...learningPath.modules[i], ...itemModule };
		}

		for (let j = 0; j < learningPath.modules[i].units.length; j++) {
			const itemUnit = items.find(item => item.uid === learningPath.modules[i].units[j].uid);
			if (itemUnit === undefined) {
				learningPath.modules[i].units[j].status = 'notStarted';
				learningPath.modules[i].units[j].remainingTime =
					learningPath.modules[i].units[j].durationInMinutes;
			} else {
				learningPath.modules[i].units[j] = { ...learningPath.modules[i].units[j], ...itemUnit };
			}
		}
	}

	return learningPath;
}

// Flattens a 2d array
export function flatten<T>(array: T[][]): T[] {
	/* eslint-disable-next-line */
	return [].concat.apply([], array);
}

export function process429Response(response: Response) {
	if (response.status === 429) {
		eventBus.publish(new TooManyAttemptsEvent());
		throw response.status;
	}

	return response;
}

export function getBranch() {
	if (traits.accessLevel === 'local') {
		return 'master';
	}

	if (traits.reviewFeatures === true) {
		const params = parseQueryString(location.search);
		return params['api-branch'] || params.branch || '';
	}

	return null;
}

// Interfaces for Learn APIs
export interface Quiz {
	uid: string;
	title: string;
	questions: Question[];
}

export interface SelectedAnswer {
	id: number;
	answers: number[];
}

export interface Question {
	content: string;
	id: number;
	type: 'multiple-choices-with-single-answer'; // For now, only have one type
	choices: Choice[];
}

export interface Choice {
	content: string;
	id: number;
}

export interface GradedQuestion {
	id: number;
	choices: GradedChoice[];
	isCorrect: boolean;
}

export interface GradedQuestionForBi {
	id: number;
	isCorrect: boolean;
}

interface GradedChoice {
	id: number;
	isCorrect: boolean;
	explanation?: string;
}

// Interface for progress response data
export interface ModuleProgress {
	uid: string;
	type: string;
	url: string;
	title: string;
	isCompleted?: boolean;
	items: UnitProgress[];
}

export interface UnitProgress {
	uid: string;
	type: string;
	url: string;
	title: string;
	isCompleted?: boolean;
	completedAt?: string;
}

export type Level = 'beginner' | 'intermediate' | 'advanced';

interface LearnItemDetail extends LearnItem {
	summary: string;
	abstract: string;
	levels: Level[];
	roles: string[];
	products: string[];
}

// Achievement for Module, LearningPath
export interface Achievement {
	uid: string;
	type: 'badge' | 'trophy';
	title: string;
	iconUrl: string;
	summary?: string;
	sourceType: 'progress';
	sourceUId: string;
	sourceUrl: string;
	sourceTitle: string;
}

// Base structure for (Unit, Module, LearningPath)
export interface LearnItem {
	uid: string;
	type: string;
	title: string;
	url: string;
	iconUrl?: string;
	durationInMinutes: number;
	points: number;
	status?: string;
	remainingTime?: number;
	interactive?: string; // todo: find out whether this is implemented for ignite
	ratingEnabled?: boolean;
}

export interface Unit extends LearnItemDetail {
	paths: LearnItem[];
	parent: LearnItem;
}

export interface Module extends LearnItemDetail {
	parents: LearnItem[];
	units: LearnItem[];
	achievement: Achievement;
}

export interface LearningPath extends LearnItemDetail {
	modules: Module[];
	achievement: Achievement;
}

export type ModuleOrPath = Module | LearningPath;

export interface QuizAnswer {
	id: string;
	answers: string[];
}

export interface TaskValidationCredential {
	type: 'FreeAzureSubscription';
	azureSubscription: string;
	resourceGroup: string;
}

// For GET

export type StandardProgressResponse = ProgressItem[];

export interface ProgressItem {
	uid: string;
	status: 'completed' | 'inProgress' | 'notStarted';
	type?: 'module' | 'unit' | 'learningPath';
	remainingTime: number;
}

// for PUT

export interface ViewUnitUpdatedResponse {
	updated: boolean;
	passed: boolean;
	achievements: LearnAchievement[];
}

export interface LearnAchievement {
	uid: string;
	type: string;
	points: LearnPoints[];
}

export interface LearnPoints {
	value: number;
	reasonId: string;
}

export interface UnitUpdatedResponse extends ViewUnitUpdatedResponse {
	details: GradedQuestion[] | TaskValidationResult[];
	answers?: GradedQuestion[];
}

export interface TaskValidationResult {
	environment: 'Azure';
	type: string;
	name: string;
	action: 'exists' | 'non-exists';
	resourceGroup: string;
	passed: boolean;
	hint: string;
	detailedMessage: string;
}

export type ProductOrFamily = ProductFamilyId | ProductId;

export interface ModuleAndPathSummary {
	uid: string;
	title: string;
	url: string;
	type: 'learningPath' | 'module';
	childCount: number;
	childUIds: string[];
	summary: string;
	iconUrl: string;
	durationInMinutes: number;
	levels: Level[];
	products: ProductOrFamily[];
	roles: RoleId[];
}

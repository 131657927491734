import { loc_jobsAvailableFormat } from '@msdocs/strings';
import { contentLoaded } from '../globals';
import { renderLearnBreadcrumbs } from '../learn/breadcrumbs';
import { getMeta } from '../meta';
import { toQueryString } from '../query-string';

export async function rolePage() {
	await contentLoaded;

	renderLearnBreadcrumbs(`${getMeta('og:title')}`);

	const mainContainer = document.getElementById('main');

	addPathSelectListener(mainContainer);

	updateLinkedInResourceCard(mainContainer);
}

function addPathSelectListener(container: HTMLElement) {
	const pathSelectElement = container.querySelector<HTMLSelectElement>('#path-list');
	pathSelectElement.onchange = () => {
		Array.from(container.querySelectorAll<HTMLDivElement>('.certification-path')).forEach(
			element => {
				if (!element.hidden) {
					element.setAttribute('hidden', 'true');
				}
			}
		);

		const pathId = pathSelectElement.value;
		const pathElement = container.querySelector<HTMLDivElement>(getIdSelector(pathId));
		pathElement.removeAttribute('hidden');

		updateLinkedInResourceCard(container);
	};
}

function updateLinkedInResourceCard(container: HTMLElement) {
	const resourceDescElements = Array.from(
		container.querySelectorAll<HTMLElement>('.resource-description')
	);
	if (resourceDescElements.length === 0) {
		return;
	}

	// update linkedIn resource card description
	const pathSelectElement = container.querySelector<HTMLSelectElement>('#path-list');
	resourceDescElements[0].textContent = loc_jobsAvailableFormat.replace(
		'{typeRole}',
		pathSelectElement.options[pathSelectElement.selectedIndex].text
	);

	// update linkedIn card title search url
	const linkedInKeywords = pathSelectElement.options[pathSelectElement.selectedIndex].getAttribute(
		'data-linkedin-keywords'
	);
	const queryParams = {
		keywords: linkedInKeywords
	};
	const linkedInAnchor = Array.from(
		container.querySelectorAll<HTMLAnchorElement>('.resource-link')
	)[0];
	linkedInAnchor.href = `${linkedInAnchor.href.split('?')[0]}?${toQueryString(queryParams)}`;
}

function getIdSelector(id: string): string {
	return `#${id.replace(/\./g, '\\.')}`;
}

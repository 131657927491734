// todo: looking like it's about time for a refactor in here
export const isLocalhost = location.hostname === 'localhost';
export const isReview = location.hostname === 'review.docs.microsoft.com';
export const isPPE = location.hostname === 'ppe.docs.microsoft.com';
export const isStyleGuidePage = location.hostname === 'docsstyleguide.z13.web.core.windows.net';
// isProduction == not ppe and not localhost // review is currently considered isProduction
export const isProduction = !isPPE && !isLocalhost;
// prod is only docs.microsoft.com - prod is short for production; also technically isProduction excluding review
export const isProd = isProduction && !isReview;

export const normalizedPathSegments = location.pathname
	.split('/')
	.map(segment => segment.toLowerCase());
export const basePathname = normalizedPathSegments.length > 2 ? normalizedPathSegments[2] : ''; // assumption is normalizedPathSegments[1] == userLocale
export const isForumsPage =
	normalizedPathSegments.length > 2 &&
	normalizedPathSegments[2] === 'answers' &&
	document.head.querySelector('link[href*="forums.css"]');
export const isPpeLearnSandbox = isPPE && basePathname === 'learn-sandbox';
export const isTestRepo = isReview && basePathname === 'test';

import { ModuleAndPathSummary, StandardProgressResponse } from '../apis/learn';

// Module & learning path summary that includes user progress info
export interface UserModulePathSummary extends ModuleAndPathSummary {
	status?: 'completed' | 'inProgress';
	remainingTime?: number;
	numChildrenCompleted?: number;
}

export function countChildrenCompleted(
	childUids: string[],
	userProgress: StandardProgressResponse
): number {
	if (!childUids || !userProgress) {
		return 0;
	}

	return childUids.filter(uid => {
		return userProgress.find(progress => progress.uid === uid && progress.status === 'completed');
	}).length;
}

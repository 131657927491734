import { transformAzurePortalLinks } from '../anchors';
import { Module } from '../apis/learn';
import { user } from '../auth/user';
import { enableCaptcha } from '../captcha';
import { features } from '../environment/features';
import { EventBus, eventBus } from '../event-bus';
import { contentLoaded, document } from '../globals';
import { renderLearnBreadcrumbs } from '../learn/breadcrumbs';
import { fixModuleUnitLinksLocally, getCurrentModule } from '../learn/module';
import { TooManyAttemptsEvent, tooManyAttemptsModal } from '../learn/too-many-attempts-modal';
import {
	CombinedProgressService,
	handleXpTag,
	syncUserProgress,
	UnitProgressCheckedEvent,
	updateProgressElements
} from '../learn/unit-progress';
import { beforeUnload } from '../router/utils';
import { handleSignInAfterUnitComplete } from '../unit/auth-referral';
import { reportUnitCompletion } from './bi';
import { displayMobileNavInChromelessMode, handleChromelessNextSectionButton } from './chromeless';
import {
	createUnitConfig,
	extendModuleConfigWithProgressInfo,
	extendUnitConfigWithModuleInfo,
	UnitPageConfig
} from './config';
import { UnitStateChangeEvent } from './events';
import { feedbackLink } from './feedback';
import { handleGameStatusOnProgressUpdate } from './game-status';
import { initLearnNavigation } from './navigation-in-content';
import { renderModuleNavigation } from './navigation-menu';
import { initLearnMobileNavigation } from './navigation-mobile';
import { assignQuizUidToTag, setupQuizCompletionType } from './quiz-completion';
import { handleUnitRouting } from './routing';
import { handleAzureSandbox, handleLab, setupTaskValidation } from './task-validation';
import { addDeleteProgressListener } from './utilities';
import { getMeta } from '../meta';

export async function moduleUnitPage() {
	// configuration object extend as we get more information: pageConfig -> pageModuleConfig -> pageModuleProgressConfig
	const pageConfig = createUnitConfig();

	const interactiveContainer = document.getElementById('interactive-container');
	const currentModule: Promise<Module> = getCurrentModule();
	const contentContainer = document.querySelector('.content');
	let initialProgressReceived: boolean = false;

	eventBus.subscribe(TooManyAttemptsEvent, tooManyAttemptsModal);

	const bus = new EventBus();
	beforeUnload(() => bus.dispose());

	const combinedProgressService = features.gamification
		? new CombinedProgressService(user, bus)
		: null;

	reportUnitCompletion(bus);

	handleChromelessNextSectionButton(pageConfig);

	displayMobileNavInChromelessMode(pageConfig, document.getElementById('mobile-nav'));

	handleLab(pageConfig);

	feedbackLink();

	handleAzureSandbox(interactiveContainer, pageConfig);

	transformAzurePortalLinks(contentContainer);

	addDeleteProgressListener();

	initUnitProgress(bus, pageConfig);

	// get the module information

	const [module] = await Promise.all([currentModule, contentLoaded]);

	if (getMeta('show_latex') === 'true') {
		//@ts-ignore - mathjax in master
		MathJax.Hub.Queue(['Typeset', MathJax.Hub, contentContainer]);
	}

	handleNonGamifiedLayout();

	const pageModuleConfig = extendUnitConfigWithModuleInfo(
		pageConfig,
		fixModuleUnitLinksLocally(module)
	);

	const xpTags = Array.from(document.querySelectorAll('.xp-tag')) as HTMLElement[];

	assignQuizUidToTag(pageModuleConfig);

	handleXpTag(xpTags, [pageModuleConfig.currentUnit]);

	handleUnitRouting(pageModuleConfig);

	renderLearnBreadcrumbs(pageModuleConfig.currentUnit.title, pageModuleConfig.module);

	renderModuleNavigation(pageModuleConfig);

	setupQuizCompletionType(combinedProgressService, bus, pageModuleConfig);

	setupTaskValidation(combinedProgressService, pageModuleConfig);

	initLearnNavigation(pageConfig, document.getElementById('next-section'), bus);

	initLearnMobileNavigation(document.getElementById('mobile-nav'), bus);

	handleGameStatusOnProgressUpdate(bus);

	enableCaptcha(pageConfig);

	async function handleProgressUpdate(updatedEvent: UnitProgressCheckedEvent) {
		await Promise.all([currentModule, contentLoaded]);

		const pageModuleProgressConfig = extendModuleConfigWithProgressInfo(
			pageModuleConfig,
			updatedEvent
		);

		if (
			!initialProgressReceived ||
			updatedEvent.updated ||
			pageModuleProgressConfig.repeatQuizCompletion
		) {
			if (!initialProgressReceived || updatedEvent.updated) {
				initialProgressReceived = true;
			}
			bus.publish(new UnitStateChangeEvent(pageModuleProgressConfig));
		}

		updateProgressElements(updatedEvent.standardProgress);

		handleSignInAfterUnitComplete(pageModuleProgressConfig);
	}

	async function handleInitialProgressRequest(config: UnitPageConfig) {
		const { completionType, unitId } = config;

		await syncUserProgress();

		if (completionType === 'view') {
			// try completing the view unit and save response
			try {
				return await combinedProgressService.completeViewUnit(unitId);
			} catch (responseStatus) {
				// let it through
				if (responseStatus !== 429) {
					throw responseStatus;
				}
			}
		}
		// get a default progress response
		return await combinedProgressService.getCurrentUnitProgress(unitId);
	}

	function initUnitProgress(bus: EventBus, pageConfig: UnitPageConfig) {
		if (!features.gamification) {
			return;
		}

		bus.subscribe(UnitProgressCheckedEvent, handleProgressUpdate);
		handleInitialProgressRequest(pageConfig);
	}

	/**
	 * All units should appear like a default view unit when Gamification is not enabled.
	 */
	function handleNonGamifiedLayout() {
		if (features.gamification) {
			return;
		}
		const taskValidateButtonGroup = document.getElementById('task-button-group') as HTMLElement;
		if (taskValidateButtonGroup) {
			taskValidateButtonGroup.hidden = true;
		}
		document.documentElement.classList.remove('has-interactive', 'has-wide-layout');
		const primaryColumn = document.querySelector('.primary-holder');
		if (primaryColumn) {
			primaryColumn.classList.remove(
				'is-half-tablet',
				'is-half',
				'is-half-mobile',
				'is-full-portrait',
				'is-one-third-desktop'
			);
		}
	}
}

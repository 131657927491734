import {
	loc_azureDocs,
	loc_dotnetDocs,
	loc_officeDocs,
	loc_visualStudioDocs,
	loc_windowsDocs
} from '@msdocs/strings';
import { apis } from '../environment/apis';
import { fetchWithTimeout } from '../fetch';
import { document, msDocs } from '../globals';
import { removeLocaleFromPath } from '../locale';
import { toQueryString } from '../query-string';

const referralBasePathname = (): string => {
	let basePathname = '';
	try {
		const referralUrl = new URL(document.referrer);
		// 'https://docs.microsoft.com/en-us/users/someuser/' => '/en-us/users/someuser/' => ['', 'en-us', 'users', 'someuser'] => 'users'
		const basePathnamePart = referralUrl.pathname.split('/').map(s => s.toLowerCase())[2];
		if (basePathnamePart !== undefined) {
			basePathname = basePathnamePart;
		}
	} catch (e) {
		/* noop */
	}

	return basePathname;
};

const shouldShowSearchResults = (): boolean => referralBasePathname() !== 'users';

export function createSearchTermsFromPath(): string {
	// /en-us/dotnet/standard/components/blarg  --> "dotnet standard components blarg"
	return removeLocaleFromPath(location.pathname).replace(/\/|-/g, ' ').trim();
}

export function createSearchUrlFromPath(): string {
	const terms = encodeURIComponent(createSearchTermsFromPath()).replace(/\s+/g, '+');
	return `https://docs.microsoft.com/en-us/search/index?search=${terms}`;
}

export function notFoundPage() {
	const links = document.querySelector('.suggested-links') as HTMLUListElement;
	const SUGGESTION_LIMIT = 5;

	const searchTermLink = document.getElementById('term-to-search') as HTMLAnchorElement;
	searchTermLink.href = createSearchUrlFromPath();

	if (!shouldShowSearchResults()) {
		return;
	}

	const placeholderLinks: Api.SearchQueryResponse = {
		counter: 5,
		facets: null,
		'@nextLink': '',
		results: [
			{
				title: loc_windowsDocs,
				url: 'https://docs.microsoft.com/windows',
				description: '',
				lastUpdatedDate: '',
				breadcrumbs: []
			},
			{
				title: loc_azureDocs,
				url: 'https://docs.microsoft.com/azure/',
				description: '',
				lastUpdatedDate: '',
				breadcrumbs: []
			},
			{
				title: loc_visualStudioDocs,
				url: 'https://docs.microsoft.com/visualstudio/products/',
				description: '',
				lastUpdatedDate: '',
				breadcrumbs: []
			},
			{
				title: loc_officeDocs,
				url: 'https://docs.microsoft.com/office/',
				description: '',
				lastUpdatedDate: '',
				breadcrumbs: []
			},
			{
				title: loc_dotnetDocs,
				url: 'https://docs.microsoft.com/dotnet/',
				description: '',
				lastUpdatedDate: '',
				breadcrumbs: []
			}
		]
	};

	const apiConfig = {
		search: createSearchTermsFromPath(),
		locale: msDocs.data.userLocale,
		$top: SUGGESTION_LIMIT
	};

	fetchWithTimeout(`${apis.siteSearch}?${toQueryString(apiConfig)}`)
		.then<Api.SearchQueryResponse>(response => (response.ok ? response.json() : placeholderLinks))
		.then(search => {
			const suggestions = search.results.length ? search.results : placeholderLinks.results;

			for (let i = 0; i < SUGGESTION_LIMIT; i++) {
				links.insertAdjacentHTML(
					'beforeend',
					`
					<li>
						<a href="${suggestions[i].url}" data-bi-name="404-suggested-link">${suggestions[i].title}</a></li>
					</li>
				`
				);
			}
		});
}

import { notifyContentUpdated } from '../affix';
import { features } from '../environment/features';
import { document, msDocs } from '../globals';
import { renderInTopicTOC } from '../in-topic-toc';
import {
	InteractiveTypeInfo,
	parseInteractiveType,
	parseScaffoldingType
} from '../interactivity/activation';
import { devlangs } from '../name-maps/devlangs';
import { listenUntilUnload } from '../router/utils';
import { CodeBlock } from './block';
import { addCodeHeader } from './header';
import { LanguageConfig, languageConfig } from './language-config';
import { syntaxHighlight } from './syntax-highlighter';
export interface CodeBlockGroup {
	default: CodeBlock;
	members: CodeBlock[];
}

export function getElementLanguage(element: HTMLElement, config: LanguageConfig): string {
	for (let i = 0; i < element.classList.length; i++) {
		const name = element.classList.item(i);
		if (/^lang-.+$/i.test(name)) {
			return name.substr(5);
		}
	}
	return config.unset;
}

export function readGroupsFromContent(
	content: HTMLElement,
	config: LanguageConfig,
	selectionOptions: string[]
) {
	const selector = 'pre > code, span[class*="lang-"]';
	const elements = content.querySelectorAll(selector) as NodeListOf<HTMLElement | HTMLSpanElement>;

	const groups: CodeBlockGroup[] = [];

	let previous: CodeBlock;

	for (let i = 0; i < elements.length; i++) {
		let element = elements.item(i);

		const language = getElementLanguage(element, config);
		const syntaxLanguage = config.syntaxMap[language] || language;
		const visibilityLanguage = config.visibilityMap[language] || language;
		const displayName = config.displayNameMap[language] || language || '';
		// The content templates sometimes insert <br> elements. Use innerText in these cases because it returns the "rendered" text content.
		// Using textContent in all other cases because it's more performant, doesn't trigger reflow. https://developer.mozilla.org/en-US/docs/Web/API/Node/textContent
		const code = element.querySelector('br') ? element.innerText : element.textContent;
		let authorInteractiveType: string;
		let interactiveType: InteractiveTypeInfo = null;
		let scaffoldingType: string;
		let highlightLines = '';
		let monikers: string = null;

		const isPreCode = element.nodeName === 'CODE';
		if (isPreCode) {
			element.parentElement.tabIndex = 0;
			element.parentElement.classList.add('has-inner-focus');
			highlightLines = element.getAttribute('highlight-lines') || '';
			authorInteractiveType = element.getAttribute('data-interactive');
			monikers = element.getAttribute('data-moniker');

			element = element.parentElement;
			monikers = monikers || element.getAttribute('data-moniker');
			authorInteractiveType = authorInteractiveType || element.getAttribute('data-interactive');
			interactiveType = features.interactivity ? parseInteractiveType(authorInteractiveType) : null;
			scaffoldingType = parseScaffoldingType(element);
		}

		const current: CodeBlock = {
			type: isPreCode ? 'precode' : 'span',
			element,
			language,
			syntaxLanguage,
			visibilityLanguage,
			displayName,
			code,
			interactiveType,
			highlightLines,
			isEnhanced: false,
			monikers,
			scaffoldingType
		};

		const createNewGroup =
			!previous ||
			previous.type !== current.type ||
			previous.element !== current.element.previousElementSibling ||
			selectionOptions.indexOf(visibilityLanguage) === -1 ||
			selectionOptions.indexOf(previous.visibilityLanguage) === -1;

		if (createNewGroup) {
			const newGroup = { default: current, members: [current] };
			groups.push(newGroup);
		} else {
			const currentGroup = groups[groups.length - 1];
			currentGroup.members.push(current);
			if (current.visibilityLanguage === config.default) {
				currentGroup.default = current;
			}
		}

		previous = current;
	}

	return groups;
}

export function enhanceVisibleBlocks(groups: CodeBlockGroup[], contentDir: string) {
	const toHighlight: CodeBlock[] = [];
	for (const group of groups) {
		for (const member of group.members) {
			if (member.type === 'precode' && !member.isEnhanced && !member.element.hidden) {
				toHighlight.push(member);
				member.isEnhanced = true;
			}
		}
	}

	if (toHighlight.length === 0) {
		return Promise.resolve();
	}

	const instructions = toHighlight.map(item => ({
		language: item.syntaxLanguage,
		code: item.code,
		highlightLines: item.highlightLines
	}));

	return syntaxHighlight(instructions).then(results => {
		for (let i = 0; i < results.length; i++) {
			const { html, success } = results[i];
			const item = toHighlight[i];
			addCodeHeader(item, contentDir);
			if (success) {
				item.element.firstElementChild.innerHTML = html;
			}
		}

		notifyContentUpdated();
	});
}

export function setVisibility(groups: CodeBlockGroup[], language: string) {
	const setBlockVisibility = (block: CodeBlock, visible: boolean) => {
		block.element.hidden = !visible;
		if (block.header) {
			block.header.hidden = !visible;
		}
	};

	for (const group of groups) {
		let anyVisible = false;

		for (const member of group.members) {
			const visible = member.visibilityLanguage === language;

			setBlockVisibility(member, visible);

			anyVisible = anyVisible || visible;
		}

		if (!anyVisible) {
			setBlockVisibility(group.default, true);
		}
	}
	notifyContentUpdated();
}

export function getInitialSelection(options: string[], config: LanguageConfig): string {
	const preferred = config.preferred;
	if (preferred !== config.unset && options.indexOf(preferred) !== -1) {
		return preferred;
	}

	if (config.default !== config.unset && options.indexOf(config.default) !== -1) {
		return config.default;
	}

	return options[0];
}

const codeBlockPageTemplates: typeof msDocs.data.pageTemplate[] = [
	'Conceptual',
	'LandingPage',
	'NamespaceListPage',
	'Reference',
	'Rest',
	'Sample',
	'Tutorial',
	'ModuleUnit'
];

export function makeCodeBlocks(): Promise<void> {
	if (codeBlockPageTemplates.indexOf(msDocs.data.pageTemplate) === -1) {
		return Promise.resolve();
	}

	const title = document.getElementById('lang-title') as HTMLSelectElement;
	const dropdownLinks: HTMLAnchorElement[] = Array.from(
		document.querySelectorAll('#language-selector a')
	);
	const options: string[] = [];

	dropdownLinks.forEach((link: HTMLAnchorElement) => {
		if (msDocs.data.userDir === 'rtl') {
			link.classList.add('has-flex-justify-content-end');
		} else {
			link.classList.add('has-flex-justify-content-start');
		}

		const lang = link.dataset.biName.substr(5);
		link.textContent = devlangs[lang];
		options.push(lang);
	});

	const hasSelector = dropdownLinks !== null && options.length > 0;
	const groups = readGroupsFromContent(document.body, languageConfig, options);

	if (hasSelector) {
		const language = getInitialSelection(options, languageConfig);
		const initialLang = document.querySelector(
			'[data-bi-name="lang-' + language + '"]'
		) as HTMLElement;

		setVisibility(groups, language);

		title.textContent = initialLang.textContent;

		dropdownLinks.forEach(link => {
			if (link === initialLang) {
				link.setAttribute('aria-selected', 'true');
			}

			listenUntilUnload(link, 'click', () => {
				dropdownLinks.forEach(link => {
					link.setAttribute('aria-selected', 'false');
				});

				link.setAttribute('aria-selected', 'true');
				const language = link.dataset.biName.substr(5);
				title.textContent = link.textContent;
				languageConfig.preferred = language;
				setVisibility(groups, language);
				renderInTopicTOC(); // there may be dev-lang specific spans in the content's h2 elements
				enhanceVisibleBlocks(groups, msDocs.data.contentDir);
			});
		});
	}

	return enhanceVisibleBlocks(groups, msDocs.data.contentDir);
}

import { features } from '../environment/features';
import { EventBus } from '../event-bus';
import { msDocs } from '../globals';
import { UnitStateChangeEvent } from './events';
import {
	generateLearnNavigationActionInfo as getLearnNavigationActionInfo,
	getInPageLearnNavigationAction
} from './navigation-in-content';
import {
	isLearnNavNoOp,
	LearnActionAnchor,
	LearnActionButton,
	LearnActionLinks,
	LearnPageNavigationInfo
} from './navigation-types';

const learnMobileButtonId = 'complete-unit-link-mobile';

export function initLearnMobileNavigation(mobileNavContainer: HTMLElement, bus: EventBus): void {
	if (!features.gamification) {
		return;
	}

	bus.subscribe(UnitStateChangeEvent, async e => {
		const actionType = getInPageLearnNavigationAction(e.config);
		if (
			!actionType ||
			actionType === 'continue' ||
			actionType === 'modal-quiz' ||
			actionType === 'modal-task'
		) {
			return;
		}

		// * start afresh with newly created element
		mobileNavContainer.innerHTML = '';

		const actionInfo = await getLearnNavigationActionInfo(actionType, e.config);
		const linkColumn = document.createElement('div');
		const elt = renderLearnMobileNavigationAction(linkColumn, actionInfo);

		linkColumn.classList.add('column', 'is-fullwidth');
		linkColumn.insertAdjacentElement('afterend', elt);
		mobileNavContainer.append(linkColumn);
	});
}

export function renderLearnMobileNavigationAction(
	container: HTMLElement,
	actionInfo: LearnPageNavigationInfo
): HTMLElement {
	if (!actionInfo || isLearnNavNoOp(actionInfo)) {
		return container;
	}

	if (actionInfo.elementType === 'a') {
		container.innerHTML = createMobileActionAnchorHTML(actionInfo);
	} else if (actionInfo.elementType === 'button') {
		const button = createMobileActionButtonElement(actionInfo);
		container.appendChild(button);
	} else if (actionInfo.elementType === 'ul') {
		// * mobile menu does not have lists, just a single link to the browse page
		container.innerHTML = createMobileBrowseAnchorHTML(actionInfo);
	}

	container.classList.remove('is-hidden');
	return container;
}

export function createMobileActionAnchorHTML(actionInfo: LearnActionAnchor): string {
	const template = `
		<a href="${actionInfo.href}" id="${learnMobileButtonId}" class="button is-fullwidth is-primary is-radiusless is-large has-margin-none is-hidden-desktop is-hidden-tablet" data-bi-name="${actionInfo.biName}">
			<span>${actionInfo.text}</span>
		</a>`;
	return template;
}

export function createMobileActionButtonElement(actionInfo: LearnActionButton): HTMLButtonElement {
	const button = document.createElement('button');
	button.id = `${learnMobileButtonId}`;
	button.onclick = actionInfo.onclick;
	button.textContent = actionInfo.text;
	button.dataset.biName = actionInfo.biName;
	button.classList.add(
		'button',
		'is-fullwidth',
		'is-primary',
		'is-radiusless',
		'is-large',
		'has-margin-none'
	);
	return button;
}

export function createMobileBrowseAnchorHTML(actionInfo: LearnActionLinks): string {
	const template = `
		<a href="/${msDocs.data.userLocale}/learn/browse" id="${learnMobileButtonId}" class="button is-fullwidth is-primary is-radiusless is-large has-margin-none is-hidden-desktop is-hidden-tablet" data-bi-name="continue">
			<span>${actionInfo.sectionTitle}</span>
		</a>`;
	return template;
}

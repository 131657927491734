import {
	loc_azureSandbox_assigningRoles,
	loc_azureSandbox_checkingAuth,
	loc_azureSandbox_checkingAvailability,
	loc_azureSandbox_checkingCompletion,
	loc_azureSandbox_checkingExisting,
	loc_azureSandbox_creatingFileshare,
	loc_azureSandbox_creatingResourceGroup,
	loc_azureSandbox_creatingStorage,
	loc_azureSandbox_deactivatingPrevious,
	loc_azureSandbox_finishing,
	loc_azureSandbox_loadingModule,
	loc_azureSandbox_preparingInvitation,
	loc_azureSandbox_verifyingPermissions,
	loc_somethingWentWrong
} from '@msdocs/strings';
import { getModule, Module, getModuleIsComplete } from '../../apis/learn';
import * as api from '../../apis/sandbox';
import { authStatusDetermined } from '../../auth/index';
import { eventBus } from '../../event-bus';
import { msDocs } from '../../globals';
import { instrumentSandboxActivity, publishTenantInviteFailed } from './instrumentation';
import { getCurrentModule } from '../../learn/module';
import { getInvitationDelay } from './prepare-invite';
import { azureSandbox, AzureSandboxChangedEvent, getRemainingSandboxTime } from '../sandbox';
import { SandboxPromptState } from './state';
import * as sandboxTemplates from './ui';
import { isSandboxDisabled } from '../../unit/module-availability';
import * as urlActivation from './url-activation';
import { waitForButtonClick } from '../../risk-evaluation/risk-templates';
import * as riskPrompts from '../../risk-evaluation/risk-prompts';

/**
 * Delay in milliseconds before retrying a POST to the sandbox API.
 */
export const aadDelay = 5000;

/**
 * Duration in milliseconds to display 100% progress.
 */
export const progress100PercentPause = 600;

export function sandboxDisabled(state: SandboxPromptState): Promise<void> {
	sandboxTemplates.renderSandboxDisabled(state.container);
	return riskPrompts.neverResolve;
}

export async function activatePrompt(state: SandboxPromptState): Promise<void> {
	// TypeScript type guard:
	if (!state.apiResult || state.apiResult.hasError === true) {
		throw new Error('Successful sandbox API call required for activatePrompt controller.');
	}

	sandboxTemplates.renderActivatePrompt(state.container, state.apiResult.sandbox);
	await waitForButtonClick(state.container);
	state.userDidActivate = true;
}

export async function awaitingAuthStatus(state: SandboxPromptState): Promise<void> {
	sandboxTemplates.renderProgress(state.container, loc_azureSandbox_checkingAuth);
	await authStatusDetermined;
	state.auth.authStatusDetermined = true;
}

export function signInPrompt(state: SandboxPromptState): Promise<void> {
	sandboxTemplates.renderSignInPrompt(state.container);
	return riskPrompts.neverResolve;
}

export function loginExpiredPrompt(state: SandboxPromptState): Promise<void> {
	sandboxTemplates.renderLoginExpiredPrompt(state.container);
	return riskPrompts.neverResolve;
}

export async function loadingCurrentModule(state: SandboxPromptState): Promise<void> {
	sandboxTemplates.resetProgress();
	sandboxTemplates.renderProgress(state.container, loc_azureSandbox_loadingModule);
	state.module = await getCurrentModule();
}

export async function loadingSandbox(state: SandboxPromptState): Promise<void> {
	sandboxTemplates.renderProgress(state.container, loc_azureSandbox_checkingExisting);
	state.apiResult = await api.get(state.module.uid);
}

export async function loadingSandboxAvailability(state: SandboxPromptState): Promise<void> {
	sandboxTemplates.renderProgress(state.container, loc_azureSandbox_checkingAvailability);
	state.disabled = await isSandboxDisabled(state.module.uid);
}

export function retryPrompt(state: SandboxPromptState): Promise<void> {
	const message = getErrorMessage(state);
	state.apiResult = null;
	sandboxTemplates.renderRetryPrompt(state.container, message);
	return waitForButtonClick(state.container);
}

const pause = (delay: number) =>
	location.hostname === 'localhost'
		? new Promise(resolve => requestAnimationFrame(resolve)) // simplify tests by shortening the delay and avoiding clock conflicts
		: new Promise(resolve => setTimeout(resolve, delay));

export async function provisioningSandbox(state: SandboxPromptState): Promise<void> {
	sandboxTemplates.resetProgress();
	sandboxTemplates.renderProgress(state.container, loc_azureSandbox_verifyingPermissions);
	const progressTimeouts = [
		setTimeout(
			() =>
				sandboxTemplates.renderProgress(state.container, loc_azureSandbox_creatingResourceGroup),
			aadDelay + 2000
		),
		setTimeout(
			() => sandboxTemplates.renderProgress(state.container, loc_azureSandbox_assigningRoles),
			aadDelay + 7000
		),
		setTimeout(
			() => sandboxTemplates.renderProgress(state.container, loc_azureSandbox_creatingStorage),
			aadDelay + 12000
		),
		setTimeout(
			() => sandboxTemplates.renderProgress(state.container, loc_azureSandbox_creatingFileshare),
			aadDelay + 25000
		)
	];

	state.provisionAttempts++;
	if (state.referredByTenantInvite) {
		await new Promise(resolve => setTimeout(resolve, aadDelay));
	}
	state.apiResult = await api.post({
		returnUrl: urlActivation.createTenantInviteReturnUrl(),
		locale: state.auth.user.locale,
		unitUid: state.unitUid,
		moduleUid: state.module.uid
	});

	progressTimeouts.forEach(t => clearTimeout(t));
	sandboxTemplates.renderProgress(state.container, loc_azureSandbox_finishing, true);
	await pause(progress100PercentPause);
}

export async function preparingInvite(state: SandboxPromptState): Promise<void> {
	// TypeScript type guard:
	if (!state.apiResult || state.apiResult.hasError === true) {
		throw new Error('Successful sandbox API call required for preparingInvite controller.');
	}

	sandboxTemplates.resetProgress();
	sandboxTemplates.renderProgress(state.container, loc_azureSandbox_preparingInvitation);

	const delay = getInvitationDelay(state.apiResult.sandbox);
	await pause(delay);

	sandboxTemplates.renderProgress(state.container, loc_azureSandbox_preparingInvitation, true);
	await pause(progress100PercentPause);
}

export function tenantInvite(state: SandboxPromptState): Promise<void> {
	// TypeScript type guard:
	if (!state.apiResult || state.apiResult.hasError === true) {
		throw new Error('Successful sandbox API call required for tenantInvite controller.');
	}

	if (state.referredByTenantInvite) {
		publishTenantInviteFailed(state.provisionAttempts, aadDelay);
	}
	const invitationUrl = state.apiResult.sandbox.invitationUrl;
	sandboxTemplates.renderTenantInvite(state.container, invitationUrl);
	return riskPrompts.neverResolve;
}

export async function deletingSandbox(state: SandboxPromptState): Promise<void> {
	// TypeScript type guard:
	if (!state.apiResult || state.apiResult.hasError === true) {
		throw new Error('Successful sandbox API call required for deletingSandbox controller.');
	}

	sandboxTemplates.resetProgress();
	sandboxTemplates.renderProgress(state.container, loc_azureSandbox_deactivatingPrevious);

	await api.remove(state.apiResult.sandbox.moduleId);
	state.apiResult = null;
	state.userDidDeactivate = false;

	sandboxTemplates.renderProgress(state.container, loc_azureSandbox_deactivatingPrevious, true);
	await pause(progress100PercentPause);
}

export async function loadingSandboxModule(state: SandboxPromptState): Promise<void> {
	// TypeScript type guard:
	if (!state.apiResult || state.apiResult.hasError === true) {
		throw new Error('Successful sandbox API call required for loadingSandboxModule controller.');
	}

	sandboxTemplates.renderProgress(state.container, loc_azureSandbox_checkingCompletion);
	const moduleId = state.apiResult.sandbox.moduleId;
	const [module, isComplete] = await Promise.all([
		getModule(msDocs.data.userLocale, moduleId).catch(
			() => ({ title: moduleId, url: '' } as Module)
		),
		getModuleIsComplete(moduleId).catch(() => false)
	]);
	state.sandboxModuleInfo = { module, isComplete };
}

export async function releasePrompt(state: SandboxPromptState): Promise<void> {
	// TypeScript type guard:
	if (!state.apiResult || state.apiResult.hasError === true) {
		throw new Error('Successful sandbox API call required for releasePrompt controller.');
	}

	sandboxTemplates.renderActivatePrompt(state.container, state.apiResult.sandbox);
	const modal = sandboxTemplates.renderReleaseModal(state.sandboxModuleInfo.module);
	const result = await modal.show();
	if (result === 'release') {
		state.userDidRelease = true;
	} else {
		// re-activation is required.
		state.userDidActivate = false;
	}
}

export async function sandboxActivated(state: SandboxPromptState): Promise<void> {
	// TypeScript type guard:
	if (!state.apiResult || state.apiResult.hasError === true) {
		throw new Error('Successful sandbox API call required for sandboxActivated controller.');
	}

	const sandbox = state.apiResult.sandbox;
	const isNew = state.apiResult.requestVerb === 'POST';
	const destroyInstrumentation = instrumentSandboxActivity(sandbox, isNew);
	sandboxTemplates.renderSandboxActivated(state.container, sandbox);

	const expired = new Promise(resolve => setTimeout(resolve, getRemainingSandboxTime(sandbox)));
	// instead, assign the moduleExpiresAt property.
	const deactivated = waitForButtonClick(state.container, '#deactivate-sandbox').then(
		() => (state.userDidDeactivate = true)
	);

	// reset state
	state.apiResult = null;
	state.userDidRelease = false;
	state.userDidActivate = false;

	azureSandbox.value = sandbox;
	eventBus.publish(new AzureSandboxChangedEvent(sandbox));

	await Promise.race([expired, deactivated]);

	azureSandbox.value = null;
	eventBus.publish(new AzureSandboxChangedEvent(null));

	destroyInstrumentation();
}

// Risk Evaluation controllers
export function errorAppealDenied(state: SandboxPromptState): Promise<void> {
	return riskPrompts.errorAppealDenied(state.container);
}
export function errorAppealPending(state: SandboxPromptState): Promise<void> {
	return riskPrompts.errorAppealPending(state.container);
}
export function errorQuotaExceeded(state: SandboxPromptState): Promise<void> {
	return riskPrompts.errorQuotaExceeded(state.container);
}
export function errorMissingEmail(state: SandboxPromptState): Promise<void> {
	return riskPrompts.errorMissingEmail(state.container, state);
}
export function errorRestrictedCloud(state: SandboxPromptState): Promise<void> {
	return riskPrompts.errorRestrictedCloud(state.container);
}
export function errorUserRejected(state: SandboxPromptState): Promise<void> {
	return riskPrompts.errorUserRejected(state.container);
}
export function sandboxAppeal(state: SandboxPromptState): Promise<void> {
	return riskPrompts.sandboxAppeal(state);
}
export function sandboxTriggerCaptcha(state: SandboxPromptState): Promise<void> {
	return riskPrompts.sandboxTriggerCaptcha(state);
}
export function smsChallenge(state: SandboxPromptState): Promise<void> {
	return riskPrompts.smsChallenge(state);
}

function getErrorMessage(state: SandboxPromptState) {
	const result = state.apiResult;
	if (result && result.hasError === true) {
		return result.error.message || loc_somethingWentWrong;
	}
	return loc_somethingWentWrong;
}

import { msDocs } from './globals';
import { getMeta } from './meta';
import { handleMonikerChange } from './monikers/moniker-changed-handler';
import { pageMonikers } from './monikers/page-monikers';
import { platformId } from './monikers/platform';

const pageTemplate = msDocs.data.pageTemplate;
const pageType = getMeta('page_type');
const oldPageType = getMeta('pagetype');
const interactiveType = getMeta('interactive_type');

export const useMonikerPicker =
	// Any versioned page. Eg any page with <meta name="monikers" content="...">
	(pageMonikers.any && msDocs.data.pageTemplate !== 'HubPage') ||
	// DEPRECATED: "conceptual reference" (powershell) page with docset platform meta
	(pageTemplate === 'Conceptual' && oldPageType === 'Reference' && platformId !== null) ||
	// TEMPORARY: "conceptual reference" (powershell) page with docset platform meta
	(pageTemplate === 'Conceptual' && pageType === 'powershell' && platformId !== null) ||
	// DEPRECATED: powershell conceptual pages
	(pageTemplate === 'Conceptual' && oldPageType === 'Conceptual' && platformId === 'powershell') ||
	// TEMPORARY: powershell conceptual pages
	(pageTemplate === 'Conceptual' && pageType === 'conceptual' && platformId === 'powershell') ||
	// TEMPORARY: python cognitive toolkit pages
	(((pageTemplate === 'Conceptual' && pageType === 'conceptual') ||
		(pageTemplate === 'Reference' && pageType === 'python')) &&
		platformId === 'python') ||
	// TEMPORARY: azure CLI pages
	(((pageTemplate === 'Conceptual' && pageType === 'conceptual') ||
		(pageTemplate === 'Reference' && pageType === 'cli')) &&
		interactiveType === 'azurecli') ||
	// SDP pipeline powershell reference pages
	(pageTemplate === 'Reference' && pageType === 'powershell' && platformId !== null);

export const useApiSearch =
	// A true reference page with docset platform meta
	(pageTemplate === 'Reference' && platformId !== null) ||
	// DEPRECATED: "conceptual reference" (powershell) page with docset platform meta
	(pageTemplate === 'Conceptual' && oldPageType === 'Reference' && platformId !== null) ||
	// TEMPORARY: "conceptual reference" (powershell) page with docset platform meta
	(pageTemplate === 'Conceptual' && pageType === 'powershell' && platformId !== null);

export function setupToc() {
	if (useMonikerPicker) {
		handleMonikerChange();
	}
}

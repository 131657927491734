import { cookieConsent } from '../cookie-consent';
import { features } from '../environment/features';
import { contentLoaded, msDocs } from '../globals';

/* eslint-disable */

declare var ga: any;

export function enableGoogleAnalytics() {
	if (!features.googleAnalytics) {
		return;
	}
	Promise.all([contentLoaded, cookieConsent]).then(() => {
		// prettier-ignore
		// @ts-ignore
		(function (i, s, o, g, r, a, m) {
			// @ts-ignore
			(i as any).GoogleAnalyticsObject = r; i[r] = i[r] || function () {
				// @ts-ignore
				(i[r].q = i[r].q || []).push(arguments);
				// @ts-ignore
			}, i[r].l = 1 * (new Date() as any); a = s.createElement(o),
				m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a, m);
		})(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
		ga('create', 'UA-62780441-21', 'auto');
		ga('set', 'anonymizeIp', true);

		//tracking data into GA custom dimensions
		let saveData = 'unknown';
		const connection = (navigator as any).connection; // break out of strong typing to use non-standard navigator property (https://developer.mozilla.org/en-US/docs/Web/API/Navigator/connection)
		if (connection && 'saveData' in connection) {
			// we have a connection and it defines a saveData prop...
			if (connection.saveData) {
				saveData = 'on';
			} else {
				saveData = 'off';
			}
		}
		ga('set', 'dimension2', saveData);
		ga('set', 'dimension3', msDocs.data.pageTemplate);
		ga('send', 'pageview');
	});
}

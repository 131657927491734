import { html } from '../../lit-html';
import { features } from '../../environment/features';

/**
 * @param questionTitle The title of the question being rendered.
 * @param questionDescription The description of the question being rendered. null or '' if no description is set.
 * @description LitHTML generator for the title of the question and its description. This is layout and
 * styling is shared across all question types.
 */
export const createQuestionHeaderTemplate = (
	questionTitle: string,
	questionDescription: string
) => {
	const questionDescTemplateResult = questionDescription
		? html`<p class="has-margin-top-small">${questionDescription}</p>`
		: '';

	const titleSizeClass = features.assessmentSideNav ? 'is-2 has-padding-top-small' : 'is-3';
	return html`
		<legend class="title ${titleSizeClass} has-margin-bottom-none">
			<strong>${questionTitle}</strong>
		</legend>
		${questionDescTemplateResult}
	`;
};

import { features } from './environment/features';
import { fetchWithTimeout } from './fetch';
import { contentLoaded, document, msDocs } from './globals';
import { initDocsHeader } from './header';
import { setHeaderLoaded } from './header/header-loaded';
import { SiteContext } from './header/site-context';
import { loadLibrary } from './load-library';
import { apis } from './environment/apis';

async function loadMooncakeFooter() {
	const footerHTMLPromise = getMooncakeHTML(apis.uhf.mooncakeFooter);

	await contentLoaded;

	// remove scripts from html
	const footerParser = document.createElement('div');
	const footerHTML = await footerHTMLPromise;
	footerParser.innerHTML = footerHTML;
	const scripts = Array.from(footerParser.querySelectorAll('script'));
	for (const script of scripts) {
		script.remove();
	}

	// 2 footers for interactive layouts
	const footerContainers = Array.from(document.querySelectorAll('.footerContainer'));
	for (const container of footerContainers) {
		container.innerHTML = footerParser.innerHTML;
	}

	msDocs.functions.notifyContentUpdated();

	// load non-jquery, non-polyfill scripts
	for (const { src } of scripts) {
		if (src && !/jquery/.test(src)) {
			await loadLibrary(src);
		}
	}

	msDocs.functions.notifyContentUpdated();
}

async function getMooncakeHTML(apiUrl: string) {
	try {
		const response = await fetchWithTimeout(apiUrl, { mode: 'cors' });
		const html = await response.text();
		return html;
	} catch {
		return '';
	}
}

export async function initHeader(siteContext: SiteContext) {
	await initDocsHeader();

	if (siteContext === 'Mooncake' && features.thirdPartyHeader) {
		// If we're not enabling third-party headers, the page will display without any footer.
		await loadMooncakeFooter();
	}

	setHeaderLoaded();
}

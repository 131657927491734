//
// This file is generated by 'strings\build\taxonomy.js'
// If you need to make a change, update the build script
//

import {
	loc_products_azure,
	loc_products_connected_services_framework,
	loc_products_consumer,
	loc_products_customer_care_framework,
	loc_products_dotnet,
	loc_products_dynamics,
	loc_products_expression,
	loc_products_github,
	loc_products_internet_explorer,
	loc_products_m365,
	loc_products_mdatp,
	loc_products_microsoft_edge,
	loc_products_microsoft_servers,
	loc_products_ms_graph,
	loc_products_msc,
	loc_products_office,
	loc_products_office_365,
	loc_products_power_platform,
	loc_products_project_acoustics,
	loc_products_qdk,
	loc_products_silverlight,
	loc_products_skype,
	loc_products_sql_server,
	loc_products_vs,
	loc_products_windows,
	loc_products_xbox
} from '@msdocs/strings';

export const productFamilyNames: { [key: string]: string } = {
	azure: loc_products_azure,
	'connected-services-framework': loc_products_connected_services_framework,
	consumer: loc_products_consumer,
	'customer-care-framework': loc_products_customer_care_framework,
	dotnet: loc_products_dotnet,
	dynamics: loc_products_dynamics,
	expression: loc_products_expression,
	github: loc_products_github,
	'internet-explorer': loc_products_internet_explorer,
	m365: loc_products_m365,
	mdatp: loc_products_mdatp,
	'microsoft-edge': loc_products_microsoft_edge,
	'microsoft-servers': loc_products_microsoft_servers,
	'ms-graph': loc_products_ms_graph,
	msc: loc_products_msc,
	office: loc_products_office,
	'office-365': loc_products_office_365,
	'power-platform': loc_products_power_platform,
	'project-acoustics': loc_products_project_acoustics,
	qdk: loc_products_qdk,
	silverlight: loc_products_silverlight,
	skype: loc_products_skype,
	'sql-server': loc_products_sql_server,
	vs: loc_products_vs,
	windows: loc_products_windows,
	xbox: loc_products_xbox
};

/**
 * Change particular product names to match the css brand names
 */
export const productFamilyAlternativeMap: { [key: string]: string } = {
	'ms-graph': 'graph',
	'sql-server': 'sql',
	vs: 'visual-studio',
	xbox: 'gaming'
};

export type ProductFamilyId = keyof typeof productFamilyNames;

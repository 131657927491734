import { process401Response } from '../auth/service';
import { ProfileResponse } from '../auth/user';
import { getDocsToken, getIdToken } from '../auth/v2/jwt';
import { apis } from '../environment/apis';
import { fetchWithTimeout } from '../fetch';
import { Following } from '../following/model';
import { msDocs } from '../globals';
import { toQueryString } from '../query-string';
import { detectDocsTokenInResponseHeader } from './auth';
import type { ModuleAndPathSummary, ProgressItem } from './learn';
import { Interests } from '../learner-interests/model';

// get profile by username
export async function getProfile(userName: string): Promise<ProfileResponse> {
	const url = `${apis.profile.profiles}/${userName}`;
	const init = { method: 'GET' } as RequestInit;

	const response = await fetchWithTimeout(createRequest(url, init, false));
	process401Response(response);

	if (response.ok) {
		const profile = await response.json();
		return profile;
	} else if (response.status === 204) {
		return null;
	}

	return null;
}

// get activity by username
export async function getUserActivity(
	userName: string,
	pageIndex: number,
	pageSize: number
): Promise<UserActivity> {
	const url = `${apis.profile.profiles}/${userName}/activity?page=${pageIndex}&pageSize=${pageSize}`;
	const init = { method: 'GET' } as RequestInit;

	const response = await fetchWithTimeout(createRequest(url, init, true));
	process401Response(response);

	if (response.ok) {
		const userActivity = await response.json();
		return userActivity;
	}

	return { totalItems: 0, items: [] }; // totalItems === 0 may not make sense for a pageIndex > 1, but the api failed anyway
}

// edit profile
export async function putProfile(
	userId: string,
	profile: ProfileRequest
): Promise<UpdateProfileResult> {
	const url = `${apis.profile.profiles}/${userId}`;
	const body = JSON.stringify(profile);
	const init = { credentials: 'include', method: 'PUT', body } as RequestInit;

	const response = await fetchWithTimeout(createRequest(url, init));
	process401Response(response);

	if (response.ok) {
		detectDocsTokenInResponseHeader(response, false);
		const profile = await response.json();
		return { hasError: false, profile };
	} else if (response.status === 400) {
		const error = await response.json();
		return { hasError: true, message: error.message, errors: error.errorCode.split(',') };
	}

	return { hasError: true, message: response.statusText, errors: ['somethingWentWrong'] };
}

export async function putProfileAvatar(avatar: FormData): Promise<UpdateProfileResult> {
	const url = apis.profile.avatar;
	const body = avatar;
	const init = { method: 'PUT', body } as RequestInit;
	const request = createRequest(url, init, true, 'multipart/form-data');

	const response = await fetchWithTimeout(request);
	process401Response(response);

	if (response.ok) {
		detectDocsTokenInResponseHeader(response, false);
		const profile = await response.json();
		return { hasError: false, profile };
	} else if (response.status === 400) {
		const error = await response.json();
		return { hasError: true, message: error.message, errors: error.errorCode.split(',') };
	}

	return { hasError: true, message: response.statusText, errors: ['somethingWentWrong'] };
}

export async function deleteProfileAvatar(): Promise<UpdateProfileResult> {
	const url = apis.profile.avatar;
	const init = { method: 'DELETE' } as RequestInit;

	const response = await fetchWithTimeout(createRequest(url, init));
	process401Response(response);

	if (response.ok) {
		detectDocsTokenInResponseHeader(response, false);
		return { hasError: false, profile: null };
	} else if (response.status === 400) {
		const error = await response.json();
		return { hasError: true, message: error.message, errors: error.errorCode.split(',') };
	}

	return { hasError: true, message: response.statusText, errors: ['somethingWentWrong'] };
}

// register profile
export async function postProfile(profile: ProfileRequest): Promise<UpdateProfileResult> {
	const url = apis.profile.profiles;
	const body = JSON.stringify(profile);
	const init = { method: 'POST', body } as RequestInit;
	const request = createRequest(url, init);
	request.headers.set('Authorization', `Bearer ${getIdToken()}`);

	const response = await fetchWithTimeout(request);
	process401Response(response);

	if (response.ok) {
		detectDocsTokenInResponseHeader(response, false);
		const profile = await response.json();
		return { hasError: false, profile };
	} else if (response.status === 400) {
		const error = await response.json();
		return { hasError: true, message: error.message, errors: error.errorCode.split(',') };
	} else if (response.status === 409) {
		return { hasError: true, message: response.statusText, errors: ['alreadyRegistered'] };
	}

	const result: UpdateProfileResult = {
		hasError: true,
		message: response.statusText,
		errors: ['somethingWentWrong']
	};
	return result;
}

// delete profile
export async function deleteProfile(): Promise<boolean> {
	const url = apis.privacy.delete;
	const init = { method: 'POST' } as RequestInit;

	const response = await fetchWithTimeout(createRequest(url, init));
	process401Response(response);

	return response.ok;
}

// partial delete
export async function devPartialDeleteProfile(servicesToDelete: string[]): Promise<boolean> {
	const url = apis.privacy.partialDelete;
	const body = JSON.stringify(servicesToDelete);
	const init = { method: 'POST', body } as RequestInit;

	const response = await fetchWithTimeout(createRequest(url, init));
	process401Response(response);

	return response.ok;
}

export async function exportProfile(
	locale = msDocs.data.userLocale,
	isDownload = true
): Promise<{ data: Blob; filename: string }> {
	const url = `${apis.privacy.export}?${toQueryString({ locale, isDownload })}`;
	const init = { method: 'GET' } as RequestInit;

	const response = await fetchWithTimeout(createRequest(url, init));
	process401Response(response);

	if (!response.ok) {
		throw new Error(`Unexpected response status ${response.status} for ${url}`);
	}

	const data = await response.blob();
	const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
	const matches = filenameRegex.exec(response.headers.get('Content-Disposition') || '');
	const filename = matches ? matches[0] : '';

	return {
		data,
		filename
	};
}

// Avert api for report abuse
export async function postAvert(profileRequest: ProfileRequest): Promise<void> {
	const url = apis.profile.avert;
	const body = JSON.stringify(profileRequest);
	const init = { method: 'POST', body } as RequestInit;

	const response = await fetchWithTimeout(createRequest(url, init));
	process401Response(response);
	detectDocsTokenInResponseHeader(response, false);

	if (!response.ok) {
		throw new Error(`Unexpected response status ${response.status} for ${url}`);
	}
}

export async function setAcceptedPrivacyNotice(): Promise<void> {
	const url = apis.profile.acknowledgePrivacyNotice;
	const init = { method: 'POST' } as RequestInit;

	const response = await fetchWithTimeout(createRequest(url, init));
	process401Response(response);
	detectDocsTokenInResponseHeader(response, false);

	if (!response.ok) {
		throw new Error(`Unexpected response status ${response.status} for ${url}`);
	}
}

export async function linkAccount(idToken: string): Promise<UpdateProfileResult> {
	const url = apis.profile.link;
	const init = { method: 'POST', body: `"${idToken}"` } as RequestInit;

	const response = await fetchWithTimeout(createRequest(url, init));
	process401Response(response);

	if (response.ok) {
		detectDocsTokenInResponseHeader(response, false);
		return { hasError: false, profile: await response.json() };
	} else if (response.status === 405) {
		return { hasError: true, message: response.statusText, errors: ['accountLimitReached'] };
	} else if (response.status === 409) {
		return { hasError: true, message: response.statusText, errors: ['accountAlreadyRegistered'] };
	} else {
		throw new Error(`Unexpected response status ${response.status} for ${url}`);
	}
}

export async function unlinkAccount(type: string, userId: string): Promise<UpdateProfileResult> {
	const url = `${apis.profile.link}/${type}/${userId}`;
	const init = { method: 'DELETE' } as RequestInit;

	const response = await fetchWithTimeout(createRequest(url, init));
	process401Response(response);

	if (response.ok) {
		detectDocsTokenInResponseHeader(response, false);
		return { hasError: false, profile: await response.json() };
	} else if (response.status === 405) {
		return {
			hasError: true,
			message: response.statusText,
			errors: ['needAtLeastOneAccountLinked']
		};
	} else {
		throw new Error(`Unexpected response status ${response.status} for ${url}`);
	}
}

export async function speakersBureauStart(
	speakersBureauAnswer: SpeakersBureauAnswer
): Promise<SpeakersBureauResult> {
	const url = apis.profile.speakersBureauStart;
	const body = JSON.stringify(speakersBureauAnswer);
	const init = { method: 'POST', body } as RequestInit;

	const response = await fetchWithTimeout(createRequest(url, init, true));
	process401Response(response);

	if (response.ok) {
		detectDocsTokenInResponseHeader(response, false);
		return { hasError: false, message: null, error: null };
	} else {
		const message = `Unexpected response status ${response.status} for ${url}`;
		return { hasError: true, message: `${message}`, error: new Error(`${message}`) };
	}
}

export async function speakersBureauGetProfile(
	email: string
): Promise<SpeakersBureauProfileResult> {
	if (email === '') {
		return {
			hasError: true,
			message: 'Email not supplied.',
			profile: null,
			error: new Error('Email not supplied.')
		};
	}
	const url = apis.profile.speakersBureauStart + `/${email}`;
	const init = { method: 'GET' } as RequestInit;

	const response = await fetchWithTimeout(createRequest(url, init, true));
	process401Response(response);

	if (response.ok) {
		detectDocsTokenInResponseHeader(response, false);
		return { hasError: false, profile: await response.json() };
	} else {
		const message = `Unexpected response status ${response.status} for ${url}`;
		return { hasError: true, profile: null, message: `${message}`, error: new Error(`${message}`) };
	}
}

// Common function to create Request based on url and init
function createRequest(
	url: string,
	init?: RequestInit,
	requiresAuthorization: boolean = true,
	contentType: string = 'application/json'
): Request {
	init = init || {};
	init.mode = 'cors';

	const request = new Request(url, init);
	if (requiresAuthorization) {
		request.headers.set('Authorization', `Bearer ${getDocsToken()}`);
	}

	if (request.method === 'PUT' || request.method === 'POST') {
		// if we have a multipart upload, let the browser determine the boundary.
		if (contentType !== 'multipart/form-data') {
			request.headers.set('Content-Type', contentType);
		}
	}
	return request;
}

export async function getFollowingQnA(): Promise<Following> {
	const url = apis.profile.qnaFollowing;
	const init = { method: 'GET' } as RequestInit;

	const response = await fetchWithTimeout(createRequest(url, init, true));
	process401Response(response);

	if (response.ok) {
		const following = await response.json();
		return following;
	} else if (response.status === 204) {
		return null;
	}

	return null;
}

export interface UpdateProfileResult {
	hasError: boolean;
	profile?: ProfileResponse;
	message?: string;
	errors?: string[];
}

export type OptIn = 'OptInExplicit' | 'OptInImplicit' | null;

export interface ProfileRequest {
	userName?: string;
	isPrivate?: boolean;
	displayName: string;
	email?: string;
	locale?: string;
	country?: string;
	contactPointTopicSetting?: OptIn;
	avatarImageBytes: File;
	avatarExtension: string;
	source: string;
	interests?: Interests;
	gsi: boolean | null;
}

export interface SpeakersBureauResult {
	hasError: boolean;
	message: string;
	error: Error;
}

export interface SpeakersBureauProfileResult {
	hasError: boolean;
	message?: string;
	profile: SpeakersBureauAnswer;
	error?: Error;
}

export interface SpeakersBureauAnswer {
	displayName: string;
	email: string;
	eventTracks: string[];
	imageURL: string;
	roles: string[];
	orgs: string[];
	cityOrTown: string;
	stateOrProvince: string;
	countryOrRegionCode: string;
	postCode: string;
	bio: string;
	description: string;
	puid: string;
	travel: boolean;
	range?: number;
	isMCT: boolean;
	videoUrls?: string[];
	technologyAreas: string[];
	learningPaths: string[];
	languages: string[];
}

export interface UserActivity {
	totalItems: number;
	items: (AnswerItem | ModuleItem)[];
}

export interface AnswerItem {
	type: string;
	timestamp: string;
	data: AnswerData;
}

export interface AnswerData {
	verb: string;
	extra: string;
	node: AnswerDataNode;
	rootNode: AnswerDataNode;
}

export interface AnswerDataNode {
	id: number;
	type: string;
	body: string;
	commentIds: number[];
	childrenIds: number[];
	upVoteCount: number;
	title: string;
	slug: string;
}

export interface ModuleItem {
	type: string;
	timestamp: string;
	data: ModuleData;
}

export interface ModuleData {
	module: ModuleAndPathSummary;
	moduleProgress: ProgressItem;
	unitProgress: ProgressItem[];
}

import { loc_addParameter, loc_deleteParameter, loc_emptyNameNotAllowed } from '@msdocs/strings';
import { AzSubscriptionsResult } from '../azure/oauth';
import { azureToken } from '../azure/token';
import { EventBus } from '../event-bus';
import {
	RestAddParamEvent,
	RestParamValueChanged,
	RestRetrieveRequestData,
	RestSubscriptionIdLoaded,
	RestSubscriptionIdLoading
} from './events';
import { Parameter } from './types';

const subscriptionUrl = 'https://management.azure.com/subscriptions?api-version=2016-06-01';

//Load subscription Ids
let loadSubscriptions = (bus: EventBus) => {
	bus.publish(new RestSubscriptionIdLoading());
	const request = new Request(subscriptionUrl, { mode: 'cors' });
	if (azureToken.value === null) {
		bus.publish(new RestSubscriptionIdLoaded([]));
		throw new Error('error fetching subscription: authorization header no token');
	}
	request.headers.append('Authorization', `Bearer ${azureToken.value.access_token}`);
	return fetch(request).then(
		response => {
			if (!response.ok) {
				response.text().then(text => {
					throw new Error(`error fetching subscriptions:\n\n${text}`);
				});
				bus.publish(new RestSubscriptionIdLoaded([]));
				return false;
			}

			return response.json().then(({ value: subscriptions }: AzSubscriptionsResult) => {
				bus.publish(new RestSubscriptionIdLoaded(subscriptions));
				return true;
			});
		},
		() => false
	);
};

export function renderParam(
	container: HTMLElement,
	bus: EventBus,
	param: Parameter,
	isHeaders: boolean = true,
	urlParams: { [name: string]: string } = {}
) {
	const columnContainer = document.createElement('div');
	columnContainer.classList.add('columns');

	const columnNameDiv = document.createElement('div');
	columnNameDiv.classList.add('column', 'is-5', 'param-name');

	const fieldLabelNameDiv = document.createElement('div');
	fieldLabelNameDiv.classList.add('field-label', 'is-normal');

	const nameInput = document.createElement('label');
	nameInput.classList.add('label', 'is-radiusless');
	nameInput.setAttribute('aria-label', 'parameter name ' + param.name);
	nameInput.textContent = param.name;
	fieldLabelNameDiv.appendChild(nameInput);
	columnNameDiv.appendChild(fieldLabelNameDiv);
	columnContainer.appendChild(columnNameDiv);

	// Parameter value group
	const columnValueDiv = document.createElement('div');
	columnValueDiv.classList.add('column', 'is-5', 'param-value');

	const paramValueDiv = document.createElement('div');
	paramValueDiv.classList.add('field-body');

	const fieldValueDiv = document.createElement('div');
	fieldValueDiv.classList.add('field');

	const controlValueParagraph = document.createElement('p');
	controlValueParagraph.classList.add('control');

	const valueInput = document.createElement('input');
	valueInput.classList.add('input', 'is-radiusless');

	const selectList = [];
	const selectDiv = document.createElement('div');
	selectDiv.classList.add('select');

	const selectElement = document.createElement('select');
	selectElement.classList.add('is-radiusless');
	if (param.type === 'boolean') {
		selectList.push('True');
		selectList.push('False');
	}

	// // Subscription ID display
	const subscriptionId = document.createElement('div');
	subscriptionId.classList.add('subscription-id');
	subscriptionId.textContent = '';
	// valueGroup.appendChild(subscriptionId); // keeping in case this needs to be added back

	const handleRetrieveData = (event: RestRetrieveRequestData) => {
		const requestData = event.restTryItRequest;
		const value =
			param.type === 'boolean' || param.type === 'azure-subscriptions' || param.type === 'enum'
				? selectElement.value
				: valueInput.value;

		if (isHeaders) {
			requestData.headers.push({
				name: param.name,
				value,
				type: param.type,
				in: param.in,
				isRequired: param.isRequired,
				skipUrlEncoding: false
			});
		} else {
			requestData.params.push({
				name: param.name,
				value,
				type: param.type,
				in: param.in,
				isRequired: param.isRequired,
				skipUrlEncoding: param.skipUrlEncoding
			});
		}
	};

	switch (param.type) {
		case 'boolean':
		case 'azure-subscriptions':
		case 'enum':
			if (selectList.length >= 1) {
				const defaultOption = document.createElement('option');
				defaultOption.value = '';
				defaultOption.selected = true;
				defaultOption.disabled = true;
				defaultOption.hidden = true;
				defaultOption.text = 'Select';
				selectElement.appendChild(defaultOption);
			}

			selectList.forEach(item => {
				const option = document.createElement('option');
				option.value = item;
				option.text = item;
				selectElement.appendChild(option);
			});
			if (param.isRequired) {
				selectElement.setAttribute('required', 'true');
			}
			selectElement.setAttribute('aria-label', 'select parameter value option for ' + param.name);

			selectElement.onchange = () => {
				bus.publish(new RestParamValueChanged());

				if (selectElement.textContent !== selectElement.value) {
					subscriptionId.textContent = selectElement.value;
				}
			};

			if (param.name === 'subscriptionId') {
				selectElement.disabled = true;
				selectElement.innerHTML = '<option value="">Login to load...</option>';
			}
			selectDiv.appendChild(selectElement);
			columnValueDiv.appendChild(selectDiv);
			break;
		case 'array':
			break;
		case 'object':
			break;
		default:
			// 'string', 'integer', and all other types
			valueInput.setAttribute('value', param.value);
			if (param.isRequired) {
				valueInput.setAttribute('required', 'true');
			}
			valueInput.setAttribute('aria-label', 'enter parameter value for ' + param.name);
			valueInput.onblur = () => {
				bus.publish(new RestParamValueChanged());
			};
			controlValueParagraph.appendChild(valueInput);
			fieldValueDiv.appendChild(controlValueParagraph);
			paramValueDiv.appendChild(fieldValueDiv);
			columnValueDiv.appendChild(paramValueDiv);
			break;
	}

	if (param.isRequired) {
		nameInput.insertAdjacentHTML(
			'beforeend',
			`<span class="required-indicator" aria-hidden="true"></span>`
		);
	}

	columnContainer.appendChild(columnValueDiv);

	// Delete button
	const columnDelButton = document.createElement('div');
	columnDelButton.classList.add('column');
	columnDelButton.classList.add('is-2');
	columnDelButton.classList.add('delete-button');

	const delButton = document.createElement('button');
	delButton.classList.add('button');
	delButton.classList.add('docon');
	delButton.classList.add('docon-math-multiply');
	if (param.isRequired) {
		delButton.style.visibility = 'hidden';
	}
	delButton.setAttribute('aria-label', loc_deleteParameter);
	delButton.setAttribute('type', 'button');
	columnDelButton.appendChild(delButton);
	columnContainer.appendChild(columnDelButton);

	delButton.onclick = event => {
		columnContainer.parentElement.removeChild(columnContainer);
		bus.unsubscribe(RestRetrieveRequestData, handleRetrieveData);
		bus.publish(new RestParamValueChanged());

		event.preventDefault();
	};

	container.appendChild(columnContainer);

	// Listen for RestRetrieveRequestData event
	bus.subscribe(RestRetrieveRequestData, handleRetrieveData);

	if (!isHeaders) {
		// Listen for RestSubscriptionIdLoading and RestSubscriptionIdLoaded events
		if (param.name === 'subscriptionId') {
			bus.subscribe(RestSubscriptionIdLoading, () => {
				selectElement.innerHTML = '<option value=""></option>';
				selectElement.disabled = false;
			});

			bus.subscribe(RestSubscriptionIdLoaded, (event: RestSubscriptionIdLoaded) => {
				const subscriptions = event.subscriptions;
				if (subscriptions.length === 0) {
					selectElement.innerHTML = '<option value="">Error</option>';
					selectElement.value = null;
				} else {
					selectElement.innerHTML = subscriptions
						.map(
							({ displayName, subscriptionId }) =>
								`<option value="${subscriptionId}">${displayName}</option>`
						)
						.join('\n');
					selectElement.value = subscriptions[0].subscriptionId;
					selectElement.hidden = false;
					selectElement.disabled = false;
				}

				subscriptionId.textContent = subscriptions[0].subscriptionId;

				bus.publish(new RestParamValueChanged());
			});
		}
	}

	if (
		param.type === 'string' &&
		param.in === 'query' &&
		urlParams[param.name] !== undefined &&
		urlParams[param.name].indexOf(param.name) === -1
	) {
		valueInput.value = urlParams[param.name];
		bus.publish(new RestParamValueChanged());
	}

	//Load subscription Ids if we have parameter subscriptionId
	if (param.name === 'subscriptionId') {
		loadSubscriptions(bus); //.then();
	}

	return { nameInput, valueInput, delButton, columnContainer };
}

export function renderEmptyParam(container: HTMLElement, bus: EventBus, isHeaders: boolean = true) {
	const columnContainer = document.createElement('div');
	columnContainer.classList.add('columns');

	const columnNameDiv = document.createElement('div');
	columnNameDiv.classList.add('column', 'is-5', 'param-name');

	// Parameter name group
	const paramNameDiv = document.createElement('div');
	paramNameDiv.classList.add('field-body');

	const fieldNameDiv = document.createElement('div');
	fieldNameDiv.classList.add('field');

	const controlNameParagraph = document.createElement('p');
	controlNameParagraph.classList.add('control');

	const nameInput = document.createElement('input');
	nameInput.classList.add('input', 'is-radiusless');
	nameInput.setAttribute('placeholder', 'name');
	nameInput.setAttribute('aria-label', 'add new parameter name');
	controlNameParagraph.appendChild(nameInput);
	fieldNameDiv.appendChild(controlNameParagraph);
	paramNameDiv.appendChild(fieldNameDiv);
	columnNameDiv.appendChild(paramNameDiv);

	const errorLabel = document.createElement('div');
	errorLabel.classList.add('error-message');
	columnNameDiv.appendChild(errorLabel);
	columnContainer.appendChild(columnNameDiv);

	nameInput.onblur = () => {
		if (nameInput.value !== '' && errorLabel.textContent !== '') {
			columnNameDiv.removeChild(errorLabel);
		}
	};

	// Parameter value group
	const columnValueDiv = document.createElement('div');
	columnValueDiv.classList.add('column', 'is-5', 'param-value');

	const paramValueDiv = document.createElement('div');
	paramValueDiv.classList.add('field-body');

	const fieldValueDiv = document.createElement('div');
	fieldValueDiv.classList.add('field');

	const controlValueParagraph = document.createElement('p');
	controlValueParagraph.classList.add('control');

	const valueInput = document.createElement('input');
	valueInput.classList.add('input', 'is-radiusless');
	valueInput.setAttribute('type', 'text');
	valueInput.setAttribute('placeholder', 'value');
	valueInput.setAttribute('aria-label', 'add new parameter value');
	controlValueParagraph.appendChild(valueInput);
	fieldValueDiv.appendChild(controlValueParagraph);
	paramValueDiv.appendChild(fieldValueDiv);
	columnValueDiv.appendChild(paramValueDiv);
	columnContainer.appendChild(columnValueDiv);

	// Add button
	const columnAddButtonDiv = document.createElement('div');
	columnAddButtonDiv.classList.add('column', 'is-2', 'add-button');

	const addButton = document.createElement('button');
	addButton.classList.add(
		'button',
		'is-transparent',
		'has-text-success',
		'docon',
		'docon-math-plus'
	);
	addButton.setAttribute('type', 'button');
	addButton.setAttribute('aria-label', loc_addParameter);
	columnAddButtonDiv.appendChild(addButton);
	columnContainer.appendChild(columnAddButtonDiv);
	container.appendChild(columnContainer);

	const addEmptyParam = (event: Event) => {
		const name = nameInput.value;
		const value = valueInput.value;

		if (name === '') {
			errorLabel.textContent = loc_emptyNameNotAllowed;
			columnNameDiv.appendChild(errorLabel);
			event.preventDefault();
			return;
		}

		let inType: 'header' | 'path' | 'query';
		if (isHeaders) {
			inType = 'header';
		} else {
			const inputUrl = document.querySelector('.url-input') as HTMLInputElement;
			if (inputUrl.value.indexOf('{' + name + '}') !== -1) {
				inType = 'path';
			} else {
				inType = 'query';
			}
		}

		columnContainer.parentElement.removeChild(columnContainer);
		bus.publish(
			new RestAddParamEvent({
				name,
				value,
				type: 'string',
				in: inType,
				isRequired: false,
				skipUrlEncoding: false
			})
		);
	};

	addButton.onclick = event => {
		addEmptyParam(event);
	};

	return { nameInput, valueInput, addButton, columnContainer };
}

// This function is only used for unit test, will get excluded by tree-shaking
export function mockloadSubscriptions(mockFn: (bus: EventBus) => Promise<boolean>) {
	loadSubscriptions = mockFn;
}

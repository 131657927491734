import { loc_nextPage, loc_pageOfPages, loc_pagination, loc_previousPage } from '@msdocs/strings';

const defaultBufferSize = 4;

const previousButtonTemplate = (currentPage: number) => {
	return currentPage > 1
		? `<a class="pagination-link pagination-previous" rel="prev" data-page="${Math.max(
				currentPage - 1,
				1
		  )}" href="?page=${
				currentPage - 1
		  }" aria-label="${loc_previousPage}"><span class="icon" aria-hidden="true"><span class="docon docon-arrow-left"></span></span></a>`
		: '';
};

const pageNumberTemplate = (currentPage: number, maxPages: number) => (page: number) => `
	<li>
		<a class="pagination-link ${
			page === currentPage ? `is-current` : ''
		}" data-page="${page}" href="?page=${page}" aria-label="${loc_pageOfPages
	.replace('{pageNumber}', String(page))
	.replace('{numberOfPages}', String(maxPages))}" ${
	page === currentPage ? `aria-current="true"` : ''
}>${page}</a>
	</li>
`;

const nextButtonTemplate = (currentPage: number, maxPages: number) => {
	return currentPage < maxPages
		? `<a class="pagination-link pagination-next" rel="next" data-page="${Math.min(
				currentPage + 1,
				maxPages
		  )}" href="?page=${
				currentPage + 1
		  }" aria-label="${loc_nextPage}"><span class="icon" aria-hidden="true"><span class="docon docon-arrow-right"></span></span></a>`
		: '';
};

export const pagingControlTemplate = (
	currentPage = 1,
	maxPages = Infinity,
	buffer = defaultBufferSize
) => {
	// eslint-disable-next-line prefer-spread
	const pages: number[] = (Array.apply(null, Array(buffer * 2 + 1)) as number[])
		.map((_, i) => currentPage - buffer + i)
		.filter(x => x > 0 && x <= maxPages);

	return `
	<nav class="pagination is-centered" role="navigation" aria-label="${loc_pagination}">
		${previousButtonTemplate(currentPage)}
		<ul class="pagination-list">
			${pages.map(pageNumberTemplate(currentPage, maxPages)).join('')}
		</ul>
		${nextButtonTemplate(currentPage, maxPages)}
	</nav>
	`;
};

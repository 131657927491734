import {
	loc_backToPriorMenu,
	loc_bookmark,
	loc_bookmarks,
	loc_close,
	loc_collections,
	loc_edit,
	loc_editdesc,
	loc_email,
	loc_facebook,
	loc_feedback,
	loc_linkedin,
	loc_moreActions,
	loc_pageActions,
	loc_profile,
	loc_readInEnglish,
	loc_readInLanguageTemplate,
	loc_sendFeedbackAboutThisPage,
	loc_share,
	loc_sharedesc,
	loc_signIn,
	loc_signOut,
	loc_twitter,
	loc_viewCode,
	loc_wechat,
	loc_yourAccount
} from '@msdocs/strings';
import { initSteps } from '../../src/pages/tutorial';
import { refreshProfileFields, user } from '../auth/user';
import { isDevelopment } from '../branch-selector';
import { Modal } from '../components/modal/modal';
import { features } from '../environment/features';
import {
	GitAttributes as pageGitAttribs,
	LocalizationAttributes as pageLocAttribs,
	TemplateAttributes as pageTemplateAttribs
} from '../environment/page';
import { document, location, msDocs, window } from '../globals';
import { setButtonPressed } from '../lists';
import { html, ifDefined, render, TemplateResult } from '../lit-html';
import { scrollToHash } from '../router/router';
import { initSharingLinks } from '../sharing';
import { supportedLocaleNames } from '@msdocs/locales';

/**
 * The modal which contains the menu. Will be undefined while the modal is not showing.
 */
let pageActionsModal: Modal;

export function initMobileModalPageActions() {
	if (!msDocs.data.hasPageActions) {
		return;
	}

	let pageActionsContentParams: PageActionsContentParams = createModalPageActionsMenu();
	if (pageActionsContentParams.menuItems.length === 0) {
		document.querySelector('.page-actions-button')?.parentElement?.remove();
		return;
	}

	window.addEventListener('click', async event => {
		const button = event.target instanceof Element && event.target.closest('.page-actions-button');
		if (!button) {
			return;
		}

		pageActionsContentParams = createModalPageActionsMenu();
		const { container, pageActionsInitializer } = createModalPageActionsContent(
			pageActionsContentParams
		);

		event.preventDefault();
		const modal = new Modal(container);
		pageActionsModal = modal;
		const width = window.innerWidth;
		// close on horizontal resize.
		const handleResize = () => {
			if (window.innerWidth === width) {
				return;
			}
			window.removeEventListener('resize', handleResize);
			modal.hide();
		};
		window.addEventListener('resize', handleResize);
		pageActionsInitializer(container);
		await modal.show();

		// Cleanup
		pageActionsModal = undefined;
		pageActionsContentParams = undefined;
		window.removeEventListener('resize', handleResize);
	});
}

function createModalPageActionsMenu(): PageActionsContentParams {
	const isDevelopmentUrl = isDevelopment(location.origin);

	// Create menu items
	const bookMarkMenuItem = createBookmarkMenuItem();
	const feedbackMenuItem = createFeedbackMenuItem();
	const editContentMenuItem = createEditContentMenuItem(
		pageGitAttribs.contentGitUrl,
		pageGitAttribs.originalContentGitUrl,
		pageGitAttribs.originalContentGitUrlTemplate,
		pageGitAttribs.fragmentsFileGitUrl,
		pageGitAttribs.isEditDisplayable,
		isDevelopmentUrl,
		pageGitAttribs.prRepo,
		pageGitAttribs.prBranch
	);
	const viewCodeMenuItem = createViewCodeMenuItem(
		pageGitAttribs.hideViewSource,
		pageGitAttribs.isEditDisplayable,
		pageGitAttribs.sourceUrl
	);
	const shareMenuItem = createShareMenuItem(pageTemplateAttribs.brand, pageLocAttribs.userLocale);
	const languageToggleItem = createLanguageToggleMenuItem(
		pageLocAttribs.hasLocSideBySideTranslation,
		pageTemplateAttribs.template,
		pageLocAttribs.contentLocale
	);
	const signInMenuItem = createSignInMenuItem(
		pageTemplateAttribs.brand,
		pageGitAttribs.isDeveloperSite
	);
	const menuItems = [
		bookMarkMenuItem,
		feedbackMenuItem,
		editContentMenuItem,
		viewCodeMenuItem,
		shareMenuItem,
		languageToggleItem,
		signInMenuItem
	].filter(x => x !== null);

	return {
		menuItems,
		signInMenuItem
	};
}

interface PageActionsContentParams {
	menuItems: TemplateResult[];
	signInMenuItem: TemplateResult;
}

function createModalPageActionsContent({ menuItems, signInMenuItem }: PageActionsContentParams) {
	// Render top-level menu
	//eslint-disable-next-line
	return {
		container: renderMenu({
			menuHeading: loc_pageActions,
			allMenuItems: menuItems,
			initializer: (container, displayedMenuItems) => {
				if (signInMenuItem && displayedMenuItems && displayedMenuItems.includes(signInMenuItem)) {
					user.whenAuthenticated().then(() => refreshProfileFields(container, user));
				}
			}
		}),
		pageActionsInitializer: (container: HTMLDivElement) => {
			const bookmarkMenuItem = container.querySelector(
				'button[data-list-type="bookmarks"]'
			) as HTMLButtonElement;
			const bookmarkButton = document.querySelector(
				'.content-header button[data-list-type="bookmarks"]'
			) as HTMLButtonElement;
			if (bookmarkMenuItem && bookmarkButton) {
				const bookmarked = bookmarkButton.getAttribute('aria-pressed') === 'true';
				setButtonPressed(bookmarkMenuItem, 'bookmarks', document.title, bookmarked);
			}
		}
	};
}

type HandleMenuItemClickFunction = (event: MouseEvent) => any;

interface ButtonMenuItemTemplateParameters {
	label: string;
	title?: string;
	doconName?: string;
	buttonExtraClasses?: string;
	dataListType?: string;
	dataBiName?: string;
	childMenuView?: HTMLDivElement;
	isMoreMenuItem?: boolean;
	handleClick?: HandleMenuItemClickFunction;
}

function createButtonMenuItemTemplate({
	label = '',
	title,
	doconName,
	buttonExtraClasses = '',
	dataListType,
	dataBiName,
	childMenuView,
	isMoreMenuItem = false,
	handleClick = () => {}
}: ButtonMenuItemTemplateParameters): TemplateResult {
	const handleSelection = (event: MouseEvent) => {
		if (childMenuView) {
			event.preventDefault();
			event.stopPropagation();
			if (document.activeElement as HTMLElement) {
				dispatchEvent(new CustomEvent('blur', { bubbles: true }));
			}
			pageActionsModal.pushContent(childMenuView);
		}

		if (handleClick) {
			handleClick(event);
		}
	};
	const icon = doconName
		? html` <span class="icon is-flex-column" aria-hidden="true">
				<span class="docon ${doconName}"></span>
		  </span>`
		: null;
	const doconChevron =
		msDocs.data.userDir === 'rtl' ? 'docon-chevron-left-light' : 'docon-chevron-right-light';
	const subMenuChevron =
		childMenuView && !isMoreMenuItem
			? html` <span class="icon is-flex-column">
					<span class="docon ${doconChevron}" aria-hidden="true"></span>
			  </span>`
			: null;
	const listItemMargin = !isMoreMenuItem
		? 'has-margin-medium'
		: 'has-margin-left-medium has-margin-top-small has-margin-right-medium has-margin-bottom-small';
	const textMargin = !isMoreMenuItem
		? msDocs.data.userDir === 'rtl'
			? 'has-margin-right-medium'
			: 'has-margin-left-small'
		: '';
	const buttonHasPopup = childMenuView ? true : false;
	return html`
		<li class="${listItemMargin}" role="none">
			<button
				@click="${handleSelection}"
				autofocus
				type="button"
				class="is-flex button is-text is-fullwidth has-flex-justify-content-start has-inner-focus modal-close ${ifDefined(
					buttonExtraClasses
				)}"
				title="${ifDefined(title)}"
				data-list-type="${ifDefined(dataListType)}"
				data-bi-name="${ifDefined(dataBiName)}"
				aria-haspopup="${buttonHasPopup}"
			>
				${icon}
				<span class="is-flex-column is-full-width has-text-justified ${textMargin}">${label}</span>
				${subMenuChevron}
			</button>
		</li>
	`;
}

interface AnchorMenuItemTemplateParameters {
	label: string;
	title?: string;
	doconName?: string;
	extraClasses?: string;
	dataListType?: string;
	dataBiName?: string;
	href?: string;
	dataProfileProperty?: string;
}

function createAnchorMenuItemTemplate({
	label = '',
	title,
	doconName,
	extraClasses = '',
	dataListType,
	dataBiName,
	href,
	dataProfileProperty
}: AnchorMenuItemTemplateParameters): TemplateResult {
	const handleSelection = () => {
		if (href && href.startsWith('#')) {
			pageActionsModal.hide();
			scrollToHash(href);
		}
	};
	const icon = doconName
		? html` <span class="icon" aria-hidden="true">
				<span class="docon ${doconName}"></span>
		  </span>`
		: null;
	const textMargin =
		msDocs.data.userDir === 'rtl' ? 'has-margin-right-medium' : 'has-margin-left-small';
	return html`
		<li class="has-margin-medium" role="none">
			<a
				@click="${handleSelection}"
				autofocus
				href="${href}"
				role="menuitem"
				class="is-flex button is-text is-fullwidth has-flex-justify-content-start has-inner-focus ${ifDefined(
					extraClasses
				)}"
				data-list-type="${ifDefined(dataListType)}"
				data-bi-name="${ifDefined(dataBiName)}"
				title="${ifDefined(title)}"
				data-profile-property="${ifDefined(dataProfileProperty)}"
			>
				${icon}
				<span class="${textMargin}">${label}</span>
			</a>
		</li>
	`;
}

interface EditContentAnchorMenuItemTemplateParameters {
	href: string;
	label: string;
	title: string;
	doconName: string;
	dataBiName: string;
	originalContentGitUrl?: string;
	originalContentGitUrlTemplate?: string;
	prRepo?: string;
	prBranch?: string;
}

function createEditContentAnchorMenuItemTemplate({
	href,
	label,
	title,
	doconName,
	dataBiName,
	originalContentGitUrl,
	originalContentGitUrlTemplate,
	prRepo,
	prBranch
}: EditContentAnchorMenuItemTemplateParameters): TemplateResult {
	const icon = doconName
		? html` <span class="icon" aria-hidden="true">
				<span class="docon ${doconName}"></span>
		  </span>`
		: null;
	const textMargin =
		msDocs.data.userDir === 'rtl' ? 'has-margin-right-medium' : 'has-margin-left-small';
	return html`
		<li class="has-margin-medium" role="none">
			<a
				href="${href}"
				role="menuitem"
				class="button is-text is-fullwidth has-flex-justify-content-start has-inner-focus"
				title="${ifDefined(title ? title : undefined)}"
				data-bi-name="${dataBiName}"
				data-original_content_git_url="${ifDefined(
					originalContentGitUrl ? originalContentGitUrl : undefined
				)}"
				data-original_content_git_url_template="${ifDefined(
					originalContentGitUrlTemplate ? originalContentGitUrlTemplate : undefined
				)}"
				data-pr_repo="${ifDefined(prRepo ? prRepo : undefined)}"
				data-pr_branch="${ifDefined(prBranch ? prBranch : undefined)}"
			>
				${icon}
				<span class="${textMargin}">${label}</span>
			</a>
		</li>
	`;
}

function createBookmarkMenuItem(): TemplateResult {
	if (!features.userServices || !msDocs.data.hasBookmark) {
		return null;
	}

	const desktopButton = document.querySelector(
		'.content-header button[data-list-type="bookmarks"]'
	) as HTMLButtonElement;
	const bookmarked = desktopButton.getAttribute('aria-pressed') === 'true';
	const doconName = `docon-single-bookmark${bookmarked ? '-solid' : ''}`;
	const label = loc_bookmark;
	const title = desktopButton.title;
	const dataBiName = 'bookmark';
	const dataListType = 'bookmarks';
	const handleClick = () => {
		// Fake click on the desktop bookmark button to ensure things remain in sync.
		desktopButton.dispatchEvent(new CustomEvent('click', { bubbles: true }));
	};

	return createButtonMenuItemTemplate({
		label,
		title,
		doconName,
		dataListType,
		dataBiName,
		handleClick
	});
}

function createFeedbackMenuItem(): TemplateResult {
	if (!features.githubIssues || !msDocs.data.hasGithubIssues) {
		return null;
	}

	if (pageTemplateAttribs.type === 'tutorial') {
		const { isLast } = initSteps();
		if (!isLast) {
			return null;
		}
	}

	return createAnchorMenuItemTemplate({
		label: loc_feedback,
		title: loc_sendFeedbackAboutThisPage,
		doconName: 'docon-comment-lines',
		dataBiName: 'comments',
		href: '#feedback'
	});
}

function createEditContentMenuItem(
	contentGitUrl: string,
	originalContentGitUrl: string,
	originalContentGitUrlTemplate: string,
	fragmentsFileGitUrl: string,
	isEditDisplayable: boolean,
	isDevelopmentUrl: boolean,
	prRepo: string,
	prBranch: string
): TemplateResult {
	if (!isEditDisplayable || !isDevelopmentUrl) {
		return null;
	}

	if (fragmentsFileGitUrl) {
		return createEditContentAnchorMenuItemTemplate({
			href: fragmentsFileGitUrl,
			label: loc_edit,
			title: loc_editdesc,
			doconName: 'docon-edit-outline',
			dataBiName: 'edit'
		});
	} /* tslint:disable-next-line */ // remove after eslint migration

	/* eslint-disable-next-line */ return createEditContentAnchorMenuItemTemplate({
		href: contentGitUrl,
		label: loc_edit,
		title: loc_editdesc,
		doconName: 'docon-edit-outline',
		dataBiName: 'edit',
		originalContentGitUrl,
		originalContentGitUrlTemplate,
		prRepo,
		prBranch
	});
}

function createViewCodeMenuItem(
	hideViewSource: boolean,
	isEditDisplayable: boolean,
	pageSourceUrl: string
): TemplateResult {
	if (!hideViewSource && isEditDisplayable && pageSourceUrl) {
		return createAnchorMenuItemTemplate({
			label: loc_viewCode,
			doconName: 'docon-file-code',
			dataBiName: 'viewsource',
			href: pageSourceUrl
		});
	}

	return null;
}

function createShareMenuItem(brand: string, userLocale: string): TemplateResult {
	if (!features.shareLink || !msDocs.data.hasShare) {
		return null;
	}

	if (brand === 'mooncake') {
		const weiboMenuItem = createAnchorMenuItemTemplate({
			label: '微博',
			doconName: 'docon-weibo',
			extraClasses: 'share-weibo',
			dataBiName: 'weibo'
		});
		const wechatMenuItem = createWeChatMenuItem();
		const shareSubMenuItems = [weiboMenuItem, wechatMenuItem];
		const shareSubMenu = renderMenu({
			menuHeading: loc_share,
			allMenuItems: shareSubMenuItems,
			isSubMenu: true,
			ulExtraClasses: 'share-links',
			initializer: container => {
				initSharingLinks(
					container,
					location.origin + location.pathname + location.search,
					document.title
				);
			}
		});

		return createButtonMenuItemTemplate({
			label: loc_share,
			title: loc_sharedesc,
			doconName: 'docon-sharing',
			dataBiName: 'share',
			childMenuView: shareSubMenu
		});
	}

	const twitterMenuItem = createAnchorMenuItemTemplate({
		label: loc_twitter,
		doconName: 'docon-brand-twitter',
		extraClasses: 'share-twitter',
		dataBiName: 'twitter'
	});
	const linkedInMenuItem = createAnchorMenuItemTemplate({
		label: loc_linkedin,
		doconName: 'docon-brand-linkedin',
		extraClasses: 'share-linkedin',
		dataBiName: 'linkedin'
	});
	const facebookMenuItem = createAnchorMenuItemTemplate({
		label: loc_facebook,
		doconName: 'docon-brand-facebook',
		extraClasses: 'share-facebook',
		dataBiName: 'facebook'
	});
	const emailMenuItem = createAnchorMenuItemTemplate({
		label: loc_email,
		doconName: 'docon-mail-message-fill',
		extraClasses: 'share-email',
		dataBiName: 'email'
	});
	let shareSubMenuItems = [twitterMenuItem, linkedInMenuItem, facebookMenuItem, emailMenuItem];

	if (userLocale === 'zh-cn' || userLocale === 'zh-tw') {
		const wechatMenuItem = createWeChatMenuItem();
		shareSubMenuItems = shareSubMenuItems.concat([wechatMenuItem]);
	}

	const shareSubMenu = renderMenu({
		menuHeading: loc_share,
		allMenuItems: shareSubMenuItems,
		isSubMenu: true,
		initializer: container => {
			initSharingLinks(
				container,
				location.origin + location.pathname + location.search,
				document.title
			);
		}
	});

	return createButtonMenuItemTemplate({
		label: loc_share,
		title: loc_sharedesc,
		doconName: 'docon-sharing',
		dataBiName: 'share',
		childMenuView: shareSubMenu
	});
}

function createWeChatMenuItem(): TemplateResult {
	const qrCodeMenuItem = createQrCodeMenuItem();
	const wechatSubMenuItems = [qrCodeMenuItem];
	const wechatSubMenu = renderMenu({
		menuHeading: loc_wechat,
		allMenuItems: wechatSubMenuItems,
		isSubMenu: true,
		initializer: container => {
			initSharingLinks(
				container,
				location.origin + location.pathname + location.search,
				document.title
			);
		}
	});
	return createButtonMenuItemTemplate({
		label: loc_wechat,
		doconName: 'docon-wechat',
		buttonExtraClasses: 'share-wechat',
		dataBiName: 'wechat',
		childMenuView: wechatSubMenu
	});
}

function createQrCodeMenuItem(): TemplateResult {
	return html`
		<li class="is-flex is-flex-column has-margin-top-small has-margin-bottom-small" role="none">
			<canvas class="qr-code has-flex-align-self-center"></canvas>
		</li>
	`;
}

function createLanguageToggleMenuItem(
	hasLocSideBySideTranslation: boolean,
	pageTemplate: string,
	contentLocale: string
): TemplateResult {
	if (!hasLocSideBySideTranslation || pageTemplate !== 'Conceptual') {
		return null;
	}

	const handleSelection = () => {
		// Fake click on the desktop language toggle switch to ensure things remain in sync.
		const languageToggle = document.getElementById('language-toggle') as HTMLInputElement;
		languageToggle.checked = !languageToggle.checked;
		languageToggle.dispatchEvent(new CustomEvent('change', { bubbles: true }));
	};
	const isToggledEnglish = localStorage.getItem('toggledToEnglish') === 'true';
	const toggledLanguage = isToggledEnglish
		? loc_readInLanguageTemplate.replace('{language}', supportedLocaleNames[contentLocale])
		: loc_readInEnglish;
	const margin =
		msDocs.data.userDir === 'rtl' ? 'has-margin-right-medium' : 'has-margin-left-small';

	return html`
		<li class="has-margin-medium" role="none">
			<button
				@click="${handleSelection}"
				type="button"
				class="is-flex button is-text is-fullwidth has-flex-justify-content-start has-inner-focus modal-close"
			>
				<span class="icon is-flex-column" aria-hidden="true">
					<span class="docon docon-reading-settings"></span>
				</span>
				<span class="is-flex-column is-full-width has-text-justified ${margin}"
					>${toggledLanguage}</span
				>
			</button>
		</li>
	`;
}

function createSignInMenuItem(brand: string, isDeveloperSite: boolean): TemplateResult {
	if (!features.userServices) {
		return null;
	}

	if (
		brand !== 'mooncake' &&
		brand !== 'advocates' &&
		brand !== 'regional-directors' &&
		!isDeveloperSite
	) {
		const profileMenuItem = createAnchorMenuItemTemplate({
			label: loc_profile,
			href: '/profile',
			dataProfileProperty: 'profileUrl'
		});
		const bookmarksMenuItem = createAnchorMenuItemTemplate({
			label: loc_bookmarks,
			href: '/profile',
			dataProfileProperty: 'bookmarksUrl'
		});
		const collectionsMenuItem = createAnchorMenuItemTemplate({
			label: loc_collections,
			href: '/profile',
			dataProfileProperty: 'collectionsUrl'
		});
		const signOutMenuItem = createAnchorMenuItemTemplate({
			label: loc_signOut,
			extraClasses: 'docs-sign-out',
			href: '#'
		});
		const authSubMenuItems = [
			profileMenuItem,
			bookmarksMenuItem,
			collectionsMenuItem,
			signOutMenuItem
		];
		const authSubMenu = renderMenu({
			menuHeading: loc_yourAccount,
			allMenuItems: authSubMenuItems,
			isSubMenu: true,
			initializer: container => {
				user.whenAuthenticated().then(() => refreshProfileFields(container, user));
			}
		});

		const handleSelection = (event: MouseEvent) => {
			event.preventDefault();
			event.stopPropagation();
			pageActionsModal.pushContent(authSubMenu);
		};
		const unauthMargin = msDocs.data.userDir === 'rtl' ? 'has-margin-right-small' : '';
		const authMargin =
			msDocs.data.userDir === 'rtl' ? 'has-margin-right-medium' : 'has-margin-left-small';
		return html`
			<li class="has-margin-medium" role="none">
				<a
					role="menuitem"
					class="docs-sign-in auth-status-determined not-authenticated is-flex button is-text has-text-primary is-fullwidth has-flex-justify-content-start has-inner-focus ${unauthMargin}"
					href="#"
					>${loc_signIn}</a
				>
				<div class="auth-status-determined authenticated">
					<button
						@click="${handleSelection}"
						type="button"
						class="is-flex button is-text is-fullwidth has-flex-justify-content-start has-inner-focus"
						aria-label="${loc_yourAccount}"
						aria-controls="user-menu"
						aria-expanded="false"
					>
						<span class="icon" aria-hidden="true">
							<span class="image">
								<img
									class="has-body-background-medium is-rounded"
									data-profile-property="avatarThumbnailUrl"
								/>
							</span>
						</span>
						<span class="is-inline-truncated ${authMargin}" data-profile-property="userName"></span>
					</button>
				</div>
			</li>
		`;
	}

	return null;
}

function createMenuItemDivider(): TemplateResult {
	return html`<li class="menu-divider" role="presentation">
		<div class="menu-divider-line"></div>
	</li>`;
}

type MenuInitializerFunction = (container: HTMLElement, menuItems: TemplateResult[]) => any;

function createMoreMenuItem(
	label: string,
	menuHeading: string,
	menuItems: TemplateResult[],
	initializer?: MenuInitializerFunction
): TemplateResult {
	/* eslint-disable-next-line */ /* tslint:disable-next-line */ // remove after eslint migration
	const moreSubMenu = renderMenu({
		menuHeading,
		allMenuItems: menuItems,
		isSubMenu: true,
		ulExtraClasses: 'is-primary',
		initializer
	}); /* tslint:disable-next-line */ // remove after eslint migration
	/* eslint-disable-next-line */ return createButtonMenuItemTemplate({
		label: label,
		buttonExtraClasses: 'has-text-primary',
		childMenuView: moreSubMenu,
		isMoreMenuItem: true
	});
}

function createContainer(): HTMLDivElement {
	const container = document.createElement('div');
	container.setAttribute('data-bi-name', 'page actions');
	container.classList.add(
		'modal-content',
		'page-actions-menu',
		'is-flex',
		'is-flex-column',
		'has-padding-none',
		'has-border',
		'has-overflow-hidden'
	);

	return container;
}

interface RenderMenuParameters {
	menuHeading: string;
	allMenuItems: TemplateResult[];
	isSubMenu?: boolean;
	ulExtraClasses?: string;
	ulDataBiName?: string;
	initializer?: MenuInitializerFunction;
}

function renderMenu({
	menuHeading = '',
	allMenuItems,
	isSubMenu = false,
	ulExtraClasses,
	ulDataBiName,
	initializer
}: RenderMenuParameters): HTMLDivElement {
	const doconArrow = msDocs.data.userDir === 'rtl' ? 'docon-arrow-right' : 'docon-arrow-left';
	const maxDisplayItemsCount = 6;
	const container = createContainer();
	const handleMenuNavBack = (event: MouseEvent) => {
		event.preventDefault();
		event.stopPropagation();
		if (document.activeElement as HTMLElement) {
			dispatchEvent(new CustomEvent('blur', { bubbles: true }));
		}
		pageActionsModal.popContent();
	};
	const backButton = isSubMenu
		? html` <button
				@click="${handleMenuNavBack}"
				type="button"
				class="button is-flex-column has-border-none has-flex-align-self-center is-icon-only has-inner-focus has-padding-none has-margin-left-medium"
		  >
				<span class="visually-hidden">${loc_backToPriorMenu}</span>
				<span class="icon">
					<span class="docon ${doconArrow}" aria-hidden="true"></span>
				</span>
		  </button>`
		: null;

	const menuItems =
		allMenuItems.length > maxDisplayItemsCount
			? allMenuItems.slice(0, maxDisplayItemsCount)
			: allMenuItems;
	const moreMenuItem =
		allMenuItems.length > maxDisplayItemsCount
			? createMoreMenuItem(
					loc_moreActions,
					loc_moreActions,
					allMenuItems.slice(maxDisplayItemsCount),
					initializer
			  )
			: null;
	const menuItemDividerLine =
		allMenuItems.length > maxDisplayItemsCount ? createMenuItemDivider() : null;

	// Render menu
	render(
		html`
			<div class="is-flex has-border-bottom">
				${backButton}
				<h3
					tabindex="-1"
					class="is-flex-column has-text-centered is-full-width has-padding-left-large has-padding-top-medium has-padding-right-large has-padding-bottom-medium has-border-right"
				>
					<span>${menuHeading}</span>
				</h3>
				<button
					type="button"
					class="is-flex-column has-flex-align-self-center modal-close button is-text is-icon-only has-inner-focus has-margin-top-none is-full-height"
				>
					<span class="visually-hidden">${loc_close}</span>
					<span class="icon">
						<span class="docon docon-math-multiply" aria-hidden="true"></span>
					</span>
				</button>
			</div>
			<ul
				class="${ifDefined(ulExtraClasses)}"
				role="menu"
				data-bi-name="${ifDefined(ulDataBiName)}"
			>
				${menuItems.map(menuItem => {
					return html`${menuItem}`;
				})}
				${menuItemDividerLine} ${moreMenuItem}
			</ul>
		`,
		container
	);

	if (initializer) {
		initializer(container, menuItems);
	}

	return container;
}

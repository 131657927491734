import { LearnItem, Module } from '../apis/learn';
import { user } from '../auth/user';
import { features } from '../environment/features';
import { msDocs } from '../globals';
import {
	InteractiveType,
	InteractiveTypeInfo,
	parseInteractiveType
} from '../interactivity/activation';
import type { LearnItemProgress, UnitProgressCheckedEvent } from '../learn/unit-progress';
import { getMeta } from '../meta';
import { toQueryString } from '../query-string';
import { isSignInAfterLearnComplete } from '../unit/auth-referral';

// configuration object extends to contain all unit configuration as it becomes available, types: UnitPageConfig -> UnitModuleConfig -> UnitProgressConfig
export interface UnitPageConfig {
	// page related
	completionType: UnitCompletionType;
	hasSandbox: boolean;
	hasTaskValidation: boolean;
	interactiveType: InteractiveTypeInfo;
	interactiveName: InteractiveType;
	isChromeless: boolean;
	moduleCompletedBeforeSignIn: boolean;
	userLocale: string;
	unitId: string;
}

export interface UnitModuleConfig extends UnitPageConfig {
	// navigation and module related
	module: Module;
	unitIndex: number;
	currentUnit: LearnItem;
	nextUnit: LearnItem;
	nextUnitUrl: string;
	isFinalUnit: boolean;
}

export interface UnitProgressConfig extends UnitModuleConfig {
	module: Module;
	// progress related
	moduleComplete: boolean;
	moduleCompletedThisPageView: boolean;
	finalUnitHasIncompletePredecessor: boolean;
	firstIncompleteUnit: LearnItem | null;
	hasIncompleteQuiz: boolean;
	hasIncompleteTask: boolean;
	oneParentPath: boolean;
	zeroParentPaths: boolean;
	multipleParentPaths: boolean;
	singlePathComplete: boolean;
	singlePathInfo: LearnItemProgress | null;
	firstQuizCompletion: boolean;
	repeatQuizCompletion: boolean;
	totalPoints: number;
}

export function createUnitConfig(): UnitPageConfig {
	const interactiveType = features.interactivity
		? parseInteractiveType(getMeta('interactive'))
		: null;
	const interactiveName = interactiveType ? interactiveType.name : null;
	const completionType = getMeta('unit_completion_type') as UnitCompletionType;
	const isChromeless: boolean = features.chromeless || false;
	const hasSandbox = getMeta('azure_sandbox') === 'true';
	const hasTaskValidation = completionType === 'arm-task' && !isChromeless && hasSandbox;
	const userLocale = msDocs.data.userLocale;
	const moduleCompletedBeforeSignIn = isSignInAfterLearnComplete();
	const unitId = getMeta('uid');

	return {
		// page related
		completionType,
		hasSandbox,
		hasTaskValidation,
		interactiveType,
		interactiveName,
		isChromeless,
		userLocale,
		unitId,
		moduleCompletedBeforeSignIn
	};
}

export function extendUnitConfigWithModuleInfo(
	unitConfig: UnitPageConfig,
	module: Module
): UnitModuleConfig {
	const { unitIndex, currentUnit, nextUnit, nextUnitUrl } = getModuleInformation(
		module,
		unitConfig.unitId
	);
	const extendConfig = unitConfig as UnitModuleConfig;
	const isFinalUnit = unitIndex === module.units.length - 1;

	extendConfig.module = module;
	extendConfig.currentUnit = currentUnit;
	extendConfig.nextUnit = nextUnit;
	extendConfig.nextUnitUrl = nextUnitUrl;
	extendConfig.unitIndex = unitIndex;
	extendConfig.isFinalUnit = isFinalUnit;

	if (unitConfig.isChromeless) {
		const currentIsLab = unitConfig.interactiveName === 'lab-on-demand';
		const targetIsLab = nextUnit.interactive === 'lab-on-demand';
		const stayInFrame = currentIsLab && targetIsLab;

		if (unitConfig.isChromeless && stayInFrame) {
			// the hosting layer will not set the frame options response header
			// unless we provide the FromOrigin argument.
			const args = {
				FromOrigin: 'https://labclient.labondemand.com',
				context: 'context/chromeless'
			};
			const base = nextUnitUrl.split('?')[0];
			const query = [nextUnitUrl.split('?')[1], toQueryString(args)].join('&');
			extendConfig.nextUnitUrl = `${base}?${query}`;
		}
	}

	return extendConfig as UnitModuleConfig;
}

export function getModuleInformation(
	module: Module,
	unitId: string
): { unitIndex: number; currentUnit: LearnItem; nextUnit: LearnItem; nextUnitUrl: string } {
	const unitIndex = module.units.findIndex(unit => unit.uid === unitId);
	const currentUnit = module.units[unitIndex];
	const nextUnit = module.units[unitIndex + 1];
	const nextUnitUrl = nextUnit ? `/${msDocs.data.userLocale + nextUnit.url}` : '';

	return {
		unitIndex,
		currentUnit,
		nextUnit,
		nextUnitUrl
	};
}

export function extendModuleConfigWithProgressInfo(
	pageModuleConfig: UnitModuleConfig,
	updatedEvent: UnitProgressCheckedEvent
): UnitProgressConfig {
	const updateConfig = pageModuleConfig as UnitProgressConfig;
	const {
		moduleComplete,
		updated,
		firstIncompleteUnit,
		unitsComplete,
		unitComplete,
		module: { item: module },
		passed,
		totalPoints
	} = updatedEvent;
	const finalUnitHasIncompletePredecessor =
		pageModuleConfig.isFinalUnit && unitsComplete < pageModuleConfig.module.units.length - 1;

	const numberOfPaths = updatedEvent.paths.length;

	// * module related settings
	updateConfig.module = module as Module;
	updateConfig.moduleComplete = moduleComplete;
	updateConfig.moduleCompletedThisPageView = moduleComplete && updated;

	// * navigation that changes based on the user's progress
	updateConfig.finalUnitHasIncompletePredecessor = finalUnitHasIncompletePredecessor;

	updateConfig.firstIncompleteUnit = firstIncompleteUnit;
	updateConfig.hasIncompleteQuiz = pageModuleConfig.completionType === 'quiz' && !unitComplete;
	updateConfig.hasIncompleteTask = pageModuleConfig.completionType === 'arm-task' && !unitComplete;

	// * path related settings, which are only needed if the module is complete
	updateConfig.oneParentPath = numberOfPaths === 1;
	updateConfig.zeroParentPaths = numberOfPaths === 0;
	updateConfig.multipleParentPaths = numberOfPaths > 1;
	updateConfig.singlePathInfo = numberOfPaths === 1 ? updatedEvent.paths[0] : null;
	updateConfig.singlePathComplete =
		updateConfig.singlePathInfo && updateConfig.singlePathInfo.progress && user.isAuthenticated
			? updateConfig.singlePathInfo.progress.status === 'completed'
			: false;

	// If it's quiz unit, check if it's first time quiz completion
	updateConfig.firstQuizCompletion =
		pageModuleConfig.completionType === 'quiz' && unitComplete && updated && passed;
	updateConfig.repeatQuizCompletion =
		pageModuleConfig.completionType === 'quiz' && unitComplete && !updated && passed;
	updateConfig.totalPoints = totalPoints;

	return updateConfig;
}

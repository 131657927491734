import {
	loc_assessment_accessAssessmentsWithAccount,
	loc_assessment_categoriesInfluencingYourScore,
	loc_assessment_critical,
	loc_assessment_excellent,
	loc_assessment_imroveYourScores,
	loc_assessment_moderate,
	loc_assessment_notAssessed,
	loc_assessment_oneRecommendedAction,
	loc_assessment_ourRecommendationsForImproving,
	loc_assessment_recommendedActions,
	loc_assessment_score_highFormat,
	loc_assessment_score_highMessage,
	loc_assessment_score_highScreenReaderFormat,
	loc_assessment_score_lowFormat,
	loc_assessment_score_lowMessage,
	loc_assessment_score_lowScreenReaderFormat,
	loc_assessment_score_mediumFormat,
	loc_assessment_score_mediumMessage,
	loc_assessment_score_mediumScreenReaderFormat,
	loc_assessment_score_scoreOutOfTotalFormat,
	loc_assessment_scoringBreakdown,
	loc_assessment_signInToSaveAssessment,
	loc_assessment_youCanFindOutMore,
	loc_assessment_yourOverallScore,
	loc_assessment_yourScore,
	loc_showMore,
	loc_signIn
} from '@msdocs/strings';
import { html, render, TemplateResult, classMap, unsafeHTML } from '../../lit-html';
import { user } from '../../auth/user';
import { CategoryScore, Guidance, GuidanceLink, RecommendationCategory } from '../types';

export function renderGuidance(guidance: Guidance, view: HTMLElement) {
	render(createGuidanceTemplate(guidance), view);
}

/**
 * The guidance categories come back from the API in the order in which they
 * were presented to the user.  This screen will reorder them for all scored
 * assessment views to be "assessed" followed by "unassessed", in stable order
 */
export function applyDisplaySortOrderToGuidanceScoreCategories(guidance: Guidance) {
	if (guidance.score && guidance.score.categories) {
		guidance.score.categories.sort((a: CategoryScore, b: CategoryScore) => {
			if (a.maxScoreValue <= 0) {
				return 1;
			} else if (b.maxScoreValue <= 0) {
				return -1;
			} else {
				return 0;
			}
		});
	}
}

function createGuidanceTemplate(guidance: Guidance): TemplateResult {
	if (!guidance) {
		return html``;
	}

	applyDisplaySortOrderToGuidanceScoreCategories(guidance);

	return html`
		<div id="guidance" data-bi-name="guidance">
			${createHeroTemplate(guidance)} ${createScoringOverviewTemplate(guidance)}
			${createNextStepsTemplate(guidance)}
			${guidance.score
				? createScoringImprovementTemplate(guidance)
				: createRecommendationTemplate(guidance)}
		</div>
	`;
}

function createHeroTemplate(guidance: Guidance): TemplateResult {
	const signInHtml = user.isAuthenticated
		? html``
		: html`
				<div class="column is-two-fifths sign-in-prompt">
					<div class="box has-margin-left-extra-large-tablet has-margin-right-extra-large-tablet">
						<p class="has-text-weight-semibold">${loc_assessment_signInToSaveAssessment}</p>
						<p>${loc_assessment_accessAssessmentsWithAccount}</p>
						<a href="#" class="button docs-sign-in is-primary has-margin-top-medium"
							>${loc_signIn}</a
						>
					</div>
				</div>
		  `;
	const leftColumnClass = user.isAuthenticated ? '' : 'is-thee-fifths';
	return html`
		<section id="guidance-hero" class="hero has-background-docs" data-bi-name="hero">
			<div class="hero-body">
				<div class="uhf-container">
					<div class="columns">
						<div class="column ${leftColumnClass} has-text-docs-invert">
							<h1 class="title is-1 has-margin-bottom-small">${guidance.title}</h1>
							<p class="has-margin-top-none has-margin-top-none">${guidance.description}</p>
						</div>
						${signInHtml}
					</div>
				</div>
			</div>
		</section>
	`;
}

function createNextStepsTemplate(guidance: Guidance): TemplateResult {
	return html`
		<section
			id="guidance-next-steps-section"
			data-bi-name="next-steps"
			class="has-body-background uhf-container has-padding has-padding-top-large has-padding-bottom-extra-large"
		>
			<h2 class="title is-2 has-margin-top-large">${guidance.nextSteps.title}</h2>
			<p class="has-margin-bottom-large">${guidance.nextSteps.description}</p>
			<ul class="columns is-multiline">
				${guidance.nextSteps.steps.map(
					step => html`
						<li class="column is-full-mobile is-half-tablet is-one-third-desktop step">
							<h3 class="title is-3">${step.title}</h3>
							<p class="has-margin-top-none is-truncated has-three-lines">${step.description}</p>
							${step.links.map(
								link => html`<a
									href="${link.url}"
									class="button is-text is-link has-primary-text has-margin-top-small"
								>
									<span>${link.text}</span>
									<span class="icon">
										<span class="docon docon-chevron-right-light is-small"></span>
									</span>
								</a>`
							)}
						</li>
					`
				)}
			</ul>
		</section>
	`;
}

function createRecommendationTemplate(guidance: Guidance): TemplateResult {
	return html`
		<section
			id="guidance-recommendation-section"
			data-bi-name="recommendations"
			class="has-background-alternating-grey-reversed uhf-container has-padding has-padding-top-large has-padding-bottom-large"
		>
			<h2 class="title is-2 has-margin-top-large">${guidance.recommendation.title}</h2>
			<p class="has-margin-bottom-large">${guidance.recommendation.description}</p>
			<ul class="grid is-3">
				${guidance.recommendation.categories.map(
					category => html`
						<li class="grid-item">
							<div class="box category">
								<article class="media">
									<div class="media-left">
										<img aria-hidden="true" class="image is-64x64" src="${category.iconUrl}" />
									</div>
									<div class="media-content">
										<h3 class="title is-3">${category.title}</h3>
										<p class="has-margin-bottom-small is-truncated has-three-lines">
											${category.description}
										</p>
										<ul>
											${category.links.map(
												link => html`<li>
													<a href="${link.url}" class="bar-link is-small">${link.text}</a>
												</li>`
											)}
										</ul>
									</div>
								</article>
							</div>
						</li>
					`
				)}
			</ul>
		</section>
	`;
}

function createScoringOverviewTemplate(guidance: Guidance) {
	// bail if no score returned
	if (!guidance.score) {
		return html``;
	}

	const scoreConfig = setScoreConfig(
		guidance.score.aggregateScore,
		guidance.score.maxScoreValue,
		guidance.score.gradeHighValue,
		guidance.score.gradeMidValue
	);

	return html`
		<section
			id="guidance-scoring-section"
			data-bi-name="scoring-overview"
			class="has-body-background uhf-container has-padding has-padding-top-large has-padding-bottom-large"
		>
			<h2 class="title is-2 has-margin-top-large has-margin-bottom-small">
				${guidance.scoring.title}
			</h2>
			${guidance.scoring.description
				? html`<p class="has-margin-bottom-large">${guidance.scoring.description}</p>`
				: ''}
			<div
				class="box has-padding-extra-large-desktop has-margin-small-mobile has-box-shadow-medium has-border has-margin-small-mobile has-margin-small-tablet has-margin-none-desktop"
			>
				<div class="columns is-multiline">
					<div class="column is-12 is-6-desktop">
						<h3 class="title">${loc_assessment_yourOverallScore}</h3>
						<div
							class="has-border has-border-radius-large has-padding-large has-body-background-medium"
						>
							<div class="has-margin-bottom-small">
								<div class="columns">
									<div class="column is-narrow">
										<span class="tag is-${scoreConfig.color} is-large">${scoreConfig.grade}</span>
									</div>
									<div class="column">
										<p class="is-size-small has-line-height-reset">
											${unsafeHTML(scoreConfig.message)}
										</p>
									</div>
								</div>
							</div>
							${createScoreMeterWithDetails(
								guidance.score.aggregateScore,
								guidance.score.maxScoreValue,
								guidance.score.gradeHighValue,
								guidance.score.gradeMidValue
							)}
						</div>
					</div>
					<div class="column is-12 is-offset-1-desktop is-5-desktop">
						<h3 class="title">${loc_assessment_categoriesInfluencingYourScore}</h3>
						${guidance.score.categories.map(category => createCategoryScore(category))}
						<p class="has-margin-top-medium has-text-subtle has-line-height-reset is-size-small">
							${unsafeHTML(loc_assessment_youCanFindOutMore)}
						</p>
					</div>
				</div>
			</div>
		</section>
	`;
}

function createScoreMeterWithDetails(
	score: number,
	maxScoreValue: number,
	gradeHighValue: number,
	gradeMidValue: number
) {
	// round
	score = Math.round(score);
	maxScoreValue = Math.round(maxScoreValue);
	gradeHighValue = Math.round(gradeHighValue);
	gradeMidValue = Math.round(gradeMidValue);

	return html`
		<div class="columns">
			<div class="column is-8">
				<div class="columns is-gapless is-size-extra-small has-margin-none is-mobile">
					<div class="column is-4 ${score < gradeMidValue ? 'has-text-weight-semibold' : ''}">
						<span aria-hidden="true"
							>${loc_assessment_score_lowFormat.replace(
								'{midGrade}',
								gradeMidValue.toString()
							)}</span
						>
						<span class="visually-hidden"
							>${loc_assessment_score_lowScreenReaderFormat.replace(
								'{midGrade}',
								gradeMidValue.toString()
							)}</span
						>
					</div>
					<div
						class="column is-4 ${score >= gradeMidValue && score <= gradeHighValue
							? 'has-text-weight-semibold'
							: ''}"
					>
						<span aria-hidden="true"
							>${loc_assessment_score_mediumFormat
								.replace('{midGrade}', gradeMidValue.toString())
								.replace('{highGrade}', gradeHighValue.toString())}</span
						>
						<span class="visually-hidden"
							>${loc_assessment_score_mediumScreenReaderFormat
								.replace('{midGrade}', gradeMidValue.toString())
								.replace('{highGrade}', gradeHighValue.toString())}</span
						>
					</div>
					<div class="column is-4 ${score >= gradeHighValue ? 'has-text-weight-semibold' : ''}">
						<span aria-hidden="true"
							>${loc_assessment_score_highFormat
								.replace('{highGrade}', gradeHighValue.toString())
								.replace('{maxGrade}', maxScoreValue.toString())}</span
						>
						<span class="visually-hidden"
							>${loc_assessment_score_highScreenReaderFormat
								.replace('{highGrade}', gradeHighValue.toString())
								.replace('{maxGrade}', maxScoreValue.toString())}</span
						>
					</div>
				</div>
				${createScoringMeter(score, maxScoreValue, gradeHighValue, gradeMidValue)}
			</div>
			<div class="column is-4 has-text-weight-semibold has-flex-align-items-flex-end is-flex">
				<span class="is-block has-line-height-reset">
					<span class="is-size-extra-small is-block">${loc_assessment_yourScore}</span>
					<span aria-hidden="true">${score}/${maxScoreValue}</span>
					<span class="visually-hidden"
						>${loc_assessment_score_scoreOutOfTotalFormat
							.replace('{score}', score.toString())
							.replace('{maxGrade}', maxScoreValue.toString())}</span
					>
				</span>
			</div>
		</div>
	`;
}

function createScoringMeter(
	score: number,
	maxScoreValue: number,
	gradeHighValue: number,
	gradeMidValue: number,
	isSmall?: boolean
) {
	const calculatedScore = (score / maxScoreValue) * 100;
	// helps visually so tick doesn't extend past meter end
	const adjustedScore = calculatedScore > 95 ? calculatedScore - 2 : calculatedScore;
	return html`
		<div class="score-meter">
			<meter
				class="meter ${score < gradeMidValue
					? 'sub-sub-optimum'
					: score >= gradeHighValue
					? 'optimum'
					: 'sub-optimum'} ${isSmall ? 'is-small' : ''}"
				value="${score}"
				low="${gradeMidValue}"
				high="${gradeHighValue}"
				optimum="${gradeHighValue}"
				max="${maxScoreValue}"
			></meter>
			<div class="meter-tick" style="left: ${adjustedScore}%"></div>
		</div>
	`;
}

const createAssessmentSummaryCategoryScoreTitleTemplate = (
	isCategoryScored: boolean,
	category: CategoryScore
) => {
	return isCategoryScored
		? html`<a href="#recommends-${category.id}">${category.title}</a>`
		: html`<span>${category.title}</span>`;
};

const createAssessmentSummaryCategoryScoringMeterTemplate = (
	isCategoryScored: boolean,
	category: CategoryScore
) => {
	return isCategoryScored
		? createScoringMeter(
				category.score,
				category.maxScoreValue,
				category.gradeHighValue,
				category.gradeMidValue,
				true
		  )
		: html`<span>${loc_assessment_notAssessed}</span>`;
};

const createAssessmentSummaryCategoryGradeTemplate = (
	isCategoryScored: boolean,
	category: CategoryScore
) => {
	return isCategoryScored
		? html`<span class="has-margin-left-small has-text-weight-semibold"
				>${getGrade(
					category.score,
					category.maxScoreValue,
					category.gradeHighValue,
					category.gradeMidValue
				)}</span
		  >`
		: '';
};

function createCategoryScore(category: CategoryScore) {
	const isCategoryScored = category.maxScoreValue > 0;
	const classes = { 'has-text-subtle': !isCategoryScored };

	return html`
		<div>
			<div
				class="columns is-mobile is-gapless is-size-small is-flex-tablet has-flex-align-items-center ${classMap(
					classes
				)}"
			>
				<div class="column is-3">
					${createAssessmentSummaryCategoryScoreTitleTemplate(isCategoryScored, category)}
				</div>
				<div class="column is-6">
					${createAssessmentSummaryCategoryScoringMeterTemplate(isCategoryScored, category)}
				</div>
				<div class="column is-3">
					${createAssessmentSummaryCategoryGradeTemplate(isCategoryScored, category)}
				</div>
			</div>
		</div>
	`;
}

function createScoringImprovementTemplate(guidance: Guidance) {
	return html`
		<section
			id="guidance-scoring-improvement"
			data-bi-name="scoring-improvements"
			class="has-body-background-grey uhf-container has-padding has-padding-top-large has-padding-bottom-large"
		>
			<h2 class="title is-2 has-margin-top-large has-margin-bottom-small">
				${loc_assessment_imroveYourScores}
			</h2>
			<p class="has-margin-bottom-large">${loc_assessment_ourRecommendationsForImproving}</p>
			${guidance.score.categories.map(category =>
				createScoringImprovementCard(
					category,
					guidance.recommendation.categories.filter(recommendCategory =>
						recommendCategory.id.startsWith(category.id)
					)
				)
			)}
		</section>
	`;
}

function createScoringImprovementCard(
	category: CategoryScore,
	categoryRecommend: RecommendationCategory[]
) {
	const scoreConfig = setScoreConfig(
		category.score,
		category.maxScoreValue,
		category.gradeHighValue,
		category.gradeMidValue
	);
	const recommendationLinks = concatRecommendationLinks(categoryRecommend) as GuidanceLink[];
	const recommendationTitle =
		recommendationLinks.length === 1
			? loc_assessment_oneRecommendedAction
			: loc_assessment_recommendedActions.replace(
					'{index}',
					recommendationLinks ? recommendationLinks.length.toString() : '0'
			  );
	const isCategoryScored = category.maxScoreValue > 0;
	const hasRecommendationLinks = recommendationLinks.length > 0;

	return html`
		<div
			id="recommends-${category.id}"
			class="box has-border has-border-radius-large has-border-left-large-${scoreConfig.color} has-margin-small"
		>
			<div class="columns is-gapless is-multiline">
				<div class="column is-6 is-flex-tablet has-flex-align-items-center">
					<h4 class="title has-margin-none is-inline-block">${category.title}</h4>
					<span class="tag is-${scoreConfig.color} has-margin-left-small"
						>${scoreConfig.grade}</span
					>
				</div>
				<div class="column is-4 is-hidden-touch is-flex-tablet has-flex-align-items-center">
					${isCategoryScored || hasRecommendationLinks
						? html`<span class="has-text-weight-semibold">${recommendationTitle}</span>`
						: ''}
				</div>
				<div class="column is-6 is-2-desktop is-text-right-tablet">
					${isCategoryScored || hasRecommendationLinks
						? html` <button
								class="button is-text has-text-primary has-margin-top-small-mobile"
								aria-expanded="false"
								aria-controls="${category.id}-details"
								data-show-more=""
						  >
								<span class="show-more-text">
									${loc_showMore}
								</span>
								<span class="icon" aria-hidden="true">
									<span class="expanded-indicator docon docon-chevron-down-light"></span>
								</span>
						  </button>`
						: ''}
				</div>
				<div
					class="column is-full"
					id="${category.id}-details"
					aria-live="polite"
					aria-relevant="additions"
					aria-label="${category.title}"
				>
					<div class="expandable has-margin-top-medium">
						<div class="columns is-gapless is-multiline">
							<div class="column is-12 is-6-desktop">
								${isCategoryScored
									? html` <div class="box has-border-radius-large has-margin-right-large">
											<p class="has-text-weight-semibold is-size-small">
												${loc_assessment_scoringBreakdown}
											</p>
											${createScoreMeterWithDetails(
												category.score,
												category.maxScoreValue,
												category.gradeHighValue,
												category.gradeMidValue
											)}
									  </div>`
									: ''}
							</div>
							<div class="column is-12 is-5-desktop is-offset-1-desktop">
								<p class="has-text-weight-semibold has-margin-top-medium is-hidden-desktop">
									${recommendationTitle}
								</p>
								<ul
									class="has-margin-small ${recommendationLinks.length > 4
										? 'has-two-columns'
										: ''} "
								>
									${recommendationLinks.map(
										link =>
											html`<li>
												<a href="${link.url}" class="bar-link is-small">${link.text}</a>
											</li>`
									)}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	`;
}

function setScoreConfig(
	score: number,
	maxScore: number,
	gradeHighValue: number,
	gradeMidValue: number
) {
	const scoreConfig = {
		color:
			maxScore > 0
				? score < gradeMidValue
					? 'danger'
					: score >= gradeHighValue
					? 'success'
					: 'warning'
				: 'secondary',
		grade: getGrade(score, maxScore, gradeHighValue, gradeMidValue),
		message:
			maxScore > 0
				? score < gradeMidValue
					? loc_assessment_score_lowMessage
					: score >= gradeHighValue
					? loc_assessment_score_highMessage
					: loc_assessment_score_mediumMessage
				: ''
	};
	return scoreConfig;
}

function getGrade(score: number, maxScore: number, gradeHighValue: number, gradeMidValue: number) {
	return (maxScore > 0
		? score < gradeMidValue
			? loc_assessment_critical
			: score >= gradeHighValue
			? loc_assessment_excellent
			: loc_assessment_moderate
		: loc_assessment_notAssessed
	).toLocaleUpperCase();
}

function concatRecommendationLinks(categoryRecommends: RecommendationCategory[]) {
	const recommendationLinks = [] as GuidanceLink[];
	categoryRecommends.forEach(category => {
		category.links.forEach(link => {
			recommendationLinks.push(link);
		});
	});
	return recommendationLinks;
}

import {
	loc_documentation,
	loc_learn,
	loc_reference,
	loc_resultsCountFormat,
	loc_siteSearch_results_allFormat,
	loc_siteSearch_results_allSingularFormat,
	loc_siteSearch_results_allInScopeFormat,
	loc_siteSearch_results_allInScopeSingularFormat,
	loc_siteSearch_results_inScopeWithPivotCategoryFormat,
	loc_siteSearch_results_inScopeWithPivotCategorySingularFormat
} from '@msdocs/strings';
import { formatNumber } from './number-format';
import { ContentAreaId } from './name-maps/content-areas';
import { PivotCategory } from './apis/site-search';

/**
 * @param resultCount The total number of the search results
 * @param searchPhrase The un-encoded search phrase
 * @param pivotCategory The pivot category
 * @param scope The search scope
 */
export function getSearchHeaderString(
	resultCount: number,
	searchPhrase?: string,
	pivotCategory?: PivotCategory | ContentAreaId,
	scope?: string
) {
	if (isNaN(resultCount)) {
		return '';
	}

	if (!searchPhrase) {
		return loc_resultsCountFormat.replace('{numberOfResults}', formatNumber(resultCount));
	}

	const searchResultsAllFormat =
		resultCount === 1 ? loc_siteSearch_results_allSingularFormat : loc_siteSearch_results_allFormat;
	const searchResultsAllInScopeFormat =
		resultCount === 1
			? loc_siteSearch_results_allInScopeSingularFormat
			: loc_siteSearch_results_allInScopeFormat;
	const searchResultsInScopeWithPivotCategoryFormat =
		resultCount === 1
			? loc_siteSearch_results_inScopeWithPivotCategorySingularFormat
			: loc_siteSearch_results_inScopeWithPivotCategoryFormat;
	let headerPhrase: string;

	switch (pivotCategory) {
		case 'Documentation':
		case 'documentation':
			headerPhrase = searchResultsInScopeWithPivotCategoryFormat.replace(
				'{pivotCategory}',
				loc_documentation
			);
			break;
		case 'Learn':
		case 'learn':
			headerPhrase = searchResultsInScopeWithPivotCategoryFormat.replace(
				'{pivotCategory}',
				loc_learn
			);
			break;
		case 'Reference':
		case 'reference':
			headerPhrase = searchResultsInScopeWithPivotCategoryFormat.replace(
				'{pivotCategory}',
				loc_reference
			);
			break;
		default:
			headerPhrase = scope ? searchResultsAllInScopeFormat : searchResultsAllFormat;
	}

	return headerPhrase
		.replace('{count}', formatNumber(resultCount))
		.replace('{searchPhrase}', searchPhrase)
		.replace('{scope}', scope ? scope : '')
		.replace(/\s\s+/g, ' '); //fix for double space in sentence that appears when the scope isn't defined
}

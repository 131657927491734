import { html, TemplateResult } from './lit-html';
import { taxonomyNames } from './name-maps/taxonomy';
import { ProductId } from './name-maps/product';
import { RoleId } from './name-maps/roles';
import { LevelId } from './name-maps/level';
import { ProductFamilyId } from './name-maps/product-family';

interface Tagged {
	products?: ProductId | ProductFamilyId[];
	roles?: RoleId[];
	levels?: LevelId[];
}

export type TaggedItem = Partial<Tagged>;

export function createTagStrings(item: TaggedItem): string[] {
	const tags: string[] = [];

	const isVsCode = item.products.indexOf('vs-code') === 1; //TODO: remove when branding rework is done.
	const productSlug = item.products[isVsCode ? 1 : 0];
	if (productSlug && productSlug in taxonomyNames.products) {
		const product = taxonomyNames.products[productSlug];
		tags.push(product);
	}

	const roleSlug = item.roles[0];
	if (roleSlug && roleSlug in taxonomyNames.roles) {
		const roles = taxonomyNames.roles[roleSlug];
		tags.push(roles);
	}

	const levelSlug = item.levels[0];
	if (levelSlug && levelSlug in taxonomyNames.levels) {
		const level = taxonomyNames.levels[levelSlug];
		tags.push(level);
	}

	return tags;
}

export function createTagHTML(tags: string[]): TemplateResult[] {
	return tags.map(tag => html`<li class="tag is-small">${tag}</li>`);
}

import { jsllReady } from '../bi';

export async function reportRestFetch(
	targetElement: HTMLElement,
	authenticationMethod: string,
	fields: { [key: string]: boolean }[]
) {
	const awa = await jsllReady;
	awa.ct.capturePageAction(targetElement, {
		behavior: awa.behavior.STARTPROCESS,
		contentTags: {
			scn: 'Rest Try It',
			scnstp: 'Request',
			scnnxtstp: 'Response',
			isSuccess: true
		},
		content: {
			event: 'rest-tryit-run',
			authenticationMethod,
			fields
		}
	});
}

export async function reportRestFetchComplete(request: Request, response: Response) {
	const awa = await jsllReady;
	awa.ct.captureContentPageAction({
		behavior: awa.behavior.COMPLETEPROCESS,
		actionType: awa.actionType.OTHER,
		contentTags: {
			scn: 'Rest Try It',
			scnstp: 'Response',
			isSuccess: true
		},
		content: {
			event: 'rest-tryit-fetch-complete',
			status: response.status,
			method: request.method
		}
	});
}

import {
	loc_cancel,
	loc_collections_addPageTypeToCollection,
	loc_collections_addToCollection,
	loc_collections_createANewCollection_titleDefaultValueFormat,
	loc_goBack,
	loc_newCollection,
	loc_save,
	loc_submit,
	loc_youDontHaveAnyCollectionsYet
} from '@msdocs/strings';
import { getListItemInit, List, listApi } from '../apis/lists';
import { user } from '../auth/user';
import { Modal } from '../components/modal/modal';
import { html, render, unsafeHTML } from '../lit-html';
import { SuccessResult } from '../profile/detail/form-view';
import { getCollections } from '../profile/detail/user-util';
import { addNewCollection, formTemplate } from './collection-form';

/**
 * Render the users collections as checkboxes
 */
export async function renderCollectionCheckboxes(modalConsentContainer: HTMLElement, url: string) {
	// Get all the collections
	const collections = await getCollections();
	const noCollections =
		collections.map(collection => collection.type === 'collection').length === 0;
	const holder = modalConsentContainer.querySelector('#collections');

	// if no collections render no collections string
	if (noCollections) {
		const noCollections = html`<p class="has-text-subtle">${loc_youDontHaveAnyCollectionsYet}</p>`;
		render(noCollections, holder);
		return;
	}

	// If has collections get all collections this url has then build list
	const activeCollections = await listApi.getAllListsByUrl([url]);
	const collectionItems = html` ${collections.map(
		collection =>
			html` <div class="field">
				<div class="control">
					<label class="checkbox" for="${collection.id}">
						<input
							@click="${() => updateCollectionCheckbox(url)}"
							.checked=${activeCollections[0].lists.includes(collection.id)}
							type="checkbox"
							class="collection-input"
							id="${collection.id}"
							name="${collection.name}"
							autofocus
						/>
						<span class="checkbox-check" role="presentation"></span>
						<span class="checkbox-text">${collection.name}</span>
					</label>
				</div>
			</div>`
	)}`;
	render(collectionItems, holder);
}

/**
 * The inital collection modal template
 */
export function collectionModalTemplate(modal: Modal, title: string, url: string) {
	return html`
		<div class="modal-card has-padding-medium has-padding-large-tablet">
			<div class="has-padding-extra-small">
				<h2 class="title has-margin-bottom-extra-small">${loc_collections_addToCollection}</h2>
				<button
					class="modal-close modal-card-close-button is-large is-sticky"
					aria-label="Close"
				></button>
				<p class="is-size-small">
					${unsafeHTML(loc_collections_addPageTypeToCollection.replace('{pageName}', title))}
				</p>
				<hr class="hr has-margin-top-small" />
				<div id="collections" class="has-max-height-300 is-vertically-scrollable"></div>
				<hr class="hr has-margin-bottom-small" />
				<button
					@click="${() =>
						nextModal(modal, url, document.querySelectorAll('#collections input').length)}"
					class="button is-text has-margin-bottom-medium"
				>
					<span class="icon" aria-hidden="true">
						<span class="has-text-primary docon docon-math-plus-light"></span>
					</span>
					<span>${loc_newCollection}</span>
				</button>
				<div class="buttons">
					<button
						id="save-collection"
						class="button is-primary title is-small"
						type="submit"
						autofocus
					>
						${loc_save}
					</button>
					<button class="modal-close button is-unstyled title is-small">
						${loc_cancel}
					</button>
				</div>
			</div>
		</div>
	`;
}

/**
 * The second step modal that contains the add new collection form
 */
export async function collectionFormModalTemplate(
	modal: Modal,
	url: string,
	collectionCount: number
) {
	const holder = document.createElement('div');
	holder.classList.add('modal-content');
	const title = loc_collections_createANewCollection_titleDefaultValueFormat
		.replace('{userDisplayName}', user.displayName)
		.replace('{index}', (collectionCount + 1).toString());
	const description = '';
	const template = html`
		<div class="modal-card has-padding-medium has-padding-large-tablet">
			<div>
				<button
					@click="${() => previousModal(modal)}"
					class="button is-text is-small has-text-primary"
				>
					<span class="icon">
						<span class="docon docon-arrow-left"></span>
					</span>
					<span>${loc_goBack}</span>
				</button>
			</div>
			<div class="has-padding-extra-small">
				<form id="addToCollection">
					${formTemplate(
						loc_collections_addToCollection,
						title,
						description,
						loc_submit,
						loc_cancel
					)}
				</form>
			</div>
		</div>
	`;
	render(template, holder);
	holder.querySelector<HTMLButtonElement>('button[type=submit]').onclick = async () => {
		await addToNewCollection(modal, url);
	};

	return holder;
}

/**
 * On form submit add the new item to the collection
 */
async function addToNewCollection(modal: Modal, url: string) {
	event.preventDefault();
	const button = event.target as HTMLButtonElement;
	button.classList.add('is-loading'); // Add loading

	const init = await getListItemInit(url);
	const form = document.getElementById('addToCollection') as HTMLFormElement;
	await addNewCollection(form).then(async result => {
		if (result.hasError) {
			// if it has error run through normal error reporting
			button.classList.remove('is-loading'); // Remove loading
		} else {
			// Add the new collection then add the url to it
			result = result as SuccessResult<List>;
			await listApi.addItem(result.data.id, init);
			// pop the collections modal and reload the collections
			previousModal(modal);
			await renderCollectionCheckboxes(document.querySelector('.modal-card'), url);
		}
	});
}

/**
 * When a collection item is checked or unchecked update the url in that collection
 */
async function updateCollectionCheckbox(url: string) {
	const checkbox = event.target as HTMLInputElement;
	if (checkbox.checked) {
		// if checked add url to list
		const init = await getListItemInit(url);
		await listApi.addItem(checkbox.id, init);
	} else {
		// if unchecked remove it from the list
		const collection = await listApi.getList(checkbox.id);
		const item = collection.items.find(b => b.data.url === url);
		await listApi.deleteItem(checkbox.id, item.id);
	}
}

async function nextModal(modal: Modal, url: string, collectionCount: number) {
	modal.pushContent(await collectionFormModalTemplate(modal, url, collectionCount));
}

function previousModal(modal: Modal) {
	modal.popContent();
}

import { loc_next, loc_pageOfPages, loc_pagination, loc_previous } from '@msdocs/strings';
import { html } from '../../../lit-html';
import { SearchViewModel } from '../view-model';

export function paginationTemplate<TResult>(vm: SearchViewModel<TResult>) {
	if (!vm.pager || vm.results.length < 1) {
		return null;
	}
	const directLinkList = [];
	for (let i = vm.pager.displayRange.first; i <= vm.pager.displayRange.last; i++) {
		directLinkList.push(html`
			<li>
				<button
					type="button"
					class="pagination-link ${vm.pager.pageIndex === i
						? 'is-current'
						: 'has-background-transparent'}"
					?disabled=${vm.busy}
					data-bi-name="page-button"
					data-page="${i + 1}"
					aria-label="${loc_pageOfPages
						.replace('{pageNumber}', String(i + 1))
						.replace('{numberOfPages}', String(vm.pager.pageIndexes + 1))}"
					aria-current="${vm.pager.pageIndex === i ? 'true' : 'false'}"
					@click=${() => vm.pager.setPage(i)}
				>
					${i + 1}
				</button>
			</li>
		`);
	}

	return html`
		<nav
			class="pagination is-centered has-flex-justify-content-start has-margin-top-large has-margin-bottom-medium"
			role="navigation"
			aria-label="${loc_pagination}"
			data-bi-name="search-pagination"
		>
			<button
				type="button"
				data-bi-name="previous-button"
				class="pagination-previous has-background-transparent"
				aria-label="${loc_previous}"
				@click=${() => vm.pager.setPage(vm.pager.pageIndex - 1)}
				?disabled=${vm.busy}
				?hidden=${vm.pager.pageIndex === 0}
			>
				<span class="icon" aria-hidden="true">
					<span class="docon docon-arrow-left"></span>
				</span>
			</button>
			<button
				type="button"
				class="pagination-next has-background-transparent"
				aria-label="${loc_next}"
				data-bi-name="next-button"
				@click=${() => vm.pager.setPage(vm.pager.pageIndex + 1)}
				?disabled=${vm.busy}
				?hidden=${vm.pager.pageIndex === vm.pager.pageIndexes}
			>
				<span class="icon" aria-hidden="true">
					<span class="docon docon-arrow-right"> </span>
				</span>
			</button>
			<ul class="pagination-list">
				${directLinkList}
			</ul>
		</nav>
	`;
}

import { toLocaleDate } from './text-formatting';
import { msDocs } from './globals';

/**
 * Formats the article date in the user's locale.
 * The content templating JINT environment does not have the formatting capabilities
 * required to do this server-side.
 */
export function formatArticleDate(
	container: Element = document.body,
	locale = msDocs.data.userLocale
) {
	const elements = container.querySelectorAll<HTMLTimeElement>('time[data-article-date]');
	elements.forEach(element => {
		element.classList.remove('is-invisible');

		// Try to parse the date.
		let date: Date;
		try {
			date = new Date(element.getAttribute('datetime'));
			if (isNaN(date.getTime())) {
				return;
			}
		} catch {
			return;
		}

		// Ensure datetime attribute is ISO-8601 formatted per <time> element guidelines.
		element.setAttribute('datetime', date.toISOString());

		// Format the text in the user's locale.
		const formatOptions: Intl.DateTimeFormatOptions = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit'
		};
		const originalSource = element.getAttribute('data-article-date-source');
		if (originalSource === 'ms.date') {
			// Default to UTC timezone when timezone is ambiguous (ms.date).
			formatOptions.timeZone = 'UTC';
		}
		const formatted = toLocaleDate(date, formatOptions, locale);
		element.textContent = formatted;
	});
}

import { loc_removeItem } from '@msdocs/strings';
import { html, repeat, TemplateResult } from '../../../lit-html';
import { TaxonomyLabel } from '../../../name-maps/taxonomy';
import { FacetViewModel } from '../facets';
import { SearchViewModel } from '../view-model';

export function removeTagsTemplate<TResult>(vm: SearchViewModel<TResult>) {
	const labels = Object.keys(vm.facetGroups).filter(l => l !== 'category') as TaxonomyLabel[];
	return repeat(
		labels,
		label => label,
		label => createRemoveFacetTags(vm.facetGroups[label as TaxonomyLabel].facets)
	);
}

function createRemoveFacetTags(facets: FacetViewModel[]): TemplateResult {
	return html`${facets.map(facet => {
		if (!facet.isSelected && !facet.hasSelectedChild) {
			return html``;
		}
		if (facet.hasSelectedChild) {
			// parents can't be selected if they have a child selected so we can return
			return html`${createRemoveFacetTags(facet.children)}`;
		}
		return singleRemoveFacetButton(facet);
	})}`;
}

function singleRemoveFacetButton(facet: FacetViewModel): TemplateResult {
	return html`
		<button
			@click=${() => facet.toggleSelected()}
			class="tag"
			aria-label="${loc_removeItem.replace('{itemName}', facet.name)}"
			data-bi-name="remove-facet-tag"
		>
			<span>${facet.name}</span>
			<span class="delete"></span>
		</button>
	`;
}

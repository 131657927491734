import { fetchWithTimeout } from '../fetch';
import { msDocs } from '../globals';
import { parseQueryString, toQueryString } from '../query-string';

interface SurveyAnswer {
	id: string;
	title: string;
	scores: number[];
}

export interface SurveyQuestion {
	id: string;
	answers: SurveyAnswer[];
}

let surveyQuestions: Promise<SurveyQuestion[]>;
/**
 * Loads the survey questions definitions. File is expected to be
 * at docsroot eg: "/en-us/survey-questions.json"
 */
export function getSurveyQuestions(): Promise<SurveyQuestion[]> {
	if (surveyQuestions === undefined) {
		let url;

		if (location.hostname === 'localhost') {
			const { branch } = parseQueryString();
			const queryString = branch ? '?' + toQueryString({ branch }) : '';
			const docsetBase = location.pathname.split('/').slice(0, 3).join('/');
			url = `${docsetBase}/survey-questions.json${queryString}`;
		} else {
			url = `${location.origin}/${msDocs.data.contentLocale}/survey-questions.json?branch=master`;
		}

		surveyQuestions = fetchWithTimeout(url, { credentials: 'include', mode: 'cors' })
			.then(response => {
				return response.json();
			})
			.then(data => {
				return data.questions;
			});
	}

	return surveyQuestions;
}

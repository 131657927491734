import { loc_exploreOtherPaths } from '@msdocs/strings';
import { msDocs } from '../globals';
import {
	isLearnNavNoOp,
	LearnActionAnchor,
	LearnActionButton,
	LearnActionLinks,
	LearnPageNavigationInfo,
	LearnPageNavigationType
} from './navigation-types';

export function renderLearnNavigationAction(
	actionItem: LearnPageNavigationInfo,
	type: LearnPageNavigationType
): HTMLElement {
	const container = document.createElement('div');
	if (isLearnNavNoOp(actionItem)) {
		return container;
	}

	const hr = document.createElement('hr');
	hr.classList.add('hr');
	container.appendChild(hr);

	if (actionItem.elementType === 'a') {
		const element = createActionAnchorElement(actionItem, type);
		container.appendChild(element);
	} else if (actionItem.elementType === 'button') {
		const button = createActionButtonElement(actionItem);
		container.appendChild(button);
	} else if (actionItem.elementType === 'ul') {
		const ul = createLearnItemLinkListElement(actionItem);
		container.appendChild(ul);
	}
	return container;
}

export function createActionAnchorElement(
	info: LearnActionAnchor,
	type: LearnPageNavigationType
): HTMLElement {
	const { sectionTitle, doconClass, text, biName, href, target } = info;
	const container = document.createElement('div');

	const extraSection =
		type === 'next-step-path-complete'
			? `
		<p class="is-hidden-portrait">
			<a data-bi-name="${biName}" class="is-size-5" href="/en-us/learn/browse/">${loc_exploreOtherPaths}</a>
		</p>`
			: ``;

	const anchorSection =
		`
		${sectionTitle ? `<h2 class="title is-4 has-margin-top-none">${sectionTitle}</h2>` : ''}
		<p>
			<a href="${href}" class="button is-primary" data-bi-name="${biName}" ${
			target ? ` target="${target}"` : ''
		}>
			<span>${text}</span>
			${doconClass ? `<span class="icon docon ${doconClass}" aria-hidden="true"></span>` : ''}
			</a>
		</p>
	` + extraSection;

	container.innerHTML = anchorSection;
	return container;
}

export function createActionButtonElement(info: LearnActionButton): HTMLDivElement {
	const container = document.createElement('div');

	const button = document.createElement(info.elementType);
	button.classList.add('button', 'is-primary');
	button.setAttribute('data-bi-name', info.biName);
	button.textContent = info.text;
	button.onclick = info.onclick;
	container.insertAdjacentElement('beforeend', button);

	if (info.sectionTitle) {
		const title = document.createElement('h2');
		title.classList.add('title', 'is-4', 'has-margin-top-none');
		title.id = 'next-module';
		title.textContent = info.sectionTitle;
		container.insertAdjacentElement('afterbegin', title);
	}

	return container;
}

export function createLearnItemLinkListElement(info: LearnActionLinks): HTMLDivElement {
	const container = document.createElement('div');

	const linkHtml = info.items
		.map(
			item => `<li class="is-unstyled">
			<a href="/${msDocs.data.userLocale}${item.url}">${item.title}</a>
		</li>`
		)
		.join('');

	const template = `<h2 class="has-margin-top-none title is-4">${info.sectionTitle}</h2>
		<ul class="is-unstyled has-margin-none">${linkHtml}</ul>`;

	container.insertAdjacentHTML('beforeend', template);
	return container;
}

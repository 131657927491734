import { render, html } from 'lit-html';
import {
	loc_azureSandbox_appealBan,
	loc_azureSandbox_appealDenied,
	loc_azureSandbox_appealPending,
	loc_azureSandbox_furtherDetails,
	loc_azureSandbox_operationFailedBody,
	loc_azureSandbox_operationFailedTitle,
	loc_azureSandbox_quotaExceeded,
	loc_azureSandbox_smsChallengeBody,
	loc_azureSandbox_smsChallengeTitle,
	loc_azureSandbox_userRejectedBody,
	loc_azureSandbox_userRejectedTitle,
	loc_countryCode,
	loc_emailIsRequiredExplanation,
	loc_emailIsRequiredSandbox,
	loc_hideDetails,
	loc_phoneNumber,
	loc_portallab_labAccessSuspended,
	loc_portallab_labAppeal,
	loc_restrictedCloud,
	loc_retryActivating,
	loc_sandboxTroubleshootingMessage,
	loc_textMe,
	loc_verifyYourAccount,
	loc_viewDetails
} from '@msdocs/strings';
import { unsafeHTML } from '../lit-html';

export function waitForButtonClick(container: Element, selector: string = 'button') {
	return new Promise<void>(
		resolve => ((container.querySelector(selector) as HTMLButtonElement).onclick = () => resolve())
	);
}

export function renderCaptcha(container: Element) {
	render(
		html`
			<h2 class="title is-2">${loc_verifyYourAccount}</h2>
			<div class="has-text-centered">
				<div id="captcha-container"></div>
			</div>
		`,
		container
	);
}

export function renderAppealDenied(container: Element) {
	render(
		html`
			<p>${unsafeHTML(loc_azureSandbox_appealDenied)}</p>
			${troubleshootingLinkTemplate()}
		`,
		container
	);
}

export function renderAppealPending(container: Element) {
	render(
		html`
			<p>${loc_azureSandbox_appealPending}</p>
			${troubleshootingLinkTemplate()}
		`,
		container
	);
}

export function renderMissingEmail(container: Element) {
	render(
		html`
			<p>${loc_emailIsRequiredSandbox}</p>
			<p>${unsafeHTML(loc_emailIsRequiredExplanation)}</p>
			${troubleshootingLinkTemplate()}
			<p>
				<button type="button" class="button" data-bi-name="retry">
					${loc_retryActivating}
				</button>
			</p>
		`,
		container
	);
}

export function renderRestrictedCloud(container: Element) {
	render(
		html`
			<p>${loc_restrictedCloud}</p>
			${troubleshootingLinkTemplate()}
		`,
		container
	);
}

export function renderUserRejected(container: Element) {
	// User has been banned for abuse. Do not show retry button if sentinel does not allow them to re-appeal.
	render(buildErrorAlert('rejected'), container);
}

export function renderAppeal(container: Element) {
	// User has been banned for abuse. Allow user to appeal the ban if their appeal has not already been rejected.
	render(buildErrorAlert('appeal', true), container);
}

function buildErrorAlert(errorType: 'rejected' | 'appeal', isLab?: boolean) {
	// rejected means the user is fully blocked and cannot appeal
	const rejected = errorType === 'rejected';
	const config = {
		appealButton: rejected
			? ''
			: html`
					<button
						type="button"
						class="button has-margin-top-medium is-block"
						data-bi-name="appealSandboxBan"
					>
						${loc_azureSandbox_appealBan}
					</button>
			  `,
		body: rejected
			? loc_azureSandbox_userRejectedBody
			: isLab
			? loc_portallab_labAppeal
			: loc_azureSandbox_operationFailedBody,
		color: rejected ? 'danger' : 'warning',
		docon: rejected ? 'error' : 'info',
		title: rejected
			? loc_azureSandbox_userRejectedTitle
			: isLab
			? loc_portallab_labAccessSuspended
			: loc_azureSandbox_operationFailedTitle
	};
	return html`
		<div class="alert is-${config.color} is-header">
			<p>
				<span class="icon is-left" aria-hidden="true">
					<span class="docon docon-status-${config.docon}-outline"></span>
				</span>
				${config.title}
			</p>
		</div>
		<div class="alert">
			<p class="has-margin-none">
				${config.body}
			</p>
			${config.appealButton}
			<button
				class="button is-text has-text-primary has-margin-top-small"
				aria-expanded="false"
				aria-controls="details"
				data-show-more=""
				data-title="${loc_viewDetails}"
				data-expanded-title="${loc_hideDetails}"
			>
				<span class="icon" aria-hidden="true">
					<span class="expanded-indicator docon docon-chevron-down-light"></span>
				</span>
				<span class="show-more-text">
					${loc_viewDetails}
				</span>
			</button>
			<div id="details" aria-live="polite" aria-relevant="additions">
				<div class="expandable has-margin-top-medium">
					<hr class="hr" />
					<p class="has-margin-none">
						${unsafeHTML(loc_azureSandbox_furtherDetails)}
					</p>
				</div>
			</div>
		</div>
	`;
}

export function renderQuotaExceeded(container: Element) {
	// User has exhausted sandbox limits within given interval.
	render(
		html`
			<p>${loc_azureSandbox_quotaExceeded}</p>
			${troubleshootingLinkTemplate()}
		`,
		container
	);
}

export function renderSmsChallenge(container: Element) {
	// User must provide a texted code to proceed.
	/* TODO:
		- Add Select values, look to getCountrySelectHtml() for insperation, loc strings
		- Form validation
		- Option to enter in texted code
	*/
	render(
		html`
			<div class="column is-9-desktop is-7-widescreen">
				<h2 class="title is-size-h2 has-margin-none">${loc_azureSandbox_smsChallengeTitle}</h2>
				<p class="has-margin-top-small">${loc_azureSandbox_smsChallengeBody}</p>
				<form>
					<div class="field">
						<label class="label has-margin-top-large" for="country-list">
							${loc_countryCode}
							<span class="required-indicator" aria-hidden="true"></span>
						</label>
						<div class="control">
							<div class="select is-full-width">
								<select
									class="is-radiusless is-full-width"
									name="country-list"
									id="country-list"
									required="true"
									autocomplete="country"
									aria-required="true"
									aria-label="Select a country code"
								>
									<option value="+1">United States (+1)</option>
								</select>
							</div>
						</div>
					</div>
					<div class="field">
						<label class="label" for="phone-number">
							${loc_phoneNumber}
							<span class="required-indicator" aria-hidden="true"></span>
						</label>
						<div class="control">
							<input id="phone-number" class="input" type="tel" />
						</div>
					</div>
					<div class="field">
						<div class="control">
							<button type="submit" class="button is-primary has-margin-top-small">
								${loc_textMe}
							</button>
						</div>
					</div>
				</form>
			</div>
		`,
		container
	);
}

/**
 * @returns {TemplateResult} - A message that contains a link to the Azure Cloud Shell FAQ
 */
export function troubleshootingLinkTemplate() {
	return unsafeHTML(`<p>${loc_sandboxTroubleshootingMessage}</p>`);
}

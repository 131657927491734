import { location } from '../../src/globals';
import { authStatusDetermined } from '../auth/index';
import { signInClassName } from '../auth/sign-in-links';
import { user } from '../auth/user';
import { parseQueryString, toQueryString, updateQueryString } from '../query-string';
import type { UnitProgressConfig } from '../unit/config';

export const signInAfterLearnCompleteArgName: string = 'sign-in-after-learn-complete';

export function createSignInReferralUrl(argName: string) {
	const args = parseQueryString(location.search);
	args[argName] = 'true';
	return `${location.protocol}//${location.hostname}${location.pathname}?${toQueryString(args)}${
		location.hash
	}`;
}

export async function updateSignInLinksReferralUrl(argName: string) {
	await authStatusDetermined;

	if (!user.isAuthenticated) {
		// Change action bar sign in link href to use sign in after complete url
		const signInLinks = Array.from(document.querySelectorAll(`.${signInClassName}`));
		signInLinks.forEach(anchor => {
			anchor.setAttribute('href', createSignInReferralUrl(argName));
		});
	}
}

export function isSignInAfterLearnComplete(): boolean {
	const args = parseQueryString(location.search);
	if (args[signInAfterLearnCompleteArgName] === 'true') {
		args[signInAfterLearnCompleteArgName] = null;
		updateQueryString(args, 'replaceState');
		return true;
	}
	return false;
}

export function handleSignInAfterUnitComplete(unitUpdateConfig: UnitProgressConfig) {
	if (unitUpdateConfig.moduleCompletedThisPageView) {
		updateSignInLinksReferralUrl(signInAfterLearnCompleteArgName);
	}
}

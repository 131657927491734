import { ratingApi } from '../apis/rating';
import { msDocs } from '../globals';
import { SingleRequestBrowseTypes, LearnBrowseResult } from '../apis/browse';

export async function getResultRatings(browseType: SingleRequestBrowseTypes, resultUids: string[]) {
	if (browseType !== 'home' && browseType !== 'topiclanding') {
		return [];
	}

	const pages = resultUids.map(pageId => {
		return {
			pageId
		};
	});
	const { ratings } = await ratingApi.getBatchPageRating(msDocs.data.userLocale, {
		locale: msDocs.data.userLocale,
		pages
	});
	return ratings.filter(
		rating =>
			rating.ratingCountByScore[1] ||
			rating.ratingCountByScore[2] ||
			rating.ratingCountByScore[3] ||
			rating.ratingCountByScore[4] ||
			rating.ratingCountByScore[5]
	);
}

export function getUidsFromBrowseResults(browseResult: LearnBrowseResult[]): string[] {
	return browseResult.map(result => result.uid);
}

import { features } from '../environment/features';
import { contentLoaded } from '../globals';
import { initFacetSearch } from '../components/facet-search';
import { createSampleSearchConfig } from '../components/facet-search/implementations/sample';
import { syncUserProgress } from '../learn/unit-progress';
import { createLearnSearchConfig } from '../components/facet-search/implementations/learn';
import { createCourseFacetSearchConfig } from '../components/facet-search/implementations/course';
import { createCertificationFacetSearchConfig } from '../components/facet-search/implementations/certifications';
import { createLifecycleFacetSearchConfig } from '../components/facet-search/implementations/lifecycle';
import { getMeta } from '../meta';

export async function contentBrowserPage() {
	const browseType = getBrowseType();
	if (features.facetSearch) {
		await contentLoaded;
		const container = document.getElementById('content-browser-container') as HTMLElement;
		switch (browseType) {
			case 'sample':
				initFacetSearch(createSampleSearchConfig(), container);
				break;
			case 'learn':
				syncUserProgress();
				initFacetSearch(createLearnSearchConfig(), container);
				break;
			case 'course':
				initFacetSearch(createCourseFacetSearchConfig(), container);
				break;
			case 'certification':
				initFacetSearch(createCertificationFacetSearchConfig(), container);
				break;
			case 'lifecycle':
				initFacetSearch(createLifecycleFacetSearchConfig(), container);
				break;
		}
		return;
	}
}

export type ContentBrowserTypes = 'learn' | 'sample' | 'certification' | 'course' | 'lifecycle';

/**
 * Accesses metadata to ensure that the page supports browse functionality. page_type must be 'browse', although BrowseType is determined by page_kind.
 */
export function getBrowseType(): ContentBrowserTypes {
	const pageType = getMeta('page_type');
	const pageKind = getMeta('page_kind');

	if (
		pageType === 'browse' &&
		(pageKind === 'learn' ||
			pageKind === 'sample' ||
			pageKind === 'certification' ||
			pageKind === 'course' ||
			pageKind === 'lifecycle')
	) {
		return pageKind;
	} else {
		throw new Error(
			`The page configuration (page_kind: ${pageKind} and page_type: ${pageType}) does not support browse functionality`
		);
	}
}

export const loc_aadPrivacyNotice = "You have signed in with an account associated to an organization ({email}). By becoming a member, <strong>you understand and acknowledge</strong> that your Microsoft Docs membership means our network of sites and applications will record and track your achievements and activities and we may share your results and activities with your organization. Additionally, your username, display name, profile picture, affiliation, achievements and activities will be publicly visible.";
export const loc_about = "About";
export const loc_accept = "Accept";
export const loc_acceptedValues = "accepted values";
export const loc_acceptedAnswer = "Accepted answer";
export const loc_acceptedAnswers = "Accepted answers";
export const loc_advocates = "Advocates";
export const loc_articleReviewDate = "Article review date";
export const loc_assessment_accessAssessmentsWithAccount = "Save, share, and access all assessments anytime with your Microsoft account.";
export const loc_assessment_assessmentOverview = "Assessment overview";
export const loc_assessment_assessmentName = "Assessment name";
export const loc_assessment_azureArchitectureReview = "Azure Architecture Review";
export const loc_assessment_azureFreeTrial = "Azure Free Trial";
export const loc_assessment_categoriesInfluencingYourScore = "Categories that influenced your results";
export const loc_assessment_cloudJourneyTracker = "Cloud Journey Tracker";
export const loc_assessment_companyName = "Company name";
export const loc_assessment_companySize = "Company size";
export const loc_assessment_companySize_1to10 = "1 to 10 employees";
export const loc_assessment_companySize_11to24 = "11 - 24 employees";
export const loc_assessment_companySize_25to49 = "25 - 49 employees";
export const loc_assessment_companySize_50to99 = "50 - 99 employees";
export const loc_assessment_companySize_100to499 = "100 - 499 employees";
export const loc_assessment_companySize_500to999 = "500 - 999 employees";
export const loc_assessment_companySize_1000to2499 = "1,000 - 2,499 employees";
export const loc_assessment_companySize_2500to9999 = "2,500 - 9,999 employees";
export const loc_assessment_companySize_10000to14999 = "10,000 - 14,999 employees";
export const loc_assessment_companySize_15000to49999 = "15,000 - 49,999 employees";
export const loc_assessment_companySize_50000plus = "50,000 + employees";
export const loc_assessment_completedAssessmentWorkNotSaved = "You have already started an assessment, but your work was not saved. Signing in will save your assessment, provide quick access later, and allow sharing with coworkers.";
export const loc_assessment_country = "Country";
export const loc_assessment_critical = "Critical";
export const loc_assessment_homeLocaleNotFound = "Unfortunately, some assessments aren't available in your language. We're showing the English assessments instead.";
export const loc_assessment_questionnaireLocaleNotFound = "Unfortunately, this assessment isn't available in your language. We're showing the English assessment instead.";
export const loc_assessment_assessmentNotFound = "The assessment you selected does not exist. Our available assessments are shown here.";
export const loc_assessment_division = "Division";
export const loc_assessment_division_businessDevelopment = "Business Development";
export const loc_assessment_division_finance = "Finance";
export const loc_assessment_division_humanResources = "Human resources";
export const loc_assessment_division_informationTechnology = "Information technology";
export const loc_assessment_division_marketing = "Marketing";
export const loc_assessment_division_operations = "Operations";
export const loc_assessment_division_sales = "Sales";
export const loc_assessment_enterCompanyName = "Enter company name";
export const loc_assessment_excellent = "Excellent";
export const loc_assessment_governanceBenchmark = "Governance Benchmark";
export const loc_assessment_guidance = "Guidance";
export const loc_assessment_imroveYourScores = "Improve your results";
export const loc_assessment_industry = "Industry";
export const loc_assessment_industry_aerospaceDefense = "Aerospace and Defense";
export const loc_assessment_industry_agricultureForestryFishing = "Agriculture, Forestry and Fishing";
export const loc_assessment_industry_automotive = "Automotive";
export const loc_assessment_industry_chemicalPetroleum = "Chemical and Petroleum";
export const loc_assessment_industry_communications = "Communications";
export const loc_assessment_industry_communityServices = "Community Services";
export const loc_assessment_industry_construction = "Construction";
export const loc_assessment_industry_education = "Education";
export const loc_assessment_industry_educationHigherEducation = "Education - Higher Education";
export const loc_assessment_industry_educationPrimarySecondaryEducation = "Education - Primary and Secondary Education";
export const loc_assessment_industry_electronics = "Electronics";
export const loc_assessment_industry_energyUtilities = "Energy and Utilities";
export const loc_assessment_industry_financialServices = "Financial Services";
export const loc_assessment_industry_financialServicesBankingCapitalMarkets = "Financial Services - Banking and Capital Markets";
export const loc_assessment_industry_financialServicesInsurance = "Financial Services - Insurance";
export const loc_assessment_industry_government = "Government";
export const loc_assessment_industry_governmentCivilianGovernment = "Government - Civilian Government";
export const loc_assessment_industry_governmentDefenseIntelligence = "Government - Defense and Intelligence";
export const loc_assessment_industry_governmentLocalRegional = "Government - Local and Regional";
export const loc_assessment_industry_health = "Health";
export const loc_assessment_industry_healthHealthPayor = "Health - Health Payor";
export const loc_assessment_industry_healthHealthProvider = "Health - Health Provider";
export const loc_assessment_industry_healthPharmaceuticals = "Health - Pharmaceuticals";
export const loc_assessment_industry_hospitality = "Hospitality";
export const loc_assessment_industry_itSoftware = "IT and Software";
export const loc_assessment_industry_lifeSciences = "Life Sciences";
export const loc_assessment_industry_manufacturingResources = "Manufacturing and Resources";
export const loc_assessment_industry_manufacturingResourcesChemicalsAgrochemicals = "Manufacturing and Resources - Chemicals and Agrochemicals";
export const loc_assessment_industry_manufacturingResourcesDiscreteManufacturing = "Manufacturing and Resources - Discrete Manufacturing";
export const loc_assessment_industry_mediaEntertainment = "Media and Entertainment";
export const loc_assessment_industry_metalsMining = "Metals and Mining";
export const loc_assessment_industry_professionalBusinessServices = "Professional and Business Services";
export const loc_assessment_industry_retail = "Retail";
export const loc_assessment_industry_retailConsumerGoods = "Retail - Consumer Goods";
export const loc_assessment_industry_travelTransportation = "Travel and Transportation";
export const loc_assessment_moderate = "Moderate";
export const loc_assessment_myAssessment = "My assessment";
export const loc_assessment_noDoNotSave = "No, do not save";
export const loc_assessment_notAssessed = "Not assessed";
export const loc_assessment_oneRecommendedAction = "1 recommended action";
export const loc_assessment_organizationProfile = "Organization Profile";
export const loc_assessment_organizationProfileFormat = "{organization} Profile";
export const loc_assessment_organizationProfileSummary = "To achieve your digital transformation goals with minimal disruption, turn to our Azure Expert Managed Services Providers. They have deep cloud technical know-how, consistently deliver customer success, and validate the high standards of their services each year with an independent auditor. <a href=\"https://aka.ms/azureexpertmsp/\">Azure Expert Managed Services Providers in your area</a>.";
export const loc_assessment_ourRecommendationsForImproving = "Our recommendations for improving your results are organized by category below.";
export const loc_assessment_preparingYourAssessment = "Preparing your assessment";
export const loc_assessment_recommendedActions = "{index} recommended actions";
export const loc_assessment_removeThisAssessment = "Remove This Assessment";
export const loc_assessment_returnToGuidance = "Return to Guidance report";
export const loc_assessment_saveYourPreviousAssessment = "Save your previous assessment?";
export const loc_assessment_score_lowFormat = "Critical 0-{midGrade}";
export const loc_assessment_score_lowScreenReaderFormat = "Critical: 0 to {midGrade}";
export const loc_assessment_score_lowMessage = "<span class=\"has-text-weight-semibold\">Room to improve.</span> It looks like there are key items needing attention before you're ready for successful cloud enablement.";
export const loc_assessment_score_mediumFormat = "Moderate {midGrade}-{highGrade}";
export const loc_assessment_score_mediumScreenReaderFormat = "Moderate: {midGrade} to {highGrade}";
export const loc_assessment_score_mediumMessage = "<span class=\"has-text-weight-semibold\">Almost there.</span> You have some room to improve your current environment, but you're on track. If you continue to optimize, you'll soon be ready for successful cloud enablement.";
export const loc_assessment_score_highFormat = "Excellent {highGrade}-{maxGrade}";
export const loc_assessment_score_highScreenReaderFormat = "Excellent: {highGrade} to {maxGrade}";
export const loc_assessment_score_highMessage = "<span class=\"has-text-weight-semibold\">You're all set.</span> You meet the criteria necessary and are ready for successful cloud enablement.";
export const loc_assessment_score_scoreOutOfTotalFormat = "{score} out of {maxGrade}";
export const loc_assessment_scoringBreakdown = "Results breakdown";
export const loc_assessment_selectCompanySize = "Select company size";
export const loc_assessment_selectCountry = "Select country";
export const loc_assessment_selectDivision = "Select division";
export const loc_assessment_selectIndustry = "Select industry";
export const loc_assessment_selectRole = "Select role";
export const loc_assessment_signInToSaveAssessment = "Sign in to save this assessment";
export const loc_assessment_signInAndSave = "Sign in and save";
export const loc_assessment_someRequiredQuestionsAreIncomplete = "Some required questions are incomplete";
export const loc_assessment_azureStrategicMigrationAssessment = "Strategic Migration Assessment and Readiness Tool";
export const loc_assessment_weJustNeedAMomentToGetEverythingReady = "We just need a moment to get everything ready.";
export const loc_assessment_whatYouAreLookingFor = "What you are looking for:";
export const loc_assessment_yesSignInAndSave = "Yes, sign in and save";
export const loc_assessment_youCanFindOutMore = "You can find out how to improve on individual categories by reviewing the <a href=\"#guidance-scoring-improvement\">recommendations</a> below in the report.";
export const loc_assessment_youMustCompleteAllRequiredQuestions = "You must complete all required questions of this assessment to submit for guidance. Required questions are denoted with the asterisk icon:";
export const loc_assessment_yourOverallScore = "Your overall results";
export const loc_assessment_yourScore = "Your result:";
export const loc_achievements = "Achievements";
export const loc_actionPanel = "Action Panel";
export const loc_actions = "Actions";
export const loc_activateNewSandbox = "Yes, activate a new sandbox";
export const loc_activateSandbox = "Activate sandbox";
export const loc_activeFilters = "Active filters";
export const loc_activity = "Activity";
export const loc_aDayAgo = "a day ago";
export const loc_additionalFeaturesAndRequirements = "Additional features and requirements";
export const loc_additionalFeedbackOptions = "Additional feedback options";
export const loc_addParameter = "Add Parameter";
export const loc_advanced = "Advanced";
export const loc_africa = "Africa";
export const loc_all = "All";
export const loc_allapis = "All APIs";
export const loc_allFilters = "All filters";
export const loc_allPackages = "All Packages";
export const loc_allSet = "You are all set";
export const loc_americas = "Americas";
export const loc_aMinuteAgo = "a minute ago";
export const loc_aMonthAgo = "a month ago";
export const loc_and = "and";
export const loc_anHourAgo = "an hour ago";
export const loc_answer = "Answer";
export const loc_answers = "Answers";
export const loc_answer_accepted = "Answer accepted";
export const loc_answers_accepted = "Answers accepted";
export const loc_anyAdditionalFeedback = "Any additional feedback?";
export const loc_apiInPreview = "This API is in preview.";
export const loc_apiKey = "API Key";
export const loc_apiReference = "{platformName} API Reference";
export const loc_apiSearchIsUnavailable = "API search is unavailable. Please try again later.";
export const loc_apiVersion = "API Version";
export const loc_appliesTo = "Applies to";
export const loc_architecture = "Architecture";
export const loc_archiveDocs = "Previous versions";
export const loc_areYouSure = "Are you sure?";
export const loc_areYouSureDescription = "Deleting your data cannot be undone.";
export const loc_articleOutline = "Article Outline";
export const loc_articles = "Articles";
export const loc_aSecondAgo = "a second ago";
export const loc_asia = "Asia";
export const loc_askQuestion = "Ask a question";
export const loc_assemblies = "Assemblies";
export const loc_assembly = "Assembly";
export const loc_assessment = "assessment";
export const loc_assessments = "Assessments";
export const loc_assessmentProgressLabelFormat = "{question} of {questionsTotal} Questions";
export const loc_attachedEvent = "Attached Event";
export const loc_attachedEventsInSubtitle = "Attached Events";
export const loc_attachedProperty = "Attached Property";
export const loc_attachedPropertiesInSubtitle = "Attached Properties";
export const loc_attributes = "Attributes";
export const loc_availableAssessments = "Available assessments";
export const loc_awardedForAward = "Awarded for {LearnItem}";
export const loc_aWeekAgo = "a week ago";
export const loc_azureCategory_ai_machine_learning = "AI + Machine Learning";
export const loc_azureCategory_analytics = "Analytics";
export const loc_azureCategory_blockchain = "Blockchain";
export const loc_azureCategory_compute = "Compute";
export const loc_azureCategory_containers = "Containers";
export const loc_azureCategory_databases = "Databases";
export const loc_azureCategory_developer_tools = "Developer Tools";
export const loc_azureCategory_devops = "DevOps";
export const loc_azureCategory_featured = "Featured";
export const loc_azureCategory_hybrid = "Hybrid";
export const loc_azureCategory_identity = "Identity";
export const loc_azureCategory_integration = "Integration";
export const loc_azureCategory_iot = "Internet of Things";
export const loc_azureCategory_management_and_governance = "Management and Governance";
export const loc_azureCategory_media = "Media";
export const loc_azureCategory_migration = "Migration";
export const loc_azureCategory_mixed_reality = "Mixed Reality";
export const loc_azureCategory_mobile = "Mobile";
export const loc_azureCategory_networking = "Networking";
export const loc_azureCategory_security = "Security";
export const loc_azureCategory_storage = "Storage";
export const loc_azureCategory_web = "Web";
export const loc_azureCategory_windows_virtual_desktop = "Windows Virtual Desktop";
export const loc_azureDisclaimer = "Azure Cloud Shell uses your existing Azure subscriptions. Using Cloud Shell and executing commands will incur associated costs.";
export const loc_azureDocs = "Azure Documentation";
export const loc_azureSandbox_allActivationsRemaining = "A <a href=\"https://docs.microsoft.com/en-us/learn/support/faq?pivots=sandbox\">sandbox</a> gives you access to Azure resources. Your Azure subscription will not be charged. The sandbox may only be used to complete training on Microsoft Learn. Use for any other reason is prohibited, and may result in permanent loss of access to the sandbox.";
export const loc_azureSandbox_appealBan = "Appeal";
export const loc_azureSandbox_appealDenied = "We have reviewed your appeal and determined that your account has violated our <a href=\"https://docs.microsoft.com/en-us/legal/termsofuse\" data-linktype=\"external\">Terms of Use</a>. Your sandbox and lab access will not be reinstated. However, you can continue to access Microsoft Learn's training content. Many module exercises can be completed using your own Azure subscription, but charges may apply, and you may need to modify the instructions to work with your subscription.";
export const loc_azureSandbox_appealPending = "Your appeal has been received. Appeals are reviewed on a first-come, first-served basis, and may take several days to process. If your appeal is approved, you will regain access to sandboxes and other interactive learning resources.";
export const loc_azureSandbox_assigningRoles = "Assigning roles...";
export const loc_azureSandbox_checkingAuth = "Checking authentication status...";
export const loc_azureSandbox_checkingAvailability = "Checking sandbox availability...";
export const loc_azureSandbox_checkingCompletion = "Checking module completion status...";
export const loc_azureSandbox_checkingExisting = "Checking for existing sandbox...";
export const loc_azureSandbox_creatingFileshare = "Creating file share...";
export const loc_azureSandbox_creatingResourceGroup = "Creating resource group...";
export const loc_azureSandbox_creatingStorage = "Creating storage account...";
export const loc_azureSandbox_deactivatingPrevious = "Deactivating previous sandbox...";
export const loc_azureSandbox_finishing = "Finishing...";
export const loc_azureSandbox_furtherDetails = "What happened?<br/><br/> We use several methods to prevent misuse of Learn's resources in order to keep it free for everyone. Your account, activity, or connection indicated an elevated risk to our system. Please see our <a href=\"https://docs.microsoft.com/en-us/learn/support/troubleshooting\" data-bi-name=\"cloud-shell-troubleshooting-guide\">troubleshooting guidance</a> for more information.<br /><br/> The sandbox may only be used to complete training on Learn. Any other use is prohibited by our <a href=\"https://docs.microsoft.com/en-us/legal/termsofuse\" class=\"terms-link\">Terms of Use</a> and may result in the permanent loss of your access to the sandbox.";
export const loc_azureSandbox_loadingModule = "Loading current module...";
export const loc_azureSandbox_needPermissions = "Microsoft Learn needs your permission to create Azure resources.";
export const loc_azureSandbox_operationFailedBody = "Your account or connection was flagged for unusual activity. Because of this we've suspended your access to the Learn sandbox. If you think we've made an error, you can submit an appeal to regain access.";
export const loc_azureSandbox_operationFailedTitle = "Sandbox access suspended";
export const loc_azureSandbox_preparingInvitation = "Preparing invitation...";
export const loc_azureSandbox_quotaExceeded = "You have used all of your sandboxes for today. More sandboxes will be available tomorrow.";
export const loc_azureSandbox_resourcePrioritizationDisclaimer = "Due to the impact of the global health pandemic, Azure resources are being prioritized towards health and safety organizations. You may experience some issues when you deploy resources used in the exercises. Please try again or choose a different region. For more information, see Azure blog post - <a href=\"https://azure.microsoft.com/en-us/blog/update-2-on-microsoft-cloud-services-continuity/\">March 28: Update #2 on Microsoft cloud services continuity</a>.";
export const loc_azureSandbox_reviewPermissions = "Review permissions";
export const loc_azureSandbox_smsChallengeBody = "We'll send a text to make sure it's you.";
export const loc_azureSandbox_smsChallengeTitle = "Identity verification by phone";
export const loc_azureSandbox_someActivationsRemainingFormat = "You have used {num-used} of {num-limit} sandboxes for today. More sandboxes will be available tomorrow.";
export const loc_azureSandbox_userRejectedBody = "Your account could not be verified and we've suspended your access to Learn sandbox.";
export const loc_azureSandbox_userRejectedTitle = "Account verification failed";
export const loc_azureSandbox_verifyingPermissions = "Verifying permissions...";
export const loc_azureSandbox_zeroActivationsRemainingFormat = "You have used all {num-limit} sandboxes for today. More sandboxes will be available tomorrow.";
export const loc_azureSelectorPrompt = "-- Select Value --";
export const loc_back = "Back";
export const loc_backToPriorMenu = "Back to prior menu";
export const loc_badgeEarnedFor = "You've earned a badge for completing";
export const loc_badge = "Badge";
export const loc_badges = "Badges";
export const loc_beforeStartedSignInToSaveProgress = "Before you get started, consider <a class=\"docs-sign-in has-text-primary\" href=\"#\">Signing in</a> to save your progress.";
export const loc_begin = "Begin";
export const loc_beginner = "Beginner";
export const loc_beta = "beta";
export const loc_blog = "Blog";
export const loc_body = "Body";
export const loc_bookmark = "Bookmark";
export const loc_bookmarkThisPage = "Bookmark this page";
export const loc_bookmarks = "Bookmarks";
export const loc_bookmarks_addTheBookmark = "Add the bookmark";
export const loc_bookmarks_addedToBookmarks = "Added to <a href=\"{url}\" class=\"has-text-tertiary-invert is-underlined\">Bookmarks</a>";
export const loc_bookmarks_bookmarked = "Bookmarked";
export const loc_bookmarks_bookmarkRemoved = "Bookmark removed";
export const loc_bookmarks_pleaseSignInToBookmark = "Please sign in to save a bookmark";
export const loc_bookmarks_removeTheBookmark = "Remove the bookmark";
export const loc_bookmarks_undo = "Undo";
export const loc_bookmarks_weNeedYouToSignIn = "We need you to sign in so we can save bookmarks to your profile.";
export const loc_bookmarks_bookmarkYourFavorite = "Bookmark your favorite pages and jump back anytime.";
export const loc_bookmarks_signin_title = "Please sign in to bookmark this page.";
export const loc_bookmarks_button_pressedFormat = "Remove bookmark for {title}";
export const loc_bookmarks_button_unpressedFormat = "Add bookmark for {title}";
export const loc_breadcrumb = "Breadcrumb";
export const loc_browse = "Browse";
export const loc_browseAll = "Browse all";
export const loc_browseAll_achievements = "Browse all achievements";
export const loc_browseAll_activities = "Browse all activities";
export const loc_browseAll_bookmarks = "Browse all bookmarks";
export const loc_browseAll_tags = "Browse all tags";
export const loc_browseAll_users = "Browse all users";
export const loc_browseAll_threads = "Browse all Q&A threads";
export const loc_browseAllCertificationsInLevel = "Browse all {level} certifications";
export const loc_browseAllPaths = "Browse all paths";
export const loc_browseAllSessions = "Browse All Sessions";
export const loc_browseCertifications = "Browse Certifications";
export const loc_browseCode = "Browse code";
export const loc_browseCourses = "Browse Courses";
export const loc_browseLearn = "Browse Learn";
export const loc_browseLearningPaths = "Browse learning paths";
export const loc_browseModules = "Browse modules";
export const loc_callbacks = "Callbacks";
export const loc_calls = "Calls";
export const loc_capabilities = "Capabilities";
export const loc_caution = "Caution";
export const loc_cancel = "Cancel";
export const loc_certification = "Certification";
export const loc_certificationDetails = "Certification details";
export const loc_certificationExam = "Certification exam";
export const loc_certificationExams = "Certification exams";
export const loc_certificationExamLanguages = "Languages:";
export const loc_certificationExamOfficialPracticeTest = "Official practice test";
export const loc_certificationExamPracticeTestDescription = "All objectives of the exam are covered in depth so you'll be ready for any question on the exam.";
export const loc_certificationExamPriceDescription = "Price based on the country in which the exam is proctored.";
export const loc_certificationExamPricingDisclaimer = "* Pricing does not reflect any promotional offers or reduced pricing for Microsoft Imagine Academy program members, Microsoft Certified Trainers, and Microsoft Partner Network program members. Pricing is subject to change without notice. Pricing does not include applicable taxes. Please confirm exact pricing with the exam provider before registering to take an exam.";
export const loc_certificationExamRetirementDate = "Retirement date:";
export const loc_certificationExamRetirementDisclaimer = "** Complete this exam before the retirement date to ensure it is applied toward your certification. After the retirement date, please refer to the related certification for exam requirements.";
export const loc_certificationInstructorLedSectionHeaderFormat = "<strong>Instructor-led courses</strong>&nbsp;to gain the skills needed to become certified";
export const loc_certificationLearningPathsSectionHeaderFormat = "<strong>Learning paths</strong>&nbsp;to gain the skills needed to become certified";
export const loc_certificationLevelAssociate = "Associate";
export const loc_certificationLevelAssociateLabel = "Associate Certification";
export const loc_certificationLevelExpert = "Expert";
export const loc_certificationLevelExpertLabel = "Expert Certification";
export const loc_certificationLevelFundamentals = "Fundamentals";
export const loc_certificationLevelFundamentalsLabel = "Fundamentals Certification";
export const loc_certificationNPrerequisitesSeeDetailsFormat = "<a href=\"#certification-prerequisites\" class=\"is-size-small is-undecorated\">{numberOf} certifications</a>";
export const loc_certificationOnePrerequisiteSeeDetailsFormat = "<a href=\"#certification-prerequisites\" class=\"is-size-small is-undecorated\">1 certification</a>";
export const loc_certificationPath = "Certification path";
export const loc_certificationPrerequisites = "Certification prerequisites";
export const loc_certificationNPrerequisitesDescriptionFormat = "To become a {certTitle}, you must earn at least one of the following:";
export const loc_certificationPrerequisitesDescriptionFormat = "To become a {certTitle}, you must earn the ";
export const loc_certificationPrerequisitesOptionNFormat = "Prerequisite option {optionNumber}";
export const loc_certificationResources = "Certification resources";
export const loc_certifications = "Certifications";
export const loc_certifications_fundamentals = "Fundamentals";
export const loc_certifications_mce = "MCE";
export const loc_certifications_mcsa = "MCSA";
export const loc_certifications_mcsd = "MCSD";
export const loc_certifications_mcse = "MCSE";
export const loc_certifications_mos = "MOS";
export const loc_certifications_mta = "MTA";
export const loc_certifications_role_based = "Role-based";
export const loc_certifications_specialty = "Specialty";
export const loc_certificationSkillsMeasured = "Skills measured";
export const loc_certificationTakeOneExam = "Take one exam";
export const loc_certificationTakeThreeExams = "Take three exams";
export const loc_certificationTakeTwoExams = "Take two exams";
export const loc_certificationType = "Certification Type";
export const loc_certificationTypes = "Certification Types";
export const loc_changeYourProfilePicture = "Change your profile picture";
export const loc_checkAnswers = "Check your answers";
export const loc_checkWorkAgain = "Check your work again";
export const loc_checkYourKnowledge = "Check your knowledge";
export const loc_checkYourWork = "Check your work";
export const loc_chooseAzureAccount = "Choose one of your Azure accounts.";
export const loc_chooseYourInterests = "Choose your interests";
export const loc_cityTown = "City or Town";
export const loc_claimReward = "Unlock achievement";
export const loc_class = "Class";
export const loc_classesInSubtitle = "Classes";
export const loc_clearAll = "Clear all";
export const loc_clearfilter = "Clear Filter";
export const loc_clearform = "Clear form";
export const loc_clearYourFiltersAndSearchTerms = "clear your filters and search terms";
export const loc_clearterm = "Clear Term";
export const loc_clickForRSS = "Click for RSS feed";
export const loc_close = "Close";
export const loc_closedIssue = "Closed Issue";
export const loc_closedIssues = "Closed Issues";
export const loc_cloudShellTroubleshoot = "Unable to start the Cloud Shell. Take a look at the <a href=\"https://docs.microsoft.com/en-us/learn/support/troubleshooting\" data-bi-name=\"cloud-shell-troubleshooting-guide\">troubleshooting guide</a>.";
export const loc_clsCompliant = "This API is not CLS-compliant.";
export const loc_clsCompliantAlt = "CLS-compliant alternative";
export const loc_code = "Code";
export const loc_codeIncludeNotFound = "It looks like the sample you are looking for has moved! Rest assured we are working on resolving this.";
export const loc_codeOfConduct = "Code of Conduct";
export const loc_codeSamples = "Code Samples";
export const loc_collection = "Collection";
export const loc_collections = "Collections";
export const loc_collections_addItems = "Add items";
export const loc_collections_addToCollection = "Add to collection";
export const loc_collections_addedToCollection = "Added to <a href=\"{url}\" class=\"has-text-tertiary-invert is-underlined\">{collectionName}</a>";
export const loc_collections_addedToCollections = "Added to <a href=\"{url}\" class=\"has-text-tertiary-invert is-underlined\">Collections</a>";
export const loc_collections_addPageTypeToCollection = "Add <span class=\"has-text-weight-semibold\">\"{pageName}\"</span> to:";
export const loc_collections_browseToAddMore = "Browse to add more";
export const loc_collections_createANewCollection = "Create a new collection";
export const loc_collections_createANewCollection_duplicateTitleMessageFormat = "A collection already exists with the title \"{title}\". Collection titles should be unique.";
export const loc_collections_createANewCollection_invalidTitleMessage = "Title is required and up to 80 characters long.";
export const loc_collections_createANewCollection_invalidDescriptionMessage = "Description is optional and up to 200 characters long.";
export const loc_collections_createANewCollection_learnMore = "Collections may be publicly viewable. <a href=\"/learn/support/?pivots=profile\">Learn more</a>";
export const loc_collections_createANewCollection_titleDefaultValueFormat = "{userDisplayName}'s Collection {index}";
export const loc_collections_editCollection = "Edit collection";
export const loc_collections_itemRemoveMessageFormat = "\"{itemTitle}\" will be permanently removed from this collection.";
export const loc_collections_itemsInThisCollection = "Items in this collection";
export const loc_collections_noItemsYetInCollection = "There are no items in this collection.";
export const loc_collections_learningPathMultipleFormat = "{numberOf} Learning Paths";
export const loc_collections_learningPathSingleFormat = "{numberOf} Learning Path";
export const loc_collections_moduleMultipleFormat = "{numberOf} Modules";
export const loc_collections_moduleSingleFormat = "{numberOf} Module";
export const loc_collections_moveLearningPathDown = "Move learning path down";
export const loc_collections_moveLearningPathUp = "Move learning path up";
export const loc_collections_moveModuleDown = "Move module down";
export const loc_collections_moveModuleUp = "Move module up";
export const loc_collections_signin_titleForModule = "Please sign in to add this module to your collection.";
export const loc_collections_signin_titleForLp = "Please sign in to add this learning path to your collection.";
export const loc_collections_button_pressedFormat = "Manage {title} in collections";
export const loc_collections_button_unpressedFormat = "Add {title} to a collection";
export const loc_commands = "Commands";
export const loc_comment = "Comment";
export const loc_commentPostedFormat = "{name} commented {time ago}";
export const loc_comments = "Comments";
export const loc_companyDetails = "Company details";
export const loc_complete = "Complete";
export const loc_completedFormat = "{percentComplete}% completed";
export const loc_completeModuleMessage = "Complete this module and tell us what you think. Your feedback is essential to us.";
export const loc_completed = "Completed";
export const loc_completedModules = "{completedCount} of {totalCount} modules completed";
export const loc_completedUnits = "{completedCount} of {totalCount} units completed";
export const loc_completedOn = "Completed on";
export const loc_completeOnePrerequisite = "Complete one prerequisite";
export const loc_components = "Components";
export const loc_concept = "Concept";
export const loc_configuringCloudShellForSandbox = "Configuring Cloud Shell for sandbox access...";
export const loc_congratulations = "Congratulations!";
export const loc_connectionsPerAccount = "Connections per Account";
export const loc_constructorDetails = "Constructor Details";
export const loc_constructorInSubtitle = "Constructor";
export const loc_constructorsInSubtitle = "Constructors";
export const loc_content = "content";
export const loc_contentArea = "Content area";
export const loc_contentNavigation = "Content Navigation";
export const loc_contents = "Contents";
export const loc_continue = "Continue";
export const loc_continueHere = "Continue Here";
export const loc_continueToFirstStep = "Continue to first step";
export const loc_continueToNextModule = "Continue to next module";
export const loc_continueWithoutSaving = "Continue without saving progress";
export const loc_contravariantDescription = "This type parameter is contravariant. That is, you can use either the type you specified or any type that is less derived. For more information about covariance and contravariance, see <a href=\"https://docs.microsoft.com/en-us/dotnet/standard/generics/covariance-and-contravariance\">Covariance and Contravariance in Generics</a>.";
export const loc_contributorGuide = "Contribute";
export const loc_contributor = "Contributor";
export const loc_contributors = "Contributors";
export const loc_copied = "Copied";
export const loc_copy = "Copy";
export const loc_copyCode = "Copy code";
export const loc_correctAnswer = "Correct answer";
export const loc_course = "Course";
export const loc_courseOutline = "Course outline";
export const loc_courseCannotBePurchasedAfterDate = "This course cannot be purchased by Microsoft training partners after this date. Please check with your local or chosen training provider to check availability.";
export const loc_courseScheduling_location = "Location by city";
export const loc_courseScheduling_virtualOnly = "Show only virtual courses";
export const loc_courseScheduling_courseSchedulingMessage = "Courses are offered in {timezone}";
export const loc_courseScheduling_providedBy = "By {partnerName}";
export const loc_courseScheduling_startingOn = "Starting on";
export const loc_courseScheduling_courseInfo = "Courses are scheduled over {duration} unless otherwise noted. Session times are based on course location. For information regarding your enrollment, please go to the partner you've registered with.";
export const loc_courseScheduling_scheduledTime = "From <time datetime=\"{startTime}\">{startTimeDisplay}</time> to <time datetime=\"{endTime}\">{endTimeDisplay}</time>";
export const loc_courseScheduling_noCoursesFound = "Sorry, we aren’t aware of any courses scheduled at this time. You can view our <a class=\"is-undecorated\" href=\"{href}\">partner directory</a> to find other options for this course.";
export const loc_courseScheduling_noCoursesFoundForFilter = "Sorry, we do not currently offer any session in {location}. Please check this page later.";
export const loc_courseScheduling_noCoursesFoundForFilterCTA = "Find specific partners and more course options. <a class=\"is-undecorated\" href=\"{href}\">View the directory.</a>";
export const loc_courseScheduling_noCoursesFoundForFilterHelpText = "Having trouble finding what you need?";
export const loc_courseScheduling_errorLoadingCourseSchedules = "We couldn't load the course schedules. Please try again later.";
export const loc_countriesRegions_AF = "Afghanistan";
export const loc_countriesRegions_AX = "Aland Islands";
export const loc_countriesRegions_AL = "Albania";
export const loc_countriesRegions_DZ = "Algeria";
export const loc_countriesRegions_AS = "American Samoa";
export const loc_countriesRegions_AD = "Andorra";
export const loc_countriesRegions_AO = "Angola";
export const loc_countriesRegions_AI = "Anguilla";
export const loc_countriesRegions_AQ = "Antarctica";
export const loc_countriesRegions_AG = "Antigua and Barbuda";
export const loc_countriesRegions_AR = "Argentina";
export const loc_countriesRegions_AM = "Armenia";
export const loc_countriesRegions_AW = "Aruba";
export const loc_countriesRegions_AU = "Australia";
export const loc_countriesRegions_AT = "Austria";
export const loc_countriesRegions_AZ = "Azerbaijan";
export const loc_countriesRegions_BS = "Bahamas, The";
export const loc_countriesRegions_BH = "Bahrain";
export const loc_countriesRegions_BD = "Bangladesh";
export const loc_countriesRegions_BB = "Barbados";
export const loc_countriesRegions_BY = "Belarus";
export const loc_countriesRegions_BE = "Belgium";
export const loc_countriesRegions_BZ = "Belize";
export const loc_countriesRegions_BJ = "Benin";
export const loc_countriesRegions_BM = "Bermuda";
export const loc_countriesRegions_BT = "Bhutan";
export const loc_countriesRegions_BO = "Bolivia";
export const loc_countriesRegions_BQ = "Bonaire Saint Eustatius and Saba";
export const loc_countriesRegions_BA = "Bosnia and Herzegovina";
export const loc_countriesRegions_BW = "Botswana";
export const loc_countriesRegions_BV = "Bouvet Island";
export const loc_countriesRegions_BR = "Brazil";
export const loc_countriesRegions_IO = "British Indian Ocean Territory";
export const loc_countriesRegions_BN = "Brunei";
export const loc_countriesRegions_BG = "Bulgaria";
export const loc_countriesRegions_BF = "Burkina Faso";
export const loc_countriesRegions_BI = "Burundi";
export const loc_countriesRegions_KH = "Cambodia";
export const loc_countriesRegions_CM = "Cameroon";
export const loc_countriesRegions_CA = "Canada";
export const loc_countriesRegions_CV = "Cape Verde";
export const loc_countriesRegions_KY = "Cayman Islands";
export const loc_countriesRegions_CF = "Central African Republic";
export const loc_countriesRegions_TD = "Chad";
export const loc_countriesRegions_CL = "Chile";
export const loc_countriesRegions_CN = "China";
export const loc_countriesRegions_CX = "Christmas Island";
export const loc_countriesRegions_CC = "Cocos (Keeling) Islands";
export const loc_countriesRegions_CO = "Colombia";
export const loc_countriesRegions_KM = "Comoros";
export const loc_countriesRegions_CG = "Congo";
export const loc_countriesRegions_CD = "Congo (DRC)";
export const loc_countriesRegions_CK = "Cook Islands";
export const loc_countriesRegions_CR = "Costa Rica";
export const loc_countriesRegions_CI = "Côte d'Ivoire";
export const loc_countriesRegions_HR = "Croatia";
export const loc_countriesRegions_CW = "Curaçao";
export const loc_countriesRegions_CY = "Cyprus";
export const loc_countriesRegions_CZ = "Czech Republic";
export const loc_countriesRegions_DK = "Denmark";
export const loc_countriesRegions_DJ = "Djibouti";
export const loc_countriesRegions_DM = "Dominica";
export const loc_countriesRegions_DO = "Dominican Republic";
export const loc_countriesRegions_EC = "Ecuador";
export const loc_countriesRegions_EG = "Egypt";
export const loc_countriesRegions_SV = "El Salvador";
export const loc_countriesRegions_GQ = "Equatorial Guinea";
export const loc_countriesRegions_ER = "Eritrea";
export const loc_countriesRegions_EE = "Estonia";
export const loc_countriesRegions_ET = "Ethiopia";
export const loc_countriesRegions_FK = "Falkland Islands";
export const loc_countriesRegions_FO = "Faroe Islands";
export const loc_countriesRegions_FJ = "Fiji";
export const loc_countriesRegions_FI = "Finland";
export const loc_countriesRegions_FR = "France";
export const loc_countriesRegions_GF = "French Guiana";
export const loc_countriesRegions_PF = "French Polynesia";
export const loc_countriesRegions_GA = "Gabon";
export const loc_countriesRegions_GM = "Gambia";
export const loc_countriesRegions_GE = "Georgia";
export const loc_countriesRegions_DE = "Germany";
export const loc_countriesRegions_GH = "Ghana";
export const loc_countriesRegions_GI = "Gibraltar";
export const loc_countriesRegions_GR = "Greece";
export const loc_countriesRegions_GL = "Greenland";
export const loc_countriesRegions_GD = "Grenada";
export const loc_countriesRegions_GP = "Guadeloupe";
export const loc_countriesRegions_GU = "Guam";
export const loc_countriesRegions_GT = "Guatemala";
export const loc_countriesRegions_GG = "Guernsey";
export const loc_countriesRegions_GN = "Guinea";
export const loc_countriesRegions_GW = "Guinea-Bissau";
export const loc_countriesRegions_GY = "Guyana";
export const loc_countriesRegions_HT = "Haiti";
export const loc_countriesRegions_HM = "Heard Island / McDonald Islands";
export const loc_countriesRegions_HN = "Honduras";
export const loc_countriesRegions_HK = "Hong Kong SAR";
export const loc_countriesRegions_HU = "Hungary";
export const loc_countriesRegions_IS = "Iceland";
export const loc_countriesRegions_IN = "India";
export const loc_countriesRegions_ID = "Indonesia";
export const loc_countriesRegions_IQ = "Iraq";
export const loc_countriesRegions_IE = "Ireland";
export const loc_countriesRegions_IM = "Isle of Man";
export const loc_countriesRegions_IL = "Israel";
export const loc_countriesRegions_IT = "Italy";
export const loc_countriesRegions_JM = "Jamaica";
export const loc_countriesRegions_JP = "Japan";
export const loc_countriesRegions_JE = "Jersey";
export const loc_countriesRegions_JO = "Jordan";
export const loc_countriesRegions_KZ = "Kazakhstan";
export const loc_countriesRegions_KE = "Kenya";
export const loc_countriesRegions_KI = "Kiribati";
export const loc_countriesRegions_KW = "Kuwait";
export const loc_countriesRegions_KG = "Kyrgyzstan";
export const loc_countriesRegions_LA = "Laos";
export const loc_countriesRegions_LV = "Latvia";
export const loc_countriesRegions_LB = "Lebanon";
export const loc_countriesRegions_LS = "Lesotho";
export const loc_countriesRegions_LR = "Liberia";
export const loc_countriesRegions_LY = "Libya";
export const loc_countriesRegions_LI = "Liechtenstein";
export const loc_countriesRegions_LT = "Lithuania";
export const loc_countriesRegions_LU = "Luxembourg";
export const loc_countriesRegions_MO = "Macao SAR";
export const loc_countriesRegions_MK = "Macedonia FYRO";
export const loc_countriesRegions_MG = "Madagascar";
export const loc_countriesRegions_MW = "Malawi";
export const loc_countriesRegions_MY = "Malaysia";
export const loc_countriesRegions_MV = "Maldives";
export const loc_countriesRegions_ML = "Mali";
export const loc_countriesRegions_MT = "Malta";
export const loc_countriesRegions_MH = "Marshall Islands";
export const loc_countriesRegions_MQ = "Martinique";
export const loc_countriesRegions_MR = "Mauritania";
export const loc_countriesRegions_MU = "Mauritius";
export const loc_countriesRegions_YT = "Mayotte";
export const loc_countriesRegions_MX = "Mexico";
export const loc_countriesRegions_FM = "Micronesia";
export const loc_countriesRegions_MD = "Moldova";
export const loc_countriesRegions_MC = "Monaco";
export const loc_countriesRegions_MN = "Mongolia";
export const loc_countriesRegions_ME = "Montenegro";
export const loc_countriesRegions_MS = "Montserrat";
export const loc_countriesRegions_MA = "Morocco";
export const loc_countriesRegions_MZ = "Mozambique";
export const loc_countriesRegions_MM = "Myanmar";
export const loc_countriesRegions_NA = "Namibia";
export const loc_countriesRegions_NR = "Nauru";
export const loc_countriesRegions_NP = "Nepal";
export const loc_countriesRegions_NL = "Netherlands";
export const loc_countriesRegions_NC = "New Caledonia";
export const loc_countriesRegions_NZ = "New Zealand";
export const loc_countriesRegions_NI = "Nicaragua";
export const loc_countriesRegions_NE = "Niger";
export const loc_countriesRegions_NG = "Nigeria";
export const loc_countriesRegions_NU = "Niue";
export const loc_countriesRegions_NF = "Norfolk Island";
export const loc_countriesRegions_MP = "Northern Mariana Islands";
export const loc_countriesRegions_NO = "Norway";
export const loc_countriesRegions_OM = "Oman";
export const loc_countriesRegions_PK = "Pakistan";
export const loc_countriesRegions_PW = "Palau";
export const loc_countriesRegions_PS = "Palestine, State of";
export const loc_countriesRegions_PA = "Panama";
export const loc_countriesRegions_PG = "Papua New Guinea";
export const loc_countriesRegions_PY = "Paraguay";
export const loc_countriesRegions_PE = "Peru";
export const loc_countriesRegions_PH = "Philippines";
export const loc_countriesRegions_PN = "Pitcairn";
export const loc_countriesRegions_PL = "Poland";
export const loc_countriesRegions_PT = "Portugal";
export const loc_countriesRegions_PR = "Puerto Rico";
export const loc_countriesRegions_QA = "Qatar";
export const loc_countriesRegions_RE = "Réunion";
export const loc_countriesRegions_RO = "Romania";
export const loc_countriesRegions_RU = "Russia";
export const loc_countriesRegions_RW = "Rwanda";
export const loc_countriesRegions_BL = "Saint Barthelemy";
export const loc_countriesRegions_KN = "Saint Kitts and Nevis";
export const loc_countriesRegions_LC = "Saint Lucia";
export const loc_countriesRegions_MF = "Saint Martin";
export const loc_countriesRegions_WS = "Samoa";
export const loc_countriesRegions_SM = "San Marino";
export const loc_countriesRegions_ST = "Sao Tome and Principe";
export const loc_countriesRegions_VC = "Saint Vincent and the Grenadines";
export const loc_countriesRegions_SA = "Saudi Arabia";
export const loc_countriesRegions_SN = "Senegal";
export const loc_countriesRegions_RS = "Serbia";
export const loc_countriesRegions_SC = "Seychelles";
export const loc_countriesRegions_SL = "Sierra Leone";
export const loc_countriesRegions_SG = "Singapore";
export const loc_countriesRegions_SX = "Sint Maarten";
export const loc_countriesRegions_SK = "Slovakia";
export const loc_countriesRegions_SI = "Slovenia";
export const loc_countriesRegions_SB = "Solomon Islands";
export const loc_countriesRegions_SO = "Somalia";
export const loc_countriesRegions_ZA = "South Africa";
export const loc_countriesRegions_GS = "South Georgia and the South Sandwich Islands";
export const loc_countriesRegions_SS = "South Sudan";
export const loc_countriesRegions_KR = "Korean";
export const loc_countriesRegions_ES = "Spain";
export const loc_countriesRegions_LK = "Sri Lanka";
export const loc_countriesRegions_SH = "St. Helena";
export const loc_countriesRegions_PM = "St. Pierre and Miquelon";
export const loc_countriesRegions_SR = "Suriname";
export const loc_countriesRegions_SJ = "Svalbard and Jan Mayen";
export const loc_countriesRegions_SZ = "Swaziland";
export const loc_countriesRegions_SE = "Sweden";
export const loc_countriesRegions_CH = "Schweiz / Suisse";
export const loc_countriesRegions_TW = "Taiwan";
export const loc_countriesRegions_TJ = "Tajikistan";
export const loc_countriesRegions_TZ = "Tanzania";
export const loc_countriesRegions_TH = "Thailand";
export const loc_countriesRegions_TL = "Timor-Leste";
export const loc_countriesRegions_TG = "Togo";
export const loc_countriesRegions_TK = "Tokelau";
export const loc_countriesRegions_TO = "Tonga";
export const loc_countriesRegions_TT = "Trinidad and Tobago";
export const loc_countriesRegions_TN = "Tunisia";
export const loc_countriesRegions_TR = "Turkey";
export const loc_countriesRegions_TM = "Turkmenistan";
export const loc_countriesRegions_TC = "Turks and Caicos Islands";
export const loc_countriesRegions_TV = "Tuvalu";
export const loc_countriesRegions_UG = "Uganda";
export const loc_countriesRegions_UA = "Ukraine";
export const loc_countriesRegions_AE = "United Arab Emirates";
export const loc_countriesRegions_GB = "United Kingdom";
export const loc_countriesRegions_US = "United States";
export const loc_countriesRegions_UY = "Uruguay";
export const loc_countriesRegions_UM = "US Minor Outlying Islands";
export const loc_countriesRegions_UZ = "Uzbekistan";
export const loc_countriesRegions_VU = "Vanuatu";
export const loc_countriesRegions_VA = "Vatican City State";
export const loc_countriesRegions_VE = "Venezuela";
export const loc_countriesRegions_VN = "Vietnam";
export const loc_countriesRegions_VG = "British Virgin Islands";
export const loc_countriesRegions_VI = "US Virgin Islands";
export const loc_countriesRegions_WF = "Wallis and Futuna Islands";
export const loc_countriesRegions_EH = "Western Sahara";
export const loc_countriesRegions_YE = "Yemen";
export const loc_countriesRegions_ZM = "Zambia";
export const loc_countriesRegions_ZW = "Zimbabwe";
export const loc_countriesRegions_TF = "French Southern Territories";
export const loc_countryCode = "Country code";
export const loc_countryRegion = "Country/Region";
export const loc_covariantDescription = "This type parameter is covariant. That is, you can use either the type you specified or any type that is more derived. For more information about covariance and contravariance, see <a href=\"https://docs.microsoft.com/en-us/dotnet/standard/generics/covariance-and-contravariance\">Covariance and Contravariance in Generics</a>.";
export const loc_create = "Create";
export const loc_createConnectionInstruction = "To connect your account, you will need the following information:";
export const loc_createdBy = "Created by";
export const loc_createFTEProfileExplanation = "By using Microsoft Learn, <strong>you understand and acknowledge</strong> that your membership means our network of sites and applications will record, and track your achievements and activities and we may share your results and activities with other teams within Microsoft. Additionally, your username, display name, achievements and activities will be publicly visible.<ul><li>Your profile will publicly indicate you work at Microsoft.</li><li>If you lose access to the email, you won't be able to login to the site.</li><li>Some gamification features aren't available to Microsoft employees.</li><li>If you'd like to use this site for personal activity, please sign in with a different account.</li></ul>";
export const loc_createMSAProfileExplanation = "By becoming a member, you understand and acknowledge that your Microsoft Docs membership means our network of sites and applications will record and track your achievements and activities. Additionally, your username, display name, profile picture, affiliation, achievements and activities will be publicly visible.";
export const loc_createNewArchive = "Create new archive";
export const loc_creatingAConnection = "Creating a connection";
export const loc_creativeCommonsPartnerText = "This learning path and modules are licensed under a <a href=\"https://creativecommons.org/licenses/by-nc-sa/4.0/\">Creative Commons Attribution-NonCommercial-ShareAlike International License</a>.";
export const loc_currentSelection = "Current Selection:";
export const loc_daily = "Daily";
export const loc_dark = "Dark";
export const loc_dateNotAvailable = "date not available";
export const loc_day = "Day";
export const loc_days = "Days";
export const loc_daysAgo = "{duration} days ago";
export const loc_decline = "Decline";
export const loc_defaultValue = "default value";
export const loc_defaultValueHeader = "Default Value";
export const loc_definition = "Definition";
export const loc_definitions = "Definitions";
export const loc_delegateInSubtitle = "Delegate";
export const loc_delegatesInSubtitle = "Delegates";
export const loc_delete = "Delete";
export const loc_deleteParameter = "Delete Parameter";
export const loc_deleteAvatar = "Delete current picture";
export const loc_deploy = "Deploy";
export const loc_deployToAzure = "Deploy to Azure";
export const loc_deprecated = "This API is now obsolete.";
export const loc_derived = "Derived";
export const loc_description = "Description";
export const loc_descriptionNotAvailable = "description not available";
export const loc_detail = "Detail";
export const loc_devlangs_aspx = "ASP.NET";
export const loc_devlangs_aspx_csharp = "ASP.NET (C#)";
export const loc_devlangs_aspx_vb = "ASP.NET (VB)";
export const loc_devlangs_al_language = "AL Language";
export const loc_devlangs_azcopy = "AzCopy";
export const loc_devlangs_azurecli = "Azure CLI";
export const loc_devlangs_azurepowershell = "Azure PowerShell";
export const loc_devlangs_bash = "Bash";
export const loc_devlangs_brainscript = "BrainScript";
export const loc_devlangs_c__ = "C";
export const loc_devlangs_console = "Console";
export const loc_devlangs_cpp = "C++";
export const loc_devlangs_cppcx = "C++/CX";
export const loc_devlangs_cppwinrt = "C++/WinRT";
export const loc_devlangs_csharp = "C#";
export const loc_devlangs_cshtml = "CSHTML";
export const loc_devlangs_dax = "DAX";
export const loc_devlangs_dockerfile = "Dockerfile";
export const loc_devlangs_dotnetcli = ".NET Core CLI";
export const loc_devlangs_fsharp = "F#";
export const loc_devlangs_go = "Go";
export const loc_devlangs_gradle = "Gradle";
export const loc_devlangs_hcl = "HashiCorp Configuration Language";
export const loc_devlangs_hiveql = "HiveQL";
export const loc_devlangs_html = "HTML";
export const loc_devlangs_http = "HTTP";
export const loc_devlangs_inf = "INF";
export const loc_devlangs_inkling = "Inkling";
export const loc_devlangs_java = "Java";
export const loc_devlangs_javascript = "JavaScript";
export const loc_devlangs_js = "JavaScript";
export const loc_devlangs_json = "JSON";
export const loc_devlangs_kusto = "Kusto";
export const loc_devlangs_md = "Markdown";
export const loc_devlangs_mof = "Managed Object Format";
export const loc_devlangs_nodejs = "Node.js";
export const loc_devlangs_objc = "Objective-C";
export const loc_devlangs_odata = "OData";
export const loc_devlangs_output = "Output";
export const loc_devlangs_php = "PHP";
export const loc_devlangs_powerapps_comma = "Power Apps";
export const loc_devlangs_powerapps_dot = "Power Apps";
export const loc_devlangs_powerappsfl = "Power Apps Formula";
export const loc_devlangs_powershell = "PowerShell";
export const loc_devlangs_protobuf = "ProtoBuf";
export const loc_devlangs_python = "Python";
export const loc_devlangs_qsharp = "Q#";
export const loc_devlangs_r__ = "R";
export const loc_devlangs_ruby = "Ruby";
export const loc_devlangs_scala = "Scala";
export const loc_devlangs_solidity = "Solidity";
export const loc_devlangs_sql = "SQL";
export const loc_devlangs_swift = "Swift";
export const loc_devlangs_terraform = "Terraform";
export const loc_devlangs_tsql = "Transact-SQL";
export const loc_devlangs_typescript = "TypeScript";
export const loc_devlangs_usql = "U-SQL";
export const loc_devlangs_vb = "VB";
export const loc_devlangs_vba = "Visual Basic for Applications";
export const loc_devlangs_vbscript = "Visual Basic Script";
export const loc_devlangs_xaml = "XAML";
export const loc_devlangs_xml = "XML";
export const loc_devlangs_xpp = "X++";
export const loc_devlangs_yaml = "YAML";
export const loc_dialogCloseWindow = "Close this dialog";
export const loc_differentSandboxAlreadyActive = "A different sandbox is already active";
export const loc_differentSandboxAlreadyActiveExplanation = "You've already activated a sandbox in the module <a></a>. If you activate this sandbox, you'll lose your work in the other module.";
export const loc_disable = "Disable";
export const loc_disclaimer_archive = "We're no longer updating this content regularly. Check the <a href='https://support.microsoft.com/en-us/lifecycle/selectindex'>Microsoft Product Lifecycle</a> for information about how this product, service, technology, or API is supported.";
export const loc_disclaimer_archiveMachineTranslated = "This article was translated by machine. We're no longer updating this content regularly. Check the <a href='https://support.microsoft.com/en-us/lifecycle/selectindex'>Microsoft Product Lifecycle</a> for information about how this product, service, technology, or API is supported.";
export const loc_disclaimer_archiveRetire = "This content has been retired and may not be updated in the future.  The product, service, or technology mentioned in this content is no longer supported.";
export const loc_disclaimer_archiveRetireMachineTranslated = "This article was translated by machine. This content has been retired and may not be updated in the future.  The product, service, or technology mentioned in this content is no longer supported.";
export const loc_disclaimer_dismissAlert = "Dismiss alert";
export const loc_disclaimer_learnMobileInteractive = "Contains learning options not available on mobile";
export const loc_disclaimer_machineTranslatedOnly = "Parts of this topic may be machine translated. You can suggest a better translation by clicking the Edit button. Refer to the <a href=\"https://docs.microsoft.com/en-us/contribute/\">contribution guide</a> for instructions.";
export const loc_disclaimer_machineTranslatedWithoutEditButton = "Parts of this topic may be machine translated.";
export const loc_disclaimer_moniker = "This API is not supported in the currently selected framework.";
export const loc_disclaimer_pageRedirectedToDocs = "This page was permanently redirected to {url}";
export const loc_disclaimer_recommendedVersion = "Recommended Version";
export const loc_disclaimer_returnToMain = "Return to main site";
export const loc_disclaimer_text = "This content is not available in your language. Here is the English version.";
export const loc_learning_path_supported = "This learning path will soon be available in the following language: {language}.";
export const loc_learning_path_not_supported = "This learning path is not available in the following language: {language}.";
export const loc_mainstreamEndDate = "Mainstream End Date";
export const loc_module_supported = "This module will soon be available in the following language: {language}.";
export const loc_module_not_supported = "This module is not available in the following language: {language}.";
export const loc_disclaimerSearchPreviousVersions = "Search results are from previous versions' documentation and not regularly updated.";
export const loc_docsProfile = "Docs profile";
export const loc_documentation = "Documentation";
export const loc_documentDetails = "Document Details";
export const loc_doNotEditThis = "Do not edit this section. It is required for docs.microsoft.com ➟ GitHub issue linking.";
export const loc_done = "Done";
export const loc_dotnetDocs = ".NET Documentation";
export const loc_dotnetEditor = ".NET Editor";
export const loc_download = "Download";
export const loc_downloadCertificationSkillsOutline = "Download certification skills outline";
export const loc_downloadExamSkillsOutline = "Download exam skills outline";
export const loc_downloadPdf = "Download PDF";
export const loc_downloadZIP = "Download ZIP";
export const loc_downloadWhitepaper = "Download Whitepaper";
export const loc_dynamicOutputsDescription = "The outputs of this operation are dynamic.";
export const loc_earnMoreAchievements = "Earn More Achievements";
export const loc_earnTheCertification = "Earn the certification";
export const loc_edit = "Edit";
export const loc_editedOn = "Edited on";
export const loc_editdesc = "Edit This Document";
export const loc_editYourRating = "Edit your rating";
export const loc_eiisInSubtitle = "Explicit Interface Implementations";
export const loc_email = "Email";
export const loc_emailForNotifications = "Email for notifications";
export const loc_emailIsRequired = "Email is required.";
export const loc_emailIsRequiredSandbox = "Email is required to activate a sandbox or lab";
export const loc_emailIsRequiredExplanation = "Your Microsoft account must be linked to a valid email to activate a sandbox or lab. Go to <a href=\"https://account.live.com\">Microsoft Account Settings</a> to link your email and try again.";
export const loc_emptyNameNotAllowed = "Empty name is not allowed";
export const loc_enable = "Enable";
export const loc_endDate = "End Date";
export const loc_enterFocusMode = "Enter focus mode";
export const loc_enumInSubtitle = "Enum";
export const loc_enumsInSubtitle = "Enums";
export const loc_error = "Error";
export const loc_errorCodes = "Error Codes";
export const loc_errorLoadingBrowseContent = "We couldn't load your search results. Please try again later.";
export const loc_errorLoadingComments = "Error loading comments.";
export const loc_europe = "Europe";
export const loc_event = "Event";
export const loc_eventDetails = "Event Details";
export const loc_eventsInSubtitle = "Events";
export const loc_exam = "Exam";
export const loc_examImportant = "Important:";
export const loc_examination = "Examination";
export const loc_examOfficialPracticeTest = "Official practice test for";
export const loc_examOneRelatedExamFormat = "1 related exam";
export const loc_examMultipleRelatedExamsFormat = "{numberOf} related exams";
export const loc_examPartOfRequirement = "<strong>Part of the requirements for:</strong>";
export const loc_examples = "Examples";
export const loc_examResources = "Exam resources";
export const loc_exceptions = "Exceptions";
export const loc_exit = "Exit";
export const loc_exitFocusMode = "Exit focus mode";
export const loc_exploreOtherModules = "Explore other modules";
export const loc_exploreOtherPaths = "Explore other paths";
export const loc_exploreOtherPopularPaths = "Explore other popular paths";
export const loc_exportProfileNoInformation = "You didn't have any information to export.";
export const loc_extendedEndDate = "Extended End Date";
export const loc_extensionMethodsInSubtitle = "Extension Methods";
export const loc_facebook = "Facebook";
export const loc_faq = "FAQ";
export const loc_faqHelp = "FAQ & Help";
export const loc_fair = "Fair";
export const loc_features = "Features";
export const loc_feedback = "Feedback";
export const loc_feedbackPlaceholder = "[Enter feedback here]";
export const loc_feedbackProduct = "Give product feedback";
export const loc_feedbackReport = "Report feedback";
export const loc_feedbackReport_attachment = "Attach a screenshot (optional)";
export const loc_feedbackReport_attachmentAction = "Choose Files...";
export const loc_feedbackReport_attachmentAllowed = ".jpeg, .jpg, .png or .gif";
export const loc_feedbackReport_category = "What's your feedback about?";
export const loc_feedbackReport_categoryContent = "What about the content?";
export const loc_feedbackReport_categoryProduct = "What about the product or functionality?";
export const loc_feedbackReport_completionMessage = "Complete the module and tell us what you think. Your feedback is essential to us.";
export const loc_feedbackReport_description = "Describe your feedback, including steps that got you there if possible";
export const loc_feedbackReport_optionCloudShellProblem = "Problems with Cloud Shell";
export const loc_feedbackReport_optionContentMistake = "Incorrect Information/Spelling/Content Mistake";
export const loc_feedbackReport_optionEditorProblem = "Problems with .NET Editor";
export const loc_feedbackReport_optionFeatureRequest = "Feature request";
export const loc_feedbackReport_optionFunctionalityOther = "Product or functionality";
export const loc_feedbackReport_optionGeneral = "General feedback";
export const loc_feedbackReport_optionLearnContentQuestion = "I have questions about the learning content";
export const loc_feedbackReport_optionOther = "Other";
export const loc_feedbackReport_optionProblemWithInstall = "Problems with installing dependencies";
export const loc_feedbackReport_optionRegistrationProblem = "Problems with registration/signing-in";
export const loc_feedbackReport_optionTranslationQuality = "Issues with translation quality";
export const loc_feedbackReport_optionTutorialContentQuality = "Tutorial content quality";
export const loc_feedbackReport_selectOption = "Select an option";
export const loc_feedbackReport_describeYourFeedback = "Describe your feedback. If applicable, include steps to replicate your issue";
export const loc_feedbackReport_feedbackTitle = "Enter your feedback title";
export const loc_feedbackReport_categoryContentMistake = "Incorrect Information/Spelling/Content Mistake";
export const loc_feedbackReport_categoryFeatureRequest = "Feature request";
export const loc_feedbackReport_categoryFunctionalityOther = "Product or functionality";
export const loc_feedbackReport_categoryGeneral = "General feedback";
export const loc_feedbackReport_categoryLearnContentOther = "Learning content quality";
export const loc_feedbackReport_categoryLearnContentQuestion = "I have questions about the learning content";
export const loc_feedbackReport_categoryLearnCreditProblem = "I am not being granted credit/XP for completion";
export const loc_feedbackReport_categoryOther = "Other";
export const loc_feedbackReport_categoryRegistrationProblem = "Problems with registration/signing-in";
export const loc_feedbackReport_categorySandboxProblem = "Problems with the Sandbox";
export const loc_feedbackReport_categoryTranslationQuality = "Issues with translation quality";
export const loc_feedbackReport_selectAnIssueThatAppliesToYou = "Select an issue that applies to you";
export const loc_feedbackReport_selectTypeOfFeedback = "Select the type of feedback";
export const loc_feedbackReport_summary = "Summarize your feedback";
export const loc_field = "Field";
export const loc_fieldsInSubtitle = "Fields";
export const loc_fieldValue = "Field Value";
export const loc_filter = "Filter";
export const loc_filterByTitle = "Filter by title";
export const loc_filterPlaceholder = "Filter";
export const loc_findAFacetFormat = "Find a {facet}";
export const loc_findALanguage = "Find a language";
export const loc_findALearningPartner = "Find a learning partner";
export const loc_findPostsTagsUsers = "Find posts, tags, and users";
export const loc_finishModuleFormat = "Finish {module}";
export const loc_flagsAttributeDisclaimer = "This enumeration has a <a href=\"https://docs.microsoft.com/en-us/dotnet/api/system.flagsattribute\">FlagsAttribute</a> attribute that allows a bitwise combination of its member values.";
export const loc_flows = "Flows";
export const loc_follower = "Follower";
export const loc_followers = "Followers";
export const loc_following = "Following";
export const loc_for = "for";
export const loc_format = "Format";
export const loc_forNonStudentsInterestedInTechnology = "For non-students interested in technology";
export const loc_forums = "Forums";
export const loc_forStudentsOrInstructors = "For students or instructors";
export const loc_functionDetails = "Function Details";
export const loc_functionsInSubtitle = "Functions";
export const loc_getHelp = "Get help";
export const loc_getStarted = "Get Started";
export const loc_gettingCertified = "Getting certified?";
export const loc_giveFeedback = "Give feedback";
export const loc_global = "Global";
export const loc_globalNavigation = "Global navigation";
export const loc_globalParameters = "Global Parameters";
export const loc_goBack = "Go back";
export const loc_goBackToFinish = "Go back to finish";
export const loc_goBackToFinishModule = "Go back to finish module";
export const loc_good = "Good";
export const loc_goodWork = "Good work!";
export const loc_goToCertificationDashboard = "Go to Certification Dashboard";
export const loc_great = "Great";
export const loc_greatWork = "Great work!";
export const loc_gsi_comingSoonLearnMore = "Coming soon, learn more";
export const loc_gsi_forJobSeekers = "For job seekers impacted by COVID-19";
export const loc_gsi_getUpdatesGlobalSkillsInitiative = "Get updates about our global skills initiative";
export const loc_gsi_learnAboutGsi = "<a href=\"https://opportunity.linkedin.com/\" class=\"is-undecorated\">Learn about our commitment</a> to support people impacted by COVID-19.";
export const loc_gsi_profileSubscribeInfoText = "If you're unemployed or furloughed due to COVID-19, turn on email subscriptions to get updates about our <a href=\"https://opportunity.linkedin.com/\">global skills initiative</a> to help you get back to work.";
export const loc_gsi_profileSubscribeLabel = "Get updates about our global skills initiative";
export const loc_gsi_privacyStatement = "Yes, I agree to receive email updates about Microsoft Docs and other Microsoft products and services. <a href=\"https://go.microsoft.com/fwlink/?LinkId=521839\">Privacy Statement.</a>";
export const loc_gsi_optInForDocsEmailBody = "To receive updates about the global skills initiative, you’ll need to subscribe to emails from Docs. Don’t worry, you can always edit your notifications settings in your profile.";
export const loc_gsi_optInForDocsEmailTitle = "We noticed you have Microsoft Docs emails turned off";
export const loc_gsi_subscribeToEmailIfAffectedByCovid = "If you’re unemployed or furloughed due to COVID-19, subscribe to receive email updates about our global skills initiative to help you get back to work. You can opt out anytime in your profile settings.";
export const loc_gsi_yesIWouldLikeUpdates = "Yes, I'd like to receive updates";
export const loc_handsOnLab = "Hands-on Lab";
export const loc_headers = "Headers";
export const loc_helpMeChoose = "Help me choose";
export const loc_hideCompleted = "Hide completed";
export const loc_hideDetails = "Hide details";
export const loc_hint = "<strong>Hint:</strong> {hint}";
export const loc_highContrast = "High contrast";
export const loc_home = "Home";
export const loc_hour = "Hour";
export const loc_hours = "Hours";
export const loc_hoursAgo = "{duration} hours ago";
export const loc_howCanWeImprove = "How can we improve?";
export const loc_howToGuide = "How-To Guide";
export const loc_hr = "hr";
export const loc_hrMin = "{numberOfHours} hr {numberOfMinutes} min";
export const loc_implements = "Implements";
export const loc_important = "Important";
export const loc_impressum = "Impressum";
export const loc_incorrectAnswer = "Incorrect answer";
export const loc_inHeader = "In";
export const loc_inheritance = "Inheritance";
export const loc_inheritedFrom = "Inherited from";
export const loc_inheritedMembers = "Inherited Members";
export const loc_inProgress = "In progress";
export const loc_inProgressModules = "In progress modules";
export const loc_inProgressLearningPaths = "Learning paths with in progress modules";
export const loc_input = "Input";
export const loc_inputs = "Inputs";
export const loc_instant = "Instant";
export const loc_instructorLedCourses = "Instructor led courses";
export const loc_instructorLedPaid = "Instructor-led - Paid";
export const loc_instructorLedTraining = "Instructor-led training";
export const loc_inSupport = "In Support";
export const loc_interface = "Interface";
export const loc_interfacesInSubtitle = "Interfaces";
export const loc_intermediate = "Intermediate";
export const loc_internalUseOnly = "This API supports the product infrastructure and is not intended to be used directly from your code.";
export const loc_inThisArticle = "In this article";
export const loc_intuneFeedback = "Intune Feedback";
export const loc_invalidEmailAddressPleaseTryAgain = "Invalid email address. Please try again.";
export const loc_isolatedPrivacyTrademarkLinksTooltip = "This link can be accessed from the internet but not from disconnected or air-gapped cloud services.";
export const loc_isolatedMessageSandboxLod = "This exercise requires a feature which is not present in this network. The exercise cannot be completed exactly as written but can possibly be performed by changing some of the steps. Please check <a href=\"{subscriptionLink}\">this document</a> for details on the types of changes you will need to make to go through this exercise. Alternatively, you can read through the instructions to understand the steps.";
export const loc_issueProduct = "Report a product issue";
export const loc_isThisHelpful = "Is this page helpful?";
export const loc_itemDeleteConfirmationMessageFormat = "\"{itemTitle}\" will be permanently deleted.";
export const loc_itPro = "IT Pro";
export const loc_jobsAvailableFormat = "Microsoft certified professionals are in high demand. There are jobs available for {typeRole} right now.";
export const loc_jobRole = "Job role:";
export const loc_jobRoles = "Job roles:";
export const loc_joinNow = "Join now";
export const loc_justNow = "just now";
export const loc_key = "Key:";
export const loc_keyHeader = "Key";
export const loc_knowledgeCheck = "Knowledge check";
export const loc_knowledgeCheckCompleAllAnswers = "Knowledge check complete. You answered all questions correctly.";
export const loc_knowledgeCheckComplete = "Knowledge Check Complete";
export const loc_knowledgeCheckCompleted = "You have completed the {tutorial} knowledge check.";
export const loc_knowledgeCheckCompletedText = "You have completed the {tutorial} section of the module. Now you can move to the next section {nextUnitName}.";
export const loc_knowledgeCheckCouldNotGrade = "Oops, we could not grade your knowledge check. Please try again.";
export const loc_knowledgeCheckFinishSection = "You have finished this section. Continue on to the next part {nextUnitName}.";
export const loc_knowledgeCheckFinishSectionContinueMoreTutorials = "You have finished this section. Continue on to more tutorials.";
export const loc_knowledgeCheckFinishSectionModule = "By completing the knowledge check challenge, you have unlocked the {moduleName} achievement.";
export const loc_landmarkToc = "Table of contents";
export const loc_landmarkTocFilterFormLabel = "Filter table of contents";
export const loc_lang_arabic = "Arabic";
export const loc_lang_bokmal = "Norwegian Bokmål";
export const loc_lang_chineseSimplified = "Chinese (Simplified)";
export const loc_lang_chineseTraditional = "Chinese (Traditional)";
export const loc_lang_czech = "Czech";
export const loc_lang_danish = "Danish";
export const loc_lang_dutch = "Dutch";
export const loc_lang_english = "English";
export const loc_lang_finnish = "Finnish";
export const loc_lang_french = "French";
export const loc_lang_german = "German";
export const loc_lang_hebrew = "Hebrew";
export const loc_lang_hungarian = "Hungarian";
export const loc_lang_indonesian = "Indonesian";
export const loc_lang_italian = "Italian";
export const loc_lang_japanese = "Japanese";
export const loc_lang_korean = "Korean";
export const loc_lang_nynorsk = "Norwegian Nynorsk";
export const loc_lang_polish = "Polish";
export const loc_lang_portuguese = "Portuguese";
export const loc_lang_russian = "Russian";
export const loc_lang_spanish = "Spanish";
export const loc_lang_swedish = "Swedish";
export const loc_lang_thai = "Thai";
export const loc_lang_turkish = "Turkish";
export const loc_language = "Language";
export const loc_languages = "Languages";
export const loc_learn = "Learn";
export const loc_learnAtYourOwnPace = "Learn at your own pace";
export const loc_learnModule = "Learn Module";
export const loc_learnDisclaimer = "Explore Microsoft technology with live tutorials, including hands-on activities. This experience is in preview so please <a href=\"https://aka.ms/learningfeedback\">send us your feedback</a>!";
export const loc_learningpath = "Learning Path";
export const loc_learningPath = "Learning Path";
export const loc_learningPaths = "Learning Paths";
export const loc_learnmore = "Learn more";
export const loc_learnMoreAboutMVP = "Learn more about the MVP program";
export const loc_leaveAComment = "Leave a comment";
export const loc_level = "level";
export const loc_levelFormat = "Level {number}";
export const loc_levelDescription_advanced = "You have lots of experience and are looking to learn about specialized topics";
export const loc_levelDescription_beginner = "You're starting out and want to learn the fundamentals";
export const loc_levelDescription_intermediate = "You have some experience but want to learn more in-depth skills";
export const loc_levels = "Levels";
export const loc_light = "Light";
export const loc_limits = "Limits";
export const loc_linkedin = "LinkedIn";
export const loc_linkString = "Get parameter from response of";
export const loc_linkWithPropertyString = "Get parameter from response property <code>{linkedProperty}</code> of";
export const loc_listing = "Listing";
export const loc_loading = "Loading...";
export const loc_loadingSandboxInformation = "Loading sandbox information...";
export const loc_loadMore = "More&#8230;";
export const loc_loadMoreResults = "Load more results";
export const loc_locales_ar = "Arabic";
export const loc_locales_ar_sa = "Arabic (Saudi Arabia)";
export const loc_locales_bg = "Bulgarian";
export const loc_locales_bg_bg = "Bulgarian (Bulgaria)";
export const loc_locales_de = "German";
export const loc_locales_el = "Greek";
export const loc_locales_el_gr = "Greek (Greece)";
export const loc_locales_en = "English";
export const loc_locales_en_my = "English (Malaysia)";
export const loc_locales_es = "Spanish";
export const loc_locales_es_mx = "Spanish (Mexico)";
export const loc_locales_fr = "French";
export const loc_locales_hu = "Hungarian";
export const loc_locales_hu_hu = "Hungarian (Hungary)";
export const loc_locales_id = "Indonesian";
export const loc_locales_id_id = "Indonesian (Indonesia)";
export const loc_locales_it = "Italian";
export const loc_locales_ja = "Japanese";
export const loc_locales_ko = "Korean";
export const loc_locales_nb = "Norwegian";
export const loc_locales_nl = "Dutch";
export const loc_locales_pl = "Polish";
export const loc_locales_pt = "Portuguese";
export const loc_locales_pt_br = "Portuguese (Brazil)";
export const loc_locales_ro = "Romanian";
export const loc_locales_ru = "Russian";
export const loc_locales_th = "Thai";
export const loc_locales_tr = "Turkish";
export const loc_locales_vi = "Vietnamese";
export const loc_locales_vi_vn = "Vietnamese (Vietnam)";
export const loc_locales_zh = "Chinese";
export const loc_locales_zh_cn = "Chinese (Simplified)";
export const loc_locales_zh_tw = "Chinese (Traditional)";
export const loc_login = "Login";
export const loc_loginAzure = "Please login with your Azure account.";
export const loc_mainNavigation = "Main Navigation";
export const loc_manageAppointmentsCertificatesAndTranscripts = "Manage appointments, certificates and transcripts.";
export const loc_manageEmailPreferences = "Manage email preferences";
export const loc_manageMyProfile = "Manage my profile";
export const loc_mapView = "Map view";
export const loc_mavenArtifact = "Maven Artifact";
export const loc_maximize = "Maximize";
export const loc_memberDetails = "Member Details";
export const loc_members = "Members";
export const loc_menu = "Menu";
export const loc_method = "Method";
export const loc_methodDetails = "Method Details";
export const loc_methodsInSubtitle = "Methods";
export const loc_microsoftEducation = "Microsoft Education";
export const loc_microsoftEmployee = "Microsoft Employee";
export const loc_microsoftEmployeePrivacyNotice = "Microsoft Employee Privacy Notice";
export const loc_microsoftEmployeePrivacyStatement = "You have signed in with an account associated to an organization ({email}). By becoming a member, <strong>you understand and acknowledge</strong> that your Microsoft Docs membership means our network of sites and applications will record, and track your achievements and activities and we may share your results and activities with your organization. Additionally, your username, display name, profile picture, affiliation, achievements and activities will be publicly visible.";
export const loc_microsoftGraphEditor = "Microsoft graph editor";
export const loc_microsoftDocsWillSendEmailToYou = "Microsoft Docs will send email to you at the email you provide below.";
export const loc_microsoftPrivacyNotice = "Microsoft Privacy Notice";
export const loc_microsoftPrivacyNoticeAgreementStatement = "You need to agree with the Microsoft Privacy Notice to keep your profile and relating data.";
export const loc_middleEast = "Middle East";
export const loc_min = "min";
export const loc_minFormat = "{numberOfMinutes} min";
export const loc_minimize = "Minimize";
export const loc_minimumSupportedOS = "Minimum supported OS";
export const loc_minutePlural = "{numberOfMinutes} minutes";
export const loc_minutesToRead = "{numberOfMinutes} minutes to read";
export const loc_minutesAgo = "{duration} minutes ago";
export const loc_minuteSingular = "1 minute";
export const loc_moderation = "Moderation";
export const loc_module = "Module";
export const loc_moduleComplete = "Module complete";
export const loc_moduleIncomplete = "Module incomplete";
export const loc_moduleReference = "{platformName} Module Reference";
export const loc_moduleRequiresSandbox = "This module requires a sandbox to complete.";
export const loc_modules = "Modules";
export const loc_modulesInPath = "Modules in this learning path";
export const loc_monikerFallback = "The requested page is not available for {moniker}. You have been redirected to the newest product version this page is available for.";
export const loc_monthsAgo = "{duration} months ago";
export const loc_more = "More";
export const loc_moreActions = "More actions";
export const loc_moreTutorials = "More tutorials";
export const loc_msLearn = "Microsoft Learn";
export const loc_myProfile = "My profile";
export const loc_name = "Name";
export const loc_namespace = "Namespace";
export const loc_namespaces = "Namespaces";
export const loc_never = "Never";
export const loc_next = "Next";
export const loc_nextPage = "Next page";
export const loc_nextUnit = "Next unit";
export const loc_no = "No";
export const loc_newCollection = "New collection";
export const loc_noClosed = "There are no closed issues";
export const loc_noCurrentCoursesAvailableForCertification = "No current courses available for this certification";
export const loc_noCurrentCoursesAvailableForExam = "No current courses available for this exam";
export const loc_noLearningPathsAvailableForCertification = "Learning paths are not yet available for this certification";
export const loc_noLearningPathsAvailableForExam = "Learning paths are not yet available for this exam";
export const loc_none = "none";
export const loc_noOpen = "There are no open issues";
export const loc_noOutput = "No output";
export const loc_noResults = "No results";
export const loc_noResultsFound = "No results found";
export const loc_noResultsRest = "No results yet, but keep typing…";
export const loc_noResultsToc = "No results for {term}<br /><a href=\"{url}\">Search for \"{filter-text}\" in all {scope} documentation</a>";
export const loc_noThanks = "No, thanks";
export const loc_noValue = "No value";
export const loc_note = "Note";
export const loc_notes = "Notes";
export const loc_notesToCallers = "Notes to Callers";
export const loc_notesToImplementers = "Notes to Implementers";
export const loc_notesToInheritors = "Notes to Inheritors";
export const loc_notSureYet = "I'm not sure yet";
export const loc_officeDocs = "Office Documentation";
export const loc_offLabel = "Off";
export const loc_ok = "OK";
export const loc_okGotIt = "OK, got it.";
export const loc_onboardingExperience_confirmationPromptBrowseRecommendation = "Browse your recommendations";
export const loc_onboardingExperience_confirmationPromptSignin = "Sign in to save your recommendations.";
export const loc_onboardingExperience_confirmationPromptTitle = "We've created some recommendations for learning paths and modules based on your answers.";
export const loc_onboardingExperience_customizeMyPath = "Customize my path";
export const loc_onboardingExperience_editYourInterests = "Edit your interests";
export const loc_onboardingExperience_levelPromptTitle = "What is your skill level?";
export const loc_onboardingExperience_pleaseEditYourInterests = "Please edit your interests";
export const loc_onboardingExperience_productPromptTitle = "Which products are you interested in?";
export const loc_onboardingExperience_promptForASelectionOrSkip = "Please make at least one selection";
export const loc_onboardingExperience_rolePromptSubText = "Tell us about yourself and your interests and we'll recommend some options to deepen your knowledge.";
export const loc_onboardingExperience_rolePromptTitle = "What roles are you interested in?";
export const loc_onboardingExperience_selectLevel = "Select your level";
export const loc_onboardingExperience_selectProducts = "Select products you're interested in";
export const loc_onboardingExperience_selectRole = "Select roles you're interested in";
export const loc_onboardingExperience_zeroTrendingContentDescription = "We don't currently have anything that fits your selections.";
export const loc_oneModule = "1 Module";
export const loc_oneUnit = "1 Unit";
export const loc_onLabel = "On";
export const loc_onlineFree = "Online - Free";
export const loc_onSpecificDate = "on {date}";
export const loc_openIssue = "Open Issue";
export const loc_openIssues = "Open Issues";
export const loc_operationId = "Operation ID:";
export const loc_operations = "Operations";
export const loc_operator = "Operator";
export const loc_operatorsInSubtitle = "Operators";
export const loc_iAgree = "I agree";
export const loc_selectACountryRegion = "Select a country/region";
export const loc_selectOneCheckboxBeforeProceeding = "Please select at least one checkbox before proceeding.";
export const loc_servers = "Servers";
export const loc_skillsGained = "Skills gained";
export const loc_skillsMeasured = "Skills measured";
export const loc_speakersBureau_azureTrainingDay = "Microsoft Azure Training Day";
export const loc_speakersBureau_buildingApplicationsForCloud = "Building Applications for the Cloud";
export const loc_speakersBureau_deliveringDataWareHouse = "Delivering the Modern Data Warehouse";
export const loc_speakersBureau_deliveringDataWareHouseDescription = "This event covers advanced (level 300) content, technologies include: Azure Data Factory, Azure Databricks, Azure SQL Data Warehouse, Azure Data Lake Storage and Power BI.  Attendees will learn how these technologies can be used together to build a modern data warehouse. They will learn to automate the movement of data in various formats gathered from various sources, then prepare and analyze the data, and finally write the aggregations to the data warehouse.";
export const loc_speakersBureau_deployingWindowsOffice = "Deploying and managing Windows and Office in your enterprise";
export const loc_speakersBureau_deployingWindowsOfficeDevices = "Deploying, Managing and Servicing Windows, Office and All Your Devices";
export const loc_speakersBureau_deployingWindowsOfficeDevicesDescription = "This event covers a broad level of content from intermediate to advanced (level 200-400), technologies include: Windows 10, Office 365 ProPlus, Deployment & services, Intune/SCCM.  Attendees will learn how to transition to a modern, productive environment—and simplify the process of keeping desktop and mobile devices secure and up to date with Microsoft Intune and System Center Configuration Manager.";
export const loc_speakersBureau_developingCloudNativeApplications = "Developing Cloud Native Applications";
export const loc_speakersBureau_developingCloudNativeApplicationsDescription = "This event covers advanced (level 300) content, technologies include: Visual Studio 2019, Visual Studio Code, Azure CLI, Terminal, ARM Templates, Virtual Machine Scale Sets, Azure Container Instances, Azure Kubernetes Service, Web Application Services, Azure Blob Static Websites, Azure Key Vault, Xamarin, Azure SQL, Azure Search,  CosmosDB, Cloud Shell, Docker Build, Web Application Containers, Helm, Deploy from KubeApps, Azure Monitor, Azure SQL Query, Performance Insights, Azure Front Door.";
export const loc_speakersBureau_developersGuideToAi = "Developers Guide to AI";
export const loc_speakersBureau_developersGuideToAiDescription = "This event covers advanced (level 300) content, technologies include: Azure Cognitive Services, Azure Cognitive Search, Power BI, Azure Notebooks, Azure Machine Learning Visual Interface, Jupyter, Visual Studio Code, Automated ML, ML Compute (GPU), Azure DevOps.";
export const loc_speakersBureau_dynamicsTrainingDay = "Microsoft Dynamics 365 Training Day";
export const loc_speakersBureau_dynamicsTrainingDayDescription = "This event covers intermediate (level 200) content and is intended for those new to Dynamics 365 or looking to update their knowledge of:  D365 Sales, Marketing, Customer Service, Field Service, Finance, and Retail.  D365 functionality has grown beyond the typical CRM and ERP requirements enabling organizations to gain efficiencies of scale and providing IT Professionals with the ability to expand their technical skills using the newest technologies including AI, Mixed Reality, and IoT.";
export const loc_speakersBureau_envisionForum = "Microsoft Envision Forum";
export const loc_speakersBureau_envisionForumDescription = "Microsoft Envision Forum targets the Business Decision Maker (BDM) audience.  This event unpacks leading trends, challenges, and opportunities, including thought leadership sessions focused on innovation and transformation, breakout sessions that dive into real-world examples of how business leaders are embracing digital technologies, and networking opportunities with industry leaders. Customers will hear directly from experts and peers and walk away ready to build their transformation plan.";
export const loc_speakersBureau_eventTracks = "Events";
export const loc_speakersBureau_financialServices = "Financial Services";
export const loc_speakersBureau_fundamentals = "Fundamentals";
export const loc_speakersBureau_fundamentalsDescription = "This event covers foundational level knowledge of cloud services provided with Microsoft Azure. This 1-day introduction explains cloud computing concepts, models and services such as Public, Private and Hybrid cloud and Infrastructure-as-a-Service (IaaS), Platform-as-a-Service (PaaS) and Software-as-a-Service (SaaS). It also covers how Azure supports security, privacy, compliance, and trust, and prepares attendees for the Microsoft Azure Fundamentals certification.";
export const loc_speakersBureau_government = "Government";
export const loc_speakersBureau_healthcare = "Healthcare";
export const loc_speakersBureau_improvingReliability = "Improving Reliability Through Modern Operations Practices";
export const loc_speakersBureau_improvingReliabilityDescription = "This event covers advanced (level 300) content, technologies include: Azure Monitor, Azure Log Analytics, Azure Data Explorer, Microsoft Teams, Logic Apps, Azure Service Health, Azure Workbooks, Azure Service Alerts, Github Actions, Azure Pipelines, ARM Templates, Azure Auto-Scaling, Azure Cost Analysis Tools, Power BI.";
export const loc_speakersBureau_interestedTechnologyAreas = "Select all of the Product area(s) of expertise";
export const loc_speakersBureau_journeyToMicrosoftTeams = "Journey to Microsoft Teams";
export const loc_speakersBureau_journeyToMicrosoftTeamsDescription = "This event covers intermediate (200 Level) content, technologies include: End user value, Administering Microsoft Teams, Voice & Meetings, Integrations  During this event attendees will learn how to build a collaborative workforce for their organization, bringing together everything in a shared workspace where they can chat, meet, share files, and integrate business applications.";
export const loc_speakersBureau_languagesToDeliverSessions = "Languages";
export const loc_speakersBureau_managingCloudOperations = "Managing Cloud Operations";
export const loc_speakersBureau_managingCloudOperationsDescription = "This event covers advanced (level 300) content, technologies include: Azure Policy, Azure Blueprints, Role Based Access Control, Subscription Management Virtual Machines, Virtual Machine Extensions, Configuration Management, Inventory and Change Tracking, Virtual Machine / Backup and Recovery, Azure Active Directory.";
export const loc_speakersBureau_manufacturing = "Manufacturing";
export const loc_speakersBureau_mct = "MCT";
export const loc_speakersBureau_mctDescription = "Microsoft Certified Trainer (MCT)";
export const loc_speakersBureau_mediaAndEntertainment = "Media and Entertainment";
export const loc_speakersBureau_meetingCompliance = "Meeting Organizational Compliance Requirements";
export const loc_speakersBureau_meetingComplianceDescription = "This event covers intermediate to advanced (level 200-300) content, technologies include:  Data classification, labeling, governance, policy violation remediation, eDiscovery, audit, risk assessment.  Attendees will learn to leverage intelligent and integrated Microsoft solutions to help their organization achieve its compliance goals.";
export const loc_speakersBureau_microsoft365TrainingDay = "Microsoft 365 Training Day";
export const loc_speakersBureau_migratingServerInfrastructure = "Migrating Server Infrastructure";
export const loc_speakersBureau_migratingServerInfrastructureDescription = "The event covers advanced (level 300) content, technologies include: Windows Server 2008 R2, DHCP, Windows Server 2019, Virtual Machines, Windows Server 2008 Data Center, DNS, Azure Active Directory, Windows Server 2008 File Server, Windows Server Migration Toolkit, Windows Admin Center, Azure File Sync, Azure AD Password Protector, Windows Defender ATP, Azure Backup, Cloud Witness, Azure Network Adapter, SQL Server 2019, VPN, Express Route, Azure Site Recovery, Azure Migrate, Hyper-V Replica.";
export const loc_speakersBureau_modernizingDataApplications = "Modernizing Data, Applications, and APIs to the Cloud";
export const loc_speakersBureau_modernizingWebApplications = "Modernizing Web Applications and Data";
export const loc_speakersBureau_modernizingWebApplicationsDescription = "This event covers advanced (level 300) content, technologies include: ARM Templates, Virtual Networks, Virtual Machines, Web Application Services, Image Management, Virtual Machine Scale Sets, Cloud Shell, Azure SQL, CosmosDB, Azure SQL Managed Instance, PostgresSQL, Azure Storage Explorer, Logic Apps, Azure API, Azure AI, Azure Machine Learning, Xamarin, Azure Cognitive Services, Debug Logs, Live Log Streaming, Deployment Slots, Snapshot Debugging, Github Actions, Azure DevOps.";
export const loc_speakersBureau_multiTopic = "Multi Topic";
export const loc_speakersBureau_org = "Org";
export const loc_speakersBureau_selectALearningPath = "Select a Learning Path";
export const loc_speakersBureau_powerPlatformTrainingDay = "Microsoft Power Platform Training Day";
export const loc_speakersBureau_powerPlatformTrainingDayDescription = "This event covers the all the information needed to build and manage innovative business solutions and is open to everyone, no matter their technical expertise: from the citizen dev, to the IT Pro/Admin, data analyst or Professional Developer. Attendees will discover how to easily connect all their data to analyze real-time business performance, act on data and insights with custom built apps, and automate workflows to continuously improve the processes they work on every day.";
export const loc_speakersBureau_retail = "Retail";
export const loc_speakersBureau_role = "Role";
export const loc_speakersBureau_securingYourOrganization = "Securing Your Organization";
export const loc_speakersBureau_securingYourOrganizationDescription = "This event covers intermediate to advanced (level 200-300) content, technologies include: Threat Protection, Information Protection, Identify and Access Management, Security Management.  Attendees will learn how to protect their organization’s identities, data, applications, and devices across on-premises, cloud, and mobile - end to-end using the latest tools and guidance.";
export const loc_speakersBureau_selectAnOrg = "Select an Org";
export const loc_speakersBureau_selectARole = "Select a Role";
export const loc_speakersBureau_speakerBio = "Speaker Bio";
export const loc_speakersBureau_speakerBureauForm = "Speaker Bureau Profile";
export const loc_speakersBureau_speakerDescription = "Speaker Description";
export const loc_speakersBureau_telecommunications = "Telecommunications";
export const loc_speakersBureau_trainingDays = "Microsoft Training Days";
export const loc_speakersBureau_travel = "Travel";
export const loc_speakersBureau_travelOption = "Available for Travel (T&E is self-funded)";
export const loc_speakersBureau_travelDistance = "Travel Range (Kilometers)";
export const loc_speakersBureau_learningPathsPresenting = "Select all of the <a href=\"https://docs.microsoft.com/learn/\">Learning Paths</a> that you are comfortable presenting";
export const loc_speakersBureau_videoLinkShowcasing = "Provide a link to a video showcasing your presentation skills";
export const loc_optInOptOutEmailPrivacyPolicy = "I would like information, tips, and offers about the Microsoft Docs site and other Microsoft products and services. <a href=\"https://go.microsoft.com/fwlink/?LinkId=521839\">Privacy Statement.</a>";
export const loc_optional = "Optional";
export const loc_optionalCertification = "Optional certification";
export const loc_optionalCertificationPrereqDescriptionFormat = "Highly recommended but optional prerequisites for {certTitle}.";
export const loc_optionalCertifications = "Optional certifications";
export const loc_optionalParameters = "Optional Parameters";
export const loc_options = "Options";
export const loc_or = "or";
export const loc_other = "Other";
export const loc_output = "Output";
export const loc_outputs = "Outputs";
export const loc_overloads = "Overloads";
export const loc_overrides = "Overrides";
export const loc_overview = "Overview";
export const loc_pacific = "Pacific";
export const loc_package = "Package";
export const loc_packageReference = "{platformName} Package Reference";
export const loc_packages = "Packages";
export const loc_page = "Page {pageNumber}";
export const loc_pageOfPages = "Page {pageNumber} of {numberOfPages}";
export const loc_pageActions = "Page Actions";
export const loc_pagination = "pagination";
export const loc_parameters = "Parameters";
export const loc_path = "Path:";
export const loc_pathHeader = "Path";
export const loc_phoneNumber = "Phone number";
export const loc_platform = "Platform";
export const loc_pleaseFillOut = "Please fill out this field.";
export const loc_pleaseProvideAnEmailAddress = "Please provide an email address.";
export const loc_pleaseTryAgain = "Please try again.";
export const loc_primary = "Primary";
export const loc_poor = "Poor";
export const loc_portallab_disabled = "We're sorry. The lab environment needed to complete the exercises in this unit is temporarily unavailable.";
export const loc_portallab_labAccessSuspended = "Lab access suspended";
export const loc_portallab_labAppeal = "Your account or connection was flagged for unusual activity. Because of this we've suspended your access to the Learn lab on demand. If you think we've made an error, you can submit an appeal to regain access.";
export const loc_portallab_launchButton = "Launch lab";
export const loc_portallab_loading = "Loading lab...";
export const loc_portallab_modalCloseTitle = "Lab is complete";
export const loc_portallab_modalCloseDescription = "We'll take you to where you left off.";
export const loc_portallab_prompt1 = "This unit includes a lab to complete.";
export const loc_portallab_prompt2 = "Use the free resources provided in the lab to complete the exercises in this unit. You will not be charged.";
export const loc_portallab_ready = "Lab will launch in a new window. Temporary credentials will be provided to log into the lab environment.";
export const loc_portallab_releaseButton = "Yes, start a new lab";
export const loc_portallab_releasePrompt1 = "A lab for a different unit is already active";
export const loc_portallab_releasePrompt2 = "You currently have a lab open for another unit ({lab-name}). Would you like to discard your pre-existing lab and start a new lab for this unit?";
export const loc_portallab_retryButton = "Retry launching lab";
export const loc_portallab_signInButton = "Sign in to launch the lab";
export const loc_portallab_startButton = "Start lab";
export const loc_post = "Post";
export const loc_postalCode = "Postal code/Zip code";
export const loc_preparationForExam = "Preparation for exam:";
export const loc_preparationForExams = "Preparation for exams:";
export const loc_prerequisite = "Prerequisite";
export const loc_prerequisiteOptionDescFormat = "<strong>Option {number}:</strong> Prerequisite certification";
export const loc_prerequisites = "Prerequisites";
export const loc_preview = "Preview";
export const loc_previewTypescript = "This API is provided as a preview for developers and may change based on feedback that we receive. Do not use this API in a production environment.";
export const loc_previous = "Previous";
export const loc_previousPage = "Previous page";
export const loc_previousTutorialStep = "Previous tutorial step";
export const loc_previousVersionDocs = "Previous Version Docs";
export const loc_privacyAndCookies = "Privacy &amp; Cookies";
export const loc_privacystatement = "Privacy Statement";
export const loc_product = "Product";
export const loc_productFeedbackPrompt = "To submit product feedback, please visit";
export const loc_products = "Products";
export const loc_products_ai_builder = "AI Builder";
export const loc_products_aspnet = "ASP.NET";
export const loc_products_aspnet_core = "ASP.NET Core";
export const loc_products_azure = "Azure";
export const loc_products_azure_active_directory = "Azure Active Directory";
export const loc_products_azure_active_directory_b2c = "Active Directory B2C";
export const loc_products_azure_active_directory_domain = "Active Directory Domain Services";
export const loc_products_azure_advisor = "Azure Advisor";
export const loc_products_azure_analysis_services = "Analysis Services";
export const loc_products_azure_anomaly_detector = "Anomaly Detector";
export const loc_products_azure_api_apps = "API Apps";
export const loc_products_azure_api_fhir = "API for FHIR";
export const loc_products_azure_api_management = "API Management";
export const loc_products_azure_app_configuration = "App Configuration";
export const loc_products_azure_app_service = "App Service";
export const loc_products_azure_app_service_mobile = "App Service - Mobile Apps";
export const loc_products_azure_app_service_static = "App Service Static Web Apps";
export const loc_products_azure_app_service_web = "App Service - Web Apps";
export const loc_products_azure_application_gateway = "Application Gateway";
export const loc_products_azure_application_insights = "Application Insights";
export const loc_products_azure_arc = "Arc";
export const loc_products_azure_archive_storage = "Archive Storage";
export const loc_products_azure_artifacts = "Artifacts";
export const loc_products_azure_automation = "Automation";
export const loc_products_azure_avere_vFXT = "Avere vFXT";
export const loc_products_azure_live_video_analytics = "Live Video Analytics";
export const loc_products_azure_backup = "Backup";
export const loc_products_azure_bastion = "Bastion";
export const loc_products_azure_batch = "Batch";
export const loc_products_azure_bing_autosuggest = "Bing Autosuggest";
export const loc_products_azure_bing_custom = "Bing Custom Search";
export const loc_products_azure_bing_entity = "Bing Entity Search";
export const loc_products_azure_bing_image = "Bing Image Search";
export const loc_products_azure_bing_news = "Bing News Search";
export const loc_products_azure_bing_spellcheck = "Bing Spell Check";
export const loc_products_azure_bing_video = "Bing Video Search";
export const loc_products_azure_bing_visual = "Bing Visual Search";
export const loc_products_azure_bing_web = "Bing Web Search";
export const loc_products_azure_blob_storage = "Blob Storage";
export const loc_products_azure_blockchain_tokens = "Blockchain tokens";
export const loc_products_azure_blockchain_service = "Blockchain Service";
export const loc_products_azure_blockchain_workbench = "Blockchain Workbench";
export const loc_products_azure_blueprints = "Blueprints";
export const loc_products_azure_boards = "Boards";
export const loc_products_azure_bot_service = "Bot Service";
export const loc_products_azure_cache_redis = "Cache for Redis";
export const loc_products_azure_cdn = "Content Delivery Network";
export const loc_products_azure_clis = "CLIs";
export const loc_products_azure_cloud_services = "Cloud Services";
export const loc_products_azure_cloud_shell = "Cloud Shell";
export const loc_products_azure_cognitive_search = "Cognitive Search";
export const loc_products_azure_cognitive_services = "Cognitive Services";
export const loc_products_azure_computer_vision = "Computer Vision";
export const loc_products_azure_container_instances = "Container Instances";
export const loc_products_azure_container_registry = "Container Registry";
export const loc_products_azure_content_moderator = "Content Moderator";
export const loc_products_azure_content_protection = "Content Protection";
export const loc_products_azure_cosmos_db = "Cosmos DB";
export const loc_products_azure_cost_management = "Cost Management";
export const loc_products_azure_custom_vision = "Custom Vision";
export const loc_products_azure_cyclecloud = "CycleCloud";
export const loc_products_azure_database_mariadb = "Database for MariaDB";
export const loc_products_azure_database_migration = "Database Migration service";
export const loc_products_azure_database_mysql = "Database for MySQL";
export const loc_products_azure_database_postgresql = "Database for PostgreSQL";
export const loc_products_azure_data_box_family = "Data Box Family";
export const loc_products_azure_data_catalog = "Data Catalog";
export const loc_products_azure_data_explorer = "Data Explorer";
export const loc_products_azure_data_factory = "Data Factory";
export const loc_products_azure_data_lake = "Data Lake";
export const loc_products_azure_data_lake_analytics = "Data Lake Analytics";
export const loc_products_azure_data_lake_gen1 = "Data Lake Storage Gen1";
export const loc_products_azure_data_lake_gen2 = "Data Lake Storage Gen2";
export const loc_products_azure_data_lake_storage = "Data Lake Storage";
export const loc_products_azure_data_science_vm = "Data Science Virtual Machines";
export const loc_products_azure_data_share = "Data Share";
export const loc_products_azure_databricks = "Databricks";
export const loc_products_azure_devops = "Azure DevOps";
export const loc_products_azure_dev_spaces = "Azure Dev Spaces";
export const loc_products_azure_digital_twins = "Digital Twins";
export const loc_products_azure_ddos_protection = "DDos Protection";
export const loc_products_azure_dedicated_host = "Dedicated Host";
export const loc_products_azure_dedicated_hsm = "Dedicated HSM";
export const loc_products_azure_devops_tool_integrations = "DevOps tool integrations";
export const loc_products_azure_devtest_labs = "DevTest Labs";
export const loc_products_azure_dev_tool_integrations = "Developer tool integrations";
export const loc_products_azure_disk_encryption = "Disk Encryption";
export const loc_products_azure_disk_storage = "Disk Storage";
export const loc_products_azure_dns = "DNS";
export const loc_products_azure_encoding = "Encoding";
export const loc_products_azure_event_grid = "Event Grid";
export const loc_products_azure_event_hubs = "Event Hubs";
export const loc_products_azure_expressroute = "ExpressRoute";
export const loc_products_azure_face = "Face";
export const loc_products_azure_farmbeats = "FarmBeats";
export const loc_products_azure_firewall = "Firewall";
export const loc_products_azure_firewall_manager = "Firewall Manager";
export const loc_products_azure_form_recognizer = "Form Recognizer";
export const loc_products_azure_front_door = "Front Door";
export const loc_products_azure_fxt_edge_filer = "FXT Edge Filer";
export const loc_products_azure_files = "Files";
export const loc_products_azure_functions = "Functions";
export const loc_products_azure_genomics = "Microsoft Genomics";
export const loc_products_azure_hdinsight_rserver = "R Server for HDInsight";
export const loc_products_azure_hpc_cache = "HPC Cache";
export const loc_products_azure_hdinsight = "HDInsight";
export const loc_products_azure_immersive_reader = "Immersive Reader";
export const loc_products_azure_information_protection = "Information Protection";
export const loc_products_azure_ink_recognizer = "Ink Recognizer";
export const loc_products_azure_internet_analyzer = "Internet Analyzer";
export const loc_products_azure_iot = "IoT";
export const loc_products_azure_iot_central = "IoT Central";
export const loc_products_azure_iot_dps = "IoT Device Provisioning Service";
export const loc_products_azure_iot_edge = "IoT Edge";
export const loc_products_azure_iot_hub = "IoT Hub";
export const loc_products_azure_iot_pnp = "IoT Plug and Play";
export const loc_products_azure_iot_sdk = "IoT SDK";
export const loc_products_azure_iot_security_center = "Security Center for IoT";
export const loc_products_azure_iot_solution_accelerators = "IoT Solution Accelerators";
export const loc_products_azure_key_vault = "Key Vault";
export const loc_products_azure_kinect_dk = "Kinect DK";
export const loc_products_azure_kubernetes_service = "Kubernetes Service";
export const loc_products_azure_lab_services = "Lab Services";
export const loc_products_azure_language_understanding = "Language Understanding";
export const loc_products_azure_lighthouse = "Lighthouse";
export const loc_products_azure_linux_vm = "Linux Virtual Machines";
export const loc_products_azure_live_ondemand_streaming = "Live and On-Demand Streaming";
export const loc_products_azure_load_balancer = "Load Balancer";
export const loc_products_azure_log_analytics = "Log Analytics";
export const loc_products_azure_logic_apps = "Logic Apps";
export const loc_products_azure_machine_learning = "Machine Learning";
export const loc_products_azure_machine_learning_service = "Machine Learning Service";
export const loc_products_azure_machine_learning_studio = "Machine Learning Studio";
export const loc_products_azure_managed_applications = "Managed Applications";
export const loc_products_azure_managed_disks = "Managed Disks";
export const loc_products_azure_maps = "Maps";
export const loc_products_azure_media_analytics = "Media Analytics";
export const loc_products_azure_media_player = "Media Player";
export const loc_products_azure_media_services = "Media Services";
export const loc_products_azure_migrate = "Migrate";
export const loc_products_azure_mobile_apps = "Mobile Apps";
export const loc_products_azure_monitor = "Monitor";
export const loc_products_azure_netapp_files = "Azure NetApp Files";
export const loc_products_azure_network_watcher = "Network Watcher";
export const loc_products_azure_notebooks = "Azure Notebooks";
export const loc_products_azure_notification_hubs = "Notification Hubs";
export const loc_products_azure_open_datasets = "Open Datasets";
export const loc_products_azure_personalizer = "Personalizer";
export const loc_products_azure_pipelines = "Pipelines";
export const loc_products_azure_playfab = "Playfab";
export const loc_products_azure_policy = "Policy";
export const loc_products_azure_portal = "Azure Portal";
export const loc_products_azure_powerbi_embedded = "Power BI Embedded";
export const loc_products_azure_private_link = "Private Link";
export const loc_products_azure_qna_maker = "QnA Maker";
export const loc_products_azure_quantum = "Quantum";
export const loc_products_azure_queue_storage = "Queue Storage";
export const loc_products_azure_rbac = "Azure role-based access control";
export const loc_products_azure_redhat_openshift = "Red Hat OpenShift";
export const loc_products_azure_repos = "Repos";
export const loc_products_azure_remote_rendering = "Remote Rendering";
export const loc_products_azure_resource_graph = "Resource Graph";
export const loc_products_azure_resource_manager = "Azure Resource Manager";
export const loc_products_azure_rtos = "RTOS";
export const loc_products_azure_sap = "SAP HANA on Azure Large Instances";
export const loc_products_azure_search = "Search";
export const loc_products_azure_scheduler = "Scheduler";
export const loc_products_azure_sdks = "SDKs";
export const loc_products_azure_security_center = "Security Center";
export const loc_products_azure_sentinel = "Sentinel";
export const loc_products_azure_service_bus = "Service Bus";
export const loc_products_azure_service_fabric = "Service Fabric";
export const loc_products_azure_service_health = "Service Health";
export const loc_products_azure_signalr_service = "SignalR Service";
export const loc_products_azure_site_recovery = "Site Recovery";
export const loc_products_azure_sovereign_china = "Azure China 21Vianet";
export const loc_products_azure_sovereign_germany = "Azure Germany";
export const loc_products_azure_sovereign_us = "Azure US Government";
export const loc_products_azure_spatial_anchors = "Spatial Anchors";
export const loc_products_azure_speech = "Speech";
export const loc_products_azure_speaker_recognition = "Speaker Recognition";
export const loc_products_azure_speech_text = "Speech to Text";
export const loc_products_azure_speech_translation = "Speech Translation";
export const loc_products_azure_sphere = "Sphere";
export const loc_products_azure_spring_cloud = "Spring Cloud";
export const loc_products_azure_sql_data_warehouse = "SQL Data Warehouse";
export const loc_products_azure_sql_database = "SQL Database";
export const loc_products_azure_sql_edge = "SQL Edge";
export const loc_products_azure_sql_managed_instance = "SQL Managed Instance";
export const loc_products_azure_sql_virtual_machines = "SQL Virtual Machines";
export const loc_products_azure_sqlserver_stretchdb = "SQL Server Stretch Database";
export const loc_products_azure_sqlserver_vm = "SQL Server on Virtual Machines";
export const loc_products_azure_stack = "Azure Stack";
export const loc_products_azure_stack_edge = "Azure Stack Edge";
export const loc_products_azure_stack_hci = "Azure Stack HCI";
export const loc_products_azure_storage = "Storage";
export const loc_products_azure_storage_accounts = "Storage Accounts";
export const loc_products_azure_storage_explorer = "Storage Explorer";
export const loc_products_azure_storsimple = "StorSimple";
export const loc_products_azure_stream_analytics = "Stream Analytics";
export const loc_products_azure_synapse_analytics = "Synapse Analytics";
export const loc_products_azure_table_storage = "Table Storage";
export const loc_products_azure_test_plans = "Test Plans";
export const loc_products_azure_text_analytics = "Text Analytics";
export const loc_products_azure_text_speech = "Text to Speech";
export const loc_products_azure_time_series_insights = "Time Series Insights";
export const loc_products_azure_traffic_manager = "Traffic Manager";
export const loc_products_azure_translator = "Translator";
export const loc_products_azure_translator_speech = "Translator Speech";
export const loc_products_azure_translator_text = "Translator Text";
export const loc_products_azure_video_indexer = "Video Indexer";
export const loc_products_azure_virtual_wan = "Virtual WAN";
export const loc_products_azure_virtual_machines = "Virtual Machines";
export const loc_products_azure_virtual_machines_windows = "Windows Virtual Machines";
export const loc_products_azure_virtual_network = "Virtual Network";
export const loc_products_azure_vm_scalesets = "Virtual Machine Scale Sets";
export const loc_products_azure_vmware_solution = "Vmware Solution by CloudSimple";
export const loc_products_azure_vpn_gateway = "VPN Gateway";
export const loc_products_azure_webapp_containers = "Web App for Containers";
export const loc_products_azure_web_application_firewall = "Web Application Firewall";
export const loc_products_azure_web_apps = "Web Apps";
export const loc_products_common_data_service = "Common Data Service";
export const loc_products_consumer = "Consumer";
export const loc_products_connected_services_framework = "Microsoft Connected Services Framework";
export const loc_products_customer_care_framework = "Customer Care Framework";
export const loc_products_customer_voice = "Customer Voice";
export const loc_products_dotnet = ".NET";
export const loc_products_dotnet_core = ".NET Core";
export const loc_products_dotnet_standard = ".NET Standard";
export const loc_products_dynamics = "Dynamics";
export const loc_products_dynamics_365 = "Dynamics 365";
export const loc_products_dynamics_ai_market_insights = "AI for Market Insights";
export const loc_products_dynamics_ai_customer_service = "AI for Customer Service";
export const loc_products_dynamics_ai_sales = "AI for Sales";
export const loc_products_dynamics_business_central = "Business Central";
export const loc_products_dynamics_commerce = "Commerce";
export const loc_products_dynamics_customer_engagement = "Customer Engagement apps";
export const loc_products_dynamics_cust_insights = "Customer Insights";
export const loc_products_dynamics_customer_service = "Customer Service";
export const loc_products_dynamics_cust_svc_insights = "Customer Service Insights";
export const loc_products_dynamics_field_service = "Field Service";
export const loc_products_dynamics_finance = "Finance";
export const loc_products_dynamics_finance_operations = "Finance and Operations";
export const loc_products_dynamics_fraud_protection = "Fraud Protection";
export const loc_products_dynamics_guides = "Guides";
export const loc_products_dynamics_human_resources = "Human Resources";
export const loc_products_dynamics_layout = "Layout";
export const loc_products_dynamics_market_insights = "Market Insights";
export const loc_products_dynamics_marketing = "Marketing";
export const loc_products_dynamics_operations = "Operations";
export const loc_products_dynamics_prod_visualize = "Product Visualize";
export const loc_products_dynamics_product_insights = "Product Insights";
export const loc_products_dynamics_project_service = "Project Service Automation";
export const loc_products_dynamics_remote_assist = "Remote Assist";
export const loc_products_dynamics_retail = "Retail";
export const loc_products_dynamics_sales = "Sales";
export const loc_products_dynamics_sales_insights = "Sales Insights";
export const loc_products_dynamics_scm = "Supply Chain Management";
export const loc_products_dynamics_talent = "Talent";
export const loc_products_dynamics_talent_attract = "Talent Attract";
export const loc_products_dynamics_talent_onboard = "Talent Onboard";
export const loc_products_dynamics_talent_core = "Talent Core";
export const loc_products_ef_core = "Entity Framework Core";
export const loc_products_expression = "Expression";
export const loc_products_expression_studio = "Expression Studio";
export const loc_products_forms_pro = "Forms Pro";
export const loc_products_github = "GitHub";
export const loc_products_internet_explorer = "Internet Explorer";
export const loc_products_m365 = "Microsoft 365";
export const loc_products_m365_ems = "Enterprise Mobility + Security";
export const loc_products_m365_ems_cloud_app_security = "Cloud App Security";
export const loc_products_m365_ems_configuration_manager = "System Center Configuration Manager";
export const loc_products_m365_ems_intune = "Intune";
export const loc_products_m365_information_protection = "Information Protection";
export const loc_products_m365_security_center = "Security Center";
export const loc_products_m365_security_score = "Security Score";
export const loc_products_m365_threat_protection = "Threat Protection";
export const loc_products_m365_workplace_analytics = "Workplace Analytics";
export const loc_products_mdatp = "Microsoft Defender ATP";
export const loc_products_mlnet = "ML.NET";
export const loc_products_office_365 = "Office 365";
export const loc_products_microsoft_edge = "Microsoft Edge";
export const loc_products_microsoft_servers = "Microsoft Servers";
export const loc_products_ms_graph = "Microsoft Graph";
export const loc_products_msc = "Microsoft System Center";
export const loc_products_msc_configuration_manager = "Configuration Manager";
export const loc_products_msc_operations_manager = "Operations Manager";
export const loc_products_msc_service_manager = "Service Manager";
export const loc_products_nuget = "NuGet";
export const loc_products_office = "Office";
export const loc_products_office_365_atp = "Advanced Threat Protection";
export const loc_products_office_access = "Access";
export const loc_products_office_adaptive_cards = "Adaptive Cards";
export const loc_products_office_add_ins = "Office Add-ins";
export const loc_products_office_bookings = "Bookings";
export const loc_products_office_excel = "Excel";
export const loc_products_office_exchange_server = "Exchange Server";
export const loc_products_office_forefront = "Forefront";
export const loc_products_office_kaizala = "Kaizala";
export const loc_products_office_lync_server = "Lync Server";
export const loc_products_office_onedrive = "OneDrive";
export const loc_products_office_onenote = "OneNote";
export const loc_products_office_outlook = "Outlook";
export const loc_products_office_planner = "Planner";
export const loc_products_office_powerpoint = "PowerPoint";
export const loc_products_office_project = "Project";
export const loc_products_office_project_server = "Project Server";
export const loc_products_office_publisher = "Publisher";
export const loc_products_office_skype_business = "Skype for Business";
export const loc_products_office_sp = "SharePoint";
export const loc_products_office_sp_designer = "SharePoint Designer";
export const loc_products_office_sp_framework = "SharePoint Framework";
export const loc_products_office_sp_server = "SharePoint Server";
export const loc_products_office_teams = "Teams";
export const loc_products_office_ui_fabric = "Office UI Fabric";
export const loc_products_office_visio = "Visio";
export const loc_products_office_word = "Word";
export const loc_products_office_yammer = "Yammer";
export const loc_products_power_automate = "Power Automate";
export const loc_products_power_bi = "Power BI";
export const loc_products_power_apps = "Power Apps";
export const loc_products_powerapps = "Power Apps";
export const loc_products_powershell = "PowerShell";
export const loc_products_power_platform = "Power Platform";
export const loc_products_power_platform_power_query = "Power Query";
export const loc_products_power_virtual_agents = "Power Virtual Agents";
export const loc_products_project_acoustics = "Project Acoustics";
export const loc_products_qdk = "Quantum Development Kit";
export const loc_products_silverlight = "Silverlight";
export const loc_products_skype = "Skype";
export const loc_products_sql_server = "SQL Server";
export const loc_products_vs = "Visual Studio";
export const loc_products_vs_app_center = "App Center";
export const loc_products_vs_code = "Visual Studio Code";
export const loc_products_vs_mac = "Visual Studio for Mac";
export const loc_products_vs_online = "Visual Studio Online";
export const loc_products_windows = "Windows";
export const loc_products_windows_api_win32 = "Windows API - Win32";
export const loc_products_windows_azure_pack = "Windows Azure Pack";
export const loc_products_windows_forms = "Windows Forms";
export const loc_products_windows_iot = "Windows IoT";
export const loc_products_windows_iot_10core = "Windows 10 IoT Core";
export const loc_products_windows_mdop = "Desktop Optimization Pack";
export const loc_products_windows_server = "Windows Server";
export const loc_products_windows_smb_server = "Windows Small Business Server";
export const loc_products_windows_system_center = "Windows System Center";
export const loc_products_windows_uwp = "Universal Windows Platform (UWP)";
export const loc_products_windows_virtual_desktop = "Windows Virtual Desktop";
export const loc_products_windows_wdk = "Windows Driver Kit (WDK)";
export const loc_products_windows_wpf = "Windows Presentation Foundation (WPF)";
export const loc_products_xamarin = "Xamarin";
export const loc_products_xbox = "Xbox";
export const loc_profile = "Profile";
export const loc_profile_askOrAnswerAQuestion = "Ask or answer a question in <a href=\"https://docs.microsoft.com/answers\">the community</a>";
export const loc_profile_browseLearn = "Browse learn";
export const loc_profile_catchUpOnTheLatestSiteNews = "Catch up on the latest <a href=\"https://docs.microsoft.com/teamblog\">site news</a>";
export const loc_profile_exploreTheDocumentation = "Explore <a href=\"https://docs.microsoft.com/\">the documentation</a>";
export const loc_profile_learnSkillStepByStep = "Learn skills with <a href=\"https://docs.microsoft.com/learn\">step-by-step guidance</a>";
export const loc_profile_zeroState_activity = "Start learning new skills. Your activities will show here.";
export const loc_profile_zeroState_activity_answers = "Start learning new skills or asking questions. Your activities will show here.";
export const loc_profile_zeroState_overview = "Welcome! There is a large amount of content available for you.";
export const loc_profile_zeroState_bookmarks = "Bookmark your favorite pages and your list will show here.";
export const loc_profile_zeroState_collections = "Create collections with learning modules to customize your own curriculum.";
export const loc_profile_zeroState_following = "Follow your favorite threads, topics and users.";
export const loc_profile_zeroState_achievement = "Complete a learning path or module to get a trophy or badge. Your achievements will show here.";
export const loc_programmingLanguage = "Programming language";
export const loc_programmingLanguages = "Programming languages";
export const loc_progress = "Progress";
export const loc_propertiesInSubtitle = "Properties";
export const loc_property = "Property";
export const loc_propertyDetails = "Property Details";
export const loc_propertyValue = "Property Value";
export const loc_provisioningSandbox = "Building your sandbox. This will take just a minute...";
export const loc_psModule = "Module";
export const loc_qAndA_accepted = "Accepted";
export const loc_qAndA_feedbackMenuText = "Share your feedback";
export const loc_qAndA_msdnForum = "MSDN Forum";
export const loc_qAndA_requiredField = "Required field";
export const loc_qAndA_typeYourQuestion = "Type your question...";
export const loc_qAndA_questionThatMayHaveAnswer = "Question that may already have your answer";
export const loc_qAndA_questionsThatMayHaveAnswer = "Questions that may already have your answer";
export const loc_qAndA_questionTitle = "Question Title";
export const loc_qAndAAnswer = "Q&A Answer";
export const loc_qna_action = "modified";
export const loc_qna_actionAccepted = "accepted";
export const loc_qna_actionAnswered = "answered";
export const loc_qna_actionAsked = "asked";
export const loc_qna_actionCommented = "commented";
export const loc_qna_actionEdited = "edited";
export const loc_qna_actionLabeled = "labeled";
export const loc_qna_actionPosted = "posted";
export const loc_qna_actionSuggested = "suggested";
export const loc_qna_actionVoted = "voted";
export const loc_qna_activity = "the activity";
export const loc_qna_activityTag = "tag";
export const loc_qna_activityTheTag = "the tag";
export const loc_qna_activityArticle = "the article";
export const loc_qna_activityFeedback = "the feedback";
export const loc_qna_activityQuestion = "the question";
export const loc_qna_activityQuestionThread = "the question thread";
export const loc_qna_summaryAccepted = "{displayName} accepted an answer to {activity}";
export const loc_qna_summaryCommented = "{displayName} added a comment to {activity}";
export const loc_qna_summaryLabeled = "{displayName} created a new {activity}";
export const loc_questionIsIncorrectFormat = "1 out of {numberOfQuestions} questions is incorrect. Please correct question {lastIncorrectQuestionNumber}.";
export const loc_questionsAreIncorrectFormat = "{numberOfIncorrectQuestions} out of {numberOfQuestions} questions are incorrect. Please correct questions {incorrectQuestionNumbers} and {lastIncorrectQuestionNumber}.";
export const loc_questions = "Questions";
export const loc_quickfilters = "Quick Filters";
export const loc_quickstart = "Quickstart";
export const loc_quizComplete = "Congratulations!";
export const loc_rateLimitedLoadingComments = "Unable to load comments. GitHub API rate limit exceeded.";
export const loc_rateThisModule = "Rate this module";
export const loc_rateThisPage = "Rate this page";
export const loc_ratingComment = "rating comment";
export const loc_ratingCommentsPrompt = "Comments or suggestions? <a href=\"#\" data-bi-name=\"rating-verbatim-link\">Tell us here</a>.";
export const loc_ratingDetails = "Rating details";
export const loc_ratingShareIdeas = "Please share your ideas. We welcome feedback in any language.";
export const loc_rated = "RATED";
export const loc_rateIt = "Rate it";
export const loc_readInEnglish = "Read in English";
export const loc_readInLanguageTemplate = "Read in {language}";
export const loc_readonly = "read only";
export const loc_recentBookmarks = "Recent bookmarks";
export const loc_recommendedAssessments = "Recommended assessments";
export const loc_recommendedTitle = "Related Articles";
export const loc_recommendedContentDisclaimer = "Recommendations are based on machine learning analysis and tuned by our content authors.";
export const loc_reference = "Reference";
export const loc_references = "References";
export const loc_refresh = "Refresh";
export const loc_refineHeaderText = "Refine Learning Content";
export const loc_regexPattern = "Regex pattern";
export const loc_regionalDirectors = "Regional Directors";
export const loc_register = "Register";
export const loc_relatedCertification = "Related certification";
export const loc_relatedCertifications = "Related certifications";
export const loc_relatedCertificationsAndPrerequisitesFormat = "There may be certifications and prerequisites related to \"{title}\"";
export const loc_relatedExams = "Related exams";
export const loc_relatedLearningPaths = "This module is part of these learning paths";
export const loc_relatedLinks = "Related Links";
export const loc_releases = "Releases";
export const loc_remaining = "remaining";
export const loc_remarks = "Remarks";
export const loc_remove = "Remove";
export const loc_removeItem = "Remove {itemName}";
export const loc_renewalPeriod = "Renewal Period";
export const loc_reportAbuse = "Report inappropriate name/photo";
export const loc_reportAbuseResponse = "Thank you for reporting your concern to us. We review these submissions very carefully and will take appropriate steps to address this issue. Because of legal and privacy concerns, we may not provide you any additional updates about this submission. Thank you again for contacting us.";
export const loc_reputation = "Reputation";
export const loc_reputationInformation = "Reputation information";
export const loc_reputationPoint = "Reputation point";
export const loc_reputationPointEarned = "reputation point earned.";
export const loc_reputationPoints = "Reputation points";
export const loc_reputationPointsEarned = "reputation points earned.";
export const loc_reputationPointsCommunity = "Reputation points are earned by gaining the trust of the Q&A community.";
export const loc_requestBody = "Request Body";
export const loc_requestBodyCanBeFollowing = "The request body can be one of the following:";
export const loc_requestHeader = "Request Header";
export const loc_requestPreview = "Request Preview";
export const loc_requestUrl = "Request URL";
export const loc_required = "required";
export const loc_requiredFieldsAsterisk = "Required fields are marked with an asterisk";
export const loc_requiredHeader = "Required";
export const loc_requiredExams = "Required exams:";
export const loc_requiredFor = "Required for";
export const loc_requiredParameters = "Required Parameters";
export const loc_requirements = "Requirements";
export const loc_resource_type = "Types";
export const loc_resourcesFooter_samples = "Code Samples";
export const loc_resourcesFooter_startups = "Startups";
export const loc_resourcesFooter_students = "Students";
export const loc_resourcesFooter_title = "Resources";
export const loc_resourcesFooter_videoLearning = "Video Learning";
export const loc_responseCode = "Response Code";
export const loc_responses = "Responses";
export const loc_restart = "Restart";
export const loc_restrictedCloud = "We're unable to invite you to a sandbox tenant due to restrictions on your account. Please contact your administrator.";
export const loc_resultsCountFormat = "{numberOfResults} results";
export const loc_resultsFound = "{numberOfResults} results found";
export const loc_retirementDate = "Retirement Date";
export const loc_retryActivating = "Retry activating";
export const loc_retryActivatingSandbox = "Retry activating sandbox";
export const loc_returns = "Returns";
export const loc_returnToModule = "Return to module";
export const loc_returnValue = "Return Value";
export const loc_reviewYourLearnHistory = "Review your Learning Path history";
export const loc_role = "Role";
export const loc_roleCertificationPathFormat = "{role} certification path";
export const loc_roleCertificationPathDescriptionFormat = "The {roleCertificationPath} is organized into 3 levels: Fundamentals, Associate and Expert.";
export const loc_roleCertificationsFormat = "{role} certifications";
export const loc_roleCertificationsDescriptionFormat = "Explore {roleCertifications} most sought after by employers";
export const loc_roleFishboneMiddleColumnHeadingMultipleCerts = "Choose from multiple associate certifications";
export const loc_roleFishboneMiddleColumnHeadingOneCert = "Complete an associate certification";
export const loc_roleFishboneOptionalLeftColumnHeadingFormat = "An optional start for those new to {platform}";
export const loc_roleFishboneRequiredLeftColumnHeadingFormat = "A required start for those new to {platform}";
export const loc_roleFishboneRightColumnHeading = "Requires an associate certification on the path";
export const loc_roleFishboneRightColumnHeadingIfNoMiddleColumn = "Complete an expert certification";
export const loc_roles = "Roles";
export const loc_roles_administrator = "Administrator";
export const loc_roles_ai_edge_engineer = "AI Edge Engineer";
export const loc_roles_ai_engineer = "AI Engineer";
export const loc_roles_auditor = "Auditor";
export const loc_roles_business_analyst = "Business Analyst";
export const loc_roles_business_owner = "Business Owner";
export const loc_roles_business_user = "Business User";
export const loc_roles_data_analyst = "Data Analyst";
export const loc_roles_data_engineer = "Data Engineer";
export const loc_roles_data_scientist = "Data Scientist";
export const loc_roles_database_administrator = "Database Administrator";
export const loc_roles_devops_engineer = "DevOps Engineer";
export const loc_roles_developer = "Developer";
export const loc_roles_functional_consultant = "Functional Consultant";
export const loc_roles_maker = "App Maker";
export const loc_roles_privacy_manager = "Privacy Manager";
export const loc_roles_risk_practitioner = "Risk Practitioner";
export const loc_roles_security_engineer = "Security Engineer";
export const loc_roles_solution_architect = "Solutions Architect";
export const loc_roles_student = "Student";
export const loc_roles_technology_manager = "Technology Manager";
export const loc_rss = "RSS";
export const loc_run = "Run";
export const loc_sandboxAccessAvailable = "Sandbox activated! Time remaining:";
export const loc_sandboxAccessExpired = "Sandbox access expired";
export const loc_sandboxDuration = "Sandbox Duration";
export const loc_sandboxDurationDetails = "All data is removed when time runs out. Afterward, you'll be able to create a fresh sandbox.";
export const loc_sandboxExpiredExplanation = "Your sandbox has expired. Activate a new sandbox.";
export const loc_sandboxLoginExpired = "Looks like your login has expired. Please sign in to activate the sandbox.";
export const loc_sandboxTemporaryDisabled = "The sandbox for this module is currently unavailable. We're working to resolve this as quickly as possible. In the meantime, you may be able to complete this module's exercises using your own Azure subscription, but charges may apply and you may have to modify the instructions for your subscription. Don't have an Azure subscription? Create a <a href=\"https://azure.microsoft.com/free/?azure-portal=true\">free account</a>. Otherwise, please try again tomorrow and check <a href=\"https://twitter.com/docsmsft\">@docsmsft</a> on Twitter for updates.";
export const loc_sandboxTroubleshootingMessage = "For more information, please check the <a href=\"https://docs.microsoft.com/en-us/learn/support/troubleshooting\">troubleshooting guidance page</a>.";
export const loc_save = "Save";
export const loc_saveSuccessful = "Save successful.";
export const loc_scheduleExam = "Schedule exam";
export const loc_scheduleWithCertiport = "Schedule with Certiport";
export const loc_scheduleWithGsi = "Schedule with GSI";
export const loc_scheduleWithPearsonVue = "Schedule with Pearson VUE";
export const loc_search = "Search";
export const loc_searchAllAcrossMicrosoftDocs = "Search all across Microsoft Docs";
export const loc_searchApi = "Search API";
export const loc_searchFilters = "Search filters";
export const loc_searchHelp = "Search help";
export const loc_searchNavigation = "Search Navigation";
export const loc_searchPreviousVersions = "Search previous versions";
export const loc_searchResultsFor = "Search results for";
export const loc_secondsAgo = "{duration} seconds ago";
export const loc_security = "Security";
export const loc_seeAlso = "See also";
export const loc_seeAllInstructorLedCourses = "See all instructor-led courses";
export const loc_seeDetails = "See details";
export const loc_seeNote = "See Note";
export const loc_selectACountryOrRegionValue = "Select a country or region";
export const loc_selectedVersion = "Selected Version";
export const loc_selectLocaleInstructions = "Filter the list of languages by typing the language name or locale code.";
export const loc_selectPackage = "Select Package";
export const loc_selectVersion = "Select Version";
export const loc_selectYourRole = "Select your role";
export const loc_sendFeedbackAbout = "Send feedback about";
export const loc_sendFeedbackAboutThisPage = "Send feedback about this page";
export const loc_sendFeedbackAboutThisProduct = "Send feedback about this product";
export const loc_service = "Service";
export const loc_services = "Services";
export const loc_serviceUnavailable = "The service is temporarily unavailable. We are working on it.";
export const loc_settings = "Settings";
export const loc_share = "Share";
export const loc_shareAchievementMessage = "Checkout the achievement I just earned for {moduleName} on Microsoft Guided learning. #docsmfst";
export const loc_sharedArticleSubject = "[Shared Article] {articleTitle}";
export const loc_sharedesc = "Share This Document";
export const loc_shareThisAchievement = "Share this achievement";
export const loc_shareThisCollection = "Share this collection";
export const loc_shareYourAchievement = "Share your achievement:";
export const loc_showAllDerived = "Show all derived classes";
export const loc_showCompleted = "Show completed";
export const loc_showHideUnits = "Show/hide units in this module";
export const loc_showLess = "Show less";
export const loc_showMore = "Show more";
export const loc_signIn = "Sign in";
export const loc_signInAzure = "Please sign in with your Azure account.";
export const loc_signinCheckYourWork = "Sign in to check your work";
export const loc_signInCloudShellWithDifferentAccount = "You're signed into the cloud shell with an account that's different from your docs account. Please sign out of the cloud shell to retry accessing the sandbox.";
export const loc_signingIn = "Signing in...";
export const loc_signInToActivateSandbox = "Sign in to activate sandbox";
export const loc_signInToComment = "Sign in to comment";
export const loc_signInToGainFullAccess = "To gain full access to guidance and recommendations, please consider <a class=\"docs-sign-in has-text-primary\" href=\"#\">Signing in</a>.";
export const loc_signInToIncreaseRateLimit = "<a class=\"feedback-sign-in-button\" data-bi-name=\"rate-limit-sign-in\" href=\"#\">Sign in</a> to increase your GitHub API rate limit.";
export const loc_signInToSaveProgress = "Sign in to save progress";
export const loc_signInToSeeProfile = "<a class='docs-sign-in' href=\"#\">Sign in</a> to view your user profile.";
export const loc_signOut = "Sign out";
export const loc_site = "Site";
export const loc_siteFeedback = "Site Feedback";
export const loc_siteSearch_form_cancelSearch = "Cancel Search";
export const loc_siteSearch_form_legend = "Choose the type of Docs content you'd like to search";
export const loc_siteSearch_form_allFormat = "All {count}";
export const loc_siteSearch_form_documentationFormat = "Documentation {count}";
export const loc_siteSearch_form_learnFormat = "Learn {count}";
export const loc_siteSearch_form_referenceFormat = "Reference {count}";
export const loc_siteSearch_results_allFormat = "{count} results for \"{searchPhrase}\"";
export const loc_siteSearch_results_allSingularFormat = "{count} result for \"{searchPhrase}\"";
export const loc_siteSearch_results_allInScopeFormat = "{count} results for \"{searchPhrase}\" in {scope}";
export const loc_siteSearch_results_allInScopeSingularFormat = "{count} result for \"{searchPhrase}\" in {scope}";
export const loc_siteSearch_results_allResultsOnMsDocsLinkFormat = "or view <a href=\"{link}\">all results on Microsoft Docs<a>";
export const loc_siteSearch_results_allResultsOnMsDocs = "or view all results on Microsoft Docs";
export const loc_siteSearch_results_inScopeWithPivotCategoryFormat = "{count} results for \"{searchPhrase}\" in {scope} {pivotCategory}";
export const loc_siteSearch_results_inScopeWithPivotCategorySingularFormat = "{count} result for \"{searchPhrase}\" in {scope} {pivotCategory}";
export const loc_siteSearch_results_noResults = "Sorry, we couldn't find anything that fit your search.";
export const loc_siteSearch_results_noResultsWithinScopeFormat = "Sorry, we couldn't find anything that fit your search in {scope}. You can still <button id=\"search-globally-btn\" class=\"button has-text-primary is-text is-link has-inner-focus\">view all results in Microsoft Docs</button>.";
export const loc_siteSearch_results_nothingFound = "We couldn't find any results matching \"{searchPhrase}\"";
export const loc_siteSearch_results_nothingFoundInScope = "We couldn't find any results matching \"{searchPhrase}\" in {scope}";
export const loc_skip = "Skip";
export const loc_skipToMainContent = "Skip to main content";
export const loc_slack = "Slack";
export const loc_softwareDevelopmentKit = "Software Development Kit";
export const loc_somethingWentWrong = "Hmm, something went wrong";
export const loc_somethingWentWrongPleaseTryAgain = "Something went wrong. Please try again.";
export const loc_start = "Start";
export const loc_startDate = "Start Date";
export const loc_startHere = "Start Here";
export const loc_startLearning = "Start Learning";
export const loc_startTutorial = "Start tutorial";
export const loc_stateProvince = "State/Province";
export const loc_stepOfSteps = "Step {step-index} of {step-count}";
export const loc_struct = "Struct";
export const loc_structsInSubtitle = "Structs";
export const loc_submit = "Submit";
export const loc_submitAnotherFeedback = "Submit another feedback";
export const loc_submitFeedback = "Submit feedback";
export const loc_subscriptionType = "Subscription type";
export const loc_submitAndViewFeedbackFor = "Submit and view feedback for";
export const loc_successfullySubmitted = "Successfully Submitted!";
export const loc_successfullySubmittedFeedback = "Thank you for submitting your feedback. We will review it shortly. Please note that feedback reporting is a one way communication and we are not responding back at this time.";
export const loc_suggestionProduct = "Suggest a product idea";
export const loc_supportDates = "Support Dates";
export const loc_supportedValues = "Supported Values";
export const loc_syntax = "Syntax";
export const loc_tableFormat = "Table {index}";
export const loc_tags = "Tags";
export const loc_taskNotComplete = "Task not completed correctly.";
export const loc_imageIsInvalid = "The file {fileName} is invalid. File type must be a valid gif, jpg, jpeg, or png image.";
export const loc_imageSizeInvalid = "The file {fileName} is too large. File size cannot exceed five megabytes.";
export const loc_techProfile_changePicture = "Change picture";
export const loc_techProfile_changingUsernameWillBreakLinks = "Changing your username will break any existing links to your profile";
export const loc_techProfile_deleteDoesNotImpactMicrosoftIdentity = "Deleting your information does not impact your Microsoft identity.";
export const loc_techProfile_deleteProfile = "Delete profile";
export const loc_techProfile_deleteYourProfile = "Delete your profile";
export const loc_techProfile_downloadData = "Download your data";
export const loc_techProfile_invalidDisplayName = "Invalid display name.";
export const loc_techProfile_invalidUsername = "Invalid username. Username must start with a letter or number and can contain non-consecutive dashes. It must be 4 to 40 characters in length.";
export const loc_techProfile_noAchievementsMessage = "Showcase your learning achievements today!";
export const loc_techProfile_noInProgressMessage = "Things you've started learning will show up here.";
export const loc_techProfile_personalInfo = "Personal info, username and URL";
export const loc_techProfile_profileIsPrivate = "{name}'s profile is private";
export const loc_techProfile_privacyConfirmationMessage = "Your profile will be set to private and Learn badges, trophies, and Q&A contributions will be hidden on your public profile. Are you sure you want to hide your achievements and contributions?";
export const loc_techProfile_privacyConfirmationTitle = "Your profile will be set to private";
export const loc_techProfile_privacySettings = "Privacy Settings";
export const loc_techProfile_privacySettingsInfo = "When private mode is on, Learn badges, trophies, and Q&A contributions are hidden on your public profile.";
export const loc_techProfile_privacySuccessMessage = "Private mode is on. Your Learn badges, trophies, and Q&A contributions are now hidden from the public.";
export const loc_techProfile_publicSuccessMessage = "Private mode is off. Your Learn badges, trophies, and Q&A contributions are now viewable to the public.";
export const loc_techProfile_privacyToggleLabel = "Private Mode";
export const loc_techProfile_microsoftMvp = "Microsoft MVP";
export const loc_techProfile_microsoftMvpInfo = "Microsoft MVP Information";
export const loc_techProfile_microsoftMvpInfoDetails = "The MVP designation recognizes exceptional technical community leaders from around the world who voluntarily share their deep, real-world knowledge about Microsoft technologies with others.";
export const loc_techProfile_usernameUnavailable = "That username is unavailable. Please try another.";
export const loc_techProfile_usernameUrl = "Username and URL";
export const loc_techProfile_whereYouCanDownloadYourData = "This is where you can download a copy of your data.";
export const loc_techProfile_accountLinking_accountAlreadyRegistered = "{account} already has a profile";
export const loc_techProfile_accountLinking_accountLimitReached = "You have reached your account limit";
export const loc_techProfile_accountLinking_accountLimitReachedMessage = "No more than five school or work accounts can be linked to one profile. Please remove one account and try again.";
export const loc_techProfile_accountLinking_accountLinked = "{account} linked";
export const loc_techProfile_accountLinking_accountManagement = "Account Management";
export const loc_techProfile_accountLinking_accountManagementDescription = "You can have up to five school or work accounts and one personal account linked to your profile. Linking a school or work account will allow your organization to see your learning progress data.";
export const loc_techProfile_accountLinking_accountOrgAlreadyLinked = "You already have a linked {organization} account";
export const loc_techProfile_accountLinking_accountRemovalConfirmation = "Account removal confirmation";
export const loc_techProfile_accountLinking_accountRemovalConfirmationMessage = "By removing this account, you will not be able to access your profile with it. The corresponding organization will no longer receive your learning progress data.";
export const loc_techProfile_accountLinking_accountRemoved = "{account} removed";
export const loc_techProfile_accountLinking_addAccount = "Add account";
export const loc_techProfile_accountLinking_addAndTryAgain = "Please add another account and try again.";
export const loc_techProfile_accountLinking_canOnlyLinkAccountWithoutRegisteredProfile = "You can only link accounts that don't have a registered profile. Please select a different account or see our <a href=\"https://docs.microsoft.com/en-us/learn/support/troubleshooting#user-content-account-linking\">troubleshooting guidance <span class=\"docon docon-navigate-external\" aria-hidden=\"true\"></span></a> for more details.";
export const loc_techProfile_accountLinking_linkedAccounts = "Linked accounts";
export const loc_techProfile_accountLinking_linkAccountConsentNotice = "You are adding a school or work account ({account}) to your profile. You understand and acknowledge that we may share your results and activities with your organization. You may choose to unlink this account at any time and your organization will stop receiving your learning progress data.";
export const loc_techProfile_accountLinking_linkedAccountAlready = "You have linked this account already";
export const loc_techProfile_accountLinking_linkPersonalAccountMessage = "{account} is a personal account. Please select a school or work account.";
export const loc_techProfile_accountLinking_microsoftLinkAccountConsentNotice = "Additionally, your affiliation with Microsoft will be publicly available.";
export const loc_techProfile_accountLinking_needAtLeastOneAccountLinked = "You need at least one account linked to your profile.";
export const loc_techProfile_accountLinking_noLinkedAccounts = "No accounts linked";
export const loc_techProfile_accountLinking_onlyOneAccountPerOrg = "Only one account per organization is supported.";
export const loc_techProfile_accountLinking_onlyOnePersonalAccount = "You can only have one personal account linked";
export const loc_techProfile_accountLinking_selectAccount = "Select account";
export const loc_techProfile_accountLinking_selectADifferentAccount = "Please select a different account";
export const loc_techProfile_activity_youStartedAModule = "You started a <span class=\"has-text-weight-semibold\">module</span>";
export const loc_techProfile_activity_youCompletedAModule = "You completed a <span class=\"has-text-weight-semibold\">module</span>";
export const loc_techProfile_avatarUpload_cvsTakeDownNotice = "We have removed your profile picture, since it does not align with our terms of use.";
export const loc_techProfile_avatarUpload_avatarUpdateAllowableLimitsExceeded = "Upload limit exceeded. Please try again later.";
export const loc_techProfile_multistepRegister_accountMismatch = "There was an account mismatch, please try signing in with a different account.";
export const loc_techProfile_multistepRegister_achievementsMigratedSuccessfully = "Your achievements have successfully been migrated. Welcome to Microsoft Learn!";
export const loc_techProfile_multistepRegister_bringAchievementsFromXamarinUniversity = "Bring your achievements from Xamarin University";
export const loc_techProfile_multistepRegister_createdProfile = "You have successfully created your profile.";
export const loc_techProfile_multistepRegister_createdSpeakersBureauProfile = "You have successfully created your Speaker Bureau profile. Microsoft Events planners may be in touch soon!";
export const loc_techProfile_multistepRegister_createYourProfile = "Create your profile";
export const loc_techProfile_multistepRegister_displayName = "Display name";
export const loc_techProfile_multistepRegister_errorMovingProgressOver = "Sorry, there was an error moving your progress over. Please try again.";
export const loc_techProfile_multistepRegister_goToYourProfile = "Go to your profile";
export const loc_techProfile_multistepRegister_invalidToken = "Sorry, there was an invalid token. Please go back to <a href=\"https://university.xamarin.com/profile\">Xamarin University</a> and try again.";
export const loc_techProfile_multistepRegister_keepInTouch = "Don't want to miss out on new and updated content, personalized recommendations or helpful tips? Let's keep in touch!";
export const loc_techProfile_multistepRegister_pleaseAgree = "Please agree to the privacy statement.";
export const loc_techProfile_multistepRegister_publiclyVisible = "It will be publicly visible.";
export const loc_techProfile_multistepRegister_privateProfileInfoMessage = "You can hide your achievements and activities under settings.";
export const loc_techProfile_multistepRegister_retry = "Retry";
export const loc_techProfile_multistepRegister_returnToStep = "Return to step {stepnumber}";
export const loc_techProfile_multistepRegister_signedInAs = "You're signed in as";
export const loc_techProfile_multistepRegister_signInDifferentAccount = "Sign in with a different account";
export const loc_techProfile_multistepRegister_stayUpToDate = "Stay up to date";
export const loc_techProfile_multistepRegister_viewYourProfile = "View your profile";
export const loc_techProfile_qa_emailNotifications = "Q&A email notifications";
export const loc_techProfile_qa_allEmail = "All email";
export const loc_techProfile_qa_allEmailDescription = "Control whether to receive e-mail from Microsoft Q&A. You'll still receive administrative emails even if this setting is turned off.";
export const loc_techProfile_qa_mentions = "Mentions";
export const loc_techProfile_qa_mentionsDescription = "Notifications when someone mentions you using @username notation.";
export const loc_techProfile_qa_newFollowers = "New Followers";
export const loc_techProfile_qa_newFollowersDescription = "Notifications when someone follows you.";
export const loc_techProfile_qa_newPostModeration = "New Post Moderation";
export const loc_techProfile_qa_newPostModerationDescription = "Notifications when a new post has entered moderation.";
export const loc_techProfile_qa_unansweredQuestions = "Unanswered Questions";
export const loc_techProfile_qa_unansweredQuestionsDescription = "Control whether to receive a daily digest email of unanswered questions.";
export const loc_techProfile_qa_settings = "Settings for followed tags & users";
export const loc_techProfile_qa_autoAdd = "Automatically add you as a follower for:";
export const loc_techProfile_qa_autoAdd_questionsAsked = "Questions you've asked";
export const loc_techProfile_qa_autoAdd_questionsAnswered = "Questions you've answered";
export const loc_techProfile_qa_autoAdd_questionsCommented = "Questions you've commented on";
export const loc_techProfile_qa_autoAdd_questionsInTags = "Questions in tags you follow";
export const loc_techProfile_qa_autoAdd_yourFeedbackItems = "Your feedback items";
export const loc_techProfile_qa_autoAdd_feedbackItemsPostComments = "Feedback items you post comments on";
export const loc_techProfile_qa_autoAdd_feedbackItemsTags = "Feedback in tags you follow";
export const loc_techProfile_qa_followedTagsAndUser = "Followed tags & user notifications";
export const loc_techProfile_qa_followedTagsAndUserDescription = "Control whether to receive emails for the following site activities.";
export const loc_techProfile_qa_followedTagsAndUserDescription_questionPosted = "A question is posted";
export const loc_techProfile_qa_followedTagsAndUserDescription_questionEdited = "A question is edited";
export const loc_techProfile_qa_followedTagsAndUserDescription_answerPosted = "An answer is posted";
export const loc_techProfile_qa_followedTagsAndUserDescription_answerAccepted = "An answer is accepted";
export const loc_techProfile_qa_followedTagsAndUserDescription_questionAnswerCommentPosted = "A comment to a question or answer is posted";
export const loc_techProfile_qa_followedTagsAndUserDescription_feedbackPosted = "A feedback item is posted";
export const loc_techProfile_qa_followedTagsAndUserDescription_feedbackCommentPosted = "A comment on a feedback item is posted";
export const loc_techProfile_qa_followedTagsAndUserDescription_changeInStatus = "A change in status for a feedback item is made";
export const loc_techProfile_qa_followedTagsAndUserDescription_articlePosted = "An article is posted";
export const loc_tellUsWhatYouThink = "Tell us what you think.";
export const loc_tellUsWhatYouThinkYourFeedbackIsEssentialToUs = "Tell us what you think! Your feedback is essential to us.";
export const loc_tempUpdatingPage = "We are updating this page with a new interactive experience. Check back soon!";
export const loc_termsOfUse = "Terms of Use";
export const loc_terrible = "Terrible";
export const loc_textMe = "Text me";
export const loc_thanks = "Thanks!";
export const loc_thankYou = "Thank you.";
export const loc_thankYouForYourFeedback = "Thank you for your feedback.";
export const loc_thatIsNotRight = "That's not quite right.";
export const loc_theArticle = "the article";
export const loc_theFeedback = "the feedback";
export const loc_theSpace = "the space";
export const loc_theQuestion = "the question";
export const loc_theme = "Theme";
export const loc_thisPage = "This page";
export const loc_thisProduct = "This product";
export const loc_threadMemberScope_all = "All members of this class are thread safe.";
export const loc_threadMemberScope_instance = "All instance members of this class are thread safe.";
export const loc_threadMemberScope_static = "All static members of this class are thread safe.";
export const loc_threadMemberScope_unsupported = "This type is not thread safe.";
export const loc_threadSafety = "Thread Safety";
export const loc_threads = "Q&A Threads";
export const loc_throttlingLimits = "Throttling Limits";
export const loc_tip = "Tip";
export const loc_title = "Title";
export const loc_toggleUnits = "Toggle Units";
export const loc_trademarks = "Trademarks";
export const loc_triggers = "Triggers";
export const loc_trophies = "Trophies";
export const loc_trophy = "Trophy";
export const loc_trophyEarnedFor = "You've earned a trophy for completing";
export const loc_tryAgain = "Try again";
export const loc_tryInBrowser = "Try the code in your browser";
export const loc_tryIt = "Try It";
export const loc_tryItDescription = "Try the REST API with the inputs below.";
export const loc_tryItAuth_selectAuth = "Select Authentication";
export const loc_tryItAuth_selectAuthDescription = "How would you like to authenticate?";
export const loc_tryItAuth_changeAuth = "Change Authentication";
export const loc_tutorial = "Tutorial";
export const loc_tutorial_complete100 = "100% complete!";
export const loc_tutorial_congratulations = "Congratulations!";
export const loc_tutorial_continueHere = "Continue Here";
export const loc_tutorial_contributors = "Contributors";
export const loc_tutorial_furtherReading = "Further Reading";
export const loc_tutorial_introduction = "Introduction";
export const loc_tutorial_minsRemaining = "{numberOfMinutes} minutes remaining";
export const loc_tutorial_minutesToComplete = "{numberOfMinutes} minutes to complete";
export const loc_tutorial_nextSteps = "Next steps";
export const loc_tutorial_nextTutorial = "Next Tutorial";
export const loc_tutorial_resources = "Resources";
export const loc_tutorial_youLearned = "You learned how to...";
export const loc_tutorial_youWillLearn = "You will learn how to...";
export const loc_moduleUnitFeedback = "Need help? See our <a id=\"troubleshooting-guide\" class=\"has-text-primary\" data-bi-name=\"troubleshooting\">troubleshooting guide</a> or provide specific feedback by <a id=\"module-unit-feedback-link\" class=\"has-text-primary\" data-bi-name=\"feedback\">reporting an issue</a>.";
export const loc_tutorialFeedback = "Have an issue with this section? If so, please <a href=\"\" id=\"tutorial-feedback-link\" class=\"has-text-primary\" data-bi-name=\"tutorialfeedback\">give us some feedback</a> so we can improve this section.";
export const loc_tutorialIssue = "Report an issue on GitHub";
export const loc_tutorialNextSteps = "Next steps";
export const loc_tweets = "Tweets";
export const loc_twitter = "Twitter";
export const loc_twitterAttribution = "Curated tweets by";
export const loc_twoWaysToPrepare = "Two ways to prepare";
export const loc_type = "type";
export const loc_typeHeader = "Type";
export const loc_typeAliasInSubtitle = "Type Aliases";
export const loc_typeDefinition = "Type Definition";
export const loc_typeParameters = "Type Parameters";
export const loc_unitComplete = "Unit Complete";
export const loc_unitMarker = "Unit {index} of {total}";
export const loc_units = "Units";
export const loc_unsubscribeFromMicrosoftDocs = "Microsoft Docs will send email to you at {email}. You can opt out by <a href=\"#\" id=\"unsubscribe-from-learn-email\" class=\"is-undecorated\">unsubscribing from Microsoft Docs</a>.";
export const loc_unsubscribeNow = "Unsubscribe now";
export const loc_updated = "updated";
export const loc_uploadAvatar = "Upload new picture";
export const loc_uriParameters = "URI Parameters";
export const loc_usage = "Usage";
export const loc_useAVirtualMachine = "Use a virtual machine";
export const loc_useAVirtualMachineExplanation = "Visit the Hands-on Lab site for integrated instruction.";
export const loc_userDisplayNameProfile = "{userDisplayName}'s profile";
export const loc_userNameFallback = "Docs Learner";
export const loc_users = "Users";
export const loc_useYourLocalComputer = "Use your local computer";
export const loc_useYourLocalComputerExplanation = "Stay on this site and follow instructions with your own machine.";
export const loc_usingThisConnector = "Using this Connector";
export const loc_validation_cantStartOrEndInAPeriod = "{text} cannot start or end with a period.";
export const loc_validation_maxCharacters = "{maxChars} characters max.";
export const loc_validation_textLength = "{text} must be {minChars} to {maxChars} characters in length.";
export const loc_validation_textRequired = "{text} is required.";
export const loc_value = "Value";
export const loc_valueFrom = "value from";
export const loc_values = "Values";
export const loc_variables = "Variables";
export const loc_verifyYourAccount = "Verify your account";
export const loc_version = "Version";
export const loc_video = "Video";
export const loc_videoSchedule = "Schedule";
export const loc_videoScheduleWatchNow = "Watch now";
export const loc_videoScheduleLive = "Live";
export const loc_videos = "Videos";
export const loc_viewAllPageFeedback = "View all page feedback";
export const loc_viewBy = "View by:";
export const loc_viewCode = "View Code";
export const loc_viewDetails = "View details";
export const loc_viewMore = "View more";
export const loc_viewMoreOnGitHub = "View more feedback on GitHub";
export const loc_viewResultsWithCountFormat = "View results ({totalResults})";
export const loc_viewOnGitHub = "View on GitHub";
export const loc_visualStudioArchive = "We recommend using Visual Studio 2019.";
export const loc_visualStudioArchiveDownload = "Download now";
export const loc_visualStudioDocs = "Visual Studio Documentation";
export const loc_vmlab_launchButton = "Launch VM mode";
export const loc_vmlab_loading = "Loading virtual machine...";
export const loc_vmlab_prompt1 = "This unit requires a VM to complete.";
export const loc_vmlab_prompt2 = "VM mode provides free Azure virtual machine access to complete the steps in this unit. Your Azure subscription will not be charged";
export const loc_vmlab_releaseButton = "Yes, activate a new VM";
export const loc_vmlab_releasePrompt1 = "A VM for a different unit is already active";
export const loc_vmlab_releasePrompt2 = "You've already opened a VM in module {lab-name}. If you activate this VM, you'll lose your work in the other module.";
export const loc_vmlab_retryButton = "Retry launching VM mode";
export const loc_vmlab_signInButton = "Sign in to launch VM mode";
export const loc_vote = "Vote";
export const loc_votes = "Votes";
export const loc_warning = "Warning";
export const loc_wasThisHelpful = "Was this page helpful?";
export const loc_wechat = "WeChat";
export const loc_week = "Week";
export const loc_weekly = "Weekly";
export const loc_weeks = "Weeks";
export const loc_weeksAgo = "{duration} weeks ago";
export const loc_welcomeBackUser = "Welcome back, {user}";
export const loc_welcomeToMicrosoftDocs = "<span class=\"has-text-weight-semibold\">Welcome</span> to Microsoft Docs!";
export const loc_welcomeToMicrosoftQA = "Welcome to Microsoft Q&A";
export const loc_weThinkYouMightLikeThese = "We think you might like these";
export const loc_whatsNew = "What's new";
export const loc_whyDidYouChooseYourRating = "Why did you choose your rating?";
export const loc_withOptionalParameters = "With optional parameters";
export const loc_win10 = "Windows 10";
export const loc_win10_apiContract = "API contract";
export const loc_win10_appCapabilities = "App capabilities";
export const loc_win10_deviceFamily = "Device family";
export const loc_win10_introducedIn = "introduced in {version}";
export const loc_win10Requirements = "Windows 10 requirements";
export const loc_windowsDocs = "Windows Documentation";
export const loc_worldwide = "Worldwide";
export const loc_xp = "{totalXP} XP";
export const loc_yes = "Yes";
export const loc_yesDelete = "Yes, delete";
export const loc_yesRemove = "Yes, remove";
export const loc_you = "You";
export const loc_youDidIt = "You did it!";
export const loc_youDontHaveAnyCollectionsYet = "You don't have any collections yet.";
export const loc_youEarnedXpKnowledgeCheck = "You earned experience points for completing the knowledge check.";
export const loc_youEarnedXpTask = "You earned experience points for completing the task.";
export const loc_youFollowed = "You followed";
export const loc_youGotXp = "You've just earned <span class=\"experience-points\"></span> experience points.";
export const loc_youMustAnswerAll = "You must answer all questions before checking your work.";
export const loc_youMustUseSandboxResources = "You must use the resources provided by the sandbox to check your work.";
export const loc_youNailedIt = "You nailed it";
export const loc_yourAccount = "Your Account";
export const loc_yourAccountHasBeenDeleted = "Your account has been deleted.";
export const loc_yourAssessments = "Your assessments";
export const loc_yourAssessmentsCompleteFormat = "You have {completedNum} complete {completedAssessment}.";
export const loc_yourAssessmentsInProgressFormat = "You have {inProgressNum} {progressAssessment} in progress.";
export const loc_yourAssessmentsInProgressAndCompleteFormat = "You have {inProgressNum} {progressAssessment} in progress and {completedNum} complete {completedAssessment}.";
export const loc_yourData = "Your data";
export const loc_yourDataDescription = "Download or delete your saved progress and achievements on Docs. Deleting your information does not impact your Microsoft identity.";
export const loc_yourFeedbackIsVeryImportantToUs = "Your feedback is very important to us. We appreciate you helping us build a better product.";
export const loc_yourRating = "Your rating";
export const loc_yourRecentActivity = "Your recent activity";
export const loc_yourWorkNotCompletedCorrectly = "Your work was not completed correctly.";
export const loc_tooManyAttempts = "Too many attempts.";
export const loc_youWillNotBeNotifiedOfFutureMicrosoftDocsNews = "You will not be notified of future Microsoft Docs related news if you unsubscribe.";

export const assessmentAuthReferralKey = 'assessmentAuthReferal';
export const assessmentSignOutReferralKey = 'assessmentSignOut';
export const assessmentBranchKey = 'assessment-branch';

// Assessment interfaces

export interface Assessment {
	categories: Category[];
	id: string;
	assessmentId: string;
	locale: string;
	title: string;
	description: string;
	version: string;
	branch: string;
}

export interface Category {
	id: string;
	title: string;
	description: string;
	questions: AssessmentInputTypeMap[keyof AssessmentInputTypeMap][];
	isRequired?: boolean;
}

export interface Question {
	id: string;
	type: 'singleSelect' | 'multiSelect' | 'singleSelectImage';
	title: string;
	description?: string;
	isRequired?: boolean;
	choices: Choice[];
	video?: Video;
}

export interface MatrixQuestion {
	type: 'matrix';
	id: string;
	title?: string;
	description?: string;
	isRequired?: boolean;
	choices: AssessmentMatrixHeadings[];
	rows: AssessmentMatrixRow[];
	video?: Video;
}

export interface Choice {
	id: string;
	text: string;
	imageUrl?: string; // The imageUrl used in singleSelectImage type question
	description?: string; // The tooltip text to be used if available
}

export interface AssessmentInputTypeMap {
	singleSelect: Question;
	singleSelectImage: Question;
	singleSelectHorizontal: Question; // not supported yet
	multiSelect: Question;
	matrix: MatrixQuestion;
}

export type QuestionType = keyof AssessmentInputTypeMap;

export interface AssessmentMatrixHeadings {
	id: string;
	text: string;
	description?: string;
}

export interface AssessmentMatrixRow {
	id: string;
	text: string;
}

/**
 * Represents a video to be rendered in the assessment
 */
export interface Video {
	/**
	 * The URL of the video player to have rendered in an iframe.
	 */
	url: string;

	/**
	 * The assistive technology title to apply to the video iframe.
	 */
	title: string;
}

export interface AssessmentSession {
	id: string;
	name: string; // the name user has given to the assessment, session name
	categoriesSelected: { [key: string]: boolean };
	status: 'not-started' | 'in-progress' | 'completed';
	assessmentId: string;
	version: string;
	responses: string[];
	dateModified: string;
	dateCreated: string;
	questionTotal: number;
	questionTotalIndex: number;
	questionsAnswered: number;
	branch?: string;
	locale?: string; // mandatory for new sessions, null allowed to support pre-existent session not having it
}

export type AssessmentListResponse = AssessmentResponseListItem[];

export interface AssessmentResponseListItem {
	id: string;
	locale: string;
	title: string;
	description: string;
	branch: string | null;
}

export type SessionInputKeyValue = { sessionName: string } | { selectCategory: string };

// Navigation action types

export interface QuestionnaireNavigation {
	mode: 'questionnaire';
	session: Partial<AssessmentSession>;
	assessment: Assessment;
	categoryId: string | undefined | null;
	questionId: string | undefined | null;
	branch: string | null;
}

export interface AssessmentSearchParams {
	[assessmentSignOutReferralKey]?: 'true';
	[assessmentAuthReferralKey]?: 'true';
	mode?: 'questionnaire' | 'pre-assessment' | 'guidance';
	[assessmentBranchKey]?: string;
	session?: string; // this takes the place of assessment id
	category?: string;
	question?: string;
	id?: string;
	reportValidity?: string;
}

export type AssessmentNavigation =
	| QuestionnaireNavigation
	| GuidanceNavigation
	| HomeNavigation
	| PreAssessmentNavigation
	| InvalidNavigation;

export interface InvalidNavigation {
	mode: 'invalid';
	session: Partial<AssessmentSession>;
	message?: string;
	content: {
		links: { url: URL; text: string; buttonClass?: string }[];
		heading: string;
		body: string;
	};
	branch: string | null;
}

export interface HomeNavigation {
	mode: 'home';
	assessmentList: Promise<AssessmentResponseListItem[]>;
	sessionList: Promise<AssessmentSession[]>; // will be empty if unauthenticated
	branch: string | null;
	reason?: string;
}

export interface PreAssessmentNavigation {
	mode: 'pre-assessment';
	session?: Partial<AssessmentSession>;
	assessment?: Assessment;
	message?: string;
	reportValidity?: boolean;
	branch: string | null;
}

export interface GuidanceNavigation {
	mode: 'guidance';
	session?: Partial<AssessmentSession>;
	guidance: Guidance;
	message?: string;
	branch: string | null;
}

export type AssessmentInitializationObject = QuestionnaireNavigation | PreAssessmentNavigation;

// Guidance interface

export interface Guidance {
	title: string;
	description?: string;
	sessionId: string;
	assessmentId: string;
	sessionName: string;
	dateCreated: string;
	dateModified: string;
	nextSteps: NextSteps;
	recommendation: Recommendation;
	scoring: Scoring;
	score: Score;
	errorCode: undefined; // used to make typescript accept union types with possible error object sent back after api call
}

interface Recommendation {
	title: string;
	description?: string;
	categories: RecommendationCategory[];
}

export interface GuidanceLink {
	text: string;
	url: string;
}

interface Step {
	title: string;
	description?: string;
	links: GuidanceLink[];
}

interface NextSteps {
	title: string;
	description?: string;
	steps: Step[];
}

export interface RecommendationCategory {
	id: string;
	title: string;
	description?: string;
	iconUrl: string;
	links: GuidanceLink[];
}

interface Scoring {
	title: string;
	description?: string;
	calculationType: string;
}

export interface Score {
	aggregateScore: number;
	maxScoreValue: number;
	gradeHighValue: number;
	gradeMidValue: number;
	categories: CategoryScore[];
}

export interface CategoryScore {
	id: string;
	title: string;
	score: number;
	maxScoreValue: number;
	gradeHighValue: number;
	gradeMidValue: number;
	questionAvgChoices: number;
}

export interface AssessmentBusyIndication {
	busy: boolean;
	action?: Action;
}

type Action = 'next' | 'back';

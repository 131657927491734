import {
	getModuleByUnit,
	Module,
	getUserProgressByUids,
	StandardProgressResponse
} from '../apis/learn';
import { msDocs } from '../globals';
import { isLocalhost } from '../is-production';
import { getMeta } from '../meta';
import { user } from '../auth/user';

let moduleLoaded: Promise<Module>;

export function getCurrentModule(): Promise<Module> {
	if (moduleLoaded === undefined) {
		const unitId = getMeta('uid');
		if (!user.isAuthenticated) {
			moduleLoaded = getModuleByUnit(msDocs.data.userLocale, unitId);
		} else {
			moduleLoaded = getModuleByUnitWithProgress(unitId);
		}
	}

	return moduleLoaded;
}

/**
 * Due to some proclivities in the hosting layer,
 * unit urls do not work locally unless you remove
 * - unnecessary - trailing slash.
 */
export function fixModuleUnitLinksLocally(module: Module): Module {
	if (!isLocalhost) {
		return module;
	}

	module.units.map(unit => {
		unit.url = unit.url.replace(/\/$/, '');
	});

	return module;
}

export function consolidateModuleAndProgress(
	module: Module,
	items: StandardProgressResponse
): Module {
	if (!items || !items.length) {
		return module;
	}

	const itemModule = items.find(item => item.uid === module.uid);
	if (itemModule === undefined) {
		module.status = 'notStarted';
		module.remainingTime = module.durationInMinutes;
	} else {
		module = { ...module, ...itemModule };
	}

	for (let i = 0; i < module.units.length; i++) {
		const itemUnit = items.find(item => item.uid === module.units[i].uid);
		if (itemUnit === undefined) {
			module.units[i].status = 'notStarted';
			module.units[i].remainingTime = module.units[i].durationInMinutes;
		} else {
			module.units[i] = { ...module.units[i], ...itemUnit };
		}
	}

	return module;
}

function getModuleByUnitWithProgress(unitId: string): Promise<Module> {
	return Promise.all([
		getModuleByUnit(msDocs.data.userLocale, unitId),
		getUserProgressByUids([unitId])
	]).then(([module, items]) => {
		return consolidateModuleAndProgress(module, items);
	});
}

import { fetchFamilyByMoniker } from '../apis/monikers';
import { getMoniker } from './moniker';

let familyPromise: Promise<Api.Family>;

export function getFamily() {
	if (familyPromise === undefined) {
		const moniker = getMoniker();
		familyPromise = fetchFamilyByMoniker(moniker);
	}
	return familyPromise;
}

export function findPackageInFamily(family: Api.Family, moniker: string): Api.Package | null {
	for (const product of family.products) {
		for (const pkg of product.packages) {
			if (pkg.moniker === moniker) {
				return pkg;
			}
		}
	}
	return null;
}

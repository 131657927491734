type MessageData = string | object; // numbers don't work on IE.

interface MessageSubscription {
	predicate: (data: MessageData) => boolean;
	callback: (data: MessageData) => any;
}

/**
 * Boxes a window in an object which can be safely passed to Promise.resolve
 * without triggering a bug in Safari and Edge.
 * Both Safari and Edge have internal code that checks the value passed to Promise.resolve
 * to determine whether it's a Promise. This check accesses properties on the window object
 * which trigger an "Access denied" error when the window is cross-origin.
 * https://dev.azure.com/ceapex/Engineering/_git/docs-ui/pullrequest/15126?_a=overview
 */
interface BoxedWindow {
	value: Window;
}

/**
 * Window pub/sub.
 */
export class WindowMessenger {
	private readonly subscriptions: MessageSubscription[] = [];
	private readonly targetWindowLoaded: Promise<BoxedWindow>;

	/**
	 * @param target The window or iframe messages will be published to and received from (not Docs).
	 * @param targetOrigin The target window's expected origin. Used to validate received messages.
	 * @param sourceWindow The Docs window.
	 */
	constructor(
		target: Window | HTMLIFrameElement,
		private readonly targetOrigin: string,
		sourceWindow = window
	) {
		if (!target) {
			throw new Error('target is required');
		} else if (target instanceof HTMLIFrameElement) {
			if (target.contentWindow) {
				this.targetWindowLoaded = Promise.resolve({ value: target.contentWindow });
			} else {
				this.targetWindowLoaded = new Promise(resolve => {
					target.onload = () => resolve({ value: target.contentWindow });
				});
			}
		} else {
			this.targetWindowLoaded = Promise.resolve({ value: target });
		}
		sourceWindow.addEventListener('message', this.messageHandler);
	}

	/**
	 * Publish a message to the target window.
	 * @param data The message data.
	 */
	public async publish<T extends MessageData>(data: T): Promise<void> {
		const targetWindow = await this.targetWindowLoaded;
		targetWindow.value.postMessage(data, this.targetOrigin);
	}

	/**
	 * Subscribe to messages from the target window.
	 * @param callback The callback to be invoked when messages are received.
	 * @param predicate An optional predicate the message must satisfy to trigger the callback.
	 * @returns A function to destroy the subscription.
	 */
	public subscribe<T extends MessageData>(
		callback: (data: T) => any,
		predicate: (data: T) => boolean = () => true
	) {
		const subscription = {
			predicate,
			callback
		};
		this.subscriptions.push(subscription);
		return () => this.unsubscribe(subscription);
	}

	/**
	 * Subscribe once to a message from the target window.
	 * @param predicate An optional predicate the message must satisfy to resolve the promise.
	 * @param timeout An optional timeout in milliseconds for the message to be recieved.
	 * @returns A promise that resolves with the received message.
	 */
	public subscribeOnce<T extends MessageData>(
		predicate: (data: T) => boolean = () => true,
		timeout?: number
	): Promise<T> {
		return new Promise((resolve, reject) => {
			const didTimeout = {};
			const timeoutHandle = timeout === undefined ? 0 : setTimeout(settle, timeout, didTimeout);
			const unsubscribe = this.subscribe<T>(settle, predicate);
			function settle(data: T) {
				clearTimeout(timeoutHandle);
				unsubscribe();
				if (data === didTimeout) {
					reject('timeout');
				} else {
					resolve(data);
				}
			}
		});
	}

	/**
	 * Destroy a subscription.
	 * @param subscription The subscription to destroy.
	 */
	private unsubscribe(subscription: MessageSubscription) {
		const index = this.subscriptions.indexOf(subscription);
		if (index === -1) {
			return;
		}
		this.subscriptions.splice(index, 1);
	}

	/**
	 * Handle messages from other windows.
	 */
	private messageHandler = ({ data, origin }: MessageEvent) => {
		if (origin !== this.targetOrigin) {
			return;
		}
		const matches = this.subscriptions.filter(s => s.predicate(data));
		for (const subscription of matches) {
			subscription.callback(data);
		}
	};
}

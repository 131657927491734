import { UISearchArgs } from './view-model';
import { apis } from '../../environment/apis';
import { msDocs } from '../../globals';
import { isReview } from '../../is-production';

export function getRssUrl(args: UISearchArgs): string {
	return `${apis.siteSearch}/rss?${serializeRssSearchApiArgs(args)}`;
}

function serializeRssSearchApiArgs(args: UISearchArgs): string {
	const params = new URLSearchParams();
	params.set('search', args.terms);
	params.set('locale', msDocs.data.userLocale);

	if (args.dataSource) {
		params.set('dataSource', args.dataSource);

		if (args.dataSource.toLowerCase() === 'previousversions') {
			args.scoringProfile = null; // No A/B test for previous version search
		}
	}

	if (isReview || (args.dataSource && args.dataSource.toLowerCase() === 'previousversions')) {
		return params.toString();
	}

	let facetArg = params.get('facet') || '';
	for (const facet in args.selectedFacets) {
		facetArg = facetArg.length > 0 ? `${facetArg}&facet=${facet}` : facet;
	}
	params.set('facet', facetArg);

	let selectedCategory: string;
	for (const c in args.selectedFacets.category) {
		selectedCategory = c;
		break;
	}

	const categoryFilter =
		selectedCategory && selectedCategory !== 'All' ? `category eq '${selectedCategory}'` : '';

	const scopeFilter = args.scope ? `scopes/any(t: t eq '${args.scope}')` : '';

	if (scopeFilter && categoryFilter) {
		params.set('$filter', `${scopeFilter} and ${categoryFilter}`);
	} else if (scopeFilter || categoryFilter) {
		params.set('$filter', scopeFilter ? scopeFilter : categoryFilter);
	}

	return params.toString();
}

import { document } from './globals';

/**
 * Preload an image to prevent jank.
 */
export function preloadImg(src: string) {
	return new Promise((resolve, reject) => {
		const img = document.createElement('img');
		img.classList.add('visually-hidden');
		img.setAttribute('aria-hidden', 'true');
		img.src = src;
		img.onload = () => {
			img.remove();
			resolve();
		};
		img.onerror = () => {
			img.remove();
			reject();
		};
		document.body.appendChild(img);
	});
}

import { loc_close } from '@msdocs/strings';
import { html, render } from '../lit-html';
import { UserRating } from '../apis/rating';
import { Modal } from '../components/modal/modal';
import { RatingFeedback } from '../feedback-report/rating';
import { createRatingCard } from './rating-card';
import { SurveyQuestion } from './survey-questions';

export function createRatingModal(
	score: number,
	onSubmit: (data: UserRating, ratingFeedbackData: RatingFeedback) => void,
	createSharingLinks: () => Element,
	surveyQuestions: SurveyQuestion[]
): Modal {
	const container = document.createElement('div');
	render(
		html`
			<div
				class="modal-card modal-completed fill-mobile has-border"
				aria-describedby="modalText"
				aria-labelledby="modalTitle"
			>
				<button
					class="modal-close modal-card-close-button is-large is-sticky"
					data-bi-name="close"
					aria-label="${loc_close}"
					autofocus
				></button>
				<div class="modal-card-content modal-slide-container"></div>
				<div
					class="modal-card-foot is-sticky has-flex-justify-content-center has-background-transparent"
				></div>
			</div>
		`,
		container
	);

	const cardContent = createRatingCard(score, onSubmit, surveyQuestions);
	const cardContainer = container.querySelector('.modal-card-content');
	render(cardContent, cardContainer);

	// Show share achievement links on a user's first rating
	if (score === 0) {
		const footer = container.querySelector('.modal-card-foot');
		const sharingLinks = createSharingLinks();
		render(sharingLinks, footer);
	}

	const ratingModal = new Modal(container);

	return ratingModal;
}

import { LearningPath, loadLearningPath } from '../apis/learn';

let learningPathLoaded: Promise<LearningPath>;

export function loadLearningPathInformation(pathId: string): Promise<LearningPath> {
	if (learningPathLoaded === undefined) {
		learningPathLoaded = loadLearningPath(pathId);
	}

	return learningPathLoaded;
}

import { render } from '../lit-html';

export type AuthorInteractiveType =
	| 'azurecli'
	| 'azurepowershell'
	| 'bash'
	| 'bash-editor'
	| 'csharp'
	| 'http'
	| 'lab-on-demand'
	| 'powershell'
	| 'powershell-editor'
	| 'msgraph';
export type InteractiveType =
	| 'bash'
	| 'csharp'
	| 'http'
	| 'powershell'
	| 'lab-on-demand'
	| 'msgraph';

/**
 * Attributes used to create a button that launches an interactive experience.
 */
export interface ActivateButtonConfig {
	name: string;
	iconClass: string;
	attributes: { name: string; value: string }[];
}

export interface InteractiveComponent {
	element: Element;
	/**
	 * Called at activation - will be passed the text content from the associated code block.
	 */
	setCode: (code: string, scaffoldingType?: string) => Promise<void>;
	/**
	 * Execution code called when the "Try It" button is clicked.
	 */
	execute: () => void;
	dispose: () => void;
}

export interface RegisterInteractiveTypeArgs {
	name: InteractiveType;
	activateButtonConfig: ActivateButtonConfig;
	create: () => InteractiveComponent;
}

const interactiveTypes: { [name: string]: RegisterInteractiveTypeArgs } = {};

export function registerInteractiveType(interactiveType: RegisterInteractiveTypeArgs) {
	interactiveTypes[interactiveType.name] = interactiveType;
}

const instantiatedComponents: { [interactiveType: string]: InteractiveComponent } = {};

export interface InteractiveTypeInfo {
	name: InteractiveType;
	flags: {
		[name: string]: boolean;
		isExternal: boolean;
		requiresStructuredData: boolean;
	};
	activateButtonConfig: ActivateButtonConfig;
}

/**
 * Parses the raw interactive type as written by the authors and returns name and flags.
 * Returns null when the interactive type is invalid.
 * @param interactiveType The raw interactive type as written by the content authors.
 */
export function parseInteractiveType(interactiveType: string): InteractiveTypeInfo | null {
	if (!interactiveType) {
		return null;
	}

	// azurecli & azurepowershell are deprecated
	// possible problem below, using eslint-disable for now
	/* eslint-disable-next-line */
	let raw = interactiveType.replace(/^azurecli/, 'bash').replace(/^azurepowershell/, 'powershell');

	// parse the type.
	let name: InteractiveType;
	raw = raw.replace(/^(bash|csharp|http|powershell|lab-on-demand|msgraph)(?:-|$)/, (_, t) => {
		name = t;
		return '';
	});
	if (name === undefined) {
		return null;
	}

	// create the return object
	const activateButtonConfig = interactiveTypes[name]
		? interactiveTypes[name].activateButtonConfig
		: { name: 'unknown', attributes: [], iconClass: '' }; // todo: refactor registration for lab-on-demand scenario
	const result: InteractiveTypeInfo = {
		name,
		flags: {
			isExternal: name === 'lab-on-demand',
			requiresStructuredData: name === 'http'
		},
		activateButtonConfig
	};

	// parse the flags
	raw = raw.replace(/(\w+)$/g, (_, flag) => {
		result.flags[flag] = true;
		return '';
	});

	return result;
}

export function parseScaffoldingType(element: any) {
	return Array.from(element.childNodes)
		.map((child: any) => {
			if (child.nodeName === 'CODE') {
				const attribute = child.getAttribute('data-interactive-mode');
				return attribute;
			}
		})
		.pop();
}

/**
 * Renders an interactive component into the specified container if the element is not already in the container.
 * @param type The interactive type.
 * @param container The container where the component should be rendered.
 */
export function renderInteractiveComponent(type: InteractiveTypeInfo, container: Element) {
	let component = instantiatedComponents[type.name];
	if (!component) {
		component = instantiatedComponents[type.name] = interactiveTypes[type.name].create();
	}
	if (container.firstElementChild !== component.element) {
		render(component.element, container);
	}
	// todo... send type.flags to component.
	// for example, cloud shell might need to enable or disable the "editor"
	return {
		element: component.element,
		setCode: (code: string, scaffoldingType?: string) => component.setCode(code, scaffoldingType),
		execute: () => component.execute(),
		dispose: () => {
			delete instantiatedComponents[type.name];
			component.dispose();
		}
	};
}

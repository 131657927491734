import { loc_filterByTitle } from '@msdocs/strings';
import { jsllReady } from '../../bi';
import {
	autocompleteChangeEvent,
	AutoCompleteSuggestionDetail,
	createAutoComplete
} from '../../components/autocomplete';
import { features } from '../../environment/features';
import { location } from '../../globals';
import { html, TemplateResult, unsafeHTML } from '../../lit-html';
import { getMoniker } from '../../monikers/moniker';
import { escapeRegExp } from '../../text-formatting';
import { TocNodeAccessor } from '../toc-node-accessor';
import { TocNode } from '../types';
import { resolveUrl } from '../url';
import { walk } from '../walk';
import {
	getSiteSearchSuggestion,
	isSiteSearch,
	reportAutocompleteWithJsll,
	suggestionClass
} from './shared';

export function trimAncestry(nodes: TocNode[]) {
	const isAmbiguous = (a: TocNode, b: TocNode, maxDepth: number) => {
		for (let i = 0; i < maxDepth; i++) {
			const aa = a.ancestors[i];
			const bb = b.ancestors[i];
			if (
				aa &&
				bb &&
				aa.toc_title.localeCompare(bb.toc_title, undefined, { sensitivity: 'base' }) !== 0
			) {
				return false;
			}
		}
		return true;
	};

	for (const node of nodes) {
		let i = 0;
		let ambiguousMatch: TocNode;
		do {
			ambiguousMatch = nodes.find(
				x => x !== node && x.toc_title === node.toc_title && isAmbiguous(node, x, i)
			);
			i++;
		} while (ambiguousMatch && i < node.ancestors.length);
		node.ancestors.length = Math.max(i - 1, Math.min(node.ancestors.length, 2));
	}
}

function getSuggestions(nodes: TocNode[], term: string, moniker: string, maxResults: number) {
	term = term.trim();
	const regex = new RegExp(escapeRegExp(term), 'i');
	const results: TocNode[] = [];
	walk(nodes, (node, ancestors) => {
		if (
			node.url &&
			termMatchesTocNode(regex, node) &&
			(!node.monikers || !moniker || node.monikers.indexOf(moniker) !== -1)
		) {
			node.ancestors = ancestors.slice(0);
			results.push(node);
		}
		return results.length < maxResults;
	});

	trimAncestry(results);

	if (features.tocSearch) {
		const { url: href, title: toc_title } = getSiteSearchSuggestion(term, true);
		const url = resolveUrl(href, location.href);
		results.push({ toc_title, href, url });
	}

	return results;
}

export function termMatchesTocNode(regex: RegExp, node: TocNode): boolean {
	return regex.test(node.toc_title) || (node.displayName && regex.test(node.displayName));
}

function itemTemplate(node: TocNode, accessor: TocNodeAccessor) {
	if (isSiteSearch(node.href)) {
		return html`<span class="${suggestionClass} has-text-primary"
			>${unsafeHTML(accessor.htmlTitle(node))}</span
		>`;
	}
	const ancestors: TemplateResult[] = [];
	for (let i = 0; i < node.ancestors.length; i++) {
		const ancestor = node.ancestors[i];
		ancestors.push(html`${unsafeHTML(accessor.htmlTitle(ancestor))}`);
		if (i !== node.ancestors.length - 1) {
			ancestors.push(
				html`<span
					aria-hidden="true"
					class="docon docon-chevron-right-light has-padding-left-extra-small has-padding-right-extra-small"
					style="font-size: .55rem;"
				></span>`
			);
		}
	}
	return html` <span class="${suggestionClass}">
		<span class="is-block has-overflow-ellipsis-tablet"
			>${unsafeHTML(accessor.htmlTitle(node))}</span
		>
		<span
			?hidden=${!node.ancestors.length}
			class="is-block has-text-subtle has-overflow-ellipsis is-size-extra-small has-line-height-reset has-padding-bottom-extra-small"
		>
			${ancestors}
		</span>
	</span>`;
}

export function createTocAutocomplete(id: string, nodes: TocNode[], accessor: TocNodeAccessor) {
	const autocomplete = createAutoComplete<TocNode>({
		input: {
			id,
			placeholder: loc_filterByTitle,
			docon: 'filter-settings',
			isFullWidth: true,
			size: 'small'
		},
		getSuggestions: term => {
			reportTocSearchWithJsll(autocomplete, term);
			const suggestions = getSuggestions(nodes, term, getMoniker(), 50);
			return Promise.resolve(suggestions);
		},
		getTitle: node => accessor.textTitle(node),
		itemTemplate: node => itemTemplate(node, accessor)
	});

	autocomplete.addEventListener(
		autocompleteChangeEvent,
		(event: CustomEvent<AutoCompleteSuggestionDetail<TocNode>>) => {
			const title = accessor.textTitle(event.detail.suggestion);
			const href = accessor.href(event.detail.suggestion);
			reportAutocompleteWithJsll(autocomplete, title, href);
			location.href = href;
			// Azure CLI reference TOCs contain nodes whose url is the current page, only the hash is different, eg #properties.
			// This is unofficially supported :(
			// Clear the autocomplete since the location.href line above might not result in a page reload.
			autocomplete.value = null;
		}
	);
	return autocomplete;
}

async function reportTocSearchWithJsll(autocomplete: HTMLElement, term: string) {
	const awa = await jsllReady;
	awa.ct.capturePageAction(autocomplete, {
		actionType: awa.actionType.OTHER,
		behavior: awa.behavior.SEARCH,
		content: {
			event: 'toc-filter',
			value: term
		}
	});
}

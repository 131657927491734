export interface StyleGuideTocNode {
	title: string;
	href?: string;
	children?: StyleGuideTocNode[];
	isNewSection?: boolean;
	expanded?: boolean;
	selected?: boolean;
}

export const tocJson = {
	data: [
		{
			title: 'Overview',
			expanded: true,
			children: [
				{
					title: 'Animations',
					href: 'animations.html'
				},
				{
					title: 'Breakpoints',
					href: 'breakpoints.html'
				},
				{
					title: 'Colors',
					expanded: true,
					children: [
						{
							title: 'Framework Colors',
							href: ' ./framework-colors.html'
						},
						{
							title: 'System Colors',
							href: './system-colors.html'
						},
						{
							title: 'Theming',
							href: './theming.html'
						},
						{
							title: 'Color Class Modifiers',
							href: './color-class-modifiers.html'
						},
						{
							title: 'Brand Colors',
							href: './brand-colors.html'
						},
						{
							title: 'Code Highlighting',
							href: './code-highlighting.html'
						}
					]
				},
				{
					title: 'Content',
					href: './content.html'
				},
				{
					title: 'Direction',
					href: './direction.html'
				},
				{
					title: 'Fonts',
					expanded: true,
					children: [
						{
							title: 'Sizes',
							href: './typography-sizes.html'
						},
						{
							title: 'Font stack',
							href: './font-stack.html'
						},
						{
							title: 'Icon font',
							href: './icon-font.html'
						}
					]
				},
				{
					title: 'Focus',
					href: './focus.html'
				}
			]
		},
		{
			title: 'Modifiers',
			expanded: true,
			children: [
				{
					title: 'Border',
					href: './border.html'
				},
				{
					title: 'Display',
					href: './display.html'
				},
				{
					title: 'Position',
					href: './position.html'
				},
				{
					title: 'Spacing',
					href: './spacing.html'
				},
				{
					title: 'Typography',
					href: './typography.html'
				}
			]
		},
		{
			title: 'Components',
			expanded: true,
			children: [
				{
					title: 'Autocomplete',
					href: './autocomplete.html'
				},
				{
					title: 'Card',
					href: './card.html'
				},
				{
					title: 'Cards on Learn',
					href: './learn-cards.html'
				},
				{
					title: 'Columns',
					href: './columns.html'
				},
				{
					title: 'Metadata',
					href: './metadata.html'
				},
				{
					title: 'Dropdown',
					href: './dropdown.html'
				},
				{
					title: 'Grid',
					href: './grid.html'
				},
				{
					title: 'Hero',
					href: './hero.html'
				},
				{
					title: 'Level',
					href: './level.html'
				},
				{
					title: 'Media',
					href: './media.html'
				},
				{
					title: 'Media Card',
					href: './media-card.html'
				},
				{
					title: 'Modal',
					href: './modal.html'
				},
				{
					title: 'Nav Box',
					href: './nav-box.html'
				},
				{
					title: 'Pagination',
					href: './pagination.html'
				},
				{
					title: 'Star Rating',
					href: './star-rating.html'
				},
				{
					title: 'Show More',
					href: './show-more.html'
				},
				{
					title: 'Tabs',
					href: './tabs.html'
				},
				{
					title: 'Toast',
					href: './toast.html'
				},
				{
					title: 'Tree',
					href: './tree.html'
				}
			]
		},
		{
			title: 'Elements',
			expanded: true,
			children: [
				{
					title: 'Alert',
					href: './alert.html'
				},
				{
					title: 'Bar link',
					href: './bar-link.html'
				},
				{
					title: 'Bookend',
					href: './bookend.html'
				},
				{
					title: 'Box',
					href: './box.html'
				},
				{
					title: 'Breadcrumbs',
					href: './breadcrumbs.html'
				},
				{
					title: 'Button',
					href: './button.html'
				},
				{
					title: 'Button Grouping',
					href: './button-grouping.html'
				},
				{
					title: 'Checkbox',
					href: './checkbox.html'
				},
				{
					title: 'Checkbox button',
					href: './checkbox-button.html'
				},
				{
					title: 'Completable',
					href: './completable.html'
				},
				{
					title: 'File',
					href: './file.html'
				},
				{
					title: 'Form',
					href: './form.html'
				},
				{
					title: 'Horizontal rule',
					href: './hr.html'
				},
				{
					title: 'Icon',
					href: './icon.html'
				},
				{
					title: 'Image',
					href: './image.html'
				},
				{
					title: 'Input',
					href: './input.html'
				},
				{
					title: 'Meter',
					href: './meter.html'
				},
				{
					title: 'Progress',
					href: './progress.html'
				},
				{
					title: 'Radio',
					href: './radio.html'
				},
				{
					title: 'Select',
					href: './select.html'
				},
				{
					title: 'Skeleton',
					href: './skeleton.html'
				},
				{
					title: 'Table',
					href: './table.html'
				},
				{
					title: 'Tag',
					href: './tag.html'
				},
				{
					title: 'Textarea',
					href: './textarea.html'
				},
				{
					title: 'Title',
					href: './title.html'
				},
				{
					title: 'XP Tag',
					href: './xp-tag.html'
				}
			]
		}
	]
};

import { loc_service } from '@msdocs/strings';
import { platformConfig } from '../api-browser/platform-config';
import { apis } from '../environment/apis';
import { fetchWithTimeout } from '../fetch';
import { getRestProducts, RestProduct } from './monikers';

export function fetchNamespaces(platform: string, moniker: string, locale: string = 'en-us') {
	if (platform === 'rest') {
		return fetchRestNamespaces(moniker);
	}

	const namespacesPath = platformConfig[platform].namespacesPath;
	return fetchWithTimeout(
		`${apis.apiBrowser}/${encodeURIComponent(
			platform
		)}/${namespacesPath}?moniker=${encodeURIComponent(moniker)}&api-version=0.2&locale=${locale}`
	).then<Api.NamespaceResponse>(response => response.json());
}

export function fetchRestNamespaces(moniker: string) {
	return getRestProducts().then(data => mapRestProductsToNamespaceResponse(data, moniker));
}

function mapRestProductsToNamespaceResponse(
	products: RestProduct[],
	moniker: string
): Api.NamespaceResponse {
	const apiItems = (
		products.filter(
			platform => (platform.scope || '').toLowerCase() === (moniker || '').toLowerCase()
		)[0].services || []
	).map(each => ({
		displayName: each.name,
		url: each.url,
		description: each.description,
		itemKind: loc_service
	}));

	return { apiItems };
}

export function search(platform: string, moniker: string, term: string, locale: string = 'en-us') {
	if (platform === 'rest') {
		return siteSearch(locale, '', moniker, term + '*', 0, 25);
	}
	let url = `${apis.apiBrowser}/${platform}/search?api-version=0.2&search=${encodeURIComponent(
		term
	)}&locale=${locale}`;
	if (moniker !== '') {
		url += `&$filter=monikers/any(t: t eq '${encodeURIComponent(moniker)}')`;
	}
	return fetchWithTimeout(url).then<Api.QueryResponse>(response => response.json());
}

export function siteSearch(
	locale: string,
	dataSource: string,
	scope: string,
	term: string,
	skip: number,
	take: number
) {
	let url = `${apis.siteSearch}?locale=${locale}&search=${term}&$skip=${skip}&$top=${take}`;
	const allScopes = scope !== '' ? ['REST'].concat([scope]) : ['REST'];

	const filterExpression = allScopes.map(x => `scopes/any(t:t eq '${x}')`).join(' and ');

	url += `&$filter=${encodeURIComponent(filterExpression)}`;

	if (dataSource !== '') {
		url += `&dataSource=${encodeURIComponent(dataSource)}`;
	}

	return fetchWithTimeout(url)
		.then(response => response.json())
		.then(siteSearchToApiSearch);
}

export function siteSearchToApiSearch(
	siteSearchResponse: Api.SiteSearchResponse
): Api.QueryResponse {
	return {
		count: siteSearchResponse.count,
		'@nextLink': siteSearchResponse['@nextLink'],
		results: siteSearchResponse.results.map(y => ({
			displayName: y.title,
			url: y.url,
			description: y.description,
			itemType: null,
			itemKind: null
		}))
	};
}

import { loc_selectACountryOrRegionValue, loc_selectACountryRegion } from '@msdocs/strings';
import { fetchWithTimeout } from '../fetch';
import { msDocs } from '../globals';
import { isPPE } from '../is-production';
import { parseQueryString, toQueryString } from '../query-string';
import { renderOptInCheckbox } from './opt-in';

interface WelcomeEmailOptInCountry {
	id: string;
	country: string;
	countryCode: string;
	email: string;
}

interface WelcomeEmailOptInPreferences {
	id: string;
	countries: WelcomeEmailOptInCountry[];
}

export let welcomeEmailCountries: Promise<WelcomeEmailOptInPreferences[]>;
export let welcomeEmailCountryCodeLookup: Promise<{ [key: string]: WelcomeEmailOptInCountry }>;

/**
 * Retrieve data from the country json file
 *
 * @param container The element to insert the dropdown menu
 * @param formId The ID where the country and opt-in html will reside
 * @param submitButtonId The ID of the submit button
 */
export function initCountryDropDownList(
	container?: HTMLElement,
	formId?: string,
	submitButtonId?: string
) {
	return getCountryList().then((preferences: []) => {
		return renderCountryDropDownList(preferences, container, formId, submitButtonId);
	});
}

/**
 * Get the list of countries from the JSON file
 */
function getCountryList() {
	if (welcomeEmailCountries === undefined) {
		let url;
		const optInFileName = 'email-opt-in-preferences.json';

		if (location.hostname === 'localhost') {
			const { branch } = parseQueryString();
			const queryString = branch ? '?' + toQueryString({ branch }) : '';
			const docsetBase = location.pathname.split('/').slice(0, 3).join('/');
			url = `${docsetBase}/${optInFileName}${queryString}`;
		} else if (isPPE) {
			const pathParams = location.pathname.split('/');
			const newPath = pathParams.slice(0, pathParams.length - 1).join('/');
			url = `${location.origin}${newPath}/${optInFileName}?branch=master`;
		} else {
			url = `${location.origin}/${msDocs.data.contentLocale}/${optInFileName}?branch=master`;
		}

		welcomeEmailCountries = fetchWithTimeout(url, { credentials: 'include', mode: 'cors' })
			.then(response => {
				return response.json();
			})
			.then(data => {
				return data.preferences;
			});

		welcomeEmailCountryCodeLookup = welcomeEmailCountries.then(x => {
			return x[0].countries.reduce((p: { [key: string]: WelcomeEmailOptInCountry }, c) => {
				p[c.countryCode] = c;
				return p;
			}, {});
		});
	}

	return welcomeEmailCountries;
}

/**
 * Render country dropdown menu
 *
 * @param preferences The opt in country preferences
 * @param container The element to insert the dropdown menu
 * @param formId The id where the country and opt-in list will reside
 * @param submitButtonId The ID of the submit button
 */
function renderCountryDropDownList(
	preferences: WelcomeEmailOptInPreferences[],
	container?: HTMLElement,
	formId?: string,
	submitButtonId?: string
) {
	const countryDropDownBlock =
		container !== undefined
			? (container.querySelector('#create-country-list') as HTMLElement)
			: null;

	const selectDiv = document.createElement('div');
	selectDiv.classList.add('select', 'is-full-width-mobile');

	const selectElement = document.createElement('select');
	selectElement.classList.add('is-radiusless', 'is-full-width-mobile');
	selectElement.setAttribute('name', 'country-list');
	selectElement.setAttribute('id', 'country-list');
	selectElement.setAttribute('autocomplete', 'country');
	selectElement.setAttribute('aria-required', 'true');

	const countries = preferences[0].countries;
	countries.sort((a, b) => a.country.localeCompare(b.country, undefined, { sensitivity: 'base' }));

	if (countries.length >= 1) {
		const defaultOption = document.createElement('option');
		defaultOption.value = `${loc_selectACountryRegion}`;
		defaultOption.selected = true;
		defaultOption.disabled = true;
		defaultOption.hidden = true;
		defaultOption.text = `${loc_selectACountryRegion}`;
		selectElement.appendChild(defaultOption);
	}

	countries.forEach(countryDetails => {
		const option = document.createElement('option');
		option.value = countryDetails.countryCode;
		option.text = countryDetails.country;
		selectElement.appendChild(option);
	});

	selectElement.onchange = () => {
		handleCountrySelection(selectElement.value, countries, container, formId, submitButtonId);
	};

	selectElement.setAttribute('aria-label', `${loc_selectACountryOrRegionValue}`);
	selectDiv.appendChild(selectElement);
	if (countryDropDownBlock !== null) {
		countryDropDownBlock.appendChild(selectDiv);
	}

	return selectDiv;
}

export function handleCountrySelection(
	selectValue: string,
	countries: WelcomeEmailOptInCountry[],
	container: HTMLElement,
	formId?: string,
	submitButtonId?: string
) {
	const selectedCountryDetails = countries.filter(obj => {
		return obj.countryCode === selectValue;
	});
	const countryOptInPreference = selectedCountryDetails[0].email;
	renderOptInCheckbox(container, countryOptInPreference, formId, submitButtonId);
}

import { loc_global, loc_site } from '@msdocs/strings';
import { notifyContentUpdated } from '../affix';
import { createNavBar } from '../components/navbar/index';
import { NavBarConfig, NavBarElement, NavLink } from '../components/navbar/model';
import { siteSearchTemplate } from '../components/navbar/site-search-view';
import {
	ctaTemplate,
	logoTemplate,
	mobileNavTemplate,
	siteCategoryTemplate,
	tabletNavTemplate,
	tabletProfileTemplate
} from '../components/navbar/view';
import { NavBarViewModel } from '../components/navbar/view-model';
import { features } from '../environment/features';
import { contentLoaded, document } from '../globals';
import { html, render } from '../lit-html';
import { CallToAction, docsGlobalHeader, developerHeader } from './model';
import {
	hasSecondaryNavigation,
	resolveLinkUrl,
	siteContentNav,
	siteContext
} from './site-context';

let siteNavBar: NavBarElement;
let category: NavLink;
let cta: CallToAction;

const hasUserProfileTemplate =
	features.userServices &&
	siteContext !== 'Advocates' &&
	siteContext !== 'Developer' &&
	siteContext !== 'Regional Directors' &&
	siteContext !== 'Style-Guide';

siteContentNav.then(uhf => {
	category = uhf.category;
	cta = uhf.callToAction;
	if (siteNavBar) {
		siteNavBar.paint();
	}
});

export async function initDocsHeader() {
	siteNavBar = createNavBar(getSiteConfig());
	siteContentNav.then(uhf => (siteNavBar.items = uhf.items));
	siteNavBar.classList.add('is-content');

	const globalNavBar = siteContext === 'Developer' ? getDeveloperNavBar() : getGlobalNavBar();

	await contentLoaded;
	const header = document.querySelector('#headerAreaHolder > header');
	render(html``, header);
	header.appendChild(globalNavBar);

	if (hasSecondaryNavigation(siteContext)) {
		header.appendChild(siteNavBar);
	}
	notifyContentUpdated();
}

export function getGlobalNavBar(): NavBarElement {
	const navBar = createNavBar({
		leftTemplate: () => logoTemplate(),
		rightTemplate: (viewModel: NavBarViewModel) => html`
			${mobileNavTemplate(viewModel)}
			${tabletNavTemplate(
				viewModel,
				loc_global,
				resolveLinkUrl(docsGlobalHeader.category) as NavLink
			)}
			<span class="nav-bar-spacer"></span>
			${viewModel.mobile ? null : siteSearchTemplate(viewModel)}
			${hasUserProfileTemplate ? tabletProfileTemplate() : null}
		`
	});
	Promise.resolve(docsGlobalHeader.items.map(resolveLinkUrl)).then(items => (navBar.items = items));
	return navBar;
}

function getSiteConfig(): NavBarConfig {
	const leftTemplate = (viewModel: NavBarViewModel) => siteCategoryTemplate(viewModel, category);
	const rightTemplate = (viewModel: NavBarViewModel) => html`
		${tabletNavTemplate(viewModel, loc_site)}
		<span class="nav-bar-spacer"></span>
		${ctaTemplate(cta)}
	`;

	return {
		leftTemplate,
		rightTemplate
	};
}

function getDeveloperNavBar(): NavBarElement {
	const navBar = createNavBar({
		leftTemplate: () => logoTemplate(),
		rightTemplate: (viewModel: NavBarViewModel) => html`
			${mobileNavTemplate(viewModel)}
			${tabletNavTemplate(viewModel, loc_global, developerHeader.category)}
			<span class="nav-bar-spacer"></span>
			${viewModel.mobile ? null : siteSearchTemplate(viewModel)}
		`
	});
	siteContentNav.then(content => (navBar.items = content.items));
	return navBar;
}

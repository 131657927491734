import {
	loc_close,
	loc_collections_createANewCollection_duplicateTitleMessageFormat,
	loc_collections_createANewCollection_invalidDescriptionMessage,
	loc_collections_createANewCollection_invalidTitleMessage,
	loc_collections_createANewCollection_learnMore,
	loc_description,
	loc_optional,
	loc_title
} from '@msdocs/strings';
import { ApiResult, List, listApi, ListErrorCode } from '../apis/lists';
import { html, unsafeHTML } from '../lit-html';
import { SubmitResult, SuccessResult } from '../profile/detail/form-view';
import { createNewCollection } from '../profile/detail/user-util';

export async function editCollection(
	form: HTMLFormElement,
	listId: string
): Promise<SubmitResult<List>> {
	const title = (form.elements.namedItem('collection-title') as HTMLInputElement).value;
	const description = (form.elements.namedItem('collection-description') as HTMLTextAreaElement)
		.value;
	form.querySelector('button[type=submit]').classList.add('is-loading');
	const listUpdateResult = await listApi.updateListMetadata(listId, { name: title, description });
	return handleResponse(listUpdateResult, form);
}

export async function addNewCollection(form: HTMLFormElement): Promise<SubmitResult<List>> {
	const title = (form.elements.namedItem('collection-title') as HTMLInputElement).value;
	const description = (form.elements.namedItem('collection-description') as HTMLTextAreaElement)
		.value;
	form.querySelector('button[type=submit]').classList.add('is-loading');
	const createListResult = await createNewCollection({
		type: 'collection',
		name: title,
		description
	});
	return handleResponse(createListResult, form);
}

function handleResponse(
	apiResult: ApiResult<List, ListErrorCode>,
	form: HTMLFormElement
): SubmitResult<List> {
	if (apiResult.hasError === true) {
		const submitButton = form.querySelector('button[type=submit]');
		if (submitButton) {
			submitButton.classList.remove('is-loading');
		}
		const { code } = apiResult.error;
		setCustomValidityByErrorCode(code, form);
		return { hasError: true };
	}

	return { hasError: false, data: apiResult.data } as SuccessResult<List>;
}

function setCustomValidityByErrorCode(code: ListErrorCode, form: HTMLFormElement) {
	const titleElement = form.elements.namedItem('collection-title') as HTMLInputElement;
	const descriptionElement = form.elements.namedItem(
		'collection-description'
	) as HTMLTextAreaElement;

	switch (code) {
		case 'InvalidBody':
		case 'MissingType':
			return;
		case 'InvalidTitle':
			titleElement.setCustomValidity(loc_collections_createANewCollection_invalidTitleMessage);
			titleElement.reportValidity();
			return;
		case 'InvalidDescription':
			descriptionElement.setCustomValidity(
				loc_collections_createANewCollection_invalidDescriptionMessage
			);
			descriptionElement.reportValidity();
			return;
		case 'DuplicateTitle':
			const message = loc_collections_createANewCollection_duplicateTitleMessageFormat.replace(
				'{title}',
				titleElement.value
			);
			titleElement.setCustomValidity(message);
			titleElement.reportValidity();
			return;
		default:
			return;
	}
}

export function formTemplate(
	modalTitle: string,
	title: string,
	description: string,
	submit: string,
	cancel: string
) {
	return html`
		<h2 class="title is-3 has-margin-bottom-medium">${modalTitle}</h2>
		<button
			class="modal-close modal-card-close-button is-large is-sticky"
			aria-label="${loc_close}"
		></button>
		<div>
			<div class="field">
				<label for="collection-title" class="label">${loc_title}</label>
				<div class="control">
					<input
						id="collection-title"
						class="input"
						type="text"
						name="title"
						.value=${title}
						required
						minlength="1"
						maxlength="80"
						autofocus
					/>
				</div>
			</div>
			<div class="field">
				<label for="collection-description" class="label">${loc_description}</label>
				<div class="control">
					<textarea
						id="collection-description"
						class="textarea"
						name="description"
						maxlength="200"
						.value=${description}
						placeholder="${loc_optional}"
					></textarea>
				</div>
			</div>
			<p class="has-padding-bottom-small">
				${unsafeHTML(loc_collections_createANewCollection_learnMore)}
			</p>
			<div class="buttons">
				<button class="button is-primary has-text-weight-semibold" type="submit">${submit}</button>
				<button type="button" class="modal-close button has-text-weight-semibold">${cancel}</button>
			</div>
		</div>
	`;
}

import { AzureSandbox, AzureSandboxChangedEvent } from '../azure/sandbox';
import { EventBus } from '../event-bus';
import { beforeUnload, listenUntilUnload } from '../router/utils';

export function initResourceGroupNameElements(
	container: Element,
	eventBus: EventBus,
	azureSandbox: { value: AzureSandbox }
) {
	const stashAttr = 'data-author-content';
	const update = (sandbox: AzureSandbox | null) => {
		for (const el of Array.from(container.querySelectorAll<HTMLElement>('rgn, pre > code'))) {
			// stash element's author content in case the sandbox expires.
			if (!el.hasAttribute(stashAttr)) {
				el.setAttribute(stashAttr, el.textContent);
			}
			const authorContent = el.getAttribute(stashAttr);

			if (el.tagName === 'RGN') {
				el.textContent = sandbox ? sandbox.resourceGroupName : authorContent;
			} else if (!el.firstElementChild) {
				// When doing text replacement, ensure the code element only contains text.
				el.textContent = authorContent.replace(
					/<rgn>([^<]*)<\/rgn>/gi,
					sandbox ? sandbox.resourceGroupName : '$1'
				);
			}
		}
	};

	update(azureSandbox.value);
	beforeUnload(eventBus.subscribe(AzureSandboxChangedEvent, event => update(event.sandbox)));
	listenUntilUnload(window, 'content-update', () => update(azureSandbox.value));
}

import {
	getBranch,
	getUserProgressByUids,
	StandardProgressResponse,
	ProgressItem,
	ProductOrFamily
} from '../../../apis/learn';
import { authStatusDetermined } from '../../../auth';
import { defaultFacetSearchFilterOptions } from '../filter-options';
import { FacetSearchResponse } from '../model';
import { SearchVMConfig, UISearchArgs } from '../view-model';
import { apis } from '../../../environment/apis';
import { createRequest, fetchWithTimeout } from '../../../fetch';
import { msDocs } from '../../../globals';
import { html } from '../../../lit-html';
import { InputArgs, toQueryString } from '../../../query-string';
import { traits } from '../../../environment/traits';
import { addLocaleToPath } from '../../../locale';
import { ratingApi, PageRatingResult } from '../../../apis/rating';
import { features } from '../../../environment/features';
import { brandedHeader } from '../../../learn/branded-header';
import { taxonomyNames } from '../../../name-maps/taxonomy';
import { convertMinsToHrsMins } from '../../../time-format';
import { createSearchResultRatingTemplate } from '../../../content-browser/rating-template';
import { RatingAverageTotal, calculateRatingFields } from '../../../rating/rating-dropdown';
import { bookmarkButton, collectionButton } from '../../../learn/cards';
import { createTagStrings, createTagHTML } from '../../../tags';
import { loc_completed } from '@msdocs/strings';
import { ProductId } from '../../../name-maps/product';
import { LevelId } from '../../../name-maps/level';
import { RoleId } from '../../../name-maps/roles';

interface LearnBrowseResult {
	uid: string;
	resource_type: 'module' | 'learning path';
	title: string;
	abstract: string;
	summary: string;
	description: string;
	hidden: boolean;
	last_modified: string;
	locale: string;
	products: ProductId[];
	roles: RoleId[];
	levels: LevelId[];
	duration_in_minutes: number;
	icon_url: string;
	url: string;
	number_of_children: number;
}

interface LearnItemAggregated extends LearnBrowseResult {
	progress?: ProgressItem;
	rating?: RatingAverageTotal;
	hidden: boolean; // added by us, matches uids with test in them
}

export function createLearnSearchConfig(): SearchVMConfig<LearnBrowseResult> {
	return {
		layout: 'grid',
		hideCompleted: true,
		fetch: fetchLearnResults,
		resultTemplate: createLearnFacetSearchCard
	};
}

const learnSearchFacetDefaults = {
	orderBy: 'popularity desc,last_modified desc,title',
	top: 30
};

interface LearnArgs {
	branch: string;
	environment: string;
	locale: string;
	terms: string;
	facet: string[];
	$filter: string;
	$orderBy: string;
	$skip: string;
	$top: string;
	hideCompleted: string;
	showHidden: string;
}

/**
 * Function that renders the cards for the Learn facet search page.
 * @param item The resultant items from the Learn api, learn progress call, and ratings call. All melded into one object.
 */
function createLearnFacetSearchCard(item: LearnItemAggregated) {
	return html`<article data-bi-name="card" class="card is-branded">
		<div class="card-header ${brandedHeader(item.products as ProductOrFamily[])}">
			<figure class="card-header-image">
				<img role="presentation" src="${item.icon_url}" alt="" />
			</figure>
		</div>
		<div class="card-content">
			<p class="card-content-super-title">
				${taxonomyNames.resource_type[item.resource_type]}
			</p>
			<a href="${item.url}" class="card-content-title"><h3>${item.title}</h3></a>
			<ul class="card-content-metadata">
				<li>${convertMinsToHrsMins(item.duration_in_minutes)}</li>
				<li>${createSearchResultRatingTemplate(item.rating)}</li>
			</ul>
			<ul class="tags">
				${hiddenTagTemplate(item)} ${createTagHTML(createTagStrings(item))}
			</ul>
		</div>
		<div class="card-footer">
			${cardProgressTemplate(item)}
			<div class="card-footer-item">
				<div class="buttons">
					${bookmarkButton(item.title, item.url)} ${collectionButton(item.title, item.url)}
				</div>
			</div>
		</div>
	</article>`;
}

function hiddenTagTemplate(item: LearnItemAggregated): unknown {
	return item.hidden ? html`<li class="tag is-small is-warning">Hidden</li>` : '';
}

async function fetchLearnResults(
	args: UISearchArgs
): Promise<FacetSearchResponse<LearnItemAggregated>> {
	const [browseResult] = await Promise.all([getLearnResults(args), authStatusDetermined]);
	const resultUids = browseResult.results.map(result => result.uid);

	const [userProgress, ratings] = await Promise.all([
		(features.gamification ? getUserProgressByUids(resultUids) : Promise.resolve([])) as Promise<
			StandardProgressResponse[]
		>,
		getResultRatings(resultUids)
	]);

	const progressLookup = userProgress.reduce(reduceFacetById<ProgressItem>('uid'), {});
	const ratingLookup = ratings.reduce(reduceFacetById<PageRatingResult>('pageId'), {});

	for (const result of browseResult.results as LearnItemAggregated[]) {
		if (result.icon_url) {
			result.icon_url = addLocaleToPath(result.icon_url, 'en-us');
		}
		if (result.uid in progressLookup) {
			result.progress = progressLookup[result.uid];
		}
		if (result.uid in ratingLookup) {
			result.rating = calculateRatingFields(ratingLookup[result.uid]);
		}
	}
	return browseResult;
}

export async function getResultRatings(resultUids: string[]): Promise<PageRatingResult[]> {
	const pages = resultUids.map(pageId => {
		return {
			pageId
		};
	});
	const { ratings } = await ratingApi.getBatchPageRating(msDocs.data.userLocale, {
		locale: msDocs.data.userLocale,
		pages
	});
	return ratings.filter(
		rating =>
			rating.ratingCountByScore[1] ||
			rating.ratingCountByScore[2] ||
			rating.ratingCountByScore[3] ||
			rating.ratingCountByScore[4] ||
			rating.ratingCountByScore[5]
	);
}

function uiArgsToLearn(args: UISearchArgs): LearnArgs {
	return {
		branch: getBranch(),
		environment: 'prod',
		locale: msDocs.data.userLocale,
		terms: args.terms,
		facet: ['roles', 'levels', 'products', 'resource_type'],
		$filter: defaultFacetSearchFilterOptions(args.selectedFacets),
		// always null if terms are present
		$orderBy: args.terms && args.terms.length > 0 ? null : learnSearchFacetDefaults.orderBy,
		$skip: args.skip ? args.skip.toString() : null,
		$top: learnSearchFacetDefaults.top.toString(),
		hideCompleted: args.hideCompleted ? args.hideCompleted.toString() : null,
		showHidden: traits.reviewFeatures.toString()
	};
}

function getLearnResults(args: UISearchArgs): Promise<FacetSearchResponse<LearnBrowseResult>> {
	const query = (uiArgsToLearn(args) as unknown) as InputArgs;
	const url = `${apis.browse.learn}?${toQueryString(query, true)}`;

	const init = { method: 'GET' };

	return fetchWithTimeout(createRequest(url, init)).then(response => {
		if (response.ok) {
			return response.json();
		}
		/* eslint-disable-next-line */
		throw { error: response.json() };
	});
}

export function reduceFacetById<T>(idProperty: 'uid' | 'pageId') {
	return (accumulator: { [key: string]: T }, current: any): { [key: string]: T } => {
		accumulator[current[idProperty]] = current;
		return accumulator;
	};
}

function cardProgressTemplate(item: LearnItemAggregated) {
	const { progress } = item;
	if (!progress || progress.status === 'notStarted') {
		return '';
	}

	const isProgressElement = progress.status === 'inProgress';

	if (isProgressElement) {
		const percentageComplete =
			Math.floor(
				((item.duration_in_minutes - progress.remainingTime) / item.duration_in_minutes) * 100
			) + '%';
		return html`<div class="card-footer-item">
			<progress
				class="progress is-success is-extra-small"
				value="${item.duration_in_minutes - progress.remainingTime}"
				max="${item.duration_in_minutes}"
			>
			</progress>
			<span class="progress-label">${percentageComplete}</span>
		</div>`;
	}

	return html`
		<div class="card-footer-item">
			<span class="progress-label"
				>${loc_completed} <span class="docon docon-check" role="presentation"></span
			></span>
		</div>
	`;
}

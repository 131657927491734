import { reportFeedback } from '../apis/site-feedback';
import { cookies } from '../cookies';
import { msDocs } from '../globals';
import { isProduction, isReview } from '../is-production';
import { getMeta } from '../meta';
import { getContentPath } from './content-path';

// For rating verbatim feedback
export interface RatingFeedback {
	selectedReasons: string[];
	ratingScore: number;
	description: string;
}

const getRatingFormData = (ratingFeedbackData: RatingFeedback): FormData => {
	const formData = new FormData();
	formData.append('description', ratingFeedbackData.description);
	formData.append(
		'summary',
		ratingFeedbackData.description.substr(
			0,
			ratingFeedbackData.description.length > 50 ? 50 : ratingFeedbackData.description.length
		)
	);
	formData.append('ratingStar', ratingFeedbackData.ratingScore.toString());
	formData.append('selectedReason', ratingFeedbackData.selectedReasons.join(','));

	// Other details
	formData.append('contentLocale', msDocs.data.contentLocale);
	formData.append('userLocale', msDocs.data.userLocale);
	formData.append('feedbackType', 'reportIssue');
	formData.append('feedbackSource', 'Star rating verbatim');
	formData.append('organizationId', '0842495d64db4f69a49eaab7007ce057');
	if (isProduction && !isReview) {
		formData.append('category', 'Microsoft Learn/Customer Feedback/Content/{{REPO-AREA}}');
	} else {
		formData.append('category', 'Microsoft Learn/Customer Feedback/Content/z-test-staging');
	}

	const sessionId = cookies.get('ai_session');
	formData.append('aiSessionId', sessionId !== undefined ? sessionId.split('|')[0] : '');
	formData.append('uid', getMeta('uid') || '');
	formData.append('contentPath', getContentPath());
	formData.append('url', location.href);

	return formData;
};

export async function reportRatingVerbatim(feedbackRatingData: RatingFeedback) {
	reportFeedback(getRatingFormData(feedbackRatingData));
}

import { desktopOnlyQuery } from '../src/match-media';

export function setMasonryContainerHeight(
	masonryElement: HTMLElement,
	widthRestriction: MediaQueryList
) {
	if (!widthRestriction.matches) {
		masonryElement.style.height = '';
		return;
	}

	const columns = Array.from(masonryElement.querySelectorAll('.column')) as HTMLElement[];
	const bottomPadding = 128;
	// assumes .columns.is-masonry is the offsetParent because offsetTop returns the distance of the current element relative to the top of the offsetParent node.
	const height = Math.max(...columns.map(x => x.offsetTop + x.offsetHeight)) + bottomPadding;
	masonryElement.style.height = `${height}px`;
}

export function initMasonryColumns(columns: HTMLElement) {
	setMasonryContainerHeight(columns, desktopOnlyQuery); // fire onload and resize
	addEventListener('resize', () => setMasonryContainerHeight(columns, desktopOnlyQuery));
}

import { EventBus } from '../../event-bus';

export class CancelLoopEvent {}

/**
 * Given an stack of instructions that return true or false for success, pop instructions
 * from the stack. If an instruction is not successful, run the next instruction in the stack with the
 * same conditions. If the stack is emptied or an unhandled error occurs, run the "final" function.
 *
 * Implementation uses arrays and indexes.
 */
export class Orchestrator {
	public bus: EventBus;
	private stack: OrchestratorFunction[];
	private final: () => void;

	constructor(stack: OrchestratorFunction[], final: () => void, bus: EventBus = new EventBus()) {
		this.stack = stack;
		this.final = final;
		this.bus = bus;
	}

	public async run() {
		let position = 0;
		let proceed = true;
		let final = this.final;
		// Track previous results of instructions via positions. Second unsuccessful attempt == exit. Second successful attempt shouldn't be possible.
		const previousResult: boolean[] = [];

		const cancelSubscription = this.bus.subscribe(CancelLoopEvent, () => (proceed = false));
		const cancel: (override?: () => void) => false = (override?: () => void) => {
			this.bus.publish(new CancelLoopEvent());
			final = override || final;
			return false;
		};
		try {
			while (proceed && this.stack[position]) {
				const success = await this.stack[position](cancel);

				if (success || previousResult[position] !== false) {
					previousResult[position] = success;
					position += success ? -1 : 1;
					continue;
				}

				// Couldn't continue. Do not proceed.
				proceed = false;
			}

			if (position >= 0) {
				final();
			}

			cancelSubscription();
		} catch (ex) {
			cancelSubscription();
			this.final();
			throw ex;
		}
	}
}

/**
 * Complex type specifying a "cancel" function that is passed by the orchestrator. If the "cancel" function is called
 * by one of the functions in the stack during orchestrator execution, the orchestrator will stop processing
 * the current stack. If the "cancel" function is called with an "override" argument, instead of running the final
 * function provided by the orchestrator constructor, the "override" argument will be called instead.
 */
export type OrchestratorCancellationFunction = (override?: () => void) => false;

export type OrchestratorFunction = (cancel?: OrchestratorCancellationFunction) => Promise<boolean>;

import { document, window } from './globals';

export let osHighContrastEnabled: boolean = false;

export function detectHighContrast(): void {
	const div = document.createElement('div');
	div.style.cssText = 'position:absolute;top:0;left:-2300px;background-color:#878787';
	div.textContent = 'hc';

	document.body.appendChild(div);

	const color = window.getComputedStyle(div).backgroundColor.toLowerCase();

	document.body.removeChild(div);

	if (color !== '#878787' && color !== 'rgb(135, 135, 135)') {
		document.documentElement.className += ' os-high-contrast-enabled theme-high-contrast';

		osHighContrastEnabled = true;
	}
}

import { loc_video } from '@msdocs/strings';
import { jsllReady } from './bi';
import { generateElementId } from './html';
import { loadLibrary } from './load-library';
import { getMeta } from './meta';
import { html, TemplateResult } from './lit-html';
import { msDocs } from './globals';

/**
 * @param element The element in which to search for matching video content.
 * @param userLocale The target locale to use for the video.
 * @description For OnePlayer video content embedded from microsoft.com, alter the URL to include the userlocale.
 *              If we are on a learn page, use the MSOnePlayer Javascript to load the embedded video so that the video
 *              can use our instance of JSLL.
 */
export async function processOnePlayerFrames(element: ParentNode, userLocale: string) {
	userLocale = userLocale.toLowerCase();
	const matches = Array.from(
		element.querySelectorAll<HTMLIFrameElement>(
			'iframe[src^="https://www.microsoft.com"][src*="/videoplayer/"]'
		)
	);

	if (!matches.length) {
		return;
	}

	const isLearnPage = getMeta('page_type') === 'learn';
	let msOnePlayer: MsOnePlayer;

	if (isLearnPage) {
		msOnePlayer = await getMsOnePlayer();
	}

	matches.forEach(match => {
		const id = match.src.split('/').pop();
		const title = `${loc_video}: ${id}`;

		if (!isLearnPage) {
			match.title = match.title || title;
			if (userLocale === 'en-us') {
				match.src = `https://www.microsoft.com/${userLocale}/videoplayer/${
					match.src.split('videoplayer/')[1]
				}`;
			} else {
				match.src = `https://www.microsoft.com/${userLocale}/videoplayer/${
					match.src.split('videoplayer/')[1]
				}?autoCaptions=${userLocale}`;
			}
			return;
		}

		const elementId = `${id}-${generateElementId()}-id`;
		match.parentElement.id = elementId;
		match.parentElement.innerHTML = '';
		const playerData: MsOnePlayerData = {
			options: {
				autoplay: false,
				mute: false,
				loop: false,
				market: userLocale,
				playFullScreen: false
			},
			metadata: {
				videoId: id
			}
		};
		if (userLocale !== 'en-us') {
			playerData.options.autoCaptions = userLocale;
		}

		msOnePlayer.render(elementId, playerData, () => {
			const iframe = element.querySelector('iframe') as HTMLIFrameElement;
			iframe.title = iframe.title || title;
		});
	});
}

/**
 * Add a title attribute to Channel9 videos
 *
 * @param element The element in which to search for matching video
 */
export function processChannel9Frames(element: ParentNode) {
	const matches = Array.from(
		element.querySelectorAll<HTMLIFrameElement>(`iframe[src^="https://channel9.msdn.com/"]`)
	);

	if (!matches.length) {
		return;
	}

	matches.forEach(match => {
		const url = match.src.split('/');
		const id = url[url.length - 2];
		const title = `${loc_video}: ${id}`;
		match.title = match.title || title;
	});
}

/**
 * Add a title attribute to YouTube videos
 *
 * @param element The element in which to search for matching video content.
 */
export function processYouTubeFrames(element: ParentNode) {
	const matches = Array.from(
		element.querySelectorAll<HTMLIFrameElement>('iframe[src^="https://www.youtube"]')
	);

	if (!matches.length) {
		return;
	}

	matches.forEach(match => {
		const id = match.src.split('/').pop();
		const title = `${loc_video}: ${id}`;
		match.title = match.title || title;
	});
}

let msOnePlayer: MsOnePlayer;

async function getMsOnePlayer(): Promise<MsOnePlayer> {
	if (!msOnePlayer) {
		await jsllReady;
		msOnePlayer = await loadLibrary<MsOnePlayer>(
			'https://www.microsoft.com/videoplayer/js/oneplayeriframe.js',
			'MsOnePlayer'
		);
	}

	return msOnePlayer;
}

interface MsOnePlayer {
	render: (elementId: string, playerData: MsOnePlayerData, onLoadCallback: Function) => void;
}

interface MsOnePlayerData {
	options: {
		autoplay: boolean;
		mute: boolean;
		loop: boolean;
		market: string;
		playFullScreen: boolean;
		autoCaptions?: string;
	};
	metadata: {
		videoId: string;
	};
}

/**
 * Following the logic in the MarkDig extension, we apply some additional
 * logic to video links.
 * * All: http -> https
 * * Channel9: ensure ?nocookie=true is in the query string
 * * YouTube: ensure host is www.youtube-nocookie.com
 * @param videoPlayerUrl The URL to the supported video player
 * @returns The fixed up link or parse exception if the link is unrecognizable.
 * @see https://github.com/dotnet/docfx/blob/master/src/Microsoft.DocAsCode.MarkdigEngine.Extensions/QuoteSectionNote/QuoteSectionNoteRender.cs
 */
export const fixupVideoLink = (videoPlayerUrl: string): string => {
	const url = new URL(videoPlayerUrl);
	url.protocol = 'https';
	if (url.host.localeCompare('channel9.msdn.com', undefined, { sensitivity: 'base' }) === 0) {
		url.searchParams.set('nocookie', 'true');
	} else if (
		url.host.localeCompare('youtube.com', undefined, { sensitivity: 'base' }) === 0 ||
		url.host.localeCompare('www.youtube.com', undefined, { sensitivity: 'base' }) === 0
	) {
		url.host = 'www.youtube-nocookie.com';
	}

	return url.href;
};

/**
 * We support OnePlayer, YouTube and Channel9 videos, however the html generated for them
 * (an \<iframe> with src to the video player) might not have things like the iframe title
 * set for accessibility, or necessary javascript loaded. Calling this will apply those
 * adjustments to the video elements found in the provided node.
 * @param element the HTML Element to process child video \<iframe>s for.
 */
export function processVideoFrames(element: ParentNode) {
	processOnePlayerFrames(element, msDocs.data.userLocale);
	processYouTubeFrames(element);
	processChannel9Frames(element);
}

/**
 * Builds a video player template following the same output as our triple-colon parser does for Markdown -> HTML conversion.
 * @param videoPlayerUrl The well-formed URL to the approved video player (expected: Channel9, Red Tiger, or YouTube)
 * @param title Used as the video player iframe's title.
 * @returns An HTML Element that looks like \<div class="embeddedvideo">\<iframe src="your_videoPlayerUrl" title="your_accessibilityTitle">\</iframe>\</div>.
 * If the videoPlayerUrl does not appear to be valid then an exception will be thrown.
 * @see https://github.com/dotnet/docfx/blob/master/src/Microsoft.DocAsCode.MarkdigEngine.Extensions/TripleColon/VideoExtension.cs
 */
export const createVideoTemplate = (videoPlayerUrl: string, title: string): TemplateResult => {
	const safeTitle = title ?? ''; // If null is provided, then the auto titling process does not work, convert null to ''
	return html`<div class="embeddedvideo">
		<iframe
			src="${fixupVideoLink(videoPlayerUrl)}"
			frameborder="0"
			allowfullscreen="true"
			title="${safeTitle}"
		></iframe>
	</div>`;
};

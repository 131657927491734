import { html, render } from '../../lit-html';
import { InvalidNavigation } from '../types';

const createInvalidTemplate = (action: InvalidNavigation) => {
	const {
		content: { heading, body: description, links }
	} = action;
	return html` <div
		class="modular-content-container has-margin-top-medium box has-margin-bottom-medium"
	>
		<div id="assessments-container" class="section is-uniform">
			<h1 class="title is-1">${heading}</h1>
			<p>${description}</p>
			<div class="buttons has-margin-top-large">
				${links.map(
					link => html`
						<a href="${link.url}" class="button ${link.buttonClass || ''} is-fullwidth-mobile">
							${link.text}
						</a>
					`
				)}
			</div>
		</div>
	</div>`;
};

export function renderInvalidMode(action: InvalidNavigation, view: HTMLElement) {
	render(createInvalidTemplate(action), view);
}

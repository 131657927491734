/**
 * Tracking of last-clicked leaf for trees with duplicate nodes.
 */
import { TocNode } from './types';
import { walk } from './walk';

const storageKey = 'toc-last-click';

/**
 * Saves the index of the last clicked leaf node.
 * This information will be used in subsequent page-views to select the
 * appropriate node in scenarios where the TOC tree has multiple nodes with
 * the same URL.
 */
export function setLastClicked(nodes: TocNode[], clickedNode: TocNode) {
	if (clickedNode.children || !clickedNode.url) {
		return;
	}
	let i = 0;
	walk(nodes, node => {
		if (node !== clickedNode) {
			i++;
			return true;
		}
		try {
			sessionStorage.setItem(storageKey, i.toString(10));
		} catch (e) {}
		return false;
	});
}

/**
 * Get's the index of the last clicked leaf node.
 */
export function getLastClicked() {
	try {
		return parseInt(sessionStorage.getItem(storageKey) || '0', 10);
	} catch (e) {}
	return 0;
}

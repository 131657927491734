import { Awa, jsllReady } from '../../bi';
import { window } from '../../globals';
import { imageBrowserCloseEvent, ImageBrowserCloseEventDetail } from './events';

export function initInstrumentation() {
	jsllReady.then(instrument);
}

function instrument(awa: Awa) {
	window.addEventListener(imageBrowserCloseEvent, (event: CustomEvent) => {
		const detail = event.detail as ImageBrowserCloseEventDetail;
		awa.ct.captureContentPageAction({
			behavior: awa.behavior.REDUCE,
			actionType: awa.actionType.CLICKLEFT,
			content: {
				type: 'lightbox-close',
				image: detail.image
			}
		});
	});
}

import { gameApi } from '../apis/game-status';
import { authStatusDetermined } from '../auth/index';
import { user } from '../auth/user';
import { features } from '../environment/features';
import { EventBus } from '../event-bus';
import { updateGameStatus } from '../learn/level-status';
import { UnitStateChangeEvent } from './events';

// Get game status and update: used on initial load and completed modal pop
export function getGameStatus() {
	if (!features.gamification) {
		return;
	}

	authStatusDetermined.then(() => {
		if (user.isAuthenticated && features.gamification) {
			gameApi.getStatus(user.userId).then(updateGameStatus);
		}
	});
}

export function handleGameStatusOnProgressUpdate(bus: EventBus) {
	if (!features.gamification) {
		return;
	}

	bus.subscribe(UnitStateChangeEvent, event => {
		// if this is not the last unit, fire game status
		// on last unit we wait to fire the game status until the completed modal is shown
		if (!event.config.moduleComplete) {
			getGameStatus();
		}
	});
}

import {
	loc_cancel,
	loc_close,
	loc_countryRegion,
	loc_email,
	loc_gsi_getUpdatesGlobalSkillsInitiative,
	loc_gsi_optInForDocsEmailBody,
	loc_gsi_optInForDocsEmailTitle,
	loc_gsi_privacyStatement,
	loc_gsi_subscribeToEmailIfAffectedByCovid,
	loc_gsi_yesIWouldLikeUpdates,
	loc_invalidEmailAddressPleaseTryAgain,
	loc_noThanks,
	loc_pleaseProvideAnEmailAddress,
	loc_save,
	loc_somethingWentWrongPleaseTryAgain
} from '@msdocs/strings';
import {
	getBrowseContent,
	SingleRequestBrowseTypes,
	getTopByType,
	TrendingBrowseResponse,
	TrendingBrowseResult,
	BrowseFilterCriteria,
	BrowseRequestCriteria
} from '../apis/browse';
import { flatten } from '../apis/learn';
import { ProfileRequest, putProfile } from '../apis/profile';
import { authStatusDetermined } from '../auth';
import { tryLoadUserFromProfileAPI } from '../auth/service';
import { hasValidEmail, user } from '../auth/user';
import { Modal } from '../components/modal/modal';
import { getResultRatings, getUidsFromBrowseResults } from '../content-browser';
import { createListItemsHtml } from '../content-browser/rendering';
import { features } from '../environment/features';
import { contentLoaded, localStorage, msDocs } from '../globals';
import { isReview } from '../is-production';
import { getUserProgressFromMetaTags, organizeLearnCards, renderLearnCards } from '../learn/cards';
import { getItemSummariesFromMetaTags } from '../learn/summaries';
import { synchronizeListButtons } from '../lists';
import { html, render, unsafeHTML } from '../lit-html';
import { addLocaleToPath } from '../locale';
import { getMeta, getMetas } from '../meta';
import { initCountryDropDownList, welcomeEmailCountryCodeLookup } from '../profile/country';
import { hideLabels, showLabel } from '../profile/labels';
import { toQueryString } from '../query-string';

const gsiModalKey = 'gsi-topiclanding-modal';
const gsiSubscriptionKey = 'gsi-subscribe';
let profileUpdatePromise = Promise.resolve();

export async function topicLandingPage() {
	await contentLoaded;
	await authStatusDetermined;

	if (user.isAuthenticated && getMeta('isGsi') === 'true') {
		profileUpdatePromise = tryLoadUserFromProfileAPI();
	}

	const trendingSection = document.getElementById('trending-section');
	const browseType: SingleRequestBrowseTypes = 'topiclanding';

	if (features.contentBrowse && trendingSection) {
		// the trending criteria top value is set to one more than
		// should be displayed so that we can determine whether there
		// are additional items which can be browsed
		const browseResponse = (await getBrowseContent(
			getTrendingCriteria(getTopByType(browseType)),
			browseType
		)) as TrendingBrowseResponse;

		if (browseResponse.popularity) {
			browseResponse.popularity = browseResponse.popularity.map((x: TrendingBrowseResult) => {
				x.icon_url = addLocaleToPath(x.icon_url, 'en-us');
				return x;
			});

			if (browseResponse.popularity.length === 0) {
				// no results so hide the trending section
				trendingSection.hidden = true;
			} else if (browseResponse.popularity.length === getTopByType(browseType)) {
				// maximum number of responses were returned so remove the last item
				// prior to displaying (max of 9)
				const resultsCount = browseResponse.popularity.length;
				const maxResultsToDisplay = getTopByType(browseType) - 1;
				browseResponse.popularity.splice(maxResultsToDisplay, resultsCount - maxResultsToDisplay);

				// set the url and show the trending browse link
				const browseTrendingLink = document.getElementById('browse-trending-link');
				const trendingBrowseUrl = getFilteredBrowseUrl();
				browseTrendingLink.setAttribute('href', trendingBrowseUrl);
				browseTrendingLink.hidden = false;
			}

			renderTrendingResultsGrid(
				browseResponse.popularity,
				browseType,
				document.getElementById('trending-grid')
			);
		}
	}

	renderCurated(browseType);
	synchronizeListButtons(document.querySelector('#main'));
	renderGsiSubscription();
}

async function renderCurated(browseType: SingleRequestBrowseTypes) {
	const curatedSections = Array.from(
		document.querySelectorAll<HTMLElement>('#curated-section,[data-section="curated-section"]')
	);

	if (!curatedSections.length) {
		return;
	}

	const summaries = (await getItemSummariesFromMetaTags()).map(x =>
		x.map(y => {
			y.iconUrl = addLocaleToPath(y.iconUrl, 'en-us');
			return y;
		})
	);

	const userProgress =
		user.isAuthenticated && features.gamification
			? getUserProgressFromMetaTags()
			: Promise.resolve([]);

	const ratings = await getResultRatings(browseType, getMetas('learn_item'));

	curatedSections.forEach(curatedSection => {
		renderLearnCards(
			curatedSection,
			organizeLearnCards(flatten(summaries)),
			userProgress,
			msDocs.data.pageTemplate,
			ratings
		);
	});
}

async function renderGsiSubscription() {
	await profileUpdatePromise;
	const isGsiSubscribed = localStorage.getItem(gsiSubscriptionKey) === 'true';
	if (isGsiSubscribed) {
		user.whenAuthenticated().then(async () => {
			if (user.gsi) {
				localStorage.removeItem(gsiSubscriptionKey);
				return;
			}

			if (hasValidEmail()) {
				const result = await enableGsiInProfile();
				if (!result.hasError) {
					localStorage.removeItem(gsiSubscriptionKey);
				}
			} else {
				await showGsiModalSubscription('opt-in');
				localStorage.removeItem(gsiSubscriptionKey);
			}
		});
		return;
	}

	const hasSeenModal = localStorage.getItem(gsiModalKey) === 'hide';
	const isGsi = getMeta('isGsi') === 'true';
	if (!isGsi || (user.isAuthenticated && user.gsi) || hasSeenModal) {
		return;
	}

	setTimeout(() => showGsiModalSubscription('init'), 3000);
}

function handleAlreadyOpenModal() {
	const openModal = document.querySelector('.modal');
	if (!openModal) {
		return;
	}

	const closeElement =
		openModal.querySelector('button.modal-close') || openModal.querySelector('.modal-close');
	if (closeElement) {
		closeElement.dispatchEvent(new CustomEvent('click', { bubbles: true }));
	}
}

async function showGsiModalSubscription(mode: 'opt-in' | 'init') {
	handleAlreadyOpenModal();
	const content = document.createElement('div');
	const modal = new Modal(content);

	const template = mode === 'opt-in' ? optInTemplate(modal, content) : gsiModalTemplate(modal);
	render(template, content);
	if (mode === 'opt-in') {
		initCountryDropDownList(content, 'gsi-opt-in', 'update-profile-button');
	}

	await modal.show();
	localStorage.setItem(gsiModalKey, 'hide');
}

function gsiModalTemplate(modal: Modal) {
	const primaryButton = user.isAuthenticated
		? html`
				<button
					autofocus
					type="submit"
					@click=${(e: Event) => handleSubscription(e, modal)}
					class="button is-primary"
					data-bi-name="subscribe-to-gsi"
				>
					${loc_gsi_yesIWouldLikeUpdates}
				</button>
		  `
		: html`
				<a
					href="#"
					autofocus
					@click=${(e: Event) => handleSubscription(e, modal)}
					class="docs-sign-in auth-status-determined not-authenticated button is-primary"
					data-bi-name="subscribe-to-gsi"
				>
					${loc_gsi_yesIWouldLikeUpdates}
				</a>
		  `;

	return html`
		<div class="modal-card" data-bi-name="gsi-modal">
			<div class="modal-card-content">
				<section class="has-padding-large">
					<h1 class="title is-size-h5">
						${loc_gsi_getUpdatesGlobalSkillsInitiative}
					</h1>
					<button
						class="modal-close modal-card-close-button is-large is-sticky"
						aria-label="${loc_close}"
						data-bi-name="close"
					></button>
					<div
						class="has-padding-bottom-extra-large has-flex-grow has-flex-shrink has-flex-justify-content-space-between has-four-lines-height"
					>
						<p class="is-size-5">${loc_gsi_subscribeToEmailIfAffectedByCovid}</p>
						<p
							hidden
							data-label="optInSomethingWentWrong"
							class="help has-text-danger"
							role="alert"
							aria-live="polite"
						>
							${loc_somethingWentWrongPleaseTryAgain}
						</p>
					</div>
					<div class="buttons">
						${primaryButton}
						<button
							class="button modal-close is-text has-text-primary"
							data-bi-name="not-subscribe-to-gsi"
						>
							${loc_noThanks}
						</button>
					</div>
				</section>
			</div>
		</div>
	`;
}

async function handleSubscription(event: Event, modal: Modal) {
	const errorElement = modal.contentElement.querySelector<HTMLElement>(
		'[data-label="optInSomethingWentWrong"]'
	);

	if (errorElement) {
		errorElement.hidden = true;
	}

	if (!user.isAuthenticated) {
		localStorage.setItem(gsiSubscriptionKey, 'true');
		return;
	}

	event.preventDefault();
	const buttons = Array.from(
		modal.contentElement.querySelectorAll('button[type]')
	) as HTMLButtonElement[];
	buttons.forEach(button => {
		if (button.type === 'submit') {
			button.classList.add('is-loading');
		}

		button.disabled = true;
	});

	if (hasValidEmail()) {
		const reportError = () => {
			if (errorElement) {
				errorElement.hidden = false;
			}
			buttons.forEach(button => {
				if (button.type === 'submit') {
					button.classList.remove('is-loading');
				}

				button.disabled = false;
			});
		};

		try {
			const result = await enableGsiInProfile();
			if (!result.hasError) {
				modal.hide();
				return;
			}

			reportError();
		} catch {
			reportError();
		}

		return;
	}

	const optInContainer = document.createElement('div');
	render(optInTemplate(modal, optInContainer), optInContainer);
	initCountryDropDownList(optInContainer, 'gsi-opt-in', 'update-profile-button');
	modal.pushContent(optInContainer);
}

async function enableGsiInProfile(profile?: Partial<ProfileRequest>) {
	const updatedProfile: ProfileRequest = {
		userName: user.userName,
		locale: user.locale,
		email: user.email,
		displayName: user.displayName,
		country: user.country,
		contactPointTopicSetting: user.contactPointTopicSetting,
		isPrivate: user.isPrivate,
		avatarImageBytes: null,
		avatarExtension: null,
		source: 'learn',
		interests: user.interests,
		gsi: true
	};

	Object.assign(updatedProfile, profile || {});

	const result = await putProfile(user.userName, updatedProfile);
	if (!result.hasError && result.profile) {
		user.readUserProfile(result.profile);
	}
	return result;
}

function optInTemplate(modal: Modal, container: HTMLElement) {
	let isSubmitting = false;
	const handleSave = async (event: Event) => {
		event.preventDefault();

		if (isSubmitting) {
			return;
		}

		const form = event.target as HTMLFormElement;
		const countryListValue = (form.elements.namedItem('country-list') as HTMLSelectElement).value;
		const emailValue = (form.elements.namedItem('email') as HTMLInputElement).value;
		const optInSetting = (await welcomeEmailCountryCodeLookup)[countryListValue].email;

		isSubmitting = true;
		hideLabels(form);
		form.querySelectorAll<HTMLButtonElement>('button').forEach(x => {
			if (x.type === 'submit') {
				x.classList.add('is-loading');
			}

			x.disabled = true;
		});

		const profileRequest: Partial<ProfileRequest> = {};
		profileRequest.country = countryListValue;
		profileRequest.locale = msDocs.data.userLocale;
		profileRequest.email = emailValue;
		// figure out contact point topic setting. This is incorrect placeholder code.
		if (optInSetting === 'notice') {
			profileRequest.contactPointTopicSetting = 'OptInImplicit';
		} else {
			const agreementValue = (form.querySelector('.opt-in') as HTMLInputElement).checked;
			profileRequest.contactPointTopicSetting = agreementValue ? 'OptInExplicit' : null;
		}

		// save email and country to profile
		try {
			const response = await enableGsiInProfile(profileRequest);

			if (!response.hasError) {
				modal.hide();
				return;
			}

			response.errors.forEach(error => {
				showLabel(form, error);
			});
		} catch (error) {
			showLabel(container, 'optInSomethingWentWrong');
		} finally {
			form.querySelectorAll<HTMLButtonElement>('button').forEach(x => {
				x.classList.remove('is-loading');
				x.disabled = false;
			});
			isSubmitting = false;
		}
	};

	return html`
		<form id="gsi-opt-in" @submit=${handleSave} class="modal-card">
			<div class="modal-card-content">
				<section class="has-padding-large">
					<h1 id="email" class="title is-size-h5">
						${loc_gsi_optInForDocsEmailTitle}
					</h1>
					<button
						type="button"
						class="modal-close modal-card-close-button is-large is-sticky"
						aria-label="${loc_close}"
					></button>
					<div
						class="has-padding-top-none has-flex-grow has-flex-shrink has-flex-justify-content-space-between"
					>
						<p class="is-size-5">${loc_gsi_optInForDocsEmailBody}</p>
						<div class="columns has-margin-none">
							<div class="column has-padding-none">
								<div class="title is-6 has-margin-top-large has-margin-bottom-medium">
									<label for="email">${loc_email}</label>
								</div>
								<input
									autofocus
									type="email"
									id="update-email"
									name="email"
									class="input"
									maxlength="50"
									value=""
									required
								/>
								<p
									hidden
									data-label="emptyEmailForOptIn"
									class="help has-text-danger"
									role="alert"
									aria-live="polite"
								>
									${loc_pleaseProvideAnEmailAddress}
								</p>
								<p
									hidden
									data-label="emailIsInvalid"
									class="help has-text-danger"
									role="alert"
									aria-live="polite"
								>
									${loc_invalidEmailAddressPleaseTryAgain}
								</p>
							</div>
						</div>
						<div class="columns has-margin-none">
							<div class="column has-padding-none">
								<div class="title is-6 has-margin-top-large has-margin-bottom-medium">
									<label for="email">${loc_countryRegion}</label>
								</div>
								<div id="create-country-list" class="column has-padding-none"></div>
								<p
									id="create-optin-optout-privacy-policy"
									class="is-size-small has-margin-top-large has-padding-left-none has-padding-right-none"
								>
									${unsafeHTML(loc_gsi_privacyStatement)}
								</p>
								<div id="create-opt-in-checkbox" class="has-margin-top-small"></div>
								<p
									hidden
									data-label="optInSomethingWentWrong"
									class="help has-text-danger has-margin-top-small"
									role="alert"
									aria-live="polite"
								>
									${loc_somethingWentWrongPleaseTryAgain}
								</p>
							</div>
						</div>
					</div>
					<div class="buttons has-padding-top-large">
						<button
							id="update-profile-button"
							name="update-profile-button"
							type="submit"
							class="button is-primary"
							disabled
						>
							${loc_save}
						</button>
						<button type="button" class="button modal-close has-text-primary is-text">
							${loc_cancel}
						</button>
					</div>
				</section>
			</div>
		</form>
	`;
}

function getTrendingCriteria(top: number = null) {
	return {
		locale: msDocs.data.userLocale,
		filter: getTrendingFilters(),
		terms: '',
		top,
		hideCompleted: true,
		excludeUids: getMetas('learn_item')
	} as BrowseRequestCriteria;
}

function getTrendingFilters() {
	const filters = msDocs.data.topicTrendingFilters;
	return {
		roles: filters.roles?.length > 0 ? filters.roles : [],
		levels: filters.levels?.length > 0 ? filters.levels : [],
		products: filters.products?.length > 0 ? filters.products : [],
		resource_type: filters.types?.length > 0 ? filters.types : []
	} as BrowseFilterCriteria;
}

async function renderTrendingResultsGrid(
	results: TrendingBrowseResult[],
	browseType: SingleRequestBrowseTypes,
	resultsGrid: HTMLElement
) {
	if (!resultsGrid) {
		return;
	}

	const resultUids = getUidsFromBrowseResults(results);
	const ratings = await getResultRatings(browseType, resultUids);
	render(html`${createListItemsHtml(results, browseType, ratings)}`, resultsGrid);
}

function getFilteredBrowseUrl() {
	const filters = msDocs.data.topicTrendingFilters;
	const query = {
		products: filters.products?.length > 0 ? filters.products.join(',') : undefined,
		levels: filters.levels?.length > 0 ? filters.levels.join(',') : undefined,
		roles: filters.roles?.length > 0 ? filters.roles.join(',') : undefined,
		resource_type: filters.types?.length > 0 ? filters.types.join(',') : undefined,
		'api-branch': isReview ? 'master' : undefined
	};

	return '../browse/?' + toQueryString(query, true);
}

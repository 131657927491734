import { html, render, TemplateResult } from './lit-html';
import { msDocs } from './globals';
import { isPpeLearnSandbox, isTestRepo } from './is-production';

export interface Breadcrumb {
	title: string;
	url?: string;
}

/**
 * Dynamically generated breadcrumbs class.
 */
export class DynamicBreadcrumbs {
	public breadcrumbsList: Breadcrumb[];
	public basePath: string;
	private breadcrumbsWrapper: HTMLElement;
	private baseTitle: string;

	constructor(container: HTMLElement, breadcrumbs: Breadcrumb[] = []) {
		this.breadcrumbsWrapper = container;
		this.basePath = msDocs.data.userLocale;
		this.baseTitle = 'Docs';

		if (isTestRepo) {
			this.baseTitle = 'Test';
			this.basePath += '/test';
		} else if (isPpeLearnSandbox) {
			this.baseTitle = 'Learn Sandbox';
			this.basePath += '/learn-sandbox';
		}

		this.breadcrumbsList = [{ title: this.baseTitle, url: `/${this.basePath}/` }, ...breadcrumbs];
	}

	public add(...breadcrumbs: Breadcrumb[]) {
		breadcrumbs.forEach(breadcrumb => this.breadcrumbsList.push(breadcrumb));
	}

	public render() {
		render(this.breadcrumbsTemplate(), this.breadcrumbsWrapper);
	}

	public breadcrumbsTemplate() {
		return this.breadcrumbsList.map((breadcrumb, i) => {
			let crumbItem: TemplateResult;
			if (breadcrumb.url) {
				breadcrumb.url = breadcrumb.url.replace(/\/index$/i, '/');
				crumbItem = html`<a itemprop="item" href="${breadcrumb.url}"
					><span itemprop="name">${breadcrumb.title}</span></a
				>`;
			} else {
				crumbItem = html`<span itemprop="name">${breadcrumb.title}</span>`;
			}

			return html` <li
				itemprop="itemListElement"
				itemscope=""
				itemtype="http://schema.org/ListItem"
			>
				${crumbItem}
				<meta itemprop="position" content="${i + 1}" />
			</li>`;
		});
	}
}

export function createBreadcrumbs(container?: HTMLElement): DynamicBreadcrumbs | void {
	if (msDocs.data.context.chromeless) {
		return;
	}

	return new DynamicBreadcrumbs(
		container ? container : document.getElementById('page-breadcrumbs')
	);
}

import { features } from './environment/features';
import { traits } from './environment/traits';
import { msDocs } from './globals';
import { loadLibrary } from './load-library';
import { getMeta } from './meta';
import { UnitPageConfig } from './unit/config';

let loadedCaptcha: boolean;
let captchaSolved: () => void;
export let captchaResolved = new Promise<void>(resolve => (captchaSolved = resolve));

export function enableCaptcha(config: UnitPageConfig) {
	// bail if captcha script already loaded
	if (!features.sandbox || loadedCaptcha) {
		return;
	}

	// bail if we're in chromeless mode or if the unit doesn't use a sandbox.
	const isLab = getMeta('lab-id');
	const { isChromeless, hasSandbox } = config;
	if (isChromeless || !(hasSandbox || isLab)) {
		return;
	}

	loadLibrary('https://client-api.arkoselabs.com/fc/api/?onload=loadCaptchaChallenge');
	loadedCaptcha = true;
}

export function loadCaptchaChallenge() {
	// clear container: ensures we don't get a double load of captcha
	const captchaContainer = document.querySelector('#captcha-container') as HTMLElement;
	captchaContainer.innerHTML = '';

	const config = buildCaptchaConfig(captchaContainer);
	// @ts-ignore - loaded in arkose script
	return new ArkoseEnforcement(config) as ArkoseEnforcement;
}

export function resetCaptcha(arkose: ArkoseEnforcement) {
	// reset promise and arkose session
	captchaResolved = new Promise<void>(resolve => (captchaSolved = resolve));
	arkose.refresh_session();
}

const ArkoseLanguageMap: LanguageMap = {
	'ar-sa': 'ar',
	'bg-bg': 'bg',
	'bn-in': 'bn-in',
	'bs-latn-ba': 'bs-latn-ba',
	'ca-es': 'ca',
	'cs-cz': 'cs',
	'da-dk': 'da',
	'de-de': 'de',
	'el-gr': 'el',
	'en-gb': 'en-gb',
	'en-us': 'en',
	'es-mx': 'es-mx',
	'es-es': 'es',
	'et-ee': 'et',
	'eu-es': 'eu',
	'fi-fi': 'fi',
	'fil-ph': 'fil',
	'fr-ca': 'fr-ca',
	'fr-fr': 'fr',
	'ga-ie': 'ga-ie',
	'gl-es': 'gl',
	'he-il': 'he',
	'hi-in': 'hi',
	'hr-hr': 'hr',
	'hu-hu': 'hu',
	'id-id': 'id',
	'ig-ng': 'ig-ng',
	'is-is': 'is',
	'it-it': 'it',
	'ja-jp': 'ja',
	'kk-kz': 'kk',
	'ko-kr': 'ko',
	'lb-lu': 'lb-lu',
	'ms-my': 'ms',
	'mt-mt': 'mt-mt',
	'nl-nl': 'nl',
	'nb-no': 'no',
	'pl-pl': 'pl',
	'pt-br': 'pt-br',
	'pt-pt': 'pt',
	'ro-ro': 'ro',
	'ru-ru': 'ru',
	'sk-sk': 'sk',
	'sl-si': 'sl',
	'sr-cyrl-rs': 'sr-cyrl',
	'sr-latn-rs': 'sr',
	'sv-se': 'sv',
	'th-th': 'th',
	'zh-cn': 'zh',
	'zh-tw': 'zh-TW',
	'zh-hk': 'zh-HK'
};

function buildCaptchaConfig(captchaContainer: HTMLElement) {
	// lookup language based on locale, if none use browser language
	const locale = msDocs.data.userLocale;
	const supportedLanguage = ArkoseLanguageMap[locale] || 'default';

	const prodKey = '958652F9-1B3E-C23A-E908-CDFFFE508ECB';
	const devKey = '4459FB26-0753-0E59-AA51-E12E04E8D946';
	const captchaKey = traits.supportLevel === 'production' ? prodKey : devKey;

	return {
		public_key: captchaKey,
		target_html: captchaContainer.id,
		language: supportedLanguage,
		callback: () => {
			captchaSolved();
		}
	};
}

export interface ArkoseEnforcement {
	callback: Function; // - A function that will be called when the captcha has been solved by the user.
	getSessionToken?: Function; // - Returns the users session token, only returned after successful completion
	language: string; // – HTML ISO language parameter to lock Arkose Enforcement. If not set, it will automatically translate based on the users language. https://arkoselabs.atlassian.net/wiki/spaces/DG/pages/198215091/Supported+Languages
	loaded_callback?: Function; // – A function that will be called when the captcha has been fully loaded. This event only occurs when an captcha is displayed. It does not occur if you are using passive mode, where an captcha is not shown.  If you use passive mode, an onsuppress event will occur.
	onsuppress?: Function; // – Triggered if an captcha has not been presented due to user passing security checks.
	onshown?: Function; // – Triggered if an captcha has been presented due to user failing security checks
	public_key: string; // – The public key to authenticate with our server. Found in your Arkose Labs account settings.
	target_html: string; // - This is an ID or class of where you want the captcha to be loaded on the page.
	refresh_session: Function; // Resets the session
}

interface LanguageMap {
	[name: string]: string;
}

import {
	loc_close,
	loc_continue,
	loc_greatWork,
	loc_hint,
	loc_thatIsNotRight,
	loc_tryAgain,
	loc_youEarnedXpTask,
	loc_yourWorkNotCompletedCorrectly
} from '@msdocs/strings';
import { LearnItem } from '../apis/learn';
import { Modal } from '../components/modal/modal';
import { document } from '../globals';
import { handleXpTag } from '../learn/unit-progress';
import { html, render, unsafeHTML } from '../lit-html';

export function taskValidationModal(
	complete: boolean,
	currentUnit: LearnItem,
	nextUnitUrl: string,
	errorHtml: string = null
) {
	const errorBodyHtml =
		errorHtml === null
			? html`<p id="taskModalBody" class="task-body has-margin-top-medium">
					${loc_yourWorkNotCompletedCorrectly}
			  </p>`
			: html`<div id="taskModalBody" class="task-body has-margin-top-medium">
					${unsafeHTML(loc_hint.replace('{hint}', errorHtml))}
			  </div>`;
	const taskValidationTitle = html`<h1 id="taskModalTitle" class="task-title is-size-2">
		${loc_thatIsNotRight}
	</h1>`;
	const taskModal = document.createElement('div');
	render(
		html`
			<div class="modal-card fill-mobile">
				<div class="modal-card-head has-padding-small">
					<button
						class="modal-close modal-card-close-button is-large"
						data-bi-name="close"
						aria-label="${loc_close}"
					></button>
				</div>
				<section
					class="modal-card-body has-padding-top-none has-flex-justify-content-center has-text-centered has-padding-bottom-none"
				>
					${taskValidationTitle} ${errorBodyHtml}
					<img
						alt=""
						src="https://docs.microsoft.com/en-us/media/learn/modal-task-error.svg"
						class="task-modal-incorrect has-padding-top-large has-padding-bottom-large has-margin-top-medium"
					/>
					<div
						class="task-modal-background-correct task-modal-correct has-background-image has-padding-top-large has-padding-bottom-large has-margin-top-medium is-hidden"
					>
						<div
							data-progress-uid="${currentUnit.uid}"
							class="xp-tag is-large is-complete has-flex-justify-content-center has-margin-top-medium is-flex"
						>
							<div class="xp-tag-hexagon has-margin-none">
								<span class="xp-tag-icon is-shown-complete docon docon-check"></span>
								<span class="xp-tag-xp x-hidden-focus"></span>
							</div>
						</div>
					</div>
				</section>
				<div class="modal-card-foot has-flex-justify-content-center has-padding-bottom-extra-large">
					<button class="task-modal-incorrect modal-close button is-primary" autofocus>
						<span class="task-button-text">${loc_tryAgain}</span>
						<span class="icon docon docon-chevron-right-light" aria-hidden="true"></span>
					</button>
					<a
						href="${nextUnitUrl}"
						class="task-modal-correct button modal-close is-primary is-hidden"
					>
						<span class="task-button-text">${loc_continue}</span>
						<span class="icon docon docon-chevron-right-light" aria-hidden="true"></span>
					</a>
				</div>
			</div>
		`,
		taskModal
	);
	new Modal(taskModal).show();

	taskModal.classList.add('task-completed-modal', 'is-incorrect'); // set wrapper classes for task val modal
	const taskTitle = taskModal.querySelector('.task-title') as HTMLParagraphElement;
	const taskBody = taskModal.querySelector('.task-body') as HTMLElement;
	const taskCorrects = Array.from(
		taskModal.querySelectorAll('.task-modal-correct')
	) as HTMLElement[];
	const taskIncorrects = Array.from(
		taskModal.querySelectorAll('.task-modal-incorrect')
	) as HTMLElement[];
	const taskIncorrectImg = taskModal.querySelector('img.task-modal-incorrect');
	taskIncorrectImg.classList.remove('is-hidden');

	if (complete) {
		// set class to correct
		taskModal.classList.remove('is-incorrect');
		// build complete modal details and swap incorrect for correct details
		taskTitle.textContent = loc_greatWork;
		taskBody.textContent = loc_youEarnedXpTask;
		for (const taskIncorrect of taskIncorrects) {
			taskIncorrect.classList.add('is-hidden');
		}
		for (const taskCorrect of taskCorrects) {
			taskCorrect.classList.remove('is-hidden');
		}
		// build xp points
		const xpTags = Array.from(taskModal.querySelectorAll('.xp-tag')) as HTMLElement[];
		handleXpTag(xpTags, [currentUnit]);
	}
}

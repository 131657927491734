import { loc_close, loc_landmarkToc } from '@msdocs/strings';
import { Modal } from '../components/modal/modal';
import { html, render } from '../lit-html';
import { getMoniker } from '../monikers/moniker';
import { useMonikerPicker } from '../toc-setup';

function createModalTocContent(title?: string) {
	const container = document.createElement('div');
	// override modal styles to position the modal-content
	// over entire window on mobile and docked to the user-left
	// on tablet resolution and above.
	container.setAttribute('data-bi-name', 'left toc'); // backwards compat
	container.classList.add('modal-content', 'is-docked-left', 'is-flex', 'is-flex-column');

	render(
		html`
			<div class="is-flex has-border-bottom has-flex-shrink-none">
				<h3 class="has-padding-medium has-flex-grow">
					${title || loc_landmarkToc}
				</h3>
				<button
					type="button"
					class="modal-close button has-height-auto is-text is-icon-only has-inner-focus has-border-left has-border-right"
					autofocus
				>
					<span class="visually-hidden">${loc_close}</span>
					<span class="icon">
						<span class="docon docon-math-multiply" aria-hidden="true"></span>
					</span>
				</button>
			</div>
		`,
		container
	);
	return container;
}

/**
 * The modal which contains the TOC. Will be undefined while the modal is not showing.
 */
export let tocModal: Modal;

export function initModalToc(
	renderTocContent: (
		container: Element,
		searchExtraClasses?: string[],
		monikerPickerExtraClasses?: string[],
		treeExtraClasses?: string[],
		pdfLinkExtraClasses?: string[]
	) => void,
	title?: string
) {
	if (title) {
		const buttons = Array.from(
			document.getElementsByClassName('contents-expand-title')
		) as HTMLElement[];
		for (const button of buttons) {
			button.innerText = title;
		}
	}

	window.addEventListener('click', event => {
		const button = event.target instanceof Element && event.target.closest('.contents-button');
		if (!button) {
			return;
		}
		event.preventDefault();
		const container = createModalTocContent(title);

		let searchExtraClasses = ['has-margin-medium'];
		if (useMonikerPicker && getMoniker()) {
			// Has moniker picker, need to make search form less top margin to  make it closer to moniker picker
			searchExtraClasses = [
				'has-margin-left-medium',
				'has-margin-right-medium',
				'has-margin-bottom-medium',
				'has-margin-top-none'
			];
		}

		const monikerPickerExtraClasses = [
			'has-padding-left-medium',
			'has-padding-right-medium',
			'has-padding-top-medium'
		];
		const treeExtraClasses = [
			'has-padding-left-medium',
			'has-padding-right-medium',
			'has-padding-top-small',
			'has-border-top'
		];
		const pdfLinkExtraClasses = ['has-padding-large'];
		renderTocContent(
			container,
			searchExtraClasses,
			monikerPickerExtraClasses,
			treeExtraClasses,
			pdfLinkExtraClasses
		);
		const modal = new Modal(container);
		tocModal = modal;
		modal.show().then(() => {
			tocModal = undefined;
			window.removeEventListener('resize', handleResize);
		});
		const width = window.innerWidth;
		// close on horizontal resize.
		function handleResize() {
			if (window.innerWidth === width) {
				return;
			}
			window.removeEventListener('resize', handleResize);
			modal.hide();
		}
		window.addEventListener('resize', handleResize);
	});
}

import { loc_ratingDetails } from '@msdocs/strings';
import { PageRatingResult } from '../apis/rating';
import { escape } from '../text-formatting';
import { createRatingDisplay } from './star-rating-display';

export function createRatingDropdown(data: PageRatingResult): HTMLElement {
	const ratingDropdownItem = document.createElement('div');
	ratingDropdownItem.classList.add('dropdown', 'rating-dropdown', 'has-snug-menu', 'has-caret');
	ratingDropdownItem.setAttribute('data-bi-name', 'rating-popover'); // old bi-name kept to ensure reports are not broken.
	const container = document.createElement('div');
	container.classList.add('level-item');
	container.appendChild(ratingDropdownItem);

	const { averageScore, totalCount } = calculateRatingFields(data);

	let progressBarsHtml = ``;
	for (let i = 5; i > 0; i--) {
		let countPercentage = 0;
		if (data.ratingCountByScore[i]) {
			countPercentage = Math.round((data.ratingCountByScore[i] / totalCount) * 100);
		}
		progressBarsHtml += `
			<div class="columns has-margin-none is-mobile">
				<span class="column is-3 has-padding-top-none has-padding-bottom-none has-padding-left-none has-padding-right-small">${i} star</span>
				<progress class="column is-auto has-margin-top-small has-margin-bottom-none has-padding-none progress has-high-contrast-border-white" value="${countPercentage}" max="100">${countPercentage}%</progress>
				<span class="column is-2 has-padding-top-none has-padding-bottom-none has-padding-left-small has-padding-right-none">${countPercentage}%</span>
			</div>`;
	}

	const ratingDropDownHtml = `
		<button class="dropdown-trigger button has-padding-none is-text is-size-small is-icon-only-touch star-rating-display-container" aria-controls="rating-menu" aria-expanded="false" data-bi-name="star-rating-display" aria-label="${escape(
			loc_ratingDetails
		)}" aria-describedby="rating-menu">
		</button>
		<div class="dropdown-menu has-min-width-300 has-padding-medium is-size-small has-body-background-medium" id="rating-menu" role="tooltip">
			<p class="has-margin-none has-padding-none">${averageScore} out of 5 stars</p>
			<div class="has-padding-top-medium has-padding-bottom-medium has-padding-left-none has-padding-right-none">
				${progressBarsHtml}
			</div>
		</div>`;

	ratingDropdownItem.innerHTML = ratingDropDownHtml;

	const ratingDisplayContainer = container.querySelector('.star-rating-display-container');
	const ratingDisplay = createRatingDisplay(averageScore, totalCount);
	ratingDisplayContainer.appendChild(ratingDisplay);

	return container;
}

export interface RatingAverageTotal {
	averageScore: number;
	totalCount: number;
}

export function calculateRatingFields(data: PageRatingResult): RatingAverageTotal {
	let averageScore = 0;
	let totalCount = 0;

	for (const key in data.ratingCountByScore) {
		totalCount += data.ratingCountByScore[key];
		averageScore += parseInt(key) * data.ratingCountByScore[key];
	}

	if (averageScore > 0) {
		averageScore = Math.round((averageScore / totalCount) * 10) / 10;
	} else {
		averageScore = averageScore;
	}

	return { averageScore, totalCount };
}

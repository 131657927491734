import { loc_recommendedContentDisclaimer } from '@msdocs/strings';
import { html, render } from '../lit-html';
import { features } from '../environment/features';
import { removeLocaleFromPath } from '../locale';
import { getRecommendations } from './get-recommendations';

export async function renderRecommendations() {
	if (!features.pageRecommendations) {
		return;
	}

	const contentContainer = document.getElementById('recommended-content-center') as HTMLElement;
	const sidebarContainer = document.getElementById('recommended-content-nav') as HTMLElement;

	if (contentContainer === null || sidebarContainer === null) {
		return;
	}

	try {
		const result = await getRecommendations();
		const recommendations = matchUrl(result).filter(r => r.recommendedTitle); // filter recommendations whose title is missing

		if (!recommendations.length) {
			return;
		}

		const sidebarElement = initSidebarElement(sidebarContainer);
		render(createSidebarRecommendations(recommendations), sidebarElement);
		sidebarContainer.hidden = false;

		const contentElement = initContentElement(contentContainer);
		render(createContentRecommendations(recommendations), contentElement);
		contentContainer.hidden = false;
	} catch (err) {
		// No-op - there was an error retrieving recommendations.
	}
}

function initSidebarElement(container: HTMLElement) {
	const element = document.createElement('div');
	element.classList.add('has-margin-bottom-small', 'has-margin-top-small');

	container.appendChild(element);
	return element;
}

function initContentElement(container: HTMLElement) {
	const element = document.createElement('div');

	container.appendChild(element);
	return element;
}

function createContentRecommendations(recommendations: Recommendation[]) {
	return html`
		<div class="box has-padding-small is-size-small">
			<ul class="is-unstyled">
				${recommendations.map(
					r => html`
						<li class="has-margin-extra-small">
							<div class="media">
								<div class="media-left has-margin-right-none">
									<span aria-hidden="true" class="docon icon docon-text-document"></span>
								</div>
								<div class="media-content has-line-height-reset has-padding-extra-small">
									<a class="is-block" href="${location.origin}${r.recommendedUrl}"
										>${r.recommendedTitle}</a
									>
								</div>
							</div>
						</li>
					`
				)}
			</ul>
			<div class="has-margin-extra-small has-margin-top-small">
				<div class="media">
					<div class="media-left has-margin-right-none">
						<span aria-hidden="true" class="docon icon docon-telescope"></span>
					</div>
					<div class="media-content has-line-height-reset has-padding-extra-small">
						<p class="is-size-extra-small has-line-height-reset">
							${loc_recommendedContentDisclaimer}
						</p>
					</div>
				</div>
			</div>
		</div>
	`;
}

function createSidebarRecommendations(recommendations: Recommendation[]) {
	return html`
		<ul class="list is-unstyled">
			${recommendations.map(
				r => html`
					<li
						class="is-size-small has-padding-none has-padding-bottom-extra-small has-border-none has-margin-none"
					>
						<div class="media">
							<div
								class="media-content has-line-height-reset has-padding-none has-padding-left-extra-small"
							>
								<a class="is-block" href="${location.origin}${r.recommendedUrl}"
									>${r.recommendedTitle}</a
								>
							</div>
						</div>
					</li>
				`
			)}
		</ul>
	`;
}

function matchUrl(results: Recommendation[], url: string = location.pathname) {
	return results.filter(
		x => (x.url || '').toLowerCase() === removeLocaleFromPath(url || '').toLowerCase()
	);
}

interface Recommendation {
	url: string;
	recommendedTitle: string;
	recommendedUrl: string;
}

import { loc_noValue, loc_tableFormat } from '@msdocs/strings';
import { document, window } from '../globals';
import { listenUntilUnload } from '../router/utils';
import { getVisibleTextContent } from '../text-formatting';

const addBorders = (elements: HTMLElement[]) => {
	elements.forEach(wrapper => {
		const table = wrapper.firstElementChild;
		if (wrapper.clientWidth < table.clientWidth) {
			wrapper.classList.add('table-scroll-wrapper-scrollable');
		} else {
			wrapper.classList.remove('table-scroll-wrapper-scrollable');
		}
	});
};

const throttle = (fn: Function, thisArg?: any) => {
	let running = false;

	return (...args: any[]) => {
		if (!running) {
			running = true;
			window.requestAnimationFrame(() => {
				fn.apply(thisArg, args);
				running = false;
			});
		}
	};
};

const tableTypes: { [key: string]: string[] } = {
	definitions: ['has-margin-bottom-large', 'is-left-aligned'],
	nameValue: ['is-left-aligned', 'is-stacked-mobile'],
	parameters: ['has-text-subtle-headers', 'has-margin-bottom-large', 'is-left-aligned'],
	schema: ['has-text-subtle-headers', 'is-left-aligned'],
	dataMatrix: ['has-margin-top-large', 'has-inner-borders']
};

/**
 * Assigns table modifiers for specific table types
 */
function assignTableTypeStyles(table: HTMLTableElement) {
	const classes = table.classList;

	if (classes.contains('definitions')) {
		table.classList.add(...tableTypes.definitions);

		const definitionsHeaderCells = Array.from(table.querySelectorAll('th'));
		for (let i = 0; i < definitionsHeaderCells.length - 1; i++) {
			definitionsHeaderCells[i].classList.add('is-narrow');
		}
	}

	if (classes.contains('parameters')) {
		table.classList.add(...tableTypes.parameters);

		const parametersHeaderCells = Array.from(table.querySelectorAll('th'));
		for (const headerCell of parametersHeaderCells) {
			headerCell.classList.add('has-text-subtle');
		}
	}

	if (classes.contains('schema')) {
		table.classList.add(...tableTypes.schema);

		const schemaHeaderCells = Array.from(table.querySelectorAll('th'));
		for (let i = 0; i < schemaHeaderCells.length - 1; i++) {
			schemaHeaderCells[i].classList.add('is-narrow');
		}
	}

	if (classes.contains('nameValue')) {
		table.classList.add(...tableTypes.nameValue);

		if (!classes.contains('cols3')) {
			const nameValueTableCells = Array.from(table.querySelectorAll('td:nth-child(1)'));
			for (const cell of nameValueTableCells) {
				cell.classList.add('is-one-third-width-tablet');
			}
		}
	}

	if (classes.contains('dataMatrix')) {
		table.classList.add(...tableTypes.dataMatrix);
	}
}

function getTableCaption(table: HTMLTableElement, index: number) {
	const findLastHeadingText = () => {
		let sibling = table.previousElementSibling as HTMLElement;
		let i = 0;
		while (sibling && i < 5) {
			if (sibling.matches('h1,h2,h3,h4,h5,h6')) return getVisibleTextContent(sibling);
			sibling = sibling.previousElementSibling as HTMLElement;
			i++;
		}
		return loc_tableFormat.replace('{index}', (index + 1).toString());
	};
	return findLastHeadingText();
}

function insertTableCaption(table: HTMLTableElement, captionText: string) {
	const caption = document.createElement('caption');
	const textNode = document.createTextNode(captionText);
	caption.appendChild(textNode);
	caption.classList.add('visually-hidden');
	table.insertAdjacentElement('afterbegin', caption);
}

/**
 * Ensure matrix tables are accessible
 * Remove if/when a markdown convention is created to do this automatically.
 */
export function setMatrixTableHeadings(element: HTMLElement) {
	const tables = Array.from(element.querySelectorAll<HTMLTableElement>('.content table'));
	tables.forEach(table => {
		const tableMatrixHeads = Array.from(table.querySelectorAll('thead tr > th')) as HTMLElement[];
		const tbody = table.querySelector('tbody');
		if (!tbody) {
			return;
		}
		const tbodyChildren = Array.from(tbody.children) as HTMLElement[];
		if (!hasMatrixStructure(tableMatrixHeads, tbodyChildren)) {
			return;
		}

		for (const child of tbodyChildren) {
			const tr = child;
			const td = tr.firstElementChild;

			// remove strong element accounts for sxs content, we already know content at this point
			if (td.firstElementChild.nodeName === 'SPAN') {
				td.firstElementChild.textContent = td.firstElementChild.textContent;
				td.lastElementChild.firstElementChild.textContent =
					td.lastElementChild.firstElementChild.textContent;
			} else {
				// accounts for strong as initial element
				td.innerHTML = td.firstElementChild.innerHTML;
			}

			const th = document.createElement('th');
			th.setAttribute('scope', 'row');
			th.innerHTML = td.innerHTML;

			td.parentElement.replaceChild(th, td);
		}

		tableMatrixHeads.forEach(columnHeader => {
			if (columnHeader.textContent === '') {
				return;
			}
			columnHeader.setAttribute('scope', 'col');

			const tableMatrixHeads = Array.from(table.querySelectorAll('thead tr > th'));
			if (!tableMatrixHeads.length || tableMatrixHeads[0].innerHTML !== '') {
				return;
			} // bail if structure is not met

			const tableMatrixRowHeaders = Array.from(
				table.querySelectorAll('tbody tr > td:first-of-type > strong')
			);
			tableMatrixRowHeaders.forEach(rowHeader => {
				const newMarkup = rowHeader.parentElement.parentElement.innerHTML
					.replace('<td><strong>', '<th scope="row">')
					.replace('</strong></td>', '</th>');
				rowHeader.parentElement.parentElement.innerHTML = newMarkup;
			});

			tableMatrixHeads.forEach(columnHeader => {
				if (columnHeader.innerHTML === '') {
					return;
				}
				columnHeader.setAttribute('scope', 'col');
			});
		});
	});
}

export function hasMatrixStructure(
	tableMatrixHeads: HTMLElement[],
	tbodyChildren: HTMLElement[]
): boolean {
	const hasMatrixStructure =
		tableMatrixHeads.length &&
		tableMatrixHeads[0].firstElementChild === null &&
		tableMatrixHeads[0].textContent === '';
	if (!hasMatrixStructure) {
		return false;
	} // bail if structure is not met

	for (const child of tbodyChildren) {
		const tr = child;
		const td = tr.firstElementChild;
		const firstChild = td.firstElementChild;
		const lastChild = td.lastElementChild;

		// quick check
		if (td.nodeName !== 'TD' || !firstChild) {
			return false;
		}

		const hasSxs = td.querySelector('.sxs-lookup');
		const hasMatrixBodyStructure = hasSxs
			? checkSxsTableCellStructure(firstChild)
			: firstChild === lastChild &&
			  td.textContent.trim() === firstChild.textContent.trim() &&
			  firstChild.nodeName === 'STRONG';

		if (!hasMatrixBodyStructure) {
			return false;
		}
	}
	return true;
}

// Look for 2 cases for sxs, if sxs span is first element or sxs is nested in <strong>
export function checkSxsTableCellStructure(element: Element): boolean {
	if (element.nodeName === 'SPAN') {
		const strong = element.firstElementChild;
		return (
			element.hasAttribute('data-ttu-id') &&
			strong &&
			strong.nodeName === 'STRONG' &&
			element.textContent.trim() === strong.textContent.trim() &&
			!strong.firstElementChild
		);
	}
	return false;
}

/**
 * Wrap content tables in a scrollable div element for width purposes.
 * Remove if/when a markdown extension is created to do this automatically.
 * Adds table class and modifiers to certain table types
 */
export function initContentTables() {
	// Wrap all table elements in main content with table wrappers for scrolling.
	const tables: HTMLTableElement[] = Array.from(document.querySelectorAll('.content table'));
	if (!tables.length) {
		return;
	}

	const wrappers = tables.map((table, index) => {
		if (index < 20 && !table.querySelector('caption')) {
			const caption = getTableCaption(table, index);
			insertTableCaption(table, caption);
		}

		table.classList.add('table');

		if (table.classList.length !== 1) {
			assignTableTypeStyles(table);
		}

		// find blank cell values and add an aria label for Narrator
		const tableTds = Array.from(table.querySelectorAll('td'));
		tableTds.forEach(td => {
			if (!td.textContent.trim().length) {
				td.setAttribute('aria-label', loc_noValue);
			}
		});

		const wrapper = document.createElement('div');
		wrapper.classList.add('table-scroll-wrapper');

		table.parentElement.insertBefore(wrapper, table);
		wrapper.appendChild(table);

		return wrapper;
	});

	listenUntilUnload(
		window,
		'resize',
		throttle(() => addBorders(wrappers))
	);

	addBorders(wrappers);
}

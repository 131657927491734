import { user } from '../auth/user';
import { features } from '../environment/features';
import { html, render } from '../lit-html';
import { localStorage } from '../protected-storage';

export const browseHideCompletedStorageKey = 'browse-hide-completed';
export const certHideCompletedStorageKey: string = 'cert-hide-completed';
const pathItemShowThreshold = 6;

/**
 * Checks what the value of the hide completed checkbox
 */
export function initHideCompletedStorage(storageKey: string): boolean {
	if (!features.gamification) {
		return false;
	}
	if (user.isAuthenticated) {
		if (localStorage.getItem(storageKey) === null) {
			localStorage.setItem(storageKey, 'false'); // defaults to false
			return false;
		}
		return localStorage.getItem(storageKey) === 'true';
	}
	return false;
}

// Browse specific hide competed

export function saveBrowseHideCompleted(hideCompleted: boolean) {
	localStorage.setItem(browseHideCompletedStorageKey, `${hideCompleted}`);
}

export function getBrowseHideCompleted(): boolean {
	if (!features.gamification) {
		return false;
	}
	return localStorage.getItem(browseHideCompletedStorageKey) === 'true';
}

export function toggleBrowseHideCompleted() {
	const newValue = !Boolean(localStorage.getItem(browseHideCompletedStorageKey) === 'true');
	localStorage.setItem(browseHideCompletedStorageKey, `${newValue}`);
	return newValue;
}

export function getCheckboxCheckedValue(idSelector: string): boolean {
	const checkbox = document.getElementById(idSelector) as HTMLInputElement;
	if (!checkbox) {
		return false;
	}

	return checkbox.checked;
}

export function handleCertPathInteractivity() {
	if (!features.gamification) {
		const trigger = document.getElementById('path-section-hide-completed');
		if (trigger) {
			trigger.hidden = true;
		}
		return;
	}
	handleCertHideCompleteCheckbox(
		document.getElementById('main'),
		'path-section-hide-completed',
		'#tabpanel-learning-paths [data-learn-uid]'
	);
	showAllPathsAfterFiveComplete(document.getElementById('tabpanel-learning-paths'));
}

// Certification and examination specific hide completed

function handleCertHideCompleteCheckbox(
	container: HTMLElement,
	triggerId: string,
	elementsSelector: string
) {
	const elements = Array.from(container.querySelectorAll(elementsSelector)) as HTMLElement[];
	// markLearnItemsComplete(document.getElementById('learning-paths-list')); // 👈 dev function
	const trigger = container.querySelector(`#${triggerId}`) as HTMLInputElement;
	if (elements.length < pathItemShowThreshold || !trigger) {
		return;
	}
	if (checkForAllComplete(elements)) {
		return;
	}

	// Set inital checkbox value after checking local storage
	const initialValue = initHideCompletedStorage(certHideCompletedStorageKey);
	trigger.checked = initialValue;
	toggleCompletePathDisplay(initialValue, elements);

	container.addEventListener('change', event => {
		const target = event.target instanceof HTMLElement && event.target.closest(`#${triggerId}`);
		if (!target || !user.isAuthenticated || !features.gamification) {
			return;
		}
		const hideCompleted = toggleCertHideCompleted();
		toggleCompletePathDisplay(hideCompleted, elements);
	});
	trigger.parentElement.classList.remove('is-hidden');
}

export function checkForAllComplete(elements: HTMLElement[]): boolean {
	for (const elt of elements) {
		if (elt.dataset.learnStatus !== 'completed') {
			return false;
		}
	}
	return true;
}

export function showHideShowMoreButton(elements: HTMLElement[], hideCompleted: boolean) {
	const pathTabId = document.getElementById('tabpanel-learning-paths');
	const showMoreButton = pathTabId.querySelector('[data-show-more]') as HTMLButtonElement;
	const numberIncomplete = elements.filter(element => element.dataset.learnStatus !== 'completed')
		.length;

	if (hideCompleted && numberIncomplete < pathItemShowThreshold) {
		showMoreButton.classList.add('is-hidden');
	} else {
		showMoreButton.classList.remove('is-hidden');
	}
}

function toggleCompletePathDisplay(hideCompleted: boolean, elements: HTMLElement[]) {
	if (hideCompleted) {
		// when hide completed is selected and show more is not, we want at least 5 paths to show anyway
		const numberOfElementsToAdd = getNumberOfElementsToReplace(elements);
		const replacementElements = findReplacementElements(elements);
		removeExpandableFromParent(replacementElements, numberOfElementsToAdd);

		// place hidden on parent li elements
		elements.forEach(element => hideParentIfComplete(element));
	} else {
		// reset effects of show more / hide completed acrobatics
		resetExpandableOnParents(elements);

		// remove hidden from elements' parent li
		elements.forEach(element => (element.parentElement.hidden = false));
	}
	showHideShowMoreButton(elements, hideCompleted);
}

/**
 * Place hidden on the parent list item of an particular element that has data-learn-status='complete'
 * @param element a single html learning path element
 */
export function hideParentIfComplete(element: HTMLElement): boolean {
	return element.dataset.learnStatus === 'completed'
		? (element.parentElement.hidden = true)
		: (element.parentElement.hidden = false);
}

/**
 * Find number of elements that aren't expandable and are completed
 * @param elements Full list of path elements
 */
export function getNumberOfElementsToReplace(elements: HTMLElement[]): number {
	return elements.filter(
		element =>
			element.dataset.learnStatus === 'completed' &&
			!element.parentElement.classList.contains('expandable')
	).length;
} // these will be hidden automatically, we really just need the number

/**
 * Find first x number of elements that are expandable and not complete
 * @param elements Full list of path elements
 */
export function findReplacementElements(elements: HTMLElement[]) {
	return elements.filter(
		element =>
			element.dataset.learnStatus !== 'completed' &&
			element.parentElement.classList.contains('expandable')
	);
} // these will need to have the expandable class removed

/**
 * Utility to remove the expandable class (for when hide complete is checked and show more is not), add data-expandable-removed="true" to these elements
 * @param replacementElements The full list of elements with .expandable and aren't completed
 * @param numberToRemove The number of element we need to add to the visible list of paths (to get to 5)
 */
export function removeExpandableFromParent(
	replacementElements: HTMLElement[],
	numberToRemove: number
) {
	replacementElements.forEach((element, i) => {
		if (i < numberToRemove) {
			element.parentElement.classList.remove('expandable');
			element.parentElement.dataset.expandableRemoved = 'true';
		}
	});
}

/**
 * Resets the work done by removeExpandableFromParent, targets elements with data-expandable-removed="true"
 * @param elements  Full list of path elements
 */
export function resetExpandableOnParents(elements: HTMLElement[]) {
	elements.forEach(element => {
		if (element.parentElement.dataset.expandableRemoved === 'true') {
			element.parentElement.classList.add('expandable');
		}
	});
}

function toggleCertHideCompleted() {
	const newValue = !Boolean(localStorage.getItem(certHideCompletedStorageKey) === 'true');
	localStorage.setItem(certHideCompletedStorageKey, `${newValue}`);
	return newValue;
}

/**
 * Expands the show more button when more than five paths are complete
 * @param container HTML Element within which to query select.
 */
function showAllPathsAfterFiveComplete(container: HTMLElement) {
	const firstFiveComplete = determineFirstFiveComplete(container);
	if (!firstFiveComplete) {
		return;
	}
	const showMoreButton = container.querySelector(
		'[aria-controls="learning-paths-list"]'
	) as HTMLButtonElement;
	showMoreButton.click();
}

/**
 * Utility function to determine if the first five paths are completed
 * @param container HTML Element within which to query select.
 */
export function determineFirstFiveComplete(container: HTMLElement) {
	const elements = Array.from(container.querySelectorAll(`[data-learn-uid]`)) as HTMLElement[];
	const loopThreshold = pathItemShowThreshold - 1;

	if (elements.length < loopThreshold) {
		return false;
	}

	for (let i = 0; i < loopThreshold; i++) {
		const isComplete = elements[i].dataset.learnStatus === 'completed';

		if (!isComplete) {
			return false;
		}
	}
	return true;
}

/**
 * 👩‍💻👨‍💻 Development function only: marks paths within a section as complete
 * @param container HTML Element within which to query select.
 * @param numberToMarkComplete If supplied marks that number of paths complete, otherwise marks all paths complete.
 */
export function markLearnItemsComplete(container: HTMLElement, numberToMarkComplete?: number) {
	const elementsToMarkComplete = Array.from(
		container.querySelectorAll('[data-learn-uid]')
	) as HTMLElement[];
	const threshold = numberToMarkComplete ? numberToMarkComplete : elementsToMarkComplete.length;
	const tagInnerTemplate = html`Completed<span
			class="progress-icon docon"
			role="presentation"
		></span>`;

	for (let i = 0; i < threshold; i++) {
		elementsToMarkComplete[i].dataset.learnStatus = 'completed';
		const tag = elementsToMarkComplete[i].querySelector('.progress-tag');

		if (tag) {
			tag.classList.remove('is-in-progress');
			tag.classList.add('is-complete');
			tag.classList.remove('is-hidden');
			render(tagInnerTemplate, tag);
		}
	}
}

import { render } from '../../lit-html';
import { PropertyChangedEvent } from '../../view-model';
import { gridItemsPerPage, listItemsPerPage } from './constants';
import { SearchViewModel, SearchVMConfig } from './view-model';
import { facetSearchTemplate } from './view/index';
import { mobileMenuTemplate, moduleMenuContent } from './view/mobile-menu';

export async function initFacetSearch<TResult>(
	config: SearchVMConfig<TResult>,
	container: HTMLElement
): Promise<void> {
	if (!container) {
		throw new Error('Invalid container provided to Facet Search.');
	}

	const {
		fetch,
		autocomplete,
		layout,
		hideCompleted,
		resultTemplate,
		scoringProfile,
		rss,
		headingLevel
	} = config;
	// note that current search service just sends back 24 anyway, because it doesn't care about rules
	const resultsPerPage = layout === 'grid' ? gridItemsPerPage : listItemsPerPage;

	const search = new SearchViewModel(fetch, resultsPerPage, {
		autocomplete,
		hideCompleted,
		scoringProfile,
		rss
	});

	await search.initialized;

	render(facetSearchTemplate(search, layout, resultTemplate, headingLevel), container);

	search.subscribe(PropertyChangedEvent, () => {
		render(facetSearchTemplate(search, layout, resultTemplate, headingLevel), container);
		if (moduleMenuContent) {
			render(mobileMenuTemplate(search), moduleMenuContent);
		}
	});
}

import { authStatusDetermined } from '../auth/index';
import { features } from '../environment/features';
import { EventBus } from '../event-bus';
import { Quiz } from '../learn/quiz';
import { QuizValidationRequestEvent } from '../learn/quiz-events';
import { CombinedProgressService } from '../learn/unit-progress';
import { UnitModuleConfig } from './config';

export function setupQuizCompletionType(
	combinedProgressService: CombinedProgressService,
	bus: EventBus,
	config: UnitModuleConfig
) {
	const { completionType, unitId, currentUnit } = config;

	if (completionType !== 'quiz') {
		return;
	}

	if (!features.gamification || !combinedProgressService) {
		const quizForms = Array.from(document.querySelectorAll('form.quiz-form')) as HTMLFormElement[];
		quizForms.forEach(form => (form.hidden = true));
	}

	// Set up event listener for form to try to complete the unit type on submit.
	const quizForms = Array.from(document.querySelectorAll('form.quiz-form')) as HTMLFormElement[];
	quizForms.forEach(form => new Quiz(form, bus, currentUnit));

	bus.subscribe(
		QuizValidationRequestEvent,
		async ({ instance, answers }: QuizValidationRequestEvent) => {
			await authStatusDetermined;
			try {
				await combinedProgressService.validateQuiz(unitId, answers);
			} catch (responseStatus) {
				if (responseStatus === 429) {
					instance.handleTooManyAttempts();
				} else {
					throw responseStatus;
				}
			}
		}
	);
}

export function assignQuizUidToTag(config: UnitModuleConfig) {
	const { currentUnit, completionType } = config;

	if (completionType === 'quiz') {
		const quizXp = document.querySelector('.quiz.modal .xp-tag') as HTMLElement;
		if (quizXp) {
			quizXp.dataset.progressUid = currentUnit.uid;
		}
	}
}

import { getMeta } from '../meta';

// contentPath is intended to receive a github URL - a full URL in a POST body is making the Akamai WAF reject the request
// ...so strip the protocol and domain. Worst case if something slips through here, Akamai could reject it.
export function getContentPath(): string {
	const contentPath =
		getMeta('original_content_git_url') || getMeta('original_ref_skeleton_git_url') || '';
	let cp = contentPath;
	if (cp !== '' && cp.substring(0, 8) === 'https://') {
		try {
			const cpUrl = new URL(contentPath);
			cp = `${cpUrl.pathname}${cpUrl.search}`;
		} catch (ex) {
			// remove https at least if the URL constructor throws from contentPath being an invalid URL - e.g. https://
			cp = cp.substring(8);
		}
	}
	return cp;
}

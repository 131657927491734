import { documentNavigationHandler } from '../router/document-navigation-handler';
import { router } from '../router/router';
import { UnitModuleConfig } from './config';

export function handleUnitRouting(config: UnitModuleConfig) {
	const { module, isChromeless, userLocale } = config;
	if (!isChromeless) {
		router.enable({
			canHandle: url => {
				const isInModule = !!module.units.find(
					unit => unit.url === url.pathname || `/${userLocale}${unit.url}` === url.pathname
				);
				return isInModule;
			},
			handle: documentNavigationHandler,
			params: []
		});
	}
}

import { loc_showLess, loc_showMore } from '@msdocs/strings';
import { html, TemplateResult } from '../lit-html';

export function initShowMoreSections(element: HTMLElement) {
	element.addEventListener('click', e => {
		const button =
			e.target instanceof HTMLElement &&
			(e.target.closest('button[data-show-more]') as HTMLAnchorElement);
		if (!button) {
			return;
		}

		const controlId = button.getAttribute('aria-controls');
		const targetContainer = document.getElementById(controlId);

		if (!targetContainer) {
			return;
		}

		const expanded = button.getAttribute('aria-expanded') === 'true';
		button.setAttribute('aria-expanded', `${!expanded}`);

		const expandableElements = Array.from(
			targetContainer.querySelectorAll('.expandable')
		) as HTMLElement[];

		for (const elt of expandableElements) {
			elt.classList.toggle('is-expanded');
		}

		// get the span that is not the icon or docon
		const stringSpan = button.querySelector('.show-more-text') as HTMLSpanElement;
		if (!stringSpan) {
			return;
		}

		const expandButtonStrings = {
			show: button.getAttribute('data-title') || loc_showMore,
			hide: button.getAttribute('data-expanded-title') || loc_showLess
		};
		stringSpan.innerText = expanded ? expandButtonStrings.show : expandButtonStrings.hide;
	});
}

/**
 * Creates a show more button TemplateResult
 * @param showMoreId The id of the element that contains elements with the .expandable class.
 * @param customClasses A string with space delimited classes that will provide custom appearance.
 */
export function createShowMoreButton(showMoreId: string, customClasses?: string): TemplateResult {
	const classes = customClasses ? customClasses : 'button is-text is-text-primary';
	return html`
		<button class="${classes}" aria-controls="${showMoreId}" aria-expanded="false" data-show-more>
			<span class="show-more-text">${loc_showMore}</span>
			<span class="icon" aria-hidden="true">
				<span class="expanded-indicator docon docon-chevron-up-light"></span>
			</span>
		</button>
	`;
}

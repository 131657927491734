import { AutoCompleteSuggestionDetail } from '../autocomplete';
import { jsllReady } from '../../bi';
import type { UISearchArgs } from './view-model';
import { getMeta } from '../../meta';

export function instrumentAutocompleteChange(
	event: CustomEvent<AutoCompleteSuggestionDetail<string>>
) {
	instrumentAutocompleteSelection(event.detail);
}

export function instrumentAutocompleteDisplayed(event: CustomEvent<string[]>) {
	instrumentAutocompleteList(event.detail);
}

/**
 * Send data about the selection that was chosen
 * @param param event object containing the suggestion chosen and term
 */
export function instrumentAutocompleteSelection({
	suggestion,
	term
}: AutoCompleteSuggestionDetail<string>) {
	jsllReady.then(awa => {
		awa.ct.captureContentPageAction({
			behavior: awa.behavior.SEARCHAUTOCOMPLETE,
			actionType: awa.actionType.OTHER,
			content: {
				srchq: term,
				srchtype: 'auto suggest',
				resultselected: suggestion
			}
		});
	});
}
/**
 * Send analytics data regarding the list of suggestions
 * @param suggestions The list of suggested strings
 */
export function instrumentAutocompleteList(suggestions: string[]) {
	const content: { [key: string]: string | number }[] = [
		{
			srchtype: 'auto suggest' // search type
		}
	];

	suggestions.forEach((suggestion, index) => {
		content.push({
			cN: suggestion,
			sN: index
		});
	});

	jsllReady.then(awa => awa.ct.captureContentPageAction({ content }));
}

/**
 *
 * @param args The arguments used by the Search View Model.
 * @param resultUrls x<TResults> => x.url and array of string urls
 * @param categoryLabel Category being an unfortunate and unique cases, we calculate the label in the view model.
 */
export function instrumentSearchResults(
	args: UISearchArgs,
	resultUrls: string[],
	count: number,
	categoryLabel: string
) {
	jsllReady.then(function (awa) {
		awa.ct.captureContentPageAction({
			behavior: awa.behavior.OTHER,
			actionType: awa.actionType.OTHER,
			content: {
				event: 'uhf-search-results',
				category: categoryLabel,
				term: args.terms,
				scope: args.scope || '',
				results: resultUrls.length,
				resultUrls,
				skip: args.skip,
				dataSource: args.dataSource || '',
				scoringProfileName: args.scoringProfile || '',
				experimentId: getMeta('experiment_id'),
				hideCompleted: args.hideCompleted || '',
				count
			}
		});
	});
}

import { contentAttrs, getName, jsllReady } from './bi';
import { document, window } from './globals';
import { unbreakText } from './text-formatting';

// http://stackoverflow.com/a/30810322/725866

export const clipboardCopySupported =
	document.queryCommandSupported && document.queryCommandSupported('copy');

/**
 * Copies text to the clipboard.
 * @param text The text to copy
 * @param owner The owner element, for BI purposes.
 */
export function clipboardCopy(text: string, owner: HTMLElement): boolean {
	if (!clipboardCopySupported) {
		return false;
	}
	const txt = document.createElement('textarea');
	txt.setAttribute(contentAttrs.name, getName(owner));
	txt.textContent = text;
	txt.classList.add('visually-hidden');
	document.body.appendChild(txt);
	txt.select();
	try {
		return document.execCommand('copy');
	} catch (ex) {
		return false;
	} finally {
		document.body.removeChild(txt);
	}
}

let unprintable = false;

/**
 * Subscribe to the copy event and redo the copy when zero-width string is encountered.
 * Publish copy BI events.
 */
export function interceptCopy() {
	function handleCopy(event: ClipboardEvent) {
		const value = window.getSelection().toString();
		const cleanValue = unbreakText(value);

		if (clipboardCopySupported && value !== cleanValue && !unprintable) {
			// set the flag published in the BI events tracking whether the copy included
			// an unprintable char.
			unprintable = true;

			// redo the copy on the user's behalf to ensure they do not have
			// zero-width space in their clipboard.
			clipboardCopy(cleanValue, event.target as HTMLElement);
			return;
		}

		jsllReady.then(awa =>
			awa.ct.capturePageAction(event.target as HTMLElement, {
				actionType: awa.actionType.OTHER,
				behavior: awa.behavior.COPY,
				content: {
					event: 'copy',
					name: getName(event.target as HTMLElement),
					value,
					unprintable
				}
			})
		);

		// reset the bi flag.
		unprintable = false;
	}
	document.addEventListener('copy', handleCopy, { passive: true } as any);
}

export function disableScrollbar(
	parent: HTMLElement = document.documentElement,
	container: HTMLElement = document.documentElement || document.body,
	scrollPosition: number = (document.documentElement || document.body).scrollTop
) {
	container.style.left = '0';
	container.style.right = '0';
	container.style.top = `-${scrollPosition}px`;
	container.style.position = 'fixed';

	return (restoreScrollPosition = true) => {
		container.style.left = '';
		container.style.right = '';
		container.style.top = '';
		container.style.position = '';

		if (restoreScrollPosition) {
			parent.scrollTop = scrollPosition;
		}
	};
}

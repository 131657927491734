import { loc_caution, loc_important, loc_note, loc_tip, loc_warning } from '@msdocs/strings';
import { breakText, WordBreakFlags } from '../text-formatting';
import { setMatrixTableHeadings } from './modify-tables';

export function fixContent() {
	fixAlerts();
	fixDivAnchors();
	addWbrViaClass(document.body);
	setMatrixTableHeadings(document.body);
}

// // new class -> old alert class -> icon :(
// 	$alerts:
// 	($danger, 'warning', get-icon-code('status-failure-outline')), // red
// 	($warning, 'caution', get-icon-code('status-warning-outline')), // "yellow"
// 	($success, 'tip', get-icon-code('lightbulb')), // green
// 	($primary, 'important', get-icon-code('status-info-outline')),  // purple
// 	($info, 'note', get-icon-code('status-error-outline')), // cyan
// 	($primary, 'disclaimer', get-icon-code('status-error-outline')), // .disclaimer
// 	($info, 'previous-version-disc', get-icon-code('status-info-outline')), // .previous-versions-disc
// 	($primary, 'visual-studio-disc', get-icon-code('status-error-outline')); // .visual-studio-disc

function fixAlerts() {
	const alertMap: { [name: string]: { class: string; docon: string; title: string } } = {
		TIP: { class: 'is-success', docon: 'lightbulb', title: loc_tip },
		NOTE: { class: 'is-info', docon: 'status-error-outline', title: loc_note },
		IMPORTANT: { class: 'is-primary', docon: 'status-info-outline', title: loc_important },
		WARNING: { class: 'is-warning', docon: 'status-warning-outline', title: loc_warning },
		CAUTION: { class: 'is-danger', docon: 'status-failure-outline', title: loc_caution }
	};

	const legacyAlerts = Array.from(
		document.querySelectorAll('.TIP, .NOTE, .IMPORTANT, .WARNING, .CAUTION')
	);
	legacyAlerts.forEach(alert => {
		const legacyName = /TIP|NOTE|IMPORTANT|WARNING|CAUTION/.exec(alert.className)[0];
		alert.className = `alert ${alertMap[legacyName].class}`;

		const firstParagraph = alert.firstElementChild;
		if (!firstParagraph || !(firstParagraph instanceof HTMLParagraphElement)) {
			return;
		}

		firstParagraph.classList.add('alert-title');
		firstParagraph.textContent = alertMap[legacyName].title;
		firstParagraph.insertAdjacentHTML(
			'afterbegin',
			`<span class="docon docon-${alertMap[legacyName].docon}" aria-hidden="true"></span> `
		);
	});
}

/**
 * Fixes anchors that are authored like this:
 * > [!div class="button"]
 * > [Download Visual Studio 2017](https://visualstudio.microsoft.com/downloads/?utm_medium=microsoft&utm_source=docs.microsoft.com&utm_campaign=button+cta&utm_content=download+vs2017)
 */
function fixDivAnchors() {
	Array.from(
		document.querySelectorAll(
			'.content div.button a, .content div.step-by-step a, .content div.nextstepaction a'
		)
	).forEach(anchor => {
		// add the .button.is-primary classes to the anchor
		anchor.classList.add('button', 'is-primary', 'has-text-wrap');
		// remove the div's class.
		const container = anchor.closest('div.button, .step-by-step');
		if (container) {
			container.className = '';
		}
	});
}

export function addWbrViaClass(element: HTMLElement) {
	//  add <wbr> elements on the front end wtih the .break-text class on a parent element in conjuntion with the (optional) .dots-only class
	const xrefs = Array.from(element.querySelectorAll('.break-text > .xref'));

	xrefs.forEach(node => {
		if (node.firstElementChild !== null) {
			return;
		}

		const dotsOnly = node.parentElement.classList.contains('dots-only');
		// the `.replace(/</g, '&lt;').replace(/>/g, '&gt;')` on the following line is there because:
		// 1. the occurance of <> branckets in text content.
		const breakPattern = dotsOnly ? WordBreakFlags.Dot : WordBreakFlags.Dot | WordBreakFlags.Case;
		const replacementHTML = breakText(
			node.textContent.replace(/</g, '&lt;').replace(/>/g, '&gt;'),
			breakPattern
		);
		node.innerHTML = replacementHTML;
	});
}

import { features } from '../environment/features';
import { html, TemplateResult } from '../lit-html';
import { numberFormatCompact } from '../number-format';
import { createRatingTemplate } from '../rating/star-rating-display';

export function createSearchResultRatingTemplate(rating: {
	averageScore: number;
	totalCount: number;
}): TemplateResult | '' {
	if (!rating || !features.starRatings) {
		return '';
	}
	return html`
		<li>
			<div class="is-size-small has-text-subtle">
				${createRatingTemplate(rating.averageScore)}
				<span>${rating.averageScore} (${numberFormatCompact.format(rating.totalCount)})</span>
			</div>
		</li>
	`;
}

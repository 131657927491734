import { loc_close } from '@msdocs/strings';
import { ImageBrowser } from './components/image-browser/image-browser';
import { initInstrumentation } from './components/image-browser/instrumentation';
import { Modal } from './components/modal/modal';
import { listenUntilUnload } from './router/utils';

/**
 * Create array of links from anchor elements.
 * @param mainElement
 */
export function initializeLightBox(mainElement: Element) {
	initInstrumentation();

	const className = 'lightbox-enabled';
	const elements = Array.from(
		mainElement.querySelectorAll('a[href$="#lightbox"]')
	) as HTMLAnchorElement[];
	const browser = new ImageBrowser();

	elements.forEach(elem => {
		elem.classList.add(className);
		elem.setAttribute('data-bi-name', 'lightbox');
		elem.setAttribute('aria-haspopup', 'dialog');

		listenUntilUnload(elem, 'click', event => {
			event.preventDefault();

			const element = document.createElement('div');
			element.classList.add('modal-content');
			browser.open(element, { url: elem.href, alt: elem.querySelector('img').alt });

			new Modal(element).show().then(() => browser.close());
			element.insertAdjacentHTML(
				'afterend',
				`<button class="modal-close-button is-large modal-close" aria-label="${loc_close}" autofocus></button>`
			);
		});
	});
}

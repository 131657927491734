import { cloneAsLeaf } from './transforms';
import { TocNode } from './types';
import { walk } from './walk';

/**
 * Finds the node path to the specified location matching on origin and pathname.
 * - Continues to search until passing the index of the last clicked node.
 * - Prefers matches with same hash.
 */
export function findLocation(
	nodes: TocNode[],
	lastClicked: number,
	location: { origin: string; pathname: string; hash: string }
) {
	let match: TocNode;
	let matchAncestors: TocNode[];
	let i = 0;
	walk(nodes, (node, ancestors) => {
		if (
			node.url &&
			node.url.originAndPathnameEquals(location) &&
			(node.url.hash === '' || node.url.hash === location.hash)
		) {
			if (node.children) {
				cloneAsLeaf(node);
			} else {
				match = node;
				matchAncestors = ancestors.slice(0);
			}
		}
		// Continue walking if...
		const continueWalking =
			// We haven't found a match
			!match ||
			// Or our match might not be the last-clicked match (some TOCs have multiple nodes with the same href)
			i < lastClicked + ancestors.length || // ancestors.length is a fudge factor to account for synthetic node insertion
			// Or there might be a more complete match (some TOCs have multiple nodes with the same pathname, differing only by hash)
			(location.hash && location.hash !== '#' && match.url.hash !== location.hash);
		i++;
		return continueWalking;
	});

	if (!match) {
		return [];
	}

	return [...matchAncestors, match];
}

import { loc_sharedArticleSubject } from '@msdocs/strings';
import { allowsWeChat, buildShareQR } from './wechat-qr';

/**
 * Mapping of platforms to sharing link href functions
 * NOTE: the `url` and `title` arguments are already URI encoded.
 */
const sharing: { [platform: string]: (url: string, title: string) => string } = {
	facebook: (url: string) => `https://www.facebook.com/sharer/sharer.php?u=${url}`,
	twitter: (url: string, title: string) =>
		`https://twitter.com/intent/tweet?original_referer=${url}&text=${title}&tw_p=tweetbutton&url=${url}`,
	linkedin: (url: string) => `https://www.linkedin.com/cws/share?url=${url}`,
	email: (url: string, title: string) => {
		const subject = encodeURIComponent(loc_sharedArticleSubject).replace(
			encodeURIComponent('{articleTitle}'),
			title
		);
		const body = `${title}${encodeURIComponent('\n\n')}${url}`;
		return `mailto:?subject=${subject}&body=${body}`;
	},
	weibo: (url: string, title: string) =>
		`http://service.weibo.com/share/share.php?title=${title}&url=${url}`
};

export function initSharingLinks(container: Element, url: string, title: string) {
	const encodedTitle = encodeURIComponent(title);
	const campaignUrl = (url += (url.indexOf('?') !== -1 ? '&' : '?') + 'WT.mc_id=');
	for (const platform of Object.keys(sharing)) {
		const anchor = container.querySelector(`.share-${platform}`) as HTMLAnchorElement;
		if (anchor === null) {
			continue;
		}
		const encodedUrl = encodeURIComponent(campaignUrl + platform);
		const hrefFunc = sharing[platform];
		anchor.href = hrefFunc(encodedUrl, encodedTitle);
	}

	buildShareQR(container.querySelector('canvas.qr-code'), url, allowsWeChat);
}

export function removeSharePageAction() {
	document.querySelectorAll('div.sharing').forEach(div => div.remove());
}

import { loc_prerequisiteOptionDescFormat } from '@msdocs/strings';
import { html, render, unsafeHTML } from '../../lit-html';

export function renderPrerequisiteOptionDescription(container: HTMLElement) {
	const optionDescElements = Array.from(
		container.querySelectorAll<HTMLElement>(`.prerequisite-option-desc`)
	);
	const optionsLength = optionDescElements.length;
	optionDescElements.forEach((element, i) => {
		const number = optionsLength === 1 ? '' : i + 1;
		const optionDesc =
			optionsLength === 1
				? loc_prerequisiteOptionDescFormat.replace('Option {number}:', '')
				: loc_prerequisiteOptionDescFormat.replace('{number}', number.toString());
		const optionDescHtml = html`${unsafeHTML(optionDesc)}`;
		render(optionDescHtml, element);
	});
}

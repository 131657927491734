import {
	loc_email,
	loc_facebook,
	loc_linkedin,
	loc_shareYourAchievement,
	loc_twitter
} from '@msdocs/strings';
import { initSharingLinks } from '../sharing';
import { msDocs } from '../globals';

export function createSharingLinks(pathname: string, title: string) {
	if (!msDocs.data.hasShare) {
		return null;
	}

	const container = document.createElement('div');
	container.setAttribute('data-bi-name', 'share-links');

	const sharingLinksHtml = `
		<p class="has-margin-none has-text-weight-semibold is-inline-desktop">${loc_shareYourAchievement}</p>
		<p class="has-margin-none is-text-centered is-inline-desktop" data-bi-name="share-links">
			<a title="${loc_facebook}" class="share-facebook is-undecorated is-inline-block has-padding-small has-padding-none-tablet" data-bi-name="facebook">
				<span class="has-text-subtle icon docon docon-brand-facebook" aria-hidden="true"></span>
			</a>
			<a title="${loc_twitter}" class="share-twitter is-undecorated is-inline-block has-padding-small has-padding-none-tablet" data-bi-name="twitter">
				<span class="has-text-subtle icon docon docon-brand-twitter" aria-hidden="true"></span>
			</a>
			<a title="${loc_linkedin}" class="share-linkedin is-undecorated is-inline-block has-padding-small has-padding-none-tablet" data-bi-name="linkedin">
				<span class="has-text-subtle icon docon docon-brand-linkedin" aria-hidden="true"></span>
			</a>
			<a title="${loc_email}" class="share-email is-undecorated is-inline-block has-padding-small has-padding-none-tablet" data-bi-name="email">
				<span class="has-text-subtle icon docon docon-mail-message-fill" aria-hidden="true"></span>
			</a>
		</p>`;

	container.innerHTML = sharingLinksHtml;

	initSharingLinks(container, location.origin + pathname, title);

	return container;
}

//
// This file is generated by 'strings\build\taxonomy.js'
// If you need to make a change, update the build script
//

import {
	loc_certifications_fundamentals,
	loc_certifications_mce,
	loc_certifications_mcsa,
	loc_certifications_mcsd,
	loc_certifications_mcse,
	loc_certifications_mos,
	loc_certifications_mta,
	loc_certifications_role_based,
	loc_certifications_specialty
} from '@msdocs/strings';

export const certificationNames = {
	fundamentals: loc_certifications_fundamentals,
	mce: loc_certifications_mce,
	mcsa: loc_certifications_mcsa,
	mcsd: loc_certifications_mcsd,
	mcse: loc_certifications_mcse,
	mos: loc_certifications_mos,
	mta: loc_certifications_mta,
	'role-based': loc_certifications_role_based,
	specialty: loc_certifications_specialty
};

export type CertificationId = keyof typeof certificationNames;

import { loc_ratingComment, loc_tellUsWhatYouThink } from '@msdocs/strings';

export function createRatingComment() {
	const container = document.createElement('div');

	const ratingCommentHtml = `<div class="has-padding-small">
			<textarea rows="4" maxlength="900" placeholder="${loc_tellUsWhatYouThink}" class="textarea rating-comments is-radiusless" aria-label="${loc_ratingComment}"></textarea>
		</div>`;

	container.innerHTML = ratingCommentHtml;

	return container;
}

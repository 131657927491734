import {
	loc_fair,
	loc_good,
	loc_great,
	loc_poor,
	loc_tellUsWhatYouThink,
	loc_terrible
} from '@msdocs/strings';
import { generateElementId } from '../html';

const ratingTexts = [loc_terrible, loc_poor, loc_fair, loc_good, loc_great];

export function createRatingControl(
	score: number,
	size: 2 | 3,
	textSize: 3 | 5 | 6,
	customLayout: 'is-stacked' | 'is-centered' | '',
	showHint: boolean,
	onRate: (score: number) => void
): HTMLElement {
	const container = document.createElement('fieldset');
	container.classList.add('star-rating-control');
	container.setAttribute('data-bi-name', 'star-rating-control');

	const starSize = `is-size-${size}`;
	const messageSize = `is-size-${textSize}`;

	const radioName = generateElementId();
	const starListHtml = [];

	for (let i = 0; i < 5; i++) {
		const id = generateElementId();
		starListHtml.push(`
			<input value="${
				i + 1
			}" id="${id}" type="radio" name="${radioName}" aria-labelledby="${id}" class="visually-hidden ${
			score - 1 >= i ? 'is-selected' : ''
		}" data-bi-name="rating-${i + 1}"/>
			<label for="${id}">
				<span class="visually-hidden">${ratingTexts[i]}</span>
				<span aria-hidden="true" class="docon rating-star"></span>
			</label>`);
	}

	let ratingScoreHtml = `
			<div class="star-layout ${customLayout}">
				<div class="${starSize} star-collection ${customLayout}">
					${starListHtml.join('')}
				</div>
				<output for="ax1 ax2 ax3 ax4 ax5" class="rating-output ${messageSize} ${customLayout}"></output>
			</div>`;

	if (showHint) {
		ratingScoreHtml += `
			<div class="rating-hint">
				<span>${loc_tellUsWhatYouThink}</span>
			</div>`;
	}

	container.innerHTML = ratingScoreHtml;
	const outputElement = container.querySelector<HTMLElement>('.rating-output');

	if (score !== 0) {
		const selectedRatingInput = container.querySelector<HTMLInputElement>(
			`input[value="${score}"]`
		);
		selectedRatingInput.checked = true;

		outputElement.textContent = selectedRatingInput.nextElementSibling.textContent;
	}

	container.onmouseover = event => {
		const label = (event.target as Element).closest('label');
		if (!label) {
			return;
		}

		outputElement.textContent = label.textContent;
	};

	container.onmouseleave = () => {
		outputElement.textContent = '';
	};

	container.onchange = event => {
		const target = event.target as HTMLInputElement;
		const currentRating = parseInt(target.value);
		const inputs = container.querySelectorAll('.star-collection input');

		for (let i = 0; i < inputs.length; i++) {
			if (currentRating - 1 >= i) {
				inputs[i].classList.add('is-selected');
			} else {
				inputs[i].classList.remove('is-selected');
			}
		}

		onRate(currentRating);

		if (target.nextElementSibling) {
			outputElement.textContent = target.nextElementSibling.textContent;
		}
	};

	return container;
}

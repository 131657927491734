import { document } from '../globals';
import { scrollTo } from '../scroll-to';

export function homePage() {
	const dirLink = document.querySelector(".home-greeting-container a[href='#docs-directory']");

	if (dirLink === null) {
		return;
	}

	dirLink.addEventListener('click', e => {
		e.preventDefault();
		const directoryOffset = document.querySelector('#docs-directory').getBoundingClientRect().top;
		scrollTo(directoryOffset, 500);
	});
}

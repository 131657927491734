import { user } from '../auth/user';
import { updateUrlSearchFromMap } from '../query-string';
import { initSharingLinks } from '../sharing';
import { createAssessmentBranchSelector, getAssessmentBranch } from './branches';
import { getBranches } from './model';
import { currentAssessmentNavigation } from './navigation';
import {
	assessmentAuthReferralKey,
	AssessmentNavigation,
	assessmentSignOutReferralKey
} from './types';

export function updateAssessmentChrome(url: URL, title: string) {
	handleAssessmentSignOutLinks();
	updateAssessmentSignInLinks();
	updateBookmarkButton(currentAssessmentNavigation.mode);
	updateShareMenu(currentAssessmentNavigation, url, title);
	createAssessmentBranchSelector(currentAssessmentNavigation, getAssessmentBranch(), getBranches());
}

function handleAssessmentSignOutLinks() {
	if (!user.isAuthenticated) {
		return;
	}
	const signOutLinks = Array.from(
		document.querySelectorAll('.docs-sign-out')
	) as HTMLAnchorElement[];
	signOutLinks.forEach(updateAnchorSearchWithKey(assessmentSignOutReferralKey));
}

function updateAssessmentSignInLinks() {
	if (user.isAuthenticated) {
		return;
	}
	const signInLinks = Array.from(document.querySelectorAll('.docs-sign-in')) as HTMLAnchorElement[];
	signInLinks.forEach(updateAnchorSearchWithKey(assessmentAuthReferralKey));
}

function updateAnchorSearchWithKey(
	key: string
): (value: HTMLAnchorElement, index: number, array: HTMLAnchorElement[]) => void {
	return link => {
		const searchParams = new URLSearchParams(location.search);
		searchParams.append(key, 'true');
		link.href = `${location.origin}${location.pathname}?${searchParams}`;
	};
}

function updateBookmarkButton(mode: string) {
	const bookmarkButton = document.querySelector<HTMLButtonElement>('button.bookmark');
	if (!bookmarkButton) {
		return;
	}

	if (mode === 'home') {
		bookmarkButton.hidden = false;
	} else {
		bookmarkButton.hidden = true;
	}
}

function updateShareMenu(action: AssessmentNavigation, url: URL, title: string) {
	const shareElement = document.querySelector<HTMLDivElement>('.sharing');
	if (!shareElement) {
		return;
	}

	url = getAssessmentSharingUrl(action, url);
	initSharingLinks(document.body, url.toString(), title);
}

function getAssessmentSharingUrl(action: AssessmentNavigation, url: URL) {
	switch (action.mode) {
		case 'guidance':
			if (!user.isAuthenticated) {
				url = updateUrlSearchFromMap({
					id: action.guidance.assessmentId,
					mode: null,
					session: null
				});
			}
			break;
		case 'pre-assessment':
		case 'questionnaire':
		case 'invalid':
			url = updateUrlSearchFromMap({
				category: null,
				id: action.session.assessmentId,
				mode: null,
				question: null,
				session: null
			});
			break;
	}
	return url;
}

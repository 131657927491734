import { LevelId } from '../name-maps/level';
import { toQueryString, parseQueryString } from '../query-string';
import { fetchWithTimeout, createRequest } from '../fetch';
import { isLocalhost, isPPE, isReview } from '../is-production';
import { user } from '../auth/user';
import { apis } from '../environment/apis';
import { Interests } from '../learner-interests/model';

export interface BrowseConfig {
	filterCriteria: BrowseFilterCriteria;
	facets: ['roles', 'levels', 'products', 'resource_type'];
	hideCompleted: boolean;
	orderBy: {
		default: string;
		terms: string | null;
	};
}

export const browseConfig: BrowseConfig = {
	filterCriteria: { roles: [], levels: [], products: [], resource_type: [] },
	facets: ['roles', 'levels', 'products', 'resource_type'],
	hideCompleted: true,
	orderBy: {
		default: 'popularity desc,last_modified desc,title',
		terms: null as string
	}
};

export function getTopByType(browseType: SingleRequestBrowseTypes) {
	return browseType === 'home' ? 9 : 10;
}

export function getBrowseContent(
	criteria: BrowseRequestCriteria,
	browseType: SingleRequestBrowseTypes
): Promise<BrowseResponse> {
	const query = {
		branch: getBranch(browseType),
		locale: criteria.locale,
		terms: criteria.terms,
		facet: [] as any[],
		$filter: createODataFilterOptions(criteria.filter),
		$orderBy: criteria.order,
		$skip: criteria.skip,
		$top: criteria.top,
		excludeUids: criteria.excludeUids
	};
	const url = `${getBrowserServerUrl(browseType)}?${toQueryString(query, true)}`;

	const init = { method: 'GET' };

	return fetchWithTimeout(createRequest(url, init)).then(response => {
		if (response.ok) {
			return response.json();
		}

		throw { error: response.json() };
	});
}

export function createODataFilterOptions(filterCriteriaObject: BrowseFilterCriteria): string {
	const queries: string[] = [];

	for (const facetKey in filterCriteriaObject) {
		const facetContainer = filterCriteriaObject[facetKey as keyof BrowseFilterCriteria] || [];

		const query = facetContainer
			.map(value => {
				return facetKey !== 'resource_type' && facetKey !== 'type'
					? `(${facetKey}/any(t: t eq '${value}'))`
					: `(${facetKey} eq '${value.replace('_', ' ')}')`;
			})
			.join(' or ');

		if (query.length) {
			queries.push(`(${query})`);
		}
	}

	return queries.join(' and ');
}

function getBranch(browseType: SingleRequestBrowseTypes): string {
	if (isLocalhost || isPPE) {
		return 'master';
	}

	if ((browseType === 'home' || browseType === 'topiclanding') && isReview) {
		return 'live';
	}

	const params = parseQueryString(location.search);
	return params['api-branch'] || params.branch || '';
}

function getBrowserServerUrl(browseType: SingleRequestBrowseTypes): string {
	return (browseType === 'home' || browseType === 'topiclanding') && user.isAuthenticated
		? apis.browse.learnHomePersonalized
		: apis.browse.learnHome;
}

// * Browse Types

export interface FetchBrowseTypes {
	home: TrendingBrowseTypeInfo;
	topiclanding: TrendingBrowseTypeInfo;
}

export type SingleRequestBrowseTypes = keyof FetchBrowseTypes;

export interface BrowseFilterCriteriaMap {
	[key: string]: BrowseFilterCriteria;
}

export interface BrowseFilterCriteria {
	roles: string[];
	levels: string[];
	products: string[];
	resource_type?: string[];
}

// * Learn Types

export interface LearnBrowseTypeInfo {
	url: string;
	filterCriteria: BrowseFilterCriteria;
	facets: LearnBrowseFacetName[];
	flatMap: { [facet: string]: string };
	hideCompleted: boolean;
	orderBy: { default: string; terms: string };
	top: number;
}

export interface TrendingBrowseTypeInfo {
	url: string;
	authenticatedUrl?: string;
	filterCriteria: BrowseFilterCriteria;
	facets: LearnBrowseFacetName[];
	flatMap: { [facet: string]: string };
	hideCompleted: boolean;
	orderBy: { default: string; terms: string };
	top: number;
}

export type LearnBrowseFacetName = 'levels' | 'products' | 'roles' | 'resource_type';

// * API related types

export type BrowseFacetNames = LearnBrowseFacetName[];
export type BrowseFacetName = LearnBrowseFacetName;

// * API Related Types

export interface BrowseResponse {
	facets: FacetMap;
	results: LearnBrowseResult[];
	count: number;
}

export interface TrendingBrowseResponse extends BrowseResponse {
	facets: FacetMap;
	results: LearnBrowseResult[];
	count: number;
	newest: TrendingBrowseResult[];
	popularity: TrendingBrowseResult[];
	interests: Interests;
}

export interface FacetMap {
	[key: string]: Facet[];
}

export interface Facet {
	type: string;
	value: string;
	count: number;
	children?: Facet[];
}

// * Browse Response Types

export interface BrowseRequestCriteria {
	locale: string;
	terms: string;
	facets?: BrowseFacetNames;
	filter: BrowseFilterCriteria;
	hideCompleted: boolean;
	order?: string;
	skip?: number;
	top?: number;
	excludeUids?: string[];
}

// * Learn Response Types

export interface LearnBrowseResult {
	uid: string;
	resource_type: 'module' | 'learning path';
	title: string;
	abstract: string;
	summary: string;
	description: string;
	hidden: boolean;
	last_modified: string;
	locale: string;
	products: string[];
	roles: string[];
	levels: LevelId[];
	duration_in_minutes: number;
	icon_url: string;
	url: string;
	number_of_children: number;
}

export interface TrendingBrowseResult extends LearnBrowseResult {
	average_rating: number;
	children: string[];
	number_of_completion: number;
	number_of_ratings: number;
	page_views: number;
	percentage_of_completion: number;
	popularity: number;
	progress_status?: 'completed' | 'inProgress' | 'notStarted';
	remaining_time?: number;
}

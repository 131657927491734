import { loc_qAndA_msdnForum } from '@msdocs/strings';
import { apis } from '../environment/apis';
import { createRequest, fetchWithTimeout } from '../fetch';

export const qnaMinUserInput = 3;

/**
 * @param term - user input
 * @param forumRequestType - type param for forum api request (ie: object, question)
 */
export async function getForumQuestionSuggestions(
	term: string,
	forumRequestType: string
): Promise<ForumQuestionSuggestion[]> {
	if (!term || term.length < qnaMinUserInput) {
		return Promise.resolve([]);
	}

	const [forumResults, kbResults] = await Promise.all([
		fetchForumInternalQuestionSuggestions(term, forumRequestType),
		fetchAzureKbQuestionSuggestions(term)
	]);

	return mergeSuggestions(forumResults, kbResults);
}

async function fetchForumInternalQuestionSuggestions(
	term: string,
	type: string
): Promise<ForumInternalQuestionSuggestion> {
	const emptyObj: ForumInternalQuestionSuggestion = {
		externalSearchResults: {
			count: 0,
			numberOfPages: 0,
			pageNumber: 0,
			pageSize: 0,
			query: '',
			results: [],
			resultsCount: 0,
			resultIds: []
		},
		searchResults: {
			count: 0,
			numberOfPages: 0,
			pageNumber: 0,
			pageSize: 0,
			query: '',
			results: [],
			resultsCount: 0,
			resultIds: []
		}
	};

	const requestOptions: RequestInit = {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		method: 'GET'
	};

	const url = `${
		apis.forumSearch.forumInternalQuestionSuggestions
	}?type=${type}&compact=true&q=${encodeURIComponent(term)}`;
	const request = createRequest(url, requestOptions, false);
	const response = await fetchWithTimeout(request);

	if (response.ok) {
		return response.json();
	}

	return emptyObj;
}

async function fetchAzureKbQuestionSuggestions(term: string): Promise<AzureKbQuestionSuggestion> {
	const emptyObj: AzureKbQuestionSuggestion = {
		Count: 0,
		Matches: []
	};

	const raw = JSON.stringify({ SearchText: term, Top: 7 });

	const requestOptions: RequestInit = {
		body: raw,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		method: 'POST',
		redirect: 'follow'
	};

	const request = createRequest(apis.forumSearch.azureKbQuestionSuggestions, requestOptions, false);
	const response = await fetchWithTimeout(request);

	if (response.ok) {
		return response.json();
	}

	return emptyObj;
}

export function mergeSuggestions(
	forumResults: ForumInternalQuestionSuggestion,
	azureKbResults: AzureKbQuestionSuggestion
): ForumQuestionSuggestion[] {
	const forum = forumResults.searchResults.results;
	const azure = azureKbResults.Matches;

	if (forum.length >= 4) {
		if (azure.length >= 3) {
			return [
				...forum.slice(0, 4).map(adaptForumToSuggestion),
				...azure.slice(0, 3).map(adaptAzureKbToSuggestion)
			];
		} else {
			return [
				...forum.slice(0, 7 - azure.length).map(adaptForumToSuggestion),
				...azure.map(adaptAzureKbToSuggestion)
			];
		}
	} else {
		return [
			...forum.map(adaptForumToSuggestion),
			...azure.slice(0, 7 - forum.length).map(adaptAzureKbToSuggestion)
		];
	}
}

function adaptForumToSuggestion(result: Result) {
	return {
		answerCount: result.childCount,
		acceptedAnswer: result.marked,
		title: result.title,
		type: result.type,
		upVotesCount: result.score,
		url: result.url
	};
}

function adaptAzureKbToSuggestion(result: Match) {
	return {
		answerCount: result.AnswerCount,
		acceptedAnswer: false,
		title: `${loc_qAndA_msdnForum}: ${result.Title}`,
		type: 'kbentry',
		upVotesCount: result.UpVotesCount,
		url: result.SourceUri
	};
}

export interface AzureKbQuestionSuggestion {
	Count: number;
	Matches: Match[];
}

interface Match {
	AnswerCount: number;
	ApiType: string;
	Id: string;
	Rank: number;
	Score: number;
	SourceUri: string;
	Title: string;
	UpVotesCount: number;
}

export interface ForumInternalQuestionSuggestion {
	externalSearchResults: SearchResults;
	searchResults: SearchResults;
}

interface SearchResults {
	count: number;
	numberOfPages: number;
	pageNumber: number;
	pageSize: number;
	query: string;
	results: Result[];
	resultsCount: number;
	resultIds?: any[];
}

export interface Result {
	childCount: number;
	id: number;
	marked: boolean;
	plug: string;
	score: number;
	title: string;
	type: string;
	url: string;
}

export interface ForumQuestionSuggestion {
	answerCount: number;
	acceptedAnswer: boolean;
	title: string;
	type: string;
	upVotesCount: number;
	url: string;
}

import { loc_imageIsInvalid, loc_imageSizeInvalid } from '@msdocs/strings';
const allowedExtensions = ['jpeg', 'jpg', 'png', 'gif'];
const maxFileSize = 1024 * 1024 * 5; // 5 megabytes

export function validateFileInput(files: FileList): string[] {
	const errors: string[] = [];
	Array.from(files).forEach(file => {
		if (
			file.type.split('/')[0] !== 'image' ||
			allowedExtensions.indexOf(file.type.split('/')[1]) === -1
		) {
			errors.push(loc_imageIsInvalid.replace('{fileName}', file.name));
		}
		if (file.size >= maxFileSize) {
			errors.push(loc_imageSizeInvalid.replace('{fileName}', file.name));
		}
	});
	return errors;
}

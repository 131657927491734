export interface AzureSandbox {
	moduleId: string;
	tenantId: string;
	resourceGroupId: string;
	resourceGroupName: string;
	subscriptionId: string;
	storageAccountId: string;
	storageAccountRegion: string;
	fileShareName: string;
	userCreatedAt: string;
	userExpiresAt: string;
	moduleStartedAt: string;
	moduleExpiresAt: string;
	invitationUrl: string;
	sandboxesRemaining: number;
	sandboxesPermitted: number;
}

export const azureSandbox = {
	value: null as AzureSandbox | null
};

export class AzureSandboxChangedEvent {
	constructor(public readonly sandbox: AzureSandbox | null) {}
}

/**
 * Query string parameter indicating sandbox activation should skip
 * prompt to activate.
 */
export const azureSandboxActivateParameter = 'activate-azure-sandbox';

/**
 * Get the sandbox's remaining time in milliseconds, or 0 if the sandbox's expiration is in the past.
 */
export function getRemainingSandboxTime(sandbox: AzureSandbox) {
	return Math.max(0, new Date(sandbox.moduleExpiresAt).getTime() - Date.now());
}

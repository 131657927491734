import {
	loc_locales_ar_sa,
	loc_locales_bg_bg,
	loc_locales_de,
	loc_locales_el_gr,
	loc_locales_en,
	loc_locales_en_my,
	loc_locales_es,
	loc_locales_es_mx,
	loc_locales_fr,
	loc_locales_id_id,
	loc_locales_it,
	loc_locales_ja,
	loc_locales_ko,
	loc_locales_nb,
	loc_locales_nl,
	loc_locales_pl,
	loc_locales_pt,
	loc_locales_pt_br,
	loc_locales_ro,
	loc_locales_ru,
	loc_locales_th,
	loc_locales_tr,
	loc_locales_zh_cn,
	loc_locales_zh_tw
} from '@msdocs/strings';

export const localeNames: { [key: string]: string } = {
	'ar-sa': loc_locales_ar_sa,
	'bg-bg': loc_locales_bg_bg,
	de: loc_locales_de,
	'el-gr': loc_locales_el_gr,
	en: loc_locales_en,
	'en-my': loc_locales_en_my,
	es: loc_locales_es,
	'es-mx': loc_locales_es_mx,
	fr: loc_locales_fr,
	'id-id': loc_locales_id_id,
	it: loc_locales_it,
	ja: loc_locales_ja,
	ko: loc_locales_ko,
	nb: loc_locales_nb,
	nl: loc_locales_nl,
	pl: loc_locales_pl,
	pt: loc_locales_pt,
	'pt-br': loc_locales_pt_br,
	ru: loc_locales_ru,
	ro: loc_locales_ro,
	th: loc_locales_th,
	tr: loc_locales_tr,
	'zh-cn': loc_locales_zh_cn,
	'zh-tw': loc_locales_zh_tw
};

export type LocaleId = keyof typeof localeNames;

/**
 * A view model that can be lazily activated.
 */
export interface Activatable {
	/**
	 * Invoked before a view model is activated.
	 * Good time to load data.
	 */
	beforeActivate(): Promise<void>;
}

export class PropertyChangedEvent {}

/**
 * Monikers supported by the page.
 */

import { getMetas } from '../meta';

export const pageMonikers: {
	[moniker: string]: boolean;
	any: boolean;
} = { any: false };

function readPageMonikers() {
	const tags = getMetas('monikers');
	pageMonikers.any = tags.length > 0;
	for (const tag of tags) {
		pageMonikers[tag] = true;
	}
}

readPageMonikers();

export function pageSupportsMoniker(moniker: string) {
	return moniker !== '' && pageMonikers[moniker] !== undefined;
}

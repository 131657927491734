import { window } from '../../globals';
import { imageBrowserCloseEvent } from './events';
import { attachEvents as attachKeyboardEvents } from './keyboard-interaction';
import { attachEvents as attachMouseEvents } from './pointer-interaction';
import { template } from './template';
import { attachEvents as attachTouchEvents } from './touch-interaction';

export const maxWidthInPixels = 1200;

export class ImageBrowser {
	public imageElement: HTMLImageElement;
	public imageContainer: HTMLElement;
	public captionElement: HTMLElement;
	public state: { expanded: boolean; translationX?: number; translationY?: number } = {
		expanded: false
	};

	public open(container: HTMLElement, { url, alt }: { url: string; alt: string }) {
		const promise = new Promise(resolve => {
			container.innerHTML = template;
			this.imageContainer = container.querySelector('div');
			this.imageContainer.dir = 'ltr';
			this.imageContainer.style.width = `${maxWidthInPixels}px`;
			this.imageContainer.style.height = `${(maxWidthInPixels * 16) / 9}px`;

			this.imageElement = container.querySelector('#image-browser-image') as HTMLImageElement;
			this.imageElement.style.width = `${maxWidthInPixels}px`;
			this.imageElement.style.height = `${(maxWidthInPixels * 16) / 9}px`;
			this.imageElement.style.opacity = '0';

			this.captionElement = container.querySelector('#image-browser-caption');
			this.imageElement.style.transformOrigin = `0 0`;

			this.imageElement.addEventListener('error', () => {});

			this.imageElement.addEventListener('load', () => {
				this.imageElement.classList.add('image-browser-expandable');
				this.imageElement.style.width = null;
				this.imageElement.style.height = null;
				const scale = Math.min(this.imageContainer.clientWidth / this.imageElement.naturalWidth, 1);
				this.imageElement.style.transform = `scale(${scale})`;

				this.imageContainer.classList.add('image-browser-transition');
				this.imageContainer.addEventListener('transitionend', () => {
					this.imageContainer.classList.remove('image-browser-transition');
				});

				this.imageContainer.style.height = `${this.imageElement.naturalHeight * scale}px`;
				this.imageContainer.style.width = `${Math.min(
					maxWidthInPixels,
					this.imageElement.naturalWidth
				)}px`;
				this.imageElement.style.opacity = '1';

				attachKeyboardEvents(this, this.imageElement.parentElement);
				attachMouseEvents(this, this.imageElement.parentElement);
				attachTouchEvents(this, this.imageElement.parentElement);

				window.addEventListener('resize', () => {
					if (!this.state.expanded) {
						this.imageElement.style.transform = `scale(${Math.min(
							this.imageContainer.clientWidth / this.imageElement.naturalWidth,
							1
						)})`;
					} else {
						this.panViewport(0, 0);
					}

					this.imageContainer.style.height = `${
						this.imageElement.naturalHeight *
						Math.min(this.imageContainer.clientWidth / this.imageElement.naturalWidth, 1)
					}px`;
				});

				resolve();
			});

			this.imageElement.src = url;
			this.captionElement.textContent = alt;
		});

		return promise;
	}

	public close() {
		window.dispatchEvent(
			new CustomEvent(imageBrowserCloseEvent, { detail: { image: this.imageElement.src } })
		);
	}

	public toggleExpand(x?: number, y?: number, convertCoordinates?: boolean) {
		if (convertCoordinates) {
			x = x + this.imageContainer.clientWidth / 2;
			y =
				y +
				(this.imageElement.naturalHeight *
					Math.min(this.imageContainer.clientHeight / this.imageElement.naturalHeight, 1)) /
					2;
		}

		if (!this.state.expanded) {
			this.state.translationX = !isNaN(x)
				? Math.min(
						0,
						Math.max(this.imageElement.naturalWidth * -1 + this.imageContainer.clientWidth, x)
				  )
				: 0;

			this.state.translationY = !isNaN(y)
				? Math.min(
						0,
						Math.max(this.imageElement.naturalHeight * -1 + this.imageContainer.clientHeight, y)
				  )
				: 0;
		}

		this.imageElement.style.transform = this.state.expanded
			? `scale(${Math.min(this.imageContainer.clientWidth / this.imageElement.naturalWidth, 1)})`
			: `scale(1) translate(${this.state.translationX}px,  ${this.state.translationY}px)`;
		this.imageElement.classList.remove(
			this.state.expanded ? 'image-browser-pannable' : 'image-browser-expandable'
		);
		this.imageElement.classList.add(
			this.state.expanded ? 'image-browser-expandable' : 'image-browser-pannable'
		);
		this.state.expanded = !this.state.expanded;
	}

	public panViewport(x: number, y: number) {
		if (this.state.expanded) {
			this.state.translationX = Math.min(
				0,
				Math.max(
					this.imageElement.naturalWidth * -1 + this.imageContainer.clientWidth,
					this.state.translationX + x
				)
			);
			this.state.translationY = Math.min(
				0,
				Math.max(
					this.imageElement.naturalHeight * -1 + this.imageContainer.clientHeight,
					this.state.translationY + y
				)
			);

			this.imageElement.style.transform = `translate(${this.state.translationX}px,  ${this.state.translationY}px)`;
		}
	}
}

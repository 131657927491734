import { loc_all, loc_documentation, loc_learn, loc_reference } from '@msdocs/strings';

export const contentAreaNames = {
	all: loc_all,
	documentation: loc_documentation,
	learn: loc_learn,
	reference: loc_reference
};

export type ContentAreaId = keyof typeof contentAreaNames;

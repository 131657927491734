import { loc_siteSearch_results_allResultsOnMsDocs } from '@msdocs/strings';
import { html, TemplateResult } from '../../../lit-html';
import { SearchViewModel, SearchVMConfig } from '../view-model';
import { clearAllFilterButton } from './clear-filters';
import { facetSidebarDesktopTemplate } from './facets';
import { facetSearchFormTemplate } from './form';
import { mobileFilterButtonTemplate } from './mobile-menu';
import { paginationTemplate } from './pagination';
import { facetSearchResultsTemplate } from './results';
import { removeTagsTemplate } from './tags';
import { rssTemplate } from './rss';
import { blankSlateSearchTemplate } from './blank';
import { searchHeadingTemplate } from './search-header';

export function facetSearchTemplate<TResult>(
	vm: SearchViewModel<TResult>,
	layout: SearchVMConfig<TResult>['layout'],
	resultTemplate: SearchVMConfig<TResult>['resultTemplate'],
	headingLevel?: SearchVMConfig<TResult>['headingLevel']
): TemplateResult {
	if (vm.showBlankSlate) {
		vm.disallowBlankSlate = true;
		return blankSlateSearchTemplate(vm);
	}
	return html` <div class="has-padding-top-medium">
		<div class="columns has-large-gaps is-gapless-mobile">
			${facetSidebarDesktopTemplate(vm)}
			<div
				class="column is-full is-three-fifths-tablet ${layout === 'list'
					? ''
					: 'is-three-quarters-widescreen'}"
			>
				${facetSearchFormTemplate(vm)}
				${vm.facetsEnabled && vm.resultsCount !== 0
					? html` <div class="is-hidden-desktop has-margin-top-small">
								${mobileControlsTemplate(vm)}
							</div>
							<div class="has-margin-top-medium">
								${removeTagsTemplate(vm)}
							</div>`
					: ''}

				<div class="has-margin-top-large ${vm.resultsCount !== 0 ? '' : 'visually-hidden'}">
					${searchHeadingTemplate(vm, headingLevel)}
					${vm.scope && !vm.busy && vm.resultsCount !== 0
						? html`
								<p class="subtitle">
									<button
										class="has-padding-none is-inline has-inner-focus has-margin-none has-appearance-none has-text-primary has-border-none has-background-transparent"
										@click=${() => vm.clearScope()}
									>
										${loc_siteSearch_results_allResultsOnMsDocs}
									</button>
								</p>
						  `
						: ''}
				</div>
				${layout === 'list' && vm.resultsCount !== 0 ? html`<hr class="hr" />` : ''}
				${facetSearchResultsTemplate(vm, layout, resultTemplate)}
				<div class="columns is-gapless">
					<div class="column">
						${paginationTemplate(vm)}
					</div>
					<div class="column is-narrow">
						${rssTemplate(vm)}
					</div>
				</div>
			</div>
		</div>
	</div>`;
}

export function mobileControlsTemplate<TResult>(vm: SearchViewModel<TResult>) {
	return html`
		<div class="level is-mobile is-hidden-tablet">
			<div class="level-left">
				<div class="level-item">
					${mobileFilterButtonTemplate(vm)}
				</div>
			</div>
			<div class="level-right">
				${clearAllFilterButton(vm)}
			</div>
		</div>
	`;
}

import { apis } from '../environment/apis';
import { AzureToken } from './token';

export const tokenApi = {
	origin: `${apis.auth.token}`,

	async tryLoadTokens(): Promise<AzureToken[]> {
		try {
			const url = `${this.origin}/accesstokens`;
			const requestInit = { method: 'POST', mode: 'cors', credentials: 'include' } as RequestInit;
			const response = await fetch(url, requestInit);
			if (!response.ok) {
				return null;
			}
			const tokens: AzureToken[] = await response.json();
			tokens.forEach(t => (t.tenant_id = t.tenant_id.replace('/tenants/', '')));
			return tokens;
		} catch (err) {
			return null;
		}
	},

	async tryLoadClaims(): Promise<TokenServiceClaims> {
		try {
			const url = `${this.origin}/claims`;
			const requestInit = { method: 'GET', mode: 'cors', credentials: 'include' } as RequestInit;
			const response = await fetch(url, requestInit);
			const claims = await response.json();
			return {
				email: claims.upn || claims.email || '', // MSA has email, AAD has upn
				authenticationMode: claims.email ? 'MSA' : 'AAD'
			};
		} catch (err) {
			return null;
		}
	}
};

export interface TokenServiceClaims {
	email: string;
	authenticationMode: 'MSA' | 'AAD';
}

import { breakText, escape } from '../text-formatting';
import { getFamily } from './family';
import { pageMonikers, pageSupportsMoniker } from './page-monikers';

export function renderAppliesTo(container: Element) {
	getFamily().then(family => {
		let output = ``;
		let productIndex = 0;

		for (const product of family.products) {
			const packageLinks = [];

			for (const { moniker, versionDisplayName } of product.packages) {
				if (pageSupportsMoniker(moniker) && pageMonikers[moniker]) {
					packageLinks.push(`<span class="cdl">${breakText(escape(versionDisplayName))}</span>`);
				}
			}
			if (packageLinks.length === 0) {
				continue;
			}
			const titleClass = productIndex === 0 ? 'propertyInfoTitle' : 'propertyInfoTitle stack';

			output += `<h4 class="${titleClass}">${escape(product.displayName)}</h4>\n
			<div class="cdlHolder">${packageLinks.join(' ')}</div>\n`;

			productIndex++;
		}

		container.insertAdjacentHTML('afterend', output);
	});
}

import {
	loc_chooseAzureAccount,
	loc_signIn,
	loc_signInAzure,
	loc_signInCloudShellWithDifferentAccount,
	loc_signingIn,
	loc_signOut
} from '@msdocs/strings';
import { user, User } from '../auth/user';
import { contentTags, jsllReady } from '../bi';
import { apis } from '../environment/apis';
import { eventBus } from '../event-bus';
import { loadingHtml } from '../html';
import { appInsights } from '../instrumentation/app-insights';
import { doOAuthFlow, OAuthFlowArgs } from '../oauth-common';
import { parseQueryString, updateQueryString } from '../query-string';
import { escape } from '../text-formatting';
import { azureSandbox, azureSandboxActivateParameter, AzureSandboxChangedEvent } from './sandbox';
import { AzureToken, azureToken } from './token';
import { tokenApi, TokenServiceClaims } from './token-api';

const azureAuthCompleteArgName: string = 'azure-auth-complete';

function shouldFetchToken() {
	const args = parseQueryString(location.search);
	if (args[azureAuthCompleteArgName] === 'true') {
		args[azureAuthCompleteArgName] = null;
		updateQueryString(args, 'replaceState');
		return true;
	}
	return false;
}

(() => {
	if (shouldFetchToken()) {
		tokenApi.tryLoadTokens().then(tokens => {
			if (tokens) {
				reportAuthorized();
			}
		});
	}
})();

function login(): void {
	const signInArgs = user.isAuthenticated ? { loginHint: user.upn } : {};
	const args: OAuthFlowArgs = {
		signInUrl: `${apis.auth.token}/signin`,
		returnUrlArg: 'returnUrl',
		signInArgs,
		returnUrlArgs: { [azureAuthCompleteArgName]: 'true' }
	};
	doOAuthFlow(args);
}

export function azureOAuthLogout(): void {
	const args: OAuthFlowArgs = {
		signInUrl: `${apis.auth.token}/signout`,
		returnUrlArg: 'returnUrl',
		signInArgs: {}
	};
	doOAuthFlow(args);
}

const signingInHtml = `
	<h2>${escape(loc_signingIn)}</h2>
	${loadingHtml}`;

export async function azureOAuthLogin(container: HTMLElement): Promise<AzureToken> {
	container.innerHTML = `
		<div class="azure-auth">
			<div class="azure-auth-step">
				${signingInHtml}
			</div>
		</div>`;
	const authContainer = container.firstElementChild.firstElementChild as HTMLElement;

	const processTokens = (tokens: AzureToken[]) =>
		new Promise<AzureToken>(resolve => {
			const resolveToken = (token: AzureToken) => {
				container.innerHTML = '';
				azureToken.value = token;
				resolve(token);
			};

			if (tokens === null) {
				azureToken.value = null;
				if (azureSandbox.value) {
					const args = parseQueryString();
					args[azureSandboxActivateParameter] = 'true';
					updateQueryString(args, 'replaceState');
					login();
				} else {
					renderLogin(authContainer);
				}
			} else if (azureSandbox.value) {
				const token = tokens.find(t => t.tenant_id === azureSandbox.value.tenantId);
				if (token) {
					resolveToken(token);
				} else {
					renderLogout(authContainer);
				}
			} else if (tokens.length === 1) {
				resolveToken(tokens[0]);
			} else {
				renderTokenSelector(authContainer, resolveToken, tokens);
			}
		});

	const [claims, tokens] = await Promise.all([tokenApi.tryLoadClaims(), tokenApi.tryLoadTokens()]);

	if (isAccountMismatch(user, claims)) {
		reportAccountMismatch(user, claims);
		renderLogout(authContainer);
		return new Promise(() => {});
	}

	return processTokens(tokens);
}

export function renderLogin(container: HTMLElement): void {
	reportLoginPrompt();
	container.innerHTML = `
		<h2>${escape(loc_signIn)}</h2>
		<p>${escape(loc_signInAzure)}</p>
		<button type="button" class="button is-primary is-radiusless" data-bi-name="azure-auth-login">
			${loc_signIn}
		</button>`;

	const loginButton = container.querySelector('button');
	loginButton.onclick = () => {
		reportLogin();
		container.innerHTML = signingInHtml;
		login();
	};
}

function renderTokenSelector(
	container: HTMLElement,
	resolve: (token: AzureToken) => void,
	tokens: AzureToken[]
) {
	container.innerHTML = `
		<h2>${loc_chooseAzureAccount}</h2>
		<ul class="azure-auth-tokens"></ul>`;

	const list = container.lastElementChild;

	for (const token of tokens) {
		list.insertAdjacentHTML(
			'beforeend',
			`
			<li>
				<button class="azure-auth-token has-inner-focus" type="button" data-bi-name="azure-auth-token">
					<span>${escape(token.display_name)}</span>
					<span>${escape(token.default_domain)}</span>
				</button>
			</li>`
		);
		const button = list.lastElementChild.firstElementChild as HTMLButtonElement;
		button.onclick = () => resolve(token);
	}
}

export function renderLogout(container: HTMLElement) {
	container.innerHTML = `
		<p>${escape(loc_signInCloudShellWithDifferentAccount)}</p>
		<button type="button" class="button is-primary is-radiusless" data-bi-name="azure-auth-logout">
			${loc_signOut}
		</button>`;

	const logoutButton = container.querySelector('button');

	logoutButton.onclick = () => azureOAuthLogout();
}

function reportLoginPrompt() {
	jsllReady.then(awa =>
		awa.ct.captureContentPageAction({
			behavior: awa.behavior.STARTPROCESS,
			actionType: awa.actionType.CLICKLEFT,
			contentTags: {
				[contentTags.scenario]: 'azure-cli-login',
				[contentTags.scenarioStep]: 'login-prompt'
			}
		})
	);
}

function reportLogin() {
	jsllReady.then(awa =>
		awa.ct.captureContentPageAction({
			behavior: awa.behavior.PROCESSCHECKPOINT,
			actionType: awa.actionType.CLICKLEFT,
			contentTags: {
				[contentTags.scenario]: 'azure-cli-login',
				[contentTags.scenarioStep]: 'login'
			}
		})
	);
}

function reportAuthorized() {
	jsllReady.then(awa =>
		awa.ct.captureContentPageAction({
			behavior: awa.behavior.COMPLETEPROCESS,
			actionType: awa.actionType.OTHER,
			contentTags: {
				[contentTags.scenario]: 'azure-cli-login',
				[contentTags.scenarioStep]: 'authorized'
			}
		})
	);
}

function reportAccountMismatch(user: User, claims: TokenServiceClaims) {
	appInsights.trackEvent(
		{ name: 'token-service-account-mismatch' },
		{
			docsEmail: user.upn,
			docsMode: user.authenticationMode,
			tokenServiceEmail: claims.email,
			tokenServiceMode: claims.authenticationMode
		}
	);
}

export function isAccountMismatch(user: User, claims: TokenServiceClaims) {
	// For an account mismatch to occur, the user needs to be signed into
	// both docs and the token service.
	// For an account mismatch to matter, the user needs to have an active sandbox.
	if (!user.isAuthenticated || !claims || !azureSandbox.value) {
		return false;
	}
	const userEmail = user.upn;
	return claims.email.localeCompare(userEmail, undefined, { sensitivity: 'base' }) !== 0; // case insensitive email comparison.
}

// release azure token when sandbox deactivates
// or when sandbox tenant no longer matches token tenant.
eventBus.subscribe(AzureSandboxChangedEvent, ({ sandbox }) => {
	if (!azureToken.value || (sandbox && azureToken.value.tenant_id === sandbox.tenantId)) {
		return;
	}
	azureToken.value = null;
});

export interface AzSubscriptionsResult {
	value: AzSubscription[];
}

type AzSubscriptionState = 'Deleted' | 'Disabled' | 'Enabled' | 'PastDue' | 'Warned';
type AzSpendingLimit = 'On' | 'Off';

export interface AzSubscription {
	id: string;
	subscriptionId: string;
	displayName: string;
	state: AzSubscriptionState;
	subscriptionPolicies: AzSubscriptionPolicy[];
}

interface AzSubscriptionPolicy {
	locationPlacementId: string;
	quotaId: string;
	spendingLimit: AzSpendingLimit;
}

import { loc_assessments } from '@msdocs/strings';
import { currentSave } from '../apis/assessments';
import { user } from '../auth/user';
import { eventBus } from '../event-bus';
import { getQueryStringMap, updateUrlSearchFromMap } from '../query-string';
import { RouterAfterNavigateEvent } from '../router/events';
import { router, RouterDelegate } from '../router/router';
import { renderAssessmentsBreadcrumbs } from './breadcrumbs';
import { updateAssessmentChrome } from './element-updates';
import { renderAssessmentMode, viewModel } from './modes';
import { currentAssessmentNavigation, getAssessmentNavigationStep } from './navigation';
import { getGuidanceSessionName } from './session';
import { AssessmentNavigation } from './types';
import { processVideoFrames } from '../video-adjustments';

export const createAssessmentRouterDelegate = (view: HTMLElement, hero: HTMLElement) => {
	return {
		canHandle: (url: URL) => {
			return (
				location.origin === url.origin && location.pathname === url.pathname && url.search !== ''
			);
		},
		handle: async (url: URL) => {
			if (user.isAuthenticated && currentSave !== undefined) {
				await currentSave;
			}
			const query = getQueryStringMap(url);
			const step = await getAssessmentNavigationStep(query);
			const title = getTitleForNavigationAction(step);
			renderAssessmentsBreadcrumbs(step);
			await renderAssessmentMode(step, view, hero);

			return {
				title,
				url
			};
		},
		params: ['session', 'question', 'category', 'mode']
	} as RouterDelegate;
};

export const initAssessmentRouter = (view: HTMLElement, hero: HTMLElement) => {
	router.enable(createAssessmentRouterDelegate(view, hero));
	eventBus.subscribe(RouterAfterNavigateEvent, e => {
		updateAssessmentChrome(e.url, e.title);
		processVideoFrames(view);
		setAssessmentFocus(view, currentAssessmentNavigation.mode);
		handlePostRenderFormValidity();
	});

	return router;
};

function handlePostRenderFormValidity() {
	if (currentAssessmentNavigation.mode === 'pre-assessment' && viewModel.reportValidationOnRender) {
		const form = document.querySelector('#assessments-container form') as HTMLFormElement;
		if (form) {
			form.reportValidity();
		}
		viewModel.reportValidationOnRender = false;
		updateUrlSearchFromMap({ reportValidity: null });
	}
}

/**
 * Find an focus the logical element based on the mode
 * @param container The Assessment view
 * @param mode The current mode of the assessment.
 */
function setAssessmentFocus(container: HTMLElement, mode: AssessmentNavigation['mode']) {
	if (mode === 'questionnaire' || mode === 'pre-assessment') {
		const firstFocusableElement =
			container.querySelector<HTMLElement>('input, select, textarea') ||
			container.querySelector<HTMLElement>('a[href], button');

		if (firstFocusableElement) {
			firstFocusableElement.focus();
			return;
		}
	}

	resetFocusToBody();

	function resetFocusToBody() {
		const active = document.activeElement as HTMLElement;
		if (active) {
			active.blur();
		}
	}
}

export function getTitleForNavigationAction(navigationAction: AssessmentNavigation) {
	switch (navigationAction.mode) {
		case 'home':
		case 'invalid':
			return `${loc_assessments} | Microsoft Docs`;
		case 'pre-assessment':
			return `${navigationAction.assessment.title} - ${loc_assessments} | Microsoft Docs`;
		case 'questionnaire':
			return `${navigationAction.session.name} - ${loc_assessments} | Microsoft Docs`;
		case 'guidance':
			return `${getGuidanceSessionName(navigationAction)} - ${loc_assessments} | Microsoft Docs`;
		default:
			throw new Error('Invalid navigation action case for creating page titles.');
	}
}

import { process401Response } from '../auth/service';
import { apis } from '../environment/apis';
import { createRequest, fetchWithTimeout } from '../fetch';
import { fingerprintSid } from '../risk-evaluation/fingerprint';
import { RiskApiError } from '../risk-evaluation/risk-errors';

export async function get(): Promise<LabGetResult> {
	const requestOptions = {
		method: 'GET'
	};
	const request = createRequest(apis.labOnDemand, requestOptions);
	const response = await fetchWithTimeout(request);
	process401Response(response);
	if (response.ok) {
		const data = await response.json();
		return processStatus(data);
	}
	return Promise.reject();
}

export async function post(labId: number, captchaToken?: string): Promise<LabApiResult> {
	const requestOptions = {
		method: 'POST',
		headers: {
			'X-SID': fingerprintSid
		},
		body: JSON.stringify({ captchaToken })
	};
	const request = createRequest(`${apis.labOnDemand}/${labId}`, requestOptions);
	const response = await fetchWithTimeout(request);
	process401Response(response);
	if (response.ok || response.status === 400) {
		const data = await response.json();
		if (response.ok) {
			processStatus(data);
			return { hasError: false, lab: data };
		}
		return { hasError: true, error: data };
	}
	return Promise.reject();
}

export async function remove(labInstanceId: number): Promise<LabDeleteResult> {
	const requestOptions = {
		method: 'DELETE'
	};
	const request = createRequest(`${apis.labOnDemand}/${labInstanceId}`, requestOptions);
	const response = await fetchWithTimeout(request);
	process401Response(response);
	if (response.ok) {
		const data = await response.json();
		return processStatus(data);
	}
	return Promise.reject();
}

function processStatus<TResult extends { Status: number; Error: string }>(
	result: TResult
): Promise<TResult> {
	return result.Status === 1 ? Promise.resolve(result) : Promise.reject(result);
}

export type LabApiResult =
	| {
			hasError: true;
			error: RiskApiError;
	  }
	| {
			hasError: false;
			lab: LabPostResult;
	  };

export interface RunningLab {
	LabInstanceId: number;
	LabProfileId: number;
	LabProfileName: string;
	LabProfileNumber: string;
	Start: number;
	StartTime: string;
	Expires: number;
	ExpiresTime: string;
	Url: string;
	IsExam: boolean;
}

export interface SavedLab {
	LabInstanceId: number;
	LabProfileId: number;
	LabProfileName: string;
	LabProfileNumber: string;
	MinutesRemaining: number;
	Saved: number;
	SavedTime: string;
	Expires: number;
	ExpiresTime: string;
	SaveInProgress: boolean;
	IsExam: boolean;
	SubmittedForGrading: boolean;
}

export interface LabGetResult {
	RunningLabs: RunningLab[];
	SavedLabs: SavedLab[];
	Error: string;
	Status: number;
}

export interface LabPostResult {
	Result: number;
	Url: string;
	LabInstanceId: number;
	Expires: number;
	ExpiresTime: string;
	Error: string;
	Status: number;
}

export interface LabDeleteResult {
	Result: number;
	Error: string;
	Status: number;
}

import { loc_whyDidYouChooseYourRating } from '@msdocs/strings';
import { SurveyQuestion } from './survey-questions';

export function createRatingRemarks(score: number, surveyQuestions: SurveyQuestion[]) {
	const container = document.createElement('fieldset');

	let ratingDetailsHtml = `
		<legend class="visually-hidden">${loc_whyDidYouChooseYourRating}</legend>
		<ul class="columns is-multiline has-margin-none has-padding-top-none">`;

	const answers = surveyQuestions[0].answers; //rating always only has one question

	answers.forEach(answer => {
		if (answer.scores.indexOf(score) !== -1) {
			ratingDetailsHtml += `
			<li class="column is-half has-padding-small is-flex has-flex-justify-content-left is-unstyled">
				<label class="checkbox is-small">
					<input id="${answer.id}" name="roles" value="administrator" type="checkbox" class="has-margin-right-small">
					<span class="checkbox-check" role="presentation"></span>
					<span class="checkbox-text">${answer.title}</span>
				</label>
			</li>`;
		}
	});

	ratingDetailsHtml += `</ul>`;

	container.innerHTML = ratingDetailsHtml;

	return container;
}

import { loc_assessments } from '@msdocs/strings';
import { createBreadcrumbs } from '../breadcrumbs';
import { getGuidanceSessionName } from './session';
import { AssessmentNavigation } from './types';

/*
 * Assessments breadcrumbs
 */
export function renderAssessmentsBreadcrumbs(
	navigationAction: AssessmentNavigation,
	container?: HTMLElement
) {
	const breadcrumbs = createBreadcrumbs(container);
	if (!breadcrumbs) {
		return;
	}

	let breadCrumbTitle = '';
	switch (navigationAction.mode) {
		case 'home':
		case 'invalid':
			// handled below
			break;
		case 'pre-assessment':
			breadCrumbTitle = navigationAction.assessment.title;
			break;
		case 'questionnaire':
			breadCrumbTitle = navigationAction.session.name;
			break;
		case 'guidance':
			breadCrumbTitle = getGuidanceSessionName(navigationAction);
			break;
	}

	if (breadCrumbTitle) {
		breadcrumbs.add(
			{
				title: loc_assessments,
				url: `/${breadcrumbs.basePath}/assessments/${
					navigationAction.branch ? `?assessment-branch=${navigationAction.branch}` : ''
				}`
			},
			{
				title: breadCrumbTitle
			}
		);
	} else {
		breadcrumbs.add({
			title: loc_assessments
		});
	}

	breadcrumbs.render();
}

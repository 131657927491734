import { loc_tryIt } from '@msdocs/strings';
import { initRestTryIt } from '../rest-tryit/index';
import { registerInteractiveType } from './activation';

registerInteractiveType({
	name: 'http',
	activateButtonConfig: {
		name: loc_tryIt,
		iconClass: 'docon docon-play',
		attributes: []
	},
	create: () => {
		const element = initRestTryIt();
		return {
			element,
			setCode: () => Promise.resolve(),
			execute: () => Promise.resolve(),
			dispose: () => {} // todo: implement dispose https://ceapex.visualstudio.com/Engineering/_workitems/edit/62073
		};
	}
});

import { formatNumber } from '../number-format';
import { html, render } from '../lit-html';
import { TemplateResult } from 'lit-html';

export function createRatingDisplay(
	score: number,
	count: number,
	showExplanation: boolean = true,
	beforeText: string = null
): HTMLElement {
	const container = document.createElement('div');
	container.classList.add('star-rating-display');

	const starListHtml = createRatingTemplate(score);
	const beforeTextHtml = beforeText ? html`<div class="before-text">${beforeText + ' '}</div>` : '';
	const formattedCount = formatNumber(count);

	let explanation = html`<span class="explanation-text">${score} (${formattedCount})</span>`;
	if (!score) {
		explanation = html`<span class="explanation-text">(${formattedCount})</span>`;
	}

	const explanationTextHtml = showExplanation ? explanation : '';

	const ratingScoreHtml = html`${beforeTextHtml}
		<div class="rating-stars">
			${starListHtml} ${explanationTextHtml}
		</div>`;

	render(ratingScoreHtml, container);
	return container;
}

export function updateRatingDisplayScore(container: HTMLElement, score: number): HTMLElement {
	const starList = container.querySelector<HTMLElement>('.rating-stars');
	render(createRatingTemplate(score), starList);
	return starList;
}

export function createRatingTemplate(score: number): TemplateResult[] {
	const templates = [];
	for (let i = 0; i < 5; i++) {
		if (score < i + 0.3) {
			templates.push(html`<span aria-hidden="true" class="star docon docon-star-outline"></span>`);
		} else if (i + 0.3 <= score && i + 0.7 > score) {
			templates.push(html`<span aria-hidden="true" class="star docon docon-star-half"></span>`);
		} else {
			templates.push(html`<span aria-hidden="true" class="star docon docon-star"></span>`);
		}
	}
	return templates;
}

import {
	AssessmentSideNav,
	AssessmentSideNavHeader,
	AssessmentSideNavQuestionProgress,
	AssessmentSideNavTree,
	AssessmentNavTreeNodeAccessor
} from '../side-nav';
import { html } from 'lit-html';
import { createTreeStatic, resetEventListeners } from '../../components/tree';
import { AssessmentViewModel } from '../view-model';
import { document, window, contentLoaded } from '../../globals';
import { loc_questions } from '@msdocs/strings';
import { initModalToc, tocModal } from '../../toc/modal-toc';

/*
 * Entry point to create the sidebar navigation component.
 */
export function createSideNav(viewModel: AssessmentViewModel) {
	// For mobile rendering
	if (!document.documentElement.classList.contains('hasSidebar')) {
		document.documentElement.classList.add('hasSidebar');
		initModalToc(renderSideNavAsModal, loc_questions);
	}

	// Adds events listeners
	contentLoaded.then(contentUpdated).then(addEventListeners);

	const sideNavViewModel = new AssessmentSideNav(viewModel);
	return createSideNavTemplate(sideNavViewModel);
}

function createSideNavTemplate(viewModel: AssessmentSideNav) {
	return html`<div
		id="assessments-side-nav-container"
		class="is-hidden-mobile has-width-330 has-margin-medium"
	>
		${createHeaderTemplate(viewModel.navHeader)}
		<aside role="navigation" class="assessments-side-nav is-vertically-scrollable">
			${createNavTreeTemplate(viewModel.navTree)}
		</aside>
	</div>`;
}

function createHeaderTemplate(viewModel: AssessmentSideNavHeader) {
	return html`<div class="side-nav-header">
		<div class="side-nav-header-content">
			<h2 class="title is-4 has-margin-top-small has-margin-bottom-none has-line-height-reset">
				${viewModel.assessmentTitle}
			</h2>
			<a
				href="${viewModel.sessionUrl.toString()}"
				class="title is-8 is-flex has-text-weight-normal has-margin-bottom-none has-margin-top-small has-line-height-reset"
			>
				${viewModel.sessionName}
			</a>
			${createProgressTemplate(viewModel.questionsProgress)}
		</div>
		<hr class="hr has-margin-bottom-none has-margin-top-medium" />
	</div>`;
}

function createProgressTemplate(viewModel: AssessmentSideNavQuestionProgress) {
	return html`<div class="side-nav-progress">
		<progress
			class="progress is-primary is-extra-small is-full-width is-inline-block has-margin-bottom-none"
			max="100"
			aria-describedby="progress-description"
			value="${viewModel.questionsProgress}"
		></progress>
		<div id="progress-description" class="is-size-extra-small has-padding-none">
			${viewModel.progressLabel}
		</div>
	</div>`;
}

function createNavTreeTemplate(viewModel: AssessmentSideNavTree) {
	// TODO: Define the final text for nav tree aria label
	const tree = createTreeStatic(
		viewModel.treeNodes,
		new AssessmentNavTreeNodeAccessor(),
		'Questions Tree'
	);

	tree
		.querySelectorAll('.tree-item.is-leaf')
		.forEach(el => el.classList.add('has-padding-right-extra-small'));
	tree.querySelectorAll('li.tree-item').forEach(el => el.classList.add('has-margin-bottom-small'));
	tree
		.querySelectorAll('span[data-bi-name=tree-expander]')
		.forEach(el => el.classList.add('has-text-weight-semibold'));
	tree
		.querySelectorAll('ul.tree-group')
		.forEach(el =>
			el.classList.add(
				'has-margin-left-none',
				'has-margin-bottom-extra-small',
				'has-line-height-reset'
			)
		);

	return tree;
}

function addEventListeners() {
	const sideNav = document.querySelector('#assessments-side-nav-container');
	if (!sideNav || sideNav.getAttribute('has-events') === 'true') {
		return;
	}

	sideNav.setAttribute('has-events', 'true');
	window.addEventListener('resize', contentUpdated, { passive: true } as any);
	window.addEventListener('assessment-content-update', contentUpdated);
	window.addEventListener('modal-show', modalContentUpdated);
	window.addEventListener('load', contentUpdated, false);
	window.addEventListener('DOMContentLoaded', contentUpdated, false);
}

function contentUpdated() {
	// If the modal menu is visible, we hide it
	if (tocModal) {
		tocModal.hide();
	}

	// Updates tree height
	updateSideNavHeight();

	// Check if we need to scroll after content was updated
	const sideNavContainer = document.querySelector('#assessments-side-nav-container');
	autoScroll(sideNavContainer, 'smooth');
}

function modalContentUpdated(args: any) {
	const container = args.detail.container as HTMLElement;

	// Sets navtree height after the modal has been added to DOM
	const navTree = container.querySelector('.assessments-side-nav') as HTMLElement;
	navTree.style.height = '0px';
	const newHeight = container.offsetHeight + container.offsetTop - navTree.offsetTop;
	navTree.style.height = `${newHeight}px`;

	// Check if we need to scroll after content was updated
	autoScroll(container, 'auto');
}

function renderSideNavAsModal(container: Element) {
	const sideNavContainer = document.querySelector('#assessments-side-nav-container');
	if (!sideNavContainer) {
		return;
	}

	const header = container.querySelector('.side-nav-header');
	const navtree = container.querySelector('.assessments-side-nav');
	const isUpdate = navtree && header;

	if (isUpdate) {
		navtree.innerHTML = sideNavContainer.querySelector('.assessments-side-nav').innerHTML;
		header.innerHTML = sideNavContainer.querySelector('.side-nav-header').innerHTML;
	} else {
		// Inserts the .side-nav-header and the .assessments-side-nav elements
		container.insertAdjacentHTML('beforeend', sideNavContainer.innerHTML);

		// Adds custom styling for mobile view
		const sideNavContainerModal = container.querySelector('.assessments-side-nav');
		sideNavContainerModal.removeAttribute('style');
		sideNavContainerModal.classList.add('has-margin-left-small');
	}

	const sideNavHeaderContentModal = container.querySelector('.side-nav-header-content');
	sideNavHeaderContentModal.classList.add('has-margin-left-small', 'has-margin-right-small');

	// Reset events in the mobile view, so mouse an keyboard works in the tree
	const treeModal = container.querySelector('ul.tree') as HTMLUListElement;
	resetEventListeners(treeModal, new AssessmentNavTreeNodeAccessor());
}

function isInView(container: Element, element: HTMLElement) {
	// Get container properties
	const containerTop = container.scrollTop;
	const containerBottom = containerTop + container.clientHeight;

	// Get element properties
	const elementTop = element.offsetTop;
	const elementBottom = elementTop + element.clientHeight;

	const inView = elementTop >= containerTop && elementBottom <= containerBottom;
	return inView;
}

function autoScroll(container: Element, behavior: ScrollBehavior) {
	if (!container) {
		return;
	}

	const scrollContainer = container.querySelector('.assessments-side-nav');
	if (!scrollContainer) {
		return;
	}

	const element = scrollContainer.querySelector('.is-leaf.is-selected') as HTMLElement;
	if (!element) {
		// No question selected, scroll to first page
		scrollContainer.scrollTo(0, 0);
		return;
	}

	if (!isInView(scrollContainer, element)) {
		setTimeout(() => element.scrollIntoView({ behavior, block: 'center' }));
	}
}

function updateSideNavHeight() {
	const mainContainer = document.querySelector('.mainContainer') as HTMLElement;
	if (!mainContainer) {
		return;
	}
	const assessments = mainContainer.querySelector('.assessments-with-side-nav') as HTMLElement;
	if (!assessments) {
		return;
	}

	const sideNav = assessments.querySelector('#assessments-side-nav-container') as HTMLElement;
	const treeContainer = sideNav.querySelector('.assessments-side-nav') as HTMLElement;

	// Sets the new height for the tree
	treeContainer.style.height = '0px';
	const difference = calculateHeightDifference(assessments, mainContainer);
	const newHeight = sideNav.offsetHeight + sideNav.offsetTop - treeContainer.offsetTop + difference;
	treeContainer.style.height = `${newHeight}px`;
}

function calculateHeightDifference(element1: HTMLElement, element2: HTMLElement) {
	// Calculates height difference between two elements
	const assessmentWithNavHeight = getAbsoluteHeight(element1);
	const mainContainerHeight = getAbsoluteHeight(element2);
	return mainContainerHeight - assessmentWithNavHeight;
}

function getAbsoluteHeight(element: HTMLElement) {
	const styles = getComputedStyle(element);
	const margin =
		parseFloat(styles.getPropertyValue('margin-top')) +
		parseFloat(styles.getPropertyValue('margin-bottom'));
	return element.offsetHeight + margin;
}

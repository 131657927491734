import {
	loc_archiveDocs,
	loc_back,
	loc_close,
	loc_product,
	loc_products,
	loc_selectVersion,
	loc_version
} from '@msdocs/strings';
import { html, render, TemplateResult } from '../lit-html';
import { platformConfig } from '../api-browser/platform-config';
import { collapseDropdown } from '../dropdown';
import { msDocs } from '../globals';
import { generateElementId } from '../html';
import { isPPE, isReview } from '../is-production';
import { getMeta } from '../meta';
import { parseQueryString } from '../query-string';
import { tocModal } from '../toc/modal-toc';
import { findPackageInFamily, getFamily } from './family';
import { getMoniker, monikerChangedEvent, setMoniker } from './moniker';
import { getPlatform, platformId } from './platform';

/**
 * For testing purposes, find the metadata tag experimental_moniker_picker to render this file
 */
export function experimentalMonikerPicker() {
	const isNotProduction = isReview || isPPE || location.hostname === 'localhost';
	const enabledViaQueryString =
		new URLSearchParams(location.search).get('experimental_moniker_picker') === 'true';
	const enabledViaMetadata = getMeta('experimental_moniker_picker') === 'true';

	return isNotProduction && (enabledViaMetadata || enabledViaQueryString);
}

/**
 * Initiate the rendering of the moniker picker
 *
 * @param allApis Is this the allApis page
 */
export function createMonikerPicker2(allApis: boolean) {
	const element = document.createElement('div');
	element.classList.add('moniker-picker2');
	element.setAttribute('data-bi-name', 'moniker-picker');

	// Render the elements so the user doesn't notice a UI change while we wait for the API to return data
	render(monikerPickerTemplate(allApis), element);

	if (allApis) {
		// This code will need to be refactored once we get a mockup from the design team. This is here as a placeholder.
		getPlatform().then(platform => {
			render(monikerPickerTemplate(allApis, null, platform), element);
			addEventListener(monikerChangedEvent, () =>
				render(monikerPickerTemplate(allApis, null, platform), element)
			);
		});
	} else {
		getFamily().then(family => {
			render(monikerPickerTemplate(allApis, family), element);
			addEventListener(monikerChangedEvent, () =>
				render(monikerPickerTemplate(allApis, family), element)
			);
		});
	}
	return element;
}

/**
 * Render the moniker picker
 *
 * @param allApis Is this the allApis page
 * @param family List of products from API
 * @param platform List of families and products from API
 */
export function monikerPickerTemplate(
	allApis: boolean,
	family?: Api.Family,
	platform?: Api.RefPlatform
): TemplateResult {
	const menuId = generateElementId();
	const archiveUrl = getMeta('archive_url');
	const singleProduct = family && family.products.length === 1;

	const handleProductSelection = (event: MouseEvent) => {
		if (tocModal) {
			event.preventDefault();
			event.stopPropagation();
			const productsMenu = document.createElement('div');
			productsMenu.classList.add(
				'modal-content',
				'is-docked-left',
				'is-flex',
				'is-flex-column',
				'has-padding-medium'
			);
			render(mobileMonikerPickerTemplate(family), productsMenu);
			tocModal.pushContent(productsMenu);
		}
	};

	const updateCaption = (allApis: boolean) => {
		let displayName;
		const moniker = getMoniker();

		if (allApis && moniker === '') {
			displayName = platformConfig[platformId].allApisLabel;
		} else if (allApis) {
			displayName = platform.packagesByMoniker[moniker].displayName;
		} else {
			displayName = family ? findPackageInFamily(family, moniker).displayName : '';
		}

		return displayName;
	};

	return html`
		<span class="label has-margin-bottom-extra-small">${loc_version}</span>
		<div class="dropdown has-margin-bottom-small">
			<button
				class="dropdown-trigger has-flex-justify-content-start is-full-width button is-small has-border has-inner-focus"
				aria-controls="${menuId}"
				aria-expanded="false"
				?disabled=${family &&
				family.products.length === 1 &&
				family.products[0].packages.length === 1}
				@click="${handleProductSelection}"
			>
				<span class="visually-hidden">${loc_selectVersion}</span>
				<span class="has-text-overflow-ellipsis"
					>${family || platform ? updateCaption(allApis) : ''}</span
				>
				<span class="dropdown-button-chevron" aria-hidden="true">
					<span class="icon docon docon-chevron-down-light expanded-indicator"></span>
				</span>
			</button>
			${!singleProduct
				? html`<ul class="dropdown-menu is-full-width" id="${menuId}" aria-label="${loc_product}">
						${allApis
							? html`<li>
									<a
										class="button is-small has-border-none has-inner-focus has-flex-justify-content-start is-full-width"
										href="?view="
										>${platformConfig[platformId].allApisLabel}</a
									>
							  </li>`
							: ''}
						${platform ? platform.products.map(product => createProductTemplate(product)) : ''}
						${family
							? family.products
								? family.products.map(product => createProductTemplate(product))
								: ''
							: ''}
				  </ul>`
				: html`<ul
						class="dropdown-menu is-full-width is-vertically-scrollable"
						id="${menuId}"
						aria-label="${family.products[0].displayName}"
				  >
						${family.products[0].packages.map(item => html`${createVersionTemplate(item)}`)}
						${archiveUrl
							? html`<li>
									<a
										class="button is-small has-border-none has-border-top has-inner-focus has-flex-justify-content-start is-full-width"
										href="${archiveUrl}"
										>${loc_archiveDocs}</a
									>
							  </li>`
							: ''}
				  </ul>`}
		</div>
	`;
}

/**
 * Create lit template for one product
 *
 * @param product Product data
 */
function createProductTemplate(product: Api.Product): TemplateResult {
	const menuId = generateElementId();
	const docon = msDocs.data.userDir === 'ltr' ? 'chevron-right-light' : 'chevron-left-light';
	const textAlign = msDocs.data.userDir === 'ltr' ? 'is-text-left' : 'is-text-right';
	const archiveUrl = getMeta('archive_url');

	return html`
		${product.packages.length !== 1
			? html`<li class="dropdown">
					<button
						class="dropdown-trigger button is-small has-border-none has-inner-focus has-flex has-flex-justify-content-start is-full-width has-text-wrap ${textAlign}"
						aria-controls="${menuId}"
						aria-expanded="false"
					>
						<span>${product.displayName}</span>
						<span class="dropdown-button-chevron" aria-hidden="true">
							<span class="docon docon-${docon}"></span>
						</span>
					</button>
					<ul
						class="dropdown-menu is-full-width is-vertically-scrollable"
						id="${menuId}"
						aria-label="${product.displayName}"
					>
						${product.packages.map(item => html`${createVersionTemplate(item)}`)}
						${archiveUrl
							? html`<li>
									<a
										class="button is-small has-border-none has-border-top has-inner-focus has-flex-justify-content-start is-full-width"
										href="${archiveUrl}"
										>${loc_archiveDocs}</a
									>
							  </li>`
							: ''}
					</ul>
			  </li>`
			: html`<li>
					<a
						class="preserve-view button is-small has-border-none has-inner-focus has-flex-justify-content-start is-full-width has-text-wrap ${textAlign}"
						href="?view=${product.packages[0].moniker}"
						@click=${handleVersionSelection}
						>${product.packages[0].displayName}</a
					>
			  </li>`}
	`;
}

/**
 * Create lit template for package data
 *
 * @param item package data
 */
function createVersionTemplate(item: Api.Package): TemplateResult {
	const textAlign = msDocs.data.userDir === 'ltr' ? 'is-text-left' : 'is-text-right';

	return html`
		<li>
			<a
				class="preserve-view button is-small has-border-none has-inner-focus has-flex-justify-content-start is-full-width has-text-wrap ${textAlign}"
				href="?view=${item.moniker}"
				@click=${handleVersionSelection}
				>${item.versionDisplayName}</a
			>
		</li>
	`;
}

/**
 * Render the mobile moniker picker
 *
 * @param family List of products from API
 */
export function mobileMonikerPickerTemplate(family: Api.Family): TemplateResult {
	const arrow = msDocs.data.userDir === 'ltr' ? 'arrow-left' : 'arrow-right';
	const singleProduct = family && family.products.length === 1;

	return html`
		${!singleProduct
			? html`<div
						class="is-flex has-flex-justify-content-space-between-touch has-flex-align-items-center has-text-weight-semibold has-flex-shrink-none"
					>
						<div class="is-flex has-flex-justify-content-start-mobile">
							<button
								type="button"
								class="modal-pop-content button has-border-none is-icon-only has-inner-focus has-padding-none has-flex-justify-content-start-mobile has-flex-align-self-center"
								autofocus
							>
								<span class="visually-hidden">${loc_back}</span>
								<span class="icon">
									<span class="docon docon-${arrow}" aria-hidden="true"></span>
								</span>
							</button>
							<span
								class="has-padding-left-small has-flex-justify-content-start-mobile has-flex-align-self-center"
								>${loc_products}</span
							>
						</div>
						<button
							type="button"
							class="modal-close button has-border-none is-icon-only has-inner-focus has-padding-none has-flex-align-self-center"
						>
							<span class="visually-hidden">${loc_close}</span>
							<span class="icon">
								<span class="docon docon-math-multiply" aria-hidden="true"></span>
							</span>
						</button>
					</div>
					<div class="has-flex-grow is-vertically-scrollable">
						${family
							? family.products
								? family.products.map(product => createMobileProductTemplate(product))
								: ''
							: ''}
					</div>`
			: html`${createMobileVersionTemplate(family.products[0])}`}
	`;
}

/**
 * Create mobile lit template for a list of products on mobile
 *
 * @param product Product data
 */
function createMobileProductTemplate(product: Api.Product): TemplateResult {
	const textAlign = msDocs.data.userDir === 'ltr' ? 'is-text-left' : 'is-text-right';

	return html`
		${product.packages.length !== 1
			? html` <ul aria-label="${loc_product}" @click="${() => handleMobileProductSelect(product)}">
					<li class="is-flex is-flex-column">
						<button
							class="button is-small has-border-none has-inner-focus has-flex has-flex-justify-content-start has-text-wrap ${textAlign}"
						>
							<span>${product.displayName}</span>
						</button>
					</li>
			  </ul>`
			: html`<a
					class="preserve-view button is-small has-inner-focus has-border-none has-flex-justify-content-start is-full-width has-text-wrap ${textAlign}"
					href="?view=${product.packages[0].moniker}"
					@click=${handleVersionSelection}
					>${product.packages[0].displayName}</a
			  >`}
	`;
}

/**
 * Create lit template for package versions on mobile
 *
 * @param product product versions
 */
function createMobileVersionTemplate(product: Api.Product): TemplateResult {
	const menuId = generateElementId();
	const arrow =
		msDocs.data.userDir === 'ltr' ? 'docon docon-arrow-left' : 'docon docon-arrow-right';
	const textAlign = msDocs.data.userDir === 'ltr' ? 'is-text-left' : 'is-text-right';
	const archiveUrl = getMeta('archive_url');

	return html`
		<div
			class="is-flex has-flex-justify-content-space-between-touch has-flex-align-items-center has-text-weight-semibold has-flex-shrink-none"
		>
			<div class="is-flex has-flex-justify-content-start-mobile">
				<button
					type="button"
					class="modal-pop-content button has-border-none is-icon-only has-inner-focus has-padding-none has-flex-justify-content-start-mobile has-flex-align-self-center"
				>
					<span class="visually-hidden">${loc_back}</span>
					<span class="icon">
						<span class=${arrow} aria-hidden="true"></span>
					</span>
				</button>
				<span
					class="has-padding-left-small has-flex-justify-content-start-mobile has-flex-align-self-center"
					>${product.displayName}</span
				>
			</div>
			<button
				type="button"
				class="modal-close button has-border-none is-icon-only has-inner-focus has-padding-none has-flex-align-self-center"
			>
				<span class="visually-hidden">${loc_close}</span>
				<span class="icon">
					<span class="docon docon-math-multiply" aria-hidden="true"></span>
				</span>
			</button>
		</div>
		<div class="has-flex-grow is-vertically-scrollable">
			<ul id="${menuId}" aria-label="${product.displayName}">
				${product.packages.map(
					item => html`
						<li>
							<a
								class="preserve-view button is-small has-border-none has-inner-focus is-full-width has-flex-justify-content-start has-text-wrap ${textAlign}"
								href="?view=${item.moniker}"
								@click=${handleVersionSelection}
								>${item.versionDisplayName}</a
							>
						</li>
					`
				)}
				${archiveUrl
					? html`<li>
							<a
								class="button is-small has-border-none has-inner-focus is-full-width has-flex-justify-content-start"
								href="${archiveUrl}"
								>${loc_archiveDocs}</a
							>
					  </li>`
					: ''}
			</ul>
		</div>
	`;
}

/**
 * tocModal is imported to recognize that an existing modal is being shown to the user.
 * This will determine that our mobile templates will be used to replace
 * existing modal content instead of initializing a new modal.
 *
 * In this case, the first modal is the Table of Contents data that displays after a user
 * has clicked on the Contents button found on the right hand side of the page.
 *
 * @param product Product data
 */
function handleMobileProductSelect(product: Api.Product) {
	if (tocModal) {
		const versionMenu = document.createElement('div');
		versionMenu.classList.add(
			'modal-content',
			'is-docked-left',
			'is-flex',
			'is-flex-column',
			'has-padding-medium'
		);
		render(createMobileVersionTemplate(product), versionMenu);
		tocModal.pushContent(versionMenu);
	}
}

/**
 * Handles whether a moniker page requires a full page reload or if the page can remain and only filter some of the content on the page
 *
 * @param event MouseEvent
 */
function handleVersionSelection(event: MouseEvent) {
	const target = event.target as HTMLElement;

	if (target instanceof HTMLAnchorElement) {
		event.preventDefault();
		const moniker = parseQueryString(target.search).view;
		setMoniker(moniker);

		if (tocModal) {
			tocModal.hide();
		} else {
			// collapse the first level
			collapseDropdown(target);
			// collapse the second level
			collapseDropdown(target);
		}

		return;
	}
}

import { notifyContentUpdated } from '../affix';
import { document } from '../globals';
import { keyCodes } from '../key-codes';

export function expander(controller: HTMLAnchorElement | HTMLButtonElement) {
	const isExpanded = () => controller.getAttribute('aria-expanded') === 'true';

	let finishPendingTransition: () => void;

	const toggleInternal = (expand: boolean) => {
		// pending transition? finish it.
		if (finishPendingTransition) {
			finishPendingTransition();
		}

		// set expanded status
		controller.setAttribute('aria-expanded', expand.toString());

		// lazy retrieve the target element(s)
		const targets = controller
			.getAttribute('aria-controls')
			.split(' ')
			.map(id => document.getElementById(id));

		for (const target of targets) {
			// prepare transition
			target.style.maxHeight = expand ? '0px' : '100vh';
			target.style.opacity = expand ? '0' : '1';
			target.style.transition = 'max-height 300ms ease-in-out, opacity 300ms ease-in-out';
			target.style.overflow = 'hidden';
			target.hidden = false;
		}

		// start transition
		const pendingAnimation = requestAnimationFrame(() => {
			for (const target of targets) {
				target.style.maxHeight = expand ? '100vh' : '0px';
				target.style.opacity = expand ? '1' : '0';
			}
		});

		const timeout = setTimeout(finish, 300);

		function finish() {
			// if the animation hadn't started yet, ensure it never does.
			cancelAnimationFrame(pendingAnimation);
			for (const target of targets) {
				// we can hide the target now that the animation is complete.
				target.hidden = !expand;
				// cleanup the transition.
				target.style.transition = '';
				target.style.maxHeight = '';
				target.style.opacity = '';
				target.style.overflow = '';
			}
			clearTimeout(timeout);
			finishPendingTransition = undefined;
			notifyContentUpdated();
		}

		finishPendingTransition = finish;

		controller.dispatchEvent(new CustomEvent(expand ? 'expand' : 'collapse', { bubbles: true }));
	};

	controller.onclick = event => {
		event.preventDefault();
		toggleInternal(!isExpanded());
	};

	const toggle = (expand = !isExpanded()) => {
		// no change? get out of here.
		if (isExpanded() === expand) {
			return;
		}
		toggleInternal(expand);
	};

	controller.onkeydown = event => {
		switch (event.which) {
			case keyCodes.left:
				event.preventDefault();
				toggle(false);
				break;
			case keyCodes.right:
				event.preventDefault();
				toggle(true);
				break;
		}
	};

	return toggle;
}

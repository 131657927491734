import { notifyContentUpdated } from './affix';
import { contentAttrs } from './bi';
import {
	isPlatform,
	platform,
	PlatformID,
	preferredPlatform,
	setPreferredPlatform
} from './environment';
import { document, history, location, window } from './globals';
import { keyCodes } from './key-codes';
import { parseQueryString, toQueryString } from './query-string';

/**
 * A single tab
 */
export class Tab {
	constructor(
		private li: HTMLLIElement,
		private a: HTMLAnchorElement,
		private section: HTMLElement
	) {}

	get tabIds() {
		return this.a.getAttribute('data-tab').split(' ');
	}

	// possible addition of get hasCombinedId or getCombinedId, problem being they're cleaned up a bit later

	get condition() {
		return this.a.getAttribute('data-condition');
	}

	get visible() {
		return !this.li.hasAttribute('hidden');
	}
	set visible(value) {
		if (value) {
			this.li.removeAttribute('hidden');
			this.li.removeAttribute('aria-hidden');
		} else {
			this.li.setAttribute('hidden', 'hidden');
			this.li.setAttribute('aria-hidden', 'true');
		}
	}

	get selected() {
		return !this.section.hasAttribute('hidden');
	}
	set selected(value) {
		if (value) {
			this.a.setAttribute('aria-selected', 'true');
			this.a.tabIndex = 0;
			this.section.removeAttribute('hidden');
			this.section.removeAttribute('aria-hidden');
		} else {
			this.a.setAttribute('aria-selected', 'false');
			this.a.tabIndex = -1;
			this.section.setAttribute('hidden', 'hidden');
			this.section.setAttribute('aria-hidden', 'true');
		}
	}

	public focus() {
		this.a.focus();
	}
}

/**
 * A tab group
 */
export interface TabGroup {
	independent: boolean;
	tabs: Tab[];
}

/**
 * The page's tab state: the tab groups and the selected tabs
 */
export interface PageTabState {
	groups: TabGroup[];
	selectedTabs: string[];
}

/**
 * Updates a TabGroup's tab visibility and selection status based on the
 * page's shared tab state.
 */
export function updateVisibilityAndSelection(group: TabGroup, state: PageTabState) {
	let anySelected = false;
	let platformTab: Tab;
	let firstVisibleTab: Tab;

	for (const tab of group.tabs) {
		// no condition OR condition is met? VISIBLE.
		tab.visible = tab.condition === null || state.selectedTabs.indexOf(tab.condition) !== -1;
		// visible tabs may be needed later for default tab selection...
		if (tab.visible) {
			if (!firstVisibleTab) {
				firstVisibleTab = tab;
			}

			if (!platformTab && tab.tabIds[0] === (preferredPlatform || platform)) {
				platformTab = tab;
			}
		}
		// visible AND is a selected tabId? SELECTED.
		tab.selected = tab.visible && arraysIntersect(state.selectedTabs, tab.tabIds);
		anySelected = anySelected || tab.selected;
	}

	// no tab in the group is selected? select the tab matching the user's platform or the first visible tab.
	if (!anySelected) {
		/*
			Purpose of for loop:
			- remove tabs from selectedTabs if they aren't selectable due to visibility / condition changes
			- garbage collect query string)
		*/
		for (const { tabIds } of group.tabs) {
			for (const tabId of tabIds) {
				const index = state.selectedTabs.indexOf(tabId);
				if (index === -1) {
					continue;
				}
				state.selectedTabs.splice(index, 1);
			}
		}

		const tab = platformTab || firstVisibleTab;
		tab.selected = true;
		state.selectedTabs.push(tab.tabIds[0]);
	}
}

/**
 * Reads a .tabGroup element's DOM and returns an instance of TabGroup.
 * @param element The .tabGroup element
 */
export function initTabGroup(element: HTMLDivElement) {
	// A tab group is considered independent if the shared tab "state" argument was not provided
	// OR if the tab group is decorated with the "data-tab-group-independent" attribute.
	const group: TabGroup = {
		independent: element.hasAttribute('data-tab-group-independent'),
		tabs: []
	};

	let li = element.firstElementChild.firstElementChild as HTMLLIElement;
	while (li) {
		const a = li.firstElementChild as HTMLAnchorElement;
		a.setAttribute(contentAttrs.name, 'tab');
		const dataTab = a.getAttribute('data-tab').replace(/\+/g, ' '); // combined tabs backend renders <a data-tab="linux+macos"> but should render <a data-tab="linux macos">
		a.setAttribute('data-tab', dataTab);
		const id = a.getAttribute('aria-controls');
		const section = element.querySelector(`[id="${id}"],[data-id="${id}"]`) as HTMLElement;
		const tab = new Tab(li, a, section);
		group.tabs.push(tab);
		li = li.nextElementSibling as HTMLLIElement;
	}

	element.setAttribute(contentAttrs.name, 'tab-group');

	(element as any).tabGroup = group;

	return group;
}

/**
 * Adds tab behavior to the .tabGroup elements on the page.
 */
export function initTabs(container: HTMLElement) {
	const queryStringTabs = readTabsQueryStringParam();

	const elements = container.querySelectorAll('.tabGroup') as NodeListOf<HTMLDivElement>;
	const state: PageTabState = { groups: [], selectedTabs: [] };
	for (let i = 0; i < elements.length; i++) {
		const group = initTabGroup(elements.item(i));
		if (!group.independent) {
			updateVisibilityAndSelection(group, state);
			state.groups.push(group);
		}
	}

	container.addEventListener('click', event => handleClick(event, state));
	container.addEventListener('keydown', event => handleKeyDown(event));

	if (state.groups.length === 0) {
		return state;
	}

	selectTabs(queryStringTabs, container);
	updateTabsQueryStringParam(state);

	notifyContentUpdated();

	return state;
}

/**
 * Gets the tabId and tab group associated with an event target.
 * Null is returned when the event is not related to a tab's anchor element.
 */
export function getTabInfoFromEvent(event: Event) {
	if (!(event.target instanceof HTMLElement)) {
		return null;
	}

	const anchor = event.target.closest('a[data-tab]') as HTMLAnchorElement;
	if (anchor === null) {
		return null;
	}

	const tabIds = anchor.getAttribute('data-tab').split(' ');
	const group: TabGroup = (anchor.parentElement.parentElement.parentElement as any).tabGroup;
	if (group === undefined) {
		return null;
	}

	return { tabIds, group, anchor };
}

export function handleClick(event: Event, state: PageTabState) {
	const info = getTabInfoFromEvent(event);
	if (info === null) {
		return;
	}

	event.preventDefault();
	// preventDefault is not enough in Edge... temporarily remove the hash from the href.
	info.anchor.href = 'javascript:';
	setTimeout(() => (info.anchor.href = '#' + info.anchor.getAttribute('aria-controls')));

	const { tabIds, group } = info;

	// squirrel away the target's position relative to the viewport.
	const originalTop = info.anchor.getBoundingClientRect().top;

	if (group.independent) {
		for (const tab of group.tabs) {
			tab.selected = arraysIntersect(tab.tabIds, tabIds);
		}
	} else {
		// already selected?
		if (arraysIntersect(state.selectedTabs, tabIds)) {
			return;
		}
		// find the previously selected tab in the clicked tab group.
		const previousTabId = group.tabs.filter(t => t.selected)[0].tabIds[0];
		// update the selection state.
		state.selectedTabs.splice(state.selectedTabs.indexOf(previousTabId), 1, tabIds[0]);
		// update state.
		for (const group of state.groups) {
			updateVisibilityAndSelection(group, state);
		}
		updateTabsQueryStringParam(state);
	}

	notifyContentUpdated();

	// remember the user's platform selection.
	if (isPlatform(tabIds[0])) {
		setPreferredPlatform(tabIds[0] as PlatformID);
	}

	// ensure the target is in the same position relative to the viewport.
	const top = info.anchor.getBoundingClientRect().top;
	if (top !== originalTop && event instanceof MouseEvent) {
		window.scrollTo(0, window.pageYOffset + top - originalTop);
	}
}

export function handleKeyDown(event: KeyboardEvent) {
	const info = getTabInfoFromEvent(event);
	if (info === null) {
		return;
	}

	const { tabIds, group } = info;

	// left/right/up/down arrow should move the focus to the preceding/next visible tab
	// ctrl+left/right arrow should move focus to first/last tab
	// home/end should move focus to first/last tab
	const key = event.which;
	if (
		!event.altKey &&
		(key === keyCodes.left ||
			key === keyCodes.right ||
			key === keyCodes.home ||
			key === keyCodes.end)
	) {
		event.preventDefault();

		const isLeft = key === keyCodes.left || key === keyCodes.home;
		let index: number;

		if (event.ctrlKey || key === keyCodes.home || key === keyCodes.end) {
			const increment = isLeft ? 1 : -1;
			index = isLeft ? 0 : group.tabs.length - 1;
			while (!group.tabs[index].visible) {
				index += increment;
			}
		} else {
			// find the position of the tab represented by the event target.
			const increment = isLeft ? -1 : 1;
			index = isLeft ? group.tabs.length - 1 : 0;
			while (group.tabs[index].tabIds[0] !== tabIds[0] || !group.tabs[index].visible) {
				index += increment;
			}
			// find the next visible tab.
			do {
				index += increment;
				if (index === -1) {
					index = group.tabs.length - 1;
				} else if (index === group.tabs.length) {
					index = 0;
				}
			} while (!group.tabs[index].visible);
		}

		group.tabs[index].focus();

		return;
	}
}

/**
 * Attempt to select a series of tabs.
 */
function selectTabs(tabIds: string[], container: HTMLElement) {
	for (const tabId of tabIds) {
		const a = container.querySelector(`.tabGroup > ul > li > a[data-tab="${tabId}"]:not([hidden])`);
		if (a === null) {
			return;
		}
		a.dispatchEvent(new CustomEvent('click', { bubbles: true }));
	}
}

/**
 * Reads the &tabs=tabId1,tabId2 query string parameter and returns an array
 * of tabIds.
 */
export function readTabsQueryStringParam() {
	const qs = parseQueryString();
	const t = qs.tabs;
	if (t === undefined || t === '') {
		return [];
	}
	return t.split(',');
}

/**
 * Writes the selected tabs to the query string.
 */
export function updateTabsQueryStringParam(state: PageTabState) {
	const qs = parseQueryString();
	qs.tabs = state.selectedTabs.join();
	const url = `${location.protocol}//${location.host}${location.pathname}?${toQueryString(qs)}${
		location.hash
	}`;
	if (location.href === url) {
		return;
	}
	history.replaceState({}, document.title, url);
}

export function arraysIntersect(a: string[], b: string[]) {
	for (const itemA of a) {
		for (const itemB of b) {
			if (itemA === itemB) {
				return true;
			}
		}
	}
	return false;
}

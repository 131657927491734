import { loc_search } from '@msdocs/strings';
import { html } from '../../../lit-html';
import { SearchViewModel } from '../view-model';

export function facetSearchFormTemplate<TResult>(vm: SearchViewModel<TResult>) {
	return html`
		<form action="javascript:" data-bi-name="search-form" @submit=${(e: Event) => vm.submit(e)}>
			<div class="columns is-mobile is-gapless">
				<div class="column">${vm.input}</div>
				<div class="column is-narrow">
					<button
						class="button is-primary is-large has-margin-left-small is-icon-only-mobile"
						type="submit"
						aria-label="${loc_search}"
						data-bi-name="search-button"
					>
						<span class="icon is-small" aria-hidden="true">
							<span class="docon docon-search"></span>
						</span>
						<span class="is-hidden-mobile" aria-hidden="true">
							${loc_search}
						</span>
					</button>
				</div>
			</div>
		</form>
	`;
}

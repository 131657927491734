import { notifyContentUpdated } from '../affix';
import { isPlatform, platform, preferredPlatform, setPreferredPlatform } from '../environment';
import { fetchWithTimeout } from '../fetch';
import { contentLoaded, location, msDocs } from '../globals';
import { renderInTopicTOC } from '../in-topic-toc';
import { getMeta } from '../meta';
import { localStorage } from '../protected-storage';
import { parseQueryString, toQueryString, updateQueryString } from '../query-string';
import { beforeUnload, listenUntilUnload } from '../router/utils';

const selectedClasses = ['is-primary', 'is-selected'];
const docsetBase = location.pathname.split('/').slice(0, 3).join('/');

interface ZonePivot {
	id: string;
	title: string;
}

interface ZonePivotGroup {
	id: string;
	title: string;
	prompt: string;
	pivots: ZonePivot[];
}

interface ZonePivotPreferences {
	get(group: string): string | null;
	set(group: string, pivot: string): void;
}

/**
 * Sets up zone pivots.
 */
export function initZonePivots() {
	const groups = (getMeta('zone_pivot_groups') || '')
		.split(',')
		.map(g => g.trim())
		.filter(g => g.length);
	if (!groups.length) {
		return Promise.resolve();
	}

	const preferences: ZonePivotPreferences = {
		get(group) {
			return localStorage.getItem(`zone-pivot${docsetBase}/${group}`);
		},
		set(group, pivot) {
			localStorage.setItem(`zone-pivot${docsetBase}/${group}`, pivot);
		}
	};

	return Promise.all([getDefinitions(), contentLoaded]).then(([definitions]) => {
		let insertAfter = document.querySelector('.content .top-alert');
		if (!insertAfter) {
			insertAfter = document.querySelector('.content .page-metadata');
		}
		if (!insertAfter) {
			return;
		}
		renderZonePivots(insertAfter, groups, definitions, preferences);
	});
}

/**
 * Renders the zone pivot experience.
 * @param insertAfter The element to insert the pivot alert after.
 * @param groups The pivot groups to display.
 * @param definitions The pivot group definitions.
 * @param preferences The pivot group preferences.
 */
function renderZonePivots(
	insertAfter: Element,
	groups: string[],
	definitions: ZonePivotGroup[],
	preferences: ZonePivotPreferences
) {
	// read contextual information from msDocs, query string and meta.
	const pivotsArg = parseQueryString().pivots;
	const queryStringPivots = pivotsArg ? pivotsArg.split(',').map(x => x.trim().toLowerCase()) : [];
	const selectedPivots = getInitialSelection(
		definitions,
		groups,
		queryStringPivots,
		preferences,
		preferredPlatform || platform
	);

	// create the style element that will hold the pivot show/hide rules.
	const style = document.createElement('style');
	document.head.appendChild(style);

	displaySelectedPivots(style, selectedPivots);

	const toDisplay = groups
		.map(group => definitions.find(g => g.id === group))
		.filter(element => element !== undefined);
	if (toDisplay.length === 0) {
		return;
	}

	const alert = createPivotAlert(insertAfter, toDisplay, selectedPivots);
	const radios = Array.from(
		alert.querySelectorAll<HTMLInputElement>('input[type="radio"]')
	).map(input => ({ input, button: input.closest('.button') }));

	// syncs the .button element's selected status with the visually hidden input's checked status.
	const syncChecked = () => {
		displaySelectedPivots(
			style,
			radios.filter(x => x.input.checked).map(x => x.input.value)
		);

		radios.forEach(({ input, button }) => {
			if (input.checked) {
				button.classList.add(...selectedClasses);
				button.querySelector<HTMLElement>('.icon').classList.remove('is-hidden');
			} else {
				button.classList.remove(...selectedClasses);
				button.querySelector<HTMLElement>('.icon').classList.add('is-hidden');
			}
		});
	};

	// syncs the .button element's focus status with the visually hidden input's focus status.
	const syncFocus = () =>
		radios.forEach(({ input, button }) => {
			const method = input.matches(':focus') && input.matches('.focus-visible') ? 'add' : 'remove';
			button.classList[method]('is-focused');
		});

	// when the user changes the selected pivot, store their preference.
	const storePreference = (event: Event) => {
		const { name: group, value: pivot } = event.target as HTMLInputElement;
		preferences.set(group, pivot);
		if (isPlatform(pivot)) {
			setPreferredPlatform(pivot);
		}
	};

	listenUntilUnload(alert, 'change', syncChecked);
	listenUntilUnload(alert, 'blur', syncFocus, true);
	listenUntilUnload(alert, 'focus', syncFocus, true);
	listenUntilUnload(alert, 'change', storePreference);
	beforeUnload(() => style.remove());
}

/**
 * Syncs the UX with the selected pivots.
 */
function displaySelectedPivots(style: HTMLStyleElement, selectedPivots: string[]) {
	style.textContent = `
		[data-pivot]${selectedPivots.map(pivot => `:not([data-pivot~='${pivot}'])`).join('')} {
			display: none !important;
		}`;

	renderInTopicTOC();
	notifyContentUpdated();
	updateQueryString({ pivots: selectedPivots.join() }, 'replaceState');
}

/**
 * Inserts the pivot alert after the specified element.
 * @param insertAfter The element to insert after.
 * @param toDisplay The pivot group to display in Pivot Alert.
 * @param selectedPivots The pivots that should be selected initially.
 */
function createPivotAlert(
	insertAfter: Element,
	toDisplay: ZonePivotGroup[],
	selectedPivots: string[]
) {
	const { userDir, userLocale } = msDocs.data;

	insertAfter.insertAdjacentHTML(
		'afterend',
		`
		<form data-bi-name="zone-pivots" class="alert" dir="${userDir}" lang="${userLocale}">
			${toDisplay
				.map(
					group => `
			<fieldset class="field has-padding-none" aria-label="${group.prompt}">
				<legend class="label">
					${group.prompt}
				</legend>

				<div class="buttons has-addons">
					${group.pivots
						.map(
							pivot => `
					<label class="button ${selectedPivots.indexOf(pivot.id) === -1 ? '' : selectedClasses.join(' ')}">
						<span class="icon high-contrast-only ${
							selectedPivots.indexOf(pivot.id) === -1 ? 'is-hidden' : ''
						}"><span aria-hidden="true" class="docon docon-location"></span></span>
						<input class="visually-hidden" type="radio" name="${group.id}" value="${pivot.id}" ${
								selectedPivots.indexOf(pivot.id) === -1 ? '' : 'checked'
							}>
						<span>${pivot.title}</span>
					</label>`
						)
						.join('\n')}
			</fieldset>`
				)
				.join('\n')}
		</form>`
	);
	return insertAfter.nextElementSibling;
}

/**
 * Determines which pivots should be selected on page load.
 * @param definitions The pivot group definitions.
 * @param groups The pivot groups to display.
 * @param queryStringPivots The pivots passed in via the query string.
 * @param platform The current/preferred platform.
 */
export function getInitialSelection(
	definitions: ZonePivotGroup[],
	groups: string[],
	queryStringPivots: string[],
	preferences: ZonePivotPreferences,
	platform: string
) {
	const selectedPivots = [];
	// loop through the groups to display
	for (const group of groups) {
		// get the group's definition
		const definition = definitions.find(d => d.id === group);
		if (!definition) {
			continue;
		}
		const preferred = preferences.get(group);
		const pivots = definition.pivots;
		// find the first pivot in the group, the preferred pivot in the group (if any),
		// the platform pivot in the group (if any), and the query string-pivot in the group.
		let queryStringPivot: string;
		let preferredPivot: string | undefined;
		let platformPivot: string | undefined;
		let firstPivotInGroup: string | undefined;

		for (const pivot of pivots) {
			if (!firstPivotInGroup) {
				firstPivotInGroup = pivot.id;
			}
			if (!queryStringPivot && queryStringPivots.indexOf(pivot.id) !== -1) {
				queryStringPivot = pivot.id;
			}
			if (!preferredPivot && pivot.id === preferred) {
				preferredPivot = pivot.id;
			}
			if (!platformPivot && pivot.id === platform) {
				platformPivot = pivot.id;
			}
		}
		// select a pivot from the group, prioritizing: query-string > preferred > platform > first.
		selectedPivots.push(queryStringPivot || preferredPivot || platformPivot || firstPivotInGroup);
	}
	return selectedPivots;
}

/**
 * Loads the zone pivot group definitions.
 */
function getDefinitions(): Promise<ZonePivotGroup[]> {
	const url = getDefinitionsUrl();

	return fetchWithTimeout(url, { credentials: 'include' })
		.then(response => response.json())
		.then(definitions => definitions.groups);
}

/**
 * Gets the url of the zone pivot group definitions.
 * File is expected to be at the root of the docset eg: "/en-us/azure/zone-pivot-groups.json"
 * zone_pivot_group_filename meta may be used to override the default name for the file.
 */
export function getDefinitionsUrl() {
	const { branch } = parseQueryString();
	const filename = getMeta('zone_pivot_group_filename') || 'zone-pivot-groups.json';
	const queryString = branch ? '?' + toQueryString({ branch }) : '';
	return `${docsetBase}/${filename}${queryString}`;
}

import { getOrigin } from '../get-origin';
import { msDocs } from '../globals';
import { loadHoverImages, loadScaleImages } from '../image-effects';
import { parseQueryString } from '../query-string';

// TODO: Delete entire file
export function oldHubPagePage() {
	const hash = parseQueryString(location.hash);
	let selectedPivotID = hash.pivot;
	let selectedPanelID = hash.panel;
	let panelItemNavOffsets: number[] = [];
	const pivotSelectorClassName = 'pivotSelector';
	let isPivotMenuVisible = false;
	let savedPanelNavItem: JQuery;

	function hidePivotMenu() {
		if (isPivotMenuVisible) {
			$('.pivots').parent().removeClass('pivotMenu');
		}
		isPivotMenuVisible = false;
	}

	function togglePivotMenu() {
		if (isPivotMenuVisible) {
			hidePivotMenu();
		} else {
			$('.pivots').parent().addClass('pivotMenu');
			isPivotMenuVisible = true;
		}
	}

	let handlingHashChange = false;

	function updateHash() {
		if (handlingHashChange) {
			return;
		}
		let hsh = '';

		if (selectedPivotID !== undefined) {
			hsh = 'pivot=' + selectedPivotID.substring(1);

			if (selectedPanelID !== undefined) {
				hsh += '&panel=' + selectedPanelID.substring(1);
			}

			removeEventListener('hashchange', handleHashChange);
			parent.location.hash = hsh;
			setTimeout(function () {
				addEventListener('hashchange', handleHashChange);
			});
		}
	}

	function handleHashChange() {
		const hash = parseQueryString(location.hash);

		handlingHashChange = true;

		// simulate a click on the pivot corresponding to the current hash.
		const tabs = document.querySelector('nav.pivotTabs');
		let el =
			tabs.querySelector('a[href$="#' + hash.pivot + '"]') ||
			tabs.querySelector('a[data-default="true"]') ||
			tabs.querySelector('a');

		$(el).trigger('click');

		// simulate a click on the panel corresponding to the current hash.
		el = document.querySelector('a[href$="#' + hash.panel + '"]');
		if (el) {
			$(el).trigger('click');
		}

		setTimeout(function () {
			handlingHashChange = false;
		}, 100);
	}

	addEventListener('hashchange', handleHashChange);

	function selectPanelNavItem($this: JQuery, isUrlUpdated: boolean) {
		const $link = $($this.children('a')[0]);
		selectedPanelID = $link.attr('href');

		const $selectedPivot = $('ul.pivots').find(selectedPivotID);

		if (isUrlUpdated) {
			updateHash();
		}

		const panelNavIndex = +$this.attr('data-index');
		let topOffset = '0';
		if (panelNavIndex > 0) {
			topOffset = '-' + panelItemNavOffsets[panelNavIndex] + 'px';
		} else {
			//check if only panelNavItem
			if ($link.parent().siblings().length === 0 && !$link.hasClass('singlePanelNavItem')) {
				$selectedPivot.find(selectedPanelID).addClass('singlePanelContent');
				$link.addClass('singlePanelNavItem');
			}
		}

		$(selectedPivotID).find('li>a').removeClass('selected');
		$link.addClass('selected');

		//hide all panelContent divs except for unplanned embedded divs
		$selectedPivot.find('.panelContent').not('.panelContent .panelContent').hide();
		$selectedPivot.find(selectedPanelID).css({ 'margin-top': topOffset, display: 'flex' });
	}

	function selectPanel($this: JQuery) {
		if ($this === undefined) {
			let hasPanelBeenSelected = false;
			let panelCount = 0;
			const $selectedPivot = $('ul.pivots').find(selectedPivotID);

			$selectedPivot.children('li').each(function () {
				panelCount += 1;

				if (
					selectedPanelID !== undefined &&
					$(this).children('a').attr('href').toLowerCase() === selectedPanelID.toLowerCase()
				) {
					hasPanelBeenSelected = true;
					// @ts-ignore
					selectPanelNavItem($(this), false);
				}
			});

			if (panelCount > 0 && !hasPanelBeenSelected) {
				const defaultLinks = $selectedPivot.find("li > a[data-default='true']");
				if (defaultLinks.length > 0) {
					// @ts-ignore
					selectPanelNavItem($(defaultLinks[0]).parent(), false);
				} else {
					// @ts-ignore
					selectPanelNavItem($selectedPivot.children('li:first'), false);
				}
			}
		} else {
			const thisParentID = $this.parent().parent().attr('data-id');
			if (selectedPivotID !== thisParentID) {
				savedPanelNavItem = $this;
				selectPivot($("nav.pivotTabs a[href='" + thisParentID + "']"), true);
			} else {
				selectPanelNavItem($this, true);
			}
		}
	}

	function selectPivot($this: JQuery, isUrlUpdated: boolean) {
		selectedPivotID = $this.attr('href');

		if (isUrlUpdated) {
			selectedPanelID = undefined;
			updateHash();
		}

		$('nav.pivotTabs li').removeClass('selected');
		$this.parent().addClass('selected');

		$('.pivotItem').hide();
		const $selectedPivot = $(".pivotItem[data-id='" + selectedPivotID + "']");
		$selectedPivot.show();

		panelItemNavOffsets = [];
		$selectedPivot.find('.panelItem>a').each(function (index) {
			$(this).parent().attr('data-index', index);
			// @ts-ignore
			panelItemNavOffsets.push(this.offsetTop);
		});

		$('.' + pivotSelectorClassName).text($this.text());

		$('.pivotItem>ul>li>a').removeClass('selected');

		selectPanel(savedPanelNavItem);

		savedPanelNavItem = undefined;
	}

	function init() {
		let hasPivotBeenSelected = false;

		//init
		if (selectedPivotID !== undefined) {
			selectedPivotID = '#' + selectedPivotID;
		}
		if (selectedPanelID !== undefined) {
			selectedPanelID = '#' + selectedPanelID;
		}

		//build pivots
		const pivotsNavUl = $('<ul>');
		const pivotLinks = $('ul.pivots>li>a');
		const pivotCount = pivotLinks.length;
		pivotLinks.each(function () {
			pivotsNavUl.append($('<li>').append($(this).clone()));

			$(this).parent().attr('data-id', $(this).attr('href')).addClass('pivotItem');

			const hrefAttr = $(this).attr('href');
			if (typeof hrefAttr !== 'undefined' && hrefAttr.substring(0, 1) === '#') {
				$(this).on('click', function (e) {
					e.preventDefault();
					hidePivotMenu();
					selectPivot($(this), false);
				});
			}
		});

		$('.pivotItem>ul').each(function () {
			$(this).css({ position: 'relative' });
		});

		$('.pivotItem>ul>li').each(function () {
			$(this).addClass('panelItem');
		});

		$('.panelItem>ul').each(function () {
			$(this).addClass('panelContent');
		});

		$('ul.panelContent > li > div.container').each(function () {
			$(this).parent().addClass('fullSpan');
		});

		const $pivotsNav = $('<nav>').addClass('pivotTabs').append(pivotsNavUl);
		const $pivots = $('ul.pivots');

		if (pivotCount === 1) {
			$pivotsNav.addClass('singlePivot');
			$pivots.addClass('tabLess');
		}

		$pivots.before($pivotsNav);

		if (pivotCount > 1) {
			$pivots.before($('<button>').addClass(pivotSelectorClassName).on('click', togglePivotMenu));
		}

		//panel nav setup
		$('.panelItem>a').each(function () {
			$(this).attr('data-bi-name', 'hubpage-vertical-tab');
			if ($(this).attr('href').substring(0, 1) === '#') {
				$(this).on('click', function (e) {
					e.preventDefault();
				});

				$(this)
					.parent()
					.on('click', function () {
						hidePivotMenu();
						selectPanel($(this));
					});
			}
		});

		//pivot tabs nav setup
		$('nav.pivotTabs a').each(function () {
			const hrefAttr = $(this).attr('href');
			$(this).attr('data-bi-name', 'hubpage-horizontal-tab');
			if (typeof hrefAttr !== 'undefined' && hrefAttr.substring(0, 1) === '#') {
				if (
					selectedPivotID !== undefined &&
					$(this).attr('href').toLowerCase() === selectedPivotID.toLowerCase()
				) {
					hasPivotBeenSelected = true;
					selectPivot($(this), false);
				}

				$(this).on('click', function (e) {
					selectPivot($(this), true);
					e.stopPropagation();
					e.preventDefault();
				});
			}
		});

		if (pivotCount > 0 && !hasPivotBeenSelected) {
			const defaultLinks = $("nav.pivotTabs a[data-default='true']");
			if (defaultLinks.length > 0) {
				selectPivot($(defaultLinks[0]), false);
			} else {
				selectPivot($('nav.pivotTabs a:first'), false);
			}
		}

		const setUserLocaleInAnchors = function () {
			if (msDocs.data.userLocale === 'en-us') {
				return;
			}

			Array.from(
				document.querySelectorAll<HTMLAnchorElement>(
					'.panelItem ul li a[href]:not([href=""]):not([href="#"]'
				)
			).forEach(element => {
				const isDocsLink = getOrigin(element) === location.origin;
				if (!isDocsLink) {
					return;
				}

				const segments = element.pathname.split('/');
				if (segments[1] === 'en-us') {
					segments[1] = msDocs.data.userLocale;
					element.pathname = segments.join('/');
				}
			});
		};

		setUserLocaleInAnchors();

		loadScaleImages();
		loadHoverImages();
	}

	init();
}

import { msDocs } from './globals';

export function formatNumber(value: number) {
	return new Intl.NumberFormat(msDocs.data.userLocale).format(value);
}

export const numberFormatCompact = new Intl.NumberFormat(msDocs.data.userLocale, {
	notation: 'compact',
	compactDisplay: 'short'
} as any);

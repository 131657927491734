import { toQueryString } from '../query-string';
import { context } from './context';

export function initViewOnGithub(section: Element) {
	const viewOnGitHub = section.querySelector('.view-on-github') as HTMLAnchorElement;
	viewOnGitHub.search = toQueryString({
		utf8: '✓',
		q: `"${context.versionIndependentDocumentId}"`,
		in: 'body'
	});
}

import { loc_loading } from '@msdocs/strings';
import { cookies } from './cookies';
import { msDocs } from './globals';
import { html, until } from './lit-html';
import { parseQueryString } from './query-string';

export function isDevelopment(origin: string): boolean {
	const allowList = {
		'https://review.docs.microsoft.com': true,
		'https://ppe.docs.microsoft.com': true
	} as { [origin: string]: true };
	if (allowList[origin]) {
		return true;
	}
	return false;
}

function createBranchSelectorHtml(branches: string[]) {
	const selectedBranch = parseQueryString().branch || cookies.get('CONTENT_BRANCH') || '';
	const branchesHtml = branches
		.sort()
		.map(branchName => {
			const selected = branchName === selectedBranch ? 'selected' : '';
			return `<option value="${branchName}" ${selected}>${branchName}</option>`;
		})
		.join('');

	return `
		<div class="select is-small is-rounded">
			<select id="branch-selector" class="has-width-150">
				<option value="">Select Branch</option>
				${branchesHtml}
			</select>
		</div>`;
}

export function renderBranchSelector() {
	if (!isDevelopment(location.origin)) {
		return;
	}
	const actionList = document.querySelector('.action-list');
	if (actionList === null) {
		return;
	}

	const contentEditButton = document.querySelector<HTMLElement>('#contenteditbtn');
	if (contentEditButton !== null) {
		contentEditButton.hidden = false;
	}

	if (msDocs.data.context.branches) {
		const branchList = msDocs.data.context.branches.split(',');
		if (branchList.length === 0) {
			return;
		}

		const dropdownBlock = document.createElement('div');
		dropdownBlock.classList.add('control');
		dropdownBlock.innerHTML = createBranchSelectorHtml(branchList);
		const dropdownSelect = dropdownBlock.querySelector('select');

		dropdownSelect.onchange = () => {
			const targetBranchName = dropdownSelect.value;
			cookies.set('CONTENT_BRANCH', targetBranchName);
			window.location.search = `?branch=${encodeURIComponent(targetBranchName)}`;
		};

		const branchSelector = document.createElement('li');
		branchSelector.appendChild(dropdownBlock);

		// append to action list
		actionList.insertAdjacentElement('afterbegin', branchSelector);
	}
}

export interface BranchSelectorConfig {
	id: string;
	branches: Promise<string[]>;
	onchange: (e: Event) => void;
	currentBranch?: string;
	loadingMessage?: string;
	classList?: string;
}

export function createBranchSelectorTemplate({
	id,
	branches,
	onchange,
	currentBranch = 'master',
	loadingMessage = loc_loading,
	classList
}: BranchSelectorConfig) {
	const promised = branches.then(data => {
		if (data.length < 1) {
			return html``;
		}
		const sortedBranches = data.sort();
		return html`
			<div class="select ${classList ? classList : ''}">
				<select id="${id}" class="has-width-150" @change=${onchange}>
					<option value="">Select Branch</option>

					${sortedBranches.map(branchName => {
						return html` <option value="${branchName}" ?selected=${currentBranch === branchName}>
							${branchName}
						</option>`;
					})}
				</select>
			</div>
		`;
	});

	const loadingTemplate = () => {
		return html`<div class="select ${classList ? classList : ''}">
			<select id="${id}" class="has-width-150">
				<option value="">${loadingMessage}</option>
			</select>
		</div>`;
	};
	return html`${until(promised, loadingTemplate())}`;
}

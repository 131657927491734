import { loc_landmarkToc, loc_primary } from '@msdocs/strings';
import { tabletOrDesktopQuery } from '../../src/match-media';
import { notifyContentUpdated } from '../affix';
import { jsllReady } from '../bi';
import { createTree, resetFocusTarget } from '../components/tree';
import { features } from '../environment/features';
import { contentLoaded, document, location, msDocs } from '../globals';
import { getMeta } from '../meta';
import { getMoniker, monikerChangedEvent } from '../monikers/moniker';
import { createMonikerPicker2 } from '../monikers/moniker-picker2';
import { useMonikerPicker } from '../toc-setup';
import { extendBreadcrumbs, getBreadcrumbsForUrl, renderBreadcrumbs } from './breadcrumbs';
import { findLocation } from './find-location';
import { loadFusionTocs, mergeTocs } from './fusion';
import { getLastClicked, setLastClicked } from './last-clicked';
import { initModalToc } from './modal-toc';
import { normalizeBreadcrumbResponse, normalizeTocResponse, removeLrm } from './normalize';
import { createPdfLink, getPdfUrl } from './pdf';
import { createTocSearchForm } from './search/form';
import { TocNodeAccessor } from './toc-node-accessor';
import { expandAndSelectPath, resolveUrls, ungroupRootNode } from './transforms';
import { TocMetadata, TocNode } from './types';
import { normalizeLocation } from './url';
import { getUrlQueue, loadUrlQueue } from './url-queue';

const normalizedLocation = normalizeLocation();
const emptyToc: ReturnType<typeof loadToc> = Promise.resolve({
	url: null,
	nodes: [],
	metadata: {}
});
const emptyBreadcrumbs: ReturnType<typeof loadBreadcrumbs> = Promise.resolve({
	url: null,
	nodes: []
});

function pageHasStandardToc() {
	const hasSidebar = document.documentElement.classList.contains('hasSidebar');
	const isExcluded = msDocs.data.pageTemplate === 'Tutorial';
	return hasSidebar && !isExcluded;
}

function pageHasStandardBreadcrumbs() {
	const isExcluded = getMeta('page_type') === 'learn' || getMeta('page_kind') === 'assessments';
	const hasBreadcrumbs = document.documentElement.classList.contains('hasBreadcrumb');
	return hasBreadcrumbs && !isExcluded;
}

export async function initTocBreadcrumbsAndPdf() {
	// does the page use the toc or breadcrumbs?
	const hasToc = pageHasStandardToc();
	const hasBreadcrumbs = pageHasStandardBreadcrumbs();
	if (!hasToc && !hasBreadcrumbs) {
		return;
	}

	// load toc and breadcrumbs in parallel.
	const [
		{ url: tocUrl, nodes: tocNodes, metadata },
		{ url: breadcrumbUrl, nodes: breadcrumbNodes }
	] = await Promise.all([
		hasToc ? loadToc() : emptyToc,
		hasBreadcrumbs ? loadBreadcrumbs() : emptyBreadcrumbs
	]);

	const accessor = new TocNodeAccessor(
		getMoniker,
		tocUrl,
		breadcrumbUrl,
		msDocs.data.userDir === 'rtl'
	);
	const tocPathToLocation = findLocation(tocNodes, getLastClicked(), normalizedLocation);
	expandAndSelectPath(tocPathToLocation);

	await contentLoaded;

	if (hasToc) {
		instrumentExperimentalToc(metadata);
		initLeftToc(tocNodes, accessor, metadata);
		initModalToc(
			(
				container,
				searchExtraClasses,
				monikerPickerExtraClasses,
				treeExtraClasses,
				pdfLinkExtraClasses
			) =>
				renderToc(
					container,
					tocNodes,
					accessor,
					metadata,
					searchExtraClasses,
					monikerPickerExtraClasses,
					treeExtraClasses,
					pdfLinkExtraClasses
				)
		);
	}

	if (hasBreadcrumbs) {
		const breadcrumbs = getBreadcrumbsForUrl(breadcrumbNodes, normalizedLocation, breadcrumbUrl);
		if (hasToc && msDocs.data.extendBreadcrumb) {
			extendBreadcrumbs(breadcrumbs, tocPathToLocation);
		}
		const breadcrumbsUL = document.getElementById('page-breadcrumbs');
		renderBreadcrumbs(breadcrumbsUL, breadcrumbs, normalizedLocation, accessor);
		notifyContentUpdated();
	}
}

function initLeftToc(tocNodes: TocNode[], accessor: TocNodeAccessor, metadata: TocMetadata) {
	const container = document.getElementById('affixed-left-container');
	container.setAttribute('data-bi-name', 'left toc'); // backwards compat
	container.setAttribute('role', 'navigation');
	container.setAttribute('aria-label', loc_primary);
	const render = () => {
		tabletOrDesktopQuery.removeListener(render);
		const searchExtraClasses = ['has-margin-bottom-small'];
		renderToc(container, tocNodes, accessor, metadata, searchExtraClasses);
	};
	if (tabletOrDesktopQuery.matches) {
		render();
	} else {
		tabletOrDesktopQuery.addListener(render);
	}
}

async function renderToc(
	container: Element,
	nodes: TocNode[],
	accessor: TocNodeAccessor,
	metadata: TocMetadata,
	searchExtraClasses?: string[],
	monikerPickerExtraClasses?: string[],
	treeExtraClasses?: string[],
	pdfLinkExtraClasses?: string[]
) {
	// get a reference to the root node(s) to **display** in the tree.
	// when there's a single root node, it's not displayed.
	const nodesToDisplayInTree = nodes.length === 1 && nodes[0].children ? nodes[0].children : nodes;
	const search = createTocSearchForm(nodesToDisplayInTree, accessor);
	const tree = createTree(nodesToDisplayInTree, accessor, loc_landmarkToc);
	tree.classList.add('is-vertically-scrollable', 'has-flex-grow', 'has-flex-shrink');
	tree.addEventListener(
		'tree-item-clicked',
		({ detail }: CustomEvent<TocNode>) => setLastClicked(nodes, detail),
		true
	);
	if (useMonikerPicker && getMoniker()) {
		const allApis = false;
		const monikerPicker = createMonikerPicker2(allApis);
		if (monikerPickerExtraClasses) {
			monikerPicker.classList.add(...monikerPickerExtraClasses);
		}
		addEventListener(monikerChangedEvent, () => resetFocusTarget(tree));

		container.appendChild(monikerPicker);
	}
	if (searchExtraClasses) {
		search.classList.add(...searchExtraClasses);
	}
	if (treeExtraClasses) {
		tree.classList.add(...treeExtraClasses);
	}
	container.appendChild(search);
	container.appendChild(tree);

	if (features.pdfDownload) {
		const pdfUrl = getPdfUrl(metadata);
		if (pdfUrl) {
			const pdfLink = createPdfLink(pdfUrl);
			if (pdfLinkExtraClasses) {
				pdfLink.classList.add(...pdfLinkExtraClasses);
			}
			container.appendChild(pdfLink);
		}
	}

	await new Promise(requestAnimationFrame);
	scrollSelectedNodeIntoView(tree);
}

async function loadToc() {
	const tocUrls = getUrlQueue('tocRel', 'toc', 'toc_rel');
	const { url, data } = await loadUrlQueue('TOC', tocUrls);
	const { metadata, nodes } = normalizeTocResponse(data);
	const fusionTocs = await loadFusionTocs(metadata, url, getMoniker());
	resolveUrls(nodes, url);
	removeLrm(nodes, url);

	let conceptual: TocNode[];
	let reference: TocNode[];
	for (const fusionToc of fusionTocs) {
		resolveUrls(fusionToc.nodes, fusionToc.url);
		removeLrm(fusionToc.nodes, fusionToc.url);
		if (fusionToc.type === 'conceptual') {
			conceptual = fusionToc.nodes;
		} else {
			reference = fusionToc.nodes;
		}
	}
	mergeTocs(nodes, conceptual, reference);
	ungroupRootNode(nodes);
	return { url, nodes, metadata };
}

async function loadBreadcrumbs() {
	try {
		const bcUrls = getUrlQueue('breadcrumbPath', 'bc', 'breadcrumb_path');
		const { url, data } = await loadUrlQueue('breadcrumb', bcUrls);
		const nodes = normalizeBreadcrumbResponse(data);
		resolveUrls(nodes, url);
		return { url, nodes };
	} catch (err) {
		// log error details because they contain troubleshooting information.
		/* eslint-disable-next-line */ /* tslint:disable-next-line */ // remove after eslint migration
		console.log(err);

		const url = location.href;
		const nodes = [{ href: '/', toc_title: 'Docs' }];
		resolveUrls(nodes, url);
		return { url, nodes };
	}
}

/**
 * Scroll the selected TOC node into view.
 */
export function scrollSelectedNodeIntoView(tree: HTMLElement) {
	const treeitem = tree.querySelector('.tree-item.is-selected') as HTMLElement;
	// This logic depends on the treeitem's closest relatively positioned
	// ancestor being the .tree element.
	// https://developer.mozilla.org/en-US/docs/Web/API/HTMLelement/offsetTop
	// https://developer.mozilla.org/en-US/docs/Web/API/HTMLelement/offsetParent
	if (treeitem && treeitem.offsetTop + treeitem.offsetHeight > tree.offsetHeight) {
		// Scroll the selected treeitem into view, ensuring the preceding node's
		// text is clipped, making it evident the .tree is scrollable in browsers
		// that do not display a scrollbar (such as Safari).
		tree.scrollTop += treeitem.offsetTop - 14;
	}
}

export async function instrumentExperimentalToc({ experiment_id, experimental }: TocMetadata) {
	if (!experiment_id || !experimental) {
		return;
	}
	const awa = await jsllReady;

	awa.ct.captureContentPageAction({
		behavior: awa.behavior.OTHER,
		actionType: awa.actionType.OTHER,
		content: {
			event: 'toc-experiment',
			toc_experimental: experimental,
			toc_experiment_id: experiment_id
		}
	});
}

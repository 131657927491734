import { isPPE, isReview } from './is-production';

export function updateEditLink(container: HTMLElement) {
	if (!isReview && !isPPE) {
		return;
	}

	const contentEditLink = container.querySelector<HTMLElement>('#contenteditbtn a');

	if (!contentEditLink) {
		return;
	}

	const original_content_git_url = contentEditLink.dataset.original_content_git_url;
	const original_content_git_url_template =
		contentEditLink.dataset.original_content_git_url_template;
	const pr_repo = contentEditLink.dataset.pr_repo;
	const pr_branch = contentEditLink.dataset.pr_branch;
	if (
		original_content_git_url_template &&
		original_content_git_url_template.length &&
		pr_repo &&
		pr_repo.length &&
		pr_branch &&
		pr_branch.length
	) {
		contentEditLink.setAttribute(
			'href',
			original_content_git_url_template.replace('{repo}', pr_repo).replace('{branch}', pr_branch)
		);
	} else if (original_content_git_url && original_content_git_url.length) {
		contentEditLink.setAttribute('href', original_content_git_url);
	}
}

import { loc_codeSamples, loc_documentation } from '@msdocs/strings';
import { deepClone } from '../clone-object';
import { NavItem } from '../components/navbar/model';
import { apis } from '../environment/apis';
import { msDocs } from '../globals';
import { isStyleGuidePage, normalizedPathSegments } from '../is-production';
import { getMeta } from '../meta';
import {
	advocatesHeader,
	assessmentsHeader,
	developerHeader,
	docsDefaultHeader,
	learnHeader,
	regionalDirectorsHeader,
	styleGuideHeader,
	UhfParseResult
} from './model';
import { getContentNav, getHeaderId, UhfError } from './uhf-service';
import { traits } from '../environment/traits';

export const siteContext = getSiteContext({
	brand: msDocs.data.brand,
	pathnameSegments: normalizedPathSegments,
	page_kind: getMeta('page_kind'),
	page_type: getMeta('page_type'),
	headerId: getHeaderId()
});

export const siteNavMenuTitle = getSiteContextTitle(siteContext);

function getSiteContextTitle(siteContext: SiteContext) {
	switch (siteContext) {
		case 'Code Samples':
			return loc_codeSamples;
		case 'Learn':
		case 'Profile':
			return 'Learn';
		case 'Q&A':
			return 'Q&A';
		case 'Advocates':
		case 'Assessments':
		case 'Developer':
		case 'Docs_Home':
		case 'Documentation':
		case 'Mooncake':
		case 'Regional Directors':
		case 'Style-Guide':
		case 'Third-Party':
		default:
			return loc_documentation;
	}
}

export const siteContentNav = getSiteNavBarUhf(siteContext);

export interface SiteContextConfig {
	/**
	 * The string from msDocs.data.brand
	 */
	brand: string;

	/**
	 * The normalized pathname segments
	 */
	pathnameSegments: string[];

	/**
	 * The meta page_kind
	 */
	page_kind: string;

	/**
	 * The meta page_type
	 */
	page_type: string;

	/**
	 * The header Id determined by getHeaderId function
	 */
	headerId: string;
}

/**
 * Type that ascribes categories to Docs pages for classification under global navigation menu.
 * Some pages/sites are orphaned (i.e. Assessments, Developer); these will default to Documentation for parent association.
 */
export type SiteContext =
	| 'Advocates' // tages pages with headerId === 'MSDocsHeader-Advocates'
	| 'Assessments' // tags pages with page_kind === 'assessments'
	| 'Code Samples' // tags pages with pathname matching /{locale}/samples
	| 'Developer' // tags pages with headerId DEV_Homeheader
	| 'Docs_Home' // tags the page with page_kind === 'docs' and page_type === 'home'
	| 'Documentation' // tags pages with headerId === or defaulting to MSDocsHeader-DocsL1 when missing
	| 'Learn' // tags pages with headerId MSDocsHeader-Learn
	| 'Mooncake' // tags pages with headerId mooncake
	| 'Profile' // tags pages with page_type === 'profile'
	| 'Q&A' // tags pages with headerId === 'answers'
	| 'Regional Directors' // tags pages with headerId === 'MSDocsHeader-RegionalDirectors'
	| 'Style-Guide' // tags pages with page_kind === 'style-guide' or location.hostname === 'docsstyleguide.z13.web.core.windows.net'
	| 'Third-Party'; // tags pages that are distinguishable by headerId but are not Docs First-party

export function getSiteContext(config: SiteContextConfig): SiteContext {
	const { brand, pathnameSegments, page_kind, page_type, headerId } = config;
	if (page_kind === 'docs' && page_type === 'home') {
		return headerId === 'MSDocsHeader-Archive' ? 'Third-Party' : 'Docs_Home';
	}

	if (page_type === 'profile') {
		return 'Profile';
	}

	if (page_kind === 'assessments') {
		return 'Assessments';
	}

	if (isStyleGuidePage || page_kind === 'style-guide') {
		return 'Style-Guide';
	}

	if (pathnameSegments[2] === 'samples') {
		return 'Code Samples';
	}

	// temporary code to be removed when mooncake has headerId
	if (brand === 'mooncake') {
		return 'Mooncake';
	}

	switch (headerId) {
		case 'answers':
			return 'Q&A';
		case 'mooncake':
			return 'Mooncake';
		case 'MSDocsHeader-Advocates':
			return 'Advocates';
		case 'MSDocsHeader-Learn':
			// learn, learn-sandbox
			return 'Learn';
		case 'MSDocsHeader-RegionalDirectors':
			return 'Regional Directors';
		case 'DEV_Homeheader':
			return 'Developer';
		case 'MSDocsHeader-DocsL1':
			return 'Documentation';
		default:
			return 'Third-Party';
	}
}

export async function getSiteNavBarUhf(siteContext: SiteContext): Promise<UhfParseResult> {
	switch (siteContext) {
		case 'Advocates':
			return Promise.resolve(advocatesHeader);
		case 'Assessments':
			return Promise.resolve(assessmentsHeader);
		case 'Code Samples':
		case 'Docs_Home':
		case 'Documentation':
		case 'Profile':
			return Promise.resolve(docsDefaultHeader);
		case 'Learn':
			// learn, learn-sandbox
			return Promise.resolve(learnHeader);
		case 'Style-Guide':
			return Promise.resolve(styleGuideHeader);
		case 'Regional Directors':
			return Promise.resolve(regionalDirectorsHeader);
		case 'Developer':
		case 'Mooncake':
		case 'Q&A':
		case 'Third-Party':
		default:
			if (siteContext === 'Developer' && traits.accessLevel === 'isolated') {
				return Promise.resolve(developerHeader);
			}
			try {
				const resolvedUhf = await getContentNav();
				return Promise.resolve(resolvedUhf);
			} catch (e) {
				/* eslint-disable-next-line */ /* tslint:disable-next-line */ // remove after eslint migration
				console.log(`UHF header ID: ${(e as UhfError).headerId}, ${e}`);
				return Promise.resolve(docsDefaultHeader);
			}
	}
}

/**
 * Determines if there's secondary navigation given the site context.
 * @param context The site context
 */
export function hasSecondaryNavigation(context: SiteContext) {
	return (
		context === 'Third-Party' ||
		context === 'Advocates' ||
		context === 'Assessments' ||
		context === 'Learn' ||
		context === 'Mooncake' ||
		context === 'Q&A' ||
		context === 'Regional Directors' ||
		context === 'Style-Guide'
	);
}

export function resolveLinkUrl(navItem: NavItem): NavItem {
	if (traits.systemContent === true) {
		return navItem;
	}

	if (navItem.kind === 'menu') {
		const menu = deepClone(navItem);
		menu.items = menu.items.map(resolveLinkUrl);
		return menu;
	}

	const link = deepClone(navItem);
	link.href = new URL(navItem.href, apis.contentNav).href;
	return link;
}

import { getMeta } from '../../meta';

export function hasApplicationInsights(): boolean {
	const pageType = getMeta('page_type');
	const pageKind = getMeta('page_kind');

	const isIncluded =
		pageType === 'learn' ||
		pageType === 'profile' ||
		(pageKind === 'learn' && (pageType === 'browse' || pageType === 'home'));

	return isIncluded;
}

import { html } from '../../../lit-html';
import { FacetViewModel } from '../facets';

/**
 * Create an exander element if a facet view model is expandable.
 * Only on trees and parent view models with no selected children.
 * @param fvm A facet's view model
 * @param isTree Whether or not this Facet View Model is part of a tree-like structure
 */
export function createExpander(fvm: FacetViewModel, isTree: boolean) {
	if (!isTree && !fvm.hasChildren) {
		// This isn't a tree, render nothing.
		return null;
	}

	if (!fvm.hasChildren || fvm.hasSelectedChild || fvm.group.filterActive) {
		// Introduce a spacer element instead. Line up with items with a expand/collapse button.
		return html` <div class="has-padding-left-large has-margin-right-extra-small"></div> `;
	}

	return html` <button
		data-bi-name="facet-group-expander-${name}"
		aria-controls="${fvm.value}-child-list"
		aria-expanded="${fvm.isExpanded}"
		class="has-inner-focus has-cursor-pointer has-margin-right-extra-small has-border-none has-appearance-none has-background-transparent has-padding-none"
		@click=${() => fvm.toggleExpanded()}
	>
		<span class="icon expanded-indicator" aria-hidden="true">
			<span class="docon docon-chevron-down-light"></span>
		</span>
	</button>`;
}

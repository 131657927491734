import { loc_all } from '@msdocs/strings';
import { html } from '../../../lit-html';
import { contentAreaNames, ContentAreaId } from '../../../name-maps/content-areas';
import { forceWrite } from '../../../plugins/lit-html/force-write';
import { FacetGroupViewModel } from '../facet-group';
import { numberFormatCompact } from '../../../number-format';

/**
 * Create the Content Area facet radios, handle their changes.
 * @param vm The 'category' facet's view model,
 * @param idSuffix: a string used to differentiate between different groups of radio buttons
 * Instance of the same view model that handles normal facets.
 */
export function categoryFacetTemplate(vm: FacetGroupViewModel, idSuffix: string) {
	return html`
			<fieldset
				data-bi-name="facet-group-${vm.name}${idSuffix ? `-${idSuffix}` : ''}"
				class="control has-margin-bottom-medium"
				@change=${(e: Event) => {
					// lit-html continues its life-long battle with radios,
					// deselect all before changes to prevent selection bugs
					(Array.from(
						document.querySelectorAll('input[type="checkbox"]')
					) as HTMLInputElement[]).forEach(c => (c.checked = false));

					const target = e.target as HTMLInputElement;
					vm.selectByValue(target.value);
				}}
			>
				<div class="radios is-vertical">
					${facetRadios(`${vm.key}${idSuffix}`, vm.facets)}
				</div>
			</fieldset>
		</div>
	</div>`;
}

const radioTemplate = (
	name: string,
	value: string,
	localizeName: string,
	count: number,
	checked: boolean = false
) => {
	const selectedTagClass = checked ? 'is-primary' : '';
	return html`<label class="radio" data-bi-name="facet-radio-${name}">
		<input name="${name}" type="radio" value="${value}" .checked=${forceWrite(checked)} />
		<span class="radio-dot" aria-hidden="true"></span>
		<span
			class="radio-label-text has-flex-grow is-flex has-flex-justify-content-space-between ${count ===
			0
				? 'has-text-subtle'
				: ''}"
		>
			<span>${localizeName}</span>
			<span class="tag is-small is-rounded ${selectedTagClass}"
				>${numberFormatCompact.format(count)}</span
			>
		</span>
	</label>`;
};

/**
 * Produce the template for the 'all' content area facet.
 * This is different because it is not returned by the api response.
 * It requires calculation before rendering.
 * @param name The name of the radio group, i.e. 'category'
 * @param facets The facets creates as a result of the api response.
 */
function allFacetRadio(name: string, facets: FacetGroupViewModel['facets']) {
	const facetTotals = facets.reduce(
		(totals, facet) => {
			if (facet.isSelected) {
				totals.selection = true;
			}
			totals.count += facet.count;
			return totals;
		},
		{ count: 0, selection: false }
	);
	return radioTemplate(name, 'all', loc_all, facetTotals.count, !facetTotals.selection);
}

function facetRadios(name: string, facets: FacetGroupViewModel['facets']) {
	const keys = Object.keys(contentAreaNames);
	return keys.map(key => {
		const facet = facets.find(f => f.value.toLowerCase() === key);
		// all area has to be calculated
		if (key === 'all') {
			return allFacetRadio(name, facets);
		} else if (!facet) {
			// unselected area aren't returned, so in this case we must create a placeholder
			return radioTemplate(name, key, contentAreaNames[key as ContentAreaId], 0, false);
		} else {
			// the template for an area with results
			return radioTemplate(name, facet.value, facet.name, facet.count, facet.isSelected);
		}
	});
}
